import React from "react";
import withShipment from "../../../withShipment";
import {
    ADD_DOCUMENT, BACK,
    CANCEL,
    FTL_DOCUMENT_TYPES,
    FTL_PAGES,
    FTL_SETUP_HELPER_TEXT,
    FTL_SETUP_TABLE_HEADERS,
    FULL_TRUCKLOAD, FULL_TRUCKLOAD_SETTINGS,
    REMOVE_DOCUMENT,
    SAVE
} from "../constants";
import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import SaveIcon from '@mui/icons-material/Save';
import IconButton from "@material-ui/core/IconButton";
import {getByteArray} from "../../global/constants";
import FileUpload from "../../global/FileUpload";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {Button, Table, TableRow, TextField, Tooltip} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Chip from "@material-ui/core/Chip";
import {listProducts} from "../../../redux/actions/settings";
import {productsSelector} from "../../../redux/selectors/settings";
import {fetchFullTruckloadSettings, saveFullTruckloadSettings} from "../../../redux/actions/fullTruckload";
import TableCell from "@material-ui/core/TableCell";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import Divider from "@material-ui/core/Divider";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
// import styling
import { Tabs, Tab } from '@material-ui/core';
import './FullTruckloadSetup.css'
import {fullTruckloadSettingsSelector} from "../../../redux/selectors/fullTruckload";
import DragAndDrop from "../../global/Dnd/DragAndDrop";

class FullTruckloadSetup extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            attachments: [],
            billOfLading: {},
            deletedAttachments: [],
            deletingRowIndex: -1,
            settingsChanged: false,
            selectedTab: 0,
        }
    }

    componentDidMount() {
        this.props.listProducts()
        this.props.fetchFullTruckloadSettings()
        if (this.props.fullTruckloadSettings) {
            this.setState({
                attachments: this.props.fullTruckloadSettings.attachments ? [...this.props.fullTruckloadSettings.attachments] : [],
                billOfLading: this.props.fullTruckloadSettings.billOfLading ? {...this.props.fullTruckloadSettings.billOfLading} : {}
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.fullTruckloadSettings !== prevProps.fullTruckloadSettings) {
            this.setState({
                attachments: this.props.fullTruckloadSettings.attachments ? [...this.props.fullTruckloadSettings.attachments] : [],
                billOfLading: this.props.fullTruckloadSettings.billOfLading ? {...this.props.fullTruckloadSettings.billOfLading} : {}
            })
        }
    }

    handleTabChange = (event, newValue) => {
        this.setState({ selectedTab: newValue });
    };

    updateAttachmentShortcodes = (index, newShortcodes) => {
        let newAttachments = this.state.attachments
        newAttachments[index].shortcodes = newShortcodes
        this.setState({
            attachments: newAttachments,
            settingsChanged: true
        })
    }

    updateAttachmentType = (index, type) => {
        let newAttachments = this.state.attachments
        newAttachments[index].type = type
        this.setState({
            attachments: newAttachments,
            settingsChanged: true
        })
    }

    addAttachment = () => {
        let newAttachments = this.state.attachments
        newAttachments.push({
            file: {},
            shortcodes: [],
            type: "",
            requiredForDomestic: false,
            requiredForInternational: false
        })
        this.setState({
            attachments: newAttachments,
            settingsChanged: true
        })
    }

    deleteAttachment = (index) => {
        let newAttachments = this.state.attachments
        let newDeletedAttachments = this.state.deletedAttachments
        newDeletedAttachments.push(newAttachments[index])
        newAttachments.splice(index, 1)
        this.setState({
            attachments: newAttachments,
            deletedAttachments: newDeletedAttachments,
            deletingRowIndex: -1,
            settingsChanged: true
        })
    }

    getFormattedGeneralFileObject = (file) => {
        return { localURL: URL.createObjectURL(file), displayName: file.name, byteArray: getByteArray(file), type: file.type }

    }

    handleAddBillOfLading = (file) => {
        let newFile = this.getFormattedGeneralFileObject(file)
        this.setState({
            billOfLading: newFile,
            settingsChanged: true
        })
    }

    handleGeneralFileAdd = (file, index) => {
        let newAttachments = this.state.attachments
        // replace new file with previous file
        if (newAttachments[index].file?.displayName && !newAttachments[index].file?.byteArray) {
            let deletedAttachment = JSON.parse(JSON.stringify(newAttachments[index]));
            this.setState({
                deletedAttachments: [...this.state.deletedAttachments, deletedAttachment]
            });
        }
        newAttachments[index].file = this.getFormattedGeneralFileObject(file)
        this.setState({
            attachments: newAttachments,
            settingsChanged: true
        });
    }

    handleGeneralFileDelete = (file, fileIndex, index) => {
        let newAttachments = this.state.attachments
        // make a deep copy
        let deletedAttachment = JSON.parse(JSON.stringify(newAttachments[index]));
        newAttachments[index].file = {}
        this.setState({
            deletedAttachments: [...this.state.deletedAttachments, deletedAttachment],
            attachments: newAttachments,
            settingsChanged: true,
        })
    }

    toggleRequiredForDomestic = (index) => {
        let newAttachments = this.state.attachments
        newAttachments[index].requiredForDomestic = !newAttachments[index].requiredForDomestic

        this.setState({
            attachments: newAttachments,
            settingsChanged: true
        })
    }

    toggleRequiredForInternational = (index) => {
        let newAttachments = this.state.attachments
        newAttachments[index].requiredForInternational = !newAttachments[index].requiredForInternational

        this.setState({
            attachments: newAttachments,
            settingsChanged: true
        })
    }

    saveSettings = () => {
        let newSettings = {
            attachments: this.state.attachments,
            billOfLading: this.state.billOfLading,
            deletedAttachments: this.state.deletedAttachments.filter(attachment => attachment.file?.displayName)
        }
        this.props.saveFullTruckloadSettings(newSettings, () => this.setState({ settingsChanged: false }))
    }

    handleAttachmentsOrderChange = (attachments) => {
        let newOrderedAttachments = []
        for (let i = 0; i < attachments.length; i++) {
            if (this.state.attachments.indexOf(attachments[i]) !== -1) {
                newOrderedAttachments.push(attachments[i])
            }
        }
        this.setState({
            attachments: newOrderedAttachments,
            settingsChanged: true
        })
    }

    render() {
        const shortcodes = this.props.products.map((product) => {
            return product.shortcode
        })

        return (
            <React.Fragment>
                <TopToolbar
                    pageName={FULL_TRUCKLOAD_SETTINGS}
                    menuItems={[
                        {title: BACK, icon: <KeyboardBackspaceIcon/>, onClick: () => {
                            this.props.setCurrentPage(FTL_PAGES.TABLE)
                        }},
                        {title: SAVE, icon: <SaveIcon/>, onClick: () => {
                                this.saveSettings()
                                this.props.setCurrentPage(FTL_PAGES.TABLE)
                            }, disabled: !this.state.settingsChanged}
                    ]}
                />

                <Tabs
                    value={this.props.selectedTab}
                    onChange={(event, value) => this.props.handleTabChange(value)}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab label= {FTL_PAGES.SETUP} />
                    <Tab label= {FTL_PAGES.BROKERS} />
                    <Tab label= {FTL_PAGES.CARRIERS} />
                </Tabs>

                <div>
                    <div style={{margin: "25px"}}>
                        {FTL_SETUP_HELPER_TEXT}
                    </div>



                    <Table style={{marginLeft: "25px", width: "97%"}}>
                        <TableRow className="table">
                            <TableCell className="add-delete-col" />
                            {FTL_SETUP_TABLE_HEADERS.map((headCell) => (
                                <TableCell key={headCell.id}
                                           style={{textAlign: "center", fontWeight: "bold", width: headCell.width}}>
                                    {headCell.label}
                                </TableCell>
                            ))}
                        </TableRow>
                        <TableRow className="table">
                            <TableCell style={{ textAlign: "center" }} colSpan={6}>
                                <div style={{
                                    justifyContent: "center",
                                    marginRight: "auto",
                                    marginLeft: "auto",
                                }}>
                                    <DndProvider backend={HTML5Backend}>
                                        <DragAndDrop
                                            cards={this.state.attachments}
                                            handleTableDisplayChange = {(attachments) => this.handleAttachmentsOrderChange(attachments)}
                                            items = {this.state.attachments.map((attachment, index) => {
                                                return (
                                                    {
                                                        id: attachment.id,
                                                        component:
                                                            <div style={{
                                                                display: "flex",
                                                                border: "1px solid grey",
                                                                margin: "5px",
                                                                marginBottom: "20px",
                                                                borderRadius: "10px",
                                                                flexDirection: "row",
                                                                alignItems: "center",
                                                                height: "75px"
                                                            }}>
                                                                <Tooltip title={REMOVE_DOCUMENT}>
                                                                    <IconButton onClick={() => this.deleteAttachment(index)}>
                                                                        <RemoveCircleIcon
                                                                            fontSize='small'
                                                                            color='error'
                                                                        />

                                                                    </IconButton>
                                                                </Tooltip>
                                                                <div style={{borderRadius: "1px", width:"21%", paddingLeft: "1.8%"}}>
                                                                    <Autocomplete
                                                                        className="ftl-attachment-shortcode-field"
                                                                        multiple
                                                                        disableCloseOnSelect
                                                                        options={shortcodes}
                                                                        getOptionLabel={(entry) => entry}
                                                                        renderTags={(value, getTagProps) =>
                                                                            value.map((option) => (
                                                                                <Chip
                                                                                    variant="outlined"
                                                                                    size="small"
                                                                                    style={{ borderColor: "#acd685", margin: "1px 6px 1px 0", fontSize: "12px"}}
                                                                                    label={option}
                                                                                />
                                                                            ))
                                                                        }
                                                                        renderOption={(option, { selected }) => (
                                                                            <React.Fragment>
                                                                                <Checkbox
                                                                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                                                    style={{ marginRight: 8 }}
                                                                                    checked={selected}
                                                                                />
                                                                                {option}
                                                                            </React.Fragment>
                                                                        )}
                                                                        autoHighlight={true}
                                                                        blurOnSelect={false}
                                                                        value={attachment.shortcodes}
                                                                        onChange={(event, value) => this.updateAttachmentShortcodes(index, value)}
                                                                        renderInput={(params) =>
                                                                            <TextField
                                                                                {...params}
                                                                                InputLabelProps={{ shrink: true }}
                                                                                variant="outlined"
                                                                                name="shortcodes"
                                                                            />
                                                                        }
                                                                    />
                                                                </div>
                                                                <div style={{width:"22%", paddingLeft: "2.4%"}}>
                                                                    <FileUpload
                                                                        handleFileAdd={(file) => this.handleGeneralFileAdd(file, index)}
                                                                        handleFileDelete={(file, fileIndex) => this.handleGeneralFileDelete(file, fileIndex, index)}
                                                                        files={attachment.file.displayName || attachment.file.path ? [attachment.file] : []}
                                                                        fetchFilePreview={(file) => this.props.previewFullTruckloadFile({path: file.path, displayName: file.displayName, index: index})}
                                                                        singleFileUpload={true}
                                                                        isFTL={true}
                                                                    />
                                                                </div>
                                                                <div style={{borderRadius: "1px", width:"22%",  paddingLeft: "2.8%"}}>
                                                                    <Autocomplete
                                                                        className="ftl-item-shortcode-field"
                                                                        options={FTL_DOCUMENT_TYPES}
                                                                        getOptionLabel={(entry) => entry}
                                                                        autoHighlight={true}
                                                                        blurOnSelect={false}
                                                                        value={attachment.type}
                                                                        onChange={(event, value) => this.updateAttachmentType(index, value)}
                                                                        renderInput={(params) =>
                                                                            <TextField
                                                                                {...params}
                                                                                variant="outlined"
                                                                                name="type"
                                                                                InputLabelProps={{ shrink: true }}
                                                                            />
                                                                        }
                                                                    />
                                                                </div>
                                                                <div style={{borderRadius: "1px", width:"10%", paddingLeft: "9%"}}>
                                                                    <Checkbox
                                                                        checked={attachment.requiredForDomestic}
                                                                        onChange={() => this.toggleRequiredForDomestic(index)}
                                                                        name="requiredForDomestic"
                                                                        style={{ color: '#acd685' }}
                                                                    />
                                                                </div>
                                                                <div style={{borderRadius: "1px", width:"10%",  paddingLeft: "14.5%"}}>
                                                                    <Checkbox
                                                                        checked={attachment.requiredForInternational}
                                                                        onChange={() => this.toggleRequiredForInternational(index)}
                                                                        name="requiredForInternational"
                                                                        style={{ color: '#acd685' }}
                                                                    />
                                                                </div>
                                                            </div>
                                                    }
                                                )
                                            })}
                                        />
                                    </DndProvider>
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableCell style={{ textAlign: "center", border: "none" }}>
                            <Tooltip title={ADD_DOCUMENT}>
                                <IconButton onClick={this.addAttachment}>
                                    <AddCircleIcon
                                        fontSize="small"
                                        color='secondary'
                                    />
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                    </Table>



                    <div className="ftl-stepper-buttons">
                        <Button
                            style={{marginRight: "10px"}}
                            variant='contained'
                            onClick={() => this.props.setCurrentPage(FTL_PAGES.TABLE)}
                        >
                            {CANCEL}
                        </Button>

                        <Button
                            style={{marginLeft: "10px"}}
                            variant='contained'
                            color='primary'
                            onClick={() => {
                                this.saveSettings()
                                this.props.setCurrentPage(FTL_PAGES.TABLE)
                            }}
                            disabled={!this.state.settingsChanged}
                        >
                            {SAVE}
                        </Button>
                    </div>
                </div>

            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    products: productsSelector(state),
    fullTruckloadSettings: fullTruckloadSettingsSelector(state),
})

const actionCreators = {
    fetchFullTruckloadSettings,
    listProducts,
    saveFullTruckloadSettings,
}

export default withShipment({
    mapStateToProps,
    actionCreators,
}, FullTruckloadSetup);