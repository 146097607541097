export const FETCH_FULL_TRUCKLOADS_CARRIERS = "FETCH_FULL_TRUCKLOADS_CARRIERS"
export const DELETE_FULL_TRUCKLOADS_CARRIER = "DELETE_FULL_TRUCKLOADS_CARRIER"
export const SAVE_FULL_TRUCKLOADS_CARRIERS = "SAVE_FULL_TRUCKLOADS_CARRIERS"
export const STORE_FULL_TRUCKLOADS_CARRIERS = "STORE_FULL_TRUCKLOADS_CARRIERS"
export const STORE_FULL_TRUCKLOADS_CARRIER = "STORE_FULL_TRUCKLOADS_CARRIER"
export const LIST_FTL_CARRIER_INVOICE_TEMPLATES = "LIST_FTL_CARRIER_INVOICE_TEMPLATES"
export const LIST_FTL_CARRIERS_WITHOUT_TEMPLATE = "LIST_FTL_CARRIERS_WITHOUT_TEMPLATE"
export const GET_FTL_CARRIER_INVOICE_TEMPLATE_PDF = "GET_FTL_CARRIER_INVOICE_TEMPLATE_PDF"
export const STORE_FTL_CARRIERS_WITHOUT_TEMPLATE = "STORE_FTL_CARRIERS_WITHOUT_TEMPLATE"
export const CREATE_OR_EDIT_FTL_CARRIER_INVOICE_TEMPLATE = "CREATE_OR_EDIT_FTL_CARRIER_INVOICE_TEMPLATE"
export const STORE_FTL_CARRIER_TEMPLATES = "STORE_FTL_CARRIER_TEMPLATES"
export const UPDATE_FTL_CARRIER_INVOICE_TEMPLATES = "UPDATE_FTL_CARRIER_INVOICE_TEMPLATES"
export const STORE_FTL_CARRIER_INVOICE_TEMPLATE_PDF = "STORE_FTL_CARRIER_INVOICE_TEMPLATE_PDF"
export const DELETE_INVOICE_TEMPLATE = "DELETE_INVOICE_TEMPLATE"
export const REMOVE_INVOICE_TEMPLATE = "REMOVE_INVOICE_TEMPLATE"