import React from "react";
import withShipment from "../../../../withShipment";
import './ListingsLoading.css';
import {LISTING_TITLE, LISTINGS_PAGES, LOADING_MESSAGE} from "../constants";
import TopToolbar from "../../../global/subcomponents/topToolbar/TopToolbar";
import {Typography} from "@mui/material";
import {CircularProgress} from "@material-ui/core";
import {fetchAmazonSkus} from "../../../../redux/actions/settings";
import {loadingSelector} from "../../../../redux/selectors/global";


class ListingsLoading extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }

        if (!this.props.isLoading) {
            this.props.setCurrentPage(LISTINGS_PAGES.TABLE)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.isLoading) {
            this.props.setCurrentPage(LISTINGS_PAGES.TABLE)
        }
    }

    render() {
        return (
            <React.Fragment>
                <TopToolbar
                    pageName={LISTING_TITLE}
                />
                <div className="getPrinterLoadingOuterBox">
                    <Typography variant="h6">
                        {LOADING_MESSAGE}
                    </Typography>
                    <div className="loadingBox">
                        <CircularProgress size={100} />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

ListingsLoading.defaultProps = {
}

ListingsLoading.propTypes = {
}

const mapStateToProps = (state) => ({
    isLoading: loadingSelector(state),
})

const actionCreators = {
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, ListingsLoading);