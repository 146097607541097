import React from "react";
import withShipment from "../../withShipment";
import {FTL_PAGES, FTL_STATUSES} from "./constants";
import FullTruckloadTable from "./FullTruckloadPages/FullTruckloadTable";
import FullTruckloadForm from "./FullTruckloadPages/FullTruckloadForm";
import FullTruckloadSetup from "./FullTruckloadPages/FullTruckloadSetup";
import {
    fullTruckloadOperationResponseSelector,
    previewingFullTruckloadDataSelector
} from "../../redux/selectors/fullTruckload";
import {
    previewFullTruckloadFile,
    previewFullTruckloadDocument, fetchFullTruckloads
} from "../../redux/actions/fullTruckload";
import FullTruckLoadPreview from "./FullTruckloadPages/FullTruckLoadPreview";
import {userInfoSelector} from "../../redux/selectors/auth";
import FullTruckloadFileUpload from "./FullTruckloadPages/FullTruckloadFileUpload";
import ErrorFeedbackBar from "../global/ErrorFeedbackBar";
import FullTruckLoadSetting from "./FullTruckloadPages/FullTruckLoadSetting";
import FeedbackBar from "../feedbackBar/FeedbackBar";
import {SUCCESS} from "../shipments/constants";

class FullTruckload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: FTL_PAGES.TABLE,
            prevPage: null,
            isPreviewingFinalDoc: null,
            editingFullTruckloadInfo: {
                id: "",
                items: [{
                    shortcode: "",
                    quantity: 0,
                    numOfSkids: "0",
                    isSample: false
                }],
                fromAddress: {
                    contactName: "",
                    companyName: "",
                    phoneNumber: "",
                    addressLine1: "",
                    addressLine2: "",
                    city: "",
                    stateOrProvince: "",
                    postalCode: "",
                    country: "",
                    email: "",
                    shipAddressId: ""
                },
                toAddress: {
                    contactName: "",
                    companyName: "",
                    phoneNumber: "",
                    addressLine1: "",
                    addressLine2: "",
                    city: "",
                    stateOrProvince: "",
                    postalCode: "",
                    country: "",
                    email: "",
                    shipAddressId: ""
                },
                shipmentNumber: "",
                sealNumber: "",
                poNumber: "",
                deliveryNumber: "",
                truckNumber: "",
                trailerNumber: "",
                shipDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0),
                expectedDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0),
                receivedDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0),
                pickupTimeIn: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0),
                pickupTimeOut: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0),
                receivedTimeIn: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0),
                receivedTimeOut: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0),
                carrierName: "",
                brokerName: "",
                packingCost: "",
                freightCost: "",
                devanCost: "",
                insuranceCost: "",
                otherCosts: "",
                partiesToTransaction: "",
                shipmentTerms: "",
                dutiesTaxesPayableBy: "",
                dutiesTaxesPayableByExporter: "",
                dutiesTaxesPayableByOther: "",
                currencyOfSale: "",
                emergencyPhoneNum: "",
                notes: "",
                attachments: [],
                billOfLading: null,
                isBillOfLadingSigned: false,
                commercialInvoice: null,
                isCommercialInvoiceSigned: false,
                finalDocument: null,
                status: FTL_STATUSES.DRAFT,
                newAttachments: [],
                feedbackBarOpen:false
            },
        }
    }

    setPreviewingFinalDoc = (isFinalDoc) => {
        this.setState({
            isPreviewingFinalDoc: isFinalDoc
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.operationResponse !== this.props.operationResponse && this.props.operationResponse!=="") {
            this.setState({
                feedbackBarOpen: true
            })
        }
    }

    setCurrentPage = (newPage, info = null, prevPage = null) => {
        if (newPage === FTL_PAGES.TABLE) {
            this.props.fetchFullTruckloads()
        }
        if (info) {
            let newInfo = this.state.editingFullTruckloadInfo
            newInfo.id = info.fullTruckloadId
            newInfo.items = info.items
            newInfo.fromAddress = info.fromAddress
            newInfo.toAddress = info.toAddress
            newInfo.shipmentNumber = info.shipmentNumber
            newInfo.sealNumber= info.sealNumber
            newInfo.poNumber = info.poNumber
            newInfo.deliveryNumber = info.deliveryNumber
            newInfo.truckNumber = info.truckNumber
            newInfo.trailerNumber = info.trailerNumber
            newInfo.shipDate = info.shipDate
            newInfo.expectedDate = info.expectedDate
            newInfo.receivedDate = info.receivedDate
            newInfo.pickupTimeIn = info.pickupTimeIn
            newInfo.pickupTimeOut = info.pickupTimeOut
            newInfo.receivedTimeIn = info.receivedTimeIn
            newInfo.receivedTimeOut = info.receivedTimeOut
            newInfo.carrierName = info.carrierName
            newInfo.brokerName = info.brokerName
            newInfo.packingCost = info.packingCost
            newInfo.freightCost = info.freightCost
            newInfo.devanCost = info.devanCost
            newInfo.insuranceCost = info.insuranceCost
            newInfo.otherCosts = info.otherCosts
            newInfo.partiesToTransaction = info.partiesToTransaction
            newInfo.shipmentTerms = info.shipmentTerms
            newInfo.dutiesTaxesPayableBy = info.dutiesTaxesPayableBy
            newInfo.dutiesTaxesPayableByExporter = info.dutiesTaxesPayableByExporter
            newInfo.dutiesTaxesPayableByOther = info.dutiesTaxesPayableByOther
            newInfo.currencyOfSale = info.currencyOfSale
            newInfo.emergencyPhoneNum = info.emergencyPhoneNum
            newInfo.notes = info.notes
            newInfo.attachments = info.attachments
            newInfo.billOfLading = info.billOfLading
            newInfo.isBillOfLadingSigned = info.isBillOfLadingSigned
            newInfo.commercialInvoice = info.commercialInvoice
            newInfo.isCommercialInvoiceSigned = info.isCommercialInvoiceSigned
            newInfo.finalDocument = info.finalDocument
            newInfo.status = info.status
            newInfo.newAttachments = info.newAttachments

            this.setState({
                currentPage: newPage,
                editingFullTruckloadInfo: newInfo,
                prevPage: prevPage
            })
        }
        else {
            this.setState({
                currentPage: newPage,
                prevPage: prevPage
            })
        }
    }

    displayTable = () => {
        return <React.Fragment>
            <FullTruckloadTable
                setCurrentPage={this.setCurrentPage}
                previewFullTruckloadDocument={this.props.previewFullTruckloadDocument}
                prevPage={this.state.prevPage}
                user={this.props.user}
            />
        </React.Fragment>
    }

    displayCreating = () => {
        return <React.Fragment>
            <FullTruckloadForm
                creating={true}
                setCurrentPage={this.setCurrentPage}
                prevPage={this.state.prevPage}
                user={this.props.user}
            />
        </React.Fragment>
    }

    displayUpdating = () => {
        return <React.Fragment>
            <FullTruckloadForm
                updating={true}
                setCurrentPage={this.setCurrentPage}
                editingFullTruckload={this.state.editingFullTruckloadInfo}
                previewFullTruckloadDocument={this.props.previewFullTruckloadDocument}
                previewingFullTruckloadData={this.props.previewingFullTruckloadData}
                prevPage={this.state.prevPage}
                setPreviewingFinalDoc={this.setPreviewingFinalDoc}
                user={this.props.user}
            />
        </React.Fragment>
    }

    displayFileUpload = () => {
        return <React.Fragment>
            <FullTruckloadFileUpload
                setCurrentPage={this.setCurrentPage}
                billOfLading={this.state.editingFullTruckloadInfo.billOfLading ? this.state.editingFullTruckloadInfo.billOfLading : {}}
                commercialInvoice={this.state.editingFullTruckloadInfo.commercialInvoice ? this.state.editingFullTruckloadInfo.commercialInvoice : {}}
                id={this.state.editingFullTruckloadInfo.id}
            />
        </React.Fragment>
    }

    displayPreview = () => {
        return <React.Fragment>
            <FullTruckLoadPreview
                setCurrentPage={this.setCurrentPage}
                previewingFullTruckloadData={this.props.previewingFullTruckloadData}
                date={this.state.editingFullTruckloadInfo.shipDate}
                prevPage={this.state.prevPage}
                isPreviewingFinalDoc={this.state.isPreviewingFinalDoc}
                isBillOfLadingSigned={this.state.editingFullTruckloadInfo.isBillOfLadingSigned}
            />
        </React.Fragment>
    }

    displaySettings = () =>{
        return <React.Fragment>
            <FullTruckLoadSetting
                setCurrentPage={this.setCurrentPage}
                previewFullTruckloadFile={this.props.previewFullTruckloadFile}
                prevPage={this.state.prevPage}
            />
        </React.Fragment>
    }


    displayCurrentPage = (currentPage) => {
        switch (currentPage) {
            case FTL_PAGES.TABLE:
                return this.displayTable()
            case FTL_PAGES.CREATE:
                return this.displayCreating()
            case FTL_PAGES.UPDATE:
                return this.displayUpdating()
            case FTL_PAGES.SETTINGS:
                return this.displaySettings()
            case FTL_PAGES.UPLOAD:
                return this.displayFileUpload()
            case FTL_PAGES.PREVIEW:
                return this.displayPreview()
            default:
                console.log("Error: current damages page doesn't exist")
                return <div>We encountered an error. Please refresh the page.</div>
        }
    }

    closeFeedbackBar = () => {
        this.setState({
            feedbackBarOpen: false
        })
    }

    render() {
        return (
            <React.Fragment>
                <ErrorFeedbackBar/>
                <FeedbackBar
                    open={this.state.feedbackBarOpen}
                    severity={SUCCESS}
                    message={this.props.operationResponse}
                    handleClose={this.closeFeedbackBar}
                />
                {this.displayCurrentPage(this.state.currentPage)}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    operationResponse: fullTruckloadOperationResponseSelector(state),
    previewingFullTruckloadData: previewingFullTruckloadDataSelector(state),
    user: userInfoSelector(state),
})

const actionCreators = {
    fetchFullTruckloads,
    previewFullTruckloadFile,
    previewFullTruckloadDocument,
}

export default withShipment({
    mapStateToProps,
    actionCreators,
}, FullTruckload);