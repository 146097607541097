import {
    createSelector
} from 'reselect'

export const tokenSelector = createSelector(state => state && state.auth && state.auth.token, tokenSelector => tokenSelector);
export const isAuthenticatedSelector = createSelector(state => state && state.auth && state.auth.isAuthenticated, isAuthenticatedSelector => isAuthenticatedSelector);
export const userInfoSelector = createSelector(state => state && state.auth && state.auth.userInfo, userInfoSelector => userInfoSelector);
export const usersSelector = createSelector(state => state && state.auth && state.auth.users, usersSelector=>usersSelector);
export const selectedLocationIdSelector = createSelector(state => state && state.auth && state.auth.userInfo && state.auth.userInfo.selectedLocationId, selectedLocationIdSelector=>selectedLocationIdSelector);

