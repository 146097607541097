import React from "react";
import {
    Button, Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl, FormHelperText, IconButton,
    MenuItem,
    Select, TableBody, TablePagination,
    TextField, Tooltip
} from "@material-ui/core";

// material components
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

// constants

// styling
import PropTypes from "prop-types";
import withShipment from "../../../withShipment";
import CustomToolbar from "../../global/CustomToolbar";
import {
    MANAGE_PRODUCTS_HEAD_CELLS,
    PRODUCT_TOOLBAR_TITLE,
    DEFAULT_SORT_BY_HEAD_CELL,
    DEFAULT_ROWS_PER_PAGE,
    ROWS_PER_PAGE_LABEL, CREATE_PRODUCT_TITLE, BACK_TO_MENU
} from "./constants";
import Paper from "@material-ui/core/Paper";
import SearchableTable from "../../global/Search/SearchableTable";
import { ASC, DESC } from "../../global/constants";
import SearchField from "../../global/Search/SearchField";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import { RETURNS_TABLE_HEAD_CELLS } from "../../returns/constants";
import GlobalTableHead from "../../global/Table/GlobalTableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import EditIcon from "@material-ui/icons/Edit";
import {
    availableLocationsSelector,
    categorySelector,
    productsSelector
} from "../../../redux/selectors/settings";
import AddProductForm from "./AddProductForm";
import {
    createProduct,
    deleteProduct,
    listProducts,
    updateProduct,
    getAllCategory, listAvailableLocations
} from "../../../redux/actions/settings";
import CustomTableHeader from "../../global/Table/CustomTableHeader";
import { fetchAlerts } from "../../../redux/actions/alerts";
import { userInfoSelector } from "../../../redux/selectors/auth";
import DeleteProduct from "./DeleteProduct"
import "./ManageProductsTable.css";
import ErrorFeedbackBar from "../../global/ErrorFeedbackBar";

class ManageProductsTable extends SearchableTable {

    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            addingProduct: false,
            order: ASC,
            orderBy: DEFAULT_SORT_BY_HEAD_CELL,
            isSearch: false, // true if search value is not empty, false otherwise
            searchValue: "", // the value in the search field (updates on change)
            searchRows: 0, // number of rows displayed while search criteria is met
            editingProduct: null,
            rowsPerPage: DEFAULT_ROWS_PER_PAGE,
            isDelete: {},
            categories: [],
            selectedCategory: {},
        }
    }

    componentDidMount() {
        this.props.getAllCategory()
        this.props.listProducts()
        this.props.listAvailableLocations()
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.categories !== this.props.categories) {
            await this.setState({
                categories: this.props.categories
            })
        }
    }


    getDisplayed = (rows) => {
        if (!rows)
            return [];
        return [...rows.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)];
    }

    handleSave = (creatingProduct, data) => {
        if (creatingProduct) {
            this.props.createProduct(data, () => window.location.reload())
        }
        else {

            this.props.updateProduct(data)
            this.setState({
                addingProduct: false,
            }, () => {
                this.setState({
                    editingProduct: null
                })
            }
            )
        }
    }

    handleRequestSort = (event, property) => {
        const isAsc = this.state.orderBy === property && this.state.order === ASC;
        this.setState({
            order: isAsc ? DESC : ASC,
            orderBy: property,
        });
    };


    handleCancel = () => {
        this.setState({
            addingProduct: false,
        }, () => {
            this.setState({
                editingProduct: null
            })
        })
    }

    handleOpenProduct = (product) => {
        this.setState({
            editingProduct: product
        })
    }

    onSearchValueChange = (e) => {
        this.setState({
            isSearch: e.target.value.length > 0,
            searchValue: e.target.value,
            page: 0
        });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: event.target.value,
            page: 0,
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
        });
    };

    handleDeleteTrue = (index) => {
        let newIsDelete = this.state.isDelete
        newIsDelete[index] = true
        this.setState({ isDelete: newIsDelete })
    }

    handleDeleteFalse = (index) => {
        let newIsDelete = this.state.isDelete
        newIsDelete[index] = false
        this.setState({ isDelete: newIsDelete })
    }

    setSelectedCategory = (value) => {
        this.setState({
            selectedCategory: value
        })
    }

    render() {
        return (
            <>
                <Paper className='paper'>
                    <ErrorFeedbackBar />
                    <CustomToolbar
                        creatingOrder={this.state.addingProduct || (!!this.state.editingProduct)}
                        title={PRODUCT_TOOLBAR_TITLE}
                        createTitle={CREATE_PRODUCT_TITLE}
                        backTitle={BACK_TO_MENU}
                        onClick={() => { this.setState({ addingProduct: true }) }}
                        handleClose={this.handleCancel}
                    />

                    {!this.state.editingProduct ?

                        this.state.addingProduct ?
                            <AddProductForm
                                creatingProduct={true}
                                handleSave={this.handleSave}
                                handleCancel={this.handleCancel}
                                locations={this.props.availableLocations}
                                products={this.props.products}
                                fetchAlerts={this.props.fetchAlerts}
                                user={this.props.user}
                                categories={this.state.categories}
                                setSelectedCategory={this.setSelectedCategory}
                                selectedCategory={this.state.selectedCategory}
                            />
                            :
                            <>
                                <CustomTableHeader
                                    searchText={this.state.searchValue}
                                    onSearchValueChange={this.onSearchValueChange}
                                    pagination={
                                        <TablePagination
                                            className="table-pagination"
                                            labelRowsPerPage={ROWS_PER_PAGE_LABEL}
                                            rowsPerPageOptions={[5, 25, 50, 100, {value: this.props.products.length, label: "All"} ]}
                                            count={!this.props.products ? 0 : this.filterBySearch(this.props.products).length}
                                            rowsPerPage={this.state.rowsPerPage}
                                            page={this.state.page}
                                            onChangePage={this.handleChangePage}
                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                        />
                                    }
                                />

                                <TableContainer>
                                    <Table size='medium'>
                                        <GlobalTableHead
                                            isCreatePurchaseOrderTable={false}
                                            isShipmentTable={false}
                                            headCells={MANAGE_PRODUCTS_HEAD_CELLS}
                                            order={this.state.order}
                                            orderBy={this.state.orderBy}
                                            onRequestSort={this.handleRequestSort}
                                            rowCount={!this.props.products ? 0 : this.props.products.length}
                                        />
                                        <TableBody>
                                            {this.getDisplayed(this.stableSort(this.filterBySearch(this.props.products), this.getComparator(this.state.order, this.state.orderBy))).map((row, index) => {

                                                return (
                                                    <TableRow hover onDoubleClick={() => this.handleOpenProduct(row)}>
                                                        <TableCell>
                                                            <IconButton onClick={() => this.handleOpenProduct(row)}>
                                                                <EditIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                        <TableCell className='cellPadding'>
                                                            {row.shortcode}
                                                        </TableCell>
                                                        <TableCell className='cellPadding'>
                                                            {row.productCode}
                                                        </TableCell>
                                                        <TableCell className='cellPadding'>
                                                            {row.brand}
                                                        </TableCell>
                                                        <TableCell className='cellPadding brand-cell'>
                                                            {row.manufacturerPartNum}
                                                        </TableCell>
                                                        <TableCell className='cellPadding'>
                                                            {row.actualSize}
                                                        </TableCell>
                                                        <TableCell className='cellPadding'>
                                                            {row.nominalSize}
                                                        </TableCell>
                                                        <DeleteProduct
                                                            product={row}
                                                            delete={this.props.deleteProduct}
                                                            isDelete={this.state.isDelete[index]}
                                                            handleDeleteTrue={this.handleDeleteTrue}
                                                            handleDeleteFalse={this.handleDeleteFalse}
                                                            index={index}
                                                        />


                                                    </TableRow>
                                                );

                                            })
                                            }
                                        </TableBody>

                                    </Table>


                                </TableContainer>
                            </>

                        :
                        <AddProductForm
                            creatingProduct={false}
                            editingProduct={this.state.editingProduct}
                            handleSave={this.handleSave}
                            handleCancel={this.handleCancel}
                            locations={this.props.availableLocations}
                            products={this.props.products}
                            fetchAlerts={this.props.fetchAlerts}
                            user={this.props.user}
                            categories={this.props.categories}
                            setSelectedCategory={this.setSelectedCategory}
                            selectedCategory={this.state.selectedCategory}
                        />
                    }



                </Paper>

            </>
        );
    }


}


const mapStateToProps = (state) => ({
    products: productsSelector(state),
    availableLocations: availableLocationsSelector(state),
    user: userInfoSelector(state),
    categories: categorySelector(state),
})

const actionCreators = {
    createProduct,
    updateProduct,
    listProducts,
    deleteProduct,
    getAllCategory,
    listAvailableLocations,
    fetchAlerts,
}

ManageProductsTable.propTypes = {
    products: PropTypes.array,
    availableLocations: PropTypes.array
}

ManageProductsTable.defaultProps = {
    products: [],
    availableLocations: [],
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, ManageProductsTable);


