import React from 'react';

// material components
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

// custom components

// styling
import "./InventoryThresholdDialog.css"

// constants
import {
    DAYS_OF_COVERAGE,
    INVENTORY_THRESHOLD_DIALOG_TITLE,
    NUM_BOXES,
    NUM_SKIDS,
    SHORTCODE,
    UNITS_ON_HAND
} from "./constants";
import Button from "@material-ui/core/Button";
import { APPLY, CANCEL } from "../global/constants";
import PropTypes from "prop-types";
import {Typography} from '@material-ui/core';
import InventoryOverviewTable from "./InventoryOverviewTable";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

class InventoryThresholdDialog extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            selectedShortcode: '',
            threshold: '',
        };

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // get the shortcode threshold from props
        if (this.props.currentShortcodeThreshold !== prevProps.currentShortcodeThreshold) {
            this.setState({
                threshold: this.props.currentShortcodeThreshold
            });
        }
    }

    onChange = (e) => {
        this.setState({
            threshold: e.target.value
        });
    }

    shortcodeChange = (value) => {
        this.setState({
            selectedShortcode: value
        })
    }


    findInventoryByShortcode = (shortcode) => {
        let inventory = this.props.allInventory
        let inventoryArray = []
        for (let i = 0; i < this.props.allInventory.length; i++) {
             if (inventory[i].shortcode === shortcode){
                 inventoryArray.push(inventory[i])
                 return inventoryArray
            }
        }
    }


    render() {
        return (
            <>
                <hr/>
                <div className={'header'}>
                    <Typography variant="h6">
                        {INVENTORY_THRESHOLD_DIALOG_TITLE}
                    </Typography>
                </div>
                <div className={"threshold-container"}>
                    <div className={'threshold-form'}>
                        <div className="location-name">
                            <TextField
                                variant='outlined'
                                disabled
                                label="Location"
                                InputLabelProps={{ shrink: true }}
                                value={this.props.currentLocationName}
                            />
                        </div>

                        <div className="shortcode-autocomplete">
                            <Autocomplete
                                options={this.props.availableShortcodes}
                                getOptionLabel={(entry) => entry}
                                autoHighlight={true}
                                name="shortcodes"
                                disableClearable
                                onChange={(event, value) => {
                                    this.shortcodeChange(value)
                                    this.props.getShortcodeThreshold(value)
                                }}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Select a shortcode"
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        name="shortcode"
                                    />
                                }
                            />
                        </div>

                        <div className="threshold-field">
                            <TextField
                                variant='outlined'
                                disabled={this.props.currentShortcodeThreshold === '-'}
                                label="Threshold"
                                InputLabelProps={{ shrink: true }}
                                value={this.state.threshold}
                                onChange={this.onChange}
                            />
                        </div>

                        <div className={'buttons'}>
                            <div className={'form-buttons'}>
                                <Button
                                    variant='contained'
                                    onClick={() => this.props.onClose(false)}

                                >{CANCEL}</Button>
                            </div>
                            <div className={'form-buttons'}>
                                <Button
                                    variant='contained'
                                    disabled={this.state.selectedShortcode === '' || this.state.threshold.length === 0}
                                    onClick={() => {
                                        this.props.onClose(true, this.state)
                                    }} color="primary"
                                >{APPLY}</Button>
                            </div>
                        </div>
                    </div>
                    <div className={'threshold-table'}>
                        {this.state.selectedShortcode ?
                            <InventoryOverviewTable
                                style = {{overflow: 'hidden'}}
                                dialog ={true}
                                filteredInventoryOverview={this.findInventoryByShortcode(this.state.selectedShortcode)}
                                shortcodeIndices={this.props.shortcodeIndices}
                                selectedLocationId={this.props.locationId}
                                locationIds={this.props.locationIds}
                                showProducts={true}
                                alerts={this.props.alerts}
                                listInventoryAlerts={this.props.listInventoryAlerts}
                                showThresholds={false}
                            />
                            : //if no shortcode is selected put an empty table, inventoryOverviewTable does not have a 2nd column to set blank
                            <Table className={'empty-table'}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell className={"table-cell"}>
                                            {SHORTCODE}
                                        </TableCell>
                                        <TableCell className={"table-cell"}/>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={"table-cell"}>
                                            {UNITS_ON_HAND}
                                        </TableCell>
                                        <TableCell className={"table-cell"}/>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={"table-cell"}>
                                            {NUM_BOXES}
                                        </TableCell>
                                        <TableCell className={"table-cell"}/>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={"table-cell"}>
                                            {NUM_SKIDS}
                                        </TableCell>
                                        <TableCell className={"table-cell"}/>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={"table-cell"}>
                                            {DAYS_OF_COVERAGE}
                                        </TableCell>
                                        <TableCell className={"table-cell"}/>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        }
                    </div>
                </div>
            </>
        )
    }
}

InventoryThresholdDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    availableShortcodes: PropTypes.array,
    currentShortcodeThreshold: PropTypes.string,
    getShortcodeThreshold: PropTypes.func,
    alerts: PropTypes.array,
    locationId: PropTypes.string,
}

InventoryThresholdDialog.defaultProps = {
    open: false,
    onClose: () => { },
    availableShortcodes: [],
    currentShortcodeThreshold: "",
    getShortcodeThreshold: () => { },
    alerts: [],
    locationId: "",
}

export default InventoryThresholdDialog;