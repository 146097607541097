import React, {useState, useEffect} from 'react';

// material components
import {
    FormControl,
    FormHelperText,
    Select,
    MenuItem, DialogContent, TextField, Checkbox, FormControlLabel, FormGroup
} from "@material-ui/core";

//constants
import {
    AVAILABLE_MARKETPLACES,
    ADD_MARKETPLACE, MARKETPLACE_COUNTRY_OPTIONS, SELECT_COUNTRY, CUSTOM_MARKETPLACE, AMAZON, ENTER_NAME
} from "./constants";

import styles from "./CreateMarketplaceDialog.module.css";
import PropTypes from "prop-types";
import DeleteDialog from "../../global/DeleteDialog";
import Autocomplete from "@material-ui/lab/Autocomplete";

function SelectMarketplace(props) {
    return(
        <div className={styles.rowSelects}>
            <div>
                <div className={styles.rowSpec}>
                    <div className={styles.formSelect}>
                        <Autocomplete
                            options={AVAILABLE_MARKETPLACES}
                            getOptionLabel={(marketplace) => marketplace}
                            name="marketplaceCompany"
                            onChange={(e, value) => props.handleAutocompleteChange(value, "marketplaceCompany")}
                            value={props.parentState.marketplaceCompany}
                            className={styles.formInput + " " + styles.formSelect}
                            disabled={props.isEdit}
                            error={props.parentState.error && (props.parentState.marketplaceCompany === '' || props.parentState.marketplaceCompany === undefined)}
                            blurOnSelect={true}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    required
                                    label="Marketplace (Company)"
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    name="marketplaceCompany"
                                />
                            }
                        />
                    </div>
                    <div className={styles.formSelect}>
                        {props.parentState.marketplaceCompany !== CUSTOM_MARKETPLACE ?
                            <Autocomplete
                                options={MARKETPLACE_COUNTRY_OPTIONS}
                                getOptionLabel={(service) => service}
                                name="marketplaceCountry"
                                onChange={(e, value) => props.handleAutocompleteChange(value, "marketplaceCountry")}
                                value={props.parentState.marketplaceCountry}
                                className={styles.formInput}
                                blurOnSelect={true}
                                autoComplete='new-password'
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        required
                                        label="Country"
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        name="marketplaceCountry"
                                    />
                                }
                            />
                            :
                            <TextField
                                required
                                className={styles.formInput}
                                variant="outlined"
                                value={props.parentState.url}
                                name="url"
                                label="URL"
                                onChange={props.handleChange}
                                InputLabelProps={{shrink: true}}
                                autoComplete='new-password'
                            />
                        }
                    </div>
                </div>
                <div className={styles.rowSpec}>
                    <TextField
                        required
                        variant="outlined"
                        name="marketplaceName"
                        label="Name"
                        onChange={props.handleChange}
                        value={props.parentState.marketplaceName}
                        className={styles.formTwoColInput}
                        InputLabelProps={{shrink: true}}
                        autoComplete='new-password'
                    />
                </div>
                <div className={styles.rowSpec} style={{justifyContent: "center"}}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="marketplaceEnabled"
                                    checked={props.parentState.marketplaceEnabled}
                                    onChange={props.handleCheckboxChange}
                                />
                            }
                            label="Enable marketplace"
                        />
                    </FormGroup>
                </div>
            </div>
        </div>
    )
}

SelectMarketplace.propTypes = {
    handleChange: PropTypes.func,
    handleAutocompleteChange: PropTypes.func,
    handleCheckboxChange: PropTypes.func,
    parentState: PropTypes.object,
    errors: PropTypes.array,
    isEdit: PropTypes.bool
}

SelectMarketplace.defaultProps = {
    handleChange: ()=>{},
    handleAutocompleteChange: ()=>{},
    handleCheckboxChange: ()=>{},
    parentState: {},
    errors: [],
    isEdit: false
}

export default SelectMarketplace;