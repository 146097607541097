export const INVOICE_TEMPLATE_TABLE_HEAD_CELLS = [
    { id: 'supplierName', center: false, disablePadding: false, label: 'Name'},
    { id: 'createDate', center: false, disablePadding: false, label: 'Creation Date'},
    { id: 'lastUpdatedDate', center: false, disablePadding: false, label: 'Last Updated Date'},
    { id: 'delete', center: true, disablePadding: false, label: '' }
]
export const DEFAULT_SORT_BY_HEAD_CELL = "supplierName"

export const ROWS_PER_PAGE_LABEL = "Supplier Invoices Templates per page"

export const DEFAULT_ROWS_PER_PAGE = 50

export const DEFAULT_DATE_FORMAT = "MM/dd/yyyy"

export const INVOICE_TEMPLATE_DATA_FIELDS = {
    marker: { label: "Marker", required: true, visible: true, position: { x: 0, y: 0, w: 0, h: 0 } },
    invoiceNumber: { label: "Invoice Number", required: true, visible: true, position: { x: 0, y: 0, w: 0, h: 0 } },
    date: { label: "Date", required: true, visible: true, position: { x: 0, y: 0, w: 0, h: 0 } },
    poNumber: { label: "PO Number", required: true, visible: true, position: { x: 0, y: 0, w: 0, h: 0 } },
    subtotalAmount: { label: "Sub Total", required: false, visible: true, position: { x: 0, y: 0, w: 0, h: 0 } },
    taxAmount: { label: "Tax", required: true, visible: true, position: { x: 0, y: 0, w: 0, h: 0 } },
    totalAmount: { label: "Total", required: true, visible: true, position: { x: 0, y: 0, w: 0, h: 0 } },
    table: { label: "Line Items", required: true, visible: true, position: { x: 0, y: 0, w: 0, h: 0 } }
}

export const INVOICE_TEMPLATE_DATA_SUB_FIELDS = {
    qtyColumn: { label: "Quantity Shipped", required: true, visible: true, position: { x: 0, w: 0 } },
    partNumberColumn: { label: "Part Number", required: true, visible: true, position: { x: 0, w: 0 } },
    priceColumn: { label: "Price", required: true, visible: true, position: { x: 0, w: 0 } },
    amountColumn: { label: "Amount", required: true, visible: true, position: { x: 0, w: 0 } },
    discountColumn: { label: "Discount", required: false, visible: true, position: { x: 0, w: 0 } }
}