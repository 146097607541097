import React from "react";
import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";
import {
    ADD_LEDGER,
    BACK,
    LEDGERS_PAGES,
    SAVE,
    TYPE_OPTIONS
} from "./constants";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import SaveIcon from "@material-ui/icons/Save";
import { IconButton, Menu, MenuItem } from "@mui/material";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import MenuIcon from '@mui/icons-material/Menu';
import Tooltip from "@material-ui/core/Tooltip";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

class AddLedger extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            templates: this.props.templates || [],
            anchorEl: null,
            activeIndex: null,
            draggedIndex: null,
        };
    }

    componentDidMount() {
        if (this.props.ledgers && this.props.ledgers.length > 0) {
            this.setState({
                templates: [...this.props.ledgers],
            });
        }
    }

    onDragStart = (index) => {
        this.setState({ draggedIndex: index });
    };

    onDragOver = (index) => {
        const { draggedIndex, templates } = this.state;
        if (draggedIndex === index) return;

        const updatedTemplates = [...templates];
        const [draggedItem] = updatedTemplates.splice(draggedIndex, 1);
        updatedTemplates.splice(index, 0, draggedItem);

        this.setState({
            templates: updatedTemplates,
            draggedIndex: index,
        });
    };

    onDragEnd = () => {
        this.setState({ draggedIndex: null });
    };


    getContextualMenuItems = () => {
        let menuItems = [];
        menuItems.push(
            {
                title: BACK,
                icon: <KeyboardBackspaceIcon />,
                onClick: () => { this.props.setCurrentPage(LEDGERS_PAGES.TABLE, {}); },
            },
            {
                title: SAVE,
                icon: <SaveIcon />,
                onClick: () => this.handleSave(),
                disabled: this.isSaveDisabled(),
            }
        );

        return menuItems;
    };

    handleSave = () => {
        this.props.handleSave(this.state.templates);
    };

    isSaveDisabled = () => {
        return this.state.templates.some(template =>
            !template.name || !template.type
        );
    };

    handleTemplateChange = (e, index) => {
        const { name, value } = e.target;
        this.setState(prevState => {
            const updatedTemplates = [...prevState.templates];
            updatedTemplates[index] = {
                ...updatedTemplates[index],
                [name]: value,
            };

            return { templates: updatedTemplates };
        });
    };



    removeTemplateRow = (index) => {
        this.setState(prevState => {
            const updatedTemplates = prevState.templates.filter((_, curIndex) => curIndex !== index);
            return { templates: updatedTemplates };
        });
    };


    addTemplateRow = () => {
        this.setState(prevState => {
            const updatedTemplates = [...prevState.templates, {
                name: "",
                type: "",
                category: ""
            }];
            return { templates: updatedTemplates };
        });
    };

    render() {
        return (
            <React.Fragment>
                <TopToolbar
                    pageName={ADD_LEDGER}
                    menuItems={this.getContextualMenuItems()}
                />

                <div style={{ display: "flex", justifyContent: "center", marginTop: "16px" }}>
                    <div className="transaction-form-section">
                        {this.state.templates.map((template, index) => (
                            <div
                                className="transaction-form-details"
                                key={index}
                                draggable
                                onDragStart={() => this.onDragStart(index)}
                                onDragOver={() => this.onDragOver(index)}
                                onDragEnd={this.onDragEnd}
                                style={{ background: this.state.draggedIndex === index ? "#f0f0f0" : "white" }}
                            >
                                <div style={{ width: '4.5%', display: "flex" }}>
                                    <IconButton onClick={() => this.removeTemplateRow(index)}>
                                        <RemoveCircleIcon
                                            fontSize="small"
                                            color="error"
                                        />
                                    </IconButton>
                                </div>


                                    <TextField
                                        variant="outlined"
                                        name="name"
                                        label="Name"
                                        InputLabelProps={{ shrink: true }}
                                        value={template.name || ""}
                                        onChange={(e) => this.handleTemplateChange({
                                            target: { name: "name", value: e.target.value },
                                        }, index)}
                                        required
                                        error={!template.name}
                                    />


                                <Autocomplete
                                    options={TYPE_OPTIONS}
                                    getOptionLabel={(option) => option}
                                    value={template.type || null}
                                    onChange={(e, value) => this.handleTemplateChange({
                                        target: { name: "type", value: value },
                                    }, index)}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            name="type"
                                            label="Type"
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                            required
                                            error={!template.type}
                                        />
                                    }
                                />

                                <Autocomplete
                                    options={(this.props.categories || []).slice()?.sort((a, b) => a.displayOrder - b.displayOrder)}
                                    getOptionLabel={(option) => option.name || ""}
                                    value={this.props.categories.find(category => category.name === template?.category?.name) || null}
                                    onChange={(e, value) => this.handleTemplateChange({
                                        target: {
                                            name: "category",
                                            value: value ? { name: value.name, transactionCategoryId: value.transactionCategoryId } : { name: null, transactionCategoryId: null }
                                        },
                                    }, index)}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            name="category"
                                            label="Category"
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    }
                                />


                                <IconButton aria-label='info' style={{width:"24px"}}>
                                    <Tooltip
                                        title={"Selecting the category will include all transactions in specified category in this ledger"}
                                         classes={{ tooltip: 'custom-tooltip' }}
                                        enterDelay={50}
                                    >
                                        <ErrorOutlineIcon variant='outlined' fontSize='small'/>
                                    </Tooltip>
                                </IconButton>


                                <MenuIcon
                                    style={{ cursor: 'grab', marginLeft: '8px', opacity: '0.6', alignSelf: 'center' }}
                                />
                            </div>
                        ))}
                        <div style={{ marginTop: '30px' }}>
                            <IconButton onClick={() => this.addTemplateRow()}>
                                <AddCircleIcon
                                    fontSize="small"
                                    color='secondary'
                                />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default AddLedger;
