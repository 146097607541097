import {listFTLCarrierInvoiceTemplates} from "../../actions/invoices";

export const GET_ALL_SUPPLIER_INVOICES = 'GET_ALL_SUPPLIER_INVOICES'
export const STORE_ALL_SUPPLIER_INVOICES = 'STORE_ALL_SUPPLIER_INVOICES'

export const STORE_SUPPLIER_INVOICE = 'STORE_SUPPLIER_INVOICE'

export const PROCESS_SUPPLIER_INVOICE = 'PROCESS_SUPPLIER_INVOICE'
export const REPROCESS_SUPPLIER_INVOICE = 'REPROCESS_SUPPLIER_INVOICE'

export const GET_SUPPLIER_INVOICE_PDF = 'GET_SUPPLIER_INVOICE_PDF'
export const STORE_SUPPLIER_INVOICE_PDF = 'STORE_SUPPLIER_INVOICE_PDF'

export const GET_SUPPLIER_INVOICE_PO = 'GET_SUPPLIER_INVOICE_PO'
export const STORE_SUPPLIER_INVOICE_PO = 'STORE_SUPPLIER_INVOICE_PO'

export const GET_SUPPLIER_INVOICE_ATTACHMENT = 'GET_SUPPLIER_INVOICE_ATTACHMENT'
export const DELETE_SUPPLIER_INVOICE_ATTACHMENT = 'DELETE_SUPPLIER_INVOICE_ATTACHMENT'
export const REMOVE_DELETED_SUPPLIER_INVOICES = "REMOVE_DELETED_SUPPLIER_INVOICES";

export const UPDATE_SUPPLIER_INVOICE = 'UPDATE_SUPPLIER_INVOICE'

export const DELETE_SUPPLIER_INVOICE = "DELETE_SUPPLIER_INVOICE"

export const UPDATE_SUPPLIER_INVOICE_STATUS = "UPDATE_SUPPLIER_INVOICE_STATUS"

export const OVERWRITE_SUPPLIER_INVOICE = "OVERWRITE_SUPPLIER_INVOICE"

export const STORE_UPDATED_SUPPLIER_INVOICE = 'STORE_UPDATED_SUPPLIER_INVOICE';
export const STORE_SUPPLIER_INVOICE_OPERATION_RESPONSE = 'STORE_SUPPLIER_INVOICE_OPERATION_RESPONSE';
export const UPDATE_SUPPLIER_INVOICE_CHANGED = 'UPDATE_SUPPLIER_INVOICE_CHANGED';
export const SAVE_SUPPLIER_INVOICE_PAYMENT_DETAILS = "SAVE_SUPPLIER_INVOICE_PAYMENT_DETAILS";
export const REIMPORT_SUPPLIER_INVOICE_TEMPLATE_PDF = "REIMPORT_SUPPLIER_INVOICE_TEMPLATE_PDF";
export const FETCH_SUPPLIER_INVOICE_TEMPLATES = "FETCH_SUPPLIER_INVOICE_TEMPLATES";
export const STORE_SUPPLIER_INVOICE_TEMPLATES = "STORE_SUPPLIER_INVOICE_TEMPLATES";

export const FETCH_SUPPLIERS_WITHOUT_TEMPLATE = "FETCH_SUPPLIERS_WITHOUT_TEMPLATE";
export const STORE_SUPPLIERS_WITHOUT_TEMPLATE = "STORE_SUPPLIERS_WITHOUT_TEMPLATE";

export const CREATE_SAVE_SUPPLIER_INVOICE_TEMPLATE = "CREATE_SAVE_SUPPLIER_INVOICE_TEMPLATE";

export const GET_SUPPLIER_INVOICE_TEMPLATE_PDF = "GET_SUPPLIER_INVOICE_TEMPLATE_PDF";
export const STORE_SUPPLIER_INVOICE_TEMPLATE_PDF = "STORE_SUPPLIER_INVOICE_TEMPLATE_PDF";

export const DELETE_SUPPLIER_INVOICE_TEMPLATE = "DELETE_SUPPLIER_INVOICE_TEMPLATE";

export const SAVE_CARRIER_INVOICE_PAYMENT_DETAILS = "SAVE_CARRIER_INVOICE_PAYMENT_DETAILS";

export const LIST_CARRIER_INVOICE_TEMPLATES = "LIST_CARRIER_INVOICE_TEMPLATES";
export const STORE_CARRIER_INVOICE_TEMPLATES = "STORE_CARRIER_INVOICE_TEMPLATES";

export const LIST_CARRIERS_WITHOUT_TEMPLATE = "LIST_CARRIERS_WITHOUT_TEMPLATE";
export const STORE_CARRIERS_WITHOUT_TEMPLATE = "STORE_CARRIERS_WITHOUT_TEMPLATE";


export const DELETE_CARRIER_INVOICE_TEMPLATE = "DELETE_CARRIER_INVOICE_TEMPLATE";

export const CREATE_SAVE_CARRIER_INVOICE_TEMPLATE = "CREATE_SAVE_CARRIER_INVOICE_TEMPLATE";

export const GET_CARRIER_INVOICE_TEMPLATE_PDF = "GET_CARRIER_INVOICE_TEMPLATE_PDF";
export const STORE_CARRIER_INVOICE_TEMPLATE_PDF = "STORE_CARRIER_INVOICE_TEMPLATE_PDF";
export const REIMPORT_CARRIER_INVOICE_TEMPLATE = "REIMPORT_CARRIER_INVOICE_TEMPLATE";


export const GET_ALL_CARRIER_INVOICES = "GET_ALL_CARRIER_INVOICES";
export const SAVE_CARRIER_INVOICE = "SAVE_CARRIER_INVOICE";
export const STORE_ALL_CARRIER_INVOICES = "STORE_ALL_CARRIER_INVOICES";

export const GET_CARRIER_INVOICE_PDF = "GET_CARRIER_INVOICE_PDF";
export const STORE_CARRIER_INVOICE_PDF = "STORE_CARRIER_INVOICE_PDF";

export const UPDATE_CARRIER_INVOICE_STATUS = "UPDATE_CARRIER_INVOICE_STATUS";
export const STORE_UPDATED_CARRIER_INVOICES = "STORE_UPDATED_CARRIER_INVOICES";
export const DELETE_CARRIER_INVOICE = "DELETE_CARRIER_INVOICE";
export const REPROCESS_CARRIER_INVOICE = "REPROCESS_CARRIER_INVOICE"
export const STORE_UPDATED_CARRIER_INVOICE_STATUS = "STORE_UPDATED_CARRIER_INVOICE_STATUS";
export const REMOVE_DELETED_CARRIER_INVOICES = "REMOVE_DELETED_CARRIER_INVOICES";
export const STORE_REPROCESSED_CARRIER_INVOICES = "STORE_REPROCESSED_CARRIER_INVOICES";
export const SAVE_CARRIER_INVOICE_ITEM_DETAILS = "SAVE_CARRIER_INVOICE_ITEM_DETAILS";
export const STORE_CARRIER_INVOICE_OPERATION_RESPONSE = "STORE_CARRIER_INVOICE_OPERATION_RESPONSE";
export const CLEAR_CARRIER_INVOICE_OPERATION_RESPONSE = "CLEAR_CARRIER_INVOICE_OPERATION_RESPONSE";

export const PROCESS_CARRIER_INVOICE = "PROCESS_CARRIER_INVOICE";

export const GET_DATE_FORMATS = "GET_DATE_FORMATS"
export const STORE_DATE_FORMATS = "STORE_DATE_FORMATS";

export const SET_PROCESSING = "SET_PROCESSING"

export const FETCH_INVOICE_BY_TRACKING_ID = "FETCH_INVOICE_BY_TRACKING_ID"
export const SET_INVOICE_SMART_SEARCH_ROWS = "SET_INVOICE_SMART_SEARCH_ROWS"
export const SET_INVOICE_ITEM_SMART_SEARCH_DETAILS = "SET_INVOICE_ITEM_SMART_SEARCH_DETAILS"
export const STORE_UPDATED_INVOICE_SMART_SEARCH_ROWS = 'STORE_UPDATED_INVOICE_SMART_SEARCH_ROWS'
export const STORE_UPDATED_INVOICE_ITEM_SMART_SEARCH_DETAILS = 'STORE_UPDATED_INVOICE_ITEM_SMART_SEARCH_DETAILS'

export const PROCESS_FTL_CARRIER_INVOICE = 'PROCESS_FTL_CARRIER_INVOICE'

export const GET_FTL_CARRIER_INVOICES = "GET_FTL_CARRIER_INVOICES"
export const STORE_FTL_CARRIER_INVOICES = "STORE_FTL_CARRIER_INVOICES"
export const STORE_PROCESSED_FTL_CARRIER_INVOICE = "STORE_PROCESSED_FTL_CARRIER_INVOICE"
export const REMOVE_DELETED_FTL_CARRIER_INVOICE = "REMOVE_DELETED_FTL_CARRIER_INVOICE"
export const UPDATE_FTL_CARRIER_INVOICE_STATUS = "UPDATE_FTL_CARRIER_INVOICE_STATUS"
export const STORE_REPROCESSED_FTL_CARRIER_INVOICE = "STORE_REPROCESSED_FTL_CARRIER_INVOICE"
export const SAVE_FTL_CARRIER_INVOICE = "SAVE_FTL_CARRIER_INVOICE"