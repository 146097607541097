export const DEFAULT_ROWS_PER_PAGE = 50;

//toolbar
export const TAGS_TOOLBAR_TITLE = "Tags"
export const BACK_TO_MENU_TITLE = "Back to Menu";
export const MANAGE_TAGS = "Manage Tags";
//table

export const TAG_TABLE_HEAD_CELLS = [
    { id: 'tagName', center: false, disablePadding: false, label: 'Tag Name'},
    { id: 'isPrintable', center: false, disablePadding: false, label: 'Printable'},
    { id: 'ending', center: true, disablePadding: false, label: ''}
]
export const EDITING_TAG_TABLE_HEAD_CELLS = [
    { id: 'tagName', center: false, disablePadding: false, label: 'Tag Name'},
    { id: 'isPrintableEdit', center: false, disablePadding: false, label: 'Printable'},
    { id: 'ending', center: true, disablePadding: false, label: ''}
]
export const ROWS_PER_PAGE_LABEL = "tags per page"
export const DEFAULT_SORT_BY_HEAD_CELL = "tagName"
export const REMOVE_TAG_TITLE = "Remove tag"
export const ADD_TAG_TITLE = "Add tag"


export const CANCEL = "cancel";
export const SAVE = "save";

//feedback
export const PLEASE_MAKE_CHANGE = "Please make change"
export const UPDATE_SUCCESS = "Update success"
export const UPDATE_FAIL = "Update fail"



