export const AUTOMATION_SETTINGS_TOOLBAR_TITLE = "Automation Settings";

export const OPTIONS = [
    { id: "1", label: "1 Month" },
    { id: "2", label: "2 Months" },
    { id: "3", label: "3 Months" },
    { id: "4", label: "4 Months" },
    { id: "5", label: "5 Months" },
    { id: "6", label: "6 Months" },
    { id: "7", label: "7 Months" },
    { id: "8", label: "8 Months" },
    { id: "9", label: "9 Months" },
    { id: "10", label: "10 Months" },
    { id: "11", label: "11 Months" },
    { id: "12", label: "12 Months" },
];

export const INTRO = "All calculations are going to be converted to the currency of the marketplace."
export const REFERRAL_FEE_INFO =  "Referral fee is calculated as a percentage of selling price"
export const OTHER_COSTS_INFO_PART1 = "Other costs are calculated as either a percentage of selling price or a fixed amount and assumed to be the currency of the marketplace"
export const PRODUCT_COST_AVERAGE_INFO_PART1 = "Product cost is calculated automatically as an average based on invoices over the date range specified."
export const PRODUCT_COST_AVERAGE_INFO_PART2 = "Product cost will be converted to the currency of the marketplace"
export const PRICE_INFO = "Price is in the currency of the marketplace"
export const SHIPPING_COST_INFO = "Shipping cost will be converted to currency of the marketplace if required"
export const BOX_COST_INFO_PART1 = "Box cost is calculated automatically as an average based on invoices over the date range specified."
export const BOX_COST_INFO_PART2 = "Box cost will be converted to the currency of the marketplace"

export const CURRENCY = [
    { code: 'USD', name: 'US Dollar' },
    { code: 'EUR', name: 'Euro' },
    { code: 'JPY', name: 'Japanese Yen' },
    { code: 'GBP', name: 'British Pound' },
    { code: 'AUD', name: 'Australian Dollar' },
    { code: 'CAD', name: 'Canadian Dollar' },
    { code: 'CHF', name: 'Swiss Franc' },
    { code: 'CNY', name: 'Chinese Yuan' },
    { code: 'SEK', name: 'Swedish Krona' },
    { code: 'NZD', name: 'New Zealand Dollar' }
]