import React from 'react';

import FeedbackBar from "../feedbackBar/FeedbackBar";

import { hideError, showErrorMessage } from "../../redux/actions/global/index.js";
import { errorMessageSelector, showErrorSelector } from '../../redux/selectors/global';
import { ERROR } from '../shipments/constants';
import withShipment from '../../withShipment';

class ErrorFeedbackBar extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <FeedbackBar
                    open={this.props.error}
                    severity={ERROR}
                    message={this.props.errorMessage}
                    handleClose={this.props.hideError}
                />
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    error: showErrorSelector(state),
    errorMessage: errorMessageSelector(state)
})

const actionCreators = {
    hideError,
    showErrorMessage
}

ErrorFeedbackBar.propTypes = {
}

ErrorFeedbackBar.defaultProps = {
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, ErrorFeedbackBar);