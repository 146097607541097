import {
    FETCH_ALL_SUPPLIERS,
    CREATE_NEW_PURCHASE_ORDER,
    CREATE_SEND_NEW_PURCHASE_ORDER,
    FILTER_RECEIVED_PURCHASE_ORDERS,
    FILTER_PENDING_PURCHASE_ORDERS,
    GET_PDF_PREVIEW,
    STORE_PDF_PREVIEW_READY,
    CACHE_PDF_PREVIEW,
    STORE_PENDING_ORDERS,
    FETCH_ALL_PURCHASE_ORDERS,
    STORE_NEW_PURCHASE_ORDERS,
    STORE_COMPLETED_PURCHASE_ORDERS,
    STORE_PARTIAL_PURCHASE_ORDERS,
    STORE_ALL_PURCHASE_ORDERS,
    UPDATE_PURCHASE_ORDER,
    DELETE_INVENTORY_RECEIVED,
    STORE_UPDATED_PURCHASE_ORDER,
    PREVIEW_CREATED_PURCHASE_ORDER,
    STORE_CREATED_PURCHASE_ORDER_PREVIEW,
    CANCEL_PURCHASE_ORDER,
    EDIT_PURCHASE_ORDER,
    UNDO_CANCEL,
    RESET_PDF_PREVIEW_DATA,
    PREVIEW_PACKING_SLIP_FILE,
    STORE_UPDATED_PURCHASE_ORDER_PREVIEW,
    DELETE_PACKING_SLIP,
    UPDATE_EXPECTED_DELIVERY_DATE
} from "../../constants/purchaseOrders";
import {STORE_UPDATED_TICKET_WITH_PREVIEW} from "../../constants/tickets";


export const fetchAllSuppliers = () => ({
    type: FETCH_ALL_SUPPLIERS,
})

export const storePDFpreviewReady = (storePDFPreviewReady) => ({
    type: STORE_PDF_PREVIEW_READY,
    storePDFPreviewReady
})

export const createNewPurchaseOrder = (data, reset) => (
    {
        type: CREATE_NEW_PURCHASE_ORDER,
        data,
        reset
    })
export const createSendNewPurchaseOrder = (data, reset) => (
    {
        type: CREATE_SEND_NEW_PURCHASE_ORDER,
        data,
        reset
    })


export const getPDFpreview = (pdfPreview, setPdfError) => ({
    type: GET_PDF_PREVIEW,
    pdfPreview,
    setPdfError
})

export const cachePDFpreview = (storePDFPreviewReady) => ({
    type: CACHE_PDF_PREVIEW,
    storePDFPreviewReady
})



export const fetchAllPurchaseOrders = (data) => ({
    type: FETCH_ALL_PURCHASE_ORDERS,
    data
})


export const storeAllPurchaseOrders = (allPurchaseOrders) => ({
    type: STORE_ALL_PURCHASE_ORDERS,
    allPurchaseOrders
})

export const storeNewPurchaseOrders = (newPurchaseOrders) => ({
    type: STORE_NEW_PURCHASE_ORDERS,
    newPurchaseOrders
})

export const storePartialPurchaseOrders = (partialPurchaseOrders) => ({
    type: STORE_PARTIAL_PURCHASE_ORDERS,
    partialPurchaseOrders
})

export const storeCompletedPurchaseOrders = (completedPurchaseOrders) => ({
    type: STORE_COMPLETED_PURCHASE_ORDERS,
    completedPurchaseOrders
})

export const updatePurchaseOrder = (purchaseOrder, data, callback) => ({
    type: UPDATE_PURCHASE_ORDER,
    purchaseOrder,
    data,
    callback
})

export const deleteInventoryReceived = (inventoryReceived, data, callback) => ({
    type: DELETE_INVENTORY_RECEIVED,
    inventoryReceived,
    data,
    callback
})

export const storeUpdatedPurchaseOrder = (purchaseOrder) => ({
    type: STORE_UPDATED_PURCHASE_ORDER,
    purchaseOrder
})

export const storeUpdatedPurchaseOrderPreview = (updatedPurchaseOrder, index)=> ({
    type: STORE_UPDATED_PURCHASE_ORDER_PREVIEW,
    updatedPurchaseOrder,
    index
})

export const previewPackingSlipFile = (previewRequest) => ({
    type: PREVIEW_PACKING_SLIP_FILE,
    previewRequest
});

export const previewCreatedPurchaseOrder = (purchaseOrderData) => ({
    type: PREVIEW_CREATED_PURCHASE_ORDER,
    purchaseOrderData
});

export const storeCreatedPurchaseOrderPreview = (purchaseOrderPreview) => ({
    type: STORE_CREATED_PURCHASE_ORDER_PREVIEW,
    purchaseOrderPreview
})

export const deletePackingSlip = (data) => ({
    type: DELETE_PACKING_SLIP,
    data
})

export const cancelPurchaseOrder = (purchaseOrderData, callback) => ({
    type: CANCEL_PURCHASE_ORDER,
    purchaseOrderData,
    callback
})

export const editPurchaseOrder = (purchaseOrderData, reset, showPdf, setPdfError) => ({
    type: EDIT_PURCHASE_ORDER,
    purchaseOrderData,
    reset,
    showPdf,
    setPdfError
})

export const undoCancelPurchaseOrder = (purchaseOrderData, callback) => ({
    type: UNDO_CANCEL,
    purchaseOrderData,
    callback
})

export const resetPdfPreviewData = () => ({
    type: RESET_PDF_PREVIEW_DATA,
})

export const updateExpectedDeliveryDate = (data) => ({
    type: UPDATE_EXPECTED_DELIVERY_DATE,
    data
})