import React from "react";
import withShipment from "../../../withShipment";
import { locationsSelector } from "../../../redux/selectors/settings";
import { createLocation, listLocations, updateLocation } from "../../../redux/actions/settings";
import CustomToolbar from "../../global/CustomToolbar";
import {
    CREATE_LOCATION_TITLE,
    BACK_TO_MENU_TITLE,
    LOCATIONS_TOOLBAR_TITLE,
    DEFAULT_ROWS_PER_PAGE,
    MANAGE_LOCATIONS_TABLE_HEAD_CELLS,
    DEFAULT_SORT_BY_HEAD_CELL, ROWS_PER_PAGE_LABEL, EDIT
} from "./constants";
import ManageLocationForm from "./ManageLocationForm";
import Paper from "@material-ui/core/Paper";
import SearchField from "../../global/Search/SearchField";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import GlobalTableHead from "../../global/Table/GlobalTableHead";
import { Hidden, IconButton, TableBody, TablePagination } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import EditIcon from "@material-ui/icons/Edit";
import { ASC, DESC } from "../../global/constants";
import SearchableTable from "../../global/Search/SearchableTable";
import { userInfoSelector } from "../../../redux/selectors/auth";
import CustomTableHeader from "../../global/Table/CustomTableHeader";
import { display } from '@material-ui/system';
import ErrorFeedbackBar from "../../global/ErrorFeedbackBar";
import {Layout} from "../../global/Table/TablePageHelpers/LayoutObject";
import {Tooltip} from "@mui/material";
import TablePage from "../../global/Table/TablePage";




class ManageLocationsTable extends SearchableTable {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: DEFAULT_ROWS_PER_PAGE,
            addingLocation: false,
            editingLocation: null,
            order: ASC,
            orderBy: DEFAULT_SORT_BY_HEAD_CELL,
            isSearch: false, // true if search value is not empty, false otherwise
            searchValue: "", // the value in the search field (updates on change)
            searchRows: 0, // number of rows displayed while search criteria is met
            missingError: false, //missing error to print at alert
        }
    }

    handleRequestSort = (event, property) => {
        const isAsc = this.state.orderBy === property && this.state.order === ASC;
        this.setState({
            order: isAsc ? DESC : ASC,
            orderBy: property,
        });
    };

    componentDidMount() {
        this.props.listLocations()
    }

    getDisplayed = (rows) => {
        if (!rows)
            return [];
        return [...rows.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)];
    }

    handleOpenLocation = (row) => {
        this.setState({
            editingLocation: row
        })
    }

    handleClose = () => {
        this.setState({
            addingLocation: false,
        }, () => this.setState({ editingLocation: null }))
    }

    handleSave = (addingLocation, data) => {
        //todo error checking

        if (data.name == '' || data.phone == '' || data.addressLine1 == '' || data.city == '' || data.state == '' || data.postalCode == '' || data.country == '' || data.email == '') {
            this.setState({ missingError: true })
        } else {
            this.setState({ missingError: false })
            let dataToPost = { ...data, companyId: this.props.currentUser.company }
            if (addingLocation) {
                this.props.createLocation(dataToPost, () => window.location.reload())
            }
            else {
                this.props.updateLocation(dataToPost)
                this.setState({
                    addingLocation: false
                }, () => this.setState({ editingLocation: null }))
            }
        }
    }

    onSearchValueChange = (e) => {
        this.setState({
            isSearch: e.target.value.length > 0,
            searchValue: e.target.value,
            page: 0
        });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: event.target.value,
            page: 0,
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
        });
    };

    getTableHeaders = () => {
        let headers = []
        headers.push(Layout.columnHead(null,null,null))
        MANAGE_LOCATIONS_TABLE_HEAD_CELLS.forEach((headCell) => {
            headers.push(Layout.columnHead(headCell.label, headCell.id, "center"))
        })
        return headers
    }

    render() {
        return (
            <>
                <Paper className='paper'>
                    <ErrorFeedbackBar />
                    <CustomToolbar
                        title={LOCATIONS_TOOLBAR_TITLE}
                        createTitle={CREATE_LOCATION_TITLE}
                        backTitle={BACK_TO_MENU_TITLE}
                        onClick={() => { this.setState({ addingLocation: true }) }}
                        handleClose={this.handleClose}
                        creatingOrder={this.state.addingLocation || (!!this.state.editingLocation)}
                    />
                    {!this.state.editingLocation ?
                        this.state.addingLocation ?
                            <ManageLocationForm
                                addingLocation={true}
                                handleClose={this.handleClose}
                                handleSave={this.handleSave}
                                missingError={this.state.missingError}
                                locations={this.props.locations}
                            />
                            :
                            <>
                                <TablePage
                                    tableName="locationsTable"
                                    tableRows={this.props.locations}
                                    tableColumnHeads={this.getTableHeaders()}
                                    tableRow={(row, index) => <React.Fragment>
                                        <TableCell style={{textAlign: "center", width: "1%"}}>
                                            <Tooltip title={EDIT}>
                                                <IconButton onClick={() => this.handleOpenLocation(row)}>
                                                    <EditIcon
                                                        fontSize='medium'
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell >
                                        <TableCell style={{textAlign: "center"}}>
                                            {row.name}
                                        </TableCell>
                                        <TableCell style={{textAlign: "center"}}>
                                            {row.addressLine1}
                                        </TableCell>
                                        <TableCell style={{textAlign: "center"}}>
                                            {row.city}
                                        </TableCell>
                                        <TableCell style={{textAlign: "center"}}>
                                            {row.state}
                                        </TableCell>
                                        <TableCell style={{textAlign: "center"}}>
                                            {row.country}
                                        </TableCell>
                                        <TableCell style={{ color: row.enabled ? "black" : "red", textAlign: "center" }}>
                                            {row.enabled ? "Yes" : "No"}
                                        </TableCell>
                                    </React.Fragment>}
                                    onRowDoubleClick={(row) => {
                                        this.handleOpenLocation(row)
                                    }}
                                />
                            </>
                        :
                        <ManageLocationForm
                            addingLocation={false}
                            editingLocation={this.state.editingLocation}
                            handleClose={this.handleClose}
                            handleSave={this.handleSave}
                            locations={this.props.locations}
                        />
                    }
                </Paper>


            </>
        );
    }

}

const mapStateToProps = (state) => ({
    locations: locationsSelector(state),
    currentUser: userInfoSelector(state),
})

const actionCreators = {
    createLocation,
    updateLocation,
    listLocations
}
export default withShipment({
    mapStateToProps,
    actionCreators
}, ManageLocationsTable);