import React from "react";
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ClearIcon from '@material-ui/icons/Clear';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Chip from "@material-ui/core/Chip";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

class TicketFiltersDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
    }

    render() {
        return (
            <>
                <div>
                    <DialogTitle id="form-dialog-title">
                        <div className={'filters-title'}>
                            <div>
                                Filters
                            </div>
                            <div>
                                <Button className="clearFiltersButton" startIcon={<ClearIcon />}
                                        onClick={this.props.clearFilters}>CLEAR FILTERS</Button>
                            </div>
                        </div>
                    </DialogTitle>
                    <DialogContent className={"dialogBox"}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Autocomplete
                                multiple
                                open={this.state.open}
                                value={this.props.statusFiltersMap}
                                options={this.props.status?.sort((a, b) => {
                                    return a.toLowerCase().localeCompare(b.toLowerCase())
                                })}
                                renderTags={(value) =>
                                    value.map((option) => (
                                        <Chip
                                            variant="outlined"
                                            size="small"
                                            style={{borderColor: "#acd685", margin: "1px 6px 1px 0", fontSize: "12px"}}
                                            label={option}
                                        />
                                    ))
                                }
                                getOptionLabel={(entry) => entry}
                                autoHighlight={true}
                                style={{ width: 300, margin: "10px 10px 20px 20px", marginBottom: 20 }}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option}
                                    </React.Fragment>
                                )}
                                onOpen={(event) => {this.setState({open : true})}}
                                onClose={(event, reason) => {
                                    reason === 'blur' ? this.setState({open : false}) : this.setState({open : true})
                                }}
                                name={"Status"}
                                onChange={(event, value) => {
                                    this.props.onChange(value)
                                }}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label={"Status"}
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        name={"Status"}
                                    />
                                }
                            />
                            <KeyboardDatePicker
                                autoOk
                                name="fromDate"
                                variant="inline"
                                inputVariant="outlined"
                                label="Due Date From"
                                format="yyyy/MM/dd"
                                value={this.props.fromDate}
                                InputAdornmentProps={{ position: "start" }}
                                onChange={date => {
                                    this.props.handleDateChange("fromDate", date)
                                }}
                                style={{ width: 300, marginLeft: 10, marginBottom: 20 }}
                            />
                            <KeyboardDatePicker
                                autoOk
                                name="toDate"
                                variant="inline"
                                inputVariant="outlined"
                                label="Due Date To"
                                format="yyyy/MM/dd"
                                // value={this.getToDate()}
                                value={this.props.toDate}
                                InputAdornmentProps={{ position: "start" }}
                                onChange={date => {
                                    this.props.handleDateChange("toDate", date)
                                }}
                                style={{ width: 300, marginLeft: 10, marginBottom: 20 }}
                            />
                        </MuiPickersUtilsProvider>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "center", marginBottom: 10 }}>
                        <Button variant='contained' onClick={() => this.props.onClose(false)}>Cancel</Button>
                        <Button variant='contained' onClick={() => { this.props.onClose(true)
                            this.props.updateTicketTableFilter({
                                updatedFilter: this.props.statusFiltersMap
                            })
                        }} color="primary">Apply</Button>
                    </DialogActions>
                </div>
            </>
        )
    }
}

export default TicketFiltersDialog;