import React from "react";
import withShipment from "../../../../withShipment";
import './ListingsTableRow.css';
import Tooltip from "@material-ui/core/Tooltip";
import {APPLY_EDIT_ENTRY, CANCEL_EDIT_ENTRY, DELETE_SKU, EDIT_ENTRY} from "../constants";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import {deleteListing, updateListing} from "../../../../redux/actions/settings";
import {Checkbox, IconButton, TableCell, TextField} from "@material-ui/core";
import {printConfigSelector, productsSelector} from "../../../../redux/selectors/settings";
import ClearIcon from '@mui/icons-material/Clear';
import {Autocomplete, FormControlLabel} from "@mui/material";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";


class ListingsTableRow extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

        let newState = {
            deleteListing: false,
            shortcode: this.props.row.shortcode,
            shippingTemplate: this.props.row.shippingTemplate,
            handlingTime: this.props.row.handlingTime,
            newQuantity: 0,
            isSelecting: false,
        }
        this.props.setRowState(newState)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.listingIdBeingEdited !== this.props.listingIdBeingEdited) {
            if (this.props.listingIdBeingEdited === this.props.row.webstoreProductId) {
                this.props.setRowState({
                    shortcode: this.props.row.shortcode,
                    shippingTemplate: this.props.row.shippingTemplate,
                    handlingTime: this.props.row.handlingTime,
                    newQuantity: this.props.row.quantity
                })
            }
        }
    }

    handleCancel = (row) => {
        this.props.setListingIdBeingEdited("")
        this.props.setRowState({
            shortcode: this.props.row.shortcode,
            shippingTemplate: this.props.row.shippingTemplate ? this.props.row.shippingTemplate : "",
            handlingTime: this.props.row.handlingTime ? this.props.row.handlingTime : "",
            newQuantity: 0,
            newQuantities: this.props.row.quantities,
        })
    }

    handleConfirm = (row) => {
        let state = this.props.getRowState()
        if (!state.shortcode || !state.newQuantity) {
            return // do not save if no shortcode or quantity
        }

        this.props.setListingIdBeingEdited("")
        const request = {
            id: row.webstoreProductId,
            shortcode: state.shortcode,
            shippingTemplate: state.shippingTemplate === undefined? "" : state.shippingTemplate,
            handlingTime: state.handlingTime === undefined? "" : state.handlingTime,
            quantity: state.newQuantity,
        };
        this.props.setRowState({
            shortcode: this.props.row.shortcode,
            shippingTemplate: this.props.row.shippingTemplate,
            handlingTime: this.props.row.handlingTime,
            newQuantity: 0,
        })
        this.props.updateListing(request);
    }

    getEditingCancelConfirm = (row) => {
        return <>
            <div style={{width: "72px"}}>
                <Tooltip title={CANCEL_EDIT_ENTRY}>
                    <IconButton onClick={() => this.handleCancel(row)}>
                        <ClearIcon
                            fontSize='small'
                            color='error'
                        />
                    </IconButton>
                </Tooltip>

                <Tooltip title={APPLY_EDIT_ENTRY}>
                    <IconButton onClick={() => this.handleConfirm(row)}>
                        <CheckCircleIcon
                            fontSize="small"
                            color='secondary'
                        />
                    </IconButton>
                </Tooltip>
            </div>
        </>
    }

    displayShortcodeEditingAutocomplete = () => {
        let state = this.props.getRowState()
        return <React.Fragment>
            <Autocomplete
                options={this.props.products ? this.props.products.map(product => product.shortcode).sort((a, b) => { return a.toLowerCase().localeCompare(b.toLowerCase()) }) : []}
                getOptionLabel={(product) => product}
                style={{ width: 180 }}
                autoHighlight={true}
                blurOnSelect={false}
                value={state.shortcode? state.shortcode: ""}
                onChange={(e, value) => {
                    this.props.setRowState({ shortcode: value })
                }}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        variant="outlined"
                        name="shortcode"
                        label='Shortcode'
                        error={state.shortcode === '' || state.shortcode === null || state.shortcode === undefined}
                        InputLabelProps={{ shrink: true }}
                    />
                }
            />
        </React.Fragment>
    }

    displayShippingTemplateEditingAutocomplete = () => {
        let availableOptions = []
        this.props.availableShippingTemplates.forEach(option => {
            availableOptions.push(option.shippingTemplateName)
        })
        let state = this.props.getRowState()
        return <React.Fragment>
            <Autocomplete
                options={availableOptions? availableOptions :[]}
                getOptionLabel={(availableOptions) => availableOptions}
                style={{ width: 180 }}
                autoHighlight={true}
                blurOnSelect={false}
                value={this.props.row?.shippingTemplate? this.props.row?.shippingTemplate: ""}
                onChange={(e, value) => {
                    this.props.setRowState({ shippingTemplate: value })
                }}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        variant="outlined"
                        name="shippingTemplate"
                        label='Shipping Template'
                        error={state.shippingTemplate === '' || state.shippingTemplate === null}
                        InputLabelProps={{ shrink: true }}
                    />
                }
            />
        </React.Fragment>
    }

    updateNewQuantity = (newValue) => {
        let value
        try {
            if (!newValue) {
                value = ""
            } else {
                value = parseInt(newValue)
            }
        } catch (ignored) {
            return
        }

        this.props.setRowState({
            newQuantity: value
        })
    }

    displayQuantityEditingTextField = () => {
        let state = this.props.getRowState()
        return <TextField
            variant="outlined"
            name="quantity"
            style={{ width: 120 }}
            label="Quantity"
            value={state.newQuantity}
            onChange={(e) => this.updateNewQuantity(e.target.value)}
            error={!state.newQuantity}
        />
    }

    updateHandlingTime = (newValue) => {
        let value
        try {
            if (!newValue) {
                value = ""
            } else {
                value = parseInt(newValue)
            }
        } catch (ignored) {
            return
        }

        this.props.setRowState({
            handlingTime: value
        })
    }

    displayHandlingTimeEditingTextField = () => {
        let state = this.props.getRowState()
        return <TextField
            variant="outlined"
            name="handlingTime"
            style={{ width: 120 }}
            label="Handling time"
            value={state.handlingTime}
            onChange={(e) => this.updateHandlingTime(e.target.value)}
        />
    }

    displayEditingRow = (row) => {
        return <React.Fragment>
            <TableCell align="center" style = {{paddingLeft: "13px"}}>
                {this.getEditingCancelConfirm(row)}
            </TableCell>
            <TableCell style={{minWidth: "90px", width: "15%", paddingLeft: "3px"}}>
                {row.sku}
            </TableCell>
            <TableCell style={{minWidth: "90px", width: "15%", paddingLeft: "3px"}}>
                <a href={`https://www.${row.marketplaces[0]}/dp/${row.asin}`} target="_blank" rel="noopener noreferrer" style={{ color: 'black' }}>
                    {row.asin}
                </a>
            </TableCell>
            <TableCell style={{minWidth: "80px", width: "15%", paddingLeft: "3px"}}>
                {this.displayShortcodeEditingAutocomplete()}
            </TableCell>
            <TableCell style={{minWidth: "90px", width: "5%", paddingLeft: "3px"}}>
                {this.displayQuantityEditingTextField()}
            </TableCell>
            <TableCell style={{minWidth: "90px", width: "8%", paddingLeft: "3px"}}>
                {row.shipAddressId?this.props.matchLocations(row.shipAddressId):null}
            </TableCell>
            <TableCell style={{minWidth: "110px", width: "8%", paddingLeft: "3px"}}>
                {row.marketplaces?row.marketplaces[0]:null}
            </TableCell >
            <TableCell style={{minWidth: "180px", width: "10%", paddingLeft: "3px"}}>
                {this.displayShippingTemplateEditingAutocomplete()}
            </TableCell>
            <TableCell style={{minWidth: "120px", width: "5%", paddingLeft: "3px"}}>
                {this.displayHandlingTimeEditingTextField()}
            </TableCell>
            {this.getDelistedSkuCell(row)}
        </React.Fragment>
    }

    getDelistedSkuCell = (row) => {
        return <React.Fragment>
            {
                !row.isDeleted ? <TableCell style={{width: "14%",}}/> :
                    <TableCell style={{width: "14%", borderLeft: '1px solid lightgrey'}}>
                    {!this.props.getRowState().deleteListing ?
                        <Tooltip title={DELETE_SKU}>
                            <IconButton onClick={() => this.props.setRowState({ deleteListing: true })} size={"small"}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                        : <div style={{display: "flex"}}>
                            <Button onClick={() => this.props.setRowState({ deleteListing: false })} size={"small"}>
                                Cancel
                            </Button>
                            <br />
                            <Button
                                variant="contained"
                                startIcon={<DeleteIcon/>}
                                onClick={() => this.props.deleteListing({id: row.webstoreProductId, initQuantity: row.quantity})}
                                style={{padding: '5px'}}
                                size={"small"}
                            >
                                Delete
                            </Button>
                        </div>
                    }
                </TableCell>
            }
        </React.Fragment>
    }

    displayNonEditingRow = (row) => {
        return <React.Fragment>
            <TableCell align="center" style = {{paddingLeft: "18px", width: "8%",}}>
                <FormControlLabel
                    control={
                <Checkbox
                            checked={this.props.selectedEntries.includes(row.webstoreProductId)}
                            onChange={() => this.props.handleSelectListing(row.webstoreProductId)}
                        />
                    }
                />
            </TableCell>
            <TableCell style={{minWidth: "90px", width: "15%", paddingLeft: "3px"}}>
                {row.sku}
            </TableCell>
            <TableCell style={{minWidth: "90px", width: "15%", paddingLeft: "3px"}}>
                <a href={`https://www.${row.marketplaces[0]}/dp/${row.asin}`} target="_blank" rel="noopener noreferrer" style={{ color: 'black' }}>
                    {row.asin}
                </a>
            </TableCell>
            <TableCell style={{minWidth: "90px", width: "15%", paddingLeft: "3px"}}>
                {row.shortcode}
            </TableCell>
            <TableCell style={{minWidth: "90px", width: "5%", paddingLeft: "10px"}}>
                {row.quantity}
            </TableCell>
            <TableCell style={{minWidth: "90px", width: "8%", paddingLeft: "3px"}}>
                {row.shipAddressId?this.props.matchLocations(row.shipAddressId):null}
            </TableCell>
            <TableCell style={{minWidth: "110px", width: "8%", paddingLeft: "3px"}}>
                {row.marketplaces?row.marketplaces[0]:null}
            </TableCell >
            <TableCell style={{minWidth: "180px", width: "10%", paddingLeft: "3px"}}>
                {row.shippingTemplate}
            </TableCell>
            <TableCell style={{minWidth: "120px", width: "5%", paddingLeft: "3px"}}>
                {row.handlingTime}
            </TableCell>
            {this.getDelistedSkuCell(row)}
        </React.Fragment>
    }

    displayListingRow = (listingIdBeingEdited) => {
        if (listingIdBeingEdited === this.props.row.webstoreProductId) {
            return this.displayEditingRow(this.props.row)
        } else {
            return this.displayNonEditingRow(this.props.row)
        }
    }

    render() {
        // should return a <React.Fragment> of <TableCell> elements
        return (
            <React.Fragment>
                {this.displayListingRow(this.props.listingIdBeingEdited)}
            </React.Fragment>
        )
    }
}

ListingsTableRow.defaultProps = {
    deleteListing: false,
    shortcode: "",
    shippingTemplate: "",
    handlingTime: "",
    newQuantity: 0,
}

ListingsTableRow.propTypes = {
}

const mapStateToProps = (state) => ({
    printConfig: printConfigSelector(state),
    products: productsSelector(state),
})

const actionCreators = {
    deleteListing,
    updateListing,
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, ListingsTableRow);