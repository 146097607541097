import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import {
  DialogContent,
  DialogTitle,
  ListItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Tooltip
} from '@material-ui/core'
import DehazeIcon from '@material-ui/icons/Dehaze';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import DragAndDrop from "../global/Dnd/DragAndDrop";

// import styling
import './TableDisplayDialog.css';
import PropTypes from "prop-types";
import { TABLE_DISPLAY_LABEL, RESET } from "./constants"

class TableDisplayDialog extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
        orderId: ""
    }
  }

  /**
   * Helper method to get values for input fields. Using this.props.filtersMap[id] 
   * directly in the value field can make the textfields not render correctly when 
   * the map clears and the map becomes "undefined" 
   * 
   * @param id the ID of the filtersMap for which key is being retrieved
   */
  handleChange = (selected, text) => {
    this.props.setChecked(selected, text)
  }
  
  getItem = () => {
    return (
      this.props.tableDisplay.map((card, i) => {
        const handleChange = (event) => {
          this.props.setChecked(event.target.checked, card.label)
        }
        const isdisabled = () => {
          return card.label == 'Name' || card.label == 'Order ID'// || card.label == 'Status'
        }
        return({
          id: card.key,
          component:
            <ListItem
                style={{cursor: 'grab'}}>
              <ListItemIcon>
                <Checkbox name={card.text} checked={card.selected} onChange={handleChange} disabled={isdisabled()}>
                </Checkbox>
              </ListItemIcon>

              <ListItemText>
                  {card.label}
              </ListItemText>

              <ListItemIcon style={{justifyContent: 'flex-end'}}>
                <DehazeIcon/>
              </ListItemIcon>
            </ListItem>
        }
        
        )
      }
      
        
      )
      
    )
  }
  render() {
    return (
        <Dialog onEntered={this.props.onOpen} 
                open={this.props.isOpen} 
                onClose={() => this.props.onClose(false)}
                aria-labelledby="filters-dialog-title"
                scroll={'paper'}
                maxWidth={"sm"}
                classes={{ paper: 'tableDisplayDialogBox'}}
        >
            <DialogTitle id="form-dialog-title" style={{backgroundColor: '#acd685'}}>
              <div className="tableDisplayDialogTitleBox">
                <div>
                  {TABLE_DISPLAY_LABEL}
                </div>
                <div className="tableDisplayDialogTitleBtn">
                  <Tooltip className='conditionIcons' title={RESET}>
                    <IconButton
                      aria-label={RESET}
                      onClick={() => this.props.handleTableDisplayChange(this.props.defaultTable)}
                    >
                      <RotateLeftIcon variant='outlined'/>
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </DialogTitle>

            <DialogContent>
                <DndProvider backend={HTML5Backend}>
                    <DragAndDrop
                        cards={this.props.tableDisplay} 
                        handleTableDisplayChange={this.props.handleTableDisplayChange}
                        setChecked = {this.handleChange}
                        items = {this.getItem()}/>

                </DndProvider>
            </DialogContent>
        </Dialog>
    )
  }
}

TableDisplayDialog.propTypes = {
    isOpen: PropTypes.bool,
    onOpen: PropTypes.func,
    onChange: PropTypes.func,
    clearFilters: PropTypes.func,
    onClose: PropTypes.func,
    filtersMap: PropTypes.object

}

TableDisplayDialog.defaultProps = {
    isOpen: false,
    onOpen: ()=>{},
    onChange: ()=>{},
    clearFilters: ()=>{},
    onClose: ()=>{},
    filtersMap: {}
}


export default TableDisplayDialog