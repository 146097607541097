import React from "react";

// material components
import {
  Select,
  MenuItem,
  Box,
  TextField,
  Tooltip,
  IconButton,
} from "@material-ui/core";

// material icons
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
// import constants
import {BETWEEN, FILTER_TYPE_NUMERIC_OPTIONS, FILTER_TYPE_NUMERIC_OPTIONS2, IS_EMPTY, ORDER_ITEM_WARNING} from "./constants";
import {COPY_FILTER, DELETE_FILTER, DRAG} from "../constants";

// import styling
import "./FilterNumericField.css";
import PropTypes from "prop-types";
import FilterBooleanSelect from "./FilterBooleanSelect";
import DehazeIcon from "@material-ui/icons/Dehaze";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import InputAdornment from "@material-ui/core/InputAdornment";

/**
 * Numeric Textfield with predefined dropdown and an inputTextField.
 */
class FilterNumericField extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filterId: "",
      name: "",
      value1: "", // value for the first drop down
      value2: "", // value of the numeric textfield
      value3: "", //value of the second numeric textfield
    };
  }

  componentDidMount = () => {
    this.setState({
      // on component mount, the parent component stores this child's state.
      // We restore it here
      ...this.props.filter,
    });
  };

  handleValue1Change = (event) => {
    const value1 = event.target.value;
    this.setState(
      {
        value1,
      },
      () => {
        this.props.updateFilterWithParent({ ...this.state });
      }
    );
  };

  handleValue2Change = (event) => {
    const value2 = event.target.value;
    this.setState(
      {
        value2,
      },
      () => {
        this.props.updateFilterWithParent({ ...this.state });
      }
    );
  };

  handleValue3Change = (event) => {
    const value3 = event.target.value;
    this.setState(
        {
          value3,
        },
        () => {
          this.props.updateFilterWithParent({ ...this.state });
        }
    );
  };

  handleValue2ChangeProfit = (event) => {
    if (this.state.name === "Profit Threshold" && !event.target.value.includes(".") && !event.target.value.includes("-") && isNaN(event.target.value) || event.target.value === "") {
      this.setState({
        value2: "0.00"
      },
          () => {
            this.props.updateFilterWithParent({ ...this.state });
          })
      return;
    }
    let value2;
    try {
      value2 = this.state.name !== "Profit Threshold" ? event.target.value : event.target.value.charAt(0) !== "-" ? parseFloat(event.target.value).toFixed(2) : "-" + parseFloat(event.target.value.slice(1)).toFixed(2);
    }
    catch {
      this.setState({
        value2: "0.00"
      },
          () => {
            this.props.updateFilterWithParent({ ...this.state });
          })
      return;
    }
    this.setState(
        {
          value2,
        },
        () => {
          this.props.updateFilterWithParent({ ...this.state });
        }
    );
  };

  handleValue3ChangeProfit = (event) =>{
    if (this.state.name === "Profit Threshold" && !event.target.value.includes(".") && !event.target.value.includes("-") && isNaN(event.target.value) || event.target.value === "") {
      this.setState({
            value3: "0.00"
          },
          () => {
            this.props.updateFilterWithParent({ ...this.state });
          })
      return;
    }
    let value3;
    try {
      value3 = this.state.name !== "Profit Threshold" ? event.target.value : event.target.value.charAt(0) !== "-" ? parseFloat(event.target.value).toFixed(2) : "-" + parseFloat(event.target.value.slice(1)).toFixed(2);
    }
    catch {
      this.setState({
            value3: "0.00"
          },
          () => {
            this.props.updateFilterWithParent({ ...this.state });
          })
      return;
    }
    this.setState(
        {
          value3,
        },
        () => {
          this.props.updateFilterWithParent({ ...this.state });
        }
    );
  }

  render() {
    return (
      <Box
          className='filterNumericField'
          display='flex'
          flexDirection='column'
          justifyContent='left'
          alignItems='center'
      >
        <Box
          display='flex'
          flexDirection='row'
          textAlign='left'
          alignItems='center'
          width='100%'
        >
          <Box className='filterNumericFieldName'>{this.state.name}</Box>
          {this.state.name =="Days of Coverage" || this.state.name =="Inventory (quantity)"?
              <Select
                  className='filterNumericFieldOptions'
                  variant='outlined'
                  label={this.state.name}
                  value={this.state.value1}
                  onChange={this.handleValue1Change}
              >
                {FILTER_TYPE_NUMERIC_OPTIONS2.map((item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
              :
              <Select
                  className='filterNumericFieldOptions'
                  variant='outlined'
                  label={this.state.name}
                  value={this.state.value1}
                  onChange={this.handleValue1Change}
              >
                {FILTER_TYPE_NUMERIC_OPTIONS.map((item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
          }
          <div>
            {this.state.value1 === IS_EMPTY ? <></> :
                this.state.value1 === BETWEEN ?
                    <div style={{ display: 'flex' }}>
                      <TextField
                          className='filterNumericFieldInput'
                          variant='outlined'
                          label={this.state.name}
                          value={this.state.value2}
                          onChange={this.handleValue2Change}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{startAdornment: (<InputAdornment position="start">$</InputAdornment>)}}
                          onBlur={this.handleValue2ChangeProfit}
                          type="number"
                      />
                      <TextField
                          className='filterNumericFieldInput'
                          variant='outlined'
                          label={this.state.name}
                          value={this.state.value3}
                          onChange={this.handleValue3Change}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{startAdornment: (<InputAdornment position="start">$</InputAdornment>)}}
                          onBlur={this.handleValue3ChangeProfit}
                          type="number"
                      />
                    </div>:
              this.state.name === "Profit Threshold" ?
                  <TextField
                      className='filterNumericFieldInput'
                      variant='outlined'
                      label={this.state.name}
                      value={this.state.value2}
                      onChange={this.handleValue2Change}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{startAdornment: (<InputAdornment position="start">$</InputAdornment>)}}
                      onBlur={this.handleValue2ChangeProfit}
                  />
                  :
                  <TextField
                      className='filterNumericFieldInput'
                      variant='outlined'
                      type='number'
                      label={this.state.name}
                      value={this.state.value2}
                      onChange={this.handleValue2Change}
                      InputLabelProps={{
                        shrink: true,
                      }}
                  />
            }
          </div>
          <div className='filterBooleanEmptyDiv'>
            <Tooltip title={COPY_FILTER}>
              <IconButton
                  aria-label={COPY_FILTER}
                  onClick={() => this.props.copyFilter(this.state.filterId)}>
                <FileCopyIcon fontSize='small'/>
              </IconButton>
            </Tooltip>
            <Tooltip title={DELETE_FILTER}>
              <IconButton
                aria-label={DELETE_FILTER}
                onClick={() => this.props.deleteFilter(this.state.filterId)}
              >
                <DeleteOutlineIcon variant='outlined' fontSize='small' />
              </IconButton>
            </Tooltip>
            <Tooltip title={DRAG}>
              <IconButton
                  aria-label='drag'
              >
                <DehazeIcon variant='outlined' fontSize='small' />
              </IconButton>
            </Tooltip>
          </div>
        </Box>
        {this.state.name == "Order item qty"?
        <Box
            textAlign='left'
            width='100%'
        >
            <div className="order-item-warning">
                <InfoOutlinedIcon variant='outlined' fontSize='small'/>{ORDER_ITEM_WARNING}
            </div>
        </Box>
        :null}
      </Box>
    );
  }
}

FilterNumericField.propTypes = {
  filter: PropTypes.object,
  updateFilterWithParent: PropTypes.func,
  deleteFilter: PropTypes.func,
  isEdit: PropTypes.bool,
  copyFilter: PropTypes.func
}

FilterNumericField.defaultProps = {
  filter: {},
  updateFilterWithParent: ()=>{},
  deleteFilter: ()=>{},
  isEdit: false,
  copyFilter: ()=>{}
}

export default FilterNumericField;
