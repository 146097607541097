export const BANK_ACCOUNTS_TITLE = "Bank Accounts";

export const SAVE_ACCOUNT = "Save Account";

export const BACK = "Back to Menu"

export const BANK_PAGES = {
    TABLE: "table",
    ADD_BANK: "add_bank",
}

export const ACCOUNT_TYPE_OPTIONS = [
    "Chequing",
    "Credit Card",
    "Cash"
];

export const CURRENCY_TYPE_OPTIONS = [
    "CAD",
    "USD",
];

export const CREATE_BANK_ACCOUNT_HEAD_CELLS = [
    { id: 'accountType', label: 'Account Type', fieldType: "Dropdown", options: ACCOUNT_TYPE_OPTIONS },
    { id: 'name', label: 'Name', fieldType: "Textfield", options: null },
    { id: 'accountNumber', label: 'Account Number', fieldType: "Textfield", options: null},
    { id: 'currency', label: 'Currency', fieldType: "Textfield", options: null},
];

export const CURRENCY_HEAD_CELLS = [
    { id: 'currency', label: 'Currency', fieldType: "Dropdown", options: CURRENCY_TYPE_OPTIONS }
];

export const EDIT_BANK_ACCOUNT_TOOLTIP = "Edit Bank Account";
export const ADD_BANK_ACCOUNT_TOOLTIP = "Add A Bank Account";
export const EDIT_BANK_ACCOUNT_INFO = "Note: Editting a bank account information will change all transactions related with this bank account"