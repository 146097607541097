import React, {useState, useEffect} from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
// material components
import {
    FormControl,
    FormHelperText,
    MenuItem,
    Select,
    TextField,
    Checkbox,
    FormControlLabel,
} from "@material-ui/core";

// styling
import styles from './CreateCarrierDialog.module.css';
import PropTypes from "prop-types";
import {setAddressValidation} from "../../../redux/actions/settings";
import withShipment from "../../../withShipment";


function AddressValidationForm(props) {
    // const [addresses, setAddresses] = useState([])
    // useEffect(() => {
    //     setAddresses([{addressLine1: "NONE", name: ""}, ...props.addresses])
    // }, [props.parentState])


    return(
        <div className={styles.rowCarrier}>
            <div className={styles.innerBox}>
                <div className={styles.rowSpec}>
                    <FormControlLabel
                        className={styles.formTwoInput}
                        control={
                            <Checkbox
                                checked={props.parentState.addressValidation}
                                disabled={!props.isEdit}
                                onChange={props.handleAddressValidationChange}
                            />

                        }
                        label="Address Validation"
                    />
                </div>
            </div>
        </div>
    )
}


AddressValidationForm.propTypes = {
    parentState: PropTypes.object,
    handleChange: PropTypes.array,
    errors: PropTypes.array,
    isEdit: PropTypes.bool,
    handleAddressValidationChange: PropTypes.func,
}

AddressValidationForm.defaultProps = {
    parentState: {},
    handleChange: [],
    errors: [],
    isEdit: false,
    handleAddressValidationChange: () => {}
}


export default AddressValidationForm;
