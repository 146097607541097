import {
    takeLatest,
    put,
    call,
} from 'redux-saga/effects';
import axios from 'axios';

// constants for endpoints
import {
    AUTHENTICATION, PURCHASE_ORDERS
} from "../../../components/global/Server/endpoints";

// constants for actions
import {
    AUTH_LOGIN, AUTH_LOGOUT, GET_USERS,
    REGISTER_USER,
    TIMEOUT
} from "../../constants/auth";
import {
    FETCH_LINKED_USERS
} from "../../constants/userManagement";

// secondary actions
import {
    requestStatus,
    clearStatus,
    setOpen,
    showErrorMessage,
    hideError
} from "../../actions/global";
import {
    fetchLinkedUsers
} from "../../actions/userManagement";
import {
    storeToken, storeUsers
} from "../../actions/auth";
import { HTTP_SUCCESS } from "../../constants/global";
import { SERVER_FAILED_ERROR_MESSAGE } from '../../../pages/Dashboard/DashboardFinal/constants';

function* getUserInfo(data, token) {
    return yield axios.post(AUTHENTICATION.GET_USER_INFO, data, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
        .then(response => response.data)
}

const getHeaders = () => {
    const token = localStorage.getItem('token');
    return {
        "Authorization": `Bearer ${token}`
    }
}

const getRequest = (endpoint) => {
    return axios.get(endpoint, {
        headers: getHeaders()
    })
        .then(response => response);
}

function* postRequest(endpoint, data) {
    return yield axios.post(endpoint, data, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
        .then(response => response)
}

function* request(username, password) {
    return yield axios.post(AUTHENTICATION.AUTH_LOGIN, {
        username,
        password,
    }).then(res => res.data).catch(error => {
        let response = {}
        if (error.response) {
            response.status = error.response.status
            response.statusText = error.response.statusText
            response.data = error.response.data
        }
        return response
    })
}

function* authLogout() {
    yield put(hideError())
    try {
        yield call(() => postRequest(AUTHENTICATION.AUTH_LOGOUT, {}));
        yield localStorage.clear();
        window.location.replace('/login')
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* authLogin(action) {
    yield put(hideError())
    try {
        const response = yield call(() => request(action.username, action.password))
        if (response.access_token) {
            localStorage.setItem('token', response.access_token)

            const additionalInfo = yield call(() => getUserInfo({ username: action.username }, response.access_token))

            const userInfo = {
                username: response.username,
                authority: response.roles[0],
                firstname: additionalInfo.firstname,
                lastname: additionalInfo.lastname,
                userId: additionalInfo.userId,
                company: additionalInfo.companyId,
                linkedUsers: additionalInfo.linkedUsers,
                routes: additionalInfo.routes,
                userType: additionalInfo.userType,
                locationIds: additionalInfo.locationIds,
                defaultLocationId: additionalInfo.defaultLocationId,
                selectedLocationId: additionalInfo.defaultLocationId
            }


            yield put(storeToken(response.access_token, userInfo))

            yield action.history.push('/')
            //setTimeout(() => action.history.push('/'), TIMEOUT)
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* registerUser(action) {
    yield call(clearStatus)

    try {
        const response = yield call(() => postRequest(AUTHENTICATION.AUTH_REGISTER, action.newUser))

        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === 200,
        }

        yield put(requestStatus(status))
        if (status.success && action.newUser.is_linked_user) {
            const userAction = {
                type: FETCH_LINKED_USERS,
                username: action.newUser.admin
            }
            yield put(fetchLinkedUsers(userAction))
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* getUsersSaga() {
    try {
        const response = yield call(() => getRequest(AUTHENTICATION.GET_USERS))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        yield put(storeUsers(response.data));
        yield put(requestStatus(status));
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

export default function* authSagas() {
    yield takeLatest(AUTH_LOGIN, authLogin);
    yield takeLatest(REGISTER_USER, registerUser);
    yield takeLatest(AUTH_LOGOUT, authLogout);

    yield takeLatest(GET_USERS, getUsersSaga)
}