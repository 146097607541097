import React, { useEffect, useState } from 'react';

// material components
import {
    FormControl,
    InputLabel,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    Select,
    TextField,
    makeStyles,
    createStyles, IconButton, Tooltip, RadioGroup, FormControlLabel, withStyles, Radio, Box, Typography
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

// import styling
import '../ShipmentForm.css';
import PropTypes from "prop-types";
import SelectCarrier from "../../settings/ManageCarriers/SelectCarrier";

// constants
import { ADD_TRACKING_NUMBER, CURRENCIES, DELETE_TRACKING_NUMBER } from "./constants"
import {CREATE_ORDER_TITLE, INTERNATIONAL_TITLE} from "../constants";
import {CANCELLED, CLONED, SHIPPED, UNSHIPPED} from "../../stages/constants"
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import { AddBoxSharp } from '@material-ui/icons';
import FormLabel from "@material-ui/core/FormLabel";
import {Divider} from "material-ui";
import ShipmentFormInternationalDialogue from "../ShipmentFormInternationalDialogue";

const useStyles = makeStyles((theme) => createStyles({
    quantitySelect: {
        maxWidth: 130,
        width: 130
    }
}))

const defaultProps = {
    bgcolor: 'background.paper',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    m: 1,

    border: 1,
    style: { width: '100%', height: '100%' },
};

const GreenRadio = withStyles({
    root: {
        color: "#acd685",
        '&$checked': {
            color: "#acd685",
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);

function ItemDetails(props) {
    const classes = useStyles();

    const [state, setState] = useState({
        quantities: [],
        packaging: '',
        packageId: 0,
        shipmentId: '',
        trackingNumber: '',
        reference1: '',
        reference2: '',
        reference3: '',
        weight: '',
        length: '',
        width: '',
        height: '',
        canparLabelId: '',
        zplLabel: '',
        skus: [],
        currency: '',
        declaredValue: '',
        products: [],
        orderItems: [],
        packageType: '',
        allBoxes: [],
        selectedSuggestedBox: "",
        originalLength: "",
        originalHeight: "",
        originalWidth: "",
        originalPackaging: "",
        otherBoxes: [{ shortcode: "-", boxId: "-" },
        { shortcode: "Box/Other", boxId: "other" }],
        showTrackingNumber: false,
        deleteDialogOpen: false,
        rate: '',
    })

    const isCreateShip = () => {
        return props.isCreateShip
    }
    // iterate through all products initially and set the total quantity



    useEffect(() => {
        let allBoxCodes = props.boxes.map(item => "Box/" + item.shortcode + " " + "(" + item.nominalSize + ")");
        if (!isCreateShip()) {
            let quantities = []
            if (props.order.products) {
                quantities = [...props.order.products.map(product => ({
                    product: product.product,
                    quantity: product.quantity
                }))];
            }
            setState({
                ...state,
                packageId: props.order.packageId,
                shipmentId: props.order.shipmentId,
                trackingNumber: props.order.trackingNumber || '',
                reference1: props.order.reference1 || '',
                reference2: props.order.reference2 || '',
                reference3: props.order.reference3 || '',
                weight: props.order.weight || '',
                length: props.order.length || '',
                width: props.order.width || '',
                height: props.order.height || '',
                originalHeight: state.originalHeight ? state.originalHeight : (props.order.height || ''),
                originalLength: state.originalLength ? state.originalLength : (props.order.length || ''),
                originalWidth: state.originalWidth ? state.originalWidth : (props.order.width || ''),
                canparLabelId: props.order.canparLabelId || '',
                zplLabel: props.order.zplLabel || '',
                skus: props.order.skus ? [...props.order.skus] : [],
                currency: props.order.currency || '',
                declaredValue: props.order.declaredValue || '',
                products: props.order.products ? [...props.order.products] : [],
                orderItems: props.order.orderItems ? [...props.order.orderItems] : [],
                quantities: quantities,
                packaging: props.order.packaging,
                selectedSuggestedBox: props.order.packaging,
                originalPackaging: state.originalPackaging ? state.originalPackaging : props.order.packaging,
                showTrackingNumber: !!props.trackingNumber,
                allBoxes: allBoxCodes.concat(["-", "Box/Other"]),
                rate: props.order?.rate ?? '',
            })
        } else {
            setState({
                ...state,
                reference1: props.order.reference1,
                reference2: props.order.reference2,
                reference3: props.order.reference3,
                weight: props.order.weight,
                length: props.order.length,
                width: props.order.width,
                height: props.order.height,
                packaging: props.order.packaging,
                allBoxes: allBoxCodes.concat(["-", "Box/Other"])
            })
        }

    }, [props.order])

    const handlePackageChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        });


        props.handleChange(e.target.value, e.target.name, true, props.index)
    }

    const displaySelectOptions = (sku) => {
        // always display up to the original quantity of the item
        let index = -1
        for (let i = 0; i < state.orderItems.length; i++) {
            if (sku === state.orderItems[i].sellerSku)
                index = i;
        }
        let numList = [];
        let origNum = parseInt(state.orderItems[index]?.quantityOrdered)
        for (let i = 0; i <= origNum; i++) {
            numList.push(i)
        }

        return numList;
    }

    const displaySplitSelectOptions = (product) => {
        const sku = product.sku
        const prod = product.product
        let numberItemsPerSku = product.quantityPerProduct ? parseInt(product.quantityPerProduct) : prod.split(")-")[1] //Takes the number after ")-"
        let index = -1
        for (let i = 0; i < state.orderItems.length; i++) {
            if (sku === state.orderItems[i].sellerSku)
                index = i;
        }
        let numList = [];
        let origNum = parseInt(state.orderItems[index]?.quantityOrdered)
        let biggest = 0
        for (let i = 0; i <= origNum * numberItemsPerSku; i++) {
            numList.push(i)
            biggest = i
        }
        for (let a = biggest+1; a <= product.splitQuantity; a++){
            numList.push(a)
        }
        return numList;
    }

    const handleProductChange = (e, sku) => {
        let productInfo = {
            splitQuantity: 0,
            quantity: 0,
            index: 0
        };
        let products = [...state.products.map((product, index) => {
            if (product.sku === sku) {
                productInfo.index = index;
                //TODO add split as a constant
                if (e.target.value === "split") {
                    //TODO check this
                    productInfo.quantity = e.target.value
                    productInfo.splitQuantity = 0
                    return {
                        product: product.product,
                        sku: product.sku,
                        quantity: e.target.value,
                        splitQuantity: 0
                    }
                } else {
                    productInfo.quantity = parseInt(e.target.value)
                    productInfo.splitQuantity = 0
                    return {
                        product: product.product,
                        sku: product.sku,
                        quantity: e.target.value,
                        splitQuantity: 0
                    }
                }
            } else {
                return {
                    product: product.product,
                    sku: product.sku,
                    quantity: product.quantity,
                    splitQuantity: product.splitQuantity,
                }
            }
        })];

        const data = {
            id: props.shipmentId,
            field_to_edit: '',
            value: '',
            isPackage: false,
            index: props.index,
            isProduct: true,
            productInfo: productInfo
        }

        setState({
            ...state,
            products: products
        })

        props.editOrder(data)
        let updateBoxSuggestions = {...props.packages}
        updateBoxSuggestions[props.index].products = products
        props.getSuggestedBoxTypes(props.packages)

    }

    const handleSplitProductChange = (e, sku) => {
        let productInfo = {
            splitQuantity: 0,
            quantity: 0,
            index: 0
        };
        let products = [...state.products.map((product, index) => {
            if (product.sku === sku) {
                productInfo.index = index;
                productInfo.quantity = product.quantity
                productInfo.splitQuantity = parseInt(e.target.value)
                return {
                    product: product.product,
                    sku: product.sku,
                    quantity: product.quantity,
                    splitQuantity: e.target.value
                }
            } else {
                return {
                    product: product.product,
                    sku: product.sku,
                    quantity: product.quantity,
                    splitQuantity: product.splitQuantity
                }
            }
        })];

        const data = {
            id: props.shipmentId,
            field_to_edit: '',
            value: '',
            isPackage: false,
            index: props.index,
            isProduct: true,
            productInfo: productInfo
        }

        setState({
            ...state,
            products: products
        })

        props.editOrder(data)
        let updateBoxSuggestions = {...props.packages}
        updateBoxSuggestions[props.index].products = products
        props.getSuggestedBoxTypes(props.packages)
    }

    const getCreateShipComps = () => {
        return (
            <>
            <div className="row-spec">
                <div className="row-col">
                    <Autocomplete
                        options={state.allBoxes?.sort((a, b) => {
                            return a?.toLowerCase().localeCompare(b?.toLowerCase())
                        })}
                        getOptionLabel={(option) => option}
                        className="input-control-package"
                        name="packaging"
                        value={!props.order ? '' : props.order.packaging}
                        onChange={(event, value) => {
                            if (value == null || value == undefined) {
                                props.handleAutocompleteChange('', "packaging", true, props.index)
                            } else {
                                handleBoxTypeSelect(value, false)
                            }
                        }}
                        style={{}}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                error={!props.order?.packaging || props.order?.packaging === '-'}
                                label="Packaging"
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                name="packaging"
                                required
                            />
                        }
                    />
                    <div className="row-spec-package">
                        <TextField
                            error={!props.order.weight}
                            required
                            className="input-dim"
                            variant="outlined"
                            name="weight"
                            label="Weight"
                            InputLabelProps={{ shrink: true }}
                            value={props.order.weight}
                            onChange={(e) => props.handleChange(e.target.value, e.target.name, true, props.index)}
                            onFocus={props.handleFocus}
                        />
                        <TextField
                            error={!props.order.length}
                            required
                            className="input-dim"
                            variant="outlined"
                            name="length"
                            label="L"
                            InputLabelProps={{ shrink: true }}
                            value={props.order.length}
                            onFocus={props.handleFocus}
                            onChange={(e) => props.handleChange(e.target.value, e.target.name, true, props.index)}
                        />
                        <TextField
                            error={!props.order.width}
                            required
                            className="input-dim"
                            variant="outlined"
                            name="width"
                            value={props.order.width}
                            label="W"
                            InputLabelProps={{ shrink: true }}
                            onFocus={props.handleFocus}
                            onChange={(e) => props.handleChange(e.target.value, e.target.name, true, props.index)}
                        />
                        <TextField
                            error={!props.order.height}
                            required
                            className="input-dim"
                            variant="outlined"
                            name="height"
                            value={props.order.height}
                            label="H"
                            InputLabelProps={{ shrink: true }}
                            onFocus={props.handleFocus}
                            onChange={(e) => props.handleChange(e.target.value, e.target.name, true, props.index)}
                        />
                    </div>
                    <div className="row-spec-package">
                        <TextField
                            className="input-dv"
                            variant="outlined"
                            name="declaredValue"
                            label="Declared Value"
                            InputLabelProps={{ shrink: true }}
                            value={props.order.declaredValue}
                            onFocus={props.handleFocus}
                            onChange={(e) => props.handleChange(e.target.value, e.target.name, true, props.index)}
                        />
                        <Autocomplete
                            options={CURRENCIES}
                            getOptionLabel={(option) => option}
                            className="input-dv"
                            name="currency"
                            value={!props.order ? '' : props.order.currency}
                            onChange={(event, value) => {
                                if (value == null || value == undefined) {
                                    props.handleAutocompleteChange('', "currency", true, props.index)
                                } else {
                                    props.handleAutocompleteChange(value, "currency", true, props.index)
                                }
                            }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    error={!props.order.currency}
                                    label="Currency"
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    name="currency"
                                />
                            }
                        />
                    </div>
                </div>
                <div className="row-col">
                    <TextField
                        required
                        className="input-ref"
                        variant="outlined"
                        name="reference1"
                        label="Reference 1"
                        InputLabelProps={{ shrink: true }}
                        onFocus={props.handleFocus}
                        value={props.order.reference1}
                        error={props.order.reference1 === null || props.order.reference1 === ''}
                        onChange={(e) => props.handleChange(e.target.value, e.target.name, true, props.index)}
                    />
                    <TextField
                        className="input-ref"
                        variant="outlined"
                        name="reference2"
                        label="Reference 2"
                        InputLabelProps={{ shrink: true }}
                        onFocus={props.handleFocus}
                        value={props.order.reference2}
                        onChange={(e) => props.handleChange(e.target.value, e.target.name, true, props.index)}

                    />
                    <TextField
                        required
                        className="input-ref"
                        variant="outlined"
                        name="reference3"
                        label="Reference 3"
                        InputLabelProps={{ shrink: true }}
                        value={props.order.reference3}
                        error={props.order.reference3 === null || props.order.reference3 === ''}
                        onFocus={props.handleFocus}
                        onChange={(e) => props.handleChange(e.target.value, e.target.name, true, props.index)}

                    />
                </div>
            </div>
            <div className="row-spec">
                {props.orderInfo.fromAccount.fromAddressCountry && props.orderInfo.shippingAddressCountry && props.orderInfo.fromAccount.fromAddressCountry != props.orderInfo.shippingAddressCountry ?
                    <>
                        <ShipmentFormInternationalDialogue
                            getSampleCommercialInvoicePdf={props.getSampleCommercialInvoicePdf}
                            packageId={props.value + 1}
                            commericialInvoice={props.commercialInvoice}
                            getShipmentCommercialInvoice={props.getShipmentCommercialInvoice}
                            shipmentId={props?.shipmentId}
                            handleAutocompleteSave={props.handleAutocompleteSave}
                            handleAutocompleteChange={props.handleAutocompleteChange}
                            orderInfo={props.orderInfo}
                            handleCheck={props.handleCheck}
                            commodityEditing={props.commodityEditing}
                            romoveCommodity={props.romoveCommodity}
                            handleFocus={props.handleFocus}
                            isCreateShip={props.isCreateShip}
                            handleChange={props.handleChange}
                            handleSave={props.handleSave}
                            addCommodity={props.addCommodity}
                            shipmentStatus={props.shipmentStatus}
                            isShipmentReady={props.handleCheck}
                        />
                    </>
                    : null}
            </div>
            </>
        )
    }

    const addTrackingNumber = () => {
        setState({
            ...state,
            showTrackingNumber: true
        })
    }

    const openDeleteDialog = () => {
        setState({
            ...state,
            deleteDialogOpen: true
        })
        props.handleFocus({ target: { value: state.trackingNumber } })
    }

    const cancelDeleteDialog = () => {
        setState({
            ...state,
            deleteDialogOpen: false
        })
    }

    const closeDeleteDialog = () => {
        setState({
            ...state,
            deleteDialogOpen: false,
            showTrackingNumber: false,
            trackingNumber: ''
        })
        //TODO change and save package number
        props.handleChange('', 'trackingNumber', true, props.index)
        props.handleSave({
            target: {
                value: '',
                name: 'trackingNumber'
            }
        }, true, props.index)
    }


    const handleBoxTypeSelect = (box, save) => {
        const tempbox = box;
        let boxCode = '';
        if (box !== '-') {
            boxCode = box.split('/')[1];
            boxCode = boxCode.substring(0, boxCode.indexOf('(')).trim()
        } else {
            boxCode = '-'
        }
        let selectedBoxType = {};
        for (let item of props.boxes) {
            if (item.shortcode === boxCode) {
                selectedBoxType = item;
                break;
            }
        }
        let len = ''
        let width = ''
        let height = ''
        const regex = /\((\d+)x(\d+)x(\d+)\)/;
        const match = tempbox.match(regex);
        if (match) {
            len = match[1];
            width = match[2];
            height = match[3];
            setState({
                ...state,
                length: len,
                width: width,
                height: height,
                packaging: box
            })
        }
        else {
            setState({
                ...state,
                length: '',
                width: '',
                height: '',
                packaging: box
            })
        }

        if (!save) {
            props.handleAutocompleteChange(box, "packaging", true, props.index)
            props.handleChange(len, 'length', true, props.index)
            props.handleChange(width, 'width', true, props.index)
            props.handleChange(height, 'height', true, props.index)
        } else {
            if (box === 'Box/Other') {
                boxCode = box
                props.handleBoxChange(box, state.length, state.width, state.height, props.index)
            } else {
                props.handleBoxChange(box, len, width, height, props.index)
            }
        }
    }

    const handleSuggestedSelect = (e, size) => {
        let sizeSplit = size.split("x")
        setState({ ...state, selectedSuggestedBox: e.target.value })
        props.handleBoxChange(e.target.value, sizeSplit[0], sizeSplit[1], sizeSplit[2], props.index)
    }
    const resetSuggestion = () => {
        setState({ ...state, selectedSuggestedBox: "" })
        props.handleBoxChange(state.originalPackaging, state.originalLength, state.originalWidth, state.originalHeight, props.index)
    }
    const ignoreSuggestion = (box) => {
        props.suggestedBoxTypes.suggestions.shift()
        resetSuggestion()
        props.ignoreSuggestion(box.boxId, props.order.products)
    }
    return (
        <div hidden={props.index !== props.value} className="item-detail-border">
            <div className="row-col">
                {isCreateShip() ?
                    getCreateShipComps() :


                    <div className="row-col">
                        <div className="row-spec">
                            <div className="row-col">
                                <Autocomplete
                                    options={state.allBoxes?.sort((a, b) => {
                                        return a?.toLowerCase().localeCompare(b?.toLowerCase())
                                    })}
                                    disabled={props.shipmentStatus === SHIPPED || props.shipmentStatus === CANCELLED}
                                    getOptionLabel={(option) => option}
                                    className="input-control-package"
                                    name="packaging"
                                    // value={state.packaging}
                                    value={!props.order ? '' : props.order.packaging}
                                    onChange={(e, value) => {
                                        if (value === null || value === undefined) {
                                            handleBoxTypeSelect("-", true)
                                        } else {
                                            handleBoxTypeSelect(value, true)
                                        }
                                    }}
                                    style={{ width: '360px' }}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            error={!props.order?.packaging || props.order?.packaging === '-'}
                                            label="Packaging"
                                            InputLabelProps={{ shrink: true }}
                                            variant="outlined"
                                            name="packaging"
                                            required
                                        />
                                    }
                                />
                                <div className="row-spec-package">
                                    <TextField
                                        disabled={props.shipmentStatus === SHIPPED || props.shipmentStatus === CANCELLED}
                                        error={!state.weight}
                                        required
                                        className="input-dim"
                                        variant="outlined"
                                        name="weight"
                                        label="Weight"
                                        InputLabelProps={{ shrink: true }}
                                        value={state.weight}
                                        onChange={handlePackageChange}
                                        onBlur={(e) => props.handleSave(e, true, props.index)}
                                        onFocus={props.handleFocus}
                                    />
                                    <TextField
                                        error={!state.length}
                                        disabled={props.shipmentStatus === SHIPPED || props.shipmentStatus === CANCELLED}
                                        required
                                        className="input-dim"
                                        variant="outlined"
                                        name="length"
                                        label="L"
                                        InputLabelProps={{ shrink: true }}
                                        value={props.order.length}
                                        onFocus={props.handleFocus}
                                        onChange={handlePackageChange}
                                        onBlur={(e) => props.handleSave(e, true, props.index)}
                                    />
                                    <TextField
                                        disabled={props.shipmentStatus === SHIPPED || props.shipmentStatus === CANCELLED}
                                        error={!state.width}
                                        required
                                        className="input-dim"
                                        variant="outlined"
                                        name="width"
                                        label="W"
                                        InputLabelProps={{ shrink: true }}
                                        value={props.order.width}
                                        onFocus={props.handleFocus}
                                        onChange={handlePackageChange}
                                        onBlur={(e) => props.handleSave(e, true, props.index)}
                                    />
                                    <TextField
                                        disabled={props.shipmentStatus === SHIPPED || props.shipmentStatus === CANCELLED}
                                        error={!state.height}
                                        required
                                        className="input-dim"
                                        variant="outlined"
                                        name="height"
                                        label="H"
                                        InputLabelProps={{ shrink: true }}
                                        value={props.order.height}
                                        onFocus={props.handleFocus}
                                        onChange={handlePackageChange}
                                        onBlur={(e) => props.handleSave(e, true, props.index)}
                                    />
                                </div>
                                <div className="row-spec-package">
                                    <TextField
                                        disabled={props.shipmentStatus === SHIPPED || props.shipmentStatus === CANCELLED}
                                        className="input-dv"
                                        variant="outlined"
                                        name="declaredValue"
                                        label="Declared Value"
                                        InputLabelProps={{ shrink: true }}
                                        value={state.declaredValue}
                                        onFocus={props.handleFocus}
                                        onChange={handlePackageChange}
                                        onBlur={(e) => props.handleSave(e, true, props.index)}
                                    />
                                    <TextField
                                        error={!state.currency}
                                        disabled={props.shipmentStatus === SHIPPED || props.shipmentStatus === CANCELLED}
                                        required
                                        className="input-dv"
                                        variant="outlined"
                                        name="currency"
                                        label="Currency"
                                        InputLabelProps={{ shrink: true }}
                                        value={state.currency}
                                        onFocus={props.handleFocus}
                                        onChange={handlePackageChange}
                                        onBlur={(e) => props.handleSave(e, true, props.index)}
                                    />
                                </div>
                            </div>
                            <div className="row-col">
                                <TextField
                                    disabled={props.shipmentStatus === SHIPPED || props.shipmentStatus === CANCELLED}
                                    required
                                    error={!state.reference1}
                                    className="input-ref"
                                    variant="outlined"
                                    name="reference1"
                                    label="Reference 1"
                                    InputLabelProps={{ shrink: true }}
                                    value={state.reference1}
                                    onFocus={props.handleFocus}
                                    onChange={handlePackageChange}
                                    onBlur={(e) => props.handleSave(e, true, props.index)}
                                />
                                <TextField
                                    disabled={props.shipmentStatus === SHIPPED || props.shipmentStatus === CANCELLED}
                                    className="input-ref"
                                    variant="outlined"
                                    name="reference2"
                                    label="Reference 2"
                                    InputLabelProps={{ shrink: true }}
                                    value={state.reference2}
                                    onFocus={props.handleFocus}
                                    onChange={handlePackageChange}
                                    onBlur={(e) => props.handleSave(e, true, props.index)}
                                />
                                <TextField
                                    disabled={props.shipmentStatus === SHIPPED || props.shipmentStatus === CANCELLED}
                                    required
                                    error={!state.reference3}
                                    className="input-ref"
                                    variant="outlined"
                                    name="reference3"
                                    label="Reference 3"
                                    InputLabelProps={{ shrink: true }}
                                    value={state.reference3}
                                    onFocus={props.handleFocus}
                                    onChange={handlePackageChange}
                                    onBlur={(e) => props.handleSave(e, true, props.index)}
                                />
                            </div>
                        </div>

                        <div className="row-spec">
                            <List className="product-list">
                                {state.products?.map((product, index) => (
                                    <ListItem>
                                        <div className="row-col product-container">
                                            <ListItemText primary={product.product} secondary={product.sku} className="product-sku-field" style={{ marginRight: 5, marginBottom: 5 }} />
                                            {product.quantity === "split" ? <ListItemText primary={"Product item quantity"} secondary={"Number of items included in the package"} style={{ width: 360, marginRight: 5 }} /> : null}
                                        </div>
                                        <div className="row-col">
                                            <div className="menu-select">
                                                <Select
                                                    disabled={props.shipmentStatus === SHIPPED || props.shipmentStatus === CANCELLED}
                                                    autoWidth
                                                    onChange={(e) => handleProductChange(e, product.sku)}
                                                    variant="outlined"
                                                    className={classes.quantitySelect}
                                                    value={product.quantity}
                                                    style={{ marginBottom: 5 }}
                                                >
                                                    {displaySelectOptions(product.sku).map(num => (
                                                        <MenuItem className="menu-select" value={num}>qty: {num}</MenuItem>
                                                    ))}
                                                    <MenuItem className="menu-select" value={"split"}>Split</MenuItem>
                                                </Select>
                                            </div>
                                            {product.quantity === "split" ? <div className="menu-select">
                                                <Select
                                                    disabled={props.shipmentStatus === SHIPPED || props.shipmentStatus === CANCELLED}
                                                    autoWidth
                                                    onChange={(e) => handleSplitProductChange(e, product.sku)}
                                                    variant="outlined"
                                                    className={classes.quantitySelect}
                                                    value={product.splitQuantity.toString()}
                                                >
                                                    {displaySplitSelectOptions(product).map(num => (
                                                        <MenuItem className="menu-select" value={num}>qty: {num}</MenuItem>
                                                    ))}
                                                </Select>
                                            </div> : null}
                                        </div>
                                    </ListItem>
                                ))}
                            </List>
                        </div >
                        <hr style={{marginTop: "10px", width: "96%"}}/>
                        {state.trackingNumber || state.showTrackingNumber || props.shipmentStatus === SHIPPED ?
                            <div className="trackingNumberField">
                                <div className="add-tracking-box">
                                    <TextField
                                        className="package-trackingNumber-ref"
                                        disabled={props.shipmentStatus === SHIPPED || props.shipmentStatus === CANCELLED}
                                        variant="outlined"
                                        name="trackingNumber"
                                        label="Tracking Number"
                                        InputLabelProps={{ shrink: true }}
                                        value={state.trackingNumber}
                                        onFocus={props.handleFocus}
                                        onChange={handlePackageChange}
                                        onBlur={(e) => props.handleSave(e, true, props.index)}
                                    />
                                    {props.shipmentStatus === UNSHIPPED || props.shipmentStatus === CLONED ?
                                        state.deleteDialogOpen ?
                                            <div align="left" style={{ paddingTop: 18 }}>
                                                <Button
                                                    onClick={cancelDeleteDialog}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    onClick={closeDeleteDialog}
                                                >
                                                    Delete
                                                </Button>
                                            </div>
                                            :
                                            <Tooltip title={DELETE_TRACKING_NUMBER} style={{ marginLeft: 10 }}>
                                                <IconButton aria-label={DELETE_TRACKING_NUMBER}
                                                    onClick={openDeleteDialog}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        : null
                                    }
                                </div>
                                {props.order?.rate && props.order?.rate.length !== 0 ?
                                    <div className="package-rate-tracking-open">
                                        <p>{"Rate - $" + props.order.rate + " " + props.order.currency}</p>
                                    </div>: null}
                            </div>
                            :
                            props.shipmentStatus === UNSHIPPED || props.shipmentStatus === CLONED ?
                                <>
                                <div className="row-spec">
                                    <div className="add-tracking-box">
                                        <Button variant='contained' color='primary' onClick={addTrackingNumber}
                                            style={{ marginLeft: 10 }}>
                                            {ADD_TRACKING_NUMBER}
                                        </Button>
                                    </div>
                                    {props.order?.rate && props.order?.rate.length !== 0 ?
                                        <div className="package-rate">
                                            <p>{"Rate - $" + props.order.rate + " " + props.order.currency}</p>
                                        </div>: null}
                                </div></>
                                : null
                        }
                        {(props.shipmentStatus === UNSHIPPED || props.shipmentStatus === CLONED) && <div>
                            {/*<hr />*/}
                            {/*<div className="row-spec" style={{paddingTop: "10px"}}>*/}
                            <Box borderRadius="borderRadius" {...defaultProps} style={{ marginRight: '20px', height: 'auto' }}>
                                <Typography variant="h6" gutterBottom component="div" className="suggested-box-type-header">
                                    Suggested Box Type
                                    <hr style={{marginTop: "10px"}}/>
                                </Typography>
                                {/*<FormControl component="fieldset" style={{width: "60%"}}>*/}
                                <div className="box-suggestion-flexbox">
                                    {props.suggestedBoxTypes?.success && props.suggestedBoxTypes.suggestions.length > 0 ?
                                        <RadioGroup name="suggestedBoxes" value={state.selectedSuggestedBox}>
                                            {props.suggestedBoxTypes.suggestions.slice(0, 1)?.map((box, index) => (
                                                <div key={index} style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                                    <FormControlLabel
                                                        value={"Box/" + box.shortcode + " (" + box.size + ")"}
                                                        control={<GreenRadio onClick={(e) => handleSuggestedSelect(e, box.size)} />}
                                                        label={"Box/" + box.shortcode + " (" + box.size + ")"}
                                                        style={{ margin: 0, width: "100%", paddingRight: "150px" }}
                                                    />
                                                    <Button
                                                        variant='contained'
                                                        color='primary'
                                                        onClick={(e) => ignoreSuggestion(box)}
                                                        style={{minWidth: "95px"}}
                                                    >
                                                        Ignore
                                                    </Button>
                                                </div>
                                            ))}
                                        </RadioGroup> :
                                        <div style={{color: "red", paddingLeft: "10px"}}>{props.suggestedBoxTypes?.message ?? "No suggested box types"}</div>}
                                        <div style={{display: "flex", alignItems: "center", marginBottom: "0.5rem", height: "42px", paddingLeft: "15px" }}>
                                            {/*<FormLabel component="legend" style={{margin: 0}}>Suggested Box Type</FormLabel>*/}
                                            <div style={{width: "100%"}}/>
                                            {props.suggestedBoxTypes?.success && props.suggestedBoxTypes.suggestions.length > 0 && <Button
                                                variant='outlined'
                                                color='primary'
                                                onClick={(e) => resetSuggestion()}
                                                style={{minWidth: "95px"}}
                                            >
                                                Reset
                                            </Button>}
                                        </div>
                                {/*</FormControl>*/}
                                </div>
                            </Box>
                        </div>}
                        {props.orderInfo.fromAccount.fromAddressCountry && props.orderInfo.shippingAddressCountry && props.orderInfo.fromAccount.fromAddressCountry != props.orderInfo.shippingAddressCountry ?
                            <>
                                <ShipmentFormInternationalDialogue
                                    toggleCommercialInvoiceView={props.toggleCommercialInvoiceView}
                                    getSampleCommercialInvoicePdf={props.getSampleCommercialInvoicePdf}
                                    packageId={props.value + 1}
                                    commericialInvoice={props.commercialInvoice}
                                    getShipmentCommercialInvoice={props.getShipmentCommercialInvoice}
                                    shipmentId={props?.shipmentId}
                                    handleAutocompleteSave={props.handleAutocompleteSave}
                                    handleAutocompleteChange={props.handleAutocompleteChange}
                                    orderInfo={props.orderInfo}
                                    handleCheck={props.handleCheck}
                                    commodityEditing={props.commodityEditing}
                                    romoveCommodity={props.romoveCommodity}
                                    handleFocus={props.handleFocus}
                                    isCreateShip={props.isCreateShip}
                                    handleChange={props.handleChange}
                                    handleSave={props.handleSave}
                                    addCommodity={props.addCommodity}
                                    shipmentStatus={props.shipmentStatus}
                                    isShipmentReady={props.isShipmentReady}
                                />
                            </>
                            : null}
                    </div>

                }
            </div >
        </div >
    )

}

ItemDetails.propTypes = {
    shipmentId: PropTypes.string,
    value: PropTypes.number,
    index: PropTypes.number,
    order: PropTypes.object,
    isCreateShip: PropTypes.bool,
    filterCarrierService: PropTypes.func,
    carrierServices: PropTypes.array,
    handleChange: PropTypes.func,
    handleFocus: PropTypes.func,
    handleSave: PropTypes.func,
    editOrder: PropTypes.func,
    suggestedBoxTypes: PropTypes.array,
    boxes: PropTypes.array
}

ItemDetails.defaultProps = {
    shipmentId: "",
    value: 0,
    index: 0,
    order: {},
    isCreateShip: false,
    filterCarrierService: () => { },
    carrierServices: [],
    handleChange: () => { },
    handleFocus: () => { },
    handleSave: () => { },
    editOrder: () => { },
    suggestedBoxTypes: [],
    boxes: []
}

export default ItemDetails;