import React, { Component } from 'react';
import Alerts from "../alerts/Alerts";

import "./Header.css";
import PropTypes from "prop-types";
import FileUpload from "../global/FileUpload";
import MenuIcon from '@material-ui/icons/Menu';
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import { IconButton, Tooltip } from "@material-ui/core";
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { isAuthenticatedSelector, userInfoSelector } from "../../redux/selectors/auth";
import { showingMenuItemsSelector } from "../../redux/selectors/global";
import { authLogout } from "../../redux/actions/auth";
import withShipment from "../../withShipment";
import { hideMenuItems, showMenuItems } from "../../redux/actions/global";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

import { AccountCircle, DoubleArrow } from "@material-ui/icons";
import Divider from "@material-ui/core/Divider";
import "../alerts/AlertsDrawer.css";
import Drawer from "@material-ui/core/Drawer";
import { getRoutes, getSubmenuRoutes, protectedRoutes, routes } from '../../routes';
import LeftMenu from '../leftmenu/LeftMenu';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showingProfileDropdown: false
        };
        this.anchorRef = React.createRef();
        this.prevOpen = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.showingProfileDropdown !== this.state.showingProfileDropdown) {
            if (this.prevOpen.current === true && this.state.showingProfileDropdown === false) {
                this.anchorRef.current.focus();
            }
            this.prevOpen.current = this.state.showingProfileDropdown;
        }
    }

    handleMenuBarToggle = () => {
        const menuOpen = this.props.showingMenuItems
        menuOpen ? this.props.hideMenuItems() : this.props.showMenuItems()
    }

    handleProfileDropdownOpen = () => {
        this.setState({
            showingProfileDropdown: true
        })
    }

    handleProfileDropdownClose = (e) => {
        if (this.anchorRef.current && this.anchorRef.current.contains(e.target)) {
            return;
        }
        this.setState({
            showingProfileDropdown: false
        })
    }

    render() {
        return (
            <nav className='navbar sticky-top navbar-dark  top-nav'>
                <div className="top-nav">
                    <div className='menu-toggle-name'>
                        <IconButton onClick={this.handleMenuBarToggle}>
                            <MenuIcon color='secondary' />
                        </IconButton>
                        <a className='navbar-brand' href='#'>
                            Firebolt
                        </a>
                    </div>

                    {window.screen.width <= 800 &&
                        <div>
                            <Drawer anchor='left' open={this.props.showingMenuItems}
                                onClose={this.props.hideMenuItems} className="menu-drawer">
                                <div>
                                    <div className="drawer-header">
                                        <div className="menu-drawer-header-text">
                                            Firebolt
                                        </div>
                                        <div className="drawer-header-close-icon">
                                            <Tooltip title={'Close'}>
                                                <IconButton
                                                    aria-label='close'
                                                    onClick={this.props.hideMenuItems}
                                                >
                                                    <DoubleArrowIcon variant='outlined' className='drawer-close-icon' />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <LeftMenu isDrawer={true} closeDrawer={this.props.hideMenuItems} routes={this.props.routes} userRoutes={this.props.userRoutes} />
                                </div>
                            </Drawer>
                        </div>
                    }

                    <div className="alerts-component-logout">
                        <Alerts />
                        <Tooltip title={this.props.email} placement={"down"}>
                            <IconButton
                                ref={this.anchorRef}
                                aria-label='delete'
                                onClick={this.handleProfileDropdownOpen}

                            >
                                <AccountCircle color='secondary' variant='outlined' fontSize='large' />
                            </IconButton>
                        </Tooltip>

                        <Popper open={this.state.showingProfileDropdown} anchorEl={this.anchorRef.current} role={undefined} transition disablePortal>
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                >
                                    <Paper>
                                        <ClickAwayListener onClickAway={this.handleProfileDropdownClose}>
                                            <MenuList autoFocusItem={this.state.showingProfileDropdown} id="menu-list-grow">
                                                <MenuItem onClick={this.props.logout}>Logout</MenuItem>
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                        {/*<a style={{color: '#fff', cursor: 'pointer'}} className='navbar-text' onClick={this.props.logout}>
                      Logout
                  </a>*/}
                    </div>


                </div>
            </nav>
        );
    }
}

Header.propTypes = {
    logout: PropTypes.func,
    showingMenuItems: PropTypes.bool
}

Header.defaultProps = {
    logout: () => { },
    showingMenuItems: true
}

const mapStateToProps = (state) => ({
    /*userInfo: userInfoSelector(state),
    isAuthenticated: isAuthenticatedSelector(state),*/
    showingMenuItems: showingMenuItemsSelector(state)

})

const actionCreators = {
    showMenuItems,
    hideMenuItems
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, Header);