import React from "react";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";
import {
    CARRIER_REASON_FOR_DECLINE,
    CLAIM_PAGE_NAME,
    CLAIMS_PAGES,
    CURRENCIES,
    STATUS,
    BACK_BUTTON,
    PAYMENT_VERIFIED_PROMPT,
    CLAIM_FILE_INFO,
} from "../constants";
import {TextField, Button} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import "./ClaimsForm.css"
import Divider from "@material-ui/core/Divider";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {CANCEL, getByteArray, SAVE} from "../../global/constants";
import {
    createClaim,
    updateClaim,
    previewClaimFile
} from "../../../redux/actions/claims";
import withShipment from "../../../withShipment";
import FileUpload from "../../global/FileUpload";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DescriptionIcon from "@material-ui/icons/Description";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {Tooltip} from "@mui/material";

class ClaimsForm extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            closeDate: (new Date()).toISOString(),
            trackingNumber: "",
            orderId: "",
            checkNumber:"",
            amountPaid:"",
            currency:"",
            status:"",
            carrierNotes: "",
            notes: "",
            paymentVerified: false,
            files: [],
            newFiles: [],
            deletedFiles: [],
            claimId: "",
            carrier:""
        }
    }

    componentDidMount() {
        if (!this.props.creating && this.props.data) {
            const editingClaim = this.props.data
            this.setState({
                closeDate: editingClaim.closeDate,
                trackingNumber: editingClaim.trackingNumber,
                orderId: editingClaim.orderId,
                checkNumber: editingClaim.checkNumber,
                amountPaid: editingClaim.amountPaid,
                currency: editingClaim.currency,
                status: editingClaim.status,
                carrierNotes: editingClaim.carrierNotes,
                notes: editingClaim.notes,
                paymentVerified: editingClaim.paymentVerified,
                files: editingClaim.files,
                claimId: editingClaim.claimId,
                carrier: editingClaim.carrier
            })
        }
    }

    handleDateChange = (date) => {
        this.setState({
            closeDate: date
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSave = () => {
        this.setState({
            files:[...this.state.files, ...this.state.newFiles]
        }, () => {
            this.setState({
                newFiles:[]
            })
        if (this.props.creating) {
            this.props.createClaim(this.state, () => this.props.setCurrentPage(CLAIMS_PAGES.TABLE))
        } else {
            let info = this.state
            info.id = this.props.data.claimId
            this.props.updateClaim(this.state, () => this.props.setCurrentPage(CLAIMS_PAGES.TABLE))
        }})
    }

    getFormattedGeneralFileObject = (file) => {
        // Extract numbers from filenames
        const numbers = this.state.files.map(file => {
            const match = file.displayName.match(/\((\d+)\)/);
            return match ? parseInt(match[1], 10) : 0;
        });

        // Find the maximum number
        const maxNumber = Math.max(...numbers);
        return {
            localURL: URL.createObjectURL(file),
            displayName: this.state.trackingNumber+"("+(maxNumber+1)+").pdf",
            byteArray: getByteArray(file),
            type: file.type,
        }
    }

    handleGeneralFileAdd = (file) => {
        let newFile = this.getFormattedGeneralFileObject(file);
        this.setState({
            newFiles: [...this.state.newFiles, newFile],
        });
    };

    handleGeneralFileDelete = (file, index) => {
        const { files, deletedFiles, newFiles } = this.state;
        let deletedFile = files[index];
        if (!("byteArray" in file)) {
            this.setState({
                deletedFiles: [...deletedFiles, deletedFile],
            });
        }
        else {
            newFiles.splice(index, 1)
        }
        if ("localURL" in file) {
            URL.revokeObjectURL(file.localURL);
        }
        this.setState({
            files: files.filter((_, i) => i !== index),
        });
    };

    handleClaimFilePreview = (file) => {
        this.props.previewClaimFile({
            displayName: file.displayName,
            orderId: this.state.orderId,
            claimId: this.state.claimId
        }, () => this.props.setCurrentPage(CLAIMS_PAGES.PREVIEW, this.state.claimId, file.displayName))
    }

    render() {
        return (
            <React.Fragment>
                <TopToolbar
                    pageName={CLAIM_PAGE_NAME}
                    menuItems={[
                        {title: BACK_BUTTON, icon: <KeyboardBackspaceIcon/>, onClick: () => {this.props.setCurrentPage(CLAIMS_PAGES.TABLE)}}
                    ]}
                />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div className="return-form-box">
                        <div className='return-from-input-box'>
                            <div style={{width: "55%"}}>
                                <div className='return-row-flexbox'>
                                    <KeyboardDatePicker
                                        error={this.state.closeDate === null || this.state.closeDate === 'Invalid Date'}
                                        autoOk
                                        variant="inline"
                                        inputVariant="outlined"
                                        label="Close Date"
                                        format="yyyy/MM/dd"
                                        className='date-fields'
                                        value={this.state.closeDate}
                                        InputAdornmentProps={{ position: "start" }}
                                        onChange={date => this.handleDateChange(date)}
                                        required
                                    />
                                    <TextField
                                        className='text-field'
                                        variant="outlined"
                                        name="trackingNumber"
                                        value={this.state.trackingNumber}
                                        onChange={this.handleChange}
                                        label="Tracking Number"
                                        InputLabelProps={{ shrink: true }}
                                        required
                                    />
                                    <TextField
                                        className='text-field'
                                        variant="outlined"
                                        name="orderId"
                                        value={this.state.orderId}
                                        onChange={this.handleChange}
                                        label="Order ID"
                                        InputLabelProps={{ shrink: true }}
                                        required
                                    />
                                </div>

                                <div className="return-row-flexbox">
                                    <TextField
                                        className='text-field'
                                        variant="outlined"
                                        name="checkNumber"
                                        value={this.state.checkNumber}
                                        onChange={this.handleChange}
                                        label="Check Number"
                                        InputLabelProps={{ shrink: true }}
                                        required
                                    />
                                    <TextField
                                        className='text-field'
                                        variant="outlined"
                                        name="amountPaid"
                                        value={this.state.amountPaid}
                                        onChange={this.handleChange}
                                        label="Amount Paid"
                                        InputLabelProps={{ shrink: true }}
                                        required
                                    />
                                    <Autocomplete
                                        options={CURRENCIES}
                                        getOptionLabel={(option)=>option}
                                        className={'text-field'}
                                        name="currency"
                                        value={this.state.currency}
                                        onChange={(event,value) => {
                                            this.setState({
                                                currency: value ?? ""
                                            })
                                        }}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                label="Currency"
                                                InputLabelProps={{ shrink: true }}
                                                variant="outlined"
                                                name="currency"
                                            />
                                        }
                                    />
                                </div>
                                <div className="row-spec">
                                    <Autocomplete
                                        options={STATUS}
                                        getOptionLabel={(option)=>option}
                                        className={'input-spec-commodity'}
                                        name="status"
                                        value={this.state.status}
                                        onChange={(event,value) => {
                                            this.setState({
                                                status: value ?? ""
                                            })
                                        }}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                label="Status"
                                                InputLabelProps={{ shrink: true }}
                                                variant="outlined"
                                                name="status"
                                            />
                                        }
                                    />
                                    <Autocomplete
                                        options={this.props.carriers}
                                        getOptionLabel={(option)=>option}
                                        className={'input-spec-two-col'}
                                        name="carrier"
                                        value={this.state.carrier}
                                        onChange={(event,value) => {
                                            this.setState({
                                                carrier: value ?? ""
                                            })
                                        }}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                label="Carrier"
                                                InputLabelProps={{ shrink: true }}
                                                variant="outlined"
                                                name="carrier"
                                            />
                                        }
                                    />
                                </div>
                                <Divider className='section-divider' style={{marginLeft: "10px"}} />
                                <div className="notesField">
                                    <TextField
                                        variant="outlined"
                                        name="carrierNotes"
                                        value={this.state.carrierNotes}
                                        onChange={this.handleChange}
                                        label="Carrier Notes"
                                        style={{ width: "inherit" }}
                                    />
                                </div>
                                <div className="notesField">
                                    <TextField
                                        variant="outlined"
                                        name="notes"
                                        value={this.state.notes}
                                        onChange={this.handleChange}
                                        label="Notes"
                                        style={{ width: "inherit" }}
                                    />
                                </div>
                                <div className="row-spec">
                                    <FormControlLabel
                                        style={{margin: 10, width: 225, whiteSpace: 'nowrap'}}
                                        control={<Checkbox
                                            checked={this.state.paymentVerified}
                                            onChange={(e) => this.setState({ paymentVerified: e.target.checked })}
                                        />}
                                        label={PAYMENT_VERIFIED_PROMPT}
                                    />
                                </div>
                                <Divider className='section-divider' style={{marginLeft: "10px"}} />
                                <Typography variant='h5' style={{marginLeft: "10px"}}>
                                    Attachments
                                </Typography>

                                <div className='ticket-form-input-box'>
                                    <div>
                                        <div className='ticket-row-flexbox'>
                                            <div className='row-empty-box' />
                                            <div className='claim-text-field'>
                                                <FileUpload
                                                    handleFileAdd={this.handleGeneralFileAdd}
                                                    handleFileDelete={this.handleGeneralFileDelete}
                                                    files={this.state.files ? this.state.files.concat(this.state.newFiles) : []}
                                                    singleFileUpload={false}
                                                    fetchFilePreview={(file) => this.handleClaimFilePreview(file)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Divider style={{marginTop: "1%", marginBottom: "1%", marginLeft: "1%", width: "98%"}} />
                                <div className='save-cancel-buttons'>
                                    <div>
                                        <Button
                                            variant='contained'
                                            onClick={() => this.props.setCurrentPage(CLAIMS_PAGES.TABLE)}
                                        >
                                            {CANCEL}
                                        </Button>
                                    </div>
                                    <div className='save-spacing' />
                                    <div>
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            onClick={this.handleSave}
                                        >
                                            {SAVE}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </MuiPickersUtilsProvider>
            </React.Fragment>
        )
    }
}

const actionCreators = {
    createClaim,
    updateClaim,
    previewClaimFile
}

export default withShipment({
    actionCreators
}, ClaimsForm);