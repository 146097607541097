
/*  This object was originally designed to work with TablePage, but it can be used independently too.
    It creates a series of rows that contain input fields of various types.

    Example code taken from InventoryFiltersDialog.jsx:
        constructor(props) {
            super(props);
            this.state = {
                filterDialogCreator: this.getFilterDialogCreator(),
                filterDialog: <></>
            };

            this.state.filterDialog = this.getFilterDialog()
        }

        componentDidUpdate(prevProps, prevState, snapshot) {

            if (prevProps.fromDate !== this.props.fromDate ||
                prevProps.toDate !== this.props.toDate ||
                prevProps.brands !== this.props.brands ||
                prevProps.shortcodes !== this.props.shortcodes ||
                prevProps.actionTypes !== this.props.actionTypes ||
                prevProps.allBrands !== this.props.allBrands ||
                prevProps.allShortcodes !== this.props.allShortcodes ||
                prevProps.allActionTypes !== this.props.allActionTypes) {
                this.setState({
                    filterDialog: this.getFilterDialog()
                })
            }

        }

        getFilterDialogCreator = () => {
            return Layout.newLayout(2, [
                Layout.row([
                    Layout.rowElement(1, "brands", "Brand", Layout.elementStyle.checkboxDropdown, this.props.allBrands),
                    Layout.rowElement(1, "shortcodes", "Shortcode", Layout.elementStyle.checkboxDropdown, this.props.allShortcodes),
                ]),
                Layout.row([
                    Layout.rowElement(2, "actionTypes", "Action Type", Layout.elementStyle.checkboxDropdown, this.props.allActionTypes),
                ]),
                Layout.row([
                    Layout.rowElement(1, "fromDate", "From", Layout.elementStyle.dateSelect),
                    Layout.rowElement(1, "toDate", "To", Layout.elementStyle.dateSelect),
                ]),
            ])
        }

        handleFilterValueChange = (nameValueObj) => {
            this.props.setFilterValue(nameValueObj)
        }

        getFilterValue = (displayName, filterName, elementStyle) => {
            return this.props[filterName] ?? Layout.getDefaultElementValueFromStyle(elementStyle)
        }

        getFilterDialog = () => {
            return this.state.filterDialogCreator(this.handleFilterValueChange, this.getFilterValue)
        }

        render() {
            return <React.Fragment>
                <div className='horizontally-center'>
                    {Layout.titleRow(2, "Filters", "Clear Filters", () => {this.props.clearFilters()})}
                    {this.state.filterDialog.errorMessage === "" ? this.state.filterDialog.content : this.state.filterDialog.errorMessage}
                    {Layout.cancelSaveRow(2, () => {this.props.cancelFilters()}, () => {this.props.applyFilters()})}
                </div>
            </React.Fragment>
        }
 */





import React from 'react';
import './LayoutObject.css';
import {Checkbox, DialogTitle, TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {APPLY, CANCEL} from "../../constants";
import ClearIcon from "@material-ui/icons/Clear";
import {MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker} from "@material-ui/pickers";
import Chip from "@material-ui/core/Chip";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import {Autocomplete} from "@material-ui/lab";
import DateFnsUtils from "@date-io/date-fns";


const WIDTH_COEFFICIENT = 300
const WIDTH_MARGIN = 10

export const Layout = {

    newLayout: (rowWidth, rows, widthIsFlexible = false) => {
        return (onElementChange, getElementValue) => {

            if (!rows)
                return {errorMessage: "No rows provided", width: 0, content: <></>}

            let rowObjects = rows.map((row) => row(onElementChange, getElementValue, rowWidth, widthIsFlexible))

            // check for too-long row
            let errorMessage = ""
            for (let i = 0; i < rowObjects.length; i++) {
                if (rowObjects[i].width > rowWidth) {
                    errorMessage = "Row " + i + " too long for row width " + rowWidth
                }
                if (rowObjects[i].errorMessage !== "") {
                    errorMessage = rowObjects[i].errorMessage
                }
            }

            let content = <div className={widthIsFlexible ? 'layout-container-flexible' : 'layout-container'}>
                {rowObjects.map((row) => row.content)}
            </div>

            return {errorMessage: errorMessage, width: rowWidth, content: content}
        }
    },


    row: (rowElements) => {
        return (onElementChange, getElementValue, rowWidth, widthIsFlexible) => {

            if (!rowElements)
                return {errorMessage: "No row elements provided", width: 0, content: <></>}

            let rowElementObjects = rowElements.map((rowElement) => rowElement(onElementChange, getElementValue, rowWidth, widthIsFlexible))

            let errorMessage = ""
            let width = 0
            for (let i = 0; i < rowElementObjects.length; i++) {
                width += rowElementObjects[i].width
                if (rowElementObjects[i].errorMessage !== "") {
                    errorMessage = rowElementObjects[i].errorMessage
                }
            }

            const WIDTH_PIXELS = (width * WIDTH_COEFFICIENT)

            let content = <div style={widthIsFlexible ? {display: "flex", width: "100%"} : {display: "flex", width: WIDTH_PIXELS + "px"}}>
                {rowElementObjects.map((rowElementObject) => rowElementObject.content)}
            </div>

            return {errorMessage: errorMessage, width: width, content: content}
        }
    },

    rowElement: (width, connectedFilterName, displayName, style, dropdownOptions, disabled = false, required, errorCheck = true, InputProps = null, checkForInvalidDate = true, verticalBorder = false) => {
        // dropdownOptions is an array of strings, and is only used if style = Layout.elementStyle.checkboxDropdown or selectDropdown
        // otherwise, it does not need to be provided

        return (onChange, getElementValue, rowWidth, widthIsFlexible) => {

            if (width <= 0) {
                return {errorMessage: "Row element width <= 0", width: 0, content: <></>}
            }

            const WIDTH_PIXELS = (width * WIDTH_COEFFICIENT)
            const FLEXIBLE_WIDTH = (100 * width / rowWidth)

            let finalContent = <div style={widthIsFlexible ? {width: FLEXIBLE_WIDTH + "%"} : verticalBorder ? {width: WIDTH_PIXELS + "px", borderRight: "1px solid #d3d3d3"} :{width: WIDTH_PIXELS + "px"}}>
                <div style={{margin: WIDTH_MARGIN + "px"}}>
                    {
                        (() => {
                            let result
                            switch (style) {
                                case Layout.elementStyle.textBox:
                                    result = <TextField
                                        style={{width: "100%"}}
                                        label={displayName}
                                        name={connectedFilterName}
                                        InputProps={InputProps}
                                        variant="outlined"
                                        InputLabelProps={{shrink: true}}
                                        onChange={(e) => {onChange({name: e.target.name, value: e.target.value})}}
                                        value={getElementValue(displayName, connectedFilterName)}
                                        disabled={disabled}
                                        required={required}
                                        error={!errorCheck}
                                    />
                                    break
                                case Layout.elementStyle.dateSelect:
                                    result = <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            style={{width: "100%"}}
                                            autoOk
                                            name={connectedFilterName}
                                            label={displayName}
                                            variant="inline"
                                            inputVariant="outlined"
                                            format="yyyy-MM-dd"
                                            InputAdornmentProps={{position: "start"}}
                                            onChange={dateInput => {
                                                let date = new Date(dateInput?.toString())
                                                if (date.toString() === "Invalid Date" && checkForInvalidDate) {
                                                    return
                                                }
                                                onChange({name: connectedFilterName, value: date.toString() !== "Invalid Date" ? date.toISOString() : date})
                                            }}
                                            value={getElementValue(displayName, connectedFilterName, style)}
                                            disabled={disabled}
                                        />
                                    </MuiPickersUtilsProvider>
                                    break
                                case Layout.elementStyle.timeSelect:
                                    result = <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker
                                            style={{width: "100%"}}
                                            autoOk
                                            name={connectedFilterName}
                                            label={displayName}
                                            variant="inline"
                                            inputVariant="outlined"
                                            InputAdornmentProps={{position: "start"}}
                                            onChange={dateInput => {
                                                let date = new Date(dateInput?.toString())
                                                if (date.toString() === "Invalid Date" && checkForInvalidDate) {
                                                    return
                                                }
                                                onChange({name: connectedFilterName, value: date.toString() !== "Invalid Date" ? date.toISOString() : date})
                                            }}
                                            value={getElementValue(displayName, connectedFilterName, style)}
                                            disabled={disabled}
                                            required={required}
                                            error={!errorCheck}
                                        />
                                    </MuiPickersUtilsProvider>
                                    break
                                case Layout.elementStyle.checkboxDropdown:
                                    result = <Autocomplete
                                        style={{width: "100%"}}
                                        multiple
                                        autoHighlight={true}
                                        options={dropdownOptions}
                                        name={connectedFilterName}
                                        getOptionLabel={(entry) => entry}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                label={displayName}
                                                InputLabelProps={{ shrink: true }}
                                                variant="outlined"
                                                required={required}
                                                error={!errorCheck}
                                            />
                                        }
                                        renderTags={(value) =>
                                            value.map((option) => (
                                                <Chip
                                                    variant="outlined"
                                                    size="small"
                                                    style={{borderColor: "#acd685", margin: "1px 6px 1px 0", fontSize: "12px"}}
                                                    label={option}
                                                />
                                            ))
                                        }
                                        renderOption={(option, { selected }) => (
                                            <React.Fragment>
                                                <Checkbox
                                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option}
                                            </React.Fragment>
                                        )}
                                        onChange={(event, newValueStringArray) => {
                                            onChange({name: connectedFilterName, value: newValueStringArray})
                                        }}
                                        value={getElementValue(displayName, connectedFilterName, style) || []}
                                        disabled={disabled}
                                        disableCloseOnSelect
                                    />
                                    break
                                case Layout.elementStyle.selectDropdown:
                                case Layout.elementStyle.textBoxWithSuggestions:
                                    result = <Autocomplete
                                        freeSolo={style === Layout.elementStyle.textBoxWithSuggestions}
                                        style={{width: "100%"}}
                                        autoHighlight={true}
                                        options={dropdownOptions}
                                        name={connectedFilterName}
                                        getOptionLabel={(entry) => entry}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                label={displayName}
                                                InputLabelProps={{ shrink: true }}
                                                variant="outlined"
                                                required={required}
                                                error={required && !getElementValue(displayName, connectedFilterName)}
                                                onChange={(event) => {
                                                    if (style === Layout.elementStyle.textBoxWithSuggestions) {
                                                        onChange({name: connectedFilterName, value: event.target.value})
                                                    }
                                                }}
                                            />
                                        }
                                        renderOption={(option, { selected }) => (
                                            <React.Fragment>
                                                {option}
                                            </React.Fragment>
                                        )}
                                        onChange={(event, newValueString) => {
                                            onChange({name: connectedFilterName, value: newValueString})
                                        }}
                                        value={getElementValue(displayName, connectedFilterName, style)}
                                        disabled={disabled}
                                    />
                                    break
                                case Layout.elementStyle.blankSpacer:
                                    result = <div style={{width: "100%"}} />
                                    break
                            }

                            return result
                        })()
                    }
                </div>
            </div>

            return {errorMessage: "", width: width, content: finalContent}
        }
    },

    checkBox: (connectedFilterName, displayText, customUncheckedIcon = null, customCheckedIcon = null, onChangeCallback = null, invertCheckbox = false) => {

        let createCheckboxMethod = (isChecked, onChange) => {
            return <div className='checkbox-container' style={{verticalAlign : "0%"}}>
                <div className='standard-checkbox-margin-1'>
                    { customUncheckedIcon && customCheckedIcon ?
                        <Checkbox
                            checked={invertCheckbox ? !isChecked : isChecked}
                            onChange={(event) => {
                                onChange()
                                if (onChangeCallback) {
                                    onChangeCallback(invertCheckbox? ! event.target.checked : event.target.checked)
                                }
                            }}
                            icon={customUncheckedIcon}
                            checkedIcon={customCheckedIcon}
                        />
                        :
                        <Checkbox
                            checked={invertCheckbox ? !isChecked : isChecked}
                            onChange={(event) => {
                                onChange()
                                if (onChangeCallback) {
                                    onChangeCallback(invertCheckbox? ! event.target.checked : event.target.checked)
                                }
                            }}
                        />
                    }
                    <div className='vertically-center'>
                        <div style={{fontFamily: "Arial"}}>
                            {displayText}
                        </div>
                    </div>
                </div>
            </div>
        }

        return {
            connectedFilterName: connectedFilterName,
            checkedByDefault: false,
            createCheckbox: createCheckboxMethod
        }
    },

    columnHead: (text, id, alignLeftRightCenter, widthString = null) => {
        // withstring is in a format directly applicatble to style={{width: widthString}}
        // ex. "100px" or "50%"
        if (!alignLeftRightCenter) {
            alignLeftRightCenter = "left"
        }
        return {text: text, id: id, alignLeftRightCenter: alignLeftRightCenter.toLowerCase(), widthString: widthString}
    },

    titleRow: (width, titleText, clearText = "", onClear = null) => {

        const WIDTH_PIXELS = (width * WIDTH_COEFFICIENT)
        const WIDTH_LESS_MARGIN = WIDTH_PIXELS - WIDTH_MARGIN * 2

        return <div className='horizontally-center'>
            <div style={{width: WIDTH_LESS_MARGIN + "px", marginLeft: WIDTH_MARGIN + "px", marginRight: WIDTH_MARGIN + "px"}}>
                <div className='margin-vertical-1'>
                    <div className='full-width-bar'>
                        <div className={onClear ? 'half-row-align-left' : 'full-row-align-center' }>
                            <DialogTitle
                                id="form-dialog-title"
                                style={{margin: '0px', padding: '0px'}}
                            >
                                {titleText}
                            </DialogTitle>
                        </div>
                        {onClear ?
                            <div className='half-row-align-right'>
                                <Button
                                    style={{float: 'right', margin: '0px', padding: '0px'}}
                                    startIcon={<ClearIcon />}
                                    onClick={onClear}
                                >
                                    {clearText}
                                </Button>
                            </div>
                            : null
                        }
                    </div>
                </div>
            </div>
        </div>
    },

    cancelSaveRow: (width, onCancel, onSave, saveDisabled = false, overwriteSaveText = null) => {

        let saveText = overwriteSaveText ? overwriteSaveText : APPLY

        return <div className='margin-vertical-1'>
            <div className='horizontally-center-row'>
                <Button
                    style={{width: "100px"}}
                    variant='contained'
                    onClick={() => onCancel()}
                >
                    {CANCEL}
                </Button>
                <div className='margin-20px'/>
                <Button
                    style={{width: "100px"}}
                    variant='contained'
                    onClick={() => onSave()}
                    color="primary"
                    disabled={saveDisabled}
                >
                    {saveText}
                </Button>
            </div>
        </div>
    },

    elementStyle: {
        textBox: 0,
        dateSelect: 1,
        checkboxDropdown: 2,
        selectDropdown: 3,
        blankSpacer: 4,
        timeSelect: 5,
        textBoxWithSuggestions: 6,
    },

    getDefaultElementValueFromStyle: (elementStyle) => {
        switch (elementStyle) {
            case Layout.elementStyle.textBox:
                return ""
            case Layout.elementStyle.dateSelect:
            case Layout.elementStyle.timeSelect:
                return (new Date()).getTime()
            case Layout.elementStyle.checkboxDropdown:
                return []
            case Layout.elementStyle.selectDropdown:
            case Layout.elementStyle.textBoxWithSuggestions:
                return ""
            default:
                return ""
        }
    }

}










