import React from "react";


// import { TextField } from "material-ui";
import {
    Button, TextField, DialogTitle,
    FormControl, FormHelperText, IconButton,
    MenuItem, Checkbox,
    Select, TableBody, FormGroup, FormLabel, FormControlLabel
} from "@material-ui/core";
import "./AddPackageForm.css";
import { CANCEL, SAVE } from "../../global/constants";
import PropTypes from "prop-types";
import {SELECT_LOCATION_HEADER, SELECT_LOCATION_HELPER_TEXT, WEIGHT_UNITS} from "../ManageProducts/constants";
import {Autocomplete} from "@material-ui/lab";
import {ORIGIN_CRITERION_OPTIONS} from "./constants";

class AddPackageForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shortcode: this.getValue('shortcode'),
            nominalSize: this.getValue('nominalSize'),
            actualSize: this.getValue('actualSize'),
            textDescription: this.getValue('textDescription'),
            manufacturerPartNum: this.getValue('manufacturerPartNum'),
            boxQuantity: this.getValue('boxQuantity'),
            paleteQuantity: this.getValue('paleteQuantity'),
            selectedLocationsMap: {},
            validating: false,
            fluteType: this.getValue('fluteType'),
            boxStrength: this.getValue('boxStrength'),
            locationIds: this.getValue('locations'),
            preLocationIds: this.getValue('preLocationIds'),
            boxId: this.getValue('boxId'),
            weight: this.getValue('weight'),
            weightUnit: this.getValue('weightUnit') ?? "LB",
            selectedCategoryId: this.getValue('categoryId'),
            countryOfManufacture: this.getValue('countryOfManufacture'),
            originCriterion: this.getValue('originCriterion'),
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.categories !== this.props.categories) {
            this.setState({
                selectedCategory: this.getSelectedCategoryId()
            })
        }
    }

    setCategory = (value) => {
        this.setState({
            selectedCategoryId: value?.id ?? ""
        })
    }

    setOriginCriterion = (value) => {
        this.setState({
            originCriterion: value
        })
    }

    setWeightUnit = (value) => {
        this.setState({
            weightUnit: value
        })
    }

    getSelectedCategoryId = () => {
        return this.getSelectedCategory()?.id ?? ""
    }

    getSelectedCategory = () => {
        return this.props.categories?.find(category => category.id === this.state.selectedCategoryId)
    }

    getValue = (key) => {

        if (this.props.creatingProduct && key === "locations") {
            return []
        }

        if (this.props.creatingProduct && key === "preLocationIds") {
            return []
        } else if (key === "preLocationIds") {
            return [...this.props.editingProduct["locations"]]
        }

        if (this.props.creatingProduct)
            return ''


        return this.props.editingProduct[key]
    }

    setCountry = (value) => {
        this.setState({
            countryOfManufacture: value
        })
    }

    handleTextChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    checkSave = () => {
        return (this.state.shortcode == '' || this.state.textDescription == '' || this.state.boxStrength == '' || this.state.fluteType == '' ||
            this.state.manufacturerPartNum == '' || this.state.paleteQuantity == '' ||
            this.state.actualSize == '' || this.state.nominalSize == '')
    }

    save = () => {
        this.props.handleSave(this.props.creatingProduct, this.state)
    }

    handleLocationSelect = (e, location) => {
        let shipAddressId = location.shipAddressId
        let newLocationIds = []

        if (!e.target.checked) {
            this.state.locationIds.forEach(locationId => {
                if (locationId !== shipAddressId) {
                    newLocationIds.push(locationId)
                }
            })
        } else {
            newLocationIds = this.state.locationIds
            newLocationIds.push(shipAddressId)
        }

        this.setState({
            locationIds: newLocationIds
        })
    }

    render() {
        return (
            <div className="package-fields-container">
                <div>
                    <div className="row-flexbox">
                        <TextField
                            className='input-text-field'
                            // error={this.checkShortcode()}
                            // helperText={this.checkShortcode() ? "Duplicated shortcode" : this.editedShortcode()? "Rename inventory and return": null}
                            variant='outlined'
                            label="Shortcode"
                            InputLabelProps={{ shrink: true }}
                            name='shortcode'
                            value={this.state.shortcode}
                            onChange={this.handleTextChange}
                            required
                        />
                        <TextField
                            className='input-text-field'
                            variant='outlined'
                            label="Nominal Size (LxWxH)"
                            InputLabelProps={{ shrink: true }}
                            name='nominalSize'
                            value={this.state.nominalSize}
                            onChange={this.handleTextChange}
                            required
                        />

                        <TextField
                            className='input-text-field'
                            variant='outlined'
                            label="Actual Size (LxWxH)"
                            InputLabelProps={{ shrink: true }}
                            name='actualSize'
                            value={this.state.actualSize}
                            onChange={this.handleTextChange}
                            required
                        />
                    </div>
                    <div className='row-flexbox'>
                        <TextField
                            className='package-single-line-field'
                            variant='outlined'
                            label="Description"
                            InputLabelProps={{ shrink: true }}
                            name='textDescription'
                            value={this.state.textDescription}
                            onChange={this.handleTextChange}
                            required
                        />
                    </div>
                    <div className='row-flexbox'>
                        <TextField
                            className='input-text-field'
                            variant='outlined'
                            label="Manufacturer Part Number"
                            InputLabelProps={{ shrink: true }}
                            name='manufacturerPartNum'
                            value={this.state.manufacturerPartNum}
                            onChange={this.handleTextChange}
                            required
                        />
                        <TextField
                            className='input-text-field'
                            variant='outlined'
                            label="Flute Type"
                            InputLabelProps={{ shrink: true }}
                            name='fluteType'
                            value={this.state.fluteType}
                            onChange={this.handleTextChange}
                            required
                        />
                        <TextField
                            className='input-text-field'
                            variant='outlined'
                            label="Box Strength"
                            InputLabelProps={{ shrink: true }}
                            name='boxStrength'
                            value={this.state.boxStrength}
                            onChange={this.handleTextChange}
                            type="number"
                            required
                        />
                    </div>
                    <div className='row-flexbox'>
                        <TextField
                            className='input-text-field'
                            variant='outlined'
                            label="Pallet Quantity"
                            InputLabelProps={{ shrink: true }}
                            name='paleteQuantity'
                            value={this.state.paleteQuantity}
                            onChange={this.handleTextChange}
                            type="number"
                            required
                        />
                        <div className="input-text-field"></div>
                        <div className="input-text-field"></div>
                    </div>
                    <div className="row-flexbox">
                        <Autocomplete
                            className={'input-text-field'}
                            options={this.props.categories}
                            getOptionLabel={(entry) => entry.category}
                            autoHighlight={true}
                            onChange={(event, value) => {
                                this.setCategory(value)
                            }}
                            value={this.getSelectedCategory()}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="Select Category"
                                    InputLabelProps={{shrink: true}}
                                    variant="outlined"
                                    name="selectCategory"
                                />}
                        />
                        <Autocomplete
                            className={'input-text-field'}
                            options={Object.values(this.props.countries)}
                            value={this.state.countryOfManufacture}
                            onChange={(event, value) => {
                                this.setCountry(value)
                            }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="Country of Origin"
                                    InputLabelProps={{shrink: true}}
                                    variant="outlined"
                                    name="countryOfOrigin"
                                />
                            }
                        />
                        <Autocomplete
                            className={'input-text-field'}
                            options={ORIGIN_CRITERION_OPTIONS}
                            getOptionLabel={(entry) => entry}
                            autoHighlight={true}
                            onChange={(event, value) => {
                                this.setOriginCriterion(value)
                            }}
                            value={this.state.originCriterion}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="Origin Criterion"
                                    InputLabelProps={{shrink: true}}
                                    variant="outlined"
                                    name="originCriterion"
                                />}
                        />
                    </div>
                    <div className="row-flexbox">
                        <TextField
                            className='input-weight'
                            variant='outlined'
                            label="Weight"
                            InputLabelProps={{ shrink: true }}
                            name='weight'
                            value={this.state.weight}
                            onChange={this.handleTextChange}
                        />
                        <Autocomplete
                            className='input-weight-unit'
                            options={WEIGHT_UNITS}
                            value={this.state.weightUnit}
                            onChange={(event, value) => {
                                this.setWeightUnit(value)
                            }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="Weight Unit"
                                    InputLabelProps={{shrink: true}}
                                    variant="outlined"
                                    name="weight"
                                />
                            }
                        />
                    </div>
                    <div className='row-flexbox'>
                        <div className='product-single-line-field'>
                            <FormLabel component="legend">{SELECT_LOCATION_HEADER}</FormLabel>
                            <FormHelperText>{SELECT_LOCATION_HELPER_TEXT}</FormHelperText>
                            <FormGroup>
                                {this.props.locations.map((location) => {
                                    const checked = this.state.locationIds.includes(location.shipAddressId)
                                    const preChecked = this.state.preLocationIds.includes(location.shipAddressId)
                                    const getRemoveText = () => {
                                        return !checked && checked != preChecked ? " Note: Removing the location will also delete the existing inventory for that location." : ""
                                    }
                                    return (
                                        <>
                                            <div
                                                style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                }}
                                            >
                                                <Checkbox checked={checked} onChange={(e) => this.handleLocationSelect(e, location)} name="location" color='primary' />
                                                <div
                                                    style={{
                                                        marginTop: "auto",
                                                        marginBottom: "auto",
                                                    }}
                                                >
                                                    <div
                                                        className="MuiTypography-body1"
                                                    >
                                                        {location.name}
                                                    </div>
                                                </div>
                                            </div>
                                            <div>{getRemoveText()}</div>
                                        </>
                                    )
                                })}
                            </FormGroup>
                        </div>
                    </div>
                    <div className='buttons-row'>
                        <div className='input-text-field cancel-save-buttons'>
                            <Button
                                variant='contained'
                                onClick={this.props.handleCancel}
                            >
                                {CANCEL}
                            </Button>
                            <div className='button-spacing' />
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={this.save}
                                disabled={this.checkSave()}
                            >
                                {SAVE}
                            </Button>


                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

AddPackageForm.propTypes = {
    packages: PropTypes.array,
    editingPackage: PropTypes.object,
    creatingPackage: PropTypes.bool,
    locations: PropTypes.array

}

AddPackageForm.defaultProps = {
    packages: [],
    editingPackage: {},
    creatingPackage: true,
    locations: []
}

export default AddPackageForm;