import React from "react";

// constants
import {
    ACCOUNT_TYPE_OPTIONS,
    ADD_BANK_ACCOUNT_TOOLTIP,
    BACK,
    CURRENCY_TYPE_OPTIONS,
    EDIT_BANK_ACCOUNT_TOOLTIP,
    SAVE_ACCOUNT,
} from "./constants";


import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";

import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import SaveIcon from "@material-ui/icons/Save";
import {TextField, Typography} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import PhoneIcon from "@material-ui/icons/Phone";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {getByteArray} from "../../global/constants";
import Divider from "@material-ui/core/Divider";
import { IconButton, Tooltip} from "@mui/material";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Chip from "@material-ui/core/Chip";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

class BankAccountForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chequeNumber :  this.getValue('chequeNumber'),
            bankFullName :  this.getValue('bankFullName'),
            name :  this.getValue('name'),
            phone : this.getValue('phone'),
            addressLine1 : this.getValue('addressLine1'),
            addressLine2 : this.getValue('addressLine2'),
            city : this.getValue('city'),
            country : this.getValue('country'),
            zip : this.getValue('zip'),
            state : this.getValue('state'),
            prefix : this.getValue('prefix'),
            currency : this.getValue('currency'),
            accountNumber : this.getValue('accountNumber'),
            institutionNumber : this.getValue('institutionNumber'),
            transitNumber : this.getValue('transitNumber'),
            accountType : this.getValue('accountType'),
            designationNumber : this.getValue('designationNumber'),
            files: this.getValue('files'),
            requiredList: [
                 "name",
                // "phone",
                // "addressLine1",
                // "city",
                // "state",
                // "zip",
                // "country",
                 "accountNumber",
                // "institutionNumber",
                // "designationNumber",
                // "transitNumber",
                // "currency",
                "accountType",
            ],
            label: this.getValue1('label'),
            type: this.getValue1('type'),
            newFiles: [],
            deletedFiles: [],
            editBankAccount: this.props.editBankAccount,
            showingPreviews: false,
            previewIndex: 0,
            fetchingPreviews: [],
        }
    }

    componentDidMount() {
        this.setState({
            fetchingPreviews: Array(this.state.files.length).fill(0)
        });
    }

    fetchFilePreview = (file) => {
        const { previewIndex, fetchingPreviews } = this.state;

        if (fetchingPreviews[previewIndex] === 0) {
            let currentFetchingPreviews = [...fetchingPreviews];
            currentFetchingPreviews[previewIndex] += 1;
            this.setState({ fetchingPreviews: currentFetchingPreviews }, () => {
                if (this.state.fetchingPreviews[previewIndex] === 1) {
                    this.fetchFilePrevieww(file);
                }
            });
        }

        return <CircularProgress />;
    };


    getFilePreview = (file) => {
        if ("localURL" in file) {
            return (
                <div className='image-outline'>
                    <a href={file.localURL} download={file.displayName}>
                        <img src={file.localURL} className='image' alt="signature"/>
                    </a>
                </div>
            );
        }
        return this.fetchFilePreview(file);
    };


    iterateFiles = (left) => {
        let curIndex = this.state.previewIndex;
        if (((curIndex === this.state.files.length - 1) && !left) || (curIndex === 0 && left))
            return;

        if (left)
            curIndex -= 1;
        else
            curIndex += 1;

        this.setState({ previewIndex: curIndex });
    }


    getValue1 = (key)=>{
        return ''
    }
    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        });
    };

    isHandleSave = () => {
        const { requiredList,files, accountType, ...fields } = this.state;
        let hasEmpty = false;

        requiredList.forEach(field => {
            if (!fields[field]) {
                hasEmpty = true;
            }
        });

        if (accountType === "Chequing") {
            if (!files || files.length === 0) {
                hasEmpty = false;
            } else {
                hasEmpty = files.some(file => !file.signatoryName);
            }
        } else {
            hasEmpty = false;
        }


        return hasEmpty;
    };

    getValue = (key) => {
        if (!this.props.editBankAccount) {
            return key === 'files' ? [] : '';
        }

        const value = this.props.editBankAccount[key];
        if (value === undefined || value === null || value === '') {
            return key === 'files' ? [] : '';
        }

        return value;
    }


    handleSaveAccount = () =>{
        const {
            chequeNumber,
            bankFullName,
            name,
            prefix,
            phone,
            addressLine1,
            addressLine2,
            city,
            state,
            zip,
            country,
            accountNumber,
            institutionNumber,
            designationNumber,
            transitNumber,
            currency,
            files,
            newFiles,
            deletedFiles,
            accountType} = this.state;
        const bankAddress = {
            chequeNumber,
            bankFullName,
            bankAccountId : this.getValue('bankAccountId'),
            name,
            prefix,
            phone,
            addressLine1,
            addressLine2,
            city,
            state,
            zip,
            country,
            accountNumber,
            institutionNumber,
            designationNumber,
            transitNumber,
            currency,
            files,
            newFiles,
            deletedFiles,
            accountType }

        this.props.saveBankAccount(bankAddress)
        this.props.openFeedbackBar()
        this.props.handleEditingClear()
    }

    handleTemplateChange = (e, index) => {
        const { name, value } = e.target;
        this.setState(prevState => {
            const updatedFiles = [...prevState.files];
            updatedFiles[index] = {
                ...updatedFiles[index],
                [name]: value,
            };

            const updatedNewFiles = [...prevState.newFiles];
            const newFileIndex = updatedNewFiles.findIndex(file => file.localURL === updatedFiles[index].localURL);
            if (newFileIndex !== -1) {
                updatedNewFiles[newFileIndex] = {
                    ...updatedNewFiles[newFileIndex],
                    [name]: value,
                };
            }

            return {
                files: updatedFiles,
                newFiles: updatedNewFiles
            };
        });
    };

    removeTemplateRow = (file,index) => {
       this.handleFileDelete(file, index)
        if (index === this.state.previewIndex && this.state.files.length > 0) {
            this.setState({
                previewIndex: Math.max(0, this.state.previewIndex - 1)
            });
        }
    }

    handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            this.handleFileAdd(file);
        }
    };

    handleFileAdd = (file) => {
        const { newFiles, files } = this.state

        let newFile = this.getFormattedFileObject(file)
        files.push(newFile)
        newFiles.push(newFile)
        this.setState({
            newFiles: newFiles,
            files: files,
            previewIndex: files.length - 1
        });
    }

    getFormattedFileObject = (file) => {
        return { localURL: URL.createObjectURL(file), displayName: file.name, type: file.type, byteArray: getByteArray(file) }
    }


    handleFileDelete = (file, index) => {
        this.setState(prevState => {
            const updatedTemplates = prevState.files.filter((_, curIndex) => curIndex !== index);
            return { files: updatedTemplates }
        },()=>{

            const { files, deletedFiles, newFiles } = this.state
            if (!("byteArray" in file)) {
                deletedFiles.push(file)
            }
            else {
                newFiles.splice(index, 1)
            }
            if ("localURL" in file) {
                this.freeFromMemory(file.localURL)
            }

            this.setState({
                files: files,
                deletedFiles: deletedFiles,
                newFiles: newFiles
            })
        });

    }


    freeFromMemory = (url) => {
        URL.revokeObjectURL(url)
    }

    fetchFilePrevieww = (file) => {
        this.props.previewBankFile({
            path: file.path,
            accountNumber: this.state.accountNumber
        });
    };

    handleFilePreview = (index) => {
        this.setState({
            showingPreviews: true,
            previewIndex: index
        })
    }

    handlePreviewClose = () => {
        this.setState({
            showingPreviews: false
        })
    }

    render() {

        const { previewIndex, files} = this.state;
        const currentFile = files[previewIndex];

        return (
            <React.Fragment>
                {/*<ErrorFeedbackBar/>*/}
                <TopToolbar
                    pageName={this.state.accountNumber ? EDIT_BANK_ACCOUNT_TOOLTIP : ADD_BANK_ACCOUNT_TOOLTIP}
                    menuItems={[
                        {
                            title: BACK,
                            icon: <KeyboardBackspaceIcon/>,
                            onClick: () => {
                                this.props.handleEditingClear()
                            }
                        },
                        {
                            title: SAVE_ACCOUNT,
                            icon: <SaveIcon/>,
                            disabled: this.isHandleSave(),
                            onClick: () => this.handleSaveAccount()
                        }
                    ]}
                />

                {this.state.showingPreviews && (

                    <Dialog open={this.state.showingPreviews} onClose={this.handlePreviewClose} maxWidth='md'>
                        <DialogTitle className='file-preview-header'>{currentFile?.displayName}</DialogTitle>
                        <DialogContent className='file-preview-content'>
                            <div className="file-preview-btn-box">
                                <Tooltip title="Previous">
                                    <IconButton
                                        aria-label='previous'
                                        onClick={() => this.iterateFiles(true)}
                                        disabled={previewIndex === 0}
                                        className="file-preview-btn"
                                    >
                                        <ChevronLeftIcon variant='outlined' fontSize='large' />
                                    </IconButton>
                                </Tooltip>
                            </div>

                            {this.getFilePreview(currentFile)}

                            <div className="file-preview-btn-box">
                                <Tooltip title="Next">
                                    <IconButton
                                        aria-label='next'
                                        onClick={() => this.iterateFiles(false)}
                                        disabled={previewIndex === files.length - 1}
                                        className="file-preview-btn"
                                    >
                                        <ChevronRightIcon variant='outlined' fontSize='large' />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </DialogContent>
                    </Dialog>
                )}

                <div className='currencyStyle'>
                    <div>
                        <div className='rowSpecSuppliers'>
                            <TextField
                                className='input-text-field'
                                variant='outlined'
                                label="Nick Name"
                                InputLabelProps={{shrink: true}}
                                name='name'
                                value={this.state.name}
                                onChange={this.handleChange}
                                required={this.state.requiredList.includes("name")}
                            />

                            <Autocomplete
                                options={ACCOUNT_TYPE_OPTIONS}
                                getOptionLabel={(option) => option}
                                value={this.state.accountType}
                                onChange={(e, value) => this.handleChange({
                                    target: {
                                        name: "accountType",
                                        value: value
                                    }
                                })}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Account Type"
                                        variant="outlined"
                                        required={this.state.requiredList.includes("accountType")}
                                        InputLabelProps={{shrink: true}}
                                    />
                                )}
                                className='input-text-field'
                            />

                            <TextField
                                className='input-text-field'
                                variant='outlined'
                                label="Account Number"
                                InputLabelProps={{shrink: true}}
                                name='accountNumber'
                                value={this.state.accountNumber}
                                onChange={this.handleChange}
                                required={this.state.requiredList.includes("accountNumber")}
                            />

                        </div>

                        <div className='rowSpecSuppliers'>

                            <TextField
                                className='input-text-field'
                                variant='outlined'
                                label="Full Name"
                                InputLabelProps={{shrink: true}}
                                name='bankFullName'
                                value={this.state.bankFullName}
                                onChange={this.handleChange}
                                required={this.state.requiredList.includes("bankFullName")}
                            />
                            <TextField
                                className='input-text-field'
                                variant='outlined'
                                label="Phone"
                                InputLabelProps={{shrink: true}}
                                name='phone'
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PhoneIcon/>
                                        </InputAdornment>
                                    ),
                                }}
                                // value={this.state.phone}
                                // onChange={this.handleTextChange}
                                value={this.state.phone}
                                onChange={this.handleChange}
                                required={this.state.requiredList.includes("phone")}

                            />
                        </div>


                        <div className='rowSpecSuppliers'>
                            <TextField
                                variant='outlined'
                                label="Address Line 1"
                                className='single-line-field'
                                InputLabelProps={{shrink: true}}
                                name='addressLine1'
                                value={this.state.addressLine1}
                                onChange={this.handleChange}
                                required={this.state.requiredList.includes("addressLine1")}
                            />
                        </div>

                        <div className='rowSpecSuppliers'>
                            <TextField
                                variant='outlined'
                                label="Address Line 2"
                                className='single-line-field'
                                InputLabelProps={{shrink: true}}
                                name='addressLine2'
                                value={this.state.addressLine2}
                                onChange={this.handleChange}

                            />
                        </div>
                        <div className='rowSpecSuppliers'>
                            <TextField
                                className='input-text-field'
                                variant='outlined'
                                label="City"
                                InputLabelProps={{shrink: true}}
                                name='city'
                                value={this.state.city}
                                onChange={this.handleChange}
                                required={this.state.requiredList.includes("city")}
                            />
                            <TextField
                                className='input-text-field'
                                variant='outlined'
                                label="State or Province"
                                InputLabelProps={{shrink: true}}
                                name='state'
                                value={this.state.state}
                                onChange={this.handleChange}
                                required={this.state.requiredList.includes("state")}
                            />
                            <TextField
                                className='input-text-field'
                                variant='outlined'
                                label="Zip or Postal Code"
                                InputLabelProps={{shrink: true}}
                                name='zip'
                                value={this.state.zip}
                                onChange={this.handleChange}
                                required={this.state.requiredList.includes("zip")}
                            />
                        </div>
                        <div className='rowSpecSuppliers'>
                            <TextField
                                className='input-text-field'
                                variant='outlined'
                                label="Country"
                                InputLabelProps={{shrink: true}}
                                name='country'
                                value={this.state.country}
                                onChange={this.handleChange}
                                required={this.state.requiredList.includes("country")}
                            />

                            <Autocomplete
                                required={this.state.requiredList.includes("currency")}
                                options={CURRENCY_TYPE_OPTIONS}
                                getOptionLabel={(option) => option}
                                value={this.state.currency}
                                onChange={(e, value) => this.handleChange({
                                    target: {
                                        name: "currency",
                                        value: value
                                    }
                                })}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Currency"
                                        variant="outlined"
                                        InputLabelProps={{shrink: true}}
                                    />
                                )}
                                className='input-text-field'
                            />

                        </div>


                        <div className='rowSpecSuppliers'>

                            <TextField
                                className='input-text-field'
                                variant='outlined'
                                label="Institution Number"
                                InputLabelProps={{shrink: true}}
                                name='institutionNumber'
                                value={this.state.institutionNumber}
                                onChange={this.handleChange}
                                required={this.state.requiredList.includes("institutionNumber")}
                            />

                            <TextField
                                className='input-text-field'
                                variant='outlined'
                                label="Transit Number"
                                InputLabelProps={{shrink: true}}
                                name='transitNumber'
                                value={this.state.transitNumber}
                                onChange={this.handleChange}
                                required={this.state.requiredList.includes("transitNumber")}
                            />
                            <TextField
                                className='input-text-field'
                                variant='outlined'
                                label="Designation Number"
                                InputLabelProps={{shrink: true}}
                                name='designationNumber'
                                value={this.state.designationNumber}
                                onChange={this.handleChange}
                                required={this.state.requiredList.includes("designationNumber")}
                            />

                        </div>

                        {this.state.accountType === "Chequing" &&
                            <div>
                                <Divider className='divider'
                                         style={{marginLeft: "10px", marginTop: "10px", marginBottom: "10px"}}/>

                                <div className='rowSpecSuppliers'>
                                    <TextField
                                        className='input-text-field'
                                        variant='outlined'
                                        label="Cheque Prefix"
                                        InputLabelProps={{shrink: true}}
                                        name='prefix'
                                        value={this.state.prefix}
                                        onChange={this.handleChange}
                                        required={this.state.requiredList.includes("prefix")}
                                    />
                                    <TextField
                                        className='input-text-field'
                                        variant='outlined'
                                        label="Last Cheque Number"
                                        InputLabelProps={{shrink: true}}
                                        name='chequeNumber'
                                        value={this.state.chequeNumber}
                                        onChange={this.handleChange}
                                        required={this.state.requiredList.includes("chequeNumber")}
                                    />
                                </div>

                                <div>
                                    <div>
                                        <>
                                            <Typography style={{
                                                marginTop: "8px",
                                                marginLeft: "10px",
                                                marginBottom: "16px",
                                                color: "black",
                                                opacity: "0.6",
                                                fontSize: "13px"
                                            }}>
                                                Cheque Signature
                                            </Typography>
                                        </>
                                    </div>


                                    <Divider/>

                                    <div style={{
                                        display: "flex",
                                        justifyContent: "left",
                                        marginTop: "16px",
                                        marginLeft: '16px'
                                    }}>
                                        <div className="transaction-form-section">
                                            {this.state.files?.map((file, index) => (
                                                <CustomUploadRow
                                                    key={index}
                                                    index={index}
                                                    file={file}
                                                    handleTemplateChange={this.handleTemplateChange}
                                                    removeTemplateRow={this.removeTemplateRow}
                                                    handleFileDelete={this.handleFileDelete}
                                                    handleFilePreview={this.handleFilePreview}
                                                />
                                            ))}
                                            <div style={{marginTop: '30px'}}>

                                                <IconButton component="label">
                                                    <AddCircleIcon fontSize="small" color='secondary'/>
                                                    <input
                                                        type="file"
                                                        hidden
                                                        onChange={(e) => this.handleFileChange(e)}
                                                    />
                                                </IconButton>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        }


                    </div>
                </div>

            </React.Fragment>
        )
    }

}


const CustomUploadRow = React.memo(({
                                        index,
                                        file,
                                        handleTemplateChange,
                                        removeTemplateRow, handleFilePreview
                                    }) => {

    return (
        <div
            className="transaction-form-details"
            style={{background: "white"}}
        >

            <div style={{display: 'flex', alignItems: 'center'}}>

                <div className='attachment-chip'>
                    <Chip
                        key={index}
                        icon={<InsertDriveFileIcon/>}
                        label={file.displayName}
                        onDoubleClick={() => handleFilePreview(index)}
                    />
                </div>
            </div>


            <div style={{display: 'flex', alignItems: 'center'}}>
                <TextField
                    variant="outlined"
                    name="signatoryName"
                    label="Signatory Name"
                    InputLabelProps={{shrink: true}}
                    value={file.signatoryName || ""}
                    onChange={(e) => handleTemplateChange({
                        target: {
                            name: "signatoryName",
                            value: e.target.value
                        }
                    }, index)}
                    required
                    error={!file.signatoryName}
                />
            </div>
            <div style={{width: '4.5%', display: "flex"}}>
                <IconButton onClick={() => removeTemplateRow(file, index)}>
                    <RemoveCircleIcon fontSize="small" color="error"/>
                </IconButton>
            </div>

        </div>
    );
});


export default BankAccountForm;