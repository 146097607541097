import {
    STORE_CLAIMS,
    STORE_CLAIMS_FILE, STORE_CREATED_CLAIM, STORE_DELETED_CLAIM, STORE_UPDATED_CLAIM
} from "../../constants/claims";
import {deleteItem, updateList} from "../global";

const initialState = {
    claims: [],
    previewingClaimData: [],
}

export default (state = initialState, action) => {
    switch (action.type) {
        case STORE_CLAIMS:
            return {
                ...state,
                claims: action.claims
            }
        case STORE_CLAIMS_FILE:
            return {
                ...state,
                previewingClaimData: action.data
            }
        case STORE_UPDATED_CLAIM:
            return {
                ...state,
                claims: updateList(action.claim.data, state.claims, "claimId")
            }
        case STORE_CREATED_CLAIM:
            return {
                ...state,
                claims: [...state.claims, action.claim.data]
            }
        case STORE_DELETED_CLAIM:
            return {
                ...state,
                claims: deleteItem(action.claimId.data, state.claims, "claimId")
            }
        default:
            return state;
    }
}