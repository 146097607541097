import {
    FETCH_RULES,
    STORE_RULES,
    CREATE_RULE,
    STORE_NEW_RULE,
    EDIT_RULE,
    STORE_RULE_TO_EDIT,
    CLEAR_STORED_RULE,
    DUPLICATE_RULE,
    DELETE_RULE,
    RUN_RULES, STORE_APPLIED_ORDERS, UPDATE_COMPANY, GET_COMPANY
} from "../../constants/automationRules";

export const fetchRules = () => ({
    type: FETCH_RULES,
});

export const updateCompany = (data) => ({
    type: UPDATE_COMPANY,
    data,
});

export const getCompany = (data) => ({
    type: GET_COMPANY,
});

export const storeRules = (rules) => ({
    type: STORE_RULES,
    rules,
});

export const createRule = (newRule) => ({
    type: CREATE_RULE,
    newRule,
});

export const storeNewRule = (ruleToStore, isEdit) => ({
    type: STORE_NEW_RULE,
    ruleToStore,
    isEdit,
});

export const storeToEdit = (ruleToEdit, history) => ({
    type: STORE_RULE_TO_EDIT,
    ruleToEdit,
    history,
})

export const editRule = (editedRule, history) => ({
    type: EDIT_RULE,
    editedRule,
    history,
});

export const duplicateRule = (ruleToCopy) => ({
    type: DUPLICATE_RULE,
    ruleToCopy,
})

export const deleteRule = (ruleToDelete) => ({
    type: DELETE_RULE,
    ruleToDelete,
})

export const clearStored = () => ({
    type: CLEAR_STORED_RULE,
});

export const runRules = (data) => ({
    type: RUN_RULES,
    data,
});

export const storeAppliedOrders = (newOrders) => ({
    type: STORE_APPLIED_ORDERS,
    newOrders,
})
