import React from "react";

// material components
import {
  Select,
  MenuItem,
  Box,
  TextField,
  Tooltip,
  IconButton,
} from "@material-ui/core";

// material icons
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

// import constants
import {FILTER_TYPE_TEXT_OPTIONS, FILTER_TYPE_TEXT_OPTIONS2, FILTERS, IS_EMPTY} from "./constants";
import {COPY_FILTER, DELETE_FILTER, DRAG} from "../constants";

// import styling
import "./FilterTextField.css";
import PropTypes from "prop-types";
import FilterBooleanSelect from "./FilterBooleanSelect";
import DehazeIcon from "@material-ui/icons/Dehaze";
import FileCopyIcon from "@material-ui/icons/FileCopy";

/**
 * Numeric Textfield with predefined dropdown and an inputTextField.
 */
class FilterTextField extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filterId: "",
      name: "",
      value1: "", // value for the first drop down
      value2: "", // value of the textfield
      secondaryOptions: [],
    };
  }

  componentDidMount = () => {
    this.setState({
      // on component mount, the parent component stores this child's state.
      // We restore it here
      ...this.props.filter,
    });
    this.setState({
      secondaryOptions: this.getSecondaryOptions() || [],
    });
  };

  getSecondaryOptions = () => {
    return FILTERS.find(filter => filter.name === this.props.filter.name).secondaryOptions
  }

  handleValue1Change = (event) => {
    const value1 = event.target.value;
    this.setState(
      {
        value1,
      },
      () => {
        this.props.updateFilterWithParent({ ...this.state });
      }
    );
  };

  handleValue2Change = (event) => {
    const value2 = event.target.value;
    this.setState(
      {
        value2,
      },
      () => {
        this.props.updateFilterWithParent({ ...this.state });
      }
    );
  };

  render() {
    return (
      <Box
        className='filterTextField'
        display='flex'
        flexDirection='row'
        justifyContent='left'
        alignItems='center'
      >
        <Box className='filterTextFieldName'>{this.state.name}</Box>

        {this.state.name =="Inventory (threshold)"?
            <Select
                className='filterTextFieldOptions'
                variant='outlined'
                label={this.state.name}
                value={this.state.value1}
                onChange={this.handleValue1Change}
            >
              {FILTER_TYPE_TEXT_OPTIONS2.map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
            :<Select
                className='filterTextFieldOptions'
                variant='outlined'
                label={this.state.name}
                value={this.state.value1}
                onChange={this.handleValue1Change}
            >
              {FILTER_TYPE_TEXT_OPTIONS.map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
        }
        {this.state.secondaryOptions.length !== 0?
            <div>

              <Select
                  className='filterBooleanPredefinedSecondarySelectOptions'
                  variant='outlined'
                  value={this.state.value2}
                  onChange={this.handleValue2Change}
              >
                {this.state.secondaryOptions.map((item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
            </div>
          :<div>
              {this.state.value1 === IS_EMPTY ? <></> :
                  <TextField
                      className='filterTextFieldInput'
                      variant='outlined'
                      label={this.state.name}
                      value={this.state.value2}
                      onChange={this.handleValue2Change}
                      InputLabelProps={{
                        shrink: true,
                      }}
                  />
              }
          </div>}
        <div className='filterBooleanEmptyDiv'>
          <Tooltip title={COPY_FILTER}>
            <IconButton
                aria-label={COPY_FILTER}
                onClick={() => this.props.copyFilter(this.state.filterId)}>
              <FileCopyIcon fontSize='small'/>
            </IconButton>
          </Tooltip>
          <Tooltip title={DELETE_FILTER}>
            <IconButton
              aria-label={DELETE_FILTER}
              onClick={() => this.props.deleteFilter(this.state.filterId)}
            >
              <DeleteOutlineIcon variant='outlined' fontSize='small' />
            </IconButton>
          </Tooltip>
          <Tooltip title={DRAG}>
            <IconButton
                aria-label='drag'
            >
              <DehazeIcon variant='outlined' fontSize='small' />
            </IconButton>
          </Tooltip>
        </div>
      </Box>
    );
  }
}

FilterTextField.propTypes = {
  filter: PropTypes.object,
  updateFilterWithParent: PropTypes.func,
  deleteFilter: PropTypes.func,
  isEdit: PropTypes.bool,
  copyFilter: PropTypes.func
}

FilterTextField.defaultProps = {
  filter: {},
  updateFilterWithParent: ()=>{},
  deleteFilter: ()=>{},
  isEdit: false,
  copyFilter: ()=>{}
}

export default FilterTextField;
