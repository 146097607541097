import axios from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import {
    CREATE_INVENTORY_ADJUSTMENT,
    UPDATE_FTL_RECEIVE,
    LIST_ALL_INVENTORY,
    LIST_INVENTORY_ADJUSTMENT_TYPES,
    LIST_INVENTORY_ENTRIES,
    LIST_MANUFACTURERS,
    UPDATE_INVENTORY_THRESHOLD,
    LIST_ALL_BOX_INVENTORY,
    GET_INVENTORY_SUMMARY_FOR_FTL,
    LIST_ALL_PRODUCT_INVENTORY,
    LIST_INVENTORY_ALERTS,
} from "../../constants/inventory";
import {DAMAGE, INVENTORY} from "../../../components/global/Server/endpoints";
import {
    listAllBoxInventory,
    listAllProductInventory, listInventoryAlerts,
    storeAllBoxInventory, storeAllDamages,
    storeAllInventory,
    storeAllProductInventory,
    storeInventoryAdjustmentTypes, storeInventoryAlerts, storeInventoryEntries, storeInventorySummaryForFTL,
    storeManufacturers, storePoInformation, storeSupplierByPoNumber,
    storeUpdatedInventoryItem,

} from "../../actions/inventory";
import { hideError, requestStatus, setLoadingStatus, showErrorMessage } from "../../actions/global";
import { fetchAlerts } from "../../actions/alerts";
import { HTTP_SUCCESS } from "../../constants/global";
import { SERVER_FAILED_ERROR_MESSAGE } from "../../../pages/Dashboard/DashboardFinal/constants";


const getHeaders = () => {
    const token = localStorage.getItem('token');
    return {
        "Authorization": `Bearer ${token}`
    }
}

const postRequest = (endpoint, data) => {
    return axios.post(endpoint, data, {
        headers: getHeaders()
    })
        .then(response => response)
}

const getRequest = (endpoint) => {
    return axios.get(endpoint, {
        headers: getHeaders()
    })
        .then(response => response);
}



function* listAllInventorySaga() {
    yield put(setLoadingStatus(true))
    yield put(hideError())
    try {
        const response = yield call(() => getRequest(INVENTORY.LIST_ALL_INVENTORY));
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        yield put(storeAllInventory(response.data));
        yield put(requestStatus(status));
    } catch (error) {
        yield put(setLoadingStatus(false))
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
    yield put(setLoadingStatus(false))
}

function* listInventoryAdjustmentTypesSaga() {
    yield put(hideError())
    try {
        const response = yield call(() => getRequest(INVENTORY.LIST_INVENTORY_ADJUSTMENT_TYPES));
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        yield put(storeInventoryAdjustmentTypes(response.data));
        yield put(requestStatus(status));
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* listManufacturersSaga() {
    yield put(hideError())
    try {
        const response = yield call(() => getRequest(INVENTORY.LIST_MANUFACTURERS));
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        yield put(storeManufacturers(response.data));
        yield put(requestStatus(status));
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* listInventoryAlertsSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(INVENTORY.LIST_INVENTORY_ALERTS, {updateAlerts: action.updateAlerts, fetchAlertsData: action.fetchAlertsData}));
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        yield put(storeInventoryAlerts(response.data));
        yield put(requestStatus(status));
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* listBoxesInventorySaga(action) {
    yield put(setLoadingStatus(true))
    try {
        const response = yield call(() => postRequest(INVENTORY.LIST_BOXES_INVENTORY, action.data));
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        yield put(storeAllBoxInventory(response.data));
        yield put(requestStatus(status));
    } catch (error) {
    } finally {
        yield put(setLoadingStatus(false))
    }
}

function* getInventorySummaryForFTL(action) {
    yield put(setLoadingStatus(true))
    try {
        const response = yield call(() => postRequest(INVENTORY.GET_INVENTORY_SUMMARY_FOR_FTL, action.data));
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        yield put(storeInventorySummaryForFTL(response.data));
        yield put(requestStatus(status));
    } catch (error) {
    } finally {
        yield put(setLoadingStatus(false))
    }
}

function* listProductsInventorySaga(action) {
    yield put(setLoadingStatus(true))
    try {
        const response = yield call(() => postRequest(INVENTORY.LIST_PRODUCTS_INVENTORY, action.data));
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        yield put(storeAllProductInventory(response.data));
        yield put(requestStatus(status));
    } catch (error) {
    } finally {
        yield put(setLoadingStatus(false))
    }
}



///////


function* createInventoryAdjustmentSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(INVENTORY.CREATE_INVENTORY_ADJUSTMENT, action.inventoryAdjustment));
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };

        if (response.data.success) {
            yield put(fetchAlerts(action.data))
            yield put(storeInventoryEntries(response.data.inventoryEntries));
            action.isProducts ?
                yield put(listAllProductInventory(action.selectedLocationData))
                : yield put(listAllBoxInventory(action.selectedLocationData));
        } else {
            yield put(showErrorMessage(response.data.message))
        }
        yield put(requestStatus(status));
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

//////////////////////

function* listInventoryEntriesSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(INVENTORY.LIST_INVENTORY_ENTRIES, action.dateRange));
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        yield put(storeInventoryEntries(response.data));
        yield put(requestStatus(status));
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}
///

function* updateInventoryThresholdSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(INVENTORY.UPDATE_INVENTORY_THRESHOLD, action.inventoryItem));
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        if (response.data.success) {
            yield put(storeUpdatedInventoryItem(response.data.inventory));
            yield put(listInventoryAlerts( true, action.data))
        } else {
            yield put(showErrorMessage(response.data.message))
        }

        yield put(requestStatus(status));
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* updateFTLReceiveSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(INVENTORY.UPDATE_FTL_RECEIVE, { transferId: action.transferId, fromDate: action.fromDate }));
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        if (response.data.success) {
            yield put(fetchAlerts(action.data));
            yield put(storeInventoryEntries(response.data.inventoryEntries));
            action.isProducts ?
                yield put(listAllProductInventory(action.selectedLocationData))
                : yield put(listAllBoxInventory(action.selectedLocationData));
        } else {
            yield put(showErrorMessage(response.data.message))
        }

        yield put(requestStatus(status));
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}


export default function* inventorySagas() {
    yield takeLatest(LIST_ALL_INVENTORY, listAllInventorySaga);
    yield takeLatest(LIST_INVENTORY_ADJUSTMENT_TYPES, listInventoryAdjustmentTypesSaga);
    yield takeLatest(LIST_MANUFACTURERS, listManufacturersSaga);
    yield takeLatest(LIST_INVENTORY_ALERTS, listInventoryAlertsSaga);
    yield takeLatest(LIST_ALL_BOX_INVENTORY, listBoxesInventorySaga);
    yield takeLatest(GET_INVENTORY_SUMMARY_FOR_FTL, getInventorySummaryForFTL);
    yield takeLatest(LIST_ALL_PRODUCT_INVENTORY, listProductsInventorySaga);

    yield takeLatest(CREATE_INVENTORY_ADJUSTMENT, createInventoryAdjustmentSaga);
    yield takeLatest(UPDATE_FTL_RECEIVE, updateFTLReceiveSaga);

    yield takeLatest(LIST_INVENTORY_ENTRIES, listInventoryEntriesSaga);

    yield takeLatest(UPDATE_INVENTORY_THRESHOLD, updateInventoryThresholdSaga);

}