import {
    GET_USER_SETTING,
    STORE_USER_SETTING,
    SAVE_USER_SETTING,
    GET_SHIPMENT_SETTINGS,
    STORE_SHIPMENT_SETTINGS, GET_USER_TICKET_FILTER, STORE_USER_TICKET_FILTER, UPDATE_TABLE_FILTER
} from "../../constants/account";

export const getTableSetting = (userId) => ({
    type: GET_USER_SETTING,
    userId
})

export const getTableFilter = (setting) =>({
    type: GET_USER_TICKET_FILTER,
    setting
})

export const storeTableFilter = (userTableFilter) => ({
    type: STORE_USER_TICKET_FILTER,
    userTableFilter
})

export const saveTableSetting = (setting) => ({
    type: SAVE_USER_SETTING,
    setting
})

export const storeTableSetting = (userTableSetting) => ({
    type: STORE_USER_SETTING,
    userTableSetting
})

export const getShipmentSettings = (userId) => ({
    type: GET_SHIPMENT_SETTINGS,
    userId
})

export const storeShipmentSettings = (shipmentSettings) => ({
    type: STORE_SHIPMENT_SETTINGS,
    shipmentSettings
})

export const updateTicketTableFilter = (filter) => ({
    type: UPDATE_TABLE_FILTER,
    filter
})