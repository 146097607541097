import React from "react";
import {Layout} from "../../global/Table/TablePageHelpers/LayoutObject";
import {
    LIST_CLAIMS_CELLS,
    EDIT_ENTRY,
    CLAIMS_PAGES,
    CLAIM_PAGE_NAME,
    CREATE_CLAIM_ENTRY,
    STATUS,
    CURRENCIES,
} from "../constants";
import withShipment from "../../../withShipment";
import {TableCell} from "@mui/material";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import './ClaimsTable.css';
import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import TablePage from "../../global/Table/TablePage";
import {
    deleteClaim
} from "../../../redux/actions/claims";
import TwoStepDelete from "../../global/subcomponents/twoStepDelete/TwoStepDelete";
import {Filter} from "../../global/Table/TablePageHelpers/FilterObject";
import {carrierNamesSelector} from "../../../redux/selectors/settings";

class ClaimsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deleteId: "",
            carrierOptions: []
        }
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if(prevProps.carriers !== this.props.carriers) {
            this.setState({
                carrierOptions: this.props.carriers
            })
        }
    }

    getTableHeaders = () => {
        let headers = []
        headers.push(Layout.columnHead(null,null,null))
        LIST_CLAIMS_CELLS.forEach((headCell) => {
            headers.push(Layout.columnHead(headCell.label, headCell.id, "center"))
        })
        headers.push(Layout.columnHead(null,null,null))
        return headers
    }

    tableRow = (row, index) => {
        return <React.Fragment>
            <TableCell className="editIcon">
                <Tooltip title={EDIT_ENTRY}>
                    <IconButton onClick={() => {this.props.setCurrentPage(CLAIMS_PAGES.UPDATE, row.claimId)}}>
                        <EditIcon
                            fontSize='medium'
                        />
                    </IconButton>
                </Tooltip>
            </TableCell>
            <TableCell className="claim-cell-center"> {row?.closeDate.split("T")[0]} </TableCell>
            <TableCell className="claim-cell-center"> {row?.trackingNumber} </TableCell>
            <TableCell className="claim-cell-center"> {row?.orderId} </TableCell>
            <TableCell className="claim-cell-center"> {row?.checkNumber} </TableCell>
            <TableCell className="claim-cell-center"> {row?.carrier} </TableCell>
            <TableCell className="claim-cell-center"> {row?.currency} </TableCell>
            <TableCell className="claim-cell-center"> {row?.status} </TableCell>
            <TableCell className="claim-cell-center"> {row?.paymentVerified? "Yes" : "No"} </TableCell>
            <TwoStepDelete
                rowId={row.claimId}
                handleDelete={(rowIdToDelete) => this.props.deleteClaim(rowIdToDelete)}
                deleteId={this.state.deleteId}
                setDeleteId={(newDeleteId) => this.setState({deleteId: newDeleteId})}
            />
        </React.Fragment>
    }

    render() {
        return (
            <React.Fragment>
                <TopToolbar
                    pageName={CLAIM_PAGE_NAME}
                    menuItems={[
                        {title: CREATE_CLAIM_ENTRY, icon: <AddCircleIcon/>, onClick: () => {this.props.setCurrentPage(CLAIMS_PAGES.CREATE)}}
                    ]}
                />
                <TablePage
                    tableName="ClaimsTable"
                    tableRows={this.props.claims}
                    tableColumnHeads={this.getTableHeaders()}
                    tableRow={this.tableRow}
                    hasSearchField
                    paginationText="Claims per page"
                    searchStrings={(row) => LIST_CLAIMS_CELLS.map(column => row[column.id]).concat(row.paymentVerified ? ["Yes"] : ["No"])}
                    filters={[
                        Filter.createFilter('closeDateFrom', (row) => row.closeDate, null, Filter.relations.greaterThanEquals, true, Filter.displayValueOverrides.valueIsDate, new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7).toISOString()),
                        Filter.createFilter('closeDateTo', (row) => row.closeDate, null, Filter.relations.lessThanEquals, true, Filter.displayValueOverrides.valueIsDate, new Date(new Date().setHours(23, 59, 59, 999)).toISOString()),
                        Filter.createFilter('isVerified', (row) => (row.paymentVerified !== true), false, Filter.relations.equals, false),
                        Filter.createFilter("status", (row) => row.status, null, Filter.relations.containedBy, true),
                        Filter.createFilter("carrier", (row) => row.carrier, null, Filter.relations.containedBy, true),
                        Filter.createFilter("currency", (row) => row.currency, null, Filter.relations.containedBy, true)
                    ]}
                    filterCheckboxes={[
                        Layout.checkBox('isVerified', "Payment Verified", null, null, null, true),
                    ]}
                    filterDropdown={ Layout.newLayout(1, [
                        Layout.row([
                            Layout.rowElement(1, 'closeDateFrom', 'Close Date (From)', Layout.elementStyle.dateSelect)
                        ]),
                        Layout.row([
                            Layout.rowElement(1, 'closeDateTo', 'Close Date (To)', Layout.elementStyle.dateSelect)
                        ]),
                        Layout.row([
                            Layout.rowElement(1, 'status', 'Status',  Layout.elementStyle.checkboxDropdown, STATUS)
                        ]),
                        Layout.row([
                            Layout.rowElement(1, 'carrier', 'Carrier',  Layout.elementStyle.checkboxDropdown, this.state.carrierOptions ? this.state.carrierOptions : [])
                        ]),
                        Layout.row([
                            Layout.rowElement(1, 'currency', 'Currency',  Layout.elementStyle.checkboxDropdown, CURRENCIES)
                        ])
                    ]) }
                />
            </React.Fragment>
        )
    }
}

const actionCreators = {
    deleteClaim,
}

export default withShipment({
    actionCreators
}, ClaimsTable);