import React from "react";
import {BACK, DOWNLOAD, FTL_PAGES, FULL_TRUCKLOAD, PRINT, ZOOM_IN, ZOOM_OUT} from "../constants";
import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import withShipment from "../../../withShipment";
import RenderPurchaseOrderPDFPreview from "../../purchaseOrders/RenderPurchaseOrderPDFPreview";
import {DEFAULT_PDF_ZOOM} from "../../global/constants";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import GetAppIcon from "@material-ui/icons/GetApp";
import PrintIcon from "@material-ui/icons/Print";
import Box from "@material-ui/core/Box";
import {Document, Page} from "react-pdf";
import {IconButton} from "@material-ui/core";
import ReactToPrint from "react-to-print";
import printJS from "print-js";

class FullTruckLoadPreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            scale: DEFAULT_PDF_ZOOM
        }
    }

    setZoom(zoom) {
        let newScale = Math.min(Math.max(this.state.scale + zoom, 1), 2)
        this.setState({
            scale: newScale
        })
    }

    downloadDocuments = () => {
        let date = this.props.date?.split("T")[0] ? this.props.date?.split("T")[0] : ""
        let blob = new Blob([Uint8Array.from(this.props.previewingFullTruckloadData)], { type: "application/pdf" });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = this.props.isPreviewingFinalDoc ? "Exponet - Customs Documents - " + date + ".pdf" :
        this.props.isBillOfLadingSigned ? "Exponet - Bill Of Lading - " + date + " (signed).pdf" : "Exponet - Bill Of Lading - " + date + ".pdf"
        link.click();
        // now clear it from memory
        window.URL.revokeObjectURL(link.href)
    };

    printDocuments = () => {
        let blob = new Blob([Uint8Array.from(this.props.previewingFullTruckloadData)], { type: "application/pdf" });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        printJS(link.href)
    }

    render() {
        return (
            <React.Fragment>
                <TopToolbar
                    pageName={FULL_TRUCKLOAD}
                    menuItems={[
                        {title: BACK, icon: <KeyboardBackspaceIcon/>, onClick: () => {this.props.setCurrentPage(this.props.prevPage ? this.props.prevPage : FTL_PAGES.UPDATE)}},
                        {title: DOWNLOAD, icon: <GetAppIcon/>, onClick: () => {this.downloadDocuments()}},
                        {title: PRINT, icon: <PrintIcon/>, onClick: () => {this.printDocuments()}},
                        {title: ZOOM_OUT, icon: <ZoomOutIcon/>, onClick: () => this.setZoom(-0.25)},
                        {title: (this.state.scale * 100) + '%', isNotIcon: true},
                        {title: ZOOM_IN, icon: <ZoomInIcon/>, onClick: () => this.setZoom(0.25)}
                    ]}
                />

                <RenderPurchaseOrderPDFPreview
                    //allowSave={true}
                    data={this.props.previewingFullTruckloadData}
                    //handlePreviewOpen={this.handlePreviewOpen}
                    //handlePreviewClose={this.handlePreviewClose}
                    //open={this.state.previewOpen}
                    handlePdfLoadSuccess={() => {}}
                    handlePdfLoadError={() => {}}
                    scale={this.state.scale}
                />

            </React.Fragment>
        )
    }
}

export default withShipment({

}, FullTruckLoadPreview);