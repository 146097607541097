import {
    STORE_ORDER_INFO_FETCH,
    STORE_TICKET_ACTION_TASKS,
    STORE_TICKET_ACTIONS,
    STORE_TICKET_ISSUES,
    STORE_STATUS,
    STORE_TICKETS,
    STORE_UPDATED_TICKET, STORE_UPDATED_TICKET_WITH_PREVIEW
} from "../../constants/tickets";
import { updateList } from "../global";


const initialState = {
    tickets: [],
    ticketActions: [],
    ticketIssues: [],
    status: [],
    ticketActionTasks: {}
}

const updateActionTasks = (prevTicketActionTasks, ticketActionTasks) => {
    let dict = prevTicketActionTasks
    dict[ticketActionTasks[0].ticketIssueName] = {
        ...dict[ticketActionTasks[0].ticketIssueName],
        ticketIssueTasks: ticketActionTasks[0].ticketIssueInfo
    }
    return dict
}

const updateTicketWithPreview = (updatedTicket, oldTickets) => {
    let filePreviewInfo = updatedTicket.files.pop()
    // get the data
    let previewData = new Uint8Array(filePreviewInfo.previewFile)
    let blobData = new Blob([previewData])
    let localStorageUrl = URL.createObjectURL(blobData)

    let ticketToUpdate = oldTickets.find((ticket) => { return ticket.ticketId === updatedTicket.ticketId })

    // we're only updating the specific file object as we dont want to lose state of currently uploaded files (locally) and other local changes user has made
    // if this is a general file
    if ("generalFileIndex" in filePreviewInfo) {
        let generalFileIndex = filePreviewInfo.generalFileIndex
        updatedTicket.files[generalFileIndex]["localURL"] = localStorageUrl
        ticketToUpdate.files[generalFileIndex] = updatedTicket.files[generalFileIndex]
        ticketToUpdate.files[generalFileIndex]['byteArray'] = previewData
    }
    // action specific
    else {
        let actionIndex = filePreviewInfo.actionIndex
        let actionFileIndex = filePreviewInfo.actionFileIndex
        updatedTicket.actions[actionIndex].files[actionFileIndex]["localURL"] = localStorageUrl
        ticketToUpdate.actions[actionIndex].files[actionFileIndex] = updatedTicket.actions[actionIndex].files[actionFileIndex]
        ticketToUpdate.actions[actionIndex].files[actionFileIndex]['byteArray'] = previewData
    }


    return updateList(ticketToUpdate, oldTickets, "ticketId")
}

const reorderTicketActions = (actions) => {
    //Force close case to be at the end of the dropdown
    if (actions?.length > 1 && actions.findIndex(action => action.ticketActionName === 'Close Case') !== -1) {
        const index = actions.findIndex(action => action.ticketActionName === 'Close Case')
        const closeCaseAction = actions[index]
        actions.splice(index, 1)
        actions = [...actions, closeCaseAction]
        return actions
    } else {
        return actions
    }
}

export default (state = initialState, action) => {
    switch (action.type) {
        case STORE_TICKET_ACTIONS:
            return {
                ...state,
                ticketActions: reorderTicketActions(action.ticketActions)
            }
        case STORE_TICKET_ISSUES:
            return {
                ...state,
                ticketIssues: action.ticketIssues
            }
        case STORE_STATUS:
            return {
                ...state,
                status: action.status
            }
        case STORE_TICKETS:
            return {
                ...state,
                tickets: action.tickets
            }
        case STORE_UPDATED_TICKET:
            return {
                ...state,
                tickets: updateList(action.updatedTicket, state.tickets, 'ticketId')
            }
        case STORE_TICKET_ACTION_TASKS:
            return {
                ...state,
                ticketActionTasks: updateActionTasks(state.ticketActionTasks, action.ticketActionTasks)
            }
        case STORE_UPDATED_TICKET_WITH_PREVIEW:
            return {
                ...state,
                tickets: updateTicketWithPreview(action.updatedTicket, state.tickets)
            }
        case STORE_ORDER_INFO_FETCH:
            return {
                ...state,
                orderInfoFetch: action.orderInfoFetch
            }
        default:
            return state
    }
}