import React from "react";


// material components
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import VisibilityIcon from '@material-ui/icons/Visibility';
import SendIcon from '@material-ui/icons/Send';
import GetAppIcon from '@material-ui/icons/GetApp';
import Paper from "@material-ui/core/Paper";

// custom components
import CreatePurchaseOrder from "./CreatePurchaseOrder";
import withShipment from "../../withShipment";
import PurchaseOrdersTable from "./PurchaseOrdersTable";
import DateFnsUtils from '@date-io/date-fns';
import PurchaseOrderDetails from "./PurchaseOrderDetails";

// constants
import { CREATE_PURCHASE_ORDER, PURCHASE_ORDER } from "./constants";
import {DEFAULT_PDF_ZOOM} from "../global/constants";

// redux
import {
    cachePDFpreview,
    deleteInventoryReceived,
    fetchAllPurchaseOrders, getPDFpreview, previewCreatedPurchaseOrder, previewPackingSlipFile,
    createSendNewPurchaseOrder,
    createNewPurchaseOrder,
    updatePurchaseOrder, cancelPurchaseOrder, editPurchaseOrder, undoCancelPurchaseOrder,
    resetPdfPreviewData,
    storeCreatedPurchaseOrderPreview,
    deletePackingSlip, updateExpectedDeliveryDate,
} from "../../redux/actions/purchaseOrders";
import {
    allPurchaseOrdersSelector,
    completedPurchaseOrdersSelector, createdPurchaseOrderPreviewSelector,
    newPurchaseOrdersSelector,
    partialPurchaseOrdersSelector, PDFpreviewReadySelector,
} from "../../redux/selectors/purchaseOrders";
import {
    saveTableSetting,
    getTableSetting
} from "../../redux/actions/account";
import {
    tableSettingSelector
} from "../../redux/selectors/account";

import PropTypes from "prop-types";
import ExpandedPurchaseOrder from "./ExpandedPurchaseOrder";
import CustomToolbar from "../global/CustomToolbar";
import RenderPurchaseOrderPDFPreview from "./RenderPurchaseOrderPDFPreview";
import { FETCH_UNSHIPPED_ALERTS_TIME } from "../alerts/constants";
import { userInfoSelector } from "../../redux/selectors/auth";
import EditIcon from "@material-ui/icons/Edit";
import BlockIcon from "@material-ui/icons/Block";
import EditPurchaseOrder from "./EditPurchaseOrders";
import FeedbackBar from "../feedbackBar/FeedbackBar";
import { statusSelector } from "../../redux/selectors/global";
import { ERROR, SUCCESS } from "../settings/ManageUsers/constants";
import { locationsSelector } from "../../redux/selectors/dashboard";
import { getLocations } from "../../redux/actions/dashboard";
import {
    availableLocationsSelector,
    emailTemplatesSelector,
    hasPurchaseOrderEmailTemplateSelector,
    suppliersSelector as realSuppliersSelector
} from "../../redux/selectors/settings";
import {
    listAvailableLocations,
    getEmailTemplates,
    listSuppliers,
    sendPurchaseOrderEmail,
    fetchPurchaseOrderEmailTemplate,
    sendEmail_purchaseOrder
} from "../../redux/actions/settings";
import DeleteIcon from "@material-ui/icons/Delete";
import ClearIcon from "@material-ui/icons/Clear";
import UndoIcon from '@material-ui/icons/Undo';
import {ArrowRight, CancelRounded, GetApp} from "@material-ui/icons";
import "./PurchaseOrdersTable.css";
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ErrorFeedbackBar from "../global/ErrorFeedbackBar";
import EmailIcon from '@material-ui/icons/Email';
import {DEFAULT_ROWS_PER_PAGE} from "../returns/constants";
import SaveIcon from "@material-ui/icons/Save";
import DescriptionIcon from "@material-ui/icons/Description";
import IconButton from "@material-ui/core/IconButton";

class PurchaseOrders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            create: false,
            showingCreatedPreview: false,
            showingPurchaseOrder: '',
            previewing: false,
            previewable: true,
            previewingName: "",
            pdfData: [],
            entries: this.props.user.routes.includes('po-pricing') ? [{
                shortcode: '',
                partNum: '',
                description: '',
                discount: '0.00',
                price: '',
                quantity: 0,
                deleteEntry: true
            }] : [{
                shortcode: '',
                partNum: '',
                description: '',
                discount: '0.00',
                quantity: 0,
                deleteEntry: true
            }],
            selectedSupplier: '',
            supplierId: '',
            purchaseOrderEmailTemplate: null,
            notes: 'Deliveries after 10 am. Please ring doorbell at the back.',
            expectedDate: this.initDate(),
            samePreview: false,
            editingPOqty: false,
            errEditQty: {},
            editingPurchaseOrder: false,
            toEditPurchaseOrder: {},
            fetchCreatedPurchaseOrderPreview: () => {
            },
            voidStatus: false,
            voidOpen: false,
            selectedLocation: {},
            toLocation: {},
            taxPercentage: "",
            discountPercentage: "0.00",
            fromDate: this.getFromDate(),
            toDate: this.getToDate(),
            isDelete: false,
            pdfLoadSuccess: true,
            canVoidPO: false,
            isCancelledPO: false,
            isUndoDelete: false,
            isCompleted: false,
            voiding: false,
            undoCancelStatus: false,
            undoCancelledFeedbackOpen: false,
            scale: DEFAULT_PDF_ZOOM,
            searchText: '',
            page: 0,
            rowsPerPage: DEFAULT_ROWS_PER_PAGE,
            previewingPS: false,
            previewingPsFile: {},
            previewingPsPoNum: 0,
            deletingPS: false,
            selectedSupplierInfo:{}
        };
        this.psDownloadRef = React.createRef();
    }

    initDate = () => {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        return tomorrow
    }

     componentDidMount() {
        this.props.fetchAllPurchaseOrders({ fromDate: this.getFromDate(), toDate: this.getToDate() });
        this.props.listAvailableLocations();
        this.props.getTableSetting(this.props.user.userId)
        this.props.listSuppliers();
        this.props.getEmailTemplates();
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.allPurchaseOrders !== this.props.allPurchaseOrders) {
            this.setState({
                ...prevState,
                ...this.props.allPurchaseOrders
            });
            //when all purchaseOrder is updated, it is likely because the toEdiPurchaseOrder is changed, update it
            if(this.state.toEditPurchaseOrder){
                this.props.allPurchaseOrders.map(purchaseOrder =>{
                    if(purchaseOrder.poNumber === this.state.toEditPurchaseOrder.poNumber){
                        //a purchase Order does not include a supplier name, so add it here
                        let matchingPurchaseOrder = purchaseOrder
                        matchingPurchaseOrder.supplierName = this.state.toEditPurchaseOrder.supplierName
                        this.setState({
                            ...prevState,
                            toEditPurchaseOrder : matchingPurchaseOrder
                        })
                    }
                })
            }
        }
        if (!this.arrayEquals(prevProps.createdPurchaseOrderPreview, this.props.createdPurchaseOrderPreview)) {
            this.setState({
                pdfData: this.props.createdPurchaseOrderPreview
            })
        }
        if (!this.arrayEquals(prevProps.PDFpreviewReady, this.props.PDFpreviewReady)) {
            this.setState({
                pdfData: this.props.PDFpreviewReady
            })
        }
        if (prevProps.availableLocations !== this.props.availableLocations) {
            let findLocation = this.props.availableLocations.find((location) => location.shipAddressId === this.props.user.defaultLocationId)
            let defaultLocation = findLocation ? findLocation : {}
            this.setState({
                selectedLocation: defaultLocation,
                toLocation: defaultLocation
            })
        }
        if (prevProps.tableSetting !== this.props.tableSetting) {
            this.setState({ fromDate: this.getFromDate(), toDate: this.getToDate() });
        }

        if (prevState.selectedSupplier !== this.state.selectedSupplier || this.props.suppliers !== prevProps.suppliers) {
            if (this.state.selectedSupplier && this.props.suppliers){
                this.setState({
                    selectedSupplierInfo: this.props.suppliers.find((supplier)=>supplier.name ==this.state.selectedSupplier)
                })
            }
        }

        // if the tax percentage is null then set previewable to false
        if (this.state.taxPercentage != prevState.taxPercentage) {
            if (this.state.taxPercentage === "" || !this.state.taxPercentage) {
                this.setState({
                    previewable: false
                })
            }
        }
    }

    getSupplierFromName(supplierName) {
        let supplier = null, s
        for (s in this.props.suppliers)
            if (this.props.suppliers[s].name === supplierName) {
                supplier = this.props.suppliers[s]
                break;
            }
        if (supplier == null) {
            return null // no supplier found
        }
        return supplier
    }

    getToday = () => {
        var today = new Date()
        today = today.toString().split('GMT')[0] + "Z"
        return new Date(today)
    }

    getFromDate = () => {
        var date = this.getToday()
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setDate(date.getDate() - 14)
        return date;
    }

    getToDate = () => {
        var date = this.getToday()
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
        return date;
    }

    setPage = (page) => {
        this.setState({
            page: page
        })
    }

    setRowsPerPage = (rowsPerPage) => {
        this.setState({
            rowsPerPage: rowsPerPage
        })
    }

    onDateFiltersInputChange = (id, value) => {
        if (!value || isNaN(value.getTime())) {
            //User is still editing the date value, do not fetch orders
            this.setState({
                fromDate: id === "fromDate" ? value : this.state.fromDate,
                toDate: id === "toDate" ? value : this.state.toDate
            })
        } else {
            const date = new Date(value.toString().split('GMT')[0] + "Z")
            this.setState({
                fromDate: id === "fromDate" ? value : this.state.fromDate,
                toDate: id === "toDate" ? value : this.state.toDate
            })
        }
    }

    applyDateFilters = (fromDate, toDate) => {
        this.props.fetchAllPurchaseOrders({
            fromDate,
            toDate
        })
    }

    arrayEquals(arr1, arr2) {
        if (!arr1 || !arr2) return false
        if (arr1.length !== arr2.length) return false
        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) return false
        }
        return true
    }

    createPurchaseOrder = () => {
        this.setState({
            create: true,
            previewable: false,
            taxPercentage: this.state.selectedLocation.taxPercentage,
            toLocation: this.state.selectedLocation
        });
    }

    editPurchaseOrderQty = (purchaseOrder, fetchCreatedPurchaseOrderPreview) => {
        this.setState({
            editingPOqty: true,
            toEditPurchaseOrder: purchaseOrder,
            fetchCreatedPurchaseOrderPreview: fetchCreatedPurchaseOrderPreview
        });
    }

    handleCancel = () => {
        this.setState({
            create: false,
            entries: this.props.user.routes.includes('po-pricing') ? [{
                shortcode: '',
                partNum: '',
                description: '',
                discount: '0.00',
                price: '',
                quantity: 0,
                skid: 0,
                deleteEntry: true
            }] : [{
                shortcode: '',
                partNum: '',
                description: '',
                discount: '0.00',
                skid:0,
                quantity: 0,
                deleteEntry: true
            }],
            previewing: false,
            previewable: true,
            selectedSupplier: '',
            supplierId: '',
            expectedDate: this.initDate(),
            notes: 'Deliveries after 10 am. Please ring doorbell at the back.',
            samePreview: false,
            editingPOqty: false,
            editingPurchaseOrder: false,
            toEditPurchaseOrder: {},
            isDelete: false,
            isUndoDelete: false
        });
    }

    deleteInventoryReceived = (data) => {
        const fetchAlertsData = {
            selectedShipAddressId: this.props.user.locationIds,
            fetchUnshipped: false,
        }
        let currHour = new Date().getHours();
        if (currHour >= FETCH_UNSHIPPED_ALERTS_TIME) {
            fetchAlertsData.fetchUnshipped = true;
        } else {
            fetchAlertsData.fetchUnshipped = false;
        }
        this.props.deleteInventoryReceived(data, fetchAlertsData, this.updatePoStatus);
    }

    updatePoStatus = () => {
        let po = this.props.allPurchaseOrders.find((po) => po.poNumber === this.state.toEditPurchaseOrder.poNumber);
        this.setState({ toEditPurchaseOrder: po })
        this.showVoidIcon(po.status === "open");
        this.checkCompltedPo(po.status === "completed");
        this.checkCancelledPo(po.status === "cancelled");
    };

    updatePurchaseOrder = (data) => {
        const fetchAlertsData = {
            selectedShipAddressId: this.props.user.locationIds,
            fetchUnshipped: false,
        }
        let currHour = new Date().getHours();
        if (currHour >= FETCH_UNSHIPPED_ALERTS_TIME) {
            fetchAlertsData.fetchUnshipped = true;
        } else {
            fetchAlertsData.fetchUnshipped = false;
        }
        this.props.updatePurchaseOrder(data, fetchAlertsData, this.updatePoStatus);
    }
    changeHeader = (page) => {
        this.setState({
            header: page
        })
    }
    errorCheck = (date) => {
        let curEntries = this.state.entries;
        if (curEntries.length === 0)
            return false
        if (date instanceof Date) {
            let today = new Date()
            today.setHours(0, 0, 0, 0)
            let expected = date
            expected.setHours(0, 0, 0, 0)
            if (expected < today)
                return false
        }
        let good = true;
        let shortcodes = {};
        curEntries.forEach((entry) => {
            if (entry.shortcode in shortcodes) {
                good = false;
            } else {
                shortcodes[entry.shortcode] = 1
            }
            let quantity = parseFloat(entry.quantity)
            let price = parseFloat(entry.price)
            if (entry.shortcode === "" || quantity <= 0 || isNaN(quantity) || (this.props.user.routes.includes('po-pricing') && (price <= 0 || isNaN(price))))
                good = false;
        });
        if (this.state.editingPurchaseOrder) {
            for (const entry of curEntries) {
                for (const orderItem of this.state.toEditPurchaseOrder.orderItems) {
                    if (orderItem.shortcode === entry.shortcode) {
                        let receiveQty = parseInt(orderItem.quantityReceived)
                        let qty = parseInt(entry.quantity)
                        if (qty < receiveQty) {
                            good = false;
                        }
                    }
                }
            }
        }
        return good;
    }

    // Functions for updating creatingPDF state
    updateField(name, value) {
        let val = this.errorCheck(value)
        this.setState({ [name]: value, samePreview: false, previewable: val, pdfData: []})
    }

    async updateErrQuan(arr, index){
        arr.forEach((item) => {
            if(item.shortcode === this.state.toEditPurchaseOrder?.orderItems[index]?.shortcode){
                if(parseInt(item.quantity) < parseInt(this.state.toEditPurchaseOrder.orderItems[index].quantityReceived)){
                    const temp = { ...this.state.errEditQty };
                    temp[`${index}`] = false
                    this.setState({
                        errEditQty: temp,
                    })

                } else {
                    const temp = { ...this.state.errEditQty };
                    temp[`${index}`] = true
                    this.setState({
                        errEditQty: temp,
                    })
                }
            }
        })
    }

    showPdfErrorMessage = (isFailed) => {
        this.props.resetPdfPreviewData();
        this.setState({ pdfLoadSuccess: !isFailed })
    }

    previewPDF = () => {
        // only fetch a new pdf render if they have made changes to the order
        if (!this.state.samePreview) {
            const data = {
                entries: this.state.entries,
                supplier: this.state.selectedSupplier,
                supplierId: this.state.supplierId,
                notes: this.state.notes,
                selectedLocation: this.state.toLocation,
                taxPercentage: this.state.taxPercentage,
            };
            this.handleUpdatePurchaseOrderEmailTemplate()
            this.props.cachePDFpreview([])
            this.props.getPDFpreview(data, this.showPdfErrorMessage);
            this.setState({
                pdfData: this.props.PDFpreviewReady,
                previewing: true,
                samePreview: true
            });
        } else
        // use same data
        {
            this.props.cachePDFpreview(this.props.PDFpreviewReady);
            this.setState({
                previewing: true
            })
        }
    }

    handleUpdatePurchaseOrderEmailTemplate = () => {
        this.setState({
            purchaseOrderEmailTemplate: this.getSupplierFromName(this.state.selectedSupplier).purchaseOrderEmailTemplate
        })
    }

    handleSubmit = () => {
        const data = {
            entries: this.state.entries,
            supplier: this.state.selectedSupplier,
            supplierId: this.state.supplierId,
            notes: this.state.notes,
            expectedDate: this.state.expectedDate,
            selectedLocation: this.state.toLocation,
            taxPercentage: this.state.taxPercentage,
            discountPercentage: this.state.discountPercentage
        };
        this.props.createNewPurchaseOrder(data, () => window.location.reload());
    }

    handleEdit = () => {
        const data = {
            entries: this.state.entries,
            supplier: this.state.selectedSupplier,
            supplierId: this.state.supplierId,
            notes: this.state.notes,
            expectedDate: this.state.expectedDate,
            selectedLocation: this.state.toLocation,
            taxPercentage: this.state.taxPercentage,
            discountPercentage: this.state.discountPercentage,
            poNumber: this.state.toEditPurchaseOrder.poNumber,
            save: true
        }
        this.props.editPurchaseOrder(data, () => window.location.reload(), false, this.showPdfErrorMessage);
    }

    handleEditExpectedDeliveryDate = (expectedDeliveryDate) =>{
        const data = {
            expectedDeliveryDate: expectedDeliveryDate,
            poNumber: this.state.toEditPurchaseOrder.poNumber,
        }
        this.props.updateExpectedDeliveryDate(data)
    }

    previewExistingPurchaseOrder = () => {
        if (!this.state.samePreview) {
            const data = {
                entries: this.state.entries,
                taxPercentage: this.state.taxPercentage,
                supplier: this.state.selectedSupplier,
                supplierId: this.state.supplierId,
                notes: this.state.notes,
                expectedDate: this.state.expectedDate,
                selectedLocation: this.state.toLocation,
                poNumber: this.state.toEditPurchaseOrder.poNumber,
                save: false
            }
            this.handleUpdatePurchaseOrderEmailTemplate()
            this.props.editPurchaseOrder(data, () => { }, true, this.showPdfErrorMessage);
            this.setState({
                pdfData: this.props.PDFpreviewReady,
                previewing: true,
                samePreview: true
            });
        } else
        // use same data
        {
            this.props.cachePDFpreview(this.props.PDFpreviewReady);
            this.setState({
                previewing: true
            })
        }

    }

    openPackingSlipPreview = (file, poNumber) => {
        this.setState({ previewingPS: true, previewingPsFile: file, previewingPsPoNum: poNumber, deletingPS: false })
    }


    closeCreatingPreview = () => {
        this.setState({ previewing: false, previewingPS: false, previewingPsFile: {}, previewingPsPoNum: 0, deletingPS: false })
    }

    closePreview = () => {
        this.setState({ previewing: false, pdfData: [] })
    }
    downloadFile = () => {
        let blob = new Blob([Uint8Array.from(this.props.createdPurchaseOrderPreview)], { type: "application/pdf" });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = this.state.previewingName !== "" ? this.state.previewingName : "PurchaseOrder"//this.state.showingPurchaseOrder;
        link.click();
        // now clear it from memory
        window.URL.revokeObjectURL(link.href)
    };

    openEditPurchaseOrderPage = () => {
        this.setState({
            editingPurchaseOrder: true,
            selectedSupplier: this.state.toEditPurchaseOrder.supplierName,
            taxPercentage: this.state.toEditPurchaseOrder.taxPercentage,
            discountPercentage: this.state.toEditPurchaseOrder.discountPercentage,
            entries: []
        })
    }

    handleEditPurchaseOrderPageCancel = () => {
        this.setState({
            editingPurchaseOrder: false,
            selectedSupplier: '',
            taxPercentage: '',
            discountPercentage: '0.00',
            editingPOqty: true
        })
    }

    cancelPurchaseOrder = () => {
        const data = {
            poNumber: this.state.toEditPurchaseOrder.poNumber
        }
        this.props.cancelPurchaseOrder(data, this.updatePoStatus)
        this.setState({ voidOpen: true, isDelete: false })
        if (this.props.status.success) {
            this.setState({ voidStatus: true })
        } else {
            this.setState({ voidStatus: false })
        }
    }

    handleVoidStatusClose = () => {
        this.setState({
            voidOpen: false,
            voidStatus: true
        })
    }

    handleUndoCancelledClose = () => {
        this.setState({
            undoCancelledFeedbackOpen: false,
            undoCancelStatus: true
        })
    }

    handleToLocationChange = (newLocation, updateTax) => {
        this.setState({
            toLocation: newLocation,
            taxPercentage: updateTax ? newLocation.taxPercentage ? newLocation.taxPercentage : "0" : this.state.taxPercentage
        })
        this.updateField("samePreview", false)
    }

    handleTaxPercentageChange = (newTax) => {
        if (!isNaN(newTax)) {
            this.setState({
                taxPercentage: newTax
            })
            this.updateField("samePreview", false)
        }
    }

    handleDiscountPercentageChange = (newDiscount) => {
        if (!isNaN(newDiscount)) {
            let newEntries = this.state.entries
            for (let i = 0; i < newEntries.length; i++) {
                newEntries[i].discount = newDiscount
            }
            this.setState({
                discountPercentage: newDiscount,
                entries: newEntries
            })
            this.updateField("samePreview", false)
        }
        if(newDiscount==""){
            this.setState({ previewable: false });
        }
        else{
            this.setState({ previewable: true });
        }
    }

    setPreviewable = (option) => {
        this.setState({previewable:option})
    }

    toggleDeleteOption = () => {
        this.setState({
            isDelete: !this.state.isDelete
        })
    }

    toggleUndoDeleteOptions = () => {
        this.setState({
            isUndoDelete: !this.state.isUndoDelete
        })
    }

    handlePdfLoadSuccess = () => {
        this.setState({ pdfLoadSuccess: true })
    }

    handlePdfLoadError = () => {
        this.setState({ pdfLoadSuccess: false })
    }

    showVoidIcon = (canVoidPO) => {
        this.setState({ voiding: canVoidPO })
        this.setState({ canVoidPO: canVoidPO })
    }

    checkCancelledPo = (isCancelled) => {
        this.setState({ isCancelledPO: isCancelled })
    }

    checkCompltedPo = (isCompleted) => {
        this.setState({ isCompleted: isCompleted })
    }

    undoCancelled = () => {
        const data = {
            poNumber: this.state.toEditPurchaseOrder.poNumber
        }
        this.props.undoCancelPurchaseOrder(data, this.updatePoStatus)
        if (this.props.status.success) {
            this.setState({ undoCancelStatus: true, undoCancelledFeedbackOpen: true })
        } else {
            this.setState({ undoCancelStatus: false, undoCancelledFeedbackOpen: true })
        }
    }

    download = () => {
        this.psDownloadRef.current.downloadPSFile()
    }

    setZoom(zoom) {
        let newScale = Math.min(Math.max(this.state.scale + zoom, 1), 2)
        this.setState({
            scale: newScale
        })
    }

    handleSearchTextChange = (e) => {
        this.setState({
            searchText: e.target.value
        })
    }

    switchFromPreviewToEmail = async () => {
        const data = {
            entries: this.state.entries,
            supplier: this.state.selectedSupplier,
            supplierId: this.state.supplierId,
            notes: this.state.notes,
            expectedDate: this.state.expectedDate,
            selectedLocation: this.state.toLocation,
            poNumber: this.state.toEditPurchaseOrder?.poNumber,
            taxPercentage: this.state.taxPercentage,
            discountPercentage: this.state.discountPercentage,
            save: true,
        };
        this.props.sendPurchaseOrderEmail(this.state.supplierId, data, () => window.location.reload())
    }

    sendEmail = async () => {
        const data = {
            purchaseOrderNumber: this.state.previewingName,
        };
        this.props.sendEmail_purchaseOrder(data, () => window.location.reload())
    }

    updateHasPurchaseOrderEmailTemplate = (purchaseOrderNumber) => {
        const data = {
            purchaseOrderNumber: purchaseOrderNumber,
        };
        this.props.fetchPurchaseOrderEmailTemplate(data)
        return false
    }

    deletePackingSlip = () => {
        this.props.deletePackingSlip({packingSlip: this.state.previewingPsFile, poNumber: this.state.previewingPsPoNum})
        this.closeCreatingPreview()
    }

    handlePreview = () => {
        this.state.fetchCreatedPurchaseOrderPreview(this.state.toEditPurchaseOrder.poNumber)
        this.setState({
            previewing: true
        })
    }

    // handleSendSubmit = () => {
    //     const data = {
    //         entries: this.state.entries,
    //         supplier: this.state.selectedSupplier,
    //         supplierId: this.state.supplierId,
    //         notes: this.state.notes,
    //         expectedDate: this.state.expectedDate,
    //         selectedLocation: this.state.toLocation
    //     };
    //     this.props.createSendNewPurchaseOrder(data, () => window.location.reload());
    // }

    render() {
        let headerButtons
        if (this.state.editingPurchaseOrder) {
            if (this.state.previewing) {
                headerButtons = [
                    { icon: <KeyboardBackspaceIcon />, handler: this.closeCreatingPreview, title: "Back" },
                    { icon: <SaveIcon />, handler: this.handleEdit, title: "Save", disabled: !this.state.pdfLoadSuccess },
                    { icon: <SendIcon />, handler: this.switchFromPreviewToEmail, title: "Save and Send", disabled: (!this.state.pdfLoadSuccess || !this.state.purchaseOrderEmailTemplate)},
                    { icon: <ZoomOutIcon />, handler: () => this.setZoom(-0.25), title: "Zoom Out", disabled: !this.state.pdfLoadSuccess },
                    { divider: <span style={{ padding: "5px", marginTop: "5px" }}>{this.state.scale * 100}%</span> },
                    { icon: <ZoomInIcon />, handler: () => this.setZoom(0.25), title: "Zoom In", disabled: !this.state.pdfLoadSuccess }
                ]
            } else {
                headerButtons = [{ icon: <KeyboardBackspaceIcon />, handler: this.handleEditPurchaseOrderPageCancel, title: "Back" },
                { icon: <VisibilityIcon />, handler: this.previewExistingPurchaseOrder, title: "Preview" }]
                if (!this.state.previewable) headerButtons[1].disabled = true
            }
        } else if (this.state.editingPOqty) {
            if (this.state.previewing) {
                headerButtons = [
                    { icon: <KeyboardBackspaceIcon />, handler: this.closeCreatingPreview, title: "Back" },
                    { icon: <ZoomOutIcon />, handler: () => this.setZoom(-0.25), title: "Zoom Out", disabled: !this.state.pdfLoadSuccess },
                    { divider: <span style={{ padding: "5px", marginTop: "5px" }}>{this.state.scale * 100}%</span> },
                    { icon: <ZoomInIcon />, handler: () => this.setZoom(0.25), title: "Zoom In", disabled: !this.state.pdfLoadSuccess }
                ]
            }
            else if (this.state.previewingPS && !this.state.deletingPS) {
                headerButtons = [
                    { icon: <KeyboardBackspaceIcon />, handler: this.closeCreatingPreview, title: "Back" },
                    { icon: <ZoomOutIcon />, handler: () => this.setZoom(-0.25), title: "Zoom Out", disabled: !this.state.pdfLoadSuccess },
                    { divider: <span style={{ padding: "5px", marginTop: "5px" }}>{this.state.scale * 100}%</span> },
                    { icon: <ZoomInIcon />, handler: () => this.setZoom(0.25), title: "Zoom In", disabled: !this.state.pdfLoadSuccess },
                    { icon: <GetApp />, handler: () => this.download(), title: "Download PDF" },
                    { icon: <DeleteIcon />, handler: () => this.setState({deletingPS: true}), title: "Delete" },
                ]
            }
            else if (this.state.previewingPS && this.state.deletingPS) {
                headerButtons = [
                    { icon: <KeyboardBackspaceIcon />, handler: this.closeCreatingPreview, title: "Back" },
                    { icon: <ZoomOutIcon />, handler: () => this.setZoom(-0.25), title: "Zoom Out", disabled: !this.state.pdfLoadSuccess },
                    { divider: <span style={{ padding: "5px", marginTop: "5px" }}>{this.state.scale * 100}%</span> },
                    { icon: <ZoomInIcon />, handler: () => this.setZoom(0.25), title: "Zoom In", disabled: !this.state.pdfLoadSuccess },
                    { icon: <ClearIcon />, handler: () => this.setState({deletingPS: false}), title: "Cancel" },
                    { icon: <DeleteIcon />, handler: this.deletePackingSlip, title: "Confirm Delete" },
                ]
            }
            else {
                if (this.state.isDelete) {
                    headerButtons = [{ icon: <KeyboardBackspaceIcon />, handler: this.handleCancel, title: "Back" },
                    { icon: <DescriptionIcon/>, handler: this.handlePreview, title: "View Purchase Order" },
                    { icon: <EditIcon />, handler: this.openEditPurchaseOrderPage, title: "Edit Purchase Order" },
                    { divider: <span className="line-divider">|</span> },
                    { icon: <ClearIcon />, handler: this.toggleDeleteOption, title: "Cancel" },
                    { icon: <BlockIcon />, handler: this.cancelPurchaseOrder, title: "Cancel Purchase Order" },
                    ]
                } else {
                    if (this.state.isCompleted) {
                        headerButtons = [{ icon: <KeyboardBackspaceIcon />, handler: this.handleCancel, title: "Back" },
                            { icon: <DescriptionIcon/>, handler: this.handlePreview, title: "View Purchase Order" },
                            { icon: <EditIcon />, handler: this.openEditPurchaseOrderPage, title: "Edit Purchase Order" }
                        ]
                    }
                    else if (this.state.isCancelledPO) {
                        if (this.state.isUndoDelete) {
                            headerButtons = [{ icon: <KeyboardBackspaceIcon />, handler: this.handleCancel, title: "Back" },
                            { icon: <DescriptionIcon/>, handler: this.handlePreview, title: "View Purchase Order" },
                            { divider: <span className="line-divider">|</span> },
                            { icon: <ClearIcon />, handler: this.toggleUndoDeleteOptions, title: "Cancel" },
                            { icon: <UndoIcon />, handler: this.undoCancelled, title: "Undo Cancelled" },]
                        } else {
                            headerButtons = [{ icon: <KeyboardBackspaceIcon />, handler: this.handleCancel, title: "Back" },
                            { icon: <DescriptionIcon/>, handler: this.handlePreview, title: "View Purchase Order" },
                            { icon: <UndoIcon />, handler: this.toggleUndoDeleteOptions, title: "Undo Cancelled" },]
                        }
                    } else if (!this.state.canVoidPO) {
                        headerButtons = [{ icon: <KeyboardBackspaceIcon />, handler: this.handleCancel, title: "Back" },
                        { icon: <DescriptionIcon/>, handler: this.handlePreview, title: "View Purchase Order" },
                        { icon: <EditIcon />, handler: this.openEditPurchaseOrderPage, title: "Edit Purchase Order" }
                        ]
                    } else {
                        headerButtons = [{ icon: <KeyboardBackspaceIcon />, handler: this.handleCancel, title: "Back" },
                        { icon: <DescriptionIcon/>, handler: this.handlePreview, title: "View Purchase Order" },
                        { icon: <EditIcon />, handler: this.openEditPurchaseOrderPage, title: "Edit Purchase Order" },
                        { icon: <BlockIcon />, handler: this.toggleDeleteOption, title: "Cancel Purchase Order" }
                        ]
                    }
                }
            }
        } else if (this.state.create) {
            if (this.state.previewing) {
                headerButtons = [{ icon: <KeyboardBackspaceIcon />, handler: this.closeCreatingPreview, title: "Back" },
                { icon: <SaveIcon />, handler: this.handleSubmit, title: "Save", disabled: !this.state.pdfLoadSuccess },
                { icon: <SendIcon />, handler: this.switchFromPreviewToEmail, title: "Save and Send", disabled:(!this.state.pdfLoadSuccess || !this.state.purchaseOrderEmailTemplate)},
                { icon: <ZoomOutIcon />, handler: () => this.setZoom(-0.25), title: "Zoom Out", disabled: !this.state.pdfLoadSuccess },
                { divider: <span style={{ padding: "5px", marginTop: "5px" }}>{this.state.scale * 100}%</span> },
                { icon: <ZoomInIcon />, handler: () => this.setZoom(0.25), title: "Zoom In", disabled: !this.state.pdfLoadSuccess }]
            } else {
                headerButtons = [{ icon: <KeyboardBackspaceIcon />, handler: this.handleCancel, title: "Cancel" },
                { icon: <VisibilityIcon />, handler: this.previewPDF, title: "Preview" }]
                if (!this.state.previewable) headerButtons[1].disabled = true
            }
        } else {
            if (this.state.previewing) {
                headerButtons = [{ icon: <KeyboardBackspaceIcon />, handler: this.closePreview, title: "Back" },
                { icon: <GetAppIcon />, handler: this.downloadFile, title: "Download", disabled: !this.state.pdfLoadSuccess },
                { icon: <SendIcon />, handler: this.sendEmail, title: "Send", disabled: (!this.state.pdfLoadSuccess || !this.props.hasPurchaseOrderEmailTemplate)},
                { icon: <ZoomOutIcon />, handler: () => this.setZoom(-0.25), title: "Zoom Out", disabled: !this.state.pdfLoadSuccess },
                { divider: <span style={{ padding: "5px", marginTop: "5px" }}>{this.state.scale * 100}%</span> },
                { icon: <ZoomInIcon />, handler: () => this.setZoom(0.25), title: "Zoom In", disabled: !this.state.pdfLoadSuccess }]
                if (this.props.createdPurchaseOrderPreview.length === 0) headerButtons[1].disabled = true
            } else {
                headerButtons = [{
                    icon: <AddCircleIcon />,
                    handler: this.createPurchaseOrder,
                    title: CREATE_PURCHASE_ORDER
                }]
            }
        }
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <ErrorFeedbackBar />
                <Paper className="paper">
                    <FeedbackBar
                        open={this.state.voidOpen}
                        handleClose={this.handleVoidStatusClose}
                        severity={this.state.voidStatus ? SUCCESS : ERROR}
                        message={this.state.voidStatus ? 'PO Cancel Success' : 'PO Cancel Failed'}
                    />
                    <FeedbackBar
                        open={this.state.undoCancelledFeedbackOpen}
                        handleClose={this.handleUndoCancelledClose}
                        severity={this.state.undoCancelStatus ? SUCCESS : ERROR}
                        message={this.state.undoCancelStatus ? 'PO Undo Cancelled Success' : 'PO Undo Cancelled Failed'}
                    />
                    <FeedbackBar
                        open={!this.state.pdfLoadSuccess}
                        handleClose={this.handlePdfLoadSuccess}
                        severity={ERROR}
                        message={"Failed to Load PDF"}
                    />
                    <CustomToolbar
                        title={PURCHASE_ORDER}
                        createTitle={CREATE_PURCHASE_ORDER}
                        backTitle={CREATE_PURCHASE_ORDER}
                        onClick={this.createPurchaseOrder}
                        handleClose={this.handleCancel}
                        creatingOrder={this.state.create}
                        headerButtons={headerButtons}
                    />
                    {this.state.editingPurchaseOrder &&
                        <EditPurchaseOrder
                            updatePurchaseOrder={this.updatePurchaseOrder}
                            handleCancel={this.handleCancel}
                            changeHeader={this.changeHeader}
                            updateField={(name, value) => this.updateField(name, value)}
                            updateErrQuan={(arr, index) => this.updateErrQuan(arr, index)}
                            pdfData={this.state.pdfData}
                            previewing={this.state.previewing}
                            entries={this.state.entries}
                            selectedSupplier={this.state.selectedSupplier}
                            supplierId={this.state.supplierId}
                            samePreview={this.state.samePreview}
                            expectedDate={this.state.expectedDate}
                            purchaseOrder={this.state.toEditPurchaseOrder}
                            editingPurchaseOrder={true}
                            selectedLocation={this.state.toLocation}
                            taxPercentage={this.state.taxPercentage}
                            handleTaxChange={this.handleTaxPercentageChange}
                            discountPercentage={this.state.discountPercentage}
                            handleDiscountChange={this.handleDiscountPercentageChange}
                            locations={this.props.availableLocations}
                            handleLocationChange={this.handleToLocationChange}
                            suppliers={this.props.suppliers}
                            handlePdfLoadSuccess={this.handlePdfLoadSuccess}
                            handlePdfLoadError={this.handlePdfLoadError}
                            pdfLoadSuccess={this.state.pdfLoadSuccess}
                            notes={this.state.notes}
                            scale={this.state.scale}
                            errEditQty={this.state.errEditQty}
                            hasPricingRole={this.props.user.routes.includes('po-pricing')}
                            setPreviewable={this.setPreviewable}
                            previewable={this.state.previewable}
                            discountPercentage={this.state.discountPercentage}
                            selectedSupplierInfo={this.state.selectedSupplierInfo}
                        /> ||
                        this.state.editingPOqty &&
                        <PurchaseOrderDetails
                            updatePurchaseOrder={this.updatePurchaseOrder}
                            deleteInventoryReceived={this.deleteInventoryReceived}
                            previewing={this.state.previewing}
                            handleEditExpectedDeliveryDate={this.handleEditExpectedDeliveryDate}
                            pdfData={this.props.createdPurchaseOrderPreview}
                            previewCreatedPurchaseOrder={this.props.previewCreatedPurchaseOrder}
                            purchaseOrder={this.state.toEditPurchaseOrder}
                            fetchCreatedPurchaseOrderPreview={this.state.fetchCreatedPurchaseOrderPreview}
                            allPurchaseOrders={this.props.allPurchaseOrders}
                            handlePdfLoadSuccess={this.handlePdfLoadSuccess}
                            handlePdfLoadError={this.handlePdfLoadError}
                            pdfLoadSuccess={this.state.pdfLoadSuccess}
                            canVoidPO={this.showVoidIcon}
                            checkCancelledPo={this.checkCancelledPo}
                            checkCompletedPo={this.checkCompltedPo}
                            previewPackingSlipFile={this.props.previewPackingSlipFile}
                            openPackingSlipPreview={this.openPackingSlipPreview}
                            previewingPS={this.state.previewingPS}
                            scale={this.state.scale}
                            user={this.props.user}
                            ref={this.psDownloadRef}
                        /> ||
                        this.state.create &&
                        <CreatePurchaseOrder
                            emailTemplates={this.props.emailTemplates}
                            handleCancel={this.handleCancel}
                            changeHeader={this.changeHeader}
                            updateField={(name, value) => this.updateField(name, value)}
                            pdfData={this.state.pdfData}
                            previewing={this.state.previewing}
                            entries={this.state.entries}
                            selectedSupplier={this.state.selectedSupplier}
                            supplierId={this.state.supplierId}
                            samePreview={this.state.samePreview}
                            expectedDate={this.state.expectedDate}
                            selectedLocation={this.state.toLocation}
                            taxPercentage={this.state.taxPercentage}
                            handleTaxChange={this.handleTaxPercentageChange}
                            discountPercentage={this.state.discountPercentage}
                            handleDiscountChange={this.handleDiscountPercentageChange}
                            locations={this.props.availableLocations}
                            handleLocationChange={this.handleToLocationChange}
                            handlePdfLoadSuccess={this.handlePdfLoadSuccess}
                            handlePdfLoadError={this.handlePdfLoadError}
                            pdfLoadSuccess={this.state.pdfLoadSuccess}
                            notes={this.state.notes}
                            scale={this.state.scale}
                            hasPricingRole={this.props.user.routes.includes('po-pricing')}
                            selectedSupplierInfo={this.state.selectedSupplierInfo}
                        />
                        ||
                        <PurchaseOrdersTable
                            updatePurchaseOrder={this.updatePurchaseOrder}
                            deleteInventoryReceived={this.deleteInventoryReceived}
                            //changeHeader={this.changeHeader}
                            rows={this.props.allPurchaseOrders}
                            previewing={this.state.previewing}
                            updateField={(name, value) => this.updateField(name, value)}
                            pdfData={this.props.createdPurchaseOrderPreview}
                            previewCreatedPurchaseOrder={this.props.previewCreatedPurchaseOrder}
                            updateHasPurchaseOrderEmailTemplate={this.updateHasPurchaseOrderEmailTemplate}
                            storeCreatedPurchaseOrderPreview={this.props.storeCreatedPurchaseOrderPreview}
                            editingPO={this.state.editingPOqty}
                            handleEditPo={this.editPurchaseOrderQty}
                            fromDate={this.state.fromDate}
                            toDate={this.state.toDate}
                            page={this.state.page}
                            setPage={this.setPage}
                            rowsPerPage={this.state.rowsPerPage}
                            setRowsPerPage={this.setRowsPerPage}
                            handleDateChange={this.onDateFiltersInputChange}
                            handlePdfLoadSuccess={this.handlePdfLoadSuccess}
                            handlePdfLoadError={this.handlePdfLoadError}
                            pdfLoadSuccess={this.state.pdfLoadSuccess}
                            saveTableSetting={this.props.saveTableSetting}
                            userInfo={this.props.user}
                            tableSetting={this.props.tableSetting}
                            scale={this.state.scale}
                            searchText={this.state.searchText}
                            handleSearchTextChange={this.handleSearchTextChange}
                            fromDate={this.state.fromDate}
                            toDate={this.state.toDate}
                            applyDateFilters={this.applyDateFilters}
                            fetchAllPurchaseOrders={this.props.fetchAllPurchaseOrders}
                            selectedSupplierInfo={this.state.selectedSupplierInfo}
                        />
                    }
                </Paper>
                {/*<RenderPurchaseOrderPDFPreview
                    data={this.props.createdPurchaseOrderPreview}
                    open={this.state.showingCreatedPreview}
                    download={this.downloadFile}
                    handlePreviewClose={()=>{this.setState({showingCreatedPreview: false})}}
                />*/}
            </MuiPickersUtilsProvider>
        )
    }
}

const mapStateToProps = (state) => ({
    PDFpreviewReady: PDFpreviewReadySelector(state),
    allPurchaseOrders: allPurchaseOrdersSelector(state),
    newPurchaseOrders: newPurchaseOrdersSelector(state),
    partialPurchaseOrders: partialPurchaseOrdersSelector(state),
    completedPurchaseOrders: completedPurchaseOrdersSelector(state),
    createdPurchaseOrderPreview: createdPurchaseOrderPreviewSelector(state),
    user: userInfoSelector(state),
    status: statusSelector(state),
    availableLocations: availableLocationsSelector(state),
    suppliers: realSuppliersSelector(state),
    tableSetting: tableSettingSelector(state),
    emailTemplates: emailTemplatesSelector(state),
    hasPurchaseOrderEmailTemplate: hasPurchaseOrderEmailTemplateSelector(state),
});

const actionCreators = {
    fetchAllPurchaseOrders,
    updatePurchaseOrder,
    deleteInventoryReceived,
    createNewPurchaseOrder,
    createSendNewPurchaseOrder,
    getPDFpreview,
    cachePDFpreview,
    previewPackingSlipFile,
    previewCreatedPurchaseOrder,
    storeCreatedPurchaseOrderPreview,
    deletePackingSlip,
    cancelPurchaseOrder,
    listSuppliers,
    listAvailableLocations,
    editPurchaseOrder,
    undoCancelPurchaseOrder,
    resetPdfPreviewData,
    saveTableSetting,
    getTableSetting,
    getEmailTemplates,
    sendPurchaseOrderEmail,
    fetchPurchaseOrderEmailTemplate,
    sendEmail_purchaseOrder,
    updateExpectedDeliveryDate
}

PurchaseOrders.propTypes = {
    allPurchaseOrders: PropTypes.array,
    newPurchaseOrders: PropTypes.array,
    partialPurchaseOrders: PropTypes.array,
    completedPurchaseOrders: PropTypes.array,
    availableLocations: PropTypes.array,
}

PurchaseOrders.defaultProps = {
    allPurchaseOrders: [],
    newPurchaseOrders: [],
    partialPurchaseOrders: [],
    completedPurchaseOrders: [],
    availableLocations: []
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, PurchaseOrders);