import {
    STORE_LINKED_USERS,
    STORE_ROLES
} from "../../constants/userManagement";
import {ROLE_DEPTH_AND_ORDER} from "../../../components/settings/ManageUsers/constants";

const initialState = {
    users: [],
    roles: [],
}

const setRoles = (actionRoles) => {
    let newRoles = []
    for (const [key, value] of Object.entries(ROLE_DEPTH_AND_ORDER)) {
        if (actionRoles.includes(key)) {
            newRoles.push(key)
        }
    }
    return newRoles
}

export default (state = initialState, action) => {
    switch (action.type) {
        case STORE_LINKED_USERS:
            return {
                ...state,
                users: action.users
            }
        case STORE_ROLES:
            return {
                ...state,
                roles: setRoles(action.roles)
            }
        default:
            return state
    }
}