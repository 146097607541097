import React from "react";
import withShipment from "../../../withShipment";

// styling
import "./ManageBankAccounts.css"

// constants
import {
    ADD_BANK_ACCOUNT_TOOLTIP, BANK_ACCOUNTS_TITLE,
    BANK_PAGES,
} from "./constants";


import FeedbackBar from "../../feedbackBar/FeedbackBar";
import ManageBankAccountsTable from "./ManageBankAccountsTable";
import BankAccountForm from "./BankAccountForm";
import {clearStatus} from "../../../redux/actions/global";
import {bankAccountsSelector} from "../../../redux/selectors/settings";
import {
    deleteBankAccount,
    listAllBankAccounts,
    previewBankFile,
    saveBankAccount
} from "../../../redux/actions/settings";
import PropTypes from "prop-types";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";


class ManageBankAccounts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bankAccounts: [],
            currentPage: BANK_PAGES.TABLE,
            feedbackBarOpen: false,
            beforeEditRow: null,
            editingRowIndex: null,
            deleteId: "",
            editBankAccount: null,
        }
    }

    handleDeleteRow = (deleteId) => {
        this.props.deleteBankAccount({bankAccountId: deleteId})
    }

    componentDidMount() {
        this.props.listAllBankAccounts()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.bankAccounts !== this.props.bankAccounts) {
            this.setState({
                bankAccounts: this.props.bankAccounts,
                editingRowIndex: null,
            })
        }
    }

    handleEditRow = (rowIndex) => {
        this.setState({ editBankAccount: this.state.bankAccounts[rowIndex] }, () => {
          this.setCurrentPage(BANK_PAGES.ADD_BANK, {})
        })
    }

    handleEditingClear = () => {
        this.setState({ editBankAccount: null }, () => {
            this.setCurrentPage(BANK_PAGES.TABLE, {})
        })
    }

    setCurrentPage = (newPage) => {
        this.setState({
            currentPage: newPage
        })
    }
    displayTable = () => {
        return (
            <ManageBankAccountsTable
                handleEditRow={this.handleEditRow}
                handleDeleteRow={this.handleDeleteRow}
                 bankAccounts={this.state.bankAccounts}
                setCurrentPage={this.setCurrentPage}
                openFeedbackBar={this.openFeedbackBar}
            />
        )
    }

    displayForm = () => {
        return (
            <BankAccountForm
                handleEditingClear={this.handleEditingClear}
                saveBankAccount={this.props.saveBankAccount}
                editBankAccount={this.state.editBankAccount}
                setCurrentPage={this.setCurrentPage}
                openFeedbackBar={this.openFeedbackBar}
                previewBankFile={this.props.previewBankFile}
            />
        )
    }

    displayCurrentPage = () => {
        switch (this.state.currentPage) {
            case BANK_PAGES.TABLE:
                return this.displayTable()
            case BANK_PAGES.ADD_BANK:
                return this.displayForm()
            default:
                console.log("Error: current transactions page doesn't exist")
                return <div>We encountered an error. Please refresh the page.</div>
        }
    }

    openFeedbackBar = () => {
        this.setState({feedbackBarOpen: true})
    }

    closeFeedbackBar = () => {
        this.setState({ feedbackBarOpen: false }, () => {
            this.props.clearStatus()
        })
    }

    render() {
        return (
            <React.Fragment>
                {/*<ErrorFeedbackBar/>*/}
                <FeedbackBar
                    open={this.state.feedbackBarOpen}
                    handleClose={this.closeFeedbackBar}
                    severity={'success'}
                    message={"Saved"}
                />
                {this.displayCurrentPage()}
            </React.Fragment>
        )
    }
}


const actionCreators = {
    clearStatus,
    listAllBankAccounts,
    deleteBankAccount,
    saveBankAccount,
    previewBankFile
}


const mapStateToProps = (state) => ({
    bankAccounts: bankAccountsSelector(state),
})


ManageBankAccounts.propTypes = {
    bankAccounts: PropTypes.array,
}

ManageBankAccounts.defaultProps = {
    bankAccounts: [],
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, ManageBankAccounts);