export const DAMAGE_PAGE_NAME = "Damages"

export const CREATE_DAMAGE_ENTRY = "Create Damage Entry"
export const SAVE_DAMAGE_ENTRY = "Save Damage Entry"
export const DAMAGE_BACK_BUTTON = "Back"
export const DAMAGE_SAVE_BUTTON = "Save"

export const LIST_DAMAGES_CELLS = [
    { id: 'creationDate',label: 'Reported Date'},
    { id: 'poNumber',label: 'PO #'},
    { id: 'supplier',label: 'Supplier'},
    { id: 'shortcode',label: 'Shortcode'},
    { id: 'qtyDamaged',label: 'Quantity Damaged'},
    { id: 'qtyReplaced',label: 'Quantity Replaced'},
    { id: 'qtyCredited',label: 'Quantity Credited'},
    { id: 'qtyRemaining',label: 'Pending Replacements'}
];

export const DAMAGES_PAGES = {
    TABLE: "table",
    CREATE: "create",
    UPDATE: "update"
}

export const EDIT_ENTRY = "Edit";
export const CANCEL_EDIT_ENTRY = "Cancel Edit";

export const DEFAULT_SORT_BY_HEAD_CELL = 'createDate';
export const ROWS_PER_PAGE_LABEL = "Damage entries per page"
export const HIDE_COMPLETED_DAMAGES_TEXT = "Hide Complete Damages"
