import React from 'react';

// Custom Components
import PropTypes from "prop-types";
import withShipment from "../../../../withShipment";
import EditInvoiceTemplate from "./EditInvoiceTemplate"

// Redux
import { suppliersWithoutTemplateSelector } from "../../../../redux/selectors/invoices";
import { fetchSuppliersWithoutTemplate } from "../../../../redux/actions/invoices";

// Material UI
import {Select, InputLabel, FormControl, Input, Button, MenuItem} from "@material-ui/core"

class CreateInvoiceTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.changeTemplate = this.changeTemplate.bind(this)
    }

    componentDidMount() {
        this.props.fetchSuppliersWithoutTemplate()
    }

    changeTemplate(event) {
        if (event.target.files.length === 0) return
        let file = event.target.files[0]
        let fileReader = new FileReader();

        fileReader.onload = (e) => {
            let contents = new Uint8Array(e.target.result)
            this.props.updateField("template", { name: file.name, contents: contents })
        }
        fileReader.readAsArrayBuffer(file)
    }

    render() {
        return (<>
            {this.props.editing &&
            <EditInvoiceTemplate
                pdf={this.props.template}
                fieldDefined={this.props.fieldDefined}
                updateParentFields={this.props.updateParentFields}
                fieldError={this.props.fieldError}
                resetError={this.props.resetError}
                scale={this.props.scale}
                updateField={this.props.updateField}
                dateFormats={this.props.dateFormats}
                setDateFormat={this.props.setDateFormat}
                selectedFormat={this.props.selectedFormat}
            /> || <>
                <div style={{padding: "100px", paddingBottom: "50px", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <FormControl variant="outlined" style={{flexGrow: 1}}>
                        <InputLabel id="SupplierSelect">Supplier</InputLabel>
                        <Select label="Supplier"
                                labelId="SupplierSelect"
                                value={this.props.selectedSupplier}
                                style={{width: "100%"}}
                                onChange={(e) => this.props.updateField("selectedSupplier", e.target.value)}>
                            <MenuItem value="none">&nbsp;</MenuItem>
                            {this.props.openSuppliers.map((supplier, index) => (
                                <MenuItem key={index} value={supplier.supplierId}>{supplier.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <div style={{textAlign: "center", marginLeft: "20px"}}>
                        <label htmlFor="btn-upload">
                            <input
                                id="btn-upload"
                                name="btn-upload"
                                style={{ display: 'none' }}
                                type="file"
                                accept="application/pdf"
                                onChange={this.changeTemplate} />
                            <Button className="btn-choose" variant="contained" color="primary" component="span">
                                Select Template
                            </Button>
                            <div style={{paddingLeft: "5px", display: "inline-block"}}>
                                {this.props.template.contents.length > 0 && this.props.template.name}
                            </div>
                        </label>
                    </div>
                </div>
                <div style={{display: "flex", justifyContent: "space-between", margin: "auto", width: "200px"}}>
                    <Button className="btn-choose" variant="outlined" component="span" onClick={this.props.exit}>Cancel</Button>
                    <Button
                        className="btn-choose"
                        variant="contained"
                        color="primary"
                        component="span"
                        onClick={this.props.next}
                        disabled={this.props.selectedSupplier === "none" || this.props.template.contents.length === 0}
                    >
                        Next
                    </Button>
                </div>
            </>}
        </>)
    }
}

const mapStateToProps = (state) => ({
    openSuppliers: suppliersWithoutTemplateSelector(state)
});

const actionCreators = {
    fetchSuppliersWithoutTemplate
}
CreateInvoiceTemplate.propTypes = {
    openSuppliers: PropTypes.array,
    selectedSupplier: PropTypes.string
}

CreateInvoiceTemplate.defaultProps = {
    openSuppliers: [],
    selectedSupplier: "none"
}

export default withShipment({
    mapStateToProps,
    actionCreators
},CreateInvoiceTemplate);