import React from 'react';

// material components
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import StyledBadge from '@material-ui/core/Badge';
import IconButton from "@material-ui/core/IconButton";

// custom components
import AlertsDrawer from "./AlertsDrawer";
import withShipment from "../../withShipment";

// constants

// redux
import {alertEntriesSelector} from "../../redux/selectors/alerts";
import {userInfoSelector} from "../../redux/selectors/auth";
import {fetchAlerts, snoozeAlert, resolveAlert} from "../../redux/actions/alerts";
import PropTypes from "prop-types";
import {FETCH_UNSHIPPED_ALERTS_TIME} from "./constants";
import {getUsers} from "../../redux/actions/auth";
import {tableSettingSelector} from "../../redux/selectors/account";
import {getTableSetting} from "../../redux/actions/account";
import {ROLE_ADMIN} from "../global/constants";

// styling

class Alerts extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            alertDrawerOpen: false,
            alerts: []
        }
    }

    handleAlertDrawerClose = () => {
        this.setState({
            alertDrawerOpen: false
        });
    }

    componentDidMount() {
        this.props.getTableSetting({})

        // loses reference to "this" within setInterval
        let self = this

        const data = {
            selectedShipAddressId: this.props.userInfo.locationIds ?? [this.props.userInfo.defaultLocationId],
            authority: this.props.userInfo.authority,
            fetchUnshipped: false
        }
        let currDate = new Date();
        if (currDate.getHours() >= FETCH_UNSHIPPED_ALERTS_TIME) {
            data.fetchUnshipped = true;
        } else {
            data.fetchUnshipped = false;
        }
        this.props.fetchAlerts(data)

        this.setState({alerts: this.props.alertEntries})

        // just make the call every 10 minutes
        // setInterval(function () {
        //     if (currDate.getHours() >= FETCH_UNSHIPPED_ALERTS_TIME) {
        //         data.fetchUnshipped = true;
        //     } else {
        //         data.fetchUnshipped = false;
        //     }
        //     self.props.fetchAlerts(data);
        // }, 600000);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.alertEntries !== this.props.alertEntries || prevProps.account !== this.props.account) {
            this.removeSnoozedAlerts()
        }
    }

    removeSnoozedAlerts = () => {
        let snoozedAlertIds = this.props.account.snoozedAlerts ? this.props.account.snoozedAlerts.map(alert => alert.alertId) : [];
        let unsnoozedAlerts = this.props.alertEntries.filter(alert => {
            return !snoozedAlertIds.includes(alert.alertId)
        })

        this.setState({alerts: unsnoozedAlerts})
    }

    toggleAlertDrawer = () => {
        this.setState({
            alertDrawerOpen: true
        })
    }
    handleResolveAlert = (alertId, index) => {
        let data = {
            alertId: alertId,
            shippingTemplateChangedIndex: index
        }
        this.props.resolveAlert(data)
    }


    render() {
        return (
            <>
                <IconButton aria-label="alerts" onClick={this.toggleAlertDrawer}>
                    <StyledBadge badgeContent={this.state.alerts.length} color="error">
                        <NotificationsActiveIcon
                            color='secondary'
                        />
                    </StyledBadge>
                </IconButton>

                <AlertsDrawer
                    alertDrawerOpen={this.state.alertDrawerOpen}
                    handleAlertDrawerClose={this.handleAlertDrawerClose}
                    alertEntries={this.state.alerts}
                    noAlerts={this.state.alerts.length === 0}
                    closeDrawer={this.handleAlertDrawerClose}
                    snoozeAlert={this.props.snoozeAlert}
                    resolveAlert={this.handleResolveAlert}
                    removeSnoozedAlerts={this.removeSnoozedAlerts}
                />
            </>
        );
    }

}

const mapStateToProps = (state) => ({
    alertEntries: alertEntriesSelector(state),
    userInfo: userInfoSelector(state),
    account: tableSettingSelector(state)
})

const actionCreators = {
    fetchAlerts,
    getUsers,
    snoozeAlert,
    resolveAlert,
    getTableSetting
}

Alerts.propTypes = {
    alertEntries: PropTypes.array,
    userInfo: PropTypes.array,
    account: PropTypes.object
};

Alerts.defaultProps = {
    alertEntries: [],
    userInfo: [],
    account: {}
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, Alerts);

