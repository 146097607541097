import {
    AUTH_LOGIN,
    STORE_TOKEN, STORE_USERS, UPDATE_SELECTED_LOCATION, UPDATE_USER_INFO
} from "../../constants/auth";
import storage from 'redux-persist/lib/storage';
import {persistReducer} from "redux-persist";

import {AUTH_KEY} from "../../constants/keys";

const initialState = {
    token: null,
    userInfo: {},
    users: [],
    isAuthenticated: false
}

const auth = (state = initialState, action) => {
    switch (action.type) {
        case STORE_TOKEN:
            return {
                ...state,
                token: action.token,
                userInfo: action.userInfo,
                isAuthenticated: action.isAuthenticated
            }
        case STORE_USERS:
            return {
                ...state,
                users: action.users
            }
        case UPDATE_USER_INFO:
            return {
                ...state,
                userInfo: action.userInfo
            }
        case UPDATE_SELECTED_LOCATION:
            return {
                ...state,
                userInfo: { ...state.userInfo, selectedLocationId: action.selectedLocation }
            }
        default:
            return state
    }
}

const config = {
    key: AUTH_KEY,
    storage: storage,
    whitelist: ['isAuthenticated', 'userInfo', 'users'],
};

export default persistReducer(config, auth)