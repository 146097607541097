import React from 'react';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {Button, IconButton} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import {CREATE_MANUAL_INVENTORY_TITLE, ADD_SHORTCODE, FTL} from "./constants";

// material components
import Autocomplete from "@material-ui/lab/Autocomplete";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";

// custom components


// constants

// styling
import './CreateInventoryAdjustmentDialog.css'
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import Header from "../header/Header";
import {KeyboardDatePicker} from "@material-ui/pickers";


class CreateInventoryAdjustmentDialog extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            type: '',
            fromShipAddressId: this.props.fromShipAddressId,
            fromShipAddressName: '',
            toShipAddressId: '',
            shortcode: '',
            quantity: '',
            ftlSelected: false,
            canSubmit: true,
            inventoryAdjustmentTypes: [],
            shortcodes: [{}],
            date: new Date().getTime(),
            notes: '',
        };

        this.baseState = this.state
    }

    onChange = (e) => {

        if(typeof e === "string"){
            this.setState({
                shortcode: e
            })
        }
        else{
            this.setState({
                quantity: e.target.value
            })
        }


    }

    onFTLChange = (name, value, index) => {
        const newShortcodes = [...this.state.shortcodes]
        newShortcodes[index][name] = value
        this.setState({
            shortcodes: newShortcodes
        })
    }

    handleReset = () => {
        this.setState({
            type: '',
            fromShipAddressId: this.props.fromShipAddressId,
            toShipAddressId: '',
            shortcode: '',
            quantity: '',
            ftlSelected: false,
            canSubmit: true,
        })
    }

    handleTypeChange = (value) => {
        if(!value){
            this.setState({
                type: '',
                ftlSelected: false
            })
            return
        }
        let type = value.type;

        this.setState({
            type: type,
            ftlSelected: type === FTL
        })

    }

    handleClose = () => {
        this.handleReset()
        this.props.handleClose();
    }

    handleSubmit = (e) => {
        e.showLoadingOverlay = true  // Prevents spam clicking the button

        const today = new Date();
        const lastWeekDate = new Date(today);
        lastWeekDate.setHours(0);
        lastWeekDate.setMinutes(0);
        lastWeekDate.setSeconds(0);
        lastWeekDate.setDate(lastWeekDate.getDate() - 7)

        this.props.handleSubmit(
            {   ...this.state,
                fromDate: lastWeekDate
            }
        );
        this.handleReset()
    }

    handleToLocationChange = (value) => {
        this.setState({
            toShipAddressId: value?.shipAddressId
        })
    }

    componentDidMount() {
        let curLocation = this.props.availableLocations.find(location=>{
                                return location.shipAddressId === this.props.fromShipAddressId
                            })
        let {inventoryAdjustmentTypes} = this.props
        inventoryAdjustmentTypes.sort((a, b)=>{
            if(a.type > b.type) return  1
            if(a.type < b.type) return  -1
            return  0
        })
        this.setState({
            fromShipAddressName: curLocation.name,
            fromShipAddressId: this.props.fromShipAddressId,
            inventoryAdjustmentTypes: inventoryAdjustmentTypes
        })
    }

    addFTLShortcode = () => {
        const shortcodes = [...this.state.shortcodes]
        
        shortcodes.push({})
        this.setState({
            shortcodes: shortcodes
        })
    }

    deleteFTLShortcode = (index) => {
        let shortcodes = [...this.state.shortcodes]
        shortcodes.splice(index,1)
        this.setState({
            shortcodes: shortcodes
        })
    }

    checkShortcode = () => {
        return !this.state.shortcode || this.state.shortcode.length == 0 ||
        !this.state.quantity || this.state.quantity.length == 0
    }

    checkShortcodes = () => {
        if(this.state.shortcodes?.length > 0){
            var hasEmpty = false
            this.state.shortcodes.forEach(n => {
                if(!n.shortcode || n.shortcode.length == 0 || !n.quantity || n.quantity.length == 0){
                    return hasEmpty = true
                }
            })
            return hasEmpty
        }else{
            return true
        }
    }

    checkEmpty = () => {
        if(this.state.ftlSelected){
            return this.checkShortcodes()
        }else{
            return this.checkShortcode()
        }
    }

    handleDateChange = (date) => {
        // need error checking
        if (!date || isNaN(date.getTime())) {
            //Null or Invalid date
        } else {
            date.setHours(17);
            date.setMinutes(0);
            date.setSeconds(0);

            const newDate = new Date()
            newDate.setHours(17);
            newDate.setMinutes(0);
            newDate.setSeconds(0);
            if (date > newDate)
                return
            this.setState({
                date: date.getTime()
            });
            //this.props.handleDateChange(date, isFromDate)
        }
    }

    render() {
        return (
            <div className='create-adjustment-container'>
                <DialogTitle>
                    {CREATE_MANUAL_INVENTORY_TITLE}
                </DialogTitle>
                
                {/*Filter by Type and Manufacturer*/}
                <div className='create-adjustment-input-outer-container'>
                    
                <div className="create-adjustment-input-container">
                    <KeyboardDatePicker
                        autoOk
                        name="date"
                        variant="inline"
                        inputVariant="outlined"
                        label="Date"
                        format="yyyy/MM/dd"
                        value={this.state.date}
                        InputAdornmentProps={{ position: "start" }}
                        style={{ width: 250 }}
                        onChange={date => this.handleDateChange(date)}
                    />

                    <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
                        <Autocomplete
                            options={this.state.inventoryAdjustmentTypes}
                            getOptionLabel={(entry)=>entry.type}
                            autoHighlight={true}
                            style={{ width: 250, marginTop: 25 }}
                            value={this.state.inventoryAdjustmentTypes.find(n => n.type === this.state.type)}
                            onChange={(event, value)=>{this.handleTypeChange(value)}}
                            name="type"

                            blurOnSelect={true}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="Type"
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    name="inventoryAdjustmentType"
                                />
                            }
                        />
                    </div>
                

                    {this.state.ftlSelected ?
                    <>
                        <TextField
                            variant="outlined"
                            name="fromLocation"
                            label="From"
                            InputLabelProps={{ shrink: true }}
                            disabled
                            value={this.state.fromShipAddressName}
                            style={{ width: 250, marginTop: 25}}
                        />

                        <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
                            <Autocomplete
                                options={this.props.availableToLocations}
                                getOptionLabel={(entry)=>entry.name}
                                autoHighlight={true}
                                style={{ width: 250, marginTop: 25}}
                                onChange={(event, value)=>{this.handleToLocationChange(value)}}
                                name="toLocation"
                                className="to-location"
                                blurOnSelect={true}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="To"
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        name="toLocation"
                                    />
                                }
                            />
                        </div>

                        {this.state.shortcodes.map((row, index)=>{
                        return(
                            <React.Fragment>
                            <div>
                                <IconButton onClick={()=>this.deleteFTLShortcode(index)}>
                                    <RemoveCircleIcon
                                        fontSize='small'
                                        color='error'
                                    />
                                </IconButton>
                            </div>
                            <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
                                <Autocomplete
                                    options={this.props.availableShortcodes.map(n=>{return ({shortcode:n})})}
                                    getOptionLabel={(entry)=>entry.shortcode}
                                    style={{ width: 250, marginTop: 5}}
                                    name="shortcode"
                                    disableClearable
                                    value={row}
                                    onChange={(event, value)=> {
                                        this.onFTLChange("shortcode", value.shortcode, index)
                                    }}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            label="Shortcode"
                                            InputLabelProps={{ shrink: true }}
                                            variant="outlined"
                                            name="shortcode"
                                        />
                                    }
                                />
                            </div>
                            <TextField
                                variant="outlined"
                                name="quantity"
                                label="Quantity"
                                type="number"
                                value={this.state.shortcodes[index].quantity}
                                style={{ width: 250, marginTop: 20, marginBottom: 25}}
                                InputLabelProps={{ shrink: true }}
                                className="quantity-input"
                                onChange={(e)=>this.onFTLChange("quantity", e.target.value, index)}
                            />
                            </React.Fragment>
                        )})}
                        <div>
                            <IconButton onClick={this.addFTLShortcode}>
                                <AddCircleIcon
                                    fontSize="small"
                                    color='secondary'
                                />
                            </IconButton>
                            {ADD_SHORTCODE}
                        </div>
                    </>
                    :
                    <>
                        <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
                            <Autocomplete
                                options={this.props.availableShortcodes?.sort((a,b)=>{return a.toLowerCase().localeCompare(b.toLowerCase())})}
                                getOptionLabel={(entry)=>entry}
                                autoHighlight={true}
                                style={{ width: 250, marginTop: 25}}
                                name="shortcodes"
                                disableClearable
                                value={this.state.shortcode}
                                onChange={(event, value)=> {
                                    this.onChange(value)
                                }
                                }
                                blurOnSelect={true}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Shortcode"
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        name="shortcode"
                                    />
                                }
                            />
                        </div>
                        <TextField
                            variant="outlined"
                            name="quantity"
                            label="Quantity"
                            type="number"
                            value={this.state.quantity}
                            style={{ width: 250, marginTop: 25, marginBottom: 10}}
                            InputLabelProps={{ shrink: true }}
                            className="quantity-input"
                            onChange={(e)=>this.onChange(e)}
                        />
                    </>
                    }

                    <TextField
                        variant="outlined"
                        name="notes"
                        label="Notes"
                        value={this.state.notes}
                        style={{ width: 700, marginTop: 20, marginBottom: 25}}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => this.setState({ notes: e.target.value })}
                    />

                    <div className=''>
                        <Button variant="contained" style={{ margin: 10}} onClick={this.handleClose} >
                            Exit
                        </Button>
                        <Button variant="contained" style={{ margin: 10}}
                            disabled={!this.state.type || this.state.type.length == 0 || this.checkEmpty()} 
                            onClick={this.handleSubmit}
                            color="primary"
                        >
                            Confirm
                        </Button>
                    </div>
                </div>
                
                </div>
            </div>
        );
    }
}

CreateInventoryAdjustmentDialog.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    manufacturers: PropTypes.array,
    inventoryAdjustmentTypes: PropTypes.array,
    fromShipAddressId: PropTypes.string,
    availableLocations: PropTypes.array,
    availableToLocations: PropTypes.array,
    availableShortcodes: PropTypes.array,
    handleSubmit: PropTypes.func,
}

CreateInventoryAdjustmentDialog.defaultProps = {
    open: false,
    handleClose:()=>{},
    manufacturers: [],
    inventoryAdjustmentTypes: [],
    fromShipAddressId: "",
    availableLocations: [],
    availableToLocations: [],
    availableShortcodes: [],
    handleSubmit: ()=>{}
}

export default CreateInventoryAdjustmentDialog;
