import React from "react";
//components
import CustomTableHeader from "../../global/Table/CustomTableHeader";
import SearchableTable from "../../global/Search/SearchableTable";
import GlobalTableHead from "../../global/Table/GlobalTableHead";
import FeedbackBar from "../../feedbackBar/FeedbackBar";

//material ui
import {TableBody, Table, TableContainer, TableRow ,TableCell, IconButton, TextField, Tooltip, Button} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";

//constants
import {ASC, DESC} from "../../global/constants";
import {
    CANCEL,REMOVE_PRODUCT_CATEGORY,
    SAVE, PRODUCT_CATEGORY_TABLE_HEAD_CELLS, ADD_PRODUCT_CATEGORY, EDIT_PRODUCT_CATEGORY_TABLE_HEAD_CELLS
} from "./constants";

import { SUCCESS, ERROR } from "../../shipments/constants";

import "./ManageProductCategory.css"
class ManageProductCategoryTable extends SearchableTable {
    constructor(props) {
        super(props);
        this.state = {
            searchValue: "",
        }
    }

    onSearchValueChange = (isSearch, searchValue) => {
        this.setState({
            isSearch: isSearch,
            searchValue: searchValue,
        });

        this.props.onSearchValueChange(isSearch, searchValue)
    }

    checkDisable = () => {
        if (this.props.rows.length === 0) return true
        const setCategory = new Set(this.props.rows.map(entry => entry.category))
        const setHSCode = new Set(this.props.rows.map(entry => entry.hsCode))
        if ([...setCategory].indexOf('') !== -1 || [...setHSCode].indexOf('') !== -1) return true
        if (setCategory.size !== this.props.rows.length) return true
        if (setHSCode.size !== this.props.rows.length) return true
        return false
    }

    render() {
        return (
            <Paper className='paper'>
                <CustomTableHeader
                    searchText={this.state.searchValue}
                    onSearchValueChange={(e) => this.onSearchValueChange(e.target.value.length > 0, e.target.value)}
                />
                <TableContainer>
                    <Table size='medium'>
                        <GlobalTableHead
                            isCreatePurchaseOrderTable={false}
                            isShipmentTable={false}
                            isOrderTable={false}
                            isInvoiceTable={false}
                            isPurchaseOrderTable={false}
                            isProductCategoryTable={true}
                            numSelected={0}
                            headCells={EDIT_PRODUCT_CATEGORY_TABLE_HEAD_CELLS}
                        />
                        <TableBody>
                            {this.filterBySearch(this.props.rows).map((row, index) => {
                                return(
                                    <TableRow>
                                        <TableCell className='checkCircleBox'>
                                            <   Tooltip placement= "left" title={REMOVE_PRODUCT_CATEGORY}>
                                                <IconButton onClick={() => this.props.removeEntry(row.id)}>
                                                    <RemoveCircleIcon
                                                        fontSize="small"
                                                        color='error'
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align={"center"} className='inputBox'>
                                                <TextField
                                                    variant="outlined"
                                                    name={row.category}
                                                    value={row.category}
                                                    onChange={(e)=>this.props.handleCategoryNameChange(e, row)}
                                                    size="small"
                                                    error={this.props.checkCategory(row) && row.category.length > 0}
                                                />
                                        </TableCell>
                                        <TableCell align={"center"} className='inputBox'>
                                            <div className='inputBox'>
                                                <TextField
                                                    variant="outlined"
                                                    name={row.hsCode}
                                                    value={row.hsCode}
                                                    onChange={(e)=>this.props.handleHSCodeChange(e, row)}
                                                    size="small"
                                                    error={this.props.checkHSCode(row) && row.hsCode.length > 0}
                                                />
                                            </div>
                                        </TableCell>
                                        <TableCell className="editingEndingBox"/>
                                    </TableRow>
                                )
                            })}
                            <TableRow>
                                <TableCell className='checkCircleBox' >
                                    <Tooltip placement= "left" title={ADD_PRODUCT_CATEGORY}>
                                        <IconButton onClick={() =>  this.props.addCategory()}>
                                            <AddCircleIcon
                                                fontSize="small"
                                                color='secondary'
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                                <TableCell className="endingBox"/>
                                <TableCell className="endingBox"/>
                                <TableCell className="endingBox"/>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className="createButtonBox">
                    <div className="createButton">
                        <Button onClick={() => this.props.handleCancel()} color="secondary" variant="outlined">
                            {CANCEL}
                        </Button>
                    </div>

                    <div className="createButton">
                        <Button onClick={()=> this.props.handleSave()} color="primary" variant="contained" disabled={this.checkDisable()}>
                            {SAVE}
                        </Button>
                    </div>
                </div>
            </Paper>
        );
    }

}

export default ManageProductCategoryTable