import React from 'react';

// material components
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Paper from "@material-ui/core/Paper";

// custom components
import withShipment from "../../withShipment";
import CustomToolbar from "../global/CustomToolbar";
import TicketsTable from "./TicketsTable";

// constants
import { CREATE_TICKET_TITLE, BACK_TO_MENU, TICKETS_TOOLBAR_TITLE } from "./constants";

// redux

// styling
import './Tickets.css';
import TicketsForm from "./TicketsForm";
import {
    createTicket, listIssueSpecificTasks, listIssueSpecificActions,
    listTicketIssues, listStatus,
    listTickets,
    updateTicket, previewTicketFile, fetchOrderInfo, deleteTicket
} from "../../redux/actions/tickets";
import {
    orderInfoFetchSelector,
    ticketActionsSelector,
    ticketActionTasksSelector,
    ticketIssuesSelector,
    ticketsSelector,
    statusSelector
} from "../../redux/selectors/tickets";
import {
    getUsers,
} from "../../redux/actions/auth";
import { userInfoSelector, usersSelector, selectedLocationIdSelector } from "../../redux/selectors/auth";
import PropTypes from "prop-types";
import {
    saveTableSetting,
    getTableSetting, getTableFilter, updateTicketTableFilter
} from "../../redux/actions/account";
import {
    tableSettingSelector, userTicketTableFilterSelector
} from "../../redux/selectors/account";
import ErrorFeedbackBar from '../global/ErrorFeedbackBar';
import { listProducts, listAvailableLocations } from "../../redux/actions/settings";
import {availableLocationsSelector, productsSelector} from "../../redux/selectors/settings";
import {ROWS_PER_PAGE} from "../invoices/carrierInvoices/constants";

class Tickets extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: ROWS_PER_PAGE,
            creatingTicket: false,
            openedTicket: null,
            searchText: '',
            originalTicketIssueName: ""
        }
    }

    componentDidMount() {
        this.props.listTickets({ fromDate: this.getFromDate(), toDate: this.getToDate() })
        this.props.listProducts()
        this.props.getUsers()
        this.props.listTicketIssues()
        this.props.listAvailableLocations()
        this.props.getTableSetting()
        this.props.getTableFilter({
            defaultFilter:this.props.statusFiltersMap
        })
        this.props.listStatus()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.tickets !== this.props.tickets && this.state.openedTicket !== null) {
            let curTicket = this.state.openedTicket
            let curTicketId = curTicket.ticketId
            let updatedTicket = this.props.tickets.find(ticket => {
                return ticket.ticketId === curTicketId
            })

            curTicket.files = updatedTicket.files
            // TODO also could have requested preview from actions
            this.setState({
                openedTicket: curTicket
            })
        }
    }

    getToday = () => {
        let today = new Date()
        today = today.toString().split('GMT')[0] + "Z"
        return new Date(today)
    }

    getFromDate = () => {
        let storage = JSON.parse(localStorage.getItem("Tickets"))
        if (storage) {
            if (storage.fromDate !== null && storage.fromDate !== undefined) {
                return new Date(storage.fromDate)
            }
        }
        let date = this.getToday()
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setDate(date.getDate() - 14)
        return date;
    }

    getToDate = () => {
        let storage = JSON.parse(localStorage.getItem("Tickets"))
        if (storage) {
            if (storage.toDate !== null && storage.toDate !== undefined) {
                return new Date(storage.toDate)
            }
        }
        let date = this.getToday()
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
        return date;
    }

    requestIssueSpecificActions = (issueId) => {
        this.props.listIssueSpecificActions(issueId);
    }
    creatingTicket = () => {
        this.setState({
            creatingTicket: true
        })
    }

    handleCancelTicket = () => {
        this.setState({
            creatingTicket: false,
            openedTicket: null
        })
    }

    handleSubmitTicket = (data, creatingNewTicket) => {
        if (creatingNewTicket) {
            data["userInfo"] = {
                createdByName: this.props.currentUser.firstname + " " + this.props.currentUser.lastname,
                createdById: this.props.currentUser.userId
            }
            delete data["files"] // we only care about the new files and deleted files
            this.props.createTicket(data, () => window.location.reload())
        }
        else {

            this.props.updateTicket(data)
            this.setState({
                creatingTicket: false,
            }, () => {
                this.setState({
                    openedTicket: null
                })
            }
            )

        }

    }

    setPage = (page) => {
        this.setState({
            page: page
        })
    }

    setRowsPerPage = (rowsPerPage) => {
        this.setState({
            rowsPerPage: rowsPerPage
        })
    }

    showOpenedTicket = (ticket) => {
        let ticketIssueName = this.props.ticketIssues.find((ticketIssue) => ticketIssue.ticketIssueId === ticket.ticketIssueId)?.ticketIssueName
        this.setState({
            originalTicketIssueName: ticketIssueName ?? "",
            openedTicket: ticket
        })
    }


    requestIssueSpecificTasks = (data) => {
        this.props.listIssueSpecificTasks(data)
    }

    handleSearchTextChange = (e) => {
        this.setState({
            searchText: e.target.value
        })
    }

    render() {
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <ErrorFeedbackBar />
                <Paper className='paper'>
                    <CustomToolbar
                        title={TICKETS_TOOLBAR_TITLE}
                        createTitle={CREATE_TICKET_TITLE}
                        backTitle={BACK_TO_MENU}
                        onClick={this.creatingTicket}
                        handleClose={this.handleCancelTicket}
                        creatingOrder={this.state.creatingTicket || (!!this.state.openedTicket)}
                    />

                    {!this.state.openedTicket ?
                        this.state.creatingTicket ?
                            <TicketsForm
                                creatingTicket={true}
                                cancel={this.handleCancelTicket}
                                submit={this.handleSubmitTicket}
                                ticketIssues={this.props.ticketIssues}
                                ticketActions={this.props.ticketActions}
                                currentUser={this.props.currentUser}
                                users={this.props.users}
                                ticket={null}
                                fetchOrderInfo={this.props.fetchOrderInfo} // send to backend
                                orderInfoFetch={this.props.orderInfoFetch} // get from backend & selector
                                products={this.props.products}
                                locations={this.props.availableLocations.filter((location) => location.enabled === true)}
                                selectedLocation={this.props.selectedLocation}
                            />
                            :
                            <TicketsTable
                                rows={this.props.tickets}
                                page={this.state.page}
                                setPage={this.setPage}
                                rowsPerPage={this.state.rowsPerPage}
                                setRowsPerPage={this.setRowsPerPage}
                                currentUser={this.props.currentUser}
                                onDoubleClick={this.showOpenedTicket}
                                tableSetting={this.props.tableSetting}
                                saveTableSetting={this.props.saveTableSetting}
                                user={this.props.currentUser}
                                searchText={this.state.searchText}
                                handleSearchChange={this.handleSearchTextChange}
                                deleteTicket={this.props.deleteTicket}
                                listTickets={this.props.listTickets}
                                status={this.props.status}
                                selectedLocation={this.props.selectedLocation}
                                filters={this.props.filters}
                                updateTicketTableFilter={this.props.updateTicketTableFilter}
                            />
                        :
                        <TicketsForm
                            currentUser={this.props.currentUser}
                            creatingTicket={false}
                            cancel={this.handleCancelTicket}
                            submit={this.handleSubmitTicket}
                            ticket={this.state.openedTicket}
                            ticketIssues={this.props.ticketIssues}
                            ticketActions={this.props.ticketActions}
                            ticketActionTasks={this.props.ticketActionTasks}
                            users={this.props.users}
                            requestIssueSpecificActions={this.requestIssueSpecificActions}
                            requestIssueSpecificTasks={this.requestIssueSpecificTasks}
                            fetchFilePreview={this.props.previewTicketFile}
                            fetchOrderInfo={this.props.fetchOrderInfo} // send to backend
                            orderInfoFetch={this.props.orderInfoFetch} // get from backend & selector
                            products={this.props.products}
                            originalTicketIssueName={this.state.originalTicketIssueName}
                            locations={this.props.availableLocations.filter((location) => location.enabled === true)}
                            selectedLocation={this.props.selectedLocation}
                        />
                    }


                </Paper>

            </MuiPickersUtilsProvider>
        );
    }

}

const mapStateToProps = (state) => ({
    ticketActionTasks: ticketActionTasksSelector(state),
    ticketActions: ticketActionsSelector(state),
    ticketIssues: ticketIssuesSelector(state),
    tickets: ticketsSelector(state),
    currentUser: userInfoSelector(state),
    users: usersSelector(state),
    tableSetting: tableSettingSelector(state),
    orderInfoFetch: orderInfoFetchSelector(state),
    products: productsSelector(state),
    status: statusSelector(state),
    selectedLocation: selectedLocationIdSelector(state),
    availableLocations: availableLocationsSelector(state),
    filters:userTicketTableFilterSelector(state)
});

const actionCreators = {
    listTicketIssues,
    listStatus,
    listTickets,
    listProducts,
    getUsers,
    getTableFilter,
    updateTicketTableFilter,
    createTicket,
    updateTicket,
    deleteTicket,

    listIssueSpecificActions,
    listIssueSpecificTasks,
    listAvailableLocations,

    previewTicketFile,

    saveTableSetting,
    getTableSetting,
    fetchOrderInfo,
}

Tickets.propTypes = {
    ticketActionTasks: PropTypes.object,
    ticketActions: PropTypes.array,
    ticketIssues: PropTypes.array,
    tickets: PropTypes.array,
    status: PropTypes.array,
    currentUser: PropTypes.object,
    users: PropTypes.array,
    availableLocations: PropTypes.array,
    statusFiltersMap: PropTypes.array
}

Tickets.defaultProps = {
    ticketActionTasks: {},
    ticketActions: [],
    ticketIssues: [],
    tickets: [],
    status: [],
    currentUser: {},
    users: [],
    availableLocations: [],
    statusFiltersMap:[]
}


export default withShipment({
    mapStateToProps,
    actionCreators
}, Tickets);