import React from "react";

// material components
import {Box, IconButton, MenuItem, Select, TextField, Tooltip} from "@material-ui/core";

// material icons
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import DehazeIcon from '@material-ui/icons/Dehaze';

// import constants
import {DELETE_ACTION, DRAG} from "../constants";
import PropTypes from "prop-types";
import SingleNumericalAction from "./SingleNumericalAction";
import {CONDITION_ACTIONS} from "./constants";


class TripleNumericalAction extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            actionId: "",
            name: "",
            value1: "",
            value2: "",
            value3: "",
            preDefinedValue: "-",
            disableManualValueField: false,
            preDefinedValues: this.getPredefinedValues()
        }
    }

    componentDidMount() {
        this.setState({
            ...this.props.action
        })
    }

    getPredefinedValues = () => {
        let preDefinedValues = CONDITION_ACTIONS.find(action => action.name === this.props.action.name)?.predefinedValues
        if (!preDefinedValues) {
            preDefinedValues = []
        }
        return preDefinedValues
    }

    handleValueChange = (event) => {
        this.setState(
            {
                [event.target.name]: event.target.value,
            },
            () => {
                this.props.updateActionWithParent({ ...this.state });
            }
        );
    }

    handlePredefinedValueChange = (e) => {
        this.setState({
            preDefinedValue: e.target.value,
            disableManualValueField: e.target.value !== '-',
            value1: e.target.value !== '-'? '': this.state.value1,
            value2: e.target.value !== '-'? '': this.state.value2,
            value3: e.target.value !== '-'? '': this.state.value3
        }, () => {
            this.props.updateActionWithParent({ ...this.state })})
    }

    render() {
        return (
            <Box
                className='actionNumericField'
                display='flex'
                flexDirection='row'
                justifyContent='left'
                alignItems='center'
            >
                <Box className='actionNumericFieldName'>{this.state.name}</Box>
                <div>
                    <TextField
                        className='actionNumericFieldInput3'
                        variant='outlined'
                        type="number"
                        label={this.props.action.label1}
                        value={this.state.value1}
                        name="value1"
                        onChange={this.handleValueChange}
                        disabled={this.state.disableManualValueField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
                <div>
                    <TextField
                        className='actionNumericFieldInput3'
                        variant='outlined'
                        type="number"
                        label={this.props.action.label2}
                        value={this.state.value2}
                        name="value2"
                        onChange={this.handleValueChange}
                        disabled={this.state.disableManualValueField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
                <div>
                    <TextField
                        className='actionNumericFieldInput3-short'
                        variant='outlined'
                        type="number"
                        label={this.props.action.label3}
                        value={this.state.value3}
                        name="value3"
                        onChange={this.handleValueChange}
                        disabled={this.state.disableManualValueField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>

                {!this.state.preDefinedValues || this.state.preDefinedValues.length <= 0 ? <></> :
                    <React.Fragment>
                        <div>
                            OR
                        </div>
                        <Select
                            variant="outlined"
                            name="predefinedValue"
                            onChange={this.handlePredefinedValueChange}
                            value={this.state.preDefinedValue === "null" ? '-' : this.state.preDefinedValue}
                            className='predefined-dropdown'
                        >
                            {this.state.preDefinedValues.map((value, index) => (
                                <MenuItem value={value} key={index}>{value === '-'? 'Select a predefined value': value}</MenuItem>
                            ))
                            }
                        </Select>
                    </React.Fragment>
                }

                {/* in order to make sure that the delete icon aligns, we add an empty component */}
                <div className='actionNumericEmptyDiv3'>
                    <Tooltip title={DELETE_ACTION}>
                        <IconButton
                            aria-label={DELETE_ACTION}
                            onClick={() => this.props.deleteAction(this.state.actionId)}
                        >
                            <DeleteOutlineIcon variant='outlined' fontSize='small' />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={DRAG}>
                        <IconButton
                            aria-label='drag'
                        >
                            <DehazeIcon variant='outlined' fontSize='small' />
                        </IconButton>
                    </Tooltip>
                </div>
            </Box>
        );
    }

}

TripleNumericalAction.propTypes = {
    action: PropTypes.object,
    updateActionWithParent: PropTypes.func,
    name: PropTypes.string,
    deleteAction: PropTypes.func,
    isEdit: PropTypes.bool
};

TripleNumericalAction.defaultProps = {
    action: {},
    name: "",
    updateActionWithParent: ()=>{},
    deleteAction: ()=>{},
    isEdit: false
}

export default TripleNumericalAction;