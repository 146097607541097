import {
    REMOVE_INVOICE_TEMPLATE,
    STORE_FTL_CARRIER_INVOICE_TEMPLATE_PDF,
    STORE_FTL_CARRIER_TEMPLATES,
    STORE_FTL_CARRIERS_WITHOUT_TEMPLATE,
    STORE_FULL_TRUCKLOADS_CARRIER,
    STORE_FULL_TRUCKLOADS_CARRIERS, UPDATE_FTL_CARRIER_INVOICE_TEMPLATES
} from "../../constants/fullTruckloadCarriers";


const initialState = {
    fullTruckloadCarriers: [],
    ftlCarriersWithoutTemplate: [],
    ftlCarrierInvoiceTemplates:[],
    ftlCarrierInvoiceTemplatePDF:[],

}

export default (state = initialState, action) => {
    switch (action.type) {
        case STORE_FULL_TRUCKLOADS_CARRIERS:
            return {
                ...state,
                fullTruckloadCarriers: action.fullTruckloadCarriers
            }
        case STORE_FULL_TRUCKLOADS_CARRIER:
            const carrierIndex = state.fullTruckloadCarriers.findIndex(
                carrier => carrier.carrierId === action.fullTruckloadCarrier.carrierId
            );
            if (carrierIndex >= 0) {
                // The carrierId exists, so replace the carrier at that index
                return {
                    ...state,
                    fullTruckloadCarriers: [
                        ...state.fullTruckloadCarriers.slice(0, carrierIndex),
                        action.fullTruckloadCarrier, // This is the new broker data
                        ...state.fullTruckloadCarriers.slice(carrierIndex + 1)
                    ]
                };
            } else {
                // The carrierId does not exist, so add the new carrier to the end of the array
                return {
                    ...state,
                    fullTruckloadCarriers: [...state.fullTruckloadCarriers, action.fullTruckloadCarrier]
                };
            }
        case STORE_FTL_CARRIERS_WITHOUT_TEMPLATE:
            return {
                ...state,
                ftlCarriersWithoutTemplate: action.carriers
            }
        case STORE_FTL_CARRIER_TEMPLATES:
            return {
                ...state,
                ftlCarrierInvoiceTemplates: action.templates
            }
        case STORE_FTL_CARRIER_INVOICE_TEMPLATE_PDF:
            return {
                ...state,
                ftlCarrierInvoiceTemplatePDF: action.templatePDF
            }
        case UPDATE_FTL_CARRIER_INVOICE_TEMPLATES:
            let templatesCopy = state.ftlCarrierInvoiceTemplates.filter((template)=>template.carrierId !=action.template.carrierId)
            templatesCopy.push(action.template)
            return {
                ...state,
                ftlCarrierInvoiceTemplates:templatesCopy
            }
        case REMOVE_INVOICE_TEMPLATE:
            return {
                ...state,
                ftlCarrierInvoiceTemplates: state.ftlCarrierInvoiceTemplates.filter((template)=>template.carrierId != action.carrierId)
            }
        default:
            return state;
    }
}