import React, { Component } from "react";
import PropTypes from "prop-types";

import { NavLink } from "react-router-dom";

import ListItem from "@material-ui/core/ListItem";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

class MenuItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
    };
  }

  componentDidMount() {}

  toggleCollapse = () => {
    this.setState((prevState) => {
      return {
        collapsed: !prevState.collapsed,
      };
    });
  };

  onClick = (e) => {
    if (Array.isArray(this.props.item.items)) {
      this.toggleCollapse();
    } else {
      window.location = this.props.item.path;
    }

    //     if (this.props.onClickProp) {
    //          this.props.onClickProp(e, this.props.item);
    //     }
  };

  render() {
    // let expandIcon;
    // if (Array.isArray(this.props.item.items) && this.props.item.items.length) {
    //   expandIcon = !this.state.collapsed ? (
    //     <ExpandLessIcon
    //       className={
    //         "leftMenu-item-expand-arrow leftMenu-item-expand-arrow-expanded"
    //       }
    //     />
    //   ) : (
    //     <ExpandMoreIcon className='leftMenu-item-expand-arrow' />
    //   );
    // }

    return (
      <div
        style={{
          paddingLeft: `${this.props.depthPaddingLeft}px`,
          textDecoration: 'none !important'
        }}
        className='leftMenu-item-content'
      >
        {this.props.item.icon && (
          <this.props.item.icon
            className='leftMenu-item-icon'
            fontSize='small'
          />
        )}
        <div className='leftMenu-item-text'>{this.props.item.label}</div>
      </div>
    );
  }
}

MenuItem.propTypes = {
  depthStep: PropTypes.number,
  depth: PropTypes.number,
  expanded: PropTypes.bool,
  item: PropTypes.object,
  Icon: PropTypes.element,
};

MenuItem.defaultProps = {
  depthStep: 20,
  depth: 0,
  expanded: false,
};

export default MenuItem;
