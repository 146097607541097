import React from 'react';
import withShipment from "../../withShipment";
import ErrorFeedbackBar from "../global/ErrorFeedbackBar";
import {CLAIMS_PAGES} from "./constants";
import ClaimsTable from "./ClaimPages/ClaimsTable";
import ClaimsForm from "./ClaimPages/ClaimsForm";
import ClaimPreview from "./ClaimPages/ClaimPreview";
import {claimsSelector, previewingClaimDataSelector} from "../../redux/selectors/claims";
import {listClaims} from "../../redux/actions/claims";
import PropTypes from "prop-types";
import {listCarriersNames} from "../../redux/actions/settings";
import {carrierNamesSelector} from "../../redux/selectors/settings";

class Claims extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: CLAIMS_PAGES.TABLE,
            previewFileName: null,
            claimId: null,
        }
    }

    componentDidMount() {
        this.props.listCarriersNames()
        this.props.listClaims()
    }

    setCurrentPage = (newPage, claimId = null, previewFileName = null) => {
        this.setState({
            claimId: claimId,
            currentPage: newPage,
            previewFileName: previewFileName,
        })
    }

    displayTable = () => {
        return <React.Fragment>
            <ClaimsTable
                claims={this.props.claims}
                setCurrentPage={this.setCurrentPage}
                carriers={this.props.carrierNames}
            />
        </React.Fragment>
    }

    displayCreating = () => {
        return <React.Fragment>
            <ClaimsForm
                creating={true}
                setCurrentPage={this.setCurrentPage}
                carriers={this.props.carrierNames}
            />
        </React.Fragment>
    }

    displayUpdating = () => {
        return <React.Fragment>
            <ClaimsForm
                creating={false}
                setCurrentPage={this.setCurrentPage}
                data={this.props.claims.find((claim) => claim.claimId === this.state.claimId)}
                carriers={this.props.carrierNames}
            />
        </React.Fragment>
    }

    displayPreview = () => {
        return <React.Fragment>
            <ClaimPreview
                creating={false}
                setCurrentPage={this.setCurrentPage}
                previewingClaimData={this.props.previewingClaimData}
                claimId={this.state.claimId}
                previewFileName={this.state.previewFileName}
                carriers={this.props.carrierNames}
            />
        </React.Fragment>
    }

    displayCurrentPage = (currentPage) => {
        switch (currentPage) {
            case CLAIMS_PAGES.TABLE:
                return this.displayTable()
            case CLAIMS_PAGES.CREATE:
                return this.displayCreating()
            case CLAIMS_PAGES.UPDATE:
                return this.displayUpdating()
            case CLAIMS_PAGES.PREVIEW:
                return this.displayPreview()
            default:
                console.log("Error: current claims page doesn't exist")
                return <div>We encountered an error. Please refresh the page.</div>
        }
    }

    render() {
        return (
            <React.Fragment>
                <ErrorFeedbackBar/>
                {this.displayCurrentPage(this.state.currentPage)}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    previewingClaimData: previewingClaimDataSelector(state),
    claims: claimsSelector(state),
    carrierNames: carrierNamesSelector(state)
})

const actionCreators = {
    listClaims,
    listCarriersNames
}
Claims.propTypes = {
    carrierNames: PropTypes.array,
}

Claims.defaultProps = {
    carrierNames: [],
}
export default withShipment({
    mapStateToProps,
    actionCreators
}, Claims);