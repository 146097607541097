import React from "react";

// material components
import {IconButton, Popover, TextField} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import { KeyboardDatePicker } from "@material-ui/pickers";
import InputAdornment from "@material-ui/core/InputAdornment";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

// custom components
import CommentHistoryTable from "../global/CommentHistoryTable";

// constants
import {
    CONDITIONS,
    ACTIONS,
    CANCEL,
    SAVE,
    PENDING,
    COMPLETE_RETURN,
    COMPLETED_STATUS,
    REFUND_AMOUNT_CHECKBOX, LOCATION_TEXTFIELD_LABEL, ERROR_MESSAGE
} from "./constants";


// styling
import "./ReturnsForm.css"
import FileChip from "../global/FileChip";
import FileUpload from "../global/FileUpload";
import { getByteArray } from "../global/constants";
import PropTypes from "prop-types";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from "@material-ui/core/Tooltip";




class ReturnsForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            additionalEntries: this.getValue('additionalEntries'),
            returnedDate: this.getValue('returnedDate'),
            customerName: this.getValue('customerName'),
            originalTrackingId: this.getValue('originalTrackingId'),
            orderId: this.getValue('orderId'),
            returnTrackingId: this.getValue('returnTrackingId'),
            rmaNum: this.getValue('rmaNum'),
            shortcode: this.getValue('shortcode'),
            quantityReceived: this.getValue('quantityReceived'),
            conditionId: this.getValue('conditionId'),
            actionId: this.getValue('actionId'),
            actionName: this.getValue('actionName'),
            preActionName: this.getValue('actionName'),
            returnReasonId: this.getValue('returnReasonId'),
            returnReasonName: this.getValue('returnReasonName'),
            preReturnReasonId: this.getValue('returnReasonId'),
            actionQuantityReceived: this.getValue('actionQuantityReceived'),
            additionalActionId: this.getValue('additionalActionId'),
            additionalActionName: this.getValue('additionalActionName'),
            preAdditionalActionName: this.getValue('additionalActionName'),
            additionalActionQuantityReceived: this.getValue('additionalActionQuantityReceived'),
            files: this.getValue('files'),
            assignedToId: this.getValue('assignedToId'),
            assignedToName: this.getValue('assignedToName'),
            dueDate: this.getValue('dueDate'),
            comments: this.getValue('comments'),
            location: this.getValue('location'),
            duplicateRmas: [],
            anchorEl: null,
            refundAmount: this.getValue('refundAmount'),
            refundCompleted: this.getValue('refundCompleted'),

            returnId: this.getValue('returnId'),
            validate: false,
            userLocation: {
                shipAddressName: 'Toronto DC'
            },

            //key: 0,
            newFiles: [],
            deletedFiles: [],
            whitelist: {
                'validate': true,
                'returnedDate': true,
                'dueDate': true,
                'comments': true,
                'refundCompleted': true,
                'refundAmount': true,
                'returnId': true,
                'actionName': true,
                'files': true,
                'key': true,
                'newFiles': true,
                'deletedFiles': true,
                'additionalActionId': true,
                'additionalActionName': true,
                'additionalActionQuantityReceived': true,
                'preReturnReasonId': true,
                'preActionName': true,
                'preAdditionalActionName': true
            },
        }

        this.baseState = this.state
    }

    componentDidMount() {
        if (!this.props.creatingForm && this.state.rmaNum && this.state.orderId) {
           this.props.fetchDuplicateRmas(this.state.rmaNum, this.state.orderId)
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        // if the file preview has been fetched
        if (this.props !== prevProps && this.props.returnItem) {
            this.setState({
                files: this.props.returnItem.files
            })
        }
        if (!this.props.creatingForm && this.props.duplicateRmas !== prevProps.duplicateRmas) {
            this.setState({
                duplicateRmas: this.props.duplicateRmas
            })
        }
        if(this.props.rmaFetch !== prevProps.rmaFetch){
            if(this.props.rmaFetch?.customerNameAndOrderItems[0]?.shippingAddressName?.length > 1){
                this.setState({
                    rmaNum: this.props.rmaFetch?.rmaNumber,
                    originalTrackingId: this.props.rmaFetch?.trackingNumber[0]?.trackingNumber,
                    customerName: this.props.rmaFetch?.customerNameAndOrderItems[0]?.shippingAddressName,
                    orderId: this.props.rmaFetch?.customerNameAndOrderItems[0]?.orderItems[0]?.orderId,
                    shortcode: this.props.rmaFetch?.shortcodes[0],
                    quantityReceived: this.props.rmaFetch?.customerNameAndOrderItems[0]?.orderItems[0]?.quantityShipped,
                    duplicateRmas: this.props.rmaFetch?.duplicateRmas
                })
                let additionalEntries = []
                for(let i = 1; i < this.props.rmaFetch?.shortcodes.length; i++){
                    additionalEntries.push({shortcode: this.props.rmaFetch?.shortcodes[i], quantityReceived: this.props.rmaFetch?.customerNameAndOrderItems[0]?.orderItems[i]?.quantityShipped})
                }
                this.setState({
                    additionalEntries: additionalEntries,
                })
            }
        }

    }


    getValue = (key) => {
        if (key === 'actionName' && !this.props.creatingForm)
            return this.props.returnActions?.find(action => action.returnActionId === this.props.returnItem.actionId)?.returnActionName
        else if (key === 'actionName')
            return undefined

        if (key === 'additionalActionName' && !this.props.creatingForm)
            return this.props.returnActions?.find(action => action.returnActionId === this.props.returnItem.additionalActionId)?.returnActionName
        else if (key === 'additionalActionName')
            return undefined

        if (key === 'files' && !this.props.creatingForm)
            return this.props.returnItem[key]
        else if (key === 'files')
            return []

        if (key === 'refundCompleted' && !this.props.creatingForm)
            return this.props.returnItem.status === COMPLETED_STATUS
        else if (key === 'refundCompleted')
            return false

        if (key === 'returnedDate' && !this.props.creatingForm)
            return this.props.returnItem[key]
        else if (key === 'returnedDate')
            return new Date()

        if (key === 'dueDate' && !this.props.creatingForm)
            return this.props.returnItem[key]
        else if (key === 'dueDate') {
            const today = new Date()
            return today
        }

        if (key === 'location' && !this.props.creatingForm)
            return this.props.returnItem[key]
        else if (key === 'location') {
            let result = this.props.locations.find(location => location["shipAddressId"] === this.props.currentUser.selectedLocationId)
            if (result == null) {
                result = {}
            }
            return result
        }

        if (this.props.creatingForm || key === 'comments')
            return ''
        return this.props.returnItem[key]
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })

    }

    handleRmaChange = (e) => {
        this.trimField(e)
        if (e.target.value.trim() !== "")
            this.props.fetchRmaInfo({rma: this.state.rmaNum})
    }
    handleOrderIdChange = (e) => {
        this.trimField(e)
        if (e.target.value.trim() !== "")
            this.props.fetchRmaInfo({orderId: this.state.orderId})
    }
    handleOriginalTrackingIdChange = (e) => {
        this.trimField(e)
        if (e.target.value.trim() !== "")
            this.props.fetchRmaInfo({originalTrackingId: this.state.originalTrackingId})
    }
    handleReturnTrackingIdChange = (e) => {
        this.trimField(e)
        if (e.target.value.trim() !== "")
            this.props.fetchRmaInfo({returnTrackingId: this.state.returnTrackingId})
    }

    trimField = (e) => {
        this.setState({
            [e.target.name]: e.target.value.trim()
        })
    }

    handleAutocompleteChange = async (value, name, index) => {
        if(index === -1) {
            this.setState({
                [name]: value
            })
        }else{
            let mutator = this.state.additionalEntries
            mutator[index][name] = value
            this.setState({
                additionalEntries: mutator,
            });
        }
    }

    handleConditionChange = (condition, index) => {
        if(index === -1) {
            this.setState({
                conditionId: condition.returnConditionId,
                additionalActionId: null,
                additionalActionName: null,
                additionalActionQuantityReceived: null
            })
        } else{
            let mutator = this.state.additionalEntries
            mutator[index]['conditionId'] = condition.returnConditionId
            mutator[index]['additionalActionId'] = null
            mutator[index]['additionalActionName'] = null
            mutator[index]['additionalActionQuantityReceived'] = null

            this.setState({
                additionalEntries: mutator,
            });
        }
    }

    handleActionChange = (action, index) => {
        if(index === -1) {
            this.setState({
                actionId: action.returnActionId,
                actionName: action.returnActionName,
                actionQuantityReceived: '0'
            })
        }else{
            let mutator = this.state.additionalEntries
            mutator[index]['actionId'] = action.returnActionId
            mutator[index]['actionName'] = action.returnActionName
            mutator[index]['actionQuantityReceived'] = '0'
            this.setState({
                additionalEntries: mutator,
            });
        }
    }

    handleAdditionalActionChange = (action, index) => {
        if(index === -1){
            this.setState({
                additionalActionId: action?.returnActionId,
                additionalActionName: action?.returnActionName,
                additionalActionQuantityReceived: action ? '0' : null
            })
        }else {
            let mutator = this.state.additionalEntries
            mutator[index]['additionalActionId'] = action?.returnActionId
            mutator[index]['additionalActionName'] = action?.returnActionName
            mutator[index]['additionalActionQuantityReceived'] = action ? '0' : null
            this.setState({
                additionalEntries: mutator,
            });
        }
    }

    handleAssignedToChange = (value) => {
        this.setState({
            assignedToId: value.userId,
            assignedToName: value.name
        })
    }

    handleDateChange = (date, isDueDate) => {
        if (isDueDate) {
            this.setState({
                dueDate: date
            })
        }
        else {
            this.setState({
                returnedDate: date
            })
        }
    }

    isReturnComplete = () => {
        return this.props.returnItem && (this.props.returnItem.status === COMPLETED_STATUS)
    }

    handleCommentUpdate = (e) => {
        this.setState({
            comments: {
                user: this.props.currentUser.firstname + " " + this.props.currentUser.lastname,
                date: new Date().toISOString(),
                comment: e.target.value
            }
        })
    }

    isError = (key) => {
        return key === ''
    }

    validateData = () => {
        //this.setState({
        //    validate:true
        //})
        // also go through all entries and validate if good !!!!!!!!
        let keys = Array.from(Object.keys(this.state));
        for (let i = 0; i < keys.length; i++) {
            if (keys[i] in this.state.whitelist)
                continue

            if (keys[i] == "location" && !(this.state.location?.name)) {
                return false
            }
        }

        if (this.state.dueDate == null || this.state.dueDate == 'Invalid Date') {
            return false
        }
        if (this.state.returnedDate == null || this.state.returnedDate == 'Invalid Date') {
            return false
        }
        return true
    }

    handleClearComment = () => {
        this.setState({
            comments: ''
        })
    }



    handleFileAdd = (file) => {
        const { newFiles, files } = this.state
        //let prevNewFiles = this.state.newFiles
        //let prevFiles = this.state.files

        let newFile = this.getFormattedFileObject(file)


        files.push(newFile)
        newFiles.push(newFile)
        this.setState({
            newFiles: newFiles,
            files: files
        });

    }

    getFormattedFileObject = (file) => {
        return { localURL: URL.createObjectURL(file), displayName: file.name, type: file.type, byteArray: getByteArray(file) }
    }


    handleFileDelete = (file, index) => {
        /*let prevFiles = this.state.files
        let deletedFiles = this.state.deletedFiles
        let prevNewFiles = this.state.newFiles*/

        const { files, deletedFiles, newFiles } = this.state
        // we need to see if the deleted file is in the newFiles or if it is a file from the server
        let deletedFile = (files.splice(index, 1))[0]
        // remote file that already exists on the server
        if (!("byteArray" in file)) {
            deletedFiles.push(deletedFile)
        }
        // local file that is to be pushed to the server
        else {
            newFiles.splice(index, 1)
        }
        if ("localURL" in file) {
            this.freeFromMemory(file.localURL)
        }


        this.setState({
            files: files,
            deletedFiles: deletedFiles,
            newFiles: newFiles
        })
    }

    freeFromMemory = (url) => {
        URL.revokeObjectURL(url)
    }

    cancel = () => {
        if (this.state.files) {
            this.state.files.forEach((file) => {
                if ("localURL" in file && !("path" in file))
                    this.freeFromMemory(file.localURL)
            })
        }
        this.setState(this.baseState)
        this.props.cancel()
    }

    handleIssueChange = (value, index) => {
        if(index === -1) {
            this.setState({
                returnReasonId: value.returnReasonId,
                returnReasonName: value.returnReasonName
            });
        }else {
            let mutator = this.state.additionalEntries
            mutator[index]['returnReasonId'] = value.returnReasonId
            mutator[index]['returnReasonName'] = value.returnReasonName
            this.setState({
                additionalEntries: mutator,
            });
        }
    }

    handlePopoverOpen = (event) => {
        this.setState({ anchorEl: event.currentTarget })
    };

    handlePopoverClose = () => {
        this.setState({ anchorEl: null })
    };

    isPopoverOpen = () => {
        return Boolean(this.state.anchorEl)
    };

    displayDuplicateRmas = () => {
        return(
            <div  className= "return-popoverContent">
                    This return has multiple RMAs:<br />
                     {this.state.duplicateRmas.map((rma, index) => (
                         <span key={index}>
                            &nbsp;&nbsp;&nbsp;&nbsp;{rma || '<blank>'}<br />
                        </span>
                     ))}
            </div>
        )
    }

    render() {
        const { anchorEl } = this.state
        return (
            <div className="return-form-box">
                <div className='return-from-input-box'>
                    <div style={{width: "55%"}}>
                        <div className='return-row-flexbox'>
                            <KeyboardDatePicker
                                error={this.state.returnedDate == null || this.state.returnedDate == 'Invalid Date'}
                                autoOk
                                variant="inline"
                                inputVariant="outlined"
                                label="Returned Date"
                                format="yyyy/MM/dd"
                                disabled={this.props.returnItem && (this.props.returnItem.status === COMPLETED_STATUS)}
                                className='date-fields'
                                value={this.state.returnedDate}
                                InputAdornmentProps={{ position: "start" }}
                                onChange={date => this.handleDateChange(date, false)}
                                required
                            />
                            <Autocomplete
                                options={this.props.locations}
                                getOptionLabel={(option) => option?.name}
                                disabled={this.props.returnItem && (this.props.returnItem.status === COMPLETED_STATUS)}
                                className='text-field'
                                name="location"
                                value={!this.state.location ? {} : this.state.location}
                                onChange={(event, value) => {
                                    if (value == null || value == undefined) {
                                        this.handleAutocompleteChange({}, "location", -1)
                                    } else {
                                        this.handleAutocompleteChange(value, "location", -1)
                                    }
                                }}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label={LOCATION_TEXTFIELD_LABEL}
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        name="location"
                                        error={this.state.location.name === undefined}
                                        required
                                    />
                                }
                            />
                            <TextField
                                className='text-field'
                                variant="outlined"
                                name="rmaNum"
                                disabled={this.props.returnItem && (this.props.returnItem.status === COMPLETED_STATUS)}
                                error={this.state.validate && this.state.rmaNum === ''}
                                helperText={this.state.validate && this.state.rmaNum === '' ? ERROR_MESSAGE : ''}
                                value={this.state.rmaNum}
                                onChange={this.handleChange}
                                onBlur={this.handleRmaChange}
                                label="RMA Number"
                                InputLabelProps={{ shrink: true }}
                                required
                            />
                            {this.state.duplicateRmas.length > 0 ? (
                                    <div className='form-info'>
                                        <IconButton onClick={this.handlePopoverOpen}>
                                            <InfoIcon style={{ color: 'gray' }} />
                                        </IconButton>
                                        <Popover
                                            open={this.isPopoverOpen()}
                                            anchorEl={anchorEl}
                                            onClose={this.handlePopoverClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                            }}
                                        >
                                            {this.displayDuplicateRmas()}
                                        </Popover>
                                    </div>

                            ) : null}

                        </div>

                        <div className="return-row-flexbox">

                            <TextField
                                className='text-field'
                                variant="outlined"
                                disabled={this.props.returnItem && (this.props.returnItem.status === COMPLETED_STATUS)}
                                name="orderId"
                                error={this.state.validate && this.state.orderId === ''}
                                helperText={this.state.validate && this.state.orderId === '' ? ERROR_MESSAGE : ''}
                                value={this.state.orderId}
                                onChange={this.handleChange}
                                onBlur={this.handleOrderIdChange}
                                label="Order ID"
                                InputLabelProps={{ shrink: true }}
                                required
                            />
                            <TextField
                                className='text-field'
                                variant="outlined"
                                disabled={this.props.returnItem && (this.props.returnItem.status === COMPLETED_STATUS)}
                                error={this.state.validate && this.state.originalTrackingId === ''}
                                helperText={this.state.validate && this.state.originalTrackingId === '' ? ERROR_MESSAGE : ''}
                                name="originalTrackingId"
                                value={this.state.originalTrackingId}
                                onChange={this.handleChange}
                                onBlur={this.handleOriginalTrackingIdChange}
                                label="Original Tracking ID"
                                InputLabelProps={{ shrink: true }}
                                required
                            />
                            <TextField
                                className='text-field'
                                variant="outlined"
                                disabled={this.props.returnItem && (this.props.returnItem.status === COMPLETED_STATUS)}
                                name="returnTrackingId"
                                value={this.state.returnTrackingId}
                                error={this.state.validate && this.state.returnTrackingId === ''}
                                helperText={this.state.validate && this.state.returnTrackingId === '' ? ERROR_MESSAGE : ''}
                                onChange={this.handleChange}
                                onBlur={this.handleReturnTrackingIdChange}
                                label="Return Tracking ID"
                                InputLabelProps={{ shrink: true }}
                                required
                            />
                        </div>


                        <div className='return-row-flexbox'>
                            <TextField
                                className='text-field'
                                variant="outlined"
                                disabled={this.props.returnItem && (this.props.returnItem.status === COMPLETED_STATUS)}
                                error={this.state.validate && this.state.customerName === ''}
                                helperText={this.state.validate && this.state.customerName === '' ? ERROR_MESSAGE : ''}
                                name="customerName"
                                value={this.state.customerName}
                                onChange={this.handleChange}
                                onBlur={this.trimField}
                                label="Customer Name"
                                InputLabelProps={{ shrink: true }}
                                required
                            />
                        </div>

                        <Divider className='section-divider' style={{marginLeft: "10px"}} />

                        <div className='return-row-flexbox'>
                            <Autocomplete
                                options={this.props.products ? this.props.products.map(product => product.shortcode).sort((a, b) => { return a.toLowerCase().localeCompare(b.toLowerCase()) }) : []}
                                getOptionLabel={(product) => product}
                                autoHighlight={true}
                                disabled={this.props.returnItem && (this.props.returnItem.status === COMPLETED_STATUS)}
                                className="return-dropdowns"
                                onChange={(event, value) => { this.handleAutocompleteChange(value, "shortcode", -1) }}
                                value={this.state.shortcode}
                                name="shortcode"
                                disableClearable
                                blurOnSelect={true}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Shortcode"
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        error={this.state.validate && this.state.shortcode === ''}
                                        helperText={this.state.validate && this.state.shortcode === '' ? ERROR_MESSAGE : ''}
                                        name="conditionType"
                                        required
                                    />
                                }
                            />
                            <TextField
                                className='text-field'
                                variant="outlined"
                                disabled={this.props.returnItem && (this.props.returnItem.status === COMPLETED_STATUS)}
                                value={this.state.quantityReceived}
                                error={this.state.validate && this.state.quantityReceived === ''}
                                helperText={this.state.validate && this.state.quantityReceived === '' ? ERROR_MESSAGE : ''}
                                name="quantityReceived"
                                onChange={this.handleChange}
                                onBlur={this.trimField}
                                label="Quantity Received"
                                InputLabelProps={{ shrink: true }}
                                required
                            />
                            <Autocomplete
                                options={this.props.returnConditions}
                                getOptionLabel={(returnCondition) => returnCondition.returnConditionName}
                                autoHighlight={true}
                                disabled={this.props.returnItem && (this.props.returnItem.status === COMPLETED_STATUS)}
                                className="return-dropdowns"
                                onChange={(event, value) => { this.handleConditionChange(value, -1) }}
                                value={this.props.returnConditions.find(condition => condition.returnConditionId === this.state.conditionId)}
                                name="condition"
                                disableClearable
                                blurOnSelect={true}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Condition"
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        error={this.state.validate && this.state.conditionId === ''}
                                        helperText={this.state.validate && this.state.conditionId === '' ? ERROR_MESSAGE : ''}
                                        name="conditionType"
                                        required
                                    />
                                }
                            />
                            <IconButton onClick={
                                () => {
                                    if(this.state.additionalEntries.length >= 1) {
                                        let adder = this.state.additionalEntries
                                        this.setState({
                                            actionId: this.state.additionalEntries[0].actionId,
                                            quantityReceived: this.state.additionalEntries[0].quantityReceived,
                                            shortcode: this.state.additionalEntries[0].shortcode,
                                            conditionId: this.state.additionalEntries[0].conditionId,
                                            actionQuantityReceived: this.state.additionalEntries[0].actionQuantityReceived,
                                            additionalActionId: this.state.additionalEntries[0].additionalActionId,
                                            additionalActionName: this.state.additionalEntries[0].additionalActionName,
                                            actionName: this.state.additionalEntries[0].actionName,
                                            returnReasonId: this.state.additionalEntries[0].returnReasonId,
                                            returnReasonName: this.state.additionalEntries[0].returnReasonName,
                                        })
                                        adder.splice(0, 1)
                                        this.setState({
                                            additionalEntries: adder
                                        })
                                    }
                                }}>
                                <RemoveCircleIcon
                                    fontSize='small'
                                    color='error'
                                />
                            </IconButton>
                        </div>
                        <div className='return-row-flexbox'>
                            <Autocomplete
                                disabled={this.props.returnItem && (this.props.returnItem.status === COMPLETED_STATUS) && (this.state.preActionName !== PENDING)}
                                options={this.props.returnActions}
                                value={this.props.returnActions.find(action => action.returnActionId === this.state.actionId)}
                                getOptionLabel={(returnAction) => returnAction.returnActionName}
                                autoHighlight={true}
                                className="return-dropdowns"
                                disableClearable
                                onChange={(event, value) => { this.handleActionChange(value, -1) }}
                                name="action"

                                blurOnSelect={true}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Action"
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        error={this.state.validate && this.state.actionId === ''}
                                        helperText={this.state.validate && this.state.action === '' ? ERROR_MESSAGE : ''}
                                        name="actionType"
                                        required
                                    />
                                }
                            />
                            <TextField
                                disabled={(this.props.returnItem && (this.state.preActionName !== PENDING) && (this.props.returnItem.status === COMPLETED_STATUS)) || (this.state.actionName === PENDING)}
                                className='text-field'
                                variant="outlined"
                                name="actionQuantityReceived"
                                value={this.state.actionQuantityReceived}
                                error={this.state.validate && this.state.actionQuantityReceived === ''}
                                helperText={this.state.validate && this.state.actionQuantityReceived === '' ? ERROR_MESSAGE : ''}
                                onChange={this.handleChange}
                                onBlur={this.trimField}
                                label={"Quantity " + ((!this.state.actionName || this.state.actionName === PENDING) ? "Received" : this.state.actionName)}
                                InputLabelProps={{ shrink: true }}
                                required
                            />
                            <Autocomplete
                                options={this.props.returnReasons}
                                getOptionLabel={(returnReasons) => returnReasons.returnReasonName}
                                autoHighlight={true}
                                className="return-dropdowns"
                                onChange={(event, value) => { this.handleIssueChange(value, -1) }}
                                value={{ returnReasonId: this.state.returnReasonId, returnReasonName: this.state.returnReasonName }}
                                name="issue"
                                disabled={(this.props.returnItem && (this.state.preReturnReasonId) && (this.props.returnItem.status === COMPLETED_STATUS))}
                                disableClearable
                                blurOnSelect={true}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Return Reason"
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        name="issueType"
                                        required
                                    />
                                }
                            />
                        </div>
                        <div className='return-row-flexbox'>
                            {this.state.conditionId == this.props.returnConditions.find(condition => condition.returnConditionName === "Partially Damaged").returnConditionId ?
                                <>
                                    <Autocomplete
                                        disabled={this.props.returnItem && (this.props.returnItem.status === COMPLETED_STATUS) && (this.state.preAdditionalActionName !== PENDING) && this.state.preAdditionalActionName}
                                        options={this.props.returnActions}
                                        value={this.props.returnActions.find(action => action.returnActionId === this.state.additionalActionId)}
                                        getOptionLabel={(returnAction) => returnAction.returnActionName}
                                        autoHighlight={true}
                                        className="return-dropdowns"
                                        onChange={(event, value) => { this.handleAdditionalActionChange(value, -1) }}
                                        name="additionalAction"

                                        blurOnSelect={true}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                label="Additional Action"
                                                InputLabelProps={{ shrink: true }}
                                                variant="outlined"
                                                error={this.state.validate && this.state.additionalActionId === ''}
                                                helperText={this.state.validate && this.state.action === '' ? ERROR_MESSAGE : ''}
                                                name="actionType"
                                                required
                                            />
                                        }
                                    />
                                    <TextField
                                        disabled={(this.props.returnItem && (this.state.preAdditionalActionName) && (this.state.preAdditionalActionName !== PENDING) && (this.props.returnItem.status === COMPLETED_STATUS)) || (this.state.additionalActionName === PENDING)}
                                        className='text-field'
                                        variant="outlined"
                                        name="additionalActionQuantityReceived"
                                        value={this.state.additionalActionQuantityReceived}
                                        error={this.state.validate && this.state.additionalActionQuantityReceived === ''}
                                        helperText={this.state.validate && this.state.additionalActionQuantityReceived === '' ? ERROR_MESSAGE : ''}
                                        onChange={this.handleChange}
                                        onBlur={this.trimField}
                                        label={"Quantity " + ((!this.state.additionalActionName || this.state.additionalActionName === PENDING) ? "Received" : this.state.additionalActionName)}
                                        InputLabelProps={{ shrink: true }}
                                        required
                                    />
                                </>
                                :
                                null}
                        </div>
                        {(this.state?.additionalEntries?.length === 0 || (this.state != null && this.state?.additionalEntries == null)) && <IconButton onClick={
                            () => {
                                let adder = {shortcode: '', actionName: '', additionalActionQuantityReceived: '', conditionId: '', actionId: '', returnReasonId: '', returnReasonName: ''}
                                this.setState({
                                    additionalEntries: [adder]
                                })
                            }
                        }
                        ><AddCircleIcon
                            fontSize="small"
                            color='secondary'
                        /></IconButton>}
                        {this.state?.additionalEntries?.length !== 0 && this.state.additionalEntries !== null ? this.state?.additionalEntries.map((item, index) => {
                            return(
                                <div style={{marginTop: '30px', marginBottom: '30px'}}>
                                    <div className='return-row-flexbox'>
                                        <Autocomplete
                                            options={this.props.products ? this.props.products.map(product => product.shortcode).sort((a, b) => { return a.toLowerCase().localeCompare(b.toLowerCase()) }) : []}
                                            getOptionLabel={(product) => product}
                                            autoHighlight={true}
                                            disabled={this.props.returnItem && (this.props.returnItem.status === COMPLETED_STATUS)}
                                            className="return-dropdowns"
                                            onChange={(event, value) => { this.handleAutocompleteChange(value, "shortcode", index) }}
                                            value={this.state.additionalEntries[index].shortcode}
                                            name="shortcode"
                                            disableClearable
                                            blurOnSelect={true}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    label="Shortcode"
                                                    InputLabelProps={{ shrink: true }}
                                                    variant="outlined"
                                                    error={this.state.validate && this.state.additionalEntries[index].shortcode === ''}
                                                    helperText={this.state.validate && this.state.additionalEntries[index].shortcode === '' ? ERROR_MESSAGE : ''}
                                                    name="conditionType"
                                                    required
                                                />
                                            }
                                        />
                                        <TextField
                                            className='text-field'
                                            variant="outlined"
                                            disabled={this.props.returnItem && (this.props.returnItem.status === COMPLETED_STATUS)}
                                            value={this.state.additionalEntries[index].quantityReceived}
                                            error={this.state.validate && this.state.additionalEntries[index].quantityReceived === ''}
                                            helperText={this.state.validate && this.state.additionalEntries[index].quantityReceived === '' ? ERROR_MESSAGE : ''}
                                            name="quantityReceived"
                                            onChange={(e) => this.handleAutocompleteChange(e.target.value, 'quantityReceived', index)}
                                            label="Quantity Received"
                                            InputLabelProps={{ shrink: true }}
                                            required
                                        />
                                        <Autocomplete
                                            options={this.props.returnConditions}
                                            getOptionLabel={(returnCondition) => returnCondition.returnConditionName}
                                            autoHighlight={true}
                                            disabled={this.props.returnItem && (this.props.returnItem.status === COMPLETED_STATUS)}
                                            className="return-dropdowns"
                                            onChange={(event, value) => { this.handleConditionChange(value, index) }}
                                            value={this.props.returnConditions.find(condition => condition.returnConditionId === this.state.additionalEntries[index].conditionId)}
                                            name="condition"
                                            disableClearable
                                            blurOnSelect={true}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    label="Condition"
                                                    InputLabelProps={{ shrink: true }}
                                                    variant="outlined"
                                                    error={this.state.validate && this.state.additionalEntries[index].conditionId === ''}
                                                    helperText={this.state.validate && this.state.additionalEntries[index].conditionId === '' ? ERROR_MESSAGE : ''}
                                                    name="conditionType"
                                                    required
                                                />
                                            }
                                        />
                                        <IconButton onClick={
                                            () => {
                                                let adder = this.state.additionalEntries
                                                adder.splice(index, 1)
                                                this.setState({
                                                    additionalEntries: adder
                                                })
                                            }}>
                                            <RemoveCircleIcon
                                                fontSize='small'
                                                color='error'
                                            />
                                        </IconButton>
                                    </div>
                                    <div className='return-row-flexbox'>
                                        <Autocomplete
                                            disabled={this.props.returnItem && (this.props.returnItem.status === COMPLETED_STATUS) && (this.state.preActionName !== PENDING)}
                                            options={this.props.returnActions}
                                            value={this.props.returnActions.find(action => action.returnActionId === this.state.additionalEntries[index].actionId)}
                                            getOptionLabel={(returnAction) => returnAction.returnActionName}
                                            autoHighlight={true}
                                            className="return-dropdowns"
                                            disableClearable
                                            onChange={(event, value) => { this.handleActionChange(value, index) }}
                                            name="action"
                                            blurOnSelect={true}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    label="Action"
                                                    InputLabelProps={{ shrink: true }}
                                                    variant="outlined"
                                                    error={this.state.validate && this.state.additionalEntries[index].actionId === ''}
                                                    helperText={this.state.validate && this.state.additionalEntries[index].actionId === '' ? ERROR_MESSAGE : ''}
                                                    name="actionType"
                                                    required
                                                />
                                            }
                                        />
                                        <TextField
                                            disabled={(this.props.returnItem && (this.state.preActionName !== PENDING) && (this.props.returnItem.status === COMPLETED_STATUS)) || (this.state.additionalEntries[index].actionName === PENDING)}
                                            className='text-field'
                                            variant="outlined"
                                            name="actionQuantityReceived"
                                            value={this.state.additionalEntries[index].actionQuantityReceived}
                                            error={this.state.validate && this.state.additionalEntries[index].actionQuantityReceived === ''}
                                            helperText={this.state.validate && this.state.additionalEntries[index].actionQuantityReceived === '' ? ERROR_MESSAGE : ''}
                                            onChange={(e) => this.handleAutocompleteChange(e.target.value, 'actionQuantityReceived', index)}
                                            label={"Quantity " + ((!this.state.additionalEntries[index].actionName || this.state.additionalEntries[index].actionName === PENDING) ? "Received" : this.state.additionalEntries[index].actionName)}
                                            InputLabelProps={{ shrink: true }}
                                            required
                                        />
                                            <Autocomplete
                                                options={this.props.returnReasons}
                                                getOptionLabel={(returnReasons) => returnReasons.returnReasonName}
                                                autoHighlight={true}
                                                className="return-dropdowns"
                                                onChange={(event, value) => { this.handleIssueChange(value, index) }}
                                                value={{ returnReasonId: this.state.additionalEntries[index].returnReasonId, returnReasonName: this.state.additionalEntries[index].returnReasonName }}
                                                name="issue"
                                                disabled={(this.props.returnItem && (this.state.preReturnReasonId) && (this.props.returnItem.status === COMPLETED_STATUS))}
                                                disableClearable
                                                blurOnSelect={true}
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        label="Return Reason"
                                                        InputLabelProps={{ shrink: true }}
                                                        variant="outlined"
                                                        name="issueType"
                                                        required
                                                    />
                                                }
                                            />
                                    </div>
                                    {this.state.additionalEntries[index].conditionId === this.props.returnConditions.find(condition => condition.returnConditionName === "Partially Damaged").returnConditionId ?
                                        <div className="return-row-flexbox">
                                            <Autocomplete
                                                disabled={this.props.returnItem && (this.props.returnItem.status === COMPLETED_STATUS) && (this.state.preAdditionalActionName !== PENDING) && this.state.preAdditionalActionName}
                                                options={this.props.returnActions}
                                                value={this.props.returnActions.find(action => action.returnActionId === this.state.additionalEntries[index].additionalActionId)}
                                                getOptionLabel={(returnAction) => returnAction.returnActionName}
                                                autoHighlight={true}
                                                className="return-dropdowns"
                                                onChange={(event, value) => { this.handleAdditionalActionChange(value, index) }}
                                                name="additionalAction"
                                                blurOnSelect={true}
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        label="Additional Action"
                                                        InputLabelProps={{ shrink: true }}
                                                        variant="outlined"
                                                        error={this.state.validate && this.state.additionalEntries[index].additionalActionId === ''}
                                                        helperText={this.state.validate && this.state.action === '' ? ERROR_MESSAGE : ''}
                                                        name="actionType"
                                                        required
                                                    />
                                                }
                                            />
                                            <TextField
                                                disabled={(this.props.returnItem && (this.state.preAdditionalActionName) && (this.state.preAdditionalActionName !== PENDING) && (this.props.returnItem.status === COMPLETED_STATUS)) || (this.state.additionalEntries[index].additionalActionName === PENDING)}
                                                className='text-field'
                                                variant="outlined"
                                                name="additionalActionQuantityReceived"
                                                value={this.state.additionalEntries[index].additionalActionQuantityReceived}
                                                error={this.state.validate && this.state.additionalEntries[index].additionalActionQuantityReceived === ''}
                                                helperText={this.state.validate && this.state.additionalEntries[index].additionalActionQuantityReceived === '' ? ERROR_MESSAGE : ''}
                                                onChange={(e) => this.handleAutocompleteChange(e.target.value, 'additionalActionQuantityReceived', index)}
                                                label={"Quantity " + ((!this.state.additionalEntries[index].additionalActionName || this.state.additionalEntries[index].additionalActionName === PENDING) ? "Received" : this.state.additionalEntries[index].additionalActionName)}
                                                InputLabelProps={{ shrink: true }}
                                                required
                                            />

                                        </div>
                                        :
                                        null}
                                        {(this.state.additionalEntries.length === index + 1 && this.state.additionalEntries[this.state.additionalEntries.length - 1].shortcode.length !== 0) &&
                                            <>
                                            <IconButton onClick={
                                                () => {
                                                    let adder = {shortcode: '', actionName: '', additionalActionQuantityReceived: '', conditionId: '', actionId: '', returnReasonId: '', returnReasonName: ''}
                                                    this.setState({
                                                        additionalEntries: [...this.state.additionalEntries, adder]
                                                    })
                                                }}>
                                                <AddCircleIcon
                                                    fontSize='small'
                                                    color='secondary'
                                                />
                                            </IconButton>
                                            </>
                                        }
                                </div>
                            )
                        }) : ''}
                    </div>
                </div>

                <Divider className='section-divider' />

                <div className='return-from-input-box'>
                    <CommentHistoryTable
                        disabled={this.props.returnItem && (this.props.returnItem.status === COMPLETED_STATUS)}
                        comments={this.props.creatingForm ? [] : this.props.returnItem.comments}
                        handleCommentUpdate={this.handleCommentUpdate}
                        currentUser={this.props.currentUser}
                        clearComment={this.handleClearComment}
                        iscreating={this.props.creatingForm}
                    />
                </div>

                <Divider className='section-divider' />

                <div className='return-from-input-box'>
                    <div>
                        <div className='return-row-flexbox'>
                            <div className="file-upload">
                                <FileUpload
                                    handleFileAdd={this.handleFileAdd}
                                    handleFileDelete={this.handleFileDelete}
                                    files={this.state.files ? this.state.files : []}
                                    fetchFilePreview={(file) => this.props.fetchFilePreview({ path: file.path, returnId: this.props.returnItem.returnId })}
                                    singleFileUpload={false}
                                />
                            </div>

                            <Autocomplete
                                disabled={this.props.returnItem && (this.props.returnItem.status === COMPLETED_STATUS)}
                                options={this.props.users}
                                getOptionLabel={(user) => user.name}
                                autoHighlight={true}
                                className="return-dropdowns"
                                onChange={(event, value) => { this.handleAssignedToChange(value) }}
                                name="assignedTo"
                                value={this.props.users.find(user => user.userId === this.state.assignedToId)}
                                blurOnSelect={true}
                                disableClearable
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        // className='text-field'
                                        label="Assigned To"
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        name="assignedToField"
                                        error={this.state.validate && this.state.assignedToId === ''}
                                        helperText={this.state.validate && this.state.assignedToId === '' ? ERROR_MESSAGE : ''}
                                        required
                                    />
                                }
                            />
                            <KeyboardDatePicker
                                error={this.state.dueDate == null || this.state.dueDate == 'Invalid Date'}
                                disabled={this.props.returnItem && (this.props.returnItem.status === COMPLETED_STATUS)}
                                autoOk
                                variant="inline"
                                inputVariant="outlined"
                                label="Due Date"
                                format="yyyy/MM/dd"
                                className='date-fields'
                                value={this.state.dueDate}
                                InputAdornmentProps={{ position: "start" }}
                                onChange={date => this.handleDateChange(date, true)}
                                required
                            />
                        </div>

                        {!this.props.creatingForm ?
                            <>
                                <div className='return-row-flexbox'>
                                    <div className='text-field' />
                                    <TextField
                                        className='text-field'
                                        variant="outlined"
                                        name="refundAmount"
                                        disabled={(this.props.returnItem && (this.props.returnItem.status === COMPLETED_STATUS))}
                                        error={this.state.validate && this.state.refundAmount === ''}
                                        helperText={this.state.validate && this.state.refundAmount === '' ? ERROR_MESSAGE : ''}
                                        value={this.state.refundAmount}
                                        onChange={this.handleChange}
                                        onBlur={this.trimField}
                                        label={REFUND_AMOUNT_CHECKBOX}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AttachMoneyIcon />
                                                </InputAdornment>
                                            ),
                                            shrink: true
                                        }}
                                    />
                                    <div className='refund-completed-checkbox'>
                                        <FormControlLabel
                                            control={<Checkbox disabled={this.props.returnItem && (this.props.returnItem.status === COMPLETED_STATUS)} color='primary' checked={this.state.refundCompleted} onChange={() => this.setState({ refundCompleted: !this.state.refundCompleted })} name="refundCompletedCheckBox" />}
                                            label="Refund Completed"
                                        />
                                    </div>
                                </div>
                            </>
                            :
                            null

                        }

                    </div>
                    <div className='form-buttons'>
                        <div className={'return-cancel-button'}>
                            <Button
                                variant='contained'
                                onClick={this.cancel}

                            >
                                {CANCEL}
                            </Button>
                        </div>

                        <div className='return-save-button'>
                            <Button
                                variant='contained'
                                disabled={!this.validateData()}
                                onClick={(event) => {
                                    event.showLoadingOverlay = true  // Prevents spam clicking the button
                                    this.props.submit(this.state, this.props.creatingForm)
                                }}
                                color='primary'
                            >
                                {SAVE}
                            </Button>
                        </div>
                    </div>

                </div>

            </div>
        );
    }
}

ReturnsForm.propTypes = {
    cancel: PropTypes.func,
    creatingForm: PropTypes.bool,
    submit: PropTypes.func,
    returnActions: PropTypes.array,
    returnConditions: PropTypes.array,
    returnItem: PropTypes.object,
    currentUser: PropTypes.object,
    users: PropTypes.array,
    fetchFilePreview: PropTypes.func
}

ReturnsForm.defaultProps = {
    cancel: () => { },
    creatingForm: false,
    submit: () => { },
    returnActions: [],
    returnConditions: [],
    returnItem: {},
    currentUser: {},
    users: [],
    fetchFilePreview: () => { }
}

export default ReturnsForm;