import React from 'react';

import './InvoiceTemplates.css'
import CarrierInvoiceTemplates from "./carrierInvoiceTemplates/CarrierInvoiceTemplates";
import SupplierInvoiceTemplates from "./supplierInvoiceTemplates/SupplierInvoiceTemplates";

class InvoiceTemplates extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSupplier: true,
            tabIndex: 0,
        }
    }

    handleTabChange = (e, value) => {
        if (value !== this.state.tabIndex) {
            this.setState({
                showSupplier: !this.state.showSupplier,
                tabIndex: value
            })
        }
    }

    render() {
        return (
            <>
                {this.state.showSupplier ?
                    <SupplierInvoiceTemplates
                        showSupplier={this.state.showSupplier}
                        onTabChange={this.handleTabChange}
                        tabIndex={this.state.tabIndex}
                    /> :
                    <CarrierInvoiceTemplates
                        showSupplier={this.state.showSupplier}
                        onTabChange={this.handleTabChange}
                        tabIndex={this.state.tabIndex}
                    />}
            </>
        );
    }

}

export default InvoiceTemplates