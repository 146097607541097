import React from 'react';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TextField from "@material-ui/core/TextField";
import {Autocomplete} from "@material-ui/lab";
import {ADD_TAG, CANCEL, SAVE} from "./constants";
import {Box} from "@mui/material";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import {markAsCancelled} from "../../redux/actions/orders";
import withShipment from "../../withShipment";


class MarkAsCancelledDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cancelDate: new Date(),
            cancelNote: "",
            cancelTag: null
        }
    }

    handleDateChange = (date) => {
        this.setState({
            cancelDate: date
        })
    }

    handleNoteChange = (e) => {
        this.setState({
            cancelNote: e.target.value
        })
    }

    handleCancelTag = (value) => {
        this.setState({
            cancelTag: value
        })
    }

    handleCancelSave = () => {
        this.setState({
            cancelOpen: false
        })
        this.markAsCancelled()
    }

    markAsCancelled = () => {
        const readyShipments = this.props.selected.map(selected => selected.order)
        this.props.markAsCancelled(readyShipments, this.state.cancelDate, this.state.cancelNote, this.state.cancelTag?.tagName ?? "")
        if (this.props.editOrderOpen) {
            this.props.handleEditShipmentClose()
        } else {
            this.props.clearSelected()
        }
    }

    render() {
        return (
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
                spacing={1}
                noValidate
                autoComplete="off"
            >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        autoOk
                        name="toDate"
                        variant="inline"
                        inputVariant="outlined"
                        label="Select cancelled date"
                        format="yyyy/MM/dd"
                        value={this.state.cancelDate}
                        InputAdornmentProps={{ position: "start" }}
                        onChange={date => this.handleDateChange(date)}
                    />
                </MuiPickersUtilsProvider>
                <TextField
                    id="outlined-multiline-flexible"
                    label="Cancel Reason"
                    multiline
                    maxRows={4}
                    onChange={e => this.handleNoteChange(e)}
                    variant="outlined"
                />
                <Autocomplete
                    limitTags={0}
                    options={this.props.tags}
                    getOptionLabel={(entry) => entry.tagName}
                    autoHighlight={true}
                    renderOption={(option) => (
                        <React.Fragment>
                            {option.tagName}
                        </React.Fragment>
                    )}
                    size= "small"
                    name="addTag"
                    onChange={(event, value) => {
                        this.handleCancelTag(value)
                    }}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label={ADD_TAG}
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            name="addTag"
                        />
                    }
                />
                <div className='horizontally-center-row'>
                    <Button
                        style={{width: "100px"}}
                        variant='contained'
                        onClick={this.handleCancelClose}
                    >
                        {CANCEL}
                    </Button>
                    <div className='margin-20px'/>
                    <Button
                        style={{width: "100px"}}
                        variant='contained'
                        onClick={this.handleCancelSave}
                        color="primary"
                    >
                        {SAVE}
                    </Button>
                </div>
            </Box>)
    }
}

MarkAsCancelledDialog.propTypes = {
    markAsCancelled: PropTypes.func
}
const actionCreators = {
    markAsCancelled
}

export default withShipment({
    actionCreators
}, MarkAsCancelledDialog)