
import React, {Component} from "react";
import ClearIcon from '@material-ui/icons/Clear';
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";

class FilterTag extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Button className="filterTag" size="small" endIcon={<ClearIcon />} 
                    onClick={(event) => {
                        if (this.props.tagTagId) {
                            this.props.removeTag(event, this.props.tag, this.props.tagTagId)
                        } else {
                            this.props.removeTag(event, this.props.tag)
                        }
                    }}>{this.props.name}</Button>
        )
    }
}

FilterTag.propTypes = {
    name: PropTypes.string,
    removeTag: PropTypes.func,
    id: PropTypes.string,
    tag: PropTypes.string
}

FilterTag.defaultProps = {
    name: "",
    removeTag: ()=>{},
    id: "",
    tag: ""
}

export default FilterTag;

