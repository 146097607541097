import axios from "axios";
import {call, put, takeLatest} from "redux-saga/effects";
import {
    CREATE_OR_EDIT_FTL_CARRIER_INVOICE_TEMPLATE,
    DELETE_FULL_TRUCKLOADS_CARRIER,
    FETCH_FULL_TRUCKLOADS_CARRIERS, GET_FTL_CARRIER_INVOICE_TEMPLATE_PDF,
    LIST_FTL_CARRIER_INVOICE_TEMPLATES,
    LIST_FTL_CARRIER_WITHOUT_TEMPLATE,
    LIST_FTL_CARRIERS_WITHOUT_TEMPLATE,
    SAVE_FULL_TRUCKLOADS_CARRIERS
} from "../../constants/fullTruckloadCarriers";
import {hideError, requestStatus, showErrorMessage} from "../../actions/global";
import {
    FTL_CARRIER_INVOICE_TEMPLATES,
    FULL_TRUCKLOAD_BROKERS,
    FULL_TRUCKLOAD_CARRIERS
} from "../../../components/global/Server/endpoints";
import {SERVER_FAILED_ERROR_MESSAGE} from "../../../pages/Dashboard/DashboardFinal/constants";
import {
    removeInvoiceTemplate,
    storeFTLCarrierInvoiceTemplatePDF,
    storeFTLCarrierInvoiceTemplates,
    storeFTLCarriersWithoutTemplate,
    storeFullTruckloadCarrier,
    storeFullTruckloadCarriers, updateFTLCarrierInvoiceTemplates
} from "../../actions/fullTruckloadCarriers";
import {storeFullTruckloadBrokers} from "../../actions/fullTruckloadBrokers";
import {DELETE_INVOICE_TEMPLATE} from "../../constants/settings";

const getHeaders = () => {
    const token = localStorage.getItem('token');
    return {
        "Authorization": `Bearer ${token}`
    }
}

const getRequest = (endpoint) => {
    return axios.get(endpoint, {
        headers: getHeaders()
    })
        .then(response => response);
}

const postRequest = (endpoint, data) => {
    return axios.post(endpoint, data, {
        headers: getHeaders()
    })
        .then(response => response)
}

function* fetchFullTruckloadCarriers() {
    yield put(hideError())
    try {
        const response = yield call(() => getRequest(FULL_TRUCKLOAD_CARRIERS.FETCH_FULL_TRUCKLOADS_CARRIERS))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === 200
        };
        if (response.data.success) {
            yield put(storeFullTruckloadCarriers(response.data.fullTruckloadCarriers))
        }
        yield put(requestStatus(status))
    }
    catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* saveFullTruckloadCarriers(action) {
    yield put(hideError())
    try{
        const response = yield call(() => postRequest(FULL_TRUCKLOAD_CARRIERS.SAVE_FULL_TRUCKLOAD_CARRIERS, {data : action.data}))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === 200
        };
        if (response.data.success) {
            yield put(storeFullTruckloadCarrier(response.data.updatedFullTruckLoad))
        }
        yield put(requestStatus(status))
    }
    catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))

    }
}

function* deleteFullTruckloadCarriers(action) {
    yield put(hideError())
    try{
        const response = yield call(() => postRequest(FULL_TRUCKLOAD_CARRIERS.DELETE_FULL_TRUCKLOAD_CARRIERS, {carrierId : action.data}))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.data.success
        }
        if (response.status === 200 && response.data.success) {
            yield put(storeFullTruckloadCarriers(response.data.fullTruckloadCarriers))
        } else {
            status.message = response.data?.message
            yield put(showErrorMessage(status.message))
            yield put(requestStatus(status))
        }
    }catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* listFTLCarrierInvoiceTemplates(action) {
    yield put(hideError())
    try{
        const response = yield call(() => getRequest(FTL_CARRIER_INVOICE_TEMPLATES.LIST_FTL_CARRIER_INVOICE_TEMPLATES))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.data.success
        }
        if (response.status === 200 && response.data.success) {
            yield put(storeFTLCarrierInvoiceTemplates(response.data.templates))
        } else {
            status.message = response.data?.message
            yield put(showErrorMessage(status.message))
            yield put(requestStatus(status))
        }
    }catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* listFTLCarriersWithoutTemplate(action) {
    yield put(hideError())
    try{
        const response = yield call(() => getRequest(FTL_CARRIER_INVOICE_TEMPLATES.LIST_FTL_CARRIERS_WITHOUT_TEMPLATE))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.data.success
        }
        if (response.status === 200 && response.data.success) {
            yield put(storeFTLCarriersWithoutTemplate(response.data.carriers))
        } else {
            status.message = response.data?.message
            yield put(showErrorMessage(status.message))
            yield put(requestStatus(status))
        }
    }catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* createOrEditFTLCarrierInvoiceTemplate (action) {
    yield put(hideError())
    try{
        const response = yield call(() => postRequest(FTL_CARRIER_INVOICE_TEMPLATES.CREATE_OR_EDIT_FTL_CARRIERS_INVOICE_TEMPLATE, action.data))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.data.success
        }
        if (response.status === 200 && response.data.success) {
            yield put(updateFTLCarrierInvoiceTemplates(response.data.template))
            action.reset()
        } else {
            status.message = response.data?.message
            yield put(showErrorMessage(status.message))
            yield put(requestStatus(status))
        }
    }catch (e) {
        console.log(e.toString())
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* getFTLCarrierInvoiceTemplatePDF (action) {
    yield put (hideError())
    try {
        const response = yield call(() => postRequest(FTL_CARRIER_INVOICE_TEMPLATES.GET_FTL_CARRIER_INVOICE_TEMPLATE_PDF, {carrierId: action.carrierId}))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.data.success
        }
        if (response.status === 200 && response.data.success) {
            yield put(storeFTLCarrierInvoiceTemplatePDF(response.data.bytes))
        } else {
            status.message = response.data?.message
            yield put(showErrorMessage(status.message))
            yield put(requestStatus(status))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* deleteInvoiceTemplate (action) {
    yield put (hideError())
    try {
        const response = yield call(() => postRequest(FTL_CARRIER_INVOICE_TEMPLATES.DELETE_INVOICE_TEMPLATE, {carrierId: action.carrierId}))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.data.success
        }
        if (response.status === 200 && response.data.success) {
            yield put(removeInvoiceTemplate(action.carrierId))

        } else {
            status.message = response.data?.message
            yield put(showErrorMessage(status.message))
            yield put(requestStatus(status))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

export default function* fullTruckloadCarrierSagas() {
    yield takeLatest(FETCH_FULL_TRUCKLOADS_CARRIERS,fetchFullTruckloadCarriers)
    yield takeLatest(SAVE_FULL_TRUCKLOADS_CARRIERS,saveFullTruckloadCarriers)
    yield takeLatest(DELETE_FULL_TRUCKLOADS_CARRIER,deleteFullTruckloadCarriers)
    yield takeLatest(LIST_FTL_CARRIER_INVOICE_TEMPLATES,listFTLCarrierInvoiceTemplates)
    yield takeLatest(LIST_FTL_CARRIERS_WITHOUT_TEMPLATE, listFTLCarriersWithoutTemplate)
    yield takeLatest(GET_FTL_CARRIER_INVOICE_TEMPLATE_PDF, getFTLCarrierInvoiceTemplatePDF)
    yield takeLatest(CREATE_OR_EDIT_FTL_CARRIER_INVOICE_TEMPLATE,createOrEditFTLCarrierInvoiceTemplate)
    yield takeLatest(DELETE_INVOICE_TEMPLATE,deleteInvoiceTemplate)
}