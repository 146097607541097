import React from 'react'
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Chip from "@material-ui/core/Chip";
import DateFnsUtils from "@date-io/date-fns";

class ChequeFiltersDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
    }


    render() {
        return (
            <>
                <div>
                    <DialogTitle id="form-dialog-title" style={{ width: "70%", display: "flex", justifyContent: "center" }}>
                        Filters
                    </DialogTitle>
                    <DialogContent className={"dialogBox"}>
                        <Autocomplete
                            multiple
                            open={this.state.open}
                            value={this.props.filtersMap?.category ?? []}
                            options={this.props.listItems?.sort((a, b) => {
                                const nameA = a.name ? a.name.toLowerCase() : '';
                                const nameB = b.name ? b.name.toLowerCase() : '';
                                return nameA.localeCompare(nameB);
                            })}
                            renderTags={(value) =>
                                value.map((option) => (
                                    <Chip
                                        variant="outlined"
                                        size="small"
                                        style={{borderColor: "#acd685", margin: "1px 6px 1px 0", fontSize: "12px"}}
                                        label={option.name}
                                    />
                                ))
                            }
                            getOptionLabel={(option) => option.name ?? ''}
                            autoHighlight={true}
                            style={{ width: 350, margin: 10, marginBottom: 20 }}
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    <Checkbox
                                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.name}
                                </React.Fragment>
                            )}
                            onOpen={(event) => {this.setState({open : true})}}
                            onClose={(event, reason) => {
                                reason === 'blur' ? this.setState({open : false}) : this.setState({open : true})
                            }}
                            name={this.props.fieldName}
                            onChange={(event, value) => {
                                this.props.onChange(this.props.fieldName, value)
                            }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label={this.props.fieldTitle}
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    name={this.props.fieldName}
                                />
                            }
                        />
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            autoOk
                            name="fromDate"
                            variant="inline"
                            inputVariant="outlined"
                            label="From"
                            format="yyyy-MM-dd"
                            style={{ width: 350, marginBottom: 20 }}
                            value={this.props.fromDate}
                            InputAdornmentProps={{ position: "start" }}
                            onChange={dateInput => {
                                let date = new Date(dateInput?.toString())
                                if (date.toString() === "Invalid Date") {
                                    return
                                }
                                this.props.handleDateChange(date, 'fromDate')
                            }}
                        />
                        </MuiPickersUtilsProvider>

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            autoOk
                            name="toDate"
                            variant="inline"
                            inputVariant="outlined"
                            label="To"
                            format="yyyy-MM-dd"
                            style={{ width: 350, marginBottom: 20 }}
                            value={this.props.toDate}
                            InputAdornmentProps={{ position: "start" }}
                            onChange={dateInput => {
                                let date = new Date(dateInput?.toString())
                                if (date.toString() === "Invalid Date") {
                                    return
                                }
                                date.setHours(19);
                                date.setMinutes(59);
                                date.setSeconds(59);
                                this.props.handleDateChange(date, 'toDate')
                            }
                            }
                        />
                        </MuiPickersUtilsProvider>
                    </DialogContent>

                    <DialogActions style={{ justifyContent: "center", marginBottom: 10 }}>
                        <Button variant='contained' onClick={() => this.props.onClose(false, true)}>Cancel</Button>
                        <Button variant='contained' onClick={() => {
                            this.props.onClose(true, true)
                        }} color="primary">Apply</Button>
                    </DialogActions>

                </div>
            </>
        )
    }
}

export default ChequeFiltersDialog;