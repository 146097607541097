import {
  combineReducers
} from "redux";


import orders from './orders';
import purchaseOrders from "./purchaseOrders"
import finalDashboard from './finalDashboard';
import auth from './auth'
import automationRules from './automationRules'
import userManagement from './userManagement';
import invoices from './invoices'
import global from './global';
import dashboard from './dashboard';
import settings from './settings';
import inventory from './inventory';
import alerts from './alerts';
import returns from './returns';
import tickets from './tickets';
import account from './account';
import admin from './admin';
import damage from './damage';
import fullTruckload from "./fullTruckload";
import claims from "./claims"
import accounting from "./accounting";
import fullTruckloadBroker from "./fullTruckloadBroker";
import fullTruckloadCarrier from "./fullTruckloadCarrier";
import customer from "./customer";
import calendarReducer from "./accounting/calenderReducer";

const rootReducer = combineReducers({
  orders,
  finalDashboard,
  auth,
  automationRules,
  userManagement,
  global,
  dashboard,
  settings,
  purchaseOrders,
  inventory,
  alerts,
  returns,
  tickets,
  account,
  invoices,
  admin,
  damage,
  fullTruckload,
  fullTruckloadBroker,
  fullTruckloadCarrier,
  accounting,
  claims,
  calendar: calendarReducer,
  customer,
});

export default rootReducer;