import React from 'react'

// Constants
import {
    CARRIER_INVOICE_TEMPLATE_FIELDS,
    CARRIER_INVOICE_TEMPLATE_SEPARATOR_FIELDS,
    FIELD_COLUMN_FIELDS,
    SINGLE_INVOICE_SEPARATOR,
    ENTRY_SEP_TOOL_TIP, SUMMARY_TOOL_TIP, MULTI_SEP_TOOL_TIP,
} from "./constants"

// Custom Components
import PDFViewer from "../../../global/PDFViewer";

// Material UI
import {
    FormControlLabel,
    IconButton,
    Radio,
    RadioGroup,
    Slide, Switch,
    TextField,
    Tooltip,
    withStyles,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ClearIcon from "@material-ui/icons/Clear";
import Checkbox from "@material-ui/core/Checkbox";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import styles from "../../../../pages/Dashboard/DashboardFinal/DashboardFinal.module.css";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {Autocomplete} from "@material-ui/lab";
import {ToggleButton, ToggleButtonGroup} from "@mui/material";


const GreenRadio = withStyles({
    root: {
        color: "#acd685",
        '&$checked': {
            color: "#acd685",
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);

class EditCarrierInvoiceTemplates extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 1,
            pageIndex: 0,
            selectedField: "",
            fields: props.fields ? props.fields : JSON.parse(JSON.stringify(CARRIER_INVOICE_TEMPLATE_FIELDS)),
            moveType: {
                name: ""
            },
            prevSelectedField: "",
            label: {
                x: 0,
                y: 0,
                label: "",
                boundingRect: {
                    x: 0,
                    y: 0,
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    width: 0,
                    height: 0
                }
            },
            tableValidation: false,
        }
        this.startY = 240
        this.startX = 240
        this.pdfStartY = this.startY
        this.pdfEndY = 932
        this.pdfHeight = 792
        this.pdfWidth = 612
        this.resizeMargin = "3px"
        this.green = "#acd685"
        this.changeState = this.changeState.bind(this)
        this.createSelectBox = this.createSelectBox.bind(this)
        this.resizeSelectBox = this.resizeSelectBox.bind(this)
        this.completeSelectBox = this.completeSelectBox.bind(this)
        this.removeLabel = this.removeLabel.bind(this)
        this.addFieldColumn = this.addFieldColumn.bind(this)
        this.setAllVisible = this.setAllVisible.bind(this)
        this.toggleTableValidation = this.toggleTableValidation.bind(this)
    }

    changePageNumber = (number) => {
        this.setState({pageNumber: number})
        this.setState({pageIndex: number-1})
    }

    increasePageNumber = () => {
        this.setState({pageNumber: this.state.pageNumber + 1})
        this.setState({pageIndex: this.state.pageIndex+1})
    }

    decreasePageNumber = () => {
        if(this.state.pageNumber > 0) {
            this.setState({pageNumber: this.state.pageNumber - 1})
            this.setState({pageIndex: this.state.pageIndex-1})
        }
    }

    componentDidMount() {
        let newFields = this.scaleFields(this.state.fields, this.props.scale)
        this.setState({fields: newFields})
    }

    componentWillUnmount() {
        let newFields = this.scaleFields(this.state.fields, 1.0 / this.props.scale)
        this.setState({fields: newFields})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.scale !== this.props.scale) {
            this.setState({
                fields: this.scaleFields(this.state.fields, this.props.scale / prevProps.scale),
            })
        }
    }

    scaleFields(fields, scale) {
        let newFields = {...fields}
        for (const key in newFields) {
            fields[key].position.x = fields[key].position.x * scale
            fields[key].position.y = fields[key].position.y * scale
            fields[key].position.w = fields[key].position.w * scale
            fields[key].position.h = fields[key].position.h * scale
        }
        return newFields
    }

    calculatePDFStart() {
        return ((document.documentElement.clientWidth - this.startX - (this.pdfWidth * this.props.scale)) / 2) + 233;
    }
    calculatePDFEnd() {
        return this.calculatePDFStart() + (this.pdfWidth * this.props.scale);
    }
    createSelectBox(e) {
        e.preventDefault()
        if (this.state.selectedField !== "") {
            let newFields = this.state.fields
            let box = e.target.getBoundingClientRect()
            let posX = Math.round(e.clientX - box.x)
            let posY = Math.round(e.pageY + document.getElementsByClassName("shipment-background")[0].scrollTop - this.pdfStartY)
            newFields[this.state.selectedField].position.x = posX
            newFields[this.state.selectedField].position.y = posY
            newFields[this.state.selectedField].position.w = 0
            newFields[this.state.selectedField].position.h = 0
            this.setState({
                fields: newFields,
                moveType: { name: "selecting", startX: posX, startY: posY }
            })

            if(this.state.selectedField === "shipDate")
                this.props.updateField('entrySepStart', this.state.pageIndex);
            if(this.state.selectedField === "multiWeight")
                this.props.updateField('entryMultiSepStart', this.state.pageIndex);
            if(this.state.selectedField === "transportCharges")
                this.props.updateField('entryTransportChargeStart', this.state.pageIndex);
            if(this.state.selectedField === 'summaryStart')
                this.props.updateField('summaryStartPage', this.state.pageIndex);
            if(this.state.selectedField === 'summaryEnd')
                this.props.updateField('summaryEndPage', this.state.pageIndex);
        }
    }
    resizeSelectBox(e) {
        e.preventDefault()
        let pdfStartX = Math.round(this.calculatePDFStart())
        let pdfEndX = Math.round(this.calculatePDFEnd())
        let posX = Math.round(Math.max(Math.min(e.clientX, pdfEndX), pdfStartX) - pdfStartX);
        let posY = Math.round(Math.max(Math.min(e.pageY + document.getElementsByClassName("shipment-background")[0].scrollTop, this.pdfEndY * this.props.scale), this.pdfStartY) - this.pdfStartY);
        let selected = this.state.selectedField
        let type = this.state.moveType
        let newFields = this.state.fields
        if (type.name === "selecting") {
            let startX = type.startX
            let startY = type.startY
            if (posX > startX) { // Mouse is to the right of the starting position
                newFields[selected].position.w = posX - newFields[selected].position.x
                if (posY > startY) { // Bottom right
                    newFields[selected].position.h = posY - newFields[selected].position.y
                } else { // Top Right
                    newFields[selected].position.y = posY
                    newFields[selected].position.h = startY - posY
                }
            } else { // Mouse is to the left of the starting position
                newFields[selected].position.w = startX - posX
                newFields[selected].position.x = posX
                if (posY > startY) { // Bottom left
                    newFields[selected].position.h = posY - newFields[selected].position.y
                } else { // Bottom right
                    newFields[selected].position.y = posY
                    newFields[selected].position.h = startY - posY
                }
            }
            this.setState({fields: newFields})
        } else if (type.name === "lineMove") {
            switch (type.type) {
                case "x":
                    if (posX < newFields[selected].position.x + newFields[selected].position.w) {
                        newFields[selected].position.w += newFields[selected].position.x - posX;
                        newFields[selected].position.x = posX;
                    } else {
                        type.type = "w"
                        this.setState({moveType: type})
                    }
                    break;
                case "w":
                    if (posX > newFields[selected].position.x) {
                        newFields[selected].position.w = posX - newFields[selected].position.x;
                    } else {
                        type.type = "x"
                        this.setState({moveType: type})
                    }
                    break;
                case "y":
                    if (posY < newFields[selected].position.y + newFields[selected].position.h) {
                        newFields[selected].position.h += newFields[selected].position.y - posY;
                        newFields[selected].position.y = posY;
                    } else {
                        type.type = "h"
                        this.setState({moveType: type})
                    }
                    break;
                case "h":
                    if (posY > newFields[selected].position.y) {
                        newFields[selected].position.h = posY - newFields[selected].position.y;
                    } else {
                        type.type = "y"
                        this.setState({moveType: type})
                    }
                    break;
            }
            this.setState({fields: newFields})
        } else if (type.name === "boxMove") {
            let boundEndX = this.pdfWidth * this.props.scale
            let val = Math.max(posX - type.offsetX, 0)
            if (val + newFields[selected].position.w > boundEndX) {
                val = boundEndX - newFields[selected].position.w
            }
            newFields[selected].position.x = val
            if (type.offsetY) {
                val = Math.max(posY - type.offsetY, 0)
                if (val + newFields[selected].position.h > this.pdfHeight * this.props.scale) {
                    val = (this.pdfHeight * this.props.scale) - newFields[selected].position.h
                }
                newFields[selected].position.y = val
            }
            this.setState({fields: newFields})
        }
    }
    lineMove(e, type, fieldName) {
        e.stopPropagation();
        const moveType = { name: "lineMove", type: type }
        this.setMoveType(moveType, fieldName)
    }
    initSelecting(e, startX, startY, fieldName) {
        e.stopPropagation();
        const moveType = { name: "selecting", startX: startX, startY: startY }
        this.setMoveType(moveType, fieldName)
    }
    boxMove(e, field) {
        e.stopPropagation();
        let targetRect = e.target.getBoundingClientRect()
        let moveType = { name: "boxMove", offsetX: Math.round(e.clientX - targetRect.x), offsetY: Math.round(e.clientY - targetRect.y) }
        this.setMoveType(moveType, field)
    }
    setMoveType(moveType, field) {
        this.setState({ moveType: moveType, prevSelectedField: this.state.selectedField, selectedField: field })
    }
    completeSelectBox = (e) => {
        e.preventDefault()
        if (this.state.moveType.name === "") return
        this.setState({moveType: {name: ""}, selectedField: this.state.prevSelectedField, prevSelectedField: ""})
        this.props.updateParentFields(this.state.fields)

        if(this.state.selectedField === "shipDate")
            this.props.updateField('entrySepStart', this.state.pageIndex);
        if(this.state.selectedField === "multiWeight")
            this.props.updateField('entryMultiSepStart', this.state.pageIndex);
        if(this.state.selectedField === "transportCharges")
            this.props.updateField('entryTransportChargeStart', this.state.pageIndex);
        if(this.state.selectedField === 'summaryStart')
            this.props.updateField('summaryStartPage', this.state.pageIndex);
        if(this.state.selectedField === 'summaryEnd')
            this.props.updateField('summaryEndPage', this.state.pageIndex);
    }
    clearBox(field) {
        let newFields = this.state.fields
        newFields[field].position.x = 0
        newFields[field].position.w = 0
        this.setState({ fields: newFields })
    }
    showLabel(e, label) {
        if (this.state.label.label === "") {
            this.setState({
                label: {
                    x: e.clientX,
                    y: `calc(${e.clientY}px - 1em)`,
                    label: label,
                    boundingRect: e.target.getBoundingClientRect()
                }
            })
        }
    }
    removeLabel(e) {
        let mouseX = e.clientX
        let mouseY = e.pageY + document.getElementsByClassName("shipment-background")[0].scrollTop
        let boundingRect = this.state.label.boundingRect
        if (mouseX <= boundingRect.x || boundingRect.right <= mouseX || mouseY <= boundingRect.y || boundingRect.bottom <= mouseY) {
            this.setState({
                label: { x: 0, y: 0, label: "", boundingRect: { x: 0, y: 0, top: 0, left: 0, right: 0, bottom: 0, width: 0, height: 0 } }
            })
        }
    }
    selectField(e) {
        this.changeState("selectedField", e.target.value)
        if (e.target.value === this.props.fieldError) {
            this.props.updateField("fieldError", "")
        }
    }
    changeState(field, value) {
        if (field === "selectedField" && this.state.selectedField === value) value = ""
        this.setState({ [field]: value })
    }
    handleSeparatorChange(field, e) {
        let tempSeparator = {...this.props.separatorFields}
        tempSeparator[field].value = e.target.value
        if (this.props.separatorError === field) this.props.updateField("separatorError", "")
        this.props.updateField("separatorFields", tempSeparator)
    }

    handleFieldColumnChange(index, field, e) {
        let tempFieldColumns = [...this.props.fieldColumns]
        tempFieldColumns[index][field] = field === "titleIncluded" ? e.target.checked :e.target.value
        if (field === "title" && this.props.fieldColumnError === index) this.props.updateField("fieldColumnError", -1)
        this.props.updateField("fieldColumns", tempFieldColumns)
    }
    addFieldColumn() {
        this.props.updateField("fieldColumns", [...this.props.fieldColumns, {...FIELD_COLUMN_FIELDS}])
    }
    formatLabel(label) {
        let result = label.charAt(0).toUpperCase()
        for (let i = 1; i < label.length; ++i) {
            let ch = label.charAt(i)
            if (ch === ch.toUpperCase()) {
                result += " "
            }
            result += ch
        }
        return result
    }
    removeFieldColumn(index) {
        if (this.props.fieldColumnError === index) this.props.updateField("fieldColumnError", -1)
        this.props.updateField("fieldColumns", this.props.fieldColumns.filter((column, i) => i !== index))
    }

    changeVisibility(e, field, fieldType) {
        let temp = this.state[fieldType]
        temp[field].visible = e.target.checked
        this.setState({[fieldType]: temp})
    }
    fieldVisibility(visible, key) {
        return (this.state.moveType.name && this.state.selectedField !== key) || !visible ? "hidden" : "visible"
    }
    setAllVisible(e) {
        let tempFields = this.state.fields
        for (const key in tempFields) {
            if (this.props.fieldDefined(tempFields[key].position)) tempFields[key].visible = e.target.checked
        }

        this.setState({fields: tempFields})
    }
    allVisible() {
        for (const key in this.state.fields) {
            if (!this.state.fields[key].visible) return false
        }
        return true
    }
    toggleTableValidation() {
        this.setState({
            tableValidation: !this.state.tableValidation
        })
        if(this.state.tableValidation)
            this.props.updateField('validationType', 'List')
        else
            this.props.updateField('validationType', 'Table')

    }

    render() {
        return (<>
            <div className="label" style={{ top: this.state.label.y, left: this.state.label.x }} onMouseLeave={this.removeLabel}>
                {this.state.label.label}
            </div>
            <div
                style={{position: "relative", overflow: "hidden"}}
                onMouseUp={this.completeSelectBox}
                onMouseMove={this.resizeSelectBox}
                onMouseLeave={this.completeSelectBox}
            >
                {/*View the PDF*/}
                <div style={{width: "100%", display: "inline-block", minHeight: "882px"}}>
                    <PDFViewer
                        data={this.props.pdf.contents}
                        invoiceTemplateEdit={false}
                        selecting={true}
                        createSelectBox={this.createSelectBox}
                        increasePageCount={this.increasePageNumber}
                        decreasePageCount={this.decreasePageNumber}
                        changePageNumber={this.changePageNumber}
                        scale={this.props.scale}
                    />
                </div>
                {/*Display the Markers on the PDF*/}
                <div className="selectAreaHolder" style={{height: this.pdfHeight * this.props.scale, top: "100px"}}>
                    <div
                        style={{width: this.pdfWidth * this.props.scale, position: "relative"}}
                        className={ this.state.selectedField !== "" && this.state.moveType.name === "" ? "selecting" : "" }
                        onMouseDown={this.createSelectBox}
                    >
                        {Object.keys(this.state.fields).map((key, index) => {
                            let thisBox = this.state.fields[key].position
                            if ((key === 'shipDate' && this.state.pageIndex === this.props.entrySepStart) ||
                                (key === 'multiWeight' && this.props.checked && this.state.pageIndex === this.props.entryMultiSepStart) ||
                                (key === 'transportCharges' && this.state.pageIndex === this.props.entryTransportChargeStart) ||
                                (key === 'summaryStart' && this.state.pageIndex === this.props.summaryStartPage) ||
                                (key === 'summaryEnd' && this.state.pageIndex === this.props.summaryEndPage) ||
                                (key !== 'shipDate' && key !== 'multiWeight' && key !== 'transportCharges' && key !== 'summaryStart' && key !== 'summaryEnd' && this.state.pageIndex === 0)){
                                return (<div
                                    key={"box" + key + index}
                                    style={{
                                        border: "2px dashed " + this.green,
                                        position: "absolute",
                                        top: thisBox.y + "px",
                                        left: thisBox.x + "px",
                                        width: thisBox.w + "px",
                                        height: thisBox.h + "px",
                                        cursor: "pointer",
                                        zIndex: 1000,
                                        display: this.props.fieldDefined(thisBox) ? "block" : "none",
                                        visibility: this.fieldVisibility(this.state.fields[key].visible, key)
                                    }}
                                    onMouseDown={(e) => this.boxMove(e, key)}
                                    onMouseMove={this.resizeSelectBox}
                                    onMouseUp={this.completeSelectBox}
                                >
                                    <div className="edgeContainer">
                                        {/* Labels for the boxes and reset button */}
                                        <div className="labelRow">
                                            <p
                                                className="boxLabel"
                                                onMouseEnter={(e) => this.showLabel(e, this.state.fields[key].label)}
                                                onMouseLeave={this.removeLabel}
                                            >.&#160;.&#160;.</p>
                                            <ClearIcon onClick={() => this.clearBox(key)} className="resetButton" />
                                        </div>
                                        {/* These divs are used to change the cursor around the edge of the boxes */}
                                        <div
                                            className="topEdge"
                                            style={{height: this.resizeMargin}}
                                            onMouseDown={(e) => this.lineMove(e, "y", key)}
                                            onMouseMove={this.resizeSelectBox}
                                            onMouseUp={this.completeSelectBox}
                                        />
                                        <div
                                            className="leftEdge"
                                            style={{width: this.resizeMargin}}
                                            onMouseDown={(e) => this.lineMove(e, "x", key)}
                                            onMouseMove={this.resizeSelectBox}
                                            onMouseUp={this.completeSelectBox}
                                        />
                                        <div
                                            className="rightEdge"
                                            style={{width: this.resizeMargin}}
                                            onMouseDown={(e) => this.lineMove(e, "w", key)}
                                            onMouseMove={this.resizeSelectBox}
                                            onMouseUp={this.completeSelectBox}
                                        />
                                        <div
                                            className="botEdge"
                                            style={{height: this.resizeMargin}}
                                            onMouseDown={(e) => this.lineMove(e, "h", key)}
                                            onMouseMove={this.resizeSelectBox}
                                            onMouseUp={this.completeSelectBox}
                                        />
                                        <div
                                            className="topLeftCorner"
                                            style={{height: this.resizeMargin, width: this.resizeMargin}}
                                            onMouseDown={(e) => this.initSelecting(e, thisBox.x + thisBox.w, thisBox.y + thisBox.h, key)}
                                            onMouseMove={this.resizeSelectBox}
                                            onMouseUp={this.completeSelectBox}
                                        />
                                        <div
                                            className="topRightCorner"
                                            style={{height: this.resizeMargin, width: this.resizeMargin}}
                                            onMouseDown={(e) => this.initSelecting(e, thisBox.x, thisBox.y + thisBox.h, key)}
                                            onMouseMove={this.resizeSelectBox}
                                            onMouseUp={this.completeSelectBox}
                                        />
                                        <div
                                            className="botLeftCorner"
                                            style={{height: this.resizeMargin, width: this.resizeMargin}}
                                            onMouseDown={(e) => this.initSelecting(e, thisBox.x + thisBox.w, thisBox.y, key)}
                                            onMouseMove={this.resizeSelectBox}
                                            onMouseUp={this.completeSelectBox}
                                        />
                                        <div
                                            className="botRightCorner"
                                            style={{height: this.resizeMargin, width: this.resizeMargin}}
                                            onMouseDown={(e) => this.initSelecting(e, thisBox.x, thisBox.y, key)}
                                            onMouseMove={this.resizeSelectBox}
                                            onMouseUp={this.completeSelectBox}
                                        />
                                    </div>
                                </div>)
                            }
                        })}
                    </div>
                </div>
                {/*Sidebar for Setting Markers*/}
                <div className="sidebarHolder" style={{width: this.props.widenBox ? "22%" : "0px", overflow: "auto"}}>
                    <Slide in={this.props.showFields} direction='left'>
                        <div>
                            <FormControl component='fieldset' style={{marginLeft: "25px", marginTop: "10px", width: "88%", marginRight: "25px"}}>
                                {/*Fields Checkbox to Display Markers*/}
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <FormLabel component="legend" style={{margin: 0}}>Fields</FormLabel>
                                    <Checkbox style={{ color: this.green, marginLeft: "auto", marginRight: "-15px" }} onChange={this.setAllVisible} checked={this.allVisible()} />
                                </div>
                                {/*Fields Section*/}
                                <RadioGroup aria-label="fields" name="fieldsRadio" value={this.state.selectedField}>
                                    {Object.keys(this.state.fields).map((key, index) => {
                                        if(key !== 'shipDate' && key !== 'multiWeight' && key !== 'summaryStart' && key !== 'summaryEnd'){
                                            return(
                                            <>
                                                <div key={"select" + key + index}
                                                     style={{display: "flex", alignItems: "center"}}>
                                                    {this.props.fieldDefined(this.state.fields[key].position) ? <>
                                                        <div style={{padding: "10px 6px 10px 0px", display: "inline-block"}}>
                                                            <CheckCircleIcon style={{color: this.green}}/>
                                                        </div>
                                                        <div style={{display: "inline-block"}}>
                                                            {this.state.fields[key].label +
                                                                (key === 'transportCharges'
                                                                        ? " (pg " + (this.props.entryTransportChargeStart+1) + ")"
                                                                        :  " "
                                                                )
                                                            }
                                                            {key === 'transportCharges' && this.props.entryTransportChargeText ?
                                                                <>
                                                                    <br></br>
                                                                    - "{this.props.entryTransportChargeText}"
                                                                </>  : ""}
                                                        </div>
                                                        <div style={{ display: "inline-block", marginLeft: "auto", marginRight: "-15px"}}>
                                                            <Checkbox style={{ color: this.green }} onChange={(e) => this.changeVisibility(e, key, "fields")} checked={this.state.fields[key].visible} />
                                                        </div>
                                                    </> : <FormControlLabel
                                                        value={key}
                                                        control={<GreenRadio onClick={(e) => this.selectField(e)}/>}
                                                        label={this.state.fields[key].label + (this.state.fields[key].required ? " *" : "")}
                                                    />}
                                                    {this.props.fieldError === key &&
                                                    <p style={{color: "red"}}>This field is required</p>}
                                                </div>
                                                {this.state.fields[key].label === "Date" && this.props.fieldDefined(this.state.fields[key].position) ?
                                                    <div style={{minWidth: "220px", width: "100%", paddingLeft: "31px"}}>
                                                        <Autocomplete
                                                            className="dateField"
                                                            size="small"
                                                            disableClearable
                                                            options={this.props.dateFormats}
                                                            getOptionLabel={(entry) => entry}
                                                            autoHighlight={true}
                                                            defaultValue={this.props.selectedFormat}
                                                            value={this.state.selectedDateFormat}
                                                            onChange={(event, value) => {
                                                                this.props.setDateFormat(value)
                                                            }}
                                                            name="formats"
                                                            renderInput={(params) =>
                                                                <TextField
                                                                    {...params}
                                                                    label="Date Format"
                                                                    InputLabelProps={{ shrink: true }}
                                                                    variant="outlined"
                                                                    name="formats"
                                                                />
                                                            }
                                                        />
                                                    </div> : <></>
                                                }
                                            </>
                                            )
                                        }})}
                                    <div
                                        style={{display: "flex", alignItems: "center"}}>
                                        <Checkbox checked={this.props.includeSender ? this.props.includeSender : false} disableRipple={true} disableTouchRipple={true} disableFocusRipple={true} style={{marginLeft: '-9px', backgroundColor: 'transparent', float: 'left'}} onClick={() => this.props.changedSender()}/>
                                        <div style={{display: "inline-block"}}>
                                            Include Sender
                                        </div>
                                    </div>
                                </RadioGroup><br />
                                <div style={{
                                    height: "1px",
                                    backgroundColor: '#A9A9A9',
                                    alignSelf: 'stretch',
                                }} /><br />
                                {/*Single Piece Shipment Section*/}
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <FormLabel component="legend">Single Piece (SP) Shipment Parameters</FormLabel>
                                </div>
                                <RadioGroup aria-label="fields" name="fieldsRadio" value={this.state.selectedField}>
                                    {Object.keys(this.state.fields).map((key, index) => {
                                        if(key === 'shipDate'){
                                            return(
                                                    <div key={"select" + key + index}
                                                         style={{display: "flex", alignItems: "center"}}>
                                                        {this.props.fieldDefined(this.state.fields[key].position) ? <>
                                                            <div style={{padding: "10px 6px 10px 0px", display: "inline-block"}}>
                                                                <CheckCircleIcon style={{color: this.green}}/>
                                                            </div>
                                                            <div style={{display: "inline-block"}}>
                                                                {this.state.fields[key].label + " (pg " + (this.props.entrySepStart+1) + ")" }
                                                                {this.props.entrySepText ?
                                                                    <>
                                                                        <br></br>
                                                                        - "{this.props.entrySepText}"
                                                                    </>  : ""}
                                                            </div>
                                                            <Tooltip
                                                                className={styles.tooltip}
                                                                enterTouchDelay={0}
                                                                leaveTouchDelay={30000}
                                                                enterDelay={400}
                                                                title={
                                                                    <React.Fragment>
                                                                        {ENTRY_SEP_TOOL_TIP}
                                                                        <br />
                                                                    </React.Fragment>
                                                                }
                                                            >
                                                                <IconButton aria-label='info'>
                                                                    <InfoOutlinedIcon variant='outlined' fontSize='small'/>
                                                                </IconButton>
                                                            </Tooltip>
                                                            <div style={{ display: "inline-block", marginLeft: "auto", marginRight: "-15px"}}>
                                                                <Checkbox style={{ color: this.green }} onChange={(e) => this.changeVisibility(e, key, "fields")} checked={this.state.fields[key].visible} />
                                                            </div>
                                                        </> : <FormControlLabel
                                                            value={key}
                                                            control={<GreenRadio onClick={(e) => this.selectField(e)}/>}
                                                            label={this.state.fields[key].label + (this.state.fields[key].required ? " *" : "")}
                                                        />}
                                                        {this.props.fieldError === key &&
                                                        <p style={{color: "red"}}>This field is required</p>}
                                                    </div>
                                                )
                                        }})}
                                </RadioGroup><br />
                                <div style={{
                                    height: "1px",
                                    backgroundColor: '#A9A9A9',
                                    alignSelf: 'stretch',
                                }} /> <br/>
                                {/*Multiweight (MW) Shipment Section*/}
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <FormLabel component="legend"><Checkbox checked={this.props.checked ? this.props.checked : false} onClick={() => this.props.changedCheck()} style={{marginLeft: '-9px', backgroundColor: 'transparent'}}/>Multiweight (MW) Shipment Parameters</FormLabel>
                                </div>
                                <RadioGroup aria-label="fields" name="fieldsRadio" value={this.state.selectedField}>
                                    {Object.keys(this.state.fields).map((key, index) => {
                                        if(key === 'multiWeight'){
                                            return(
                                                <div key={"select" + key + index}
                                                     style={{display: "flex", alignItems: "center"}}>
                                                    {this.props.fieldDefined(this.state.fields[key].position) ? <>
                                                        <div style={{padding: "10px 6px 10px 0px", display: "inline-block"}}>
                                                            <CheckCircleIcon style={{color: this.props.checked ? this.green : '#808080'}}/>
                                                        </div>
                                                        <div style={{display: "inline-block"}}>
                                                            {this.state.fields[key].label + " (pg " + (this.props.entryMultiSepStart+1) + ")" }
                                                            {this.props.entryMultiSepText ?
                                                                <>
                                                                    <br></br>
                                                                    - "{this.props.entryMultiSepText}"
                                                                </>  : ""}
                                                        </div>
                                                        <Tooltip
                                                            className={styles.tooltip}
                                                            enterTouchDelay={0}
                                                            leaveTouchDelay={30000}
                                                            enterDelay={400}
                                                            title={
                                                                <React.Fragment>
                                                                    {MULTI_SEP_TOOL_TIP}
                                                                    <br />
                                                                </React.Fragment>
                                                            }
                                                        >
                                                            <IconButton aria-label='info'>
                                                                <InfoOutlinedIcon variant='outlined' fontSize='small'/>
                                                            </IconButton>
                                                        </Tooltip>
                                                        <div style={{ display: "inline-block", marginLeft: "auto", marginRight: "-15px"}}>
                                                            <Checkbox style={{ color: this.green }} onChange={(e) => this.changeVisibility(e, key, "fields")} checked={this.state.fields[key].visible} />
                                                        </div>
                                                    </> : <FormControlLabel
                                                        value={key}
                                                        control={<GreenRadio
                                                            disabled={!this.props.checked}
                                                            onClick={this.props.checked ? (e) => {this.selectField(e)} : null}
                                                        />}
                                                        label={this.state.fields[key].label + (this.state.fields[key].required ? " *" : "")}
                                                    />}
                                                    {this.props.fieldError === key &&
                                                    <p style={{color: "red"}}>This field is required</p>}
                                                </div>
                                            )
                                        }})}
                                </RadioGroup><br/>
                                <div style={{
                                    height: "1px",
                                    backgroundColor: '#A9A9A9',
                                    alignSelf: 'stretch',
                                }} /> <br/>
                                {/*Validation Section*/}
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <FormLabel component="legend"><Checkbox checked={this.props.summaryCheck ? this.props.summaryCheck : false} onClick={() => this.props.changedSummaryCheck()} style={{marginLeft: '-9px', backgroundColor: 'transparent'}}/>Validation</FormLabel>
                                </div>
                                {this.props.summaryCheck ?
                                    <RadioGroup aria-label="fields" name="fieldsRadio" value={this.state.selectedField}>
                                        {Object.keys(this.state.fields).map((key, index) => {
                                            if(key === 'summaryStart' || key === 'summaryEnd'){
                                                return(
                                                    <>
                                                        <div key={"select" + key + index}
                                                             style={{display: "flex", alignItems: "center"}}>
                                                            {this.props.fieldDefined(this.state.fields[key].position) ? <>
                                                                <div style={{padding: "10px 6px 10px 0px", display: "inline-block"}}>
                                                                    <CheckCircleIcon style={{color: this.green}}/>
                                                                </div>
                                                                <div style={{display: "inline-block"}}>
                                                                    {key === 'summaryStart' ?
                                                                        <>
                                                                            {this.state.fields[key].label + " (pg " + (this.props.summaryStartPage+1) + ")" }
                                                                            <br></br>
                                                                            Start Text: "{this.props.validationStartText}"
                                                                        </>  : ""}
                                                                    {key === 'summaryEnd' ?
                                                                        <>
                                                                            {this.state.fields[key].label + " (pg " + (this.props.summaryEndPage+1) + ")" }
                                                                            <br></br>
                                                                            End Text: "{this.props.validationEndText}"
                                                                        </>  : ""}
                                                                </div>
                                                                <Tooltip
                                                                    className={styles.tooltip}
                                                                    enterTouchDelay={0}
                                                                    leaveTouchDelay={30000}
                                                                    enterDelay={400}
                                                                    title={
                                                                        <React.Fragment>
                                                                            {SUMMARY_TOOL_TIP}
                                                                            <br />
                                                                        </React.Fragment>
                                                                    }
                                                                >
                                                                    <IconButton aria-label='info'>
                                                                        <InfoOutlinedIcon variant='outlined' fontSize='small'/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <div style={{ display: "inline-block", marginLeft: "auto", marginRight: "-15px"}}>
                                                                    <Checkbox style={{ color: this.green }} onChange={(e) => this.changeVisibility(e, key, "fields")} checked={this.state.fields[key].visible} />
                                                                </div>
                                                            </> : <FormControlLabel
                                                                value={key}
                                                                disabled={!this.props.summaryCheck}
                                                                control={<GreenRadio onClick={(e) => this.selectField(e)}/>}
                                                                label={this.state.fields[key].label + (this.state.fields[key].required ? " *" : "")}
                                                            />}
                                                            {this.props.fieldError === key &&
                                                                <p style={{color: "red"}}>This field is required</p>}
                                                        </div>
                                                    </>
                                                )
                                            }})}
                                        <div>
                                            <FormControlLabel
                                                control={<Switch
                                                    disabled={!this.props.summaryCheck}
                                                    checked={this.state.tableValidation}
                                                    onChange={this.toggleTableValidation}
                                                />}
                                                label={this.state.tableValidation ? "Table Validation" : "List Validation"}
                                            />
                                        </div>
                                    </RadioGroup>
                                    : null}
                                {Object.keys(this.props.separatorFields).map((field, index) => (
                                    <>
                                        <TextField
                                            key={"separator" + field + index}
                                            variant="outlined"
                                            label={this.props.separatorFields[field].label + (this.props.separatorFields[field].required ? " *" : "")}
                                            value={this.props.separatorFields[field].value}
                                            onChange={(e) => this.handleSeparatorChange(field, e)}
                                            InputLabelProps={{ shrink: true }}
                                            error={this.props.separatorError === field}
                                            helperText={this.props.separatorError === field ? field === "startingPage" && this.props.separatorFields[field].value ? "Starting page can only be a natural number" : "Field is required" : ""}
                                        /><br />
                                    </>
                                ))}
                            </FormControl>
                        </div>
                    </Slide>
                </div>
            </div>
        </>)
    }
}

export default EditCarrierInvoiceTemplates