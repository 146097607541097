import React from 'react';

// import material icons
import {
    Box,
    IconButton, MenuItem, Select,
    TextField,
    Tooltip
} from "@material-ui/core";

// import constants
import {DELETE_ACTION, DRAG} from "../constants";
// material icons
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import DehazeIcon from '@material-ui/icons/Dehaze';

// import styling
import './ActionText.css';
import PropTypes from "prop-types";
import {CONDITION_ACTIONS, PRE_DEFINED_VALUES} from "./constants";
import styles from "../../settings/ManageCarriers/CreateCarrierDialog.module.css";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

class ActionText extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            actionId: "",
            type: "",
            name: "",
            value: "",
            preDefinedValue: "-",
            disableManualValueField: false,
            preDefinedValues: this.getPredefinedValues()
        }
    }

    componentDidMount() {
        this.setState({
            ...this.props.action
        },
            ()=>{
            if(this.props.isEdit) {
                this.setState({
                    value: this.state.value === "null" ? "" : this.state.value,
                    preDefinedValue: this.state.preDefinedValue === "null" ? "-" : this.state.preDefinedValue,
                    disableManualValueField: this.state.preDefinedValue !== "null" && this.state.preDefinedValue !== "-"
                }, () => {
                    this.props.updateActionWithParent({...this.state});
                })
            }
            })
    }

    getPredefinedValues = () => {
        let preDefinedValues = CONDITION_ACTIONS.find(action => action.name === this.props.action.name)?.predefinedValues
        if (!preDefinedValues) {
            preDefinedValues = PRE_DEFINED_VALUES
        }
        return preDefinedValues
    }

    handleValueChange = (event) => {
        const value = event.target.value;
        this.setState(
            {
                value,
            },
            () => {
                this.props.updateActionWithParent({ ...this.state });
            }
        );
    }

    handlePredefinedValueChange = (e) => {
        this.setState({
            preDefinedValue: e.target.value,
            disableManualValueField: e.target.value !== '-',
            value: e.target.value !== '-'? '': this.state.value
        }, () => {
            this.props.updateActionWithParent({ ...this.state })})
    }

    handleDateChange = (date) => {
        const value = date;
        this.setState(
            {
                value,
            },
            () => {
                this.props.updateActionWithParent({ ...this.state });
            }
        );
    }

    renderValueInput = () => {
        if (this.state.name === "Set invoice payment date") {
            return (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        className='paymentDatePicker'
                        disableToolbar
                        variant="inline"
                        format="yyyy/MM/dd"
                        id="date-picker-inline"
                        value={this.state.value || null}
                        onChange={date => this.handleDateChange(date, true)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        disabled={this.state.disableManualValueField}
                        autoOk
                    />
                </MuiPickersUtilsProvider>
            );
        } else {
            return (
                <TextField
                    className='actionTextFieldInput'
                    variant='outlined'
                    disabled={this.state.disableManualValueField}
                    value={this.state.value}
                    onChange={this.handleValueChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            );
        }
    };

    render() {
        return (
            <Box
                className='actionTextField'
                display='flex'
                flexDirection='row'
                justifyContent='left'
                alignItems='center'
            >
                <div className="actionTextFieldName">{this.state.name}</div >
                <div>
                    {this.renderValueInput()}
                </div>

                {!this.state.preDefinedValues || this.state.preDefinedValues.length <= 0 ? <></> :
                    <React.Fragment>
                        <div>
                            OR
                        </div>
                        <Select
                            variant="outlined"
                            name="predefinedValue"
                            onChange={this.handlePredefinedValueChange}
                            value={this.state.preDefinedValue === "null" ? '-' : this.state.preDefinedValue}
                            className='predefined-dropdown'
                        >
                            {this.state.preDefinedValues.map((value, index) => (
                                <MenuItem value={value} key={index}>{value === '-'? 'Select a predefined value': value}</MenuItem>
                            ))
                            }
                        </Select>
                    </React.Fragment>
                }

                {/* in order to make sure that the delete icon aligns, we add an empty component */}
                <div className='actionTextEmptyDiv'>
                    <Tooltip title={DELETE_ACTION}>
                        <IconButton
                            aria-label={DELETE_ACTION}
                            onClick={() => this.props.deleteAction(this.state.actionId)}
                        >
                            <DeleteOutlineIcon variant='outlined' fontSize='small' />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={DRAG}>
                        <IconButton
                            aria-label='drag'
                        >
                            <DehazeIcon variant='outlined' fontSize='small' />
                        </IconButton>
                    </Tooltip>
                </div>
            </Box>
        );
    }

}

ActionText.propTypes = {
    action: PropTypes.array,
    updateActionWithParent: PropTypes.func,
    deleteAction: PropTypes.func,
    isEdit: PropTypes.bool
};

ActionText.defaultProps = {
    action: {},
    updateActionWithParent: ()=>{},
    deleteAction: ()=>{},
    isEdit: true
}

export default ActionText;