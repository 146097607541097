import React from "react";
import {Button, Paper, ClickAwayListener, TextField} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PropTypes from "prop-types";
import withShipment from "../../../../withShipment";
import {setSkuLocation} from "../../../../redux/actions/settings";

class SetSkuLocationPopper extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedLocation: null,
        }
    }

    handleClose = () => {
        this.props.handleClosePopper()

    }

    handleSave = () => {
        const data = {
            skus: this.props.skus,
            selectedLocation: this.state.selectedLocation,
        }
        this.props.setSkuLocation(data)
        this.props.handleClosePopper()
        this.props.handlePopperClose()

    }

    render() {
        const noLocationOption = { name: "No Location" };
        const locationOptions = [...this.props.locations.filter(location => location.enabled),noLocationOption];

        return (
            <>
                <ClickAwayListener onClickAway={this.handleClose}>
                    <Paper className="import-popper-container">
                        <div className="import-popper-item">
                            <Autocomplete
                                options={locationOptions}
                                getOptionLabel={(option) => option.name ? option.name : ""}
                                value={this.state.selectedLocation}
                                onChange={(e, value) => this.setState({selectedLocation: value})}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        name="location"
                                        label="Select a Location"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                }
                                style={{width: "250px"}}
                            />
                        </div>
                        <div className="supplier-payment-popper-buttons">
                            <Button variant='contained' onClick={this.handleClose} style={{width: '100px'}}>Close</Button>
                            <Button variant='contained' onClick={this.handleSave} color="primary" style={{width: '100px'}}>Save</Button>
                        </div>
                    </Paper>
                </ClickAwayListener>
            </>
        )
    }
}

const actionCreators = {
    setSkuLocation,
}


SetSkuLocationPopper.propTypes = {
    locations: PropTypes.array
}

SetSkuLocationPopper.defaultProps = {
    locations: [],
}

export default withShipment({
    actionCreators
}, SetSkuLocationPopper);