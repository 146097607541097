import {STORE_ALERTS} from "../../constants/alerts";

const initialState = {
    alertEntries: []
}


export default (state = initialState, action) => {

    switch(action.type){

        case STORE_ALERTS:
            return {
                ...state,
                alertEntries: action.alertEntries
            }

        default:
            return state;
    }
}