import withShipment from "../../../withShipment";
import {
    BACK, BILL_OF_LADING, COMMERCIAL_INVOICE, FTL_DOC_TYPES,
    FTL_PAGES,
    FULL_TRUCKLOAD,
} from "../constants";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";
import React from "react";
import {getByteArray, SAVE} from "../../global/constants";
import FileUpload from "../../global/FileUpload";
import {Button} from "@material-ui/core";
import {uploadSignedDocuments} from "../../../redux/actions/fullTruckload";

class FullTruckloadFileUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            billOfLading: {},
            commercialInvoice: {},
            newBillOfLading: {},
            newCommercialInvoice: {},
            id: -1,
        }
    }

    componentDidMount() {
        this.setState({
            billOfLading: this.props.billOfLading,
            commercialInvoice: this.props.commercialInvoice,
            id: this.props.id,
        })
    }

    getFormattedGeneralFileObject = (file) => {
        return { localURL: URL.createObjectURL(file), displayName: file.name, byteArray: getByteArray(file), type: file.type }

    }

    handleAddBillOfLading = (file) => {
        let newFile = this.getFormattedGeneralFileObject(file)
        this.setState({
            newBillOfLading: newFile,
        })
    }

    handleAddCommercialInvoice = (file) => {
        let newFile = this.getFormattedGeneralFileObject(file)
        this.setState({
            newCommercialInvoice: newFile,
        })
    }

    handleSave = () => {
        this.props.uploadSignedDocuments(this.state, () => this.props.setCurrentPage(FTL_PAGES.TABLE))
    }

    render() {
        return (
            <React.Fragment>
                <TopToolbar
                    pageName={FULL_TRUCKLOAD}
                    menuItems={[
                        {title: BACK, icon: <ArrowBackIcon/>, onClick: () => {this.props.setCurrentPage(FTL_PAGES.UPDATE)}}
                    ]}
                />

                <div style={{marginLeft: "25px", marginTop: "15px"}}>
                    {BILL_OF_LADING}

                    <div style={{display: "flex", flexDirection: "row", marginTop: "10px", border: "1px solid", borderRadius: "1px", width: "20%"}}>
                        <FileUpload
                            handleFileAdd={(file) => this.handleAddBillOfLading(file)}
                            handleFileDelete={(file, fileIndex) => this.setState({ newBillOfLading: {} })}
                            files={!this.state.newBillOfLading.displayName ? [] : [this.state.newBillOfLading]}
                            fetchFilePreview={(file) => {

                            }}
                            singleFileUpload={true}
                            isFTL={true}
                        />
                    </div>
                </div>

                <div style={{marginLeft: "25px", marginTop: "15px"}}>
                    {COMMERCIAL_INVOICE}

                    <div style={{display: "flex", flexDirection: "row", marginTop: "10px", border: "1px solid", borderRadius: "1px", width: "20%"}}>
                        <FileUpload
                            handleFileAdd={(file) => this.handleAddCommercialInvoice(file)}
                            handleFileDelete={(file, fileIndex) => this.setState({ newCommercialInvoice: {} })}
                            files={!this.state.newCommercialInvoice.displayName ? [] : [this.state.newCommercialInvoice]}
                            fetchFilePreview={(file) => {

                            }}
                            singleFileUpload={true}
                            isFTL={true}
                        />
                    </div>

                    <Button
                        style={{ marginTop: "15px" }}
                        disabled={!this.state.newBillOfLading.displayName && !this.state.newCommercialInvoice.displayName}
                        variant='contained'
                        color='primary'
                        onClick={() => {this.handleSave()}}
                    >
                        {SAVE}
                    </Button>
                </div>
            </React.Fragment>
        )
    }
}

const actionCreators = {
    uploadSignedDocuments
}

export default withShipment({
    actionCreators,
}, FullTruckloadFileUpload);