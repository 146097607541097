import React from 'react';

// material components
import {
    TableSortLabel,
    TableHead,
    TableRow,
    TableCell
} from '@material-ui/core';

// import constants
import {ASC} from '../../global/constants';

// styling
import './ManageUsersTable.css'
import PropTypes from "prop-types";

class ManageUsersTableHead extends React.PureComponent {

    constructor(props) {
        super(props);
    }

    createSortHandler = (property) => (event) => {
        this.props.onRequestSort(event, property);
    }

    render() {
        return (
            <TableHead>
                <TableRow className="table manage-head">

                    {this.props.headCells.map((headCell, index) => (
                        <TableCell
                            className="manage-table-cell"
                            key={headCell.id}
                            align={headCell.center ? 'center' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'default'}
                            sortDirection={this.props.orderBy === headCell.id ? this.props.order : false}
                        >
                            <TableSortLabel
                                active={this.props.orderBy === headCell.id}
                                direction={this.props.orderBy === headCell.id ? this.props.order : ASC}
                                onClick={this.createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                    <TableCell />
                </TableRow>
            </TableHead>
        );
    }
}

ManageUsersTableHead.propTypes = {
    headCells: PropTypes.array,
    order: PropTypes.string,
    orderBy: PropTypes.string
}

ManageUsersTableHead.defaultProps = {
    headCells: [],
    order: "desc",
    orderBy: ""
}

export default ManageUsersTableHead;