import React, { useState } from "react";

// material components
import { TablePagination, Typography } from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Table from '@material-ui/core/Table';
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import GlobalTableHead from "../../global/Table/GlobalTableHead";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FilterIcon from "../../shipments/FilterIcon";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { Slide } from "@material-ui/core";

// custom components
import SearchableTable from "../../global/Search/SearchableTable";
import ExpandedCustomerPurchaseOrder from "./ExpandedCustomerPurchaseOrder";

// constants
import { LIST_PURCHASE_ORDERS_CELLS, ROW_KEYS, ROWS_PER_PAGE_LABEL, STATUS, FILTER } from "./constants";
import { ASC, DESC } from "../../global/constants";
import { DEFAULT_ROWS_PER_PAGE, DEFAULT_SORT_BY_HEAD_CELL, } from "./constants";


// styling
import "./PurchaseCustomerOrdersTable.css"
import PropTypes from "prop-types";
import TableFooter from "@material-ui/core/TableFooter";
import SearchField from "../../global/Search/SearchField";
import CustomTableHeader from "../../global/Table/CustomTableHeader";
import RenderPurchaseOrderPDFPreview from "./RenderPurchaseOrderPDFPreview";
import PurchaseCustomerOrderDetails from "./PurchaseCustomerOrderDetails";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import PurchaseCustomerOrderFiltersDialog from "./PurchaseCustomerOrderFiltersDialog";
import FilterTagsBar from "../../shipments/FilterTagsBar";
import LocationSelectBox from "../../global/subcomponents/locationSelectBox/LocationSelectBox";

class PurchaseCustomerOrdersTable extends SearchableTable {
    constructor(props) {
        super(props);
        this.state = {
            order: DESC,
            orderBy: DEFAULT_SORT_BY_HEAD_CELL,
            selectedOrder: [],
            isSearch: false,
            searchValue: '',
            searchRows: 0,
            isFilterApplied: false,
            filtersMapBeforeOpenDialog: {},
            selectedStatus: [],
            dropdownAll: false,
            filtersOpen: false,
            fromDate: this.getFromDate(),
            toDate: this.getToDate(),
            dateFiltersMap: {},
            deletedTagsWithCookies: [],
            filterShipAddressId : this.props.userInfo.selectedLocationId,
            deleteId: "",
        };
    }

    async componentDidMount() {
        let changeBack = false
        await this.setState({
            selectedStatus: this.props.tableSetting?.purchaseOrderFilterSetting,
            searchValue: this.props.searchText
        })
        let storage = JSON.parse(localStorage.getItem("Purchase Orders"))
        if (storage) {
            this.getNewDates()
            const expiry = parseInt(JSON.parse(localStorage.getItem("expiry")))
            if (storage.fromDate !== null && storage.fromDate !== undefined) {
                if (expiry > new Date().getTime()) {
                    this.closeFilters(true)
                } else {
                    changeBack = true
                }
            }
            if (storage.toDate !== null && storage.toDate !== undefined) {
                if (expiry > new Date().getTime()) {
                    this.closeFilters(true)
                } else {
                    changeBack = true
                }
            }
            if (changeBack) {
                localStorage.removeItem("Purchase Orders")
                this.setState({
                    fromDate: this.getFromDate(),
                    toDate: this.getToDate(),
                    dateFiltersMap: {}
                })
            }
        }

    }

    componentDidUpdate(prevProps) {
        if (prevProps.tableSetting !== this.props.tableSetting) {
            this.setState({ selectedStatus: this.props.tableSetting?.purchaseOrderFilterSetting })
        }
        if (prevProps.searchText !== this.props.searchText) {
            this.setState({ searchValue: this.props.searchText })
        }
    }

    getFromDate = () => {
        var date = new Date()
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setDate(date.getDate() - 14)
        return date;
    }

    getToDate = () => {
        var date = new Date()
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
        return date;
    }

    updateDeleteId = (deleteId) =>{
        this.setState({ deleteId:  deleteId})
    }

    handleDelete = () => {
       this.props.deleteOrder({ poNumber: this.state.deleteId }, () => window.location.reload())
       this.setState({ deleteId: "" })
    }

    isDefaultFromDate = (someDate) => {
        const defaultFromDate = this.getFromDate()
        return someDate.getDate() == defaultFromDate.getDate() &&
            someDate.getMonth() == defaultFromDate.getMonth() &&
            someDate.getFullYear() == defaultFromDate.getFullYear()
    }

    getNewDates = () => {
        let filter = {...this.state.dateFiltersMap}
        let storage = JSON.parse(localStorage?.getItem("Purchase Orders"))
        if (storage.fromDate !== null && storage.fromDate !== undefined ) {
            const temp = new Date(storage.fromDate)
            filter.fromDate = temp;
            this.setState({
                fromDate: temp
            })
        }
        if (storage.toDate !== null && storage.toDate !== undefined) {
            const temp = new Date(storage.toDate)
            filter.toDate = temp;
            this.setState({
                toDate: temp
            })
        }
        if (filter) {
            this.setState({
                dateFiltersMap: filter,
            })
        }
    }

    isToday = (someDate) => {
        const today = new Date()
        return someDate.getDate() == today.getDate() &&
            someDate.getMonth() == today.getMonth() &&
            someDate.getFullYear() == today.getFullYear()
    }

    handleChangePage = (event, newPage) => {
        this.props.setPage(newPage)
    };

    handleChangeRowsPerPage = (event) => {
        this.props.setRowsPerPage(event.target.value)
        this.props.setPage(0)
    };


    getDisplayed = (rows) => {
        if (!rows)
            return [];
        return [...rows.slice(this.props.page * this.props.rowsPerPage, this.props.page * this.props.rowsPerPage + this.props.rowsPerPage)];
    }

    onSearchValueChange = (event) => {
        this.setState({
            isSearch: event.target.value.length > 0,
            searchValue: event.target.value
        });
        this.props.setPage(0)
        this.props.handleSearchTextChange(event)
    }

    handleDenseChange = (event) => {
        this.setState({
            dense: event.target.checked,
        });
    }

    handleStatusChange = (event) => {
        let checked = event.target.checked
        if (checked) {
            this.setState({
                selectedStatus: [...this.state.selectedStatus, event.target.name]
            });
        }
        // remove from the list
        else {
            let index = this.state.selectedStatus.indexOf(event.target.name)
            let selected = this.state.selectedStatus
            selected.splice(index, 1)
            this.setState({
                selectedStatus: selected
            });
        }

        this.onFiltersInputChange(event);
    }

    handleRequestSort = (event, property) => {
        const isAsc = this.state.orderBy === property && this.state.order === ASC;
        this.setState({
            order: isAsc ? DESC : ASC,
            orderBy: property,
        });
    };

    handleSelectAllClick = () => {
        const isDropdownAll = !this.state.dropdownAll
        this.setState({
            dropdownAll: isDropdownAll
        })
    }
    fetchCreatedPurchaseOrderPreview = (purchaseOrderDate) => {
        this.props.storeCreatedPurchaseOrderPreview([])
        this.props.previewCreatedPurchaseOrder(purchaseOrderDate)
        this.props.updateField("previewing", true)
        this.props.updateField("previewingName", purchaseOrderDate)
        this.props.updateHasPurchaseOrderEmailTemplate(purchaseOrderDate)
        this.setState({
            showingPurchaseOrder: purchaseOrderDate
        })
    }

    getFilteredPurchaseOrderLength = (purchaseOrderList) => {
        if (this.state.selectedStatus) {
            return purchaseOrderList.filter((item) => this.state.selectedStatus.includes(item.status)).length
        }
        return 0;
    }

    getStatusFilteredPurchaseOrders = (purchaseOrderList) => {
        if (this.state.selectedStatus) {
            return purchaseOrderList.filter((item) => this.state.selectedStatus.includes(item.status))
        }
        return [];
    }

    showFiltersDialog = () => {
        const filtersMapClone = { ...this.state.dateFiltersMap };
        this.setState({
            filtersOpen: !this.state.filtersOpen,
            filtersMapBeforeOpenDialog: filtersMapClone
        });
    }

    closeFilters = (apply) => {
        this.setState({
            filtersOpen: false,
        });

        let filters = {}
        if (!apply) {
            filters = { ...this.state.filtersMapBeforeOpenDialog }
            this.resetDateFilters(filters?.fromDate ?? this.getFromDate(), filters?.toDate ?? this.getToDate())
            this.setState({
                dateFiltersMap: filters
            })
        } else {
            if (this.state.deletedTagsWithCookies.length > 0) {
                let storage = JSON.parse(localStorage.getItem("Purchase Orders"))
                this.state.deletedTagsWithCookies.forEach((tagName) => {
                    delete storage[tagName]
                })
                localStorage.setItem("Purchase Orders", JSON.stringify(storage))
                if(JSON.stringify(storage) == JSON.stringify({})) localStorage.removeItem("Purchase Orders")
                this.setState({deletedTagsWithCookies: []})
            }
            filters = { ...this.state.dateFiltersMap }
            const expiry = new Date().setHours(23, 59, 59, 0);
            if (filters.fromDate !== null && filters.fromDate !== undefined) {
                let existing = localStorage.getItem("Purchase Orders")
                existing = existing ? JSON.parse(existing) : {};
                existing.fromDate = filters.fromDate;
                localStorage.setItem("Purchase Orders", JSON.stringify(existing));
                localStorage.setItem("expiry", JSON.stringify(expiry))
            }
            if (filters.toDate !== null && filters.toDate !== undefined) {
                let existing = localStorage.getItem("Purchase Orders")
                existing = existing ? JSON.parse(existing) : {};
                existing.toDate = filters.toDate;
                localStorage.setItem("Purchase Orders", JSON.stringify(existing));
                localStorage.setItem("expiry", JSON.stringify(expiry))
            }
            this.applyDateFilters(this.state.fromDate, this.state.toDate)
        }
    }

    clearFiltersMap = () => {
        this.setState({
            dateFiltersMap: {},
            fromDate: this.getFromDate(),
            toDate: this.getToDate()
        })
    }

    resetDateFilters = (fromDate, toDate) => {
        let filtersMapClone = { ...this.state.dateFiltersMap }
        if (!this.isDefaultFromDate(fromDate)) {
            filtersMapClone['fromDate'] = fromDate
        }
        if (!this.isToday(toDate)) {
            filtersMapClone['toDate'] = toDate
        }
        this.setState({
            fromDate: fromDate,
            toDate: toDate,
            dateFiltersMap: filtersMapClone,
            deletedTagsWithCookies: [],
        })
    }

    onTagClickedWhenDialogueOpen = (event, tag) => {
        const filtersMap = { ...this.state.dateFiltersMap };
        let tagValue = filtersMap[tag]
        let deletedTagsWithCookies = this.state.deletedTagsWithCookies
        delete filtersMap[tag];
        if (tag === 'fromDate') {
            let storage = JSON.parse(localStorage.getItem('Purchase Orders'))
            if (storage) {
                if (storage.fromDate !== null && storage.fromDate !== undefined) {
                    if (new Date(storage.fromDate).getTime() === new Date(tagValue).getTime()) {
                        this.setState({deletedTagsWithCookies: [...deletedTagsWithCookies, "fromDate"]})
                    }
                }
            }
            this.setState({
                fromDate: this.getFromDate()
            })
        }
        if (tag === 'toDate') {
            let storage = JSON.parse(localStorage.getItem('Purchase Orders'))
            if (storage) {
                if (storage.toDate !== null && storage.toDate !== undefined) {
                    if (new Date(storage.toDate).getTime() === new Date(tagValue).getTime()) {
                        this.setState({deletedTagsWithCookies: [...deletedTagsWithCookies, "toDate"]})
                    }
                }
            }
            this.setState({
                toDate: this.getToDate()
            })
        }
        this.setState({
            dateFiltersMap: filtersMap,
        });
    }

    onTagClicked = (event, tag) => {

        // the tag name comes as key:value pair as a string, so we split it

        // remove the key from the filters map
        // clone the map, delete the key, set the state
        const filtersMap = { ...this.state.dateFiltersMap };
        delete filtersMap[tag];

        if (tag === 'fromDate') {
            let storage = JSON.parse(localStorage.getItem("Purchase Orders"))
            delete storage.fromDate
            // this.onDateFiltersInputChange('fromDate', this.getFromDate())
            this.setState({
                fromDate: this.getFromDate()
            }, () => this.applyDateFilters(this.state.fromDate, this.state.toDate))
            localStorage.setItem("Purchase Orders", JSON.stringify(storage))
            if(JSON.stringify(storage) == JSON.stringify({})) localStorage.removeItem("Purchase Orders")
        }
        if (tag === 'toDate') {
            let storage = JSON.parse(localStorage.getItem("Purchase Orders"))
            delete storage.toDate
            // this.onDateFiltersInputChange('toDate', new Date())
            this.setState({
                toDate: this.getToDate()
            }, () => this.applyDateFilters(this.state.fromDate, this.state.toDate))
            localStorage.setItem("Purchase Orders", JSON.stringify(storage))
            if(JSON.stringify(storage) == JSON.stringify({})) localStorage.removeItem("Purchase Orders")
        }
        this.setState({
            dateFiltersMap: filtersMap
        })
    }

    onFiltersInputChange = (event) => {
        let checked = event.target.checked
        let filtersMap = this.props.tableSetting?.purchaseOrderFilterSetting;

        let id = event.target.name;
        if (checked) {
            filtersMap.push(id)
        } else {
            let i = filtersMap.indexOf(id)
            if (i > -1) {
                filtersMap.splice(i, 1);
            }
        }

        this.saveUpdatedFilters(filtersMap)
    }

    onDateFiltersInputChange = (id, value) => {
        if (!value || isNaN(value.getTime())) {
            //User is still editing the date value, do not fetch orders
            this.setState({
                fromDate: id === "fromDate" ? value : this.state.fromDate,
                toDate: id === "toDate" ? value : this.state.toDate
            })
        } else {
            const date = new Date(value.toString().split('GMT')[0] + "Z")
            let filtersMapClone = { ...this.state.dateFiltersMap }
            if (id === 'fromDate' && !this.isDefaultFromDate(value) ||
                id === 'toDate' && !this.isToday(value)) {
                filtersMapClone[id] = date
            }
            this.setState({
                fromDate: id === "fromDate" ? value : this.state.fromDate,
                toDate: id === "toDate" ? value : this.state.toDate,
                dateFiltersMap: filtersMapClone
            })
        }
    }

    applyDateFilters = (fromDate, toDate) => {
        this.props.fetchAllPurchaseOrders({
            fromDate,
            toDate
        })
    }

    saveUpdatedFilters = (filters) => {
        this.props.saveTableSetting({
            userId: this.props.userInfo.userId,
            tableSetting: null,
            pageTitle: this.props.shipmentStatus,
            defaultTable: this.props.defaultTableDisplay,
            changeFilters: false,
            changePurchaseOrderFilters: true,
            purchaseOrderFilters: filters
        })
    }
    handleLocationChange = (location) => {
        this.setState({
            filterShipAddressId: location.shipAddressId
        })
    }

    render() {
        return (
            <>
                {this.props.previewing &&
                    <RenderPurchaseOrderPDFPreview
                        //allowSave={true}
                        data={this.props.pdfData}
                        //handlePreviewOpen={this.handlePreviewOpen}
                        //handlePreviewClose={this.handlePreviewClose}
                        //open={this.state.previewOpen}
                        handlePdfLoadSuccess={this.props.handlePdfLoadSuccess}
                        handlePdfLoadError={this.props.handlePdfLoadError}
                        pdfLoadSuccess={this.props.pdfLoadSuccess}
                        scale={this.props.scale}
                    /> ||
                    <>
                        <FilterTagsBar
                            filtersMap={this.state.dateFiltersMap}
                            onClick={this.state.filtersOpen ? this.onTagClickedWhenDialogueOpen : this.onTagClicked}
                        />
                        <CustomTableHeader
                            isPurchaseOrderTable={true}
                            searchText={this.props.searchText}
                            onSearchValueChange={this.onSearchValueChange}
                            location={
                                <LocationSelectBox
                                    selectedLocationId={this.state.filterShipAddressId}
                                    updateCallback={this.handleLocationChange}
                                />
                            }
                            filterIcon={
                                <Tooltip title={FILTER} style={{ paddingTop: 10 }}>
                                    <IconButton className="filterButton" size='small' aria-label={FILTER} onClick={this.showFiltersDialog} >
                                        <FilterIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                            showFilters={this.state.filtersOpen}
                            filtersComponent={
                                <Slide in={this.state.filtersOpen} mountOnEnter unmountOnExit timeout={300}>
                                    <PurchaseCustomerOrderFiltersDialog
                                        isOpen={this.state.filtersOpen}
                                        onOpen={this.showFiltersDialog}
                                        onChange={this.onFiltersInputChange}
                                        clearFilters={this.clearFiltersMap}
                                        onClose={this.closeFilters}
                                        fromDate={this.state.fromDate}
                                        toDate={this.state.toDate}
                                        handleDateChange={this.onDateFiltersInputChange}
                                    />
                                </Slide>}
                            customFilterField={
                                <>
                                    <div className="filters">
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.selectedStatus ? this.state.selectedStatus.includes('open') : false}
                                                onChange={this.handleStatusChange} name="open"
                                                style={{ color: '#acd685' }} />}
                                            label="Open"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.selectedStatus ? this.state.selectedStatus.includes('partial') : false}
                                                onChange={this.handleStatusChange} name="partial"
                                                style={{ color: '#acd685' }} />}
                                            label="Partial"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.selectedStatus ? this.state.selectedStatus.includes('completed') : false}
                                                onChange={this.handleStatusChange} name="completed"
                                                style={{ color: '#acd685' }} />}
                                            label="Completed"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.selectedStatus ? this.state.selectedStatus.includes('cancelled') : false}
                                                onChange={this.handleStatusChange} name="cancelled"
                                                style={{ color: '#acd685' }} />}
                                            label="Cancelled"
                                        />
                                    </div>

                                </>
                            }
                            pagination={
                                <TablePagination
                                    className="table-pagination"
                                    labelRowsPerPage={ROWS_PER_PAGE_LABEL}
                                    rowsPerPageOptions={[5, 25, 50, 100, {value: this.props.rows.filter(row => row.toShipAddressId === this.state.filterShipAddressId).length, label: "All"} ]}
                                    count={!this.props.rows.filter(row => row.toShipAddressId === this.state.filterShipAddressId) ? 0 :
                                        this.getFilteredPurchaseOrderLength(this.filterBySearch(this.props.rows.filter(row => row.toShipAddressId === this.state.filterShipAddressId)))
                                    }
                                    rowsPerPage={this.props.rowsPerPage}
                                    page={this.props.page}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            }
                        />

                        <TableContainer>
                            <Table
                                aria-labelledby="tableTitle"
                                size={'medium'}
                                aria-label={this.props.title}
                            >
                                <GlobalTableHead
                                    isCreatePurchaseOrderTable={false}
                                    isShipmentTable={false}
                                    isPurchaseOrderTable={true}
                                    headCells={LIST_PURCHASE_ORDERS_CELLS}
                                    order={this.state.order}
                                    orderBy={this.state.orderBy}
                                    onRequestSort={this.handleRequestSort}
                                    rowCount={!this.props.rows.filter(row => row.toShipAddressId === this.state.filterShipAddressId) ? 0 : this.props.rows.filter(row => row.toShipAddressId === this.state.filterShipAddressId).length}
                                    onSelectAllClick={this.handleSelectAllClick}
                                />
                                <TableBody>
                                    {this.getDisplayed(this.stableSort(this.getStatusFilteredPurchaseOrders(this.filterBySearchWithSpecificKeys(this.props.rows.filter(row => row.toShipAddressId === this.state.filterShipAddressId), ROW_KEYS)),
                                        this.getComparator(this.state.order, this.state.orderBy))).map((row, index) => {
                                            return (
                                                <>
                                                    <ExpandedCustomerPurchaseOrder
                                                        purchaseOrder={row}
                                                        fetchCreatedPurchaseOrderPreview={this.fetchCreatedPurchaseOrderPreview}
                                                        //changeHeader={this.props.changeHeader}
                                                        onReceivedChange={this.props.onReceivedChange}
                                                        updatePurchaseOrder={this.props.updatePurchaseOrder}
                                                        deleteInventoryReceived={this.props.deleteInventoryReceived}
                                                        key={row.poNumber}
                                                        showDropdown={this.state.dropdownAll}
                                                        editingPo={this.props.editingPO}
                                                        handleEditPo={this.props.handleEditPo}
                                                        handleDelete={this.handleDelete}
                                                        updateDeleteId={this.updateDeleteId}
                                                        deleteId={this.state.deleteId}
                                                    />
                                                </>
                                            );
                                        })
                                    }
                                </TableBody>

                            </Table>
                        </TableContainer>
                    </>}
            </>
        )
    }


}

PurchaseCustomerOrdersTable.propTypes = {
    updatePurchaseOrder: PropTypes.func,
    deleteInventoryReceived: PropTypes.func,
    rows: PropTypes.array
}

PurchaseCustomerOrdersTable.defaultProps = {
    updatePurchaseOrder: () => {
    },
    deleteInventoryReceived: () => {
    },
    rows: []
}


export default PurchaseCustomerOrdersTable;