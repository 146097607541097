export const CARRIER_INVOICE_TEMPLATE_HEAD_CELLS = [
    { id: 'carrierName', center: false, disablePadding: false, label: "Name"},
    { id: 'accountNumber', center: false, disablePadding: false, label: "Account Number"},
    { id: "delete", center: true, disablePadding: false, label: ''}
]

export const DEFAULT_SORT_BY_HEAD_CELL = "carrierName"
export const DEFAULT_ROWS_PER_PAGE = 50;
export const ROWS_PER_PAGE_LABEL = "Carrier Invoice Templates per page"
export const ENTRY_SEP_TOOL_TIP = "Mark the words that defines the starting period for the first invoice"
export const MULTI_SEP_TOOL_TIP = "Mark the words that identifies a Multi Weight Shipment"
export const SUMMARY_TOOL_TIP = "Use the start and end markers to outline the words that define the start and end points of the summary"

export const DEFAULT_DATE_FORMAT = "MMM d, yyyy"

export const CARRIER_INVOICE_TEMPLATE_FIELDS = {
    accountNumber: { label: "Account Number", required: true, visible: true, position: { x: 0, y: 0, w: 0, h: 0 } },
    invoiceNumber: { label: "Invoice Number", required: true, visible:true, position: { x: 0, y: 0, w: 0, h: 0 } },
    invoiceDate: { label: "Date", required: true, visible: true, position: { x: 0, y: 0, w: 0, h: 0 } },
    summaryStart: { label: "Summary Start Marker", required: false, visible: true, position: { x: 0, y: 0, w: 0, h: 0 } },
    summaryEnd: { label: "Summary End Marker", required: false, visible: true, position: { x: 0, y: 0, w: 0, h: 0 } },
    shipDate: { label: "SP Entry Marker", required: true, visible: true, position: { x: 0, y: 0, w: 0, h: 0 } },
    multiWeight: { label: "MW Entry Marker", required: false, visible: true, position: { x: 0, y: 0, w: 0, h: 0 } },
    transportCharges: { label: "Transportation/Freight Marker", visible: true, required: true, position: { x: 0, y: 0, w: 0, h: 0 } },
}

export const CARRIER_INVOICE_TEMPLATE_SEPARATOR_FIELDS = {
}

export const FIELD_COLUMN_FIELDS = { title: "", for: "", titleIncluded: false }