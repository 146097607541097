import withShipment from "../../../withShipment";
import React from "react";
import Divider from "@material-ui/core/Divider";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {TableBody, TableHead, TextField, Typography, withStyles} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import styles from "../../../pages/Dashboard/DashboardFinal/DashboardFinal.module.css";
import { parseISO, isValid  } from 'date-fns';


const StyledTextField = withStyles({
    root: {
        paddingLeft: "5px",
        paddingRight: "5px"
    }
})(TextField)

const StyledDateField = withStyles({
    root: {
        paddingLeft: "5px",
        paddingRight: "5px",
        width: "20%"
    }
})(KeyboardDatePicker)

class FTLCarrierInvoiceForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editingInvoice: {}
        }
    }

    componentDidMount() {
        this.setState({
            editingInvoice: this.props.editingInvoice
        })
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevProps.editingInvoice !== this.props.editingInvoice) {
            this.setState({
                editingInvoice: this.props.editingInvoice
            })
        }
    }

    formatAmount = (amount) => {
        const num = parseFloat(amount);
        // Ensure the conversion is successful before formatting
        if (!isNaN(num)) {
            return new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(num);
        }
        return 'null';
    }

    render() {
        return (<>
            <div className = {"invoiceWrapper"}>
                <div className="infoRow">
                    <StyledTextField
                        variant="outlined"
                        label="Carrier Name"
                        InputLabelProps={{ shrink: true }}
                        name="carrierName"
                        value={this.state.editingInvoice.carrierName}
                        InputProps={{readOnly: true}}
                        error={!this.state.editingInvoice.carrierName}
                        disabled={true}
                    />
                    <StyledTextField
                        variant="outlined"
                        label="Account Number"
                        InputLabelProps={{ shrink: true }}
                        name="accountNumber"
                        value={this.state.editingInvoice.accountNumber}
                        InputProps={{readOnly: true}}
                        error={!this.state.editingInvoice.accountNumber}
                        disabled={true}
                    />
                    <StyledTextField
                        variant="outlined"
                        label="Invoice Number"
                        InputLabelProps={{ shrink: true }}
                        name="invoiceNumber"
                        value={this.state.editingInvoice.invoiceNumber}
                        onChange={e => {this.props.editSelectedInvoice("invoiceNumber", e.target.value);}}
                        error={!this.state.editingInvoice.invoiceNumber}
                    />
                    <StyledDateField
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        label="Date"
                        format="yyyy-MM-dd"
                        onChange={date => {this.props.editSelectedInvoice("invoiceDate", date);}}
                        value={this.state.editingInvoice?.invoiceDate ? parseISO(this.state.editingInvoice.invoiceDate) : null}
                    />
                    <StyledTextField
                        variant="outlined"
                        label="status"
                        InputLabelProps={{ shrink: true }}
                        name="status"
                        value={this.state.editingInvoice.status}
                        InputProps={{readOnly: true}}
                        error={!this.state.editingInvoice.status}
                        disabled={true}
                    />
                </div>
                <Divider style={{marginTop: "1%", marginBottom: "1%", width: "100%"}} />
                <div className="mainContent">
                    <div className="leftBox">
                        <h5>Summary</h5>
                        {this.state.editingInvoice?.invoiceTax && <div style={{display: "flex", justifyContent: "space-between", width: "100%", fontSize: '16px' }}>
                            <p>{"Tax"}</p>
                            <div style={{display: "flex", justifyContent: "space-between" , width: "27%"}}>
                                <p>$</p>
                                <p>{this.formatAmount(this.state.editingInvoice?.invoiceTax)}</p>
                            </div>
                        </div>}
                        <div style={{display: "flex", justifyContent: "space-between", width: "100%", fontSize: '16px' }}>
                            <p>{"Total"}</p>
                            <div style={{display: "flex", justifyContent: "space-between" , width: "27%"}}>
                                <p>$</p>
                                <p>{this.formatAmount(this.state.editingInvoice?.invoiceAmount)}</p>
                            </div>
                        </div>
                    </div>
                    <Divider orientation="vertical" flexItem sx={{ width: 2, bgcolor: 'primary.main' }} />
                    <div className="rightBox">
                        <div className="infoRow">
                            <KeyboardDatePicker
                                autoOk
                                name="paymentDate"
                                label="Payment Date"
                                onChange={date => {this.props.editSelectedInvoice("paymentDate", date);}}
                                value={this.state.editingInvoice?.paymentDate ? parseISO(this.state.editingInvoice.paymentDate) : null}
                                format="yyyy-MM-dd"
                                variant="inline"
                                inputVariant="outlined"
                                InputLabelProps={{ shrink: true }}
                                InputAdornmentProps={{ position: "start" }}
                                style={{width:'33%', marginRight:"10px"}}
                                error={
                                    this.state.editingInvoice?.paymentDate
                                        ? !isValid(parseISO(this.state.editingInvoice.paymentDate)) // Invalid date
                                        : false // No error if empty
                                }
                            />

                            <Autocomplete
                                style={{width:'33%', marginRight:"10px"}}
                                options={this.props.bankAccounts}
                                getOptionLabel={(option) => option.name && option.accountNumber ? `${option.name} (${option.accountNumber})`: ``}
                                value={this.props.bankAccounts.find(account => account.bankAccountId === this.state.editingInvoice?.bankAccount?.bankAccountId) || null}
                                onChange={(e, value) => this.props.editSelectedInvoice("bankAccount", value)}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        name="bankAccount"
                                        label="Bank Account"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                }
                            />
                            <TextField
                                style={{width:'33%'}}
                                variant="outlined"
                                name="referenceId"
                                label="Reference Id"
                                value={this.state.editingInvoice?.referenceId}
                                onChange={(e) => this.props.editSelectedInvoice(e.target.name, e.target.value)}
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>

                        <div style={{paddingBottom: "10px"}}>
                            <TextField
                                label="Notes"
                                multiline
                                rows={4}
                                rowsMax={8}
                                InputLabelProps={{ shrink: true }}
                                style={{ width: "100%", marginTop:"10px"}}
                                name="notes"
                                value={this.props.editingInvoice?.notes ?? ""}
                                onChange={(e) => this.props.editSelectedInvoice(e.target.name, e.target.value)}
                                variant="outlined"
                            />
                        </div>
                    </div>
                </div>
                <Divider style={{marginTop: "1%", marginBottom: "1%", width: "100%"}} />
                {
                    <div className="needs-review-container" style={{width: "100%", marginTop:"10px"}}>
                        <Typography variant="h6" component="div" gutterBottom className="form-header">
                        </Typography>
                        <div className="errorMessage-content">
                            <TableContainer>
                                <Table size={"medium"} stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className='cellPadding'>
                                                <p className={styles.cardTotalHeader}>
                                                    Issues:
                                                </p>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.editingInvoice?.errorMessage?.map((report, index) => (
                                            <TableRow hover key={index}>
                                                <TableCell className='cellPadding'>
                                                    <p className={styles.cardCellItems}>
                                                        {report}
                                                    </p>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                }
            </div>
        </>)
    }
}

const actionCreators = {

}

export default withShipment({
    actionCreators
}, FTLCarrierInvoiceForm)