import {
    STORE_ALL_DAMAGES,
    STORE_DAMAGED_ENTRY,
    STORE_PO_INFORMATION, STORE_SPECIFIC_DAMAGE,
} from "../../constants/damage";


const initialState = {
damageEntries: [],
    poInformation: [],
    allDamages: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case STORE_DAMAGED_ENTRY:
            return {
                ...state,
                damagedEntries: action.data
            }
        case STORE_PO_INFORMATION:
            return {
                ...state,
                poInformation: action.data
            }
        case STORE_ALL_DAMAGES:
            return {
                ...state,
                allDamages: action.data
            }
        case STORE_SPECIFIC_DAMAGE:
            return {
                ...state,
                specificDamage: action.damage
            }
        default:
            return state;
    }
}