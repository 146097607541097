import React from "react";

// material components
import {Box, IconButton, MenuItem, Select, TextField, Tooltip} from "@material-ui/core";

// material icons
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import DehazeIcon from '@material-ui/icons/Dehaze';

// constants
import {DELETE_ACTION, DRAG} from "../constants";

// import styling
import './ActionNumericInput.css';
import PropTypes from "prop-types";
import DoubleNumericalAction from "./DoubleNumericalAction";
import {
    CONDITION_ACTIONS,
    PACKAGE_WEIGHT_CALCULATE_AUTOMATICALLY,
    PACKAGE_WEIGHT_CALCULATE_AUTOMATICALLY_MESSAGE,
    PRE_DEFINED_VALUES,
} from "./constants";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

class SingleNumericalAction extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            actionId: "",
            name: "",
            value: "",
            preDefinedValue: "-",
            disableManualValueField: false,
            preDefinedValues: this.getPredefinedValues()
        }
    }

    componentDidMount() {
        this.setState({
            ...this.props.action
        })
    }

    getPredefinedValues = () => {
        let preDefinedValues = CONDITION_ACTIONS.find(action => action.name === this.props.action.name)?.predefinedValues
        if (!preDefinedValues) {
            preDefinedValues = PRE_DEFINED_VALUES
        }
        return preDefinedValues
    }

    handleValueChange = (event) => {
        const value = event.target.value;
        this.setState(
            {
                value,
            },
            () => {
                this.props.updateActionWithParent({ ...this.state });
            }
        );
    }

    handlePredefinedValueChange = (e) => {
        this.setState({
            preDefinedValue: e.target.value,
            disableManualValueField: e.target.value !== '-',
            value: e.target.value !== '-'? '': this.state.value
        }, () => {
            this.props.updateActionWithParent({ ...this.state })})
    }

    render() {
        return (
            <Box
                className='actionNumericField'
                display='flex'
                flexDirection='row'
                justifyContent='left'
                alignItems='center'
            >
                <Box className='actionNumericFieldName'>{this.state.name}</Box>
                <div>
                    <TextField
                        className='actionNumericFieldInput'
                        variant='outlined'
                        type="number"
                        disabled={this.state.disableManualValueField}
                        label={this.props.action.label1}
                        value={this.state.value}
                        onChange={this.handleValueChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>

                {!this.state.preDefinedValues || this.state.preDefinedValues.length <= 0 ? <></> :
                    <React.Fragment>
                        <div>
                            OR
                        </div>
                        <Select
                            variant="outlined"
                            name="predefinedValue"
                            onChange={this.handlePredefinedValueChange}
                            value={this.state.preDefinedValue === "null" ? '-' : this.state.preDefinedValue}
                            className='predefined-dropdown'
                        >
                            {this.state.preDefinedValues.map((value, index) => (
                                <MenuItem value={value} key={index}>{value === '-'? 'Select a predefined value': value}</MenuItem>
                            ))
                            }
                        </Select>
                    </React.Fragment>
                }

                {(this.state.preDefinedValue === PACKAGE_WEIGHT_CALCULATE_AUTOMATICALLY) ?
                    <div className="package-action-warning">
                        <Tooltip
                            className='infoTooltipIcon'
                            enterTouchDelay={0}
                            leaveTouchDelay={30000}
                            enterDelay={400}
                            title={
                                <React.Fragment>
                                    {PACKAGE_WEIGHT_CALCULATE_AUTOMATICALLY_MESSAGE}
                                </React.Fragment>
                            }
                        >
                            <IconButton aria-label='info'>
                                <InfoOutlinedIcon variant='outlined' fontSize='small'/>
                            </IconButton>
                        </Tooltip>
                    </div>
                    :null}

                {/* in order to make sure that the delete icon aligns, we add an empty component */}
                <div className='actionNumericEmptyDiv'>
                    <Tooltip title={DELETE_ACTION}>
                        <IconButton
                            aria-label={DELETE_ACTION}
                            onClick={() => this.props.deleteAction(this.state.actionId)}
                        >
                            <DeleteOutlineIcon variant='outlined' fontSize='small' />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={DRAG}>
                        <IconButton
                            aria-label='drag'
                        >
                            <DehazeIcon variant='outlined' fontSize='small' />
                        </IconButton>
                    </Tooltip>
                </div>
            </Box>
        );
    }

}

SingleNumericalAction.propTypes = {
    action: PropTypes.object,
    updateActionWithParent: PropTypes.func,
    deleteAction: PropTypes.func,
    isEdit: PropTypes.bool
};

SingleNumericalAction.defaultProps = {
    action: {},
    updateActionWithParent: ()=>{},
    deleteAction: ()=>{},
    isEdit: false
}

export default SingleNumericalAction;