import {
  ADD_NEW_APPLICATION,
  UPDATE_APPLICATION_NAME,
  BUTTON_SELECTED,
  TESTING_SAGAS, MORE_TESTING_SAGAS,
} from "../../constants/test";

export const addNewApplication = (name) => ({
  type: ADD_NEW_APPLICATION,
  name,
});

export const updateApplicationName = (name) => ({
  type: UPDATE_APPLICATION_NAME,
  name,
});

export const buttonSelected = (buttonSelected) => ({
  type: BUTTON_SELECTED,
  buttonSelected,
});

export const testingSagas = () => ({
  type: TESTING_SAGAS,
  message: 'Hello Sagas',
});

export const testingMoreSagas = (someDate) =>( {
  type: MORE_TESTING_SAGAS,
  someDate,
})

export const testActions = {
  addNewApplication,
  updateApplicationName,
  buttonSelected,
  testingSagas,
  testingMoreSagas,
};