import React from 'react';
import {
    userInfoSelector
} from "../../../redux/selectors/auth";
import withShipment from "../../../withShipment";


// import constants
import {
    COMPONENT_SPECS,
    CONDITION_ACTIONS, TAX,
} from "./constants";
import {
    DELETE_ACTION, DRAG
} from "../constants";

// import styling
import "../ConditionFilters/FilterBooleanSelect.css";
import './ActionDoubleDropdown.css';

// material components
import {Box, IconButton, MenuItem, Select, Tooltip} from "@material-ui/core";

// material icons
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import DehazeIcon from '@material-ui/icons/Dehaze';
import PropTypes from "prop-types";
import {stateAndProvinceMappingSelector} from "../../../redux/selectors/global";
import {getStateAndProvinceMapping} from "../../../redux/actions/global";

class ActionDoubleDropdown extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            actionId: "",
            name: "",
            type: "",
            value1: "",
            value2: "",
            disableManualValueField: false,

        }
    }

    componentDidMount() {
        this.setState({
            ...this.props.action,
        })
        this.props.getStateAndProvinceMapping()
    }


    handleChange = (event) => {
        this.setState(
            {
                [event.target.name]: event.target.value,
            },
            () => {
                this.props.updateActionWithParent({ ...this.state });
            }
        );
    };

    getSpec = () => {
        const action = CONDITION_ACTIONS.filter(n => n.name === this.props.name)
        return action[0].spec
    }

    displayDropdown1 = () => {
        let dropdownSpec = this.props.isEdit ? this.getSpec() : this.props.action.spec;
        switch (dropdownSpec) {
            case COMPONENT_SPECS.TAX:
                return (
                    this.props.stateAndProvinceMapping.map(spec => (
                        <MenuItem value={spec.state}>{spec.state} ({spec.displayName})</MenuItem>
                    ))
                )
            default:
                return (
                    dropdownSpec.map(spec => (
                        <MenuItem value={spec}>{spec}</MenuItem>
                    ))
                )
        }
    }

    displayDropdown2 = () => {
        let dropdownSpec = this.props.isEdit ? this.getSpec() : this.props.action.spec;
        switch (dropdownSpec) {
            //case COMPONENT_SPECS.TAX using the default
            default:
                return (
                    dropdownSpec.map(spec => (
                        <MenuItem value={spec}>{spec}</MenuItem>
                    ))
                )
        }
    }

    render() {
        return (
            <Box
                className='actionDropdownSelect'
                display='flex'
                flexDirection='row'
                justifyContent='left'
                alignItems='center'
            >
                <Box className='actionDropdownSelectName'>{this.state.name}</Box>
                <Select
                    className={'actionDropdownSelectOptions'}
                    variant='outlined'
                    name="value1"
                    value={this.state.value1}
                    onChange={this.handleChange}
                    disabled={this.state.disableManualValueField}
                >
                    {this.displayDropdown1()}
                </Select>
                <Select
                    className={'actionDropdownSelectOptions'}
                    variant='outlined'
                    name="value2"
                    label={this.props.action.label2}
                    value={this.state.value2}
                    onChange={this.handleChange}
                    disabled={this.state.disableManualValueField}
                >
                    {this.displayDropdown2()}
                </Select>

                {/* in order to make sure that the delete icon aligns, we add an empty component */}
                <div className='filterBooleanEmptyDiv'>
                    <Tooltip title={DELETE_ACTION}>
                        <IconButton
                            aria-label={DELETE_ACTION}
                            onClick={() => this.props.deleteAction(this.state.actionId)}
                        >
                            <DeleteOutlineIcon variant='outlined' fontSize='small' />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={DRAG}>
                        <IconButton
                            aria-label='drag'
                        >
                            <DehazeIcon variant='outlined' fontSize='small' />
                        </IconButton>
                    </Tooltip>
                </div>
            </Box>
        );
    }
}

const mapStateToProps = (state) => ({
    userInfo: userInfoSelector(state),
    stateAndProvinceMapping: stateAndProvinceMappingSelector(state),
})

const actionCreators = {
    getStateAndProvinceMapping
}

ActionDoubleDropdown.propTypes = {
    action: PropTypes.array,
    name: PropTypes.string,
    updateActionWithParent: PropTypes.func,
    deleteAction: PropTypes.func,
    isEdit: PropTypes.bool
};

ActionDoubleDropdown.defaultProps = {
    action: {},
    name: "",
    updateActionWithParent: ()=>{},
    deleteAction: ()=>{},
    isEdit: true,
    stateAndProvinceMapping: [],
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, ActionDoubleDropdown);