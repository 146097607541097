import React from 'react';

// styling
import styles from "./CreateCarrierDialog.module.css";

// material components
import {Button, TextField} from "@material-ui/core";
import PropTypes from "prop-types";
import {BUY_SHIPPING_SETUP, REST, SHIP, STANDARD_SETUP, TRACK, Validate, WEB_SERVICES} from "./constants";
import {FEDEX} from "../../shipments/constants";

function CarrierForm(props) {

    const getUsernameLabel = () => {
        if (props.parentState.carrierSetup === STANDARD_SETUP && props.parentState.carrier === FEDEX) {
            if (props.parentState.projectType == SHIP)
                return "Ship API Key"
            else if (props.parentState.projectType == TRACK)
                return "Track API Key"
        }
        if (props.parentState.carrierSetup === STANDARD_SETUP && props.parentState.carrier === "UPS") {
            return "Client Id"
        }
        return "Key"
    }

    const getPasswordLabel = () => {
        if (props.parentState.carrierSetup === STANDARD_SETUP && props.parentState.carrier === FEDEX) {
            if (props.parentState.projectType == SHIP)
                return "Ship Secret Key"
            else if (props.parentState.projectType == TRACK)
                return "Track Secret Key"
        }
        if (props.parentState.carrierSetup === STANDARD_SETUP && props.parentState.carrier === "UPS") {
            return "Client Secret"
        }
        return "Password"
    }

    const getAccountNumberLabel = () => {
        if (props.parentState.carrierSetup === STANDARD_SETUP && props.parentState.carrier === "Canada Post")
            return "Customer Number"
        return "Account Number"
    }

    // containing username & password
    const displayFirstRow = (carrierName) => {
        switch (carrierName) {
            case "Amazon Shipping":
                return <></>
            case "FedEx":
                // no password or username field for REST FedEx
                if (props.parentState.apiType == REST) {
                    return <></>
                }
            default:
                return(<div className={styles.rowSpec}>
                    <TextField
                        required={props.parentState.carrierSetup !== BUY_SHIPPING_SETUP}
                        error={props.errors.username}
                        className={styles.formTwoInput}
                        variant="outlined"
                        value={props.parentState.username}
                        name="username"
                        onChange={props.handleChange}
                        label={getUsernameLabel()}
                        InputLabelProps={{ shrink: true }}
                        error={props.parentState.error && (props.parentState.username === '' || props.parentState.username === undefined)}
                    />
                    <TextField
                        required={props.parentState.carrierSetup !== BUY_SHIPPING_SETUP}
                        type="password"
                        error={props.errors.password}
                        className={styles.formTwoInput}
                        variant="outlined"
                        value={props.parentState.password}
                        name="password"
                        onChange={props.handleChange}
                        label={getPasswordLabel()}
                        InputLabelProps={{ shrink: true }}
                        error={props.parentState.error && (props.parentState.password === '' || props.parentState.password === undefined)}
                    />
                </div>)
        }
    }

    // containing account number, meter number & contractId
    const displaySecondRow = (carrierName) => {
        let accountNumberComponent = <TextField
            required={props.parentState.carrierSetup !== BUY_SHIPPING_SETUP}
            error={props.errors.accountNumber}
            className={styles.formTwoInput}
            variant="outlined"
            value={props.parentState.accountNumber}
            name="accountNumber"
            onChange={props.handleChange}
            label={getAccountNumberLabel()}
            InputLabelProps={{ shrink: true }}
            error={props.parentState.error && (props.parentState.accountNumber === '' || props.parentState.accountNumber === undefined)}
        />

        switch (carrierName) {
            case "FedEx":
                return <div className={styles.rowSpec}>
                    {props.parentState.apiType === WEB_SERVICES
                        ? accountNumberComponent
                        : React.cloneElement(accountNumberComponent, {
                            style: { width: '100%' }
                        })
                    }
                    {props.parentState.apiType == WEB_SERVICES && <TextField
                        required={props.parentState.carrierSetup !== BUY_SHIPPING_SETUP}
                        className={styles.formTwoInput}
                        variant="outlined"
                        value={props.parentState.meterNumber}
                        name="meterNumber"
                        onChange={props.handleChange}
                        label="Meter Number"
                        InputLabelProps={{ shrink: true }}
                        error={props.parentState.error && (props.parentState.meterNumber === '' || props.parentState.meterNumber === undefined)&&(props.parentState.contractId === '' || props.parentState.contractId === undefined)}
                    />}
                </div>
            case "Canada Post":
                return <div className={styles.rowSpec}>
                    {accountNumberComponent}
                    <TextField
                        className={styles.formTwoInput}
                        variant="outlined"
                        value={props.parentState.contractId}
                        name="contractId"
                        onChange={props.handleChange}
                        label="Contract ID"
                        InputLabelProps={{ shrink: true }}
                        error={props.parentState.error && (props.parentState.contractId === '' || props.parentState.contractId === undefined)}
                    />
                </div>
            case "Amazon Shipping":
                return <div className={styles.rowCentered}>
                    {accountNumberComponent}
                </div>
            default:
                return <div className={styles.rowSpec}>
                    {accountNumberComponent}
                </div>
        }
    }

    const displayThirdRow = (carrierName) => {
        switch (carrierName) {
            case "FedEx":
                if (props.parentState.apiType == REST) {
                    return <div className={styles.rowSpec}>
                        <TextField
                            className={styles.formTwoInput}
                            variant="outlined"
                            value={props.parentState.shipAPIKey}
                            name="shipAPIKey"
                            style= {{ width: '100%' }}
                            onChange={props.handleChange}
                            label="Ship API Key"
                            InputLabelProps={{shrink: true}}
                            error={props.parentState.error && (props.parentState.shipAPIKey === '' || props.parentState.shipAPIKey === undefined)}
                        />
                    </div>
                }
            default:
                return <div></div>
        }
    }

    const displayFourthRow = (carrierName) => {
        switch (carrierName) {
            case "FedEx":
                if (props.parentState.apiType == REST) {
                    return <div className={styles.rowSpec}>
                        <TextField
                            className={styles.formTwoInput}
                            variant="outlined"
                            value={props.parentState.shipSecretKey}
                            name="shipSecretKey"
                            onChange={props.handleChange}
                            style= {{ width: '100%' }}
                            label="Ship Secret Key"
                            InputLabelProps={{shrink: true}}
                            error={props.parentState.error && (props.parentState.shipSecretKey === '' || props.parentState.shipSecretKey === undefined)}
                        />
                    </div>
                }
            default:
                return <div></div>
        }
    }

    const displayFifthRow = (carrierName) => {
        switch (carrierName) {
            case "FedEx":
                if (props.parentState.apiType == REST) {
                    return <div className={styles.rowSpec}>
                        <TextField
                            className={styles.formTwoInput}
                            variant="outlined"
                            value={props.parentState.trackAPIKey}
                            name="trackAPIKey"
                            onChange={props.handleChange}
                            style= {{ width: '100%' }}
                            label="Track API Key"
                            InputLabelProps={{shrink: true}}
                            error={props.parentState.error && (props.parentState.trackAPIKey === '' || props.parentState.trackAPIKey === undefined)}
                        />
                    </div>
                }
            default:
                return <div></div>
        }
    }

    const displaySixthRow = (carrierName) => {
        switch (carrierName) {
            case "FedEx":
                if (props.parentState.apiType == REST) {
                    return <div><div className={styles.rowSpec}>
                        <TextField
                            className={styles.formTwoInput}
                            variant="outlined"
                            value={props.parentState.trackSecretKey}
                            name="trackSecretKey"
                            onChange={props.handleChange}
                            style= {{ width: '100%' }}
                            label="Track Secret Key"
                            InputLabelProps={{shrink: true}}
                            error={props.parentState.error && (props.parentState.trackSecretKey === '' || props.parentState.trackSecretKey === undefined)}
                        />
                    </div>
                    <div className={styles.rowCentered}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={validateFedExCredentials}
                            styles={{paddingRight: '5px'}}
                        >
                            {Validate}
                        </Button>
                    </div>
                    </div>
                }
            default:
                return <div></div>
        }
    }

    const validateFedExCredentials = () => {
        const data = {
            accountNumber: props.parentState.accountNumber,
        }
        props.validateFedExRESTCredentials(data)
    }

    return(
        <div className={styles.rowCarrier}>
            <div>
                {displayFirstRow(props.parentState.carrier)}
                {displaySecondRow(props.parentState.carrier)}
                {displayThirdRow(props.parentState.carrier)}
                {displayFourthRow(props.parentState.carrier)}
                {displayFifthRow(props.parentState.carrier)}
                {displaySixthRow(props.parentState.carrier)}
            </div>
        </div>
    )
}

CarrierForm.propTypes = {
    parentState: PropTypes.object,
    handleChange: PropTypes.func,
    errors: PropTypes.array,
    isEdit: PropTypes.bool
}

CarrierForm.defaultProps = {
    parentState: {},
    handleChange: ()=>{},
    errors: [],
    isEdit: ()=>{}
}

export default CarrierForm;