import React from "react";
import withShipment from "../../../withShipment";
import './Listings.css';
import {LISTINGS_PAGES} from "./constants";
import ListingsTable from "./ListingsPages/ListingsTable";
import ErrorFeedbackBar from "../../global/ErrorFeedbackBar";
import {
    updateInventoryAllocation, getPrintConfig, listMarketplaces, listProducts,
    storeResponseMessage
} from "../../../redux/actions/settings";
import {userInfoSelector} from "../../../redux/selectors/auth";
import {marketplacesSelector, responseMessageSelector} from "../../../redux/selectors/settings";
import ListingsLoading from "./ListingsPages/ListingsLoading";
import {AUTOMATION_RULES_PAGES} from "../../automationrules/constants";
import ListingsSettings from "./ListingsPages/ListingsSettings";
import {SUCCESS} from "../../shipments/constants";
import FeedbackBar from "../../feedbackBar/FeedbackBar";
import {listManufacturers} from "../../../redux/actions/inventory";


class Listings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: LISTINGS_PAGES.LOADING,
            selectedEntries: {},
            selecting: false,
            responseMessage: "",
            feedbackBarOpen: false,
        }

        this.props.listMarketplaces({id: this.props.user.company})
        this.props.listProducts()
        this.props.listManufacturers();
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevProps.responseMessage !== this.props.responseMessage && this.props.responseMessage!=="") {
            this.setState({
                feedbackBarOpen: true
            })
        }
    }
    closeFeedbackBar = () => {
        this.props.storeResponseMessage("")
        this.setState({
            feedbackBarOpen: false
        })
    }

    setCurrentPage = (newPage, pageChangeInfo) => {
        switch (this.state.currentPage){
            case "":
                break
        }

        switch (newPage) {
            case "":
                break
        }

        this.setState({
            currentPage: newPage
        })
    }


    updateInventoryAllocation= (updatedPercentages, companyId) => {
        this.props.updateInventoryAllocation(updatedPercentages,companyId)
    }


    displayTable = () => {
        return <React.Fragment>
            <ListingsTable
                setCurrentPage={this.setCurrentPage}
                setSelected={(newEntries, selecting) => this.setState({ selectedEntries: newEntries, selecting: selecting })}
                checkedEntries={this.state.selectedEntries}
            />
        </React.Fragment>
    }

    displayLoading = () => {
        return <React.Fragment>
            <ListingsLoading
                setCurrentPage={this.setCurrentPage}
            />
        </React.Fragment>
    }

    displaySetting = () => {
        return <React.Fragment>
            <ListingsSettings
                updateInventoryAllocation = {this.updateInventoryAllocation}
                setCurrentPage={this.setCurrentPage}
                listMarketplaces= {this.props.listMarketplaces({id: this.props.user.company})}
                companyId={this.props.user.company}
                history={this.props.history}
            />
        </React.Fragment>
    }

    displayCurrentPage = (currentPage) => {
        switch (currentPage) {
            case LISTINGS_PAGES.TABLE:
                return this.displayTable()
            case LISTINGS_PAGES.LOADING:
                return this.displayLoading()
            case LISTINGS_PAGES.SETTINGS:
                return this.displaySetting()
            default:
                console.log("Error: current listings page doesn't exist")
                return <div>We encountered an error. Please refresh the page.</div>
        }
    }

    render() {
        return (
            <React.Fragment>
                <ErrorFeedbackBar/>
                <FeedbackBar
                    open={this.state.feedbackBarOpen}
                    severity={SUCCESS}
                    message={this.props.responseMessage}
                    handleClose={this.closeFeedbackBar}
                />
                {this.displayCurrentPage(this.state.currentPage)}
            </React.Fragment>
        )
    }
}

Listings.defaultProps = {
    user: []
}

Listings.propTypes = {
}

const mapStateToProps = (state) => ({
    user: userInfoSelector(state),
    responseMessage: responseMessageSelector(state),
})

const actionCreators = {
    listMarketplaces,
    listProducts,
    listManufacturers,
    updateInventoryAllocation,
    storeResponseMessage,
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, Listings);