import React from "react";
import withShipment from "../../../withShipment";
import PropTypes from "prop-types";

// styling
import "./ManageBankAccounts.css"

// constants
import {
    ADD_ACCOUNT,
    ADD_BANK_ACCOUNT_TOOLTIP,
    BANK_ACCOUNTS_TITLE, BANK_PAGES,
    CREATE_BANK_ACCOUNT_HEAD_CELLS, EDIT_BANK_ACCOUNT_INFO
} from "./constants";

// custom components
import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";
import TwoStepDelete from "../../global/subcomponents/twoStepDelete/TwoStepDelete";

// mui components and icons
import {IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";



class ManageBankAccountsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deleteId: null,
        }
    }


    getContextualMenuItems = () => {
        let menuItems = []

        menuItems.push(
            {
                title: ADD_BANK_ACCOUNT_TOOLTIP,
                icon: <AddCircleIcon/>,
                onClick: () => this.props.setCurrentPage(BANK_PAGES.ADD_BANK, {})
            }
        )

        return menuItems
    }


    handleChange = (e, index) => {
        const updatedBankAccounts = [...this.props.bankAccounts]
        updatedBankAccounts[index][e.target.name] = e.target.value
        this.setState({ bankAccounts: updatedBankAccounts })
    }


    render() {
        return (
            <>
                {/*<ErrorFeedbackBar />*/}
                <TopToolbar
                    pageName={BANK_ACCOUNTS_TITLE}
                    menuItems={this.getContextualMenuItems()}
                />
                <div className="header-title">

                </div>
                <Table aria-labelledby="tableTitle" size='small' className="table-wrapper">
                    <TableHead>
                        <TableRow className="table">
                            <TableCell/>
                            {CREATE_BANK_ACCOUNT_HEAD_CELLS.map((headCell) => (
                                <TableCell key={headCell.id}>
                                    {headCell.label}
                                </TableCell>))
                            }
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.bankAccounts?.map((row, rowIndex) => {
                            return (
                                <TableRow>
                                    <TableCell className="icon-cell">
                                            <IconButton onClick={() => this.props.handleEditRow(rowIndex)}>
                                                <EditIcon/>
                                            </IconButton>
                                    </TableCell>
                                    {CREATE_BANK_ACCOUNT_HEAD_CELLS.map((headCell, colIndex) => (
                                        <TableCell key={colIndex} className="table-cell">
                                            {row[headCell.id]}
                                        </TableCell>
                                    ))}
                                    <TwoStepDelete
                                            rowId={row.bankAccountId}
                                            handleDelete={(rowIdToDelete) => this.props.handleDeleteRow(row.bankAccountId)}
                                            deleteId={this.state.deleteId}
                                            setDeleteId={(newDeleteId) => this.setState({deleteId: newDeleteId})}
                                        />

                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </>
        )
    }
}


export default ManageBankAccountsTable;
