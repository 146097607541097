import {
    DELETE_DAMAGE_ENTRY,
    FIND_PO_INFORMATION, LIST_ALL_DAMAGES, LIST_SPECIFIC_DAMAGE,
    SAVE_DAMAGED_ENTRY, STORE_ALL_DAMAGES,
    STORE_DAMAGED_ENTRY,
    STORE_PO_INFORMATION, STORE_SPECIFIC_DAMAGE, UPDATE_DAMAGE_ENTRY
} from "../../constants/damage";

export const saveDamagedEntry = (data, reset) => ({
    type: SAVE_DAMAGED_ENTRY,
    data,
    reset
})

export const storeDamagedEntry = (data) => ({
    type: STORE_DAMAGED_ENTRY,
    data
})

export const findPoInformation = (data) => ({
    type: FIND_PO_INFORMATION,
    data
})

export const storePoInformation = (data) => ({
    type: STORE_PO_INFORMATION,
    data
})

export const listAllDamages = (locationId) => ({
    type: LIST_ALL_DAMAGES,
    locationId
})

export const storeAllDamages = (data) => ({
    type: STORE_ALL_DAMAGES,
    data
})

export const listSpecificDamage = (damageId) => ({
    type: LIST_SPECIFIC_DAMAGE,
    damageId
})

export const storeSpecificDamage = (damage) => ({
    type: STORE_SPECIFIC_DAMAGE,
    damage
})

export const deleteDamageEntry = (damageId, reset) => ({
    type: DELETE_DAMAGE_ENTRY,
    damageId,
    reset
})

export const updateDamageEntry = (data, reset) => ({
    type: UPDATE_DAMAGE_ENTRY,
    data,
    reset
})