import React, {Component} from "react";

class InTransitIcon extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
    }

    render() {
        return (

            <svg
                width="32px"
                height="30px"
                viewBox="3 0 30 30"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <path
                      d="M12 17.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm10.043 0a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm3.457.5h.5v1h-1.457c0-1.38-1.12-2.5-2.5-2.5a2.502 2.502 0 00-2.5 2.5H14.5c0-1.38-1.122-2.5-2.5-2.5A2.502 2.502 0 009.5 19h-2v-1H8v-1.625h.585c.773 0 1.51-.275 2.11-.75H4a3.41 3.41 0 012.414-1h3.171c.773 0 1.51-.275 2.11-.75H5a3.41 3.41 0 012.414-1h3.171c.773 0 1.51-.275 2.11-.75H6a3.404 3.404 0 012-.96V10h10v6h1v-4h3.5l3 3v3zM21 15h3.086l-2-2H21v2z">
                </path>
            </svg>

    );
    }
}

export default InTransitIcon