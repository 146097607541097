import { DAYS_OF_WEEK } from "../../components/global/constants";
import { capitalize, Typography } from "@material-ui/core";
import React from "react";
import {
    DIFFERENCE, EXPAND_ALL,
    MISSING_PACKED_TITLE,
    MISSING_SCANNED_TITLE, REPORT_TITLE,
    SHIPMENTS_FULLFILLED_TITLE,
    TOTAL_TITLE
} from "./DashboardFinal/constants";
import styles from "./DashboardFinal/DashboardFinal.module.css";
import {
    TableBody, TableHead, IconButton, Tooltip, TableFooter
} from '@material-ui/core';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import "./DashboardSummary.css";
import SearchableTable from "../../components/global/Search/SearchableTable";
import {Assessment, ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon} from "@material-ui/icons";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import PrimeIcon from "../../media/images/Amazon-Prime.png";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

class DashboardSummary extends SearchableTable {
    constructor(props) {
        super(props);
        this.state = {
            missingScanned: [],
            missingPacked: [],
            reportData: [],
            orderDirectionShipments: "desc",
            valueToOrderByShipments: "timestamp",
            orderDirectionLabels: "desc",
            valueToOrderByLabels: "timestamp",
            orderDirectionReport: "asc",
            valueToOrderByReport: "product",
            expandAll: false
        }
    }

    componentDidMount() {
        this.searchForMissingPrinted();
        this.searchForMissingPacked();
        this.gatherReport();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.scannedLabels !== this.props.scannedLabels ||
            prevProps.packedLabels !== this.props.packedLabels ||
            prevProps.unpackedLabels !== this.props.unpackedLabels ||
            prevProps.pendingPackedLabels !== this.props.pendingPackedLabels ||
            prevProps.searchText !== this.props.searchText
        ) {
            this.searchForMissingPrinted();
            this.searchForMissingPacked();
            this.gatherReport();
        }
    }

    searchForMissingPrinted() {
        let searchText = this.props.searchText
        let missing = [];
        let scannedTrackingNumbers = this.props.scannedLabels ? this.props.scannedLabels.map((label) => label.trackingNumber) : []
        let pendingTrackingNumbers = this.props.unpackedLabels ? this.props.unpackedLabels.map((label) => label.trackingNumber) : []
        let printedTrackingNumbers = this.props.printedLabels ? this.props.printedLabels.map((label) => label.trackingNumber) : []

        for (let label of printedTrackingNumbers) {
            if (!scannedTrackingNumbers.includes(label) || !pendingTrackingNumbers.includes(label)) {
                let idx = printedTrackingNumbers.indexOf(label)
                missing.push(this.props.printedLabels[idx]);
            }
        }
        missing = missing.filter((label)=> this.props.searchText ?
            ((label.trackingNumber && label.trackingNumber.includes(searchText)) || (label.product && label.product.includes(searchText)))
            : true)
        this.setState({
            missingScanned: missing
        })
    }

    searchForMissingPacked() {
        let searchText = this.props.searchText
        let missing = [];
        let allPackedLabels = []
        allPackedLabels.push(...this.props.packedLabels)
        allPackedLabels.push(...this.props.pendingPackedLabels)
        let packedTrackingNumbers = this.props.packedLabels ? allPackedLabels.map((label) => label.trackingNumber) : []
        let scannedTrackingNumbers = this.props.scannedLabels ? this.props.scannedLabels.map((label) => label.trackingNumber) : []
        let pendingTrackingNumbers = this.props.unpackedLabels ? this.props.unpackedLabels.map((label) => label.trackingNumber) : []
        for (let scanned of scannedTrackingNumbers) {
            if (scanned && !packedTrackingNumbers.includes(scanned) && !missing.includes(scanned)) {
                let idx = scannedTrackingNumbers.indexOf(scanned)
                missing.push(this.props.scannedLabels[idx]);
            }
        }
        for (let pendingScan of pendingTrackingNumbers) {
            if (pendingScan && !packedTrackingNumbers.includes(pendingScan) && !missing.includes(pendingScan)) {
                let idx = pendingTrackingNumbers.indexOf(pendingScan)
                missing.push({ ...this.props.unpackedLabels[idx], pending: true });
            }
        }
        missing = missing.filter((label)=> this.props.searchText ?
            ((label.trackingNumber && label.trackingNumber.includes(searchText)) || (label.product && label.product.includes(searchText)))
            : true)
        this.setState({ missingPacked: missing });
    }

    gatherReport = () => {
        let searchText = this.props.searchText
        // loop through printed + pending to get all shortcodes
        let shortcodes = []
        let reportData = []
        let updatedData = {}
        // generate printed labels summary
        for (let label of this.props.printedLabels) {
            updatedData = this.addPrintedLabelsReport(reportData, shortcodes, label)
            reportData = updatedData.reportData
            shortcodes = updatedData.shortcodes
        }
        for (let label of this.props.scannedLabels) {
            updatedData = this.addPrintedLabelsReport(reportData, shortcodes, label)
            reportData = updatedData.reportData
            shortcodes = updatedData.shortcodes
        }
        for (let label of this.props.unpackedLabels) {
            updatedData = this.addPrintedLabelsReport(reportData, shortcodes, label)
            reportData = updatedData.reportData
            shortcodes = updatedData.shortcodes
        }

        // generate packed labels summary
        for (let packedLabel of this.props.packedLabels) {
            reportData = this.addPackedLabelsReport(reportData, packedLabel)
        }
        for (let pendingLabel of this.props.pendingPackedLabels) {
            reportData = this.addPackedLabelsReport(reportData, pendingLabel)
        }

        reportData = reportData.sort((a, b) => {
            return a.productCode.toLowerCase().localeCompare(b.productCode.toLowerCase())
        })

        reportData = reportData.filter((label) =>
            searchText ? (label.productCode && label.productCode.includes(searchText) || (label.trackingNumbers && label.trackingNumbers.some(number => number.includes(searchText)))) : true
        )
        this.setState({
            reportData: reportData
        })

    }

    addPrintedLabelsReport = (reportData, shortcodes, label) => {
        if (label.product) {
            let codes = label.product.split('/')
            for (let product of codes) {
                product = product.trim()
                let code = product.split("-")[0].replace(/[()]/g, "");
                if (!shortcodes.includes(product)) {
                    let shortcodeReport = {
                        productCode: product,
                        shortcode: code,
                        printedQty: 1,
                        packedQty: 0,
                        pendingQty: 1,
                        trackingNumbers: [label.trackingNumber],
                        primePrintedQty: label?.isPrime ? 1 : 0 ,
                        primePendingQty: label?.isPrime ? 1 : 0 ,
                        primePackedQty: 0 ,
                        windowExpand: false
                    }
                    reportData.push(shortcodeReport)
                    shortcodes.push(product)
                } else {
                    let shortcodeReport = {};
                    let idx = 0;
                    for (let report of reportData) {
                        if (report.productCode === product) {
                            shortcodeReport = report;
                            idx = reportData.indexOf(report);
                            break;
                        }
                    }
                    if (Object.keys(shortcodeReport).length !== 0) {
                        shortcodeReport.printedQty++;
                        shortcodeReport.pendingQty++;
                        if(label.isPrime){
                            shortcodeReport.primePrintedQty++
                            shortcodeReport.primePendingQty++
                        }
                        shortcodeReport.trackingNumbers.push(label.trackingNumber)
                        reportData[idx] = shortcodeReport;
                    }
                }
            }
        }
        return { reportData: reportData, shortcodes: shortcodes };
    }

    addPackedLabelsReport = (reportData, label) => {
        if (label.product) {
            let codes = label.product.split('/')
            for (let product of codes) {
                product = product.trim()
                let shortcodeReport = {};
                let idx = 0;
                for (let report of reportData) {
                    if (report.productCode === product) {
                        shortcodeReport = report;
                        idx = reportData.indexOf(report);
                        break;
                    }
                }
                if (Object.keys(shortcodeReport).length !== 0) {
                    shortcodeReport.packedQty++;
                    shortcodeReport.pendingQty--;
                    reportData[idx] = shortcodeReport;
                }
            }
        }
        return reportData;
    }

    createSortHandler = (property, leftOrRight) => (event) => {
        this.handleRequestSort(event, property, leftOrRight);
    }

    handleRequestSort = (event, property, leftOrRight) => {
        if(leftOrRight === "-1") {
            const isAscendingShipments = (this.state.valueToOrderByReport === property && this.state.orderDirectionReport === "asc");
            this.setState({
                valueToOrderByReport: property,
                orderDirectionReport: (isAscendingShipments ? 'desc' : 'asc')
            })
            return
        }
        if(leftOrRight) {
            const isAscendingShipments = (this.state.valueToOrderByShipments === property && this.state.orderDirectionShipments === "asc");
            this.setState({
                valueToOrderByShipments: property,
                orderDirectionShipments: (isAscendingShipments ? 'desc' : 'asc')
            })
            return
        }
        if(!leftOrRight) {
            const isAscendingLabels = (this.state.valueToOrderByLabels === property && this.state.orderDirectionLabels === "asc");
            this.setState({
                valueToOrderByLabels: property,
                orderDirectionLabels: (isAscendingLabels ? 'desc' : 'asc')
            })
        }
    }
    addItUp = (addOn) => {
        let summer = 0;
        this.state.reportData.forEach(entry => {
            summer += entry[addOn]
        })
        return summer;
    }

    missingLabelsTable = (missingLabels, isPackedTable) => (
        <div className="missing-labels-table-content">
            <TableContainer className="missing-labels-table">
                <Table size={"medium"} stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell align={'left'} className='cellPadding table-cell' key="trackingNumber" style={{maxWidth:'25%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                                <TableSortLabel
                                active={isPackedTable ? this.state.valueToOrderByShipments === "trackingNumber" : this.state.valueToOrderByLabels === "trackingNumber"}
                                direction={isPackedTable ? (this.state.valueToOrderByShipments === "trackingNumber" ? this.state.orderDirectionShipments : "asc") : (this.state.valueToOrderByLabels === "trackingNumber" ? this.state.orderDirectionLabels : "asc")}
                                onClick={this.createSortHandler("trackingNumber", isPackedTable)}
                                >
                                    <p className={styles.cardTotalHeader}>
                                        Tracking #
                                    </p>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align={'left'} className='cellPadding table-cell' key="trackingNumber" style={{maxWidth:'25%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                                <TableSortLabel
                                    active={isPackedTable ? (this.state.valueToOrderByShipments === "product") : (this.state.valueToOrderByLabels === "product")}
                                    direction={isPackedTable ? (this.state.valueToOrderByShipments === "product" ? this.state.orderDirectionShipments : "asc") : (this.state.valueToOrderByLabels === "product" ? this.state.orderDirectionLabels : "asc")}
                                    onClick={this.createSortHandler("product", isPackedTable)}
                                >
                                    <p className={styles.cardTotalHeader}>
                                        Product
                                    </p>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align={'middle'} className='cellPadding table-cell' key="trackingNumber" style={{minWidth: '75px',maxWidth:'25%'}}>
                                <TableSortLabel
                                    active={this.state.valueToOrderByShipments === "isPrime"}
                                >
                                    <p className={styles.cardTotalHeader}>
                                        Prime
                                    </p>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align={'right'} className='cellPadding table-cell' key="timestamp" style={{maxWidth:'25%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                                <TableSortLabel
                                    active={isPackedTable ? (this.state.valueToOrderByShipments === "timestamp") : (this.state.valueToOrderByLabels === "timestamp")}
                                    direction={isPackedTable ? (this.state.valueToOrderByShipments === "timestamp" ? this.state.orderDirectionShipments : "asc") : (this.state.valueToOrderByLabels === "timestamp" ? this.state.orderDirectionLabels : "asc")}
                                    onClick={this.createSortHandler("timestamp", isPackedTable)}
                                >
                                    <p className={styles.cardTotalHeader}>
                                        {isPackedTable ? "Scan Time" : "Print Time"}
                                    </p>
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.stableSort(missingLabels, isPackedTable ?
                            this.getComparator(this.state.orderDirectionShipments, this.state.valueToOrderByShipments) :
                            this.getComparator(this.state.orderDirectionLabels, this.state.valueToOrderByLabels))?.map((scan, index) => (
                            <TableRow hover key={index}>
                                <TableCell align={'middle'} style={{maxWidth: '25%'}} className='cellPadding'>
                                    <p className={styles.cardCellItems}>
                                        {scan.trackingNumber}
                                    </p>
                                </TableCell>
                                <TableCell align={'middle'} style={{maxWidth: '25%'}} className='cellPadding'>
                                    <p className={styles.cardCellItems}>
                                        {scan.product ? scan.product : ''}
                                    </p>
                                </TableCell>
                                <TableCell align={'middle'} style={{minWidth: '75px', maxWidth:'25%'}} className='cellPadding'>
                                    <p className={styles.cardCellItems}>
                                        {scan.isPrime ?
                                            <img src={PrimeIcon} style={{
                                                marginRight: 5,
                                                height: 16,
                                                width: 50,
                                            }}/>
                                            : null}
                                    </p>
                                </TableCell>
                                <TableCell align={'middle'} style={{maxWidth: '25%'}} className='cellPadding'>
                                    <p className={styles.cardCellItems}>
                                        {scan.pending ? scan.timestamp.split("T")[0] : this.formatTimestamp(scan.timestamp)}
                                    </p>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )

    handleCollapse  = (report) => {
        let tempReportData = this.state.reportData
        let index = tempReportData.findIndex(obj => obj.productCode == report.productCode)
        if (index !== -1) {
            tempReportData[index].windowExpand = !tempReportData[index].windowExpand
            this.setState({
                reportData: tempReportData
            })
        }
    }

    expandAllReport = () => {
        let tempReportData = this.state.reportData
        tempReportData.map(report => report.windowExpand = !this.state.expandAll)
        this.setState({
            reportData: tempReportData,
            expandAll: !this.state.expandAll
        })
    }

    formatTimestamp = (timestamp) => {
        return timestamp?.split('T')[1].slice(0, -1)
    }

    render() {
        return (
            <>

                {this.props.dateSelectRow()}

                <div className={styles.dashboardSummary}>
                    <div className="missing-labels-container-left">
                        <Typography variant="h6" component="div" gutterBottom className={styles.header}>
                            {MISSING_SCANNED_TITLE}
                            <hr className={styles.underline} />
                        </Typography>
                        {this.missingLabelsTable(this.state.missingScanned, false)}
                    </div>
                    <div className="missing-labels-container-right">
                        <Typography variant="h6" component="div" gutterBottom className={styles.header}>
                            {MISSING_PACKED_TITLE}
                            <hr className={styles.underline} />
                        </Typography>
                        {this.missingLabelsTable(this.state.missingPacked, true)}
                    </div>
                </div>

                <div>
                    <div className="reports-table-container">
                        <Typography variant="h6" component="div" gutterBottom className={styles.header} style={{display:"flex"}}>
                            {REPORT_TITLE}
                            <FormControlLabel
                                control={<Switch checked={this.state.expandAll} onChange={this.expandAllReport} />}
                                label={EXPAND_ALL}
                                style={{marginLeft:"10px"}}
                            />
                            <hr className={styles.underline} />
                        </Typography>
                        <div className="missing-labels-table-content">
                            <TableContainer className="missing-labels-table" style={{ overflow: 'scroll' }}>
                                <Table size={"medium"} stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className='cellPadding' key="productCode">
                                                <TableSortLabel
                                                    active={(this.state.valueToOrderByReport === "productCode")}
                                                    direction={this.state.valueToOrderByReport === "productCode" ? this.state.orderDirectionReport : "asc"}
                                                    onClick={this.createSortHandler("productCode", "-1")}
                                                >
                                                    <p className={styles.cardTotalHeader}>
                                                        Product
                                                    </p>
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell className='cellPadding' key="printedQty">
                                                <TableSortLabel
                                                    active={(this.state.valueToOrderByReport === "printedQty")}
                                                    direction={this.state.valueToOrderByReport === "printedQty" ? this.state.orderDirectionReport : "asc"}
                                                    onClick={this.createSortHandler("printedQty", "-1")}
                                                >
                                                    <p className={styles.cardTotalHeader}>
                                                        Printed
                                                    </p>
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell className='cellPadding' key="packedQty">
                                                <TableSortLabel
                                                    active={(this.state.valueToOrderByReport === "packedQty")}
                                                    direction={this.state.valueToOrderByReport === "packedQty" ? this.state.orderDirectionReport : "asc"}
                                                    onClick={this.createSortHandler("packedQty", "-1")}
                                                >
                                                    <p className={styles.cardTotalHeader}>
                                                        Packed
                                                    </p>
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell className='cellPadding' key="pendingQty">
                                                <TableSortLabel
                                                    active={(this.state.valueToOrderByReport === "pendingQty")}
                                                    direction={this.state.valueToOrderByReport === "pendingQty" ? this.state.orderDirectionReport : "asc"}
                                                    onClick={this.createSortHandler("pendingQty", "-1")}
                                                >
                                                    <p className={styles.cardTotalHeader}>
                                                        Not Packed
                                                    </p>
                                                </TableSortLabel>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.stableSort(this.state.reportData, this.getComparator(this.state.orderDirectionReport, this.state.valueToOrderByReport))?.map((report, index) => (
                                            <>
                                                <TableRow hover key={index}>
                                                    <TableCell className='cellPadding' style={{display:"flex"}}>
                                                        <IconButton onClick={() => this.handleCollapse(report)}>
                                                            {report.windowExpand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                        </IconButton>
                                                        <p className={styles.cardCellItems}>
                                                            {report.productCode}
                                                        </p>
                                                    </TableCell>
                                                    <TableCell className='cellPadding'>
                                                        <p className={styles.cardCellItems}>
                                                            {report.printedQty}
                                                        </p>
                                                    </TableCell>
                                                    <TableCell className='cellPadding'>
                                                        <p className={styles.cardCellItems}>
                                                            {report.packedQty}
                                                        </p>
                                                    </TableCell>
                                                    <TableCell className='cellPadding'>
                                                        <p className={styles.cardCellItems}>
                                                            {report.pendingQty}
                                                        </p>
                                                    </TableCell>
                                                </TableRow>
                                                {report.windowExpand && (
                                                    <React.Fragment>
                                                        {(report.primePrintedQty >0 ||
                                                                report.primePrintedQty > 0 ||
                                                                report.primePendingQty > 0) &&
                                                            <TableRow>
                                                            <TableCell className='cellPadding' >
                                                                <img src={PrimeIcon} style={{
                                                                    marginLeft: 20,
                                                                    marginRight: 5,
                                                                    height: 16,
                                                                    width: 50,
                                                                }}/>
                                                            </TableCell>
                                                            <TableCell className='cellPadding'>
                                                                <p className={styles.cardCellItems}>
                                                                    {report.primePrintedQty}
                                                                </p>
                                                            </TableCell>
                                                            <TableCell className='cellPadding'>
                                                                <p className={styles.cardCellItems}>
                                                                    {report.primePackedQty}
                                                                </p>
                                                            </TableCell>
                                                            <TableCell className='cellPadding'>
                                                                <p className={styles.cardCellItems}>
                                                                    {report.primePendingQty}
                                                                </p>
                                                            </TableCell>
                                                        </TableRow>
                                                        }
                                                        {(report.printedQty - report.primePrintedQty >0 ||
                                                            report.packedQty - report.primePackedQty > 0 ||
                                                            report.pendingQty - report.primePendingQty > 0) &&
                                                            <TableRow>
                                                            <TableCell></TableCell>
                                                            <TableCell className='cellPadding'>
                                                                <p className={styles.cardCellItems}>
                                                                    {report.printedQty - report.primePrintedQty}
                                                                </p>
                                                            </TableCell>
                                                            <TableCell className='cellPadding'>
                                                                <p className={styles.cardCellItems}>
                                                                    {report.packedQty - report.primePackedQty}
                                                                </p>
                                                            </TableCell>
                                                            <TableCell className='cellPadding'>
                                                                <p className={styles.cardCellItems}>
                                                                    {report.pendingQty - report.primePendingQty}
                                                                </p>
                                                            </TableCell>
                                                        </TableRow>
                                                        }
                                                    </React.Fragment>
                                                )}
                                            </>
                                            ))}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow hover>
                                            <TableCell className='cellPadding'>
                                                <p className={styles.cardCellItems}>
                                                    Total
                                                </p>
                                            </TableCell>
                                            <TableCell className='cellPadding'>
                                                <p className={styles.cardCellItems}>
                                                    {this.addItUp( "printedQty")}
                                                </p>
                                            </TableCell>
                                            <TableCell className='cellPadding'>
                                                <p className={styles.cardCellItems}>
                                                    {this.addItUp( "packedQty")}
                                                </p>
                                            </TableCell>
                                            <TableCell className='cellPadding'>
                                                <p className={styles.cardCellItems}>
                                                    {this.addItUp( "pendingQty")}
                                                </p>
                                            </TableCell>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </div>


            </>
        )
    }
}

DashboardSummary.defaultProps = {
    scannedLabels: [],
    printedLabels: [],
    packedLabels: [],
    pendingPackedLabels: [],
    unpackedLabels: []
}

export default DashboardSummary;