import {
    CREATE_TICKET, LIST_ISSUE_SPECIFIC_ACTIONS, LIST_ISSUE_SPECIFIC_TASKS,
    LIST_TICKET_ISSUES,
    LIST_TICKETS, PREVIEW_TICKET_FILE, FETCH_ORDER_INFO, STORE_ORDER_INFO_FETCH, STORE_TICKET_ACTION_TASKS,
    STORE_TICKET_ACTIONS, STORE_TICKET_ISSUES,
    STORE_TICKETS, STORE_UPDATED_TICKET, STORE_UPDATED_TICKET_WITH_PREVIEW,
    UPDATE_TICKET, DELETE_TICKET, LIST_STATUS, STORE_STATUS
} from "../../constants/tickets";

export const listTickets = (data) => ({
    type: LIST_TICKETS,
    data
})

export const storeTickets = (tickets) => ({
    type: STORE_TICKETS,
    tickets
})

export const createTicket = (newTicket, reset) => ({
    type: CREATE_TICKET,
    newTicket,
    reset
});

export const storeUpdatedTicket = (updatedTicket) => ({
    type: STORE_UPDATED_TICKET,
    updatedTicket
})

export const updateTicket = (updatedTicket) => ({
    type: UPDATE_TICKET,
    updatedTicket
})

export const deleteTicket = (ticketId, callback) => ({
    type: DELETE_TICKET,
    ticketId,
    callback
})

export const storeTicketActions = (ticketActions) => ({
    type: STORE_TICKET_ACTIONS,
    ticketActions
})

export const listStatus = () => ({
    type: LIST_STATUS
})

export const storeStatus = (status) => ({
    type: STORE_STATUS,
    status
})

export const listTicketIssues = () => ({
    type: LIST_TICKET_ISSUES
})

export const storeTicketIssues = (ticketIssues) => ({
    type: STORE_TICKET_ISSUES,
    ticketIssues
})

export const storeTicketActionTasks = (ticketActionTasks) => ({
    type: STORE_TICKET_ACTION_TASKS,
    ticketActionTasks
})



export const listIssueSpecificActions = (ticketIssueId) => ({
    type: LIST_ISSUE_SPECIFIC_ACTIONS,
    ticketIssueId
});

export const listIssueSpecificTasks = (ticketIssueId) => ({
    type: LIST_ISSUE_SPECIFIC_TASKS,
    ticketIssueId
});



export const previewTicketFile = (previewRequest) => ({
    type: PREVIEW_TICKET_FILE,
    previewRequest
});

export const storeUpdatedTicketWithPreview = (updatedTicket)=>({
    type: STORE_UPDATED_TICKET_WITH_PREVIEW,
    updatedTicket
})

export const fetchOrderInfo = (orderInfo, orderId, originalTrackingId) => ({
    type: FETCH_ORDER_INFO,
    orderInfo,
    orderId,
    originalTrackingId
})

export const storeOrderInfoFetch = (orderInfoFetch) => ({
    type: STORE_ORDER_INFO_FETCH,
    orderInfoFetch
})