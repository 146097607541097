

//toolbar
export const SHIPMENTS_TOOLBAR_TITLE = "Shipments"
export const DELETE_SHIPMENTS = "Delete Shipments"
export const UPDATE_SHIPMENTS = "Update Shipments"
export const RECREATE_SHIPMENTS = "Recreate Shipments"
export const BACK_TO_MENU_TITLE = "Back to Menu"
//table
export const DEFAULT_ROWS_PER_PAGE = 50;
export const CONTROL_PANEL_TABLE_HEAD_CELLS = [
    // { id: 'shipmentId', center: true, disablePadding: false, label: 'shipmentId'},
    { id: 'orderId', center: true, disablePadding: false, label: 'OrderId' },
    { id: 'orderStatus', center: true, disablePadding: false, label: 'Order Status' },
]
export const ROWS_PER_PAGE_LABEL = "Shipments per page"
export const DEFAULT_SORT_BY_HEAD_CELL = "shipmentId"

//feedback
export const PLEASE_SELECT_SHIPMENTS = "Please select shipments"
export const PLEASE_SELECT_STATUS = "Please select status"
export const DELETE_SUCCESS = "Delete success"
export const DELETE_FAIL = "Delete fail"
export const UPDATE_SUCCESS = "Update success"
export const UPDATE_FAIL = "Update fail"
export const RECREATE_SUCCESS = "Recreate success"
export const RECREATE_FAIL = "Recreate fail"


