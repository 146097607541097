import {
    LIST_CLAIMS,
    STORE_CLAIMS,
    CREATE_CLAIM,
    DELETE_CLAIM,
    UPDATE_CLAIM,
    PREVIEW_CLAIM_FILE,
    STORE_CLAIMS_FILE,
    DELETE_CLAIM_FILE,
    STORE_UPDATED_CLAIM,
    STORE_CREATED_CLAIM,
    STORE_DELETED_CLAIM
} from "../../constants/claims";

export const listClaims = () => ({
    type: LIST_CLAIMS
})

export const storeClaims = (claims) => ({
    type: STORE_CLAIMS,
    claims
})

export const storeUpdatedClaimFile = (data) => ({
    type: STORE_CLAIMS_FILE,
    data
})

export const createClaim = (claim, refresh) => ({
    type: CREATE_CLAIM,
    claim,
    refresh
})

export const updateClaim = (claim, refresh) => ({
    type: UPDATE_CLAIM,
    claim,
    refresh
})

export const deleteClaim = (claimId) => ({
    type: DELETE_CLAIM,
    claimId
})

export const previewClaimFile = (previewRequest, refresh) => ({
    type: PREVIEW_CLAIM_FILE,
    previewRequest,
    refresh
})

export const deleteClaimFile = (deleteRequest, refresh) => ({
    type: DELETE_CLAIM_FILE,
    deleteRequest,
    refresh
})

export const storeUpdatedClaim = (claim) => ({
    type: STORE_UPDATED_CLAIM,
    claim
})

export const storeCreatedClaim = (claim) => ({
    type: STORE_CREATED_CLAIM,
    claim
})

export const storeDeletedClaim = (claimId) => ({
    type: STORE_DELETED_CLAIM,
    claimId
})
