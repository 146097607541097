import React, {useState, useEffect, useRef} from 'react';

// material components
import {
    FormControl,
    FormHelperText,
    Select,
    MenuItem, DialogContent, TextField
} from "@material-ui/core";

// constants
import {
    AVAILABLE_CARRIERS,
    ADD_CARRIER,
    DEFAULT_SERVICE, BUY_SHIPPING_SETUP, SERVICE, STANDARD_SETUP, API, REST, WEB_SERVICES, apiOptions,
} from "./constants";
import { CARRIER_SERVICE_OPTIONS } from "../../automationrules/ConditionFilters/constants"
import styles from "./CreateCarrierDialog.module.css";
import PropTypes from "prop-types";
import DeleteDialog from "./DeleteDialog";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormLabel from "@material-ui/core/FormLabel";
import {FormControlLabel, Radio, RadioGroup} from "@mui/material";
import {FEDEX} from "../../shipments/constants";

function SelectCarrier(props) {

    // Use a ref to track if the component has been mounted
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            // Skip the effect on the first render
            isFirstRender.current = false;
        } else {
            // Call your function on subsequent updates
            props.handleAutocomplteChange("", "defaultService");
        }
    }, [props.parentState.carrier]);

    const filterCarrierService = () => {
        if (props.parentState && props.parentState.carrier && !(props.parentState.carrier === "Amazon")) {
            return CARRIER_SERVICE_OPTIONS.filter(
                service => service.startsWith(props.parentState.carrier)
            )
        } else {
            return CARRIER_SERVICE_OPTIONS
        }
    }

    const currency = ['CAD', 'USD']

    return (
        <div className={styles.rowSelects}>
            <div>
                <div className={styles.rowSpec}>
                    <Autocomplete
                        options={AVAILABLE_CARRIERS}
                        getOptionLabel={(carrier) => carrier}
                        name="carrier"
                        onChange={(e, value) => props.handleAutocomplteChange(value, "carrier")}
                        value={props.parentState.carrier}
                        className={styles.formInput}
                        disabled={props.isEdit}
                        error={props.parentState.error && (props.parentState.carrier === '' || props.parentState.carrier === undefined)}
                        blurOnSelect={true}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label="Carrier"
                                InputLabelProps={{shrink: true}}
                                variant="outlined"
                                name="carrier"
                            />
                        }
                    />
                    {/* <FormControl error={props.errors.carrier} required className={styles.formSelect}>
                        <FormHelperText>{ADD_CARRIER}</FormHelperText>
                        <Select
                            variant="outlined"
                            name="carrier"
                            onChange={props.handleChange}
                            value={props.parentState.carrier}
                            className={styles.formInput}
                            disabled={props.isEdit}
                            error={props.parentState.error && (props.parentState.carrier === '' || props.parentState.carrier === undefined)}
                        >
                            {AVAILABLE_CARRIERS.map(carrier => (
                                <MenuItem value={carrier}>{carrier}</MenuItem>
                            ))
                            }
                        </Select>
                    </FormControl> */}

                    {/* <FormControl error={props.errors.carrier} required className={styles.formSelect}>
                        <FormHelperText>{DEFAULT_SERVICE}</FormHelperText>
                        <Select
                            variant="outlined"
                            name="defaultService"
                            onChange={props.handleChange}
                            value={props.parentState.defaultService}
                            className={styles.formInput}
                        >
                            {filterCarrierService().map(service => (
                                <MenuItem value={service}>{service}</MenuItem>
                            ))
                            }
                        </Select>
                    </FormControl> */}
                    <Autocomplete
                        options={filterCarrierService()}
                        getOptionLabel={(service) => service}
                        name="defaultService"
                        onChange={(e, value) => {
                            props.handleAutocomplteChange(value, "defaultService")
                        }}
                        value={props.parentState.defaultService}
                        className={styles.formInput}
                        blurOnSelect={true}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label="Default Service"
                                InputLabelProps={{shrink: true}}
                                variant="outlined"
                                name="defaultService"
                            />
                        }
                    />
                </div>
                <div className={styles.rowSpec}>
                    <Autocomplete
                        options={currency}
                        getOptionLabel={(currency) => currency}
                        name="currencyCode"
                        onChange={(e, value) => {
                            props.handleAutocomplteChange(value, "currencyCode")
                        }}
                        value={props.parentState.currencyCode}
                        className={styles.formInput}
                        blurOnSelect={true}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label="Currency Code"
                                InputLabelProps={{shrink: true}}
                                variant="outlined"
                                name="currencyCode"
                            />
                        }
                    />

                    <TextField
                        className={styles.formInput}
                        variant='outlined'
                        label="Net Terms"
                        InputLabelProps={{shrink: true}}
                        name='netTerm'
                        value={props.parentState.netTerm}
                        onChange={props.handleNumberChange}
                    />
                </div>
                {props.parentState.carrier === FEDEX &&
                    <div className={styles.rowSpec}>
                        <Autocomplete
                            options={apiOptions}
                            getOptionLabel={(option) => option} // Extracts label from each option
                            name="apiType"
                            onChange={(e, value) => {
                                props.handleAutocomplteChange(value, "apiType"); // Handles selection changes
                            }}
                            value={props.parentState.apiType} // Controls the selected value
                            className={styles.formInput}
                            blurOnSelect
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="API Type"
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    name="apiType"
                                />
                            )}
                        />
                        <div className={styles.rowSpec}/>
                    </div>
                }

            </div>
        </div>
    )
}

SelectCarrier.propTypes = {
    handleChange: PropTypes.func,
    parentState: PropTypes.object,
    errors: PropTypes.array,
    isEdit: PropTypes.bool
}

SelectCarrier.defaultProps = {
    handleChange: () => { },
    parentState: {},
    errors: [],
    isEdit: false
}

export default SelectCarrier;