import React from "react";

// material components
import {IconButton, TextField} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

// custom components

// constants
import {COMMENTS_TABLE_TITLE} from "../returns/constants";

// styling
import './CommentHistoryTable.css'
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import green from "@material-ui/core/colors/green";
import TableBody from "@material-ui/core/TableBody";
import RemoveIcon from "@material-ui/icons/Remove";
import Divider from "@material-ui/core/Divider";
import PropTypes from "prop-types";
import GlobalTableHead from "./Table/GlobalTableHead";
import {COMPLETED_STATUS} from "../tickets/constants";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";

class CommentHistoryTable extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            numRows: 0,
            comments: this.props.comments,
            addingEntry: false,
            commentSubmitted: false
        }
    }


    render() {
        return (
            <div className='comments-container'>
                <Table className='comment-table'>
                    <TableHead>
                        <Typography variant='h5'>
                            {COMMENTS_TABLE_TITLE}
                        </Typography>
                        <TableRow>
                            <TableCell className='date-user-heading-cell'/>
                            <TableCell className='comment-heading-cell'/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.comments.map((entry, index)=>{
                            return (
                                <TableRow key={index}>
                                    <TableCell className='comment-right-border no-text-wrap no-bottom-border'>
                                        {entry.date.split('T')[0]} <br/>{" (" + entry.user + ")"}
                                    </TableCell>
                                    <TableCell className={(index +1 === this.state.comments.length && this.props.disabled) ? 'comment-right-border comment-bottom-border': 'comment-right-border'}>
                                        {entry.comment}
                                    </TableCell>
                                </TableRow>
                            )
                        })}

                        {!this.props.disabled ?

                            !this.state.addingEntry && !this.state.commentSubmitted?
                                <TableRow>
                                    <TableCell className='comment-right-border no-bottom-border'>
                                        <div className='add-delete-cell'>
                                            <IconButton onClick={()=>this.setState({addingEntry:true})}>
                                                <AddCircleIcon
                                                    fontSize="small"
                                                    color='secondary'
                                                />
                                            </IconButton>
                                        </div>
                                    </TableCell>

                                    <TableCell className='comment-right-border comment-bottom-border'/>
                                </TableRow>

                                :

                                <TableRow>
                                    <TableCell className='comment-right-border no-bottom-border'>
                                        <div className='add-delete-cell'>
                                            <IconButton onClick={()=>{
                                                this.props.clearComment()
                                                this.setState({
                                                    addingEntry:false
                                                })
                                            }
                                                }>
                                                <RemoveCircleIcon
                                                    fontSize="small"
                                                    color="secondary"
                                                />
                                            </IconButton>
                                        </div>
                                    </TableCell>
                                    <TableCell className='comment-right-border comment-bottom-border'>
                                        <div>
                                            <TextField
                                                className='comments-field'
                                                variant="outlined"
                                                multiline
                                                name="userComment"
                                                onChange={this.props.handleCommentUpdate}
                                                label="Enter your comment"
                                            />
                                        </div>

                                    </TableCell>
                                </TableRow>

                        :
                            null
                        }


                    </TableBody>


                </Table>
            </div>

        );
    }
}

CommentHistoryTable.propTypes = {
    disabled: PropTypes.bool,
    comments: PropTypes.array,
    handleCommentUpdate: PropTypes.func,
    currentUser: PropTypes.object,
    clearComment: PropTypes.func
}

CommentHistoryTable.defaultProps = {
    disabled: true,
    comments: [],
    handleCommentUpdate: ()=>{},
    currentUser: {},
    clearComment: ()=>{}
}

export default CommentHistoryTable;