import React from "react";

import { protectedRoutes, routes } from "../../routes";
import MenuItem from "./MenuItem";
import "./LeftMenu.css";
import MenuItems from "./MenuItems";
import PropTypes from "prop-types";
import InventoryToolbar from "../inventory/InventoryToolbar";

class LeftMenu extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      width: "0", // used to hide a vertical scroll bar on the left menu (scroll bar must exist, even hidden, for menu to be scrollable)
    }
    this.leftMenuRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.leftMenuRef) {
      this.setState({
        width: this.leftMenuRef.current.parentElement.offsetWidth + this.leftMenuRef.current.offsetWidth - this.leftMenuRef.current.clientWidth + "px",
      })
    }
  }

  render() {
    return (
      <div className='leftMenu' style={{overflowY: "hidden", overflowX: "hidden"}}>
        <div ref={this.leftMenuRef} className='leftMenu' style={{top: '75px', paddingBottom: '100px', width: this.state.width, overflowX: "hidden"}}>
          <MenuItems routes={this.props.routes} userRoutes={this.props.userRoutes} isDrawer={this.props.isDrawer} closeDrawer={this.props.closeDrawer} />
        </div>
      </div>
    );
  }
}

LeftMenu.propTypes = {
  routes: PropTypes.array
}

LeftMenu.defaultProps = {
  routes: []
}

export default LeftMenu;
