import {
    call,
    put,
    takeLatest,
} from 'redux-saga/effects';
import axios from 'axios';

import {
    storeMarketplaceCompanies,
    storeMarketplaceCountries, storeMarketplaceNamesByCompany,
    storeOrderResponse,
    storeOrders
} from "../../actions/admin"

// constants for endpoints
import {
    ADMIN,
} from "../../../components/global/Server/endpoints";

// constants for actions
import {
    REFRESH_ORDER_ITEMS,
    LIST_ORDERS,
    GET_ORDERS_BY_ORDER_ID,
    GET_MARKETPLACE_COMPANIES,
    GET_MARKETPLACE_NAMES_BY_COMPANY,
} from "../../constants/admin";
import {showErrorMessage} from "../../actions/global";
import {SERVER_FAILED_ERROR_MESSAGE} from "../../../pages/Dashboard/DashboardFinal/constants";

// secondary actions

const getHeaders = () => {
    const token = localStorage.getItem('token');
    return {
        "Authorization": `Bearer ${token}`
    }
}

const postRequest = (endpoint, data) => {
    return axios.post(endpoint, data, {
        headers: getHeaders()
    })
        .then(response => response)
}

function getOrders() {
    return axios.get(ADMIN.LIST_ORDERS, {
        headers: getHeaders()
    })
        .then(response => response);
}

function* listOrders() {
    const response = yield call(getOrders);
    if (response.status === 200) {
        yield put(storeOrders(response.data));
    }
}

function* refreshOrderItems(action) {
    let orderIds = []
    let success = false
    try {
        let requestOrderIds = []
        action.orderIds.forEach(orderId => {
            requestOrderIds.push({"orderId": orderId})
        });
        const response = yield call(() => postRequest(ADMIN.REFRESH_ORDER_ITEMS, {"orderIds": requestOrderIds}));
        if (response.status === 200) {
            success = response && response.data && response.data.success ? true : false
            orderIds = response.data.orderIds
            yield call(() => listOrders())
        }
    } catch (error) {
        success = false
    }
    
    action.setState(success, orderIds)
    
}

function* getOrdersByOrderId(action) {
    const response = yield call(() => postRequest(ADMIN.FETCH_ORDERS_BY_ORDER_ID, action.data))
    if (response.data.success) {
        const responseTypes = {
            types: {
                invalidId: response.data.invalidId,
                existing: response.data.existingId,
                invalidStatus: response.data.invalidStatus,
                createdShipments: response.data.createdShipments,
                updatedShipments: response.data.updatedShipments,
                skippedShipments: response.data.skippedShipments
            }
        };
        yield(put(storeOrderResponse(responseTypes)))
    } else {
        yield put(showErrorMessage(response.data?.message ? response.data.message : SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* getCompanies() {
    const response = yield call(() => postRequest(ADMIN.GET_MARKETPLACE_COMPANIES))
    yield(put(storeMarketplaceCompanies(response.data[0].companies)))
}

function* getMarketplaceNamesByCompany(action) {
    let request = {
        company: action.company
    }
    const response = yield call(() => postRequest(ADMIN.GET_MARKETPLACE_NAMES_BY_COMPANY, request))
    let data = {
        company: action.company,
        marketplaceNames: response.data[0].marketplaceNames
    }
    yield(put(storeMarketplaceNamesByCompany(data)))
}

export default function* adminSagas() {
    yield takeLatest(REFRESH_ORDER_ITEMS, refreshOrderItems);
    yield takeLatest(LIST_ORDERS, listOrders);
    yield takeLatest(GET_ORDERS_BY_ORDER_ID, getOrdersByOrderId);
    yield takeLatest(GET_MARKETPLACE_COMPANIES, getCompanies);
    yield takeLatest(GET_MARKETPLACE_NAMES_BY_COMPANY, getMarketplaceNamesByCompany)
}
