import React from "react";

import withShipment from "../../../withShipment";

import './ManageArchiveLabel.css';
import {TableCell, TableHead, TableBody, Toolbar, Typography} from "@material-ui/core";
import {MuiPickersUtilsProvider, KeyboardDatePicker} from "@material-ui/pickers";
import Button from '@mui/material/Button';
import DateFnsUtils from "@date-io/date-fns";
import TableRow from "@material-ui/core/TableRow";
import PropTypes from "prop-types";
import {archiveLabels, storeArchivedLabelsReturned} from "../../../redux/actions/settings";
import {
    archivedLabelsReturnedSelector,
    archivedLabelTrackingNumbersSelector
} from "../../../redux/selectors/settings";
import TableContainer from "@material-ui/core/TableContainer";
import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";

const ARCHIVE = 'Archive';

class ManageArchiveLabel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fromDate: '', // iso date format only
            toDate: '', // iso date format only
            orderIds: [],
            disableButtons: false
        }
    }

    componentDidMount() {
        this.setState({
            fromDate: (new Date()).toISOString(),
            toDate: (new Date()).toISOString()
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.archivedLabelsReturned) {
            this.setState({
                disableButtons: false
            })
            this.props.storeArchivedLabelsReturned(false)
        }
    }

    archiveLabelsByDate = () => {
        this.props.archiveLabels(this.state.fromDate, this.state.toDate, this.state.orderIds)
     }

    isDisabledArchiveLabelsByDateButton = (fromDate, toDate, disableButtons) => {
        if (disableButtons)
            return true

        if (fromDate === '' || toDate === '')
            return true

        if (fromDate.localeCompare(toDate) > 0)
            return true

        return false
    }

    archiveLabelsByDateButton = () => {
        return <div className={"button"}>
            <Button
                variant='contained'
                disabled={this.isDisabledArchiveLabelsByDateButton(this.state.fromDate, this.state.toDate, this.state.disableButtons)}
                onClick={(event) => {

                    this.setState({
                        disableButtons: true
                    })
                    this.archiveLabelsByDate()
                }}
                color='primary'
            >
                {ARCHIVE}
            </Button>
        </div>
    }

    dateBox = (updateDate, dateValue, dateName) => {
        return <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                className={"datePicker"}

                inputVariant="outlined"
                style={{}}

                name={dateName}
                label={dateName}
                format="yyyy/MM/dd"
                value={dateValue}
                onChange={newDate =>
                    updateDate(newDate)
                }
            />
        </MuiPickersUtilsProvider>
    }

    setFromDate = (newDate) => {
        let dateString = (new Date(newDate)).toISOString()
        this.setState({
            fromDate: dateString
        })
    }

    setToDate = (newDate) => {
        let dateString = (new Date(newDate)).toISOString()
        this.setState({
            toDate: dateString
        })
    }

    dateSelectRow = () => {
        return <div className={"margin20px"}>
            <div className={"container-row"}>
                {this.dateBox(this.setFromDate, this.state.fromDate, "From")}
                <div className={"horizMargin20px"}/>
                {this.dateBox(this.setToDate, this.state.toDate, "To")}
                <div className={"horizMargin20px"}/>
                {this.archiveLabelsByDateButton()}
                <div className={"spacer"}/>
            </div>
        </div>
    }

    archivedLabelsList = () => {
        return <div className={"margin20px"}>
            <TableContainer>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Archived Label Tracking Numbers
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.props.archivedLabelTrackingNumbers ? this.props.archivedLabelTrackingNumbers.map((entry, index) => {
                        return <TableRow key={index}>
                            <TableCell>
                                {entry}
                            </TableCell>
                        </TableRow>
                    }):null}
                </TableBody>
            </TableContainer>
        </div>
    }

    render() {
        return (
            <React.Fragment>

                <TopToolbar
                    pageName={"Archive Labels"}
                />

                {this.dateSelectRow()}

                {this.archivedLabelsList()}

            </React.Fragment>
        )
    }
}

ManageArchiveLabel.defaultProps = {
    archivedLabelTrackingNumbers: [],
}

ManageArchiveLabel.propTypes = {
    archivedLabelTrackingNumbers: PropTypes.array,
    archivedLabelsReturned: PropTypes.bool,
}

const mapStateToProps = (state) => ({
    archivedLabelTrackingNumbers: archivedLabelTrackingNumbersSelector(state),
    archivedLabelsReturned: archivedLabelsReturnedSelector(state),
})

const actionCreators = {
    archiveLabels,
    storeArchivedLabelsReturned
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, ManageArchiveLabel);