import {
    FETCH_LINKED_USERS,
    STORE_LINKED_USERS,
    EDIT_USER,
    DELETE_USER,
    GET_ROLES,
    STORE_ROLES
} from "../../constants/userManagement";

export const fetchLinkedUsers = (username) => ({
    type: FETCH_LINKED_USERS,
    username,
});

export const storeLinkedUsers = (users) => ({
    type: STORE_LINKED_USERS,
    users,
});

export const editUser = (userInfo, updatedUserInfo, setState) => ({
    type: EDIT_USER,
    userInfo,
    updatedUserInfo,
    setState,
});

export const deleteUser = (userInfo, setState) => ({
    type: DELETE_USER,
    userInfo,
    setState,
})

export const getRoles = () => ({
    type: GET_ROLES
})

export const storeRoles = (roles) => ({
    type: STORE_ROLES,
    roles
})