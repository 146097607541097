import React from "react";
import PropTypes from "prop-types";

import { ROUTE_TYPE } from "../../routes/constants";
import { NavLink } from "react-router-dom";
import { List, ListItem, Divider } from "@material-ui/core";
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";
import MenuItem from "./MenuItem";

class MenuItems extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
    };
  }

  componentDidMount = () => {
    if (this.props.collapsable) {
      this.setState({
        collapse: this.props.collapsable,
      });
    }
  };

  getMenuItem = (item, index) => {
    switch (item.type) {
      case ROUTE_TYPE.DIVIDER:
        return <Divider key={`route-divider-${index}`}></Divider>;

      case ROUTE_TYPE.MENU_ITEM:
        const userRouteIds = this.props.userRoutes
        if (userRouteIds?.includes(item.id)) {
          return (
            <NavLink to={item.path} style={{ textDecoration: 'none' }}>
              <ListItem key={item.id} className='leftMenu-item left-menu-navigation' dense
                onClick={() => {
                  if (this.props.isDrawer) {
                    this.props.closeDrawer()
                  }
                }}>
                <MenuItem
                  className='leftMenu-item'
                  item={item}
                  depthPaddingLeft={this.props.depth * this.props.depthStep}

                />
              </ListItem>
            </NavLink>
          );
        } else {
          return null
        }
      case ROUTE_TYPE.SUB_MENU:
        if (this.props.userRoutes?.includes(item.id)) {
          return (
            <MenuItems
              key={item.id}
              routes={item.submenuItems}
              collapsable={true}
              depth={this.props.depth + 1}
              label={item.label}
              icon={item.icon}
              userRoutes={this.props.userRoutes}
              isDrawer={this.props.isDrawer}
              closeDrawer={this.props.closeDrawer}
            />
          );
        } else {
          return null
        }
      default:
        return <ListItem></ListItem>;
    }
  };

  toggleCollapse = () => {
    this.setState((prevState) => ({
      collapse: !prevState.collapse,
    }));
  };

  getCollapableItem = () => {
    return (
      <ListItem
        key={this.props.id}
        className='leftMenu-item'
        button
        dense
        hover={1}
        onClick={this.toggleCollapse}
      >
        <div className='leftMenu-item-content'>
          {/*{this.props && this.props.icon != null && (
              <this.props.icon className="leftMenu-item-icon" fontSize="small" />
          )}*/}
          <div className='leftMenu-item-text'>{this.props.label}</div>
        </div>
        {this.state.collapse ? <ExpandMoreIcon /> : <ExpandLessIcon />}
      </ListItem>
    );
  };

  render() {
    return (
      <List disablePadding disableGutters>
        {this.props.collapsable && this.getCollapableItem()}
        {!this.state.collapse &&
          this.props.routes.map((route, index) => {
            if (route.sidebar) {
              return this.getMenuItem(route, index);
            }
          })}
      </List>
    );
  }
}

MenuItems.defaultProps = {
  routes: [],
  collapsable: false,
  depth: 0,
  depthStep: 20,
};

MenuItems.propTypes = {
  routes: PropTypes.array.isRequired,
  collapsable: PropTypes.bool,
  depth: PropTypes.number,
  depthStep: PropTypes.number,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  icon: PropTypes.object,
};

export default MenuItems;
