
// icons
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import StoreIcon from '@material-ui/icons/Store';
import React from "react";

export const SETTINGS_TABS = [
    {
        id: 'users',
        label: "Manage Users",
        icon: <SupervisorAccountIcon />,
        permission: 'ROLE_ADMIN'
    },
    {
        id: 'carrier',
        label: "Carrier Accounts",
        icon: <RoomServiceIcon />,
        permission: 'ROLE_ADMIN'
    },
    {
        id: 'stores',
        label: "Stores",
        icon: <StoreIcon />,
        permission: 'ROLE_ADMIN'
    },
    {
        id: 'profile',
        label: "My Profile",
        icon: <AccountCircleIcon />,
        permission: 'ANY'
    }
];

export const ADMIN = 'ROLE_ADMIN';
export const ANY = 'ANY';
export const TOOLBAR_TITLE = 'Users';