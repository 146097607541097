import React from "react";
//components
import CustomTableHeader from "../../global/Table/CustomTableHeader";
import SearchableTable from "../../global/Search/SearchableTable";
import GlobalTableHead from "../../global/Table/GlobalTableHead";
import FeedbackBar from "../../feedbackBar/FeedbackBar";

//material ui
import {TableBody, Table, TableContainer, TableRow ,TableCell, IconButton, TextField, Tooltip, Button} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import Checkbox from "@material-ui/core/Checkbox";

//constants
import {ASC, DESC} from "../../global/constants";
import {
    CANCEL,
    SAVE,
    REMOVE_TAG_TITLE,
    ADD_TAG_TITLE,
    EDITING_TAG_TABLE_HEAD_CELLS,
} from "./constants";

import { SUCCESS, ERROR } from "../../shipments/constants";

import styles from "../ManageCarriers/CreateCarrierDialog.module.css";
import "./ManageTagsTable.css"
class EditingManageTagsTable extends SearchableTable {
    constructor(props) {
        super(props);
        this.state = {
            editingTags: this.getEditingTags(),
            searchValue: "",
            saved: false
        }
    }

    onSearchValueChange = (isSearch, searchValue) => {
        this.setState({
            isSearch: isSearch,
            searchValue: searchValue,
        });

        this.props.onSearchValueChange(isSearch, searchValue)
    }

    checkSave = () => {
        return (this.state.name == '' || this.state.phone == '' || this.state.addressLine1 == '' || this.state.city == '' || 
        this.state.state == '' || this.state.postalCode == '' || this.state.country == '' || this.state.email == '')
    }


    getEditingTags = ()=>{
        return this.props.getEditingTags ? this.props.getEditingTags : []
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render() {
        return (
            <Paper className='paper'>
                <CustomTableHeader
                    onSearchValueChange={(e) => this.onSearchValueChange(e.target.value.length > 0, e.target.value)}
                />
                <TableContainer>
                    <Table size='medium'>
                        <GlobalTableHead
                            isCreatePurchaseOrderTable={false}
                            isOrderTable={false}
                            numSelected={0}
                            headCells={EDITING_TAG_TABLE_HEAD_CELLS}
                        />
                        <TableBody>
                            {this.filterBySearch(this.props.editingTags).map((row, index) => {
                                const key = row.tagId
                                return(
                                    <TableRow>
                                        <TableCell className='tag-checkCircleBox'>
                                            {!row.isLocationTag?
                                            <   Tooltip placement= "left" title={REMOVE_TAG_TITLE}>
                                                <IconButton onClick={(e)=>this.props.removeEntry(row)}>
                                                    <RemoveCircleIcon
                                                        fontSize="small"
                                                        color='error'
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                            :null}
                                        </TableCell>
                                        <TableCell className='tag-inputBox' >
                                            <div className='tag-inputBox' >
                                                <TextField
                                                    disabled={row.isLocationTag}
                                                    variant="outlined"
                                                    name={row.tagId}
                                                    value={row.tagName}
                                                    onChange={(e)=>this.props.onChange(e, row)}
                                                    size="small"
                                                    error={this.props.checkError(row)}
                                                />
                                            </div>
                                        </TableCell>
                                        <TableCell className='tag-inputBox'>
                                            <div className='tag-inputBox'>
                                                <Checkbox
                                                    disabled={row.isLocationTag}
                                                    name={row.tagId}
                                                    checked={row.isPrintable}
                                                    onChange={(e)=>this.props.handleCheckboxChange(e, row)}
                                                />
                                            </div>
                                        </TableCell>
                                        <TableCell className="tag-endingBox"/>
                                    </TableRow>
                                )
                            })}
                            <TableRow>
                                <TableCell className='tag-checkCircleBox' >
                                    <Tooltip placement= "left" title={ADD_TAG_TITLE}>
                                        <IconButton onClick={() => this.props.addEntry()}>
                                            <AddCircleIcon
                                                fontSize="small"
                                                color='secondary'
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                                <TableCell className='tag-inputBox'></TableCell>
                                <TableCell className='tag-inputBox'></TableCell>
                                <TableCell className='warningBox'></TableCell>
                                <TableCell className="tag-endingBox"/>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className="createTagButtonBox">
                    <div className="createTagButton">
                    <Button onClick={this.props.handleCancel} color="secondary" variant="outlined">
                        {CANCEL}
                    </Button>
                    </div>

                    <div className="createTagButton">
                    <Button onClick={this.props.handleSave} color="primary" variant="contained" disabled={this.props.checkDisable()}>
                        {SAVE}
                    </Button>
                    </div>
                </div>
            </Paper>
        );
    }

}

export default EditingManageTagsTable