
// a two-step deletion button for use in a row in a table
// note that this is a <TableCell> and must be used in a <TableRow>
// width 200px

/*
  Required Props:
  rowId - an Id unique to this row of the table
  handleDelete(rowId) - a method to call to set this row as "the one being confirmed",
        will generally be linked to a state variable; see example below
  deleteId - a state variable for the row being confirmed for deletion
  setDeleteId(newDeleteId) - a method to handle deleting of the row

*/

/*
  Optional Props:
  noTableCellContainer - two step delete is displayed in a plain div instead
*/

/* example use:

    <TwoStepDelete
        rowId={row.carrierInvoiceId}
        handleDelete={(rowIdToDelete) => this.handleDelete(rowIdToDelete)}
        deleteId={this.state.deleteId}
        setDeleteId={(newDeleteId) => this.setState({deleteId: newDeleteId})}
    />

*/

// ----

import React from "react";

import withShipment from "../../../../withShipment";


import './TwoStepDelete.css';
import {IconButton} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import TableCell from "@material-ui/core/TableCell";
import PropTypes from "prop-types";


class TwoStepDelete extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    displayTwoStepDelete = () => {
        return <React.Fragment>
            {this.props.deleteId !== this.props.rowId ?
                <IconButton disabled={this.props.disabled} onClick={() => this.props.setDeleteId(this.props.rowId)}>
                    <DeleteIcon />
                </IconButton> : <div>
                    <Button onClick={() => this.props.setDeleteId("")}>
                        Cancel
                    </Button>
                    <Button onClick={() => this.props.handleDelete(this.props.rowId)} variant="contained" startIcon={<DeleteIcon />}
                            disabled={this.props.rowId !== this.props.deleteId}
                    >
                        Delete
                    </Button>
                </div>
            }
        </React.Fragment>
    }

    render() {
        return (
            <React.Fragment>

                {this.props.noTableCellContainer ?
                    <div className={"defaultTableCellStyle"}>
                        {this.displayTwoStepDelete()}
                    </div>
                    :
                    <TableCell className={"defaultTableCellStyle"}>
                        {this.displayTwoStepDelete()}
                    </TableCell>
                }

            </React.Fragment>
        )
    }

}

TwoStepDelete.defaultProps = {
    disabled: false
}

TwoStepDelete.propTypes = {
    rowId: PropTypes.string,
    handleDelete: PropTypes.func,
    deleteId: PropTypes.string,
    setDeleteId: PropTypes.func,
    disabled: PropTypes.bool,
}

const mapStateToProps = (state) => ({
})

const actionCreators = {
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, TwoStepDelete);