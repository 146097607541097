export const SHIPRUSH_TOOLBAR_TITLE = 'Import Shiprush Data';
export const CLAIMS_TOOLBAR_TITLE = 'Import Claims Data';
export const FTL_TOOLBAR_TITLE = 'Import FTL Data';
export const Process_Historical_TITLE = 'Process historical data'
export const SUBMIT = 'SUBMIT'
export const IMPORT = 'IMPORT'
export const IMPORT_REVERSE = 'IMPORT REVERSELY'
export const LIST = 'LIST'
export const SELECT_ALL = 'SELECT ALL'
export const HISTORICAL_DATA_IMPORT_OPTIONS = [
    "Shiprush Google Folder Path",
    "Shiprush Google Folder Name"
]

export const HISTORICAL_DATA_HEAD_CELLS = [
    { id: 'iconButton', center: false, disablePadding: false, label: '' },
    { id: 'process result', center: true, disablePadding: false, label: 'process result' },
]

export const SHIPRUSH = 'SHIPRUSH'
export const CLAIMS = 'CLAIMS'
export const FTL = 'FTL'


export const IMPORT_SHIPRUSH_DATA_TABLE =[
    { id: 'filename', text: 'filename' },
    { id: 'reImport', text: 're-import' },
    { id: 'skip existing data', text: 'skip existing data'}
]
