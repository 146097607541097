import {
    REFRESH_ORDER_ITEMS,
    LIST_ORDERS,
    STORE_ORDERS,
    GET_ORDERS_BY_ORDER_ID,
    STORE_ORDER_RESPONSE,
    GET_MARKETPLACE_COMPANIES,
    GET_MARKETPLACE_NAMES_BY_COMPANY,
    STORE_MARKETPLACE_COMPANIES, STORE_MARKETPLACE_NAMES_BY_COMPANY
} from "../../constants/admin";
import {STORE_MARKETPLACES} from "../../constants/settings";

export const refreshOrderItems = (orderIds, setState) => ({
    type: REFRESH_ORDER_ITEMS,
    orderIds,
    setState
})

export const listOrders = () => ({
    type: LIST_ORDERS,
})

export const storeOrders = (orders) => ({
    type: STORE_ORDERS,
    orders
})


export const getOrdersByOrderId = (data) => ({
    type: GET_ORDERS_BY_ORDER_ID,
    data
})

export const storeOrderResponse = (data) => ({
    type: STORE_ORDER_RESPONSE,
    data
})

export const getMarketplaceNamesByCompany = (company) => ({
    type: GET_MARKETPLACE_NAMES_BY_COMPANY,
    company
})

export const storeMarketplaceNamesByCompany = (data) => ({
    type: STORE_MARKETPLACE_NAMES_BY_COMPANY,
    data
})

export const getMarketplaceCompanies = () => ({
    type: GET_MARKETPLACE_COMPANIES,
})

export const storeMarketplaceCompanies = (data) => ({
    type: STORE_MARKETPLACE_COMPANIES,
    data
})