import {
    STORE_ALL_BOX_INVENTORY, STORE_ALL_DAMAGES, STORE_INVENTORY_SUMMARY_FOR_FTL,
    STORE_ALL_INVENTORY,
    STORE_ALL_INVENTORY_ADJUSTMENTS,
    STORE_ALL_PRODUCT_INVENTORY,
    STORE_INVENTORY_ADJUSTMENT_TYPES, STORE_INVENTORY_ALERTS, STORE_INVENTORY_ENTRIES,
    STORE_INVENTORY_RECEIVED, STORE_INVENTORY_TRANSFERRED,
    STORE_MANUFACTURERS, STORE_NEW_INVENTORY_ADJUSTMENT, STORE_NEW_INVENTORY_ENTRY, STORE_NEW_INVENTORY_TRANSFERRED,
    STORE_SHIPPED_INVENTORY, STORE_UPDATED_INVENTORY_ITEM
} from "../../constants/inventory";


const initialState = {
    allInventory: [],
    allInventoryAdjustments: [],
    availableLocations: [],
    inventoryAdjustmentTypes: [],
    inventoryReceived: [],
    shippedInventory: [],
    inventoryTransferred: [],

    inventoryEntries: [],
    boxesInventory: [],
    productsInventory: [],
    inventorySummaryForFTL: [],
}

// list adjustment type in sequence
const typePrecedence = [
    'Shipped',
    "VC Shipped",
    "FBA Shipped",
    "Adjustment",
    "Sample",
    "Damage Replacement",
    "Damage Return",
    "Damaged",
    "Salvaged",
    'FTL',
    'Received',
    'Returned',
    "Inventory Found",
    "Inventory Lost",
    'Initial Stock'
];


const updateList = (newItem, oldList) => {
    let newList = [];
    let exists = oldList.findIndex(item => item._id.type === newItem._id.type && item._id.dayOfYear === newItem._id.dayOfYear && item._id.year === newItem._id.year)
    if (exists === -1) {
        return oldList;
    } else {
        oldList.map((item, index) => {
            if (index === exists) {
                newList[index] = newItem;
            } else {
                newList[index] = item;
            }
        });
        return newList;
    }
};

const sortData = (list) => {
    // first pass through and convert to dates
    list.forEach((entry, index) => {
        if (entry._id.receiveDayOfYear && entry._id.receiveYear) {
            entry._id["receiveDate"] = new Date(entry._id.receiveYear, 0, entry._id.receiveDayOfYear)
        } else {
            entry._id["date"] = new Date(entry._id.year, 0, entry._id.dayOfYear);
        }
    });
    const getDate = (entry) => {
        return entry._id.receiveDate ? entry._id.receiveDate : entry._id.date
    }
    // originally sort by dates
    list.sort((a, b) => {
        return getDate(b) - getDate(a)
    })

    //separete by date
    let listsByDate = [[]]
    let listIndex = 0
    for (let i = 0; i < list.length; i++) {
        if (i == 0) {
            listsByDate[listIndex].push(list[i])
        } else {
            if (getDate(list[i]).toISOString() == getDate(list[i - 1]).toISOString()) {
                listsByDate[listIndex].push(list[i])
            } else {
                listIndex++
                listsByDate.push([list[i]])
            }
        }
    }
    //sort by type
    listsByDate.forEach(listByDate => {
        listByDate.sort((a, b) => {
            return typePrecedence.indexOf(a._id.type) - typePrecedence.indexOf(b._id.type)
        })
    })
    //group lists
    list = listsByDate.flat()

    return list;
}

const updateNewInventory = (updatedInventoryItem, oldList) => {
    let list = oldList;
    for (let j = 0; j < list.length; j++) {
        if (list[j].shipAddressId === updatedInventoryItem.shipAddressId && list[j].shortcode === updatedInventoryItem.shortcode) {
            list[j].threshold = updatedInventoryItem.threshold;
            break;
        }
    }
    return list;
}


export default (state = initialState, action) => {
    switch (action.type) {
        case STORE_ALL_INVENTORY:
            return {
                ...state,
                allInventory: action.allInventory
            };
        case STORE_INVENTORY_ADJUSTMENT_TYPES:
            return {
                ...state,
                inventoryAdjustmentTypes: action.inventoryAdjustmentTypes
            };
        case STORE_MANUFACTURERS:
            return {
                ...state,
                manufacturers: action.manufacturers
            };
        case STORE_INVENTORY_ALERTS:
            return {
                ...state,
                alerts: action.alerts
            };
        case STORE_NEW_INVENTORY_ADJUSTMENT:
            return {
                ...state,
                allInventoryAdjustments: updateList(action.inventoryAdjustment, state.allInventoryAdjustments)
            };
        case STORE_NEW_INVENTORY_TRANSFERRED:
            return {
                ...state,
                inventoryTransferred: updateList(action.inventoryTransferred, state.inventoryTransferred)
            };
        case STORE_INVENTORY_ENTRIES:
            return {
                ...state,
                inventoryEntries: sortData(action.inventoryEntries)
            }

        case STORE_NEW_INVENTORY_ENTRY:
            return {
                ...state,
                inventoryEntries: sortData(action.inventoryEntries)
            }
        case STORE_UPDATED_INVENTORY_ITEM:
            return {
                ...state,
                allInventory: updateNewInventory(action.updatedInventoryItem, state.allInventory)
            }
        case STORE_ALL_BOX_INVENTORY:
            action.boxes = action.boxes.map(productObj => {
                const { box, ...rest } = productObj;
                return { details: box, ...rest };
            });
            return {
                ...state,
                boxesInventory: action.boxes,
                allInventory: action.boxes
            }
        case STORE_INVENTORY_SUMMARY_FOR_FTL:
            return {
                ...state,
                inventorySummaryForFTL: action.data,
            }
        case STORE_ALL_PRODUCT_INVENTORY:
            action.products = action.products.map(productObj => {
                const { product, ...rest } = productObj;
                return { details: product, ...rest };
            });
            return {
                ...state,
                productsInventory: action.products,
                allInventory: action.products
            }
        default:
            return state;
    }
}