import React from "react";
import withShipment from "../../../withShipment";
import {
    Button, Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl, FormHelperText, IconButton,
    MenuItem,
    Select, TableBody,
    TextField, Tooltip
} from "@material-ui/core";

// material components
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

// constants
import {ORIGIN_CRITERION_OPTIONS, WEIGHT_UNITS} from "./constants"

// redux
import {
    getAllCategory, listProducts, getCountryCodes
} from "../../../redux/actions/settings";
import {categorySelector, countriesSelector} from "../../../redux/selectors/settings";
// styling
import PropTypes from "prop-types";
import CustomToolbar from "../../global/CustomToolbar";
import {
    MANAGE_PRODUCTS_HEAD_CELLS,
    PRODUCT_TOOLBAR_TITLE,
    SELECT_LOCATION_HEADER,
    SELECT_LOCATION_HELPER_TEXT
} from "./constants";
import Paper from "@material-ui/core/Paper";
import SearchableTable from "../../global/Search/SearchableTable";
import { ASC, CANCEL, SAVE } from "../../global/constants";
import SearchField from "../../global/Search/SearchField";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import { RETURNS_TABLE_HEAD_CELLS } from "../../returns/constants";
import GlobalTableHead from "../../global/Table/GlobalTableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import VisibilityIcon from "@material-ui/icons/Visibility";

import "./AddProductForm.css"
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { FETCH_UNSHIPPED_ALERTS_TIME } from "../../alerts/constants";
import {Autocomplete} from "@material-ui/lab";
import {fetchAlerts} from "../../../redux/actions/alerts";

class AddProductForm extends React.Component {

    constructor(props) {
        super(props);
        let countryMap = {
            "CA": "Canada",
            "US": "United States",
            "MX": "Mexico"
        };
        this.state = {
            shortcode: this.getValue('shortcode'),
            nominalSize: this.getValue('nominalSize'),
            actualSize: this.getValue('actualSize'),
            textDescription: this.getValue('textDescription'),
            manufacturerPartNum: this.getValue('manufacturerPartNum'),
            brand: this.getValue('brand'),
            boxQuantity: this.getValue('boxQuantity'),
            paleteQuantity: this.getValue('paleteQuantity'),
            hasPackagingDimensions: this.getValue('hasPackagingDimensions'),
            packageSize: this.getValue('packageSize'),
            productCode: this.getValue('productCode'),
            productId: this.getValue('productId'),
            locationIds: this.getValue('locations'),
            preLocationIds: this.getValue('preLocationIds'),
            existingShortcodes: this.getValue('existingShortcodes'),
            ignoredSuggestions: this.getValue('ignoredSuggestions'),
            selectedLocationsMap: {},
            selectedCategoryId: this.getValue('categoryId'),
            validating: false,
            oldShortcode: this.getValue('shortcode'),
            weight: this.getValue('weight'),
            weightUnit: this.getValue('weightUnit') ?? "LB",
            countryOfManufacture: countryMap[ this.getValue('countryOfManufacture')] || "",
            eaBoxContains: this.getValue('eaBoxContains'),
            originCriterion: this.getValue('originCriterion'),
        }
    }

    componentDidMount() {
        this.props.getCountryCodes()
    }

    getValue = (key) => {
        if (key === "existingShortcodes") {
            return this.props.products ? this.props.products.map(n => n.shortcode) : []
        }

        if (this.props.creatingProduct && key === "locations") {
            return []
        }

        if (this.props.creatingProduct && key === "preLocationIds") {
            return []
        } else if (key === "preLocationIds") {
            return this.props.editingProduct['locations']
        }

        if (this.props.editingProduct && key === "categoryId" && this.props.editingProduct[key]) {
            let result = this.props.categories.filter(obj => {
                return obj.id === this.props.editingProduct[key]
            })

            this.props.setSelectedCategory(result[0])
            return result[0].id
        }

        if (this.props.editingProduct && key === "locations") {
            return this.props.editingProduct[key]
        }

        if (this.props.creatingProduct)
            return ''

        return this.props.editingProduct[key]
    }


    handleTextChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleCheckChange = (e) => {
        this.setState({
            hasPackagingDimensions: e.target.checked,
            packageSize: e.target.checked ? this.state.packageSize : ""
        })
    }

    checkShortcode = () => {
        return this.state.existingShortcodes.includes(this.state.shortcode) && this.state.shortcode !== this.state.oldShortcode
    }

    editedShortcode = () => {
        return this.state.oldShortcode != "" && this.state.shortcode !== this.state.oldShortcode
    }

    checkSave = () => {
        return (this.state.shortcode == '' || this.checkShortcode() || this.state.nominalSize == '' || this.state.actualSize == '' ||
            this.state.textDescription == '' || this.state.productCode == '' || this.state.brand == '' ||
            this.state.manufacturerPartNum == '' || this.state.boxQuantity == '' || this.state.paleteQuantity == '')
    }

    save = () => {
        this.props.handleSave(this.props.creatingProduct, this.state)

        const data = {
            selectedShipAddressId: this.props.user.locationIds ?? [this.props.user.defaultLocationId],
            fetchUnshipped: false
        }
        let currHour = new Date().getHours();
        data.fetchUnshipped = currHour >= FETCH_UNSHIPPED_ALERTS_TIME;
        this.props.fetchAlerts(data)
        this.props.handleCancel()
        this.props.listProducts()
    }

    handleLocationSelect = (e, location) => {
        let shipAddressId = location.shipAddressId
        let newLocationIds = []

        if (!e.target.checked) {
            this.state.locationIds.forEach(locationId => {
                if (locationId !== shipAddressId) {
                    newLocationIds.push(locationId)
                }
            })
        } else {
            newLocationIds = this.state.locationIds
            newLocationIds.push(shipAddressId)
        }

        this.setState({
            locationIds: newLocationIds
        })
    }

    deleteIgnored = (suggestedIndex, boxIndex) => {
        let tempIgnoredSuggestions = this.state.ignoredSuggestions
        tempIgnoredSuggestions[suggestedIndex].boxes.splice(boxIndex, 1)
        tempIgnoredSuggestions[suggestedIndex].boxIds.splice(boxIndex, 1)
        this.setState({ ignoredSuggestions: tempIgnoredSuggestions })
    }

    setOriginCriterion = (value) => {
        this.setState({
            originCriterion: value
        })
    }

    setCategory = (value) => {
        this.setState({
            selectedCategoryId: value?.id ?? ""
        })
        this.props.setSelectedCategory(value)
    }

    setCountry = (value) => {
        this.setState({
            countryOfManufacture: value
        })
    }

    setWeightUnit = (value) => {
        this.setState({
            weightUnit: value
        })
    }

    render() {
        return (
            <div className='product-fields-container'>
                <div>
                    <div className='row-flexbox'>
                        <TextField
                            className='input-text-field'
                            error={this.checkShortcode()}
                            helperText={this.checkShortcode() ? "Duplicated shortcode" : this.editedShortcode() ? "Rename inventory and return" : null}
                            variant='outlined'
                            label="Shortcode"
                            InputLabelProps={{ shrink: true }}
                            name='shortcode'
                            value={this.state.shortcode}
                            onChange={this.handleTextChange}
                            required
                        />

                        <TextField
                            className='input-text-field'
                            variant='outlined'
                            label="Nominal Size (HxLxW)"
                            InputLabelProps={{ shrink: true }}
                            name='nominalSize'
                            value={this.state.nominalSize}
                            onChange={this.handleTextChange}
                            required
                        />

                        <TextField
                            className='input-text-field'
                            variant='outlined'
                            label="Actual Size (HxLxW)"
                            InputLabelProps={{ shrink: true }}
                            name='actualSize'
                            value={this.state.actualSize}
                            onChange={this.handleTextChange}
                            required
                        />
                    </div>

                    <div className='row-flexbox' style={{justifyContent: "flex-start"}}>
                        <FormControlLabel
                            style={{margin: 10, width: 225, whiteSpace: 'nowrap'}}
                            control={<Checkbox
                                checked={this.state.hasPackagingDimensions}
                                onChange={this.handleCheckChange}
                            />}
                            label="Use package dimensions for box suggestions"
                        />
                        <div className='input-text-field'/>
                        <TextField
                            className='input-text-field'
                            variant='outlined'
                            label='Package Size (HxLxW)'
                            InputLabelProps={{ shrink: true }}
                            name='packageSize'
                            value={this.state.packageSize}
                            disabled={!this.state.hasPackagingDimensions}
                            onChange={this.handleTextChange}
                            required={this.state.hasPackagingDimensions}
                        />
                    </div>

                    <div className='row-flexbox'>
                        <TextField
                            className='product-single-line-field'
                            variant='outlined'
                            label="Description"
                            InputLabelProps={{ shrink: true }}
                            name='textDescription'
                            value={this.state.textDescription}
                            onChange={this.handleTextChange}
                            required
                        />

                    </div>

                    <div className='row-flexbox'>
                        <TextField
                            className='input-text-field'
                            variant='outlined'
                            label="Product Code"
                            InputLabelProps={{ shrink: true }}
                            name='productCode'
                            value={this.state.productCode}
                            onChange={this.handleTextChange}
                            required
                        />
                        <TextField
                            className='input-text-field'
                            variant='outlined'
                            label="Brand"
                            InputLabelProps={{ shrink: true }}
                            name='brand'
                            value={this.state.brand}
                            onChange={this.handleTextChange}
                            required
                        />
                        <TextField
                            className='input-text-field'
                            variant='outlined'
                            label="Manufacturer Part Number"
                            InputLabelProps={{ shrink: true }}
                            name='manufacturerPartNum'
                            value={this.state.manufacturerPartNum}
                            onChange={this.handleTextChange}
                            required
                        />
                    </div>

                    <div className='row-flexbox'>
                        <TextField
                            className='input-text-field'
                            variant='outlined'
                            label="Original Box Quantity (units)"
                            InputLabelProps={{ shrink: true }}
                            name='boxQuantity'
                            value={this.state.boxQuantity}
                            onChange={this.handleTextChange}
                            required
                        />
                        <TextField
                            className='input-text-field'
                            variant='outlined'
                            label="Each Unit Contains"
                            InputLabelProps={{ shrink: true }}
                            name='eaBoxContains'
                            value={this.state.eaBoxContains}
                            onChange={this.handleTextChange}
                            required
                        />
                        <TextField
                            className='input-text-field'
                            variant='outlined'
                            label="Skid / Pallet Quantity"
                            InputLabelProps={{ shrink: true }}
                            name='paleteQuantity'
                            value={this.state.paleteQuantity}
                            onChange={this.handleTextChange}
                            required
                        />
                    </div>

                    <div className="row-flexbox">
                        <Autocomplete
                            className={'input-text-field'}
                            options={this.props.categories}
                            getOptionLabel={(entry) => entry.category}
                            autoHighlight={true}
                            onChange={(event, value) => {
                                this.setCategory(value)
                            }}
                            value={this.props.selectedCategory}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="Select Category"
                                    InputLabelProps={{shrink: true}}
                                    variant="outlined"
                                    name="selectCategory"
                                />}
                        />
                        <Autocomplete
                            className={'input-text-field'}
                            options={Object.values(this.props.countries)}
                            value={this.state.countryOfManufacture}
                            onChange={(event, value) => {
                                this.setCountry(value)
                            }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="Country of Origin"
                                    InputLabelProps={{shrink: true}}
                                    variant="outlined"
                                    name="countryOfOrigin"
                                />
                            }
                        />
                        <Autocomplete
                            className={'input-text-field'}
                            options={ORIGIN_CRITERION_OPTIONS}
                            getOptionLabel={(entry) => entry}
                            autoHighlight={true}
                            onChange={(event, value) => {
                                this.setOriginCriterion(value)
                            }}
                            value={this.state.originCriterion}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="Origin Criterion"
                                    InputLabelProps={{shrink: true}}
                                    variant="outlined"
                                    name="originCriterion"
                                />}
                        />
                    </div>

                    <div className="row-flexbox">
                        <TextField
                            className='input-weight'
                            variant='outlined'
                            label="Weight"
                            InputLabelProps={{ shrink: true }}
                            name='weight'
                            value={this.state.weight}
                            onChange={this.handleTextChange}
                        />
                        <Autocomplete
                            className='input-weight-unit'
                            options={WEIGHT_UNITS}
                            value={this.state.weightUnit}
                            onChange={(event, value) => {
                                this.setWeightUnit(value)
                            }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="Weight Unit"
                                    InputLabelProps={{shrink: true}}
                                    variant="outlined"
                                    name="weight"
                                />
                            }
                        />
                    </div>

                    {this.state.ignoredSuggestions && <React.Fragment>
                        <hr />

                        <div className='row-flexbox'>
                            <div style={{width: "45%"}}>
                                <FormLabel>Ignored Suggestions</FormLabel>
                                {this.state.ignoredSuggestions.map((suggestion, index) => (suggestion.boxes.length > 0 ?
                                    <div key={"suggest" + index} className="suggestion">
                                        <div>{suggestion.products.map((product, i) => (i ? ", " : "") + product)}</div>
                                        <div className="boxes-list">
                                            {suggestion.boxes.map((box, i ) => (<div className="box-entry" key={i}>
                                                <div style={{display: "inline-block"}}>{box.shortcode} ({box.nominalSize})</div>
                                                <IconButton aria-label="Delete Ignored Suggestion" onClick={(e) => this.deleteIgnored(index, i)}>
                                                    <HighlightOffIcon style={{color: "red"}} />
                                                </IconButton>
                                            </div>))}
                                        </div>
                                    </div> : null))}
                            </div>
                        </div>

                        <hr />
                    </React.Fragment>}
                    <div className='row-flexbox'>
                        <div className='product-single-line-field'>
                            <FormLabel component="legend">{SELECT_LOCATION_HEADER}</FormLabel>
                            <FormHelperText>{SELECT_LOCATION_HELPER_TEXT}</FormHelperText>
                            <FormGroup>

                                {this.props.locations.map((location) => {
                                    const checked = this.state.locationIds.includes(location.shipAddressId)
                                    const preChecked = this.state.preLocationIds.includes(location.shipAddressId)
                                    const getRemoveText = () => {
                                        return !checked && checked != preChecked ? " Note: Removing the location will also delete the existing inventory for that location." : ""
                                    }
                                    return (
                                        <>
                                            <div
                                                style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                }}
                                            >
                                                <Checkbox checked={checked} onChange={(e) => this.handleLocationSelect(e, location)} name="location" color='primary' />
                                                <div
                                                    style={{
                                                        marginTop: "auto",
                                                        marginBottom: "auto",
                                                    }}
                                                >
                                                    <div
                                                        className="MuiTypography-body1"
                                                    >
                                                        {location.name}
                                                    </div>
                                                </div>
                                            </div>
                                            <div>{getRemoveText()}</div>
                                        </>
                                    )
                                })}
                            </FormGroup>
                        </div>

                    </div>
                    <div className='row-flexbox'>

                        <div className='input-text-field' />
                        <div className='input-text-field cancel-save-buttons'>
                            <Button
                                variant='contained'
                                onClick={this.props.handleCancel}
                            >
                                {CANCEL}
                            </Button>
                            <div className='button-spacing' />
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={this.save}
                                disabled={this.checkSave()}
                            >
                                {SAVE}
                            </Button>


                        </div>
                    </div>
                </div>

            </div>
        );
    }


}


const mapStateToProps = (state) => ({
    countries: countriesSelector(state)
})

const actionCreators = {
    listProducts,
    getCountryCodes
}


// todo proptypes
AddProductForm.propTypes = {
    editingProduct: PropTypes.object,
    creatingProduct: PropTypes.bool,
    locations: PropTypes.array

}

AddProductForm.defaultProps = {
    editingProduct: {},
    creatingProduct: true,
    locations: []
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, AddProductForm);
