import { Button, Tooltip, IconButton } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import CustomToolbar from "../../../global/CustomToolbar";
import DateFnsUtils from "@date-io/date-fns";
import "./ScrubData.css";
import { scrubData } from "../../../../redux/actions/settings";
import withShipment from "../../../../withShipment";
import { loadingSelector } from "../../../../redux/selectors/global";
import { SCRUB_DATA_DATE_INFO } from "./constants";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { InfoOutlined } from "@material-ui/icons";
import Delete from "@material-ui/icons/Delete";

class ScrubData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fromDate: new Date(),
            toDate: new Date(),
        }
    }

    componentDidMount() {
        this.setState({
            fromDate: this.state.fromDate.setDate(this.state.fromDate.getDate() - 14),
            toDate: this.state.toDate.setDate(this.state.toDate.getDate())
        })
    }

    handleDateChange = (name,value) => {
        this.setState({
            [name]: value
        })
    }

    render() {
        return (
            <>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <CustomToolbar
                        title={'Scrub Data'}
                        headerButtons={[]}
                    />
                    {this.props.isLoading ?
                        <div className="getPrinterLoadingOuterBox">
                            <div className="getPrinterLoadingBox">
                                <CircularProgress size={100} />
                            </div>
                        </div> :
                        <div className="scrub-data-container">
                            <div>
                                <KeyboardDatePicker
                                    autoOk
                                    name="scrub-from-date"
                                    variant="inline"
                                    inputVariant="outlined"
                                    label="scrub from date"
                                    format="yyyy/MM/dd"
                                    value={this.state.fromDate}
                                    InputAdornmentProps={{ position: "start" }}
                                    onChange={date => this.handleDateChange("fromDate",date)}
                                    style={{ width: 200 }}
                                />
                            </div>
                            <div style={{ marginLeft: "30px"}}>
                                <KeyboardDatePicker
                                    autoOk
                                    name="scrub-to-date"
                                    variant="inline"
                                    inputVariant="outlined"
                                    label="scrub to date"
                                    format="yyyy/MM/dd"
                                    value={this.state.toDate}
                                    InputAdornmentProps={{ position: "start" }}
                                    onChange={date => this.handleDateChange("toDate",date)}
                                    style={{ width: 200 }}
                                />
                            </div>
                            <div>
                                <Tooltip title={SCRUB_DATA_DATE_INFO}>
                                    <IconButton aria-label={SCRUB_DATA_DATE_INFO}>
                                        <InfoOutlinedIcon variant='outlined' fontSize='small' />
                                    </IconButton>
                                </Tooltip>
                            </div>

                            <div>
                                <Button
                                    style={{ marginLeft: 10 }}
                                    color="primary"
                                    variant="contained"
                                    onClick={() => this.props.scrubData(
                                        new Date(this.state.fromDate).toISOString().split('T')[0],
                                        new Date(this.state.toDate).toISOString().split('T')[0],
                                    )}
                                >
                                    Scrub Data
                                </Button>
                            </div>
                        </div>
                    }
                </MuiPickersUtilsProvider>

            </>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: loadingSelector(state)
})

const actionCreators = {
    scrubData
}


export default withShipment({
    mapStateToProps,
    actionCreators
}, ScrubData);