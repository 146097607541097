import React from "react";

// material components
import {Box, IconButton, TextField, Tooltip, Select, MenuItem} from "@material-ui/core";

// material icons
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import DehazeIcon from '@material-ui/icons/Dehaze';

// constants
import {DELETE_ACTION, DRAG} from "../constants";
import {CONDITION_ACTIONS, CURRENCIES} from "./constants";
import PropTypes from "prop-types";
import ConditionActionsTable from "./ConditionActionsTable";

class DoubleNumericalAction extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            actionId: "",
            name: "",
            value1: "",
            value2: "",
        }
    }

    componentDidMount() {
        this.setState({
            ...this.props.action
        })
    }

    handleValueChange = (event) => {
        this.setState(
            {
                [event.target.name]: event.target.value,
            },
            () => {
                this.props.updateActionWithParent({ ...this.state });
            }
        );
    }

    getLabel = (labelType) => {
        let action = [...CONDITION_ACTIONS];
        action.filter(n => n.name === this.state.name)
        return action[0][labelType]
    }

    render() {
        return (
            <Box
                className='actionNumericField'
                display='flex'
                flexDirection='row'
                justifyContent='left'
                alignItems='center'
            >
                <Box className='actionNumericFieldName'>{this.state.name}</Box>
                <TextField
                    className='actionNumericFieldInput2'
                    variant='outlined'
                    type="number"
                    label={this.props.isEdit ? this.getLabel('label1') : this.props.action.label1}
                    value={this.state.value1}
                    name="value1"
                    onChange={this.handleValueChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <Select
                    className='actionNumericFieldInput2'
                    variant='outlined'
                    label={this.props.isEdit ? this.getLabel('label2') : this.props.action.label2}
                    value={this.state.value2}
                    name="value2"
                    onChange={this.handleValueChange}
                >
                    {CURRENCIES.map(val => (
                        <MenuItem value={val}>{val}</MenuItem>
                    ))}
                </Select>

                {/* in order to make sure that the delete icon aligns, we add an empty component */}
                <div className='actionNumericEmptyDiv2'>
                    <Tooltip title={DELETE_ACTION}>
                        <IconButton
                            aria-label={DELETE_ACTION}
                            onClick={() => this.props.deleteAction(this.state.actionId)}
                        >
                            <DeleteOutlineIcon variant='outlined' fontSize='small' />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={DRAG}>
                        <IconButton
                            aria-label='drag'
                        >
                            <DehazeIcon variant='outlined' fontSize='small' />
                        </IconButton>
                    </Tooltip>
                </div>
            </Box>
        );
    }

}

DoubleNumericalAction.propTypes = {
    action: PropTypes.object,
    updateActionWithParent: PropTypes.func,
    deleteAction: PropTypes.func,
    isEdit: PropTypes.bool
};

DoubleNumericalAction.defaultProps = {
    action: {},
    updateActionWithParent: ()=>{},
    deleteAction: ()=>{},
    isEdit: false
}

export default DoubleNumericalAction;