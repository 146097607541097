import React from "react";

// material components
import { Box, Toolbar } from "@material-ui/core";
import { IconButton, Tooltip, Typography, Button } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import SettingsIcon from '@material-ui/icons/Settings';
import RefreshIcon from '@material-ui/icons/Refresh';
import FindReplaceIcon from '@material-ui/icons/FindReplace';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import UpdateIcon from '@material-ui/icons/Update';
import DeleteIcon from '@material-ui/icons/Delete';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
// custom components

// constants
import { SUPPLIERS_TOOLBAR_TITLE } from "../settings/ManageSuppliers/constants"
import { PRINTERS_TOOLBAR_TITLE } from "../settings/ManagePrinters/constants"
import { ORDERS_TOOLBAR_TITLE } from "../settings/ControlPanel/RefreshOrder/constants"
import { SHIPMENTS_TOOLBAR_TITLE } from "../settings/ControlPanel/RecreateShipment/constants"
// import styling
import "../global/TableToolbar.css";
import PropTypes from "prop-types";
import CommentHistoryTable from "./CommentHistoryTable";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import {Popper} from "@mui/material";


class CustomToolbar extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null
        }
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevProps.headerButtons !== this.props.headerButtons){
            this.setState({anchorEl: null})
        }
     }

    handleBackBtn = () => {
        this.props.handleClose()
    }

    getPrinterButtons = () => {
        return (
            this.props.showReflash ?
                this.props.showReflashConfirm === true ?
                    <div className="contextualMenu">
                        <div className="toolbarCancelBtn">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    this.props.setReflashConfirm(false)
                                }}
                            >
                                Cancel
                            </Button>

                            <Button
                                variant="contained"
                                color="secondary"
                                startIcon={<RefreshIcon />}
                                onClick={() => {
                                    this.props.myreflash()
                                }}
                            >
                                Refresh
                            </Button>
                        </div>


                        <Tooltip title={this.props.settingTitle}>
                            <IconButton aria-label={this.props.settingTitle} onClick={this.props.onClick}>
                                <SettingsIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                    :
                    !this.props.creatingOrder ?
                        <div className="contextualMenu">
                            <Tooltip title={this.props.createTitle}>
                                <IconButton aria-label={this.props.createTitle}
                                    onClick={() => this.props.setReflashConfirm(true)}>
                                    <RefreshIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={this.props.settingTitle}>
                                <IconButton aria-label={this.props.settingTitle} onClick={this.props.onClick}>
                                    <SettingsIcon />
                                </IconButton>
                            </Tooltip>
                        </div> :
                        this.getCreateOrBackButtons()
                :
                null
        )

    }

    getOrderButtons = () => {
        return (
            <div className="contextualMenu">
                <Tooltip title={this.props.updateOrderTitle}>
                    <IconButton aria-label={this.props.updateOrderTitle} onClick={this.props.onClick}>
                        <FindReplaceIcon />
                    </IconButton>
                </Tooltip>
            </div>
        )
    }

    getShipmentButtons = () => {
        return (
            <>
                <div className="contextualMenu">
                    <Tooltip title={this.props.recreateShipmentTitle}>
                        <IconButton aria-label={this.props.recreateShipmentTitle} onClick={this.props.onClick}>
                            <SettingsBackupRestoreIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={this.props.updateShipmentTitle}>
                        <IconButton aria-label={this.props.updateShipmentTitle} onClick={this.props.updateShipments}>
                            <UpdateIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={this.props.deleteShipmentTitle}>
                        <IconButton aria-label={this.props.deleteShipmentTitle} onClick={this.props.deleteShipments}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            </>
        )
    }

    getToolbarButtons = () => {
        switch (this.props.title) {
            case PRINTERS_TOOLBAR_TITLE:
                return (
                    this.getPrinterButtons()
                )
            case ORDERS_TOOLBAR_TITLE:
                return (
                    this.getOrderButtons()
                )
            case SHIPMENTS_TOOLBAR_TITLE:
                return (
                    this.getShipmentButtons()
                )
            default:
                return null
        }
    }

    getCreateOrBackButtons = () => {
        if (this.props.creatingOrder) {
            return (
                <div className="contextualMenu">
                    <Tooltip title={this.props.backTitle}>
                        <IconButton aria-label={this.props.backTitle} onClick={() => { this.props.handleClose() }} >
                            <KeyboardBackspaceIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            )
        } else {
            return (
                <div className="contextualMenu">
                    <Tooltip title={this.props.createTitle}>
                        <IconButton aria-label={this.props.createTitle} onClick={this.props.onClick}>
                            <AddCircleIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            )
        }
    }

    handleClick = (event, button) => {
        if (button.popper)
            this.setState({anchorEl: event.currentTarget})
        else
            button.handler()
    }

    handleClosePopper = () => {
        this.setState({anchorEl: null})
    }

    render() {
        const popperOpen = Boolean(this.state.anchorEl)
        return (
            <div className="toolbar">
                <Toolbar>
                    {this.props.customIcons ? this.props.customIcons
                        : this.props.headerButtons ? <div className="contextualMenu">
                            {this.props.headerButtons.map((button, index) => (
                                button.divider ? button.divider :
                                    <div key={button.title + index}>
                                        <Tooltip title={button.title}>
                                            <IconButton
                                                aria-label={button.title}
                                                onClick={(event) => this.handleClick(event, button)}
                                                disabled={button.disabled}
                                            >
                                                {button.icon}
                                            </IconButton>
                                        </Tooltip>
                                        <Popper open={popperOpen} anchorEl={this.state.anchorEl} placement="bottom-start" style={{zIndex: "100"}}>
                                            {button.popper && React.cloneElement(button.popper, { handleClosePopper: this.handleClosePopper })}
                                        </Popper>
                                    </div>
                            ))}
                            {this.props.scale && this.props.addZoom && <div className="zoom-box">
                                <div style={{padding: "5px", cursor: "pointer"}}>
                                    <IconButton aria-label="Zoom Out" onClick={this.props.zoomOut} disabled={this.props.scale === 1}>
                                        <ZoomOutIcon />
                                    </IconButton>
                                </div>
                                <span style={{padding: "5px"}}>{this.props.scale * 100}%</span>
                                <div style={{padding: "5px", cursor: "pointer"}}>
                                    <IconButton aria-label="Zoom In" onClick={this.props.zoomIn} disabled={this.props.scale === 2}>
                                        <ZoomInIcon />
                                    </IconButton>
                                </div>
                            </div>}
                            </div> : this.props.title === PRINTERS_TOOLBAR_TITLE || this.props.title === ORDERS_TOOLBAR_TITLE || this.props.title === SHIPMENTS_TOOLBAR_TITLE?
                            this.getToolbarButtons()
                            :
                            this.getCreateOrBackButtons()}

                    <div style={{ width: "100%", alignItems: "center", display: "flex", justifyContent: "flex-end" }}>
                        <Typography className="titleTypography" variant="h6" id="tableTitle">
                            {this.props.title}
                        </Typography>
                        {this.props.rightHeaderButtons && <div style={{ display: "flex" }}>
                            {this.props.rightHeaderButtons.map((button, index) => (
                                <Tooltip title={button.title} key={button.title + index}>
                                    <IconButton aria-label={button.title} onClick={button.handler} disabled={button.disabled}>
                                        {button.icon}
                                    </IconButton>
                                </Tooltip>))
                            }
                        </div>}
                    </div>
                </Toolbar>
            </div>
        );
    }
}

CustomToolbar.propTypes = {
    title: PropTypes.string,
    createTitle: PropTypes.string,
    onClick: PropTypes.func,
    creatingOrder: PropTypes.bool,
    handleClose: PropTypes.func
}

CustomToolbar.defaultProps = {
    title: "",
    createTitle: "",
    onClick: () => { },
    creatingOrder: false,
    handleClose: () => { }
}

export default CustomToolbar;