import {
    call,
    put,
    takeLatest,
} from 'redux-saga/effects';
import axios from 'axios';

// constants for endpoints
import {
    ALERTS,
} from "../../../components/global/Server/endpoints";
import { HTTP_SUCCESS } from "../../constants/global";


// constants for actions
import {
    FETCH_ALERTS, SNOOZE_ALERT,RESOLVE_ALERT
} from "../../constants/alerts";
import { clearStatus, requestStatus, showErrorMessage } from "../../actions/global";
import {fetchAlerts, storeAlerts} from "../../actions/alerts";
import { getTableSetting } from "../../actions/account";
import { SERVER_FAILED_ERROR_MESSAGE } from '../../../pages/Dashboard/DashboardFinal/constants';
import {storeResponseMessage} from "../../actions/settings";

const getHeaders = () => {
    const token = localStorage.getItem('token');
    return {
        "Authorization": `Bearer ${token}`
    }
}

const getRequest = (endpoint) => {
    return axios.get(endpoint, {
        headers: getHeaders()
    })
        .then(response => response);
}

const postRequest = (endpoint, data) => {
    return axios.post(endpoint, data, {
        headers: getHeaders()
    })
        .then(response => response)
}

function* fetchAlertsSaga(action) {
    yield call(clearStatus)
    try {
        const response = yield call(() => getRequest(ALERTS.FETCH_ALERTS))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        yield put(storeAlerts(response.data))

        yield put(requestStatus(status));
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }

}

function* snoozeAlertsSaga(action) {
    yield call(clearStatus)
    try {
        const response = yield call(() => postRequest(ALERTS.SNOOZE_ALERT, action.data))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        yield put(requestStatus(status));

        yield put(getTableSetting())
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}
function* resolveAlertsSaga(action) {
    yield call(clearStatus)
    try {
        const response = yield call(() => postRequest(ALERTS.RESOLVE_ALERT, action.data))
        if (response.data.success) {
            const status = {
                status: response.status,
                statusText: response.data.message,
                success: response.status === HTTP_SUCCESS,
            };
            yield put(storeResponseMessage(response.data.message))
            yield put(requestStatus(status))
        } else {
            const message = response.data.message || SERVER_FAILED_ERROR_MESSAGE
            yield put(showErrorMessage(message))
        }
        yield put(fetchAlerts());

    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

export default function* alertsSaga() {
    yield takeLatest(FETCH_ALERTS, fetchAlertsSaga);
    yield takeLatest(SNOOZE_ALERT, snoozeAlertsSaga);
    yield takeLatest(RESOLVE_ALERT, resolveAlertsSaga);
}

