import React from "react";
import withShipment from "../../../../withShipment";
import './ListingsSettings.css';
import { marketplacesSelector} from "../../../../redux/selectors/settings";
import {
    LISTING_SETTING_TITLE,
    INVENTORY_ALLOCATION_INTRO,
    LISTINGS_PAGES,
    INVENTORY_ALLOCATION_TRIGGER_CHECK,
    INVENTORY_ALLOCATION_TRIGGER_CHECK_INTRO,
} from "../constants";
import {List, ListItem, Input, TextField, Button} from '@material-ui/core';
import TopToolbar from "../../../global/subcomponents/topToolbar/TopToolbar";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import {Checkbox, FormControlLabel } from '@material-ui/core';
import {userInfoSelector} from "../../../../redux/selectors/auth";

class ListingsSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            marketplaceOptions: this.props.marketplaces ? this.props.marketplaces.map(marketplace => marketplace.marketplaceName) : [],
            percentages:this.props.marketplaces ? this.props.marketplaces.map(marketplace => {
                return {
                    marketplaceName: marketplace.marketplaceName,
                    percentage: marketplace.percentage ? marketplace.percentage: ""
                };
            }) : [],
            originalPercentages: this.props.marketplaces ? this.props.marketplaces.map(marketplace => {
                return {
                    marketplaceName: marketplace.marketplaceName,
                    percentage: marketplace.percentage ? marketplace.percentage: ""
                };
            }) : [],
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.isLoading) {
            this.props.setCurrentPage(LISTINGS_PAGES.LOADING)
        }
        if (prevProps.marketplaces !== this.props.marketplaces) {
            this.setState({
                percentages:this.props.marketplaces ? this.props.marketplaces.filter(mp => mp.enabled === true).map(marketplace => {
                    return {
                        marketplaceName: marketplace.marketplaceName,
                        percentage: marketplace.percentage ? marketplace.percentage: ""
                    };
                }) : [],
                originalPercentages: this.props.marketplaces ? this.props.marketplaces.filter(mp => mp.enabled === true).map(marketplace => {
                    return {
                        marketplaceName: marketplace.marketplaceName,
                        percentage: marketplace.percentage ? marketplace.percentage: ""
                    };
                }) : []
            })
        }
    }

    handleClick = (event) => {
        this.setState({ anchorEl: event.currentTarget })
    }

    handleClose = () => {
        this.setState({ anchorEl: null })
    }

    handleCancel = () => {
        // Reset percentages to original values
        this.setState({ percentages: this.state.originalPercentages, valueIsChanged: false });
    };

    handleApply = () => {
        this.props.updateInventoryAllocation(this.state.percentages, this.props.companyId);
    };

    areArraysEqual = (array1, array2) => {
        if (array1.length !== array2.length) {
            return false;
        }

        for (let i = 0; i < array1.length; i++) {
            if (array1[i].marketplaceName !== array2[i].marketplaceName || array1[i].percentage !== array2[i].percentage) {
                return false;
            }
        }
        return true;
    };

    handleChange = (event) => {

        const { name, value } = event.target;

        if (!value.match(/^\d*\.?\d*$/) || value < 0 || value > 100) {
            return
        }
        const index = parseInt(name.split('-')[1], 10);

        let newPercentages = [...this.state.percentages];

        newPercentages[index] = {
            ...newPercentages[index],
            percentage: value
        };

        this.setState({ percentages: newPercentages });
    };

    redirect = () => {
        this.props.history.push('/automation-rules')
    }


    render() {
        const { originalPercentages, percentages, } = this.state;
        return (
            <React.Fragment>
                <TopToolbar
                    pageName={LISTING_SETTING_TITLE}
                    menuItems={[
                        {title: "Back", icon: <KeyboardBackspaceIcon/>, onClick: () => {this.props.setCurrentPage(LISTINGS_PAGES.TABLE)} },
                    ]}
                />

                <div>
                    <div className="subTitle">Inventory Allocation
                    </div>
                    <p className="listingsSettingIntro"> {INVENTORY_ALLOCATION_INTRO} </p>

                    <List style={{ marginLeft: '20px' }}>
                        {this.state.originalPercentages.map((marketplace, index) => {
                            let percentageValue = this.state.percentages[index].percentage
                            return (
                                <ListItem key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ width: '180px' }}>{marketplace.marketplaceName}</div>
                                    <TextField
                                        style={{ width: '130px' }}
                                        variant="outlined"
                                        type="text"
                                        name={`percentage-${index}`}
                                        value={percentageValue}
                                        onChange={this.handleChange}
                                        label={"Percentage"}
                                        InputProps={{
                                            endAdornment: <span>%</span>
                                        }}
                                    />
                                </ListItem>
                            );
                        })}
                        <div className="bottom-wrapper" style={{width: '310px', alignItems: 'center'}}>
                            <Button
                                onClick={this.handleApply}
                                color="primary"
                                variant="contained"
                                className="submit-button"
                                disabled={this.areArraysEqual(originalPercentages, percentages)}
                            >
                                APPLY
                            </Button>
                            <div style={{width: '30px'}}></div>
                            <Button
                                onClick={this.handleCancel}
                                variant="contained"
                                className="submit-button"
                                disabled={this.areArraysEqual(originalPercentages, percentages)}
                            >
                                CANCEL
                            </Button>
                        </div>
                    </List>

                </div>
            </React.Fragment>
        );
    }
}

ListingsSettings.defaultProps = {
}

const mapStateToProps = (state) => ({
    marketplaces: marketplacesSelector(state),
    userInfo: userInfoSelector(state),
})

const actionCreators = {
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, ListingsSettings);