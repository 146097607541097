import React from "react";
import withShipment from "../../../withShipment";

//components
import CustomToolbar from "../../global/CustomToolbar";
import CustomTableHeader from "../../global/Table/CustomTableHeader";
import SearchableTable from "../../global/Search/SearchableTable";
import GlobalTableHead from "../../global/Table/GlobalTableHead";
import FeedbackBar from "../../feedbackBar/FeedbackBar";

//material ui
import {
    TableBody,
    TablePagination,
    Table,
    TableContainer,
    TableRow,
    TableCell,
    IconButton,
    TextField,
    Tooltip,
    TableHead
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import EditAttributesIcon from '@material-ui/icons/EditAttributes';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import RestoreIcon from '@material-ui/icons/Restore';


//constants
import { ASC, DESC } from "../../global/constants";
import {
    PRODUCT_CATEGORY_TOOLBAR_TITLE,
    MANAGE_PRODUCT_CATEGORY, PRODUCT_CATEGORY_TABLE_HEAD_CELLS, DEFAULT_ROWS_PER_PAGE, DEFAULT_SORT_BY_HEAD_CELL
} from "./constants";

import {SUCCESS, ERROR, BACK_TO_MENU_TITLE} from "../../shipments/constants";

//redux
import {
    saveProductCategoryChanges,
    removeProductCategory,
    getAllCategory,
    getUUID,
    getAmazonFeeds, restartFeedFetch
} from "../../../redux/actions/settings";
import {categorySelector, feedsSelector} from "../../../redux/selectors/settings"


import './ManageAmazonTable.css'
import ErrorFeedbackBar from "../../global/ErrorFeedbackBar";
import EditIcon from "@material-ui/icons/Edit";

class ManageAmazonTable extends SearchableTable {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: DEFAULT_ROWS_PER_PAGE,
            order: ASC,
            orderBy: DEFAULT_SORT_BY_HEAD_CELL,
            isSearch: false, // true if search value is not empty, false otherwise
            searchValue: "", // the value in the search field (updates on change)
            searchRows: 0, // number of rows displayed while search criteria is met
            editChanges: [],
            editRows: [],
            rows: [],
            isEditProducts: false,
            changeRequest: false
        }
    }

    componentDidMount() {
        this.props.getAmazonFeeds()
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {

    }

    handleRestartButton = (feedId) => {
        this.props.restartFeedFetch(feedId)
    }

    render() {
        return (
            <>
                <ErrorFeedbackBar />
                <FeedbackBar
                    open={this.state.feedbackOpen}
                    handleClose={this.handleSuccessClose}
                    severity={SUCCESS}
                    message={this.state.feedbackMessage} />
                <FeedbackBar
                    open={this.state.errorFeedbackOpen}
                    handleClose={this.handleErrorClose}
                    severity={ERROR}
                    message={this.state.feedbackMessage} />
                <Paper className='paper'>
                    <CustomToolbar
                        title={PRODUCT_CATEGORY_TOOLBAR_TITLE}
                        customIcons={
                            <div className="contextualMenu">
                                <Tooltip title={MANAGE_PRODUCT_CATEGORY}>
                                    <div/>
                                </Tooltip>
                            </div>
                        }
                    />
                        <>
                            <TableContainer>
                                <Table size='medium'>
                                    <GlobalTableHead
                                        isCreatePurchaseOrderTable={false}
                                        isShipmentTable={false}
                                        isOrderTable={false}
                                        isInvoiceTable={false}
                                        isPurchaseOrderTable={false}
                                        isProductCategoryTable={true}
                                        numSelected={0}
                                        headCells={PRODUCT_CATEGORY_TABLE_HEAD_CELLS}
                                        order={this.state.order}
                                        orderBy={this.state.orderBy}
                                        onRequestSort={this.handleRequestSort}
                                    />
                                    <TableBody>
                                        {(this.props.submittedFeedLog ? this.props.submittedFeedLog : []).map((row, index) => {
                                            return (
                                                <TableRow>
                                                    <TableCell align={"center"} className='inputBoxAmz'>
                                                        <div className='inputBoxAmz'>
                                                            <div className='textBox'>
                                                                {row.feedSubmissionDate.split('T')[0].split('-')[2] + '/' +
                                                                row.feedSubmissionDate.split('T')[0].split('-')[1] + '/' +
                                                                row.feedSubmissionDate.split('T')[0].split('-')[0]}
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell align={"center"} className='inputBoxAmz'>
                                                        <div className='inputBoxAmz'>
                                                            <div className='textBox'>
                                                                {row.feedSubmissionId}
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell align={"center"} className='inputBoxAmz'>
                                                        <div className='inputBoxAmz'>
                                                            <div className='textBox'>
                                                                {row.feedType}
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell align={"center"} className='inputBoxAmz'>
                                                        <div className='inputBoxAmz'>
                                                            <div className='textBox'>
                                                                {row.feedProcessingStatus}
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell align={"center"} className='inputBoxAmz'>
                                                        <div className='inputBoxAmz'>
                                                            <div className='textBox'>
                                                                {row.marketplace}
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell align={"center"}>
                                                        <div>
                                                            <IconButton onClick={() => this.handleRestartButton(row.feedSubmissionId)}>
                                                                <RestoreIcon/>
                                                            </IconButton>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                </Paper>
            </>
        );
    }

}

const mapStateToProps = (state) => ({
    submittedFeedLog: feedsSelector(state),
})

const actionCreators = {
    getAmazonFeeds,
    restartFeedFetch
}
export default withShipment({
    mapStateToProps,
    actionCreators
}, ManageAmazonTable);