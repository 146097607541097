import React from "react";

// material components
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";

// custom components
import GlobalTableHead from "../global/Table/GlobalTableHead";
import SearchableTable from "../global/Search/SearchableTable";

// constants
import {
    RETURNS_TABLE_HEAD_CELLS,
    ROWS_PER_PAGE_LABEL,
    DEFAULT_ROWS_PER_PAGE,
    SHOW_MY_RETURNS,
    SHOW_COMPLETED_RETURNS,
    SHOW_REFUND_PENDING_RETURNS
} from "./constants";
import { ASC, DESC } from "../global/constants";
import { DEFAULT_SORT_BY_HEAD_CELL, COMPLETED_STATUS } from "./constants";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import { IconButton, TableBody, TablePagination } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";


// styling
import './ReturnsTable.css'
import TableFooter from "@material-ui/core/TableFooter";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PropTypes from "prop-types";
import ReturnsForm from "./ReturnsForm";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import SearchField from "../global/Search/SearchField";
import CustomTableHeader from "../global/Table/CustomTableHeader";
import LocationSelectBox from "../global/subcomponents/locationSelectBox/LocationSelectBox";

class ReturnsTable extends SearchableTable {
    constructor(props) {
        super(props);
        this.state = {
            order: DESC,
            orderBy: DEFAULT_SORT_BY_HEAD_CELL,
            isSearch: false,
            searchValue: '',
            searchRows: 0,
            showMyReturns: false,
            showCompletedReturns: false,
            showRefundPendingReturns: true,
        }
    }

    componentDidMount() {
        this.setState({
            showMyReturns: this.props.tableSetting?.returnsFilterSetting?.showMyReturns,
            showCompletedReturns: this.props.tableSetting?.returnsFilterSetting?.showCompletedReturns,
            showRefundPendingReturns: this.props.tableSetting?.returnsFilterSetting?.showRefundPendingReturns,
            searchValue: this.props.searchText
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.tableSetting !== this.props.tableSetting) {
            this.setState({
                showMyReturns: this.props.tableSetting?.returnsFilterSetting?.showMyReturns,
                showCompletedReturns: this.props.tableSetting?.returnsFilterSetting?.showCompletedReturns,
                showRefundPendingReturns: this.props.tableSetting?.returnsFilterSetting?.showRefundPendingReturns
            })
        }
        if (prevProps.searchText !== this.props.searchText || this.state.searchValue !== this.props.searchText) {
            this.initializeSearchText()
        }
    }

    initializeSearchText = () => {
        this.setState({ searchValue: this.props.searchText })
    }

    onSearchValueChange = (e) => {
        this.setState({
            isSearch: e.target.value.length > 0,
            searchValue: e.target.value
        });
        this.props.setPage(0)
        this.props.handleSearchChange(e)
    }

    handleRequestSort = (event, property) => {
        const isAsc = this.state.orderBy === property && this.state.order === ASC;
        this.setState({
            order: isAsc ? DESC : ASC,
            orderBy: property,
        });
    };

    getDisplayed = (rows) => {
        if (!rows)
            return [];
        return [...rows.slice(this.props.page * this.props.rowsPerPage, this.props.page * this.props.rowsPerPage + this.props.rowsPerPage)];
    }

    handleChangePage = (event, newPage) => {
        this.props.setPage(newPage)
    };

    handleChangeRowsPerPage = (event) => {
        this.props.setRowsPerPage(event.target.value)
        this.props.setPage(0)
    };

    filterByMyReturns = (rows) => {
        let returnsListFinal = [];
        if (!this.state.showMyReturns) {
            returnsListFinal = rows;
        } else {
            returnsListFinal = rows.filter((row) =>
                row.assignedToId === this.props.currentUser.userId
            )
        }

        returnsListFinal = this.showCompletedReturns(returnsListFinal)
        return this.showRefundPendingReturns(returnsListFinal)
    }

    filterByLocation = (rows) => {
        return rows.filter((row) => {return row.location?.shipAddressId === this.props.selectedLocation})
    }

    showCompletedReturns = (rows) => {
        if (this.state.showCompletedReturns)
            return rows

        return rows.filter((row) =>
            row.status !== "Completed"
        )
    }

    showRefundPendingReturns = (rows) => {
        if (this.state.showRefundPendingReturns)
            return rows

        return rows.filter((row) =>
            row.status !== "Refund Pending"
        )
    }

    render() {
        return (
            <>
                <CustomTableHeader
                    searchText={this.props.searchText}
                    onSearchValueChange={this.onSearchValueChange}
                    customFilterField={
                        <div>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.showMyReturns} onChange={() => {
                                    const checked = !this.state.showMyReturns
                                    this.props.saveTableSetting({
                                        userId: this.props.user.userId,
                                        tableSetting: null,
                                        changeFilters: false,
                                        changeReturnsFilters: true,
                                        returnFilterSetting: {
                                            showCompletedReturns: this.state.showCompletedReturns,
                                            showMyReturns: checked,
                                            showRefundPendingReturns: this.state.showRefundPendingReturns
                                        }
                                    })
                                    this.setState({ showMyReturns: !this.state.showMyReturns })
                                }} name="checkedG" />}
                                label={SHOW_MY_RETURNS}
                            />
                            <FormControlLabel
                                control={<Checkbox checked={this.state.showCompletedReturns}
                                    onChange={() => {
                                        const checked = !this.state.showCompletedReturns
                                        this.props.saveTableSetting({
                                            userId: this.props.user.userId,
                                            tableSetting: null,
                                            changeFilters: false,
                                            changeReturnsFilters: true,
                                            returnFilterSetting: {
                                                showCompletedReturns: checked,
                                                showMyReturns: this.state.showMyReturns,
                                                showRefundPendingReturns: this.state.showRefundPendingReturns
                                            }
                                        })
                                        this.setState({ showCompletedReturns: !this.state.showCompletedReturns })
                                    }}
                                    name="checkedG" />}
                                label={SHOW_COMPLETED_RETURNS}
                            />
                            <FormControlLabel
                                control={<Checkbox checked={this.state.showRefundPendingReturns} onChange={() => {
                                    const checked = !this.state.showRefundPendingReturns
                                    this.props.saveTableSetting({
                                        userId: this.props.user.userId,
                                        tableSetting: null,
                                        changeFilters: false,
                                        changeReturnsFilters: true,
                                        returnFilterSetting: {
                                            showCompletedReturns: this.state.showCompletedReturns,
                                            showMyReturns: this.state.showMyReturns,
                                            showRefundPendingReturns: checked
                                        }
                                    })
                                    this.setState({ showRefundPendigReturns: !this.state.showRefundPendingReturns })
                                }} name="checkedG" />}
                                label={SHOW_REFUND_PENDING_RETURNS}
                            />
                        </div>
                    }
                    pagination={
                        <TablePagination
                            className="table-pagination"
                            labelRowsPerPage={ROWS_PER_PAGE_LABEL}
                            rowsPerPageOptions={[5, 25, 50, 100, {value: this.props.rows.length, label: "All"} ]}
                            count={!this.props.rows ? 0 : this.filterByMyReturns(this.filterBySearch(this.props.rows)).length}
                            rowsPerPage={this.props.rowsPerPage}
                            page={this.props.page}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    }
                    locationField={<LocationSelectBox/>}
                />

                <TableContainer>
                    <Table
                        size='medium'
                    >
                        <GlobalTableHead
                            isCreatePurchaseOrderTable={false}
                            isShipmentTable={false}
                            headCells={RETURNS_TABLE_HEAD_CELLS}
                            order={this.state.order}
                            orderBy={this.state.orderBy}
                            onRequestSort={this.handleRequestSort}
                            rowCount={!this.props.rows ? 0 : this.props.rows.length}
                        />
                        <TableBody>
                            {this.getDisplayed(this.stableSort(this.filterByLocation(this.filterByMyReturns(this.filterBySearch((this.props.rows)))), this.getComparator(this.state.order, this.state.orderBy))).map((row, index) => {

                                return (
                                    <TableRow key={index} hover onDoubleClick={() => this.props.handleDoubleClick(row)}>
                                        <TableCell>
                                            <IconButton onClick={() => this.props.handleDoubleClick(row)}>
                                                {row.status === COMPLETED_STATUS ? <VisibilityIcon /> : <EditIcon />}
                                            </IconButton>
                                        </TableCell>
                                        <TableCell className='cellPadding'>
                                            {row.dueDate.split('T')[0]}
                                        </TableCell>
                                        <TableCell className='cellPadding'>
                                            {row.customerName}
                                        </TableCell>
                                        <TableCell className='cellPadding'>
                                            {row.originalTrackingId}
                                        </TableCell>
                                        <TableCell className='cellPadding'>
                                            {row.orderId}
                                        </TableCell>
                                        <TableCell className='cellPadding'>
                                            {row.rmaNum}
                                        </TableCell>
                                        <TableCell className='cellPadding'>
                                            {row.status}
                                        </TableCell>
                                        <TableCell className='cellPadding'>
                                            {row.returnedDate.split('T')[0]}
                                        </TableCell>
                                        <TableCell className='cellPadding'>
                                            {row.assignedToName}
                                        </TableCell>


                                    </TableRow>
                                );

                            })
                            }
                        </TableBody>

                    </Table>
                </TableContainer>
            </>
        );
    }

}

ReturnsTable.propTypes = {
    rows: PropTypes.array,
    currentUser: PropTypes.object,
    handleDoubleClick: PropTypes.func,
    saveTableSetting: PropTypes.func
}

ReturnsTable.defaultProps = {
    rows: [],
    currentUser: {},
    handleDoubleClick: () => { },
    saveTableSetting: () => { }
}

export default ReturnsTable;