import React from "react";
import withShipment from "../../../withShipment";
import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";
import {
    BACK, CANCEL,
    COUNTRY_OPTIONS,
    FTL_PAGES,
    FULL_TRUCKLOAD_BROKERS,
    NEW_BROKER,
    SAVE,
} from "../constants";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import {Box} from "@mui/material";
import {Button, Tab, Tabs, TextField, Typography} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {saveFullTruckloadBrokers} from "../../../redux/actions/fullTruckloadBrokers";

const defaultProps = {
    bgcolor: 'background.paper',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    m: 1,

    border: 1,
    style: { width: '100%', height: '100%' },
};

class FullTruckloadBrokersForm extends React.Component{


    constructor(props) {
        super(props);
        this.state = {
            brokerInformation: {
                brokerId        : "",
                contactName     : "",
                name            : "",   //company name
                phoneNumber     : "",
                addressLine1    : "",
                addressLine2    : "",
                city            : "",
                stateOrProvince : "",
                postalCode      : "",
                country         : "",
                email           : "",
                accountNumber   : ""
            },
            showError:false
        }
    }

    componentDidMount () {
        if(this.props.selectedRow){
            let newBrokerInformation = this.state.brokerInformation
            newBrokerInformation.brokerId = this.props.selectedRow?.brokerId
            newBrokerInformation.contactName = this.props.selectedRow?.contactName
            newBrokerInformation.name = this.props.selectedRow?.name
            newBrokerInformation.phoneNumber = this.props.selectedRow?.phoneNumber
            newBrokerInformation.addressLine1 = this.props.selectedRow?.addressLine1
            newBrokerInformation.addressLine2 = this.props.selectedRow?.addressLine2
            newBrokerInformation.city = this.props.selectedRow?.city
            newBrokerInformation.stateOrProvince = this.props.selectedRow?.stateOrProvince
            newBrokerInformation.postalCode = this.props.selectedRow?.postalCode
            newBrokerInformation.country = this.props.selectedRow?.country
            newBrokerInformation.email = this.props.selectedRow?.email
            newBrokerInformation.accountNumber = this.props.selectedRow?.accountNumber

            this.setState({
                brokerInformation: newBrokerInformation
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.selectedRow !== this.props.selectedRow){
            let newBrokerInformation = this.state.brokerInformation
            newBrokerInformation.brokerId = this.props.selectedRow?.brokerId
            newBrokerInformation.contactName = this.props.selectedRow?.contactName
            newBrokerInformation.name = this.props.selectedRow?.name
            newBrokerInformation.phoneNumber = this.props.selectedRow?.phoneNumber
            newBrokerInformation.addressLine1 = this.props.selectedRow?.addressLine1
            newBrokerInformation.addressLine2 = this.props.selectedRow?.addressLine2
            newBrokerInformation.city = this.props.selectedRow?.city
            newBrokerInformation.stateOrProvince = this.props.selectedRow?.stateOrProvince
            newBrokerInformation.postalCode = this.props.selectedRow?.postalCode
            newBrokerInformation.country = this.props.selectedRow?.country
            newBrokerInformation.email = this.props.selectedRow?.email
            newBrokerInformation.accountNumber = this.props.selectedRow?.accountNumber

            this.setState({
                brokerInformation: newBrokerInformation
            })
        }
    }

    handleSaveBroker = () => {
        this.setState({
            showError:true
        })
        if(this.state.brokerInformation.contactName && this.state.brokerInformation.name && this.state.brokerInformation.phoneNumber &&
            this.state.brokerInformation.addressLine1 &&this.state.brokerInformation.city && this.state.brokerInformation.stateOrProvince &&
            this.state.brokerInformation.postalCode && this.state.brokerInformation.country && this.state.brokerInformation.email
            && this.state.brokerInformation.accountNumber){
            //send save request
            this.props.saveFullTruckloadBrokers(this.state.brokerInformation)
            //update the row in the table
            this.props.updateRow(this.state.brokerInformation)
            //go back to table page
            this.props.switchToFormAndTable()
        }
        else{

        }
    }

    handleCancel = () => {
        this.props.switchToFormAndTable()
    }

    render() {
        return (
            <React.Fragment>
                <TopToolbar
                    pageName={FULL_TRUCKLOAD_BROKERS}
                    menuItems={[
                        {title: BACK, icon: <KeyboardBackspaceIcon/>, onClick: () => {this.props.switchToFormAndTable()}},
                    ]}
                />

                <Tabs
                    value={this.props.selectedTab}
                    onChange={(event, value) => this.props.handleTabChange(value)}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab label= {FTL_PAGES.SETUP} />
                    <Tab label= {FTL_PAGES.BROKERS} />
                    <Tab label= {FTL_PAGES.CARRIERS} />
                </Tabs>

                <div>
                <div style={{margin: "25px"}}>
                </div>

                <div className="ftl-address-section">
                    <Box borderRadius="borderRadius" {...defaultProps} style={{borderColor: "lightgray", borderRadius: "5px"}}>
                        <Typography variant="h6" gutterBottom component="div" className="ftl-form-header">
                            {NEW_BROKER}
                            <hr/>
                        </Typography>

                        <div className="ftl-row-spec">
                            <TextField
                                style={{margin: '10px', width: '200px'}}
                                variant="outlined"
                                name="brokerInformationContactName"
                                label="Contact Name"
                                value={this.state.brokerInformation.contactName}
                                onChange={(e) => {
                                    let newBrokerInformation = this.state.brokerInformation
                                    newBrokerInformation.contactName = e.target.value
                                    this.setState({
                                        brokerInformation: newBrokerInformation
                                    })
                                }}
                                error={this.state.showError&&this.state.brokerInformation.contactName===''}
                                size="medium"
                                InputLabelProps={{shrink: true}}
                            />

                            <TextField
                                style={{margin: '10px', width: '200px'}}
                                variant="outlined"
                                name="brokerInformationCompanyName"
                                label="Company Name"
                                value={this.state.brokerInformation.name}
                                onChange={(e) => {
                                    let newBrokerInformation = this.state.brokerInformation
                                    newBrokerInformation.name = e.target.value
                                    this.setState({
                                        brokerInformation: newBrokerInformation
                                    })
                                }}
                                error={this.state.showError&&this.state.brokerInformation.name===''}
                                size="medium"
                                InputLabelProps={{shrink: true}}
                            />

                            <TextField
                                style={{margin: '10px', width: '200px'}}
                                variant="outlined"
                                name="brokerInformationPhoneNumber"
                                label="Phone Number"
                                value={this.state.brokerInformation.phoneNumber}
                                onChange={(e) => {
                                    let newBrokerInformation = this.state.brokerInformation
                                    newBrokerInformation.phoneNumber = e.target.value
                                    this.setState({
                                        brokerInformation: newBrokerInformation
                                    })
                                }}
                                error={this.state.showError&&this.state.brokerInformation.phoneNumber===''}
                                size="medium"
                                InputLabelProps={{shrink: true}}
                            />
                        </div>

                        <div className="ftl-row-spec">
                            <TextField
                                style={{margin: '10px', width: '100%'}}
                                variant="outlined"
                                name="brokerInformationAccountNumber"
                                label="Account Number"
                                value={this.state.brokerInformation.accountNumber}
                                onChange={(e) => {
                                    let newBrokerInformation = this.state.brokerInformation
                                    newBrokerInformation.accountNumber = e.target.value
                                    this.setState({
                                        brokerInformation: newBrokerInformation
                                    })
                                }}
                                error={this.state.showError&&this.state.brokerInformation.accountNumber===''}
                                size="medium"
                                InputLabelProps={{shrink: true}}
                            />
                        </div>

                        <div className="ftl-row-spec">
                            <TextField
                                style={{margin: '10px', width: '100%'}}
                                variant="outlined"
                                name="brokerInformationAddressLine1"
                                label="Address Line 1"
                                value={this.state.brokerInformation.addressLine1}
                                onChange={(e) => {
                                    let newBrokerInformation = this.state.brokerInformation
                                    newBrokerInformation.addressLine1 = e.target.value
                                    this.setState({
                                        brokerInformation: newBrokerInformation
                                    })
                                }}
                                error={this.state.showError&&this.state.brokerInformation.addressLine1===''}
                                size="medium"
                                InputLabelProps={{shrink: true}}
                            />
                        </div>

                        <div className="ftl-row-spec">
                            <TextField
                                style={{margin: '10px', width: '100%'}}
                                variant="outlined"
                                name="brokerInformationAddressLine2"
                                label="Address Line 2"
                                value={this.state.brokerInformation.addressLine2}
                                onChange={(e) => {
                                    let newBrokerInformation = this.state.brokerInformation
                                    newBrokerInformation.addressLine2 = e.target.value
                                    this.setState({
                                        brokerInformation: newBrokerInformation
                                    })
                                }}
                                size="medium"
                                InputLabelProps={{shrink: true}}
                            />
                        </div>

                        <div className="ftl-row-spec">
                            <TextField
                                style={{margin: '10px', width: '200px'}}
                                variant="outlined"
                                name="brokerInformationCity"
                                label="City"
                                value={this.state.brokerInformation.city}
                                onChange={(e) => {
                                    let newBrokerInformation = this.state.brokerInformation
                                    newBrokerInformation.city = e.target.value
                                    this.setState({
                                        brokerInformation: newBrokerInformation
                                    })
                                }}
                                error={this.state.showError&&this.state.brokerInformation.city===''}
                                size="medium"
                                InputLabelProps={{shrink: true}}
                            />

                            <Autocomplete
                                style={{margin: '10px', width: '200px'}}
                                value={this.state.brokerInformation.stateOrProvince}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                error={this.state.showError&&this.state.brokerInformation.stateOrProvince===''}
                                options={this.props.stateAndProvinceMapping ? this.props.stateAndProvinceMapping.map(state => state.state) : []}
                                getOptionLabel={(entry) => entry}
                                autoHighlight={true}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        {option + " (" + this.props.stateAndProvinceMapping.find(state => state.state === option)?.displayName + ")"}
                                    </React.Fragment>
                                )}
                                name="brokerInformationStateOrRegion"
                                onChange={(event, value) => {
                                    let newBrokerInformation = this.state.brokerInformation
                                    newBrokerInformation.stateOrProvince = value
                                    this.setState({
                                        brokerInformation: newBrokerInformation
                                    })
                                }}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="State or Province"
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        name="brokerInformationStateOrRegion"
                                        error={this.state.showError&&this.state.brokerInformation.stateOrProvince===''}
                                    />
                                }
                            />

                            <TextField
                                style={{margin: '10px', width: '200px'}}
                                variant="outlined"
                                name="brokerInformationPostalCode"
                                label="Postal Code"
                                value={this.state.brokerInformation.postalCode}
                                onChange={(e) => {
                                    let newBrokerInformation = this.state.brokerInformation
                                    newBrokerInformation.postalCode = e.target.value
                                    this.setState({
                                        brokerInformation: newBrokerInformation
                                    })
                                }}
                                error={this.state.showError&&this.state.brokerInformation.postalCode===''}
                                size="medium"
                                InputLabelProps={{shrink: true}}
                            />
                        </div>

                        <div className="ftl-row-spec">
                            <Autocomplete
                                style={{margin: '10px', width: '200px'}}
                                value={this.state.brokerInformation.country}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                options={COUNTRY_OPTIONS}
                                getOptionLabel={(entry) => entry}
                                autoHighlight={true}
                                error={this.state.showError&&this.state.brokerInformation.country===''}
                                name="brokerInformationCountry"
                                onChange={(event, value) => {
                                    let newBrokerInformation = this.state.brokerInformation
                                    newBrokerInformation.country = value
                                    this.setState({
                                        brokerInformation: newBrokerInformation
                                    })
                                }}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Country"
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        name="brokerInformationCountry"
                                        error={this.state.showError&&this.state.brokerInformation.country===''}
                                    />
                                }
                            />

                            <TextField
                                style={{margin: '10px', width: '420px'}}
                                variant="outlined"
                                name="brokerInformationEmail"
                                label="Email"
                                value={this.state.brokerInformation.email}
                                onChange={(e) => {
                                    let newBrokerInformation = this.state.brokerInformation
                                    newBrokerInformation.email = e.target.value
                                    this.setState({
                                        brokerInformation: newBrokerInformation
                                    })
                                }}
                                error={this.state.showError&&this.state.brokerInformation.email===''}
                                size="medium"
                                InputLabelProps={{shrink: true}}
                            />
                        </div>
                    </Box>
                </div>
                <div className="ftl-stepper-buttons">
                    <Button
                        style={{marginRight: "10px"}}
                        variant='contained'
                        onClick={this.handleCancel}
                    >
                        {CANCEL}
                    </Button>


                    <Button
                        style={{marginRight: "10px"}}
                        variant='contained'
                        onClick={this.handleSaveBroker}
                    >
                        {SAVE}
                    </Button>
                </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
})

const actionCreators = {
    saveFullTruckloadBrokers
}

export default withShipment({
    mapStateToProps,
    actionCreators,
}, FullTruckloadBrokersForm);