import {
    call,
    put,
    takeLatest,
} from 'redux-saga/effects';
import axios from 'axios';

// constants for endpoints
import {
    PURCHASE_ORDERS, TICKETS
} from "../../../components/global/Server/endpoints";
import { HTTP_SUCCESS } from "../../constants/global";


// constants for actions
import {
    FETCH_ALL_SUPPLIERS,
    CREATE_NEW_PURCHASE_ORDER,
    CREATE_SEND_NEW_PURCHASE_ORDER,
    GET_PDF_PREVIEW,
    FETCH_ALL_PURCHASE_ORDERS,
    UPDATE_PURCHASE_ORDER,
    DELETE_INVENTORY_RECEIVED,
    PREVIEW_CREATED_PURCHASE_ORDER,
    CANCEL_PURCHASE_ORDER,
    EDIT_PURCHASE_ORDER,
    UNDO_CANCEL,
    PREVIEW_PACKING_SLIP_FILE, DELETE_PACKING_SLIP, UPDATE_EXPECTED_DELIVERY_DATE
} from "../../constants/purchaseOrders";

// secondary actions
import {
    storePDFpreviewReady,
    storeNewPurchaseOrders,
    storePartialPurchaseOrders,
    storeCompletedPurchaseOrders,
    storeAllPurchaseOrders,
    storeUpdatedPurchaseOrder, storeCreatedPurchaseOrderPreview, storeUpdatedPurchaseOrderPreview,
} from "../../actions/purchaseOrders";
import {
    clearStatus,
    requestStatus, setVoidOpen, setVoidStatus, hideError, showErrorMessage
} from "../../actions/global";
import {
    COMPLETED_PURCHASE_ORDER,
    NEW_PURCHASE_ORDER,
    PARTIAL_PURCHASE_ORDER
} from "../../../components/stages/constants";
import { fetchAlerts } from "../../actions/alerts";
import { SERVER_FAILED_ERROR_MESSAGE } from '../../../pages/Dashboard/DashboardFinal/constants';
import {storeUpdatedTicketWithPreview} from "../../actions/tickets";


const getHeaders = () => {
    const token = localStorage.getItem('token');
    return {
        "Authorization": `Bearer ${token}`
    }
}

const postRequest = (endpoint, data) => {
    return axios.post(endpoint, data, {
        headers: getHeaders()
    })
        .then(response => response)
}

const pdfRenderRequest = (endpoint, data, setPdfError) => {
    return axios.post(endpoint, data, {
        headers: getHeaders()
    })
        .then(response => {
            return response
        })
        .catch(err => {
            setPdfError(true)
        })
}

const getRequest = (endpoint) => {
    return axios.get(endpoint, {
        headers: getHeaders()
    })
        .then(response => response);
}

function* createNewPurchaseOrder(action) {
    yield call(clearStatus)
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(PURCHASE_ORDERS.CREATE_ORDER, action.data))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        yield put(requestStatus(status));
        if (response.data.success) {
            yield action.reset()
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* createSendNewPurchaseOrder(action) {
    yield call(clearStatus)
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(PURCHASE_ORDERS.CREATE_SEND_ORDER, action.data))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        yield put(requestStatus(status));

        yield action.reset()
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* editPurchaseOrder(action) {
    yield call(clearStatus)
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(PURCHASE_ORDERS.EDIT_PURCHASE_ORDER, action.purchaseOrderData))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        if (action.showPdf) {
            if (response.data.success) {
                yield put(storePDFpreviewReady(Uint8Array.from(response.data.pdf.bytes)))
            } else {
                yield put(storePDFpreviewReady(new Uint8Array(0)))
                yield put(showErrorMessage(response.data.message))
            }
        }

        yield put(requestStatus(status));

        yield action.reset()
    } catch (e) {
        if (action.showPdf) {
            yield put(showErrorMessage("Failed to load PDF"))
        } else {
            yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
        }
        yield put(storePDFpreviewReady(new Uint8Array(0)))
    }
}

function* getPurchaseOrderPreview(action) {
    yield call(clearStatus)
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(PURCHASE_ORDERS.PREVIEW_ORDER_PDF, action.pdfPreview))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        if (response.data.success) {
            yield put(storePDFpreviewReady(Uint8Array.from(response.data.pdf.bytes)))
        } else {
            yield put(storePDFpreviewReady(new Uint8Array(0)))
            yield put(showErrorMessage(response.data.message))
        }
        yield put(requestStatus(status));
    } catch (e) {
        yield put(showErrorMessage("Failed to load PDF"))
        yield put(storePDFpreviewReady(new Uint8Array(0)))
    }
}

function* fetchPurchaseOrders(action) {
    yield call(clearStatus)
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(PURCHASE_ORDERS.LIST_ALL_PURCHASE_ORDERS, action.data))

        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        if (response.data.success) {
            const newPurchaseOrders = yield [...response.data.list.filter(row => row.status === NEW_PURCHASE_ORDER)];
            const partialPurchaseOrders = yield [...response.data.list.filter(row => row.status === PARTIAL_PURCHASE_ORDER)];
            const completedPurchaseOrders = yield [...response.data.list.filter(row => row.status === COMPLETED_PURCHASE_ORDER)];

            yield put(storeNewPurchaseOrders(newPurchaseOrders))
            yield put(storePartialPurchaseOrders(partialPurchaseOrders))
            yield put(storeCompletedPurchaseOrders(completedPurchaseOrders))
            yield put(storeAllPurchaseOrders(response.data.list))
        } else {
            yield put(showErrorMessage(response.data.message))
        }
        yield put(requestStatus(status))
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* updatePurchaseOrderSaga(action) {
    yield call(clearStatus)
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(PURCHASE_ORDERS.UPDATE_PURCHASE_ORDER, action.purchaseOrder))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        if (response.data.success) {
            yield put(fetchAlerts(action.data))
            yield put(storeUpdatedPurchaseOrder(response.data.list[0]))
            action.callback();
        } else {
            yield put(showErrorMessage(response.data.message))
        }
        yield put(requestStatus(status))
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* deleteInventoryReceivedSaga(action) {
    yield call(clearStatus)
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(PURCHASE_ORDERS.DELETE_INVENTORY_RECEIVED, action.inventoryReceived))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        if (response.data.success) {
            yield put(fetchAlerts(action.data))
            yield put(storeUpdatedPurchaseOrder(response.data.list[0]))
            action.callback();
        } else {
            yield put(showErrorMessage(response.data.message))
        }
        yield put(requestStatus(status))
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* deletePackingSlipSaga(action) {
    yield call(clearStatus)
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(PURCHASE_ORDERS.DELETE_PACKING_SLIP, action.data))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        if (!response.data.success) {
            yield put(showErrorMessage(response.data.message));
        } else {
            yield put(storeUpdatedPurchaseOrder(response.data.po))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE));
    }
}

function* previewPackingSlipFileSaga(action) {
    yield call(clearStatus)
    yield put(hideError());
    try {
        const response = yield call(() => postRequest(PURCHASE_ORDERS.PREVIEW_PACKING_SLIP_FILE, action.previewRequest))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        if (!response.data.success) {
            yield put(showErrorMessage(response.data.message));
        } else {
            yield put(storeUpdatedPurchaseOrderPreview(response.data.po, response.data.index))
        }
        yield put(requestStatus(status))
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE));
    }
}

function* previewCreatedPurchaseOrderSaga(action) {
    yield call(clearStatus)
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(PURCHASE_ORDERS.PREVIEW_CREATED_PURCHASE_ORDER, { poNumber: action.purchaseOrderData }))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        if (response.data.success) {

            yield put(storeCreatedPurchaseOrderPreview(response.data.bytes))
        } else {
            yield put(showErrorMessage(response.data.message))
        }

        yield put(requestStatus(status))
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* cancelPurchaseOrderSaga(action) {
    yield call(clearStatus)
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(PURCHASE_ORDERS.CANCEL_PURCHASE_ORDER, action.purchaseOrderData))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        if (response.data.success) {
            yield put(storeUpdatedPurchaseOrder(response.data.list[0]))
        } else {
            yield put(showErrorMessage(response.data.message))
        }
        yield put(requestStatus(status))
        action.callback();
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* undoCancelPurchaseOrder(action) {
    yield call(clearStatus)
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(PURCHASE_ORDERS.UNDO_CANCEL, action.purchaseOrderData))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };

        if (response.data.success) {
            yield put(storeUpdatedPurchaseOrder(response.data.list[0]))
        } else {
            yield put(showErrorMessage(response.data.message))
        }
        yield put(requestStatus(status))
        action.callback();
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* updateExpectedDeliveryDate(action){
    yield call(clearStatus)
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(PURCHASE_ORDERS.UPDATE_EXPECTED_DELIVERY_DATE, action.data))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };

        if (response.data.success) {
            yield put(storeUpdatedPurchaseOrder(response.data.purchaseOrder))
        } else {
            yield put(showErrorMessage(response.data.message))
        }

    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

export default function* purchaseOrdersSagas() {
    yield takeLatest(CREATE_NEW_PURCHASE_ORDER, createNewPurchaseOrder);
    yield takeLatest(CREATE_SEND_NEW_PURCHASE_ORDER, createSendNewPurchaseOrder);
    yield takeLatest(FETCH_ALL_PURCHASE_ORDERS, fetchPurchaseOrders);
    yield takeLatest(GET_PDF_PREVIEW, getPurchaseOrderPreview);
    yield takeLatest(UPDATE_PURCHASE_ORDER, updatePurchaseOrderSaga)
    yield takeLatest(DELETE_INVENTORY_RECEIVED, deleteInventoryReceivedSaga);
    yield takeLatest(DELETE_PACKING_SLIP, deletePackingSlipSaga);
    yield takeLatest(PREVIEW_PACKING_SLIP_FILE, previewPackingSlipFileSaga);
    yield takeLatest(PREVIEW_CREATED_PURCHASE_ORDER, previewCreatedPurchaseOrderSaga);
    yield takeLatest(CANCEL_PURCHASE_ORDER, cancelPurchaseOrderSaga);
    yield takeLatest(EDIT_PURCHASE_ORDER, editPurchaseOrder);
    yield takeLatest(UNDO_CANCEL, undoCancelPurchaseOrder);
    yield takeLatest(UPDATE_EXPECTED_DELIVERY_DATE, updateExpectedDeliveryDate);
}
