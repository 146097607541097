export const CREATE_PURCHASE_ORDER = "Create Purchase Order"

export const LIST_PURCHASE_ORDERS_CELLS = [
    { id: 'poNumber', numeric: false, disablePadding: false, label: 'PO #' },
    { id: 'supplierName', numeric: false, disablePadding: false, label: 'Supplier' },
    { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
    { id: 'createDate', numeric: false, disablePadding: false, label: 'Ordered Date' },
    { id: 'expectedDate', numeric: false, disablePadding: false, label: 'Expected Delivery Date' },

    { id: 'preview', numeric: false, disablePadding: false, label: '' }

];

export const CREATE_PURCHASE_ORDERS_CELLS = [
    { id: 'shortcode', numeric: false, disablePadding: false, label: 'Shortcode' },
    { id: 'partNumberCol', numeric: false, disablePadding: false, label: 'Part #' },
    { id: 'description', numeric: false, disablePadding: false, label: 'Description' },
    { id: 'discount', numeric: false, disablePadding: false, label: 'Discount' },
    { id: 'skid', numeric: true, disablePadding: false, label: 'Skids / Pallets' },
    { id: 'qty', numeric: false, disablePadding: false, label: 'Quantity' },
];

export const CREATE_PO_CELLS_PRICING_ROLE = [
    { id: 'shortcode', numeric: false, disablePadding: false, label: 'Shortcode' },
    { id: 'partNumberCol', numeric: false, disablePadding: false, label: 'Part #' },
    { id: 'description', numeric: false, disablePadding: false, label: 'Description' },
    { id: 'price', numeric: false, disablePadding: false, label: 'Price' },
    { id: 'discount', numeric: false, disablePadding: false, label: 'Discount' },
    { id: 'skid', numeric: true, disablePadding: false, label: 'Skids / Pallets' },
    { id: 'qty', numeric: false, disablePadding: false, label: 'Quantity' },
];

export const EDIT_PURCHASE_ORDERS_CELLS = [
    { id: 'shortcode', numeric: false, disablePadding: false, label: 'Shortcode' },
    { id: 'quantityOrdered ', numeric: false, disablePadding: false, label: 'Quantity Ordered' },
    { id: 'quantityReceived', numeric: false, disablePadding: false, label: 'Quantity Received' },
    { id: 'quantityRemaining', numeric: false, disablePadding: false, label: 'Quantity Remaining' },

];

export const ROW_KEYS = ['supplierName', 'orderItems', 'poNumber'];

export const CANCEL = "Cancel";
export const PREVIEW = "Preview";
export const SUBMIT = "Submit";
export const PURCHASE_ORDERS_PER_PAGE = "Purchase Orders per page";
export const PENDING = "Pending";
export const RECEIVED = "Received";
export const ADD_PO_ENTRY = "Add an entry to the Purchase Order"
export const REMOVE_PO_ENTRY = "Remove this entry from the Purchase Order"
export const PURCHASE_ORDER = "Purchase Orders"

export const DEFAULT_SORT_BY_HEAD_CELL = 'createDate';
export const ROWS_PER_PAGE_LABEL = "Purchase orders per page"
export const DEFAULT_ROWS_PER_PAGE = 50;

export const NOTES = "Notes"
export const STATUS = "Status"

export const MAX_NOTES_LINES = 3;

export const FILTER = 'Filter';

export const PACKING_SLIP_INFO = "Upload packing slips by selecting files from your computer. When two or more pages of the packing slip are attached, the system will combine them into one document.";