import React from "react";
import './ChequeToPrint.css';
import './micr-e13b.ttf';

class ChequeToPrint extends React.Component {
    render() {
        return (
            <div className="check-container" style={{marginLeft:'14px'}}>
                <div style={{paddingTop: "16px"}}/>
                <header className="check-container__header-banner">
                    <div className="check-container__name-address">

                        <div className='nested-column'>
                            {this.props.companyAddress[0] && this.props.companyAddress[0].addressLine1 &&
                                <div style={{display: "flex", flexDirection: "column", textAlign: "left"}}>
                                    <div className='check-company'>
                                        {this.props.companyAddress[0].name}
                                    </div>
                                    {this.props.companyAddress[0].addressLine2 &&
                                        <div style={{marginTop: " -4px"}}>
                                            {this.props.companyAddress[0].addressLine2}
                                        </div>}
                                    <div style={{marginTop: " -4px"}}>
                                        {this.props.companyAddress[0].addressLine1}
                                    </div>
                                    <div style={{marginTop: "-4px"}}>
                                        {this.props.companyAddress[0].city}, {this.props.companyAddress[0].state} {this.props.companyAddress[0].zip}
                                    </div>

                                    <div style={{marginTop: " -4px"}}>
                                        {this.props.companyAddress[0].phone}
                                    </div>
                                </div>}
                        </div>
                    </div>

                    <div className='nested-column'>
                        {this.props.bankAccount && this.props.bankAccount.addressLine1 &&
                            <div style={{display: "flex", flexDirection: "column", textAlign: "left"}}>
                                <div className='check-bank'>
                                    {this.props.bankAccount.bankFullName}
                                </div>
                                {this.props.bankAccount.addressLine2 &&
                                    <div style={{marginTop: " -4px"}}>
                                        {this.props.bankAccount.addressLine2}
                                    </div>}
                                <div style={{marginTop: " -4px"}}>
                                    {this.props.bankAccount.addressLine1}
                                </div>
                                <div style={{marginTop: " -4px"}}>
                                    {this.props.bankAccount.city}, {this.props.bankAccount.state} {this.props.bankAccount.zip}
                                </div>
                                <div style={{marginTop: " -4px"}}>
                                    {this.props.bankAccount.phone}
                                </div>
                            </div>}
                    </div>

                    <div style={{textAlign: "right"}}>
                        <div className='check-number'>
                            <span style={{paddingRight: "8px"}}>{this.props.bankAccount.prefix}</span>
                            <span style={{marginRight: "16px"}}>{this.props.chequeNumber}</span>
                        </div>

                        <div className="check-container__date" style={{paddingTop: "40px"}}>
                            <span
                            ><label htmlFor="date-input" style={{marginRight: "8px"}}>DATE</label>
                            <span
                                className="check-container__date-border">{this.props.paymentDate}</span>
                            </span>
                        </div>
                    </div>
                </header>

                <main className="check-container__main-section" style={{marginTop:"-30px"}}>
                    <div className="check-container__payto">
              <span className="check-container__order">
                <label htmlFor="name-payto" className="check-container__payto-desc check-pay-to-the-order-of">
                  PAY TO THE ORDER OF
                </label>
                  <span id="name-payto"> {this.props.payToName}</span>
              </span>
                        <span className="check-container__dollar-part" style={{marginTop: "40px"}}
                        ><label htmlFor="dollar" className='check-dollar'>$</label>

                                    <span id="dollar" style={{ marginLeft: "8px"}}
                                          className="check-container__dollar-border check-dollar">
                                        {this.props.amount}
                                    </span>
                         </span>
                    </div>
                    <div className="check-container__dollar-word" style={{paddingTop: "10px"}}>
                    </div>
                    <div className="check-amount-words"
                         style={{marginTop: "-54px", marginLeft: "24px"}}>
                        {this.props.amountWords}
                    </div>
                </main>

                <footer className="check-container__footer" style={{marginTop: "78px"}}>
                    <div style={{marginTop: "-2px"}}>MEMO</div>
                    <div style={{marginLeft: "14px"}} className="check-container__memo">

                        <div style={{whiteSpace: "nowrap"}}>{this.props.memoo}</div>
                    </div>
                    <div className="check-container__signature">
                        {this.props.signature && (
                            <img style={{marginTop: "-48px", marginRight: "50px", marginLeft: "50px"}}
                                 src={this.props.signature} alt="Signature"/>
                        )}
                    </div>
                </footer>


                {this.props.bankAccount && this.props.bankAccount.transitNumber &&

                    <div className="micr">

                        C{parseInt(this.props.chequeNumber, 10).toString()}C
                        A21242D{this.props.bankAccount.institutionNumber}A {this.props.bankAccount.designationNumber}D{this.props.bankAccount.accountNumber}C</div>
                }
            </div>
        )
    }
}

export const ComponentChequeToPrint = React.memo(ChequeToPrint);
