import React from 'react';

import { LinearProgress, Box, Typography } from '@material-ui/core';



function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box width={props.width} mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>

            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

export default LinearProgressWithLabel;