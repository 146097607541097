export const SCAN_SCANNED_INPUT = 'SCAN_SCANNED_INPUT';
export const SCAN_PACKED_INPUT = 'SCAN_PACKED_INPUT';
export const GET_DASHBOARD_INFO = 'GET_DASHBOARD_INFO';
export const STORE_DASHBOARD_INFO = 'STORE_DASHBOARD_INFO';
export const STORE_SCAN_RESPONSE = 'STORE_SCAN_RESPONSE';
export const CLEAR_SCAN_RESPONSE = 'CLEAR_SCAN_RESPONSE';
export const STORE_PACKED_RESPONSE = 'STORE_PACKED_RESPONSE';
export const CLEAR_PACKED_RESPONSE = 'CLEAR_PACKED_RESPONSE';
export const STORE_SCANNED_BARCODES = 'STORE_SCANNED_BARCODES';
export const UPDATE_SCANNED_BARCODE = 'UPDATE_SCANNED_BARCODE';
export const STORE_PACKED_BARCODES = 'STORE_PACKED_BARCODES';
export const UPDATE_PACKED_BARCODE = 'UPDATE_PACKED_BARCODE';
export const STORE_PENDING_PACKED_BARCODES = 'STORE_PENDING_PACKED_BARCODES';
export const UPDATE_PENDING_PACKED_BARCODE = 'UPDATE_PENDING_PACKED_BARCODE';
export const STORE_TOTAL_BARCODES = 'STORE_TOTAL_BARCODES';
export const MANUAL_PACK = 'MANUAL_PACK';
export const MANUAL_VOID = 'MANUAL_VOID';
export const SET_REFRESH = 'SET_REFRESH'
export const DASHBOARD_REFRESH = 'DASHBOARD_REFRESH'