import axios from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import {
    SAVE_DAMAGED_ENTRY,
    FIND_PO_INFORMATION,
    LIST_ALL_DAMAGES, DELETE_DAMAGE_ENTRY, UPDATE_DAMAGE_ENTRY, LIST_SPECIFIC_DAMAGE,
} from "../../constants/damage";
import {DAMAGE} from "../../../components/global/Server/endpoints";
import {
    storeAllDamages,
    storeDamagedEntry,
    storePoInformation, storeSpecificDamage,
} from "../../actions/damage";
import { hideError, requestStatus, setLoadingStatus, showErrorMessage } from "../../actions/global";
import { fetchAlerts } from "../../actions/alerts";
import { HTTP_SUCCESS } from "../../constants/global";
import { SERVER_FAILED_ERROR_MESSAGE } from "../../../pages/Dashboard/DashboardFinal/constants";



const getHeaders = () => {
    const token = localStorage.getItem('token');
    return {
        "Authorization": `Bearer ${token}`
    }
}

const postRequest = (endpoint, data) => {
    return axios.post(endpoint, data, {
        headers: getHeaders()
    })
        .then(response => response)
}

const getRequest = (endpoint) => {
    return axios.get(endpoint, {
        headers: getHeaders()
    })
        .then(response => response);
}


function* saveDamagedEntry(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(DAMAGE.SAVE_DAMAGED_ENTRY, action.data))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === 200
        };
        if (response.data.success) {
            yield put(storeDamagedEntry(response.data))
            yield action.reset()
        } else {
            yield put(showErrorMessage("Failed to save damage entry"))
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* findPoInformation(action) {
    yield put(hideError())
    try {
        const data = {'poNumber': action.data}
        const response = yield call(() => postRequest(DAMAGE.FIND_PO_INFORMATION, data))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status=== 200
        };
        if (response.data.success) {
            yield put(storePoInformation(response.data))
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* listAllDamages(action) {
    yield put(hideError())
    const response = yield call(() => postRequest(DAMAGE.LIST_ALL_DAMAGES, {locationId: action.locationId}))
    const status = {
        status: response.status,
        statusText: response.statusText,
        success: response.status=== 200
    };
    if (response.data.success) {
        yield put(storeAllDamages(response.data))
    }
}

function* listSpecificDamageSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(DAMAGE.FETCH_SPECIFIC_DAMAGE, {damageId: action.damageId}))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status=== 200
        };
        if (response.data.success) {
            yield put(storeSpecificDamage(response.data.damage))
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* deleteDamageEntry(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(DAMAGE.DELETE_DAMAGE_ENTRY, {damageId: action.damageId}))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === 200
        }
        if (response.data.success) {
            yield action.reset()
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

    function* updateDamageEntry(action) {
        yield put(hideError())
        try {
            const response = yield call(() => postRequest(DAMAGE.UPDATE_DAMAGE_ENTRY, action.data))
            const status = {
                status: response.status,
                statusText: response.statusText,
                success: response.status === 200
            }
            if (response.data.success) {
                yield action.reset()
            } else {
                try {
                    yield put(showErrorMessage(response.data.message))
                } catch (error) {
                    yield put(showErrorMessage("Failed to update damage entry"))
                }
            }
        } catch (error) {
            yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
        }
    }






export default function* damageSagas() {
    yield takeLatest(SAVE_DAMAGED_ENTRY, saveDamagedEntry);

    yield takeLatest(FIND_PO_INFORMATION, findPoInformation);

    yield takeLatest(LIST_ALL_DAMAGES, listAllDamages);
    yield takeLatest(LIST_SPECIFIC_DAMAGE, listSpecificDamageSaga);

    yield takeLatest(DELETE_DAMAGE_ENTRY, deleteDamageEntry);

    yield takeLatest(UPDATE_DAMAGE_ENTRY, updateDamageEntry);
}
