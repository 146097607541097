import React from 'react';
import { CircularProgress } from "@material-ui/core";
import styles from './Loding.module.css';

class Loading extends React.PureComponent {
  render() {
    return(
        <div className={styles.loader}>
          <p>Loading Content...</p>
          <CircularProgress />
        </div>
    );
  }
}

export default Loading;
