import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";

// import tables
import ConditionFiltersTable from "./ConditionFilters/ConditionFiltersTable";
import ConditionActionsTable from "./ConditionActions/ConditionActionsTable";

// import styling
import "./ConditionDialog.css";

import { APPLY, CANCEL } from "../global/constants";

// import constants
import {
  TABLE_TYPES,
} from "./constants";
import PropTypes from "prop-types";
import AutomationRulesTableToolbar from "./AutomationRulesTableToolbar";
import {
  SET_INVOICE_BANK_ACCOUNT, SET_INVOICE_PAYMENT_DATE,
  SET_INVOICE_REFERENCE_ID,
  SET_INVOICE_STATUS,
  SET_NUMBER_OF_PACKAGES
} from "./ConditionActions/constants";

class ConditionDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedFilters: [], // selected filters - we store this here and not the table
      selectedActions: [], // selected actions - we store this here and not the table
    };
  }

  isSelected = (name, isFilter) => {
    // handle filter or action accordingly
    if (isFilter) {
      return this.state.selectedFilters.findIndex((item) => item.name === name) !== -1;
    } else {
      return this.state.selectedActions.findIndex((item) => item.name === name) !== -1;
    }
  };

  handleSelectAllClick = (event, rows, isFilter) => {
    // handle filter or action accordingly
    let selected = isFilter ? [...this.state.selectedFilters] : [...this.state.selectedActions];
    if (event.target.checked) {
      selected = rows;
    } else {
      selected = [];
    }

    //remove the set number of packages if it already exists (note that it does not matter if it is a filter or not)
    selected = this.props.hasSetPackageNumber ? selected.filter((action) => {return action.name !== SET_NUMBER_OF_PACKAGES}): selected
    selected = !this.props.showInvoiceActions? selected.filter((action) => {return action.name !== SET_INVOICE_REFERENCE_ID && action.name !== SET_INVOICE_BANK_ACCOUNT
        && action.name !== SET_INVOICE_STATUS && action.name !== SET_INVOICE_PAYMENT_DATE}): selected
    // handle filter or action accordingly
    isFilter ? this.setState({selectedFilters: selected,}) : this.setState({selectedActions: selected});

  };

  handleClick = (event, filter, isFilter) => {
    // make a copy of the current selected items
    let selected = isFilter ? [...this.state.selectedFilters] : [...this.state.selectedActions];

    // first check if this row actually exists in the list of selections
    let index = -1;
    index = selected.findIndex((item) => item.name === filter.name);

    // if it does not exist, add the item else remove it
    if (index === -1) {
      selected = [...selected, filter];
    } else {
      selected.splice(index, 1);
    }

    //remove the set number of packages if it already exists (note that it does not matter if it is a filter or not)
    selected = this.props.hasSetPackageNumber ? selected.filter((action) => {return action.name !== SET_NUMBER_OF_PACKAGES}): selected
    selected = !this.props.showInvoiceActions? selected.filter((action) => {return action.name !== SET_INVOICE_REFERENCE_ID && action.name !== SET_INVOICE_BANK_ACCOUNT
        && action.name !== SET_INVOICE_STATUS && action.name !== SET_INVOICE_PAYMENT_DATE}): selected
    // update the state with actions or filters accordingly
    isFilter ? this.setState({selectedFilters: selected,}) : this.setState({selectedActions: selected});

  };

  onApply = (isFilter) => {
    // apply selectedFilters or actions accordingly
    this.props.onApply(isFilter ? this.state.selectedFilters : this.state.selectedActions);
    // reset both state variables to empty arrays
    // no need to check which one to reset based off of isFilter, since they will both be empty anyway
    this.setState({
      selectedFilters: [],
      selectedActions: []
    });
  };

  closeDialog = () => {
    this.setState({
      selectedFilters: [],
      selectedActions: []
    });
    this.props.onClose(false)
  };

  renderCorrectTable = () => {
    switch (this.props.tableType) {
      case TABLE_TYPES.FILTER:
        return (
            <ConditionFiltersTable
                selected={this.state.selectedFilters}
                isSelected={this.isSelected}
                handleClick={this.handleClick}
                handleSelectAllClick={this.handleSelectAllClick}
                ruleCategory = {this.props.ruleCategory}
            />
        )
      case TABLE_TYPES.ACTION:
        return (
            <ConditionActionsTable
                selected={this.state.selectedActions}
                isSelected={this.isSelected}
                handleClick={this.handleClick}
                handleSelectAllClick={this.handleSelectAllClick}
                hasSetPackageNumber={this.props.hasSetPackageNumber}
                ruleCategory = {this.props.ruleCategory}
                showInvoiceActions = {this.props.showInvoiceActions}
            />
        )
    }
  }

  render() {

    let classname = this.props.tableType === TABLE_TYPES.FILTER ? 'conditionFiltersDialogContent' : 'conditionActionsDialogContent'

    return (
      <Dialog
        open={this.props.isOpen}
        onClose={() => this.props.onClose(false)}
      >
        <DialogContent className={classname}>
          {this.renderCorrectTable()}{" "}
        </DialogContent>{" "}
        <DialogActions>
          <Button onClick={() => this.closeDialog()} color='primary'>
            {" "}
            {CANCEL}{" "}
          </Button>{" "}
          <Button onClick={() => this.onApply(this.props.tableType === TABLE_TYPES.FILTER)} color='primary'>
            {" "}
            {APPLY}{" "}
          </Button>{" "}
        </DialogActions>{" "}
      </Dialog>
    );
  }
}

ConditionDialog.propTypes = {
  numSelected: PropTypes.number,
  selected: PropTypes.array,
  redirect: PropTypes.func,
  prepEdit: PropTypes.func,
  handleCopy: PropTypes.func,
  handleDelete: PropTypes.func,
  hasSetPackageNumber: PropTypes.bool
}

ConditionDialog.defaultProps = {
  numSelected: 0,
  selected: [],
  redirect: ()=>{},
  prepEdit: ()=>{},
  handleCopy: ()=>{},
  handleDelete: ()=>{},
  hasSetPackageNumber: false
}

export default ConditionDialog;
