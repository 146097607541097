
export const PACKAGE_DETAILS = "Package Details";

export const ADD_PACKAGE = "Add Package";
export const COPY_PACKAGE = "Duplicate Package";
export const DELETE_PACKAGE = "Delete Package";
export const REPRINT_LABEL = "Reprint Label";
export const CURRENCIES = [
    "USD",
    "CAD",
]

export const ADD_TRACKING_NUMBER = "Add Tracking Number";
export const DELETE_TRACKING_NUMBER = "Delete Tracking Number";