import {
    STORE_RETURN_ACTIONS,
    STORE_RETURN_CONDITIONS,
    STORE_RETURN_REASONS,
    STORE_RETURNS, STORE_RMA_FETCH, STORE_DUPLICATE_RMAS,
    STORE_UPDATED_RETURN, STORE_UPDATED_RETURN_WITH_PREVIEW,
    UPDATE_RETURN
} from "../../constants/returns";

import { updateList } from "../global";

const initialState = {
    returns: [],
    returnActions: [],
    returnConditions: [],
    returnReasons: [],
    duplicateRmas: [],
}

const addPreviewToReturn = (updatedReturnItem, oldReturns) => {
    let filePreviewInfo = updatedReturnItem.files.pop()
    let previewedFileIndex = filePreviewInfo.indexOfPreviewedFile
    let previewData = new Uint8Array(filePreviewInfo.previewedFile)
    let blobData = new Blob([previewData])
    updatedReturnItem.files[previewedFileIndex]["localURL"] = URL.createObjectURL(blobData)
    updatedReturnItem.files[previewedFileIndex]['byteArray'] = previewData

    let returnToUpdate = oldReturns.find((returnItem) => { return returnItem.returnId === updatedReturnItem.returnId })
    returnToUpdate.files[previewedFileIndex] = updatedReturnItem.files[previewedFileIndex]

    return updateList(returnToUpdate, oldReturns, "returnId")
}

export default (state = initialState, action) => {
    switch (action.type) {

        case STORE_RETURNS:
            return {
                ...state,
                returns: action.returnItems
            }
        case STORE_UPDATED_RETURN:
            return {
                ...state,
                returns: updateList(action.updatedReturnItem, state.returns, 'returnId')
            }
        case STORE_RETURN_ACTIONS:
            return {
                ...state,
                returnActions: action.returnActions
            }
        case STORE_RETURN_CONDITIONS:
            return {
                ...state,
                returnConditions: action.returnConditions
            }
        case STORE_RETURN_REASONS:
            return {
                ...state,
                returnReasons: action.returnReasons
            }
        case STORE_RMA_FETCH:
            return{
                ...state,
                rmaFetch: action.rmaFetch
            }
        case STORE_DUPLICATE_RMAS:
            return{
                ...state,
                duplicateRmas: action.duplicateRmas
            }

        case STORE_UPDATED_RETURN_WITH_PREVIEW:
            return {
                ...state,
                returns: addPreviewToReturn(action.updatedReturnItem, state.returns)
            }
        default:
            return state
    }
}