import React from 'react'
import PropTypes from "prop-types";
import DeleteIcon from "@material-ui/icons/Delete";
import {IconButton, TableCell} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Box from '@material-ui/core/Box'


//TODO May want to send this to the DeleteSection and clean this up
class DeleteUser extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            //isDelete: false,
        }
    }

    componentDidMount() {
        this.setState({
            username: this.props.user.username
        })
    }

    handleDelete = () => {
        const data = {
            admin: this.props.admin.username,
            username: this.props.user.username,
            is_linked_user: (this.props.user.username !== this.props.admin.username),
        }
        this.props.deleteUser(data, this.props.handleOpen)
        this.props.handleDeleteFalse(this.props.index)
    }

    render() {
        return (
            <TableCell
                padding="default"
                className='cellPadding'
                width="20%"
            >
                {this.props.isDelete ?
                    <div align="left">
                        <Button
                            onClick={() => this.props.handleDeleteFalse(this.props.index)}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            startIcon={<DeleteIcon/>}
                            onClick={this.handleDelete}
                        >
                            Delete
                        </Button>
                    </div>
                    :
                    <div>
                        <IconButton onClick={() => this.props.handleDeleteTrue(this.props.index)}>
                            <DeleteIcon/>
                        </IconButton>
                    </div>


                }
            </TableCell>

        )
    }
}

DeleteUser.propTypes = {
    admin: PropTypes.object,
    user: PropTypes.object,
    deleteUser: PropTypes.func,
    handleDeleteTrue: PropTypes.func,
    handleDeleteFalse: PropTypes.func,
    index: PropTypes.object,
    handleOpen: PropTypes.func,
}

DeleteUser.defaultProps = {
    admin: {},
    user: {},
    deleteUser: () => {},
    handleDeleteTrue: () => {},
    handleDeleteFalse: PropTypes.func,
    index: {},
    handleOpen: ()=>{},
}

export default DeleteUser