import React from "react";
import {Button, Paper, ClickAwayListener, TextField} from "@material-ui/core";
import "./Transactions.css"
import Autocomplete from "@material-ui/lab/Autocomplete";
import PropTypes from "prop-types";
import withShipment from "../../../withShipment";

class ImportTransactionsPopper extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedCategory: null,
            allCategories: this.props.categories || []
        }
    }

    componentDidMount() {
        this.insertUnCategorizedOption()

    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {

        if (this.props.categories !== prevProps.categories) {
            this.insertUnCategorizedOption()
        }
    }

    insertUnCategorizedOption = () => {
        let tempList = [...this.props.categories];  // Create a copy of the array
        tempList.splice(1, 0, { name: "UnCategorized",  "displayOrder": 1,
            "version": null,
            "isDefault": true});  // Insert the new object at index 1

        this.setState({
            allCategories: tempList
        });
    }

    handleClose = () => {
        this.props.handleClosePopper()
    }

    handleSave = () => {
        this.props.callback(this.state.selectedCategory)
        this.props.handleClosePopper()
    }

    render() {
        return (
            <>
                <ClickAwayListener onClickAway={this.handleClose}>
                    <Paper className="import-popper-container">
                        <div className="import-popper-item">
                            <Autocomplete
                                options={this.state.allCategories}
                                getOptionLabel={(option) => `${option.name} `}
                                value={this.state.selectedCategory}
                                onChange={(e, value) => this.setState({selectedCategory: value})}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        name="category"
                                        label="Select a Category"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                }
                                style={{width: "250px"}}
                            />
                        </div>
                        <div className="supplier-payment-popper-buttons">
                            <Button variant='contained' onClick={this.handleClose} style={{width: '100px'}}>Close</Button>
                            <Button variant='contained' onClick={this.handleSave} color="primary" style={{width: '100px'}}>Save</Button>
                        </div>
                    </Paper>
                </ClickAwayListener>
            </>
        )
    }
}

ImportTransactionsPopper.propTypes = {
    bankAccounts: PropTypes.array
}

ImportTransactionsPopper.defaultProps = {
    bankAccounts: [],
}

export default withShipment({
}, ImportTransactionsPopper);