import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { getRoutes, getSubmenuRoutes, protectedRoutes, routes } from '../../routes';
import './Base.css';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import LeftMenu from '../../components/leftmenu/LeftMenu';
import PendingShipments from "../../components/stages/PendingShipments";
import AccessDenied from "../Errors/AccessDenied";

// redux
import withShipment from "../../withShipment";
import { userInfoSelector, isAuthenticatedSelector } from "../../redux/selectors/auth";
import { authLogout } from "../../redux/actions/auth";
import { ROUTE_TYPE } from "../../routes/constants";
import Login from "../Login/Login";
import { showingMenuItemsSelector } from "../../redux/selectors/global";
import PropTypes from "prop-types";
import DashboardFinal from "../Dashboard/DashboardFinal/DashboardFinal";
import Slide from "@material-ui/core/Slide";
import { Drawer } from 'material-ui';
import { Fade } from '@material-ui/core';

class Base extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() { }

  protectRoutes = () => {
    const userRoutes = this.props.userInfo.routes ? [...this.props.userInfo.routes] : []
    return getRoutes().filter(route => userRoutes.includes(route.id)).concat(routes)
  }

  leftMenuRoutes = () => {
    let displayedRoutes = []
    const userRoutes = this.props.userInfo.routes ? [...this.props.userInfo.routes] : []
    let dividerCount = 0
    protectedRoutes.map(route => {
      if (route.type === ROUTE_TYPE.DIVIDER) {
        if (dividerCount == 0) {
          displayedRoutes = [...displayedRoutes, route]
          dividerCount++
        }
      } else if (userRoutes.includes(route.id)) {
        displayedRoutes = [...displayedRoutes, route]
        dividerCount = 0
      }
    }
    )
    return displayedRoutes
  }

  render() {
    return (
      <>

        <div className='custom-container-fluid'> {/* prevents a horizontal scroll bar on the entire app */}
          <div style={{width: "100%"}}>

            {this.props.isAuthenticated && <Header logout={this.props.authLogout} email={this.props.userInfo.username}userRoutes={[...this.props.userInfo.routes]} routes={this.leftMenuRoutes()} />}

            <div className='custom-container-fluid'>
              <div className='leftContainer'>
                {this.props.isAuthenticated ?

                  <>
                    {window.screen.width > 800 ?
                      <Slide direction='right' in={this.props.showingMenuItems} mountOnEnter unmountOnExit>
                        <LeftMenu routes={this.leftMenuRoutes()} userRoutes={[...this.props.userInfo.routes]} isDrawer={false} />
                      </Slide>
                      :
                      null
                    }
                  </>
                  : null
                }

              </div>

          <div className={this.props.showingMenuItems && window.screen.width > 800 && this.props.isAuthenticated ? 'rightContainer' : 'fullWidthContainer'}>
            <Switch>
              {
                this.protectRoutes().map(route => {
                  return (
                    <Route
                      key={route.id}
                      component={route.component}
                      path={route.path}
                    />
                  )
                })
              }
              {/* need to change access denied to home page */}

                  <Route key='base' component={this.props.isAuthenticated ? DashboardFinal : Login} path='/' exact />
                </Switch>
              </div>

            </div>
            <Footer />

          </div>
        </div>

      </>
    );
  }
}

Base.propTypes = {
  userInfo: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  showingMenuItems: PropTypes.bool
}

Base.defaultProps = {
  userInfo: {},
  isAuthenticated: false,
  showingMenuItems: true
}

const mapStateToProps = (state) => ({
  userInfo: userInfoSelector(state),
  isAuthenticated: isAuthenticatedSelector(state),
  showingMenuItems: showingMenuItemsSelector(state)

})

const actionCreators = {
  authLogout,
}

export default withShipment({
  mapStateToProps,
  actionCreators
}, Base)
