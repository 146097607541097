import React from "react";
import withShipment from "../../../withShipment";
import './PricingDashboardLoading.css';
import {PRICING_DASHBOARD_PAGE_NAME, PRICING_DASHBOARD_PAGES, LOADING_MESSAGE} from "../constants";
import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";
import {Typography} from "@mui/material";
import {CircularProgress} from "@material-ui/core";
import {pricingDashboardLoadingSelector} from "../../../redux/selectors/global";


class PricingDashboardLoading extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }

        if (!this.props.pricingDashboardLoading) {
            this.props.setCurrentPage(PRICING_DASHBOARD_PAGES.TABLE)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.pricingDashboardLoading) {
            this.props.setCurrentPage(PRICING_DASHBOARD_PAGES.TABLE)
        }
    }

    render() {
        return (
            <React.Fragment>
                <TopToolbar
                    pageName={PRICING_DASHBOARD_PAGE_NAME}
                />
                <div className="getPrinterLoadingOuterBox">
                    <Typography variant="h6">
                        {LOADING_MESSAGE}
                    </Typography>
                    <div className="loadingBox">
                        <CircularProgress size={100} />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

PricingDashboardLoading.defaultProps = {
}

PricingDashboardLoading.propTypes = {
}

const mapStateToProps = (state) => ({
    pricingDashboardLoading: pricingDashboardLoadingSelector(state),
})

const actionCreators = {
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, PricingDashboardLoading);