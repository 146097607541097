import React from 'react';

// material components
import TextField from "@material-ui/core/TextField";
import {IconButton, Slide, TableContainer, TablePagination} from "@material-ui/core";
import Table from "@material-ui/core/Table";

// custom components
import SearchableTable from "../global/Search/SearchableTable";
import GlobalTableHead from "../global/Table/GlobalTableHead";

// constants
import {
    TICKETS_TABLE_HEAD_CELLS,
    ROWS_PER_PAGE_LABEL,
    SHOW_COMPLETED_TICKETS,
    SHOW_MY_TICKETS,
    TICKETS_TOOLBAR_TITLE
} from "./constants";
import { ASC, DESC } from "../global/constants";

// styling
import './TicketsTable.css';
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PropTypes from "prop-types";

import "../global/TableHeaderRow.css"


import EditIcon from "@material-ui/icons/Edit";
import CustomTableHeader from "../global/Table/CustomTableHeader";
import TwoStepDelete from "../global/subcomponents/twoStepDelete/TwoStepDelete";
import PurchaseOrderFiltersDialog from "../purchaseOrders/PurchaseOrderFiltersDialog";
import Tooltip from "@material-ui/core/Tooltip";
import {FILTER} from "../purchaseOrders/constants";
import FilterIcon from "../shipments/FilterIcon";
import withShipment from "../../withShipment";
import {userInfoSelector} from "../../redux/selectors/auth";
import TicketFiltersDialog from "./TicketFiltersDialog";
import LocationSelectBox from "../global/subcomponents/locationSelectBox/LocationSelectBox";
class TicketsTable extends SearchableTable {
    constructor(props) {
        super(props);
        this.state = {
            order: "",
            orderBy: "",
            selectedOrder: [],
            isSearch: false,
            searchValue: '',
            searchRows: 0,
            showMyTickets: false,
            showCompletedTickets:false,
            deleteId: "",
            filtersOpen: false,
            dateFiltersMap: {},
            filtersMapBeforeOpenDialog: {},
            fromDate: this.getFromDate(),
            toDate: this.getToDate(),
            tableFiltersMap: [],
            statusFiltersMap: []
        }
    }

     componentDidMount() {
        let changeBack = false
         this.setState({
              statusFiltersMap:this.props.filters,
             showMyTickets: this.props.tableSetting?.ticketsFilterSetting?.showMyTickets,
             showCompletedTickets:this.props.tableSetting?.ticketsFilterSetting?.showCompletedTickets,
             searchValue: this.props.searchText,
             order: this.props.tableSetting?.ticketsTableSetting?.find(i => i.sortIndex === 1)?.sortOrder,
             orderBy: this.props.tableSetting?.ticketsTableSetting?.find(i => i.sortIndex === 1)?.id
        })

        let storage = JSON.parse(localStorage.getItem("Tickets"))
        if (storage) {
            this.getNewDates()
            const expiry = parseInt(JSON.parse(localStorage.getItem("expiry")))
            if (storage.fromDate !== null && storage.fromDate !== undefined) {
                if (expiry > new Date().getTime()) {
                    this.closeFiltersWithoutRefresh()
                } else {
                    changeBack = true
                }
            }
            if (storage.toDate !== null && storage.toDate !== undefined) {
                if (expiry > new Date().getTime()) {
                    this.closeFiltersWithoutRefresh()
                } else {
                    changeBack = true
                }
            }
            if (changeBack) {
                localStorage.removeItem("Tickets")
                this.setState({
                    fromDate: this.getFromDate(),
                    toDate: this.getToDate(),
                    dateFiltersMap: {}
                })
            }
        }
    }

    closeFiltersWithoutRefresh = () => {
        this.setState({
            filtersOpen: false,
        });

        let filters = {}
        filters = { ...this.state.dateFiltersMap }
        const expiry = new Date().setHours(23, 59, 59, 0);
        if (filters.fromDate !== null && filters.fromDate !== undefined) {
            let existing = localStorage.getItem("Tickets")
            existing = existing ? JSON.parse(existing) : {};
            existing.fromDate = filters.fromDate;
            localStorage.setItem("Tickets", JSON.stringify(existing));
            localStorage.setItem("expiry", JSON.stringify(expiry))
        }
        if (filters.toDate !== null && filters.toDate !== undefined) {
            let existing = localStorage.getItem("Tickets")
            existing = existing ? JSON.parse(existing) : {};
            existing.toDate = filters.toDate;
            localStorage.setItem("Tickets", JSON.stringify(existing));
            localStorage.setItem("expiry", JSON.stringify(expiry))
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let tableFiltersMap = this.props.tableSetting?.ticketsTableSetting
        if (prevProps.tableSetting !== this.props.tableSetting) {
            this.setState({
                showMyTickets: this.props.tableSetting?.ticketsFilterSetting?.showMyTickets,
                showCompletedTickets: this.props.tableSetting?.ticketsFilterSetting?.showCompletedTickets,
                tableFiltersMap: tableFiltersMap,
                order: tableFiltersMap.find(i => i.sortIndex === 1).sortOrder,
                orderBy: tableFiltersMap.find(i => i.sortIndex === 1).id
            })
        }
        if (prevProps.searchText !== this.props.searchText || this.state.searchValue !== this.props.searchText) {
            this.initializeSearchText()
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let tableFiltersMap = this.props.filters
        if (prevProps.filters !== this.props.filters) {
            this.setState({
                statusFiltersMap:this.props.filters,
            })
        }
    }

    getNewDates = () => {
        let filter = {...this.state.dateFiltersMap}
        let storage = JSON.parse(localStorage?.getItem("Tickets"))
        if (storage.fromDate !== null && storage.fromDate !== undefined ) {
            const temp = new Date(storage.fromDate)
            filter.fromDate = temp;
            this.setState({
                fromDate: temp
            })
        }
        if (storage.toDate !== null && storage.toDate !== undefined) {
            const temp = new Date(storage.toDate)
            filter.toDate = temp;
            this.setState({
                toDate: temp
            })
        }
        if (filter) {
            this.setState({
                dateFiltersMap: filter,
            })
        }
    }

    getToday = () => {
        let today = new Date()
        today = today.toString().split('GMT')[0] + "Z"
        return new Date(today)
    }

    isToday = (someDate) => {
        const today = new Date()
        return someDate.getDate() == today.getDate() &&
            someDate.getMonth() == today.getMonth() &&
            someDate.getFullYear() == today.getFullYear()
    }

    getFromDate = () => {
        let storage = JSON.parse(localStorage.getItem("Tickets"))
        if (storage) {
            if (storage.fromDate !== null && storage.fromDate !== undefined) {
                return new Date(storage.fromDate)
            }
        }
        let date = this.getToday()
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setDate(date.getDate() - 14)
        return date;
    }

    getToDate = () => {
        let storage = JSON.parse(localStorage.getItem("Tickets"))
        if (storage) {
            if (storage.toDate !== null && storage.toDate !== undefined) {
                return new Date(storage.toDate)
            }
        }
        let date = this.getToday()
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
        return date;
    }

    initializeSearchText = () => {
        this.setState({ searchValue: this.props.searchText })
    }

    onSearchValueChange = (e) => {
        this.setState({
            isSearch: e.target.value.length > 0,
            searchValue: e.target.value
        });
        this.props.setPage(0)
        this.props.handleSearchChange(e)
    }

    handleRequestSort = (event, property) => {
        const tableFiltersMap = this.props.tableSetting?.ticketsTableSetting
        const index1 = tableFiltersMap.findIndex(obj => obj.id === property)
        const index2 = tableFiltersMap.findIndex(obj => obj.id === this.state.orderBy)
        const isAsc = this.state.order === ASC
        if (this.state.orderBy === property) {
            const objectToMutate = { ...tableFiltersMap[index1] };
            objectToMutate.sortOrder = isAsc ? DESC : ASC
            tableFiltersMap[index1] = objectToMutate
            this.setState({
                order: isAsc ? DESC : ASC,
                tableFiltersMap: tableFiltersMap
            })
        } else {
            const objectToMutate1 = { ...tableFiltersMap[index1] };
            const objectToMutate2 = { ...tableFiltersMap[index2] };
            objectToMutate1.sortOrder = ASC
            objectToMutate1.sortIndex = 1
            objectToMutate2.sortOrder = ""
            objectToMutate2.sortIndex = 0
            tableFiltersMap[index1] = objectToMutate1
            tableFiltersMap[index2] = objectToMutate2
            this.setState({
                order: ASC,
                orderBy: property,
                tableFiltersMap: tableFiltersMap
            })
        }
        this.props.saveTableSetting({
            userId: this.props.userInfo.userId,
            tableSetting: tableFiltersMap,
            pageTitle: TICKETS_TOOLBAR_TITLE,
        })
    };

    handleChangeRowsPerPage = (event) => {
        this.props.setRowsPerPage(event.target.value)
        this.props.setPage(0)
    };

    handleChangePage = (event, newPage) => {
        this.props.setPage(newPage)
    };

    filterByMyReturns = (rows) => {
        let ticketsListFinal = [];
        if (!this.state.showMyTickets) {
            ticketsListFinal = rows;
        } else {
            ticketsListFinal = rows.filter((row) =>
                row.assignedToId === this.props.currentUser.userId
            )
        }

        return ticketsListFinal
    }

    filterByCompletedTickets = (rows) => {
        if(!this.state.showCompletedTickets) {
            return rows.filter((row) => row.status !== "Completed");
        }
        else {
            return rows;
        }
    }

    filterByLocation = (rows) => {
        if (!rows)
            return [];
        return rows.filter((row) => {return row.shipAddressId === this.props.selectedLocation})
    }

    filterByStatus = (rows) => {
        if (!rows)
            return [];
        return this.state.statusFiltersMap.length === 0 ? rows : rows.filter((row) => this.state.statusFiltersMap.includes(row.status))
    }

    getDisplayed = (rows) => {
        if (!rows)
            return [];
        return [...rows.slice(this.props.page * this.props.rowsPerPage, this.props.page * this.props.rowsPerPage + this.props.rowsPerPage)];
    }

    isDefaultFromDate = (someDate) => {
        const defaultFromDate = this.getFromDate()
        return someDate.getDate() == defaultFromDate.getDate() &&
            someDate.getMonth() == defaultFromDate.getMonth() &&
            someDate.getFullYear() == defaultFromDate.getFullYear()
    }

    resetDateFilters = (fromDate, toDate) => {
        let filtersMapClone = { ...this.state.dateFiltersMap }
        if (!this.isDefaultFromDate(fromDate)) {
            filtersMapClone['fromDate'] = fromDate
        }
        if (!this.isToday(toDate)) {
            filtersMapClone['toDate'] = toDate
        }
        this.setState({
            fromDate: fromDate,
            toDate: toDate,
            dateFiltersMap: filtersMapClone,
        })
    }

    applyDateFilters = (fromDate, toDate) => {
        this.props.listTickets({
            fromDate,
            toDate
        })
    }

    onStatusFiltersInputChange = (value) => {
        this.setState({statusFiltersMap:value})
    }

    showFiltersDialog = () => {
        const filtersMapClone = { ...this.state.dateFiltersMap };
        this.setState({
            filtersOpen: !this.state.filtersOpen,
            filtersMapBeforeOpenDialog: filtersMapClone
        });
    }

    onDateFiltersInputChange = (id, value) => {
        if (!value || isNaN(value.getTime())) {
            //User is still editing the date value, do not fetch orders
            this.setState({
                fromDate: id === "fromDate" ? value : this.state.fromDate,
                toDate: id === "toDate" ? value : this.state.toDate
            })
        } else {
            const date = new Date(value.toString().split('GMT')[0] + "Z")
            let filtersMapClone = { ...this.state.dateFiltersMap }
            filtersMapClone[id] = date
            this.setState({
                fromDate: id === "fromDate" ? value : this.state.fromDate,
                toDate: id === "toDate" ? value : this.state.toDate,
                dateFiltersMap: filtersMapClone
            })
        }
    }

    closeFilters = (apply) => {
        this.setState({
            filtersOpen: false,
        });

        let filters = {}
        if (!apply) {
            filters = { ...this.state.filtersMapBeforeOpenDialog }
            this.resetDateFilters(filters?.fromDate ?? this.getFromDate(), filters?.toDate ?? this.getToDate())
            this.setState({
                dateFiltersMap: filters,
                statusFiltersMap: []
            })
        } else {
            filters = { ...this.state.dateFiltersMap }
            const expiry = new Date().setHours(23, 59, 59, 0);
            if (filters.fromDate !== null && filters.fromDate !== undefined) {
                let existing = localStorage.getItem("Tickets")
                existing = existing ? JSON.parse(existing) : {};
                existing.fromDate = filters.fromDate;
                localStorage.setItem("Tickets", JSON.stringify(existing));
                localStorage.setItem("expiry", JSON.stringify(expiry))
            }
            if (filters.toDate !== null && filters.toDate !== undefined) {
                let existing = localStorage.getItem("Tickets")
                existing = existing ? JSON.parse(existing) : {};
                existing.toDate = filters.toDate;
                localStorage.setItem("Tickets", JSON.stringify(existing));
                localStorage.setItem("expiry", JSON.stringify(expiry))
            }
            this.applyDateFilters(this.state.fromDate, this.state.toDate)
        }
    }

    clearFiltersMap = () => {
        this.setState({
            dateFiltersMap: {},
            statusFiltersMap: [],
            fromDate: this.getFromDate(),
            toDate: this.getToDate()
        })
    }


    render() {
        return (
            <>
                <CustomTableHeader
                    searchText={this.props.searchText}
                    onSearchValueChange={this.onSearchValueChange}
                    filterIcon={
                        <Tooltip title={FILTER} style={{ paddingTop: 10 }}>
                            <IconButton className="filterButton" size='small' aria-label={FILTER} onClick={this.showFiltersDialog} >
                                <FilterIcon />
                            </IconButton>
                        </Tooltip>
                    }
                    showFilters={this.state.filtersOpen}
                    filtersComponent={
                        <Slide in={this.state.filtersOpen} mountOnEnter unmountOnExit timeout={300}>
                            <TicketFiltersDialog
                                isOpen={this.state.filtersOpen}
                                onOpen={this.showFiltersDialog}
                                onChange={this.onStatusFiltersInputChange}
                                clearFilters={this.clearFiltersMap}
                                onClose={this.closeFilters}
                                fromDate={this.state.fromDate}
                                toDate={this.state.toDate}
                                handleDateChange={this.onDateFiltersInputChange}
                                statusFiltersMap={this.state.statusFiltersMap}
                                updateTicketTableFilter={this.props.updateTicketTableFilter}
                                status={this.props.status}
                            />
                        </Slide>}
                    customFilterField={
                        <div>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.showMyTickets} onChange={() => {
                                    const checked = !this.state.showMyTickets
                                    this.props.saveTableSetting({
                                        userId: this.props.user.userId,
                                        tableSetting: null,
                                        changeFilters: false,
                                        changeTicketsFilters: true,
                                        ticketFilterSetting: {
                                            showMyTickets: checked,
                                            showCompletedTickets:this.state.showCompletedTickets
                                        }
                                    })
                                    this.setState({ showMyTickets: !this.state.showMyTickets })
                                }} name="checkedG" />}
                                label={SHOW_MY_TICKETS}
                            />
                            <FormControlLabel
                                control={<Checkbox checked={this.state.showCompletedTickets} onChange={() => {
                                    const checked = !this.state.showCompletedTickets
                                    this.props.saveTableSetting({
                                        userId: this.props.user.userId,
                                        tableSetting: null,
                                        changeFilters: false,
                                        changeTicketsFilters: true,
                                        ticketFilterSetting: {
                                            showMyTickets:this.state.showMyTickets,
                                            showCompletedTickets:checked
                                        }
                                    })
                                    this.setState({ showCompletedTickets: !this.state.showCompletedTickets })

                                }} name="checkedC" />}
                                label={SHOW_COMPLETED_TICKETS}
                            />
                        </div>
                    }
                    pagination={
                        <TablePagination
                            className="table-pagination"
                            labelRowsPerPage={ROWS_PER_PAGE_LABEL}
                            rowsPerPageOptions={[5, 25, 50, 100, {value: this.props.rows.length, label: "All"} ]}
                            count={!this.props.rows ? 0 : this.filterByLocation(this.filterByMyReturns(this.filterByCompletedTickets(this.filterByStatus(this.filterBySearch(this.props.rows))))).length}
                            rowsPerPage={this.props.rowsPerPage}
                            page={this.props.page}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    }
                    locationField={<LocationSelectBox/>}
                />



                <TableContainer>
                    <Table
                        size='medium'
                    >
                        <GlobalTableHead
                            isCreatePurchaseOrderTable={false}
                            isShipmentTable={false}
                            headCells={TICKETS_TABLE_HEAD_CELLS}
                            order={this.state.order}
                            orderBy={this.state.orderBy}
                            onRequestSort={this.handleRequestSort}
                            rowCount={!this.props.rows ? 0 : this.props.rows.length}
                        />

                        <TableBody>
                            {this.getDisplayed(this.stableSort(this.filterByLocation(this.filterByMyReturns(this.filterByCompletedTickets(this.filterByStatus(this.filterBySearch((this.props.rows)))))), this.getComparator(this.state.order, this.state.orderBy))).map((row, index) => {
                                return (
                                    <TableRow hover onDoubleClick={() => this.props.onDoubleClick(row)} key={index}>
                                        <TableCell>
                                            <IconButton onClick={() => this.props.onDoubleClick(row)}>
                                                <EditIcon
                                                />
                                            </IconButton>

                                        </TableCell>

                                        <TableCell className='cellPadding' style={{ width: 100 }}>
                                            {row.dueDate.split('T')[0]}
                                        </TableCell>
                                        <TableCell className='cellPadding'>
                                            {row.customerName}
                                        </TableCell>
                                        <TableCell className='cellPadding'>
                                            {row.originalTrackingId}
                                        </TableCell>
                                        <TableCell className='cellPadding'>
                                            {row.orderId}
                                        </TableCell>
                                        <TableCell className='cellPadding'>
                                            {row.ticketIssueName}
                                        </TableCell>
                                        <TableCell className='cellPadding'>
                                            {row.status}
                                        </TableCell>
                                        <TableCell className='cellPadding'>
                                            {row.createdByName}
                                        </TableCell>
                                        <TableCell className='cellPadding'>
                                            {row.assignedToName}
                                        </TableCell>

                                        <TwoStepDelete
                                            rowId={row.ticketId}
                                            handleDelete={(rowIdToDelete) => this.props.deleteTicket(rowIdToDelete, this.props.listTickets)}
                                            deleteId={this.state.deleteId}
                                            setDeleteId={(newDeleteId) => this.setState({deleteId: newDeleteId})}
                                        />

                                    </TableRow>
                                );

                            })
                            }
                        </TableBody>

                    </Table>
                </TableContainer>
            </>
        );
    }

}

const mapStateToProps = (state) => ({
    userInfo: userInfoSelector(state),
});


TicketsTable.propTypes = {
    rows: PropTypes.array,
    currentUser: PropTypes.object,
    onDoubleClick: PropTypes.func
}

TicketsTable.defaultProps = {
    rows: [],
    currentUser: {},
    onDoubleClick: () => { }
}

export default withShipment({
    mapStateToProps,
}, TicketsTable);