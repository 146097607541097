import React from "react";
import withShipment from "../../../withShipment";

//components
import CustomToolbar from "../../global/CustomToolbar";
import CustomTableHeader from "../../global/Table/CustomTableHeader";
import SearchableTable from "../../global/Search/SearchableTable";
import GlobalTableHead from "../../global/Table/GlobalTableHead";
import FeedbackBar from "../../feedbackBar/FeedbackBar";
import EditingManageTagsTable from "./EditingManageTagsTable"

//material ui
import { TableBody, TablePagination, Table, TableContainer, TableRow, TableCell, IconButton, TextField, Tooltip } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import EditAttributesIcon from '@material-ui/icons/EditAttributes';
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';


//constants
import { ASC, DESC } from "../../global/constants";
import {
    MANAGE_TAGS,
    TAGS_TOOLBAR_TITLE,
    BACK_TO_MENU_TITLE,
    DEFAULT_ROWS_PER_PAGE,
    TAG_TABLE_HEAD_CELLS,
    DEFAULT_SORT_BY_HEAD_CELL, ROWS_PER_PAGE_LABEL,
    PLEASE_MAKE_CHANGE,
    UPDATE_SUCCESS,
    UPDATE_FAIL,
} from "./constants";

import { SUCCESS, ERROR } from "../../shipments/constants";

//redux
import {
    editTags,
    listTags,
    storeTags,
    getUUID,
    listAvailableLocations
} from "../../../redux/actions/settings";
import { userInfoSelector } from "../../../redux/selectors/auth";
import {availableLocationsSelector, tagsSelector} from "../../../redux/selectors/settings"


import './ManageTagsTable.css'
import ErrorFeedbackBar from "../../global/ErrorFeedbackBar";
import PropTypes from "prop-types";

class ManageTagsTable extends SearchableTable {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: DEFAULT_ROWS_PER_PAGE,
            order: ASC,
            orderBy: DEFAULT_SORT_BY_HEAD_CELL,
            isSearch: false, // true if search value is not empty, false otherwise
            searchValue: "", // the value in the search field (updates on change)
            searchRows: 0, // number of rows displayed while search criteria is met
            editingTags: [],
            newTags: [],
            editedTags: [],
            deletedTags: [],

            successList: [],
            isEditingTags: false,
        }
    }

    handleRequestSort = (event, property) => {
        const isAsc = this.state.orderBy === property && this.state.order === ASC;
        this.setState({
            order: isAsc ? DESC : ASC,
            orderBy: property,
        });
    };

    componentDidMount() {
        console.log(this.state)
        const companyId = this.props.userInfo.company
        this.props.listTags(companyId)
        this.props.listAvailableLocations()
        console.log(this.state)
    }
    componentDidUpdate() {
        if (this.state !== this.prevState) {
            console.log("State has changed:", this.state);
        }
    }

    onSearchValueChange = (isSearch, searchValue) => {
        this.setState({
            isSearch: isSearch,
            searchValue: searchValue,
            page: 0
        });
    }

    checkDuplicatedAndEmpty = (row) => {
        if (row.tagName?.length > 0) {
            return this.state.editingTags.filter(tag => { return tag.tagName?.toLowerCase() === row.tagName?.toLowerCase() }).length > 1
        }
        return true
    }

    checkDisable = () => {
        var saveDisable = false
        this.state.editingTags.forEach(tag => {
            if (this.checkDuplicatedAndEmpty(tag)) {
                saveDisable = true
            }
        })
        var isSame = true
        var printableUpdated = true
        this.state.editingTags.forEach(tag => {
            if (!this.props.tags.find(originTag => {
                return originTag.tagId == tag.tagId && originTag.tagName == tag.tagName
            })) {
                isSame = false
            }
            else if (!this.props.tags.find(originTag => {
                return originTag.tagId == tag.tagId && originTag.isPrintable == tag.isPrintable
            })) {
                printableUpdated = false
            }
        })
        this.props.tags.forEach(tag => {
            if (!this.state.editingTags.find(editingTag => {
                return editingTag.tagId == tag.tagId && editingTag.tagName == tag.tagName
            })) {
                isSame = false
            }
            else if (!this.state.editingTags.find(editingTag => {
                return editingTag.tagId == tag.tagId && editingTag.isPrintable == tag.isPrintable
            })) {
                printableUpdated = false
            }
        })
        return saveDisable || (isSame && printableUpdated)
    }

    handleUpdateTags = () => {
        var newTags = []
        var editedTags = []
        var deletedTags = []
        const curTagIds = this.props.tags.map(tag => { return tag.tagId })
        const editingTagIds = this.state.editingTags.map(tag => { return tag.tagId })
        //check remove
        this.props.tags.forEach(tag => {
            if (!editingTagIds.includes(tag.tagId)) {
                deletedTags.push(tag)
            }
        })

        this.state.editingTags.forEach(editingTag => {
            if (curTagIds.includes(editingTag.tagId)) {
                const originTag = this.props.tags.find(tag => { return tag.tagId === editingTag.tagId })
                if ((editingTag.tagName != originTag.tagName)||(editingTag.isPrintable!=originTag.isPrintable)) { //check edit
                    editedTags.push(editingTag)
                }
            } else { //add Tag
                newTags.push(editingTag)
            }
        })

        const effect = (success) => {
            this.setState({
                feedbackOpen: success,
                errorFeedbackOpen: !success,
                feedbackMessage: success ? UPDATE_SUCCESS : UPDATE_FAIL,
            })
            if (success) {
                this.props.storeTags(this.state.editingTags)
                this.handleCancel()
            }
        }
        this.props.editTags(this.props.userInfo.company, newTags, editedTags, deletedTags, effect)
    }

    handleEditingTags = () => {
        const editingTags = []
        this.stableSort(this.props.tags.filter(tag => { return tag.isLocationTag }), this.getComparator(this.state.order, this.state.orderBy)).forEach(tag => {
            editingTags.push({
                companyId: tag.companyId,
                tagName: tag.tagName,
                isLocationTag: tag.isLocationTag,
                isPrintable: tag.isPrintable,
                tagId: tag.tagId,
                key: tag.tagId,
            })
        })
        this.stableSort(this.props.tags.filter(tag => { return !tag.isLocationTag }), this.getComparator(this.state.order, this.state.orderBy)).forEach(tag => {
            editingTags.push({
                companyId: tag.companyId,
                tagName: tag.tagName,
                isLocationTag: tag.isLocationTag,
                isPrintable: tag.isPrintable,
                tagId: tag.tagId,
                key: tag.tagId,
            })
        })
        this.setState({
            editingTags: editingTags,
            isEditingTags: true,
        })
    }

    handleCancel = () => {
        this.setState({
            editingTags: [],
            isEditingTags: false,
        })
    }

    handleSuccessClose = () => {
        this.setState({
            feedbackOpen: false
        })
    }

    handleErrorClose = () => {
        this.setState({
            errorFeedbackOpen: false
        })
    }

    addEntry = () => {
        const companyId = this.props.userInfo.company
        let curEditingTags = this.state.editingTags
        const setUUID = (tagUUID) => {
            if (tagUUID && tagUUID != "") {
                const newTag = {
                    companyId: companyId,
                    tagName: '',
                    isPrintable:true,
                    isLocationTag: false,
                    tagId: tagUUID,
                    key: tagUUID,
                }
                curEditingTags.push(newTag)
                this.setState({
                    editingTags: curEditingTags,
                })
            }
        }
        this.props.getUUID("tag", setUUID)
    }

    removeEntry = (row) => {
        const tagId = row.tagId
        let newEditingTags = []
        //update editingTagList
        this.state.editingTags.forEach(tag => {
            if (tag.tagId != tagId) {
                newEditingTags.push(tag)
            }
        })
        this.setState({
            editingTags: newEditingTags,
        })
    }

    handleTagNameChange = (e, row) => {
        const tagId = row.tagId
        const value = e.target.value
        row["tagName"] = value

        let newEditingTags = []

        //update editingTagList
        this.state.editingTags.forEach(tag => {
            if (tag.tagId != tagId) {
                newEditingTags.push(tag)
            } else {
                newEditingTags.push(row)
            }
        })
        this.setState({
            editingTags: newEditingTags,
        })
    }
    handleCheckboxChange= (e, row) => {
        const tagId = row.tagId
        const isChecked = e.target.checked
        row["isPrintable"] = isChecked

        let newEditingTags = []

        // update editingTagList
        this.state.editingTags.forEach(tag => {
            if (tag.tagId !== tagId) {
                newEditingTags.push(tag)
            } else {
                newEditingTags.push(row)
            }
        })
        this.setState({
            editingTags: newEditingTags,
        })

    }

    render() {
        return (
            <>
                <ErrorFeedbackBar />
                <FeedbackBar
                    open={this.state.feedbackOpen}
                    handleClose={this.handleSuccessClose}
                    severity={SUCCESS}
                    message={this.state.feedbackMessage} />
                <FeedbackBar
                    open={this.state.errorFeedbackOpen}
                    handleClose={this.handleErrorClose}
                    severity={ERROR}
                    message={this.state.feedbackMessage} />
                <Paper className='paper'>
                    <CustomToolbar
                        title={TAGS_TOOLBAR_TITLE}
                        customIcons={this.state.isEditingTags ?
                            <div className="contextualMenu">
                                <Tooltip title={BACK_TO_MENU_TITLE}>
                                    <IconButton aria-label={this.props.backTitle} onClick={this.handleCancel}>
                                        <KeyboardBackspaceIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            :
                            <div className="contextualMenu">
                                <Tooltip title={MANAGE_TAGS}>
                                    <IconButton aria-label={this.props.createTitle} onClick={this.handleEditingTags} >
                                        <EditAttributesIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        }
                    />
                    {this.state.isEditingTags ?
                        <EditingManageTagsTable
                            editingTags={this.state.editingTags}
                            searchValue={this.state.searchValue}
                            onSearchValueChange={this.onSearchValueChange}
                            onChange={this.handleTagNameChange}
                            handleCheckboxChange={this.handleCheckboxChange}
                            addEntry={this.addEntry}
                            removeEntry={this.removeEntry}
                            handleUpdateTags={this.handleUpdateTags}
                            handleCancel={this.handleCancel}
                            handleSave={this.handleUpdateTags}
                            checkDisable={this.checkDisable}
                            checkError={this.checkDuplicatedAndEmpty}
                        />
                        :
                        <>
                            <CustomTableHeader
                                searchText={this.state.searchValue}
                                onSearchValueChange={(e) => this.onSearchValueChange(false, e.target.value)}
                            />
                            <TableContainer>
                                <Table size='medium'>
                                    <GlobalTableHead
                                        isCreatePurchaseOrderTable={false}
                                        isOrderTable={false}
                                        numSelected={0}
                                        headCells={TAG_TABLE_HEAD_CELLS}
                                        order={this.state.order}
                                        orderBy={this.state.orderBy}
                                        onRequestSort={this.handleRequestSort}
                                    />
                                    <TableBody>
                                        {this.stableSort(this.filterBySearch(this.props.tags.filter(tag => { return tag.isLocationTag })), this.getComparator(this.state.order, this.state.orderBy)).map((row, index) => {
                                            const key = row.TagId
                                            return (
                                                <TableRow>
                                                    <TableCell className='tag-checkCircleBox' />
                                                    <TableCell className='tag-inputBox'>
                                                        <div className='tag-inputBox'>
                                                            <div className='tag-textBox'>
                                                                {row.tagName}
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className='tag-inputBox'>
                                                        <div className='tag-inputBox'>
                                                            <Checkbox
                                                                checked={row.isPrintable}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className="tag-endingBox" />
                                                </TableRow>
                                            )
                                        })}
                                        {this.stableSort(this.filterBySearch(this.props.tags.filter(tag => { return !tag.isLocationTag })), this.getComparator(this.state.order, this.state.orderBy)).map((row, index) => {
                                            const key = row.TagId
                                            return (
                                                <TableRow>
                                                    <TableCell className='tag-checkCircleBox' />
                                                    <TableCell className='tag-inputBox'>
                                                        <div className='tag-inputBox'>
                                                            <div className='tag-textBox'>
                                                                {row.tagName}
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell  className='tag-inputBox'>
                                                        <div className='tag-inputBox'>
                                                            {row.isPrintable ? <CheckBoxIcon color="secondary" /> : <CheckBoxOutlineBlankIcon color="disabled" />}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className="tag-endingBox" />
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    }
                </Paper>
            </>
        );
    }

}

const mapStateToProps = (state) => ({
    tags: tagsSelector(state),
    userInfo: userInfoSelector(state),
    availableLocations: availableLocationsSelector(state),
})

const actionCreators = {
    editTags,
    listTags,
    storeTags,
    listAvailableLocations,
    getUUID,
}

ManageTagsTable.propTypes = {
    tags: PropTypes.array,
    userInfo: PropTypes.object,
    availableLocations: PropTypes.array,
}
ManageTagsTable.defaultProps = {
    tags: [],
    userInfo: {},
    availableLocations: [],
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, ManageTagsTable);