import {TableBody, TableHead, TextField, Typography} from "@material-ui/core";
import React from "react";
import {TableSortLabel} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import styles from "../../pages/Dashboard/DashboardFinal/DashboardFinal.module.css";
import "./ShipmentSummary.css";
import "../../pages/Dashboard/DashboardSummary.css";
import { REPORT_TITLE } from "../../pages/Dashboard/DashboardFinal/constants";
import "./ShipmentForm.css";
import SearchableTable from "../global/Search/SearchableTable";
import {TableFooter} from '@material-ui/core';
import Autocomplete from "@material-ui/lab/Autocomplete";
import withShipment from "../../withShipment";
import {updateUserInfo} from "../../redux/actions/auth";
import {selectedLocationIdSelector, userInfoSelector} from "../../redux/selectors/auth";
import PropTypes from "prop-types";
import LocationSelectBox from "../global/subcomponents/locationSelectBox/LocationSelectBox";

class ShipmentsSummary extends SearchableTable {
    constructor(props) {
        super(props);
        this.state = {
            missingScanned: [],
            missingPacked: [],
            reportData: [],
            valueToOrderByShipmentsSummary: "printedQty",
            orderDirectionShipmentsSummary: "desc",
            selectedLocation: {},
            rows: []
        }
    }

    componentDidMount() {
        this.state.rows = this.props.allRows.filter(row => row.shipAddressId === this.props.selectedLocationId)

        this.state.selectedLocation = this.props.locations.find(location =>
            location.shipAddressId === this.props.selectedLocationId)

        this.gatherPrintedPendingReport();
        this.props.updateExportInfo(this.state.rows)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        this.state.rows = this.props.allRows.filter(row => row.shipAddressId === this.props.selectedLocationId)

        if (prevProps.selectedLocationId !== this.props.selectedLocationId) {
            this.state.selectedLocation = this.props.locations.find(location =>
                location.shipAddressId === this.props.selectedLocationId)
            this.gatherPrintedPendingReport();
            this.props.updateExportInfo(this.state.rows)
        }
    }

    gatherPrintedPendingReport = () => {
        let productCodes = this.state.rows.map(row => {
            return row.packages.map(pkg => {
                return pkg.reference3?.split('/')
            });
        }).flat().flat();
        let shortcodes = []
        let reports = []
        for (let row of productCodes) {
            if (row) {
                row = row.trim()
                if (!shortcodes.includes(row)) {
                    let newReport = {
                        productCode: row,
                        printedQty: 1
                    }
                    reports.push(newReport);
                    shortcodes.push(row);
                } else {
                    let selectedReport = {};
                    let selectedIndex = 0;
                    for (let report of reports) {
                        if (report.productCode === row) {
                            selectedReport = report;
                            selectedIndex = reports.indexOf(report);
                            break;
                        }
                    }
                    if (selectedReport !== null) {
                        selectedReport.printedQty++;
                        reports[selectedIndex] = selectedReport;
                    }
                }
            }
        }
        reports = reports.sort((a, b) => {
            return a.productCode.toLowerCase().localeCompare(b.productCode.toLowerCase())
        })

        this.setState({
            reportData: reports
        })
    }

    createSortHandler = (property) => (event) => {
        this.handleRequestSort(event, property);
    }

    handleRequestSort = (event, property) => {
        const isAscendingShipments = (this.state.valueToOrderByShipmentsSummary === property && this.state.orderDirectionShipmentsSummary === "asc");
        this.setState({
            valueToOrderByShipmentsSummary: property,
            orderDirectionShipmentsSummary: (isAscendingShipments ? 'desc' : 'asc')
        })
        this.props.setShipmentSummaryOrderDirection(isAscendingShipments ? 'desc' : 'asc', property)
    }

    addItUp = (addOn) => {
        let summer = 0;
        this.state.reportData.forEach(entry => {
            summer += entry[addOn];
        })
        return summer;
    }

    locationSelectRow = () => (
        <div className={styles.dateselectrow}>

            <div style={{width: "auto"}}></div>

            <div className={styles.dateLocationBlock}>

                <LocationSelectBox/>

            </div>

        </div>
    )

    render() {
        return (
            <>

                {this.locationSelectRow()}

                <div className="missing-shipments-container">
                    <Typography variant="h6" component="div" gutterBottom className="form-header">
                    </Typography>
                    <div className="missing-labels-table-content">
                        <TableContainer>
                            <Table size={"medium"} stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className='cellPadding' key="productCode">
                                            <TableSortLabel
                                                active={this.state.valueToOrderByShipmentsSummary === "productCode"}
                                                direction={this.state.valueToOrderByShipmentsSummary ? this.state.orderDirectionShipmentsSummary : "asc"}
                                                onClick={this.createSortHandler("productCode")}
                                            >
                                                <p className={styles.cardTotalHeader}>
                                                    Product
                                                </p>
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell className='cellPadding' key="printedQty">
                                            <TableSortLabel
                                                active={this.state.valueToOrderByShipmentsSummary === "printedQty"}
                                                direction={this.state.valueToOrderByShipmentsSummary ? this.state.orderDirectionShipmentsSummary : "asc"}
                                                onClick={this.createSortHandler("printedQty")}
                                            >
                                                <p className={styles.cardTotalHeader}>
                                                    # of packages
                                                </p>
                                            </TableSortLabel>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.stableSort(this.state.reportData, this.getComparator(this.state.orderDirectionShipmentsSummary, this.state.valueToOrderByShipmentsSummary)).map((report, index) => (
                                        <TableRow hover key={index}>
                                            <TableCell className='cellPadding' style={{ width: "30%" }}>
                                                <p className={styles.cardCellItems}>
                                                    {report.productCode}
                                                </p>
                                            </TableCell>
                                            <TableCell className='cellPadding'>
                                                <p className={styles.cardCellItems}>
                                                    {report.printedQty}
                                                </p>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                <TableFooter>
                                    <TableRow hover>
                                        <TableCell className='cellPadding' style={{ width: "30%" }}>
                                            <p className={styles.cardCellItems}>
                                                Total:
                                            </p>
                                        </TableCell>
                                        <TableCell className='cellPadding'>
                                            <p className={styles.cardCellItems}>
                                                {this.addItUp("printedQty")}
                                            </p>
                                        </TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    userInfo: userInfoSelector(state),
    selectedLocationId: selectedLocationIdSelector(state),
})

const actionCreators =
{
    updateUserInfo,
}

ShipmentsSummary.propTypes = {
    selectedLocation: PropTypes.object,
}

ShipmentsSummary.defaultProps = {
    rows: []
}

export default withShipment(
    {
        mapStateToProps,
        actionCreators
    }
    , ShipmentsSummary);