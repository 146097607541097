
export const CONDITION_FILTERS = "Condition Filters";
export const FILTER = "Filter";

export const CONDITION_MAPPING = "Condition Mapping";

export const DEFAULT_ROWS_PER_PAGE = 50;

export const DEFAULT_SORT_BY_HEAD_CELL = "filter";

export const HEAD_FILTER_CELLS = [
  {
    id: "filter",
    numeric: false,
    disablePadding: true,
    label: "Filter",
  },
  {
    id: "Category",
    numeric: false,
    disablePadding: true,
    label: "Category",
  },
];
export const HEAD_MAPPING_CELLS = [
  {
    id: 'mapping',
    numeric: false,
    disablePadding: true,
    label: "Mapping",
  },
]

export const YES = "yes";
export const NO = "no";
export const EQUALS = "equals";
export const DOES_NOT_EQUAL = "does not equal";
export const EQUALS_IGNORE_CASE = "equals ignore case"
export const DOES_NOT_EQUAL_IGNORE_CASE = "does not equal ignore case"
export const IS_EMPTY = "is empty";
export const MORE_THAN = "is more than";
export const MORE_OR_EQUAL_TO = "is more than or equal to";
export const LESS_THAN = "is less than";
export const LESS_OR_EQUAL_TO = "is less than or equal to";
export const BETWEEN = "is between";
export const CONTAINS = "contains";
export const DOES_NOT_CONTAIN = "does not contain";
export const STARTS_WITH = "starts with";
export const ENDS_WITH = "ends with";
export const REGULAR_EXPRESSION = "regular expression";
export const IS = "is";
export const IS_NOT = "is not";

// options for boolean filter type component
export const FILTER_TYPE_BOOLEAN_1_OPTIONS = [YES, NO];

// options for boolean 2 filter type component
export const FILTER_TYPE_BOOLEAN_2_OPTIONS = [EQUALS, DOES_NOT_EQUAL];

// options for boolean filter type component
export const FILTER_TYPE_NUMERIC_OPTIONS = [
  EQUALS,
  DOES_NOT_EQUAL,
  IS_EMPTY,
  MORE_THAN,
  MORE_OR_EQUAL_TO,
  LESS_THAN,
  LESS_OR_EQUAL_TO,
  BETWEEN,
];

// options for text filter type component
export const FILTER_TYPE_TEXT_OPTIONS = [
  EQUALS,
  DOES_NOT_EQUAL,
  EQUALS_IGNORE_CASE,
  DOES_NOT_EQUAL_IGNORE_CASE,
  IS_EMPTY,
  CONTAINS,
  DOES_NOT_CONTAIN,
  STARTS_WITH,
  ENDS_WITH,
  REGULAR_EXPRESSION,
];

export const FILTER_TYPE_TEXT_OPTIONS2 = [
  EQUALS,
  DOES_NOT_EQUAL,
  MORE_THAN,
  MORE_OR_EQUAL_TO,
  LESS_THAN,
  LESS_OR_EQUAL_TO,
];

export const FILTER_TYPE_NUMERIC_OPTIONS2 = [
  EQUALS,
  DOES_NOT_EQUAL,
  MORE_THAN,
  MORE_OR_EQUAL_TO,
  LESS_THAN,
  LESS_OR_EQUAL_TO,
];

// options for category filter type component
export const FILTER_TYPE_MATCH_CATEGORY_OPTIONS = [
  IS,
  IS_NOT
];

// options for secondary boolean filter type component
export const STORE_OPTIONS = ["Amazon", "Ebay", "Web"];

export const BANK_ACCOUNT = ["Some Bank Account"];
export const AMOUNT_PREDEFINED_VALUES = ['-', "Supplier invoice total", "Carrier invoice total"]
export const AMOUNT_PREDEFINED_VALUES2 = ['-', "Supplier", "Carrier"]
export const AMOUNT_PREDEFINED_VALUES3 = ['-', "Air Heat Supplies"]
// options for secondary boolean filter type component
export const SHIPPING_ACCOUNT_OPTIONS = []; // should be populated dynamically

// options for secondary boolean filter type component
export const CARRIER_OPTIONS = ["FedEx", "Canpar", "UPS"];

export const MARKETPLACE_OPTIONS = ["Amazon.ca","Amazon.com"];
export const THRESHOLD_OPTIONS = ["threshold"];

// options for secondary boolean filter type component
export const CARRIER_SERVICE_OPTIONS = [
  "USPS First Class",
  "USPS Priority",
  "USPS Express",
  "USPS Retail Ground™",
  "USPS Media Mail",
  "USPS Library Mail",
  "USPS Parcel Select",
  "USPS Intl Express",
  "USPS Intl Priority",
  "USPS Intl First Class",
  "USPS Bound Printed Matter",
  "FedEx First Overnight®",
  "FedEx Priority Overnight®",
  "FedEx Standard Overnight®",
  "FedEx Express Saver®",
  "FedEx 2Day®",
  "FedEx 2Day®A.M.",
  "FedEx Ground®",
  "FedEx Home Delivery®",
  "FedEx SmartPost®",
  "FedEx First Overnight® Freight",
  "FedEx 1Day® Freight",
  "FedEx 2Day® Freight",
  "FedEx 3Day® Freight",
  "FedEx International First®",
  "FedEx International Priority®",
  "FedEx International Economy®",
  "FedEx International Ground®",
  "FedEx International Priority® Freight",
  "FedEx International Economy® Freight",
  "UPS Standard",
  "UPS Worldwide Express®",
  "UPS Worldwide Expedited®",
  "UPS Worldwide Express Plus®",
  "UPS Worldwide Saver®",
  "UPS 2nd Day Air®",
  "UPS 2nd Day Air A.M.®",
  "UPS 3 Day Select®",
  "UPS Ground",
  "UPS Next Day Air®",
  "UPS Next Day Air® Early",
  "UPS Next Day Air Saver®",
  "UPS Expedited®",
  "UPS Express Saver®",
  "UPS 3 Day Select®",
  "UPS Access Point® Economy",
  "UPS Express",
  "UPS Express® Early",
  "UPS Express Saver®",
  "UPS Worldwide Express Freight®",
  "UPS Worldwide Express Freight® Midday",
  "UPS Worldwide Economy DDU",
  "UPS Worldwide Economy DDP",
  "DYNAMEX Same Day",
  "OnTrac Ground",
  "Amazon Shipping Ground",
  "Standard FIMS",
  "Premium FIMS",
  "FIMS over 4.4 Pound",
  "Direct Link International Mail",
  "Direct Link Parcel",
  "Direct Link Registered Mail",
  "Business Mail Economy",
  "MDSE Mail Plus Level 1",
  "MDSE Mail Plus Level 2",
  "MDSE Mail Plus Level 3",
  "MDSE Mail Plus Parcel",
  "MDSE Mail Plus Express",
  "MailView Over 4.4 pounds",
  "MailView Under 4.4 pounds",
  "MailView Lite",
  "DHL Sprintline",
  "DHL Europack",
  "DHL Breakbulk Express",
  "DHL Medical Express",
  "DHL Globalmail Business",
  "DHL Same Day",
  "DHL Jetline",
  "DHL Freight Worldwide",
  "DHL Jumbo Box",
  "DHL B2C",
  "DHL Express Easy",
  "DHL Express Worldwide",
  "DHL EconomySelect",
  "DHL Express 9:00",
  "DHL Express 10:30",
  "DHL Express 12:00",
  "DHL SmartMail BPM Expedited",
  "DHL SmartMail BPM Ground",
  "DHL SmartMail Parcel Plus Expedited",
  "DHL SmartMail Parcel Plus Ground",
  "DHL SmartMail Parcels Expedited",
  "DHL SmartMail Parcels Ground",
  "DHL SmartMail Parcel Expedited Max",
  "DHL SmartMail Marketing Parcel Expedited",
  "DHL SmartMail Marketing Parcel Ground",
  "DHL Metro Next Day",
  "DHL Metro Same Day",
  "DHL Paket",
  "DHL Paket Taggleich",
  "DHL Kurier Taggleich",
  "DHL Kurier Wunschzeit",
  "DHL Paket Connect",
  "Globegistics eCom Domestic",
  "Globegistics eCom Domestic BPM",
  "Globegistics eCom Domestic Flats",
  "Globegistics eCom Priority Plus",
  "Globegistics PMI",
  "Globegistics PMEI",
  "Globegistics PSDS PMI",
  "Globegistics PSDS PMEI",
  "Globegistics eCom Tracked DDU",
  "Globegistics eCom Tracked DDP",
  "Globegistics PMI Duty Paid",
  "Globegistics PMEI Duty Paid",
  "Globegistics eCom Packet",
  "Globegistics eCom ISAL",
  "Globegistics eCom IPA",
  "Globegistics eCom Extra",
  "XParcel Ground",
  "XParcel Expedited",
  "XParcel Max",
  "XParcel Priority",
  "LSO Ground",
  "LSO Economy Next Day",
  "LSO 2nd Day",
  "LSO Priority Next Day",
  "LSO Early Next Day",
  "LSO Saturday",
  "LSO eCommerce",
  "L5 First Class",
  "L5 Priority Mail",
  "L5 Priority Mail Express",
  "L5 Priority Prime",
  "L5 Priority Lightweight",
  "L5 Super Saver Lightweight",
  "Canpar Ground",
  "Canpar Express",
  "Canpar Express Pak",
  "Canpar Express Letter",
  "Canpar Select",
  "Canpar Select Pak",
  "Canpar Select Letter",
  "Canpar USA",
  "Canpar USA Select",
  "Canpar USA Pack",
  "Canpar USA Letter",
  "Canpar International",
  "ChitChats Canada Tracked",
  "ChitChats International Standard",
  "GLS Express",
  "GLS Freight",
  "GLS Ground",
  "GLS Priority Overnight",
  "GLS Early Priority Overnight",
  "GLS Noon Priority Overnight",
  "GLS Saturday Delivery",
  "GLS Early Saturday Delivery",
  "Canada Post Regular Parcel",
  "Canada Post Expedited Parcel",
  "Canada Post Xpresspost",
  "Canada Post Xpresspost Certified",
  "Canada Post Priority",
  "Canada Post Library Materials",
  "Canada Post Expedited Parcel USA",
  "Canada Post Priority Worldwide Envelope USA",
  "Canada Post Priority Worldwide pak USA",
  "Canada Post Priority Worldwide Parcel USA",
  "Canada Post Small Packet USA Air",
  "Canada Post Tracked Packet – USA",
  "Canada Post Tracked Packet – USA (LVM)",
  "Canada Post Xpresspost USA",
  "Canada Post Xpresspost International",
  "Canada Post International Parcel Air",
  "Canada Post International Parcel Surface",
  "Canada Post Priority Worldwide Envelope Int’l",
  "Canada Post Priority Worldwide pak Int’l",
  "Canada Post Priority Worldwide parcel Int’l",
  "Canada Post Small Packet International Air",
  "Canada Post Small Packet International Surface",
  "Canada Post Tracked Packet – International"
];

export const FILTER_TYPE_BOOLEAN_1 = 1;
// equals/not equals with a secondary component which has a set of predefined options
export const FILTER_TYPE_BOOLEAN_2 = 2;
// equals/not equals/more than/more than or equal to/less than/less than or equal to/between with a numeric textfield
export const FILTER_TYPE_NUMERIC = 3;
// equals/not equals/is empty/contains/does not contain/starts with/ends with/reg ex with a string textfield
export const FILTER_TYPE_TEXT = 4;
// equals/not equals to a multi-select of options that can each map to several values on the backend
export const FILTER_TYPE_MATCH_CATEGORY = 5;

export const FILTER_TYPE_NUMERIC_PREDEFINED = 6;

export const SHIPPING_ACCOUNT = "Carrier";
export const CARRIER = "Carrier";
export const SERVICE = "Service";
export const STORE = "Store";

export const SHIPMENTS_CATEGORY= "Shipments"
export const TRANSACTIONS_CATEGORY= "Transactions"
export const LISTINGS_CATEGORY= "Listings"

export const FEDEX_SERVICE_OPTIONS = [
  "FedEx First Overnight®",
  "FedEx Priority Overnight®",
  "FedEx Standard Overnight®",
  "FedEx Express Saver®",
  "FedEx 2Day®",
  "FedEx 2Day® A.M.",
  "FedEx Ground®",
  "FedEx Home Delivery®",
  "FedEx SmartPost®",
  "FedEx First Overnight® Freight",
  "FedEx 1Day® Freight",
  "FedEx 2Day® Freight",
  "FedEx 3Day® Freight",
  "FedEx International First®",
  "FedEx International Priority®",
  "FedEx International Economy®",
  "FedEx International Ground®",
  "FedEx International Priority® Freight",
  "FedEx International Economy® Freight",
];

export const FILTERS = [
  {
    name: "Shipping account",
    type: FILTER_TYPE_BOOLEAN_2,
    secondaryOptions: SHIPPING_ACCOUNT_OPTIONS, category: SHIPMENTS_CATEGORY
  },
  {
    name: "Carrier",
    type: FILTER_TYPE_BOOLEAN_2,
    secondaryOptions: CARRIER_OPTIONS, category: SHIPMENTS_CATEGORY
  },
  {
    name: "Service",
    type: FILTER_TYPE_BOOLEAN_2,
    secondaryOptions: CARRIER_SERVICE_OPTIONS, category: SHIPMENTS_CATEGORY
  },
  { name: "Ship to company", type: FILTER_TYPE_TEXT, category: SHIPMENTS_CATEGORY },
  { name: "Ship to name", type: FILTER_TYPE_TEXT, category: SHIPMENTS_CATEGORY },
  { name: "Ship to phone", type: FILTER_TYPE_TEXT, category: SHIPMENTS_CATEGORY },
  { name: "Ship to address1", type: FILTER_TYPE_TEXT, category: SHIPMENTS_CATEGORY },
  { name: "Ship to address2", type: FILTER_TYPE_TEXT, category: SHIPMENTS_CATEGORY },
  { name: "Ship to city", type: FILTER_TYPE_TEXT, category: SHIPMENTS_CATEGORY },
  { name: "Ship to country", type: FILTER_TYPE_BOOLEAN_2, secondaryOptions: [], category: SHIPMENTS_CATEGORY },
  { name: "Ship to state or province", type: FILTER_TYPE_MATCH_CATEGORY, secondaryOptions: [] /*because the actual secondary options are fetched from server for this*/ , category: SHIPMENTS_CATEGORY},
  { name: "Ship to postal code", type: FILTER_TYPE_TEXT, category: SHIPMENTS_CATEGORY },
  { name: "Ship method", type: FILTER_TYPE_TEXT, category: SHIPMENTS_CATEGORY},
  { name: "Reference 1", type: FILTER_TYPE_TEXT, category: SHIPMENTS_CATEGORY },
  { name: "Reference 2", type: FILTER_TYPE_TEXT, category: SHIPMENTS_CATEGORY },
  { name: "Reference 3", type: FILTER_TYPE_TEXT, category: SHIPMENTS_CATEGORY },
  { name: "Package length", type: FILTER_TYPE_NUMERIC, category: SHIPMENTS_CATEGORY },
  { name: "Package width", type: FILTER_TYPE_NUMERIC, category: SHIPMENTS_CATEGORY },
  { name: "Package height", type: FILTER_TYPE_NUMERIC, category: SHIPMENTS_CATEGORY },
  { name: "Number of packages", type: FILTER_TYPE_NUMERIC, category: SHIPMENTS_CATEGORY },
  { name: "Amazon prime", type: FILTER_TYPE_BOOLEAN_1, category: SHIPMENTS_CATEGORY },
  { name: "Profit Threshold", type: FILTER_TYPE_NUMERIC, category: SHIPMENTS_CATEGORY },
  {
    name: "Store",
    type: FILTER_TYPE_BOOLEAN_2,
    secondaryOptions: STORE_OPTIONS, category: SHIPMENTS_CATEGORY
  },
  { name: "Number of line items for order", type: FILTER_TYPE_NUMERIC, category: SHIPMENTS_CATEGORY },
  { name: "Order item qty", type: FILTER_TYPE_NUMERIC, category: SHIPMENTS_CATEGORY },
  { name: "Total order qty (for all line items)", type: FILTER_TYPE_NUMERIC, category: SHIPMENTS_CATEGORY },
  { name: "Order number", type: FILTER_TYPE_TEXT, category: SHIPMENTS_CATEGORY },
  { name: "Order item sku", type: FILTER_TYPE_TEXT, category: SHIPMENTS_CATEGORY },
  { name: "Description", type: FILTER_TYPE_TEXT, category: TRANSACTIONS_CATEGORY },
  { name: "Amount", type: FILTER_TYPE_NUMERIC_PREDEFINED, predefinedValues: AMOUNT_PREDEFINED_VALUES, predefinedValues2: AMOUNT_PREDEFINED_VALUES2,  predefinedValues3: AMOUNT_PREDEFINED_VALUES3, category: TRANSACTIONS_CATEGORY },
  { name: "Bank account", type: FILTER_TYPE_BOOLEAN_2, secondaryOptions: [], category: TRANSACTIONS_CATEGORY },
/*
  { name: "Marketplace", type: FILTER_TYPE_BOOLEAN_2, secondaryOptions: MARKETPLACE_OPTIONS, category: LISTINGS_CATEGORY },
*/
  { name: "Inventory (quantity)", type: FILTER_TYPE_NUMERIC, category: LISTINGS_CATEGORY },
  { name: "Inventory (threshold)", type: FILTER_TYPE_TEXT, secondaryOptions: THRESHOLD_OPTIONS, category: LISTINGS_CATEGORY },
  { name: "Days of Coverage", type: FILTER_TYPE_NUMERIC, category: LISTINGS_CATEGORY },
  { name: "Location", type: FILTER_TYPE_BOOLEAN_2, secondaryOptions: [], category: LISTINGS_CATEGORY},
  { name: "Insufficient Stock", type: FILTER_TYPE_BOOLEAN_1, category: LISTINGS_CATEGORY},
  { name: "Out of Stock", type: FILTER_TYPE_BOOLEAN_1, category: LISTINGS_CATEGORY},
  { name: "Low Inventory", type: FILTER_TYPE_BOOLEAN_1, category: LISTINGS_CATEGORY},
  { name: "Shipping Template", type: FILTER_TYPE_BOOLEAN_2, secondaryOptions: [], category: LISTINGS_CATEGORY},
  { name: "SKU", type: FILTER_TYPE_TEXT, category: LISTINGS_CATEGORY },
];

export const ORDER_ITEM_WARNING = "This filter will only be applicable to orders with one line item."