import React, { useState, useEffect } from 'react';

// custom components
import RatingCircularProgress from "./RatingCircularProgress";
import CompareRatesTable from "./CompareRatesTable";

// material components
import {
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Button,
    Select, MenuItem,
} from "@material-ui/core";
import {PACKAGES, RATE_DESCRIPTION, RATE_TITLE} from "../constants";

// constants
import {
    NAME,
    VALUE,
    RATES,
    CANCEL,
    RATE_ALL,
    SET_SERVICE,
    UNABLE_TO_RATE_THIS_SERVICE, PACKAGERATES
} from "./constants";
import {
    CARRIER_SERVICE_OPTIONS
} from "../../automationrules/ConditionFilters/constants";
import PropTypes from "prop-types";

import "./RatingDialog.css"

function RatingDialog(props) {
    const [state, setState] = useState({
        carrierService: props.carrierService, //carrierService selected at shipment form
        carrierAccount: props.carrierAccount, //carrierAccount selected at shipment form
        ratedServices: [],
        rates: [],
        accounts: [],
        weight:props.selectedShipment.packages[0].weight,
        length:props.selectedShipment.packages[0].length,
        width:props.selectedShipment.packages[0].width,
        height:props.selectedShipment.packages[0].height,
        isInboundShipment: props.selectedShipment.orderStatus == "Return" // Inbound: "Shipment From" -> "Shipment To"; Outbound: "Carrier Location" -> "Shipment To"
    })

    useEffect(() => {
        if(props.selectedShipment){
            handleAccountChange(props.selectedShipment, 0)
            handleServiceChange(props.selectedShipment.carrierService ? props.selectedShipment.carrierService : "", 0)
            handleRateAll()
        } else {
            handleAccountChange({}, 0)
            handleServiceChange("", 0)
        }

        setState({
            ...state,
            carrierService: props.carrierService
        })
    }, [])

    const handleAccountChange = (value, index) => {
        const accountNumber = value?.accountNumber ? value?.accountNumber : value?.fromAddressAccountNumber
        const carrierName = value?.name ? value?.name : value?.carrierName
        const location = value?.alias ? value?.alias : value?.fromAddressName

        let account = props.carriers.find(carrier => {
            return carrier.accountNumber == accountNumber &&
                carrier.name == carrierName &&
                carrier.alias == location
        })

        let newAccounts = state.accounts
        let newRatedServices = state.ratedServices
        newAccounts[index] = account
        newRatedServices[index] = account?.defaultService
        let newRates = state.rates
        newRates[index] = {}
        setState({
            ...state,
            accounts: newAccounts,
            ratedServices: newRatedServices,
            rates: newRates
        })
    }

    const handleServiceChange = (service, index) => {
        let newRatedServices = state.ratedServices
        newRatedServices[index] = service
        let newRates = state.rates
        newRates[index] = {}
        setState({
            ...state,
            carrierService: service,
            ratedServices: newRatedServices,
            rates: newRates
        })
    }

    const handleRate = (index) => {
        const data = {
            shipmentId: props.selectedShipment?.shipmentId,
            carrierIds: [state.accounts[index].carrierId],
            shipmentServices: [state.ratedServices[index]],
            fromShipments: [state.isInboundShipment]
        }
        const setRates = (rates) => {
            let editedRateLists = [...state.rates]
            editedRateLists[index] = rates[0]
            if(rates[0].rates[0].value != UNABLE_TO_RATE_THIS_SERVICE){
                let rateTitles = []
                //get rate titles
                editedRateLists.forEach(editedRateList => {

                    if (editedRateList.rates) {
                        editedRateList.rates.forEach((rate, rateIndex) => {
                            if (!rateTitles.find((title) => title === rate.name)) { // then insert rate at earliest point after everything before it
                                let previousRates = editedRateList.rates.slice(0, rateIndex)
                                let maxIndexOfAPreviousRate = 0
                                previousRates.forEach((previousRate) => {
                                    let indexOfPreviousRate = rateTitles.findIndex((rateTitle) => rateTitle === previousRate.name)
                                    maxIndexOfAPreviousRate = maxIndexOfAPreviousRate > indexOfPreviousRate ? maxIndexOfAPreviousRate : indexOfPreviousRate
                                })
                                rateTitles.splice(maxIndexOfAPreviousRate + 1, 0, rate.name)
                            }
                        })
                    }
                })
                //map title to value
                let newRateLists = []
                editedRateLists.forEach(editedRateList => {
                    if(editedRateList.rates){
                        if(editedRateList.rates[0].value == UNABLE_TO_RATE_THIS_SERVICE){
                            newRateLists.push(editedRateList)
                        }else{
                            let newRates = []
                            rateTitles.forEach(rateTitle => {
                                
                                var rate = editedRateList.rates.find(editedRate => {return editedRate.name == rateTitle})
                                if(!rate){
                                    rate = {}
                                    rate[NAME] = rateTitle
                                    rate[VALUE] = "0.00"
                                }
                                newRates.push(rate)
                            })
                            let newRateItem = []
                            newRateItem[RATES] = newRates

                            if(editedRateList.packageRates){
                                newRateItem[PACKAGERATES] = editedRateList.packageRates
                            }
                            newRateLists.push(newRateItem)
                        }
                    }else{
                        newRateLists.push({})
                    }
                })
                setState({
                    ...state,
                    rates: newRateLists
                })
            }else{
                setState({
                    ...state,
                    rates: editedRateLists
                })
            }
        }
        props.rateShipment(data, setRates)
    }

    const handleRateAll = () => {
        let fromShipments = []
        let index = 0
        state.ratedServices.forEach(n => {
            if (index == 0) { // First rate always uses from address
                fromShipments.push(true)
            } else {
                fromShipments.push(state.isInboundShipment)
            }
            index++
        })
        const data = {
            shipmentId: props.selectedShipment?.shipmentId,
            carrierIds: state.accounts.map(account => { return account?.carrierId }),
            shipmentServices: state.ratedServices,
            fromShipments: fromShipments
        }
        const setRates = (newRates) => {
            setState({
                ...state,
                rates: newRates
            })
        }
        props.rateShipment(data, setRates)
    }

    const handleClose = () => {
        props.handleRateCancel()
    }

    const handleAddService = () => {
        setState({
            ...state,
            carrierService: '',
            ratedServices: [...state.ratedServices, ""],
            accounts: [...state.accounts, {}],
            rates: [...state.rates, {}]
        })
    }

    const handleApplyRate = (accountNumber, service, rateTotal, packageRates, alias) => {
        props.editShipmentCarrier(props.selectedShipment?.shipmentId, accountNumber, service, rateTotal, packageRates, alias)
        props.storeRates([])
        handleClose()
    }

    const checkRate = () => {
        var cantRate = false
        state.ratedServices.forEach(ratedService => {
            if(!ratedService || ratedService.length <= 0){
                cantRate = true
            }
        })
        state.accounts.forEach(account => {
            if(!account || !account.accountNumber || account.accountNumber.length <=0){
                cantRate = true
            }
        })
        return cantRate
    }

    return (
        <div className="rate-and-compare-box">
            <DialogTitle style={{textAlign:'left'}}>{RATE_TITLE} - {props.orderId}</DialogTitle>
            <DialogContentText style={{textAlign:'left', marginLeft:42}}>
                {RATE_DESCRIPTION} - {props.city}, {props.state} {props.postalCode}
            </DialogContentText>
            <DialogContentText style={{textAlign:'left', marginLeft:42}}>

                {PACKAGES}
                {props.selectedShipment.packages.map((pkg, index) => (
                    <div key={index}>
                        {<span style={{ visibility: 'hidden' }}>{PACKAGES}</span>} {index + 1}: {pkg.weight} LB, {pkg.length}" x {pkg.width}" x {pkg.height}"
                    </div>
                ))}

            </DialogContentText>
            <CompareRatesTable
                selectedShipment={props.selectedShipment}
                ratedServices={state.ratedServices}
                accounts={state.accounts}
                rates={state.rates}
                handleApplyRate={handleApplyRate}
                handleServiceChange={handleServiceChange}
                handleAccountChange={handleAccountChange}
                carriers={props.carriers}
                handleRate={handleRate}
                handleAddService={handleAddService}
                isInboundShipment={state.isInboundShipment}
            />
            <div>
                <Button
                    onClick={handleClose}
                    variant="outlined"
                    className="rate-and-compare-btn"
                >
                    {CANCEL}
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleRateAll}
                    disabled={checkRate()}
                    className="rate-and-compare-btn"
                >
                    {RATE_ALL}
                </Button>
            </div>
        </div>
    )

}

RatingDialog.propTypes = {
    handleClose: PropTypes.func,
    open: PropTypes.bool,
    orderId: PropTypes.string,
    loading: PropTypes.bool,
    carrierService: PropTypes.object,
    rates: PropTypes.array,
    rateShipment: PropTypes.func,
    city: PropTypes.string,
    packages: PropTypes.array,
    postalCode: PropTypes.string,
    state: PropTypes.string
}

RatingDialog.defaultProps = {
    handleClose: ()=>{},
    open: false,
    orderId: "",
    loading: false,
    carrierService: {},
    rates: [],
    rateShipment: ()=>{},
    city: "",
    postalCode: "",
    state: "",
    packages:[]

}

export default RatingDialog;