



export const Filter = {
    createFilter: (name, field, initialValue, relation, appearsInTagBar, overrideDisplayValue = null, defaultFilterValue = null) => {

        // name is a string
        // field is a method (row) => row.field
        // value is any value, value is nullable, null value disables the filter, use null instead of empty string or similar
        // relation is a member of the relations attribute below
        // appearsInTagBar is a boolean, if true, tags appear in the tag bar of a TablePage
        // overrideDisplayValue, if provided, is an object containing a method called displayFunction, (value) => display value
        //      note that for arrays, this value will be a member of the array, not the entire array
        // defaultFilterValue applies at any time the filter would be null,
        //      it causes to filter to always be in effect, though when on it's default value it does not appear in the tags bar

        let getDisplayValue
        if (overrideDisplayValue) {
            getDisplayValue = overrideDisplayValue
        } else {
            getDisplayValue = (value) => value
        }

        return {name: name, field: field, value: initialValue, relation: relation, appearsInTagBar: appearsInTagBar, getDisplayValue: getDisplayValue, defaultFilterValue: defaultFilterValue}
    },

    displayValueOverrides: {
        valueIsDate: (value) => {
            return (new Date(value)).toISOString().split("T")[0]
        }
    },

    relations: {
        equals: "equals", // value is string
        lessThanEquals: "lessThanEquals", // value is string
        greaterThanEquals: "GreaterThanEquals", // value is string
        contains: "contains",
        containedBy: "containedBy", // value is array of strings
        containsMemberOf: "containsMemberOf" // value is array of strings, as is result of filter.field
    },

    filterUpdateEvent: (name, value) => {
        return {name: name, value: value}
    }


}














