import React from 'react'
import PropTypes from "prop-types";
import DeleteIcon from "@material-ui/icons/Delete";
import {IconButton, TableCell, Tooltip} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {DELETE_SELECTED} from "../shipments/constants";

class DeleteSection extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            //isDelete: false,
        }
    }

    componentDidMount() {

    }

    handleClose = () => {
        this.props.handleDeleteFalse(this.props.index)
    }

    handleDelete = () => {
        this.props.handleDeleteTrue()
    }

    render() {
        return (
            <TableCell
                padding="default"
                className='cellPadding'
                width="20%"
            >
                {this.props.isDelete ?
                    <div align="left">
                        <Button
                            onClick={this.handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            startIcon={<DeleteIcon/>}
                            onClick={this.handleDelete}
                        >
                            Delete
                        </Button>
                    </div>
                    :
                    <div>
                        <Tooltip title={this.props.deleteButtonTitle}>
                            <IconButton onClick={() => this.props.handleDeleteOpen(this.props.index)}>
                                <DeleteIcon/>
                            </IconButton>
                        </Tooltip>
                    </div>
                }
            </TableCell>

        )
    }
}

DeleteSection.propTypes = {
    deleteButtonTitle: PropTypes.string
}

DeleteSection.defaultProps = {
    handleDeleteFalse: PropTypes.func,
    index: {},
    deleteButtonTitle: DELETE_SELECTED
}

export default DeleteSection