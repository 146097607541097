import withShipment from "../../../withShipment";
import React from "react";
import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";
import {
    BACK,
    CREATE_NEW_BROKER, EDIT,
    FTL_PAGES, FTL_STATUSES,
    FULL_TRUCKLOAD_BROKERS, FULL_TRUCKLOAD_BROKERS_TABLE
} from "../constants";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import {Tab, Tabs} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {Layout} from "../../global/Table/TablePageHelpers/LayoutObject";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import TablePage from "../../global/Table/TablePage";
import DeleteIcon from "@material-ui/icons/Delete";
import {DELETE} from "../../accounting/transactions/constants";

class FullTruckloadBrokersTable extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    getTableHeaders = () => {
        let headers = []
        headers.push(Layout.columnHead(null,null,null))
        FULL_TRUCKLOAD_BROKERS_TABLE.forEach((headCell) => {
            headers.push(Layout.columnHead(headCell.text, headCell.id, "center"))
        })
        headers.push(Layout.columnHead(null,null,null))
        headers.push(Layout.columnHead(null,null,null))
        return headers
    }

    searchStrings = (row) => {
        return [row.contactName,row.name,row.email,row.accountNumber,row.phoneNumber]
    }

    render(){
        return (
            <React.Fragment>
                <TopToolbar
                    pageName={FULL_TRUCKLOAD_BROKERS}
                    menuItems={[
                        {title: BACK, icon: <KeyboardBackspaceIcon/>, onClick: () => {this.props.setCurrentPage(FTL_PAGES.TABLE)}},
                        {title: CREATE_NEW_BROKER, icon: <AddCircleIcon/>, onClick: () => {this.props.switchToFormAndTable()}},
                    ]}
                />

                <Tabs
                    value={this.props.selectedTab}
                    onChange={(event, value) => this.props.handleTabChange(value)}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab label= {FTL_PAGES.SETUP} />
                    <Tab label= {FTL_PAGES.BROKERS} />
                    <Tab label= {FTL_PAGES.CARRIERS} />
                </Tabs>

                <TablePage
                    tableName="ftlBrokers"
                    tableRows={this.props.rows}
                    tableColumnHeads={this.getTableHeaders()}
                    hasSearchField
                    searchStrings={this.searchStrings}
                    tableRow={(row, index) => <React.Fragment>
                        <TableCell style={{textAlign: "center", width: "1%"}}>
                            <Tooltip title={EDIT}>
                                <IconButton onClick={() => {this.props.switchToFormAndTable(row)}}>
                                    <EditIcon
                                        fontSize='medium'
                                    />
                                </IconButton>
                            </Tooltip>
                        </TableCell >

                        <TableCell style={{textAlign: "center"}}>
                            {row.name}
                        </TableCell>

                        <TableCell style={{textAlign: "center"}}>
                            {row.contactName}
                        </TableCell>

                        <TableCell style={{textAlign: "center"}}>
                            {row.email}
                        </TableCell>

                        <TableCell style={{textAlign: "center"}}>
                            {row.phoneNumber}
                        </TableCell>

                        <TableCell style={{textAlign: "center"}}>
                            {row.accountNumber}
                        </TableCell>

                        <TableCell style={{textAlign: "center"}}>
                            {row.addressLine1}
                        </TableCell>

                        <TableCell style={{ padding: "5px", width: "1%" }}>
                            <Tooltip title={DELETE}>
                                <IconButton onClick={() => {this.props.deleteRow(row)}}>
                                    <DeleteIcon/>
                                </IconButton>
                            </Tooltip>
                        </TableCell>

                    </React.Fragment>}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
})


const actionCreators = {
}

export default withShipment({
    mapStateToProps,
    actionCreators,
}, FullTruckloadBrokersTable);