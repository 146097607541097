import {FETCH_ALERTS, RESOLVE_ALERT, SNOOZE_ALERT, STORE_ALERTS} from "../../constants/alerts";


export const fetchAlerts = (data) => ({
    type: FETCH_ALERTS,
    data
});

export const storeAlerts = (alertEntries) => ({
    type: STORE_ALERTS,
    alertEntries
});

export const snoozeAlert = (data) => ({
    type: SNOOZE_ALERT,
    data
})
export const resolveAlert = (data) => ({
    type: RESOLVE_ALERT,
    data
})
