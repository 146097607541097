import React from "react";
import { Button, TextField } from "@material-ui/core";
import styles from "../ManageCarriers/CreateCarrierDialog.module.css";
import "./ManageLocationForm.css"
import {CANCEL, SAVE} from "../../global/constants";
import {DUPLICATE_ERROR, ENABLE_LOCATION_CHECKBOX_LABEL} from "./constants"
import {COUNTRY_OPTIONS} from "../../automationrules/ConditionActions/constants";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
class ManageLocationForm extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            addressLine1: this.getValue('addressLine1'),
            addressLine2: this.getValue('addressLine2'),
            carrierId: this.getValue('carrierId'),
            city: this.getValue('city'),
            companyId: this.getValue('companyId'),
            companyName: this.getValue('companyName'),
            contactName: this.getValue('contactName'),
            country: this.getValue('country'),
            email: this.getValue('email'),
            isDefault: this.getValue('isDefault'),
            name: this.getValue('name'),
            phone: this.getValue('phone'),
            postalCode: this.getValue('postalCode'),
            shipAddressId: this.getValue('shipAddressId'),
            state: this.getValue('state'),
            taxPercentage: this.getValue('taxPercentage'),
            enabled: this.getValue('enabled'),
            saved: false,
            requiredList: [
                "name",
                "phone",
                "addressLine1",
                // "addressLine2",
                "city",
                "state",
                "postalCode",
                "country",
                "email",
                "companyName",
                "contactName",
                "taxPercentage",
            ],
            previousLocationNames: this.getValue('locationNames'),
        }
    }

    checkName = () => {
        return this.props.editingLocation?.name?.toLowerCase() != this.state.name?.toLowerCase() && this.state.previousLocationNames.includes(this.state.name?.toLowerCase())
    }

    checkSave = () => {
        var hasEmpty = false
        this.state.requiredList.forEach(requiredFeild => {
            if (this.state[requiredFeild] == '' || !this.state[requiredFeild]) {
                hasEmpty = true
            }
        })
        return (hasEmpty || this.checkName())
    }


    getValue = (key) => {
        if (key == "locationNames")
            return this.props.locations?.map(location => { return location.name?.toLowerCase() })

        if (this.props.addingLocation)
            return ''

        return this.props.editingLocation[key]
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render() {
        return (

            <div className={styles.rowCarrier}>
                <div className={styles.innerBox}>
                    <div className={styles.rowSpec}>
                        <TextField
                            required={this.state.requiredList.includes("name")}
                            error={this.checkName()}
                            helperText={this.checkName() ? DUPLICATE_ERROR : null}
                            className={styles.formTwoInput}
                            variant="outlined"
                            name="name"
                            onChange={this.handleChange}
                            value={this.state.name}
                            label="Location (Nickname)"
                            InputLabelProps={{ shrink: true }}
                        />
                        <div className={styles.emptyDiv}></div>
                        <div className={styles.emptyDiv}></div>
                    </div>
                    <div className={styles.rowSpec}>
                        <TextField
                            required={this.state.requiredList.includes("contactName")}
                            className={styles.formTwoInput}
                            variant="outlined"
                            name="contactName"
                            onChange={this.handleChange}
                            value={this.state.contactName}
                            label="Contact Name"
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            required={this.state.requiredList.includes("companyName")}
                            className={styles.formTwoInput}
                            variant="outlined"
                            name="companyName"
                            onChange={this.handleChange}
                            value={this.state.companyName}
                            label="Company Name"
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            required={this.state.requiredList.includes("phone")}
                            className={styles.formTwoInput}
                            variant="outlined"
                            name="phone"
                            onChange={this.handleChange}
                            value={this.state.phone}
                            label="Phone"
                            InputLabelProps={{ shrink: true }}
                        />
                    </div>

                    <div className={styles.rowSpec}>
                        <TextField
                            required={this.state.requiredList.includes("addressLine1")}
                            className={styles.formInputsingle}
                            variant="outlined"
                            name="addressLine1"
                            onChange={this.handleChange}
                            value={this.state.addressLine1}
                            label="Address Line 1"
                            InputLabelProps={{ shrink: true }}
                        />
                    </div>
                    <div className={styles.rowSpec}>
                        <TextField
                            required={this.state.requiredList.includes("addressLine2")}
                            className={styles.formInputsingle}
                            variant="outlined"
                            name="addressLine2"
                            onChange={this.handleChange}
                            value={this.state.addressLine2}
                            label="Address Line 2"
                            InputLabelProps={{ shrink: true }}
                        />
                    </div>
                    <div className={styles.rowSpec}>
                        <TextField
                            required={this.state.requiredList.includes("city")}
                            className={styles.formThreeInput}
                            variant="outlined"
                            name="city"
                            onChange={this.handleChange}
                            value={this.state.city}
                            label="City"
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            required={this.state.requiredList.includes("state")}
                            className={styles.formThreeInput}
                            variant="outlined"
                            name="state"
                            onChange={this.handleChange}
                            value={this.state.state}
                            label="State or Province"
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            required={this.state.requiredList.includes("postalCode")}
                            className={styles.formThreeInput}
                            variant="outlined"
                            name="postalCode"
                            onChange={this.handleChange}
                            value={this.state.postalCode}
                            label="Zip or Postal Code"
                            InputLabelProps={{ shrink: true }}
                        />
                    </div>
                    <div className={styles.rowSpec}>
                        <Autocomplete
                            options={COUNTRY_OPTIONS.map(n => {return n.name})}
                            getOptionLabel={(option)=>option}
                            className={styles.formThreeInput}
                            name="country"
                            value={this.state.country}
                            onChange={(event,value) => {
                                this.setState({
                                    country: value
                                })
                            }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="Country"
                                    required={this.state.requiredList.includes("country")}
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    name="country"
                                />
                            }
                        />
                        <TextField
                            required={this.state.requiredList.includes("email")}
                            className={styles.formTwoColInput}
                            variant="outlined"
                            name="email"
                            onChange={this.handleChange}
                            value={this.state.email}
                            label="Email"
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            required={this.state.requiredList.includes("taxPercentage")}
                            className={styles.formTwoColInput}
                            variant="outlined"
                            name="taxPercentage"
                            onChange={this.handleChange}
                            value={this.state.taxPercentage}
                            label="Tax Percentage"
                            InputLabelProps={{ shrink: true }}
                            InputProps={
                                {
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            %
                                        </InputAdornment>
                                    ),
                                }
                            }
                        />
                    </div>
                    <div className={styles.rowSpec}>
                        <FormControlLabel
                            style={{margin: 10, width: 225, whiteSpace: 'nowrap'}}
                            control={<Checkbox
                                checked={this.state.enabled}
                                onChange={(e) => this.setState({ enabled: e.target.checked })}
                            />}
                            label={ENABLE_LOCATION_CHECKBOX_LABEL}
                        />
                    </div>

                    <div className='save-cancel-buttons'>
                        <Button
                            variant='contained'
                            onClick={this.props.handleClose}
                        >
                            {CANCEL}
                        </Button>
                        <div className='save-spacing' />
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={() => {
                                this.setState({ saved: true })
                                this.props.handleSave(this.props.addingLocation, this.state)
                            }}
                            disabled={this.checkSave()}
                        >
                            {SAVE}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

}

export default ManageLocationForm