export const LEDGERS = "Ledgers"
export const ADD_LEDGER  = "Add Ledgers"
export const EDIT_LEDGER  = "Edit Ledgers"
export const SAVE = "Save"

export const FETCH = "Fetch"

export const LEDGER_FIELDS = [
    {id: 'name', label: 'Name', align: "left"},
    {id: 'type', label: 'Type', align: "left"},
    {id: 'balance', label: 'Balance', align: "left"}
]

export const LEDGERS_PAGES = {
    TABLE: "table",
    ADD: "Add Ledgers",
    EDIT: "Edit Ledgers"
}
export const TYPE_OPTIONS = [
    "Expense",
    "Revenue",
    "Liability",
    "Asset",
    "Equity"
];

// tooltip names
export const BACK = "Back to Menu"
