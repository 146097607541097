export const SETTING_TITLE = "Setting";

export const DEFAULT_ROWS_PER_PAGE = 50;

//toolbar
export const ORDERS_TOOLBAR_TITLE = "Orders"
export const BACK_TO_MENU_TITLE = "Back to Menu";
export const UPDATE_ORDERS = "Update Orders";
//table

export const CONTROL_PANEL_TABLE_HEAD_CELLS = [
    { id: 'orderId', center: true, disablePadding: false, label: 'OrderId' },
    { id: 'orderStatus', center: true, disablePadding: false, label: 'Order Status' },
    { id: 'success', center: true, disablePadding: false, label: 'Success' },
]
export const ROWS_PER_PAGE_LABEL = "Orders per page"
export const DEFAULT_SORT_BY_HEAD_CELL = "orderId"


export const CANCEL = "cancel";

//feedback
export const PLEASE_SELECT_ORDERS = "Please select orders"
export const UPDATE_SUCCESS = "Update success"
export const UPDATE_FAIL = "Update fail"



