import React from "react";
import withShipment from "../../../withShipment";
import {stateAndProvinceMappingSelector} from "../../../redux/selectors/global";
import {FTL_PAGES} from "../constants";
import FullTruckloadSetup from "./FullTruckloadSetup";
import FullTruckloadAddBroker from "./FullTruckloadBrokersForm";
import FullTruckloadAddCarrier from "./FullTruckloadCarriersForm";
import FullTruckLoadBrokers from "./FullTruckloadBrokers";

class FullTruckLoadSetting extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: FTL_PAGES.SETUP, //options are setup, carriers, brokers
        }
    }

    handleTabChange = (selectedTab) =>{
        const tabLabels = [FTL_PAGES.SETUP, FTL_PAGES.BROKERS];
        switch (tabLabels[selectedTab]) {
            case FTL_PAGES.SETUP:
                this.setState({
                    selectedTab: FTL_PAGES.SETUP
                })
                break;
            case FTL_PAGES.BROKERS:
                this.setState({
                    selectedTab: FTL_PAGES.BROKERS
                })
                break;
            default:
                this.setState({
                    selectedTab: FTL_PAGES.SETUP
                })
        }
    }

    tabLabelToTabIndex = (label) =>{
        switch (label) {
            case FTL_PAGES.SETUP:
                return 0
            case FTL_PAGES.BROKERS:
                return 1
            default:
                return 0
        }
    }

    displaySetup = () => {
        return <React.Fragment>
            <FullTruckloadSetup
                selectedTab={this.tabLabelToTabIndex(this.state.selectedTab)}
                handleTabChange={this.handleTabChange}
                previewFullTruckloadFile={this.props.previewFullTruckloadFile}
                prevPage={this.props.prevPage}
                setCurrentPage={this.props.setCurrentPage}
            />
        </React.Fragment>
    }

    displayBrokers = () =>{
        return <React.Fragment>
            <FullTruckLoadBrokers
                selectedTab={this.tabLabelToTabIndex(this.state.selectedTab)}
                handleTabChange={this.handleTabChange}
                setCurrentPage={this.props.setCurrentPage}
                prevPage={this.state.prevPage}
            />
        </React.Fragment>
    }

    displayCurrentPage = (currentPage) =>{
        switch (currentPage) {
            case FTL_PAGES.SETUP:
                return this.displaySetup()
            case FTL_PAGES.BROKERS:
                return this.displayBrokers()
        }
    }

    render(){
        return (
            <React.Fragment>
                {this.displayCurrentPage(this.state.selectedTab)}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    stateAndProvinceMapping: stateAndProvinceMappingSelector(state),
})


const actionCreators = {
}

export default withShipment({
    mapStateToProps,
    actionCreators,
}, FullTruckLoadSetting);