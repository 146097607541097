
import React, { Component } from "react";
import FilterTag from "./FilterTag";

// import styling
import './FilterTagsBar.css'
import PropTypes from "prop-types";

class FilterTagsBar extends Component {
    constructor(props) {
        super(props);
    }

    /**
     * Creates the filter tag with "key" : "value" format
     */
    createTags = () => {
        if (!this.props.filtersMap)
            return
        let keys = Object.keys(this.props.filtersMap);
        let children = [];
        keys = keys.filter(k => k !== 'dense' && k !== 'maxAllowedDateRange')
        keys = keys.filter(k => k !== 'sortCompleted' && k !== 'sortIncomplete' && k !== 'sortNotValidated' && k !== 'sortRated')
        keys.map(key => {
            children.push(
                <div className="shipment-tag">
                    <FilterTag tag={key}
                        id={key}
                        removeTag={this.props.onClick}
                        name={
                            this.getName(key) +
                            (this.isDateFormat(key) ?
                                this.formatDate(this.props.filtersMap[key])
                            :
                                this.props.filtersMap[key])}
                    />
                </div>
            );
        });

        return children;
    }

    getName = (key) => {
        let name

        if (this.props.isUnshippedShipmentsTable) {
            switch (key) {
                case "fromDate":
                    name = "ORDER FROM: "
                    break
                case "toDate":
                    name = "ORDER TO: "
                    break
                case "shipFromDate":
                    name = "SHIP BY: "
                    break
                case "shipToDate":
                    name = "SHIP BY: "
                    break
                default:
                    name = key + ": "
            }
        } else {
            switch (key) {
                case "fromDate":
                    name = "FROM: "
                    break
                case "toDate":
                    name = "TO: "
                    break
                default:
                    name = key + ": "
            }
        }
        return name
    }

    formatDate = (dateString) => {
        let date = new Date(dateString)

        let MM_dd_yyyy = date.toLocaleDateString("en-US")
        let MM = MM_dd_yyyy.split("/")[0]
        if (MM.length === 1) {MM = "0" + MM}
        let dd = MM_dd_yyyy.split("/")[1]
        if (dd.length === 1) {dd = "0" + dd}
        let yyyy = MM_dd_yyyy.split("/")[2].substr(0,4)

        return yyyy + "-" + MM + "-" + dd
    }

    isDateFormat = (key) => {
        if (this.props.isUnshippedShipmentsTable) {
            return key === "fromDate" || key === "shipFromDate" || key === "toDate" || key === "shipToDate"
        } else {
            return key === "fromDate" || key === "toDate"
        }
    }

    createShipmentTags = () => {
        let children = [];
        if (this.props.tags) {
            this.props.tags.forEach(tag => {
                children.push(
                    <div className="shipment-tag">
                        <FilterTag tag={tag} id={tag.tagId} name={tag.tagName}
                            removeTag={this.props.onClick} />
                    </div>);
            })
        }
        return children;
    }

    /**
     * Dynamically create the tags 
     */
    render() {

        return (
            <div className="filterTagsBar" id="filtersTagBar">
                {this.props.isShipmentTags ?
                    this.createShipmentTags()
                    :
                    this.createTags()
                }
            </div>
        )
    }
}

FilterTagsBar.propTypes = {
    onClick: PropTypes.func,
    filtersMap: PropTypes.object
}

FilterTagsBar.defaultProps = {
    onClick: () => { },
    filtersMap: {}
}

export default FilterTagsBar