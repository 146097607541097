import React from "react";
import withShipment from "../../../../withShipment";
import './BarcodesTable.css';
import TopToolbar from "../../../global/subcomponents/topToolbar/TopToolbar";
import {BARCODES_EDIT_ROUTE, BARCODES_ROWS_PER_PAGE_LABEL, BARCODES_TITLE} from "../constants";
import TablePage from "../../../global/Table/TablePage";
import {barcodesSelector, printConfigSelector, productsSelector} from "../../../../redux/selectors/settings";
import {fetchBarcodes, updateBarcodes} from "../../../../redux/actions/settings";
import {Layout} from "../../../global/Table/TablePageHelpers/LayoutObject";
import {Autocomplete, TableCell} from "@mui/material";
import TwoStepDelete from "../../../global/subcomponents/twoStepDelete/TwoStepDelete";
import {ComponentToPrint2} from "../../ManageListings/ComponentToPrint";
import Tooltip from "@material-ui/core/Tooltip";
import {APPLY_EDIT_ENTRY, CANCEL_EDIT_ENTRY, EDIT_ENTRY} from "../constants";
import {IconButton, TextField} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from '@mui/icons-material/Clear';
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ReactToPrint from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import {ROLE_ADMIN} from "../../../global/constants";
import {userInfoSelector} from "../../../../redux/selectors/auth";


class BarcodesTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editRowShortcode: "",
            editingQuantityBarcodes: [],
            deleteId: "",

            printing: false,
            printingShortcode: '',
            printingQuantity: 0,
            printingBarcode: '',
            topMargin:  '',
            rowSize: '',
        }
    }

    componentDidMount() {
        this.setState({
            topMargin: this.getTopMargin(),
            rowSize: this.getRowSize()
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.printConfig !== this.props.printConfig) {
            this.setState({
                topMargin: this.getTopMargin(),
                rowSize: this.getRowSize()
            })
        }
    }

    getTopMargin = () => {
        let topMargin = this.props.printConfig?.topMargin
        if (!topMargin)
            return '25px'
        return topMargin
    }
    getRowSize = () => {
        let rowSize = this.props.printConfig?.rowSize
        if (!rowSize)
            return '144px'
        return rowSize
    }

    userIsAnAdmin = () => {
        return this.props.userInfo?.authority === ROLE_ADMIN
    }

    userHasBarcodesEditPermission = () => {
        return this.props.userInfo?.routes?.includes(BARCODES_EDIT_ROUTE)
    }

    handleDeleteBarcodeRow = (barcodeString) => {
        let barcodeRow = this.props.barcodes?.find(barcodeRow =>
            barcodeRow.quantityBarcodes?.find(quantityBarcode => quantityBarcode.barcode === barcodeString)
        )
        if (!barcodeRow) {
            return
        }

        let newQuantityBarcodes = [...barcodeRow.quantityBarcodes]
        let index = newQuantityBarcodes.findIndex(quantityBarcode => quantityBarcode.barcode === barcodeString)
        newQuantityBarcodes.splice(index, 1)

        let request = {
            shortcode: barcodeRow.shortcode,
            quantityBarcodes: newQuantityBarcodes
        }

        this.props.updateBarcodes(request)
        this.setState({
            deleteId: ""
        })
    }

    displayTwoStepDelete = (barcodeString) => {
        return <React.Fragment>
            <TwoStepDelete
                rowId={barcodeString}
                handleDelete={(barcodeString) => this.handleDeleteBarcodeRow(barcodeString)}
                deleteId={this.state.deleteId}
                setDeleteId={(barcodeString) => this.setState({deleteId: barcodeString})}
                noTableCellContainer
            />
        </React.Fragment>
    }

    displayPrintIcon = (row, quantityBarcode) => {
        return <div className="quantityBarcodesSubtable-printIcon">
            <div className="barcodesVerticallyCenter">
                <ReactToPrint
                    trigger={() =><IconButton size={"small"}>
                        <PrintIcon/>
                    </IconButton>}
                    onBeforeGetContent={() => this.handleBeforeGetContent(row, quantityBarcode)}
                    onAfterPrint={() => this.setState({
                        printing: false,
                        printingShortcode: '',
                        printingQuantity: 0,
                        printingBarcode: '',
                    })}
                    content={() => this.componentRef}
                    pageStyle={'@page { margin: 50 0 50 0 !important; }'} // page margins: top right bottom left
                />
            </div>
        </div>
    }

    prepareToPrint = () => {
        return <React.Fragment>
            {this.state.printing ?
                <div className="display-none">
                    <ComponentToPrint2
                        rows={10}
                        quantity={this.state.printingQuantity}
                        shortcode={this.state.printingShortcode}
                        barcode={this.state.printingBarcode}
                        ref={(el) => (this.componentRef = el)}
                        topMargin={this.state.topMargin}
                        rowSize={this.state.rowSize}
                    />
                </div>
                :
                null
            }
        </React.Fragment>
    }

    handleBeforeGetContent = (row, quantityBarcode) => {
        return new Promise((resolve, reject) => {
            this.setState({
                printing: true,
                printingShortcode: row.shortcode,
                printingQuantity: quantityBarcode.quantity,
                printingBarcode: quantityBarcode.barcode,
            }, () => resolve());
        });
    }

    getSearchStrings = (row) => {
        let strings = [
            row.shortcode.toString()
        ]
        if (row.quantityBarcodes) {
            for (let i = 0; i < row.quantityBarcodes.length; i++) {
                strings.push(row.quantityBarcodes[i].quantity.toString())
                strings.push(row.quantityBarcodes[i].barcode.toString())
            }
        }
        return strings
    }

    editRow = (row) => {
        if (!this.userHasBarcodesEditPermission()) {
            return // don't open editing if user doesn't have access to do so
        }

        if (this.state.editRowShortcode === row.shortcode) {
            return
        }

        let newEditingQuantityBarcodes = row.quantityBarcodes?.map(quantityBarcode => {return {quantity: quantityBarcode.quantity, barcode: quantityBarcode.barcode}})
        if (!newEditingQuantityBarcodes || newEditingQuantityBarcodes.length < 1) {newEditingQuantityBarcodes = []}

        this.setState({
            editRowShortcode: row.shortcode,
            editingQuantityBarcodes: newEditingQuantityBarcodes,
        })
    }

    handleCancel = (row) => {
        this.setState({
            editRowShortcode: '',
            editingQuantityBarcodes: [],
        })
    }

    handleConfirm = (row) => {
        let editingQuantityBarcodes = [...this.state.editingQuantityBarcodes]
        if (!editingQuantityBarcodes || editingQuantityBarcodes.length < 1) {editingQuantityBarcodes = []}

        let quantitesIncluded = []

        for (let i = 0; i < editingQuantityBarcodes.length; i++) {
            if (!editingQuantityBarcodes[i].quantity || !editingQuantityBarcodes[i].barcode) {
                return // do not send request if not all fields filled
            }
            if (quantitesIncluded.includes(editingQuantityBarcodes[i].quantity)) {
                return // do not send request if a quantity repeats
            }
            quantitesIncluded.push(editingQuantityBarcodes[i].quantity)
        }

        let request = {
            shortcode: row.shortcode,
            quantityBarcodes: editingQuantityBarcodes
        }

        this.props.updateBarcodes(request)
        this.handleCancel(row)
    }

    editIconColumn = (row) => {
        if (row.shortcode !== this.state.editRowShortcode) {
            return <TableCell style={{width: "76px", padding: "0px"}}>
                <div className='barcodesHorizontallyCenter'>
                    <Tooltip title={EDIT_ENTRY}>
                        <IconButton onClick={() => this.editRow(row)}>
                            <EditIcon
                                fontSize='small'
                            />
                        </IconButton>
                    </Tooltip>
                </div>
            </TableCell>
        } else {
            return <TableCell style={{width: "76px", padding: "0px"}}>
                <div style={{paddingLeft: "16px", paddingRight: "16px"}}>
                    <Tooltip title={CANCEL_EDIT_ENTRY}>
                        <IconButton onClick={() => this.handleCancel(row)}>
                            <ClearIcon
                                fontSize='small'
                                color='error'
                            />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title={APPLY_EDIT_ENTRY}>
                        <IconButton onClick={() => this.handleConfirm(row)}>
                            <CheckCircleIcon
                                fontSize="small"
                                color='secondary'
                            />
                        </IconButton>
                    </Tooltip>
                </div>
            </TableCell>
        }
    }

    handleUpdateBarcode = (index, value) => {
        let newEditingQuantityBarcodes = [...this.state.editingQuantityBarcodes]
        if (!newEditingQuantityBarcodes[index]) {
            return
        }

        newEditingQuantityBarcodes[index].barcode = value ? value : ""

        this.setState({
            editingQuantityBarcodes: newEditingQuantityBarcodes
        })
    }

    handleUpdateQuantity = (index, value) => {
        let newEditingQuantityBarcodes = [...this.state.editingQuantityBarcodes]
        if (!newEditingQuantityBarcodes[index]) {
            return
        }

        let newValue
        try {
            newValue = parseInt(value)
        } catch (ignored) {
        }

        newEditingQuantityBarcodes[index].quantity = newValue

        this.setState({
            editingQuantityBarcodes: newEditingQuantityBarcodes
        })
    }

    handleAddNewQuantityBarcode = () => {
        let newEditingQuantityBarcodes = [...this.state.editingQuantityBarcodes]
        if (!newEditingQuantityBarcodes || newEditingQuantityBarcodes.length < 1) {newEditingQuantityBarcodes = []}

        newEditingQuantityBarcodes.push({quantity: 0, barcode: ""})

        this.setState({
            editingQuantityBarcodes: newEditingQuantityBarcodes
        })
    }

    addQuantityBarcodeButton = () => {
        return <React.Fragment>
            <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={(e) => this.handleAddNewQuantityBarcode()}
                style={{ margin: 8 }}
            >Add</Button>
        </React.Fragment>
    }

    getEditingQuantityFieldErrorValue = (quantityBarcode, index) => {
        if (!this.state.editingQuantityBarcodes ||
            !this.state.editingQuantityBarcodes[index] ||
            !this.state.editingQuantityBarcodes[index].quantity) {
            return true
        }
        if (this.state.editingQuantityBarcodes.find((conflictingQuantityBarcode, conflictingIndex) => {
            return quantityBarcode.quantity === conflictingQuantityBarcode.quantity && conflictingIndex !== index
        })) {
            return true
        }
        return false
    }

    getEditingQuantityBarcodeRow = (row, quantityBarcode, index) => {
        return <React.Fragment>
                <div className='quantityBarcodesSubtable-editingQuantityCell'>
                    <TextField
                        name="Quantity"
                        variant="outlined"
                        label="Quantity"
                        style={{width: "100%"}}
                        value={!this.state.editingQuantityBarcodes || !this.state.editingQuantityBarcodes[index] ? "" :
                            this.state.editingQuantityBarcodes[index].quantity ? this.state.editingQuantityBarcodes[index].quantity.toString() : ""}
                        onChange={(e) => this.handleUpdateQuantity(index, e.target.value)}
                        error={this.getEditingQuantityFieldErrorValue(quantityBarcode, index)}
                    />
                </div>
                <div className='quantityBarcodesSubtable-editingBarcodesCell'>
                    <TextField
                        name="Barcode"
                        variant="outlined"
                        label="Barcode"
                        style={{width: "100%"}}
                        value={this.state.editingQuantityBarcodes && this.state.editingQuantityBarcodes[index] ? this.state.editingQuantityBarcodes[index].barcode?.toString() : ""}
                        onChange={(e) => this.handleUpdateBarcode(index, e.target.value)}
                        error={!this.state.editingQuantityBarcodes ||
                            !this.state.editingQuantityBarcodes[index] ||
                            !this.state.editingQuantityBarcodes[index].barcode
                        }
                    />
                </div>
        </React.Fragment>
    }

    getQuantityBarcodeRow = (row, quantityBarcode) => {
        return <React.Fragment>
            <div className='quantityBarcodesSubtable-quantityCell'>
                <div className='barcodesVerticallyCenter'>
                    {quantityBarcode.quantity}
                </div>
            </div>
            <div className='quantityBarcodesSubtable-barcodesCell'>
                <div className='quantityBarcodesSubtable-barcodeText'>
                    <div className='barcodesVerticallyCenter'>
                        {quantityBarcode.barcode}
                    </div>
                </div>

                {this.displayPrintIcon(row, quantityBarcode)}
                {!this.userIsAnAdmin() ? null :
                    this.displayTwoStepDelete(quantityBarcode.barcode)
                }
            </div>
        </React.Fragment>
    }

    getTableRow = (row) => {
        return <React.Fragment>
            {this.userHasBarcodesEditPermission() ? this.editIconColumn(row) : null}
            <TableCell style={{padding: "0px"}}>
                <div className='barcodesHorizontallyCenter'>
                    <div style={{height: "44px"}}>
                        <div className='barcodesVerticallyCenter'>
                            {row.shortcode}
                        </div>
                    </div>
                </div>
            </TableCell>
            <TableCell colSpan={2} style={{padding: "0px"}}>
                <div className='quantityBarcodesSubtable-container'>
                {
                    row.shortcode !== this.state.editRowShortcode ?
                        row.quantityBarcodes?.map((quantityBarcode, index) => {
                            return <div className='quantityBarcodesSubtable-row'>
                                {this.getQuantityBarcodeRow(row, quantityBarcode)}
                            </div>
                        })
                        :
                        this.state.editingQuantityBarcodes?.map((quantityBarcode, index) => {
                            return <div className='quantityBarcodesSubtableEditing-row'>
                                {this.getEditingQuantityBarcodeRow(row, quantityBarcode, index)}
                            </div>
                        })
                }

                {/*  add button  */}
                {row.shortcode !== this.state.editRowShortcode ? null :
                    <div className='quantityBarcodesSubtable-row'>
                        {this.addQuantityBarcodeButton()}
                    </div>
                }
                </div>

            </TableCell>
        </React.Fragment>
    }

    getTableColumnHeads = () => {
        let columnHeads = []

        if (this.userHasBarcodesEditPermission()) {
            columnHeads.push(Layout.columnHead(null, null, null)) // edit icon row)
        }

        columnHeads.push(Layout.columnHead("Shortcode", "shortcode", "center"))
        columnHeads.push(Layout.columnHead("Quantity", "quantity", "center", "15%"))
        columnHeads.push(Layout.columnHead("Barcode", null, "center", "45%"))

        return columnHeads
    }


    render() {
        return (
            <React.Fragment>
                {this.prepareToPrint()}
                <TopToolbar
                    pageName={BARCODES_TITLE}
                />
                <TablePage
                    tableName="BarcodesTable"

                    tableColumnHeads={this.getTableColumnHeads()}
                    tableRow={(row, index) => this.getTableRow(row)}

                    tableRows={this.props.barcodes}
                    rowFetcher={(filters) => {this.props.fetchBarcodes()}}

                    paginationText={BARCODES_ROWS_PER_PAGE_LABEL}

                    hasSearchField
                    searchStrings={(row) => this.getSearchStrings(row) }

                    // onRowDoubleClick={(row) => this.editRow(row)} // proved inconvenient with touch pad
                />
            </React.Fragment>
        )
    }
}

BarcodesTable.defaultProps = {
    userInfo: {},
}

BarcodesTable.propTypes = {
}

const mapStateToProps = (state) => ({
    barcodes: barcodesSelector(state),
    printConfig: printConfigSelector(state),
    products: productsSelector(state),
    userInfo: userInfoSelector(state),
})

const actionCreators = {
    fetchBarcodes,
    updateBarcodes,
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, BarcodesTable);