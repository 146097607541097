export const TICKETS_TOOLBAR_TITLE = 'Tickets';
export const CREATE_TICKET_TITLE = 'Create a new ticket';
export const BACK_TO_MENU = 'Back to Menu';

export const TICKETS_TABLE_HEAD_CELLS = [
    { id: 'dueDate', numeric: false, disablePadding: false, label: 'Due Date' },
    { id: 'customerName', numeric: false, disablePadding: false, label: 'Customer Name' },
    { id: 'originalTrackingId', numeric: false, disablePadding: false, label: 'Tracking Number' },
    { id: 'orderId', numeric: false, disablePadding: false, label: 'Order ID' },
    { id: 'ticketIssueName', numeric: false, disablePadding: false, label: 'Issue' },
    { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
    { id: 'createdByName', numeric: false, disablePadding: false, label: 'Created By' },
    { id: 'assignedToName', numeric: false, disablePadding: false, label: 'Assigned To' }
];

export const DEFAULT_SORT_BY_HEAD_CELL = 'dueDate';
export const ROWS_PER_PAGE = 50;

export const ISSUES = [
    'Damaged Item Received',
    'Wrong Item Sent',
    'Lost Package',
    'Wrong Item Ordered'
];
export const ACTIONS = {
    'Wrong Item Sent': [
        'Send Redirect Label',
        'Send Return Label',
        'Issue Refund',
        'Send Replacement Package',
        'Case Closed'
    ],
    'Wrong Item Ordered': [
        'Redirect',
        'Issue Refund',
        'Case Closed'
    ],
    'Damaged Item Received': [
        'Send Replacement',
        'Case Closed'
    ],
    'Lost Package': [
        'Tough'
    ]

};

export const SAVE = 'Save';
export const CANCEL = 'Cancel';

export const ADD_ACTION = 'Add an Action';

export const TASKS_CHECKBOXES_HEADER = 'Tasks';

export const ADD_ACTION_BUTTON_TEXT = "Add Action";

export const COMPLETED_STATUS = 'Completed';


export const DEFAULT_ROWS_PER_PAGE = 10;
export const ROWS_PER_PAGE_LABEL = 'Tickets per page';

export const SHOW_MY_TICKETS = "Show my tickets only";
export const SHOW_COMPLETED_TICKETS = "Show completed tickets";

export const WRONG_ITEM_ORDERED_SHORTCODE_LABEL = "Product Ordered (Shortcode)";
export const WRONG_ITEM_ORDERED_QUANTITY_LABEL = "Quantity Ordered";

export const WRONG_ITEM_SENT_SHORTCODE_LABEL = "Product Sent (Shortcode)";
export const WRONG_ITEM_SENT_QUANTITY_LABEL = "Quantity Sent";

export const ACTION_LABEL = "Select an action"