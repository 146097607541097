export const LIST_CLAIMS_CELLS = [
    { id: 'closeDate',label: 'Close Date'},
    { id: 'trackingNumber',label: 'Tracking Number'},
    { id: 'orderId',label: 'Order ID'},
    { id: 'checkNumber',label: 'Check Number'},
    { id: 'carrier',label: 'Carrier'},
    { id: 'currency',label: 'Currency'},
    { id: 'status',label: 'Status'},
    { id: 'paymentVerified',label: 'Payment Verified'}
];

export const CLAIMS_PAGES = {
    TABLE: "table",
    CREATE: "create",
    UPDATE: "update",
    PREVIEW: "preview"
}

export const EDIT_ENTRY = "Edit";
export const CLAIM_PAGE_NAME = "Claims"
export const CREATE_CLAIM_ENTRY = "Create Claim Entry"
export const DEFAULT_SORT_BY_HEAD_CELL = 'closeDate';
export const ROWS_PER_PAGE_LABEL = "Claim entries per page"
export const BACK_BUTTON = "Back"
export const PAYMENT_VERIFIED_PROMPT = "Is payment verified?"
export const CANCEL = "Cancel";
export const SAVE = "Save";

export const CURRENCIES = [
    "USD",
    "CAD"
]

export const STATUS = [
    "ACTIVE INVESTIGATION",
    "DECLINE",
    "DISPUTE",
    "INVESTIGATE",
    "NO DATA AVAILABLE",
    "OPEN",
    "PAID",
    "RECONCILED"
]

export const CLAIM_FILE_INFO = "Upload claim files by selecting files from your computer"

export const CARRIER_REASON_FOR_DECLINE = [
    "Concealed Damage",
    "Residential Driver Release",
    "Bus. Decision to Pay -Exception to Policy",
    "Denial of Signature",
    "Concealed Loss",
    "Driver Release",
    "Signature Option Not Requested",
    "Shipper Waiver Required",
    "Wreck and Catastrophe",
    "Visible Damage",
    "Loss/No Record of Delivery",
    "No Possession Scan of Package",
    "Additional Document Request/Pending Docs/9 Months",
    "Picture Exam/Insufficient Packaging",
    "Exceeding 21 Days",
    "Additional Document Request",
    "Signature Option Not Requested/Delivery at correct address as per Fedex record",
    "Picture Exam/Insufficient Pkg. Packaged not adequately packed for safe transportation, Fedex."
]