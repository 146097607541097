import React from "react";
import './PricingDashboardTableRow.css';
import Tooltip from "@material-ui/core/Tooltip";
import withShipment from "../../../withShipment";
import {getTop3BuyBoxOffers} from "../../../redux/actions/settings";
import InfoIcon from '@material-ui/icons/Info';
import {top3BuyBoxOffersSelector} from "../../../redux/selectors/settings";
import PropTypes from "prop-types";


class Top3BuyBoxOffer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            top3BuyBoxOffers: []
        };
    }

    componentDidMount() {
        this.props.getTop3BuyBoxOffers(this.props.row.webstoreProductId);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.top3BuyBoxOffers !== this.props.top3BuyBoxOffers) {
            this.setState({
                top3BuyBoxOffers: this.props.top3BuyBoxOffers
            })
        }
    }

    render() {
        const offers = this.state.top3BuyBoxOffers || []
        return (
            <div  className= "popoverContent">
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <thead>
                    <tr>
                        <th style={{ textAlign: 'left', paddingRight: '15px' }}>Seller ID</th>
                        <th style={{ textAlign: 'left', paddingRight: '15px' }}>Selling Price</th>
                    </tr>
                    </thead>
                    <tbody>
                    {offers.map((offer, index) => {
                        const listingPrice = parseFloat(offer.listingPrice) || 0;
                        const shippingFee = parseFloat(offer.shippingFee) || 0;

                        const totalPrice = listingPrice + shippingFee;
                        const sellerUrl = `https://www.${this.props.row.marketplaces[0]}/sp?seller=${offer.sellerId}`;

                        return (
                            <tr key={index}>
                                <td style={{ paddingRight: '15px' }}>
                                    <a
                                        href={sellerUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ color: 'white', cursor: 'pointer' }}
                                    >
                                        {offer.sellerId}
                                    </a>
                                </td>
                                <td style={{ paddingRight: '15px' }}>
                                    {totalPrice.toFixed(2)} {offer.listingPriceCurrency}
                                    {shippingFee > 0 && (
                                        <Tooltip
                                            title={`Shipping Fee (included): ${shippingFee.toFixed(2)} ${offer.shippingFeeCurrency}`}
                                            enterTouchDelay={0}  // Show immediately on touch
                                            leaveTouchDelay={1500} // Keeps it open for 1.5s after touch
                                        >
                                            <InfoIcon style={{ marginLeft: '8px', cursor: 'pointer' }} />
                                        </Tooltip>
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
        )
    }
}

Top3BuyBoxOffer.defaultProps = {
    top3BuyBoxOffers: [],
}

Top3BuyBoxOffer.propTypes = {
    top3BuyBoxOffers: PropTypes.array,
}

const mapStateToProps = (state) => ({
    top3BuyBoxOffers: top3BuyBoxOffersSelector(state),
})

const actionCreators = {
    getTop3BuyBoxOffers,

}

export default withShipment({
    mapStateToProps,
    actionCreators
}, Top3BuyBoxOffer);