import React from "react";


// constants
import {
    ADD_SALES_PIPELINE,
    BACK, EDIT_SALES_PIPELINE, SALES_PIPELINE_PAGES, SAVE_SALES_PIPELINE, SALES_STAGES_OPTIONS,
} from "./constants";


import ErrorFeedbackBar from "../../global/ErrorFeedbackBar";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import {Button, IconButton, TextField, Typography} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import PhoneIcon from "@material-ui/icons/Phone";
import Divider from "@material-ui/core/Divider";
import CommentHistoryTable from "../../global/CommentHistoryTable";
import {CANCEL, DEFAULT_PDF_ZOOM, getByteArray, SAVE} from "../../global/constants";
import SaveIcon from "@material-ui/icons/Save";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {Tooltip} from "@mui/material";
import {ATTACHMENTS_INFO} from "../customerInvoices/constants";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import FileUpload from "../../global/FileUpload";
import PDFViewer from "../../global/PDFViewer";
import DescriptionIcon from "@material-ui/icons/Description";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import {GetApp} from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CustomToolbar from "../../global/CustomToolbar";
import ClearIcon from "@material-ui/icons/Clear";

class SalesPipelineForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            companyName: '',
            contactName: '',
            phone: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            zip: '',
            country: '',
            email:'',
            category: null,
            stage: null,
            salesPerson: null,
            salesPipelineId: '',
            scale: DEFAULT_PDF_ZOOM,
            newComment: "",
            comments: this.props.pipeline?.comments ? this.props.pipeline?.comments : [
                {
                    user: `${this.props.currentUser.firstname} ${this.props.currentUser.lastname}`,
                    date: new Date().toISOString(),
                    comment: 'Initial Contact',
                }
            ],
            newFiles: [],
            files: this.props.pipeline?.files ? this.props.pipeline?.files : [],
            viewingFile: {},
            viewing: false,
            editing: null,
            deletingAttachment: false,
            fromDate: this.getFromDate(),
            toDate: this.getToDate(),
            requiredList: [
                "companyName",
                "contactName",
                "phone",
                "addressLine1",
                "city",
                "state",
                "zip",
                "country",
                "stage",
                "salesPerson",
                "email",
                "category"
            ],
        }

        this.hiddenFileInput = React.createRef()
        this.fileUpload = this.fileUpload.bind(this)
    }


    componentDidMount() {

        if(this.props.pipeline?.salesPipelineId){

            const selectedSalesPerson = this.props.users.find(
                user => user.name === this.props.pipeline.salesPerson
            );

            const selectedCategory = this.props.categories.find(
                category => category.categoryName === this.props.pipeline.category
            );

            const selectedStage = SALES_STAGES_OPTIONS.find(
                stage => stage.name === this.props.pipeline.stage
            );

            this.setState({
                companyName: this.props.pipeline?.companyName,
                contactName: this.props.pipeline?.contactName,
                salesPipelineId: this.props.pipeline?.salesPipelineId,
                addressLine1: this.props.pipeline?.contactAddressLine1 ,
                addressLine2: this.props.pipeline?.contactAddressLine2,
                phone: this.props.pipeline?.contactPhone,
                email: this.props.pipeline?.contactEmail,
                city: this.props.pipeline?.contactCity,
                state: this.props.pipeline?.contactState,
                zip: this.props.pipeline?.contactZip,
                country: this.props.pipeline?.contactCountry,
                category: selectedCategory,
                stage: selectedStage,
                salesPerson: selectedSalesPerson
            },() => {
                this.props.onFormChange(this.state);
            });

        }



    }


    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.editingPipelineChanged) {
            const editing = this.props.pipelines.filter(pipeline => pipeline.salesPipelineId === this.state.salesPipelineId)[0]
                this.setState({
                    files: editing.files
                })
            this.props.updatePipelineChanged()
        }

    }


    getFromDate = () => {
        const date = new Date();
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setDate(date.getDate() - 14)
        return date;
    }

    getToDate = () => {
        const date = new Date();
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
        return date;
    }

    fileUpload(e) {
        if (e.target.files.length === 0) return
        let file = e.target.files[0]
        let fileReader = new FileReader();

        let storage = localStorage.getItem("pipeline")
        let fromDate
        let toDate
        if (storage) {
            let storageObj = JSON.parse(storage);
            fromDate = storageObj?.fromDate ?? this.state.fromDate
            toDate = storageObj?.toDate ?? this.state.toDate
        }
        fileReader.onload = (e) => {
            let contents = new Uint8Array(e.target.result)
        }
        fileReader.readAsArrayBuffer(file)
    }


    //attachment
    getFormattedGeneralFileObject = (file) => {
        return { localURL: URL.createObjectURL(file), displayName: file.name, byteArray: getByteArray(file), type: file.type }

    }

    handleAttachmentPreview = (file, index) => {
        this.props.getPipelineAttachment({
            path: file.path
        })
        //type: "attachment"
        this.setState({ viewing: true, viewingFile: file})
    }


    handleGeneralFileDelete = (file, fileIndex) => {
        let newFiles = this.state.newFiles
        let deletedFile = (newFiles.splice(fileIndex, 1))[0]
        let i = 0
        for (let file1 in newFiles) {
            if (file1 === file) {
                newFiles.splice(i, 1)
            }
        }

        this.setState({
            newFiles: newFiles,
        })
    }



    handleGeneralFileAdd = (file) => {
        let newFiles = this.state.newFiles
        let newFile = this.getFormattedGeneralFileObject(file)
        if (newFiles && newFiles.length > 0) {
            newFiles.push(newFile)
        }
        else {
            newFiles = [newFile]
        }
        this.setState({
            newFiles: newFiles,
        });
    }

    handleCommentUpdate = (e) => {
        this.setState({ newComment: e.target.value });
    };

    submitComment = () => {
        const newCommentObj = {
            user: this.props.currentUser.firstname + " " + this.props.currentUser.lastname,
            date: new Date().toISOString(),
            comment: this.state.newComment
        };

        this.setState((prevState) => ({
            comments: [...prevState.comments, newCommentObj],
            newComment: ""  // Clear the input after submitting
        }), () => {
            this.props.onFormChange(this.state);
            this.save()
            // Notify parent component of the change
        });
    };

    handleClearComment = () => {
        this.setState({
            comments: ''
        }, () => {
            this.props.onFormChange(this.state);
        })
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        }, () => {
            this.props.onFormChange(this.state);
        });
    };

    isHandleSave = () => {
        const { requiredList, ...fields } = this.state;
        let hasEmpty = false;

        requiredList.forEach(field => {
            if (!fields[field]) {
                hasEmpty = true;
            }
        });


        return hasEmpty;
    };


    downloadAttachment = () => {
        let blob = new Blob([Uint8Array.from(this.props.attachment)], { type: "application/pdf" });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = this.state.viewingFile.displayName ? this.state.viewingFile.displayName : this.state.name
        link.click();

        window.URL.revokeObjectURL(link.href)
    }

    save = () =>{
        const {newFiles, salesPipelineId, companyName, contactName, email, phone, addressLine1, addressLine2, city, state, zip, country, category, stage, salesPerson, comments} = this.state


        if(this.state.newComment){
            return this.submitComment()
        }
            const pipeline = {
                salesPipelineId,
                companyName,
                contactName,
                email,
                phone,
                addressLine1,
                addressLine2,
                city,
                state,
                zip,
                country,
                category,
                stage,
                salesPerson,
                comments,
                newFiles,
            };

        const data ={
            pipeline: pipeline
        }

        this.props.handleSave(data)

    }

    deleteAttachment = () => {
        this.props.deletePipelineAttachment({
            displayName: this.state.viewingFile.displayName,
            path: this.state.viewingFile.path,
            salesPipelineId:this.state.salesPipelineId
        },this.updatePipeline)
        this.setState({ viewing: false, viewingFile: {}, deletingAttachment: false})
    }

    updatePipeline = (pipeline) => {
        this.setState({
            editing: pipeline
        })
    }

    setZoom(zoom) {
        let newScale = Math.min(Math.max(this.state.scale + zoom, 1), 2)
        this.setState({
            scale: newScale
        })
    }

    render() {

        let headerButtons
        if (this.state.viewing && !this.state.deletingAttachment) {
            headerButtons = [
                {
                    icon: <KeyboardBackspaceIcon />,
                    handler: () =>
                    {
                        this.setState({ viewing: false, viewingFile: {}, deletingAttachment: false })
                    },
                    disabled: false,
                    title: "Back"
                },
                { icon: <ZoomOutIcon />,
                    handler: () => this.setZoom(-0.25),
                    title: "Zoom Out",
                    disabled: false
                },
                {
                    divider: <span style={{ padding: "5px", marginTop: "5px" }}>{this.state.scale * 100}%</span>
                },
                { icon: <ZoomInIcon />,
                    handler: () => this.setZoom(0.25),
                    title: "Zoom In",
                    disabled: false
                },
                {
                    icon: <GetApp />,
                    handler: () => this.downloadAttachment(),
                    title: "Download PDF" },
                {
                    icon: <DeleteIcon />,
                    handler: () => this.setState({ deletingAttachment: true }),
                    disabled: false,
                    title: "Delete"
                }]
        }     else if (this.state.viewing && this.state.deletingAttachment) {
            headerButtons = [{
                icon: <KeyboardBackspaceIcon/>,
                handler: () => {
                    this.setState({viewing: false, viewingFile: {}, deletingAttachment: false})
                },
                disabled: false,
                title: "Back"
            },
                {
                    icon: <ZoomOutIcon />,
                    handler: () => this.setZoom(-0.25),
                    title: "Zoom Out",
                    disabled: false
                },
                {
                    divider: <span style={{ padding: "5px", marginTop: "5px" }}>{this.state.scale * 100}%</span>
                },
                {
                    icon: <ZoomInIcon />, handler: () => this.setZoom(0.25), title:
                        "Zoom In",
                    disabled: false
                },
                {
                    icon: <ClearIcon />,
                    handler: () => this.setState({ deletingAttachment: false }),
                    disabled: false,
                    title: "Cancel"
                },
                {
                    icon: <DeleteIcon />,
                    handler: this.deleteAttachment,
                    disabled: false,
                    title: "Confirm Delete"
                }]
        }
        else{
            headerButtons = [
                {
                    title: BACK,
                    icon: <KeyboardBackspaceIcon/>,
                    handler: () =>  this.props.setCurrentPage(SALES_PIPELINE_PAGES.TABLE, {})
                },
                {
                    title: SAVE_SALES_PIPELINE,
                    icon: <SaveIcon/>,
                    disabled: this.isHandleSave(),
                    handler: this.save

                }]
        }


        return (<>

                <React.Fragment>

                    <CustomToolbar
                        title={ this.props.pipeline?.salesPipelineId ? EDIT_SALES_PIPELINE : ADD_SALES_PIPELINE }
                        createTitle={""}
                        backTitle={""}
                        headerButtons={headerButtons}
                    />


                    {this.state.viewing && <PDFViewer data={this.props.attachment} scale={this.state.scale} /> ||
                        <>
                            <input type="file" ref={this.hiddenFileInput} accept="application/pdf" hidden
                                   onChange={this.fileUpload}/>

                            <div style={{
                                marginTop: '24px',
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}>


                                <div className="rowSpecSuppliers">

                                    <Autocomplete
                                        options={this.props.categories}
                                        getOptionLabel={(option) => option.categoryName}
                                        className='input-text-field'
                                        name="Category"
                                        value={this.state.category}
                                        onChange={(event, value) => {
                                            this.setState({
                                                category: value
                                            })
                                        }}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                label="Category"
                                                required={this.state.requiredList.includes("category")}
                                                InputLabelProps={{shrink: true}}
                                                variant="outlined"
                                                name="category"
                                            />
                                        }
                                    />

                                    <Autocomplete
                                        options={SALES_STAGES_OPTIONS}
                                        getOptionLabel={(option) => option.name}
                                        className='input-text-field'
                                        name="stage"
                                        value={this.state.stage}
                                        onChange={(event, value) => {
                                            this.setState({
                                                stage: value
                                            })
                                        }}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                label="Stage"
                                                required={this.state.requiredList.includes("stage")}
                                                InputLabelProps={{shrink: true}}
                                                variant="outlined"
                                                name="stage"
                                            />
                                        }
                                    />

                                    <Autocomplete
                                        options={this.props.users}
                                        getOptionLabel={(user) => user.name}
                                        className='input-text-field'
                                        name="Sales Person"
                                        value={this.state.salesPerson}
                                        onChange={(event, value) => {
                                            this.setState({
                                                salesPerson: value
                                            })
                                        }}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                label="Sales Person"
                                                required={this.state.requiredList.includes("salesPerson")}
                                                InputLabelProps={{shrink: true}}
                                                variant="outlined"
                                                name="salesPerson"
                                            />
                                        }
                                    />

                                </div>

                                <Divider className='section-divider' style={{marginLeft: "10px"}}/>

                                <div className='rowSpecSuppliers'>
                                    <TextField
                                        className='input-text-field'
                                        variant='outlined'
                                        label="Contact Name"
                                        InputLabelProps={{shrink: true}}
                                        name='contactName'
                                        value={this.state.contactName}
                                        onChange={this.handleChange}
                                        required={this.state.requiredList.includes("contactName")}
                                    />
                                    <TextField
                                        className='input-text-field'
                                        variant='outlined'
                                        label="Phone"
                                        InputLabelProps={{shrink: true}}
                                        name='phone'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PhoneIcon/>
                                                </InputAdornment>
                                            ),
                                        }}
                                        value={this.state.phone}
                                        onChange={this.handleChange}
                                        required={this.state.requiredList.includes("phone")}

                                    />

                                    <TextField
                                        className='input-text-field'
                                        variant='outlined'
                                        label="Email"
                                        InputLabelProps={{shrink: true}}
                                        name='email'
                                        type='email'
                                        value={this.state.email}
                                        onChange={this.handleChange}
                                        required={this.state.requiredList.includes("email")}
                                    />

                                </div>

                                <div className='rowSpecSuppliers'>
                                    <TextField
                                        variant='outlined'
                                        label="Company Name"
                                        className='single-line-field'
                                        InputLabelProps={{shrink: true}}
                                        name='companyName'
                                        value={this.state.companyName}
                                        onChange={this.handleChange}
                                        required={this.state.requiredList.includes("companyName")}
                                    />
                                </div>

                                <div className='rowSpecSuppliers'>
                                    <TextField
                                        variant='outlined'
                                        label="Address Line 1"
                                        className='single-line-field'
                                        InputLabelProps={{shrink: true}}
                                        name='addressLine1'
                                        value={this.state.addressLine1}
                                        onChange={this.handleChange}
                                        required={this.state.requiredList.includes("addressLine1")}
                                    />
                                </div>
                                <div className='rowSpecSuppliers'>
                                    <TextField
                                        variant='outlined'
                                        label="Address Line 2"
                                        className='single-line-field'
                                        InputLabelProps={{shrink: true}}
                                        name='addressLine2'
                                        value={this.state.addressLine2}
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className='rowSpecSuppliers'>
                                    <TextField
                                        className='input-text-field'
                                        variant='outlined'
                                        label="City"
                                        InputLabelProps={{shrink: true}}
                                        name='city'
                                        value={this.state.city}
                                        onChange={this.handleChange}
                                        required={this.state.requiredList.includes("city")}
                                    />
                                    <TextField
                                        className='input-text-field'
                                        variant='outlined'
                                        label="State or Province"
                                        InputLabelProps={{shrink: true}}
                                        name='state'
                                        value={this.state.state}
                                        onChange={this.handleChange}
                                        required={this.state.requiredList.includes("state")}
                                    />
                                    <TextField
                                        className='input-text-field'
                                        variant='outlined'
                                        label="Zip or Postal Code"
                                        InputLabelProps={{shrink: true}}
                                        name='zip'
                                        value={this.state.zip}
                                        onChange={this.handleChange}
                                        required={this.state.requiredList.includes("zip")}
                                    />
                                </div>
                                <div className='rowSpecSuppliers'>
                                    <TextField
                                        className='input-text-field'
                                        variant='outlined'
                                        label="Country"
                                        InputLabelProps={{shrink: true}}
                                        name='country'
                                        value={this.state.country}
                                        onChange={this.handleChange}
                                        required={this.state.requiredList.includes("country")}
                                    />
                                    <div className='input-text-field'></div>
                                    <div className='input-text-field'></div>
                                </div>

                            </div>


                            <div style={{
                                marginTop: '24px',
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}>


                                <Divider className='section-divider' style={{marginLeft: "10px"}}/>

                                <div style={{width: '98%'}}>
                                    <CommentHistoryTable
                                        disabled={false}
                                        comments={this.state.comments}
                                        handleCommentUpdate={this.handleCommentUpdate}
                                        currentUser={this.props.currentUser}
                                        clearComment={this.handleClearComment}
                                    />
                                </div>

                                <Divider className='section-divider' style={{marginLeft: "10px"}}/>

                                <div style={{width: '715px'}}>
                                    <br/>
                                    <Typography variant='h5' style={{alignSelf: "flex-start", marginBottom: "10px"}}>
                                        Attachments
                                    </Typography>
                                    <div className="attachmentsRow">
                                        <Tooltip
                                            style={{marginLeft: "2px"}}
                                            enterTouchDelay={0}
                                            leaveTouchDelay={30000}
                                            enterDelay={400}
                                            // leaveDelay={1000000} // for testing purposes
                                            title={
                                                <React.Fragment>
                                                    {ATTACHMENTS_INFO}
                                                </React.Fragment>
                                            }
                                        >
                                            <IconButton aria-label='info'>
                                                <InfoOutlinedIcon variant='outlined' fontSize='small'/>
                                            </IconButton>
                                        </Tooltip>

                                        {
                                            this.state.files?.length > 0 ?
                                                this.state.files?.map((file, index) => {
                                                    if (Object.keys(file).length > 0 && Object.keys(file).includes('displayName') && (file.displayName.includes('.pdf') || file.displayName.includes('.PDF'))) {
                                                        return (
                                                            <div className="pdf-icon">
                                                                <div style={{position: "relative"}}>
                                                                    <IconButton
                                                                        style={{position: "relative"}}
                                                                        aria-label='previous'
                                                                        onClick={() => this.handleAttachmentPreview(file, index)}
                                                                    >
                                                                        <DescriptionIcon variant='outlined'
                                                                                         fontSize='large'/>
                                                                    </IconButton>
                                                                    <div style={{
                                                                        position: "absolute",
                                                                        top: "50px",
                                                                        left: "20px",
                                                                        fontSize: "15px",
                                                                        color: "black"
                                                                    }}>
                                                                        {file.displayName.length > 10 ? file.displayName.substring(0, 12) + '...' : file.displayName}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    } else {
                                                        return null
                                                    }
                                                })
                                                :
                                                null}

                                    </div>
                                    <div style={{alignSelf: "flex-start"}}>
                                        <FileUpload
                                            handleFileAdd={(file) => this.handleGeneralFileAdd(file)}
                                            handleFileDelete={(file, fileIndex) => this.handleGeneralFileDelete(file, fileIndex)}
                                            files={this.state.newFiles ? this.state.newFiles : []}
                                            fetchFilePreview={null}
                                            singleFileUpload={true}
                                        />
                                    </div>
                                </div>

                            </div>


                            <br/>

                            <div className='save-cancel-buttons'>
                                <Button
                                    variant='contained'
                                    onClick={null}
                                >
                                    {CANCEL}
                                </Button>
                                <div className='save-spacing'/>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={() => {
                                        this.save()
                                    }}

                                    disabled={this.isHandleSave()}
                                >
                                    {SAVE}
                                </Button>
                            </div>


                        </>
                    }

                </React.Fragment>
            </>
        )
    }
}


export default SalesPipelineForm;

