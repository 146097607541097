import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Checkbox, FormControlLabel, Button, Fab } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styles from './AddressValidationPopup.module.css';
import {
    TEST_CORRECT_ADDRESS,
    TEST_WRONG_ADDRESS
} from "./constants";
import RatingDialog from "./rating/RatingDialog";

class AddressValidationPopup extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isChecked: false,
        }
    }

    toggleCheck = () => {
        this.setState({
            isChecked: !this.state.isChecked
        })
    };

    togglePopup = () => {
        this.props.toggle && this.props.toggle()
    }

    render() {
        return (
            this.props.show ?
            <div className={styles.addressPopupMain}>
                <div className={styles.fullPopupPrompt}>
                    <Fab className={styles.exitBtn} aria-label="close" color="secondary" onClick={this.togglePopup}><CloseIcon>Close</CloseIcon></Fab>
                    <h2 className={styles.popupTitle}>Confirm Shipping Address</h2>
                    <p className={styles.errorMessage}>We found an issue with some fields submitted. <br/> Please ensure that they are correct</p>
                    <div className={styles.addressPopup}>
                        <div className={styles.addressToCheck}>
                            <h4>Your submitted address</h4>
                            <div>
                                <p>{TEST_WRONG_ADDRESS.country} <br/>
                                    {TEST_WRONG_ADDRESS.province} <br/>
                                    {TEST_WRONG_ADDRESS.city} <br/>
                                    {TEST_WRONG_ADDRESS.postalCode} <br/>
                                    {TEST_WRONG_ADDRESS.addressLine1} </p>
                            </div>
                            <form className={styles.addressPopupBody} noValidate autoComplete="off">
                                <FormControlLabel disabled control={<Checkbox checked={TEST_WRONG_ADDRESS.residential === "true"} />} label="Residential"/>
                            </form>
                        </div>
                        <div className={styles.validatedAddress}>
                            <h4>The validated address</h4>
                            <form className={styles.addressPopupBody} noValidate autoComplete="off">
                                <p className={this.props.isCorrected[0] ? styles.red : styles.black}>{this.props.address.country}</p>
                                <p className={this.props.isCorrected[2] ? styles.red : styles.black}>{this.props.address.province}</p>
                                <p className={this.props.isCorrected[3] ? styles.red : styles.black}>{this.props.address.city}</p>
                                <p className={this.props.isCorrected[4] ? styles.red : styles.black}>{this.props.address.postalCode}</p>
                                <p className={this.props.isCorrected[5] ? styles.red : styles.black}>{this.props.address.addressLine1}</p>
                            </form>
                            <form className={styles.addressPopupBodyCheck} noValidate autoComplete="off">
                                <FormControlLabel control={<Checkbox checked={this.state.isChecked} onChange={this.toggleCheck.bind(this)} />} label="Residential"/>
                            </form>
                        </div>
                    </div>
                    <div className={styles.options}>
                        <div className={styles.editBtn} >
                            <Button variant="contained" className={styles.editBtnBtn}>Edit</Button>
                        </div>
                        <div className={styles.simpleOptions}>
                            <Button variant="contained" className={styles.keepBtn}>Keep original settings</Button>
                            <Button variant="contained" color="primary" className={styles.changeBtn}>Change to validated settings</Button>
                        </div>
                    </div>
                </div>
            </div>  : null
        );
    }
}

AddressValidationPopup.propTypes = {

}

AddressValidationPopup.defaultProps = {

}

export default AddressValidationPopup;