import React from "react";

// material components
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import {Typography, Button, IconButton, Tooltip, Select, MenuItem} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";
import { STATUS } from "../purchaseOrders/constants";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import TableContainer from "@material-ui/core/TableContainer";
import {
    DATE,
    NUM_BOXES,
    NUM_SKIDS,
    TYPE,
    SHORTCODE,
    UNITS_ON_HAND,
    FTL,
    RECEIVED,
    OUT_OF_STOCK,
    INSUFFICIENT_STOCK,
    LOW_INVENTORY,
    THRESHOLDS,
    DAYS_OF_COVERAGE,
    NO_RUNNING_RATE_MESSAGE,
    NO_THRESHOLD_MESSAGE,
    DAY_FILTER_GAP_LIMIT_ERROR_PARTA,
    DAY_FILTER_GAP_LIMIT_ERROR_PARTB,
    EXPECTED_END_OF_DAY,
    TO_DATE_BEFORE_FROM_DATE_ERROR
} from "./constants";
import TableHead from "@material-ui/core/TableHead";

// custom components


// styling
import './InventoryOverviewTable.css'
import PropTypes from "prop-types";
import InventoryFiltersDialog from "./InventoryFiltersDialog";
import { FETCH_UNSHIPPED_ALERTS_TIME } from "../alerts/constants";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ErrorIcon from "@material-ui/icons/Error";
import WarningIcon from "@material-ui/icons/Warning";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import DescriptionIcon from "@material-ui/icons/Description";
import ErrorFeedbackBar from "../global/ErrorFeedbackBar";
import FeedbackBar from "../feedbackBar/FeedbackBar";
import {CARRIER_MISSING_ERROR, ERROR} from "../shipments/constants";

class InventoryOverviewTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            generateMap: false,
            inventoryEntriesLoaded: false,
            shortcodeIndicesLoaded: false,
            localShortcodeIndices: {},
            valueMap: [[]],
            receivedDisabledIndex: [],
            expectedEODMap: {},
            numberOfDaysCovered: 14,
        };
    }

    componentDidMount() {
        this.props.listInventoryAlerts();
        this.updateShortcodeIndices();
    }
    getNumBoxes = (entry) => {
        if (!entry.details || !entry.details.boxQuantity)
            return '-'
        let filtersPerBox = parseInt(entry.details.boxQuantity)
        return Math.floor(parseInt(entry.quantity) * parseInt(entry.details.eaBoxContains) / filtersPerBox)
    }

    getNumSkids = (entry) => {
        if (!entry.details)
            return '-'

        let filtersPerSkid = parseFloat(entry.details.paleteQuantity)
        return (entry.details?.eaBoxContains !== undefined ?
            (parseFloat(entry.quantity) * parseFloat(entry.details?.eaBoxContains) / filtersPerSkid).toFixed(2) :
            (parseFloat(entry.quantity) / filtersPerSkid).toFixed(2))
    }

    getThresholds = (entry) => {
        return !entry.threshold || entry.threshold === "0" ? NO_THRESHOLD_MESSAGE : entry.threshold
    }


    getDaysOfCoverage = (entry, numberOfDaysCovered) => {
        let rate = ""

        switch (numberOfDaysCovered) {
            case 7:
                rate = Math.floor(parseFloat(entry.quantity) / parseFloat(entry.runningRateFor7Days))
                break
            case 14:
                rate = Math.floor(parseFloat(entry.quantity) / parseFloat(entry.runningRateFor14Days))
                break
            case 21:
                rate = Math.floor(parseFloat(entry.quantity) / parseFloat(entry.runningRateFor21Days))
                break
            case 28:
                rate = Math.floor(parseFloat(entry.quantity) / parseFloat(entry.runningRateFor28Days))
                break
            default:
                break
        }

        return rate.toString() !== "NaN" ? rate : NO_RUNNING_RATE_MESSAGE
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (Object.keys(this.props.shortcodeIndices).length !== Object.keys(prevProps.shortcodeIndices).length || this.props.filteredInventoryEntries !== prevProps.filteredInventoryEntries) {
            this.updateShortcodeIndices();
        }
        //process data to fill in this.state.expectedEOD
        if((prevProps.alerts !== this.props.alerts)||(this.props.selectedLocationId !== prevProps.selectedLocationId)){
            let map = {}
            for (let i = 0; i < this.props.alerts.length; i++) {
                //if this the alert for the current location
                if (this.props.selectedLocationId == this.props.alerts[i]?.address[0]?.shipAddressId) {
                    //regex to capture  the value
                    let match = this.props.alerts[i]?.alertMessage?.match(/Expected EOD:(\d+)/);
                    let number
                    if (match) {
                        number = parseInt(match[1], 10);
                        map[this.props.alerts[i].identifier.shortcode] = number
                    }
                }
            }
            this.setState({
                expectedEODMap : map
            })
        }
    }

    updateShortcodeIndices = () => {
        // need to update the value map
        let valueMap = [[]]
        this.props.filteredInventoryEntries.forEach((entry, i) => {
            entry.inventoryItems.forEach((item, j) => {
                let shortcode = item.shortcode;

                if (shortcode in this.props.shortcodeIndices) {
                    valueMap[i][this.props.shortcodeIndices[shortcode]] = item.quantity;
                }
            });
            // add a new row
            if (i !== this.props.filteredInventoryEntries.length - 1)
                valueMap.push([]);

        })
        this.setState({
            valueMap: valueMap,
            inventoryEntriesLoaded: false,
            shortcodeIndicesLoaded: false
        })
    }
    getDate = (year, day) => {
        // let date = new Date(year, 0);
        // return new Date(date.setDate(day));
        return new Date(year, 0, day).toDateString()
    }

    handleReceive = (transferId, index) => {
        this.setState({
            receivedDisabledIndex: [...this.state.receivedDisabledIndex, index]
        })

        const today = new Date();
        const lastWeekDate = new Date(today);
        lastWeekDate.setHours(0);
        lastWeekDate.setMinutes(0);
        lastWeekDate.setSeconds(0);
        lastWeekDate.setDate(lastWeekDate.getDate() - 7)
        const fetchAlertsData = {
            selectedShipAddressId: this.props.locationIds,
            fetchUnshipped: false,
        }
        let currHour = new Date().getHours();
        if (currHour >= FETCH_UNSHIPPED_ALERTS_TIME) {
            fetchAlertsData.fetchUnshipped = true;
        } else {
            fetchAlertsData.fetchUnshipped = false;
        }
        const locationData = {
            locationId: this.props.selectedLocationId
        }
        this.props.handleReceive(transferId, lastWeekDate, fetchAlertsData, this.props.showProducts, locationData)
    }

    inventorySort = (entries) => {
        entries.sort((a, b) => {
            const valueA = a.shortcode.toLowerCase()
            const valueB = b.shortcode.toLowerCase()
            if (valueA > valueB) {
                return 1
            } else if (valueA < valueB) {
                return -1
            } else {
                return 0
            }
        })
        return entries
    }

    getAlertTooltip = (type, content, threshold = "") => {
        let title = ""
        let rows = []
        let splitContent

        switch (type) {
            case INSUFFICIENT_STOCK:
                splitContent = content.split(",")
                title = "Insufficient Stock"
                rows = [
                    ["Stock:", splitContent.find(entry => entry.includes("Stock:")).split("Stock:")[1]],
                    ["Unshipped:", splitContent.find(entry => entry.includes("Unshipped:")).split("Unshipped:")[1]],
                    ["Expected End of Day:", splitContent.find(entry => entry.includes("Expected EOD:"))?.split("Expected EOD:")[1]],
                    ["Expected End of Seven Days:", splitContent.find(entry => entry.includes("Expected EO7D:"))?.split("Expected EO7D:")[1]],
                ]
                break
            case LOW_INVENTORY:
                title = "Low Inventory"
                splitContent = content.split(",")
                rows = [
                    ["Stock:", splitContent.find(entry => entry.includes("Stock:")).split("Stock:")[1]],
                    ["Threshold:", threshold],
                    ["Expected End of Day:", splitContent.find(entry => entry.includes("Expected EOD:"))?.split("Expected EOD:")[1]]   ,
                    ["Expected End of Seven Days:", splitContent.find(entry => entry.includes("Expected EO7D:"))?.split("Expected EO7D:")[1]],

                ]
                break
            case OUT_OF_STOCK:
                splitContent = content.split(",")
                title = "Out Of Stock"
                rows = [
                    ["Stock:", , splitContent.find(entry => entry.includes("Stock:")).split("Stock:")[1]],
                    ["Unshipped:", splitContent.find(entry => entry.includes("Unshipped:")).split("Unshipped:")[1]],
                    ["Expected End of Day:", splitContent.find(entry => entry.includes("Expected EOD:"))?.split("Expected EOD:")[1]],
                    ["Expected End of Seven Days:", splitContent.find(entry => entry.includes("Expected EO7D:"))?.split("Expected EO7D:")[1]],
                ]
                break
        }

        return <React.Fragment>
            <div style={{width: "100%", textAlign: "center"}}>
                {title}
            </div>
            <table style={{width: "100%"}}>
                {rows.map(row =>
                    <tr>
                        {row.map((cell, index) =>
                            <React.Fragment>
                                <td style={{textAlign: (index === 0 ? 'left' : 'right'), paddingRight: (index === 0 ? '5px' : '0px')}}>
                                    {cell}
                                </td>
                            </React.Fragment>
                        )}
                    </tr>
                )}
            </table>
        </React.Fragment>
    }

    hasAlert = (shortcode, type) => {
        const alert = this.props.alerts.find(a => a.alertType === type &&
            (a.identifier?.shortcode === shortcode || a.byBrandName?.some(b => b.shortcode === shortcode)) &&
            (!a.address || a.address.some(ad => ad.shipAddressId === this.props.selectedLocationId))
        );
        if (alert) {
            if (type === INSUFFICIENT_STOCK || type === LOW_INVENTORY) {
                return this.getAlertTooltip(type, alert.alertMessage, alert.threshold);
            }
            else if (type === OUT_OF_STOCK) {
                return this.getAlertTooltip(OUT_OF_STOCK, alert.alertMessage);
            }
        }

        return false;
    }

    render() {
        return (
            <div className={"sticky-table-container" + (this.props.dialog ? "-dialog" : "")}>
                <ErrorFeedbackBar/>
                <FeedbackBar
                    open={this.props.maxAllowedSearchRangeError||this.props.toDateBeforeFromDateError}
                    handleClose={() => {
                        if (this.props.maxAllowedSearchRangeError)
                            this.props.setMaxAllowedSearchRangeError(false)
                        if (this.props.toDateBeforeFromDateError)
                            this.props.setToDateBeforeFromDateError(false)
                    }}
                    severity={ERROR}
                    message={
                        (this.props.maxAllowedSearchRangeError?
                            DAY_FILTER_GAP_LIMIT_ERROR_PARTA+" "+this.props.maxAllowedSearchRange+" "+DAY_FILTER_GAP_LIMIT_ERROR_PARTB:"") +
                        (this.props.toDateBeforeFromDateError ? TO_DATE_BEFORE_FROM_DATE_ERROR : "")
                    }
                />
                <Table className="sticky-table">
                    <TableBody>
                        <TableRow>
                            {/*Shortcode heading row*/}
                            <TableCell className= {(this.props.dialog ? "dialog-cell" : "shortcode-label-cell")}>
                                {SHORTCODE}
                            </TableCell>
                            {this.props.filteredInventoryOverview.map((entry, index) => {
                                return (
                                    <TableCell className={(this.props.dialog ? "dialog-cell" : "shortcode-head-cell")}>
                                        {this.hasAlert(entry.shortcode, OUT_OF_STOCK) ?
                                            <Tooltip title={this.hasAlert(entry.shortcode, OUT_OF_STOCK)}><ErrorIcon style={{color: "red", paddingRight: "5px"}}/></Tooltip> : this.hasAlert(entry.shortcode, INSUFFICIENT_STOCK) ?
                                            <Tooltip title={this.hasAlert(entry.shortcode, INSUFFICIENT_STOCK)}><WarningIcon style={{color: "#e39a07", paddingRight: "5px"}}/></Tooltip> : this.hasAlert(entry.shortcode, LOW_INVENTORY) ?
                                            <Tooltip title={this.hasAlert(entry.shortcode, LOW_INVENTORY)}><ArrowDownwardIcon style={{color: "#e39a07", paddingRight: "5px"}}/></Tooltip> : null
                                        }
                                        {entry.shortcode}
                                    </TableCell>
                                )

                            })}
                            {!this.props.dialog?
                                <TableCell className="empty-head-col" />
                                :
                                null
                            }
                        </TableRow>

                        {!this.props.showThresholds? null :
                            <TableRow>
                                {/*Optional Threshold row*/}
                                <TableCell className='heading-cell'>
                                    {THRESHOLDS}
                                </TableCell>
                                {this.props.filteredInventoryOverview.map((entry, index) => {
                                    return (
                                        <TableCell className='text-cell'>
                                            {this.getThresholds(entry)}
                                        </TableCell>
                                    )
                                })}
                                <TableCell className="empty-cell" />
                            </TableRow>
                        }

                        <TableRow>
                            {/*Units on Hand row*/}
                            <TableCell className={(this.props.dialog ? "dialog-cell" : "heading-cell")}>
                                {UNITS_ON_HAND}
                            </TableCell>
                            {this.props.filteredInventoryOverview.map((entry, index) => {
                                return (
                                    <TableCell className={(this.props.dialog ? "dialog-cell" : "text-cell")}>
                                        {parseInt(entry.quantity)}
                                    </TableCell>
                                )

                            })}
                            {!this.props.dialog?
                                <TableCell className="empty-cell" />
                                :
                                null
                            }
                        </TableRow>

                        <TableRow>
                            {/*Number of Boxes row*/}
                            <TableCell className={(this.props.dialog ? "dialog-cell" : "heading-cell")}>
                                {NUM_BOXES}
                            </TableCell>
                            {this.props.filteredInventoryOverview.map((entry, index) => {
                                return (
                                    <TableCell className={(this.props.dialog ? "dialog-cell" : 'text-cell')}>
                                        {this.getNumBoxes(entry)}
                                    </TableCell>
                                )
                            })}
                            {!this.props.dialog?
                                <TableCell className="empty-cell" />
                                :
                                null
                            }

                        </TableRow>

                        <TableRow>
                            {/*Number of Skids row*/}
                            <TableCell className={(this.props.dialog ? "dialog-cell" : "heading-cell")}>
                                {NUM_SKIDS}
                            </TableCell>
                            {this.props.filteredInventoryOverview.map((entry, index) => {
                                return (
                                    <TableCell className={(this.props.dialog ? "dialog-cell" : "text-cell")}>
                                        {this.getNumSkids(entry)}
                                    </TableCell>
                                )
                            })}
                            {!this.props.dialog?
                                <TableCell className="empty-cell" />
                                :
                                null
                            }
                        </TableRow>


                        {this.props.onlyLowInventory &&
                        <TableRow>
                            {/*Expected End of Day row*/}
                            <TableCell className={(this.props.dialog ? "dialog-cell" : "heading-cell")}>
                                {EXPECTED_END_OF_DAY}
                            </TableCell>
                            {this.props.filteredInventoryOverview.map((entry, index) => {
                                return (
                                    <TableCell className={(this.props.dialog ? "dialog-cell" : "text-cell")}>
                                        {this.state.expectedEODMap[entry.shortcode]}
                                    </TableCell>
                                )
                            })}
                            {!this.props.dialog?
                                <TableCell className="empty-cell" />
                                :
                                null
                            }
                        </TableRow>
                        }

                        <TableRow>
                            {/* Days of Coverage row*/}
                            <TableCell className={(this.props.dialog ? "dialog-cell" : "last-heading-cell bottom-row-cell")}>
                                <Select
                                    style={{marginRight: "10px", width: "50px"}}
                                    value={this.props.numberOfDaysCovered}
                                    onChange={this.props.handleDaysOfCoverageChange}
                                >
                                    <MenuItem value={7}>7</MenuItem>
                                    <MenuItem value={14}>14</MenuItem>
                                    <MenuItem value={21}>21</MenuItem>
                                    <MenuItem value={28}>28</MenuItem>
                                </Select>
                                {DAYS_OF_COVERAGE}
                            </TableCell>
                            {this.props.filteredInventoryOverview.map((entry, index) => {
                                return (
                                    <TableCell className={(this.props.dialog ? "dialog-cell" : "text-cell bottom-row-cell")}>
                                        {this.getDaysOfCoverage(entry, this.props.numberOfDaysCovered)}
                                    </TableCell>
                                )
                            })}
                            {!this.props.dialog?
                                <TableCell className="empty-cell" />
                                :
                                null
                            }
                        </TableRow>

                        {this.props.filteredInventoryEntries?.length > 0 ?
                            <>
                                {/*This is for the first row */}
                                <TableRow>
                                    <TableCell className='detail-heading-cell'>
                                        <TableCell className="sub-head-date-col">
                                            {DATE}
                                        </TableCell>
                                        <TableCell className="sub-head-type-col">
                                            {TYPE}
                                        </TableCell>
                                    </TableCell>
                                    {/*iterate through all shortcodes and show blank cells*/}
                                    {
                                        Object.keys(this.props.filteredInventoryOverview).map((key) => {
                                            return (
                                                <TableCell className="shortcode-empty-col" />
                                            )
                                        })}
                                    <TableCell className="empty-head-details-col" />
                                </TableRow>


                                {this.props.filteredInventoryEntries.map((entry, i) => {
                                    if (entry._id.transfer == "to" && this.props.selectedLocationId == entry._id.toShipAddressId) {
                                        return (
                                            <TableRow>
                                                <TableCell className='detail-heading-cell'>
                                                    {/*date*/}
                                                    <TableCell className="details-table-cell-date-borders">
                                                        {entry._id.receiveYear && entry._id.receiveDayOfYear ? entry._id.receiveDate.toISOString().split('T')[0] :
                                                            <Button
                                                                variant="contained"
                                                                style={{ width: 80 }}
                                                                color="primary"
                                                                disabled={this.state.receivedDisabledIndex.includes(i)}
                                                                onClick={() => this.handleReceive(entry._id.transferId, i)}
                                                            >
                                                                {RECEIVED}
                                                            </Button>}
                                                    </TableCell>
                                                    {/*type*/}
                                                    <TableCell className={entry._id.receiveYear && entry._id.receiveDayOfYear ? "details-table-cell-type-borders" : "details-table-cell-type-borders-gray"}>
                                                        {/*FTL ? */}
                                                        {entry._id.type === "FTL" ? "FTL " + ((this.props.selectedLocationId === entry._id.fromShipAddressId ? " To " + entry._id.toLocation : " From " + entry._id.fromLocation)) : entry._id.type}
                                                    </TableCell>
                                                </TableCell>



                                                {/*for each row, we need to properly place the quantity in the correct quanaity column*/}

                                                {this.props.filteredInventoryOverview.map((overview, index) => {
                                                    return (
                                                        <TableCell className={entry._id.receiveYear && entry._id.receiveDayOfYear ? "details-table-cell-borders" : "details-table-cell-borders-gray"}>
                                                            {this.state.valueMap.length >= i + 1 && this.props.shortcodeIndices ? this.state.valueMap[i][this.props.shortcodeIndices[overview.shortcode]] : '-'}

                                                        </TableCell>
                                                    );
                                                })}
                                                <TableCell className="empty-details-cell" />

                                            </TableRow>
                                        )
                                    } else if (entry._id.type != FTL || (!entry._id.transfer && this.props.selectedLocationId == entry._id.fromShipAddressId)) {
                                        return (
                                            <TableRow>
                                                <TableCell className='detail-heading-cell'>
                                                    {/*date*/}
                                                    <TableCell className="details-table-cell-date-borders">
                                                        {entry._id.year && entry._id.dayOfYear ? entry._id.date.toISOString().split('T')[0] : '-'}
                                                    </TableCell>
                                                    {/*type*/}
                                                    <TableCell className="details-table-cell-type-borders">
                                                        {/*FTL ? */}
                                                        {entry._id.type === "FTL" ? "FTL " + ((this.props.selectedLocationId === entry._id.fromShipAddressId ? " To " + entry._id.toLocation : " From " + entry._id.fromLocation)) : entry._id.type}
                                                    </TableCell>
                                                </TableCell>



                                                {/*for each row, we need to properly place the quantity in the correct quanaity column*/}

                                                {this.inventorySort(this.props.filteredInventoryOverview).map((entry1, index) => {
                                                    let itemIndex = 0
                                                    for (let i = 0; i < entry.inventoryItems.length; i++) {
                                                        if (entry.inventoryItems[i].shortcode === entry1.shortcode) {
                                                            itemIndex = i
                                                            break
                                                        }
                                                    }
                                                    return (
                                                        <TableCell className="details-table-cell-borders">
                                                            {this.state.valueMap.length >= i + 1 && this.props.shortcodeIndices && (entry._id.type === "Received" || entry._id.type === "Shipped" || entry._id.type === "PO Shipped")  && this.state.valueMap[i][this.props.shortcodeIndices[entry1.shortcode]] && entry.inventoryItems[itemIndex].poNumber && entry.inventoryItems[itemIndex].poNumber.length > 0 ?
                                                                <div style={{position: "relative"}}>
                                                                    <Tooltip
                                                                        style={{position: "absolute", maxWidth: "1px", maxHeight: "1px", left: "90%", bottom: "25%"}}
                                                                        enterTouchDelay={0}
                                                                        leaveTouchDelay={30000}
                                                                        enterDelay={400}
                                                                        // leaveDelay={1000000} // for testing purposes
                                                                        title={
                                                                            <React.Fragment>
                                                                                {entry.inventoryItems[itemIndex].poNumber ? entry.inventoryItems[itemIndex].poNumber.toString() : "No PO Number Available"}
                                                                            </React.Fragment>
                                                                        }
                                                                    >
                                                                        <IconButton aria-label='info'>
                                                                            <InfoOutlinedIcon variant='outlined' fontSize='small' />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <div style={{position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)"}}>
                                                                        {this.state.valueMap[i][this.props.shortcodeIndices[entry1.shortcode]]}
                                                                    </div>
                                                                </div>
                                                                :
                                                                this.state.valueMap.length >= i + 1 && this.props.shortcodeIndices && entry._id.notes && this.state.valueMap[i][this.props.shortcodeIndices[entry1.shortcode]] ?
                                                                    <div style={{position: "relative"}}>
                                                                        <Tooltip
                                                                            style={{position: "absolute", maxWidth: "1px", maxHeight: "1px", left: "90%", bottom: "25%"}}
                                                                            enterTouchDelay={0}
                                                                            leaveTouchDelay={30000}
                                                                            enterDelay={400}
                                                                            // leaveDelay={1000000} // for testing purposes
                                                                            title={
                                                                                <React.Fragment>
                                                                                    {entry._id.notes}
                                                                                </React.Fragment>
                                                                            }
                                                                        >
                                                                            <IconButton aria-label='info'>
                                                                                <DescriptionIcon variant='outlined' fontSize='small' />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        <div style={{position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)"}}>
                                                                            {this.state.valueMap[i][this.props.shortcodeIndices[entry1.shortcode]]}
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                this.state.valueMap.length >= i + 1 && this.props.shortcodeIndices ?
                                                                this.state.valueMap[i][this.props.shortcodeIndices[entry1.shortcode]] : '-'}

                                                        </TableCell>
                                                    );
                                                })}
                                                <TableCell className="empty-details-cell" />

                                            </TableRow>
                                        )
                                    }
                                })}
                            </>
                            : null}
                    </TableBody>
                </Table>
            </div>
        )
    }
}

InventoryOverviewTable.propTypes = {
    filteredInventoryOverview: PropTypes.array,
    filteredInventoryEntries: PropTypes.array,
    style: PropTypes.object,
    dialog: PropTypes.bool,
}

InventoryOverviewTable.defaultProps = {
    filteredInventoryOverview: [],
    filteredInventoryEntries: [],
    style: {
        overflow: 'scroll',
    },
    dialog: false,
}


export default InventoryOverviewTable