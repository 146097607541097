export const ASC = 'asc';
export const DESC = 'desc';
export const ORDER_REGEX = [
    /[0-9]{3}-[0-9]{7}-[0-9]{7}$/,
    /^\d{15}$/,
    /^\d{13}$/ //Walmart Canada
];
export const TRACKING_ID_REGEX = [
    /^\d{12,14}$/,          // Fedex
    /D\d{21}$/,             // Canpar
    /FB[DX]-\d+$/,          // Flashbox
    /P\d{10}$/,             // GLS
    /^1Z[a-zA-Z\d]{16,18}$/,// UPS
    /^\d{16}$/,             //Canada Post
    /E\d{10}$/,             // GLS US
    /^90\d{11}$/,           // Amazon Shipping sandox
    /^TB[A-Za-z]\d{12}$/,   // Amazon Shipping
    /^\d{22}$/              // USPS
]
export const APPLY = "Apply";
export const CANCEL = "Cancel";

export const UPLOAD_FILE = "Upload File";
export const UPLOAD_LOGO = "Upload Logo";
export const DELETE_LOGO = "Delete Logo";
export const SAVE = "Save";
export const NEXT = "Next";
export const DELETE = "Delete"
export const NAVIGATE_LEFT = "Previous";
export const NAVIGATE_RIGHT = "Next";

export const ROLE_ADMIN = "ROLE_ADMIN";

export const FILE_CHIP_MAX_TEXT_LENGTH = 25;

export const START_INDEX = 0;

export const getByteArray = (file) => {
    let reader = new FileReader();
    let fileByteArray = [];
    reader.readAsArrayBuffer(file);
    reader.onloadend = function (evt) {
        if (evt.target.readyState === FileReader.DONE) {
            let arrayBuffer = evt.target.result,
                uint8array = new Uint8Array(arrayBuffer);
            for (let i = 0; i < uint8array.length; i++) {
                fileByteArray.push(uint8array[i]);
            }
        }
    }

    return fileByteArray
}


export const DAYS_OF_WEEK = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

export const DEFAULT_PDF_ZOOM = 1.5


