import React from 'react';

import generatePassword from 'password-generator';
import copy from 'copy-to-clipboard';

// components
import FeedbackBar from "../../feedbackBar/FeedbackBar";

// styling
import './ManageExpandEdit.css';

// material components
import {
    Button,
    Collapse,
    TableCell,
    TableRow,
    TextField,
    FormLabel,
    FormGroup,
    Checkbox,
    FormHelperText,
    Radio,
    Divider
} from "@material-ui/core";

// constants
import {
    SELECT_SERVICES,
    SELECT_LOCATIONS,
    PASSWORD_ACTION,
    PASSWORD_SUCCESS_MESSAGE,
    SAVE_ACTION,
    SAVE_SUCCESS_MESSAGE,
    CANCEL,
    SAVING,
    ERROR,
    SUCCESS,
    ROLE_DEPTH_AND_ORDER,
    ROLE_DASHBOARD,
    ROLE_ADMIN,
    PARENT_ROLE_MAP,
    CHILD_ROLE_MAP,
    TIME_ZONE,
    DEFAULT, ERROR_MESSAGE, ROLE_PO_PRICING, ROLE_FTL, ROLE_FTL_APPROVAL,
    ROLE_CLAIMS
} from "./constants";
import DeleteDialog from "../ManageCarriers/DeleteDialog";
import PropTypes from "prop-types";
import { Autocomplete } from '@material-ui/lab';
import { FETCH_UNSHIPPED_ALERTS_TIME } from "../../alerts/constants";

class ManageExpandEdit extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            displayName: '',
            username: '',
            firstname: '',
            lastname: '',
            authorities: [],
            password: '',
            copied: false,
            timezone: '',
            locationIds: [],
            defaultLocationId: ''
        }
    }

    componentDidMount() {
        const authorities = this.props.user.authorities.map(authority => {
            return authority.authority
        })
        let newAuthorities = new Set(authorities)
        if (!newAuthorities.has(ROLE_ADMIN)) {
            newAuthorities.add(ROLE_DASHBOARD)
        }
        this.setState({
            displayName: this.props.user.displayName,
            username: this.props.user.username,
            authorities: Array.from(newAuthorities),
            firstname: this.props.user.firstname,
            lastname: this.props.user.lastname,
            timezone: this.props.user.timezone,
            locationIds: this.props.user.locationIds ? this.props.user.locationIds : [],
            defaultLocationId: this.props.user.defaultLocationId
        })
    }

    handleEdit = (value, name) => {
        this.setState({
            [name]: value
        })
    }

    selectLocation = (value) => {
        let newLocationIds = [...this.state.locationIds]
        const index = newLocationIds.findIndex(newLocationId => {
            return newLocationId === value.shipAddressId
        })
        if (index > -1) {
            newLocationIds.splice(index, 1);
            if (this.state.defaultLocationId === value.shipAddressId) {
                this.setState({
                    defaultLocationId: ''
                })
            }
        } else {
            newLocationIds.push(value.shipAddressId)
        }

        if (this.state.locationIds.length === 0) {
            this.setDefaultLocation(value);
        }
        let isLocationDefault = this.getDefaultLocationChecked(value);
        if (!newLocationIds.includes(value.shipAddressId) && isLocationDefault && this.state.locationIds.length === 2) {
            let loc = this.props.locations.find((loc) => loc.shipAddressId === newLocationIds[0]);
            this.setDefaultLocation(loc);
        }

        this.setState({
            locationIds: newLocationIds
        })
    }

    setDefaultLocation = (value) => {
        let newLocationIds = [...this.state.locationIds]
        const index = newLocationIds.findIndex(newLocationId => { return newLocationId === value.shipAddressId })
        if (index > -1) {
            this.setState({
                defaultLocationId: value.shipAddressId
            })
        } else {
            newLocationIds.push(value.shipAddressId)
            this.setState({
                locationIds: newLocationIds,
                defaultLocationId: value.shipAddressId
            })
        }
    }

    createPassword = () => {
        const newPassword = generatePassword(16, false, /[\d\W\w\p]/g).toString()
        this.setState({
            password: newPassword,
            copied: true
        })
        copy(newPassword)
    }

    selectAuthority = (role) => {
        if (role === ROLE_ADMIN) {
            if (this.state.authorities.includes(role)) {
                this.setState({
                    authorities: [ROLE_DASHBOARD]
                })
            } else {
                this.setState({
                    authorities: [ROLE_ADMIN]
                })
            }
            return
        }

        let newAuthority = new Set(this.state.authorities)
        const updateChild = (isDelete, role) => {
            if (isDelete) {
                if (PARENT_ROLE_MAP[role]) {
                    PARENT_ROLE_MAP[role].forEach(child => {
                        newAuthority.delete(child)
                    })
                }
                if (CHILD_ROLE_MAP[role]) {
                    CHILD_ROLE_MAP[role].forEach(parent => {
                        let hasChild = false
                        PARENT_ROLE_MAP[parent].forEach(child => {
                            if (newAuthority.has(child)) {
                                hasChild = true
                            }
                        })
                        if (!hasChild && parent!= ROLE_DASHBOARD) {
                            newAuthority.delete(parent)
                        }
                    })
                }
            } else {
                if (PARENT_ROLE_MAP[role]) {
                    PARENT_ROLE_MAP[role].forEach(child => {
                        newAuthority.add(child)
                    })
                }
                if (CHILD_ROLE_MAP[role]) {
                    CHILD_ROLE_MAP[role].forEach(parent => {
                        newAuthority.add(parent)
                    })
                }
            }
        }
        if (!this.state.authorities.includes(role)) {
            this.props.allRoles.forEach(roleInAllRole => {
                if (role === roleInAllRole) {
                    newAuthority.add(roleInAllRole)
                    updateChild(false, roleInAllRole)
                }
                if (this.state.authorities.includes(roleInAllRole)) {
                    newAuthority.add(roleInAllRole)
                }
            });
        } else {
            newAuthority.delete(role)
            updateChild(true, role)
        }
        this.setState({
            authorities: Array.from(newAuthority)
        })
    }

    saveChanges = () => {
        const isNewPassword = this.state.password.length > 0
        const data = {
            admin: this.props.admin.username,
            authorities: this.state.authorities,
            change_password: isNewPassword,
            password: this.state.password?.length > 0 ? this.state.password : null,
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            new_displayname: this.state.displayName,
            user_to_change: this.props.user.username,
            timezone: this.state.timezone,
            locationIds: this.state.locationIds,
            defaultLocationId: this.state.defaultLocationId
        }

        let selectedLocationId = this.props.admin.selectedLocationId
        if (!this.state.locationIds.includes(selectedLocationId)) {
            selectedLocationId = this.state.defaultLocationId;
        }

        const userInfoToPost = {
            ...this.props.admin,
            defaultLocationId: this.state.defaultLocationId,
            locationIds: this.state.locationIds,
            selectedLocationId: selectedLocationId
        }
        this.props.editUser(data, userInfoToPost, this.props.handleOpen)
        this.props.handleClose()

        const fetchAlertsData = {
            selectedShipAddressId: this.state.locationIds,
            fetchUnshipped: false,
        }
        let currHour = new Date().getHours();
        if (currHour >= FETCH_UNSHIPPED_ALERTS_TIME) {
            fetchAlertsData.fetchUnshipped = true;
        } else {
            fetchAlertsData.fetchUnshipped = false;
        }
        this.props.fetchAlerts(fetchAlertsData);
    }

    handlePasswordMessageClose = () => {
        this.setState({ copied: false })
    }

    getChecked = (role) => {
        return this.state.authorities.includes(role)
    }

    getDefaultLocationChecked = (location) => {
        return location.shipAddressId === this.state.defaultLocationId
    }

    getLabel = (label) => {
        if (label === ROLE_PO_PRICING) {
            return "PO Pricing"
        }
        if (label === ROLE_FTL) {
            return "LTL/FTL Shipment"
        }
        if (label === ROLE_FTL_APPROVAL) {
            return "Approver"
        }
        if (label === ROLE_CLAIMS) {
            return "Claims"
        }
        let words = label.split('_')
        words.splice(0, 1)
        words = words.map(word => {
            return word[0].toUpperCase() + word.slice(1).toLowerCase()
        })
        return words.join(' ')
    }

    getSpace = (role) => {
        let spaces = []
        for (let index = 0; index < ROLE_DEPTH_AND_ORDER[role]; index++) {
            spaces.push("space")
        }
        return spaces.map(space => {
            return <div className="authority-space" />
        })
    }

    render() {
        return (
            <div className="row-register-new-user">
                <FeedbackBar
                    open={this.state.copied}
                    severity={SUCCESS}
                    message={PASSWORD_SUCCESS_MESSAGE}
                    handleClose={this.handlePasswordMessageClose}
                />
                <div className="rowSpec">
                    <TextField
                        disabled
                        className="register-new-user-Input"
                        variant="outlined"
                        label="Email Address"
                        InputLabelProps={{ shrink: true }}
                        name="username"
                        value={this.state.username}
                        onChange={this.handleChange}
                    />
                </div>
                <div className="rowSpec">
                    <TextField
                        className="register-new-user-TwoInput"
                        variant="outlined"
                        label="First Name"
                        InputLabelProps={{ shrink: true }}
                        name="firstname"
                        value={this.state.firstname}
                        onChange={(e) => this.handleEdit(e.target.value, e.target.name)}
                    />
                    <TextField
                        className="register-new-user-TwoInput"
                        variant="outlined"
                        label="Last Name"
                        InputLabelProps={{ shrink: true }}
                        name="lastname"
                        value={this.state.lastname}
                        onChange={(e) => this.handleEdit(e.target.value, e.target.name)}
                    />
                </div>
                <div className="rowSpec">
                    <TextField
                        disabled
                        className="register-new-user-TwoInput"
                        variant="outlined"
                        label="Display Name"
                        InputLabelProps={{ shrink: true }}
                        name="displayName"
                        value={`${this.state.firstname} ${this.state.lastname}`}
                    />
                    <Autocomplete
                        options={TIME_ZONE}
                        getOptionLabel={(entry) => entry}
                        autoHighlight={true}
                        className="register-new-user-TwoInput"
                        value={this.state.timezone}
                        onChange={(event, value) => {
                            this.handleEdit(value, "timezone")
                        }}
                        name="timezone"

                        blurOnSelect={true}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label="Time zone"
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                name="timezone"
                            />
                        }
                    />
                </div>
                <div className="rowSpec">
                    <TextField
                        className="register-new-user-TwoInput"
                        variant="outlined"
                        label="Password"
                        InputLabelProps={{ shrink: true }}
                        name="password"
                        type='password'
                        onChange={(e) => {
                            this.setState({ password: e.target.value })
                        }}
                        value={this.state.password}
                        autoComplete='new-password'
                    />
                    <Button
                        className="register-new-user-TwoInput"
                        value={this.state.password}
                        color='secondary'
                        variant='contained'
                        onClick={() => {
                            this.createPassword()
                        }}
                    >
                        {PASSWORD_ACTION}
                    </Button>
                </div>

                <div className='register-new-user-role-box'>
                    <div className="location-select-section">
                        <FormLabel className="authority-box" component="legend">{SELECT_LOCATIONS}</FormLabel>
                        <div></div>
                        <FormLabel className="authority-box" component="legend">{DEFAULT}</FormLabel>
                    </div>

                    <FormGroup>
                        {this.props.locations.map((location, i) => {
                            return (
                                <div style={{ display: 'flex' }}>
                                    <div className="authority-box">
                                        <Checkbox
                                            checked={this.state.locationIds.includes(location.shipAddressId)}
                                            onChange={() => this.selectLocation(location)}
                                            name="authority"
                                            color='primary'
                                        />
                                        <div>
                                            {location.name}
                                        </div>

                                    </div>
                                    <Radio
                                        className="default-section"
                                        checked={this.getDefaultLocationChecked(location)}
                                        onChange={() => this.setDefaultLocation(location)}
                                        name="authority"
                                        color='primary'
                                    />
                                </div>
                            )
                        })}
                    </FormGroup>
                </div>
                <Divider className='divider' />
                <div className='register-new-user-role-box'>
                    <FormLabel component="legend">{SELECT_SERVICES}</FormLabel>
                    <FormGroup>
                        {this.props.allRoles.map((role, i) => {
                            return (
                                <div className="authority-box">
                                    {this.getSpace(role)}
                                    <Checkbox
                                        disabled={role === ROLE_DASHBOARD || this.state.authorities.includes(ROLE_ADMIN)}
                                        checked={this.getChecked(role)}
                                        onChange={() => this.selectAuthority(role)}
                                        name="authority"
                                        color='primary'
                                    />
                                    <div
                                        className={role === ROLE_DASHBOARD || this.state.authorities.includes(ROLE_ADMIN) ? "authority-text-disabled" : ""}>
                                        {this.getLabel(role)}
                                    </div>
                                </div>
                            )
                        })}
                        <div className="authority-box">
                            {this.getSpace(ROLE_ADMIN)}
                            <Checkbox
                                checked={this.getChecked(ROLE_ADMIN)}
                                onChange={() => this.selectAuthority(ROLE_ADMIN)}
                                name="authority"
                                color='primary'
                            />
                            <div className="authority-text">
                                {this.getLabel(ROLE_ADMIN)}
                            </div>
                        </div>
                    </FormGroup>
                </div>
                <div>
                    <Button variant="outlined" onClick={this.props.handleClose} className="register-new-user_btn">
                        {CANCEL}
                    </Button>
                    <Button
                        className="register-new-user_btn"
                        variant="contained"
                        color="primary"
                        onClick={() => this.saveChanges()}
                    // disabled={!(this.state.password && this.state.password.length > 0)}
                    >
                        {this.props.loading ? SAVING : SAVE_ACTION}
                    </Button>
                </div>
            </div>
        );
    }

}

ManageExpandEdit.propTypes = {
    isEdit: PropTypes.bool,
    user: PropTypes.object,
    admin: PropTypes.func,
    editUser: PropTypes.func,
    status: PropTypes.number,
    statusMessage: PropTypes.string,
    loading: PropTypes.bool,
    open: PropTypes.bool,
    success: PropTypes.bool,
    handleClose: PropTypes.func,
    fetchAlerts: PropTypes.func,
    handleOpen: PropTypes.func,
}

ManageExpandEdit.defaultProps = {
    isEdit: false,
    user: {},
    admin: () => { },
    editUser: () => { },
    status: 404,
    statusMessage: "",
    loading: false,
    open: false,
    success: false,
    handleClose: () => {},
    fetchAlerts: () => {},
    handleOpen: () => {},
}

export default ManageExpandEdit;