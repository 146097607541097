// sagas effects
import {
    takeLatest,
    put,
    call,
} from 'redux-saga/effects';

// constants for actions
import {
    SCAN_PACKED_INPUT,
    SCAN_SCANNED_INPUT,
    GET_DASHBOARD_INFO, MANUAL_PACK, MANUAL_VOID
} from "../../constants/dashboard";

// endpoints
import {
    DASHBOARD
} from "../../../components/global/Server/endpoints";

// secondary actions
import {
    storeDashboardInfo,
    storeScanResponse,
    clearScanResponse,
    clearPackedResponse,
    storePackedResponse,
    storeLocations,
    storeScannedBarcodes,
    updateScannedBarcodes,
    storePackedBarcodes,
    updatePackedBarcodes,
    updatePendingPackedBarcodes,
    storePendingPackedBarcodes,
    storeTotalBarcodes,
    setRefresh,
    refreshDashboard,
} from "../../actions/dashboard";

import axios from 'axios';
import { HTTP_SUCCESS } from "../../constants/global";
import {
    clearStatus,
    hideError,
    requestStatus,
    showErrorMessage,
} from "../../actions/global";
import { LeakRemoveTwoTone } from '@material-ui/icons';
import {ACCESS_DENIED, SERVER_FAILED_ERROR_MESSAGE} from '../../../pages/Dashboard/DashboardFinal/constants';

// asynchronous call to the backend server
function* postRequest(endpoint, data) {
    return yield axios.post(endpoint, data, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
        .then(response => response)
}

const getRequest = (endpoint) => {
    return axios.get(endpoint, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
        .then(response => response);
}

function* getDashboardinfo(action) {
    yield call(clearStatus)
    yield put(hideError())
    yield put(setRefresh(true))
    try {
        const response = yield call(() => postRequest(DASHBOARD.GET_DASHBOARD, {
            shipAddressId: action.shipAddressId,
            requestedDate: action.date,
            company: action.company
        }));
        if (response.status === 200) {
            yield put(storeDashboardInfo(response.data))
            yield put(storeScannedBarcodes(response.data[0].scannedBarcodes))
            yield put(storePackedBarcodes(response.data[0].packedBarcodes))
            yield put(storePendingPackedBarcodes(response.data[0].pendingPacked))
            yield put(storeTotalBarcodes(response.data[0].printedBarcodes.concat(response.data[0].scannedBarcodes)));
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE));
    }
    yield put(setRefresh(false))
}

function* scanScannedInput(action) {
    yield put(hideError())
    yield call(clearScanResponse)
    try {
        const request = {
            companyId: action.scan.companyId,
            scan: action.scan.scan,
            shipAddressId: action.scan.shipAddressId,
            requestedDate: action.scan.requestedDate,
        };

        const response = yield call(() => postRequest(DASHBOARD.SCAN, request));

        if (!response.data.fail) {
            action.scan.clearInput();

            yield put(updateScannedBarcodes(response.data.label[0]))
        }

        yield put(storeScanResponse(response.data))
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE));
        action.scan.clearInput();
    }
}

function* scanPackedInput(action) {
    yield put(hideError())
    yield call(clearPackedResponse);
    try {
        const request = {
            company: action.packed.companyId,
            label: action.packed.rawScan,
            barcode: action.packed.shortCode,
            userId: action.packed.userId,
            shipAddressId: action.packed.selectedLocationId,
            requestedDate: action.packed.selectedDate,
            barcodes: action.packed.barcodes,
            barcodesCount: action.packed.barcodesCount
        };

        const response = yield call(() => postRequest(DASHBOARD.PACK, request));

        if (!response.data.fail) {
            if (response.data.message === "Barcodes Remaining") {
                action.packed.clearBarcodeInput(response.data.remainingCount)
            }
            if (response.data.message !== "Barcode Scan Remaining" && response.data.message !== "Barcodes Remaining") {
                action.packed.clearInput();
            }

            if (response.data.message === "Scan Success") {
                if (response.data.pendingPacked) {
                    yield put(updatePendingPackedBarcodes(response.data.label[0]));
                } else {
                    yield put(updatePackedBarcodes(response.data.label[0]));
                }
            }
        }

        if (response.data.message !== "Barcode Scan Remaining") {
            yield put(storePackedResponse(response.data));
        }
    } catch (e) {
        action.packed.clearInput();
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE));
    }
}

function* manualVoid(action){
    yield put(hideError())
    try{
        const response = yield call(() => postRequest(DASHBOARD.MANUAL_VOID, {trackingNumber: action.trackingNumber, comment: action.comment, reason: action.reason}))
        if(!response.data.success){
            yield put(showErrorMessage(response.data.message));
        } else {
            yield put(refreshDashboard(true))
        }
    }catch(e){
        if (e.toString().includes("403"))
            yield put(showErrorMessage(ACCESS_DENIED));
        else
            yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE));
    }
}

function* manualPack(action){
    yield put(hideError())
    try{
        const response = yield call(() => postRequest(DASHBOARD.MANUAL_PACK, {trackingNumber: action.trackingNumber}))
        if(!response.data.success){
            yield put(showErrorMessage(response.data.message));
        } else {
            yield put(refreshDashboard(true))
        }
    }catch(e){
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE));
    }
}

export default function* dashboardSaga() {
    yield takeLatest(GET_DASHBOARD_INFO, getDashboardinfo);
    yield takeLatest(SCAN_SCANNED_INPUT, scanScannedInput);
    yield takeLatest(SCAN_PACKED_INPUT, scanPackedInput);
    yield takeLatest(MANUAL_PACK, manualPack);
    yield takeLatest(MANUAL_VOID, manualVoid)
}