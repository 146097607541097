export const MARKETPLACE_TOOLBAR_TITLE = "Marketplaces";

export const MARKETPLACE_HEAD_CELLS = [
    { id: 'marketplaceName', numeric: false, disablePadding: false, label: 'Name' },
    { id: 'marketplaceCompany', numeric: false, disablePadding: false, label: 'Marketplace'},
    { id: 'country', numeric: false, disablePadding: false, label: 'Country'},
    { id: 'enabled', numeric: false, disablePadding: false, label: 'Enabled'},
];

export const REGISTERED_STORES_TITLE = "Marketplaces";

export const DELETE_TITLE = "Delete Marketplace?";
export const DELETE_DESCRIPTION = "This action can not be reversed. Continue with deletion?";

export const ADD_MARKETPLACE_TITLE = "Add a marketplace to sell your products";
export const ADD_MARKETPLACE = "Marketplace";

export const CREATE_MARKETPLACE_TITLE = "Add Marketplace";
export const CREATE_MARKETPLACE_DESCRIPTION = "Add a Marketplace to your company account";

export const EDIT_MARKETPLACE_TITLE = "Edit Marketplace";
export const DELETE_MARKETPLACE_TITLE = "Delete Marketplace";

export const CREATE_MARKETPLACE = "Finish";
export const CANCEL = "Cancel";
export const BACK = "Back";
export const NEXT = "Next";
export const DELETE = "Delete";
export const VALIDATE_CREDENTIALS = "Validate Credentials";

export const SELECT_COUNTRY = "Country"
export const ENTER_NAME = "Name"

export const MARKETPLACE_STEPPER_LABELS = [
    "Select Marketplace",
    "Authorize Account",
]

export const AMAZON = "Amazon"
export const EBAY = "eBay"
export const CUSTOM_MARKETPLACE = "Custom Marketplace"
export const AVAILABLE_MARKETPLACES = [
    AMAZON,
    EBAY,
    CUSTOM_MARKETPLACE
]

export const STEP_CASE = {
    SELECT_MARKETPLACE: 0,
    MARKETPLACE_INFORMATION: 1,
}

export const MARKETPLACE_COUNTRY_OPTIONS = [
    "Canada",
    "United States",
];

export const DEFAULT_ROWS_PER_PAGE = 50;
export const ROWS_PER_PAGE_LABEL = "Marketplaces per page"