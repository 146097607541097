import React from "react";
import withShipment from "../../../withShipment";

import {Layout} from "../../global/Table/TablePageHelpers/LayoutObject";
import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import {
    DAMAGE_BACK_BUTTON,
    DAMAGE_PAGE_NAME,
    DAMAGE_SAVE_BUTTON,
    DAMAGES_PAGES,
    SAVE_DAMAGE_ENTRY
} from "../constants";
import SaveIcon from "@material-ui/icons/Save";
import {
    deleteDamageEntry,
    findPoInformation,
    listSpecificDamage,
    saveDamagedEntry,
    updateDamageEntry
} from "../../../redux/actions/damage";
import {poInformationSelector, specificDamageSelector} from "../../../redux/selectors/damage";
import {userInfoSelector} from "../../../redux/selectors/auth";
import {TextField} from "@material-ui/core";
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from "@material-ui/icons/Delete";
import {DELETE, CANCEL} from "../../global/constants";

class DamagesForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            formContents: <></>,
            disableAllFields: this.props.creating ? false : true,
            saveDisabled: false,
            shortcodeOptions: [],
            locationOptions: this.props.locations.filter(location => location.enabled).map(location => location.name),

            // fields on the editable purchase order
            creationDate: (new Date()).toISOString(),
            purchaseOrderNumber: "",
            shortcode: "",
            qtyDamaged: "0",
            qtyReplaced: "0",
            qtyCredited: "0",
            qtyRemaining: "0",
            notes: "",
            replacedDate: (new Date()).toISOString(),
            supplier: "", // not editable directly
            receivedBy: "", // not editable directly
            location: "", // not editable directly
            replacementReceivedBy: "",
            creditReceivedByName: "",
            fieldHasBeenChanged: false,
            confirmDelete: false,
            replacementLocation: ""
        }

        this.state.saveDisabled = this.isSaveDisabled()
        if (!this.props.creating) {
            this.props.listSpecificDamage(this.props.idOfDamageBeingEdited)
            this.setInitialValues(true)
        }

        this.getFormContents(true)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.creationDate !== this.state.creationDate ||
            prevState.purchaseOrderNumber !== this.state.purchaseOrderNumber ||
            prevState.shortcode !== this.state.shortcode ||
            prevState.qtyDamaged !== this.state.qtyDamaged ||
            prevState.qtyReplaced !== this.state.qtyReplaced ||
            prevState.qtyCredited !== this.state.qtyCredited ||
            prevState.replacedDate !== this.state.replacedDate ||
            prevState.supplier !== this.state.supplier ||
            prevState.shortcodeOptions !== this.state.shortcodeOptions ||
            prevState.disableAllFields !== this.state.disableAllFields ||
            prevState.qtyRemaining !== this.state.qtyRemaining ||
            prevState.location !== this.state.location ||
            prevState.replacementLocation !== this.state.replacementLocation
        ) {
            this.getFormContents(false)
            this.setState({
                saveDisabled: this.isSaveDisabled()
            })
        }

        if (prevState.notes !== this.state.notes) {
            this.setState({
                saveDisabled: this.isSaveDisabled()
            })
        }

        if (prevProps.poInformation !== this.props.poInformation) {
            this.setState({
                supplier: this.props.poInformation.supplier,
                shortcodeOptions: Object.keys(this.props.poInformation.inventoryReceivedMap),
            })
        }

        if (prevState.location !== this.state.location) {
            this.setState({
                location: this.state.location,
            })
        }

        if (prevProps.specificDamage !== this.props.specificDamage) {
            if (!this.props.creating) {
                this.setInitialValues(false)
            }
        }

        if (prevState.purchaseOrderNumber !== this.state.purchaseOrderNumber) {
            this.props.findPoInformation(this.state.purchaseOrderNumber)
        }

        if (prevState.qtyDamaged !== this.state.qtyDamaged ||
            prevState.qtyReplaced !== this.state.qtyReplaced ||
            prevState.qtyCredited !== this.state.qtyCredited) {
            this.setState({
                qtyRemaining: (parseInt(this.state.qtyDamaged === "" ? "0" : this.state.qtyDamaged)
                    - parseInt(this.state.qtyReplaced === "" ? "0" : this.state.qtyReplaced)
                    - parseInt(this.state.qtyCredited === "" ? "0" : this.state.qtyCredited)).toString()
            })
        }
    }

    setInitialValues(fromConstructor) {
        if (!this.props.specificDamage)
            return

        if (fromConstructor) {
            this.state.disableAllFields = false
            this.state.creationDate = this.props.specificDamage.creationDate
            this.state.purchaseOrderNumber = this.props.specificDamage.poNumber
            this.state.shortcode = this.props.specificDamage.shortcode
            this.state.qtyDamaged = this.props.specificDamage.qtyDamaged
            this.state.qtyReplaced = this.props.specificDamage.qtyReplaced
            this.state.qtyCredited = this.props.specificDamage.qtyCredited
            this.state.supplier = this.props.specificDamage.supplier
            this.state.receivedBy = this.props.specificDamage.receivedBy
            this.state.notes = this.props.specificDamage.notes
            this.state.replacementReceivedBy = this.props.specificDamage.replacementReceivedByName
            this.state.creditReceivedByName = this.props.specificDamage.creditReceivedByName
            this.state.location = this.findLocation(this.props.specificDamage.shipAddressId)
            this.state.replacementLocation = this.props.specificDamage.replacementLocationId ? this.findLocation(this.props.specificDamage.replacementLocationId) : ""
        } else {
            this.setState({
                disableAllFields: false,
                creationDate: this.props.specificDamage.creationDate,
                purchaseOrderNumber: this.props.specificDamage.poNumber,
                shortcode: this.props.specificDamage.shortcode,
                qtyDamaged: this.props.specificDamage.qtyDamaged,
                qtyReplaced: this.props.specificDamage.qtyReplaced,
                qtyCredited: this.props.specificDamage.qtyCredited,
                supplier: this.props.specificDamage.supplier,
                receivedBy: this.props.specificDamage.receivedBy,
                notes: this.props.specificDamage.notes,
                replacementReceivedBy: this.props.specificDamage.replacementReceivedByName,
                creditReceivedByName: this.props.specificDamage.creditReceivedByName,
                location: this.findLocation(this.props.specificDamage.shipAddressId),
                replacementLocation: this.props.specificDamage.replacementLocationId ? this.findLocation(this.props.specificDamage.replacementLocationId) : ""
            })
        }
    }

    getUserName = () => {
        return this.props.user ? this.props.user.firstname + " " + this.props.user.lastname : ""
    }

    getFormDisplay = () => {
        if (this.props.creating) {
            return Layout.newLayout(3, [
                Layout.row([
                    Layout.rowElement(0.75, "creationDate", "Reported Date", Layout.elementStyle.dateSelect, null, this.state.disableAllFields, false, true),
                    Layout.rowElement(0.75, "purchaseOrderNumber", "PO #", Layout.elementStyle.textBox, null, this.state.disableAllFields, true,  this.state.purchaseOrderNumber),
                    Layout.rowElement(0.75, "shortcode", "Shortcode", Layout.elementStyle.selectDropdown, this.state.shortcodeOptions, this.state.disableAllFields, true, this.state.shortcode),
                    Layout.rowElement(0.75, "supplier", "Supplier", Layout.elementStyle.textBox, null, true, false, true),
                ]),
                Layout.row([
                    Layout.rowElement(0.75, "", "", Layout.elementStyle.blankSpacer, null, false, false, true),
                    Layout.rowElement(0.75, "qtyDamaged", "Quantity Damaged", Layout.elementStyle.textBox, null, this.state.disableAllFields, false, true),
                    Layout.rowElement(0.75, "location", "Reported Location", Layout.elementStyle.selectDropdown, this.state.locationOptions, false, true, this.state.location),
                    Layout.rowElement(0.75, "receivedBy", "Damage Reported By", Layout.elementStyle.textBox, null, true, false, true)
                ]),
                Layout.row([
                    Layout.rowElement(0.75, "", "", Layout.elementStyle.blankSpacer, null, false, false, true),
                    Layout.rowElement(0.75, "qtyReplaced", "Quantity Replaced", Layout.elementStyle.textBox, null, this.state.disableAllFields, false, true),
                    Layout.rowElement(0.75, "replacementLocation", "Replacement Location", Layout.elementStyle.selectDropdown,  this.state.locationOptions, false, false,this.state.qtyReplaced <= 0 || this.state.replacementLocation),
                    Layout.rowElement(0.75, "replacementReceivedBy", "Replacement Received By", Layout.elementStyle.textBox, null, true, false, true)
                ]),
                Layout.row([
                    Layout.rowElement(0.75, "", "", Layout.elementStyle.blankSpacer, null, false, false, true),
                    Layout.rowElement(0.75, "qtyCredited", "Quantity Credited", Layout.elementStyle.textBox, null, this.state.disableAllFields, false, true),
                    Layout.rowElement(0.75, "creditReceivedByName", "Credit Received By", Layout.elementStyle.textBox, null, true, false, true)
                ]),
                Layout.row([
                    Layout.rowElement(0.75, "", "", Layout.elementStyle.blankSpacer, null, false, false, true),
                    Layout.rowElement(0.75, "qtyRemaining", "Quantity Remaining", Layout.elementStyle.textBox, null, true, false, true)
                ])
            ])
        } else {
            return Layout.newLayout(3, [
                Layout.row([
                    Layout.rowElement(0.75, "creationDate", "Reported Date", Layout.elementStyle.dateSelect, null, this.state.disableAllFields, false, true),
                    Layout.rowElement(0.75, "purchaseOrderNumber", "PO #", Layout.elementStyle.textBox, null, this.state.disableAllFields, true,  this.state.purchaseOrderNumber),
                    Layout.rowElement(0.75, "shortcode", "Shortcode", Layout.elementStyle.selectDropdown, this.state.shortcodeOptions, this.state.disableAllFields, true, this.state.shortcode),
                    Layout.rowElement(0.75, "supplier", "Supplier", Layout.elementStyle.textBox, null, true, false, true),
                ]),
                Layout.row([
                    Layout.rowElement(0.75, "", "", Layout.elementStyle.blankSpacer, null, false, false, true),
                    Layout.rowElement(0.75, "qtyDamaged", "Quantity Damaged", Layout.elementStyle.textBox, null, this.state.disableAllFields, false, true),
                    Layout.rowElement(0.75, "location", "Reported Location", Layout.elementStyle.selectDropdown, this.state.locationOptions, false, true, this.state.location),
                    Layout.rowElement(0.75, "receivedBy", "Damage Reported By", Layout.elementStyle.textBox, null, true, false, true)
                ]),
                Layout.row([
                    Layout.rowElement(0.75, "", "", Layout.elementStyle.blankSpacer, null, false, false, true),
                    Layout.rowElement(0.75, "qtyReplaced", "Quantity Replaced", Layout.elementStyle.textBox, null, this.state.disableAllFields, false, true),
                    Layout.rowElement(0.75, "replacementLocation", "Replacement Location", Layout.elementStyle.selectDropdown,  this.state.locationOptions, false, false,this.state.qtyReplaced <= 0 || this.state.replacementLocation),
                    Layout.rowElement(0.75, "replacementReceivedBy", "Replacement Received By", Layout.elementStyle.textBox, null, true, false, true)
                ]),
                Layout.row([
                    Layout.rowElement(0.75, "", "", Layout.elementStyle.blankSpacer, null, false, false, true),
                    Layout.rowElement(0.75, "qtyCredited", "Quantity Credited", Layout.elementStyle.textBox, null, this.state.disableAllFields, false, true),
                    Layout.rowElement(0.75, "", "", Layout.elementStyle.blankSpacer, null, false, false, true),
                    Layout.rowElement(0.75, "creditReceivedByName", "Credit Received By", Layout.elementStyle.textBox, null, true, false, true)
                ]),
                Layout.row([
                    Layout.rowElement(0.75, "", "", Layout.elementStyle.blankSpacer, null, false, false, true),
                    Layout.rowElement(0.75, "qtyRemaining", "Quantity Remaining", Layout.elementStyle.textBox, null, true, false, true)
                ])
            ])
        }
    }

    getFormContents = (calledFromConstructor) => {
        if (calledFromConstructor) {
            this.state.formContents = this.getFormDisplay()(this.onElementChange, this.getElementValue)
        } else {
            this.setState({
                formContents:  this.getFormDisplay()(this.onElementChange, this.getElementValue)
            })
        }
    }

    onElementChange = (nameValueObj) => {
        if (this.props.creating || nameValueObj.name === "qtyDamaged") {
            this.setState({
                receivedBy: this.getUserName()
            })
        } else if (nameValueObj.name === "qtyReplaced") {
            this.setState({
                replacementReceivedBy: this.getUserName()
            })
        } else if (nameValueObj.name === "qtyCredited") {
            this.setState({
                creditReceivedByName: this.getUserName()
            })
        }

        this.setState({
            [nameValueObj.name]: nameValueObj.value,
            fieldHasBeenChanged: true
        })
    }

    hasAnyFieldBeenChanged = () => {
        return this.state.fieldHasBeenChanged
    }

    getElementValue = (elementDisplayName, connectedFieldName, elementStyle) => {
        return this.state[connectedFieldName] ?? Layout.getDefaultElementValueFromStyle(elementStyle)
    }

    isSaveDisabled = () => {
        return (this.hasAnyFieldBeenChanged() === false || this.state.purchaseOrderNumber === '' || this.state.supplier === '' || this.state.supplier === 'N/A' || this.state.shortcode === '' || this.state.shortcode === null ||
            this.state.creationDate === null || this.state.replacedDate === null ||this.state.location === '' ||this.state.location === null ||  this.state.qtyDamaged === '' || this.state.qtyDamaged <= 0 || this.state.qtyReplaced < 0 || this.state.qtyCredited < 0 ||
            (parseInt(this.state.qtyReplaced === "" ? "0" : this.state.qtyReplaced) + parseInt(this.state.qtyCredited === "" ? "0" : this.state.qtyCredited)) > parseInt(this.state.qtyDamaged) ||
            (this.props.creating && (this.state.qtyDamaged > this.getTotalInventoryReceivedForShortcode(this.state.shortcode))) ||
            this.state.disableAllFields|| (this.state.qtyReplaced && this.state.qtyReplaced > 0 && !this.state.replacementLocation))
    }

    getTotalInventoryReceivedForShortcode = (shortcode) => {
        if (this.props.poInformation?.inventoryReceivedMap && Object.keys(this.props.poInformation?.inventoryReceivedMap).length > 0)
            return this.props.poInformation?.inventoryReceivedMap[shortcode]
        return 0
    }

    findLocationId = (location) => {
        let searchLocation = this.props.locations.find(({name}) => name === location);
        return searchLocation.shipAddressId
    }


    findLocation = (damageShipAddressId) => {
        let searchLocation = this.props.locations.find(({shipAddressId}) => shipAddressId === damageShipAddressId);
        return searchLocation.name
    }

    handleNotesChange = (value) => {
        this.setState({
            notes: value ?? "",
            fieldHasBeenChanged: true
        })
    }

    handleSave = () => {
        let damagesEntry

        if (this.props.creating) {
            damagesEntry = {
                creationDate: this.state.creationDate,
                poNumber: this.state.purchaseOrderNumber,
                supplier: this.state.supplier,
                shortcode: this.state.shortcode,
                qtyDamaged: this.state.qtyDamaged,
                qtyReplaced: this.state.qtyReplaced,
                qtyCredited: this.state.qtyCredited,
                replacedDate: this.state.replacedDate,
                receivedBy: this.state.receivedBy,
                notes: this.state.notes,
                shipAddressId: this.findLocationId(this.state.location),
                replacementLocationId: this.findLocationId(this.state.replacementLocation)
            }
        } else {
            damagesEntry = {
                damageId: this.props.specificDamage.damageId,
                creationDate: this.state.creationDate,
                newQtyDamaged: this.state.qtyDamaged,
                newQtyReplaced: this.state.qtyReplaced,
                newQtyCredited: this.state.qtyCredited,
                oldQtyDamaged: this.props.specificDamage.qtyDamaged,
                oldQtyReplaced: this.props.specificDamage.qtyReplaced,
                oldQtyCredited: this.props.specificDamage.qtyCredited,
                poNumber: this.state.purchaseOrderNumber,
                receivedBy: this.state.receivedBy,
                replacedDate: this.state.replacedDate,
                shortcode: this.state.shortcode,
                supplier: this.props.specificDamage.supplier,
                notes: this.state.notes,
                shipAddressId: this.findLocationId(this.state.location),
                replacementLocationId: this.findLocationId(this.state.replacementLocation)
            }
        }

        this.setState({
            disableAllFields: true
        })

        if (this.props.creating) {
            this.props.saveDamagedEntry(damagesEntry, () => {this.props.setCurrentPage(DAMAGES_PAGES.TABLE)})
        } else {
            this.props.updateDamageEntry(damagesEntry, () => {this.props.setCurrentPage(DAMAGES_PAGES.TABLE)})
        }

        setTimeout(() => {
            if (this.state.disableAllFields) {
                this.setState({
                    disableAllFields: false
                })
            }
        }, 3000)
    }

    getTopToolbarOptions = () => {
        let options = [
            {title: DAMAGE_BACK_BUTTON, icon: <KeyboardBackspaceIcon/>, onClick: () => {this.props.setCurrentPage(DAMAGES_PAGES.TABLE)}},
            {title: SAVE_DAMAGE_ENTRY, icon: <SaveIcon/>, onClick: this.handleSave, disabled: this.state.saveDisabled}
        ]
        if (!this.props.creating) {
            if (!this.state.confirmDelete) {
                options.push({title: DELETE, icon: <DeleteIcon/>, onClick: () => this.setState({confirmDelete: true})})
            } else {
                options.push({title: CANCEL, icon: <CloseIcon/>, onClick: () => this.setState({confirmDelete: false})})
                options.push({title: DELETE, icon: <DeleteIcon/>, onClick: () => {
                    this.props.deleteDamageEntry(this.props.specificDamage?.damageId, () => this.props.setCurrentPage(DAMAGES_PAGES.TABLE))
                }})
            }
        }

        return options
    }

    displayTopToolbar = () => {
        return <TopToolbar
            pageName={DAMAGE_PAGE_NAME}
            menuItems={this.getTopToolbarOptions()}
        />
    }

    displayNotesSection = () => {
        return <React.Fragment>
            <div className="notesField">
                <TextField
                    variant="outlined"
                    name="damageNotes"
                    value={this.state.notes}
                    onChange={(event) => {
                        this.handleNotesChange(event.target.value)}}
                    label="Damage Notes"
                    style={{ width: "inherit" }}
                />
            </div>
        </React.Fragment>
    }

    render() {
        return (
            <React.Fragment>
                {this.displayTopToolbar()}
                <div className='full-width-bar'>
                    <div style={{width: "100%", display: "flex", flexDirection: "column"}}>
                        <div className='horizontally-center'>
                            <div style={{width: "100%", height: "20px"}}/>
                            {this.state.formContents.errorMessage ? this.state.formContents.errorMessage : this.state.formContents.content}
                            <div style={{width: "900px", paddingTop: "8px", paddingBottom: "15px"}}>
                                {this.displayNotesSection()}
                            </div>
                            {Layout.cancelSaveRow(3, () => {this.props.setCurrentPage(DAMAGES_PAGES.TABLE)}, this.handleSave, this.state.saveDisabled, DAMAGE_SAVE_BUTTON)}
                        </div>

                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: userInfoSelector(state),
    poInformation: poInformationSelector(state),
    specificDamage: specificDamageSelector(state)
})

const actionCreators = {
    saveDamagedEntry,
    updateDamageEntry,
    listSpecificDamage,
    findPoInformation,
    deleteDamageEntry,
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, DamagesForm);