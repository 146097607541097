import React from "react";
import withShipment from "../../../withShipment";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import SaveIcon from "@material-ui/icons/Save";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import MenuIcon from "@material-ui/icons/Menu";
import {ASC, DEFAULT_PDF_ZOOM} from "../../global/constants";
import ErrorFeedbackBar from "../../global/ErrorFeedbackBar";
import CustomToolbar from "../../global/CustomToolbar";
import {CARRIER_INVOICE_TEMPLATE_FIELDS, CREATING_TEMPLATE, EDITING_TEMPLATE, VIEWING_TABLE} from "./constant";
import {getDateFormats} from "../../../redux/actions/invoices";
import {
    createOrEditFTLCarrierInvoiceTemplate, deleteInvoiceTemplate, getFTLCarrierInvoiceTemplatePDF,
    listFTLCarrierInvoiceTemplates, storeFTLCarrierInvoiceTemplatePDF
} from "../../../redux/actions/fullTruckloadCarriers";
import CreateFTLCarrierInvoiceTemplate from "./CreateFTLCarrierInvoiceTemplate";
import {DEFAULT_DATE_FORMAT} from "../../settings/InvoiceTemplates/carrierInvoiceTemplates/constants";
import EditCarrierInvoiceTemplates
    from "../../settings/InvoiceTemplates/carrierInvoiceTemplates/EditCarrierInvoiceTemplates";
import EditInvoiceTemplate from "../../settings/InvoiceTemplates/supplierInvoiceTemplates/EditInvoiceTemplate";
import TemplateTable from "../../settings/InvoiceTemplates/supplierInvoiceTemplates/TemplateTable";
import {
    ftlCarrierInvoiceTemplatePDFSelector,
    ftlCarrierInvoiceTemplatesSelector
} from "../../../redux/selectors/fullTruckloadCarrier";
import CustomTableHeader from "../../global/Table/CustomTableHeader";
import {IconButton, TableBody, TablePagination} from "@material-ui/core";
import {
    DEFAULT_ROWS_PER_PAGE, DEFAULT_SORT_BY_HEAD_CELL, INVOICE_TEMPLATE_DATA_SUB_FIELDS,
    INVOICE_TEMPLATE_TABLE_HEAD_CELLS,
    ROWS_PER_PAGE_LABEL
} from "../../settings/InvoiceTemplates/supplierInvoiceTemplates/constants";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import GlobalTableHead from "../../global/Table/GlobalTableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import SearchableTable from "../../global/Search/SearchableTable";
import FTLCarrierInvoiceTemplateTable from "./FTLCarrierInvoiceTemplateTable";
import {allDateFormats} from "../../../redux/selectors/invoices";


class FTLCarrierInvoiceTemplates extends React.Component {


    constructor (props) {
        super(props);
        this.state = {
            curState: VIEWING_TABLE,

            // states for editing template
            scale: DEFAULT_PDF_ZOOM,
            selectedDateFormat: DEFAULT_DATE_FORMAT,
            selectedCarrier: null,
            templateCopy: "none",
            fields: JSON.parse(JSON.stringify(CARRIER_INVOICE_TEMPLATE_FIELDS)),
            subFields: JSON.parse(JSON.stringify({})),
            // separatorFields: JSON.parse(JSON.stringify(CARRIER_INVOICE_TEMPLATE_SEPARATOR_FIELDS)),
            template: {
                name: "",
                contents: []
            },
            fieldError: "",
            searchText: '',

            // states for table view
            page: 0,
            rowsPerPage: DEFAULT_ROWS_PER_PAGE,
            order: ASC,
            orderBy: DEFAULT_SORT_BY_HEAD_CELL,
            deleteId: -1,
            searchValue: "", // the value in the search field (updates on change)
            isSearch: false, // true if search value is not empty, false otherwise
            searchRows: 0, // number of rows displayed while search criteria is met
            showSideBar: true,
        }
        this.updateParentFields = this.updateParentFields.bind(this)
        this.saveTemplate = this.saveTemplate.bind(this)
        this.deleteInvoiceTemplate = this.deleteInvoiceTemplate.bind(this)
    }

    componentDidMount() {
        this.props.listFTLCarrierInvoiceTemplates()
        this.props.getDateFormats()
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevProps.ftlCarrierInvoiceTemplatePDF !== this.props.ftlCarrierInvoiceTemplatePDF) {
            let templateCopy = this.state.template
            templateCopy.contents = this.props.ftlCarrierInvoiceTemplatePDF
            this.setState({
                template: templateCopy
            })
        }
    }

    saveTemplate() {
        let data = {
            data: {},
            marker: {
                coordinates: {},
                text: ""
            },
            dateFormat: this.state.selectedDateFormat
        }
        let fieldKeys = Object.keys(this.state.fields)
        for (const key of fieldKeys) {
            if (this.state.fields[key].required && !this.fieldDefined(this.state.fields[key].position)) {
                this.setState({ fieldError: key })
                return
            }
            if (key !== "marker") {
                let tempPosition = {...this.state.fields[key].position}
                tempPosition.x = Math.round(tempPosition.x / this.state.scale)
                tempPosition.y = Math.round(tempPosition.y / this.state.scale)
                tempPosition.w = Math.round(tempPosition.w / this.state.scale)
                tempPosition.h = Math.round(tempPosition.h / this.state.scale)
                console.log(key)
                console.log(tempPosition)
                data.data[key] = tempPosition
            }
        }
        data.carrierId = this.state.selectedCarrier
        let tempPosition = {...this.state.fields.marker.position}
        tempPosition.x = Math.round(tempPosition.x / this.state.scale)
        tempPosition.y = Math.round(tempPosition.y / this.state.scale)
        tempPosition.w = Math.round(tempPosition.w / this.state.scale)
        tempPosition.h = Math.round(tempPosition.h / this.state.scale)
        data.marker.coordinates = tempPosition
        data.pdf = Array.from(this.state.template.contents)
        this.props.createOrEditFTLCarrierInvoiceTemplate(data, () => window.location.reload(), () => alert("Something went wrong"))
    }

    deleteInvoiceTemplate(carrierId) {
        console.log("here")
        this.props.deleteInvoiceTemplate(carrierId)
    }

    updateField = (field, value) => {
        this.setState({ [field]: value })
    }

    cancelEdit = () => {
        this.setState({
            curState: VIEWING_TABLE,
            // widenBox: false,
            // showFields: false,
            fieldError: "",
            // fieldColumnError: -1,
            // separatorError: "",
            fields: JSON.parse(JSON.stringify(CARRIER_INVOICE_TEMPLATE_FIELDS)),
            // separatorFields: JSON.parse(JSON.stringify(CARRIER_INVOICE_TEMPLATE_SEPARATOR_FIELDS)),
            // fieldColumns: [],
            selectedDateFormat: DEFAULT_DATE_FORMAT
        })
    }

    getHeaderButtons = (curState) => {
        switch (curState) {
            case VIEWING_TABLE:
                return (
                    [
                        {icon: <KeyboardBackspaceIcon />, handler: () => this.props.closeInvoiceTemplates(), disabled: false, title: "Back"},
                        {icon: <AddCircleIcon/>, handler: () => this.setState({ curState: CREATING_TEMPLATE }), disabled: false, title: "Create Carrier Invoice Template"}
                    ]
                )
            case EDITING_TEMPLATE:
                return (
                    [
                        { icon: <KeyboardBackspaceIcon />, handler: () => this.cancelEdit(), disabled: false, title: "Back" },
                        { icon: <SaveIcon />, handler: this.saveTemplate, disabled: false, title: "Save" }
                    ]
                )
            case CREATING_TEMPLATE:
                return (
                    [
                        { icon: <KeyboardBackspaceIcon />, handler: () => this.setState({ curState: VIEWING_TABLE }), disabled: false, title: "Cancel" },
                        { icon: <ArrowForwardIcon />, handler: () => this.setState({ curState: EDITING_TEMPLATE }), disabled: this.state.selectedCarrier === "none" || this.state.template?.contents.length === 0, title: "Next" },
                        { icon: <SaveIcon />, handler: this.copySaveTemplate, disabled: this.state.selectedCarrier === "none" || this.state.templateCopy === "none", title: "Save" }
                    ]
                )
            default:
                return (
                    <></>
                )
        }
    }

    getRightHeaderButtons = (curState) => {
        return curState === EDITING_TEMPLATE ? [{ icon: <MenuIcon />, handler: this.openCloseSideBar, disabled: false, title: "Show Fields" }] : null
    }

    zoomIn(){
        this.setState({scale: Math.min(2, this.state.scale + 0.25)})
    }

    zoomOut() {
        this.setState({scale: Math.max(1, this.state.scale - 0.25)})
    }

    updateParentFields = (fields) => {
        this.setState({ fields: fields })
    }

    fieldDefined = (position) => {
        return position?.w && position?.h
    }

    setDateFormat = (format) => {
        this.setState({
            selectedDateFormat: format
        })
    }

    displayMainContent(curState) {
        switch (curState) {
            case CREATING_TEMPLATE:
                return (
                    <CreateFTLCarrierInvoiceTemplate
                        updateField={(field, value) => this.updateField(field, value)}
                        template={this.state.template}
                    />
                )
            case EDITING_TEMPLATE:
                return (
                    <EditInvoiceTemplate
                        pdf={this.state.template}
                        fieldDefined={this.fieldDefined}
                        updateParentFields={this.updateParentFields}
                        fieldError={this.state.fieldError}
                        resetError={this.props.resetError}
                        scale={this.state.scale}
                        updateField={this.updateField}
                        dateFormats={this.props.dateFormats}
                        setDateFormat={this.setDateFormat}
                        selectedFormat={this.state.selectedDateFormat}
                        fields={this.state.fields}
                        subFields={this.state.subFields}
                        isFTLCarrierInvoice={true}
                        showSideBar={this.state.showSideBar}
                    />
                )
            case VIEWING_TABLE:
                return (
                    <>
                        <FTLCarrierInvoiceTemplateTable
                            data={this.props.carrierInvoiceTemplates}
                            editing={this.state.curState == EDITING_TEMPLATE}
                            updateField={(field, value) => this.updateField(field, value)}
                            template={this.props.ftlCarrierInvoiceTemplatePDF}
                            fieldDefined={this.fieldDefined}
                            getPDFTemplate={this.props.getFTLCarrierInvoiceTemplatePDF}
                            clearPDFTemplate={this.props.storeFTLCarrierInvoiceTemplatePDF}
                            updateParentFields={this.updateParentFields}
                            fieldError={this.state.fieldError}
                            fields={this.state.fields}
                            subFields={this.state.subFields}
                            entryTaxText={this.state.entryTaxText}
                            scale={this.state.scale}
                            resetError={() => this.setState({ fieldError: "" })}
                            deleteInvoiceTemplate={this.deleteInvoiceTemplate}
                            searchText={this.state.searchText}
                            handleSearchChange={this.handleSearchTextChange}
                            dateFormats={this.props.dateFormats}
                            setDateFormat={this.setDateFormat}
                            selectedFormat={this.state.selectedDateFormat}
                            showSupplier={this.props.showSupplier}
                        />
                    </>
                )
            default:
                return (
                    <></>
                )
        }
    }

    setCurState = (state) => {
        this.setState({
            curState:state
        })
    }

    openCloseSideBar = () => {
        this.setState({
            showSideBar: !this.state.showSideBar
        })
    }

    render() {
        return (
            <>
                <ErrorFeedbackBar />
                <CustomToolbar
                    createTitle="Create FTL Carrier Invoice Template"
                    title="LTL/FTL Carrier Invoice Templates"
                    onClick={() => this.setState({ curState: CREATING_TEMPLATE })}
                    headerButtons={this.getHeaderButtons(this.state.curState)}
                    rightHeaderButtons={this.getRightHeaderButtons(this.state.curState)}
                    zoomIn={() => this.zoomIn()}
                    zoomOut={() => this.zoomOut()}
                    scale={this.state.scale}
                    addZoom={this.state.curState == EDITING_TEMPLATE}
                />

                {this.displayMainContent(this.state.curState)}
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    carrierInvoiceTemplates: ftlCarrierInvoiceTemplatesSelector(state),
    ftlCarrierInvoiceTemplatePDF: ftlCarrierInvoiceTemplatePDFSelector(state),
    dateFormats: allDateFormats(state)
});

const actionCreators = {
    getDateFormats,
    listFTLCarrierInvoiceTemplates,
    createOrEditFTLCarrierInvoiceTemplate,
    getFTLCarrierInvoiceTemplatePDF,
    storeFTLCarrierInvoiceTemplatePDF,
    deleteInvoiceTemplate,
}

FTLCarrierInvoiceTemplates.propTypes = {

}

FTLCarrierInvoiceTemplates.defaultProps = {

}

export default withShipment ({
    mapStateToProps,
    actionCreators
},FTLCarrierInvoiceTemplates);