import React from 'react';


// material components
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Drawer from "@material-ui/core/Drawer";
import {IconButton, Tooltip} from "@material-ui/core";
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

// custom components
import AlertsCard from "./AlertsCard";

// constants
import {ALERT_DRAWER_HEADING, CLOSE, NO_ENTRIES_TEXT} from "./constants";

// styling
import "./AlertsDrawer.css"
import FileChip from "../global/FileChip";
import PropTypes from "prop-types";
import FeedbackBar from "../feedbackBar/FeedbackBar";
import ErrorFeedbackBar from "../global/ErrorFeedbackBar";
import {responseMessageSelector} from "../../redux/selectors/settings";
import {storeResponseMessage} from "../../redux/actions/settings";
import {statusSelector} from "../../redux/selectors/global";
import withShipment from "../../withShipment";


class AlertsDrawer extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            alerts: [],
            responseMessage: "",
            feedbackBarOpen: false,
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.responseMessage !== this.props.responseMessage && this.props.responseMessage!=="") {
            this.setState({
                feedbackBarOpen: true
            })
        }
    }

    closeFeedbackBar = () => {
        this.props.storeResponseMessage("")
        this.setState({
            feedbackBarOpen: false
        })
    }

    render() {
        return (
            <Drawer className="drawer" anchor='right' open={this.props.alertDrawerOpen}
                    onClose={this.props.handleAlertDrawerClose}>
                <ErrorFeedbackBar />
                <FeedbackBar
                    open={this.state.feedbackBarOpen}
                    severity={this.props.status?.success ? 'success' : 'error'}
                    message={this.props.responseMessage}
                    handleClose={this.closeFeedbackBar}
                />
                <div className="alerts-drawer">
                    <div className="drawer-header">
                        <div className="drawer-header-text">
                            {ALERT_DRAWER_HEADING}
                        </div>

                        <div className="drawer-header-close-icon">
                            <Tooltip title={CLOSE}>
                                <IconButton
                                    aria-label='close'
                                    onClick={this.props.closeDrawer}
                                >
                                    <DoubleArrowIcon variant='outlined' className='drawer-close-icon'/>
                                </IconButton>
                            </Tooltip>
                        </div>

                    </div>
                    {!this.props.noAlerts && this.props.alertEntries ?
                        this.props.alertEntries.map((alert) => {
                            return (
                                <AlertsCard
                                    alertEntry={alert}
                                    snoozeAlert={this.props.snoozeAlert}
                                    removeSnoozedAlert={this.props.removeSnoozedAlerts}
                                    resolveAlert={this.props.resolveAlert}
                                />
                            );
                        })
                        :

                        <Card>
                            <CardContent>
                                {NO_ENTRIES_TEXT}
                            </CardContent>
                        </Card>
                    }
                </div>
            </Drawer>
        );
    }
}

AlertsDrawer.propTypes = {
    alertDrawerOpen: PropTypes.bool,
    handleAlertDrawerClose: PropTypes.func,
    alertEntries: PropTypes.array,
    noAlerts: PropTypes.bool,
    closeDrawer: PropTypes.func
};

const mapStateToProps = (state) => ({
    status: statusSelector(state),
    responseMessage: responseMessageSelector(state),
})
const actionCreators = {
    storeResponseMessage,
}


AlertsDrawer.defaultProps = {
    alertDrawerOpen: false,
    handleAlertDrawerClose: () => {
    },
    alertEntries: [],
    noAlerts: true,
    closeDrawer: () => {
    }
}

export default withShipment({
    actionCreators,
    mapStateToProps
}, AlertsDrawer);