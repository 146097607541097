import React from 'react';

import ShipmentsTable from "../shipments/ShipmentsTable";

import { FEDEX, SHIPPED_SHIPMENTS } from "../shipments/constants";
import { SHIPPED } from "./constants";

// redux
import withShipment from "../../withShipment";
import {
    ordersSelector, shippedSelector
} from "../../redux/selectors/orders";
import {
    fetchAllOrders
} from "../../redux/actions/orders";
import {
    saveTableSetting
} from "../../redux/actions/account";
import {
    userInfoSelector
} from "../../redux/selectors/auth";
import {
    tableSettingSelector
} from "../../redux/selectors/account";
import {
    listAvailableLocations,
    listCarriers,
} from "../../redux/actions/settings";
import {
    availableLocationsSelector,
    carriersSelector,
} from "../../redux/selectors/settings";
import PropTypes from "prop-types";

class ShippedShipments extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            selected: [],
            defaultTableDisplay: [
                { key: 0, id: 'tag', numeric: false, disablePadding: true, label: 'Tag', selected: true },
                { key: 16, id: 'status', numeric: false, disablePadding: false, label: 'Status', selected: true },
                { key: 1, id: 'name', numeric: false, disablePadding: false, label: 'Name', selected: true },
                { key: 2, id: 'address', numeric: false, disablePadding: false, label: 'Address', selected: true },
                { key: 3, id: 'orderid', numeric: false, disablePadding: false, label: 'Order ID', selected: true },
                { key: 4, id: 'city', numeric: false, disablePadding: false, label: 'City', selected: true },
                { key: 5, id: 'state', numeric: false, disablePadding: false, label: 'State', selected: true },
                { key: 6, id: 'sku', numeric: false, disablePadding: false, label: 'SKU', selected: true },
                { key: 7, id: 'shipfrom', numeric: false, disablePadding: false, label: 'Ship From', selected: true },
                { key: 8, id: 'rate', numeric: false, disablePadding: false, label: 'Rate', selected: true },
                { key: 9, id: 'carrier', numeric: false, disablePadding: false, label: 'Carrier', selected: true },
                { key: 10, id: 'orderDate', numeric: false, disablePadding: false, label: 'Order Date', selected: true },
                { key: 11, id: 'shipDate', numeric: false, disablePadding: false, label: 'Shipped Date', selected: true },
                { key: 12, id: 'marketplace', numeric: false, disablePadding: false, label: 'Marketplace', selected: true },
                { key: 13, id: 'shipmethod', numeric: false, disablePadding: false, label: 'Ship Method', selected: true },
                { key: 14, id: 'isprime', numeric: false, disablePadding: false, label: 'Prime', selected: true },
                { key: 15, id: 'deliverBy', numeric: false, disablePadding: false, label: 'Deliver By', selected: true },
                { key: 16, id: 'brand', numeric: false, disablePadding: false, label: 'Brand', selected: true },
                { key: 17, id: 'postal', numeric: false, disablePadding: false, label: 'Postal Code', selected: true },
            ],
        }
    }

    componentDidMount() {
        // this.props.listLocations({ id: this.props.userInfo.company })
        this.props.listCarriers({ id: this.props.userInfo.company })
        this.props.listAvailableLocations()
        // this.props.fetchAllOrders(SHIPPED, this.getFromDate(), this.getToDate());
        this.props.saveTableSetting({
            userId: this.props.userInfo.userId,
            tableSetting: null,
            pageTitle: SHIPPED,
            defaultTable: this.state.defaultTableDisplay,
            filterSetting: null
        })
    }

    getFromDate = () => {
        var date = new Date()
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        return date
    }

    getToDate = () => {
        var date = new Date()
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
        return date
    }

    handleClick = (event, shipmentid, isFedex) => {
        const selectedIndex = this.state.selected.findIndex(item => item.order === shipmentid);
        let selected = [...this.state.selected];

        if (selectedIndex === -1) {
            selected = [...selected, { order: shipmentid, isFedex }];
        } else {
            selected.splice(selectedIndex, 1);
        }
        this.setState({
            selected,
        });
    };

    handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = this.props.shipped.map(n => ({
                order: n.shipmentId,
                isFedex: !n.carrierService ? false : n.carrierService.split(" ")[0] === FEDEX
            }));
            this.setState({
                selected: newSelected,
            });
            return;
        }

        this.setState({
            selected: []
        });
    };

    isSelected = (item) => {
        const index = this.state.selected.findIndex(order => order.order === item);
        return index !== -1
    };

    clearSelected = () => {
        this.setState({ selected: [] })
    }

    handleExpand = (shipmentId, isFedex) => {
        let selected = [{ order: shipmentId, isFedex }]
        this.setState({
            selected,
        });
    }

    handleSearchTextChange = (e) => {
        this.setState({
            searchText: e.target.value
        })
    }

    render() {
        return (
            <ShipmentsTable
                title={SHIPPED_SHIPMENTS}
                shipmentStatus={SHIPPED}
                handleSelectAllClick={this.handleSelectAllClick}
                handleClick={this.handleClick}
                isSelected={this.isSelected}
                selected={this.state.selected}
                rows={this.props.shipped}
                fetchAllOrders={this.props.fetchAllOrders}
                clearSelected={this.clearSelected}
                tableSetting={this.props.tableSetting}
                locations={this.props.availableLocations}
                carriers={this.props.carriers}
                defaultTableDisplay={this.state.defaultTableDisplay}
                handleExpand={this.handleExpand}
                history={this.props.history}
                searchText={this.state.searchText}
                handleSearchChange={this.handleSearchTextChange}
                smartSearch={true}
            />
        );
    }

}

const mapStateToProps = (state) => ({
    shipped: shippedSelector(state),
    userInfo: userInfoSelector(state),
    carriers: carriersSelector(state),
    tableSetting: tableSettingSelector(state),
    availableLocations: availableLocationsSelector(state)
})

const actionCreators = {
    fetchAllOrders,
    listCarriers,
    saveTableSetting,
    listAvailableLocations,
}

ShippedShipments.propTypes = {
    shipped: PropTypes.array,
    availableLocations: PropTypes.array,
    userInfo: PropTypes.object,
    carriers: PropTypes.array
}

ShippedShipments.defaultProps = {
    shipped: [],
    availableLocations: [],
    userInfo: {},
    carriers: []
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, ShippedShipments);