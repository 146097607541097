export const PACKAGES_TOOLBAR_TITLE = "Packaging"
export const CREATE_PACKAGE_TITLE = "Add a package"
export const BACK_TO_MENU = 'Back to Menu';
export const ORIGIN_CRITERION_OPTIONS = ['A', 'B', 'C', 'D', 'E']

export const MANAGE_PACKAGES_HEAD_CELLS = [
    { id: 'shortcode', center: false, disablePadding: false, label: 'Shortcode' },
    { id: 'actualSize', center: false, disablePadding: false, label: 'Actual Size' },
    { id: 'nominalSize', center: false, disablePadding: false, label: 'Nominal Size' },
]
export const DEFAULT_SORT_BY_HEAD_CELL = "shortcode"
export const DEFAULT_ROWS_PER_PAGE = 50;
export const ROWS_PER_PAGE_LABEL = "Packages per page"
export const SELECT_LOCATION_HEADER = "Locations"
export const SELECT_LOCATION_HELPER_TEXT = "Select the locations where this product is available";

