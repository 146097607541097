import React from "react";
import QRCode from "react-qr-code";
import './ComponentToPrint.css';

class ComponentToPrint extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            barcode: this.props.barcode,
            shortcode: `(${this.props.shortcode}) - ${this.props.quantity}`,
            rows: [0,0,0,0,0,0,0,0,0,0],
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.barcode !== this.props.barcode || prevProps.shortcode !== this.props.shortcode || prevProps.quantity !== this.props.quantity){
            this.setState({
                barcode: this.props.barcode,
                shortcode: `(${this.props.shortcode}) - ${this.props.quantity}`,
            })
        }
    }

    render() {
        return(
            <div className='some-page-wrapper'>
                <div style={{paddingBottom: this.props.topMargin}}/>
            {this.state.rows.map((row, index) => {
                return(
                    <div className='row'>
                        <div style={{paddingBottom: this.props.rowSize}}/>
                        <div className='column'>
                            <div className='nested-column'>
                                <QRCode value={this.state.barcode} size={75}/>
                                <p style={{fontWeight: 'bold', paddingTop: '10px'}}>{this.state.shortcode}</p>
                            </div>
                        </div>
                        <div className='column'>
                            <div className='nested-column'>
                                <QRCode value={this.state.barcode} size={75}/>
                                <p style={{fontWeight: 'bold', paddingTop: '10px'}}>{this.state.shortcode}</p>
                            </div>
                        </div>
                        <div className='column'>
                            <div className='nested-column'>
                                <QRCode value={this.state.barcode} size={75}/>
                                <p style={{fontWeight: 'bold', paddingTop: '10px'}}>{this.state.shortcode}</p>
                            </div>
                        </div>
                    </div>
                )
                })}
            </div>
        )
    }
}

export const ComponentToPrint2 = React.memo(ComponentToPrint);