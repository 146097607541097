import {
    ADD_TAX_INFO,
    APPLY_TRANSACTIONS_RULES,
    ASSIGN_CATEGORY,
    DELETE_TRANSACTION_ATTACHMENT,
    DELETE_TRANSACTION_CATEGORY,
    DELETE_TRANSACTIONS,
    GET_INVOICE_BY_INVOICE_NUMBER,
    GET_SUPPLIER_REFERENCES,
    GET_TRANSACTION_TAX_AMOUNT,
    GET_TAXTYPE_WITH_STATE_PROVINCE,
    GET_TRANSACTION_ATTACHMENT,
    IMPORT_TRANSACTION,
    LIST_ALL_TRANSACTIONS,
    LIST_TRANSACTION_CATEGORIES,
    REMOVE_DELETED_TRANSACTION,
    SAVE_TRANSACTION,
    SAVE_TRANSACTION_CATEGORY,
    SET_PROCESSING,
    STORE_ALL_TRANSACTION_CATEGORIES,
    STORE_ALL_TRANSACTIONS,
    STORE_FOUND_INVOICE,
    STORE_SUPPLIER_REFERENCES,
    STORE_TRANSACTION_ATTACHMENT,
    STORE_UPDATED_TRANSACTION,
    STORE_UPDATED_TAXINFO,
    STORE_TAXTYPE_WITH_STATE_PROVINCE,
    SAVE_AND_NAVIGATE_TO_CHILD_TRANSACTION,
    STORE_UPDATED_TRANSACTIONS,
    STORE_EDITING_TRANSACTION,
    NAVIGATE_TO_PARENT_TRANSACTION,
    SAVE_CHEQUE,
    STORE_UPDATED_CHEQUES,
    STORE_ALL_CHEQUES,
    LIST_ALL_CHEQUES,
    DELETE_CHEQUES,
    LIST_SHIPPING_SUMMARY,
    STORE_SHIPPING_SUMMARY,
    STORE_SHIPPING_SUMMARY_SETTINGS,
    LIST_SHIPPING_SETTINGS,
    SAVE_SHIPPING_SETTINGS,
    UPDATE_SHIPPING_SUMMARY,
    SAVE_STATEMENT_TAXES_TEMPLATE,
    LIST_STATEMENT_TAXES,
    STORE_STATEMENT_TAXES_TEMPLATE,
    STORE_STATEMENT_TAXES_SUMMARY,
    LIST_STATEMENT_TAXES_TEMPLATE,
    IMPORT_CHEQUE,
    SAVE_LEDGER,
    STORE_ALL_LEDGERS,
    LIST_ALL_LEDGERS,
    LIST_ADD_LEDGERS,
    SAVE_TRANSACTION_LEDGER,
    DELETE_LEDGER_BY_YEAR,
    LEDGER_ENTRIES,
    FETCH_TRANSACTIONS_LEDGER,
    STORE_TRANSACTIONS_LEDGER,
    VALIDATE_CHEQUE_NUMBER,
    START_LOADING,
    STOP_LOADING, LIST_PURCHASE_SUMMARY, STORE_PURCHASE_SUMMARY
} from "../../constants/accounting";

export const listAllTransactions = (data) => ({
    type: LIST_ALL_TRANSACTIONS,
    data
})


export const listAllLedgers = (data) => ({
    type: LIST_ALL_LEDGERS,
    data
})

export const fetchTransactionsLedger = (data,callback) => ({
    type: FETCH_TRANSACTIONS_LEDGER,
    data,
    callback
})

export const ledgerEntries = (data,callback) => ({
    type: LEDGER_ENTRIES,
    data,
    callback
})

export const deleteLedgerByYear = (data,callback) => ({
    type: DELETE_LEDGER_BY_YEAR,
    data,
    callback
})

export const listAllCheques = (data) => ({
    type: LIST_ALL_CHEQUES,
    data
})

export const validateChequeNumber = (data,callback) => ({
    type: VALIDATE_CHEQUE_NUMBER,
    data,
    callback
})

export const listShippingSummary = (data,callback) => ({
    type: LIST_SHIPPING_SUMMARY,
    data,
    callback
})

export const saveShippingSummarySettings = (data,callback) => ({
    type: SAVE_SHIPPING_SETTINGS,
    data,
    callback
})

export const saveStatementTaxesTemplate = (data,callback) => ({
    type: SAVE_STATEMENT_TAXES_TEMPLATE,
    data,
    callback
})

export const listStatementTaxes = (data,callback) => ({
    type: LIST_STATEMENT_TAXES,
    data,
    callback
})
export const listStatementTaxesTemplate = () => ({
    type: LIST_STATEMENT_TAXES_TEMPLATE
})
export const listShippingSettings = () => ({
    type: LIST_SHIPPING_SETTINGS
})

export const storeAllTransactions = (transactions) => ({
    type: STORE_ALL_TRANSACTIONS,
    transactions
})

export const storeAllLedgers = (ledgers) => ({
    type: STORE_ALL_LEDGERS,
    ledgers
})

export const storeTransactionsLedger = (transactions) => ({
    type: STORE_TRANSACTIONS_LEDGER,
    transactions
})

export const storeAllCheques = (cheques) => ({
    type: STORE_ALL_CHEQUES,
    cheques
})

export const storeShippingSummarySettings = (rates) => ({
    type: STORE_SHIPPING_SUMMARY_SETTINGS,
    rates
})

export const storeStatementTaxesTemplate = (templates) => ({
    type: STORE_STATEMENT_TAXES_TEMPLATE,
    templates
})

export const storeAllShippingSummary = (summary) => ({
    type: STORE_SHIPPING_SUMMARY,
    summary
})

export const storeAllStatementTaxesSummary = (taxSummary) => ({
    type: STORE_STATEMENT_TAXES_SUMMARY,
    taxSummary
})

export const getInvoiceByInvoiceNumber = (invoice) => ({
    type: GET_INVOICE_BY_INVOICE_NUMBER,
    invoice
})

export const storeFoundInvoice = (invoice) => ({
    type: STORE_FOUND_INVOICE,
    invoice
})

export const saveTransaction = (data, callback) => ({
    type: SAVE_TRANSACTION,
    data,
    callback
})

export const saveLedger = (data, callback) => ({
    type: SAVE_LEDGER,
    data,
    callback
})

export const saveTransactionsLedger = (data, callback) => ({
    type: SAVE_TRANSACTION_LEDGER,
    data,
    callback
})

export const saveCheque = (data, callback) => ({
    type: SAVE_CHEQUE,
    data,
    callback
})

export const importCheque = (data, callback) => ({
    type: IMPORT_CHEQUE,
    data,
    callback
})

export const deleteTransactions = (data, callback) => ({
    type: DELETE_TRANSACTIONS,
    data,
    callback
})
export const deleteCheques = (data, callback) => ({
    type: DELETE_CHEQUES,
    data,
    callback
})

export const assignCategory = (category,selectedEntries, callback) => ({
    type: ASSIGN_CATEGORY,
    category,
    selectedEntries,
    callback
})

export const AddTaxInfo = (taxInfo, selectedEntries) => ({
    type: ADD_TAX_INFO,
    taxInfo,
    selectedEntries,
})

export const applyTransactionsRules = (transactionIds, rules) => ({
    type: APPLY_TRANSACTIONS_RULES,
    transactionIds,
    rules,
})

export const storeUpdatedTransaction = (updatedTransaction) => ({
    type: STORE_UPDATED_TRANSACTION,
    updatedTransaction
})

export const storeUpdatedTransactions = (updatedTransactions) => ({
    type: STORE_UPDATED_TRANSACTIONS,
    updatedTransactions
})


export const storeUpdatedCheque = (updatedCheques) => ({
    type: STORE_UPDATED_CHEQUES,
    updatedCheques
})

export const storeEditingTransaction = (editingTransaction) => ({
    type: STORE_EDITING_TRANSACTION,
    editingTransaction
})

export const storeUpdatedTaxInfo = (updatedTaxInfo) => ({
    type: STORE_UPDATED_TAXINFO,
    updatedTaxInfo,
})

export const storetaxTypeWithStateProvince = (taxTypewithStateProvince) => ({
    type: STORE_TAXTYPE_WITH_STATE_PROVINCE,
    taxTypewithStateProvince
})


export const removeDeletedTransaction = (transactionId) => ({
    type: REMOVE_DELETED_TRANSACTION,
    transactionId
})

export const importTransaction = (data, callback) => ({
    type: IMPORT_TRANSACTION,
    data,
    callback
})

export const setProcessing = (processing) => ({
    type: SET_PROCESSING,
    processing
})

export const getTransactionAttachment = (data, callback) => ({
    type: GET_TRANSACTION_ATTACHMENT,
    data,
    callback
})

export const storeTransactionAttachment = (attachmentFile) => ({
    type: STORE_TRANSACTION_ATTACHMENT,
    attachmentFile
})

export const deleteTransactionAttachment = (data, callback) => ({
    type: DELETE_TRANSACTION_ATTACHMENT,
    data,
    callback
})

export const getSupplierReferences = (data) => ({
    type: GET_SUPPLIER_REFERENCES,
    data
})

export const getTransactionTaxAmount = (data, transaction, index) => ({
    type: GET_TRANSACTION_TAX_AMOUNT,
    data,
    transaction,
    index,
})

export const getTaxTypeWithStateProvince = () => ({
    type: GET_TAXTYPE_WITH_STATE_PROVINCE,
})

export const storeSupplierReferences = (references) => ({
    type: STORE_SUPPLIER_REFERENCES,
    references
})

export const listTransactionCategories = () => ({
    type: LIST_TRANSACTION_CATEGORIES,
})

export const saveTransactionCategory = (data) => ({
    type: SAVE_TRANSACTION_CATEGORY,
    data
})

export const deleteTransactionCategory = (data) => ({
    type: DELETE_TRANSACTION_CATEGORY,
    data
})

export const storeAllTransactionCategories = (data) => ({
    type: STORE_ALL_TRANSACTION_CATEGORIES,
    data
})

export const saveAndNavigateToChildTransaction = (index, transactionEdited) => ({
    type: SAVE_AND_NAVIGATE_TO_CHILD_TRANSACTION,
    index,
    transactionEdited
})

export const navigateToParentTransaction = (parentTransactionId) => ({
    type: NAVIGATE_TO_PARENT_TRANSACTION,
    parentTransactionId
})

export const startLoading = () => ({ type: START_LOADING });
export const stopLoading = () => ({ type: STOP_LOADING });

export const listPurchaseSummary = (data,callback) => ({
    type: LIST_PURCHASE_SUMMARY,
    data,
    callback
})
export const storeAllPurchaseSummary = (purchaseSummary) => ({
    type: STORE_PURCHASE_SUMMARY,
    purchaseSummary
})