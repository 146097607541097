export const LIST_TICKET_ISSUES = 'LIST_TICKET_ISSUES';
export const STORE_TICKET_ISSUES = 'STORE_TICKET_ISSUES';
export const LIST_STATUS = 'LIST_STATUS';
export const STORE_STATUS = 'STORE_STATUS';

export const STORE_TICKET_ACTIONS = 'STORE_TICKET_ACTIONS';
export const STORE_TICKET_ACTION_TASKS = 'STORE_TICKET_ACTION_TASKS';

export const LIST_TICKETS = 'LIST_TICKETS';
export const STORE_TICKETS = 'STORE_TICKETS';

export const CREATE_TICKET = 'CREATE_TICKET';
export const UPDATE_TICKET = 'UPDATE_TICKET';
export const DELETE_TICKET = 'DELETE_TICKET';

export const STORE_UPDATED_TICKET = 'STORE_UPDATED_TICKET';



export const LIST_ISSUE_SPECIFIC_ACTIONS = 'LIST_ISSUE_SPECIFIC_ACTIONS';
export const LIST_ISSUE_SPECIFIC_TASKS = 'LIST_ISSUE_SPECIFIC_TASKS';

export const PREVIEW_TICKET_FILE = 'PREVIEW_TICKET_FILE';
export const STORE_UPDATED_TICKET_WITH_PREVIEW = 'STORE_UPDATED_TICKET_WITH_PREVIEW';

export const FETCH_ORDER_INFO = 'FETCH_ORDER_INFO';
export const STORE_ORDER_INFO_FETCH = 'STORE_ORDER_INFO_FETCH';