import React from "react";

import {DELETE_RELATED_PRODUCT, DRAG, PRODUCT_ON_AMAZON} from "../constants";

import {
    Box,
    Tooltip,
    IconButton, TextField, Select, MenuItem
} from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import DehazeIcon from '@material-ui/icons/Dehaze';
import PropTypes from "prop-types";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
class EditRelatedProductCard extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            name: this.props.relatedProduct.name ? this.props.relatedProduct.name : '',

        }
    }
    componentDidMount() {
        this.setState({
            ...this.props.relatedProduct
        })
    }

    handleNameChange = (event) => {
        const name = event.target.value;
        this.setState(
            {
                name,
            },
            () => {
                this.props.updateRelatedProductWithParent({ ...this.state });
            }
        );
    }

    handleOpenProductPage = () => {
        const baseURL = `https://www.${this.props.relatedProduct.marketplaces[0]}/dp/${this.props.relatedProduct.asin}`;
        window.open(baseURL, '_blank'); // Opens the link in a new tab
    }

    render() {
        return (
            <Box
                className='relatedProductTextField'
                display='flex'
                flexDirection='row'
                justifyContent='left'
                alignItems='center'
            >
                <div>
                    <TextField
                        className='actionTextFieldInput'
                        label="Asin"
                        variant='outlined'
                        InputLabelProps={{ shrink: true }}
                        value={this.props.relatedProduct.asin}
                        disabled={true}
                    />
                    <TextField
                        className='actionTextFieldInput'
                        label="Product Name"
                        variant='outlined'
                        InputLabelProps={{ shrink: true }}
                        value={this.state.name}
                        onChange={this.handleNameChange}
                    />
                </div>

                <div className='actionTextEmptyDiv'>
                    <Tooltip title={PRODUCT_ON_AMAZON}>
                        <IconButton
                            aria-label={PRODUCT_ON_AMAZON}
                            onClick={this.handleOpenProductPage}
                        >
                            <OpenInNewIcon variant='outlined' fontSize='small' />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={DELETE_RELATED_PRODUCT}>
                        <IconButton
                            aria-label={DELETE_RELATED_PRODUCT}
                            onClick={() => this.props.deleteRelatedProduct(this.props.relatedProduct.relatedProductId)}
                        >
                            <DeleteOutlineIcon variant='outlined' fontSize='small' />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={DRAG}>
                        <IconButton
                            aria-label='drag'
                            disabled={this.props.sortRelatedProductByRank}
                        >
                            <DehazeIcon variant='outlined' fontSize='small' />
                        </IconButton>
                    </Tooltip>
                </div>
            </Box>
        );
    }

}

EditRelatedProductCard.propTypes = {
    relatedProduct: PropTypes.array,
    updateRelatedProductWithParent: PropTypes.func,
    deleteRelatedProduct: PropTypes.func,
};

EditRelatedProductCard.defaultProps = {
    relatedProduct: {},
    updateRelatedProductWithParent: ()=>{},
    deleteRelatedProduct: ()=>{},
}

export default EditRelatedProductCard;