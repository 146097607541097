import {
    createSelector
} from 'reselect'
import {loadingShipmentRequest} from "../../actions/orders";

export const ordersSelector = createSelector(state => state && state.orders && state.orders.rows, ordersSelector => ordersSelector);
export const printedCountSelector = createSelector(state => state && state.orders && state.orders.printedCount, printedCountSelector => printedCountSelector);
export const unshippedSelector = createSelector(state => state && state.orders && state.orders.unshipped, unshippedSelector => unshippedSelector);
export const shippedSelector = createSelector(state => state && state.orders && state.orders.shipped, shippedSelector => shippedSelector);
export const cancelledSelector = createSelector(state => state && state.orders && state.orders.cancelled, cancelledSelector => cancelledSelector);
export const returnsSelector = createSelector(state => state && state.orders && state.orders.returns, returnsSelector => returnsSelector);
export const clonedSelector = createSelector(state => state && state.orders && state.orders.cloned, clonedSelector => clonedSelector);
export const loadingShipmentRequestSelector = createSelector(state => state && state.orders && state.orders.loadingShipmentRequest, loadingShipmentRequestSelector => loadingShipmentRequestSelector);
export const ratesSelector = createSelector(state => state && state.orders && state.orders.rates, ratesSelector => ratesSelector);
export const rateLoadingSelector = createSelector(state => state && state.orders && state.orders.rateLoading, rateLoadingSelector => rateLoadingSelector);
export const linkedShipmentSelector = createSelector(state => state && state.orders && state.orders.linkedShipmentId, linkedShipmentSelector => linkedShipmentSelector);
export const buyShippingServicesSelector = createSelector(state => state && state.orders && state.orders.buyShippingServices, buyShippingServicesSelector => buyShippingServicesSelector);
export const packageToListMapSelector = createSelector(state => state && state.orders && state.orders.packageToListMap, packageToListMapSelector => packageToListMapSelector);

export const getSuggestedBoxTypesSelector = createSelector(state => state && state.orders && state.orders.suggestedBoxTypes, getSuggestedBoxTypesSelector => getSuggestedBoxTypesSelector)

export const testZplSelector = createSelector(state => state && state.orders && state.orders.printedZpl, testZplSelector => testZplSelector);

export const orderIdSelector = createSelector(state => state && state.orders && state.orders.messages, orderIdSelector => orderIdSelector);
export const shipmentsFromLogsSelector = createSelector(state => state && state.orders && state.orders.shipments, shipmentsFromLogsSelector => shipmentsFromLogsSelector );

export const allLogsSelector = createSelector(state => state && state.orders && state.orders.allMessages, allLogsSelector => allLogsSelector);
export const curLogsSelector = createSelector(state => state && state.orders && state.orders.curMessage, curLogsSelector => curLogsSelector);
export const storeSelector = createSelector(state => state && state.orders,storeSelector=>storeSelector );

export const commercialInvoicePDFSelector = createSelector(state => state && state.orders && state.orders.commercialInvoicePDF, commercialInvoicePDFSelector => commercialInvoicePDFSelector);
export const isSampleCommercialInvoiceSelector = createSelector(state => state && state.orders && state.orders.isSampleCommercialInvoice, isSampleCommercialInvoiceSelector => isSampleCommercialInvoiceSelector);

export const shipmentsOpenEditRowSelector = createSelector(state => state && state.orders && state.orders.shipmentsOpenEditRow, shipmentsOpenEditRowSelector => shipmentsOpenEditRowSelector)

export const shipmentsSummaryOrderSelector = createSelector(state => state && state.orders.orderDirectionShipmentsSummary, shipmentsSummaryOrderSelector => shipmentsSummaryOrderSelector);
export const shipmentsSummaryPropertySelector = createSelector(state => state && state.orders.valueToOrderByShipmentsSummary, shipmentsSummaryPropertySelector => shipmentsSummaryPropertySelector);

export const shipmentsSmartSearchRowsSelector = createSelector(state => state && state.orders && state.orders.shipmentsSmartSearchRows, shipmentsSmartSearchRowsSelector => shipmentsSmartSearchRowsSelector)

