import React from "react";
import {
    Button,
    IconButton,
    MenuItem,
    Select, Table, TableBody, TableCell, TableContainer, TableRow,
    TextField,
} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import './ManageEmailTemplates.css'
// styling
import withShipment from "../../../withShipment";

import {
    emailTemplatesSelector,
    emailTemplateTypeSelector,
    packagesSelector,
    productsSelector,
    suppliersSelector
} from "../../../redux/selectors/settings";
import {
    createSupplier,
    listProducts,
    listSuppliers,
    updateSupplier,
    listPackages,
    getEmailTemplates,
    emailTemplateChange,
    createNewEmailTemplate,
    emailTemplateDelete,
    getEmailTemplateTypes,
    emailTemplateTypeChange,
    createNewEmailTemplateType,
    emailTemplateTypeDelete,
    fetchSendgridTemplates
} from "../../../redux/actions/settings";
import SearchableTable from "../../global/Search/SearchableTable";
import CustomToolbar from "../../global/CustomToolbar";
import {
    BACK_TO_MENU,
    CREATE_SUPPLIER_TITLE,
} from "../ManageSuppliers/constants";
import {
    EMAIL_TOOLBAR_TITLE,
    EMAIL_TABLE_HEAD_CELLS,
    EMAIL_TEMPLATE_TYPE_PURCHASE_ORDER,
    CREATE_TEMPLATE_TITLE,
} from "./constants"
import GlobalTableHead from "../../global/Table/GlobalTableHead";
import EditIcon from '@material-ui/icons/Edit';
import {CANCEL, SAVE} from "../../global/constants";
import {Autocomplete} from "@material-ui/lab";


class ManageEmailTemplates extends SearchableTable {

    constructor(props) {
        super(props);
        this.state = {
            isEditing: false,
            isCreating: false,

            initTemplateName: '',

            templateText: '',
            predefinedTemplateText: '-',
            templateName: '',
            templateType: '',
            templateSubject: '',
            predefinedTemplateSubject: '-',
            typeType: '',
            typeId: '',

            disableManualTemplateSubject: false,
            isDelete: {},
            deleteEnabled:{},
        }
    }

    componentDidMount() {
        this.props.getEmailTemplates()
        this.props.getEmailTemplateTypes()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    handleEditChange = (row) => {
        this.setState({
            initTemplateName: row.templateName,
            templateName: row.templateName,
            templateType: row.templateType,
            isEditing: true,
        })

        if (row.templateType === EMAIL_TEMPLATE_TYPE_PURCHASE_ORDER) {
            this.setState({
                templateSubject: row.customStrings[0],
                templateText: row.customStrings[1],
                predefinedTemplateSubject: row.predefinedStrings[0],
                predefinedTemplateText: row.predefinedStrings[1],
            })
        }
    }

    handleTemplateDelete = async (row) => {
        await this.props.emailTemplateDelete({name: row.templateName})
        this.state.isDelete = {}
    }

    handlePredefinedTemplateSubjectChange = (e) => {
        this.setState({
            predefinedTemplateSubject: e.target.value,
            disableManualTemplateSubject: e.target.value !== '-',
            templateSubject: e.target.value !== '-'? '': this.state.templateSubject
        },
            () => {})
    }

    getEmailTemplateType = (typeName) => {
        return this.props.emailTemplateTypes.find((it) => it.type = typeName)
    }

    getPredefinedValues = (templateType, contentField) => {

        let index = 0
        let it
        for (index in templateType.contentNames) {

            if (templateType.contentNames[index] === contentField)
                return templateType.predefinedValues[index]

        }

        return ['-']
    }

    saveEnabled_createTemplate = (name, type, subject = null, content = null) => {

        if (name === '' || type === '')
            return false
        let result = this.props.emailTemplates.every(template => template.templateName !== name);

        return result
    }

    saveEnabled_editTemplate = (name, type, subject = null, content = null) => {

        if (name === '' || type === '')
            return false

        return true
    }

    fetchSendgridTemplates = () => {
        this.props.fetchSendgridTemplates()
    }

    dynamicChange = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    removeBrackets = (string) => {

        if (string === '')
            return string
        let split1 = string.split('\[')
        let split2

        let result = ''
        let i, j
        for (i in split1) {
            split2 = split1[i].split('\]')
            for (j in split2) {
                result += split2[j]
            }
        }

        return result
    }

    handleDeleteTrue = (index) => {
        let newIsDelete = this.state.isDelete
        newIsDelete[index] = true
        this.setState({ isDelete: newIsDelete })
    }

    handleDeleteFalse = (index) => {
        let newIsDelete = this.state.isDelete
        newIsDelete[index] = false
        this.setState({ isDelete: newIsDelete })
    }

    // will need to be edited when new template types require different content
    getCustomStrings = () => {
        return [this.state.templateSubject, this.state.templateText]
    }

    // will need to be edited when new template types require different content
    getPredefinedStrings = () => {
        return [this.state.predefinedTemplateSubject, this.state.predefinedTemplateText]
    }

    handleSubmit = async (updateOrCreate) => {
        if(updateOrCreate === 1) {
            await this.props.emailTemplateChange({
                initName: this.state.initTemplateName,
                name: this.state.templateName,
                type: this.state.templateType,
                customStrings: this.getCustomStrings(),
                predefinedStrings: this.getPredefinedStrings(),
            })
            this.dynamicChange('isEditing', false)
        } else if(updateOrCreate === -1){
            await this.props.createNewEmailTemplate({
                name: this.state.templateName,
                type: this.state.templateType,
                customStrings: this.getCustomStrings(),
                predefinedStrings: this.getPredefinedStrings(),
            })
            this.dynamicChange('isCreating', false)
        }
    }

    render() {
        return (
            <>
                <CustomToolbar
                    creatingOrder={this.state.isCreating || this.state.isEditing}
                    title={EMAIL_TOOLBAR_TITLE}
                    createTitle={CREATE_TEMPLATE_TITLE}
                    backTitle={BACK_TO_MENU}
                    handleClose={() => {
                        this.dynamicChange('isCreating', false)
                        this.dynamicChange('isEditing', false)
                    }}
                    onClick={() => {
                        this.dynamicChange('isCreating', true)
                        this.dynamicChange('isEditing', false)
                        this.dynamicChange('templateText', '')
                        this.dynamicChange('templateName', '')
                        this.dynamicChange('templateType', '')
                        this.dynamicChange('templateSubject', '')
                        this.dynamicChange('predefinedTemplateText', '-')
                        this.dynamicChange('predefinedTemplateSubject', '-')
                    }}
                />

                    {!this.state.isEditing && !this.state.isCreating ?
                        <>

                        <div className={"empty-line"} />

                            <TableContainer>
                            <Table size='medium'>
                                <GlobalTableHead
                                    isCreatePurchaseOrderTable={false}
                                    isOrderTable={false}
                                    numSelected={0}
                                    headCells={EMAIL_TABLE_HEAD_CELLS}
                                />
                                <TableBody>
                                    {this.props.emailTemplates && this.props.emailTemplates.map((row, index) => {
                                        return (
                                            <>
                                                <TableRow>
                                                    <TableCell>
                                                        <IconButton onClick={() => this.handleEditChange(row)}>
                                                            <EditIcon/>
                                                        </IconButton>
                                                    </TableCell>
                                                    <TableCell/>
                                                    <TableCell>
                                                        <div className='tag-inputBox'>
                                                            <div style={{paddingTop: '10px'}}>
                                                                {row.templateName}
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div className='tag-inputBox'>
                                                            <div style={{paddingTop: '10px'}}>
                                                                {row.templateType}
                                                            </div>
                                                        </div>
                                                    </TableCell>

                                                    <TableCell
                                                        className={"deleteBox"}
                                                    >
                                                        {this.state.isDelete[index] ?
                                                            <div align="left">

                                                                <Button
                                                                    onClick={() => this.handleDeleteFalse(index)}
                                                                >
                                                                    Cancel
                                                                </Button>
                                                                <Button
                                                                    variant="contained"
                                                                    startIcon={<DeleteIcon />}
                                                                    onClick={() => {this.handleTemplateDelete(row)}}
                                                                    disabled={!this.state.isDelete[index]}
                                                                >
                                                                    Delete
                                                                </Button>
                                                            </div>
                                                            :
                                                            <div>
                                                                <IconButton onClick={() => this.handleDeleteTrue(index)}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </div>


                                                        }
                                                    </TableCell>

                                                </TableRow>
                                            </>
                                        )
                                    })}
                                </TableBody>
                            </Table>

                        </TableContainer>
                        </>
                        : null}



                    {this.state.isEditing && !this.state.isCreating ?
                        <>
                            <div style={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>

                                <TextField
                                    value={this.state.templateType}
                                    className={"typeBox"}
                                    onChange={(e) => this.dynamicChange('templateType', e.target.value)}
                                    name="carrier"
                                    variant="outlined"
                                    label="Type"
                                    disabled="true"
                                />

                                <TextField
                                    value={this.state.templateName}
                                    className={"nameBox"}
                                    onChange={(e) => this.dynamicChange('templateName', e.target.value)}
                                    name="carrier"
                                    variant="outlined"
                                    label="Name"
                                    disabled="true"
                                />

                                {this.state.templateType === EMAIL_TEMPLATE_TYPE_PURCHASE_ORDER ? <div>

                                    <div className={"subjectContainer"} style={{display: "flex", flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                                        <TextField
                                            value={this.state.templateSubject}
                                            className={"subjectBox"}
                                            onChange={(e) => this.dynamicChange('templateSubject', e.target.value)}
                                            name="carrier"
                                            variant="outlined"
                                            label="Subject"
                                            disabled={this.state.disableManualTemplateSubject}
                                        />
                                        <div className={"ORdiv"}>
                                            OR
                                        </div>
                                        <Select
                                            variant="outlined"
                                            name="predefinedValue"
                                            className={"subjectDropdown"}
                                            onChange={this.handlePredefinedTemplateSubjectChange}
                                            value={this.state.predefinedTemplateSubject}
                                        >
                                            {this.getPredefinedValues(this.getEmailTemplateType(this.state.templateType), "subject").map((value, index) => (
                                                <MenuItem value={value} key={index}>{value === '-'? 'Select a predefined value': this.removeBrackets(value)}</MenuItem>
                                            ))
                                            }
                                        </Select>
                                    </div>

                                    <TextField
                                        value={this.state.templateText}
                                        className={"bodyBox"}
                                        onChange={(e) => this.dynamicChange('templateText', e.target.value)}
                                        name="carrier"
                                        variant="outlined"
                                        multiline
                                        label="Content"
                                    />

                                </div> : null}


                            </div>
                            <div style={{display: "flex", flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: '25px'}}>
                                <Button
                                    variant='contained'
                                    onClick={() => this.dynamicChange('isEditing', false)}
                                >
                                    {CANCEL}
                                </Button>
                                <div className='save-spacing' />
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={() => this.handleSubmit(1)}
                                    disabled={!this.saveEnabled_editTemplate(this.state.templateName, this.state.templateType, this.state.templateSubject, this.state.templateText)}
                                >
                                    {SAVE}
                                </Button>
                            </div>
                        </>
                    : null}



                    {this.state.isCreating && !this.state.isEditing ?
                        <>
                            <div style={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>

                                <Autocomplete
                                    options={this.props.emailTemplateTypes}
                                    getOptionLabel={option => option.type}
                                    className={"typeBox"}
                                    onChange={(event, value) => {
                                        if(value !== null){
                                            this.dynamicChange('templateType', value.type)
                                        } else{
                                            this.dynamicChange('templateType', '')
                                        }
                                    }}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label="Type"
                                            InputLabelProps={{ shrink: true }}
                                            variant="outlined"
                                            margin="normal"
                                        />
                                    )}
                                />

                                <TextField
                                    value={this.state.templateName}
                                    className={"nameBox"}
                                    onChange={(e) => this.dynamicChange('templateName', e.target.value)}
                                    name="carrier"
                                    variant="outlined"
                                    label="Name"
                                />

                                {this.state.templateType === EMAIL_TEMPLATE_TYPE_PURCHASE_ORDER ? <div>

                                    <div className={"subjectContainer"} style={{display: "flex", flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                                        <TextField
                                            value={this.state.templateSubject}
                                            className={"subjectBox"}
                                            onChange={(e) => this.dynamicChange('templateSubject', e.target.value)}
                                            name="carrier"
                                            variant="outlined"
                                            label="Subject"
                                            disabled={this.state.disableManualTemplateSubject}
                                        />
                                        <div className={"ORdiv"}>
                                            OR
                                        </div>
                                        <Select
                                            variant="outlined"
                                            name="predefinedValue"
                                            className={"subjectDropdown"}
                                            onChange={this.handlePredefinedTemplateSubjectChange}
                                            value={this.state.predefinedTemplateSubject}
                                        >
                                            {this.getPredefinedValues(this.getEmailTemplateType(this.state.templateType), "subject").map((value, index) => (
                                                <MenuItem value={value} key={index}>{value === '-'? 'Select a predefined value': this.removeBrackets(value)}</MenuItem>
                                            ))
                                            }
                                        </Select>
                                    </div>

                                <TextField
                                    value={this.state.templateText}
                                    className={"bodyBox"}
                                    onChange={(e) => this.dynamicChange('templateText', e.target.value)}
                                    name="carrier"
                                    variant="outlined"
                                    multiline
                                    label="Content"
                                />

                                </div> : null}

                            </div>
                            <div style={{display: "flex", flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: '25px'}}>
                                <Button
                                    variant='contained'
                                    onClick={() => {
                                        this.dynamicChange('isCreating', false)
                                    }}
                                >
                                    {CANCEL}
                                </Button>
                                <div style={{marginLeft: '5px', marginRight: '5px'}}/>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={() => {
                                        this.handleSubmit(-1)}
                                    }
                                    disabled={!this.saveEnabled_createTemplate(this.state.templateName, this.state.templateType, this.state.templateSubject, this.state.templateText)}
                                >
                                    {SAVE}
                                </Button>
                            </div>
                        </> : null}

            </>
        );
    }
}


const mapStateToProps = (state) => ({
    products: productsSelector(state),
    suppliers: suppliersSelector(state),
    packages: packagesSelector(state),
    emailTemplates: emailTemplatesSelector(state),
    emailTemplateTypes: emailTemplateTypeSelector(state)
})

const actionCreators = {
    listProducts,
    listSuppliers,
    createSupplier,
    updateSupplier,
    listPackages,
    getEmailTemplates,
    emailTemplateChange,
    createNewEmailTemplate,
    emailTemplateDelete,
    getEmailTemplateTypes,
    emailTemplateTypeChange,
    createNewEmailTemplateType,
    emailTemplateTypeDelete,
    fetchSendgridTemplates
}


export default withShipment({
    mapStateToProps,
    actionCreators
}, ManageEmailTemplates);