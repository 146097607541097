

// a version of the green bar across the top of a frontend page

// current functionality:
// - can set page name
// - can add icons to be clicked

/* example use:

 <TopToolbar
    pageName={"Archive Labels"}
    menuItems={[
        {title: "title", icon: <KeyboardBackspaceIcon/>, onClick: () => {console.log("clicked first")} },
        {title: "title", icon: <KeyboardBackspaceIcon/>, onClick: () => {console.log("clicked second")}, disabled: true }
    ]}
  />

*/

// ----

import React from "react";
import withShipment from "../../../../withShipment";
import PropTypes from "prop-types";
import './TopToolbar.css';

import {IconButton, Toolbar, Tooltip, Typography} from "@material-ui/core";
import {Popper} from "@mui/material";

class TopToolbar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activePopper: null, // Change to track the identifier of the active popper
            anchorEl: null
        };
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevProps.menuItems !== this.props.menuItems) {
            this.setState({ activePopper: null });
        }
    }

    handleClick = (event, menuItem, index) => {
        // Use index or a unique identifier from menuItem as the key
        if (menuItem.popper) {
            this.setState({
                activePopper: this.state.activePopper === index ? null : index,
                anchorEl: event.currentTarget, // Update this line
            });
        } else {
            menuItem.onClick();
        }
    };

    handleClosePopper = () => {
        this.setState({ activePopper: null });
    };

    displayMenuItems = (menuItems) => {
        if (!menuItems) return null;

        return menuItems.map((menuItem, index) => {
            const isOpen = this.state.activePopper === index; // Check if this popper is active
            if (menuItem.isNotIcon) {
                return <div className="menu-item-title">{menuItem.title}</div>
            }
            return (
                <>
                    <Tooltip title={menuItem.title}>
                        <IconButton
                            aria-label={menuItem.title}
                            onClick={(event) => this.handleClick(event, menuItem, index)} // Pass index or unique ID
                            disabled={menuItem.disabled}
                        >
                            {menuItem.icon}
                        </IconButton>
                    </Tooltip>
                    {menuItem.popper && (
                        <Popper open={isOpen} anchorEl={isOpen ? this.state.anchorEl : null} placement={"bottom-start"} style={{ zIndex: "100" }}>
                            {menuItem.popper && React.cloneElement(menuItem.popper, {handleClosePopper: this.handleClosePopper})}
                        </Popper>
                    )}
                </>
            );
        });
    };

    render() {
        return (
            <React.Fragment>

                <div className="topToolbar">
                    <Toolbar>
                        {this.displayMenuItems(this.props.menuItems)}
                        <Typography className="titleTypography" variant="h6" id="tableTitle">
                            {this.props.pageName}
                        </Typography>
                    </Toolbar>
                </div>

            </React.Fragment>
        )
    }
}

TopToolbar.defaultProps = {
    pageName: 'page name'
}

TopToolbar.propTypes = {
    pageName: PropTypes.string
}

const mapStateToProps = (state) => ({
})

const actionCreators = {
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, TopToolbar);

