import {
    createSelector
} from 'reselect'

export const orderSelector = createSelector(state => state && state.admin && state.admin.orders, orderSelector => orderSelector);

export const orderResponseSelector = createSelector(state => state && state.admin && state.admin.orderResponse, orderResponseSelector => orderResponseSelector);

export const marketplaceCompanySelector = createSelector(state => state && state.admin && state.admin.marketplaceCompanies, marketplaceCompanySelector => marketplaceCompanySelector);

export const marketplaceNamesByCompanySelector = createSelector(state => state && state.admin && state.admin.marketplaceNamesByCompany, marketplaceNamesByCompanySelector => marketplaceNamesByCompanySelector);