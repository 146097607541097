import React from "react";
import {Button, Paper, ClickAwayListener, TextField} from "@material-ui/core";
import "./AddRelatedProductPopper.css"
import withShipment from "../../../withShipment";
import Autocomplete from "@material-ui/lab/Autocomplete";

class EditRelatedProductPopper extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            asin: "",
            productName: "",
            relatedProductId: ''
        }
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    handleClose = () => {
        this.props.handleClosePopper()
    }

    handleSave = () => {
        this.props.handleEditRelatedProduct({
            asin: this.state.asin,
            productName: this.state.productName,
            relatedProductId: this.state.relatedProductId
        })
        this.props.handleClosePopper()
    }
    render() {
        return (
            <>
                <ClickAwayListener onClickAway={this.handleClose}>
                    <Paper className="add-related-product-popper-container">
                        <div className="add-related-product-popper-item">
                            <Autocomplete
                                options={(this.props.row?.relatedProducts ?? []).map(item => ({
                                    label: item.asin,
                                    asin: item.asin,
                                    relatedProductId: item.relatedProductId
                                }))}
                                getOptionLabel={(option) => option.label}
                                value={{ label: this.state.asin, asin: this.state.asin, relatedProductId: this.state.relatedProductId }}
                                onChange={(event, value) => {
                                    this.handleChange("asin", value ? value.asin : '');
                                    this.handleChange("relatedProductId", value ? value.relatedProductId : '');
                                }}
                                style={{ marginTop: '7px' }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="asin"
                                        label="ASIN"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                            <TextField
                                label="Product Name"
                                variant="outlined"
                                name="productName"
                                value={this.state.productName || ''}
                                onChange={(event) => this.handleChange("productName", event.target.value)}
                                style={{ marginTop: '7px' }}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                            />
                        </div>
                        <div className="add-related-product-popper-buttons">
                            <Button variant='contained' onClick={this.handleClose} style={{width: '100px'}}>Close</Button>
                            <Button
                                variant='contained'
                                onClick={this.handleSave}
                                color="primary"
                                style={{width: '100px'}}
                                disabled={this.state.asin === undefined || this.state.asin === '' || this.state.productName === undefined || this.state.asin === null}
                            >Save</Button>
                        </div>
                    </Paper>
                </ClickAwayListener>
            </>
        )
    }
}

EditRelatedProductPopper.propTypes = {
}

EditRelatedProductPopper.defaultProps = {
}
const mapStateToProps = (state) => ({
})

export default withShipment({
    mapStateToProps
}, EditRelatedProductPopper);