import React, {useState, useEffect} from 'react';

// custom components
import SelectMarketplace from "./SelectMarketplace";
import MarketplaceForm from "./MarketplaceForm";

// material components
import {
    Stepper,
    Step,
    StepButton,
} from "@material-ui/core";

// constants
import {
    MARKETPLACE_STEPPER_LABELS,
    STEP_CASE,
} from "./constants";
import PropTypes from "prop-types";

function MarketplaceStepper(props) {
    const [state, setState] = useState({
        parentState: {},
        isStepDisabled: true
    })

    useEffect(() => {
        setState({
            ...state,
            parentState: {...props.parentState}
        })
    }, [props.parentState])

    const getStepContent = (activeStep) => {
        switch (activeStep) {
            case STEP_CASE.SELECT_MARKETPLACE:
                return (
                    <SelectMarketplace
                        handleChange={props.handleChange}
                        handleAutocompleteChange={props.handleAutocompleteChange}
                        handleCheckboxChange={props.handleCheckboxChange}
                        parentState={props.parentState}
                        errors={props.parentState.errors}
                        isEdit={props.isEdit}
                    />
                )
            case STEP_CASE.MARKETPLACE_INFORMATION:
                return (
                    <MarketplaceForm
                        parentState={props.parentState}
                        handleChange={props.handleChange}
                        errors={props.parentState.errors}
                        isEdit={props.isEdit}
                        openEbay={props.openEbay}
                    />
                )
        }
    }

    return(
        <>
            <Stepper activeStep={props.activeStep} alternativeLabel>
                {MARKETPLACE_STEPPER_LABELS.map((step, index) => (
                    <Step key={step}>
                        <StepButton>{step}</StepButton>
                    </Step>
                ))}
            </Stepper>
            <div>
                {getStepContent(props.activeStep)}
            </div>
        </>
    )
}

MarketplaceStepper.propTypes = {
    handleChange: PropTypes.func,
    handleAutocompleteChange: PropTypes.func,
    handleCheckboxChange: PropTypes.func,
    parentState: PropTypes.object,
    handleClose: PropTypes.func,
    activeStep: PropTypes.number,
    isEdit: PropTypes.bool,
    openEbay: PropTypes.func,
}

MarketplaceStepper.defaultProps = {
    handleChange: ()=>{},
    handleAutocompleteChange: ()=>{},
    handleCheckboxChange: ()=>{},
    parentState: {},
    handleClose: ()=>{},
    activeStep: 0,
    isEdit: false,
    openEbay: ()=>{}
}

export default MarketplaceStepper;