import React from "react";
import {
    Button, Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl, FormHelperText, IconButton,
    MenuItem,
    Select, TableBody, TablePagination,
    TextField, Tooltip
} from "@material-ui/core";

// material components
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

// constants

// styling
import PropTypes from "prop-types";
import withShipment from "../../../withShipment";
import CustomToolbar from "../../global/CustomToolbar";
import {
    MANAGE_SUPPLIERS_HEAD_CELLS,
    SUPPLIERS_TOOLBAR_TITLE,
    DEFAULT_ROWS_PER_PAGE,
    DEFAULT_SORT_BY_HEAD_CELL,
    ROWS_PER_PAGE_LABEL, CREATE_SUPPLIER_TITLE, BACK_TO_MENU
} from "./constants";
import ManageSuppliersStepper from "./ManageSuppliersStepper";
import Paper from "@material-ui/core/Paper";
import {
    availableLocationsSelector,
    emailTemplatesSelector,
    packagesSelector,
    productsSelector,
    suppliersSelector
} from "../../../redux/selectors/settings";
import {
    createSupplier,
    listProducts,
    listSuppliers,
    updateSupplier,
    listPackages,
    getEmailTemplates,
    processSupplierInvoices, listAvailableLocations,
} from "../../../redux/actions/settings";
import SearchField from "../../global/Search/SearchField";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import GlobalTableHead from "../../global/Table/GlobalTableHead";
import { MANAGE_PRODUCTS_HEAD_CELLS } from "../ManageProducts/constants";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { ASC, DESC } from "../../global/constants";
import SearchableTable from "../../global/Search/SearchableTable";
import CustomTableHeader from "../../global/Table/CustomTableHeader";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import SaveIcon from "@material-ui/icons/Save";
import ErrorFeedbackBar from "../../global/ErrorFeedbackBar";


class ManageSuppliersTable extends SearchableTable {

    constructor(props) {
        super(props);
        this.state = {
            addingSupplier: false,
            editingSupplier: null,
            order: ASC,
            page: 0,
            rowsPerPage: DEFAULT_ROWS_PER_PAGE,
            orderBy: DEFAULT_SORT_BY_HEAD_CELL,
            isSearch: false, // true if search value is not empty, false otherwise
            searchValue: "", // the value in the search field (updates on change)
            searchRows: 0, // number of rows displayed while search criteria is met
            missingError: false, //missing error 
            availableLocations:[]
        }
    }
    onSearchValueChange = (e) => {
        this.setState({
            isSearch: e.target.value.length > 0,
            searchValue: e.target.value,
            page: 0
        });
    }

    componentDidMount() {
        this.props.listAvailableLocations()
        this.props.listSuppliers()
        this.props.listProducts()
        this.props.listPackages()
        this.props.getEmailTemplates()
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (this.props.availableLocations && this.props.availableLocations !== prevProps.availableLocations) {
            const updatedLocations = this.props.availableLocations.map((locationInfo)=>{
                let location = {
                    name: locationInfo.name,
                    shipAddressId: locationInfo.shipAddressId
                }
                return location
            })
            this.setState({
                availableLocations: updatedLocations
            })
        }
    }

    getDisplayed = (rows) => {
        if (!rows)
            return [];
        return [...rows.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)];
    }

    handleClose = () => {
        this.setState({
            addingSupplier: false
        }, () => this.setState({ editingSupplier: null }))
    }

    handleOpenSupplier = (supplier) => {
        this.setState({
            editingSupplier: supplier
        })
    }

    handleRequestSort = (event, property) => {
        const isAsc = this.state.orderBy === property && this.state.order === ASC;
        this.setState({
            order: isAsc ? DESC : ASC,
            orderBy: property,
        });
    };


    handleSave = (addingSupplier, data) => {
        if (data.name === '' || data.phone === '' || data.addressLine1 === '' || data.city === '' || data.state === '' ||
            data.postalCode === '' || data.country === '' || data.email === '' || data.supplierShortcode === '' ||
            data.currencyCode === '') {
            return
        } else {
            if (addingSupplier) {

                this.props.createSupplier(data, () => window.location.reload())
            } else {

                this.props.updateSupplier(data)

                this.setState({
                    addingSupplier: false,
                }, () => {
                    this.setState({
                        editingSupplier: null
                    })
                }
                )
            }
        }
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: event.target.value,
            page: 0,
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
        });
    };

    handleExecute = (data) => {
        this.props.processSupplierInvoices(data)
    }

    render() {
        return (
            <>

                <Paper className='paper'>
                    <ErrorFeedbackBar />
                    {!this.state.editingSupplier ?

                        this.state.addingSupplier ?
                            <ManageSuppliersStepper
                                addingSupplier={true}
                                handleClose={this.handleClose}
                                products={this.props.products}
                                packages={this.props.packages}
                                handleSave={this.handleSave}
                                emailTemplates={this.props.emailTemplates}
                                handleExecute={this.handleExecute}
                                creatingOrder={this.state.addingSupplier || (!!this.state.editingSupplier)}
                                title={SUPPLIERS_TOOLBAR_TITLE}
                                createTitle={SUPPLIERS_TOOLBAR_TITLE}
                                backTitle={SUPPLIERS_TOOLBAR_TITLE}
                                onClick={() => { this.setState({ addingSupplier: true }) }}
                                locations={this.state.availableLocations}
                            />
                            :
                            <>
                                <CustomToolbar
                                    creatingOrder={this.state.addingSupplier || (!!this.state.editingSupplier)}
                                    title={SUPPLIERS_TOOLBAR_TITLE}
                                    createTitle={CREATE_SUPPLIER_TITLE}
                                    backTitle={BACK_TO_MENU}
                                    handleClose={this.handleClose}
                                    onClick={() => { this.setState({ addingSupplier: true }) }}
                                />
                                <CustomTableHeader
                                    searchText={this.state.searchValue}
                                    onSearchValueChange={this.onSearchValueChange}
                                    pagination={
                                        <TablePagination
                                            className="table-pagination"
                                            labelRowsPerPage={ROWS_PER_PAGE_LABEL}
                                            rowsPerPageOptions={[5, 25, 50, 100, {value: this.props.suppliers.length, label: "All"} ]}
                                            count={!this.props.suppliers ? 0 : this.filterBySearch(this.props.suppliers).length}
                                            rowsPerPage={this.state.rowsPerPage}
                                            page={this.state.page}
                                            onChangePage={this.handleChangePage}
                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                        />
                                    }
                                />

                                <TableContainer>
                                    <Table size='medium'>
                                        <GlobalTableHead
                                            isCreatePurchaseOrderTable={false}
                                            isShipmentTable={false}
                                            headCells={MANAGE_SUPPLIERS_HEAD_CELLS}
                                            order={this.state.order}
                                            orderBy={this.state.orderBy}
                                            onRequestSort={this.handleRequestSort}
                                            rowCount={!this.props.suppliers ? 0 : this.props.suppliers.length}
                                        />
                                        <TableBody>
                                            {this.getDisplayed(this.stableSort(this.filterBySearch(this.props.suppliers), this.getComparator(this.state.order, this.state.orderBy))).map((row, index) => {

                                                return (
                                                    <TableRow hover onDoubleClick={() => this.handleOpenSupplier(row)}>
                                                        <TableCell>
                                                            <IconButton onClick={() => this.handleOpenSupplier(row)}>
                                                                <EditIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                        <TableCell className='cellPadding'>
                                                            {row.name}
                                                        </TableCell>
                                                        <TableCell className='cellPadding'>
                                                            {row.addressLine1}
                                                        </TableCell>
                                                        <TableCell className='cellPadding'>
                                                            {row.phone}
                                                        </TableCell>


                                                    </TableRow>
                                                );

                                            })
                                            }
                                        </TableBody>

                                    </Table>


                                </TableContainer>
                            </>


                        :
                        <ManageSuppliersStepper
                            addingSupplier={false}
                            editingSupplier={this.state.editingSupplier}
                            creatingOrder={this.state.addingSupplier || (!!this.state.editingSupplier)}
                            handleClose={this.handleClose}
                            products={this.props.products}
                            packages={this.props.packages}
                            handleSave={this.handleSave}
                            emailTemplates={this.props.emailTemplates}
                            handleExecute={this.handleExecute}
                            title={SUPPLIERS_TOOLBAR_TITLE}
                            createTitle={SUPPLIERS_TOOLBAR_TITLE}
                            backTitle={SUPPLIERS_TOOLBAR_TITLE}
                            onClick={() => { this.setState({ addingSupplier: true }) }}
                            locations={this.state.availableLocations}
                        />
                    }
                </Paper>

            </>

        );
    }


}


const mapStateToProps = (state) => ({
    products: productsSelector(state),
    suppliers: suppliersSelector(state),
    packages: packagesSelector(state),
    emailTemplates: emailTemplatesSelector(state),
    availableLocations: availableLocationsSelector(state),
})

const actionCreators = {
    listProducts,
    listSuppliers,
    createSupplier,
    updateSupplier,
    listPackages,
    getEmailTemplates,
    processSupplierInvoices,
    listAvailableLocations,
}

ManageSuppliersTable.propTypes = {
    availableLocations: PropTypes.array
}

ManageSuppliersTable.defaultProps = {
    availableLocations: [],
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, ManageSuppliersTable);