import React from "react";
import withShipment from "../../withShipment";
import {AUTOMATION_RULES_PAGES} from "./constants";
import AutomationsRulesTable from "./AutomationRulesTable";
import ManageProfitPage from "./AutomationSettings/ProfitThresholdPage";

class AutomationRules extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: AUTOMATION_RULES_PAGES.TABLE
        }
    }

    setCurrentPage = (newPage, pageChangeInfo) => {
        switch (this.state.currentPage){

        }

        switch (newPage) {

        }

        this.setState({
            currentPage: newPage
        })
    }

    displayTable = () => {
        return <AutomationsRulesTable
            setCurrentPage={this.setCurrentPage}
            history={this.props.history}
        />
    }

    displaySettings = () =>{
        return <ManageProfitPage
            setCurrentPage={this.setCurrentPage}
            history={this.props.history}
        />
    }

    displayCurrentPage = (currentPage) => {
        switch (currentPage) {
            case AUTOMATION_RULES_PAGES.TABLE:
                return this.displayTable()
            case AUTOMATION_RULES_PAGES.SETTINGS:
                return this.displaySettings()
            default:
                console.log("Error: current automation rules page doesn't exist")
                return <div>We encountered an error. Please refresh the page.</div>
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.displayCurrentPage(this.state.currentPage)}
            </React.Fragment>
        )
    }

}
AutomationRules.defaultProps = {
}

AutomationRules.propTypes = {
}

const mapStateToProps = (state) => ({
})

const actionCreators = {
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, AutomationRules);