import {LISTINGS_ROWS_PER_PAGE_LABEL} from "../ManageListings/constants";

export const BARCODES_EDIT_ROUTE = 'barcodes-edit'

export const BARCODES_PAGES = {
    TABLE: "table"
}

export const BARCODES_TITLE = "Barcodes"

export const BARCODES_ROWS_PER_PAGE_LABEL = "Barcodes per page"


export const CANCEL_EDIT_ENTRY = "Cancel";
export const APPLY_EDIT_ENTRY = "Apply";
export const EDIT_ENTRY = "Edit";


