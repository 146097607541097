import {Slide, TableBody, TableHead, TablePagination, Typography} from "@material-ui/core";
import React, {useState} from "react";
import {TableSortLabel} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import styles from "../../pages/Dashboard/DashboardFinal/DashboardFinal.module.css";
import "./ShipmentSummary.css";
import "../../pages/Dashboard/DashboardSummary.css";
import { REPORT_TITLE } from "../../pages/Dashboard/DashboardFinal/constants";
import "./ShipmentForm.css";
import SearchableTable from "../global/Search/SearchableTable";
import {TableFooter} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {DAYS_OF_WEEK, ORDER_REGEX} from "../global/constants";
import {getMessages, getShipments, editOrder, editFromAddress} from "../../redux/actions/orders";
import {
    orderIdSelector,
    ordersSelector,
    shipmentsFromLogsSelector, storeSelector,
    unshippedSelector,

} from "../../redux/selectors/orders";
import {userInfoSelector} from "../../redux/selectors/auth";
import {carriersSelector, locationsSelector, tagsSelector} from "../../redux/selectors/settings";
import {tableSettingSelector} from "../../redux/selectors/account";
import PropTypes from "prop-types";
import {
    COMPTACT_LABEL, DEFAULT_ROWS_PER_PAGE,
    FEDEX,
    FILTER, LOGS_PER_PAGE,
    PENDING_SHIPMENTS_LOGS,
    SHIPMENTS_PER_PAGE,
    SHOW_IMCOMPLETE,
    TABLE_DISPLAY_LABEL
} from "./constants";
import ShipmentForm from "./ShipmentForm";
import {v4 as uuidv4} from "uuid";
import withSystemEventLogs from "../../withSystemEventLogs";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import FilterIcon from "./FilterIcon";
import FiltersDialog from "./FiltersDialog";
import CompactTableSwitch from "./CompactTableSwitch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TocIcon from "@material-ui/icons/Toc";
import CustomTableHeader from "../global/Table/CustomTableHeader";
import {saveTableSetting} from "../../redux/actions/account";
import {SHIPPED} from "../stages/constants";
import FilterTagsBar from "./FilterTagsBar";
import {getStateAndProvinceMapping} from "../../redux/actions/global";
import {stateAndProvinceMappingSelector} from "../../redux/selectors/global";
import {AMAZON} from "../settings/ManageMarketplaces/constants";

const commodityField = [
    "numberOfPieces",
    "description",
    "countryOfManufacture",
    "harmonizedCode",
    "weight",
    "weightUnit",
    "quantity",
    "quantityUnit",
    "unitPrice",
    "customsValue",
    "tax",
    "currency",
]


class SystemEventLog extends SearchableTable {
    constructor(props) {
        super(props);
        this.expandShipment = this.expandShipment.bind(this)
        this.state = {
            missingScanned: [],
            missingPacked: [],
            reportData: [],
            valueToOrderByShipmentsSummary: "date",
            orderDirectionShipmentsSummary: "desc",
            selectedDate: new Date(),
            getMessagesOpen: false,
            show: false,
            getShipmentsOpen: false,
            index: 0,
            shipmentsIndex: -1,
            detailsPage: false,
            orderInfo: {
                fromAccount: {},
                service: '',
                accounts: [],
                original: '',
                shipmentId: '',
                shippingAddressName: '',
                shippingAddressCompanyName: '',
                shippingAddressPhone: '',
                shippingAddressAddressLine1: '',
                shippingAddressAddressLine2: '',
                shippingAddressCity: '',
                shippingAddressStateOrRegion: '',
                shippingAddressPostalCode: '',
                shippingAddressCountry: '',
                isResidential: false,
                // isResidential: typeof this.props.shipments[this.state.index].isResidential === 'undefined' ? false : this.props.shipments[this.state.index].isResidential,
                email: '',
                signatureConfirmation: '',
                dutiesPaymentType: '',
                reasonForExport: '',
                termsOfShipment: '',
                // documentsOnly: typeof this.props.shipments[this.state.index].documentsOnly === 'undefined' ? false : this.props.shipments[this.state.index].documentsOnly,
                documentsOnly: false,
                documentsDescription: '',
                packages: [],
                commodities: [],
                orders: [],
                orderId: '',
                total: 0,
                rateFeedbackOpen: false,
                rateErrorFeedbackOpen: false,
                rateFeedbackMessage: '',
                notes: '',
                isValidated: false,
            },
            commodityEditing: false,
            total: 0,
            retrieveShipments: [],
            accountSelectIndex: -1,
            searchValue: '',
            searchText: '',
            listBySearchedByOrderId: false,
            logs: [],
            filtersMap: {},
            isDialogOpen: false,
            deletedTagsWithCookies: [],
            fromDate: this.getFromDate(),
            toDate: this.getToDate(),
            rowsPerPage: DEFAULT_ROWS_PER_PAGE, // number of rows per page
            page: 0, // page number
            searchShipments: [],
        }
    }

    async componentDidMount() {
        const data = ({
            fromDate: this.state.fromDate,
            toDate: this.state.toDate
        })
        await this.props.getMessages(data);
        await this.props.getShipments(data);
        this.initializeSearchText();
        // await this.setState({
        //     retrieveShipments: this.props.shipments
        // })
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.fromDate !== this.state.fromDate || prevState.toDate !== this.state.toDate) {
            const data = {
                fromDate: this.state.fromDate,
                toDate: this.state.toDate,
            }
            this.props.getMessages(data)
            this.props.getShipments(data)
        }

        if (prevProps.shipments !== this.props.shipments) {
            await this.setState({
                retrieveShipments: this.props.shipments
            })
        }
        if (this.props?.logsDetailsPage && (prevProps.shipments !== this.props.shipments) && this.props.shipments.length !== 0) {
            let oldOrderInfo = {...this.state.orderInfo}
            oldOrderInfo.shippingAddressName = this.props?.shipments[this.state.index]?.shippingAddressName
            oldOrderInfo.shippingAddressAddressLine1 = this.props?.shipments[this.state.index]?.shippingAddressAddressLine1
            oldOrderInfo.shippingAddressAddressLine2 = this.props?.shipments[this.state.index]?.shippingAddressAddressLine2
            oldOrderInfo.shippingAddressCity = this.props.shipments[this.state.index]?.shippingAddressCity
            oldOrderInfo.shippingAddressCompanyName = this.props?.shipments[this.state.index]?.shippingAddressCompanyName
            oldOrderInfo.shippingAddressStateOrRegion = this.props?.shipments[this.state.index]?.shippingAddressStateOrRegion
            oldOrderInfo.shippingAddressPostalCode = this.props?.shipments[this.state.index]?.shippingAddressPostalCode
            oldOrderInfo.shippingAddressisResidential = this.props?.shipments[this.state.index]?.shippingAddressisResidential
            oldOrderInfo.notes = this.props?.shipments[this.state.index].notes
            oldOrderInfo.isValidated = this.props.shipments[this.state.index]?.isValidated
            // oldOrderInfo.package = packages

            await this.setState({
                orderInfo: oldOrderInfo
            })
        }
        if (this.props?.logsDetailsPage && prevState.accountSelectIndex !== this.state.accountSelectIndex) {
            let oldOrderInfo = this.state.orderInfo
            oldOrderInfo.fromAccount = this.state.orderInfo.accounts[this.state.accountSelectIndex]
            oldOrderInfo.service = this.state.orderInfo.accounts[this.state.accountSelectIndex].defaultService
            await this.setState({
                orderInfo: oldOrderInfo
            })
        }

        if (this.props.tableSetting?.shipmentFilterSetting !== prevProps.tableSetting?.shipmentFilterSetting) {
            this.setState({
                filtersMap: this.addFilterDates(this.props.tableSetting?.shipmentFilterSetting),
                dense: (this.props.tableSetting?.shipmentFilterSetting?.dense && this.props.tableSetting?.shipmentFilterSetting?.dense !== "false") ?? false
            })
        }
        if (prevProps.logs !== this.props.logs) {
            if (this.props.linkedShipmentId) {
                const rowsToCheck = this.displayList(this.props.rows)
                const index = rowsToCheck.findIndex(row => row.shipmentId === this.props.linkedShipmentId)
                if (index === -1) {
                    this.props.clearLinkedShipment()
                } else {
                    const openEditOrderRow = this.displayList(this.props.rows)[index]
                    this.setState({
                        openEditOrderDialog: true,
                        openEditOrderIndex: index
                    })
                    this.handleExpand(
                        openEditOrderRow.shipmentId,
                        !openEditOrderRow.carrierName ? false : openEditOrderRow.carrierName.split(" ") === FEDEX,
                        openEditOrderRow.labelArchived
                    )
                }
            }
            await this.setState({
                logs: this.props.logs
            })
        }
        if (prevState.searchText !== this.state.searchText || this.state.searchValue !== this.state.searchText) {
            this.initializeSearchText()
        }
    }

    displayOrderId() {
        if (this.state.retrieveShipments[this.state.shipmentsIndex]?.orders && this.state.retrieveShipments[this.state.shipmentsIndex]?.orders.length > 1) {
            return "Multiple"
        } else {
            return this.state.retrieveShipments[this.state.shipmentsIndex]?.orders ?
                this.state.retrieveShipments[this.state.shipmentsIndex]?.orders[0]?.orderId
                :
                this.state.retrieveShipments[this.state.shipmentsIndex]?.packages.reference1?.length > 0 ?
                    this.state.retrieveShipments[this.state.shipmentsIndex]?.packages.reference1
                    :
                    ''
        }
    }

    localDateConversion = (date) => {
        if (!date) return ''
        let MM_dd_yyyy = new Date(date).toLocaleString("en-US", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone}).substr(0,10)
        let MM = MM_dd_yyyy.split("/")[0]
        if (MM.length === 1) {MM = "0" + MM}
        let dd = MM_dd_yyyy.split("/")[1]
        if (dd.length === 1) {dd = "0" + dd}
        let yyyy = MM_dd_yyyy.split("/")[2].substr(0,4)
        return yyyy + "-" + MM + "-" + dd
    }

    pacificDateConversion = (date) => {
        if (!date) return ''
        let MM_dd_yyyy = new Date(date).toLocaleString("en-US", {timeZone: "America/Los_Angeles"}).substr(0,10)
        let MM = MM_dd_yyyy.split("/")[0]
        if (MM.length === 1) {MM = "0" + MM}
        let dd = MM_dd_yyyy.split("/")[1]
        if (dd.length === 1) {dd = "0" + dd}
        let yyyy = MM_dd_yyyy.split("/")[2].substr(0,4)
        return yyyy + "-" + MM + "-" + dd
    }

    initializeSearchText = () => {
        this.setState({ searchValue: this.state.searchText })
    }

    changeShipmentInformation = async () => {
            let total
            let packages = []
            if (!this.state?.retrieveShipments[this.state.shipmentsIndex] || !this.state?.retrieveShipments[this.state.shipmentsIndex]?.packages) {
                await this.setState({
                    show: false
                })
                this.props.handleEditShipmentClose()
                return
            }

            this.state.retrieveShipments[this.state.shipmentsIndex].packages.map(order => {
                if (order.orderItems) {
                    order.orderItems.forEach(item => total += item.quantityOrdered)
                }
                const packageInfo = {
                    packaging: order.packaging,
                    packageId: order.packageId,
                    shipmentId: order.shipmentId,
                    trackingNumber: order.trackingNumber,
                    reference1: order.reference1,
                    reference2: order.reference2,
                    reference3: order.reference3,
                    weight: order.weight,
                    length: order.length,
                    width: order.width,
                    height: order.height,
                    canparLabelId: order.canparLabelId,
                    zplLabel: order.zplLabel,
                    skus: order.skus,
                    currency: order.currency,
                    declaredValue: order.declaredValue,
                    products: order.products,
                    orderItems: order.orderItems
                };
                packages.push(packageInfo);
            })


            let accounts = []

            this.props.carriers.map(carrier => {
                const alias = carrier.alias;
                const carrierName = carrier.name;
                const accountNumber = carrier.accountNumber;
                const meterNumber = carrier.meterNumber;
                const contractId = carrier.contractId;
                const defaultService = carrier.defaultService;
                carrier.addresses.map(address => {
                    const account = {
                        fromAddressAlias: alias,
                        fromAddressAddressLine1: address.addressLine1,
                        fromAddressAddressLine2: address.addressLine2,
                        fromAddressEmail: address.email,
                        fromAddressName: address.name,
                        fromAddressCompanyName: address.companyName,
                        fromAddressContactName: address.contactName,
                        fromAddressPhone: address.phone,
                        fromAddressPostalCode: address.postalCode,
                        fromAddressCity: address.city,
                        fromAddressState: address.state,
                        fromAddressCountry: address.country,
                        fromAddressAccountNumber: accountNumber,
                        fromAddressMeterNumber: meterNumber,
                        carrier: carrierName,
                        defaultService: defaultService,
                        isDefault: address.isDefault
                    }
                    accounts = [...accounts, account];
                })
            })
        let shipment = this.state.retrieveShipments[this.state.shipmentsIndex]
        let marketplace = shipment.marketplace
        this.setState({
                orderInfo:{
                    accounts: accounts,
                    service: shipment.carrierService,
                    shipmentId: shipment.shipmentId,
                    tags: shipment.tags,
                    shippingAddressName: shipment.shippingAddressName,
                    shippingAddressCompanyName: shipment.shippingAddressCompanyName,
                    shippingAddressPhone: shipment.shippingAddressPhone,
                    shippingAddressAddressLine1: shipment.shippingAddressAddressLine1,
                    shippingAddressAddressLine2: shipment.shippingAddressAddressLine2,
                    shippingAddressCity: shipment.shippingAddressCity,
                    shippingAddressStateOrRegion: shipment.shippingAddressStateOrRegion,
                    shippingAddressPostalCode: shipment.shippingAddressPostalCode,
                    shippingAddressCountry: shipment.shippingAddressCountry,
                    email: shipment.email,
                    packages: packages,
                    commodities: shipment.commodities ? {} : shipment.commodities?.map(commodity => {
                        return {...commodity, id: uuidv4()}
                    }),
                    orders: shipment.orders,
                    modifiedEarliestShipDate: marketplace.includes(AMAZON) ?
                        this.pacificDateConversion(shipment.orders[0]?.earliestShipDate)
                        :
                        this.localDateConversion(shipment.orders[0]?.earliestShipDate),
                    modifiedLatestShipDate: marketplace.includes(AMAZON) ?
                        this.pacificDateConversion(shipment.orders[0]?.latestShipDate)
                        :
                        this.localDateConversion(shipment.orders[0]?.latestShipDate),
                    modifiedEarliestDeliveryDate:  marketplace.includes(AMAZON) ?
                        this.pacificDateConversion(shipment.orders[0]?.earliestDeliveryDate)
                        :
                        this.localDateConversion(shipment.orders[0]?.earliestDeliveryDate),
                    modifiedLatestDeliveryDate:  marketplace.includes(AMAZON) ?
                        this.pacificDateConversion(shipment.orders[0]?.latestDeliveryDate)
                        :
                        this.localDateConversion(shipment.orders[0]?.latestDeliveryDate),
                    // orderId: displayOrderId(),
                    orderId: this.displayOrderId(),
                    isValidated: shipment.isValidated,
                    isResidential: shipment.isResidential,
                    isReturnShipment: shipment.isReturnShipment,
                    redirectRecipient:shipment.redirectRecipient,
                    isCloneShipment: shipment.isCloneShipment,
                    isRedirectShipment: shipment.isRedirectShipment,
                    isMarkShippedShipment: shipment.isMarkShippedShipment,
                    clonedOrderId: shipment.clonedOrderId,
                    redirectFromOrderId: shipment.redirectFromOrderId,
                    redirectToOrderId: shipment.redirectToOrderId,
                    signatureConfirmation: shipment.signatureConfirmation,
                    dutiesPaymentType: shipment.dutiesPaymentType,
                    reasonForExport: shipment.reasonForExport,
                    termsOfShipment: shipment.termsOfShipment,
                    documentsOnly: shipment.documentsOnly,
                    documentsDescription: shipment.documentsDescription,
                    total: total,
                    notes: shipment.notes,
                    show: shipment.showInfo,
                    fromAccount: {
                        fromAddressAddressLine1: shipment.fromAddressAddressLine1 ? shipment.fromAddressAddressLine1 : "",
                        fromAddressAddressLine2: shipment.fromAddressAddressLine2 ? shipment.fromAddressAddressLine2 : "",
                        fromAddressEmail: shipment.fromAddressEmail ? shipment.fromAddressEmail : "",
                        fromAddressName: shipment.fromAddressName ? shipment.fromAddressName : "",
                        fromAddressCompanyName: shipment.fromAddressCompanyName ? shipment.fromAddressCompanyName : "",
                        fromAddressContactName: shipment.fromAddressContactName ? shipment.fromAddressContactName : "",
                        fromAddressPhone: shipment.fromAddressPhone ? shipment.fromAddressPhone : "",
                        fromAddressPostalCode: shipment.fromAddressPostalCode ? shipment.fromAddressPostalCode : "",
                        fromAddressCity: shipment.fromAddressCity ? shipment.fromAddressCity : "",
                        fromAddressState: shipment.fromAddressState ? shipment.fromAddressState : "",
                        fromAddressCountry: shipment.fromAddressCountry ?shipment.fromAddressCountry : "",
                        fromAddressAccountNumber: shipment.fromAddressAccountNumber ? shipment.fromAddressAccountNumber : "",
                        fromAddressMeterNumber: shipment.fromAddressMeterNumber ? shipment.fromAddressMeterNumber : "",
                        carrier: shipment.carrierName ? this.state.retrieveShipments[this.state.shipmentsIndex].carrierName : "",
                        isDefault: false,
                    }
                },
            })
    }

    filterBySearch(rows) {
        if (!rows)
            return [];
        const searchText = this.state.searchValue.toLowerCase();

        if (searchText === '') {
            return rows
        }
        // for each row in rows
        const result = rows.filter(row => {
            // traverse every key of the row as an array and match the search text against the value corresponding key
            // note: here are only searching the "filter" and not other hidden properties
            return Object.keys(row).findIndex(key => {
                return row && row[key] && JSON.stringify(row[key]).toLowerCase().includes(searchText)
            }) !== -1
        });
        return result
    }

    createSortHandler = (property) => (event) => {
        this.handleRequestSort(event, property);
    }

    handleRequestSort = async (event, property) => {
        const isAscendingShipments = (this.state.valueToOrderByShipmentsSummary === property && this.state.orderDirectionShipmentsSummary === "asc");
        await this.setState({
            valueToOrderByShipmentsSummary: property,
            orderDirectionShipmentsSummary: (isAscendingShipments ? 'desc' : 'asc')
        })
    }


    handleDateChange = (date) => {
        this.setState({
            selectedDate: date
        })
        this.props.handleDateChange(date)
    }

    getMessages = () => {
        const data = this.state.selectedDate
        this.setState({getMessagesOpen: true,})

        if (!this.props.editOrderOpen) {
            this.props.clearSelected()
        }
    }

    getShipments = () => {
        this.setState({
            getShipmentsOpen: true,
        })
    }

handleEditShipmentOpen = (index) => {
        this.setState({
            openEditOrderDialog: true,
            openEditOrderIndex: index,
        })
    }

    expandShipment = async (index) => {
        const searchLogs = this.filterBySearch(this.props.logs)
        const shipmentsIndex = this.props.shipments.findIndex((shipment) => searchLogs[index].shipmentId === shipment.shipmentId)
        await this.setState({
                index: index,
                shipmentsIndex: shipmentsIndex,
            })
        await this.changeShipmentInformation()
        this.props.orderIdFromLogs(this.props.shipments[this.state.shipmentsIndex]?.orders[0]?.orderId)
        this.props.shipmentIdFromLogs(this.props.shipments[this.state.shipmentsIndex].shipmentId)
        this.props.toggleLogsDetailsPage()
    }

    handleSave = async (e, isPackage, index, bypassCheck, validateAddress) => {
        let value = e.target.value
        const name = e.target.name

        const order = {
            id: this.state.retrieveShipments[this.state.shipmentsIndex].shipmentId,
            field_to_edit: name,
            value: value,
            isPackage: isPackage,
            index: index,
            isProduct: false,
            productInfo: {},
            validateAddress: validateAddress
        }

        if (order.value !== this.state.orderInfo.original || bypassCheck) {
            // this.props.editAndGetShipments({order: order, fromDate: this.state.fromDate, toDate: this.state.toDate})
            await this.props.editOrder(order)
            // update the from account
        }
        const data = {
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
        }
        // this.props.getShipments(data)
        await this.props.handleGetShipments()
        await this.props.getMessages(data)
    }

    handleAutocompleteSave = (value, name, isPackage, index) => {
        const order = {
            id: this.state.retrieveShipments[this.state.shipmentsIndex].shipmentId,
            field_to_edit: name,
            value: value,
            isPackage: isPackage,
            index: index,
            isProduct: false,
            productInfo: {}
        }
        this.props.editOrder(order)
    }

    handleChange = (value, name, isPackage, index) => {
        if (isPackage) {
            let packages = [...this.state.orderInfo.packages];
            packages[index][name] = value;
            this.setState({
                orderInfo: {...this.state.orderInfo, packages: packages},
            })
        } else if (commodityField.includes(name)) {
            let commodities = [...this.state.orderInfo.commodities];
            commodities[index][name] = value;
            if (name == "quantity" || name == "unitPrice") {
                commodities[index].customsValue = Math.round((parseFloat(commodities[index].quantity) * parseFloat(commodities[index].unitPrice)) * 100) / 100
            }
           this.setState({
               orderInfo: {...this.state.orderInfo, commodities: commodities},
            })
        } else {
           this.setState({
                orderInfo: {...this.state.orderInfo, [name]: value},
            })
        }
        // const data = {
        //     fromDate: this.state.fromDate,
        //     toDate: this.state.toDate,
        // }
        // this.props.getShipments(data)
        // this.props.getMessages(data)
    }

    handleFromAccountChange = (value, name) => {
        const fromAccount = { ...this.state.orderInfo?.fromAccount }
        fromAccount[name] = value
        this.setState({
            orderInfo: {...this.state.orderInfo, fromAccount: fromAccount},
        })
    }

    handleAutocompleteChange = (value, name, isPackage, index) => {
        if (isPackage) {

            let packages = [...this.state.orderInfo.packages];
            packages[index][name] = value;
            this.setState({
                orderInfo: {...this.state.orderInfo, packages: packages},
            })
        } else if (name == "tags") {
        } else {
            if (name == "fromAddressCountry") {
                this.setState({
                    orderInfo: {...this.state.orderInfo, [name]: value},
                })
            } else {
                this.setState({
                    orderInfo: {...this.state.orderInfo, [name]: value},
                })
            }
        }
    }

    handleCheck = (field) => {
        // if for some reason in the database isResidential is undefined, then we need to make it true when you click the box
        const order = {
            id: this.state.retrieveShipments[this.state.shipmentsIndex].shipmentId,
            field_to_edit: field,
            value: (!(this.state.orderInfo[field])).toString(),
            isPackage: false,
            index: -1,
            isProduct: false,
            productIndex: -1,
            quantity: -1,
            productInfo: {}
        }

        this.setState({
            orderInfo: {...this.state.orderInfo, [field]: !(this.state.orderInfo[field])}
        })

        this.props.editOrder(order)
        // this.props.getShipments({fromDate: this.state.fromDate, toDate: this.state.toDate})
    }

    handleFocus = (e) => {
        this.setState({
            orderInfo: {...this.state.orderInfo, original: e.target.value}
        })
    }


    handleAutocompleteFocus = (v) => {
        this.setState({
            orderInfo: {...this.state.orderInfo, original: v}
        })
    }

    handleAccountSelect = async (value, isCreateShip, refreshFromAddressName) => {
        // props.clearSelected()
        let index
        if (value?.isBuyShipping) {
            index = this.state.orderInfo.accounts.findIndex(account => account.fromAddressAccountNumber === value?.fromAddressAccountNumber &&
                account.marketplace === value?.marketplace)
        } else {
            index = this.state.orderInfo.accounts.findIndex(account => account.fromAddressAccountNumber === value?.fromAddressAccountNumber)
        }

        if (index !== -1) {
            this.state.orderInfo.accounts[index].fromAddressAlias = '';
        }


        this.setState({
            service: this.state.orderInfo.accounts[index]?.defaultService,
            fromAccount: this.state.orderInfo.accounts[index] ? this.state.orderInfo.accounts[index] : {
                fromAddressAddressLine1: this.state.retrieveShipments[this.state.shipmentsIndex].fromAddressAddressLine1 ? this.state.retrieveShipments[this.state.shipmentsIndex].fromAddressAddressLine1 : "",
                fromAddressAddressLine2: this.state.retrieveShipments[this.state.shipmentsIndex].fromAddressAddressLine2 ? this.state.retrieveShipments[this.state.shipmentsIndex].fromAddressAddressLine2 : "",
                fromAddressEmail: this.state.retrieveShipments[this.state.shipmentsIndex].fromAddressEmail ? this.state.retrieveShipments[this.state.shipmentsIndex].fromAddressEmail : "",
                fromAddressName: this.state.retrieveShipments[this.state.shipmentsIndex].fromAddressName ? this.state.retrieveShipments[this.state.shipmentsIndex].fromAddressName : "",
                fromAddressCompanyName: this.state.retrieveShipments[this.state.shipmentsIndex].fromAddressCompanyName ? this.state.retrieveShipments[this.state.shipmentsIndex].fromAddressCompanyName : "",
                fromAddressContactName: this.state.retrieveShipments[this.state.shipmentsIndex].fromAddressContactName ? this.state.retrieveShipments[this.state.shipmentsIndex].fromAddressContactName : "",
                fromAddressPhone: this.state.retrieveShipments[this.state.shipmentsIndex].fromAddressPhone ? this.state.retrieveShipments[this.state.shipmentsIndex].fromAddressPhone : "",
                fromAddressPostalCode: this.state.retrieveShipments[this.state.shipmentsIndex].fromAddressPostalCode ? this.state.retrieveShipments[this.state.shipmentsIndex].fromAddressPostalCode : "",
                fromAddressCity: this.state.retrieveShipments[this.state.shipmentsIndex].fromAddressCity ? this.state.retrieveShipments[this.state.shipmentsIndex].fromAddressCity : "",
                fromAddressState: this.state.retrieveShipments[this.state.shipmentsIndex].fromAddressState ? this.state.retrieveShipments[this.state.shipmentsIndex].fromAddressState : "",
                fromAddressCountry: this.state.retrieveShipments[this.state.shipmentsIndex].fromAddressCountry ? this.state.retrieveShipments[this.state.shipmentsIndex].fromAddressCountry : "",
                fromAddressAccountNumber: this.state.retrieveShipments[this.state.shipmentsIndex].fromAddressAccountNumber ? this.state.retrieveShipments[this.state.shipmentsIndex].fromAddressAccountNumber : "",
                fromAddressMeterNumber:this.state.retrieveShipments[this.state.shipmentsIndex].fromAddressMeterNumber ?this.state.retrieveShipments[this.state.shipmentsIndex].fromAddressMeterNumber : "",
                carrier: this.state.retrieveShipments[this.state.shipmentsIndex].carrierName ? this.state.retrieveShipments[this.state.shipmentsIndex].carrierName : "",
                isDefault: false,
            }
        })


        if (!isCreateShip) {
            const data = {
                ...this.state.orderInfo.accounts[index],
                id: this.state.retrieveShipments[this.state.shipmentsIndex].shipmentId,
                refreshFromAddressName: refreshFromAddressName,
            }
            await this.props.editFromAddress(data)
            const shipmentData = {
                fromDate: this.state.fromDate,
                toDate: this.state.toDate
            }
            // this.props.getShipments(shipmentData)
        }
    }

    romoveCommodity = (index) => {
        const editing = this.setState({
            commodityEditing: !this.state.commodityEditing
        })
        this.props.deleteCommodity(this.state.retrieveShipments[this.state.shipmentsIndex].shipmentId, index, editing)
    }

    openLinkedOrder = (orderId) => {
        const push = (path) => {
            this.props.history.push(path)
        }
        this.props.openLinkedOrder(orderId, push)
    }


    handleBoxChange = (boxName, length, width, height, index) => {
        const order = {
            id: this.state.retrieveShipments[this.state.shipmentsIndex].shipmentId,
            width: width,
            length: length,
            height: height,
            index: index,
            packaging: boxName
        }
        this.props.updateBoxType(order);
    }

    handleTrack = (e) => {
        const data = {
            id: this.state.retrieveShipments[this.state.shipmentsIndex].shipmentId,
            shipmentService: this.state.retrieveShipments[this.state.shipmentsIndex].carrierService
        };
        this.props.trackShipment(data)
    }

    applyFilters = (rows) => {
        if (!rows) {
            return []
        }
        if (!this.state.filtersMap) {
            return rows
        }
        const filtersMap = { ...this.state.filtersMap }

        const orderID = filtersMap["orderid"];
        const tag = filtersMap['tag'];
        const name = filtersMap["name"];
        const address = filtersMap["address"];
        const city = filtersMap["city"];
        const stateOrProvince = filtersMap["state"];
        const country = filtersMap["country"];
        const sku = filtersMap["sku"];
        const marketplace = filtersMap["marketplace"];
        const shipFrom = filtersMap["shipfrom"];
        const shipMethod = filtersMap["shipmethod"];
        const carrier = filtersMap["carrier"];
        const isPrime = filtersMap["prime"];
        const incomplete = filtersMap["incomplete"];

        let result = [...rows]

        if (orderID) {
            result = result.filter((row, index) => {
                if (row.orders) {
                    for (let i = 0; i < row.orders.length; i++) {
                        if (row.orders[i].orderId.includes(orderID.toLowerCase())) {
                            return true
                        }
                    }
                }
                return false
            });
        }

        if (tag) {
            result = result.filter(row => {
                let allTagsIncluded = false
                let tags = [];
                for (let i = 0; i < row.tags.length; i++) {
                    if (row.tags[i].tagName) {
                        tags = [...tags, row.tags[i].tagName?.toLowerCase()]
                    }
                }
                for (let i = 0; i < tag.length; i++) {
                    if (tags.includes(tag[i].toLowerCase())) {
                        allTagsIncluded = true
                        break
                    }
                }
                return allTagsIncluded
            });
        }

        if (name) {
            result = result.filter((row, index) => {
                return row.shippingAddressName?.toLowerCase().includes(name.toLowerCase());
            });
        }

        if (address) {
            result = result.filter((row, index) => {
                return row.shippingAddressAddressLine1?.toLowerCase().includes(address.toLowerCase())
                    || row.shippingAddressAddressLine2?.toLowerCase().includes(address.toLowerCase());
            });
        }

        if (city) {
            result = result.filter((row, index) => {
                return row.shippingAddressCity?.toLowerCase().includes(city.toLowerCase());
            });
        }

        if (stateOrProvince) {

            result = result.filter((row, index) => {
                return row.shippingAddressStateOrRegion?.toLowerCase().includes(stateOrProvince.toLowerCase());
            });
        }

        if (country) {
            result = result.filter((row, index) => {
                return row.shippingAddressCountry?.toLowerCase() === country.toLowerCase();
            });
        }

        if (sku) {
            result = result.filter(row => {
                if (row.packages?.length > 0 && row.packages.skus) {
                    for (let i = 0; i < row.packages.length; i++) {
                        for (let j = 0; j < row.packages[i].skus.length; j++) {
                            if (row.packages[i].skus[j]?.toLowerCase().includes(sku.toLowerCase())) {
                                return true
                            }
                        }
                    }
                }
                return false
            });
        }

        if (marketplace) {
            result = result.filter((row, index) => {
                return row.marketplace?.toLowerCase().includes(marketplace.toLowerCase());
            });
        }

        if (shipFrom) {
            result = result.filter(row => {
                return row.fromAddressName?.toLowerCase() === shipFrom.toLowerCase()
            });
        }

        if (shipMethod) {
            result = result.filter(row => {
                return row.orders?.length > 0 ? row.orders.shipmentServiceLevelCategory?.toLowerCase().includes(shipMethod.toLowerCase()) : false
            });
        }

        if (carrier) {
            result = result.filter(row => {
                return row.carrierName?.toLowerCase().includes(carrier.toLowerCase());
            });
        }

        if (isPrime) {
            result = result.filter(row => {
                return row.isPrime
            });
        }

        if (incomplete) {
            result = result.filter(row => {
                return (!this.isShipmentReady(row.shipmentId) && !this.isShipmentReadyForMarkRedirected(row.shipmentId))
            });
        }

        return result;
    }

    onSearchValueChange = async (event) => {
        await this.setState({
            isSearch: event.target.value.length > 0,
            searchValue: event.target.value,
            searchText: event.target.value,
            page: 0,
            selected: [],
        });
    }

    applyDateFilters = (fromDate, toDate) => {
        this.setState({
            fromDate: fromDate,
            toDate: toDate
        })
        const data = {
            fromDate: this.state.fromDate,
            toDate: this.state.toDate
        }
        this.props.getMessages(data);
        // this.props.getShipments(data)
        this.props.handleDateChange(fromDate, toDate)
    }

    showFiltersDialog = () => {
        // Make a copy of the filters applied as they exist upon entering
        const filtersMapClone = { ...this.state.filtersMap };

        this.setState({
            filtersMapBeforeOpenDialog: filtersMapClone,
            isDialogOpen: !this.state.isDialogOpen,
        });
    }

    clearFiltersMap = () => {
        this.setState({
            filtersMap: {},
            fromDate: this.getFromDate(),
            toDate: this.getToDate()
        })
        this.saveUpdatedFilters({dense: this.state.dense})
    }

    saveUpdatedFilters = (filters) => {
        delete filters['fromDate']
        delete filters['toDate']
    }

    getFromDate = () => {
        var date = new Date()
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);

        return date
    }

    getToday = () => {
        var today = new Date()
        today = today.toString().split('GMT') + "Z"
        return new Date(today)
    }

    getToDate = () => {
        var date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59, 999)
        return date
    }

    resetFilters = (fromDate, toDate) => {
        let filtersMapClone = { ...this.state.filtersMap }
        if (!this.isDefaultFromDate(fromDate)) {
            filtersMapClone['fromDate'] = fromDate
        }
        if (!this.isToday(toDate)) {
            filtersMapClone['toDate'] = toDate
        }
        this.setState({
            fromDate: fromDate,
            toDate: toDate,
            deletedTagsWithCookies: [],
            filtersMap: filtersMapClone
        })
    }

    isDefaultFromDate = (someDate) => {
        const defaultFromDate = this.getFromDate()
        return someDate.getDate() == defaultFromDate.getDate() &&
            someDate.getMonth() == defaultFromDate.getMonth() &&
            someDate.getFullYear() == defaultFromDate.getFullYear()
    }

    isToday = (someDate) => {
        const today = new Date()
        return someDate.getDate() == today.getDate() &&
            someDate.getMonth() == today.getMonth() &&
            someDate.getFullYear() == today.getFullYear()
    }


    hideFiltersDialog = (applyChanges) => {
        this.setState({
            isDialogOpen: false,
        })
        let filters = {}
        if (!applyChanges) {
            filters = {...this.state.filtersMapBeforeOpenDialog}
            this.resetFilters(filters?.fromDate ?? this.getFromDate(), filters?.toDate ?? this.getToDate())
            this.setState({
                filtersMap: filters,
            })
        } else {
            this.applyDateFilters(this.state.fromDate, this.state.toDate)
        }
        filters = { ...this.state.filtersMap }
        this.saveUpdatedFilters(filters)

    }

    onDateFiltersInputChange = (id, value) => {
        if (!value || isNaN(value.getTime())) {
            //User is still editing the date value, do not fetch orders
            this.setState({
                fromDate: id === "fromDate" ? value : this.state.fromDate,
                toDate: id === "toDate" ? value : this.state.toDate
            })
        } else {
            const date = new Date(value.toString().split('GMT') + "Z")
            let filtersMapClone = { ...this.state.filtersMap }
            if (id === 'fromDate' && !this.isDefaultFromDate(value)) {
                filtersMapClone[id] = value
            }
            if(id === 'toDate' && !this.isToday(value)) {
                filtersMapClone[id] = date
            }
            this.setState({
                fromDate: id === "fromDate" ? value : this.state.fromDate,
                toDate: id === "toDate" ? value : this.state.toDate,
                filtersMap: filtersMapClone
            })
        }
    }

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
        });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0,
        });
    };

    isToggleOn = (toggle) => {
        this.setState({
            toggleOn: toggle
        })
    }


    render() {
        return (
            <>
                {this.props.logsDetailsPage && this.state.retrieveShipments ?
                     <ShipmentForm
                         title={this.props.title}
                         shipmentStatus={this.props.shipmentStatus}
                         shipmentId={this.state?.retrieveShipments[this.state.shipmentsIndex]?.shipmentId}
                         orderInfo={this.state.orderInfo}
                         handleSave={this.handleSave}
                         handleAutocompleteSave={this.handleAutocompleteSave}
                         handleChange={this.handleChange}
                         handleFromAddressChange={this.handleFromAccountChange}
                         handleAutocompleteChange={this.handleAutocompleteChange}
                         handleCheck={this.handleCheck}
                         handleFocus={this.handleFocus}
                         handleAutocompleteFocus={this.handleAutocompleteFocus}
                         handleAccountSelect={this.handleAccountSelect}
                         errorChecking={false}
                         required={false}
                         copyPackage={this.props.copyPackage}
                         deletePackage={this.props.deletePackage}
                         addPackage={this.props.addPackage}
                         editOrder={this.props.editOrder}
                         loading={this.props.loadingShipmentRequest}
                         isCreateShip={false}
                         tags={this.props.tags}
                         row={this.state?.retrieveShipments[this.state.shipmentsIndex]}
                         eventLogs={this.filterBySearch(this.props.logs)}
                         logIndex={this.state.index}
                         // row={this.props.shipments[this.state.shipmentsIndex]}
                         locations={this.props.locations}
                         addCommodity={this.props.addCommodity}
                         romoveCommodity={this.romoveCommodity}
                         commodityEditing={this.state.commodityEditing}
                         getShipmentCommercialInvoice={this.props.getShipmentCommercialInvoice}
                         commercialInvoice={this.props.commercialInvoice}
                         saveRedirectToOrderId={this.props.saveRedirectToOrderId}
                         deleteRedirectToOrderId={this.props.deleteRedirectToOrderId}
                         openLinkedOrder={this.openLinkedOrder}
                         reprintPackage={this.props.reprintPackage}
                         boxes={this.props.boxes}
                         handleBoxChange={this.handleBoxChange}
                         handleTrackShipment={this.handleTrack}
                         handleValidateAddress={this.props.validateAddress}
                         // getShipments={this.props.getShipments()}
                         fromDate={this.state.fromDate}
                         toDate={this.state.toDate}
                         updateShipments={this.props.updateShipments}
                         isShipmentReady={this.props.isShipmentReady}
                         toggleCommercialInvoiceView={this.props.toggleCommercialInvoiceView}
                         showCommercialInvoice={this.props.showCommercialInvoice}
                         scale={this.props.scale}
                         handleCommercialInvoicePdfLoadSuccess={this.props.handleCommercialInvoicePdfLoadSuccess}
                         handleCommercialInvoicePdfLoadError={this.props.handleCommercialInvoicePdfLoadError}
                         commercialInvoicePdfLoadSuccess={this.props.commercialInvoicePdfLoadSuccess}
                     />
                    :
                    <>
                        <CustomTableHeader
                            searchText={this.state.searchText}
                            onSearchValueChange={this.onSearchValueChange}
                            filterIcon={
                                <Tooltip title={FILTER} style={{ paddingTop: 10 }}>
                                    <IconButton className="filterButton" size='small'
                                                aria-label={FILTER}
                                                onClick={this.showFiltersDialog}>
                                        <FilterIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                            showFilters={this.state.isDialogOpen}
                            filtersComponent={
                                <>
                                    <Slide in={this.state.isDialogOpen} mountOnEnter unmountOnExit timeout={300}>
                                        <FiltersDialog
                                            isOpen={this.state.isDialogOpen}
                                            onOpen={this.showFiltersDialog}
                                            // onChange={this.onFiltersInputChange}
                                            clearFilters={this.clearFiltersMap}
                                            onClose={this.hideFiltersDialog}
                                            filtersMap={this.state.filtersMap}
                                            locations={this.props.locations}
                                            carriers={this.props.carriers}
                                            tags={this.props.tags}
                                            fromDate={this.state.fromDate}
                                            toDate={this.state.toDate}
                                            handleDateChange={this.onDateFiltersInputChange}
                                            inLogs={true}
                                            getStateAndProvinceMapping={this.props.getStateAndProvinceMapping}
                                            stateAndProvinceMapping={this.props.stateAndProvinceMapping}
                                        />
                                    </Slide>
                                </>
                            }
                            pagination={
                                <TablePagination
                                    className="table-pagination"
                                    labelRowsPerPage={LOGS_PER_PAGE}
                                    rowsPerPageOptions={[5, 25, 50, 100]}
                                    count={!this.state.logs || this.state.logs.length <= 0 ? 0 : (this.state.listBySearchedByOrderId ? this.props.logs.length : this.filterBySearch(this.applyFilters(this.props.logs)).length)}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            }
                        />

                    <div className="missing-shipments-container">
                        <Typography variant="h6" component="div" gutterBottom className="form-header">
                        </Typography>
                        <div className="missing-labels-table-content">
                            <TableContainer>
                                <Table size={"medium"} stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className='cellPadding'>
                                                <p className={styles.cardTotalHeader}>
                                                    Classification
                                                    {/*{DAYS_OF_WEEK[this.state.selectedDate?.getDay()]}*/}
                                                </p>
                                            </TableCell>
                                            <TableCell className='cellPadding'>
                                                <p className={styles.cardTotalHeader}>
                                                    Order Id
                                                    {/*{DAYS_OF_WEEK[this.state.selectedDate?.getDay()]}*/}
                                                </p>
                                            </TableCell>
                                            <TableCell className='cellPadding' key="date">
                                                <p className={styles.cardTotalHeader}>
                                                    Changes
                                                </p>
                                                {/*</TableSortLabel>*/}
                                            </TableCell>
                                            <TableCell className='cellPadding'>
                                                    <p className={styles.cardTotalHeader}>
                                                        Timestamp
                                                    </p>
                                            </TableCell>
                                            <TableCell className='cellPadding'>
                                                <p className={styles.cardTotalHeader}>
                                                    Source
                                                    {/*{DAYS_OF_WEEK[this.state.selectedDate?.getDay()]}*/}
                                                </p>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.stableSort(this.filterBySearch(this.props.logs), this.getComparator(this.state.orderDirectionShipmentsSummary, this.state.valueToOrderByShipmentsSummary)).map((entry, index) => (
                                            <TableRow hover key={index}>
                                                <TableCell className='cellPadding' style={{width: "10%"}}>
                                                    <p className={styles.cardCellItems} onClick={() => this.expandShipment(index)}>
                                                        {entry.classification == 'critical' ? <ErrorOutlineIcon style={{fill: 'red'}} /> : <HelpOutlineIcon/>}

                                                    </p>
                                                </TableCell>
                                                <TableCell className='cellPadding' style={{width: "20%"}}>
                                                    <p className={styles.cardCellItems} onClick={() => this.expandShipment(index)}
                                                       style={{textDecoration: 'underline', cursor: 'pointer'}}>
                                                        {entry.orderId}
                                                    </p>
                                                </TableCell>
                                                <TableCell className='cellPadding'>
                                                    <p className={styles.cardCellItems}
                                                       style={{whiteSpace: 'pre-line'}}>
                                                        {entry.message}
                                                    </p>
                                                </TableCell>
                                                <TableCell className='cellPadding'>
                                                    <p className={styles.cardCellItems}>
                                                        {entry.date.replace("T", " ").replace("Z", "")}
                                                    </p>
                                                </TableCell>
                                                <TableCell className='cellPadding'>
                                                    <p className={styles.cardCellItems}>
                                                        {entry.source}
                                                    </p>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                        </>
                }
            </>
        )
    }
}

SystemEventLog.defaultProps = {
    rows: [],
    handleEditShipment: () => {
    },
    shipments: [],
}
SystemEventLog.propTypes = {
    rows:PropTypes.array,
    handleEditShipment: PropTypes.func,
}
const mapStateToProps = (state) => ({
    logs: orderIdSelector(state),
    shipments: shipmentsFromLogsSelector(state),
    carriers: carriersSelector(state),
    orders: ordersSelector(state),
    tags: tagsSelector(state),
    userInfo: userInfoSelector(state),
    store: storeSelector(state),
    stateAndProvinceMapping: stateAndProvinceMappingSelector(state),
})


const actionCreators = {
    getMessages,
    getShipments,
    editOrder,
    editFromAddress,
    getStateAndProvinceMapping,
}


export default withSystemEventLogs({
    mapStateToProps,
    actionCreators,
}, SystemEventLog);
