import React from 'react';

// redux
import {
    fetchRules
} from "../../../redux/actions/automationRules";
import {
    bankAccountsSelector,
    carriersSelector, packagesSelector, shippingTemplatesSelector, tagsSelector
} from "../../../redux/selectors/settings";
import {
    listAllBankAccounts,
    listCarriers, listShippingTemplates
} from "../../../redux/actions/settings";
import {
    rulesSelector
} from "../../../redux/selectors/automationRules";
import {
    userInfoSelector
} from "../../../redux/selectors/auth";
import withShipment from "../../../withShipment";

// import constants
import {
    COMPONENT_SPECS,
    CONDITION_ACTIONS, SET_INVOICE_BANK_ACCOUNT, SET_NUMBER_OF_PACKAGES,
    SET_SHIPPING_ACCOUNT, SET_SHIPPING_TEMPLATE,
} from "./constants";
import {
    DELETE_ACTION, DRAG
} from "../constants";

// import styling
import "../ConditionFilters/FilterBooleanSelect.css";
import './ActionDropdown.css';

// material components
import {Box, Checkbox, FormControlLabel, FormGroup, IconButton, MenuItem, Select, Tooltip} from "@material-ui/core";

// material icons
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import DehazeIcon from '@material-ui/icons/Dehaze';
import PropTypes from "prop-types";
import ActionBoolean from "./ActionBoolean";
import {listTransactionCategories} from "../../../redux/actions/accounting";
import {transactionCategoriesSelector} from "../../../redux/selectors/accounting";

class ActionDropdown extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            actionId: "",
            name: "",
            type: "",
            value: "",
            testSpec: [],
            preDefinedValue: "-",
            disableManualValueField: false,
            preDefinedValues: this.getPredefinedValues(),
            sendNotification: false,
        }
    }

    componentDidMount() {
        this.setState({
            ...this.props.action,
        },
        () => {
            let sendNotification;
            if (typeof this.state.sendNotification === "string") {
                sendNotification = this.state.sendNotification.toLowerCase() === "true"
            } else {
                sendNotification = this.state.sendNotification
            }
            this.setState({
                    sendNotification: sendNotification
            })
        })
        this.props.fetchRules()
        this.props.listCarriers({id: this.props.userInfo.company})
        this.props.listShippingTemplates({id: this.props.userInfo.company})
        this.props.listTransactionCategories()
        this.props.listAllBankAccounts()
        if(this.props.user.routes.includes('accounting') || this.props.user.routes.includes('transactions')){
                    this.props.listTransactionCategories()
        }
    }

    getPredefinedValues = () => {
        let preDefinedValues = CONDITION_ACTIONS.find(action => action.name === this.props.action.name)?.predefinedValues
        if (!preDefinedValues) {
            preDefinedValues = []
        }
        return preDefinedValues
    }

    handleChange = (event) => {
        const value = event.target.value;
        this.setState(
            {
                value,
            },
            () => {
                this.props.updateActionWithParent({ ...this.state });
            }
        );
    };

    handlePredefinedValueChange = (e) => {
        this.setState({
            preDefinedValue: e.target.value,
            disableManualValueField: e.target.value !== '-',
            value: e.target.value !== '-'? '': this.state.value
        }, () => {
            this.props.updateActionWithParent({ ...this.state })})
    }

    handleSendNotificationChange = () => {
        this.setState({
            sendNotification: !this.state.sendNotification
        }, () => {
            this.props.updateActionWithParent({ ...this.state });
        })
    }


    getSpec = () => {
        const action = CONDITION_ACTIONS.filter(n => n.name === this.props.name)
        return action[0].spec
    }

    displayDropdown = () => {
        let dropdownSpec = this.props.isEdit ? this.getSpec() : this.props.action.spec;
        switch (dropdownSpec) {
            case COMPONENT_SPECS.CATEGORIES:
                return (
                    this.props.transactionCategories.slice().sort((a, b) => a.name.localeCompare(b.name)).map(spec => (
                        <MenuItem value={spec.name}>{spec.name}</MenuItem>
                    ))
                )
            case COMPONENT_SPECS.ACCOUNT:
                return (
                    this.props.carriers?.filter(carrier => carrier.isEnabled) // Filter to include only enabled carriers.
                        .slice() // Create a shallow copy to avoid mutating the original array.
                        .sort((a, b) => a.name.localeCompare(b.name)) // Sort by name using localeCompare.
                        .map(spec => (
                            <MenuItem value={spec.carrierId}>
                                {spec.name}, {spec.accountNumber} ({spec.alias})
                            </MenuItem>
                        ))
                )
            case COMPONENT_SPECS.BANK_ACCOUNTS:
                return (
                    this.props.bankAccounts?.slice() // Create a shallow copy to avoid mutating the original array.
                        .sort((a, b) => a.name.localeCompare(b.name)) // Sort by name using localeCompare.
                        .map(spec => (
                            <MenuItem value={spec.bankAccountId}>
                                {spec.name}
                            </MenuItem>
                        ))
                )
            case COMPONENT_SPECS.SHIPPING_TEMPLATE:
                return (
                    this.props.shippingTemplates.map(template => (
                        <MenuItem value={template.shippingTemplateId}>{template.shippingTemplateName}, {template.marketplaceName}</MenuItem>
                    ))
                )
            case COMPONENT_SPECS.ADDRESS:
                return (
                    COMPONENT_SPECS.ADDRESS.map(spec => (
                        <MenuItem value={spec.address1}>{spec.address1} {spec.address2} {spec.ZIP}, {spec.city}, {spec.state}, {spec.country}</MenuItem>
                    ))
                )
            case COMPONENT_SPECS.RULES:
                return (
                    this.props.rules.map(rule => (
                        <MenuItem value={rule.name}>{rule.name}, {rule.group}</MenuItem>
                    ))
                )
            case COMPONENT_SPECS.TAGS:
                return (
                    this.props.tags.map(tag => (
                        <MenuItem value={tag.tagId}>{tag.tagName}</MenuItem>
                    ))
                )
            case COMPONENT_SPECS.REMOVE_TAGS:
                return (
                    this.props.tags.map(tag => (
                        <MenuItem value={tag.tagId}>{tag.tagName}</MenuItem>
                    ))
                )
            case COMPONENT_SPECS.PACKAGING:
                let packages = this.props.packages?.map(item => "Box/" + item.shortcode + " " + "(" + item.nominalSize + ")")
                if (!packages) {packages = []}
                packages = packages.concat(["-", "Box/Other"])
                packages.sort((a, b) => {return a?.toLowerCase().localeCompare(b?.toLowerCase())})
                return (
                    packages?.map(thisPackage => (
                        <MenuItem value={thisPackage}>{thisPackage}</MenuItem>
                    ))
                )
            default:
                return (
                    dropdownSpec.map(spec => (
                        <MenuItem value={spec}>{spec}</MenuItem>
                    ))
                )
        }
    }

    render() {
        return (
            <Box
                className='actionDropdownSelect'
                flexDirection='row'
                justifyContent='left'
                alignItems='center'
            >
                <Box
                    display='flex'
                    flexDirection='row'
                    textAlign='left'
                    alignItems='center'
                    width='100%'
                >
                <Box className='actionDropdownSelectName'>{this.state.name}</Box>
                <Select
                    className={this.state.name === SET_SHIPPING_ACCOUNT ? 'actionDropdownSelectOptionsForSetShippingAccount' : this.state.name === SET_SHIPPING_TEMPLATE  ? 'predefined-dropdown-shipping-template' : 'actionDropdownSelectOptions'}
                    variant='outlined'
                    value={this.state.value}
                    onChange={this.handleChange}
                    disabled={this.state.disableManualValueField}
                >
                    {this.displayDropdown()}
                </Select>
                {!this.state.preDefinedValues || this.state.preDefinedValues.length <= 0 ? <></> :
                    <React.Fragment>
                        <div>
                            OR
                        </div>
                        <Select
                            variant="outlined"
                            name="predefinedValue"
                            onChange={this.handlePredefinedValueChange}
                            value={this.state.preDefinedValue === "null" ? '-' : this.state.preDefinedValue}
                            className= {this.state.name === SET_INVOICE_BANK_ACCOUNT ? 'predefined-dropdown-bank-account' : 'predefined-dropdown'}
                        >
                            {this.state.preDefinedValues.map((value, index) => (
                                <MenuItem value={value} key={index}>{value === '-'? 'Select a predefined value': value}</MenuItem>
                            ))
                            }
                        </Select>
                    </React.Fragment>
                }

                {/* in order to make sure that the delete icon aligns, we add an empty component */}
                <div className= {this.state.name == SET_SHIPPING_TEMPLATE? 'actionDropdownEmptyDiv_WithCheckBox' : 'actionDropdownEmptyDiv'}>
                    <Tooltip title={DELETE_ACTION}>
                        <IconButton
                            aria-label={DELETE_ACTION}
                            onClick={() => this.props.deleteAction(this.state.actionId)}
                        >
                            <DeleteOutlineIcon variant='outlined' fontSize='small' />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={DRAG}>
                        <IconButton
                            aria-label='drag'
                        >
                            <DehazeIcon variant='outlined' fontSize='small' />
                        </IconButton>
                    </Tooltip>
                </div>
                </Box>
                {this.state.name === SET_SHIPPING_TEMPLATE ?
                    <Box
                        className='actionDropdownSelectName'
                    >
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox
                                    name="value"
                                    checked={this.state.sendNotification}
                                    onChange={this.handleSendNotificationChange}/>}
                                    labelPlacement="start"
                                label="Send notification"
                            />
                        </FormGroup>
                    </Box> : null}
            </Box>
        );
    }

}

const mapStateToProps = (state) => ({
    rules: rulesSelector(state),
    carriers: carriersSelector(state),
    userInfo: userInfoSelector(state),
    user: userInfoSelector(state),
    tags: tagsSelector(state),
    packages: packagesSelector(state),
    shippingTemplates: shippingTemplatesSelector(state),
    bankAccounts: bankAccountsSelector(state),
    transactionCategories: transactionCategoriesSelector(state),
})

const actionCreators = {
    fetchRules,
    listCarriers,
    listShippingTemplates,
    listTransactionCategories,
    listAllBankAccounts
}

ActionDropdown.propTypes = {
    action: PropTypes.array,
    name: PropTypes.string,
    updateActionWithParent: PropTypes.func,
    deleteAction: PropTypes.func,
    isEdit: PropTypes.bool
};

ActionDropdown.defaultProps = {
    action: {},
    name: "",
    updateActionWithParent: ()=>{},
    deleteAction: ()=>{},
    isEdit: true,
    bankAccounts: [],
    transactionCategories:[]
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, ActionDropdown);