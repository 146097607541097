import React from 'react';

// material components
import {
    Toolbar,
    Typography,
    IconButton,
    Tooltip,
    Button
} from '@material-ui/core';

// material icons
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import KeyboardBackspaceIcon  from '@material-ui/icons/KeyboardBackspace';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AddIcon from "@material-ui/icons/Add";
import FileCopyIcon from '@material-ui/icons/FileCopy';

// custom components
import DeleteDialog from "../global/DeleteDialog";

// import styling
import "../global/TableToolbar.css";

// import constants
import {
    DELETE_RULE,
    AUTOMATION_RULES,
    EDIT_RULE,
    COPY_RULE, CREATE_RULE, AUTOMATION_SETTINGS,
} from './constants';
import PropTypes from "prop-types";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import SettingsIcon from "@material-ui/icons/Settings";

class AutomationRulesTableToolbar extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
        open: false
    }
  }

  handleClose = () => {
      this.setState({open: !this.state.open})
  }

  displayToolbar = () => {
      //TODO HAVE TO CLEAN THIS UP
      switch (this.props.numSelected) {
        case 1:
            return (
                <div className="contextualMenu">
                    <div className="contextualMenuText">{this.props.numSelected} selected</div>
                    <Tooltip title={DELETE_RULE}>
                        <IconButton aria-label={DELETE_RULE} onClick={this.handleClose}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={EDIT_RULE}>
                        <IconButton aria-label={EDIT_RULE} onClick={this.props.prepEdit}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={COPY_RULE}>
                        <IconButton aria-label={COPY_RULE} onClick={this.props.handleCopy}>
                            <FileCopyIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            )
          case 0:
              return (<div />)
          default:
              return (
                  <div className="contextualMenu">
                      <div className="contextualMenuText">{this.props.numSelected} selected</div>
                      <div />
                  </div>
              )
      }
  }

  render() {
    return (
        <>
          <DeleteDialog
            open={this.state.open}
            handleClose={this.handleClose}
            rule={this.props.numSelected === 1 ? this.props.selected[0] : {name: 'none'}}
            handleDelete={this.props.handleDelete}
          />
          <Toolbar className="toolbar">
              {this.props.path === '/rules/create' ?
                  <React.Fragment>
                      <Tooltip title={CREATE_RULE}>
                          <IconButton onClick={() => this.props.redirect(this.props.path)} >
                              <AddCircleIcon />
                          </IconButton>
                      </Tooltip>
                      {!this.props.user?.routes?.includes('automation-settings') ? null :
                          <Tooltip title={AUTOMATION_SETTINGS}>
                              <IconButton onClick={() => this.props.navigateToSettingsPage()} >
                                  <SettingsIcon />
                              </IconButton>
                          </Tooltip>
                      }
                  </React.Fragment>
                :
                  <Tooltip title={this.props.title}>
                      <IconButton onClick={() => this.props.handleBack()} >
                          <KeyboardBackspaceIcon />
                      </IconButton>
                  </Tooltip>
              }
              

              {this.displayToolbar()}
            <Typography className="titleTypography" variant="h6" id="tableTitle">
              {AUTOMATION_RULES}
            </Typography>
          </Toolbar>
        </>
    );
  }
}

const btnStyle = {
    height: '40px',
    width: '90px',
    minWidth: '90px',
    maxWidth: '90px',
    marginRight: '20px'
}

AutomationRulesTableToolbar.propTypes = {
    title: PropTypes.string,
    numSelected: PropTypes.number,
    selected: PropTypes.array,
    redirect: PropTypes.func,
    prepEdit: PropTypes.func,
    handleCopy: PropTypes.func,
    handleDelete: PropTypes.func
}

AutomationRulesTableToolbar.defaultProps = {
    title: "No title specified",
    numSelected: 0,
    selected: [],
    redirect: ()=>{},
    prepEdit: ()=>{},
    handleCopy: ()=>{},
    handleDelete: ()=>{}
}

export default AutomationRulesTableToolbar