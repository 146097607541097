export const DEFAULT_ROWS_PER_PAGE = 50;

//toolbar
export const BACK_TO_MENU_TITLE = "Back to Menu";
export const MANAGE_CATEGORY = "Manage Category";

export const IMPORT_PIPELINE = "Import CSV";
//table

export const EDITING_CATEGORY_TABLE_HEAD_CELLS = [
    { id: 'categoryName', center: false, disablePadding: false, label: 'Category Name'},
    { id: 'ending', center: true, disablePadding: false, label: ''}

]
export const ROWS_PER_PAGE_LABEL = "Category per page"
export const DEFAULT_SORT_BY_HEAD_CELL = "categoryName"
export const REMOVE_CATEGORY_TITLE = "Remove Category"
export const ADD_CATEGORY_TITLE = "Add Category"


export const CANCEL = "cancel";
export const SAVE = "save";




