import React, { Component } from 'react';
import {
    TextField, Tooltip, IconButton
} from "@material-ui/core";
import "./ManageProfitPage.css"
import CustomToolbar from "../../global/CustomToolbar";
import {
    AUTOMATION_SETTINGS_TOOLBAR_TITLE,
    OPTIONS,
    OTHER_COSTS_INFO_PART1,
    PRODUCT_COST_AVERAGE_INFO_PART1,
    PRODUCT_COST_AVERAGE_INFO_PART2,
    REFERRAL_FEE_INFO,
    PRICE_INFO,
    SHIPPING_COST_INFO,
    CURRENCY,
    BOX_COST_INFO_PART1,
    BOX_COST_INFO_PART2,
    INTRO
} from "./constants.js"
import RemoveIcon from "@material-ui/icons/Remove";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import InputAdornment from "@material-ui/core/InputAdornment";
import SwapCallsIcon from '@material-ui/icons/SwapCalls';
import Button from "@material-ui/core/Button";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { userInfoSelector } from "../../../redux/selectors/auth"
import withShipment from "../../../withShipment";
import {tableSettingSelector, automationInfoSelector} from "../../../redux/selectors/account";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { updateCompany, getCompany } from "../../../redux/actions/automationRules"
import {AddCircleOutline as AddIcon, ArrowDownward} from "@material-ui/icons";
import SettingsIcon from "@material-ui/icons/Settings";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {Box, FormControl, Radio, RadioGroup, Select, MenuItem} from "@mui/material";
import DeleteIcon from "@material-ui/icons/Delete";
import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";
import {AUTOMATION_RULES_PAGES} from "../constants";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import green from "@material-ui/core/colors/green";
import Divider from "@material-ui/core/Divider";


class ProfitThresholdPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: 'Welcome to the Welcome Page!',
            selectedStatus: false,
            otherCostStatus: false,
            productCostFromDate: '1',
            boxCostFromDate: '1',
            otherCostText: '0.00',
            percentageCostText: '',
            dollarCostText: '',
            referralFeeText: '15',
            boxAverageCost: '',
            currencyRates:[],
            defaultCurrency: ""
        };
    }



    updateState = () => {
        this.setState({
            selectedStatus: this.props?.automationSettings?.accountForProfitOrLoss === undefined ? false : this.props?.automationSettings?.accountForProfitOrLoss,
            otherCostStatus: this.props?.automationSettings?.otherCosts === undefined ? false : this.props?.automationSettings?.otherCosts,
            productCostFromDate: this.props?.automationSettings?.productCostDateRange?.fromDate === undefined ? "1" : this.props?.automationSettings?.productCostDateRange?.fromDate,
            boxCostFromDate: this.props?.automationSettings?.boxCostDateRange?.fromDate === undefined ? "1" : this.props?.automationSettings?.boxCostDateRange?.fromDate,
            otherCostText: this.props?.automationSettings?.otherCostText === undefined ? "0.00" : this.props.automationSettings?.otherCostText,
            referralFeeText: this.props?.automationSettings?.referralFee === undefined ? "15" : this.props?.automationSettings?.referralFee,
            currencyRates: this.props?.automationSettings?.currencyRates === undefined ? [] : this.props?.automationSettings?.currencyRates,
            defaultCurrency: this.props?.automationSettings?.defaultCurrency === undefined ? [] : this.props?.automationSettings?.defaultCurrency
        });
    }

    componentDidMount = () => {
        this.props.getCompany()
        this.updateState()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.automationSettings !== this.props.automationSettings) {
            this.updateState()
        }
    }

    updateHandler = () => {
        let inputObject = {};
        let boxDateRange = {};
        let productDateRange = {};
        productDateRange["fromDate"] = this.state.productCostFromDate;
        boxDateRange["fromDate"] = this.state.boxCostFromDate;
        inputObject["accountForProfitOrLoss"] = this.state.selectedStatus;
        inputObject["productCostDateRange"] = productDateRange;
        inputObject["boxCostDateRange"] = boxDateRange;
        inputObject["otherCosts"] = this.state.otherCostStatus;
        inputObject["otherCostText"] = this.state.otherCostText;
        inputObject["referralFee"] = this.state.referralFeeText;
        inputObject["currencyRates"] = this.state.currencyRates;
        inputObject["defaultCurrency"] = this.state.defaultCurrency;
        this.props.updateCompany({
            companyId: this.props.user.company,
            automationSetting: inputObject,
        });
    }

    handleStatusChange = async () => {
        await this.setState({
            selectedStatus: !this.state.selectedStatus,
        });
        this.updateHandler();
    }

    handleSwapChange = async () => {
        await this.setState({
            otherCostStatus: !this.state.otherCostStatus,
        });
        this.updateHandler();
    }

    handleDateChange = async (date, fromOrTo) => {
        if(fromOrTo === 'from') {
            await this.setState({
                productCostFromDate: date
            });
        }
        this.updateHandler();
    }

    handleBoxCostAvgOverDateChange = async (date, fromOrTo) => {
        if(fromOrTo === 'from') {
            await this.setState({
                boxCostFromDate: date
            });
        }
        this.updateHandler();
    }

    handleReferralFeeChange = async (text) => {
        await this.setState({
            referralFeeText: text,
        });
        this.updateHandler()
    }

    handleOtherCostChange = async (text) => {
        await this.setState({
            otherCostText: text,
        });
        this.updateHandler();
    }

    getToday = () => {
        var today = new Date()
        today = today.toString().split('GMT')[0] + "Z"
        return new Date(today)
    }

    handlePageToggle = async (value) => {
        if (value === "percentage") {
            await this.setState({
                otherCostStatus: false,
            })
        }
        else if (value === "dollarAmount") {
            await this.setState({
                otherCostStatus: true,
            })
        }
        this.updateHandler();
    }


    render() {
        return (
            <>
                <TopToolbar
                    pageName={AUTOMATION_SETTINGS_TOOLBAR_TITLE}
                    menuItems={[
                        {title: "Back", icon: <KeyboardBackspaceIcon/>, onClick: () => {this.props.setCurrentPage(AUTOMATION_RULES_PAGES.TABLE)} },
                    ]}
                />
                <div className="page">
                    <div>
                        <div className="profitStyle">
                            Profit / Loss calculation
                            <br/>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.selectedStatus}
                                                   onChange={this.handleStatusChange} name="Account for Profit / Loss calculation"
                                                   style={{ color: '#acd685' }} />}
                                label="Account for Profit / Loss calculation"
                            />
                            <div class="smaller-font">
                                <p>{INTRO} </p>
                            </div>
                        </div>
                        <div className="other-margin"  style={{marginLeft:"34px"}}>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <TextField
                                    label="Price"
                                    InputProps={{
                                        disabled: true,
                                        endAdornment: (
                                            <InputAdornment position="end" >
                                                <Tooltip
                                                    enterTouchDelay={0}
                                                    leaveTouchDelay={30000}
                                                    enterDelay={400}
                                                    title={
                                                        <React.Fragment>
                                                            {PRICE_INFO}
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <IconButton aria-label='info'>
                                                        <InfoOutlinedIcon variant='outlined' fontSize='small' />
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        )
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{className: "price-label"}}
                                    style={{marginBottom: "5px", maxWidth: "175px", minWidth: "175px"}}
                                />
                            </div>
                        </div>
                        <div className="signs">
                            -
                        </div>
                        <div className="other-margin">
                            <div style={{display: "flex", alignItems: "center"}}>
                                <TextField
                                    label="Referral Fee"
                                    InputProps={{
                                        disabled: true,
                                        endAdornment: (
                                            <InputAdornment position="end" >
                                                <Tooltip
                                                    enterTouchDelay={0}
                                                    leaveTouchDelay={30000}
                                                    enterDelay={400}
                                                    // leaveDelay={1000000} // for testing purposes
                                                    title={
                                                        <React.Fragment>
                                                            {REFERRAL_FEE_INFO}
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <IconButton aria-label='info'>
                                                        <InfoOutlinedIcon variant='outlined' fontSize='small' />
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        )
                                    }}
                                    variant="outlined"
                                    style={{marginBottom: "5px", maxWidth: "175px", minWidth: "175px"}}
                                    InputLabelProps={{className: "referral-label"}}
                                />
                            </div>
                            <div className='vl'></div>
                            <TextField
                                label="Referral Fee"
                                InputProps={{endAdornment: (<InputAdornment position="end">%</InputAdornment>)}}
                                variant="outlined"
                                style={{marginTop: "10px", maxWidth: "175px", minWidth: "175px"}}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => this.handleReferralFeeChange(e.target.value)}
                                value={this.state.referralFeeText}
                                error={isNaN(parseFloat(this.state.referralFeeText)) || !isFinite(this.state.referralFeeText)}
                            />
                        </div>
                        <div className="signs">
                            -
                        </div>
                        <div className="other-margin">
                            <div style={{display: "flex", alignItems: "center"}}>
                                <TextField
                                    label="Product Cost"
                                    InputProps={{
                                        disabled: true,
                                        endAdornment: (
                                            <InputAdornment position="end" >
                                                <Tooltip
                                                    enterTouchDelay={0}
                                                    leaveTouchDelay={30000}
                                                    enterDelay={400}
                                                    // leaveDelay={1000000} // for testing purposes
                                                    title={
                                                        <React.Fragment>
                                                            <p>{PRODUCT_COST_AVERAGE_INFO_PART1}</p>
                                                            <p>{PRODUCT_COST_AVERAGE_INFO_PART2}</p>
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <IconButton aria-label='info'>
                                                        <InfoOutlinedIcon variant='outlined' fontSize='small' />
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        )
                                    }}
                                    variant="outlined"
                                    style={{marginBottom: "5px", maxWidth: "175px", minWidth: "175px"}}
                                    InputLabelProps={{className: "product-label"}}
                                />
                            </div>
                            <div className='vl'></div>
                            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                <Autocomplete
                                    options={OPTIONS}
                                    getOptionLabel={(option) => option.label}
                                    className="date-box"
                                    name="addTag"
                                    onChange={(event, value) => {
                                        this.handleDateChange(value.id, 'from')
                                    }}
                                    value={{label: this.state.productCostFromDate === "1" ? "1 Month" : this.state.productCostFromDate + " Months"}}
                                    renderInput={(params) =>
                                        <div>
                                            <TextField
                                                {...params}
                                                label="Average Over"
                                                InputLabelProps={{ shrink: true }}
                                                variant="outlined"
                                                name="addTag"
                                            />
                                        </div>
                                    }
                                />
                            </div>
                        </div>
                        <div className="signs">
                            -
                        </div>
                        <div className="other-margin">
                            <div style={{display: "flex", alignItems: "center"}}>
                                <TextField
                                    label="Shipping Cost"
                                    InputProps={{
                                        disabled: true,
                                        endAdornment: (
                                            <InputAdornment position="end" >
                                                <Tooltip
                                                enterTouchDelay={0}
                                                leaveTouchDelay={30000}
                                                enterDelay={400}
                                                title={
                                                    <React.Fragment>
                                                        {SHIPPING_COST_INFO}
                                                    </React.Fragment>
                                                    }
                                                >
                                                    <IconButton aria-label='info'>
                                                        <InfoOutlinedIcon variant='outlined' fontSize='small' />
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        )
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{className: "shipping-label"}}
                                    style={{marginBottom: "5px", maxWidth: "175px", minWidth: "175px"}}
                                />
                            </div>
                        </div>
                        <div className="signs">
                            -
                        </div>
                        <div className="other-margin">
                            <div style={{display: "flex", alignItems: "center"}}>
                                <TextField
                                    label="Box Cost"
                                    InputProps={{
                                        disabled: true,
                                        endAdornment: (
                                            <InputAdornment position="end" >
                                                <Tooltip
                                                    enterTouchDelay={0}
                                                    leaveTouchDelay={30000}
                                                    enterDelay={400}
                                                    // leaveDelay={1000000} // for testing purposes
                                                    title={
                                                        <React.Fragment>
                                                            <p>{BOX_COST_INFO_PART1}</p>
                                                            <p>{BOX_COST_INFO_PART2}</p>
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <IconButton aria-label='info'>
                                                        <InfoOutlinedIcon variant='outlined' fontSize='small' />
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        )
                                    }}
                                    variant="outlined"
                                    style={{marginBottom: "5px", maxWidth: "175px", minWidth: "175px"}}
                                    InputLabelProps={{className: "box-label"}}
                                />
                            </div>
                            <div className='vl'></div>
                            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                <Autocomplete
                                    options={OPTIONS}
                                    getOptionLabel={(option) => option.label}
                                    className="date-box"
                                    name="addTag"
                                    onChange={(event, value) => {
                                        this.handleBoxCostAvgOverDateChange(value.id, 'from')
                                    }}
                                    value={{label: this.state.boxCostFromDate === "1" ? "1 Month" : this.state.boxCostFromDate + " Months"}}
                                    renderInput={(params) =>
                                        <div>
                                            <TextField
                                                {...params}
                                                label="Average Over"
                                                InputLabelProps={{ shrink: true }}
                                                variant="outlined"
                                                name="addTag"
                                            />
                                        </div>
                                    }
                                />
                            </div>

                        </div>
                        <div className="signs">
                            -
                        </div>
                        <div className='tick-box'>
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue={this.state.otherCostStatus ? "dollarAmount" : "percentage"}
                                    name="radio-buttons-group"
                                    onChange={(event => {
                                        this.handlePageToggle(event.target.value)
                                    })}
                                >
                                    <FormControlLabel value="percentage" control={<Radio />} label="%" style={{maxHeight: "20px", marginRight:"0px"}}/>
                                    <FormControlLabel value="dollarAmount" control={<Radio />} label="$"  style={{maxHeight: "23px", marginRight:"0px"}}/>
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <div className="other-cost-margin">
                            <div style={{display: "flex", alignItems: "center"}}>
                                <TextField
                                    style={{marginBottom: "5px", maxWidth: "150px", minWidth: "150px"}}
                                    variant='outlined'
                                    label='Other Costs'
                                    error={isNaN(parseFloat(this.state.otherCostText)) || !isFinite(this.state.otherCostText)}
                                    value={this.state.otherCostText}
                                    InputProps={ this.state.otherCostStatus ? {
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    $
                                                </InputAdornment>
                                            ),
                                        } :
                                        {
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    %
                                                </InputAdornment>
                                            ),
                                        }
                                    }
                                    onChange={(e) => this.handleOtherCostChange(e.target.value)}
                                />
                                <Tooltip
                                    enterTouchDelay={0}
                                    leaveTouchDelay={30000}
                                    enterDelay={400}
                                    // leaveDelay={1000000} // for testing purposes
                                    title={
                                        <React.Fragment>
                                            <p>{OTHER_COSTS_INFO_PART1}</p>
                                        </React.Fragment>
                                    }
                                >
                                    <IconButton aria-label='info'>
                                        <InfoOutlinedIcon variant='outlined' fontSize='small' />
                                    </IconButton>
                                </Tooltip>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        );
    }

}

const mapStateToProps = (state) => ({
    user: userInfoSelector(state),
    tableSetting: tableSettingSelector(state),
    automationSettings: automationInfoSelector(state)
})

const actionCreators = {
    getCompany,
    updateCompany,
}

ProfitThresholdPage.defaultProps = {
    tableSetting: {}
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, ProfitThresholdPage);