import React from "react";
import ErrorFeedbackBar from "../../global/ErrorFeedbackBar";
import {ROWS_PER_PAGE, SALES_PIPELINE_PAGES} from "./constants";
import FeedbackBar from "../../feedbackBar/FeedbackBar";
import withShipment from "../../../withShipment";
import Papa from 'papaparse';
import {statusSelector} from "../../../redux/selectors/global";
import {clearStatus} from "../../../redux/actions/global";
import SalesPipelineForm from "./SalesPipelineForm";
import {getUsers} from "../../../redux/actions/auth";
import {userInfoSelector, usersSelector} from "../../../redux/selectors/auth";
import ManageCategoryTable from "./ManageCategory/ManageCategoryTable";
import {
    deletePipeline, deletePipelineAttachment,
    editCategory, getPipelineAttachment, importPipeline,
    listAllPipelines,
    listCategory,
    savePipeline,
    storeCategory, updatePipelineChanged
} from "../../../redux/actions/customer";
import PropTypes from "prop-types";
import {
    categoriesSelector,
    pipelineAttachmentPDFSelector, pipelineChangedSelector,
    pipelinesSelector
} from "../../../redux/selectors/customer";
import SalesPipelineTable from "./SalesPipelineTable";

class SalesPipeline extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditingPipeline: [],
            currentPage: SALES_PIPELINE_PAGES.TABLE,
            feedbackBarOpen: false,
            error: false,
            formState: {
                contactName: '',
                phone: '',
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                zip: '',
                country: '',
                email:'',
                category: null,
                stage: null,
                salesPerson: null,
                comments: [],
            },
            fromDate: this.getFromDate(),
            toDate: this.getToDate(),
            fetchedList: true,
            page: 0,
            rowsPerPage: ROWS_PER_PAGE,
            searchText: '',
            selecting: false,
            selectedEntries: {},
            feedbackStatus: '',
            feedbackMessage: '',

        }
    }

    componentDidMount() {
        this.props.getUsers()
        this.props.listCategory()
        this.setState({
            fetchedList: true
        })

    }

    setFetchedList = (fetched) => {
        this.setState({
            fetchedList: fetched
        })
    }

    setPage = (page) => {
        this.setState({
            page: page
        })
    }

    setRowsPerPage = (rowsPerPage) => {
        this.setState({
            rowsPerPage: rowsPerPage
        })
    }

    fetchSalesPipeline = (fromDate, toDate) => {
        this.props.listAllPipelines({
            fromDate: fromDate,
            toDate:  toDate
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.status.statusText && prevProps.status.statusText !== this.props.status.statusText){
            this.openFeedbackBar()
        }
    }

    getFromDate = () => {
        const date = new Date();
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setDate(date.getDate() - 14)
        return date;
    }

    getToDate = () => {
        const date = new Date();
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
        return date;
    }

    setCurrentPage = (newPage,pipeline) => {
        this.setState({
            currentPage: newPage,
            isEditingPipeline: pipeline
        })
    }

    displayTable = () => {
        return (
            <SalesPipelineTable
                deletePipeline={this.props.deletePipeline}
                setCurrentPage={this.setCurrentPage}
                openFeedbackBar={this.openFeedbackBar}
                categories={this.props.categories ? this.props.categories.map(cat => cat.categoryName) : []}
                importCSV={this.importCSV}
                data={this.props.pipelines}
                handleSearchChange={this.handleSearchTextChange}
                searchText={this.state.searchText}
                page={this.state.page}
                setPage={this.setPage}
                rowsPerPage={this.state.rowsPerPage}
                setRowsPerPage={this.setRowsPerPage}
                fetchSalesPipeline={this.props.listAllPipelines}
                user={this.props.user}
                handleDateChange={this.handleDateChange}
                setFetchedList={this.setFetchedList}
                checkedEntries={this.state.selectedEntries}
                fromDate={this.state.fromDate}
                toDate={this.state.toDate}
                setSelected={(newEntries, selecting) => this.setState({ selectedEntries: newEntries, selecting: selecting })}

            />
        )
    }

    handleDateChange = (date, key) => {
        this.setState({
            [key]: date
        })
    }

    handleSearchTextChange = (e) => {
        this.setState({
            searchText: e.target.value
        })
    }

    displayForm = () => {
        return (
            <SalesPipelineForm
                pipelines={this.props.pipelines}
                editingPipelineChanged={this.props.editingPipelineChanged}
                updatePipelineChanged={this.props.updatePipelineChanged}
                attachment={this.props.pipelinePDF}
                deletePipelineAttachment={this.props.deletePipelineAttachment}
                getPipelineAttachment={this.props.getPipelineAttachment}
                categories={this.props.categories}
                users={this.props.users}
                currentUser={this.props.currentUser}
                pipeline={this.state.isEditingPipeline}
                setCurrentPage={this.setCurrentPage}
                onFormChange={this.handleFormChange}
                handleSave={this.handleSave}
            />
        )
    }

    displaySetting = () => {
        return (
            <ManageCategoryTable
                categories={this.props.categories}
                listCategory={this.props.listCategory}
                storeCategory={this.props.storeCategory}
                editCategory={this.props.editCategory}
                setCurrentPage={this.setCurrentPage}
                openFeedbackBar={this.openFeedbackBar}
            />
        )
    }

    displayCurrentPage = () => {
        switch (this.state.currentPage) {
            case SALES_PIPELINE_PAGES.TABLE:
                return this.displayTable()
            case SALES_PIPELINE_PAGES.ADD_SALES_PIPELINE:
                return this.displayForm()
            case SALES_PIPELINE_PAGES.ADD_SALES_SETTING:
                return this.displaySetting()
            default:
                console.log("Error: current transactions page doesn't exist")
                return <div>We encountered an error. Please refresh the page.</div>
        }
    }

    openFeedbackBar = (status,message) => {
        this.setState({feedbackStatus: status, feedbackMessage: message,feedbackBarOpen: true})
    }

    closeFeedbackBar = () => {
        this.setState({ feedbackBarOpen: false, feedbackStatus: '', feedbackMessage:''}, () => {
            this.props.clearStatus()
        })
    }


    importCSV = () => {
        // Create an input element dynamically for CSV file upload
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = '.csv';

        // Handle the change event after the file is selected
        fileInput.onchange = (e) => {
            const file = e.target.files[0];
            if (file) {
                Papa.parse(file, {
                    header: true, // CSV file contains header
                    skipEmptyLines: true, // Skip empty rows
                    complete: (result) => {
                        const data = result.data.map(row => ({
                            companyName: row.companyName,
                            contactName: row.contactName,
                            email: row.email,
                            phone: row.phone,
                            addressLine1: row.addressLine1,
                            addressLine2: row.addressLine2,
                            city: row.city,
                            state: row.state,
                            zip: row.zip,
                            country: row.country,
                            category: row.category,
                            stage: row.stage,
                            salesPerson: row.salesPerson,
                            comments: [
                                {
                                    user: `${this.props.currentUser.firstname} ${this.props.currentUser.lastname}`,
                                    date: new Date().toISOString(),
                                    comment: 'Initial Contact',
                                }
                            ]
                        }));

                        const callback = (error, response) => {
                            if (error) {
                                this.openFeedbackBar('error', response)
                            } else {
                                this.openFeedbackBar('success', response)
                            }
                        };


                        this.props.importPipeline(data,callback)


                    },
                    error: (error) => {
                        console.error('Error parsing CSV:', error);
                    }
                });
            }
        };

        // Trigger the file input dialog
        fileInput.click();
    }

    handleSave = (data) => {
        const callback = (error, response) => {
            if (error) {
                this.openFeedbackBar('error', response)
            } else {
                this.openFeedbackBar('success', response)
            }
            this.setCurrentPage(SALES_PIPELINE_PAGES.TABLE, {})
        };

        this.props.savePipeline(data,callback)


}


    handleFormChange = (formState) => {
        this.setState({ formState });
    }

    render() {
        return (
            <React.Fragment>
                <ErrorFeedbackBar/>
                <FeedbackBar
                    open={this.state.feedbackBarOpen}
                    handleClose={this.closeFeedbackBar}
                    severity={this.state.feedbackStatus}
                    message={this.state.feedbackMessage}
                />
                {this.displayCurrentPage()}
            </React.Fragment>
        )
    }
}

SalesPipeline.defaultProps = {

}

const mapStateToProps = (state) => ({
    status: statusSelector(state),
    users: usersSelector(state),
    currentUser: userInfoSelector(state),
    categories: categoriesSelector(state),
    pipelines: pipelinesSelector(state),
    pipelinePDF: pipelineAttachmentPDFSelector(state),
    editingPipelineChanged: pipelineChangedSelector(state),
})

const actionCreators = {
    clearStatus,
    getUsers,
    listCategory,
    storeCategory,
    editCategory,
    listAllPipelines,
    deletePipeline,
    savePipeline,
    getPipelineAttachment,
    deletePipelineAttachment,
    updatePipelineChanged,
    importPipeline
}

SalesPipeline.propTypes = {
    categories: PropTypes.array,
    pipelines: PropTypes.array,
}
SalesPipeline.defaultProps = {
    categories: [],
    pipelines: [],
}

export default withShipment({
    actionCreators,
    mapStateToProps
}, SalesPipeline);