import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import React from "react";
import withShipment from "../../../withShipment";
import {
    ADD_BROKER, ADD_CARRIER,
    CREATE_FTL,
    EDIT,
    FTL_PAGES,
    FTL_SETUP,
    FTL_STATUSES,
    FULL_TRUCKLOAD,
    FULL_TRUCKLOAD_TABLE_HEADERS, VIEW_BOL, VIEW_DOCUMENTS
} from "../constants";
import {fetchFullTruckloads} from "../../../redux/actions/fullTruckload";
import {allFullTruckloadsSelector} from "../../../redux/selectors/fullTruckload";
import {Layout} from "../../global/Table/TablePageHelpers/LayoutObject";
import TableCell from "@material-ui/core/TableCell";
import TablePage from "../../global/Table/TablePage";
import SettingsIcon from "@material-ui/icons/Settings";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import {Filter} from "../../global/Table/TablePageHelpers/FilterObject";
import DescriptionIcon from "@material-ui/icons/Description";
import FileCopyIcon from "@material-ui/icons/FileCopy";

class FullTruckloadTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        this.props.fetchFullTruckloads()
    }

    getTableHeaders = () => {
        let headers = []
        headers.push(Layout.columnHead(null,null,null))
        FULL_TRUCKLOAD_TABLE_HEADERS.forEach((headCell) => {
            headers.push(Layout.columnHead(headCell.text, headCell.id, "center"))
        })
        headers.push(Layout.columnHead(null,null,null))
        headers.push(Layout.columnHead(null,null,null))
        return headers
    }

    handleDocumentsPreview = (row, isFinalDoc) => {
        this.props.previewFullTruckloadDocument({path: isFinalDoc ? row.finalDocument?.path : row.billOfLading?.path, id: row.fullTruckloadId})
        this.props.setCurrentPage(FTL_PAGES.PREVIEW, row, FTL_PAGES.TABLE)
    }

    render() {
        return (
            <React.Fragment>
                <TopToolbar
                    pageName={FULL_TRUCKLOAD}
                    menuItems={[
                        {title: CREATE_FTL, icon: <AddCircleIcon/>, onClick: () => {this.props.setCurrentPage(FTL_PAGES.CREATE)}},
                        {title: FTL_SETUP, icon: <SettingsIcon/>, onClick: () => {this.props.setCurrentPage(FTL_PAGES.SETTINGS)}},
                    ]}
                />

                <TablePage
                    tableName="ftlTable"
                    tableRows={this.props.tableRows}
                    hasSearchField
                    paginationText="Shipments per page"
                    searchStrings={(row) => row.items.map(item => item.shortcode).concat(FULL_TRUCKLOAD_TABLE_HEADERS.map(column => row[column.id])).concat([row.fromAddress.selectedLocation, row.toAddress.selectedLocation]) }
                    filters={ [
                        Filter.createFilter('shipFromDate', (row) => row.shipDate, null, Filter.relations.greaterThanEquals, true, Filter.displayValueOverrides.valueIsDate, new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7).toISOString()),
                        Filter.createFilter('shipToDate', (row) => row.shipDate, null, Filter.relations.lessThanEquals, true, Filter.displayValueOverrides.valueIsDate, new Date(new Date().setHours(23, 59, 59, 999)).toISOString()),
                        Filter.createFilter('isDraft', (row) => (row.status !== FTL_STATUSES.DRAFT), false, Filter.relations.equals, false),
                        Filter.createFilter('isApprovalRequested', (row) => (row.status !== FTL_STATUSES.REQUEST_APPROVAL), false, Filter.relations.equals, false),
                        Filter.createFilter('isApproved', (row) => (row.status !== FTL_STATUSES.APPROVED), false, Filter.relations.equals, false),
                        Filter.createFilter('isShipped', (row) => (row.status !== FTL_STATUSES.SHIPPED), false, Filter.relations.equals, false),
                        Filter.createFilter('isReceived', (row) => (row.status !== FTL_STATUSES.RECEIVED), false, Filter.relations.equals, false),
                        Filter.createFilter('isCompleted', (row) => (row.status !== FTL_STATUSES.COMPLETED), false, Filter.relations.equals, false),
                    ] }
                    filterCheckboxes={ [
                        Layout.checkBox('isDraft', FTL_STATUSES.DRAFT, null, null, null, true),
                        Layout.checkBox('isApprovalRequested', FTL_STATUSES.REQUEST_APPROVAL, null, null, null, true),
                        Layout.checkBox('isApproved', FTL_STATUSES.APPROVED, null, null, null, true),
                        Layout.checkBox('isShipped', FTL_STATUSES.SHIPPED, null, null, null, true),
                        Layout.checkBox('isReceived', FTL_STATUSES.RECEIVED, null, null, null, true),
                        Layout.checkBox('isCompleted', FTL_STATUSES.COMPLETED, null, null, null, true),
                    ] }
                    filterDropdown={ Layout.newLayout(2, [
                        Layout.row([
                            Layout.rowElement(1, 'shipFromDate', 'Ship Date (From)', Layout.elementStyle.dateSelect),
                            Layout.rowElement(1, 'shipToDate', 'Ship Date (To)', Layout.elementStyle.dateSelect)
                        ])
                    ]) }
                    tableColumnHeads={this.getTableHeaders()}
                    tableRow={(row, index) => <React.Fragment>
                    <TableCell style={{textAlign: "center", width: "1%"}}>
                        <Tooltip title={EDIT}>
                            <IconButton onClick={() => {this.props.setCurrentPage(FTL_PAGES.UPDATE, row)}}>
                                <EditIcon
                                    fontSize='medium'
                                />
                            </IconButton>
                        </Tooltip>
                    </TableCell >
                    <TableCell style={{textAlign: "center"}}>
                        {row.shipDate?.split("T")[0] ? row.shipDate?.split("T")[0] : ""}
                    </TableCell>
                    <TableCell style={{textAlign: "center"}}>
                        {row.receivedDate?.split("T")[0] ? row.receivedDate?.split("T")[0] : ""}
                    </TableCell>
                    <TableCell style={{textAlign: "center"}}>
                        {row.expectedDate?.split("T")[0] ?? ""}
                    </TableCell>
                    <TableCell style={{textAlign: "center"}}>
                        {row.carrierName}
                    </TableCell>
                     <TableCell style={{textAlign: "center"}}>
                        {row.fromAddress.selectedLocation}
                     </TableCell>
                     <TableCell style={{textAlign: "center"}}>
                        {row.toAddress.selectedLocation}
                     </TableCell>
                     <TableCell style={{textAlign: "center"}}>
                        {row.status}
                     </TableCell>
                    <TableCell style={{textAlign: "center"}}>
                        <Tooltip title={VIEW_BOL}>
                            <IconButton
                                onClick={() => {this.handleDocumentsPreview(row, false)}}
                                disabled={row.status !== FTL_STATUSES.APPROVED && row.status !== FTL_STATUSES.SHIPPED && row.status !== FTL_STATUSES.RECEIVED && row.status !== FTL_STATUSES.COMPLETED  || !this.props.user.routes.includes('ftl-approval')}>
                                <DescriptionIcon variant='outlined' fontSize='large' />
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                    <TableCell style={{textAlign: "center"}}>
                        <Tooltip title={VIEW_DOCUMENTS}>
                            <IconButton
                                onClick={() => {this.handleDocumentsPreview(row, true)}}
                                disabled={row.status !== FTL_STATUSES.APPROVED && row.status !== FTL_STATUSES.SHIPPED && row.status !== FTL_STATUSES.RECEIVED && row.status !== FTL_STATUSES.COMPLETED  || !this.props.user.routes.includes('ftl-approval')}>
                                <FileCopyIcon variant='outlined' fontSize='large' />
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                </React.Fragment>}

                    onRowDoubleClick={(row) => {
                        this.props.setCurrentPage(FTL_PAGES.UPDATE, row)
                    }}
                    />
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    tableRows: allFullTruckloadsSelector(state),
})

const actionCreators = {
    fetchFullTruckloads,
}

export default withShipment({
    mapStateToProps,
    actionCreators,
}, FullTruckloadTable);