import {
  takeLatest, put,
} from 'redux-saga/effects';
import {
  TESTING_SAGAS
} from '../../constants/test';

import {testingMoreSagas} from "../../actions/test";
import {INCREMENT_SCANNED_COUNT} from "../../constants/sampleDashboard";

function* testLogging(action) {
  const someDate = Date.now();
  yield put(testingMoreSagas(someDate));
  console.log(`Sagas: ${action.message} ${someDate}`);
}

function* something(action) {
  console.log(action);
}

export default function* testSagas() {
  yield takeLatest(TESTING_SAGAS, testLogging);
  yield takeLatest(INCREMENT_SCANNED_COUNT, something);
}