export const PRODUCT_TOOLBAR_TITLE = "Products"
export const CREATE_PRODUCT_TITLE = "Add a product"
export const BACK_TO_MENU = 'Back to Menu';
export const WEIGHT_UNITS = ['LB', 'KG']
export const ORIGIN_CRITERION_OPTIONS = ['A', 'B', 'C', 'D', 'E']


export const DEFAULT_SORT_BY_HEAD_CELL = "shortcode"
export const MANAGE_PRODUCTS_HEAD_CELLS = [
    { id: 'shortcode', center: false, disablePadding: false, label: 'Shortcode' },
    { id: 'productCode', center: false, disablePadding: false, label: 'Product Code' },
    { id: 'brand', center: false, disablePadding: false, label: 'Brand' },
    { id: 'manufacturerPartNum', center: false, disablePadding: false, label: 'Manufacturer Part Number' },
    { id: 'actualSize', center: false, disablePadding: false, label: 'Actual Size' },
    { id: 'nominalSize', center: false, disablePadding: false, label: 'Nominal Size' },
    { id: '', center: false, disablePadding: false, label: '' }
]

export const DEFAULT_ROWS_PER_PAGE = 50;
export const SMALLER_DEFAULT_ROWS_PER_PAGE = 15;
export const SELECT_LOCATION_HEADER = "Locations"
export const SELECT_LOCATION_HELPER_TEXT = "Select the locations where this product is available";
export const ROWS_PER_PAGE_LABEL = "Products per page"