import React from 'react';

import {
    Dialog,
    CircularProgress
} from "@material-ui/core";

function RatingCircularProgress(props) {
    return (
        <Dialog open={props.open} PaperProps={{
            style: {
                backgroundColor: 'transparent',
                overflow: 'hidden',
                boxShadow: 'none'
            }
        }}>
            <CircularProgress />
        </Dialog>
    )
}

export default RatingCircularProgress;