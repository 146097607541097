import React from 'react';

// material components
import { Document, Page, pdfjs } from 'react-pdf';
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import IconButton from "@material-ui/core/IconButton";
import './RenderPurchaseOrderPDFPreview.css';
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import FeedbackBar from "../feedbackBar/FeedbackBar";
import { CARRIER_MISSING_ERROR, ERROR } from "../shipments/constants";
import onLoadError from "react-pdf/dist/umd/Document";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class RenderPurchaseOrderPDFPreview extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            numPages: null,
            pageNumber: 1,
            attemptFailed: false
        }
        this.hider = React.createRef()
    }

    onDocumentLoadSuccess = ({ numPages }) => {

        if (this.hider?.current?.className)
            this.hider.current.className = ''

        this.setState({
            numPages: numPages,
        })

        if (this.props.handlePdfLoadSuccess)
            this.props.handlePdfLoadSuccess()

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.attemptFailed) {
            this.setState({
                attemptFailed: false
            })
        }
    }

    onDocumentLoadError = () => {
        this.setState({
            attemptFailed: true
        })
        if (this.props.handlePdfLoadError)
            this.props.handlePdfLoadError()
    }

    render() {
        return (
            <>
                <div ref={this.hider} className={'hider'}>
                    {/*<Dialog open={this.props.open} onClose={this.props.handlePreviewClose} fullWidth={true}
                        maxWidth = {'md'} >
                    <DialogContent >*/}
                    {this.state.numPages && <div className="page-navigation">
                        <IconButton disabled={this.state.pageNumber === 1} onClick={() => this.setState({ pageNumber: this.state.pageNumber - 1 })}>
                            <ArrowBackIosIcon />
                        </IconButton>
                        <IconButton disabled={this.state.pageNumber === this.state.numPages} onClick={() => this.setState({ pageNumber: this.state.pageNumber + 1 })}>
                            <ArrowForwardIosIcon />
                        </IconButton>
                        <p>Page {this.state.pageNumber} of {this.state.numPages}</p>
                    </div>}
                    {this.state.attemptFailed ? null :
                    <Document
                        file={{ data: this.props.data }}
                        onLoadSuccess={this.onDocumentLoadSuccess}
                        onLoadError={this.onDocumentLoadError}
                        className=" react-pdf__Document"
                    >
                        <Box border={1} borderColor="grey.500">
                            <Page pageNumber={this.state.pageNumber} scale={this.props.scale} />
                        </Box>
                    </Document>
                    }
                    {this.state.numPages && <div className="page-navigation">
                        <IconButton disabled={this.state.pageNumber === 1} onClick={() => this.setState({ pageNumber: this.state.pageNumber - 1 })}>
                            <ArrowBackIosIcon />
                        </IconButton>
                        <IconButton disabled={this.state.pageNumber === this.state.numPages} onClick={() => this.setState({ pageNumber: this.state.pageNumber + 1 })}>
                            <ArrowForwardIosIcon />
                        </IconButton>
                        <p>Page {this.state.pageNumber} of {this.state.numPages}</p>
                    </div>}
                    {/* </DialogContent> */}

                    {/*this.props.creating && this.state.numPages &&
                        <DialogActions>
                            <Button variant='contained' onClick={this.props.download} color="primary">
                                Download
                            </Button>
                        </DialogActions>
                    */}

                    {/*</Dialog>*/}
                </div>
            </>
        );
    }
}


RenderPurchaseOrderPDFPreview.propTypes = {
    data: PropTypes.object,
    handlePreviewClose: PropTypes.func,
    open: PropTypes.bool,
    handleSubmit: PropTypes.func,
    download: PropTypes.func
}

RenderPurchaseOrderPDFPreview.defaultProps = {
    data: {},
    handlePreviewClose: () => { },
    open: false,
    handleSubmit: () => { },
    download: () => { }
}

export default RenderPurchaseOrderPDFPreview;