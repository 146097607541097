export const ROWS_PER_PAGE_LABEL = "Customer invoices per page"
export const ROWS_PER_PAGE = 50

export const INVOICE_TYPE_CUSTOMER = "Customer"

export const INVOICE_HEAD_CELLS = [
    { id: 'customerName', center: true, disablePadding: false, addDollar: false, rightAlign: false, label: 'Customer Name'},
    { id: 'date', center: true, disablePadding: false, addDollar: false, rightAlign: false, label: 'Date'},
    { id: 'poNumber', center: true, disablePadding: false, addDollar: false, rightAlign: false, label: 'PO Number'},
    { id: 'invoiceNumber', center: true, disablePadding: false, addDollar: false, rightAlign: false, label: 'Invoice Number'},
    { id: 'invoiceSubTotal', center: true, disablePadding: false, addDollar: true, rightAlign: true, label: 'Sub Total'},
    { id: 'invoiceTax', center: true, disablePadding: false, addDollar: true, rightAlign: true, label: 'Tax'},
    { id: 'invoiceTotal', center: true, disablePadding: false, addDollar: true, rightAlign: true, label: 'Total'},
    { id: 'status', center: true, disablePadding: false, addDollar: false, rightAlign: false, label: 'Status'},
    // { id: 'lastUpdatedDate', center: true, disablePadding: false, addDollar: false, rightAlign: false, label: 'Last Updated Date'},
    { id: 'delete', center: true, disablePadding: false, addDollar: false, rightAlign: false, label: ''},
]

export const INVOICE_ITEM_CELLS = [
    { id: 'shortcode', center: true, disablePadding: false, addDollar: false, rightAlign: false, readOnly: false, label: 'Shortcode'},
    { id: 'customerPartNumber', center: true, disablePadding: false, addDollar: false, rightAlign: false, readOnly: false, label: 'Part Number'},
    { id: 'quantity', center: true, disablePadding: false, addDollar: false, rightAlign: true, readOnly: false, label: "Quantity"},
    { id: 'unitPrice', center: true, disablePadding: false, addDollar: true, rightAlign: true, readOnly: false, label: 'Price'},
    { id: 'discount', center: true, disablePadding: false, addDollar: true, rightAlign: true, readOnly: false, label: 'Discount'},
    { id: 'amount', center: true, disablePadding: false, addDollar: true, rightAlign: true, readOnly: false, label: 'Amount'},
    { id: 'tax', center: true, disablePadding: false, addDollar: true, rightAlign: true, readOnly: false, label: 'Tax'}
]

export const INVOICE_ITEM_CELLS_LEFT = [
    { id: 'shortcode', center: true, disablePadding: false, addDollar: false, rightAlign: false, readOnly: false, label: 'Shortcode'},
    { id: 'customerPartNumber', center: true, disablePadding: false, addDollar: false, rightAlign: false, readOnly: false, label: 'Part Number'},
    { id: 'quantity', center: true, disablePadding: false, addDollar: false, rightAlign: true, readOnly: false, label: "Quantity"},
    { id: 'unitPrice', center: true, disablePadding: false, addDollar: true, rightAlign: true, readOnly: false, label: 'Price'},
]

export const INVOICE_ITEM_CELLS_RIGHT = [
    { id: 'amount', center: true, disablePadding: false, addDollar: true, rightAlign: true, readOnly: false, label: 'Amount'},
]

export const NEEDS_REVIEW_STATUS = "Needs Review"
export const READY_SEND_STATUS = "Ready to Send"
export const PAID_STATUS = "Paid"
export const PAYMENT_PENDING_STATUS = "Payment Pending"

export const DEFAULT_SORT_BY_HEAD_CELL = "date"

export const ATTACHMENTS_INFO = "Upload attachments by selecting files from your computer.";

export const CUSTOMER_INVOICE_SAVE_SUCCESS_MESSAGE = "Saved"
export const CUSTOMER_INVOICE_MARKED_NEED_REVIEW_MESSAGE = "Marked need review"
export const CUSTOMER_INVOICE_READY_TO_SEND_MESSAGE = "Ready to Send"
export const CUSTOMER_INVOICE_MARKED_AS_PAID_MESSAGE = "Marked as paid"
export const CUSTOMER_INVOICE_PAYMENT_PENDING_MESSAGE = "Payment Pending"
export const CUSTOMER_INVOICE_SAVED_AND_VALIDATE_MESSAGE = "Saved and validated"
export const CUSTOMER_INVOICE_INVOICE_REPROCESSED_MESSAGE = "Invoice reprocessed"
export const CUSTOMER_INVOICE_INVOICE_ADD_PAYMENT_METHOD_MESSAGE = "Payment method added"