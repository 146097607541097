import React, { useEffect, useState } from 'react';

// custom components
import CarrierStepper from "./CarrierStepper";
import SettingsToolbar from "../SettingsToolbar";
// material components
import {
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContentText,
    DialogContent,
    Button,
    Select,
    FormControl,
    FormHelperText,
    MenuItem,
    TextField, Tooltip, IconButton
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

// constants
import {
    AVAILABLE_CARRIERS,
    BACK,
    CREATE_CARRIER,
    CANCEL,
    CARRIER_STEPPER_LABELS,
    CREATE_CARRIER_DESCRIPTION,
    CREATE_CARRIER_TITLE,
    NEXT,
    BUY_SHIPPING_SETUP,
    STANDARD_SETUP,
    BUY_SHIPPING,
    LTL_FTL_CARRIERS,
    STEP_CASE_STANDARD,
    STEP_CASE_FTL, LTL_FTL_CARRIER_STEPPER_LABELS, WEB_SERVICES, REST
} from "./constants";

// styling
import styles from './CreateCarrierDialog.module.css';
import PropTypes from "prop-types";
import { Style } from '@material-ui/icons';
import {EXECUTE, INFO_ON_MANUAL_EXECUTE, STEPPER_HEADINGS} from "../ManageSuppliers/constants";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

function CreateCarrierDialog(props) {
    const [state, setState] = useState({
        error: false,
        errorMessage: '',
        activeStep: 0,
        address: {},
        isAddress: true,
        isDisabled: true,
        nextDisabled: true,
        isDefault: false,
        isEnabled: false,
        addressValidation: false,
        carrierSetup: '',
        marketplace: '',
        carrier: '',
        defaultService: '',
        netTerm: '',
        currencyCode: '',
        nickname: '',
        username: '',
        password: '',
        location: '',
        accountNumber: '',
        meterNumber: '',
        contractId: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        country: '',
        email: '',
        phone: '',
        name: '',
        companyName: '',
        apiType:'',
        contactName: '',
        postalCode: '',
        zipPassword: '',
        pdfPassword: '',
        hasZipPassword: false,
        hasPdfPassword: false,
        autoDownload: false,
        customQuery: false,
        query: '',
        filters: [],
        filterActions: [],
        customQueryActions: [],
        applyAllFilters: false,
        isFilterDialogOpen: false,
        isActionDialogOpen: false,
        isFTLCarrier: false,
        shipAPIKey: "",
        shipSecretKey: "",
        trackAPIKey: "",
        trackSecretKey: "",
        errors: {
            carrier: false,
            defaultService: false,
            netTerm: false,
            currencyCode: false,
            nickname: false,
            username: false,
            password: false,
            accountNumber: false,
            addressLine1: false,
            addressLine2: false,
            city: false,
            state: false,
            country: false,
            companyName: false,
            contactName: false,
            email: false,
            phone: false,
            name: false,
            postalCode: false
        }
    });
    const [initialState, setInitialState] = useState(state)
    const whiteList = [
        'activeStep',
        'isAddress',
        'carrierId',
        'isDisabled',
        'nextDisabled',
        'errors',
        'error',
        'address',
        'contractId',
        'meterNumber',
        'errorMessage',
        'addressLine2',
        'marketplace',
        'filters',
        'filterActions',
        'customQueryActions',
        'query',
        'zipPassword',
        'hasZipPassword',
        'pdfPassword',
        'hasPdfPassword',
        'autoDownload',
        'customQuery',
        'applyAllFilters',
        'location',
        'apiType',
        'shipAPIKey',
        'shipSecretKey',
        'trackAPIKey',
        'trackSecretKey',
    ];
    if (state.carrierSetup === BUY_SHIPPING_SETUP) {
        whiteList.push('accountNumber', 'username', 'password')
        whiteList.splice(whiteList.indexOf('marketplace'), 1)
    }

    const requiredList0 = ['carrierSetup']
    const requiredList1 = ['carrier', 'defaultService','currencyCode', 'netTerm'];
    const requiredList2 = ['username', 'password', 'accountNumber'];
    const requiredList2FWebServices = ['username', 'password', 'accountNumber', 'meterNumber'];
    const requiredList2FREST = ['accountNumber'];
    const requiredList2C = ['username', 'password', 'accountNumber','contractId'];
    const requiredList2A = ['accountNumber'];
    const requiredList3 = ['name'];
    useEffect(() => {
        if (props.isEdit) {
            if (props.selectedCarrier?.isFTLCarrier){
                setState(prevState => ({
                    ...prevState,
                    address: props.selectedAddress,
                    isAddress: true,
                    carrier: props.selectedCarrier.name,
                    carrierId: props.selectedCarrier.carrierId,
                    nickname: props.selectedCarrier.alias,
                    username: props.selectedCarrier.username,
                    password: props.selectedCarrier.password,
                    marketplace: props.selectedCarrier?.marketplace?.marketplaceId ?? "",
                    carrierSetup: props.selectedCarrier.isFTLCarrier ? LTL_FTL_CARRIERS : props.selectedCarrier.isBuyShipping ? BUY_SHIPPING_SETUP : STANDARD_SETUP,
                    accountNumber: props.selectedCarrier.accountNumber,
                    meterNumber: props.selectedCarrier.meterNumber,
                    contractId: props.selectedCarrier.contractId,
                    addressLine1: props.selectedCarrier?.addressLine1,
                    addressLine2: props.selectedCarrier?.addressLine2,
                    city: props.selectedCarrier?.city,
                    stateOrProvince: props.selectedCarrier?.stateOrProvince,
                    country: props.selectedCarrier?.country,
                    location: props.selectedCarrier?.location,
                    companyName: props.selectedCarrier?.companyName,
                    apiType: props.selectedCarrier?.apiType,
                    shipAPIKey:  props.selectedCarrier.shipAPIKey ?? "",
                    shipSecretKey:  props.selectedCarrier.shipSecretKey ?? "",
                    trackAPIKey:  props.selectedCarrier.trackAPIKey ?? "",
                    trackSecretKey:  props.selectedCarrier.trackSecretKey ?? "",
                    contactName: props.selectedCarrier?.contactName ?? "",
                    email: props.selectedCarrier?.email,
                    phone: props.selectedCarrier?.phoneNumber,
                    phoneNumber: props.selectedCarrier?.phoneNumber,
                    name: props.selectedCarrier?.name,
                    postalCode: props.selectedCarrier?.postalCode,
                    defaultService: props.selectedCarrier.defaultService,
                    netTerm: props.selectedCarrier.netTerm,
                    currencyCode: props.selectedCarrier.currencyCode,
                    isDefault: props.selectedCarrier.isDefault ? props.selectedCarrier.isDefault : false,
                    isEnabled: props.selectedCarrier.isEnabled ? props.selectedCarrier.isEnabled : false,
                    addressValidation: props.selectedCarrier.addressValidation ? props.selectedCarrier.addressValidation : false,
                    companyId: props.selectedCarrier?.company,
                    hasPdfPassword: props.selectedCarrier?.hasPdfPassword || false,
                    hasZipPassword: props.selectedCarrier?.hasZipPassword || false,
                    autoDownload: props.selectedCarrier?.autoDownload || false,
                    customQuery: props.selectedCarrier?.customQuery || false,
                    zipPassword: props.selectedCarrier?.zipPassword || "",
                    pdfPassword: props.selectedCarrier?.pdfPassword || "",
                    query: props.selectedCarrier?.query || "",
                    filters: props.selectedCarrier?.filters || [],
                    filterActions: props.selectedCarrier?.filterActions || [],
                    customQueryActions: props.selectedCarrier?.customQueryActions || [],
                    applyAllFilters: props.selectedCarrier?.applyAllFilters || false,
                    isActionDialogOpen: props.selectedCarrier?.isActionDialogOpen || false,
                }));
            }
            else {
                setState(prevState => ({
                    ...prevState,
                    address: props.selectedAddress,
                    isAddress: true,
                    carrier: props.selectedCarrier.name,
                    carrierId: props.selectedCarrier.carrierId,
                    nickname: props.selectedCarrier.alias,
                    username: props.selectedCarrier.username,
                    password: props.selectedCarrier.password,
                    shipAPIKey:  props.selectedCarrier.shipAPIKey ?? "",
                    shipSecretKey:  props.selectedCarrier.shipSecretKey ?? "",
                    trackAPIKey:  props.selectedCarrier.trackAPIKey ?? "",
                    trackSecretKey:  props.selectedCarrier.trackSecretKey ?? "",
                    marketplace: props.selectedCarrier?.marketplace?.marketplaceId ?? "",
                    carrierSetup: props.selectedCarrier.isFTLCarrier ? LTL_FTL_CARRIERS : props.selectedCarrier.isBuyShipping ? BUY_SHIPPING_SETUP : STANDARD_SETUP,
                    accountNumber: props.selectedCarrier.accountNumber,
                    meterNumber: props.selectedCarrier.meterNumber,
                    contractId: props.selectedCarrier.contractId,
                    addressLine1: props.selectedAddress?.addressLine1,
                    addressLine2: props.selectedAddress?.addressLine2,
                    city: props.selectedAddress?.city,
                    state: props.selectedAddress?.state,
                    country: props.selectedAddress?.country,
                    companyName: props.selectedAddress?.companyName,
                    apiType: props.selectedCarrier.apiType?? "",
                    contactName: props.selectedAddress?.contactName,
                    email: props.selectedAddress?.email,
                    phone: props.selectedAddress?.phone,
                    name: props.selectedAddress?.name,
                    postalCode: props.selectedAddress?.postalCode,
                    defaultService: props.selectedCarrier.defaultService,
                    netTerm: props.selectedCarrier.netTerm,
                    currencyCode: props.selectedCarrier.currencyCode,
                    isDefault: props.selectedCarrier.isDefault ? props.selectedCarrier.isDefault : false,
                    isEnabled: props.selectedCarrier.isEnabled ? props.selectedCarrier.isEnabled : false,
                    addressValidation: props.selectedCarrier.addressValidation ? props.selectedCarrier.addressValidation : false,
                    companyId: props.selectedCarrier?.company,
                    hasZipPassword: props.selectedCarrier?.hasZipPassword || false,
                    hasPdfPassword: props.selectedCarrier?.hasPdfPassword || false,
                    autoDownload: props.selectedCarrier?.autoDownload || false,
                    customQuery: props.selectedCarrier?.customQuery || false,
                    query: props.selectedCarrier?.query || "",
                    zipPassword: props.selectedCarrier?.zipPassword || "",
                    pdfPassword: props.selectedCarrier?.pdfPassword || "",
                    filters: props.selectedCarrier?.filters || [],
                    filterActions: props.selectedCarrier?.filterActions || [],
                    customQueryActions: props.selectedCarrier?.customQueryActions || [],
                    applyAllFilters: props.selectedCarrier?.applyAllFilters || false,
                    isActionDialogOpen: props.selectedCarrier?.isActionDialogOpen || false,
                }));
            }
        }
    }, [props.isEdit])

    useEffect(() => {
        setState(prevState => ({
            ...prevState,
            isFTLCarrier: state?.carrierSetup === LTL_FTL_CARRIERS,
        }));
    }, [state?.carrierSetup])


    const handleNumberChange = (e) => {
        const numbersOnly = e.target.value.replace(/[^0-9]/g, ''); // Replace non-numeric characters with an empty string
        setState({
            ...state,
            [e.target.name]: numbersOnly,
        })
    }

    const handleChange = (e) => {
        if (e.target.name === "name") {
            setState({
                ...state,
                defaultService: '',
            })
        }
        if (e.target.name === "carrierSetup" && e.target.value === STANDARD_SETUP) {
            setState({
                ...state,
                [e.target.name]: e.target.value,
                marketplace: "",
            })
            return
        }
        setState({
            ...state,
            [e.target.name]: e.target.value,
        })
    }



    const handleAutocomplteChange = (value, name) => {
        setState({
            ...state,
            [name]: value ?? '',
        })
    }

    const handleAutomationSettingChanges = (listOfChanges) => {
        setState(currentState => {
            // Create a new state object based on the current state
            const newState = { ...currentState };

            // Apply each change to the new state object
            listOfChanges.forEach(({ name, value }) => {
                if(name === "hasZipPassword" && value === false){
                    newState["zipPassword"] = '';
                }else  if(name === "hasPdfPassword" && value === false){
                    newState["pdfPassword"] = '';
                }
                newState[name] = value;
            });
            return newState;
        });
    }

    const onSavingStep = () => {
        if (state.isFTLCarrier) {
            return state.activeStep === LTL_FTL_CARRIER_STEPPER_LABELS.length - 1
        }
        else {
            return state.activeStep === CARRIER_STEPPER_LABELS.length - 1
        }
    }

    const checkNext = () => {
        if (state.isFTLCarrier) return false
        const keys = Object.keys(state);

        if (state.activeStep === 0) {
            for (const required of requiredList0) {
                if (!state[required]) return true
            }
            return state.carrierSetup === BUY_SHIPPING_SETUP && !state.marketplace
        }

        if(state.activeStep===1){
            for (let i = 0; i < keys.length; i++) {
                if (requiredList1.includes(keys[i]) && (state[keys[i]]?.length === 0 || state[keys[i]]?.length === undefined)) {
                    return true
                }
            }
            // FedEx has an additional input of API type
            if (state.carrier==="FedEx") {
                if (!state.apiType || state.apiType == "") {
                    return true
                }
            }
        }

        if(state.activeStep===2){
            if (state.carrierSetup === BUY_SHIPPING_SETUP) return false
            if(state.carrier==="FedEx"){
                for (let i = 0; i < keys.length; i++) {
                    if (state.apiType == WEB_SERVICES && requiredList2FWebServices.includes(keys[i]) && (state[keys[i]].length === 0 || state[keys[i]].length === undefined)) {
                        return true
                    }
                    if (state.apiType == REST && requiredList2FREST.includes(keys[i]) && (state[keys[i]].length === 0 || state[keys[i]].length === undefined)) {
                        return true
                    }
                }
            }
            else if(state.carrier==="Canada Post"){
                for (let i = 0; i < keys.length; i++) {
                    if (requiredList2C.includes(keys[i]) && (state[keys[i]].length === 0 || state[keys[i]].length === undefined)) {
                        return true
                    }
                }
            }
            else if(state.carrier==="Amazon Shipping"){
                for (let i = 0; i < keys.length; i++) {
                    if (requiredList2A.includes(keys[i]) && (state[keys[i]].length === 0 || state[keys[i]].length === undefined)) {
                        return true
                    }
                }
            }
            else {
                for (let i = 0; i < keys.length; i++) {
                    if (requiredList2.includes(keys[i]) && (state[keys[i]].length === 0 || state[keys[i]].length === undefined)) {
                        return true
                    }
                }
            }

        }

        if(state.activeStep===3){
            for (let i = 0; i < keys.length; i++) {
                if (requiredList3.includes(keys[i]) && (state[keys[i]]?.length === 0 || state[keys[i]]?.length === undefined)) {
                    return true
                }
            }
        }

        return false
    }

    const handleIsDefaultChange = () => {
        setState({
            ...state,
            isDefault: !state.isDefault
        })
    }

    const handleEnabledChange = () => {
        setState({
            ...state,
            isEnabled: !state.isEnabled
        })
    }

    const handleAddressValidationChange = () => {
        setState({
            ...state,
            addressValidation: !state.addressValidation
        })
    }

    const handleAddressChange = (address, isAddress) => {

        setState({
            ...state,
            addressLine1: isAddress ? address.addressLine1 : '',
            addressLine2: isAddress ? address.addressLine2 : '',
            city: isAddress ? address.city : '',
            state: isAddress ? address.state : '',
            country: isAddress ? address.country : '',
            email: isAddress ? address.email : '',
            shipAddressId: isAddress ? address.shipAddressId : '',
            phone: isAddress ? address.phone : '',
            name: isAddress ? address.name : '',
            nickname: isAddress ? address.name : '',
            postalCode: isAddress ? address.postalCode : '',
            companyName: isAddress ? address.companyName : '',
            contactName: isAddress ? address.contactName : '',
            address,
            isAddress,
        })

    }

    const handleStepNext = () => {
        if (state.activeStep === ( state.isFTLCarrier ? LTL_FTL_CARRIER_STEPPER_LABELS.length - 1 :CARRIER_STEPPER_LABELS.length - 1 )) {
            props.handleClose();
        } else {
            setState({
                ...state,
                activeStep: state.activeStep + 1,
                isDisabled: false,
                error: false
            })
        }
    }

    const handleStepBack = () => {
        if (state.activeStep === 1) {
            setState({
                ...state,
                isDisabled: true,
                activeStep: state.activeStep - 1
            })
        } else {
            setState({
                ...state,
                activeStep: state.activeStep - 1
            })
        }
    }

    const handleCancel = () => {
        setState(initialState)
        props.handleClose();
    }

    const handleSave = () => {
        if (state.isFTLCarrier) {
            const data = {
                carrierId: props.carrierId,
                accountNumber: state.accountNumber,
                currencyCode: state.currencyCode,
                addressLine1: state.addressLine1,
                addressLine2: state.addressLine2,
                city: state.city,
                state: state.stateOrProvince,
                country: state.country,
                name: state.name, //company name
                contactName: state.contactName,
                location: state.location,
                email: state.email,
                phone: state.phoneNumber,
                postalCode: state.postalCode,//
                autoDownload: state.autoDownload,
                hasZipPassword: state.hasZipPassword,
                zipPassword: state.zipPassword,
                hasPdfPassword: state.hasPdfPassword,
                pdfPassword: state.pdfPassword,
                customQuery: state.customQuery,
                query: state.query,
                filters: state.filters,
                filterActions: state.filterActions,
                customQueryActions: state.customQueryActions,
                applyAllFilters: state.applyAllFilters,
            }
            props.saveFullTruckloadCarriers(data)
            props.handleClose();
        }
        else {
            const errors = {...state.errors};
            const keys = Object.keys(state);
            let error = false;

            if (state.carrier == "Amazon Shipping" || (state.carrier == "FedEx" && state.apiType == REST)) {
                whiteList.push("username")
                whiteList.push("password")
            }

            for (let i = 0; i < keys.length; i++) {
                if (!whiteList.includes(keys[i]) && state[keys[i]]?.length === 0) {
                    error = true;
                    errors[keys[i]] = true;
                }
            }

            if (error) {
                setState({
                    ...state,
                    errors: errors,
                    error: true,
                    errorMessage: JSON.stringify(errors)
                })
            } else {

                const data = {
                    carrierId: props.carrierId,
                    shipAddressId: state.shipAddressId ? state.shipAddressId : props.shipAddressId,
                    carrierName: state.carrier,
                    defaultService: state.defaultService,
                    netTerm: state.netTerm,
                    currencyCode: state.currencyCode,
                    marketplaceId: state.marketplace,
                    isBuyShipping: state.carrierSetup === BUY_SHIPPING_SETUP,
                    carrierAlias: state.nickname,
                    username: state.username,
                    password: state.password,
                    accountNumber: state.accountNumber,
                    meterNumber: state.meterNumber,
                    contractId: state.contractId,
                    companyId: props.company,
                    addressLine1: state.addressLine1,
                    addressLine2: state.addressLine2,
                    city: state.city,
                    state: state.state,
                    country: state.country,
                    companyName: state.companyName,
                    apiType: state.apiType,
                    shipAPIKey:  state.shipAPIKey,
                    shipSecretKey:  state.shipSecretKey,
                    trackAPIKey:  state.trackAPIKey,
                    trackSecretKey:  state.trackSecretKey,
                    contactName: state.contactName,
                    email: state.email,
                    phone: state.phone,
                    name: state.name,
                    postalCode: state.postalCode,
                    isDefault: state.isDefault,
                    isEnabled: state.isEnabled,
                    addressValidation: state.addressValidation,
                    autoDownload: state.autoDownload,
                    hasZipPassword: state.hasZipPassword,
                    hasPdfPassword: state.hasPdfPassword,
                    zipPassword: state.zipPassword,
                    pdfPassword: state.pdfPassword,
                    customQuery: state.customQuery,
                    query: state.query,
                    filters: state.filters,
                    filterActions: state.filterActions,
                    customQueryActions: state.customQueryActions,
                    applyAllFilters: state.applyAllFilters,
                }

                if (props.isEdit) {
                    props.editCarrier(data, () => window.location.reload())
                } else {
                    props.createCarrier(data, handleCancel)
                }
            }
            props.handleClose();
        }
    }

    const handleBack = () => {
        if (state.activeStep == 0) {
            props.handleClose();
        } else {
            handleStepBack();
        }

    }

    return (
        <div className={styles.carrier_stepper_align}>
            <div>
                <SettingsToolbar
                    title={props.title}
                    selected={props.selected}
                    handleDeleteOpen={props.handleDeleteOpen}
                    handleDeleteClose={props.handleDeleteClose}
                    handleDelete={props.handleDelete}
                    deleteOpen={props.deleteOpen}
                    handleBack={handleBack}
                    addingOrEditing={props.addingOrEditing}
                    nextStep={state.activeStep === ( state.isFTLCarrier ? LTL_FTL_CARRIER_STEPPER_LABELS.length - 1 :CARRIER_STEPPER_LABELS.length - 1 )? handleSave : handleStepNext}
                    disableNextStep={checkNext()}
                    onSavingStep={onSavingStep()}
                />
                <CarrierStepper
                    handleNumberChange={handleNumberChange}
                    handleChange={handleChange}
                    addresses={props.addresses}
                    parentState={state}
                    handleAddressChange={handleAddressChange}
                    handleIsDefaultChange={handleIsDefaultChange}
                    handleEnabledChange={handleEnabledChange}
                    handleClose={props.handleClose}
                    activeStep={state.activeStep}
                    isEdit={props.isEdit}
                    handleAutocomplteChange={handleAutocomplteChange}
                    handleAddressValidationChange={handleAddressValidationChange}
                    marketplaces={props.marketplaces}
                    handleAutomationSettingChanges={handleAutomationSettingChanges}
                    isFTLCarrier={state.isFTLCarrier}
                />
            </div>
            {state.activeStep === ( state.isFTLCarrier ? LTL_FTL_CARRIER_STEPPER_LABELS.length - 1 :CARRIER_STEPPER_LABELS.length - 1 ) ?
                <div className='supplier-execute-invoice-query-button'>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => props.manualExecuteCarrierAutoSetting(state)}
                    >
                        {EXECUTE}
                    </Button>
                    <Tooltip
                        //className='infoTooltipIcon'
                        enterTouchDelay={0}
                        leaveTouchDelay={30000}
                        enterDelay={400}
                        // leaveDelay={1000000} // for testing purposes
                        title={
                            <React.Fragment>
                                {INFO_ON_MANUAL_EXECUTE}
                            </React.Fragment>
                        }
                    >
                        <IconButton aria-label='info'>
                            <InfoOutlinedIcon variant='outlined' fontSize='small' />
                        </IconButton>
                    </Tooltip>
                </div>
                :
                null
            }
            <div>
                <Button
                    variant="contained"
                    onClick={handleCancel}
                    className={styles.carrier_stepper_btn}

                >
                    {CANCEL}
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleStepBack}
                    disabled={state.isDisabled}
                >
                    {BACK}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={
                        state.activeStep === ( state.isFTLCarrier ? LTL_FTL_CARRIER_STEPPER_LABELS.length - 1 :CARRIER_STEPPER_LABELS.length - 1 )? handleSave : handleStepNext
                    }
                    className={styles.carrier_stepper_btn}
                    disabled={checkNext()}
                >
                    {state.activeStep === ( state.isFTLCarrier ? LTL_FTL_CARRIER_STEPPER_LABELS.length - 1 :CARRIER_STEPPER_LABELS.length - 1 ) ? CREATE_CARRIER : NEXT}
                </Button>
            </div>
        </div>
    )

}

CreateCarrierDialog.propTypes = {
    open: PropTypes.bool,
    isEdit: PropTypes.bool,
    selectedAddress: PropTypes.object,
    selectedCarrier: PropTypes.object,
    carrierId: PropTypes.string,
    shipAddressId: PropTypes.string,
    handleClose: PropTypes.func,
    addresses: PropTypes.array,
    createCarrier: PropTypes.func,
    editCarrier: PropTypes.func,
    company: PropTypes.string
}

CreateCarrierDialog.defaultProps = {
    open: false,
    isEdit: false,
    selectedAddress: {},
    selectedCarrier: {},
    carrierId: "",
    shipAddressId: "",
    handleClose: () => { },
    addresses: [],
    createCarrier: () => { },
    editCarrier: () => { },
    company: ""
}

export default CreateCarrierDialog;