import React from "react";
import {
    Box,
    Button, Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl, FormHelperText, IconButton,
    MenuItem,
    Select,
    TextField, Tooltip
} from "@material-ui/core";

// material components
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

// constants

// styling
import "./FilePreviewDialog.css"
import { NAVIGATE_LEFT, NAVIGATE_RIGHT, START_INDEX } from "./constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";
import DeleteDialog from "./DeleteDialog";
import { Document, Page, pdfjs } from 'react-pdf';

class FilePreviewDialog extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            index: this.props.initialIndex,
            previewRequestOpen: false,
            previewCalled: false,
            fetchingPreviews: [],
            numPages: null,
            pageNumber: 1

        }
    }

    componentDidMount() {
        this.setState({
            fetchingPreviews: Array(this.props.files.length).fill(0)
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.files.length !== this.props.files.length) {

        }
    }


    iterateFiles = (left) => {
        let curIndex = this.state.index
        // are we at the final limits
        if (((curIndex === this.props.files.length - 1) && !left) || (curIndex === 0 && left))
            return

        if (left)
            curIndex -= 1
        else
            curIndex += 1

        this.setState({
            index: curIndex
        })

    }

    fetchFilePreview = (file) => {
        if (this.state.fetchingPreviews[this.state.index] === 0) {
            let currentFetchingPreviews = this.state.fetchingPreviews
            currentFetchingPreviews[this.state.index] += 1
            this.setState({
                fetchingPreviews: currentFetchingPreviews
            }, () => {
                if (this.state.fetchingPreviews[this.state.index] === 1) {
                    this.props.fetchFilePreview(file);
                }
            })
        }


        return <CircularProgress />
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({
            numPages: numPages,
        })
    }


    getFilePreview = (file) => {
        if ("localURL" in file) {
            if (file.displayName.includes('.pdf')) {
                if (this.props.isPackingSlip) {
                    return (
                        <div className='image-outline'>
                            <Document
                                file={{ data: this.props.files[this.state.index].byteArray }}
                                className="react-pdf__Document"
                                onLoadSuccess={this.onDocumentLoadSuccess}
                            >
                                <Box border={1} borderColor="grey.500">
                                    <Page pageNumber={this.state.pageNumber} />
                                </Box>
                            </Document>
                        </div>
                    )
                }
                else {
                    return (
                        <div className='image-outline'>
                            <Document
                                file={{ data: this.props.files[this.state.index].byteArray }}
                                className="react-pdf__Document"
                            >
                                <Box border={1} borderColor="grey.500">
                                    <Page pageNumber={1} />
                                </Box>
                            </Document>
                        </div>
                    )
                }
            }
            return (
                <div className='image-outline'>
                    <a href={this.props.files[this.state.index].localURL} download={this.props.files[this.state.index].displayName}>
                        <img src={this.props.files[this.state.index].localURL} className='image' />
                    </a>
                </div>
            )

        }
        return this.fetchFilePreview(this.props.files[this.state.index]);
    }
    render() {
        return (
            <Dialog open={this.props.open} onClose={this.props.handleClose} maxWidth='md'>
                <DialogTitle className='file-preview-header'>{this.props.files[this.state.index].displayName}</DialogTitle>
                <DialogContent className='file-preview-content'>
                    <div className="file-preview-btn-box">
                        <Tooltip title={NAVIGATE_LEFT}>
                            <IconButton
                                aria-label='previous'
                                onClick={!this.props.isPackingSlip || !this.state.numPages ? () => this.iterateFiles(true) : () => this.setState({ pageNumber: this.state.pageNumber - 1 })}
                                disabled={!this.props.isPackingSlip || !this.state.numPages ? this.state.index === START_INDEX : this.state.pageNumber === 1}
                                className="file-preview-btn"
                            >
                                <ChevronLeftIcon variant='outlined' fontSize='large' />
                            </IconButton>
                        </Tooltip>
                    </div>

                    {this.getFilePreview(this.props.files[this.state.index])}

                    <div className="file-preview-btn-box">
                        <Tooltip title={NAVIGATE_RIGHT}>
                            <IconButton
                                aria-label='next'
                                onClick={!this.props.isPackingSlip || !this.state.numPages ? () => this.iterateFiles(false) : () => this.setState({ pageNumber: this.state.pageNumber + 1 })}
                                disabled={!this.props.isPackingSlip || !this.state.numPages ? this.state.index === this.props.files.length - 1 : this.state.pageNumber === this.state.numPages}
                                className="file-preview-btn"
                            >
                                <ChevronRightIcon variant='outlined' fontSize='large' />
                            </IconButton>
                        </Tooltip>
                    </div>
                </DialogContent>
                <DialogActions>

                </DialogActions>
            </Dialog>
        );
    }

}

FilePreviewDialog.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    files: PropTypes.array,
    initialIndex: PropTypes.number,
    fetchFilePreview: PropTypes.func
}

FilePreviewDialog.defaultProps = {
    open: false,
    handleClose: () => { },
    files: [],
    initialIndex: 0,
    fetchFilePreview: () => { }
}

export default FilePreviewDialog;