import React from 'react';

// material components
import {
  TableCell,
  Paper,
  Tooltip
} from "@material-ui/core";

// custom components
import AutomationsRuleTableToolbar from './AutomationRulesTableToolbar';
import SearchableTable from '../global/Search/SearchableTable';
import TablePage from "../global/Table/TablePage";
import {Layout} from "../global/Table/TablePageHelpers/LayoutObject";

// redux
import {
  fetchRules,
  storeToEdit,
  duplicateRule,
  deleteRule,
} from "../../redux/actions/automationRules";
import {
  rulesSelector
} from "../../redux/selectors/automationRules";
import withShipment from "../../withShipment";

// import styling
import './AutomationRulesTable.css'
import '../global/SecondaryToolbar.css';

// import constants
import {
  DEFAULT_SORT_BY_HEAD_CELL,
  AUTOMATION_RULES,
  HEAD_CELLS,
  COPY_RULE, DELETE_RULE, AUTOMATION_RULES_PAGES
} from './constants';
import PropTypes from "prop-types";
import EditIcon from "@material-ui/icons/Edit";
import DeleteSection from "../settings/DeleteSection";
import { IconButton } from "@material-ui/core"
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ErrorFeedbackBar from '../global/ErrorFeedbackBar';
import {userInfoSelector} from "../../redux/selectors/auth";

/**
 * Table for showing automation rules
 */
class AutomationsRulesTable extends SearchableTable {

  constructor(props) {
    super(props);
    this.state = {
      //TO DELETE
      selected: [], // number of selected rows,
      selectedNames: [],

      fetchedRules: [],
      rule: {},
      //TODO Please implement this later
      selectedRuleInfo: {
        ruleId: '',
        name: ''
      }, //The info of the currently selected rule
      isDelete: {}
    };
  }

  componentDidMount() {

  }

  isSelected = (item) => this.state.selectedNames.indexOf(item) !== -1;

  handleChangeDense = (event) => {
    this.setState({
      dense: event.target.checked,
    });
  }

  //TODO Delete if necessary
  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = this.props.rules.map((n) => ({
        name: n.name,
        ruleId: n.ruleId
      }));
      const newSelectedNames = this.props.rules.map((n) => n.name)
      this.setState({
        selected: newSelected,
        selectedNames: newSelectedNames
      });
      return;
    }
    this.setState({
      selected: [],
      selectedNames: []
    });
  };

  redirect = (path) => {
    this.props.history.push(path)
  }

  handleDelete = (ruleId, name, index) => {
    const data = {
      ruleId: ruleId,
      name: name
    }
    this.props.deleteRule(data, this.handleDeleteClose(index))
    this.setState({
      selectedRuleInfo: null
    })
    setTimeout(this.props.fetchRules, 1000)
  }

  handleDeleteOpen = (index) => {
    let newIsDelete = this.state.isDelete
    newIsDelete[index] = true
    this.setState({ isDelete: newIsDelete })
  }

  handleDeleteClose = (index) => {
    let newIsDelete = this.state.isDelete
    newIsDelete[index] = false
    this.setState({ isDelete: newIsDelete })
  }

  prepEdit = (ruleId, name) => {
    const ruleToEdit = {
      ruleId: ruleId,
      name: name,
    }

    this.props.storeToEdit(ruleToEdit, this.props.history)
  }

  handleCopy = (ruleId, name) => {
    const ruleToCopy = {
      ruleId: ruleId,
      name: name
    }

    this.props.duplicateRule(ruleToCopy)
    this.setState({
      selectedRuleInfo: null
    })
    setTimeout(this.props.fetchRules, 1000)
  }

  getSearchStrings = (row) => {
    let strings = []
    if (row.name)
      strings.push(row.name)
    if (row.group)
      strings.push(row.group)
    if (row.ruleCategory)
      strings.push(row.ruleCategory)
    if (row.conditions) {
      for (let i = 0; i < row.conditions.length; i++) {
        let condition = row.conditions[i]
        if (condition.actions) {
          for (let j = 0; j < condition.actions.length; j++) {
            let action = condition.actions[j]
            if (action.name) {
              strings.push(action.name)
            }
          }
        }
        if (condition.filters) {
          for (let k = 0; k < condition.filters.length; k++) {
            let filter = condition.filters[k]
            if (filter.name) {
              strings.push(filter.name)
            }
          }
        }
      }
    }
    return strings
  }

  /**
   * Renders this component
   */
  render() {
    return (
      <div className="root">

        <Paper className="paper">
          <ErrorFeedbackBar />

          <AutomationsRuleTableToolbar
            title={AUTOMATION_RULES}
            numSelected={this.state.selected.length}
            selected={this.state.selected}
            redirect={this.redirect}
            prepEdit={this.prepEdit}
            handleCopy={this.handleCopy}
            handleDelete={this.handleDelete}
            path={'/rules/create'}
            navigateToSettingsPage={() => {this.props.setCurrentPage(AUTOMATION_RULES_PAGES.SETTINGS)}}
            user={this.props.user}
          />

          <TablePage
            tableName="AutomationRulesTable"

            eventsVariable={this.state.tableEvents}
            setEventsVariable={(newState) => {this.setState({tableEvents: newState})}}

            tableRows={this.props.rules}
            rowFetcher={(filters) => {this.props.fetchRules()}}
            refetchFilters={[]}

            paginationText="Rules per page"

            defaultSortBy={DEFAULT_SORT_BY_HEAD_CELL}

            hasSearchField
            searchStrings={this.getSearchStrings}

            tableColumnHeads={
              [Layout.columnHead(null, null, null)].concat(
              HEAD_CELLS.map((cell) => Layout.columnHead(cell.label, cell.id, "Left")))
            }

            tableRow={((row, index) => {
              return (
                <React.Fragment key={row.ruleId}>
                  <TableCell style={{width: "80px"}}>
                    <Tooltip title={'Edit Selected'}>
                      <IconButton onClick={() => this.prepEdit(row.ruleId, row.name)}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell className='cellPadding'>
                    {row.name}
                  </TableCell>
                  <TableCell className='cellPadding'>
                    {row.group}
                  </TableCell>
                  <TableCell className='cellPadding'>
                    {row.ruleCategory}
                  </TableCell>
                  <TableCell>
                    <Tooltip title={COPY_RULE}>
                      <IconButton aria-label={COPY_RULE} onClick={() => this.handleCopy(row.ruleId, row.name)}>
                        <FileCopyIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <DeleteSection
                    index={index}
                    handleDeleteOpen={this.handleDeleteOpen}
                    handleDeleteTrue={() => this.handleDelete(row.ruleId, row.name, index)}
                    handleDeleteFalse={this.handleDeleteClose}
                    isDelete={this.state.isDelete[index] ? this.state.isDelete : false}
                    deleteButtonTitle={DELETE_RULE}
                  />
                </React.Fragment>
              );
            })}
          />

        </Paper>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  rules: rulesSelector(state),
  user: userInfoSelector(state),
})

const actionCreators = {
  fetchRules,
  storeToEdit,
  duplicateRule,
  deleteRule,
}

AutomationsRulesTable.propTypes = {
  rules: PropTypes.array,
}

AutomationsRulesTable.defaultProps = {
  rules: [],
}

export default withShipment({
  mapStateToProps,
  actionCreators
}, AutomationsRulesTable)