import React from "react";

// import styling
import "./InventoryFiltersDialog.css";
import {Layout} from "../global/Table/TablePageHelpers/LayoutObject";
import {FILTER_OPTIONS, NUMERIC_COMPARISON_OPTIONS} from "./constants";

class InventoryFiltersDialog extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            filterDialogCreator: this.getFilterDialogCreator(),
            filterDialog: <></>,
            maxSearchRangeExceeded: false,
            toDateBeforeFromDateError: false
        };

        this.state.filterDialog = this.getFilterDialog()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.brands !== this.props.brands ||
            prevProps.shortcodes !== this.props.shortcodes ||
            prevProps.actionTypes !== this.props.actionTypes ||
            prevProps.allBrands !== this.props.allBrands ||
            prevProps.allShortcodes !== this.props.allShortcodes ||
            prevProps.filterType !== this.props.filterType ||
            prevProps.comparisonOption !== this.props.comparisonOption ||
            prevProps.daysOfCoverageNum !== this.props.daysOfCoverageNum ||
            prevProps.allActionTypes !== this.props.allActionTypes) {
            this.setState({
                filterDialog: this.getFilterDialog()
            })
        }

        if (prevProps.fromDate !== this.props.fromDate ||
            prevProps.toDate !== this.props.toDate) {

            this.setState({
                filterDialog: this.getFilterDialog()
            })

            // Get the difference in milliseconds
            const differenceInMilliseconds = Math.abs(this.props.toDate - this.props.fromDate);

            // Convert milliseconds to days (1 day = 24*60*60*1000 = 86400000 milliseconds)
            const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
            if ((differenceInDays) > this.props.maxAllowedSearchRange) {
                this.setState({
                    maxSearchRangeExceeded: true
                })
                this.props.setMaxAllowedSearchRangeError(true)
            }
            else {
                this.setState({
                    maxSearchRangeExceeded: false
                })
                this.props.setMaxAllowedSearchRangeError(false)
            }

            //check if toDate is before fromDate
            if (this.props.toDate - this.props.fromDate < 0) {
                this.setState({
                    toDateBeforeFromDateError: true
                })
                this.props.setToDateBeforeFromDateError(true)
            }
            else {
                this.setState({
                    toDateBeforeFromDateError: false
                })
                this.props.setToDateBeforeFromDateError(false)
            }

        }

    }

    getFilterDialogCreator = () => {
        return Layout.newLayout(2, [
            Layout.row([
                Layout.rowElement(1, "brands", "Brand", Layout.elementStyle.checkboxDropdown, this.props.allBrands),
                Layout.rowElement(1, "shortcodes", "Shortcode", Layout.elementStyle.checkboxDropdown, this.props.allShortcodes),
            ]),
            Layout.row([
                Layout.rowElement(2, "actionTypes", "Action Type", Layout.elementStyle.checkboxDropdown, this.props.allActionTypes),
            ]),
            Layout.row([
                Layout.rowElement(0.66, "filterType", "Filter Type", Layout.elementStyle.selectDropdown, FILTER_OPTIONS),
                Layout.rowElement(0.67, "comparisonOption", null, Layout.elementStyle.selectDropdown, NUMERIC_COMPARISON_OPTIONS),
                Layout.rowElement(0.67, "daysOfCoverageNum", "Days of Coverage", Layout.elementStyle.textBox, null, false, false, true),
            ]),
            Layout.row([
                Layout.rowElement(1, "fromDate", "From", Layout.elementStyle.dateSelect),
                Layout.rowElement(1, "toDate", "To", Layout.elementStyle.dateSelect),
            ]),
        ])
    }

    handleFilterValueChange = (nameValueObj) => {
        this.props.setFilterValue(nameValueObj)
    }

    getFilterValue = (displayName, filterName, elementStyle) => {
        return this.props[filterName] ?? Layout.getDefaultElementValueFromStyle(elementStyle)
    }

    getFilterDialog = () => {
        return this.state.filterDialogCreator(this.handleFilterValueChange, this.getFilterValue)
    }

    render() {
        return <React.Fragment>
            <div className='horizontally-center'>
                {Layout.titleRow(2, "Filters", "Clear Filters", () => {this.props.clearFilters()})}
                {this.state.filterDialog.errorMessage === "" ? this.state.filterDialog.content : this.state.filterDialog.errorMessage}
                {Layout.cancelSaveRow(2, () => {this.props.cancelFilters()}, () => {this.props.applyFilters()}, this.state.maxSearchRangeExceeded||this.state.toDateBeforeFromDateError)}
            </div>
        </React.Fragment>
    }
}

InventoryFiltersDialog.defaultProps = {
    allBrands: [],
    allShortcodes: [],
    allActionTypes: [],
    setFilterValue: () => {},

    brands: [],
    shortcodes: [],
    actionTypes: [],
    fromDate: "",
    toDate: "",
    filterType: "",
    comparisonOption: "",
    daysOfCoverageNum:"",
}

export default InventoryFiltersDialog