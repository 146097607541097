import React from "react";
import withShipment from "../../withShipment";
import {PRICING_DASHBOARD_PAGES} from "./constants";
import PricingDashboardTable from "./pricingDashboardPages/PricingDashboardTable";
import PricingDashboardLoading from "./pricingDashboardPages/PricingDashboardLoading";
import {pricingListingsSelector, responseMessageSelector} from "../../redux/selectors/settings";
import PricingDashboardForm from "./pricingDashboardPages/PricingDashboardForm";
import ErrorFeedbackBar from "../global/ErrorFeedbackBar";
import FeedbackBar from "../feedbackBar/FeedbackBar";
import {SUCCESS, WARNING} from "../shipments/constants";
import {listMarketplaces, storeResponseMessage} from "../../redux/actions/settings";
import {userInfoSelector} from "../../redux/selectors/auth";
import {listManufacturers} from "../../redux/actions/inventory";
import {Paper} from "@material-ui/core";
import {getTableSetting, saveTableSetting} from "../../redux/actions/account";
import {tableSettingSelector} from "../../redux/selectors/account";
class PricingDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: PRICING_DASHBOARD_PAGES.LOADING,
            webstoreProductId: null,
            responseMessage: "",
            feedbackBarOpen: false,
        }
        this.props.listMarketplaces({id: this.props.user.company})
        this.props.listManufacturers()
    }

    componentDidMount() {
        this.props.getTableSetting()
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevProps.responseMessage !== this.props.responseMessage && this.props.responseMessage!=="") {
            this.setState({
                feedbackBarOpen: true
            })
        }
    }
    setCurrentPage = (newPage,  webstoreProductId = null) => {
        switch (this.state.currentPage){
        }

        switch (newPage) {
            case "detail":
                this.setState({
                    webstoreProductId: webstoreProductId,
                    currentPage: newPage,
                })
                break
        }

        this.setState({
            currentPage: newPage
        })
    }

    displayTable = () => {
        return <React.Fragment>
            <PricingDashboardTable
                setCurrentPage={this.setCurrentPage}
            />
        </React.Fragment>
    }

    displayLoading = () => {
        return <React.Fragment>
            <PricingDashboardLoading
                setCurrentPage={this.setCurrentPage}
            />
        </React.Fragment>
    }

    displayDetail = () => {
        return <React.Fragment>
            <Paper className="paper">
            <PricingDashboardForm
                setCurrentPage={this.setCurrentPage}
                row={this.props.tableRows.find((row) => row.webstoreProductId === this.state.webstoreProductId)}
                user={this.props.user}
                tableSetting={this.props.tableSetting}
                saveTableSetting={this.props.saveTableSetting}
            />
            </Paper>
        </React.Fragment>
    }



    displayCurrentPage = (currentPage) => {
        switch (currentPage) {
            case PRICING_DASHBOARD_PAGES.TABLE:
                return this.displayTable()
            case PRICING_DASHBOARD_PAGES.LOADING:
                return this.displayLoading()
            case PRICING_DASHBOARD_PAGES.DETAIL:
                return this.displayDetail()
            default:
                console.log("Error: current automation rules page doesn't exist")
                return <div>We encountered an error. Please refresh the page.</div>
        }
    }

    closeFeedbackBar = () => {
        this.props.storeResponseMessage("")
        this.setState({
            feedbackBarOpen: false
        })
    }

    render() {
        return (
            <React.Fragment>
                <ErrorFeedbackBar />
                <FeedbackBar
                    open={this.state.feedbackBarOpen}
                    severity={this.props.responseMessage.includes("no sales rank available")? WARNING : SUCCESS}
                    message={this.props.responseMessage}
                    handleClose={this.closeFeedbackBar}
                />
                {this.displayCurrentPage(this.state.currentPage)}
            </React.Fragment>
        )
    }

}

PricingDashboard.defaultProps = {
    user: []
}

PricingDashboard.propTypes = {
}

const mapStateToProps = (state) => ({
    user: userInfoSelector(state),
    responseMessage: responseMessageSelector(state),
    tableRows: pricingListingsSelector(state),
    tableSetting: tableSettingSelector(state),
})

const actionCreators = {
    listMarketplaces,
    storeResponseMessage,
    listManufacturers,
    getTableSetting,
    saveTableSetting
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, PricingDashboard);