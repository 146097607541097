import {
    takeLatest,
    put,
    call,
} from 'redux-saga/effects';
import axios from 'axios';

// endpoints
import {
    USER_MANAGEMENT
} from "../../../components/global/Server/endpoints";

// constants for actions
import {
    FETCH_LINKED_USERS,
    EDIT_USER, DELETE_USER,
    GET_ROLES
} from "../../constants/userManagement";

// secondary actions
import {
    storeLinkedUsers,
    storeRoles
} from "../../actions/userManagement";
import {
    requestStatus,
    setLoadingStatus,
    setOpen,
    clearStatus,
    showErrorMessage
} from "../../actions/global";
import { updateUserInfo } from "../../actions/auth";
import { SERVER_FAILED_ERROR_MESSAGE } from '../../../pages/Dashboard/DashboardFinal/constants';

const getRequest = (endpoint) => {
    return axios.get(endpoint, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
        .then(response => response);
}

function* postRequest(endpoint, data) {
    return yield axios.post(endpoint, data, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
        .then(response => response)
}

function* putRequest(endpoint, data) {
    return yield axios.put(endpoint, data, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
        .then(response => response)
}

function* fetchLinkedUsers(action) {
    yield call(clearStatus)
    yield put(setLoadingStatus(true))
    try {
        const response = yield call(() => postRequest(USER_MANAGEMENT.FIND_USERS, action.username))
        if (response.status === 200) {
            yield put(storeLinkedUsers(response.data))
        }
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === 200,
        }
        yield put(requestStatus(status))
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    } finally {
        yield put(setLoadingStatus(false))
    }
}

function* editUser(action) {
    yield call(clearStatus)
    yield put(setLoadingStatus(true))
    try {
        const response = yield call(() => putRequest(USER_MANAGEMENT.UPDATE_USER, action.userInfo))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === 200,
        }
        yield put(requestStatus(status))
        yield put(setLoadingStatus(false))
        action.setState(true)

        if (status.success) {
            yield put(updateUserInfo(action.updatedUserInfo));

            const userAction = {
                type: FETCH_LINKED_USERS,
                username: {
                    username: action.userInfo.admin
                }
            }
            yield call(() => fetchLinkedUsers(userAction))
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    } finally {
        yield put(setLoadingStatus(false))
    }
}

function* deleteUser(action) {
    yield call(clearStatus)
    try {
        const response = yield call(() => postRequest(USER_MANAGEMENT.DELETE_USER, action.userInfo))

        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === 200,
        }
        yield put(requestStatus(status))
        yield put(setLoadingStatus(false))
        action.setState(true)

        if (status.success) {
            const userAction = {
                type: FETCH_LINKED_USERS,
                username: {
                    username: action.userInfo.admin
                }
            }
            yield call(() => fetchLinkedUsers(userAction))
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* getRolesSaga() {
    try {
        const response = yield call(() => getRequest(USER_MANAGEMENT.GET_ROLES))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === 200,
        };
        yield put(storeRoles(response.data));
        yield put(requestStatus(status));
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

export default function* userManagementSaga() {
    yield takeLatest(FETCH_LINKED_USERS, fetchLinkedUsers);
    yield takeLatest(EDIT_USER, editUser);
    yield takeLatest(DELETE_USER, deleteUser)
    yield takeLatest(GET_ROLES, getRolesSaga)
}

