import React from 'react';
import {
    Button,
    Checkbox, Divider,
    FormControlLabel,
    IconButton,
    Paper,
    Switch,
    TextField,
    Toolbar,
    Tooltip
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import AddIcon from "@material-ui/icons/Add";
import {
    ANY_ALL_PART_1, ANY_ALL_PART_2A,
    ANY_ALL_PART_2B,
    ANY_ALL_PART_3,
    FILTER_TYPE_BOOLEAN_1,
    FILTER_TYPE_TEXT, TABLE_TYPES
} from "../automationrules/constants";
import {
    ACTION_TYPE_BOOLEAN,
    ACTION_TYPE_TEXT,
    INFO_ON_CONTENT_FILTER,
    INFO_ON_CUSTOM_QUERY
} from "../settings/ManageSuppliers/constants";
import DragAndDrop from "./Dnd/DragAndDrop";
import FilterBooleanSelect from "../automationrules/ConditionFilters/FilterBooleanSelect";
import QueryFilterTextField from "../settings/ManageSuppliers/QueryFilterTextField";
import QueryConditionDialog from "../settings/ManageSuppliers/QueryConditionDialog";
import QueryActionBoolean from "../settings/ManageSuppliers/QueryActionBoolean";
import QueryActionText from "../settings/ManageSuppliers/QueryActionText";
import {ADD_ACTION} from "../tickets/constants";
import withCarrier from "../../withCarrier";

class AutomationSetting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasZipPassword: this.getvalue("hasZipPassword")|| false,
            hasPdfPassword: this.getvalue("hasPdfPassword")|| false,
            autoDownload: this.getvalue("autoDownload") || false,
            customQuery: this.getvalue("customQuery") || "",
            query: this.getvalue("query") || "",
            pdfPassword: this.getvalue("pdfPassword") || "",
            zipPassword: this.getvalue("zipPassword") || "",
            filters: this.getvalue("filters") || [],
            filterActions: this.getvalue("filterActions") || [],
            customQueryActions: this.getvalue("customQueryActions") || [],
            applyAllFilters: this.getvalue("applyAllFilters") || false,
            isFilterDialogOpen: false,
            isActionDialogOpen: false
        }
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (this.props.parentState!==prevProps.parentState){
            this.setState({
                hasZipPassword: this.getvalue("hasZipPassword") || false,
                hasPdfPassword: this.getvalue("hasPdfPassword") || false,
                autoDownload: this.getvalue("autoDownload") || false,
                customQuery: this.getvalue("customQuery") || "",
                query: this.getvalue("query") || "",
                pdfPassword: this.getvalue("pdfPassword") || "",
                zipPassword: this.getvalue("zipPassword") || "",
                filters: this.getvalue("filters") || [],
                filterActions: this.getvalue("filterActions") || [],
                customQueryActions: this.getvalue("customQueryActions") || [],
                applyAllFilters: this.getvalue("applyAllFilters") || false,
                isFilterDialogOpen: this.getvalue("isFilterDialogOpen") || false,
                isActionDialogOpen: this.getvalue("isActionDialogOpen") || false,
            })
        }
    }

    onActionsApply = (actions, copyActions = false) => {
        // iterate over the filters that are being added and assign an filterId property
        var count = 0
        let newActions = []
        let stateActions = this.state.customQuery ? this.state.customQueryActions : this.state.filterActions
        actions.forEach((item) => {
            // FILTER_TYPE_BOOLEAN_1 types only have value while others have value1 and value2
            if (!copyActions) {
                let filter = item.type === ACTION_TYPE_BOOLEAN
                    ? { ...item, actionId:this.state.customQueryActions.length + this.state.filterActions.length === 0 ? newActions.length : Math.max(Math.max(...this.state.customQueryActions.map(f => f.actionId)), Math.max(...this.state.filterActions.map(f => f.actionId))) + 1 + newActions.length, value: true }
                    : { ...item, actionId:this.state.customQueryActions.length + this.state.filterActions.length === 0 ? newActions.length : Math.max(Math.max(...this.state.customQueryActions.map(f => f.actionId)), Math.max(...this.state.filterActions.map(f => f.actionId))) + 1 + newActions.length, value1: '' }

                newActions.push(filter)
            } else {
                let filter = { ...item, actionId:this.state.customQueryActions.length + this.state.filterActions.length === 0 ? newActions.length : Math.max(Math.max(...this.state.customQueryActions.map(f => f.actionId)), Math.max(...this.state.filterActions.map(f => f.actionId))) + 1 + newActions.length}
                newActions.push(filter)
            }
            count++
        })

        this.state.customQuery ?
            this.props.handleAutomationSettingChanges([
                { name: 'isActionDialogOpen', value: false },
                { name: 'customQueryActions', value: [...stateActions, ...newActions] }
            ])
            :
            this.props.handleAutomationSettingChanges([
                { name: 'isActionDialogOpen', value: false },
                { name: 'filterActions', value: [...stateActions, ...newActions] }
            ]);

        this.showActionDialog(false)
    };

    onFilterApply = (filters, copyFilters = false) => {
        // iterate over the filters that are being added and assign an filterId property
        var count = 0
        let newFilters = []
        filters.forEach((item) => {
            // FILTER_TYPE_BOOLEAN_1 types only have value while others have value1 and value2
            if (!copyFilters) {
                let filter = item.type === FILTER_TYPE_BOOLEAN_1
                    ? { ...item, filterId:this.state.filters.length === 0 ? newFilters.length : Math.max(...this.state.filters.map(f => f.filterId)) + 1 + newFilters.length, value: '' }
                    : { ...item, filterId:this.state.filters.length === 0 ? newFilters.length : Math.max(...this.state.filters.map(f => f.filterId)) + 1 + newFilters.length, value1: '', value2: item.name === "From Date" || item.name === "To Date" ? new Date().toISOString().split('T')[0].replaceAll('-', '/') : "" }

                newFilters.push(filter)
            } else {
                let filter = { ...item, filterId:this.state.filters.length === 0 ? newFilters.length : Math.max(...this.state.filters.map(f => f.filterId)) + 1 + newFilters.length}
                newFilters.push(filter)
            }
            count++
        })

        this.props.handleAutomationSettingChanges([{
            name: "isFilterDialogOpen",
            value: false
        },{
            name: "filters",
            value:  [...this.state.filters, ...newFilters]
        }])
        this.showFilterDialog(false)
    };

    getvalue = (key) => {
        return this.props.parentState[key]
    }

    showFilterDialog = (show) => {
        this.props.handleAutomationSettingChanges([{
            name: "isFilterDialogOpen",
            value: show
        }])
    };

    handleAnyOrAllToggle = (event) => {
        this.props.handleAutomationSettingChanges([{
            name: "applyAllFilters",
            value: event.target.checked
        }])

    };

    updateActions = (newActions) => {
        this.state.customQuery ?
            this.props.handleAutomationSettingChanges([{
                name: "customQueryActions",
                value: newActions
            }])
            :
            this.props.handleAutomationSettingChanges([{
                name: "filterActions",
                value: newActions
            }])
    };

    deleteAction = (actionId) => {
        let stateActions = this.state.customQuery ? this.state.customQueryActions : this.state.filterActions
        const index = stateActions.findIndex((action) => action.actionId === actionId);
        if (index !== -1) {
            let actions = [...stateActions];
            actions.splice(index, 1);
            this.state.customQuery ?
                this.props.handleAutomationSettingChanges([{
                    name: "customQueryActions",
                    value: actions
                }])
                :
                this.props.handleAutomationSettingChanges([{
                    name: "filterActions",
                    value: actions
                }])
        }
    };

    updateAction = (newAction) => {
        let stateActions = this.state.customQuery ? this.state.customQueryActions : this.state.filterActions
        const index = stateActions.findIndex(
            (action) => action.actionId === newAction.actionId
        );

        if (index !== -1) {
            let actions = [...stateActions];
            actions.splice(index, 1, { ...newAction });
            this.state.customQuery ?
                this.props.handleAutomationSettingChanges([{
                    name: "customQueryActions",
                    value: actions
                }])
                :
                this.props.handleAutomationSettingChanges([{
                    name: "filterActions",
                    value: actions
                }])
        }
    };

    isFilterSet = (filterName) => {
        let filters = this.state.filters
        let isFilterSet = false
        for (let i = 0; i < filters.length; i++) {
            if (filters[i].name === filterName) {
                isFilterSet = true
                break
            }
        }
        return isFilterSet
    }

    showActionDialog = (show) => {
        this.props.handleAutomationSettingChanges([{
            name: "isActionDialogOpen",
            value: show
        }])
    }

    updateFilters = (newFilters) => {
        this.props.handleAutomationSettingChanges([{
            name: "filters",
            value: newFilters
        }])
    };

    updateFilter = (newFilter) => {
        const index = this.state.filters.findIndex(
            (filter) => filter.filterId === newFilter.filterId
        );

        if (index !== -1) {
            const filters = [...this.state.filters];
            filters.splice(index, 1, { ...newFilter });
            this.props.handleAutomationSettingChanges([{
                name: "filters",
                value: filters
            }])
        }
    };

    deleteFilter = (filterId) => {
        const index = this.state.filters.findIndex((filter) => filter.filterId === filterId);
        if (index !== -1) {
            const filters = [...this.state.filters];
            filters.splice(index, 1);
            this.props.handleAutomationSettingChanges([{
                name: "filters",
                value: filters
            }])
        }
    };

    copyFilter = (filterId) => {
        //Get filter from filterId and just copy it
        let filterToCopy = this.state.filters.find(filter => filter.filterId === filterId)
        let filterCopy = filterToCopy.type === FILTER_TYPE_BOOLEAN_1
            ? { ...filterToCopy, filterId:this.state.filters.length, value: filterToCopy.value }
            : { ...filterToCopy, filterId:this.state.filters.length, value1: filterToCopy.value1, value2: filterToCopy.value2 }
        this.onFilterApply([filterCopy], true)
    }

    displayQuerySection = () => {
        let querySection;
        let filterSection;
        if (this.state.autoDownload && this.state.customQuery) {
            filterSection=(
                <div>
                    <div>
                        <br/>
                        <TextField
                            className='query-text-field'
                            variant='outlined'
                            label="Query"
                            InputLabelProps={{shrink: true}}
                            name='query'
                            value={this.state.query}
                            onChange={(e) => {
                                this.setState({
                                    query: e.target.value
                                })
                                this.props.handleAutomationSettingChanges([{
                                    name: "query",
                                    value: e.target.value
                                }])
                            }
                            }
                            required
                            error={this.state.saved && (this.state.query === '' || this.state.query === undefined)} //TODO: Add error check for query format
                        />
                        <Tooltip
                            className='infoTooltipIcon'
                            style={{float: "right"}}
                            enterTouchDelay={0}
                            leaveTouchDelay={30000}
                            enterDelay={400}
                            // leaveDelay={1000000} // for testing purposes
                            title={
                                <React.Fragment>
                                    {INFO_ON_CUSTOM_QUERY}
                                </React.Fragment>
                            }
                        >
                            <IconButton aria-label='info'>
                                <InfoOutlinedIcon variant='outlined' fontSize='small'/>
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            );
        } else if (!this.state.autoDownload && this.state.customQuery) {
            filterSection = (
                <div>
                    <br/>
                    <TextField
                        className='query-text-field'
                        variant='outlined'
                        label="Query"
                        InputLabelProps={{shrink: true}}
                        name='query'
                        value={this.state.query}
                        onChange={(e) =>
                            this.props.handleAutomationSettingChanges([{
                                name: "query",
                                value: e.target.value
                            }])
                        }
                        //error={this.state.saved && this.state.name === ''} TODO: Add error check for query format
                    />
                    <Tooltip
                        className='infoTooltipIcon'
                        style={{float: "right"}}
                        enterTouchDelay={0}
                        leaveTouchDelay={30000}
                        enterDelay={400}
                        // leaveDelay={1000000} // for testing purposes
                        title={
                            <React.Fragment>
                                {INFO_ON_CONTENT_FILTER}
                            </React.Fragment>
                        }
                    >
                        <IconButton aria-label='info'>
                            <InfoOutlinedIcon variant='outlined' fontSize='small'/>
                        </IconButton>
                    </Tooltip>
                    </div>
        )
            ;
        } else {
            filterSection = (
                <div>
                    <DndProvider backend={HTML5Backend}>
                        <DragAndDrop
                            cards={this.state.filters}
                            handleTableDisplayChange = {this.updateFilters}
                            items = {this.state.filters?.map((filter, i) => {
                                switch (filter.type) {
                                    case FILTER_TYPE_BOOLEAN_1:
                                        return ({
                                            id: filter.filterId,
                                            component: <div>
                                                <FilterBooleanSelect
                                                    filter={filter}
                                                    updateFilterWithParent={this.updateFilter}
                                                    deleteFilter={this.deleteFilter}
                                                    isEdit={this.props.isEdit}
                                                    copyFilter={this.copyFilter}
                                                    key={filter.filterId}
                                                />
                                            </div>
                                        });
                                    case FILTER_TYPE_TEXT:
                                        return ({
                                            id: filter.filterId,
                                            component:<div>
                                                <QueryFilterTextField
                                                    filter={filter}
                                                    updateFilterWithParent={this.updateFilter}
                                                    deleteFilter={this.deleteFilter}
                                                    isEdit={this.props.isEdit}
                                                    copyFilter={this.copyFilter}
                                                    key={filter.filterId}
                                                />
                                            </div>
                                        });
                                }

                            })}
                        />
                    </DndProvider>
                    <Button
                        className='automationRulesFilterButton'
                        variant='contained'
                        color='primary'
                        startIcon={<AddIcon />}
                        onClick={() => this.showFilterDialog(true)}
                    >
                        {"Filter"}
                    </Button>
                    <br />
                    <FormControlLabel
                        className='anyAllSwitch'
                        label={
                            ANY_ALL_PART_1 +
                            ' ' +
                            (this.state.applyAllFilters ? ANY_ALL_PART_2B : ANY_ALL_PART_2A) +
                            ' ' +
                            ANY_ALL_PART_3
                        }
                        control={
                            <Switch
                                color='primary'
                                checked={this.state.applyAllFilters}
                                onChange={this.handleAnyOrAllToggle}
                            />
                        }
                    />
                </div>
            )
        }
        let stateActions = this.state.customQuery ? this.state.customQueryActions : this.state.filterActions
        querySection=(
            <div>
                <QueryConditionDialog
                    isOpen={this.state.isFilterDialogOpen}
                    onClose={this.showFilterDialog}
                    onApply={this.onFilterApply}
                    tableType={TABLE_TYPES.FILTER}
                    isFilterSet={this.isFilterSet}
                />
                <QueryConditionDialog
                    isOpen={this.state.isActionDialogOpen}
                    onClose={this.showActionDialog}
                    onApply={this.onActionsApply}
                    tableType={TABLE_TYPES.ACTION}
                />
                <Toolbar className="conditionsToolbar"></Toolbar>
                <div>
                    {filterSection}
                </div>
                <Divider className='divider' />
                <div>
                    <DndProvider backend={HTML5Backend}>
                        <DragAndDrop
                            cards={stateActions}
                            handleTableDisplayChange={this.updateActions}
                            items = {stateActions?.map((action, i) => {
                                switch (action.type) {
                                    case ACTION_TYPE_BOOLEAN:
                                        return ({
                                            id: action.actionId,
                                            component: <div>
                                                <QueryActionBoolean
                                                    action={action}
                                                    updateActionWithParent={this.updateAction}
                                                    deleteAction={this.deleteAction}
                                                    isEdit={this.props.isEdit}
                                                    key={action.actionId}
                                                />
                                            </div>
                                        });
                                    case ACTION_TYPE_TEXT:
                                        return ({
                                            id: action.actionId,
                                            component: <div>
                                                <QueryActionText
                                                    action={action}
                                                    updateActionWithParent={this.updateAction}
                                                    deleteAction={this.deleteAction}
                                                    isEdit={this.props.isEdit}
                                                    key={action.actionId}
                                                />
                                            </div>
                                        });
                                }
                            })}
                        />
                    </DndProvider>
                </div>
                <Button
                    className='queryActionButton'
                    variant='contained'
                    color='primary'
                    startIcon={<AddIcon />}
                    onClick={() => this.showActionDialog(true)}
                >
                    {ADD_ACTION}
                </Button>
                <br />
                <br />
            </div>
        );
        return (
            <div>
                <Paper className='queryType' variant='outlined' style={{minWidth:950}}>{ querySection }</Paper>
            </div>
        )
    }

    render() {
        return (
            <>
                <div>
                    <div className='stepper-page-three-grid'>
                        <FormControlLabel
                            className="autoDownload"
                            control={
                                <Checkbox
                                    checked={this.state.autoDownload}
                                    onChange={(e) =>
                                        this.props.handleAutomationSettingChanges([{
                                            name: "autoDownload",
                                            value: e.target.checked
                                        }])
                                    }
                                />
                            }
                            label={"Enable automatic download of invoices"}
                        />
                        <br/>
                        <div>
                            <FormControlLabel
                                className='invoiceQuerySwitch'
                                control={
                                    <Switch
                                        color='primary'
                                        checked={this.state.hasZipPassword}
                                        onChange={(e) =>
                                            this.props.handleAutomationSettingChanges([{
                                                name: "hasZipPassword",
                                                value: e.target.checked
                                            }])
                                        }
                                    />
                                }
                                label="Use password for UNZIP"
                            />
                            {this.state.hasZipPassword && (
                                <TextField
                                    style={{width: "15%"}}
                                    variant='outlined'
                                    label="ZIP Password"
                                    InputLabelProps={{shrink: true}}
                                    name='zipPassword'
                                    required
                                    InputProps={{
                                        style: {height: '44px'}
                                    }}
                                    value={this.state.zipPassword}
                                    onChange={(e) =>
                                        this.props.handleAutomationSettingChanges([{
                                            name: "zipPassword",
                                            value: e.target.value
                                        }])
                                    }
                                    error={this.state.saved && this.state.zipPassword === ''}
                                />
                            )}
                        </div>
                        <div style={{marginTop: "8px", marginBottom:"8px"}}>
                            <FormControlLabel
                                className='invoiceQuerySwitch'
                                control={
                                    <Switch
                                        color='primary'
                                        checked={this.state.hasPdfPassword}
                                        onChange={(e) =>
                                            this.props.handleAutomationSettingChanges([{
                                                name: "hasPdfPassword",
                                                value: e.target.checked
                                            }])
                                        }
                                    />
                                }
                                label="Use password for PDF"
                            />
                            {this.state.hasPdfPassword && (
                                <TextField
                                    style={{width: "15%", marginLeft: "16px"}}
                                    variant='outlined'
                                    label="PDF Password"
                                    InputLabelProps={{shrink: true}}
                                    name='pdfPassword'
                                    InputProps={{
                                        style: {height: '44px'}
                                    }}
                                    required
                                    value={this.state.pdfPassword}
                                    onChange={(e) =>
                                        this.props.handleAutomationSettingChanges([{
                                            name: "pdfPassword",
                                            value: e.target.value
                                        }])
                                    }
                                    error={this.state.saved && this.state.pdfPassword === ''}
                                />
                            )}
                        </div>
                            <FormControlLabel
                                className='invoiceQuerySwitch'
                                control={
                                    <Switch
                                        color='primary'
                                        checked={this.state.customQuery}
                                        onChange={(e) =>
                                            this.props.handleAutomationSettingChanges([{
                                                name: "customQuery",
                                                value: e.target.checked
                                            }])
                                        }
                                    />
                                }
                                label={
                                    this.state.customQuery ? "Use custom query (Advanced)" : "Use the wizard to specify filters and actions (Recommended)"
                                }
                            />

                            <br/>
                    </div>
                    {this.displayQuerySection()}

                </div>
            </>
        )
    }
}

const mapStateToProps = () => ({})

const actionCreators = {}

export default withCarrier({
    mapStateToProps,
    actionCreators
}, AutomationSetting);