import React from "react";

import {ADD_CHEQUE, CHEQUE, CHEQUE_FIELDS_HEAD_CELLS, CHEQUE_PAGES, IMPORT_CHEQUE} from "./constants";
import { TableCell} from "@mui/material";
import {Filter} from "../../global/Table/TablePageHelpers/FilterObject";
import EditIcon from "@material-ui/icons/Edit";
import {ASC, DESC} from "../../global/constants";
import {
    DEFAULT_SORT_BY_HEAD_CELL,
    ROWS_PER_PAGE_LABEL
} from "./constants";
import Tooltip from "@material-ui/core/Tooltip";
import FilterIcon from "../../shipments/FilterIcon";

import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import ChequeFiltersDialog from "./ChequeFiltersDialog";

// Table components
import CustomTableHeader from "../../global/Table/CustomTableHeader";
import {Checkbox, IconButton, Slide, TablePagination} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import GlobalTableHead from "../../global/Table/GlobalTableHead";
import TableBody from "@material-ui/core/TableBody";
import SearchableTable from "../../global/Search/SearchableTable";
import TableRow from "@material-ui/core/TableRow";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import PublishIcon from "@material-ui/icons/Publish";
import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";
import FilterTag from "../../shipments/FilterTag";


class ChequeTable extends SearchableTable {
    constructor(props) {
        super(props);
        this.state = {
            deleteId: "",
            selectedBankAccount: null,
            isSearch: false,
            searchValue: "",
            order: DESC,
            orderBy: DEFAULT_SORT_BY_HEAD_CELL,
            shownIndex: -1,
            fromDate: this.props.fromDate,
            toDate: this.props.toDate,
            filtersMap: {},
            showFilters: false,
            filtersMapBeforeOpenDialog: {},
            deletedTagsWithCookies: [],

        }
        this.onSearchValueChange = this.onSearchValueChange.bind(this)
        this.handleChangePage = this.handleChangePage.bind(this)
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)
        this.handleRequestSort = this.handleRequestSort.bind(this)
        this.getDisplayed = this.getDisplayed.bind(this)
        this.selectAll = this.selectAll.bind(this)
    }


    formatDollar = (amount) => { // Regex expression taken from https://stackoverflow.com/questions/2901102
        return amount.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }

    async componentDidMount() {
        let changeBack = false
        await this.setState({
            searchValue: this.props.searchText,
        })
        let storage = JSON.parse(localStorage.getItem('cheques'))
        if (storage) {
            this.getNewDates()
            const expiry = parseInt(JSON.parse(localStorage.getItem("expiry")))
            if (storage.fromDate !== null && storage.fromDate !== undefined) {
                if (expiry > new Date().getTime()) {
                    this.onFiltersClose(true, false)
                } else {
                    changeBack = true
                }
            }
            if (storage.toDate !== null && storage.toDate !== undefined) {
                if (expiry > new Date().getTime()) {
                    this.onFiltersClose(true, false)
                } else {
                    changeBack = true
                }
            }
            if (changeBack) {
                localStorage.removeItem('cheques')
                this.setState({
                    fromDate: this.getFromDate(),
                    toDate: this.getToDate(),
                    filtersMap: {}
                })
            }

        }

        if(this.props.setFetchedList){
            this.props.fetchCheques({
                fromDate: this.state.fromDate,
                toDate:  this.state.toDate
            })
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.searchText !== this.props.searchText || this.state.searchValue !== this.props.searchText) {
            this.initializeSearchText()
        }

    }

    initializeSearchText = () => {
        this.setState({ searchValue: this.props.searchText })
    }

    onSearchValueChange(e) {
        this.setState({
            isSearch: e.target.value.length > 0,
            searchValue: e.target.value
        });
        this.props.setPage(0)
        this.props.handleSearchChange(e)
    };

    getFromDate = () => {
        const date = new Date();
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setDate(date.getDate() - 14)
        return date;
    }

    getToDate = () => {
        const date = new Date();
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
        return date;
    }

    getNewDates = () => {
        let filter = {...this.state.filtersMap}
        let storage = JSON.parse(localStorage?.getItem('cheques'))
        if (storage.fromDate !== null && storage.fromDate !== undefined ) {
            const temp = new Date(storage.fromDate)
            filter.fromDate = temp;
            this.setState({
                fromDate: temp
            })
        }
        if (storage.toDate !== null && storage.toDate !== undefined) {
            const temp = new Date(storage.toDate)
            filter.toDate = temp;
            this.setState({
                toDate: temp
            })
        }

        if (filter) {
            this.setState({
                filtersMap: filter,
            })
        }
    }

    isDefaultFromDate = (someDate) => {
        const defaultFromDate = this.getFromDate()
        return someDate.getDate() === defaultFromDate.getDate() &&
            someDate.getMonth() === defaultFromDate.getMonth() &&
            someDate.getFullYear() === defaultFromDate.getFullYear()
    }


    isToday = (someDate) => {
        const today = new Date()
        return someDate.getDate() === today.getDate() &&
            someDate.getMonth() === today.getMonth() &&
            someDate.getFullYear() === today.getFullYear()
    }

    addFilterDates = (filters) => {
        if (filters) {
            if (!this.isDefaultFromDate(this.state.fromDate)) {
                filters['fromDate'] = this.state.fromDate
            }
            if (!this.isToday(this.state.toDate)) {
                filters['toDate'] = this.state.toDate
            }
        }
        return filters
    }

    handleChangePage = (event, newPage) => {
        this.props.setPage(newPage)
    };
    handleChangeRowsPerPage = (event) => {
        this.props.setRowsPerPage(event.target.value)
        this.props.setPage(0)
    };
    handleRequestSort = (event, property) => {
        const isAsc = this.state.orderBy === property && this.state.order === ASC;
        this.setState({
            order: isAsc ? DESC : ASC,
            orderBy: property,
        });
    };
    getDisplayed = (rows) => {
        if (!rows)
            return [];
        return [...rows.slice(this.props.page * this.props.rowsPerPage, (this.props.page + 1) * this.props.rowsPerPage)];
    }

    openDropdown(index) {
        let val = index
        if (this.state.shownIndex === index) val = -1
        this.setState({ shownIndex: val })
    }
    handleEdit(template) {
        this.props.setEditing(template)
    }

    handleDeleteCheques = () => {
        let chequeToDelete = []
        if (this.state.deleteId) {
            chequeToDelete.push(this.state.deleteId)
        } else if (this.state.selectedEntries.length > 0) {
            chequeToDelete.push(...this.state.selectedEntries)
        }
        this.props.deleteCheques({cheques: chequeToDelete, fromDate: this.state.fromDate, toDate: this.state.toDate},
            () => {
                this.setState({
                    deleteId: "",
                    deleting: false,
                    isSelecting: false,
                    selectedEntries: [],
                })
            })
    }

    handleCheck(e, cheque) {
        let newEntries = { ...this.props.checkedEntries, [cheque.chequeId]: e.target.checked }
        this.props.setSelected(newEntries, !!this.numSelected(newEntries))
    }
    selectAll(e) {
        let newChecked = {}
        let allEntries = this.getDisplayed(this.stableSort(this.applyOverviewFilters(this.filterBySearch(this.props.data)), this.getComparator(this.state.order, this.state.orderBy)))
        allEntries.map((entry) => newChecked[entry.chequeId] = e.target.checked)
        this.props.setSelected(newChecked, !!this.numSelected(newChecked))
    }
    numSelected(checkedEntries) {
        let rows = this.getDisplayed(this.stableSort(this.applyOverviewFilters(this.filterBySearch(this.props.data)), this.getComparator(this.state.order, this.state.orderBy)))
        return rows.reduceRight((accum, curr) => accum + (checkedEntries[curr.chequeId] ?? 0), 0)
    }

    toggleFiltersDialog = () => {
        this.setState({
            showFilters: !this.state.showFilters,
            filtersMapBeforeOpenDialog: { ...this.state.filtersMap }
        })
    }


    onFiltersInputChange = (name, val) => {
        const filtersMap = { ...this.state.filtersMap };

        // if filter already exists, then update
        if (val !== "" && val.length !== 0) {
            filtersMap[name] = val;
        }
        else {
            delete filtersMap[name];
        }

        this.saveUpdatedStatus(filtersMap)

        this.setState({
            filtersMap: filtersMap,
        });

    }

    saveUpdatedStatus = (filters) => {
        delete filters['fromDate']
        delete filters['toDate']
    }

    handleDateChange = (date, key) => {
        const filtersMap = { ...this.state.filtersMap };
        if (key === 'fromDate' && !this.isDefaultFromDate(date) ||
            key === 'toDate' && !this.isToday(date)) {
            filtersMap[key] = date;
        } else {
            delete filtersMap[key]
        }
        this.setState({
            filtersMap: filtersMap,
            [key]: date
        })
        this.props.handleDateChange(date, key)
    }

    onFiltersClose = (applyFilters, saveFilterSetting) => {
        // NOTE: changes are already applied as the user makes these changes,
        // but if the changes are cancelled, then the map is reverted to its
        // previous state
        this.setState({
            showFilters: false,
        })
        let filters = {}
        if (!applyFilters) {
            filters = { ...this.state.filtersMapBeforeOpenDialog }
            this.resetDateFilters(filters?.fromDate ?? this.getFromDate(), filters?.toDate ?? this.getToDate(), filters)
        } else {
            if (this.state.deletedTagsWithCookies.length > 0) {
                let storage = JSON.parse(localStorage.getItem('cheques'))
                this.state.deletedTagsWithCookies.forEach((tagName) => {
                    delete storage[tagName]
                })
                localStorage.setItem('cheques', JSON.stringify(storage))
                if(JSON.stringify(storage) == JSON.stringify({})) localStorage.removeItem('cheques')
                this.setState({deletedTagsWithCookies: []})
            }
            filters = { ...this.state.filtersMap }
            const expiry = new Date().setHours(23, 59, 59, 0);
            let needRefresh = this.props.fetchedList
            if (filters.fromDate !== null && filters.fromDate !== undefined) {
                let existing = localStorage.getItem('cheques')
                existing = existing ? JSON.parse(existing) : {};
                if(existing.fromDate!==filters.fromDate.toISOString()) {
                    needRefresh = true
                }
                existing.fromDate = filters.fromDate;
                localStorage.setItem('cheques', JSON.stringify(existing));
                localStorage.setItem("expiry", JSON.stringify(expiry))
            }
            if (filters.toDate !== null && filters.toDate !== undefined) {
                let existing = localStorage.getItem('cheques')
                existing = existing ? JSON.parse(existing) : {};
                if(existing.toDate!==filters.toDate.toISOString()) {
                    needRefresh = true
                }
                existing.toDate = filters.toDate;
                localStorage.setItem('cheques', JSON.stringify(existing));
                localStorage.setItem("expiry", JSON.stringify(expiry))
            }
            //fetching all the cheque
            if (this.props.data.length===0||needRefresh){
                this.fetchCheques(this.state.fromDate, this.state.toDate)
                this.props.setFetchedList(false)
            }
        }
        if (saveFilterSetting) {
            this.saveUpdatedStatus(filters)
        }
    }

    resetDateFilters = (fromDate, toDate, filters) => {
        if (!this.isDefaultFromDate(fromDate)) {
            filters['fromDate'] = fromDate
        } else {
            delete filters['fromDate']
            fromDate = this.getFromDate()
        }
        if (!this.isToday(toDate)) {
            filters['toDate'] = toDate
        } else {
            delete filters['toDate']
            toDate = this.getToDate()
        }
        this.setState({
            fromDate: fromDate,
            toDate: toDate,
            filtersMap: filters,
            deletedTagsWithCookies: [],
        })
        this.props.handleDateChange(fromDate, 'fromDate')
        this.props.handleDateChange(toDate, "toDate")
    }

    onTagClickedWhenDialogueOpen = (event, tag) => {
        const filtersMap = { ...this.state.filtersMap };
        let tagValue = filtersMap[tag]
        let deletedTagsWithCookies = this.state.deletedTagsWithCookies
        delete filtersMap[tag];
        if (tag === 'fromDate') {
            let storage = JSON.parse(localStorage.getItem('cheques'))
            if (storage) {
                if (storage.fromDate !== null && storage.fromDate !== undefined) {
                    if (new Date(storage.fromDate).getTime() === new Date(tagValue).getTime()) {
                        this.setState({deletedTagsWithCookies: [...deletedTagsWithCookies, "fromDate"]})
                    }
                }
            }
            this.setState({
                fromDate: this.getFromDate()
            })
        }
        if (tag === 'toDate') {
            let storage = JSON.parse(localStorage.getItem('cheques'))
            if (storage) {
                if (storage.toDate !== null && storage.toDate !== undefined) {
                    if (new Date(storage.toDate).getTime() === new Date(tagValue).getTime()) {
                        this.setState({deletedTagsWithCookies: [...deletedTagsWithCookies, "toDate"]})
                    }
                }
            }
            this.setState({
                toDate: this.getToDate()
            })
        }
        this.saveUpdatedStatus(filtersMap)
        this.setState({
            filtersMap: filtersMap,
        });
    }



    onTagClicked = (event, tag) => {

        // remove the key from the filters map
        // clone the map, delete the key, set the state
        const filtersMap = { ...this.state.filtersMap };
        delete filtersMap[tag];
        if (tag === 'fromDate') {
            let storage = JSON.parse(localStorage.getItem('cheques'))
            delete storage.fromDate
            this.setState({
                fromDate: this.getFromDate()
            }, () => this.fetchCheques(this.state.fromDate, this.state.toDate))
            localStorage.setItem('cheques', JSON.stringify(storage))
            if(JSON.stringify(storage) == JSON.stringify({})) localStorage.removeItem('cheques')
        }
        if (tag === 'toDate') {
            let storage = JSON.parse(localStorage.getItem('cheques'))
            delete storage.toDate
            this.setState({
                toDate: this.getToDate()
            }, () => this.fetchCheques(this.state.fromDate, this.state.toDate))
            localStorage.setItem('cheques', JSON.stringify(storage))
            if(JSON.stringify(storage) == JSON.stringify({})) localStorage.removeItem('cheques')
        }

        this.saveUpdatedStatus(filtersMap)
        this.setState({
            filtersMap: filtersMap,
        });
    }

    fetchCheques = (fromDate, toDate) => {
        this.props.fetchCheques({
            fromDate: fromDate,
            toDate: toDate
        })
    }

    applyOverviewFilters = (rows) => {
        const categoryFilters = this.state.filtersMap?.category;

        if (categoryFilters) {
            // Extract the names from the category objects
            const categoryNames = categoryFilters.map(filter => filter.name);

            // Filter the rows based on the category names
            rows = rows.filter(row => {
                return categoryNames.includes(row.category);
            });
        }

        return rows;
    }


    getContextualMenuItems = () => {
        let menuItems = []
        menuItems.push(
            {
                title: ADD_CHEQUE,
                icon: <AddCircleIcon/>,
                onClick: () => this.props.setCurrentPage(CHEQUE_PAGES.ADD_CHECK, {})
            },
            {
                title: IMPORT_CHEQUE,
                icon: <PublishIcon/>,
                onClick: () => this.props.importCSV( this.state.fromDate,this.state.toDate)
            }
        )
        return menuItems
    }

    createTags = (filtersMap,onClick) => {
        if (!filtersMap) return;

        let keys = Object.keys(filtersMap);
        let children = [];

        keys.map(key => {
            const tags = filtersMap[key]; // Get all tags for the current key
            let categoryNames = []; // Array to hold category names

            if (Array.isArray(tags)) {
                // Collect category names from the tags array
                tags.forEach(tag => {
                    categoryNames.push(tag.name); // Add tag name to the array
                });
            } else {
                // Handle non-array value (e.g., date or other types)
                let namePrefix = this.getName(key); // Get the appropriate name prefix
                let formattedValue = this.isDateFormat(key) ? this.formatDate(tags) : tags; // Format the date if applicable

                categoryNames.push(`${formattedValue}`); // Add the formatted value to the array
            }

            // Create a single category string
            const categoryString = categoryNames.join(', '); // Join categories with a comma

            children.push(
                <div className="shipment-tag" key={key}> {/* Use key to avoid duplicate keys */}
                    <FilterTag
                        tag={key}
                        id={key}
                        removeTag={onClick}
                        name={`${this.getName(key)} ${categoryString}`} // Set name to the combined categories
                    />
                </div>
            );
        });

        return children;
    }



    isDateFormat = (key) => {
        return key === "fromDate" || key === "toDate"
    }
    getName = (key) => {
        let name

            switch (key) {
                case "fromDate":
                    name = "FROM: "
                    break
                case "toDate":
                    name = "TO: "
                    break
                default:
                    name = key + ": "
            }

        return name
    }

    formatDate = (dateString) => {
        let date = new Date(dateString)

        let MM_dd_yyyy = date.toLocaleDateString("en-US")
        let MM = MM_dd_yyyy.split("/")[0]
        if (MM.length === 1) {MM = "0" + MM}
        let dd = MM_dd_yyyy.split("/")[1]
        if (dd.length === 1) {dd = "0" + dd}
        let yyyy = MM_dd_yyyy.split("/")[2].substr(0,4)

        return yyyy + "-" + MM + "-" + dd
    }

    render() {
        let rows = this.getDisplayed(this.stableSort(this.filterBySearch(this.applyOverviewFilters(this.props.data)), this.getComparator(this.state.order, this.state.orderBy)))
        return (
            <>
                <TopToolbar
                    pageName={CHEQUE}
                    menuItems={this.getContextualMenuItems()}
                />
                <div className='filter-tag-wrapper'>

                    <div className="filterTagsBar" id="filtersTagBar">
                        {this.createTags(this.state.filtersMap,this.state.showFilters ? this.onTagClickedWhenDialogueOpen : this.onTagClicked)}
                    </div>

                </div>
                <CustomTableHeader
                    searchText={this.props.searchText}
                    onSearchValueChange={this.onSearchValueChange}
                    pagination={
                        <TablePagination
                            className="table-pagination"
                            labelRowsPerPage={ROWS_PER_PAGE_LABEL}
                            rowsPerPageOptions={[5, 25, 50, 100, {value: this.props.data.length, label: "All"} ]}
                            count={!this.props.data ? 0 : this.filterBySearch(this.applyOverviewFilters(this.props.data)).length}
                            rowsPerPage={this.props.rowsPerPage}
                            page={this.props.page}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    }
                    showFilters={this.state.showFilters}
                    filtersComponent={
                        <Slide in={this.state.showFilters} mountOnEnter unmountOnExit timeout={300}>
                            <ChequeFiltersDialog
                                open={this.state.showFilters}
                                onClose={this.onFiltersClose}
                                onChange={this.onFiltersInputChange}
                                listItems={this.props.categories}
                                handleDateChange={this.handleDateChange}
                                filtersMap={this.state.filtersMap}
                                clearFilters={this.clearFiltersMap}
                                onTagClicked={this.onTagClicked}
                                fromDate={this.state.fromDate}
                                toDate={this.state.toDate}
                                fieldName={'category'}
                                fieldTitle={'Category'}
                            />
                        </Slide>}

                    filterIcon={
                        <Tooltip title={'Filter'} style={{ paddingTop: 10 }}>
                            <IconButton className="filterButton" size='small' aria-label={'Filter'} onClick={this.toggleFiltersDialog} >
                                <FilterIcon />
                            </IconButton>
                        </Tooltip>
                    }
                />


                <TableContainer>
                    <Table size='medium'>
                        <GlobalTableHead
                            isCreatePurchaseOrderTable={false}
                            isShipmentTable={false}
                            isPurchaseOrderTable={false}
                            isCheque={true}
                            headCells={CHEQUE_FIELDS_HEAD_CELLS}
                            isInvoiceTable={true}
                            order={this.state.order}
                            orderBy={this.state.orderBy}
                            onRequestSort={this.handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {rows.map((cheque, index) => (
                                <TableRow hover onDoubleClick={() => this.props.setCurrentPage(CHEQUE_PAGES.ADD_CHECK, cheque)} key={"cheque-" + index}>
                                    <TableCell>
                                        <IconButton size="small" onClick={() => this.props.setCurrentPage(CHEQUE_PAGES.ADD_CHECK, cheque)}>
                                            <EditIcon/>
                                        </IconButton>
                                    </TableCell>

                                    <TableCell>
                                    </TableCell>

                                    {CHEQUE_FIELDS_HEAD_CELLS.map((headCell, index) => (
                                        <TableCell
                                            key={index}
                                            style={{
                                                textAlign: "left",
                                                 paddingLeft: "24px",
                                                // paddingRight: "24px",
                                            }}
                                        >
                                            {headCell.addDollar ? (
                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                    gap: '3px',
                                                    marginRight: '15px'
                                                }}>
                                                    <span style={{ flexShrink: 0 }}>$</span>
                                                    <span style={{ marginLeft: 'auto' }}>
                    {this.formatDollar(cheque[headCell.id])}
                </span>
                                                </div>
                                            ) : (
                                                cheque[headCell.id] && cheque[headCell.id].length > 28
                                                    ? `${cheque[headCell.id].substring(0, 28)}...`
                                                    : cheque[headCell.id]
                                            )}
                                        </TableCell>
                                    ))}

                                    <TableCell style={{padding: "5px", width: "205px"}}>
                                        {this.state.deleteId !== cheque.chequeId ?
                                            <IconButton
                                                onClick={() => this.setState({deleteId: cheque.chequeId})}>
                                                <DeleteIcon/>
                                            </IconButton> : <div>
                                                <Button onClick={() => this.setState({deleteId: ""})}>
                                                    Cancel
                                                </Button>
                                                <Button onClick={this.handleDeleteCheques} variant="contained"
                                                        startIcon={<DeleteIcon/>}>
                                                    Delete
                                                </Button>
                                            </div>
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        )
    }
}




export default ChequeTable;