import React from "react";

import { Table, TableBody, TableContainer, TablePagination, IconButton } from "@material-ui/core";
import SearchableTable from "../../global/Search/SearchableTable";
import {
    CREATE_PACKAGE_TITLE, MANAGE_PACKAGES_HEAD_CELLS, PACKAGES_TOOLBAR_TITLE,
    DEFAULT_ROWS_PER_PAGE, DEFAULT_SORT_BY_HEAD_CELL, ROWS_PER_PAGE_LABEL, BACK_TO_MENU
} from "./constants";
import { ASC, DESC } from "../../global/constants";
import Paper from "@material-ui/core/Paper";
import GlobalTableHead from "../../global/Table/GlobalTableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import EditIcon from "@material-ui/icons/Edit";
import PropTypes from "prop-types";
import withShipment from "../../../withShipment";
import CustomToolbar from "../../global/CustomToolbar";
import CustomTableHeader from "../../global/Table/CustomTableHeader";
import { userInfoSelector } from "../../../redux/selectors/auth";
import AddPackageForm from "./AddPackageForm";
import {
    packagesSelector,
    availableLocationsSelector,
    categorySelector,
    countriesSelector
} from "../../../redux/selectors/settings";
import {
    listPackages,
    updatePackage,
    createPackage,
    listAvailableLocations, getAllCategory, getCountryCodes
} from "../../../redux/actions/settings";
import ErrorFeedbackBar from "../../global/ErrorFeedbackBar";

class ManagePackagesTable extends SearchableTable {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            addingPackage: false,
            order: ASC,
            orderBy: DEFAULT_SORT_BY_HEAD_CELL,
            isSearch: false, // true if search value is not empty, false otherwise
            searchValue: "", // the value in the search field (updates on change)
            searchRows: 0, // number of rows displayed while search criteria is met
            editingPackage: null,
            rowsPerPage: DEFAULT_ROWS_PER_PAGE,
            testData: [
                {
                    shortcode: "sc",
                    manufacturerPartNum: "mannum",
                    paleteQuantity: "pqty",
                    nominalSize: "nsize",
                    actualSize: "asize",
                    textDescription: "desc",
                    fluteType: "ftype",
                    boxStrength: "bstr",
                    nominalLength: 18,
                    nominalWidth: 19,
                    nominalHeight: 20,
                    actualLength: 28,
                    actualWidth: 29,
                    actualHeight: 22,
                }
            ]
        }

        this.props.getAllCategory()
        this.props.getCountryCodes()
    }

    componentDidMount() {
        this.props.listPackages()
        this.props.listAvailableLocations()
    }

    handleCancel = () => {
        this.setState({
            addingPackage: false,
        }, () => {
            this.setState({
                editingPackage: null
            })
        })
    }

    getDisplayed = (rows) => {
        if (!rows)
            return [];
        return [...rows.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)];
    }

    onSearchValueChange = (e) => {
        this.setState({
            isSearch: e.target.value.length > 0,
            searchValue: e.target.value,
            page: 0
        });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: event.target.value,
            page: 0,
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
        });
    };

    handleRequestSort = (event, property) => {
        const isAsc = this.state.orderBy === property && this.state.order === ASC;
        this.setState({
            order: isAsc ? DESC : ASC,
            orderBy: property,
        });
    };

    handleOpenProduct = (pkg) => {
        this.setState({
            editingPackage: pkg
        })
    }

    handleSave = (creatingPackage, data) => {
        if (creatingPackage) {
            this.props.createPackage(data, () => window.location.reload())
        }
        else {
            this.props.updatePackage(data)
            this.handleCancel();
        }
    }

    render() {
        return (
            <>
                <div>
                    <ErrorFeedbackBar />
                    <Paper className="paper">
                        <CustomToolbar
                            creatingOrder={this.state.addingPackage || (!!this.state.editingPackage)}
                            title={PACKAGES_TOOLBAR_TITLE}
                            createTitle={CREATE_PACKAGE_TITLE}
                            backTitle={BACK_TO_MENU}
                            onClick={() => { this.setState({ addingPackage: true }) }}
                            handleClose={this.handleCancel}
                        />

                        {!this.state.editingPackage ?
                            this.state.addingPackage ?
                                <AddPackageForm
                                    creatingProduct={true}
                                    handleSave={this.handleSave}
                                    handleCancel={this.handleCancel}
                                    packages={this.props.packages}
                                    // fetchAlerts={this.props.fetchAlerts}
                                    user={this.props.user}
                                    locations={this.props.availableLocations}
                                    categories={this.props.categories}
                                    countries={this.props.countries}
                                /> :
                                <>
                                    <CustomTableHeader
                                        searchText={this.state.searchValue}
                                        onSearchValueChange={this.onSearchValueChange}
                                        pagination={
                                            <TablePagination
                                                className="table-pagination"
                                                labelRowsPerPage={ROWS_PER_PAGE_LABEL}
                                                rowsPerPageOptions={[5, 25, 50, 100, {value: this.props.packages.length, label: "All"} ]}
                                                count={!this.props.packages ? 0 : this.filterBySearch(this.props.packages).length}
                                                rowsPerPage={this.state.rowsPerPage}
                                                page={this.state.page}
                                                onChangePage={this.handleChangePage}
                                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                            />
                                        }
                                    />

                                    <TableContainer>
                                        <Table size="medium">
                                            <GlobalTableHead
                                                isCreatePurchaseOrderTable={false}
                                                isShipmentTable={false}
                                                headCells={MANAGE_PACKAGES_HEAD_CELLS}
                                                order={this.state.order}
                                                orderBy={this.state.orderBy}
                                                onRequestSort={this.handleRequestSort}
                                                rowCount={!this.props.packages ? 0 : this.props.packages.length}
                                            />
                                            <TableBody>
                                                {this.getDisplayed(this.stableSort(this.filterBySearch(this.props.packages), this.getComparator(this.state.order, this.state.orderBy))).map((row, index) => {
                                                    return (
                                                        <TableRow hover onDoubleClick={() => this.handleOpenProduct(row)} key={index}>
                                                            <TableCell>
                                                                <IconButton onClick={() => this.handleOpenProduct(row)}>
                                                                    <EditIcon />
                                                                </IconButton>
                                                            </TableCell>
                                                            <TableCell className='cellPadding'>
                                                                {row.shortcode}
                                                            </TableCell>
                                                            <TableCell className='cellPadding'>
                                                                {row.actualSize}
                                                            </TableCell>
                                                            <TableCell className='cellPadding'>
                                                                {row.nominalSize}
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </>
                            :
                            <AddPackageForm
                                creatingProduct={false}
                                editingProduct={this.state.editingPackage}
                                handleSave={this.handleSave}
                                handleCancel={this.handleCancel}
                                packages={this.props.packages}
                                // products={this.props.products}
                                // fetchAlerts={this.props.fetchAlerts}
                                user={this.props.user}
                                locations={this.props.availableLocations}
                                categories={this.props.categories}
                                countries={this.props.countries}
                            />
                        }

                    </Paper>
                </div>
            </>
        )
    }


}


const mapStateToProps = (state) => ({
    user: userInfoSelector(state),
    packages: packagesSelector(state),
    availableLocations: availableLocationsSelector(state),
    categories: categorySelector(state),
    countries: countriesSelector(state)
})

const actionCreators = {
    listPackages,
    updatePackage,
    createPackage,
    listAvailableLocations,
    getAllCategory,
    getCountryCodes
}

ManagePackagesTable.propTypes = {
    users: PropTypes.array,
    packages: PropTypes.array,
    availableLocations: PropTypes.array,
}

ManagePackagesTable.defaultProps = {
    users: [],
    packages: [],
    availableLocations: [],
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, ManagePackagesTable);