/*
 * global component that renders PDFs and Images (JPEG and PNG)
 *
 * Input Props:
 *  data: the Bytes of the file to be rendered
 *  scale: Zoom Scale when viewing
 *
 * Sample Usage can be found in TransactionsAttachmentPreview.jsx
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import PDFViewer from "../../PDFViewer";

class FileViewer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fileType: null,
        }

        this.reader = new FileReader()
        this.reader.onload = this.handleFileRead
    }

    componentDidMount() {
        const { data } = this.props;
        const dataArray = new Uint8Array(data);
        this.reader.readAsArrayBuffer(new Blob([dataArray]));
    }

    componentWillUnmount() {
        this.reader.abort()
    }

    handleFileRead = (event) => {
        const { result } = event.target
        const firstTwoBytes = new Uint8Array(result).subarray(0, 2)
        let fileType = null
        if (firstTwoBytes[0] === 0xff && firstTwoBytes[1] === 0xd8) {
            fileType = 'image/jpeg'
        } else if (firstTwoBytes[0] === 0x89 && firstTwoBytes[1] === 0x50) {
            fileType = 'image/png'
        } else if (firstTwoBytes[0] === 0x25 && firstTwoBytes[1] === 0x50) {
            fileType = 'application/pdf'
        }
        this.setState({ fileType })
    }


    render() {
        const { fileType } = this.state
        switch (fileType) {
            case 'application/pdf':
                return <PDFViewer data={this.props.data} scale={this.props.scale}/>
            case 'image/jpeg':
            case 'image/png':
                const dataUrl = `data:${fileType};base64,${btoa(String.fromCharCode(...new Uint8Array(this.props.data)))}`
                return (
                    <div style={{marginTop: '10%', display: 'flex', justifyContent: 'center'}}>
                        <img src={dataUrl} alt="image" style={{ border: '1px solid grey', transform: `scale(${this.props.scale})`}}/>
                    </div>
                )
            default:
                return <p>Cannot view this File. Unsupported File Type. </p>
        }
    }
}

FileViewer.propTypes = {
    data: PropTypes.instanceOf(Uint8Array),
    scale: PropTypes.number,
}

FileViewer.defaultProps = {
    data: [],
    scale: 1.0,
}

export default FileViewer
