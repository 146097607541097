export const LOCATIONS_TOOLBAR_TITLE = "Locations";
export const CREATE_LOCATION_TITLE = "Add a Location";
export const SETTING_TITLE = "Settings";
export const BACK_TO_MENU_TITLE = "Back to Menu";
export const PRINTERS_TOOLBAR_TITLE = "Printers";
export const REFRESH_LOCATION_TITLE = "Refresh Printers";
export const PRINTER_BUTTON_TITLE = "Fetch available printers";
export const DELETE_EXISTING_LOGO_BUTTON_TITLE = "Delete existing logo";

export const DEFAULT_ROWS_PER_PAGE = 50;

export const MANAGE_PRINTERS_TABLE_HEAD_CELLS = [
    { id: 'name', center: true, disablePadding: false, label: 'Printer Name'},
    { id: 'hostname', center: false, disablePadding: false, label: 'Hostname' },
    { id: 'selected', center: false, disablePadding: false, label: 'Selected' },
]
export const ROWS_PER_PAGE_LABEL = "Printers per page"
export const DEFAULT_SORT_BY_HEAD_CELL = "name"

export const ERROR = "error";

export const NO_PRINTER_FOUND_ERROR = "No printers were found. Check to see if your client application is running."

export const NO_PRINTER_FOUND_WARNING = "No printers were found. Please fetch printers."
