import React from "react";
import {Button, TextField, Typography} from "@material-ui/core";
import FileUpload from "../../global/FileUpload";

//style
import styles from "../ManageCarriers/CreateCarrierDialog.module.css";
import "./ManagePrintersForm.css"
class ManagePrintersForm extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            label: this.getValue('label'),
            type: this.getValue('type'),
            saved: false,
            files: [],
            newFiles: [],
            deletedFiles: [],
        }
    }

    getValue = (key)=>{
        return ''
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    getByteArray = (file) => {
        let reader = new FileReader();
        let fileByteArray = [];
        reader.readAsArrayBuffer(file);
        reader.onloadend = function (evt) {
            if (evt.target.readyState === FileReader.DONE) {
                let arrayBuffer = evt.target.result,
                    uint8array = new Uint8Array(arrayBuffer);
                for (let i = 0; i < uint8array.length; i++) {
                    fileByteArray.push(uint8array[i]);
                }
            }
        }
    
        return fileByteArray
      }
    
    getFormattedGeneralFileObject = (file) =>{
        return {localURL: URL.createObjectURL(file), displayName: file.name, byteArray: this.getByteArray(file), type: file.type}
    
    }
    
    freeFromMemory = (url) => {
        URL.revokeObjectURL(url)
    }

    handleGeneralFileAdd = (file) => {
        //let prevNewFiles = this.state.newFiles
        //let prevFiles = this.state.files
        let newFile = this.getFormattedGeneralFileObject(file)

        let files=[]
        files.push(newFile)
        let newFiles=[]
        newFiles.push(newFile)

        this.setState({
            newFiles: newFiles,
            files: files
        });
    }

    handleGeneralFileDelete = (file, index) => {
        let files = [...this.state.files]
        let deletedFiles = [...this.state.deletedFiles]
        let newFiles = [...this.state.newFiles]

        let deletedFile = (files.splice(index, 1))[0]
        if (!("byteArray" in file)) {
            deletedFiles.push(deletedFile)
        } else {
            newFiles.splice(index, 1)
        }
        if ("localURL" in file) {
            this.freeFromMemory(file.localURL)
        }

        this.setState({
            files: files,
            deletedFiles: deletedFiles,
            newFiles: newFiles
        })
    }

    handleFileUploadCancel = () => {
        this.setState({
            files: [],
            deletedFiles: [],
            newFiles: []
        })

        this.props.handleClose()
    }

    handleFileUpload = () => {
        this.props.importOrders(this.state.files)
        this.setImportingPage(false)
        this.setState({
            files: [],
            deletedFiles: [],
            newFiles: []
        })
    }

    handlePreviewClose = () => {
        this.setState({
            showingPreviews: false
        })
    }

    handleDeleteExistingLogo = () => {
        this.freeFromMemory(this.props.logo)
        this.props.deleteLogo()
    }

    handleSave = () => {
        this.setState({saved: true})
        this.props.handleFileUpload(this.state.files[0] ? this.state.files[0] : null)
    }

    render() {
        return (

            <div className={styles.rowCarrier}>
                <div className={styles.logoinnerBox}>
                    <div>
                        <>
                            <Typography className="titleTypography" variant="h6" id="tableTitle">
                                {this.props.logo && !this.state.files[0] ?
                                "Current Logo" :
                                    this.state.files[0]?
                                        "New Logo":
                                        "No Logo Found"
                                }
                            </Typography>
                        </>
                    </div>
                    <div className={styles.logoUploadBox}>
                        <FileUpload
                            handleFileAdd={this.handleGeneralFileAdd}
                            handleFileDelete={this.handleGeneralFileDelete}
                            files={this.state.files ? this.state.files : []}
                            singleFileUpload={false}
                            isLogo={true}
                            existingLogo={this.props.logo ? this.props.logo : null}
                            deleteExistingLogo={this.handleDeleteExistingLogo}
                            handleFileUploadCancel={this.handleFileUploadCancel}
                            handleSave={this.handleSave}
                        />
                    </div>
                </div>
            </div>
        );
    }

}

export default ManagePrintersForm