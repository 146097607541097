import {
    DELETE_FULL_TRUCKLOADS_BROKER,
    FETCH_FULL_TRUCKLOADS_BROKERS,
    SAVE_FULL_TRUCKLOADS_BROKERS, STORE_FULL_TRUCKLOADS_BROKER,
    STORE_FULL_TRUCKLOADS_BROKERS
} from "../../constants/fullTruckloadBrokers";

export const fetchFullTruckloadBrokers = () => ({
    type: FETCH_FULL_TRUCKLOADS_BROKERS,
})

export const deleteFullTruckloadBroker = (data) => ({
    type: DELETE_FULL_TRUCKLOADS_BROKER,
    data
})

export const storeFullTruckloadBrokers = (fullTruckloadBrokers) => ({
    type: STORE_FULL_TRUCKLOADS_BROKERS,
    fullTruckloadBrokers,
})

export const storeFullTruckloadBroker = (fullTruckloadBroker) => ({
    type: STORE_FULL_TRUCKLOADS_BROKER,
    fullTruckloadBroker,
})

export const saveFullTruckloadBrokers = (data) => ({
    type: SAVE_FULL_TRUCKLOADS_BROKERS,
    data
})