export const UPDATE_TOTAL_SCANNED = 'UPDATE_TOTAL_SCANNED';
export const UPDATE_FEDEX_SCANNED = 'UPDATE_FEDEX_SCANNED';
export const UPDATE_CANPAR_SCANNED = 'UPDATE_CANPAR_SCANNED';
export const UPDATE_USPS_SCANNED = 'UPDATE_USPS_SCANNED';
export const UPDATE_TOTAL_PACKED = 'UPDATE_TOTAL_PACKED';
export const UPDATE_FEDEX_PACKED = 'UPDATE_FEDEX_PACKED';
export const UPDATE_CANPAR_PACKED = 'UPDATE_CANPAR_PACKED';
export const UPDATE_USPS_PACKED = 'UPDATE_USPS_PACKED';
export const UPDATE_TOTAL_PRINTED = 'UPDATE_TOTAL_PRINTED';
export const UPDATE_FEDEX_PRINTED = 'UPDATE_FEDEX_PRINTED';
export const UPDATE_CANPAR_PRINTED = 'UPDATE_CANPAR_PRINTED';
export const UPDATE_USPS_PRINTED = 'UPDATE_USPS_PRINTED';
export const UPDATE_RAWSCANS = 'UPDATE_RAWSCANS';
export const DELETE_RAWSCAN = 'DELETE_RAWSCAN';
export const UPDATE_RAWPACKED = 'UPDATE_RAWPACKED';
export const DELETE_RAWPACKED = 'DELETE_RAWPACKED';

