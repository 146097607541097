import {
    REQUEST_STATUS,
    SET_LOADING_STATUS,
    SET_PRICING_LOADING_STATUS,
    SET_OPEN,
    CLEAR_STATUS,
    SET_SAVING_STATUS,
    SET_SAVING_OPEN,
    SET_CREATE_ORDER_OPEN,
    SET_VOID_OPEN,
    SET_VOID_STATUS,
    HIDE_MENU_ITEMS,
    SHOW_MENU_ITEMS,
    SET_CREATE_RETURN_OPEN,
    SET_CREATE_CLONED_OPEN,
    SHOW_ERROR_MESSAGE,
    HIDE_ERROR_MESSAGE,
    SET_ARCHIVE_STATUS, SET_ARCHIVE_OPEN, STORE_STATE_AND_PROVINCE_MAPPING,
} from "../../constants/global";

const initialState = {
    status: {},
    loading: false,
    pricingDashboardLoading: false,
    open: false,
    saving: false,
    savingOpen: false,
    createOrderOpen: false,
    voidOpen: false,
    voidStatus: {},
    archiveOpen: false,
    archiveStatus: {},
    showingMenuItems: true,
    showError: false,
    errorMessage: ''
}

export const updateList = (newItem, oldList, key) => {
    let newList = [];
    let exists = oldList.findIndex(item => item[key] === newItem[key]);
    if (exists === -1) {
        return oldList.push(newItem);
    } else {

        oldList.map((item, index) => {
            if (index === exists) {
                newList[index] = newItem;
            } else {
                newList[index] = item;
            }
        })
        return newList;
    }
}

export const deleteItem = (itemToDeleteId, oldList, key) => {
    return oldList.filter(item => item[key] !== itemToDeleteId)
}

export default (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_STATUS:
            return {
                ...state,
                status: action.status
            }
        case SET_LOADING_STATUS:
            return {
                ...state,
                loading: action.loading
            }
        case SET_PRICING_LOADING_STATUS:
            return {
                ...state,
                pricingDashboardLoading: action.pricingDashboardLoading
            }
        case SET_OPEN:
            return {
                ...state,
                open: action.open
            }
        case CLEAR_STATUS:
            return {
                ...state,
                status: {}
            }
        case SET_SAVING_STATUS:
            return {
                ...state,
                saving: action.saving
            }
        case SET_SAVING_OPEN:
            return {
                ...state,
                savingOpen: action.savingOpen
            }
        case SET_CREATE_ORDER_OPEN:
            return {
                ...state,
                createOrderOpen: action.createOrderOpen
            }
        case SET_CREATE_RETURN_OPEN:
            return {
                ...state,
                createReturnOpen: action.createReturnOpen
            }
        case SET_CREATE_CLONED_OPEN:
            return {
                ...state,
                createClonedOpen: action.createClonedOpen
            }
        case SET_VOID_OPEN:
            return {
                ...state,
                voidOpen: action.voidOpen
            }
        case SET_VOID_STATUS:
            return {
                ...state,
                voidStatus: action.voidStatus
            }
        case SET_ARCHIVE_OPEN:
            return {
                ...state,
                archiveOpen: action.archiveOpen
            }
        case SET_ARCHIVE_STATUS:
            return {
                ...state,
                archiveStatus: action.archiveStatus
            }
        case HIDE_MENU_ITEMS:
            return {
                ...state,
                showingMenuItems: false
            }
        case SHOW_MENU_ITEMS:
            return {
                ...state,
                showingMenuItems: true
            }
        case SHOW_ERROR_MESSAGE:
            return {
                ...state,
                showError: true,
                errorMessage: action.message
            }
        case HIDE_ERROR_MESSAGE:
            return {
                ...state,
                showError: false,
                errorMessage: ''
            }
        case STORE_STATE_AND_PROVINCE_MAPPING:
            return {
                ...state,
                stateAndProvinceMapping: action.mapping
            }
        default:
            return state
    }
}