import React from 'react';

// material components
import { Document, Page, pdfjs } from 'react-pdf';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import IconButton from "@material-ui/core/IconButton";
import './PDFViewer.css';
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import {
    TextField,
} from '@material-ui/core';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class PDFViewer extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            numPages: null,
            pageNumber: 1,
            attemptFailed: false
        }
        this.hider = React.createRef()
    }

    onDocumentLoadSuccess = ({numPages}) => {

        if (this.hider?.current?.className)
            this.hider.current.className = ''

        this.setState({
            numPages: numPages
        })
    }

    onDocumentLoadError = () => {
        this.setState({
            attemptFailed: true
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.attemptFailed) {
            this.setState({
                attemptFailed: false
            })
        }
    }
    render() {
        return (
            <>
                <div ref={this.hider} className={'hider'}>
                    {this.state.numPages && !this.props.invoiceTemplateEdit && <div className="page-navigation">
                        <IconButton disabled={this.state.pageNumber===1} onClick={()=>{
                            this.setState({pageNumber: this.state.pageNumber -1})
                            if(this.props.decreasePageCount) {
                                this.props.decreasePageCount()
                            }
                        }}>
                            <ArrowBackIosIcon/>
                        </IconButton>
                        <TextField
                            style={{width: '50px'}}
                            inputProps={{min: 0, style: { textAlign: 'center' }}}
                            value={this.state.pageNumber}
                            required={true}
                            error={!this.state.pageNumber}
                            onChange={(e) => {
                                let pageNum = parseInt(e.target.value)
                                if(isNaN(pageNum) && e.target.value !== ""){
                                    return
                                }
                                if(e.target.value > this.state.numPages || pageNum < 1){
                                    return
                                }

                                if (e.target.value.length == 0 || isNaN(pageNum)) {
                                    this.setState({pageNumber: e.target.value})
                                } else {
                                    if (this.props.changePageNumber)
                                        this.props.changePageNumber(pageNum);
                                    this.setState({pageNumber: pageNum});
                                }
                            }}
                        />
                        <IconButton disabled={this.state.pageNumber===this.state.numPages} onClick={()=>{
                            this.setState({pageNumber: this.state.pageNumber +1});
                            if(this.props.increasePageCount) {
                                this.props.increasePageCount()
                            }
                        }}>
                            <ArrowForwardIosIcon/>
                        </IconButton>
                        <p>Page {this.state.pageNumber} of {this.state.numPages}</p>
                    </div>}
                    {this.state.attemptFailed ? null :
                    <Document
                        file={{data: this.props.data}}
                        onLoadSuccess={this.onDocumentLoadSuccess}
                        onLoadError={this.onDocumentLoadError}
                        className={" react-pdf__Document"}
                    >
                        <Box
                            border={1} borderColor="grey.500"
                            //onMouseMove={this.props.resizeSelectBox}
                            //onMouseDown={this.props.createSelectBox}
                            className={this.props.selecting ? " selecting" : ""}
                        >
                            <Page pageNumber={this.state.pageNumber} scale={this.props.scale} />
                        </Box>
                    </Document>
                    }
                    {this.state.numPages && !this.props.invoiceTemplateEdit && <div className="page-navigation">
                        <IconButton disabled={this.state.pageNumber===1} onClick={()=>{
                            this.setState({pageNumber: this.state.pageNumber -1})
                            if(this.props.decreasePageCount) {
                                this.props.decreasePageCount()
                            }
                        }}>
                            <ArrowBackIosIcon/>
                        </IconButton>
                        <TextField
                            style={{width: '50px'}}
                            inputProps={{min: 0, style: { textAlign: 'center' }}}
                            value={this.state.pageNumber}
                            required={true}
                            error={!this.state.pageNumber}
                            onChange={(e) => {
                                let pageNum = parseInt(e.target.value)
                                if(isNaN(pageNum) && e.target.value !== ""){
                                    return
                                }
                                if(e.target.value > this.state.numPages || pageNum < 1){
                                    return
                                }
                                if (e.target.value.length == 0 || isNaN(pageNum)) {
                                    this.setState({pageNumber: e.target.value})
                                } else {
                                    if (this.props.changePageNumber)
                                        this.props.changePageNumber(pageNum);
                                    this.setState({pageNumber: pageNum});
                                }
                            }}
                        />
                        <IconButton disabled={this.state.pageNumber===this.state.numPages} onClick={()=>{
                            this.setState({pageNumber: this.state.pageNumber +1});
                            if(this.props.increasePageCount) {
                                this.props.increasePageCount()
                            }
                        }}>
                            <ArrowForwardIosIcon/>
                        </IconButton>
                        <p>Page {this.state.pageNumber} of {this.state.numPages}</p>
                    </div>}
                </div>
            </>
        );
    }
}


PDFViewer.propTypes = {
    data: PropTypes.object
}

PDFViewer.defaultProps = {
    data: {}
}

export default PDFViewer;