import React from 'react';

import generatePassword from "password-generator";
import copy from "copy-to-clipboard";

// styling
import './RegisterNewUser.css';

// material components
import {
    Button,
    TextField,
    FormLabel,
    FormGroup,
    Checkbox,
    Radio,
    FormHelperText,
    Divider
} from "@material-ui/core";

// constants
import {
    SELECT_SERVICES,
    SELECT_LOCATIONS,
    DEFAULT,
    CANCEL,
    REGISTER_DIALOG_DESCRIPTION,
    REGISTER_DIALOG_TITLE,
    SAVE_ACTION,
    ROLE_DEPTH_AND_ORDER,
    PARENT_ROLE_MAP,
    CHILD_ROLE_MAP,
    ROLE_DASHBOARD,
    ROLE_ADMIN,
    PASSWORD_ACTION,
    TIME_ZONE,
} from "./constants";
import PropTypes from "prop-types";
import ManageUsersTableHead from "./ManageUsersTableHead";
import { Autocomplete } from '@material-ui/lab';

class RegisterNewUser extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            firstname: '',
            lastname: '',
            authorities: [ROLE_DASHBOARD],
            company: '',
            password: '',
            timezone: '',
            locationIds: [],
            defaultLocationId: ''
        }
    }

    handleChange = (value, name) => {
        this.setState({
            [name]: value
        })
    }

    createPassword = () => {
        const newPassword = generatePassword(16, false, /[\d\W\w\p]/g).toString()
        this.setState({
            password: newPassword,
            copied: true
        })
        copy(newPassword)
    }

    selectAuthority = (role) => {
        if(role == ROLE_ADMIN){
            if(this.state.authorities.includes(role)){
                this.setState({
                    authorities: [ROLE_DASHBOARD]
                })
            }else{
                this.setState({
                    authorities: [ROLE_ADMIN]
                })
            }
            return
        }

        let newAuthority = new Set(this.state.authorities)
        const updateChild = (isDelete, role) => {
            if(isDelete){
                if(PARENT_ROLE_MAP[role]){
                    PARENT_ROLE_MAP[role].forEach(child => {
                        newAuthority.delete(child)
                    })
                }
                if(CHILD_ROLE_MAP[role]){
                    CHILD_ROLE_MAP[role].forEach(parent => {
                        let hasChild = false
                        PARENT_ROLE_MAP[parent].forEach(child => {
                            if(newAuthority.has(child)){
                                hasChild = true
                            }
                        })
                        if(!hasChild){
                            newAuthority.delete(parent)
                        }
                    })
                }
            }else{
                if(PARENT_ROLE_MAP[role]){
                    PARENT_ROLE_MAP[role].forEach(child => {
                        newAuthority.add(child)
                    })
                }
                if(CHILD_ROLE_MAP[role]){
                    CHILD_ROLE_MAP[role].forEach(parent => {
                        newAuthority.add(parent)
                    })
                }
            }
        }
        if(!this.state.authorities.includes(role)){
            this.props.allRoles.forEach(roleInAllRole => {
                if(role == roleInAllRole){
                    newAuthority.add(roleInAllRole)
                    updateChild(false, roleInAllRole)
                }
                if(this.state.authorities.includes(roleInAllRole)){
                    newAuthority.add(roleInAllRole)
                }
            });
        }else{
            newAuthority.delete(role)
            updateChild(true, role)
        }
        
        this.setState({
            authorities: Array.from(newAuthority)
        })
    }

    selectLocation = (value) => {
        let newLocationIds = [...this.state.locationIds]
        const index = newLocationIds.findIndex(newLocationId => {return newLocationId == value.shipAddressId})
        if (index > -1) {
            newLocationIds.splice(index, 1);
        }else{
            newLocationIds.push(value.shipAddressId)
        }
        this.setState({
            locationIds: newLocationIds
        })
    }

    setDefaultLocation = (value) => {
        let newLocationIds = [...this.state.locationIds]
        const index = newLocationIds.findIndex(newLocationId => {return newLocationId == value.shipAddressId})
        if (index > -1) {
            this.setState({
                defaultLocationId: value.shipAddressId
            })
        }else{
            newLocationIds.push(value.shipAddressId)
            this.setState({
                locationIds: newLocationIds,
                defaultLocationId: value.shipAddressId
            })
        }
    }

    getChecked = (role) => {
        return this.state.authorities.includes(role)
    }

    getDefaultLocationChecked = (location) => {
        return location.shipAddressId == this.state.defaultLocationId
    }

    getLabel = (label) => {
        let words = label.split('_')
        words.splice(0, 1)
        words = words.map(word => {
            return word[0].toUpperCase() + word.slice(1).toLowerCase()
        })
        return words.join(' ')
    }

    getSpace = (role) => {
        let spaces = []
        for (let index = 0; index < ROLE_DEPTH_AND_ORDER[role]; index++) {
            spaces.push("space")
        }
        return spaces.map(space => {
            return <div className="authority-space"/>
        })
    }

    registerUser = () => {
        copy(this.state.password)
        const data = {
            admin: this.props.admin.username,
            is_linked_user: true,
            username: this.state.username,
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            displayName: this.state.firstname + " " + this.state.lastname,
            authorities: this.state.authorities,
            company: this.state.company,
            password: this.state.password,
            timezone: this.state.timezone,
            locationIds: this.state.locationIds,
            defaultLocationId: this.state.defaultLocationId
        }
        this.props.register(data)
        this.props.handleClose()
    }

    render() {
        return (
            <div className="row-register-new-user">
                <div className="rowSpec">
                    <TextField
                        className="register-new-user-Input"
                        variant="outlined"
                        label="Email Address"
                        InputLabelProps={{ shrink: true }}
                        name="username"
                        value={this.state.username}
                        onChange={(e)=>this.handleChange(e.target.value, e.target.name)}
                    />
                </div>
                <div className="rowSpec">
                    <TextField
                        className="register-new-user-TwoInput"
                        variant="outlined"
                        label="First Name"
                        InputLabelProps={{ shrink: true }}
                        name="firstname"
                        value={this.state.firstname}
                        onChange={(e)=>this.handleChange(e.target.value, e.target.name)}
                    />
                    <TextField
                        className="register-new-user-TwoInput"
                        variant="outlined"
                        label="Last Name"
                        InputLabelProps={{ shrink: true }}
                        name="lastname"
                        value={this.state.lastname}
                        onChange={(e)=>this.handleChange(e.target.value, e.target.name)}
                    />
                </div>
                <div className="rowSpec">
                    <TextField
                        disabled
                        className="register-new-user-TwoInput"
                        variant="outlined"
                        label="Display Name"
                        InputLabelProps={{ shrink: true }}
                        name="displayName"
                        value={`${this.state.firstname} ${this.state.lastname}`}
                    />
                    <Autocomplete
                        options={TIME_ZONE}
                        getOptionLabel={(entry)=>entry}
                        autoHighlight={true}
                        className="register-new-user-TwoInput"
                        value={this.state.timezone}
                        onChange={(event, value)=>{this.handleChange(value, "timezone")}}
                        name="timezone"

                        blurOnSelect={true}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label="Time zone"
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                name="timezone"
                            />
                        }
                    />
                </div>
                <div className="rowSpec">
                    <TextField
                        className="register-new-user-TwoInput"
                        variant="outlined"
                        label="Password"
                        InputLabelProps={{ shrink: true }}
                        name="password"
                        type='password'
                        value={this.state.password}
                        onChange={(e)=>this.handleChange(e.target.value, e.target.name)}
                        autoComplete='new-password'
                    />
                    <Button
                        className="register-new-user-TwoInput"
                        value={this.state.password}
                        color='secondary'
                        variant='contained'
                        onClick={() => {this.createPassword()}}
                    >
                        {PASSWORD_ACTION}
                    </Button>
                </div>
                <div className='register-new-user-role-box'>
                    <div className="rowSpec">
                        <FormLabel  className="authority-box" component="legend">{SELECT_LOCATIONS}</FormLabel>
                        <FormLabel  className="authority-box" component="legend">{DEFAULT}</FormLabel>
                    </div>
                    <FormGroup>
                        {this.props.locations.map((location, i)=>{
                            return (
                                <div style={{display: 'flex'}}>
                                    <div className="authority-box">
                                        <Checkbox
                                            checked={this.state.locationIds.includes(location.shipAddressId)} 
                                            onChange={()=>this.selectLocation(location)} 
                                            name="authority" 
                                            color='primary'
                                        />
                                        <div>
                                            {location.name}
                                        </div>
                                    </div>
                                    <Radio
                                    checked={this.getDefaultLocationChecked(location)} 
                                    onChange={()=>this.setDefaultLocation(location)} 
                                    name="authority" 
                                    color='primary'
                                    />
                                </div>
                            )
                        })}
                    </FormGroup>
                </div>
                <Divider className='divider' />
                <div className='register-new-user-role-box'>
                    <FormLabel component="legend">{SELECT_SERVICES}</FormLabel>
                    <FormGroup>
                        {this.props.allRoles.map((role, i)=>{
                            return (
                                <div className="authority-box">
                                    {this.getSpace(role)}
                                    <Checkbox 
                                        disabled={role==ROLE_DASHBOARD || this.state.authorities.includes(ROLE_ADMIN)}
                                        checked={this.getChecked(role)} 
                                        onChange={()=>this.selectAuthority(role)} 
                                        name="authority" 
                                        color='primary'
                                    />
                                    <div className={role==ROLE_DASHBOARD || this.state.authorities.includes(ROLE_ADMIN) ? "authority-text-disabled":""}>
                                        {this.getLabel(role)}
                                    </div>
                                </div>
                            )
                        })}
                        <div className="authority-box">
                            {this.getSpace(ROLE_ADMIN)}
                            <Checkbox 
                                checked={this.getChecked(ROLE_ADMIN)} 
                                onChange={()=>this.selectAuthority(ROLE_ADMIN)} 
                                name="authority" 
                                color='primary'
                            />
                            <div className="authority-text">
                                {this.getLabel(ROLE_ADMIN)}
                            </div>
                        </div>
                    </FormGroup>
                </div>
                <div>
                    <Button variant="outlined" onClick={this.props.handleClose} className="register-new-user_btn">
                        {CANCEL}
                    </Button>
                    <Button 
                        disabled={!(this.state.password?.length > 0)}
                        variant="contained"
                        color="primary"
                        onClick={this.registerUser}
                        className="register-new-user_btn"
                    >
                        {SAVE_ACTION}
                    </Button>
                </div>
            </div>
        );
    }

}

RegisterNewUser.propTypes = {
    admin: PropTypes.object,
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    register: PropTypes.func
}

RegisterNewUser.defaultProps = {
    admin: {},
    open: false,
    handleClose: ()=>{},
    register: ()=>{}
}

export default RegisterNewUser;