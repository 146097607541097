import React from 'react';

// import styling
import {
    Dialog,
    DialogContent,
    DialogActions,
    Checkbox,
    Table,
    TableCell,
    TableBody,
    TableRow,
    Button,
    Paper,
    TableContainer,
    Typography,
} from "@material-ui/core";
import GlobalTableHead from "../global/Table/GlobalTableHead";
import '../global/SecondaryToolbar.css';
import '../automationrules/ConditionFilters/ConditionFiltersTable.css';
import './AutomationRuleDialog.css';

// import constants
import {
    RULE_DIALOG_TITLE,
    RULE_DIALOG_SUBTITLE,
    RUN_RULES,
    CANCEL_RUN_RULES,
    RULE_DIALOG_HEAD_CELLS,
    RULE_DIALOG_ORDER_BY,
} from "./constants";
import { ASC, DESC } from "../global/constants";
import SearchField from "../global/Search/SearchField";
import SearchableTable from "../global/Search/SearchableTable";
import AddressValidationPopup from "./AddressValidationPopup";
import PropTypes from "prop-types";

class AutomationRuleDialog extends SearchableTable {

    constructor(props) {
        super(props);
        this.state = {
            order: ASC,
            orderBy: RULE_DIALOG_ORDER_BY,
            isSearch: false, // true if search value is not empty, false otherwise
            searchValue: "", // the value in the search field (updates on change)
            searchRows: 0, // number of rows displayed while search criteria is met
        }
    }

    handleRequestSort = (event, property) => {
        const isAsc = this.state.orderBy === property && this.state.order === ASC;
        this.setState({
            order: isAsc ? DESC : ASC,
            orderBy: property,
        });
    };

    onSearchValueChange = (event) => {
        this.setState({
            isSearch: event.target.value.length > 0,
            searchValue: event.target.value,
        });
    };

    handleClose = () => {
        this.setState({
            isSearch: false,
            searchValue: '',
        })
        this.props.handleClose()
    }

    handleRunRules = () => {
        this.setState({
            isSearch: false,
            searchValue: '',
        })
        this.props.runRules()
    }

    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.handleClose}
                scroll={'paper'}
            >
                <div style={{width: "540px"}}>
                    <DialogContent dividers={true}>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <Typography className="titleTypography" variant="h6" id="tableTitle">
                                            {RULE_DIALOG_TITLE}
                                        </Typography>
                                        {RULE_DIALOG_SUBTITLE}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <div className="root">
                            <Paper className="paper">
                                <TableContainer>
                                    <Table>
                                        <TableBody>
                                            <TableRow className='secondaryToolbar'>
                                                <TableCell>
                                                    <SearchField onChange={this.onSearchValueChange} autoFocus={true} searchText={this.state.searchValue}/>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TableContainer className='conditionFiltersContainer'  style={{minHeight: '475px'}}>
                                    <Table
                                        aria-labelledby='tableTitle'
                                        size='medium'
                                        aria-label={RULE_DIALOG_TITLE}
                                    >
                                        <GlobalTableHead
                                            isShipmentTable={false}
                                            headCells={RULE_DIALOG_HEAD_CELLS}
                                            numSelected={this.props.selectedRules.length}
                                            order={this.state.order}
                                            orderBy={this.state.orderBy}
                                            onSelectAllClick={this.props.handleSelectAll}
                                            onRequestSort={this.handleRequestSort}
                                            rowCount={this.props.rules.length}
                                        />
                                        <TableBody>
                                            {this.stableSort(
                                                this.filterBySearchWithSpecificKeys(this.props.rules, ["name", "group", 'ruleCategory'])
                                                    .filter(rule => this.props.ruleGroup === rule.ruleCategory),
                                                this.getComparator(this.state.order, this.state.orderBy)
                                            ).map((rule, index) => {
                                                const isSelected = this.props.isSelected(rule.name)
                                                const itemKey = `rule-dialog-rule-${index}`
                                                return (
                                                    <TableRow
                                                        hover
                                                        role='checkbox'
                                                        aria-checked={isSelected}
                                                        tabIndex={-1}
                                                        key={rule.name}
                                                        selected={isSelected}
                                                    >
                                                        <TableCell>
                                                            <Checkbox
                                                                checked={isSelected}
                                                                onChange={() => this.props.handleSelect(rule.name)}
                                                                value={rule.name}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            align='left'
                                                            id={itemKey}
                                                            scope='row'
                                                            padding='none'
                                                        >
                                                            {rule.name}
                                                        </TableCell>
                                                        <TableCell
                                                            align='left'
                                                            scope='row'
                                                            padding='none'
                                                        >
                                                            {rule.group}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} variant='contained'>
                            {CANCEL_RUN_RULES}
                        </Button>
                        {/* Disable if no rules selected */}
                        <Button disabled={this.props.selectedRules.length === 0} onClick={this.handleRunRules} color="primary" variant="contained">
                            {RUN_RULES}
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        );
    }

}

AutomationRuleDialog.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    rules: PropTypes.array,
    orders: PropTypes.array,
    runRules: PropTypes.func,
    selectedRules: PropTypes.array,
    allSelected: PropTypes.bool,
    isSelected: PropTypes.func,
    handleSelect: PropTypes.func,
    handleSelectAll: PropTypes.func
}

AutomationRuleDialog.defaultProps = {
    open: false,
    handleClose: ()=>{},
    rules: [],
    orders: [],
    runRules: ()=>{},
    selectedRules: [],
    allSelected: false,
    isSelected: ()=>{},
    handleSelect: ()=>{},
    handleSelectAll: ()=>{}
}

export default AutomationRuleDialog;
