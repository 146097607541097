export const FETCH_RULES = 'FETCH_RULES';
export const STORE_RULES = 'STORE_RULES';
export const CREATE_RULE = 'CREATE_RULE';
export const STORE_NEW_RULE = 'STORE_NEW_RULE';
export const EDIT_RULE = 'EDIT_RULE';
export const STORE_RULE_TO_EDIT = 'STORE_RULE_TO_EDIT';
export const CLEAR_STORED_RULE = 'CLEAR_STORED_RULE';
export const DUPLICATE_RULE = 'DUPLICATE_RULE';
export const DELETE_RULE = 'DELETE_RULE';
export const RUN_RULES = 'RUN_RULES';
export const STORE_APPLIED_ORDERS = 'STORE_APPLIED_ORDERS';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const GET_COMPANY = 'GET_COMPANY';