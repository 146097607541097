export const DEFAULT_ROWS_PER_PAGE = 50;

export const PRODUCT_CATEGORY_TOOLBAR_TITLE = "Product Category"
export const BACK_TO_MENU_TITLE = "Back to Menu";
export const MANAGE_PRODUCT_CATEGORY = "Manage Product Category";

export const DEFAULT_SORT_BY_HEAD_CELL = "category"
export const PRODUCT_CATEGORY_TABLE_HEAD_CELLS = [
    { id: 'category', center: true, disablePadding: false, label: 'Category'},
    { id: 'hsCode', center: true, disablePadding: false, label: 'HS Code'},
    {id: 'filler2', center: false, disablePadding: true, label: ''}
]

export const EDIT_PRODUCT_CATEGORY_TABLE_HEAD_CELLS = [
    {id: 'buttonFiller', center: false, disablePadding: true, label: ''},
    { id: 'category', center: true, disablePadding: false, label: 'Category'},
    { id: 'hsCode', center: true, disablePadding: false, label: 'HS Code'},
    {id: 'filler2', center: false, disablePadding: false, label: ''},
]

export const REMOVE_PRODUCT_CATEGORY = "Remove Category"

export const CANCEL = "cancel";
export const SAVE = "save";

export const ADD_PRODUCT_CATEGORY = "Add Product Category"
