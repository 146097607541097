import React from "react";
import withShipment from "../../../withShipment";
import CustomToolbar from "../../global/CustomToolbar";
import {
    BACK_TO_MENU,
} from "../ManageSuppliers/constants";
import {
    HISTORICAL_DATA_HEAD_CELLS,
    HISTORICAL_DATA_IMPORT_OPTIONS, IMPORT, IMPORT_REVERSE, IMPORT_SHIPRUSH_DATA_TABLE, LIST,
    Process_Historical_TITLE, SELECT_ALL, SHIPRUSH, SHIPRUSH_TOOLBAR_TITLE, SUBMIT
} from "./constants";
import {
    Button, IconButton,
    MenuItem,
    Select, Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import './ImportShiprushData.css'
import {CANCEL, SAVE} from "../../global/constants";
import {
    clearHistoricalData,
    clearShiprushSpreadsheetsInfo,
    listSheets,
    importHistoricalData
} from "../../../redux/actions/settings";
import {historicalDataSelector, productsSelector, shiprushFileNamesSelectors} from "../../../redux/selectors/settings";
import GlobalTableHead from "../../global/Table/GlobalTableHead";
import {Box, Typography} from "@mui/material";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import {EDIT, FULL_TRUCKLOAD_BROKERS_TABLE} from "../../fullTruckload/constants";
import EditIcon from "@material-ui/icons/Edit";
import {DELETE} from "../../accounting/transactions/constants";
import DeleteIcon from "@material-ui/icons/Delete";
import TablePage from "../../global/Table/TablePage";
import {Layout} from "../../global/Table/TablePageHelpers/LayoutObject";
import Checkbox from "@material-ui/core/Checkbox";

class ImportShiprushData extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            onImportPage : false,
            source : "",
            processQueue:[],
            processing: false,
            checkedEntries:[]
        }

    }

    componentDidMount() {
        this.props.clearHistoricalData()
    }

    parseLines = (value) => value.replace(/(\\n)/g, "\n");

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if(prevProps.historicalData !== this.props.historicalData && this.props.historicalData!="") {
            this.setState({
                processing: false
            })
        }

    }

    handleSubmit = async (importReverse = false) => {
        const curImport = {
            source: this.state.checkedEntries.filter(entry=> entry.import == true)
        }
        if (importReverse) {
            curImport.source.map(file => file.importReverse = true)
        }
        this.props.importHistoricalData(curImport.source,SHIPRUSH)
        this.setState({
            source: "",
            processQueue: [...this.state.processQueue, curImport],
            onImportPage: false,
            processing:true
        })
    }


    styleLineContent = (line) => {
        let styledLine;

        if (line.includes("high")) {
            styledLine = line.replace("high", "").replace(/(.*?):/, "<strong>$1</strong>:").replace("imported", "<span style='color: red;'>imported");
        } else if (line.includes("medium")) {
            styledLine = line.replace("medium", "").replace(/(.*?):/, "<strong>$1</strong>:").replace("imported", "<span style='color: orange;'>imported");
        } else if (line.includes("low")) {
            styledLine = line.replace("low", "").replace(/(.*?):/, "<strong>$1</strong>:").replace("imported", "<span style='color: green;'>imported");
        } else {
            styledLine = line;
        }

        // Add closing span tag
        if (styledLine.includes("imported")) {
            styledLine += "</span>";
        }

        return styledLine;
    }

    selectAllFiles = () => {
        let checkedEntries = [];
        this.props.shiprushFileNames.map(file =>{
            let newEntry = {
                ...file,
                'import':true,
                'skipExistingEntry':true
            }
            checkedEntries.push(newEntry)
        })
        this.setState({ checkedEntries });
    }

    handleCheckboxChange(e, file, attribute) {
        let checkedEntries = [...this.state.checkedEntries];
        let entryIndex = checkedEntries.findIndex(entry => entry.id === file.id);
        let newEntry;

        if (entryIndex !== -1) {
            // Entry already exists in the list
            newEntry = { ...checkedEntries[entryIndex], [attribute]: e.target.checked };
            checkedEntries[entryIndex] = newEntry;
        } else {
            // Entry not in the list, add new entry
            newEntry = { ...file, [attribute]: e.target.checked };
            checkedEntries.push(newEntry);
        }

        // Additional logic for automatically toggling 'skipExistingEntry'
        if (attribute === 'import' && e.target.checked && !newEntry.skipExistingEntry) {
            newEntry.skipExistingEntry = true;
            if (entryIndex !== -1) {
                checkedEntries[entryIndex] = newEntry;
            }
        }

        this.setState({ checkedEntries });
    }

    getTableHeaders = () => {
        let headers = []
        headers.push(Layout.columnHead(null,null,null))
        IMPORT_SHIPRUSH_DATA_TABLE.forEach((headCell) => {
            headers.push(Layout.columnHead(headCell.text, headCell.id, "center"))
        })
        headers.push(Layout.columnHead(null,null,null))
        headers.push(Layout.columnHead(null,null,null))
        return headers
    }

    render() {
        const lines = this.props.historicalData.split('*');
        return (
            <>
                <CustomToolbar
                    creatingOrder={this.state.onImportPage}
                    title={SHIPRUSH_TOOLBAR_TITLE}
                    createTitle={Process_Historical_TITLE}
                    backTitle={BACK_TO_MENU}
                    handleClose={() => {
                        this.setState({
                            onImportPage: false,
                            source : "",
                            checkedEntries:[]
                        })
                        this.props.clearShiprushSpreadsheetsInfo()
                    }}
                    onClick={() => {
                        this.setState({
                            onImportPage: true,
                            source : "",
                            checkedEntries:[]
                        })
                        this.props.clearShiprushSpreadsheetsInfo()
                    }}
                />

                {this.state.onImportPage &&
                    <>
                        <div style={{
                            display: "flex",
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '70%',
                            margin: '0 auto'
                        }}>

                            <TextField
                                value={this.state.source}
                                className={"input-select-field fullWidth"}
                                onChange={(event) => this.setState({ source: event.target.value })}
                                name="folder id or path"
                                variant="outlined"
                                label="Folder Path or Id"
                                InputLabelProps={{ shrink: true }}
                            />

                            <div style={{display: "flex", flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: '25px'}}>
                                <Button
                                    style={{ width: '150px' }}
                                    variant='contained'
                                    onClick={() => this.setState({onImportPage:false})}
                                >
                                    {CANCEL}
                                </Button>
                                <div className='save-spacing' />
                                <Button
                                    style={{ width: '150px' }}
                                    variant='contained'
                                    color='primary'
                                    onClick={() => this.props.listSheets(this.state.source)}
                                    disabled={this.state.source == ""}
                                >
                                    {LIST}
                                </Button>
                                <div className='save-spacing' />
                                <Button
                                    style={{ width: '150px' }}
                                    variant='contained'
                                    color='primary'
                                    onClick={() => this.selectAllFiles()}
                                    disabled={!this.props.shiprushFileNames || this.props.shiprushFileNames.length === 0}
                                >
                                    {SELECT_ALL}
                                </Button>
                            </div>

                            <TablePage
                                tableName="shiprushTable"
                                tableRows={this.props.shiprushFileNames}
                                tableColumnHeads={this.getTableHeaders()}
                                tableRow={(row, index) => <React.Fragment>
                                    <TableCell style={{ padding: "0.4rem" }}>
                                        <Checkbox
                                            checked={this.state.checkedEntries.some(entry => entry.id === row.id && entry.import)}
                                            onChange={(e) => {
                                                    this.handleCheckboxChange(e, row, 'import')
                                                }
                                            }
                                        />
                                    </TableCell>
                                    <TableCell style={{textAlign: "center"}}>
                                        {row.name}
                                    </TableCell>
                                    <TableCell style={{textAlign: "center"}}>
                                        <Switch
                                            color='primary'
                                            checked={this.state.checkedEntries.some(entry => entry.id === row.id && entry.reImport)}
                                            onChange={(e) => this.handleCheckboxChange(e, row, 'reImport')}
                                        />
                                    </TableCell>

                                    <TableCell style={{textAlign: "center"}}>
                                        <Switch
                                            color='primary'
                                            checked={this.state.checkedEntries.some(entry => entry.id === row.id && entry.skipExistingEntry)}
                                            onChange={(e) => this.handleCheckboxChange(e, row, 'skipExistingEntry')}
                                        />
                                    </TableCell>
                                </React.Fragment>}
                            />

                            <div style={{display: "flex", flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: '25px', width: '400px'}}>
                                <Button
                                    style={{ width: '180px', marginRight: '10px' }}
                                    variant='contained'
                                    color='primary'
                                    onClick={() => this.handleSubmit()}
                                    disabled={this.state.source == ""||this.state.checkedEntries.length==0}
                                >
                                    {IMPORT}
                                </Button>
                                <Button
                                    style={{ width: '180px' }}
                                    variant='contained'
                                    color='primary'
                                    onClick={() => this.handleSubmit(true)}
                                    disabled={this.state.source == ""||this.state.checkedEntries.length==0}
                                >
                                    {IMPORT_REVERSE}
                                </Button>
                            </div>
                        </div>
                    </>
                }
                {!this.state.onImportPage &&
                    <Box
                        component="div"
                        padding={2}
                        margin={2}
                        border={1}
                        borderColor="divider"
                        borderRadius={2}
                    >
                        {lines.map((line, index) => {
                            const styledContent = this.styleLineContent(line.replace(/,/g, ""));
                            return (
                                <Box
                                    component="div"
                                    key={index}
                                    marginBottom={index !== lines.length - 1 ? 2 : 0}
                                >
                                    <Typography variant="h5" gutterBottom>
                                        <span dangerouslySetInnerHTML={{ __html: styledContent }} />
                                    </Typography>
                                </Box>
                            );
                        })}
                        { this.state.processing ?
                            <div>
                                <CircularProgress/>
                            </div>
                            :
                            ""
                        }
                    </Box>
                }
            </>
        );
    }


}


const mapStateToProps = (state) => ({
    historicalData: historicalDataSelector(state),
    shiprushFileNames: shiprushFileNamesSelectors(state)
})

const actionCreators = {
    importHistoricalData,
    listSheets,
    clearHistoricalData,
    clearShiprushSpreadsheetsInfo
}

ImportShiprushData.propTypes = {

}

ImportShiprushData.defaultProps = {

}

export default withShipment({
    mapStateToProps,
    actionCreators
}, ImportShiprushData);