import React from 'react';

// custom components
import SearchableTable from "../../global/Search/SearchableTable";
import SettingsToolbar from "../SettingsToolbar";
import ManageUsersTableHead from "../ManageUsers/ManageUsersTableHead";
import SearchField from "../../global/Search/SearchField";
import CreateCarrierDialog from "./CreateCarrierDialog";
import DeleteSection from "../DeleteSection"

// material components
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tooltip,
    IconButton,
    Radio, Checkbox, TablePagination
} from "@material-ui/core";

// material icons
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
// constants
import {
    CARRIER_TOOLBAR_TITLE,
    CARRIER_TABLE_HEAD_CELLS,
    ADD_CARRIER_TITLE,
    ADD_CARRIER,
    DEFAULT_ROWS_PER_PAGE,
    ROWS_PER_PAGE_LABEL,
    LTL_FTL_CARRIERS
} from "./constants";
import { ASC, DESC } from "../../global/constants";

// redux
import withShipment from "../../../withShipment";
import {
    createCarrier,
    listLinkedAddresses,
    listCarriers,
    editCarrier,
    setDefaultCarrier,
    deleteCarrier, addressValidationChange,
    listAvailableLocations, manualExecuteCarrierAutoSetting, clearCarrierOperationResponse
} from "../../../redux/actions/settings";
import { listMarketplaces } from "../../../redux/actions/settings";
import {
    linkedAddressesSelector,
    carriersSelector,
    availableLocationsSelector,
    marketplacesSelector, carrierOperationResponseSelector
} from "../../../redux/selectors/settings";
import {
    userInfoSelector
} from "../../../redux/selectors/auth";
import GlobalTableHead from "../../global/Table/GlobalTableHead";
import PropTypes from "prop-types";
import CarrierStepper from "./CarrierStepper";
import CustomTableHeader from "../../global/Table/CustomTableHeader";
import ErrorFeedbackBar from '../../global/ErrorFeedbackBar';
import {
    deleteFullTruckloadCarrier,
    fetchFullTruckloadCarriers,
    saveFullTruckloadCarriers
} from "../../../redux/actions/fullTruckloadCarriers";
import {fullTruckloadCarriersSelector} from "../../../redux/selectors/fullTruckloadCarrier";
import {SUCCESS} from "../../shipments/constants";
import FeedbackBar from "../../feedbackBar/FeedbackBar";

class CarrierTable extends SearchableTable {

    constructor(props) {
        super(props);
        this.state = {
            order: ASC,
            orderBy: "carrier",
            isSearch: false, // true if search value is not empty, false otherwise
            searchValue: "", // the value in the search field (updates on change)
            searchRows: 0, // number of rows displayed while search criteria is met
            carrierDialog: false,
            deleteDialog: false,
            isDelete: {},
            rowsPerPage: DEFAULT_ROWS_PER_PAGE,
            page: 0,

            isEdit: false,
            selectedCarrier: {},
            selectedAddress: {},
            selectedCarrierInfo: {
                carrierId: '',
                shipAddressId: ''
            },
            allCarriers: [],
            feedBackBarOpen: false,
        }
    }

    componentDidMount() {
        const companyId = {
            id: this.props.userInfo.company
        }
        this.props.listAvailableLocations()
        this.props.listCarriers(companyId)
        this.props.listMarketplaces(companyId)
        this.props.fetchFullTruckloadCarriers()
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevProps.carriers !== this.props.carriers || prevProps.ftlCarriers !== this.props.ftlCarriers) {
            const ftlCarriers = this.props.ftlCarriers?.map(carrier=> {
                    carrier.isFTLCarrier = true
                    return carrier
                }
            )
            this.setState({
                allCarriers:  [...this.props.carriers, ...ftlCarriers]
            })
        }
        if ( this.props.responseMessage && prevProps.responseMessage !== this.props.responseMessage) {
            this.setState({
                feedBackBarOpen: true
            })
        }
    }

    closeFeedbackBar = () => {
        this.setState({
            feedBackBarOpen: false
        })
        this.props.clearCarrierOperationResponse()

    }

    handleRequestSort = (event, property) => {
        const isAsc = this.state.orderBy === property && this.state.order === ASC;
        this.setState({
            order: isAsc ? DESC : ASC,
            orderBy: property,
        });
    };

    handleOpenCreateDialog = () => {
        this.setState({
            carrierDialog: true,
            isEdit: false,
        })
    }

    handleOpenEditDialog = () => {
        this.setState({
            carrierDialog: true,
            isEdit: true
        })
    }

    handleCloseDialog = () => {
        this.setState({
            carrierDialog: false,
            selectedCarrier: {},
            selectedCarrierInfo: {
                carrierId: '',
                shipAddressId: ''
            }
        })
    }

    // check if current selected === selected
    isSelected = (carrierId, shipAddressId) => {
        return (this.state.selectedCarrierInfo.carrierId === carrierId && this.state.selectedCarrierInfo.shipAddressId === shipAddressId)
    }

    // only one carrier can be selected at a time for actions to be carried out correctly
    handleCarrierSelect = (carrierId, shipAddressId) => () => {

        if (this.isSelected(carrierId, shipAddressId)) {
            this.setState({
                selectedCarrier: {},
                selectedCarrierInfo: {
                    carrierId: '',
                    shipAddressId: ''
                }
            })
        } else {
            const carrierIndex = this.state.allCarriers.findIndex(carrier => carrier.carrierId === carrierId)
            const addressIndex = this.state.allCarriers[carrierIndex].addresses?.findIndex(address => address.shipAddressId === shipAddressId)
            this.setState({
                selectedCarrier: this.state.allCarriers[carrierIndex],
                selectedAddress: !isNaN(addressIndex) ? this.state.allCarriers[carrierIndex].addresses[addressIndex] : null,
                selectedCarrierInfo: {
                    carrierId,
                    shipAddressId,
                }
            })
        }
        this.handleOpenEditDialog();
    }

    handleDeleteOpen = (index) => {
        let newIsDelete = this.state.isDelete
        newIsDelete[index] = true
        this.setState({ isDelete: newIsDelete })
    }

    handleDeleteClose = (index) => {
        let newIsDelete = this.state.isDelete
        newIsDelete[index] = false
        this.setState({ isDelete: newIsDelete })
    }

    handleDelete = (index) => {
        const data = {
            carrierId: this.state.selectedCarrierInfo.carrierId,
            shipAddressId: this.state.selectedCarrierInfo.shipAddressId,
            companyId: this.props.userInfo.company
        }

        this.props.deleteCarrier(data, this.handleDeleteClose(index))
    }

    getDisplayed = (rows) => {
        if (!rows)
            return [];
        return [...rows.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)];
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: event.target.value,
            page: 0,
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
        });
    };

    onSearchValueChange = (event) => {
        this.setState({
            isSearch: event.target.value.length > 0,
            searchValue: event.target.value,
            page: 0
        });
    }


    render() {
        return (
            <Paper className="paper">
                <ErrorFeedbackBar />
                <FeedbackBar
                    open={this.state.feedBackBarOpen}
                    severity={SUCCESS}
                    message={this.props.responseMessage}
                    handleClose={()=>this.closeFeedbackBar()}
                />
                {this.state.carrierDialog ?
                    <CreateCarrierDialog
                        open={this.state.carrierDialog}
                        isEdit={this.state.isEdit}
                        selectedAddress={this.state.selectedAddress}
                        selectedCarrier={this.state.selectedCarrier}
                        carrierId={this.state.selectedCarrierInfo.carrierId}
                        shipAddressId={this.state.selectedCarrierInfo.shipAddressId}
                        handleClose={this.handleCloseDialog}
                        addresses={this.props.availableLocations}
                        createCarrier={this.props.createCarrier}
                        saveFullTruckloadCarriers={this.props.saveFullTruckloadCarriers}
                        editCarrier={this.props.editCarrier}
                        company={this.props.userInfo.company}
                        title={CARRIER_TOOLBAR_TITLE}
                        selected={this.state.selectedCarrierInfo}
                        handleDeleteOpen={this.handleDeleteOpen}
                        handleDeleteClose={this.handleDeleteClose}
                        handleDelete={this.handleDelete}
                        deleteOpen={this.state.deleteDialog}
                        handleClose={this.handleCloseDialog}
                        marketplaces={this.props.marketplaces}
                        addingOrEditing={this.state.carrierDialog}
                        manualExecuteCarrierAutoSetting={this.props.manualExecuteCarrierAutoSetting}
                    />
                    :
                    <>
                        <SettingsToolbar
                            title={CARRIER_TOOLBAR_TITLE}
                            handleCreateOpen={this.handleOpenCreateDialog}
                            handleEditOpen={this.handleOpenEditDialog}
                            selected={this.state.selectedCarrierInfo}
                            handleDeleteOpen={this.handleDeleteOpen}
                            handleDeleteClose={this.handleDeleteClose}
                            handleDelete={this.handleDelete}
                            deleteOpen={this.state.deleteDialog}
                            handleBack={this.handleBack}
                            handleClose={this.handleCloseDialog}
                            addingOrEditing={this.state.carrierDialog}
                        />
                        <CustomTableHeader
                            searchText={this.state.searchValue}
                            onSearchValueChange={this.onSearchValueChange}
                            pagination={
                                <TablePagination
                                    className="table-pagination"
                                    labelRowsPerPage={ROWS_PER_PAGE_LABEL}
                                    rowsPerPageOptions={[5, 25, 50, 100, {value: this.state.allCarriers.length, label: "All"} ]}
                                    count={!this.state.allCarriers ? 0 : this.filterBySearch(this.state.allCarriers).length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            }
                        />
                        <TableContainer>
                            <Table
                                aria-labelledby='tableTitle'
                                size='medium'
                                aria-label={CARRIER_TOOLBAR_TITLE}
                            >
                                <GlobalTableHead
                                    isCarrierTable={true}
                                    headCells={CARRIER_TABLE_HEAD_CELLS}
                                    order={this.state.order}
                                    orderBy={this.state.orderBy}
                                    onRequestSort={this.handleRequestSort}
                                />
                                <TableBody>
                                    {this.getDisplayed(this.stableSort(this.filterBySearch(this.state.allCarriers), this.getComparator(this.state.order, this.state.orderBy)))
                                        .map((carrier, index) => {
                                            const carrierName = carrier.name;
                                            const alias = carrier.isFTLCarrier ? carrier.location : carrier.alias;
                                            const accountNumber = carrier.accountNumber;
                                            const carrierId = carrier.carrierId
                                            const address = carrier.addresses ? carrier.addresses[0]?.addressLine1 : carrier.addressLine1
                                            let enabled = "Yes"
                                            if (!carrier.isFTLCarrier) {
                                                if (!carrier.isEnabled)
                                                    enabled = "No"
                                            }
                                            const handleIsDefaultChange = () => {
                                                this.props.setDefaultCarrier(this.props.userInfo.company, carrierId)
                                            }
                                            const handleDelete = () => {
                                                if (carrier.isFTLCarrier) {
                                                    this.props.deleteFullTruckloadCarrier(carrierId)
                                                }
                                                else {
                                                    const data = {
                                                        carrierId: carrierId,
                                                        shipAddressId: this.state.selectedCarrierInfo.shipAddressId,
                                                        companyId: this.props.userInfo.company
                                                    }
                                                    this.props.deleteCarrier(data, this.handleDeleteClose(index))
                                                }
                                            }
                                            return (
                                                <TableRow>

                                                    <TableCell>
                                                        <IconButton onClick={this.handleCarrierSelect(carrierId, carrier.addresses ? carrier.addresses[0]?.shipAddressId: null)}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    </TableCell>

                                                    <TableCell>{carrierName}</TableCell>
                                                    <TableCell>{alias}</TableCell>
                                                    <TableCell>{address}</TableCell>
                                                    <TableCell style={{maxWidth: "1rem", overflow: "auto"}}>{accountNumber}</TableCell>
                                                    <TableCell>
                                                        <Radio
                                                            checked={carrier.isDefault}
                                                            onChange={handleIsDefaultChange}
                                                            value={carrier.isDefault}
                                                            disabled={carrier.isFTLCarrier}
                                                            name={carrierId} />
                                                    </TableCell>
                                                    <TableCell>{enabled}</TableCell>
                                                    <DeleteSection
                                                        index={index}
                                                        handleDeleteFalse={this.handleDeleteClose}
                                                        handleDeleteOpen={this.handleDeleteOpen}
                                                        handleDeleteTrue={handleDelete}
                                                        isDelete={this.state.isDelete[index] ? this.state.isDelete : false}
                                                    />
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                }
            </Paper>
        );
    }

}

const mapStateToProps = (state) => ({
    linkedAddresses: linkedAddressesSelector(state),
    availableLocations: availableLocationsSelector(state),
    carriers: carriersSelector(state),
    userInfo: userInfoSelector(state),
    marketplaces: marketplacesSelector(state),
    ftlCarriers: fullTruckloadCarriersSelector(state),
    responseMessage: carrierOperationResponseSelector(state),
});

const actionCreators = {
    createCarrier,
    saveFullTruckloadCarriers,
    listAvailableLocations,
    listCarriers,
    listLinkedAddresses,
    editCarrier,
    setDefaultCarrier,
    deleteCarrier,
    addressValidationChange,
    listMarketplaces,
    fetchFullTruckloadCarriers,
    deleteFullTruckloadCarrier,
    manualExecuteCarrierAutoSetting,
    clearCarrierOperationResponse,
};

CarrierTable.propTypes = {
    linkedAddresses: PropTypes.array,
    availableLocations: PropTypes.array,
    carriers: PropTypes.array,
    ftlCarriers: PropTypes.array,
    userInfo: PropTypes.object,
    marketplaces: PropTypes.array,
}

CarrierTable.defaultProps = {
    linkedAddresses: [],
    availableLocations: [],
    carriers: [],
    ftlCarriers: [],
    userInfo: {},
    marketplaces: [],
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, CarrierTable)