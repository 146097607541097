import React from 'react';
import TextField from '@material-ui/core/TextField';

// import styling
import './SearchField.css'
import PropTypes from "prop-types";
import FeedbackBar from "../../feedbackBar/FeedbackBar";
import { Clear } from '@material-ui/icons';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";

class SearchField extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <div>
                <TextField
                    className={this.props.page === 'auto' ? 'auto-search' : "custom-search"}
                    margin="dense"
                    id="outlined-basic"
                    label="Search"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment>
                                <IconButton
                                    className={"clear-button"}
                                    onClick={(e) => {
                                        let ev = {
                                            target: {
                                                value: ''
                                            }
                                        }
                                        this.props.onChange(ev)
                                    }}>
                                    <Clear />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    variant="outlined"
                    onChange={(e) => {
                        this.props.onChange(e);
                    }}
                    autoFocus={this.props.autoFocus}
                    value={this.props.searchText} />
            </div>
        );
    }
}

SearchField.propTypes = {
    onChange: PropTypes.func,
    autoFocus: PropTypes.bool,
    searchText: PropTypes.string
}

SearchField.defaultProps = {
    onChange: () => { },
    autoFocus: false,
    searchText: ''
}

export default SearchField