export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const STORE_TOKEN = 'STORE_TOKEN';
export const REGISTER_USER = 'REGISTER_USER';

export const TIMEOUT = 1000;

export const GET_USERS = 'GET_USERS';
export const STORE_USERS = 'STORE_USERS';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const UPDATE_SELECTED_LOCATION = 'UPDATE_SELECTED_LOCATION';

