import React from "react";

// material components
import { Select, MenuItem, Box, Tooltip, IconButton } from "@material-ui/core";

// material icons
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

// import constants
import { FILTER_TYPE_BOOLEAN_1_OPTIONS } from "./constants";
import {COPY_FILTER, DELETE_FILTER, DRAG} from "../constants";

// import styling
import "./FilterBooleanSelect.css";
import PropTypes from "prop-types";
import FilterBooleanPredefinedSelect from "./FilterBooleanPredefinedSelect";
import DehazeIcon from "@material-ui/icons/Dehaze";
import FileCopyIcon from "@material-ui/icons/FileCopy";

/**
 * Dropdown list for FILTER_TYPE_BOOLEAN
 */
class FilterBooleanSelect extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filterId: '',
      type: '',
      name: '',
      value: ''
    };
  }

  componentDidMount = () => {
    this.setState({
      // on component mount, the parent component stores this child's state.
      // We restore it here
      ...this.props.filter,
    });
  };

  handleChange = (event) => {
    const value = event.target.value;
    this.setState(
      {
        value,
      },
      () => {
        this.props.updateFilterWithParent({ ...this.state });
      }
    );
  };

  render() {
    return (
      <Box
        className='filterBooleanSelect'
        display='flex'
        flexDirection='row'
        justifyContent='left'
        alignItems='center'
      >
        <Box className='filterBooleanSelectName'>{this.state.name}</Box>
        <Select
          className='filterBooleanSelectOptions'
          variant='outlined'
          value={this.state.value}
          onChange={this.handleChange}
        >
          {/* <option aria-label="None" value="" /> */}
          {FILTER_TYPE_BOOLEAN_1_OPTIONS.map((item) => (
            <MenuItem value={item}>{item}</MenuItem>
          ))}
        </Select>

        {/* in order to make sure that the delete icon aligns, we add an empty component */}
        <div className='filterBooleanEmptyDiv'>
          <Tooltip title={COPY_FILTER}>
            <IconButton
                disabled={this.state.name === "Has Attachment"}
                aria-label={COPY_FILTER}
                onClick={() => this.state.name === "Has Attachment" ? null : this.props.copyFilter(this.state.filterId)}>
              <FileCopyIcon fontSize='small'/>
            </IconButton>
          </Tooltip>
          <Tooltip title={DELETE_FILTER}>
            <IconButton
              aria-label={DELETE_FILTER}
              onClick={() => this.props.deleteFilter(this.state.filterId)}
            >
              <DeleteOutlineIcon variant='outlined' fontSize='small' />
            </IconButton>
          </Tooltip>
          <Tooltip title={DRAG}>
            <IconButton
                aria-label='drag'
            >
              <DehazeIcon variant='outlined' fontSize='small' />
            </IconButton>
          </Tooltip>
        </div>
      </Box>
    );
  }
}

FilterBooleanSelect.propTypes = {
  filter: PropTypes.object,
  updateFilterWithParent: PropTypes.func,
  deleteFilter: PropTypes.func,
  isEdit: PropTypes.bool,
  copyFilter: PropTypes.func
}

FilterBooleanSelect.defaultProps = {
  filter: {},
  updateFilterWithParent: ()=>{},
  deleteFilter: ()=>{},
  isEdit: false,
  copyFilter: ()=>{}
}

export default FilterBooleanSelect;
