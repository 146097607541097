export const RETURNS_TABLE_HEAD_CELLS = [
    { id: 'dueDate', numeric: false, disablePadding: false, label: 'Due Date' },

    { id: 'customerName', numeric: false, disablePadding: false, label: 'Customer Name' },
    { id: 'originalTrackingId', numeric: false, disablePadding: false, label: 'Original Tracking Number' },
    { id: 'orderId', numeric: false, disablePadding: false, label: 'Order ID' },
    { id: 'rmaNum', numeric: false, disablePadding: false, label: 'RMA #' },
    { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
    { id: 'returnedDate', numeric: false, disablePadding: false, label: 'Returned Date' },
    { id: 'assignedToName', numeric: false, disablePadding: false, label: 'Assigned To' }
];

export const DEFAULT_SORT_BY_HEAD_CELL = 'dueDate';

export const CREATE_RETURN_TITLE = 'Create a customer return';
export const BACK_TO_MENU = 'Back to Menu';
export const RETURN_TOOLBAR_TITLE = 'Returns';

export const CONDITIONS = [
    'Good',
    'Damaged',
    'Partially Damaged',
    'Used',
    'Date Written',
    'Wrong Product',
    'Other'
];

export const ACTIONS = [
    'Added Back To Inventory',
    'Discarded',
    'Pending'
];

export const CANCEL = 'Cancel';
export const SAVE = 'Save';
export const COMPLETE_RETURN = 'Complete Return';

export const COMMENTS_TABLE_TITLE = 'Comments';

export const DEFAULT_ROWS_PER_PAGE = 50;
export const ROWS_PER_PAGE_LABEL = 'Returns per page'
export const COMPLETED_STATUS = "Completed";

export const SHOW_MY_RETURNS = "Show my returns only"
export const SHOW_COMPLETED_RETURNS = "Show completed returns"
export const SHOW_REFUND_PENDING_RETURNS = "Show refund pending"

export const REFUND_AMOUNT_CHECKBOX = "Refund amount";
export const LOCATION_TEXTFIELD_LABEL = "Location";

export const ERROR_MESSAGE = "Required field";

export const PENDING = "Pending";