import React from "react";
import withShipment from "../../../withShipment";
import SearchableTable from "../../global/Search/SearchableTable";
import CustomToolbar from "../../global/CustomToolbar";
import {COMPANY_TOOLBAR_TITLE} from "./constants";
import {Box, MenuItem, Select} from "@mui/material";
import {Button, IconButton, TextField, Tooltip, Typography} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import {CURRENCY} from "../../automationrules/AutomationSettings/constants";
import DeleteIcon from "@material-ui/icons/Delete";
import Divider from "@material-ui/core/Divider";
import {AddCircleOutline as AddIcon} from "@material-ui/icons";
import green from "@material-ui/core/colors/green";
import {getCompany, updateCompany} from "../../../redux/actions/automationRules";
import {automationInfoSelector} from "../../../redux/selectors/account";
import {userInfoSelector} from "../../../redux/selectors/auth";
import PhoneIcon from "@material-ui/icons/Phone";
import "../../settings/ManageSuppliers/ManageSuppliersStepper.css"
import SaveIcon from "@material-ui/icons/Save";
import ErrorFeedbackBar from "../../global/ErrorFeedbackBar";
import FeedbackBar from "../../feedbackBar/FeedbackBar";
import {clearStatus} from "../../../redux/actions/global";

class ManageCompany extends SearchableTable{

    constructor(props) {
        super(props);
        this.state = {
            feedbackBarOpen: false,
            statusText:"Error",
            status:null,
            automationSettings: null,
            currencyRates:[],
            defaultCurrency:"",
            companyAddress: [{name: '', phone: '', hst: '', addressLine1: '', addressLine2: '', city: '', state: '', zip: '', country: ''}],
            requiredList: [
                "name",
                "phone",
                "addressLine1",
                "city",
                "state",
                "zip",
                "country",
            ],
        }
    }


    componentDidMount() {
        this.props.getCompany()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(prevProps.automationSettings !== this.props.automationSettings) {
            this.setState({
                currencyRates: this.props?.automationSettings?.currencyRates === undefined ? [] : this.props?.automationSettings?.currencyRates,
                automationSettings: this.props.automationSettings,
                defaultCurrency: this.props.automationSettings.defaultCurrency,
                companyAddress: this.props?.automationSettings?.companyAddress === undefined ? [] : this.props?.automationSettings?.companyAddress
            });

        }
    }

    setCurrencyRates= (targetIndex, updatedCurrencyRate) => {
        const updatedCurrencyRates = this.state.currencyRates.map((rate, index) => {
            return index !== targetIndex ? rate : updatedCurrencyRate;
        });

        this.setState(prevState => ({
            currencyRates: updatedCurrencyRates,
            automationSettings: {
                ...prevState.automationSettings,
                currencyRates: updatedCurrencyRates
            }
        }), this.updateHandler);
    }

    removeCurrentRate = (targetIndex) => {
        const updatedCurrencyRates = this.state.currencyRates.filter((currencyRate, index) => {
            return index !== targetIndex;
        });
        this.setState(prevState => ({
            currencyRates: updatedCurrencyRates,
            automationSettings: {
                ...prevState.automationSettings,
                currencyRates: updatedCurrencyRates
            }
        }), this.updateHandler);
    }


    getCurrencyComponent = (currencyRates) => {
        return (
            <>

                {
                    currencyRates?.map((currencyRate,index) => (
                        <Box className="columnBox">
                            <div style={{display:"flex", alignItems: "center"}}>
                                <div>
                                    <Box className="rowBox"  style={{ width: '350px' }}>
                                        <TextField
                                            variant="outlined"
                                            className="removePadding"
                                            value={currencyRate.fromAmount}
                                            onChange={(e) => {
                                                let tempCurrencyRate = currencyRate
                                                tempCurrencyRate.fromAmount = e.target.value
                                                this.setCurrencyRates(index, tempCurrencyRate)
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end" >
                                                        <Select
                                                            style={{ width: '200px' }}
                                                            value={currencyRate.fromCurrency}
                                                            onChange={(e) => {
                                                                let tempCurrencyRate = currencyRate
                                                                tempCurrencyRate.fromCurrency = e.target.value
                                                                this.setCurrencyRates(index, tempCurrencyRate)
                                                            }}
                                                            displayEmpty
                                                        >
                                                            {CURRENCY.map(currency => (
                                                                <MenuItem key={currency.code} value={currency.code}>
                                                                    {currency.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Box>
                                    <Box className="rowBox" style={{ width: '350px'}}>
                                        <TextField
                                            variant="outlined"
                                            value={currencyRate.toAmount}
                                            onChange={(e) => {
                                                let tempCurrencyRate = currencyRate
                                                tempCurrencyRate.toAmount = e.target.value
                                                this.setCurrencyRates(index, tempCurrencyRate)
                                            }}
                                            className="removePadding"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Select
                                                            style={{ width: '200px' }}
                                                            value={currencyRate.toCurrency}
                                                            onChange={(e) =>{
                                                                let tempCurrencyRate = currencyRate
                                                                tempCurrencyRate.toCurrency = e.target.value
                                                                this.setCurrencyRates(index, tempCurrencyRate)
                                                            }}
                                                            displayEmpty
                                                        >
                                                            {CURRENCY.map(currency => (
                                                                <MenuItem key={currency.code} value={currency.code}>
                                                                    {currency.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Box>
                                </div>
                                {/*<DeleteIcon onClick={() => this.removeCurrentRate(index)}/>*/}
                                <IconButton onClick={() => this.removeCurrentRate(index)} size={"medium"}>
                                    <DeleteIcon />
                                </IconButton>
                            </div>
                            <Divider style={{marginBottom: '10px', width: "400px"}} />
                        </Box>

                    ))
                }
            </>
        )
    }

    addCurrencyRate = () => {
        let currencyRate = {fromAmount:"0.0", fromCurrency:"Canadian Dollar",toAmount:"0.0", toCurrency:"Canadian Dollar"}
        this.setState(prevState => ({
            currencyRates: [...prevState.currencyRates, currencyRate]
        }));
    }

    updateHandler = () => {
        const updatedAutomationSettings = {
            ...this.state.automationSettings,
            companyAddress: this.state.companyAddress,
        };
        this.setState({ automationSettings: updatedAutomationSettings }, () => {
            this.props.updateCompany({
                companyId: this.props.user.company,
                automationSetting: this.state.automationSettings
            });
            this.setState({ status: 'success', statusText: "Saved" })
            this.openFeedbackBar()
        });

    }

    updateDefaultCurrency = () => {
        this.props.updateCompany({
            companyId: this.props.user.company,
            automationSetting: {
                ...this.state.automationSettings,
                defaultCurrency: this.state.defaultCurrency
            }
        });
    }

    handleFieldChange = ( index, key, value,addressType) => {
        this.setState((prevState) => {
            const updatedAddresses = [...prevState[addressType]];
            updatedAddresses[index] = {
                ...updatedAddresses[index],
                [key]: value,
            };
            return { [addressType]: updatedAddresses };
        });
    };

    getValue = ( index, key,addressType) => {
        const addresses = this.state[addressType];
        if (!addresses || !Array.isArray(addresses)) {
            return ''; // Return an empty string if addresses is not yet available or not an array
        }
        const address = addresses[index];
        return address ? address[key] || '' : '';
    };



    checkSave = () => {
        let hasEmpty = false;


        // Check companyAddress
        this.state.companyAddress.forEach((address) => {
            this.state.requiredList.forEach((requiredField) => {
                if (!address[requiredField]) {
                    hasEmpty = true;
                }
            });
        });

        return hasEmpty;
    };

    openFeedbackBar = () => {
        this.setState({feedbackBarOpen: true})
    }

    closeFeedbackBar = () => {
        this.setState({ feedbackBarOpen: false }, () => {
            this.props.clearStatus()
        })
    }




    render (){
        let headerButtons = [{
            icon: <SaveIcon />,
            handler: () => {
                this.setState({saved: true})
                this.updateHandler()
            },
            disabled: this.checkSave(),
            title: "Save"
        }]

        const index = 0
        return (
            <>
                <CustomToolbar
                    title={COMPANY_TOOLBAR_TITLE}
                    headerButtons={headerButtons}
                />
                <ErrorFeedbackBar/>
                <FeedbackBar
                    open={this.state.feedbackBarOpen}
                    handleClose={this.closeFeedbackBar}
                    severity={this.state.status ? 'success' : 'error'}
                    message={this.state.statusText}
                />


                <div>

                    <div className="currencyStyle">
                        Company Details
                        <br/>
                    </div>

                    <div key={index} style={{marginLeft: "24px"}}>
                            <div className='rowSpecSuppliers'>
                                <TextField
                                    className='input-text-field'
                                    variant='outlined'
                                    label="Name"
                                    InputLabelProps={{shrink: true}}
                                    name='name'
                                    value={this.getValue(index, 'name', "companyAddress")}
                                    onChange={(e) => this.handleFieldChange(index, 'name', e.target.value, "companyAddress")}
                                    // value={this.state.name}
                                    // onChange={this.handleTextChange}
                                    required={this.state.requiredList.includes("name")}
                                />
                                <TextField
                                    className='input-text-field'
                                    variant='outlined'
                                    label="Phone"
                                    InputLabelProps={{shrink: true}}
                                    name='phone'
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PhoneIcon/>
                                            </InputAdornment>
                                        ),
                                    }}
                                    // value={this.state.phone}
                                    // onChange={this.handleTextChange}
                                    value={this.getValue(index, 'phone', "companyAddress")}
                                    onChange={(e) => this.handleFieldChange(index, 'phone', e.target.value, "companyAddress")}
                                    required={this.state.requiredList.includes("phone")}

                                />

                                <TextField
                                    className='input-text-field'
                                    variant='outlined'
                                    label="HST #"
                                    InputLabelProps={{shrink: true}}
                                    name='hst'
                                    value={this.getValue(index, 'hst', "companyAddress")}
                                    onChange={(e) => this.handleFieldChange(index, 'hst', e.target.value, "companyAddress")}
                                />
                            </div>
                            <div className='rowSpecSuppliers'>
                                <TextField
                                    variant='outlined'
                                    label="Address Line 1"
                                    className='single-line-field'
                                    InputLabelProps={{shrink: true}}
                                    name='addressLine1'
                                    // value={this.state.addressLine1}
                                    // onChange={this.handleTextChange}
                                    value={this.getValue(index, 'addressLine1', "companyAddress")}
                                    onChange={(e) => this.handleFieldChange(index, 'addressLine1', e.target.value, "companyAddress")}
                                    required={this.state.requiredList.includes("addressLine1")}
                                />
                            </div>
                            <div className='rowSpecSuppliers'>
                                <TextField
                                    variant='outlined'
                                    label="Address Line 2"
                                    className='single-line-field'
                                    InputLabelProps={{shrink: true}}
                                    name='addressLine2'
                                    value={this.getValue(index, 'addressLine2', "companyAddress")}
                                    onChange={(e) => this.handleFieldChange(index, 'addressLine2', e.target.value, "companyAddress")}
                                />
                            </div>
                            <div className='rowSpecSuppliers'>
                                <TextField
                                    className='input-text-field'
                                    variant='outlined'
                                    label="City"
                                    InputLabelProps={{shrink: true}}
                                    name='city'
                                    // value={this.state.city}
                                    // onChange={this.handleTextChange}
                                    value={this.getValue(index, 'city', "companyAddress")}
                                    onChange={(e) => this.handleFieldChange(index, 'city', e.target.value, "companyAddress")}
                                    required={this.state.requiredList.includes("city")}
                                />
                                <TextField
                                    className='input-text-field'
                                    variant='outlined'
                                    label="State or Province"
                                    InputLabelProps={{shrink: true}}
                                    name='state'
                                    // value={this.state.state}
                                    // onChange={this.handleTextChange}
                                    value={this.getValue(index, 'state', "companyAddress")}
                                    onChange={(e) => this.handleFieldChange(index, 'state', e.target.value, "companyAddress")}
                                    required={this.state.requiredList.includes("state")}
                                />
                                <TextField
                                    className='input-text-field'
                                    variant='outlined'
                                    label="Zip or Postal Code"
                                    InputLabelProps={{shrink: true}}
                                    name='zip'
                                    // value={this.state.zip}
                                    // onChange={this.handleTextChange}
                                    value={this.getValue(index, 'zip', "companyAddress")}
                                    onChange={(e) => this.handleFieldChange(index, 'zip', e.target.value, "companyAddress")}
                                    required={this.state.requiredList.includes("zip")}
                                />
                            </div>
                            <div className='rowSpecSuppliers'>
                                <TextField
                                    className='input-text-field'
                                    variant='outlined'
                                    label="Country"
                                    InputLabelProps={{shrink: true}}
                                    name='country'
                                    // value={this.state.country}
                                    // onChange={this.handleTextChange}
                                    value={this.getValue(index, 'country', "companyAddress")}
                                    onChange={(e) => this.handleFieldChange(index, 'country', e.target.value, "companyAddress")}
                                    required={this.state.requiredList.includes("country")}
                                />
                            </div>
                    </div>



                    <div className="currencyStyle">
                        Default Currency
                        <br/>
                    </div>
                    <TextField
                        variant="outlined"
                        value={this.state.defaultCurrency}
                        style={{marginLeft: "34px"}}
                        onChange={(e) => {
                            this.setState({
                                defaultCurrency: e.target.value,
                            }, this.updateDefaultCurrency)
                        }}
                    />
                </div>
                <div>
                    <div className="currencyStyle">
                        Conversion Rates
                        <br/>
                    </div>
                    {this.getCurrencyComponent(this.state.currencyRates)}
                    <div className='add-single-file-button' style={{marginLeft: "34px"}}>
                        <IconButton onClick={() => this.addCurrencyRate()} style={{padding: "5px"}}>
                            <AddIcon style={{color: green[500]}}/>
                        </IconButton>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    user: userInfoSelector(state),
    automationSettings: automationInfoSelector(state),
})

const actionCreators = {
    getCompany,
    updateCompany,
    clearStatus
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, ManageCompany);