export const FETCH_ALL_SUPPLIERS = 'FETCH_ALL_SUPPLIERS'
export const CREATE_NEW_PURCHASE_ORDER = 'CREATE_NEW_PURCHASE_ORDER'
export const CREATE_SEND_NEW_PURCHASE_ORDER = 'CREATE_SEND_NEW_PURCHASE_ORDER'

export const GET_PDF_PREVIEW = 'GET_PDF_PREVIEW'
export const STORE_PDF_PREVIEW_READY = 'STORE_PDF_PREVIEW_READY'
export const CACHE_PDF_PREVIEW = 'CACHE_PDF_PREVIEW'
export const STORE_PENDING_ORDERS = 'STORE_PENDING_ORDERS'
export const STORE_ALL_PURCHASE_ORDERS = 'STORE_ALL_PURCHASE_ORDERS'
export const FETCH_ALL_PURCHASE_ORDERS = 'FETCH_ALL_PURCHASE_ORDERS'

export const STORE_NEW_PURCHASE_ORDERS = 'STORE_NEW_PURCHASE_ORDERS'
export const STORE_PARTIAL_PURCHASE_ORDERS = 'STORE_PARTIAL_PURCHASE_ORDERS'
export const STORE_COMPLETED_PURCHASE_ORDERS = 'STORE_COMPLETED_PURCHASE_ORDERS'

export const UPDATE_PURCHASE_ORDER = 'UPDATE_PURCHASE_ORDER'
export const DELETE_INVENTORY_RECEIVED = 'DELETE_INVENTORY_RECEIVED'

export const STORE_UPDATED_PURCHASE_ORDER = 'STORE_UPDATED_PURCHASE_ORDER';

export const PREVIEW_CREATED_PURCHASE_ORDER = 'PREVIEW_CREATED_PURCHASE_ORDER';
export const STORE_CREATED_PURCHASE_ORDER_PREVIEW = 'STORE_CREATED_PURCHASE_ORDER_PREVIEW';

export const PREVIEW_PACKING_SLIP_FILE = 'PREVIEW_PACKING_SLIP_FILE';
export const STORE_UPDATED_PURCHASE_ORDER_PREVIEW = 'STORE_UPDATED_PURCHASE_ORDER_PREVIEW'
export const DELETE_PACKING_SLIP = 'DELETE_PACKING_SLIP';

export const CANCEL_PURCHASE_ORDER = 'CANCEL_PURCHASE_ORDER';
export const EDIT_PURCHASE_ORDER = 'EDIT_PURCHASE_ORDER';
export const UNDO_CANCEL = 'UNDO_CANCEL';

export const RESET_PDF_PREVIEW_DATA = "RESET_PDF_PREVIEW_DATA";

export const UPDATE_EXPECTED_DELIVERY_DATE = "UPDATE_EXPECTED_DELIVERY_DATE"

