import {
    call,
    put,
    takeLatest,
} from 'redux-saga/effects';
import axios from 'axios';
import {
    CREATE_RETURN,
    LIST_RETURN_ACTIONS,
    LIST_RETURN_CONDITIONS,
    LIST_RETURN_REASONS,
    UPDATE_RETURN,
    LIST_UNCOMPLETED_RETURNS,
} from "../../constants/returns";
import { clearStatus, requestStatus, showErrorMessage, hideError } from "../../actions/global";
import { HTTP_SUCCESS } from "../../constants/global";
import {RETURNS, SEND_GRID} from "../../../components/global/Server/endpoints";
import {
    storeReturnActions,
    storeReturnConditions,
    storeReturnReasons,
    storeReturns,
    storeUpdatedReturn, storeUpdatedReturnWithPreview
} from "../../actions/returns";
import { SERVER_FAILED_ERROR_MESSAGE } from '../../../pages/Dashboard/DashboardFinal/constants';
import {LIST_CUSTOMER_MAILING_LIST} from "../../constants/customerReviews";

const getHeaders = () => {
    const token = localStorage.getItem('token');
    return {
        "Authorization": `Bearer ${token}`
    }
}

const postRequest = (endpoint, data) => {
    return axios.post(endpoint, data, {
        headers: getHeaders()
    })
        .then(response => response)
}

const getRequest = (endpoint) => {
    return axios.get(endpoint, {
        headers: getHeaders()
    })
        .then(response => response);
}

function* listCustomerMailingListSaga(){
    yield call(clearStatus)
    yield put(hideError());
    try{
        const response = yield call(() => getRequest(SEND_GRID.GET_CUSTOMER_MAILING_LIST))
    }catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE));
    }
}

export default function* customerReviewsSaga() {
    yield takeLatest(LIST_CUSTOMER_MAILING_LIST, listCustomerMailingListSaga)
}