import {
    CACHE_PDF_PREVIEW, RESET_PDF_PREVIEW_DATA, STORE_ALL_PURCHASE_ORDERS, STORE_CREATED_PURCHASE_ORDER_PREVIEW,
    STORE_PDF_PREVIEW_READY,
    STORE_UPDATED_PURCHASE_ORDER, STORE_UPDATED_PURCHASE_ORDER_PREVIEW,
} from "../../constants/purchaseOrders";
import {STORE_UPDATED_TICKET_WITH_PREVIEW} from "../../constants/tickets";

const initialState = {
    allPurchaseOrders: [],
    newPurchaseOrders: [],
    partialPurchaseOrders: [],
    completedPurchaseOrders: [],
    storePDFPreviewReady: new Uint8Array(0),
    createdPurchaseOrderPreview: new Uint8Array(0)
}

const updateList = (purchaseOrder, oldPurchaseOrders) => {
    let newList = [];
    let exists = oldPurchaseOrders.findIndex(item => item.poNumber === purchaseOrder.poNumber);
    if (exists === -1) {
        return oldPurchaseOrders;
    } else {
        oldPurchaseOrders.map((item, index) => {
            if (index === exists) {
                newList[index] = purchaseOrder;
            } else {
                newList[index] = item;
            }
        })
        return newList;
    }
}

const updatePurchaseOrderWithPreview = (updatedPO, oldPos, index) => {
    let filePreviewInfo = updatedPO.packingSlipFiles.pop()
    // get the data
    let previewData = new Uint8Array(filePreviewInfo.previewFile)
    let blobData = new Blob([previewData])
    let localStorageUrl = URL.createObjectURL(blobData)

    let poToUpdate = oldPos.find((po) => { return po.poNumber === updatedPO.poNumber })

    // we're only updating the specific file object as we dont want to lose state of currently uploaded files (locally) and other local changes user has made
    // if this is a general file
    if ("generalFileIndex" in filePreviewInfo) {
        let generalFileIndex = filePreviewInfo.generalFileIndex
        updatedPO.packingSlipFiles[generalFileIndex]["localURL"] = localStorageUrl
        poToUpdate.packingSlipFiles[generalFileIndex] = updatedPO.packingSlipFiles[generalFileIndex]
        poToUpdate.packingSlipFiles[generalFileIndex]['byteArray'] = previewData
    }


    return updateList(poToUpdate, oldPos)
}





export default (state = initialState, action) => {
    switch(action.type){
        case STORE_PDF_PREVIEW_READY || CACHE_PDF_PREVIEW:
            return {
                ...state,
                storePDFPreviewReady: action.storePDFPreviewReady
            }

        case STORE_ALL_PURCHASE_ORDERS:
            return {
                ...state,
                allPurchaseOrders: action.allPurchaseOrders
            }

        case STORE_UPDATED_PURCHASE_ORDER_PREVIEW:
            return {
                ...state,
                allPurchaseOrders: updatePurchaseOrderWithPreview(action.updatedPurchaseOrder, state.allPurchaseOrders, action.index)
            }

        case STORE_UPDATED_PURCHASE_ORDER:
            return {
                ...state,
                allPurchaseOrders: updateList(action.purchaseOrder, state.allPurchaseOrders)
            }

        case STORE_CREATED_PURCHASE_ORDER_PREVIEW:
            return {
                ...state,
                createdPurchaseOrderPreview: action.purchaseOrderPreview
            }
        case RESET_PDF_PREVIEW_DATA: 
        return {
            ...state,
            storePDFPreviewReady: new Uint8Array(0),
            createdPurchaseOrderPreview: new Uint8Array(0)
        }


        default:
            return state

    }

}