import React from 'react';

// material components
import {
    Container
} from "@material-ui/core";

// styling
import styles from './AccessDenied.module.css';

function AccessDenied() {
    return(
        <Container className={styles.center}>
            <h1>You are not authorized to view this page</h1>
        </Container>
    )
}

export default AccessDenied;