import React from 'react';

// import material icons
import {
    Box,
    IconButton, Tab, Tabs,
    Tooltip
} from "@material-ui/core";

// import styling
import './ActionText.css';
import PropTypes from "prop-types";
import {COMPONENT_TYPES} from "./constants";
import {Delete as DeleteIcon, FileCopy as CopyIcon} from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import ActionBoolean from "./ActionBoolean";
import ActionNumericDropdown from "./ActionNumericDropdown";
import SingleNumericalAction from "./SingleNumericalAction";
import DoubleNumericalAction from "./DoubleNumericalAction";
import TripleNumericalAction from "./TripleNumericalAction";
import ActionDropdown from "./ActionDropdown";
import ActionText from "./ActionText";
import {HTML5Backend} from "react-dnd-html5-backend";
import DragAndDrop from "../../global/Dnd/DragAndDrop";
import {DndProvider} from "react-dnd";

class PackageActions extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            packages: 1,
            tabIndex: 0
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.packageActions !== this.props.packageActions) {
            this.calculateNumberOfPackages()
        }
        if (!this.props.hasPredefinedPackageValue) {
            this.handleNotPredefinedValue()
        }
        if (this.props.numberOfPackages < this.state.packages) {
            this.handleNumberOfPackagesChange(this.props.numberOfPackages)
        }
    }

    calculateNumberOfPackages = () => {

        let packages = 1
        this.props.packageActions.forEach((action) => {
            packages = (parseInt(action.package)+1 > packages)? parseInt(action.package)+1: packages
        })
        this.setState({
            packages: packages > this.state.packages? packages : this.state.packages
        })
    }

    createTabs = () => {
        let tabs = []
        for (let index = 0; index < this.state.packages; index++) {
            tabs.push(<Tab
                label={index===0&&!this.props.hasPredefinedPackageValue?"All":index + 1}
                style={{textTransform: "none"}}
                className={this.state.tabIndex !== index ? "package-tab-not-selected" : "package-tab"}
            />);
        }
        return tabs
    }

    handleDeleteTab = () => {
        const index = this.state.tabIndex
        let actions = [...this.props.packageActions]
        let actionIds = []
        actions.forEach((action) => {
            if (this.checkPackageIndex(action)) {
                actionIds.push(action.actionId)
            } else if (action.package > index){
                action.package = action.package - 1
            }
        })
        this.props.deleteActions(actionIds)

        const newMaxPackages = this.state.packages - 1
        this.setState({
            tabIndex: index>0?index-1:0,
            packages: newMaxPackages
        })
    }

    handleNotPredefinedValue = () => {
        let actions = [...this.props.packageActions]
        let actionIds = []
        actions.forEach((action) => {
            if (action.package > 0) {
                actionIds.push(action.actionId)
            }
        })
        this.props.deleteActions(actionIds)

        const newMaxPackages = 1
        this.setState({
            tabIndex: 0,
            packages: newMaxPackages
        })
    }

    handleNumberOfPackagesChange = (numberOfPackages) => {
        if (numberOfPackages<1)
            return
        const index = this.state.tabIndex
        let actions = [...this.props.packageActions]
        let actionIds = []
        actions.forEach((action) => {
            if (action.package > numberOfPackages-1) {
                actionIds.push(action.actionId)
            }
        })
        this.props.deleteActions(actionIds)

        // const newMaxPackages = numberOfPackages
        this.setState({
            tabIndex: index===0?0:index>numberOfPackages-1?numberOfPackages-1:index,
            packages: numberOfPackages
        })
    }

    handleCopyTab = () => {
        const newMaxPackages = this.state.packages + 1
        const index = this.state.tabIndex
        const actionsToCopy = this.props.packageActions.filter((action) => action.package === index || parseInt(action.package) === index)
        let copiedActions = []
        actionsToCopy.forEach(action => {
            switch (action.type) {
                case COMPONENT_TYPES.BOOLEAN_1:
                    copiedActions.push({
                        ...action,
                        value: action.value,
                        package: newMaxPackages-1
                    })
                    break
                case COMPONENT_TYPES.NUMERICAL_INPUT_2:
                    copiedActions.push({
                        ...action,
                        value1: action.value1,
                        value2: action.value2,
                        package: newMaxPackages-1
                    })
                    break
                case COMPONENT_TYPES.NUMERICAL_INPUT_3:
                    copiedActions.push({
                        ...action,
                        value1: action.value1,
                        value2: action.value2,
                        value3: action.value3,
                        package: newMaxPackages-1
                    })
                    break
                default:
                    copiedActions.push({
                        ...action,
                        value: action.value,
                        package: newMaxPackages-1
                    })
            }
        })
        this.props.addActions(copiedActions, true)
        this.setState({
            packages: newMaxPackages
        })
    }

    handleAddTab = () => {
        const newMaxPackages = this.state.packages + 1
        this.setState({
            packages: newMaxPackages
        })
    }

    handleTabChange = (e, index) => {
        this.setState({
            tabIndex: index
        })
    }

    checkPackageIndex = (action) => {
        action.package = action.package === -1? this.state.tabIndex : action.package
        return action.package === this.state.tabIndex || parseInt(action.package) === this.state.tabIndex
    }

    render() {
        return (
            <>
                <Box
                    className='actionNumericDropdownField'
                    display='flex'
                    flexDirection='column'
                    justifyContent='left'
                    // alignItems='center'
                >
                    <Typography variant="h6" component="div" className="header-package" gutterBottom style={{textAlign: "left"}}>
                        {"Package Rules"}
                        <Tooltip title={"Delete Package"} onClick={this.handleDeleteTab}>
                            <IconButton disabled={this.state.packages < 2} style={{float: 'right'}}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={"Copy Package"} onClick={this.handleCopyTab}>
                            <IconButton disabled={!(this.state.packages < parseInt(this.props.numberOfPackages))} style={{float: 'right'}}>
                                <CopyIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={"Add Package"} onClick={this.handleAddTab}>
                            <IconButton disabled={!(this.state.packages < parseInt(this.props.numberOfPackages))} style={{float: 'right'}}>
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                        <hr/>
                    </Typography>
                    <Tabs
                        value={this.state.value}
                        onChange={this.handleTabChange}
                        indicatorColor="none"
                        textColor="none"
                        width={"25%"}
                        variant="scrollable"
                        style={{maxWidth: '710px', marginLeft: '10px'}}
                    >
                        {this.createTabs()}
                    </Tabs>
                    <DndProvider backend={HTML5Backend}>
                        <DragAndDrop
                            cards={this.props.packageActions.filter((action) => {
                                return this.checkPackageIndex(action)})}
                            handleTableDisplayChange={this.props.updateActions}
                            items = {this.props.packageActions.filter((action) => {
                                return this.checkPackageIndex(action)}).map((action, i) => {
                                switch (action.type) {
                                    case COMPONENT_TYPES.BOOLEAN_1:
                                        return ({
                                            id: action.actionId,
                                            component: <div>
                                                <ActionBoolean
                                                    action={action}
                                                    updateActionWithParent={this.props.updateAction}
                                                    deleteAction={this.props.deleteAction}
                                                    isEdit={this.props.isEdit}
                                                    key={action.actionId}
                                                />
                                            </div>
                                        });
                                    case COMPONENT_TYPES.NUMERICAL_DROPDOWN_1:
                                        return ({
                                            id: action.actionId,
                                            component: <div>
                                                <ActionNumericDropdown
                                                    action={action}
                                                    updateActionWithParent={this.props.updateAction}
                                                    deleteAction={this.props.deleteAction}
                                                    isEdit={this.props.isEdit}
                                                    key={action.actionId}
                                                />
                                            </div>
                                        });
                                    case COMPONENT_TYPES.NUMERICAL_INPUT_1:
                                        return ({
                                            id: action.actionId,
                                            component: <div>
                                                <SingleNumericalAction
                                                    action={action}
                                                    updateActionWithParent={this.props.updateAction}
                                                    deleteAction={this.props.deleteAction}
                                                    isEdit={this.props.isEdit}
                                                    key={action.actionId}
                                                />
                                            </div>
                                        });
                                    case COMPONENT_TYPES.NUMERICAL_INPUT_2:
                                        return ({
                                            id: action.actionId,
                                            component:<div>
                                                <DoubleNumericalAction
                                                    action={action}
                                                    updateActionWithParent={this.props.updateAction}
                                                    deleteAction={this.props.deleteAction}
                                                    isEdit={this.props.isEdit}
                                                    key={action.actionId}
                                                />
                                            </div>
                                        });
                                    case COMPONENT_TYPES.NUMERICAL_INPUT_3:
                                        return ({
                                            id: action.actionId,
                                            component:<div>
                                                <TripleNumericalAction
                                                    action={action}
                                                    updateActionWithParent={this.props.updateAction}
                                                    deleteAction={this.props.deleteAction}
                                                    isEdit={this.props.isEdit}
                                                    key={action.actionId}
                                                />
                                            </div>
                                        });
                                    case COMPONENT_TYPES.DROPDOWN_1:
                                        return ({
                                            id: action.actionId,
                                            component:<div>
                                                <ActionDropdown
                                                    action={action}
                                                    name={action.name}
                                                    updateActionWithParent={this.props.updateAction}
                                                    deleteAction={this.props.deleteAction}
                                                    isEdit={this.props.isEdit}
                                                    key={action.actionId}
                                                />
                                            </div>
                                        });
                                    case COMPONENT_TYPES.TEXT_INPUT_1:
                                        return ({
                                            id: action.actionId,
                                            component:<div>
                                                <ActionText
                                                    action={action}
                                                    updateActionWithParent={this.props.updateAction}
                                                    deleteAction={this.props.deleteAction}
                                                    isEdit={this.props.isEdit}
                                                    key={action.actionId}
                                                />
                                            </div>
                                        });
                                }
                            })}
                        />
                    </DndProvider>
                </Box>
            </>
        );
    }

}

PackageActions.propTypes = {
    deleteAction: PropTypes.func,
    isEdit: PropTypes.bool,
    packageActions: PropTypes.array,
    deleteActions: PropTypes.func,
    hasPredefinedPackageValue: PropTypes.bool,
    numberOfPackages: PropTypes.number,
    updateAction: PropTypes.func,
    updateActions: PropTypes.func,
    addActions: PropTypes.func
};

PackageActions.defaultProps = {
    deleteAction: ()=>{},
    isEdit: true,
    packageActions: [],
    deleteActions: ()=>{},
    hasPredefinedPackageValue: false,
    numberOfPackages: 1,
    updateAction: ()=>{},
    updateActions: ()=>{},
    addActions: ()=>{}
}

export default PackageActions;