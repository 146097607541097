import React from "react";
import ErrorFeedbackBar from "../../global/ErrorFeedbackBar";
import {CHEQUE_PAGES} from "./constants";
import FeedbackBar from "../../feedbackBar/FeedbackBar";
import withShipment from "../../../withShipment";

import ChequeForm from "./ChequeForm";
import ChequeTable from "./ChequeTable";
import {automationInfoSelector} from "../../../redux/selectors/account";
import {getCompany} from "../../../redux/actions/automationRules";
import {listAllBankAccounts, previewBankFile} from "../../../redux/actions/settings";
import {bankAccountsSelector} from "../../../redux/selectors/settings";
import ChequePreview from "./ChequePreview";
import {
    validateChequeNumber,
    deleteCheques,
    importCheque,
    listAllCheques,
    listTransactionCategories,
    saveCheque,
} from "../../../redux/actions/accounting";
import {chequeSelector, transactionCategoriesSelector,} from "../../../redux/selectors/accounting";
import {statusSelector} from "../../../redux/selectors/global";
import {clearStatus} from "../../../redux/actions/global";
import Papa from 'papaparse';
import {DEFAULT_ROWS_PER_PAGE} from "./constants";
class Cheques extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            statusText: '',
            status: null,
            isEditingCheque:[],
            signatureFeedbackBarOpen: false,
            currentPage: CHEQUE_PAGES.TABLE,
            feedbackBarOpen: false,
            error: false,
            companyAddress:[],
            formState: {
                memoo: '',
                payToName: '',
                chequeNumber: '',
                paymentDate: null,
                amount: '',
                amountWords: '',
                category: '',
                bankAccount: null,
            },
            transactionCategories:this.props.transactionCategories,
            fromDate: this.getFromDate(),
            toDate: this.getToDate(),
            fetchedList: true,
            selecting: false,
            selectedEntries: {},
            searchText: '',
            page: 0,
            rowsPerPage: DEFAULT_ROWS_PER_PAGE,
        }
    }

    setPage = (page) => {
        this.setState({
            page: page
        })
    }
    handleFormChange = (formState) => {
        this.setState({ formState });
    }
    componentDidMount() {
        this.props.getCompany()
        this.props.listAllBankAccounts()
        this.setState({
            fetchedList: true
        })
        this.props.listTransactionCategories()
    }

    setFetchedList = (fetched) => {
        this.setState({
            fetchedList: fetched
        })
    }
    getFromDate = () => {
        const date = new Date();
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setDate(date.getDate() - 14)
        return date;
    }

    getToDate = () => {
        const date = new Date();
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
        return date;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.status.statusText && prevProps.status.statusText !== this.props.status.statusText){
            this.openFeedbackBar()
        }
        //update state transactionCategories
        if (prevProps.transactionCategories !== this.props.transactionCategories) {
            this.setState({
                transactionCategories : this.props.transactionCategories
            })
        }
        //sort state transactionCategories by displayOrder
        if (prevState.transactionCategories !== this.state.transactionCategories) {
            this.setState({
                transactionCategories : this.state.transactionCategories.sort((a, b) => a.displayOrder - b.displayOrder)
            })
        }




        if(prevProps.automationSettings !== this.props.automationSettings) {
            this.setState({
              companyAddress: this.props?.automationSettings?.companyAddress === undefined ? [] : this.props?.automationSettings?.companyAddress,

            });
        }
    }


    setCurrentPage = (newPage,cheque) => {
        this.setState({
            currentPage: newPage,
            isEditingCheque: cheque
        })
    }

    handleDateChange = (date, key) => {
        this.setState({
            [key]: date
        })
    }
    handleSearchTextChange = (e) => {
        this.setState({
            searchText: e.target.value
        })
    }

    displayTable = () => {
        return (
            <ChequeTable
                importCSV={this.importCSV}
                setCurrentPage={this.setCurrentPage}
                openFeedbackBar={this.openFeedbackBar}
                bankAccounts={this.props.bankAccounts}
                categories={this.state.transactionCategories}
                data={this.props.cheques}
                setEditing={this.setEditing}
                deleteCheques={this.props.deleteCheques}
                checkedEntries={this.state.selectedEntries}
                setSelected={(newEntries, selecting) => this.setState({ selectedEntries: newEntries, selecting: selecting })}
                handleSearchChange={this.handleSearchTextChange}
                searchText={this.state.searchText}
                page={this.state.page}
                setPage={this.setPage}
                rowsPerPage={this.state.rowsPerPage}
                setRowsPerPage={this.setRowsPerPage}
                fetchCheques={this.props.listAllCheques}
                fromDate={this.state.fromDate}
                toDate={this.state.toDate}
                user={this.props.user}
                handleDateChange={this.handleDateChange}
                setFetchedList={this.setFetchedList}
            />
        )
    }

    setEditing(value) {
        this.setState({ editing: value })
    }

    setRowsPerPage = (rowsPerPage) => {
        this.setState({
            rowsPerPage: rowsPerPage
        })
    }



    importCSV = (fromDate, toDate) => {
        // Create an input element dynamically for CSV file upload
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = '.csv';

        // Handle the change event after the file is selected
        fileInput.onchange = (e) => {
            const file = e.target.files[0];
            if (file) {
                Papa.parse(file, {
                    header: true, // CSV file contains header
                    skipEmptyLines: true, // Skip empty rows
                    complete: (result) => {
                        const cheques = result.data.map(row => ({
                            amount: row.Amount,
                            chequeNumber: row.CheckNumber,
                            paymentDate: row.CheckDate,
                            payToName: row.RecipientName,
                            category: row.Category,
                            memo: row.Memo,
                            accountNumber: row.BankAccountName,
                        }));

                        let data = {
                            fromDate: fromDate,
                            toDate:  toDate,
                            cheques: cheques
                        }

                       this.props.importCheque(data)
                    },
                    error: (error) => {
                        console.error('Error parsing CSV:', error);
                    }
                });
            }
        };

        // Trigger the file input dialog
        fileInput.click();
    }

    displayForm = () => {
        return (
            <ChequeForm
                cheque={this.state.isEditingCheque}
                categories={this.state.transactionCategories}
                companyAddress={this.state.companyAddress}
                setCurrentPage={this.setCurrentPage}
                onFormChange={this.handleFormChange}
                saveCheque={this.props.saveCheque}
                validateChequeNumber={this.props.validateChequeNumber}
            />
        )
    }

    displayPreview = () => {
        return (
            <ChequePreview
                companyAddress={this.state.companyAddress}
                setCurrentPage={this.setCurrentPage}
                formState={this.state.formState}
                previewBankFile={this.props.previewBankFile}
                saveCheque={this.props.saveCheque}
                openSignatureFeedbackBar={this.openSignatureFeedbackBar}

            />
        )
    }

    displayCurrentPage = () => {
        switch (this.state.currentPage) {
            case CHEQUE_PAGES.TABLE:
                return this.displayTable()
            case CHEQUE_PAGES.ADD_CHECK:
                return this.displayForm()
            case CHEQUE_PAGES.PREVIEW:
                return this.displayPreview()
            default:
                console.log("Error: current transactions page doesn't exist")
                return <div>We encountered an error. Please refresh the page.</div>
        }
    }

    openFeedbackBar = () => {
        this.setState({feedbackBarOpen: true})
    }

    openSignatureFeedbackBar = () => {
        this.setState({signatureFeedbackBarOpen: true, statusText : "No Signature Found"})
    }

    closeSignatureFeedbackBar = () => {
        this.setState({ signatureFeedbackBarOpen: false }, () => {
            this.props.clearStatus()
        })
    }

    closeFeedbackBar = () => {
        this.setState({ feedbackBarOpen: false }, () => {
            this.props.clearStatus()
        })
    }

    render() {
        return (
            <React.Fragment>
                <ErrorFeedbackBar/>
                <FeedbackBar
                    open={this.state.feedbackBarOpen}
                    handleClose={this.closeFeedbackBar}
                    severity={this.props.status?.success ? 'success' : 'error'}
                    message={this.props.status?.statusText}
                />
                <FeedbackBar
                    open={this.state.signatureFeedbackBarOpen}
                    handleClose={this.closeSignatureFeedbackBar}
                    severity={this.state.status ? 'success' : 'error'}
                     message={this.state.statusText}
                />
                {this.displayCurrentPage()}
            </React.Fragment>
        )
    }
}

Cheques.defaultProps = {

}

const mapStateToProps = (state) => ({
    status: statusSelector(state),
    automationSettings: automationInfoSelector(state),
    cheques: chequeSelector(state),
    bankAccounts: bankAccountsSelector(state),
    transactionCategories: transactionCategoriesSelector(state),
})

const actionCreators = {
    clearStatus,
    getCompany,
    listAllBankAccounts,
    saveCheque,
    previewBankFile,
    listAllCheques,
    listTransactionCategories,
    importCheque,
    deleteCheques,
    validateChequeNumber
}

export default withShipment({
    actionCreators,
    mapStateToProps
}, Cheques);