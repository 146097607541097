import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import React from 'react';

// import styles 
import "./CompactTableSwitch.css";

// import constants
import PropTypes from "prop-types";
import AutomationRuleDialog from "./AutomationRuleDialog";

/**
 * A toggle switch that changes the table rows from comfortable to compact view and vice versa
 */
class CompactTableSiwtch extends React.PureComponent {
  
    constructor(props) {
      super(props);      
    }
  
    render() {
      return (
        <div className="compactTableSwitch">
          <FormControlLabel 
            control={<Switch checked={this.props.checked} onChange={this.props.onChange} />}
            label={this.props.label}
            />
        </div>
      );
    }
  }

CompactTableSiwtch.propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func
}

CompactTableSiwtch.defaultProps = {
    checked: false,
    onChange: ()=>{}
}

  export default CompactTableSiwtch