export const REQUEST_STATUS = 'REQUEST_STATUS';
export const SET_LOADING_STATUS = 'SET_LOADING_STATUS';
export const SET_PRICING_LOADING_STATUS = 'SET_PRICING_LOADING_STATUS';
export const SET_SAVING_STATUS = 'SET_SAVING_STATUS';
export const SET_OPEN = 'SET_OPEN';
export const SET_SAVING_OPEN = 'SET_SAVING_OPEN';
export const CLEAR_STATUS = 'CLEAR_STATUS';
export const SET_CREATE_ORDER_OPEN = 'SET_CREATE_ORDER_OPEN';
export const SET_CREATE_RETURN_OPEN = 'SET_CREATE_RETURN_OPEN';
export const SET_CREATE_CLONED_OPEN = 'SET_CREATE_CLONED_OPEN';
export const SET_VOID_OPEN = 'SET_VOID_OPEN';
export const SET_VOID_STATUS = 'SET_VOID_STATUS';
export const SET_ARCHIVE_OPEN = 'SET_ARCHIVE_OPEN';
export const SET_ARCHIVE_STATUS = 'SET_ARCHIVE_STATUS';
export const HTTP_SUCCESS = 200;

export const SHOW_MENU_ITEMS = "SHOW_MENU_ITEMS";
export const HIDE_MENU_ITEMS = "HIDE_MENU_ITEMS";

export const SHOW_ERROR_MESSAGE = "SHOW_ERROR_MESSAGE";
export const HIDE_ERROR_MESSAGE = "HIDE_ERROR_MESSAGE";

export const GET_STATE_AND_PROVINCE_MAPPING = "GET_STATE_AND_PROVINCE_MAPPING";
export const STORE_STATE_AND_PROVINCE_MAPPING = "STORE_STATE_AND_PROVINCE_MAPPING";
