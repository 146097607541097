import React from "react";
import {Button, Paper, ClickAwayListener, TextField} from "@material-ui/core";
import "./Transactions.css"
import Autocomplete from "@material-ui/lab/Autocomplete";
import PropTypes from "prop-types";
import withShipment from "../../../withShipment";
import InputAdornment from "@material-ui/core/InputAdornment";
import {NA_PROVINCES, TRANSACTION_TAX_TYPES} from "./constants";
import {stateAndProvinceMappingSelector, statusSelector} from "../../../redux/selectors/global";
import {taxTypewithStateProvinceSelector,} from "../../../redux/selectors/accounting";

class AddTaxInformationPopper extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
/*
            taxAmount: "",
*/
            province: "",
            taxType: ""
        }
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    handleClose = () => {
        this.props.handleClosePopper()
    }

    handleSave = () => {
        this.props.callback({
           /* taxAmount: this.state.taxAmount,*/
            province: this.state.province,
            taxType: this.state.taxType
        })
        this.props.handleClosePopper()
    }

    formatDollar = (amount, forceTwoDecimals) => { // Regex expression taken from https://stackoverflow.com/questions/2901102
        if(!amount) return
        let value
        if (forceTwoDecimals) {
            value = this.parseNumber(amount).toFixed(2)
        } else {
            // if the amount has more than two decimal places, limit it to two
            if (amount.match(/.*\.\d{2}.*/)) {
                try {
                    value = this.parseNumber(amount).toFixed(2)
                } catch {
                    value = amount.replaceAll(',','')
                }
            } else {
                value = amount.replaceAll(',','')
            }
        }

        return value.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }

    render() {
        return (
            <>
                <ClickAwayListener onClickAway={this.handleClose}>
                    <Paper className="import-popper-container">
                        <div className="import-popper-item">
                            <Autocomplete
                                options={this.props.stateAndProvinceMapping.map((item) => `${item.state} (${item.displayName})`)}
                                value={this.state.province ? `${this.state.province}` : ''}
                                style={{ marginTop: '7px' }}
                                onChange={(event, value) => {
                                    // Extract the state code from the value.
                                    const newValue = value?.split(' ')[0];
                                    this.handleChange("province", newValue);
                                }}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        name="province"
                                        label="Province"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                }
                            />
                            <Autocomplete
                                options={
                                    this.props.taxTypewithStateProvince[this.state.province] ?
                                        this.props.taxTypewithStateProvince[this.state.province] :
                                        TRANSACTION_TAX_TYPES
                                }
                                value={this.state.taxType}
                                style={{ marginTop: '7px' }}
                                onChange={(e, value) => this.handleChange("taxType", value)}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        name="taxType"
                                        label="Tax Type"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                }
                            />
                        </div>
                        <div className="supplier-payment-popper-buttons">
                            <Button variant='contained' onClick={this.handleClose} style={{width: '100px'}}>Close</Button>
                            <Button
                                variant='contained'
                                onClick={this.handleSave}
                                color="primary"
                                style={{width: '100px'}}
                            >Save</Button>
                        </div>
                    </Paper>
                </ClickAwayListener>
            </>
        )
    }
}

AddTaxInformationPopper.propTypes = {
    bankAccounts: PropTypes.array
}

AddTaxInformationPopper.defaultProps = {
    bankAccounts: [],
}
const mapStateToProps = (state) => ({
    stateAndProvinceMapping: stateAndProvinceMappingSelector(state),
    taxTypewithStateProvince: taxTypewithStateProvinceSelector(state)
})

export default withShipment({
    mapStateToProps
}, AddTaxInformationPopper);