import {
    GET_USER_SETTING, STORE_USER_SETTING, STORE_USER_TICKET_FILTER,
} from "../../constants/account";

const initialState = {
    userTableSetting: {},
    userTableFilter: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case STORE_USER_SETTING:
            return {
                ...state,
                userTableSetting: action.userTableSetting
            }
        case STORE_USER_TICKET_FILTER:
            return {
                ...state,
                userTableFilter: action.userTableFilter
            }
        default:
            return state
    }
};
