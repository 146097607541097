import axios from "axios";
import {call, put, takeLatest} from "redux-saga/effects";
import {clearStatus, hideError, requestStatus, showErrorMessage} from "../../actions/global";
import {FULL_TRUCKLOAD} from "../../../components/global/Server/endpoints";
import {SERVER_FAILED_ERROR_MESSAGE} from "../../../pages/Dashboard/DashboardFinal/constants";
import {
    FETCH_FULL_TRUCKLOAD_SETTINGS,
    FETCH_FULL_TRUCKLOADS, LIST_FULL_TRUCKLOAD_BROKERS,
    LIST_FULL_TRUCKLOAD_CARRIERS,
    PREVIEW_FULL_TRUCKLOAD_FILE,
    PREVIEW_FULL_TRUCKLOAD_DOCUMENT,
    SAVE_FULL_TRUCKLOAD,
    SAVE_FULL_TRUCKLOAD_SETTINGS,
    UPDATE_FULL_TRUCKLOAD, UPLOAD_SIGNED_DOCUMENTS
} from "../../constants/fullTruckload";
import {
    storeFullTruckloadBrokers,
    storeFullTruckloadCarriers, storeFullTruckloadOperationsResponse,
    storeFullTruckloads,
    storeFullTruckloadSettings, storeFullTruckloadUnavailableItems,
    storeUpdatedFullTruckloadSettingsWithFile, storeUpdatedFullTruckloadWithFile
} from "../../actions/fullTruckload";
import {HTTP_SUCCESS} from "../../constants/global";
import {
    APPROVE_FTL, FTL_APPROVED_MESSAGE,
    FTL_MARKED_AS_COMPLETE_MESSAGE,
    FTL_MARKED_AS_DRAFT_MESSAGE,
    FTL_MARKED_AS_INCOMPLETE_MESSAGE,
    FTL_MARKED_AS_SHIPPED_MESSAGE,
    FTL_MARKED_AS_UNSHIPPED_MESSAGE,
    FTL_SAVED_SUCCESSFUL_MESSAGE,
    FTL_UNAPPROVED_MESSAGE,
    FTL_UPDATED_SUCCESSFUL_MESSAGE,
    MARK_DRAFT_FTL,
    MARK_FTL_COMPLETE,
    MARK_FTL_INCOMPLETE,
    MARK_FTL_SHIPPED,
    MARK_FTL_UNSHIPPED,
    UNAPPROVE_FTL,
} from "../../../components/fullTruckload/constants";

const getHeaders = () => {
    const token = localStorage.getItem('token');
    return {
        "Authorization": `Bearer ${token}`
    }
}

const getRequest = (endpoint) => {
    return axios.get(endpoint, {
        headers: getHeaders()
    })
        .then(response => response);
}

const postRequest = (endpoint, data) => {
    return axios.post(endpoint, data, {
        headers: getHeaders()
    })
        .then(response => response)
}

function* saveFullTruckload(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(FULL_TRUCKLOAD.SAVE_FULL_TRUCKLOAD, {data : action.data, newSettings: action.newSettings}))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === 200
        };
        if (response.data.success) {
            yield put(storeFullTruckloadOperationsResponse(FTL_SAVED_SUCCESSFUL_MESSAGE))
            yield action.reset()
        }
        yield put(requestStatus(status))
        yield put(storeFullTruckloadUnavailableItems(response.data?.unavailableItems))
    }
    catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* updateFullTruckload(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(FULL_TRUCKLOAD.UPDATE_FULL_TRUCKLOAD, {data : action.data, newSettings: action.newSettings}))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === 200
        };
        if (response.data.success) {
            // if there is a change of status
            if (action.operationName !== undefined) {
                switch (action.operationName) {
                    case MARK_DRAFT_FTL:
                        yield put(storeFullTruckloadOperationsResponse(FTL_MARKED_AS_DRAFT_MESSAGE))
                        break
                    case APPROVE_FTL:
                        yield put(storeFullTruckloadOperationsResponse(FTL_APPROVED_MESSAGE))
                        break
                    case UNAPPROVE_FTL:
                        yield put(storeFullTruckloadOperationsResponse(FTL_UNAPPROVED_MESSAGE))
                        break
                    case MARK_FTL_UNSHIPPED:
                        yield put(storeFullTruckloadOperationsResponse(FTL_MARKED_AS_UNSHIPPED_MESSAGE))
                        break
                    case MARK_FTL_SHIPPED:
                        yield put(storeFullTruckloadOperationsResponse(FTL_MARKED_AS_SHIPPED_MESSAGE))
                        break
                    case MARK_FTL_INCOMPLETE:
                        yield put(storeFullTruckloadOperationsResponse(FTL_MARKED_AS_INCOMPLETE_MESSAGE))
                        break
                    case MARK_FTL_COMPLETE:
                        yield put(storeFullTruckloadOperationsResponse(FTL_MARKED_AS_COMPLETE_MESSAGE))
                        break
                }
            }
            // normal update response message
            else {
                yield put(storeFullTruckloadOperationsResponse(FTL_UPDATED_SUCCESSFUL_MESSAGE))
            }
            yield action.reset()
        }
        yield put(storeFullTruckloadUnavailableItems(response.data?.unavailableItems))

        yield put(requestStatus(status))
    }
    catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* uploadSignedDocuments(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(FULL_TRUCKLOAD.UPLOAD_SIGNED_DOCUMENTS, action.data))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === 200
        };
        if (response.data.success) {
            yield action.reset()
        }
        yield put(requestStatus(status))
    }
    catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* fetchFullTruckloads() {
    yield put(hideError())
    try {
        const response = yield call(() => getRequest(FULL_TRUCKLOAD.FETCH_FULL_TRUCKLOADS))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === 200
        };
        if (response.data.success) {
            yield put(storeFullTruckloads(response.data.fullTruckloads))
        }
        yield put(requestStatus(status))
    }
    catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* saveFullTruckloadSettings(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(FULL_TRUCKLOAD.SAVE_FULL_TRUCKLOAD_SETTINGS, action.data))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === 200
        };
        if (response.data.success) {
            yield put(storeFullTruckloadSettings(action.data))
            yield action.reset()
        }
        yield put(requestStatus(status))
    }
    catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* fetchFullTruckloadSettings() {
    yield put(hideError())
    try {
        const response = yield call(() => getRequest(FULL_TRUCKLOAD.FETCH_FULL_TRUCKLOAD_SETTINGS))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === 200
        };
        if (response.data.success) {
            yield put(storeFullTruckloadSettings(response.data.fullTruckloadSettings))
        }
        yield put(requestStatus(status))
    }
    catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* previewFullTruckloadAttachmentSaga(action) {
    yield call(clearStatus)
    yield put(hideError());
    try {
        const response = yield call(() => postRequest(FULL_TRUCKLOAD.PREVIEW_FULL_TRUCKLOAD_FILE, action.previewRequest))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        if (response.data.success) {
            yield put(storeUpdatedFullTruckloadSettingsWithFile(response.data.fullTruckloadSettings, response.data.index))
        }
        yield put(requestStatus(status))
    }
    catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* previewFullTruckloadDocumentSaga(action) {
    yield call(clearStatus)
    yield put(hideError());
    try {
        const response = yield call(() => postRequest(FULL_TRUCKLOAD.PREVIEW_FULL_TRUCKLOAD_DOCUMENT, action.previewRequest))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        if (response.data.success) {
            yield put(storeUpdatedFullTruckloadWithFile(response.data.previewFile))
        }
        yield put(requestStatus(status))
    }
    catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* listFullTruckloadCarriers() {
    yield put(hideError())
    try {
        const response = yield call(() => getRequest(FULL_TRUCKLOAD.LIST_FULL_TRUCKLOAD_CARRIERS))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === 200
        };
        if (response.data.success) {
            yield put(storeFullTruckloadCarriers(response.data.fullTruckloadCarriers))
        }
            yield put(requestStatus(status))
    }
    catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* listFullTruckloadBrokers() {
    yield put(hideError())
    try {
        const response = yield call(() => getRequest(FULL_TRUCKLOAD.LIST_FULL_TRUCKLOAD_BROKERS))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === 200
        };
        if (response.data.success) {
            yield put(storeFullTruckloadBrokers(response.data.fullTruckloadBrokers))
        }
        yield put(requestStatus(status))
    }
    catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

export default function* fullTruckloadSagas() {
    yield takeLatest(SAVE_FULL_TRUCKLOAD, saveFullTruckload);
    yield takeLatest(FETCH_FULL_TRUCKLOADS, fetchFullTruckloads)
    yield takeLatest(UPDATE_FULL_TRUCKLOAD, updateFullTruckload)
    yield takeLatest(UPLOAD_SIGNED_DOCUMENTS, uploadSignedDocuments)
    yield takeLatest(SAVE_FULL_TRUCKLOAD_SETTINGS, saveFullTruckloadSettings)
    yield takeLatest(FETCH_FULL_TRUCKLOAD_SETTINGS, fetchFullTruckloadSettings)
    yield takeLatest(PREVIEW_FULL_TRUCKLOAD_FILE, previewFullTruckloadAttachmentSaga)
    yield takeLatest(PREVIEW_FULL_TRUCKLOAD_DOCUMENT, previewFullTruckloadDocumentSaga)
    yield takeLatest(LIST_FULL_TRUCKLOAD_CARRIERS, listFullTruckloadCarriers)
    yield takeLatest(LIST_FULL_TRUCKLOAD_BROKERS, listFullTruckloadBrokers)
}