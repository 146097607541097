import React from 'react'
import PropTypes from "prop-types";
import DeleteIcon from "@material-ui/icons/Delete";
import { IconButton, TableCell } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Box from '@material-ui/core/Box'


class DeleteProduct extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {}
    }

    handleDelete = () => {
        const data = {
            productId: this.props.product.productId
        }
        this.props.delete(data)
        this.props.handleDeleteFalse(this.props.index)
    }

    render() {
        return (
            <TableCell
                padding="default"
                className='cellPadding'
                width="20%"
            >
                {this.props.isDelete ?
                    <div align="left">
                        <Button
                            onClick={() => this.props.handleDeleteFalse(this.props.index)}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            startIcon={<DeleteIcon />}
                            onClick={this.handleDelete}
                        >
                            Delete
                        </Button>
                    </div>
                    :
                    <div>
                        <IconButton onClick={() => this.props.handleDeleteTrue(this.props.index)}>
                            <DeleteIcon />
                        </IconButton>
                    </div>


                }
            </TableCell>

        )
    }
}

DeleteProduct.propTypes = {
    product: PropTypes.object,
    deleteProduct: PropTypes.func,
    handleDeleteTrue: PropTypes.func,
    handleDeleteFalse: PropTypes.func,
}

DeleteProduct.defaultProps = {
    product: {},
    deleteProduct: () => { },
    handleDeleteTrue: () => { },
    handleDeleteFalse: PropTypes.func,
}

export default DeleteProduct