import React from "react";
import withShipment from "../../../../withShipment";
import './ListingsTable.css';
import TopToolbar from "../../../global/subcomponents/topToolbar/TopToolbar";
import {EDIT_ENTRY, LISTING_TITLE, LISTINGS_PAGES, LISTINGS_ROWS_PER_PAGE_LABEL, SKU_FILTER_NAMES} from "../constants";
import TablePage from "../../../global/Table/TablePage";
import {Layout} from "../../../global/Table/TablePageHelpers/LayoutObject";
import ListingsTableRow from "../ListingsComponents/ListingsTableRow";
import {Filter} from "../../../global/Table/TablePageHelpers/FilterObject";
import RefreshIcon from "@material-ui/icons/Refresh";
import SettingsIcon from "@material-ui/icons/Settings";
import InfoIcon from '@material-ui/icons/Info';
import {autoApplySelector, availableLocationsSelector, listingsSelector, marketplacesSelector, shippingTemplatesSelector} from "../../../../redux/selectors/settings";
import {fetchAmazonSkus, fetchListings, listAvailableLocations, listShippingTemplates,} from "../../../../redux/actions/settings";
import {loadingSelector} from "../../../../redux/selectors/global";
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CategoryIcon from '@material-ui/icons/Category';
import SetSkuLocationPopper from "./SetSkuLocationPopper";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import {userInfoSelector} from "../../../../redux/selectors/auth";
import {manufacturersSelector} from "../../../../redux/selectors/inventory";
class ListingsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableEvents: [],

            marketplaceOptions: this.props.marketplaces ? this.props.marketplaces.map(marketplace => marketplace.marketplaceName) : [],
            brandOptions: this.props.manufacturers ? this.props.manufacturers : [],
            listingIdBeingEdited: "",
            selectAllChecked: false,
            selectedEntries: [],
            isSelecting: false,
        }
    }

    componentDidMount(){
        this.props.listAvailableLocations()
        this.props.listShippingTemplates({id: this.props.userInfo.company})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.marketplaces !== this.props.marketplaces && this.props.marketplaces) {

            let newMarketplaceOptions

            newMarketplaceOptions = this.props.marketplaces.map(marketplace => {
                return marketplace.marketplaceName
            })
            this.setState({
                marketplaceOptions: newMarketplaceOptions
            })
        }
        if (prevProps.manufacturers !== this.props.manufacturers && this.props.manufacturers) {

            let newbrandOptions

            newbrandOptions = this.props.manufacturers.map(manufacturers => {
                return manufacturers
            })
            this.setState({
                brandOptions: newbrandOptions
            })
        }

        if (this.props.isLoading) {
            this.props.setCurrentPage(LISTINGS_PAGES.LOADING)
        }

    }

    setOtherSkuFiltersFalse = (filterName, setTo) => {
        if (setTo === false) {
            return
        }

        let newTableEvents = []
        if (filterName !== SKU_FILTER_NAMES.ALL_SKUS) {newTableEvents.push(Filter.filterUpdateEvent(SKU_FILTER_NAMES.ALL_SKUS, false))}
        if (filterName !== SKU_FILTER_NAMES.ACTIVE_SKUS) {newTableEvents.push(Filter.filterUpdateEvent(SKU_FILTER_NAMES.ACTIVE_SKUS, false))}
        if (filterName !== SKU_FILTER_NAMES.INACTIVE_SKUS) {newTableEvents.push(Filter.filterUpdateEvent(SKU_FILTER_NAMES.INACTIVE_SKUS, false))}
        if (filterName !== SKU_FILTER_NAMES.UNMAPPED_SKUS) {newTableEvents.push(Filter.filterUpdateEvent(SKU_FILTER_NAMES.UNMAPPED_SKUS, false))}
        if (filterName !== SKU_FILTER_NAMES.DELETED_SKUS) {newTableEvents.push(Filter.filterUpdateEvent(SKU_FILTER_NAMES.DELETED_SKUS, false))}

        this.setState({
            tableEvents: newTableEvents
        })
    }

    isListingUnmapped = (row) => {
        return (
            row.quantity === null ||
            row.shortcode === "" ||
            row.shortcode === null ||
            row.quantity === 0 ||
            row.quantity === "" ||
            row.quantity === "0" ||
            row.barcode === [] ||
            row.barcode === null ||
            row.barcode?.length === 0
        )
    }

    getTableColumnHeads = () => {
        let headers = []
        headers.push(Layout.columnHead(null, 'selectAllCheckbox', null))
        headers.push(Layout.columnHead("SKU", "sku", "Left"))
        headers.push(Layout.columnHead("ASIN", "asin", "Left"))
        headers.push(Layout.columnHead("Shortcode", "shortcode", "Left"))
        headers.push(Layout.columnHead("Quantity", "quantity", "Left"))

        const locationHeaderWithInfo = (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                Location
                <Tooltip title="Assigning a location indicates that the corresponding SKU belongs to that location. Inventory level for this location is used to manage inventory quantity on the marketplace when automatic inventory allocation is enabled.">
                    <InfoIcon style={{ marginLeft: 5, fontSize: 'small',color: 'gray' }} />
                </Tooltip>
            </div>
        );
        headers.push(Layout.columnHead(locationHeaderWithInfo, "shipAddressId", "Left"));
        headers.push(Layout.columnHead("Marketplace", "marketplaces", "Left"))
        headers.push(Layout.columnHead("Shipping Template", "shippingTemplate", "Left"))
        headers.push(Layout.columnHead("Handling Time", "handlingTime", "Left"))
        headers.push(Layout.columnHead("Delisted SKU", "isDeleted", "Left"))
        return headers;
    }

    handleSelectAllListing = (displayedTableRows) => {
        const { selectedEntries } = this.state

        const allSkus = displayedTableRows.map((row) => row.webstoreProductId)
        const missingSkus = allSkus.filter((webstoreProductId) => !selectedEntries.includes(webstoreProductId))

        let updatedSelectedEntries = []
        if (missingSkus.length > 0) {
            updatedSelectedEntries = [...selectedEntries, ...missingSkus]
        }

        const isSelecting = updatedSelectedEntries.length > 0

        this.setState({ selectedEntries: updatedSelectedEntries, isSelecting })
    }

    handleSelectListing = (webstoreProductId) => {
        this.setState(prevState => {
            const selectedEntries = new Set(prevState.selectedEntries);
            if (selectedEntries.has(webstoreProductId)) {
                selectedEntries.delete(webstoreProductId);
            } else {
                selectedEntries.add(webstoreProductId);
            }

            return {
                selectedEntries: Array.from(selectedEntries),
                isSelecting: selectedEntries.size > 0
            };
        });
    };

    getLocationName = (shipAddressId) => {
        const matchedLocation = this.props.availableLocations.find(loc => loc.shipAddressId === shipAddressId);
        return matchedLocation ? matchedLocation.name : "";
    }
    getTableRow = (row, getRowState, setRowState) => {
        return <ListingsTableRow
            row={row}
            availableShippingTemplates = {this.props.availableShippingTemplates}
            matchLocations={this.getLocationName}
            selectedEntries={this.state.selectedEntries}
            handleSelectListing={this.handleSelectListing}
            getRowState={getRowState}
            setRowState={setRowState}
            listingIdBeingEdited={this.state.listingIdBeingEdited}
            setListingIdBeingEdited={(newId) => this.setState({listingIdBeingEdited: newId})}
        />
    }

    handlePopperClose = () => {
        this.setState({
            selectedEntries: [],
            selectAllChecked: false,
        });
    };

    getSearchStrings = (row) => {
        let searchStrings = []
        if (row.sku)
            searchStrings.push(row.sku)
        if (row.quantity)
            searchStrings.push(row.quantity)
        if (row.shortcodeQuantity)
            searchStrings.push(row.shortcodeQuantity)
        if (row.asin)
            searchStrings.push(row.asin)
        if (row.brand)
            searchStrings.push(row.brand)

        return searchStrings
    }

    render() {
        const selectInfo = `${this.state.selectedEntries.length} selected`
        let menuItems = [
            {title: selectInfo, isNotIcon: true},
            {title: "Set Location", icon: <CategoryIcon/>, onClick: () => {},
                popper: <SetSkuLocationPopper
                    locations={this.props.availableLocations}
                    skus={this.state.selectedEntries}
                    handlePopperClose={this.handlePopperClose}
                />
            },

        ]

        if (this.state.selectedEntries.length === 1) {
            menuItems.splice(1, 0, {title: EDIT_ENTRY, icon: <EditIcon/>, onClick: () => {
                    this.setState({ listingIdBeingEdited: this.state.selectedEntries[0] });
                }});
        }

        return (
            <React.Fragment>
                {this.state.selectedEntries.length > 0 ?
                    <TopToolbar
                        pageName={LISTING_TITLE}
                        menuItems={menuItems}
                    />
                    :
                    <TopToolbar
                        pageName={LISTING_TITLE}
                        menuItems={[
                            {title: "Refresh Skus", icon: <RefreshIcon/>, onClick: () => {this.props.fetchAmazonSkus()}},
                            {title: "Settings", icon: <SettingsIcon/>, onClick: () => {this.props.setCurrentPage(LISTINGS_PAGES.SETTINGS)}},
                        ]}
                    />
                }
                <TablePage
                    tableName="ListingsTable"

                    eventsVariable={this.state.tableEvents}
                    setEventsVariable={(newState) => {this.setState({tableEvents: newState})}}

                    tableColumnHeads={this.getTableColumnHeads()}
                    tableRows={this.props.tableRows}
                    rowFetcher={(filters) => {
                        this.props.fetchListings()
                    }}
                    handleSelectAll={this.handleSelectAllListing}
                    numSelectedEntries={this.state.selectedEntries.length}

                    tableRow={(row, index, getRowState, setRowState) => this.getTableRow(row, getRowState, setRowState)}
                    getRowId={(row) => row.webstoreProductId}
                    onRowDoubleClick={(row) => this.setState({listingIdBeingEdited: row.webstoreProductId})}

                    paginationText={LISTINGS_ROWS_PER_PAGE_LABEL}

                    hasSearchField
                    searchStrings={this.getSearchStrings}

                    filters={[
                        Filter.createFilter("marketplace", (row) => row.marketplaces, null, Filter.relations.containsMemberOf, true),
                        Filter.createFilter("brand", (row) => row.brand, null, Filter.relations.containedBy, true),
                        Filter.createFilter(SKU_FILTER_NAMES.ALL_SKUS, (row) => true, true, Filter.relations.equals, false),
                        Filter.createFilter(SKU_FILTER_NAMES.ACTIVE_SKUS, (row) => !row.isInactive, false, Filter.relations.equals, false),
                        Filter.createFilter(SKU_FILTER_NAMES.INACTIVE_SKUS, (row) => row.isInactive, false, Filter.relations.equals, false),
                        Filter.createFilter(SKU_FILTER_NAMES.UNMAPPED_SKUS, this.isListingUnmapped, false, Filter.relations.equals, false),
                        Filter.createFilter(SKU_FILTER_NAMES.DELETED_SKUS, (row) => row.isDeleted, false, Filter.relations.equals, false),
                    ]}

                    filterCheckboxes={[
                        Layout.checkBox(SKU_FILTER_NAMES.ALL_SKUS, "All Skus", <CircleOutlinedIcon/>, <CircleIcon/>, (setTo) => this.setOtherSkuFiltersFalse(SKU_FILTER_NAMES.ALL_SKUS, setTo)),
                        Layout.checkBox(SKU_FILTER_NAMES.ACTIVE_SKUS, "Active Skus", <CircleOutlinedIcon/>, <CircleIcon/>, (setTo) => this.setOtherSkuFiltersFalse(SKU_FILTER_NAMES.ACTIVE_SKUS, setTo)),
                        Layout.checkBox(SKU_FILTER_NAMES.INACTIVE_SKUS, "Inactive Skus", <CircleOutlinedIcon/>, <CircleIcon/>, (setTo) => this.setOtherSkuFiltersFalse(SKU_FILTER_NAMES.INACTIVE_SKUS, setTo)),
                        Layout.checkBox(SKU_FILTER_NAMES.UNMAPPED_SKUS, "Unmapped Skus", <CircleOutlinedIcon/>, <CircleIcon/>, (setTo) => this.setOtherSkuFiltersFalse(SKU_FILTER_NAMES.UNMAPPED_SKUS, setTo)),
                        Layout.checkBox(SKU_FILTER_NAMES.DELETED_SKUS, "Delisted Skus", <CircleOutlinedIcon/>, <CircleIcon/>, (setTo) => this.setOtherSkuFiltersFalse(SKU_FILTER_NAMES.DELETED_SKUS, setTo)),
                    ]}

                    filterDropdown={Layout.newLayout(1, [
                        Layout.row([
                            Layout.rowElement(1, "marketplace", "Marketplace", Layout.elementStyle.checkboxDropdown, this.state.marketplaceOptions)
                        ]),
                        Layout.row([
                            Layout.rowElement(1, "brand", "Brand", Layout.elementStyle.checkboxDropdown, this.state.brandOptions)
                        ])
                    ])}
                />

            </React.Fragment>
        )
    }
}

ListingsTable.defaultProps = {
    tableRows: [],
    availableLocations: [],
    manufacturers: [],
}

ListingsTable.propTypes = {
    availableLocations: PropTypes.array,
    manufacturers: PropTypes.array,
}

const mapStateToProps = (state) => ({
    availableLocations: availableLocationsSelector(state),
    tableRows: listingsSelector(state),
    marketplaces: marketplacesSelector(state),
    isLoading: loadingSelector(state),
    availableShippingTemplates: shippingTemplatesSelector(state),
    userInfo: userInfoSelector(state),
    manufacturers: manufacturersSelector(state),
})

const actionCreators = {
    fetchListings,
    fetchAmazonSkus,
    listAvailableLocations,
    listShippingTemplates,
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, ListingsTable);