import React, { useState } from "react";

// material components
import { Toolbar } from "@material-ui/core";
import { IconButton, Tooltip, Typography } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import NotificationsIcon from '@material-ui/icons/Notifications';

// constants
import { CREATE_MANUAL_INVENTORY_TITLE, INVENTORY, SET_INVENTORY_THRESHOLD } from "./constants";

// import styling
import "../global/TableToolbar.css";
import "./InventoryToolbar.css";
import PropTypes from "prop-types";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

class InventoryToolbar extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="toolbar">
                <Toolbar>
                    <div className="contextualMenu">
                        {(!this.props.creatingManualInventory && !this.props.settingInventoryThreshold) ?
                            <>
                                <Tooltip title={CREATE_MANUAL_INVENTORY_TITLE}>
                                    <IconButton aria-label={CREATE_MANUAL_INVENTORY_TITLE}
                                        onClick={this.props.createManualInventory}>
                                        <AddCircleIcon />
                                    </IconButton>
                                </Tooltip>
                                <div>
                                    <Tooltip title={SET_INVENTORY_THRESHOLD}>
                                        <IconButton aria-label={SET_INVENTORY_THRESHOLD}
                                            onClick={this.props.setInventoryThreshold}>
                                            <NotificationsIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </>
                            :
                            <div className="contextualMenu">
                                <Tooltip title={"Back to menu"}>
                                    <IconButton aria-label={"Back to menu"} onClick={() => { this.props.handleClose() }} >
                                        <KeyboardBackspaceIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        }
                    </div>
                    <Typography className="titleTypography" variant="h6" id="tableTitle">
                        {this.props.title}
                    </Typography>
                </Toolbar>
            </div>

        )
    }
}

InventoryToolbar.propTypes = {
    title: PropTypes.string,
    createManualInventory: PropTypes.func,
    setInventoryThreshold: PropTypes.func,
    creatingManualInventory: PropTypes.bool,
    settingInventoryThreshold: PropTypes.bool,
    handleClose: PropTypes.func
}

InventoryToolbar.defaultProps = {
    title: "",
    createManualInventory: () => { },
    setInventoryThreshold: () => { },
    creatingManualInventory: false,
    settingInventoryThreshold: false,
    handleClose: () => { }
}

export default InventoryToolbar;