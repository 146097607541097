import React from 'react';

import ManageUsersTableHead from "./ManageUsersTableHead";
import SearchableTable from "../../global/Search/SearchableTable";
import ManageExpandEdit from "./ManageExpandEdit";
import RegisterNewUser from "./RegisterNewUser";

// styling
import './ManageUsersTable.css';

// material components
import {
    Button,
    Collapse,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer, TablePagination,
    TableRow,
    TextField,
    Tooltip, Typography
} from "@material-ui/core";

// material icons
import EditIcon from '@material-ui/icons/Edit';

// constants
import {
    ACTIONS, DEFAULT_ROWS_PER_PAGE,
    EDIT_MESSAGE,
    MANAGE_USERS_TITLE, MANAGE_USERS_TOOLBAR_TITLE,
    REGISTER_DIALOG_TITLE, ROWS_PER_PAGE_LABEL,
    USER_MANAGEMENT_HEAD_CELLS,
    DEFAULT_SORT_BY_HEAD_CELL, SUCCESS, ERROR, SAVE_SUCCESS_MESSAGE, ERROR_MESSAGE
} from "./constants";
import { ASC, DESC } from "../../global/constants";
import SearchField from "../../global/Search/SearchField";

// redux
import withShipment from "../../../withShipment";
import {
    linkedUsersSelector,
    rolesSelector
} from "../../../redux/selectors/userManagement";
import {
    userInfoSelector
} from "../../../redux/selectors/auth";
import {
    statusSelector,
    loadingSelector,
    openSelector
} from "../../../redux/selectors/global";
import {
    fetchLinkedUsers,
    editUser,
    deleteUser,
    getRoles
} from "../../../redux/actions/userManagement";
import {
    listLocations
} from "../../../redux/actions/settings";
import {
    locationsSelector
} from "../../../redux/selectors/settings";
import {
    registerUser
} from "../../../redux/actions/auth";
import { fetchAlerts } from "../../../redux/actions/alerts";
import SettingsToolbar from "../SettingsToolbar";
import { TOOLBAR_TITLE } from "../constants";
import PropTypes from "prop-types";
import CustomTableHeader from "../../global/Table/CustomTableHeader";
import { MANAGE_LOCATIONS_TABLE_HEAD_CELLS } from "../ManageLocations/constants";
import GlobalTableHead from "../../global/Table/GlobalTableHead";
import DeleteUser from "./DeleteUser";
import FeedbackBar from "../../feedbackBar/FeedbackBar";
import ErrorFeedbackBar from '../../global/ErrorFeedbackBar';

class ManageUsersTable extends SearchableTable {

    constructor(props) {
        super(props);
        this.state = {
            order: ASC,
            orderBy: DEFAULT_SORT_BY_HEAD_CELL,
            isSearch: false, // true if search value is not empty, false otherwise
            searchValue: "", // the value in the search field (updates on change)
            searchRows: 0, // number of rows displayed while search criteria is met
            users: [],
            page: 0,
            rowsPerPage: DEFAULT_ROWS_PER_PAGE,
            isEdit: false,
            openDialog: false,
            isDelete: {},
            editingUser: {},
            open: false,
        }
    }

    componentDidMount() {
        const data = { username: this.props.userInfo.username }
        this.props.fetchLinkedUsers(data)
        this.props.getRoles()
        this.props.listLocations()
    }

    handleCloseDialog = () => {
        this.setState({openDialog: false, isEdit: false, editingUser: {}, open: false})
    }

    handleOpenDialog = () => {
        this.setState({ openDialog: true })
    }

    handleRequestSort = (event, property) => {
        const isAsc = this.state.orderBy === property && this.state.order === ASC;
        this.setState({
            order: isAsc ? DESC : ASC,
            orderBy: property,
        });
    };

    onSearchValueChange = (event) => {
        this.setState({
            isSearch: event.target.value.length > 0,
            searchValue: event.target.value,
            page: 0
        });
    };

    isSelected = (item) => this.state.users.indexOf(item) !== -1;

    registerNewUser = (data) => {
        data = { ...data, companyId: this.props.userInfo.company }
        this.props.registerUser(data)
    }

    handleDeleteTrue = (index) => {
        let newIsDelete = this.state.isDelete
        newIsDelete[index] = true
        this.setState({ isDelete: newIsDelete })
    }

    handleDeleteFalse = (index) => {
        let newIsDelete = this.state.isDelete
        newIsDelete[index] = false
        this.setState({ isDelete: newIsDelete })
    }

    handleEdit = (user) => {
        this.setState({ openDialog: true, isEdit: true, editingUser: user })
    }

    handleSaveOpen = (open) => {
        this.setState({
            open: open
        })
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: event.target.value,
            page: 0,
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
        });
    };

    getDisplayed = (rows) => {
        if (!rows)
            return [];
        return [...rows.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)];
    }

    getUsers = () => {
        let users = this.filterBySearch(this.props.users)
        for (let i = 0; i < users.length; i++) {
            users[i].authority = (users[i].authorities[0] ? users[i].authorities[0].authority : '')
        }
        return users
    }

    render() {
        return (
            <>
                <ErrorFeedbackBar />
                <FeedbackBar
                    open={this.state.open}
                    severity={this.props.status.success ? SUCCESS : ERROR}
                    message={this.props.status.success ? SAVE_SUCCESS_MESSAGE : ERROR_MESSAGE}
                    handleClose={this.handleCloseDialog}
                />
                <Paper className="paper">
                    <SettingsToolbar
                        handleCreateOpen={this.handleOpenDialog}
                        handleClose={this.handleCloseDialog}
                        title={MANAGE_USERS_TOOLBAR_TITLE}
                        addingOrEditing={this.state.openDialog}
                    />
                    {this.state.openDialog ?
                        this.state.isEdit?
                            <ManageExpandEdit
                                isEdit={this.state.isEdit}
                                user={this.state.editingUser}
                                admin={this.props.userInfo}
                                editUser={this.props.editUser}
                                status={this.props.status.status}
                                statusMessage={this.props.status.statusText}
                                fetchLinkedUsers={this.props.fetchLinkedUsers}
                                loading={this.props.loading}
                                open={this.props.open}
                                success={this.props.status.success}
                                handleClose={this.handleCloseDialog}
                                handleEdit={this.handleEdit}
                                allRoles={this.props.roles}
                                locations={this.props.locations}
                                fetchAlerts={this.props.fetchAlerts}
                                handleOpen={this.handleSaveOpen}
                            />
                            :
                            <RegisterNewUser
                                admin={this.props.userInfo}
                                open={this.state.openDialog}
                                handleClose={this.handleCloseDialog}
                                register={this.registerNewUser}
                                allRoles={this.props.roles}
                                locations={this.props.locations}
                            />
                        :
                        <>
                            <CustomTableHeader
                                searchText={this.state.searchValue}onSearchValueChange={this.onSearchValueChange}
                                pagination={
                                    <TablePagination
                                        className="table-pagination"
                                        labelRowsPerPage={ROWS_PER_PAGE_LABEL}
                                        rowsPerPageOptions={[5, 25, 50, 100, {value: this.props.users.length, label: "All"} ]}
                                        count={!this.props.users ? 0 : this.filterBySearch(this.props.users).length}
                                        rowsPerPage={this.state.rowsPerPage}
                                        page={this.state.page}
                                        onChangePage={this.handleChangePage}
                                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    />
                                }
                            />
                            <TableContainer>
                                <Table
                                    aria-labelledby='tableTitle'
                                    size='medium'
                                    aria-label={MANAGE_USERS_TITLE}
                                >

                                    <GlobalTableHead
                                        isCreatePurchaseOrderTable={false}
                                        isShipmentTable={false}
                                        headCells={USER_MANAGEMENT_HEAD_CELLS}
                                        order={this.state.order}
                                        orderBy={this.state.orderBy}
                                        onRequestSort={this.handleRequestSort}
                                        rowCount={!this.props.users ? 0 : this.props.users.length}
                                    />

                                    <TableBody>
                                        {this.getDisplayed(this.stableSort(this.getUsers(), this.getComparator(this.state.order, this.state.orderBy))).map((user, index) => {
                                            const itemKey = `rule-dialog-rule-${index}`
                                            return (
                                                <>
                                                    <TableRow
                                                        hover
                                                        role='checkbox'
                                                        tabIndex={-1}
                                                        key={user.username}
                                                    >
                                                        <TableCell
                                                            align='center'
                                                            scope='row'
                                                            //padding='none'
                                                            //className='cellPadding'
                                                        >
                                                            <IconButton
                                                                onClick={() => this.handleEdit(user)}>
                                                                <EditIcon/>
                                                            </IconButton>
                                                        </TableCell>
                                                        <TableCell
                                                            align='left'
                                                            id={itemKey}
                                                            scope='row'
                                                            className='cellPadding'
                                                            padding='default'
                                                        >
                                                            {user.displayName}
                                                        </TableCell>
                                                        <TableCell
                                                            align='left'
                                                            id={itemKey}
                                                            scope='row'
                                                            className='cellPadding'
                                                            padding='default'
                                                        >
                                                            {user.username}
                                                        </TableCell>
                                                        <TableCell
                                                            align='left'
                                                            scope='row'
                                                            className='cellPadding'
                                                            padding='default'
                                                        >
                                                            {user.authorities[0] ? user.authorities[0].authority : ''}
                                                        </TableCell>
                                                        <DeleteUser
                                                            admin={this.props.userInfo}
                                                            user={user}
                                                            deleteUser={this.props.deleteUser}
                                                            isDelete={this.state.isDelete[index]}
                                                            handleDeleteTrue={this.handleDeleteTrue}
                                                            handleDeleteFalse={this.handleDeleteFalse}
                                                            index={index}
                                                            handleOpen={this.handleSaveOpen}
                                                        />
                                                    </TableRow>

                                                </>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>}
                </Paper>
            </>
        );
    }

}

const mapStateToProps = (state) => ({
    users: linkedUsersSelector(state),
    status: statusSelector(state),
    loading: loadingSelector(state),
    userInfo: userInfoSelector(state),
    roles: rolesSelector(state),
    locations: locationsSelector(state)
})

const actionCreators = {
    listLocations,
    fetchLinkedUsers,
    editUser,
    registerUser,
    deleteUser,
    getRoles,
    fetchAlerts,
}

ManageUsersTable.propTypes = {
    users: PropTypes.array,
    status: PropTypes.object,
    loading: PropTypes.bool,
    userInfo: PropTypes.object
}

ManageUsersTable.defaultProps = {
    users: [],
    status: {},
    loading: false,
    userInfo: {}
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, ManageUsersTable)