import React from "react";
import {Button, Paper, ClickAwayListener, TextField} from "@material-ui/core";
import "./PaymentPopper.css"
import Autocomplete from "@material-ui/lab/Autocomplete";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import PropTypes from "prop-types";
import {STANDARD_CARRIER} from "./constants";
import {saveCarrierInvoicePaymentDetails, saveSupplierInvoicePaymentDetails} from "../../../redux/actions/invoices";
import withShipment from "../../../withShipment";

class PaymentPopper extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            paymentDate: new Date(),
            bankAccount: null,
            referenceId: "",
        }
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value})
    }

    handleClose = () => {
        this.props.handleClosePopper()
    }

    handleSave = () => {
        let data;
        if (this.props.selectedCarrierInvoiceIds.length > 0) {

             data = {
                InvoiceIds: this.props.selectedCarrierInvoiceIds,
                paymentDate: this.state.paymentDate,
                bankAccount: this.state.bankAccount,
                referenceId: this.state.referenceId,
            }
            this.props.saveCarrierInvoicePaymentDetails(data, STANDARD_CARRIER);
        }
        if (this.props.selectedSupplierInvoiceIds.length > 0) {
            data = {
                InvoiceIds: this.props.selectedSupplierInvoiceIds,
                paymentDate: this.state.paymentDate,
                bankAccount: this.state.bankAccount,
                referenceId: this.state.referenceId,
            }
          this.props.saveSupplierInvoicePaymentDetails(data);
        }
        this.props.handleClosePopper()
    }

    render() {
        return (
            <>
                <ClickAwayListener onClickAway={this.handleClose}>
                    <Paper className="supplier-payment-popper-container">
                        <div className="supplier-payment-popper-item">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    name="paymentDate"
                                    label="Payment Date"
                                    value={this.state.paymentDate}
                                    onChange={(e, value) => this.handleChange({target: {name: "paymentDate", value}})}
                                    format="yyyy-MM-dd"
                                    style={{ width: "250px" }}
                                    variant="inline"
                                    inputVariant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    InputAdornmentProps={{ position: "start" }}
                                    autoOk
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className="supplier-payment-popper-item">
                            <Autocomplete
                                options={this.props.bankAccounts}
                                getOptionLabel={(option) => `${option.name} (${option.accountNumber})`}
                                value={this.state.bankAccount}
                                onChange={(e, value) => this.handleChange({target: {name: "bankAccount", value}})}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        name="bankAccount"
                                        label="Bank Account"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                }
                                style={{width: "250px"}}
                            />
                        </div>
                        <div className="supplier-payment-popper-item">
                            <TextField
                                variant="outlined"
                                name="referenceId"
                                label="Reference Id"
                                value={this.state.referenceId}
                                onChange={(e) => this.handleChange(e)}
                                InputLabelProps={{ shrink: true }}
                                style={{width: "250px"}}
                            />
                        </div>
                        <div className="supplier-payment-popper-buttons">
                            <Button variant='contained' onClick={this.handleClose} style={{width: '100px'}}>Close</Button>
                            <Button variant='contained' onClick={this.handleSave} color="primary" style={{width: '100px'}}>Save</Button>
                        </div>
                    </Paper>
                </ClickAwayListener>
            </>
        )
    }
}

const actionCreators = {
    saveSupplierInvoicePaymentDetails,
    saveCarrierInvoicePaymentDetails
}

PaymentPopper.propTypes = {
    bankAccounts: PropTypes.array,
    selectedSupplierIds: PropTypes.array,
}

PaymentPopper.defaultProps = {
    bankAccounts: [],
    selectedSupplierIds: []
}

export default withShipment({
    actionCreators
}, PaymentPopper);