import React from "react";
import withShipment from "../../../withShipment";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Calender.css'

//styling
import "./TransactionsSettings.css"

// constants
import {
    BACK, TRANSACTION_CALENDER_PAGE_NAME,
    TRANSACTION_PAGES,
} from "./constants";

// custom components
import ErrorFeedbackBar from "../../global/ErrorFeedbackBar";
import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";

import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import PropTypes from "prop-types";
import {
    allCarrierInvoicesSelector,
    allSupplierInvoicesSelector,
} from "../../../redux/selectors/invoices";
import {
    getAllCarrierInvoices,
    getAllSupplierInvoices,
} from "../../../redux/actions/invoices";
import {connect} from "react-redux";
import {setClickedDateEvents} from "../../../redux/actions/accounting/calenderActions";

const CustomToolbar = ({ label, onNavigate, onView, selectedMonth, selectedYear, setSelectedMonth, setSelectedYear }) => {

    const months = moment.months()
    const handleChangeMonth = e => {
        const selectedMonthIndex = parseInt(e.target.value);
        setSelectedMonth(selectedMonthIndex);
    };

    const handlePrev = () => {
        let newMonth = selectedMonth - 1;
        let newYear = selectedYear;
        if (newMonth < 0) {
            newMonth = 11;
            newYear -= 1;
        }
        setSelectedMonth(newMonth);
        setSelectedYear(newYear);
        onNavigate('prev');
    };

    const handleNext = () => {
        let newMonth = selectedMonth + 1;
        let newYear = selectedYear;
        if (newMonth > 11) {
            newMonth = 0;
            newYear += 1;
        }
        setSelectedMonth(newMonth);
        setSelectedYear(newYear);
        onNavigate('next');
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: 16 }}>
            <div></div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <button
                    style={{ marginRight: 8, background: 'none', border: 'none', cursor: 'pointer' }}
                    onClick={handlePrev}
                >
                    <ChevronLeftIcon />
                </button>
                <select
                    style={{ padding: '8px', borderRadius: '4px', border: '1px solid #ccc', marginRight: 8 }}
                    value={selectedMonth}
                    onChange={handleChangeMonth}
                >
                    {months.map((month, index) => (
                        <option key={index} value={index}>{month} {selectedYear}</option>
                    ))}
                </select>
                <button
                    style={{ background: 'none', border: 'none', cursor: 'pointer' }}
                    onClick={handleNext}
                >
                    <ChevronRightIcon />
                </button>
            </div>
            <div></div>
        </div>
    );
};

class TransactionCalendar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            events: [],
            invoicesList: this.props.allInvoices,
            invoicesListCarrier: this.props.allInvoicesCarrier,
            fromDate: this.getFromDate(),
            toDate: this.getToDate(),
            selectedMonth: moment().month(),
            selectedYear: moment().year(),
        }
    }

    getToDate = () => {
        const date = new Date();
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
        date.setDate(date.getDate() + 150)
        return date;
    }
    getFromDate = () => {
        const date = new Date();
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setDate(date.getDate() - 150)
        return date;
    }

    componentDidMount() {
        const { getAllSupplierInvoices, getAllCarrierInvoices } = this.props;
        const { fromDate, toDate } = this.state;
        this.fetchInvoices(fromDate, toDate, getAllSupplierInvoices, getAllCarrierInvoices);
    }


    fetchInvoices = (fromDate, toDate, getAllSupplierInvoices, getAllCarrierInvoices) => {
        getAllSupplierInvoices({ fromDate, toDate });
        getAllCarrierInvoices({ fromDate, toDate });
        this.setState({ fromDate, toDate });
    }

    componentDidUpdate(prevProps, prevState) {

        const { fromDate, toDate, selectedMonth, selectedYear } = this.state;
        const { getAllSupplierInvoices, getAllCarrierInvoices } = this.props;
        const selectedDate = moment().month(selectedMonth).year(selectedYear);

        // Check if selected month and year fall within the range of fromDate and toDate
        if (selectedDate.isBefore(moment(fromDate)) || selectedDate.isAfter(moment(toDate))) {
            this.fetchInvoices(
                selectedDate.clone().startOf('month').subtract(90, 'days').toDate(),
                selectedDate.clone().endOf('month').add(90, 'days').toDate(),
                getAllSupplierInvoices,
                getAllCarrierInvoices
            );
        }



        if (prevProps.allInvoices !== this.props.allInvoices || prevProps.allInvoicesCarrier !== this.props.allInvoicesCarrier) {

            const { allInvoices, allInvoicesCarrier } = this.props;

            const filteredInvoicesSupplier = allInvoices
            const filteredInvoicesCarrier = allInvoicesCarrier

            const eventsFromSupplier = filteredInvoicesSupplier.map((invoice, index) => {
                const netTerm = parseInt(invoice.netTerm);
                const dueDate = moment(invoice.date).add(netTerm, 'days').toDate();

                return{
                    start: dueDate,
                    end: dueDate,
                    title: invoice.poNumber,
                    data: {
                        type: "Supplier",
                        description: invoice.poNumber,
                        supplierName: invoice.supplierName,
                        date: invoice.date,
                        poNumber: invoice.poNumber,
                        invoiceNumber: invoice.invoiceNumber,
                        total: invoice.invoiceTotal,
                        status: invoice.status,
                        dueDate: dueDate,
                        supplierInvoiceId: invoice.supplierInvoiceId
                    },
                    id: `supplier_${index}`,
                }

            });



            const eventsFromCarrier = filteredInvoicesCarrier.map((invoice, index) => {
                const netTerm = parseInt(invoice.netTerm);
                const dueDate = moment(invoice.invoiceDate).add(netTerm, 'days').toDate();

                return {
                    start: dueDate,
                    end: dueDate,
                    title: invoice.invoiceNumber,
                    data: {
                        type: "Carrier",
                        description: invoice.invoiceNumber,
                        carrierName: invoice.carrierName,
                        invoiceDate: invoice.invoiceDate,
                        invoiceNumber: invoice.invoiceNumber,
                        accountNumber: invoice.accountNumber,
                        invoiceTotal: invoice.summaryCharges['total'],
                        status: invoice.status,
                        dueDate: dueDate,
                        carrierInvoiceId: invoice.carrierInvoiceId
                    },
                    id: `carrier_${index}`,
                };
            });

            const events = [...eventsFromSupplier, ...eventsFromCarrier];

            this.setState({ events });
        }
    }


    handleDateClick = (clickedDate) => {
        const { events } = this.state;
        const eventsOnClickedDate = events.filter(event => moment(event.start).isSame(clickedDate, 'day'))
        this.props.setClickedDateEvents(eventsOnClickedDate);
        this.props.setCurrentPage(TRANSACTION_PAGES.INVOICE, {})
    }


    render() {

        const {events, selectedMonth, selectedYear} = this.state;

        return (
            <>

                <ErrorFeedbackBar />
                <TopToolbar
                    pageName={TRANSACTION_CALENDER_PAGE_NAME}
                    menuItems={[
                        {
                            title: BACK,
                            icon: <KeyboardBackspaceIcon />,
                            onClick: () => { this.props.setCurrentPage(TRANSACTION_PAGES.TABLE, {}); }
                        },
                    ]}
                />

                <div style={{ height: "95vh" }}>
                    <Calendar
                        localizer={momentLocalizer(moment)}
                        events={events}
                        views={["month"]}
                        components={{
                            toolbar: (props) => (
                                <CustomToolbar
                                    {...props}
                                    label={moment.months()[selectedMonth]}
                                    selectedMonth={selectedMonth}
                                    selectedYear={selectedYear}
                                    setSelectedMonth={selectedMonth => this.setState({ selectedMonth })}
                                    setSelectedYear={selectedYear => this.setState({ selectedYear })}
                                />
                            ),
                            event: ({ event }) => {
                                let background;
                                let color;

                                switch(event.data.status) {
                                    case "Approved for Payment":
                                        background = "#FFD966"
                                        color = "black"
                                        break;
                                    case "Paid":
                                        background = "#b0d484";
                                        color = "black"
                                        break;
                                    case "Needs Review":
                                        background = "#FF4F4B";
                                        color = "white"
                                        break;
                                    default:
                                        background = "white";
                                        color = "black"
                                        break;
                                }

                                return (
                                    <div key={event.id} style={{ margin: 2 }}>
                                        <div style={{ background: background, color: color, fontWeight: "bold", fontSize: 14, paddingLeft: 8, paddingRight: 8 }}>
                                            {event.data.description}
                                        </div>
                                    </div>
                                );
                            }
                        }}
                        date={new Date(selectedYear, selectedMonth)}
                        onSelectEvent={(event) => this.handleDateClick(moment(event.start))}
                        onNavigate={(date, view) => {
                            // Handle navigation logic here
                        }}

                    />
                </div>
            </>
        );
    }
}

const actionCreators = {
    getAllSupplierInvoices,
    getAllCarrierInvoices,
};

const mapStateToProps = (state) => ({
    allInvoices: allSupplierInvoicesSelector(state),
    allInvoicesCarrier: allCarrierInvoicesSelector(state),
});

TransactionCalendar.propTypes = {
    allInvoices: PropTypes.array,
    allInvoicesCarrier: PropTypes.array,
};

TransactionCalendar.defaultProps = {
    allInvoices: [],
    allInvoicesCarrier: [],
};
const mapDispatchToProps = {
    setClickedDateEvents,
};
export default connect(null, mapDispatchToProps)(withShipment({
    mapStateToProps,
    actionCreators
}, TransactionCalendar));






