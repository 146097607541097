import React from "react";
import withShipment from "../../../withShipment";

//components
import CustomToolbar from "../../global/CustomToolbar";
import CustomTableHeader from "../../global/Table/CustomTableHeader";
import ManagePrintersForm from "./ManagePrintersForm";
import SearchableTable from "../../global/Search/SearchableTable";
import GlobalTableHead from "../../global/Table/GlobalTableHead";
import Loading from "../../loading/Loading"
import FeedbackBar from "../../feedbackBar/FeedbackBar";

//material ui
import { TableBody, TablePagination, Table, TableContainer, TableRow, TableCell } from "@material-ui/core";
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";



//constants
import { ASC, DESC } from "../../global/constants";
import {
    REFRESH_LOCATION_TITLE,
    BACK_TO_MENU_TITLE,
    PRINTER_BUTTON_TITLE,
    PRINTERS_TOOLBAR_TITLE,
    SETTING_TITLE,
    DEFAULT_ROWS_PER_PAGE,
    MANAGE_PRINTERS_TABLE_HEAD_CELLS,
    DEFAULT_SORT_BY_HEAD_CELL, ROWS_PER_PAGE_LABEL,
    NO_PRINTER_FOUND_ERROR,
    NO_PRINTER_FOUND_WARNING,
    ERROR
} from "./constants";

//redux
import {
    listPrinters,
    refreshPrinters,
    updatePrinters,
    storePrinters,
    uploadLogo,
    fetchLogo, storeLogo, deleteLogo
} from "../../../redux/actions/settings"
import { logoSelector, printersSelector } from "../../../redux/selectors/settings"
import { loadingSelector } from "../../../redux/selectors/global"
import { setLoadingStatus } from "../../../redux/actions/global";

import "./ManagePrintersTable.css"
import { clearInterval } from "stompjs";
import ErrorFeedbackBar from "../../global/ErrorFeedbackBar";




class ManagePrintersTable extends SearchableTable {

    timeout = undefined
    interval = undefined;

    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: DEFAULT_ROWS_PER_PAGE,
            settingPrinter: false,
            order: ASC,
            orderBy: DEFAULT_SORT_BY_HEAD_CELL,
            isSearch: false, // true if search value is not empty, false otherwise
            searchValue: "", // the value in the search field (updates on change)
            searchRows: 0, // number of rows displayed while search criteria is met
            showReflashConfirm: false,
            noPrinterError: false,
            noPrinterWarning: false
        }
    }

    handleRequestSort = (event, property) => {
        const isAsc = this.state.orderBy === property && this.state.order === ASC;
        this.setState({
            order: isAsc ? DESC : ASC,
            orderBy: property,
        });
    };

    componentDidMount() {
        //TODO should fetch printer list
        this.props.setLoadingStatus(true)
        this.timeout = setTimeout(this.clearDatabasePrinterListInterval, 2000)
        this.interval = setInterval(this.keepListPrinters, 500);
        this.props.fetchLogo()
    }

    componentWillUnmount() {
        this.props.setLoadingStatus(false)
        clearInterval(this.interval);
        clearTimeout(this.timeout);
    }

    getDisplayed = (rows) => {
        if (!rows)
            return [];
        return [...rows.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)];
    }

    handleClose = () => {
        this.setState({
            settingPrinter: false,
        })
    }

    handleSave = () => {
        //Todo 
    }

    onSearchValueChange = (e) => {
        this.setState({
            isSearch: e.target.value.length > 0,
            searchValue: e.target.value,
            page: 0
        });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: event.target.value,
            page: 0,
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
        });
    };

    handleRadioChange = (e) => {
        const value = e.target.value
        const selected = e.target.checked
        let newPrinters = this.props.printers
        newPrinters.forEach(row => row.selected = value == row.name ? selected : false)
        this.props.updatePrinters(newPrinters)
    };

    handleGetAllPrinters = () => {
        this.props.storePrinters([])
        this.props.setLoadingStatus(true)
        this.props.refreshPrinters(false)
        this.timeout = setTimeout(this.clearPrinterListInterval, 5000)
        this.interval = setInterval(this.keepListPrinters, 1000);

    };

    clearDatabasePrinterListInterval = () => {
        this.props.setLoadingStatus(false);
        this.setState({
            noPrinterWarning: true
        });
        clearInterval(this.interval);
        clearTimeout(this.timeout);
    }

    clearPrinterListInterval = () => {
        this.props.setLoadingStatus(false);
        this.setState({
            noPrinterError: true
        });
        clearInterval(this.interval);
        clearTimeout(this.timeout);
    }

    handleRefreshAllPrinters = () => {
        this.setReflashConfirm(false)
        this.props.storePrinters([])
        this.props.setLoadingStatus(true)
        this.props.refreshPrinters(true)
        this.timeout = setTimeout(this.clearPrinterListInterval, 5000)
        this.interval = setInterval(this.keepListPrinters, 1000);

    };

    keepListPrinters = () => {

        if (this.props.printers.length == 0) {
            this.props.listPrinters()
        } else {
            this.props.setLoadingStatus(false)
            clearInterval(this.interval)
            clearTimeout(this.timeout)
        }
    }

    setReflashConfirm = (show) => {
        this.setState({
            showReflashConfirm: show
        })
    }

    handleErrorClose = () => {
        this.setState({
            noPrinterError: false
        })
    }

    handleWarningClose = () => {
        this.setState({
            noPrinterWarning: false
        })
    }

    handleFileUpload = (file) => {
        var fileType = file.displayName.split(".")[1].toLowerCase()
        if (file != null && fileType == "png" || fileType == "jpg" || fileType == "jpeg") {
            this.props.uploadLogo(file)
        }
        this.handleClose()
    }

    render() {
        return (
            <>
                <ErrorFeedbackBar />
                <FeedbackBar
                    open={this.state.noPrinterError}
                    handleClose={this.handleErrorClose}
                    severity={ERROR}
                    message={NO_PRINTER_FOUND_ERROR}
                />
                <FeedbackBar
                    open={this.state.noPrinterWarning}
                    handleClose={this.handleWarningClose}
                    severity={ERROR}
                    message={NO_PRINTER_FOUND_WARNING}
                />
                <Paper className='paper'>
                    <CustomToolbar
                        title={PRINTERS_TOOLBAR_TITLE}
                        createTitle={REFRESH_LOCATION_TITLE}
                        settingTitle={SETTING_TITLE}
                        backTitle={BACK_TO_MENU_TITLE}
                        onClick={() => { this.setState({ settingPrinter: true }) }}
                        handleClose={this.handleClose}
                        creatingOrder={this.state.settingPrinter}
                        myreflash={this.handleRefreshAllPrinters}
                        showReflash={this.props.printers.length !== 0}
                        setReflashConfirm={this.setReflashConfirm}
                        showReflashConfirm={this.state.showReflashConfirm}
                    />
                    {this.props.printers.length == 0 ?
                        this.props.isloading ?
                            <div className="getPrinterLoadingOuterBox">
                                <div className="getPrinterLoadingBox">
                                    <CircularProgress size={100} />
                                </div>
                            </div>
                            :
                            <div className="getAllPrinterBtnOuterBox">
                                <div className="getAllPrinterBtnBox">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className="getAllPrinterBtn"
                                        onClick={this.handleGetAllPrinters}
                                    >
                                        {PRINTER_BUTTON_TITLE}
                                    </Button>
                                </div>
                            </div>
                        :
                        <>
                            {this.state.settingPrinter ?
                                <ManagePrintersForm
                                    addingLocation={true}
                                    handleClose={this.handleClose}
                                    handleSave={this.handleSave}
                                    missingError={this.state.missingError}
                                    handleFileUpload={this.handleFileUpload}
                                    logo={this.props.logo}
                                    fetchLogo={this.props.fetchLogo}
                                    deleteLogo={this.props.deleteLogo}
                                    handleClose={this.handleClose}
                                />
                                :
                                <>
                                    <CustomTableHeader
                                        searchText={this.state.searchValue}
                                        onSearchValueChange={this.onSearchValueChange}
                                        pagination={
                                            <TablePagination
                                                className="table-pagination"
                                                labelRowsPerPage={ROWS_PER_PAGE_LABEL}
                                                rowsPerPageOptions={[5, 25, 50, 100, {value: this.props.printers.length, label: "All"} ]}
                                                count={!this.props.printers ? 0 : this.filterBySearch(this.props.printers).length}
                                                rowsPerPage={this.state.rowsPerPage}
                                                page={this.state.page}
                                                onChangePage={this.handleChangePage}
                                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                            />
                                        }
                                    />


                                    <TableContainer>
                                        <Table size='medium'>
                                            <GlobalTableHead
                                                isCreatePurchaseOrderTable={false}
                                                isShipmentTable={false}
                                                headCells={MANAGE_PRINTERS_TABLE_HEAD_CELLS}
                                                order={this.state.order}
                                                orderBy={this.state.orderBy}
                                                onRequestSort={this.handleRequestSort}
                                                rowCount={!this.props.locations ? 0 : this.props.locations.length}
                                            />
                                            <TableBody>
                                                {this.getDisplayed(this.stableSort(this.filterBySearch(this.props.printers), this.getComparator(this.state.order, this.state.orderBy))).map((row, index) => {

                                                    return (
                                                        <TableRow >
                                                            <TableCell />
                                                            <TableCell className='cellPadding'>
                                                                {row.name}
                                                            </TableCell>
                                                            <TableCell className='cellPadding'>
                                                                {row.hostname}
                                                            </TableCell>
                                                            <TableCell>
                                                                <Radio
                                                                    checked={row.selected}
                                                                    onChange={this.handleRadioChange}
                                                                    value={row.name}
                                                                    name={row.name}
                                                                //inputProps={{ 'aria-label': 'A' }}
                                                                />
                                                            </TableCell>


                                                        </TableRow>
                                                    );

                                                })
                                                }
                                            </TableBody>

                                        </Table>


                                    </TableContainer>
                                </>
                            }
                        </>
                    }
                </Paper>
            </>
        );
    }

}

const mapStateToProps = (state) => ({
    printers: printersSelector(state),
    isloading: loadingSelector(state),
    logo: logoSelector(state),
})

const actionCreators = {
    listPrinters,
    refreshPrinters,
    updatePrinters,
    storePrinters,
    setLoadingStatus,
    uploadLogo,
    fetchLogo,
    storeLogo,
    deleteLogo

}
export default withShipment({
    mapStateToProps,
    actionCreators
}, ManagePrintersTable);