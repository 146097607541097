import React from 'react';

// material components
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from '@material-ui/core';

// constants
import {
    DELETE_DIALOG_TITLE,
    DELETE_DIALOG_MESSAGE,
} from "../automationrules/constants";
import PropTypes from "prop-types";
import CustomToolbar from "./CustomToolbar";

function DeleteDialog(props) {

    const handleDelete = () => {
        props.handleDelete()
        props.handleClose()
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{DELETE_DIALOG_TITLE}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {DELETE_DIALOG_MESSAGE}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose} color="primary" variant="contained" autoFocus>
                    Go Back
                </Button>
                <Button onClick={handleDelete}>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    )

}

DeleteDialog.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    rule: PropTypes.object,
    handleDelete: PropTypes.func
}

DeleteDialog.defaultProps = {
    open: false,
    handleClose: ()=>{},
    rule: {},
    handleDelete: ()=>{}
}

export default DeleteDialog