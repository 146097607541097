import React from "react";
import withShipment from "../../withShipment";

import DamagesTable from "./DamagesPages/DamagesTable";
import {DAMAGES_PAGES} from "./constants";
import DamagesForm from "./DamagesPages/DamagesForm";
import {storeSpecificDamage} from "../../redux/actions/damage";
import {listAvailableLocations, listSuppliers} from "../../redux/actions/settings";
import PropTypes from "prop-types";
import {availableLocationsSelector} from "../../redux/selectors/settings";
import ErrorFeedbackBar from "../global/ErrorFeedbackBar";
import {selectedLocationIdSelector} from "../../redux/selectors/auth";

class Damages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: DAMAGES_PAGES.TABLE,
            idOfDamageBeingEdited: ""
        }

        this.props.listSuppliers()
    }

    componentDidMount() {
        this.props.listAvailableLocations()
    }
    setCurrentPage = (newPage, pageChangeInfo) => {
        switch (this.state.currentPage){
            case DAMAGES_PAGES.UPDATE:
                this.setState({
                    idOfDamageBeingEdited: ""
                })
                this.props.storeSpecificDamage(null)
                break
        }

        switch (newPage) {
            case DAMAGES_PAGES.UPDATE:
                this.setState({
                    idOfDamageBeingEdited: pageChangeInfo.idOfDamageBeingEdited
                })
                break
        }

        this.setState({
            currentPage: newPage
        })
    }

    displayTable = () => {
        return <React.Fragment>
            <DamagesTable
                setCurrentPage={this.setCurrentPage}
                selectedLocation={this.props.selectedLocation}
            />
        </React.Fragment>
    }

    displayCreating = () => {
        return <React.Fragment>
            <DamagesForm
                creating={true}
                setCurrentPage={this.setCurrentPage}
                locations={this.props.availableLocations}
            />
        </React.Fragment>
    }

    displayUpdating = () => {
        return <React.Fragment>
            <DamagesForm
                creating={false}
                setCurrentPage={this.setCurrentPage}
                idOfDamageBeingEdited={this.state.idOfDamageBeingEdited}
                locations={this.props.availableLocations}
            />
        </React.Fragment>
    }

    displayCurrentPage = (currentPage) => {
        switch (currentPage) {
            case DAMAGES_PAGES.TABLE:
                return this.displayTable()
            case DAMAGES_PAGES.CREATE:
                return this.displayCreating()
            case DAMAGES_PAGES.UPDATE:
                return this.displayUpdating()
            default:
                console.log("Error: current damages page doesn't exist")
                return <div>We encountered an error. Please refresh the page.</div>
        }
    }

    render() {
        return (
            <React.Fragment>
                <ErrorFeedbackBar/>
                {this.displayCurrentPage(this.state.currentPage)}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
  availableLocations: availableLocationsSelector(state),
    selectedLocation: selectedLocationIdSelector(state)
})

const actionCreators = {
    listSuppliers,
    storeSpecificDamage,
    listAvailableLocations,
}

Damages.propTypes = {
    availableLocations: PropTypes.array,
}

Damages.defaultProps = {
    availableLocations: [],
}

export default withShipment({
    actionCreators,
    mapStateToProps
}, Damages);