import React, {Component} from "react";
import withShipment from "../../../../withShipment";
import './FetchOrder.css'
import {loadingSelector} from "../../../../redux/selectors/global";
// import CustomTableHeader from "../../global/Table/CustomTableHeader";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
    Button,
    ClickAwayListener, FormControlLabel,
    IconButton, Input,
    Paper, Radio, RadioGroup,
    TableBody,
    TableCell, TableFooter,
    TableHead,
    TextField,
    Toolbar,
    Tooltip,
    Typography
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import DeleteStepper from "../../../global/DeleteStepper";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {CREATE_MANUAL_INVENTORY_TITLE, SET_INVENTORY_THRESHOLD} from "../../../inventory/constants";
import NotificationsIcon from "@material-ui/icons/Notifications";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckIcon from "@material-ui/icons/Check";
import BlockIcon from "@material-ui/icons/Block";
import {AutoComplete} from "material-ui";
import {
    marketplaceCompanySelector,
    marketplaceNamesByCompanySelector,
    orderResponseSelector
} from "../../../../redux/selectors/admin";


import {
    getOrdersByOrderId,
    getMarketplaceCompanies,
    getMarketplaceNamesByCompany
} from "../../../../redux/actions/admin"
import FormLabel from "@material-ui/core/FormLabel";
import ErrorFeedbackBar from "../../../global/ErrorFeedbackBar";

class FetchOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orderInfo: [],
            errorMessage: "",
            editIndex: -1,
            editValue: "",
            beforeEditValue: "",
            companies: [],
            marketplaces: [],
            selectedCompany: null,
            selectedMarketplaceName: null,
            selectedStatus: null
        }
    }

    async componentDidMount() {
        this.props.getMarketplaceCompanies()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.marketplaceCompanies !== prevProps.marketplaceCompanies) {
            this.setState({
                companies: this.props.marketplaceCompanies
            })
        }
        if (this.props.marketplaceNamesByCompany !== prevProps.marketplaceNamesByCompany) {
            this.setState({
                marketplaces: this.props.marketplaceNamesByCompany
            })
        }
    }

    // add row
    addRow = () => {
        let orderInfo = this.state.orderInfo ? this.state.orderInfo : []
        orderInfo.push("")
        if (this.state.editIndex !== -1) {
            if (this.state.beforeEditValue === "") {
                this.handleSaveChange()
            } else {
                this.handleRevertChange()
            }
        }
        this.setState({
            orderInfo,
            editIndex: orderInfo.length - 1
        })
    }

    changeMarketplace = (value) => {
        this.setState({
            selectedMarketplaceName: value
        })
    }

    changeCompany = (value) => {
        this.setState({
            selectedCompany: value,
            selectedMarketplaceName: null // reset
        })
        this.props.getMarketplaceNamesByCompany(value)
    }


    handleEditIndex = (index) => {
        this.setState({
            editIndex: index,
            editValue: this.state.orderInfo[index],
            beforeEditValue: this.state.orderInfo[index]
        })
    }

    handleDeleteRow = (index) => {
        let orderInfo = this.state.orderInfo
        orderInfo.splice(index, 1)
        this.setState({
            orderInfo
        })
    }

    handleSaveChange = () => {
        let orderInfo = this.state.orderInfo
        orderInfo[this.state.editIndex] = this.state.editValue
        this.setState({orderInfo, editIndex: -1, editValue: "", beforeEditValue: ""})
    }

    handleRevertChange = () => {
        this.setState({editIndex: -1, editValue: "", beforeEditValue: ""})
    }

    handleEditChange = (event) => {
        this.setState({editValue: event.target.value})
    }

    fetchOrders = () => {
        let data = {
            marketplaceName: this.state.selectedMarketplaceName,
            company: this.state.selectedCompany,
            orderIds: this.state.orderInfo,
            orderStatus: this.state.selectedStatus,
        }
        this.props.getOrdersByOrderId(data)
    }

    getResponse = (id) => {
        if (Object.keys(this.props.orderResponse).length !== 0) {
            if (this.props.orderResponse?.existing && this.props.orderResponse?.existing.indexOf(id) !== -1) { // TODO: Remove this case
                return "Order was not fetched because ID already exists"
            } else if (this.props.orderResponse?.invalidId && this.props.orderResponse.invalidId.indexOf(id) !== -1) {
                return "Order ID is invalid"
            } else if (this.props.orderResponse?.invalidStatus && this.props.orderResponse?.invalidStatus.indexOf(id) !== -1) {
                return "Order was not fetched because selected status does not match"
            } else if (this.props.orderResponse?.skippedShipments && this.props.orderResponse?.skippedShipments.indexOf(id) !== -1) {
                return "Order could not be updated because it is archived"
            } else if (this.props.orderResponse?.createdShipments && this.props.orderResponse?.createdShipments.indexOf(id) !== -1) {
                return "Successfully created shipment!"
            } else if (this.props.orderResponse?.updatedShipments && this.props.orderResponse?.updatedShipments.indexOf(id) !== -1) {
                return "Successfully updated shipment!"
            } else {
                return ""
            }
        }
    }

    handlePaste = (event) => {
        event.stopPropagation()
        event.preventDefault()
        let data = event.clipboardData.getData('Text')
        data = data.replace(/,/g, '\r\n')
        let newOrders = data.split(/[\n\r\s]+/)
        let orderInfo = this.state.orderInfo
        this.setState({
            orderInfo: [...orderInfo, ...newOrders],
        })
    }

    handleChangeStatus = (event) => {
        this.setState({
            selectedStatus: event.target.value
        })
    }

    checkSubmit = () => {
        let enable = this.state.selectedCompany && this.state.selectedMarketplaceName && this.state.selectedStatus && this.state.orderInfo.length > 0

        this.state.orderInfo.map((row) => {
            if (row === '')
                enable = false
        })

        return enable
    }

    render() {
        return (
            <React.Fragment>
                <ErrorFeedbackBar />

                <div className="toolbar">
                    <Toolbar>
                        <Typography className="titleTypography" variant="h6" id="tableTitle">
                            Fetch By Order ID
                        </Typography>
                    </Toolbar>
                </div>
                <div className={"split-table"}>
                    <div className={"location-box"}>
                        <div className={"company-box"}>
                            <Autocomplete
                                options={this.state.companies}
                                className='marketplace'
                                name='Marketplace'
                                value={this.state.selectedCompany}
                                onChange={(event, value) => {
                                    this.changeCompany(value)}}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Marketplace"
                                        InputLabelProps={{shrink: true}}
                                        variant="outlined"
                                        name="marketplace"
                                    />}
                            />
                        </div>
                        <div className={"country-box"}>
                            <Autocomplete
                                options={this.state.marketplaces}
                                className='marketplace-name'
                                name='Name'
                                disabled={!this.state.selectedCompany}
                                value={this.state.selectedMarketplaceName}
                                onChange={(event, value) => {
                                    this.changeMarketplace(value)}}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Name"
                                        InputLabelProps={{shrink: true}}
                                        variant="outlined"
                                        name="marketplace-name"
                                    />}
                            />
                        </div>

                        <div className={"radio-set"}>
                            <FormLabel component="Status" style={{fontSize: 12}}>
                                Shipment Status
                            </FormLabel>
                            <RadioGroup
                                row
                                value={this.state.selectedStatus}
                                onChange={(event) => this.handleChangeStatus(event)}
                            >
                                <FormControlLabel value="Pending" control={<Radio />} label="Pending" />
                                <FormControlLabel value="Canceled" control={<Radio />} label="Cancelled" />
                                <FormControlLabel value="Shipped" control={<Radio />} label="Shipped" />
                            </RadioGroup>
                        </div>
                    </div>
                    <div className={"full-table"}>
                        <div className={"table-header"}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={"edit-col"}/>
                                        <TableCell className={"id-col"} style={{fontWeight: "bold"}}>
                                            Order ID
                                        </TableCell>
                                        <TableCell className={"status-col"} style={{fontWeight: "bold"}}>
                                            Status
                                        </TableCell>
                                        <TableCell className={"delete-col"}/>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </div>
                            <div className={"table-body"}>
                                <Table>
                                <TableBody>
                                    {this.state.orderInfo
                                        .map((row, index) => {
                                            return (
                                                <React.Fragment>
                                                    {this.state.editIndex === -1 || this.state.editIndex !== index?
                                                    <TableRow hover key={"orderId " + index}>
                                                        <TableCell className={"edit-col"}>
                                                            <IconButton aria-label="Edit" onClick={() => this.handleEditIndex(index)}>
                                                                <EditIcon/>
                                                            </IconButton>
                                                        </TableCell>
                                                        <TableCell className={"id-col"}>
                                                            {row}
                                                        </TableCell>
                                                        <TableCell className={"status-col"}>
                                                            {this.getResponse(row)}
                                                        </TableCell>
                                                        <TableCell className={"delete-col"}>
                                                            <IconButton aria-label="Delete" onClick={() => this.handleDeleteRow(index)}>
                                                                <DeleteIcon/>
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                    :
                                                        <TableRow>
                                                            <TableCell className={"edit-col"}>
                                                                <IconButton aria-label="Save Changes" onClick={() => this.handleSaveChange()}>
                                                                    <CheckIcon/>
                                                                </IconButton>
                                                            </TableCell>
                                                            <TableCell className={"id-col"}>
                                                                <Input
                                                                    defaultValue={row}
                                                                    style={{width: "60%"}}
                                                                    onChange={(event)=>{this.handleEditChange(event)}}
                                                                    onPaste={(event) => {this.handlePaste(event)}}
                                                                />
                                                            </TableCell>
                                                            <TableCell className={"status-col"}>
                                                                {this.getResponse(row)}
                                                            </TableCell>
                                                            <TableCell className={"delete-col"}>
                                                                <IconButton aria-label="Cancel Changes" onClick={() => this.handleRevertChange()}>
                                                                    <BlockIcon/>
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>}
                                            </React.Fragment>
                                            );
                                        })}
                                </TableBody>
                                </Table>
                            </div>
                        <div className={"table-footer"}>
                            <Table>
                                <TableFooter>
                                    <div className={"footer-content"}>
                                        <IconButton onClick={() => this.addRow()}>
                                            <AddCircleIcon
                                                fontSize="small"
                                                color='secondary'
                                            />
                                        </IconButton>
                                        Add Order ID
                                    </div>
                                </TableFooter>
                            </Table>
                        </div>
                    </div>
                    <div className={"button-box"} style={{paddingLeft: 20}}>
                        <Button
                            disabled={!this.state.selectedCompany || !this.state.selectedStatus || this.state.orderInfo.length === 0}
                            onClick={() => this.fetchOrders()}
                            color="primary"
                            variant="contained"
                            disabled={!this.checkSubmit()}
                        >
                            Submit
                        </Button>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}


const mapStateToProps = (state) => ({
    orderResponse: orderResponseSelector(state),
    marketplaceCompanies: marketplaceCompanySelector(state),
    marketplaceNamesByCompany: marketplaceNamesByCompanySelector(state),
})

const actionCreators = {
    getOrdersByOrderId,
    getMarketplaceCompanies,
    getMarketplaceNamesByCompany
}

// export default FetchOrderId

export default withShipment({
    mapStateToProps,
    actionCreators
}, FetchOrder);