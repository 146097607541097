import React from 'react';

// custom components
import DeleteDialog from "./ManageCarriers/DeleteDialog";

// material components
import {
    Toolbar,
    Typography,
    Tooltip,
    IconButton,
} from "@material-ui/core";

// material icons
import {
    AddCircle as Add,
    Delete,
    Edit
} from '@material-ui/icons';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

// styling
import '../global/TableToolbar.css';

// constants
import {
    CARRIER_TOOLBAR_TITLE,
    CREATE_CARRIER_TITLE,
    DELETE_CARRIER_TITLE,
    EDIT_CARRIER_TITLE
} from "./ManageCarriers/constants";
import PropTypes from "prop-types";
import {MARKETPLACE_TOOLBAR_TITLE} from "./ManageMarketplaces/constants";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

class SettingsToolbar extends React.PureComponent {

    constructor(props) {
        super(props);
    }



    render() {
        return (
            <Toolbar className="toolbar">

                <DeleteDialog
                    handleClose={this.props.handleDeleteClose}
                    open={this.props.deleteOpen}
                    handleDelete={this.props.handleDelete}
                />

                <div className="contextualMenu">
                    {this.props.addingOrEditing ?
                        <>
                        <Tooltip title={this.props.title}>
                            <IconButton onClick={this.props.title == CARRIER_TOOLBAR_TITLE || this.props.title === MARKETPLACE_TOOLBAR_TITLE? this.props.handleBack : this.props.handleClose}>
                                <KeyboardBackspaceIcon />
                            </IconButton>
                        </Tooltip>
                            {this.props.title == CARRIER_TOOLBAR_TITLE &&
                                <Tooltip title={"Next"}>
                                    <IconButton
                                        disabled={this.props.disableNextStep || this.props.onSavingStep}
                                        onClick={this.props.nextStep}>
                                        <ArrowForwardIcon/>
                                    </IconButton>
                                </Tooltip>
                            }
                        </>
                        :
                        <Tooltip title={this.props.title}>
                            <IconButton onClick={this.props.handleCreateOpen}>
                                <Add />
                            </IconButton>
                        </Tooltip>
                    }
                    
                    
                    {/*this.props.selected && this.props.selected.carrierId ?
                        <>
                            <Tooltip title={EDIT_CARRIER_TITLE}>
                                <IconButton onClick={this.props.handleEditOpen}>
                                    <Edit />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={DELETE_CARRIER_TITLE}>
                                <IconButton onClick={this.props.handleDeleteOpen}>
                                    <Delete />
                                </IconButton>
                            </Tooltip>
                        </> : null
                    */}
                </div>

                <Typography className="titleTypography" variant="h6" id="tableTitle">
                    {this.props.title}
                </Typography>

            </Toolbar>
        );
    }

}

SettingsToolbar.propTypes = {
    title: PropTypes.string,
    handleCreateOpen: PropTypes.func,
    handleEditOpen: PropTypes.func,
    selected: PropTypes.object,
    handleDeleteOpen: PropTypes.func,
    handleDeleteClose: PropTypes.func,
    handleDelete: PropTypes.func,
    deleteOpen: PropTypes.bool
}

SettingsToolbar.defaultProps = {
    title: "No title specified",
    handleCreateOpen: ()=>{},
    handleEditOpen: ()=>{},
    selected: {},
    handleDeleteOpen: ()=>{},
    handleDeleteClose: ()=>{},
    handleDelete: ()=>{},
    deleteOpen: false
}

export default SettingsToolbar;