import {listFTLCarriersWithoutTemplate} from "../../../redux/actions/fullTruckloadCarriers";
import withShipment from "../../../withShipment";
import React from "react";
import FormControl from "@material-ui/core/FormControl";
import {InputLabel, MenuItem, Select} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {ftlCarriersWithoutTemplateSelector} from "../../../redux/selectors/fullTruckloadCarrier";
import PropTypes from "prop-types";


class CreateFTLCarrierInvoiceTemplate extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.listFTLCarriersWithoutTemplate()
    }

    changeTemplate = (e) => {
        if (e.target.files.length === 0) return
        let file = e.target.files[0]
        let fileReader = new FileReader();

        fileReader.onload = (e) => {
            let contents = new Uint8Array(e.target.result)
            this.props.updateField("template", { name: file.name, contents: contents })
            this.props.updateField("templateCopy", "none")
        }
        fileReader.readAsArrayBuffer(file)
    }

    render() {
        return (
            <>
                <div style={{padding: "100px", display: "flex", flexDirection: "column", height: "30vh", justifyContent: "space-between", textAlign: "center"}}>
                    <FormControl variant="outlined" style={{width: "100%", textAlign: "left"}}>
                        <InputLabel id="CarrierSelect">Carrier</InputLabel>
                        <Select
                            label="Carrier"
                            labelId="CarrierSelect"
                            value={this.props.selectedCarrier}
                            style={{width: "100%"}}
                            onChange={(e) => this.props.updateField("selectedCarrier", e.target.value)}
                        >
                            <MenuItem style={{maxWidth: 1500, overflow: "auto"}} value="none">&nbsp;</MenuItem>
                            {this.props.carriersWithoutTemplate.map((carrier, index) => (
                                <MenuItem style={{maxWidth: 1450, overflow: "auto"}} key={index} value={carrier.carrierId}>{carrier.name + ", " + carrier.accountNumber}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <div>
                        <label htmlFor={"btn-upload"}>
                            <input
                                id="btn-upload"
                                name="btn-upload"
                                style={{ display: "none" }}
                                type="file"
                                accept="application/pdf"
                                onChange={this.changeTemplate}
                            />
                            <Button className="btn-choose" variant="contained" color="primary" component="span">
                                Choose Template File
                            </Button>
                            <div style={{paddingLeft: "5px", display: "inline-block"}}>
                                {this.props.template.contents.length > 0 && this.props.template.name}
                            </div>
                        </label>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    carriersWithoutTemplate: ftlCarriersWithoutTemplateSelector(state)
})

const actionCreators = {
    listFTLCarriersWithoutTemplate
}

CreateFTLCarrierInvoiceTemplate.propTypes = {
    carriersWithoutTemplate: PropTypes.array
}

CreateFTLCarrierInvoiceTemplate.deafaultProps = {
    carriersWithoutTemplate: []
}

export default withShipment ({
    mapStateToProps,
    actionCreators
},CreateFTLCarrierInvoiceTemplate)