export const LIST_CLAIMS = "LIST_CLAIMS"
export const STORE_CLAIMS = "STORE_CLAIMS"
export const CREATE_CLAIM = "CREATE_CLAIM"
export const DELETE_CLAIM = "DELETE_CLAIM"
export const UPDATE_CLAIM = "UPDATE_CLAIM"
export const PREVIEW_CLAIM_FILE = "PREVIEW_CLAIM_FILE"
export const STORE_CLAIMS_FILE = "STORE_CLAIMS_FILE"
export const DELETE_CLAIM_FILE = "DELETE_CLAIM_FILE"
export const STORE_UPDATED_CLAIM = "STORE_UPDATED_CLAIM"
export const STORE_CREATED_CLAIM = "STORE_CREATED_CLAIM"
export const STORE_DELETED_CLAIM = "STORE_DELETED_CLAIM"