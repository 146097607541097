import React from "react";
import {Button, Paper, ClickAwayListener, TextField} from "@material-ui/core";
import "./AddRelatedProductPopper.css"
import withShipment from "../../../withShipment";

class AddRelatedProductPopper extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            asin: "",
            productName: ""
        }
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    handleClose = () => {
        this.props.handleClosePopper()
    }

    handleSave = () => {
        this.props.handleAddRelatedProduct({
            asin: this.state.asin,
            productName: this.state.productName
        })
        this.props.handleClosePopper()
    }
    render() {
        return (
            <>
                <ClickAwayListener onClickAway={this.handleClose}>
                    <Paper className="add-related-product-popper-container">
                        <div className="add-related-product-popper-item">
                            <TextField
                                label="Asin"
                                variant="outlined"
                                name="asin"
                                value={this.state.asin || ''}
                                onChange={(event) => this.handleChange("asin", event.target.value)}
                                style={{ marginTop: '7px' }}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                required={true}
                            />
                            <TextField
                                label="Product Name"
                                variant="outlined"
                                name="productName"
                                value={this.state.productName || ''}
                                onChange={(event) => this.handleChange("productName", event.target.value)}
                                style={{ marginTop: '7px' }}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                            />
                        </div>
                        <div className="add-related-product-popper-buttons">
                            <Button variant='contained' onClick={this.handleClose} style={{width: '100px'}}>Close</Button>
                            <Button
                                variant='contained'
                                onClick={this.handleSave}
                                color="primary"
                                style={{width: '100px'}}
                                disabled={this.state.asin === undefined || this.state.asin === '' || this.state.productName === undefined || this.state.asin === null}
                            >Save</Button>
                        </div>
                    </Paper>
                </ClickAwayListener>
            </>
        )
    }
}

AddRelatedProductPopper.propTypes = {
}

AddRelatedProductPopper.defaultProps = {
}
const mapStateToProps = (state) => ({
})

export default withShipment({
    mapStateToProps
}, AddRelatedProductPopper);