import React from "react";
import {
    ADD_TRANSACTION_PAGE_NAME,
    BACK,
    CANCEL,
    CARRIER_INVOICE,
    CHILD_REFERENCE_TYPES,
    EDIT_TRANSACTION_PAGE_NAME,
    FTL_INVOICE,
    GO_TO_PARENT_TRANSACTION,
    NA_PROVINCES,
    PARENT_REFERENCE_TYPES,
    REFERENCE_TYPES,
    SAVE_TRANSACTION,
    SPLIT_TRANSACTION,
    SUPPLIER_INVOICE,
    TRANSACTION_PAGES,
    TRANSACTION_TAX_TYPES
} from "./constants";
import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";
import SaveIcon from "@material-ui/icons/Save";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import {Button, TextField} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
    getInvoiceByInvoiceNumber,
    getSupplierReferences,
    getTransactionAttachment,
    saveTransaction,
    getTransactionTaxAmount,
    storeSupplierReferences, saveAndNavigateToChildTransaction, navigateToParentTransaction
} from "../../../redux/actions/accounting";
import withShipment from "../../../withShipment";
import {IconButton, Tooltip, Typography} from "@mui/material";
import FileUpload from "../../global/FileUpload";
import {getByteArray} from "../../global/constants";
import DescriptionIcon from "@material-ui/icons/Description";
import './Transactions.css'
import InputAdornment from "@material-ui/core/InputAdornment";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {
    fetchedReferencesSelector,
    foundInvoiceSelector,
    taxInfosSelector,
    taxTypewithStateProvinceSelector
} from "../../../redux/selectors/accounting";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import {ArrowUpward} from "@material-ui/icons";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import {CURRENCY_TYPE_OPTIONS} from "../../settings/ManageBankAccounts/constants";
import format from "date-fns/format";
import {parseISO} from "date-fns";

class TransactionsForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            transaction: { ...this.props.transaction },
            newFiles: [],
            deletedFiles: [],
            taxInfos: this.props.transaction.taxInfos,
            amountExceeded: false
        }
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevProps.transaction !== this.props.transaction && this.props.transaction) {
            this.setState({
                transaction: this.props.transaction
            })
        }
        if (prevState.transaction.category !== this.state.transaction.category) {




            const defaultCategory = this.props.categories.find(category => category.isDefault)
            if (this.state.transaction.category === defaultCategory.name) {
                this.props.getSupplierReferences({transaction: this.state.transaction})
            }else if(this.state.transaction.category === 'Freight & Shipping Costs') {
                this.props.getSupplierReferences({transaction: this.state.transaction})
            }else if(this.state.transaction.category === 'Freight & Shipping Costs (LTL/FTL)') {
                this.props.getSupplierReferences({transaction: this.state.transaction})
            }
        }


        if (this.props.taxInformations && this.props.taxInformations !== prevProps.taxInformations) {
            const { taxInformations } = this.props;

            if (typeof taxInformations === 'object' && !Array.isArray(taxInformations) && 'indexToUpdate' in taxInformations) {
                let updatedTaxInfos = [...this.state.transaction?.taxInfos || []];
                updatedTaxInfos.forEach((taxInfo, index) => {
                    if (index === taxInformations.indexToUpdate) {
                        updatedTaxInfos[index] = {
                            ...updatedTaxInfos[index],
                            taxAmount: taxInformations.taxAmount,
                        };
                    }
                });
                this.setState({ transaction: { ...this.state.transaction, taxInfos: updatedTaxInfos } });

            } else if (Array.isArray(taxInformations)) {
                if (['Purchases', 'Freight & Shipping Costs', 'Freight & Shipping Costs (LTL/FTL)'].includes(this.state.transaction.category)) {
                    this.setState({ transaction: { ...this.state.transaction, taxInfos: taxInformations } });
                }
            }

        }

        if (prevProps.fetchedReferences !== this.props.fetchedReferences && this.props.fetchedReferences.length > 0) {
            this.handleChange({target: {name: "references", value: [...this.props.fetchedReferences]}})
            this.props.storeSupplierReferences([])
        }

        if (this.props.foundInvoice&&this.props.foundInvoice !== prevProps.foundInvoice) {
            // foundInvoice has changed, perform your actions here
            let references = [...this.state.transaction?.references || []];  // create a copy of the references array

            references.forEach((reference, index) => {
                if (reference.invoiceNumber === this.props.foundInvoice.invoiceNumber) {

                    // Update the poNumber and amount of the matched reference
                    references[index] = {
                        ...references[index],
                        poNumber: this.props.foundInvoice.poNumber,  // or any value you want to update to
                        amount: this.props.foundInvoice.invoiceTotal,  // or any value you want to update to
                    };
                }
            });

            // Update the state with the new references array
            // This should trigger a re-render as the state has been updated
            this.setState({ transaction: {...this.state.transaction, references: references } });

        }

        if (this.state.transaction?.references !== undefined && Array.isArray(this.state.transaction.references) && (prevState.transaction?.references !== this.state.transaction?.references || prevState.transaction?.amount !== this.state.transaction.amount)) {
            let total = 0;
            this.state.transaction.references.forEach((reference) => {
                if (reference.amount)
                    total += parseFloat(reference.amount.replaceAll(",", ""));
            });
            this.setState({
                amountExceeded: total > parseFloat(this.state.transaction?.amount?.replaceAll(",","")) + 0.5,
            })
        }

        // if (this.state.transaction?.references !== undefined && Array.isArray(this.state.transaction.references) && (prevState.transaction?.references !== this.state.transaction?.references || prevState.transaction?.amount !== this.state.transaction.amount)) {
        //     let total = 0;
        //     this.state.transaction.references.forEach((reference) => {
        //         if (reference.amount)
        //             total += parseFloat(reference.amount.replaceAll(",", ""));
        //     });
        //
        //
        //     const updatedTransaction = {...this.state.transaction, amount: parseFloat(total).toFixed(2)}
        //     this.setState({  amountExceeded: total > parseFloat(this.state.transaction?.amount?.replaceAll(",","")) + 0.5, transaction: updatedTransaction });
        //
        //
        // }
    }

    handleChange = (e) => {
        const updatedTransaction = {...this.state.transaction, [e.target.name]: e.target.value}
        this.setState({transaction: updatedTransaction})
    }

    handleAmountChange = (e) => {
        const updatedTransaction = {...this.state.transaction, [e.target.name]: this.formatDollar(e.target.value)}
        this.setState({transaction: updatedTransaction})
    }

    disableSave = () => {
        const {date, description, amount, type, references, bankAccount} = this.state.transaction

        const missingFields = (!date || !description || !amount || !type || !bankAccount || !bankAccount.currency)

        return (missingFields)
    }

    handleGeneralFileAdd = (file) => {
        const updatedNewFiles = [...this.state.newFiles]
        let newFile = {
            localURL: URL.createObjectURL(file),
            displayName: file.name,
            byteArray: getByteArray(file),
            type: file.type
        }
        this.setState({
            newFiles: [...updatedNewFiles, newFile],
        })
    }

    formatDollar = (amount, forceTwoDecimals) => { // Regex expression taken from https://stackoverflow.com/questions/2901102

        if(!amount) return
        let value
        if (forceTwoDecimals) {
            value = this.parseNumber(amount).toFixed(2)
        } else {
            // if the amount has more than two decimal places, limit it to two
            if (amount.match(/.*\.\d{2}.*/)) {
                try {
                    value = this.parseNumber(amount).toFixed(2)
                } catch {
                    value = amount.replaceAll(',','')
                }
            } else {
                value = amount.replaceAll(',','')
            }
        }

        return value.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }

    handleGeneralFileDelete = (file, index) => {
        const updatedNewFiles = [...this.state.newFiles]
        updatedNewFiles.splice(index, 1)
        if ("localURL" in file) {
            URL.revokeObjectURL(file.localURL)
        }
        this.setState({
            newFiles: updatedNewFiles
        })
    }

    handleViewingAttachment = (file) => {
        this.props.getTransactionAttachment({
            file: file,
            transaction: this.props.transaction
        }, () => {
            this.props.setCurrentPage(TRANSACTION_PAGES.PREVIEW, this.state.transaction, file)
        })
    }

    handleAddReference = () => {
        const newReference = {
            type: null,
            amount: "",
        }

        const references = this.state.transaction?.references || []

        const updatedTransaction = {
            ...this.state.transaction,
            references: [...references, newReference],
        }

        this.setState({
            transaction: updatedTransaction,
        })
    }

    handleInvoiceNumberChange = (e,index) =>{
        const {name, value} = e.target
        this.props.getInvoiceByInvoiceNumber({value: value})
        let references = [...this.state.transaction?.references || []];
        references[index] = {
            ...references[index],
            poNumber: "",  // or any value you want to update to
            amount: "",  // or any value you want to update to
        };
        this.setState({ transaction: {...this.state.transaction, references: references } });
    }

    handleReferenceChange = (e, index) => {
        const {name, value} = e.target
        const updatedReferences = [...this.state.transaction.references]
        updatedReferences[index] = {...updatedReferences[index], [name]: value}

        const updatedTransaction = {...this.state.transaction, references: updatedReferences}
        this.setState({transaction: updatedTransaction})
    }

    handleRemoveReference = (index) => {
        const updatedReferences = [...this.state.transaction.references]
        updatedReferences.splice(index, 1)
        const updatedTransaction = {...this.state.transaction, references: updatedReferences}
        this.setState({ transaction: updatedTransaction})
    }

    handleSave = () => {
        const transaction = {...this.state.transaction}
        if(transaction.references) {
            transaction.references.map((item, index) => (
                item.amount = item.amount.replace(/,/g, "")
            ))
        }
        if (!(transaction.date instanceof Date)) {
            transaction.date = new Date(transaction.date)
            transaction.date = transaction.date.toISOString()
        }

        transaction.newFiles = [...this.state.newFiles]

        this.props.saveTransaction(transaction, () => {
            this.props.setCurrentPage(TRANSACTION_PAGES.TABLE, {}, null)
        })
    }
    handleTaxInfoChange = (e, index) => {
        const { name, value } = e.target;
        let newStateValue = value;
        const isTaxAmountChange = e.target.name === "taxAmount";

        this.setState(prevState => {
            let transactionCopy = JSON.parse(JSON.stringify(prevState.transaction));
            let currentTaxInfo = { ...transactionCopy.taxInfos[index] };
            if (name === "province" && currentTaxInfo.province === newStateValue) {
                return prevState;
            }

            if (currentTaxInfo.taxType && currentTaxInfo.province && name === "province" && currentTaxInfo.province !== newStateValue) {
                transactionCopy.taxInfos[index] = {
                    taxAmount: "",
                    taxType: "",
                    province: newStateValue
                };
            } else {
                transactionCopy.taxInfos[index][name] = newStateValue;

            }

            return {
                transaction: transactionCopy,
            };
        }, () => {
            let { taxType, province } = this.state.transaction.taxInfos[index];

            if (taxType && province && !isTaxAmountChange) {
                this.props.getTransactionTaxAmount(this.state.transaction.taxInfos[index], this.state.transaction, index);
            }
        });
    }

    removeTaxRow = (index) => {
        this.setState(prevState=>{
            // Shallow copy of transaction
            let transactionCopy = {...prevState.transaction};

            // Remove the item at the given index
            let updatedTaxInfos = transactionCopy?.taxInfos?.filter((_, curIndex) => curIndex !== index);

            // Update the state
            return { transaction: {...transactionCopy, taxInfos: updatedTaxInfos }};
        })
    }

    addTaxInfoRow = () => {
        this.setState(prevState => {
            // Creating a deep copy of the transaction object
            let transactionCopy = JSON.parse(JSON.stringify(prevState.transaction));

            // Initialize taxInfos if it doesn't exist
            if (!transactionCopy.taxInfos) {
                transactionCopy.taxInfos = [];
            }

            // Adding new row
            transactionCopy.taxInfos.push({
                taxAmount: "",
                taxType: "",
                province: ""
            });

            return { transaction: transactionCopy };
        });
    }

    displayGeneralDetails = () => {
        const dateValue = this.state.transaction.date ? new Date(this.state.transaction.date.replace('Z', '')) : null;
        return (
            <div>
                <div style={{display: "flex", justifyContent: "center" }}>
                    <div style={{width: '69%'}}>
                        <div className="transaction-form-details">
                            <TextField
                                variant="outlined"
                                name="placeholder"
                                label="placeholder"
                                InputLabelProps={{ shrink: true }}
                                style={{ width: '4.5%',visibility: "hidden" }}
                                value={""}
                                error={!this.state.transaction.description}
                                disabled={true}
                            />
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    name="date"
                                    label="Date"
                                    value={dateValue}
                                    onChange={(e, value) => this.handleChange({target: {name: "date", value}})}
                                    format="yyyy/MM/dd"
                                    variant="inline"
                                    inputVariant="outlined"
                                    style={{ width: '30%' }}
                                    InputLabelProps={{ shrink: true }}
                                    InputAdornmentProps={{ position: "start" }}
                                    disabled={this.props.transaction.isImportedEntry}
                                    required
                                    error={!this.state.transaction.date}
                                    autoOk
                                />
                            </MuiPickersUtilsProvider>
                            <TextField
                                variant="outlined"
                                name="description"
                                label="Description"
                                InputLabelProps={{ shrink: true }}
                                value={this.state.transaction.description || ""}
                                onChange={(e) => this.handleChange(e)}
                                disabled={this.props.transaction.isImportedEntry}
                                required
                                error={!this.state.transaction.description}
                                style={{ width: '72%' }}
                            />
                            <TextField
                                variant="outlined"
                                name="placeholder"
                                label="placeholder"
                                InputLabelProps={{ shrink: true }}
                                style={{ width: '4.5%',visibility: "hidden" }}
                                value={""}
                                error={!this.state.transaction.description}
                                disabled={true}
                            />
                        </div>
                        <div className="transaction-form-details">

                            <TextField
                                variant="outlined"
                                name="placeholder"
                                label="placeholder"
                                InputLabelProps={{ shrink: true }}
                                style={{ width: '4.5%',visibility: "hidden" }}
                                value={""}
                                error={!this.state.transaction.description}
                                disabled={true}
                            />
                            <Autocomplete
                                options={["Debit", "Credit"]}
                                value={this.state.transaction.type || null}
                                disabled={this.props.transaction.isImportedEntry}
                                style={{ width: '30%' }}
                                onChange={(e, value) => this.handleChange({target: {name: "type", value}})}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        name="type"
                                        label="Type"
                                        variant="outlined"
                                        required
                                        error={!this.state.transaction.type}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                }
                            />
                            <Autocomplete
                                options={this.props.bankAccounts || []}
                                getOptionLabel={(option) => `${option.name} (${option.accountNumber})`}
                                value={this.state.transaction.bankAccount || null}
                                style={{ width: '40%' }}
                                onChange={(e, value) => this.handleChange({target: {name: "bankAccount", value}})}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        name="bankAccount"
                                        label="Bank Account"
                                        variant="outlined"
                                        required
                                        error={!this.state.transaction.bankAccount}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                }
                            />

                            <Autocomplete
                                disabled
                                options={CURRENCY_TYPE_OPTIONS}
                                getOptionLabel={(option) => option}
                                value={this.state.transaction.bankAccount ? this.state.transaction.bankAccount.currency : null}
                                style={{ width: '30%' }}
                                onChange={(e, value) => this.handleChange({target: {name: "currency", value}})}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        name="currency"
                                        label="Currency"
                                        variant="outlined"
                                        required
                                        error={!this.state.transaction.bankAccount}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                }
                            />

                            <TextField
                                variant="outlined"
                                name="placeholder"
                                InputLabelProps={{ shrink: true }}
                                style={{ width: '4.5%',visibility: "hidden" }}
                                value={""}
                                error={!this.state.transaction.description}
                                disabled={true}
                            />

                        </div>
                        <div className="transaction-form-details">
                            <TextField
                                variant="outlined"
                                name="placeholder"
                                label="placeholder"
                                InputLabelProps={{ shrink: true }}
                                style={{ width: '4.5%',visibility: "hidden" }}
                                value={""}
                                error={!this.state.transaction.description}
                                disabled={true}
                            />
                            <TextField
                                variant="outlined"
                                name="referenceId"
                                label="Reference Id"
                                style={{ width: '30%' }}
                                InputLabelProps={{ shrink: true }}
                                value={this.state.transaction.referenceId || ""}
                                onChange={(e) => this.handleChange(e)}
                                error={!this.state.transaction.description}
                            />
                            <Autocomplete
                                options={
                                    (this.props.categories || [])
                                        .slice()
                                        .sort((a, b) => {
                                            const prioritizedCategories = [
                                                'Purchases',
                                                'Freight & Shipping Costs',
                                                'Freight & Shipping Costs (LTL/FTL)'
                                            ];

                                            const indexA = prioritizedCategories.indexOf(a.name);
                                            const indexB = prioritizedCategories.indexOf(b.name);

                                            if (indexA === -1 && indexB === -1) {
                                                // If neither category is prioritized, sort by displayOrder
                                                return a.displayOrder - b.displayOrder;
                                            }
                                            if (indexA === -1) {
                                                return 1;
                                            }
                                            if (indexB === -1) {
                                                return -1;
                                            }
                                            return indexA - indexB;
                                        })
                                }
                                getOptionLabel={(option) => option.name}
                                value={this.props.categories.find(category => category.name === this.state.transaction.category) || null}
                                style={{ width: '40%' }}
                                onChange={(e, value) => this.handleChange({target: {name: "category", value: value?.name}})}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        name="category"
                                        label="Category"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                }
                                disabled={this.checkCategorySelectable(this.state.transaction)}
                            />
                            <TextField
                                variant="outlined"
                                name="amount"
                                label="Amount"
                                style={{ width: '30%' }}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    inputProps: { style: { textAlign: 'right' } } }}
                                value={ this.formatDollar(this.state.transaction.amount)}
                                onChange={(e) => this.handleAmountChange(e)}
                                disabled={this.props.transaction.isImportedEntry}
                                required
                                error={!this.state.transaction.amount}
                            />
                            <TextField
                                variant="outlined"
                                name="placeholder"
                                InputLabelProps={{ shrink: true }}
                                style={{ width: '4.5%',visibility: "hidden" }}
                                value={""}
                                error={!this.state.transaction.description}
                                disabled={true}
                            />
                        </div>
                        <hr style={{marginRight: '4.5%', marginLeft: '4.5%'}}/>
                    </div>
                </div>

                <div style={{display: "flex", justifyContent: "center" }}>
                    <div className="transaction-form-section" style={{width: '69%'}}>
                        <Typography variant="h6" className="transaction-form" style={{padding:'0',marginLeft:'40px',marginTop:'16px'}} >Taxes</Typography>
                        {this.state.transaction?.taxInfos?.map((taxInfo,index)=>(
                            <div className="transaction-form-details" style={{marginTop:'24px'}}>
                                <div style={{ width: '4.5%', display: "flex"}}>
                                    <IconButton  onClick={() => this.removeTaxRow(index)}>
                                        <RemoveCircleIcon
                                            fontSize="small"
                                            color="error"
                                        />
                                    </IconButton>
                                </div>
                                <Autocomplete
                                    options={this.props.stateAndProvince.map((item) => `${item.state} (${item.displayName})`)}
                                    value={this.props.stateAndProvince.find(item => item.state === taxInfo.province) ? `${taxInfo.province}` : ''}
                                    onChange={(event, newValue) => {
                                        const stateCode = newValue?.split(' ')[0];
                                        const eventObj = {target: {name: "province", value: stateCode}};
                                        this.handleTaxInfoChange(eventObj, index);
                                    }}
                                    style={{ width: '30%' }}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            name="province"
                                            label="Province"
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    }
                                />

                                <Autocomplete
                                    options={
                                        this.props.taxTypewithStateProvince[taxInfo.province] ?
                                            this.props.taxTypewithStateProvince[taxInfo.province] :
                                            TRANSACTION_TAX_TYPES
                                    }
                                    value={taxInfo.taxType}
                                    style={{ width: '40%' }}
                                    onChange={(e, value) => this.handleTaxInfoChange({target: {name: "taxType", value: value}}, index)}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            name="taxType"
                                            label="Tax Type"
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    }
                                />
                                <TextField
                                    variant="outlined"
                                    name="taxAmount"
                                    label="Tax Amount"
                                    style={{ width: '30%' }}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        inputProps: { style: { textAlign: 'right' } } }}
                                    value={this.formatDollar(taxInfo.taxAmount) || ""}
                                    onChange={(e) => this.handleTaxInfoChange({target: {name: "taxAmount", value: this.formatDollar(e.target.value)}}, index)}
                                />
                                <TextField
                                    variant="outlined"
                                    name="placeholder"
                                    label="placeholder"
                                    InputLabelProps={{ shrink: true }}
                                    style={{ width: '4.5%',visibility: "hidden" }}
                                    value={""}
                                    error={!this.state.transaction.description}
                                    disabled={true}
                                />
                            </div>
                        ))}
                        <div style={{marginTop: '30px'}}>
                            <IconButton onClick={() => this.addTaxInfoRow()}>
                                <AddCircleIcon
                                    fontSize="small"
                                    color='secondary'
                                />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    // checks if category is disbled by checking if there is any split transaction selected
    checkCategorySelectable = (transaction) => {
        return transaction?.references?.some(reference => reference.type == "Split Transaction") || false;
    }

    displayNotesFields = () => {
        return (
            <div style={{display: "flex", justifyContent: "center" }}>
                <div className="transaction-form-section" style={{width: '60%'}}>
                    <hr/>
                    <div className="transaction-form-notes">
                        <TextField
                            variant="outlined"
                            name="notes"
                            label="Notes"
                            InputLabelProps={{ shrink: true }}
                            value={this.state.transaction.notes || ""}
                            onChange={(e) => this.handleChange(e)}
                            style={{ width: "inherit" }}
                        />
                    </div>
                </div>
            </div>
        )
    }

    displayAttachments = () => {
        return (
            <div className="transaction-form-section">
                <hr/>
                <Typography variant="h6">Attachments</Typography>
                <div className="transaction-form-attachments">
                    <div className="files-display">
                        {this.props.transaction?.files?.map((file, index) => {
                            return (
                                <div className="pdf-icon" key={index}>
                                    <div style={{position: 'relative'}}>
                                        <IconButton style={{position: "relative"}} aria-label='previous' onClick={() => this.handleViewingAttachment(file)}>
                                            <DescriptionIcon variant='outlined' fontSize='large' />
                                        </IconButton>
                                        <div style={{position: "absolute", top: "50px", left: "10px", fontSize: "15px", color: "black"}}>
                                            {file}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <FileUpload
                        handleFileAdd={this.handleGeneralFileAdd}
                        handleFileDelete={this.handleGeneralFileDelete}
                        files={this.state.newFiles || []}
                        singleFileUpload={true}
                        isPackingSlip={true}
                    />
                </div>
            </div>
        )
    }

    calculateReferenceTotal = (references) =>{
        if (!references || references.length === 0)
            return "0.00"
        const total = references.reduce((sum, reference) => {
            return sum + (parseFloat(reference.amount.replaceAll(",","")) || 0);
        }, 0);

        return this.formatDollar(total.toFixed(2));
    }

    displayReferenceEntry = (referenceType, reference, index) => {
        switch (referenceType) {
            case SUPPLIER_INVOICE:
                return <>
                    <TextField
                        variant="outlined"
                        name="invoiceNumber"
                        label="Invoice Number"
                        InputLabelProps={{ shrink: true }}
                        value={reference.invoiceNumber || ""}
                        onBlur={(e) => this.handleInvoiceNumberChange(e, index)}
                        onChange={(e) => this.handleReferenceChange(e, index)}
                    />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            name="invoiceDate"
                            label="Invoice Date"
                            value={parseISO(reference.invoiceDate) || ""}
                            onChange={(date) => {
                                const invoiceDate = format(date, 'yyyy-MM-dd');
                                this.handleReferenceChange({target: {name: "invoiceDate", value: invoiceDate}},index)
                            }}
                            format="yyyy-MM-dd"
                            variant="inline"
                            inputVariant="outlined"
                            InputLabelProps={{ shrink: true }}
                            InputAdornmentProps={{ position: "start" }}
                            autoOk
                        />
                    </MuiPickersUtilsProvider>
                    <TextField
                        variant="outlined"
                        name="poNumber"
                        label="PO Number"
                        InputLabelProps={{ shrink: true }}
                        value={reference.poNumber || ""}
                        onChange={(e) => this.handleReferenceChange(e, index)}
                    />
                    <TextField
                        variant="outlined"
                        name="amount"
                        label="Amount"
                        InputLabelProps={{ shrink: true }}
                        value={this.formatDollar(reference.amount) || ""}
                        InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            inputProps: { style: { textAlign: 'right' } } }}
                        onChange={(e) => this.handleReferenceChange(e, index)}
                    />
                </>
            case CARRIER_INVOICE:
                return <>
                    <TextField
                        variant="outlined"
                        name="invoiceNumber"
                        label="Invoice Number"
                        InputLabelProps={{ shrink: true }}
                        value={reference.invoiceNumber || ""}
                        onBlur={(e) => this.handleInvoiceNumberChange(e, index)}
                        onChange={(e) => this.handleReferenceChange(e, index)}
                    />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            name="invoiceDate"
                            label="Invoice Date"
                            value={parseISO(reference.invoiceDate) || ""}
                            onChange={(date) => {
                                const invoiceDate = format(date, 'yyyy-MM-dd');
                                this.handleReferenceChange({target: {name: "invoiceDate", value: invoiceDate}},index)
                            }}
                            format="yyyy-MM-dd"
                            variant="inline"
                            inputVariant="outlined"
                            InputLabelProps={{ shrink: true }}
                            InputAdornmentProps={{ position: "start" }}
                            autoOk
                        />
                    </MuiPickersUtilsProvider>
                    <TextField
                        variant="outlined"
                        name="carrierName"
                        label="Carrier Name / Account"
                        InputLabelProps={{ shrink: true }}
                        value={reference.carrierName || ""}
                        onChange={(e) => this.handleReferenceChange(e, index)}
                    />
                    <TextField
                        variant="outlined"
                        name="amount"
                        label="Amount"
                        InputLabelProps={{ shrink: true }}
                        value={this.formatDollar(reference.amount) || ""}
                        InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            inputProps: { style: { textAlign: 'right' } } }}
                        onChange={(e) => this.handleReferenceChange(e, index)}
                    />
                </>
            case FTL_INVOICE:
                return <>
                    <TextField
                        variant="outlined"
                        name="invoiceNumber"
                        label="Invoice Number"
                        InputLabelProps={{ shrink: true }}
                        value={reference.invoiceNumber || ""}
                        onBlur={(e) => this.handleInvoiceNumberChange(e, index)}
                        onChange={(e) => this.handleReferenceChange(e, index)}
                    />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            name="invoiceDate"
                            label="Invoice Date"
                            value={parseISO(reference.invoiceDate) || ""}
                            onChange={(date) => {
                                const invoiceDate = format(date, 'yyyy-MM-dd');
                                this.handleReferenceChange({target: {name: "invoiceDate", value: invoiceDate}},index)
                            }}
                            format="yyyy-MM-dd"
                            variant="inline"
                            inputVariant="outlined"
                            InputLabelProps={{ shrink: true }}
                            InputAdornmentProps={{ position: "start" }}
                            autoOk
                        />
                    </MuiPickersUtilsProvider>
                    <TextField
                        variant="outlined"
                        name="carrierName"
                        label="Carrier Name"
                        InputLabelProps={{ shrink: true }}
                        value={reference.carrierName || ""}
                        onChange={(e) => this.handleReferenceChange(e, index)}
                    />
                    <TextField
                        variant="outlined"
                        name="amount"
                        label="Amount"
                        InputLabelProps={{ shrink: true }}
                        value={this.formatDollar(reference.amount) || ""}
                        InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            inputProps: { style: { textAlign: 'right' } } }}
                        onChange={(e) => this.handleReferenceChange(e, index)}
                    />
                </>
            case SPLIT_TRANSACTION:
                return <>
                    <Autocomplete
                        options={this.props.categories || []}
                        value={this.props.categories?.find(cat => cat.name === reference.category)  || null}
                        getOptionLabel={(option) => option.name}
                        onChange={(e, value) => this.handleReferenceChange({target: {name: "category", value: value?.name}}, index)}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                name="category"
                                label="Category"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                            />
                        }
                    />
                    <TextField
                        variant="outlined"
                        name="amount"
                        label="Amount"
                        InputLabelProps={{ shrink: true }}
                        value={this.formatDollar(reference.amount) || ""}
                        InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            inputProps: { style: { textAlign: 'right' } } }}
                        onChange={(e) => this.handleReferenceChange(e, index)}
                    />
                    {reference?.transactionId && <Tooltip title="Save and navigate to split transaction">
                        <IconButton onClick={() => this.props.saveAndNavigateToChildTransaction(index, this.state.transaction)} style={{width:"35px", heightL:"15px"}}>
                            <ArrowForwardIcon
                                color='secondary'
                            />
                        </IconButton>
                    </Tooltip>}
                </>
            default:
                return <TextField
                    variant="outlined"
                    name="amount"
                    label="Amount"
                    InputLabelProps={{ shrink: true }}
                    value={this.formatDollar(reference.amount) || ""}
                    InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        inputProps: { style: { textAlign: 'right' } } }}
                    onChange={(e) => this.handleReferenceChange(e, index)}
                />
        }
    }

    displayReferences = () => {
        return (
            <div className="transaction-form-section">
                <hr/>
                <Typography variant="h6">References</Typography>
                {this.state.transaction?.references?.map((reference, index) => (
                    <div key={index} className="transaction-form-details" style={{justifyContent: 'start'}}>
                        <IconButton style={{width: 'inherit'}} onClick={() => this.handleRemoveReference(index)}>
                            <RemoveCircleIcon fontSize="small" color="error"/>
                        </IconButton>
                        <Autocomplete
                            options={this.state.transaction?.isSubTransaction ? CHILD_REFERENCE_TYPES : PARENT_REFERENCE_TYPES}
                            value={reference.type || null}
                            onChange={(e, value) => this.handleReferenceChange({target: {name: "type", value}}, index)}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    name="type"
                                    label="Reference Type"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            }
                        />

                        {this.displayReferenceEntry(reference.type, reference, index)}
                    </div>
                ))}
                <div style={{marginTop: '30px'}}>
                    <IconButton onClick={() => this.handleAddReference()}>
                        <AddCircleIcon
                            fontSize="small"
                            color='secondary'
                        />
                    </IconButton>
                </div>
                {this.displayTotalAmount(this.state.transaction?.references)}
            </div>
        )
    }

    displayTotalAmount = (references) =>{
        if(references && references.length > 0) {
            return (<div className="transaction-form-details" style={{justifyContent: 'start',marginTop: '15px'}}>
                <IconButton style={{width: 'inherit', visibility: 'hidden' }}>
                    <RemoveCircleIcon fontSize="small" color="error"/>
                </IconButton>
                <TextField
                    style={{visibility: 'hidden' }}
                    InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                />
                <TextField
                    style={{visibility: 'hidden' }}
                    InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                />
                <TextField
                    style={{visibility: 'hidden' }}
                    InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                />
                <TextField
                    style={{visibility: 'hidden' }}
                    InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                />
                <TextField
                    variant="outlined"
                    name="amount"
                    label="Total Amount"
                    InputLabelProps={{ shrink: true }}
                    value={this.calculateReferenceTotal(references)}
                    InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        inputProps: { style: { textAlign: 'right' } } }}
                    error={this.state.amountExceeded}
                    helperText={this.state.amountExceeded ? "Sum of references amount exceeded total amount" :""}
                />
            </div>)
        }
    }
    displayFormButtons = () => {
        return (
            <div className='transaction-form-buttons'>
                <Button
                    variant='contained'
                    onClick={() => {this.props.setCurrentPage(TRANSACTION_PAGES.TABLE, {})}}
                >
                    {CANCEL}
                </Button>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={this.handleSave}
                    disabled={this.disableSave()}
                >
                    {SAVE_TRANSACTION}
                </Button>
            </div>
        )
    }

    render() {
        return (
            <React.Fragment>
                <TopToolbar
                    pageName={Object.keys(this.props.transaction).length > 0 ? EDIT_TRANSACTION_PAGE_NAME : ADD_TRANSACTION_PAGE_NAME}
                    menuItems={[
                        {
                            title: BACK,
                            icon: <KeyboardBackspaceIcon/>,
                            onClick: () => {this.props.setCurrentPage(TRANSACTION_PAGES.TABLE, {})}
                        },
                        {
                            title: SAVE_TRANSACTION,
                            icon: <SaveIcon/>,
                            onClick: () => {this.handleSave()},
                            disabled: this.disableSave()
                        },
                        {
                            title: GO_TO_PARENT_TRANSACTION,
                            icon: <ArrowUpwardIcon/>,
                            onClick: () => {this.props.navigateToParentTransaction(this.props.transaction?.parentTransactionId)},
                            disabled: this.props.transaction?.parentTransactionId == null
                        }
                    ]}
                />
                <div className="transaction-form">
                    {this.displayGeneralDetails()}
                    {this.displayNotesFields()}
                    {this.displayAttachments()}
                    {this.displayReferences()}
                    {this.displayFormButtons()}
                </div>
            </React.Fragment>
        )
    }
}

const actionCreators = {
    saveTransaction,
    getTransactionAttachment,
    getSupplierReferences,
    storeSupplierReferences,
    getInvoiceByInvoiceNumber,
    getTransactionTaxAmount,
    navigateToParentTransaction,
}

const mapStateToProps = (state) => ({
    fetchedReferences: fetchedReferencesSelector(state),
    foundInvoice: foundInvoiceSelector(state),
    taxInformations: taxInfosSelector(state),
    taxTypewithStateProvince: taxTypewithStateProvinceSelector(state)
})

export default withShipment({
    actionCreators,
    mapStateToProps,
}, TransactionsForm);