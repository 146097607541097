import {
    STORE_FULL_TRUCKLOAD, STORE_FULL_TRUCKLOAD_BROKERS, STORE_FULL_TRUCKLOAD_CARRIERS,
    STORE_FULL_TRUCKLOAD_FILE, STORE_FULL_TRUCKLOAD_DOCUMENT,
    STORE_FULL_TRUCKLOAD_SETTINGS, STORE_FULL_TRUCKLOAD_OPERATIONS_RESPONSE, STORE_FULL_TRUCKLOAD_UNAVAILABLE_ITEMS
} from "../../constants/fullTruckload";
import {previewingFullTruckloadDataSelector} from "../../selectors/fullTruckload";

const initialState = {
    fullTruckloads: [],
    fullTruckloadSettings: {},
    fullTruckloadCarriers: [],
    fullTruckloadBrokers: [],
    previewingFullTruckloadData: [],
    operationResponse:"",
    unavailableItems:[]
}

const addPreviewToFullTruckloadSettings = (updatedSettings, index, oldSettings) => {
    let filePreviewInfo = updatedSettings.attachments.pop()
    let previewedFileIndex = index
    let previewData = new Uint8Array(filePreviewInfo.previewFile)
    let blobData = new Blob([previewData])
    if (index === -1) {
        updatedSettings.billOfLading["localURL"] = URL.createObjectURL(blobData)
        updatedSettings.billOfLading['byteArray'] = previewData

        oldSettings.billOfLading = updatedSettings.billOfLading
    }
    else {
        updatedSettings.attachments[previewedFileIndex]['file']["localURL"] = URL.createObjectURL(blobData)
        updatedSettings.attachments[previewedFileIndex]['file']['byteArray'] = previewData

        oldSettings.attachments[previewedFileIndex] = updatedSettings.attachments[previewedFileIndex]
    }

    return {...oldSettings}
}

const addPreviewToFullTruckload = (updatedFullTruckload, fullTruckloads) => {
    let filePreviewInfo = updatedFullTruckload.finalDocument
    let previewData = new Uint8Array(filePreviewInfo.previewFile)
    let blobData = new Blob([previewData])

    updatedFullTruckload.finalDocument["localURL"] = URL.createObjectURL(blobData)
    updatedFullTruckload.finalDocument['byteArray'] = previewData

    for (let i = 0; i < fullTruckloads.length; i++) {
        if (fullTruckloads[i].fullTruckloadId === updatedFullTruckload.fullTruckloadId) {
            fullTruckloads[i].finalDocument = updatedFullTruckload.finalDocument
            break
        }
    }

    return [...fullTruckloads]
}

export default (state = initialState, action) => {
    switch (action.type) {
        case STORE_FULL_TRUCKLOAD:
            return {
                ...state,
                fullTruckloads: action.data
            }
        case STORE_FULL_TRUCKLOAD_SETTINGS:
            return {
                ...state,
                fullTruckloadSettings: action.data
            }
        case STORE_FULL_TRUCKLOAD_FILE:
            return {
                ...state,
                fullTruckloadSettings: addPreviewToFullTruckloadSettings(action.fullTruckloadSettings, action.index, state.fullTruckloadSettings)
            }
        case STORE_FULL_TRUCKLOAD_DOCUMENT:
            return {
                ...state,
                previewingFullTruckloadData: action.data
            }
        case STORE_FULL_TRUCKLOAD_CARRIERS:
            return {
                ...state,
                fullTruckloadCarriers: action.data
            }
        case STORE_FULL_TRUCKLOAD_BROKERS:
            return {
                ...state,
                fullTruckloadBrokers: action.data
            }
        case STORE_FULL_TRUCKLOAD_OPERATIONS_RESPONSE:
            return {
                ...state,
                operationResponse: action.message
            }
        case STORE_FULL_TRUCKLOAD_UNAVAILABLE_ITEMS:
            return {
                ...state,
                unavailableItems: action.items ?? []
            }
        default:
            return state;
    }
}