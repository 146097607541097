import React, { Component } from "react";
import "./Login.css";
import Alert from '@material-ui/lab/Alert'
import {CircularProgress, IconButton, Typography} from "@material-ui/core";

// redux
import {
    isAuthenticatedSelector,
    tokenSelector,
} from "../../redux/selectors/auth";
import {
    authLogin,
} from "../../redux/actions/auth";
import withShipment from "../../withShipment";
import ErrorFeedbackBar from "../../components/global/ErrorFeedbackBar";
import MenuIcon from "@material-ui/icons/Menu";
import { Toolbar } from "@material-ui/core";
import {TextField} from "@material-ui/core";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            display: 'none',
            loading: false,
        };
    }

    redirect = (path) => {
        this.props.history.push(path)
    }

    componentDidMount() {
    }

    handle = (event) => {
        event.preventDefault();
        const state = { ...this.state };
        state[event.target.name] = event.target.value;
        this.setState(state);
    };

    handleLogin = (event) => {
        event.preventDefault();
        this.setState({ loading: true })
        this.props.authLogin(this.state.username, this.state.password, this.props.history)

        setTimeout(() => {
            if (!this.props.isAuthenticated) {
                this.setState({
                    display: '',
                    loading: false
                })
            }
        }, 3000)
    };

    render() {
        return (
            <div style={{height: "90vh", display: "flex", flexDirection: "column"}}>
                <div style={{marginTop: "auto", marginBottom: "auto"}}>
                    {/*<div className="top-nav">
                    <div className='menu-toggle-name'>
                        <a style={{fontSize: "20px", color: "white", textDecoration: "none", marginTop: "20px", marginBottom: "20px", marginLeft: "65px", marginRight: "20px"}} href='#'>
                            Firebolt
                        </a>
                    </div>
                </div>
                <div className='toolbar'>
                    <Toolbar>
                        <Typography className="titleTypography" variant="h6" id="tableTitle">
                            Login
                        </Typography>
                    </Toolbar>
                </div>*/}

                    <div className="col-12 login-container" >
                        <ErrorFeedbackBar />
                        <div className="col-12 col-md-4">
                            <div style={{marginBottom: "20px", textAlign: "center"}}>
                                <Typography variant='h5'>
                                    Login to Firebolt
                                </Typography>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="form-group" style={{marginBottom: "40px"}}>
                                        <TextField variant="outlined" className="form-control" InputLabelProps={{ shrink: true }} label="Username" name="username" onChange={this.handle} value={this.state.username} />
                                    </div>
                                    <div className="form-group" style={{marginBottom: "50px"}}>
                                        <TextField className="form-control" variant="outlined" InputLabelProps={{ shrink: true }} label="Password" name="password" type="password" onChange={this.handle} value={this.state.password} />
                                    </div>
                                    {!this.state.loading ? <><Alert style={{ display: this.state.display }} severity="error">Login Failed. Username and
                                        Password do not match. Please try again.</Alert>
                                        <div className="form-group text-center">
                                            <button className="btn btn-primary btn-block standard-margin-bottom" style={{backgroundColor: "#acd685", borderColor: "#acd685"}} onClick={this.handleLogin}>Login</button>
                                            <a href="/#">Forgot Password?</a>
                                        </div></> : <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}><CircularProgress /></div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: isAuthenticatedSelector(state),
    token: tokenSelector(state),
})

const actionCreators = {
    authLogin,
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, Login)