export const CUSTOMERS_TOOLBAR_TITLE = 'Customers';
export const CREATE_CUSTOMER_TITLE = 'Add a customer';
export const BACK_TO_MENU = 'Back to Menu';

export const MANAGE_CUSTOMERS_HEAD_CELLS = [
    { id: 'name', center: true, disablePadding: false, label: 'Name'},
    { id: 'addressLine1', center: false, disablePadding: false, label: 'Address' },
    { id: 'phone', center: false, disablePadding: false, label: 'Phone Number' },
]

export const DEFAULT_ROWS_PER_PAGE = 50;
export const DEFAULT_SORT_BY_HEAD_CELL = "name"

export const ROWS_PER_PAGE_LABEL = "Customers per page"

export const CURRENCY_TYPE_OPTIONS = [
    "CAD",
    "USD",
];

export const COUNTRY_OPTIONS = [
    "Canada",
    "United States",
];

export const STEPPER_HEADINGS = ["Customer Info", "Customer Products"]
export const STEPPER_HEADING_DESCRIPTIONS = ["Add customer information", "Add products to this customer"]

export const STEP_CUSTOMER_INFO_INDEX = 0
export const STEP_CUSTOMER_PRODUCTS_INDEX = 1

