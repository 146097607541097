import React from 'react';


import PropTypes from "prop-types";

// styling
import "./CustomTableHeader.css"
import SearchField from "../Search/SearchField";
import {Slide, Tab, Tabs} from '@material-ui/core';
import LocationSelectBox from "../subcomponents/locationSelectBox/LocationSelectBox";

class CustomTableHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div>
                {this.props.isPurchaseOrderTable ?
                    <>
                        <div className=''>
                            <div className="po-header-filter-row">
                                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                    <div className='po-header-search-field'>
                                        <div className='po-search-field'>
                                            <SearchField onChange={this.props.onSearchValueChange} searchText={this.props.searchText} />
                                        </div>
                                    </div>
                                    <div className='table-header-filter-icon'>
                                        {this.props.filterIcon}
                                    </div>
                                </div>
                                <div className='po-header-location-field'>
                                    <div>
                                        {this.props.location}
                                    </div>
                                </div>
                            </div>
                            <div className='po-header-filter-row'>
                                <div className='po-header-filters'>
                                    {this.props.customFilterField}
                                </div>
                                <div className='po-header-pagination'>
                                    {this.props.pagination}
                                </div>
                            </div>
                        </div>
                        <div className={'date-filters-container'}>
                            <>
                                {this.props.filtersComponent}
                            </>
                        </div>
                    </> :
                    <>
                        {this.props.dateFilter ?
                            <>
                                <div className='table-header-grid-dates'>
                                    <div className='table-header-search-field'>
                                        <div className='search-field'>
                                            <SearchField onChange={this.props.onSearchValueChange} searchText={this.props.searchText} />
                                        </div>
                                        <div className='table-header-filter-icon'>
                                            {this.props.filterIcon}
                                        </div>
                                    </div>
                                    <div className='table-header-search-field' />
                                    <div className='table-header-date-field'>
                                        {this.props.dateFilter}
                                    </div>

                                    <div className='table-header-search-field'>
                                        {this.props.customFilterField}
                                    </div>
                                    <div className='table-header-search-field' />
                                    <div className='custom-pagination-field'>
                                        {this.props.pagination}
                                    </div>
                                </div>
                            </>
                            :
                            this.props.location ?
                                <>
                                    <div className='table-header-grid-location'>
                                        <div className='table-header-search-field'>
                                            <div className='search-field'>
                                                <SearchField onChange={this.props.onSearchValueChange} searchText={this.props.searchText} />
                                            </div>
                                            <div className='table-header-filter-icon'>
                                                {this.props.filterIcon}
                                            </div>
                                        </div>
                                        <div className='table-header-date-field'>
                                            {this.props.dateFilter}
                                        </div>
                                        <div className='location-field'>
                                            {this.props.location}
                                        </div>
                                        {this.props.customFilterField ?
                                            <div className='table-header-search-field'>
                                                {this.props.customFilterField}
                                            </div> :
                                            null
                                        }
                                    </div>
                                    <div className={'date-filters-container'}>
                                        <>
                                            {this.props.filtersComponent}
                                        </>
                                    </div>
                                </>
                                :
                                this.props.showTabs ?
                                    <>
                                        <div className='table-header-grid'>
                                            <div className='table-header-search-field'>
                                                <div className='po-search-field'>
                                                    <SearchField onChange={this.props.onSearchValueChange} searchText={this.props.searchText} />
                                                </div>
                                                <div className='table-header-filter-icon'>
                                                    {this.props.filterIcon}
                                                </div>
                                            </div>
                                            <div />
                                            <div className='table-header-date-field'>
                                                {/* {this.props.dateFilter} */}
                                            </div>
                                            <div style={{marginTop: "5px", marginBottom: "-18px"}}>
                                                <Tabs
                                                    value={this.props.tabIndex}
                                                    onChange={this.props.onTabChange}
                                                    indicatorColor="none"
                                                    textColor="none"
                                                    width={"40%"}
                                                    variant="scrollable"
                                                    style={{ maxWidth: "710px", marginLeft: '33px', left: "0px", position: "absolute" }}
                                                >
                                                    <Tab
                                                        value={0}
                                                        label={"Supplier Templates"}
                                                        className={this.props.showSupplier ? "invoice-template-tab" : "invoice-template-tab-not-selected"}
                                                    />
                                                    <Tab
                                                        value={1}
                                                        label={"Carrier Templates"}
                                                        className={this.props.showSupplier ? "invoice-template-tab-not-selected" : "invoice-template-tab"}
                                                    />
                                                </Tabs>
                                            </div>
                                            <div/>
                                            <div/>
                                            <div className="table-header-bottom-field">
                                                {this.props.customFilterField}
                                            </div>
                                            <div className="table-header-bottom-field" />
                                            <div className='custom-pagination-field'>
                                                {this.props.pagination}
                                            </div>
                                        </div>
                                        <div className={'date-filters-container'}>
                                            <>
                                                {this.props.filtersComponent}
                                            </>
                                        </div>
                                    </> :
                                <>
                                    <div className='table-header-grid'>
                                        <div className='table-header-search-field'>
                                            <div className='po-search-field'>
                                                <SearchField onChange={this.props.onSearchValueChange} searchText={this.props.searchText} />
                                            </div>
                                            <div className='table-header-filter-icon'>
                                                {this.props.filterIcon}
                                            </div>
                                        </div>
                                        <div />
                                        <div className='table-header-date-field'>
                                            {/* {this.props.dateFilter} */}
                                            {this.props.locationField ?
                                                <div style={{margin: "20px", marginRight: "10px"}}>
                                                    {this.props.locationField}
                                                </div>
                                                :null}
                                        </div>

                                        <div className="table-header-bottom-field">
                                            {this.props.customFilterField}
                                        </div>
                                        <div className="table-header-bottom-field" />
                                        <div className='custom-pagination-field'>
                                            {this.props.pagination}
                                        </div>
                                    </div>
                                    <div className={'date-filters-container'}>
                                        <>
                                            {this.props.filtersComponent}
                                        </>
                                    </div>
                                </>
                        }
                    </>
                }
            </div>
        );
    }

}

CustomTableHeader.propTypes = {

    onSearchValueChange: PropTypes.func,
    filterIcon: PropTypes.elementType,
    customFilterField: PropTypes.elementType,
    pagination: PropTypes.elementType,

}

CustomTableHeader.defaultProps = {

    onSearchValueChange: () => { },
    filterIcon: <></>,
    customFilterField: <></>,
    pagination: <></>

}
export default CustomTableHeader;