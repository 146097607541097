import React from "react";
import {Layout} from "../../global/Table/TablePageHelpers/LayoutObject";
import {
    PRICING_DASHBOARD_PAGE_NAME,
    REFRESH_PRICING,
    PRICING_DASHBOARD_TABLE_PAGINATION_LABEL,
    PRICING_DASHBOARD_TABLE_CELLS, SKU_FILTER_NAMES, PRICING_DASHBOARD_PAGES
} from "../constants";
import withShipment from "../../../withShipment";
import './PricingDashboardTable.css';
import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";
import RefreshIcon from "@material-ui/icons/Refresh";
import TablePage from "../../global/Table/TablePage";
import {marketplacesSelector, pricingListingsSelector} from "../../../redux/selectors/settings";
import PricingDashboardTableRow from "./PricingDashboardTableRow";
import {fetchPricingListings, fetchAmazonListingsPrice} from "../../../redux/actions/settings";
import {pricingDashboardLoadingSelector} from "../../../redux/selectors/global";
import ErrorFeedbackBar from "../../global/ErrorFeedbackBar";
import {Filter} from "../../global/Table/TablePageHelpers/FilterObject";
import PropTypes from "prop-types";
import {manufacturersSelector} from "../../../redux/selectors/inventory";
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {Tooltip} from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';

class PricingDashboardTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableEvents: [],
            listingIdBeingEdited: '',
            marketplaceOptions: this.props.marketplaces ? this.props.marketplaces.map(marketplace => marketplace.marketplaceName) : [],
            brandOptions: this.props.manufacturers ? this.props.manufacturers : [],
        }
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevProps.marketplaces !== this.props.marketplaces && this.props.marketplaces) {

            let newMarketplaceOptions

            newMarketplaceOptions = this.props.marketplaces.map(marketplace => {
                return marketplace.marketplaceName
            })
            this.setState({
                marketplaceOptions: newMarketplaceOptions
            })
        }
        if (prevProps.manufacturers !== this.props.manufacturers && this.props.manufacturers) {

            let newbrandOptions

            newbrandOptions = this.props.manufacturers.map(manufacturers => {
                return manufacturers
            })
            this.setState({
                brandOptions: newbrandOptions
            })
        }
        if (this.props.pricingDashboardLoading) {
            this.props.setCurrentPage(PRICING_DASHBOARD_PAGES.LOADING)
        }
    }

    getTableHeaders = () => {
        let headers = []
        headers.push(Layout.columnHead(null,null,null))
        PRICING_DASHBOARD_TABLE_CELLS.forEach((headCell) => {
            headers.push(Layout.columnHead(headCell.label, headCell.id, "left"))
        })
        return headers
    }

    handleRefresh = () => {
        this.props.fetchAmazonListingsPrice()
    }

    getSearchStrings = (row) => {
        let searchStrings = []
        if (row.price)
            searchStrings.push(row.price)
        if (row.shortcode)
            searchStrings.push(row.shortcode)
        if (row.sku)
            searchStrings.push(row.sku)
        if (row.asin)
            searchStrings.push(row.asin)
        if (row.marketplaces[0])
            searchStrings.push(row.marketplaces[0])
        if (row.brand)
            searchStrings.push(row.brand)
        if (row.relatedProductsAsins)
            searchStrings.push(row.relatedProductsAsins)

        return searchStrings
    }

    getTableRow = (row, getRowState, setRowState) => {
        return <PricingDashboardTableRow
            row={row}
            getRowState={getRowState}
            setRowState={setRowState}
            setCurrentPage={this.props.setCurrentPage}
            listingIdBeingEdited={this.state.listingIdBeingEdited}
            setListingIdBeingEdited={(newId) => this.setState({listingIdBeingEdited: newId})}
        />
    }

    render() {
        return (
            <React.Fragment>
                <ErrorFeedbackBar />
                <TopToolbar
                    pageName={PRICING_DASHBOARD_PAGE_NAME}
                    menuItems={[
                        {title: REFRESH_PRICING, icon: <RefreshIcon/>, onClick: () => {this.handleRefresh()}}
                    ]}
                />
                <TablePage
                    tableName="PricingDashboardTable"
                    eventsVariable={this.state.tableEvents}
                    setEventsVariable={(newState) => {this.setState({tableEvents: newState})}}
                    tableColumnHeads={this.getTableHeaders()}
                    tableRows={this.props.tableRows}
                    rowFetcher={(filters) => {
                        this.props.fetchPricingListings()
                    }}
                    tableRow={(row, index, getRowState, setRowState) => this.getTableRow(row, getRowState, setRowState)}
                    getRowId={(row) => row.webstoreProductId}
                    onRowDoubleClick={(row) => this.setState({listingIdBeingEdited: row.webstoreProductId})}
                    paginationText={PRICING_DASHBOARD_TABLE_PAGINATION_LABEL}
                    hasSearchField
                    searchStrings={this.getSearchStrings}

                    filters={[
                        Filter.createFilter("marketplace", (row) => row.marketplaces, null, Filter.relations.containsMemberOf, true),
                        Filter.createFilter("brand", (row) => row.brand, null, Filter.relations.containedBy, true),
                        Filter.createFilter(SKU_FILTER_NAMES.SINGLE_BUY_BOX_WINNER, (row) => (!row.isBuyBoxWinner || row.has2BuyBoxWinners), false, Filter.relations.equals, false),
                        Filter.createFilter(SKU_FILTER_NAMES.TWO_BUY_BOX_WINNERS, (row) => (!row.isBuyBoxWinner || !row.has2BuyBoxWinners), false, Filter.relations.equals, false)
                    ]}

                    filterCheckboxes={[
                        Layout.checkBox(SKU_FILTER_NAMES.SINGLE_BUY_BOX_WINNER,
                            <div>show
                                <ThumbUpAltIcon style={{ marginBottom: 5, marginLeft: 2, color: 'black' }}/>
                                <Tooltip title="show listing that has 1 buy box winner">
                                    <InfoIcon style={{ marginLeft: 4, fontSize: 'medium',color: 'gray' }} />
                                </Tooltip>
                            </div>,
                            <CheckBoxIcon style={{ color: '#acd685' }}/>,
                            <CheckBoxOutlineBlankOutlinedIcon style={{ color: 'gray' }}/>
                        ),
                        Layout.checkBox(SKU_FILTER_NAMES.TWO_BUY_BOX_WINNERS,
                            <div>show
                                <ThumbUpAltIcon style={{ marginBottom: 5, marginLeft: 2, color: 'gray' }}/>
                                <Tooltip title="show listing that has 2 buy box winners">
                                    <InfoIcon style={{ marginLeft: 4, fontSize: 'medium',color: 'gray' }} />
                                </Tooltip>
                            </div>,
                            <CheckBoxIcon style={{ color: '#acd685' }}/>,
                            <CheckBoxOutlineBlankOutlinedIcon style={{ color: 'gray' }}/>
                        )
                    ]}

                    filterDropdown={Layout.newLayout(1, [
                        Layout.row([
                            Layout.rowElement(1, "marketplace", "Marketplace", Layout.elementStyle.checkboxDropdown, this.state.marketplaceOptions)
                        ]),
                        Layout.row([
                            Layout.rowElement(1, "brand", "Brand", Layout.elementStyle.checkboxDropdown, this.state.brandOptions)
                        ])
                    ])}

                />
            </React.Fragment>
        )
    }
}

PricingDashboardTable.defaultProps = {
    tableRows: [],
    manufacturers: [],
}

PricingDashboardTable.propTypes = {
    manufacturers: PropTypes.array,
}

const mapStateToProps = (state) => ({
    tableRows: pricingListingsSelector(state),
    pricingDashboardLoading: pricingDashboardLoadingSelector(state),
    marketplaces: marketplacesSelector(state),
    manufacturers: manufacturersSelector(state),
})
const actionCreators = {
    fetchPricingListings,
    fetchAmazonListingsPrice
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, PricingDashboardTable);