import React from "react";
import withShipment from "../../../withShipment";

import './DamagesTable.css';
import TablePage from "../../global/Table/TablePage";
import {allDamagesSelector} from "../../../redux/selectors/damage";
import {TableCell} from "@mui/material";
import {Layout} from "../../global/Table/TablePageHelpers/LayoutObject";
import Tooltip from "@material-ui/core/Tooltip";
import {
    CREATE_DAMAGE_ENTRY,
    DAMAGE_PAGE_NAME,
    DAMAGES_PAGES,
    DEFAULT_SORT_BY_HEAD_CELL,
    EDIT_ENTRY,
    HIDE_COMPLETED_DAMAGES_TEXT,
    LIST_DAMAGES_CELLS,
    ROWS_PER_PAGE_LABEL
} from "../constants";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import {listAllDamages} from "../../../redux/actions/damage";
import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {suppliersSelector} from "../../../redux/selectors/settings";
import {Filter} from "../../global/Table/TablePageHelpers/FilterObject";

class DamagesTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableEvents: [],
        }
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevProps.selectedLocation !== this.props.selectedLocation)
            this.props.listAllDamages(this.props.selectedLocation)
    }

    getTableHeaders = () => {
        let headers = []
        headers.push(Layout.columnHead(null,null,null))
        LIST_DAMAGES_CELLS.forEach((headCell) => {
            headers.push(Layout.columnHead(headCell.label, headCell.id, "center"))
        })
        return headers
    }

    tableRow = (damage, index) => {
        return <React.Fragment>
            <TableCell className="editIcon">
                <Tooltip title={EDIT_ENTRY}>
                    <IconButton onClick={() => {this.props.setCurrentPage(DAMAGES_PAGES.UPDATE, {idOfDamageBeingEdited: damage.damageId})}}>
                        <EditIcon
                            fontSize='medium'
                        />
                    </IconButton>
                </Tooltip>
            </TableCell>

            <TableCell className="damage-cell-center"> {damage?.creationDate.split("T")[0]} </TableCell>
            <TableCell className="damage-cell-center"> {damage?.poNumber} </TableCell>
            <TableCell className="damage-cell-center"> {damage?.supplier} </TableCell>
            <TableCell className="damage-cell-center"> {damage?.shortcode} </TableCell>
            <TableCell className="damage-cell-center-thin"> {damage?.qtyDamaged} </TableCell>
            <TableCell className="damage-cell-center-thin"> {damage?.qtyReplaced} </TableCell>
            <TableCell className="damage-cell-center-thin"> {damage?.qtyCredited} </TableCell>
            <TableCell className="damage-cell-center-thin"> {damage?.qtyRemaining} </TableCell>

        </React.Fragment>
    }

    searchStrings = (damage) => {
        return [damage.creationDate.split("T")[0], damage.poNumber, damage.supplier, damage.shortcode, damage.qtyDamaged.toString(),
            damage.qtyReplaced.toString(), damage.qtyCredited.toString(), damage.qtyRemaining.toString()]
    }

    render() {
        return (
            <React.Fragment>
                <TopToolbar
                    pageName={DAMAGE_PAGE_NAME}
                    menuItems={[
                        {title: CREATE_DAMAGE_ENTRY, icon: <AddCircleIcon/>, onClick: () => {this.props.setCurrentPage(DAMAGES_PAGES.CREATE)}}
                    ]}
                />
                <TablePage
                    tableName="DamagesTable"
                    eventsVariable={this.state.tableEvents}
                    setEventsVariable={(newState) => {this.setState({tableEvents: newState})}}

                    tableRows={this.props.tableRows.allDamages}
                    rowFetcher={(filters) => {this.props.listAllDamages(this.props.selectedLocation)}}

                    tableColumnHeads={this.getTableHeaders()}
                    tableRow={this.tableRow}

                    paginationText={ROWS_PER_PAGE_LABEL}
                    defaultSortBy={DEFAULT_SORT_BY_HEAD_CELL}

                    hasSearchField
                    searchStrings={this.searchStrings}

                    filters={[
                        Filter.createFilter("supplier", (row) => row.supplier, null, Filter.relations.containedBy, true),
                        Filter.createFilter("hasQuantityRemaining", (row) => (row.qtyRemaining >= 1), null, Filter.relations.equals, false)
                    ]}

                    filterCheckboxes={[
                        Layout.checkBox("hasQuantityRemaining", HIDE_COMPLETED_DAMAGES_TEXT)
                    ]}

                    filterDropdown={
                        Layout.newLayout(1, [
                            Layout.row([
                                Layout.rowElement(1, "supplier", "Supplier", Layout.elementStyle.checkboxDropdown, this.props.suppliers.map((supplier) => supplier.name))
                            ])
                        ])
                    }
                    hasLocationField
                    applyFilterDropdownChangesImmediately
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    tableRows: allDamagesSelector(state),
    suppliers: suppliersSelector(state)
})

const actionCreators = {
    listAllDamages,
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, DamagesTable);