export const DEFAULT_ROWS_PER_PAGE = 50;

export const PRODUCT_CATEGORY_TOOLBAR_TITLE = "Amazon Feeds"
export const BACK_TO_MENU_TITLE = "Back to Menu";
export const MANAGE_PRODUCT_CATEGORY = "Manage Product Category";

export const DEFAULT_SORT_BY_HEAD_CELL = "category"
export const PRODUCT_CATEGORY_TABLE_HEAD_CELLS = [
    { id: 'date', center: true, disablePadding: false, label: 'Date'},
    { id: 'submissionId', center: true, disablePadding: false, label: 'Submission ID'},
    { id: 'feedType', center: true, disablePadding: false, label: 'Feed Type' },
    { id: 'status', center: true, disablePadding: false, label: 'Status'},
    { id: 'marketPlace', center: true, disablePadding: false, label: 'Market Place' },
    {id: 'filler2', center: false, disablePadding: true, label: ''}
]

export const REMOVE_PRODUCT_CATEGORY = "Remove Category"

export const CANCEL = "cancel";
export const SAVE = "save";

export const ADD_PRODUCT_CATEGORY = "Add Product Category"
