import {
    call,
    put,
    takeLatest,
} from 'redux-saga/effects';
import axios from 'axios';
import {
    CREATE_TICKET,
    DELETE_TICKET,
    FETCH_ORDER_INFO,
    LIST_ISSUE_ACTION_SPECIFIC_TASKS,
    LIST_ISSUE_SPECIFIC_ACTIONS,
    LIST_ISSUE_SPECIFIC_TASKS,
    LIST_TICKET_ISSUES,
    LIST_TICKETS,
    LIST_STATUS,
    PREVIEW_TICKET_FILE,
    UPDATE_TICKET
} from "../../constants/tickets";
import { clearStatus, requestStatus, hideError, showErrorMessage } from "../../actions/global";
import { PURCHASE_ORDERS, RETURNS, TICKETS } from "../../../components/global/Server/endpoints";
import { HTTP_SUCCESS } from "../../constants/global";
import { storeReturns, storeUpdatedReturn, storeUpdatedReturnWithPreview } from "../../actions/returns";
import {
    storeOrderInfoFetch,
    storeTicketActions,
    storeTicketActionTasks,
    storeTicketIssues, storeTickets,
    storeUpdatedTicket, storeUpdatedTicketWithPreview,
    storeStatus,
    updateTicket
} from "../../actions/tickets";
import {
    ORDER_INFO_NOT_MAPPED,
    SERVER_FAILED_ERROR_MESSAGE
} from '../../../pages/Dashboard/DashboardFinal/constants';

const getHeaders = () => {
    const token = localStorage.getItem('token');
    return {
        "Authorization": `Bearer ${token}`
    }
}

const postRequest = (endpoint, data) => {
    return axios.post(endpoint, data, {
        headers: getHeaders()
    })
        .then(response => response)
}

const getRequest = (endpoint) => {
    return axios.get(endpoint, {
        headers: getHeaders()
    })
        .then(response => response);
}

function* listTicketsSaga(action) {
    yield call(clearStatus)
    yield put(hideError());
    try {
        const response = yield call(() => postRequest(TICKETS.LIST_TICKETS, action.data))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        yield put(storeTickets(response.data))
        yield put(requestStatus(status))
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE));
    }
}

function* createTicketSaga(action) {
    yield call(clearStatus)
    yield put(hideError());
    try {
        const response = yield call(() => postRequest(TICKETS.CREATE_TICKET, action.newTicket))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        if (!response.data.success) {
            yield put(showErrorMessage(response.data.message));
        } else {
            const newGeneralTicketFiles = anyUpdatedFiles(action.newTicket, true, true)

            if (newGeneralTicketFiles.length !== 0) {
                const response2 = yield call(() => postRequest(TICKETS.ADD_FILES, {
                    files: newGeneralTicketFiles,
                    ticketId: response.data.ticket.ticketId, actionFiles: false
                }))
                const status2 = {
                    status: response2.status,
                    statusText: response2.statusText,
                    success: response2.status === HTTP_SUCCESS,
                };
                if (!response2.data.success) {
                    yield put(showErrorMessage(response2.data.message));
                } else {
                    yield put(storeUpdatedTicket(response2.data.ticket))
                }
                yield put(requestStatus(status2))
            } else {
                yield put(storeUpdatedTicket(response.data.ticket))
                yield put(requestStatus(status))
            }

            yield action.reset()
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE));
    }
}

function anyUpdatedFiles(updatedTicket, newFiles, generalFile) {
    if (generalFile) {
        if (newFiles)
            return updatedTicket.newFiles
        return updatedTicket.deletedFiles
    }
    else {
        let updatedActionFiles = {}
        // go through each action of the ticket to check if the new files is not empty
        updatedTicket.actions.forEach((ticketAction) => {
            if (newFiles && ticketAction.newFiles) {
                if (ticketAction.newFiles.length !== 0)
                    updatedActionFiles[ticketAction.actionName] = ticketAction.newFiles
            }
            else if (ticketAction.deletedFiles) {
                if (ticketAction.deletedFiles.length !== 0)
                    updatedActionFiles[ticketAction.actionName] = ticketAction.deletedFiles
            }

        })

        return updatedActionFiles
    }


}

// when we initally update the ticket, we dont care about the files, we want to isolate these calls
function removeFileInfo(ticket) {
    ticket.actions.forEach((action, index) => {
        /*if(action["deletedFiles"]){
            ticket.actions[index].files =  ticket.actions[index].prevFilesCopy
        }*/
        ticket.actions[index].files = action.files.filter((file) => {
            return !("byteArray" in file)
        })
        delete action["newFiles"]
        delete action["deletedFiles"]
        delete action["prevFilesCopy"]
    })
    delete ticket.files
    delete ticket.newFiles
    delete ticket.deletedFiles
    return ticket
}

function* deleteTicketSaga(action) {
    try {
        const response = yield postRequest(TICKETS.DELETE_TICKET, {ticketId: action.ticketId})

        if (response.data.success) {
            try {
                action.callback()
            } catch (e) {
                yield put(showErrorMessage("Update Ticket Callback Failed"));
            }
        } else {
            yield put(showErrorMessage(response.data.message));
        }

    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE));
    }
}

function* updateTicketSaga(action) {
    yield call(clearStatus)
    yield put(hideError());
    try {
        const newGeneralTicketFiles = anyUpdatedFiles(action.updatedTicket, true, true)

        const deletedGeneralTicketFiles = anyUpdatedFiles(action.updatedTicket, false, true)

        const newActionFiles = anyUpdatedFiles(action.updatedTicket, true, false)

        const deletedActionFiles = anyUpdatedFiles(action.updatedTicket, false, false)

        const noFileTicket = removeFileInfo(action.updatedTicket)

        const response = yield call(() => postRequest(TICKETS.UPDATE_TICKET, noFileTicket))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        if (!response.data.success) {
            yield put(showErrorMessage(response.data.message));
        } else {
            yield put(storeUpdatedTicket(response.data.ticket))

            yield put(requestStatus(status));

            if (deletedGeneralTicketFiles.length !== 0) {
                const response2 = yield call(() => postRequest(TICKETS.DELETE_FILES, {
                    files: deletedGeneralTicketFiles,
                    ticketId: response.data.ticket.ticketId, actionFiles: false
                }))
                const status2 = {
                    status: response.status,
                    statusText: response.statusText,
                    success: response.status === HTTP_SUCCESS,
                };
                if (!response2.data.success) {
                    yield put(showErrorMessage(response2.data.message));
                } else {
                    yield put(storeUpdatedTicket(response2.data.ticket))
                }
                yield put(requestStatus(status2))
            }
            if (newGeneralTicketFiles.length !== 0) {
                const response2 = yield call(() => postRequest(TICKETS.ADD_FILES, {
                    files: newGeneralTicketFiles,
                    ticketId: response.data.ticket.ticketId, actionFiles: false
                }))
                const status2 = {
                    status: response.status,
                    statusText: response.statusText,
                    success: response.status === HTTP_SUCCESS,
                };
                if (!response2.data.success) {
                    yield put(showErrorMessage(response2.data.message));
                } else {
                    yield put(storeUpdatedTicket(response2.data.ticket))
                }
                yield put(requestStatus(status2))
            }

            if (Object.keys(newActionFiles).length !== 0) {
                const response2 = yield call(() => postRequest(TICKETS.ADD_FILES, {
                    files: newActionFiles,
                    ticketId: response.data.ticket.ticketId, actionFiles: true
                }))
                const status2 = {
                    status: response.status,
                    statusText: response.statusText,
                    success: response.status === HTTP_SUCCESS,
                };
                if (!response2.data.success) {
                    yield put(showErrorMessage(response2.data.message));
                } else {
                    yield put(storeUpdatedTicket(response2.data.ticket))
                }
                yield put(requestStatus(status2))
            }

            if (Object.keys(deletedActionFiles).length !== 0) {
                const response3 = yield call(() => postRequest(TICKETS.DELETE_FILES, {
                    files: deletedActionFiles,
                    ticketId: response.data.ticket.ticketId, actionFiles: true
                }))
                const status3 = {
                    status: response.status,
                    statusText: response.statusText,
                    success: response.status === HTTP_SUCCESS,
                };
                if (!response3.data.success) {
                    yield put(showErrorMessage(response3.data.message));
                } else {
                    yield put(storeUpdatedTicket(response3.data.ticket))
                }
                yield put(requestStatus(status3))
            }
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE));
    }
}

function* listTicketIssuesSaga() {
    yield call(clearStatus)
    yield put(hideError());
    try {
        const response = yield call(() => getRequest(TICKETS.LIST_TICKET_ISSUES))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        yield put(storeTicketIssues(response.data))
        yield put(requestStatus(status))
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE));
    }
}

function* listStatusSaga() {
    yield call(clearStatus)
    yield put(hideError());
    try {
        const response = yield call(() => getRequest(TICKETS.LIST_STATUS))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        yield put(storeStatus(response.data))
        yield put(requestStatus(status))
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE));
    }
}



function* listIssueSpecificTasksSaga(action) {
    yield call(clearStatus)
    yield put(hideError());
    try {
        const response = yield call(() => postRequest(TICKETS.LIST_ISSUE_ACTION_SPECIFIC_TASKS, action.ticketIssueId))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };

        yield put(storeTicketActionTasks(response.data))
        yield put(requestStatus(status));
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE));
    }
}


function* listIssueSpecificActionsSaga(action) {
    yield call(clearStatus)
    yield put(hideError());
    try {
        const response = yield call(() => postRequest(TICKETS.LIST_ISSUE_SPECIFIC_ACTIONS, action.ticketIssueId))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };


        yield put(storeTicketActions(response.data))
        yield put(requestStatus(status));
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE));
    }
}


function* previewTicketFileSaga(action) {
    yield call(clearStatus)
    yield put(hideError());
    try {
        const response = yield call(() => postRequest(TICKETS.PREVIEW_FILE, action.previewRequest))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        if (!response.data.success) {
            yield put(showErrorMessage(response.data.message));
        } else {
            yield put(storeUpdatedTicketWithPreview(response.data.ticket))
        }
        yield put(requestStatus(status))
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE));
    }
}

function* fetchOrderInfo(action){
    yield call(clearStatus)
    yield put(hideError());
    try {
        const response = yield call(() => postRequest(TICKETS.FETCH_ORDER_INFO, action))
        if(response.data.success) {
            yield put(storeOrderInfoFetch(response.data))

        } else{
            yield put(showErrorMessage(ORDER_INFO_NOT_MAPPED));
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE));
    }
}

export default function* ticketsSagas() {
    yield takeLatest(CREATE_TICKET, createTicketSaga)
    yield takeLatest(LIST_TICKETS, listTicketsSaga)
    yield takeLatest(UPDATE_TICKET, updateTicketSaga)
    yield takeLatest(DELETE_TICKET, deleteTicketSaga)

    yield takeLatest(LIST_TICKET_ISSUES, listTicketIssuesSaga)
    yield takeLatest(LIST_STATUS, listStatusSaga)
    yield takeLatest(LIST_ISSUE_SPECIFIC_ACTIONS, listIssueSpecificActionsSaga)
    yield takeLatest(LIST_ISSUE_SPECIFIC_TASKS, listIssueSpecificTasksSaga)

    yield takeLatest(PREVIEW_TICKET_FILE, previewTicketFileSaga)
    yield takeLatest(FETCH_ORDER_INFO, fetchOrderInfo)
}