import React from 'react';

// import constants
import {
    CONDITION_ACTIONS,
    CONDITION_ACTIONS_TITLE,
    HEAD_ACTION_CELLS,
    PACKAGE_ACTIONS_MAP,
    SET_INVOICE_BANK_ACCOUNT, SET_INVOICE_PAYMENT_DATE,
    SET_INVOICE_REFERENCE_ID,
    SET_INVOICE_STATUS,
    SET_NUMBER_OF_PACKAGES,
} from "./constants";

// import styling
import "../ConditionFilters/ConditionFiltersTable.css";
import "../../global/SecondaryToolbar.css";

// material components
import {
    Paper,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Typography,
    Checkbox
} from '@material-ui/core';

// custom components
import GlobalTableHead from "../../global/Table/GlobalTableHead";
import SearchField from "../../global/Search/SearchField";
import { ASC, DESC } from "../../global/constants";
import SearchableTable from "../../global/Search/SearchableTable";
import PropTypes from "prop-types";
import ActionText from "./ActionText";

class ConditionActionsTable extends SearchableTable {
    constructor(props) {
        super(props);
        this.state = {
            mappingName: '',
            mappingValue: '',
            linkedRuleName: '',
            order: ASC, // sort ascending or desending state
            isSearch: false, // true if search value is not empty, false otherwise
            searchValue: "", // the value in the search field (updates on change)
            searchRows: 0, // number of rows displayed while search criteria is met
        }
    }

    handleChangeDense = (event) => {
        this.setState({
            dense: event.target.checked,
        });
    };

    handleRequestSort = (event, property) => {
        const isAsc = this.state.orderBy === property && this.state.order === ASC;
        this.setState({
            order: isAsc ? DESC : ASC,
            orderBy: property,
        });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0,
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
        });
    };

    onSearchValueChange = (event) => {
        this.setState({
            isSearch: event.target.value.length > 0 ? true : false,
            searchValue: event.target.value,
        });
    };

    render() {
        return (
            <div className='root'>
                <Paper className="paper">
                    <TableContainer>
                        <Table>
                            <TableBody>
                                <TableRow className='secondaryToolbar2'>
                                    <TableCell>
                                        <SearchField page='auto' onChange={this.onSearchValueChange} autoFocus={true} searchText={this.state.searchValue} />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TableContainer className="conditionFiltersContainer">
                        <Table
                            aria-labelledby='tableTitle'
                            size='medium'
                            aria-label={CONDITION_ACTIONS_TITLE}
                        >
                            <GlobalTableHead
                                isShipmentTable={false}
                                headCells={HEAD_ACTION_CELLS}
                                numSelected={this.props.selected.length}
                                order={this.state.order}
                                orderBy={this.state.orderBy}
                                onSelectAllClick={(event) =>
                                    this.props.handleSelectAllClick(event, CONDITION_ACTIONS, false)
                                }
                                onRequestSort={this.handleRequestSort}
                                rowCount={CONDITION_ACTIONS.length}
                            />
                            <TableBody>
                                {this.stableSort(
                                    this.filterBySearchWithSpecificKeys(CONDITION_ACTIONS, ["name", "step"]).filter(filter => filter.category === this.props.ruleCategory),
                                    this.getComparator(this.state.order, this.state.orderBy)
                                )
                                    .map((action, index) => {
                                        const isItemSelected = this.props.isSelected(action.name, false);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        const disableCheckbox = (
                                            action.name === SET_NUMBER_OF_PACKAGES && this.props.hasSetPackageNumber
                                        ) || (
                                            !this.props.showInvoiceActions && (action.name === SET_INVOICE_REFERENCE_ID || action.name === SET_INVOICE_BANK_ACCOUNT
                                                || action.name === SET_INVOICE_STATUS || action.name === SET_INVOICE_PAYMENT_DATE)
                                        );
                                        return (
                                            <TableRow
                                                hover
                                                onClick={(event) =>
                                                    this.props.handleClick(event, action, false)
                                                }
                                                role='checkbox'
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={action.name}
                                                selected={isItemSelected}
                                            >
                                                <TableCell padding='checkbox'>
                                                    <Checkbox
                                                        checked={isItemSelected}
                                                        onClick={(event) =>
                                                            this.props.handleClick(event, action, false)
                                                        }
                                                        inputProps={{ "aria-labelledby": labelId }}
                                                        disabled={disableCheckbox}
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    align='left'
                                                    id={labelId}
                                                    scope='row'
                                                    padding='none'
                                                    style={{ color: action.name === SET_NUMBER_OF_PACKAGES && this.props.hasSetPackageNumber ? "gray" : "inherit" }}
                                                >
                                                    {action.name}
                                                </TableCell>
                                                <TableCell
                                                    align='left'
                                                    id={labelId}
                                                    scope='row'
                                                    padding='none'
                                                    style={{ color: action.name === SET_NUMBER_OF_PACKAGES && this.props.hasSetPackageNumber ? "gray" : "inherit" }}
                                                >
                                                    {PACKAGE_ACTIONS_MAP[action.step]}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </div>
        );
    }

}

ConditionActionsTable.propTypes = {
    selected: PropTypes.array,
    isSelected: PropTypes.func,
    handleClick: PropTypes.func,
    handleSelectAllClick: PropTypes.func,
    hasSetPackageNumber: PropTypes.bool
};

ConditionActionsTable.defaultProps = {
    selected: [],
    isSelected: () => { },
    handleClick: () => { },
    handleSelectAllClick: () => { },
    hasSetPackageNumber: false
}

export default ConditionActionsTable;