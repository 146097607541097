import React from "react";

// material components
import { Select, MenuItem, Box, Tooltip, IconButton } from "@material-ui/core";

// material icons
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

// import constants
import { FILTER_TYPE_BOOLEAN_2_OPTIONS,
  FILTERS
} from "./constants";
import {COPY_FILTER, COPY_RULE, DELETE_FILTER, DRAG} from "../constants";
import { COUNTRY_OPTIONS } from "../ConditionActions/constants";

// import styling
import "./FilterBooleanPredefinedSelect.css";

import PropTypes from "prop-types";
import FilterTextField from "./FilterTextField";
import DehazeIcon from "@material-ui/icons/Dehaze";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import {availableLocationsSelector, shippingTemplatesSelector} from "../../../redux/selectors/settings";
import {listAvailableLocations, listShippingTemplates} from "../../../redux/actions/settings";
import withShipment from "../../../withShipment";
import {listAllBankAccounts} from "../../../redux/actions/settings";
import {bankAccountsSelector} from "../../../redux/selectors/settings";
import {userInfoSelector} from "../../../redux/selectors/auth";
/**
 * Dropdown list for FILTER_TYPE_BOOLEAN_PREDEFINED with a secondary dropdown list of pre-defined options
 */
class FilterBooleanPredefinedSelect extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filterId: "",
      name: "",
      value1: "", // value for the first drop down
      value2: "", // value of the second drop down
      secondaryOptions: [],
    };
  }

  componentDidMount = () => {
    this.setState({
      // on component mount, the parent component stores this child's state.
      // We restore it here
      ...this.props.filter,
    });
    this.setState({
      secondaryOptions: this.getSecondaryOptions(),
    });
    this.props.listAvailableLocations()
    this.props.listAllBankAccounts()
    this.props.listShippingTemplates({id: this.props.userInfo.company})
  };

    componentDidUpdate(prevProps) {
        if (prevProps.availableLocations !== this.props.availableLocations) {
            this.setState({
                secondaryOptions: this.getSecondaryOptions(),
            });
        }
        if (prevProps.bankAccounts !== this.props.bankAccounts) {
            this.setState({
                secondaryOptions: this.getSecondaryOptions(),
            });
        }


    }
  getSecondaryOptions = () => {
    if(this.props.filter.name == "Ship to country"){
      return COUNTRY_OPTIONS.map(n => n.name)
    }
    if (this.props.filter.name === "Location") {
      let locationNames = []
      locationNames = this.props.availableLocations.filter((location) => location.enabled === true).map(location => location.name)
      return locationNames
    }
    if (this.props.filter.name === "Shipping Template") {
      let shippingTemplates = []
      shippingTemplates = this.props.shippingTemplates.map(template => template.shippingTemplateName)
      return shippingTemplates
    }
    if(this.props.filter.name == "Bank account"){
      return this.props.bankAccounts.map(n => n.name)
    }
    return FILTERS.find(filter => filter.name === this.props.filter.name).secondaryOptions
  }

  handleValue1Change = (event) => {
    const value1 = event.target.value;
    this.setState(
      {
        value1,
      },
      () => {
        this.props.updateFilterWithParent({ ...this.state });
      }
    );
  };

  handleValue2Change = (event) => {
    const value2 = event.target.value;
    this.setState(
      {
        value2,
      },
      () => {
        this.props.updateFilterWithParent({ ...this.state });
      }
    );
  };

  render() {
    return (
      <Box
        className='filterBooleanPredefinedSelect'
        display='flex'
        flexDirection='row'
        justifyContent='left'
        alignItems='center'
      >
        <Box className='filterBooleanPredefinedSelectName'>
          {this.state.name}
        </Box>

        <Select
          className='filterBooleanPredefinedSelectOptions'
          variant='outlined'
          value={this.state.value1}
          onChange={this.handleValue1Change}
        >
          {FILTER_TYPE_BOOLEAN_2_OPTIONS.map((item) => (
            <MenuItem value={item}>{item}</MenuItem>
          ))}
        </Select>

        <Select
          className={this.props.filter.name === "Shipping Template"? 'filterBooleanPredefinedSecondarySelectOptionsShippingTemplate': 'filterBooleanPredefinedSecondarySelectOptions'}
          variant='outlined'
          value={this.state.value2}
          onChange={this.handleValue2Change}
        >
          {this.state.secondaryOptions.map((item) => (
            <MenuItem value={item}>{item}</MenuItem>
          ))}
        </Select>

        <div className='filterBooleanEmptyDiv'>
          <Tooltip title={COPY_RULE}>
            <IconButton
                aria-label={COPY_RULE}
                onClick={() => this.props.copyFilter(this.state.filterId)}>
              <FileCopyIcon fontSize='small'/>
            </IconButton>
          </Tooltip>
          <Tooltip title={DELETE_FILTER}>
            <IconButton
              aria-label={DELETE_FILTER}
              onClick={() => this.props.deleteFilter(this.state.filterId)}
            >
              <DeleteOutlineIcon variant='outlined' fontSize='small' />
            </IconButton>
          </Tooltip>
          <Tooltip title={DRAG}>
            <IconButton
                aria-label='drag'
            >
              <DehazeIcon variant='outlined' fontSize='small' />
            </IconButton>
          </Tooltip>
        </div>
      </Box>
    );
  }
}

FilterBooleanPredefinedSelect.propTypes = {
  filter: PropTypes.object,
  updateFilterWithParent: PropTypes.func,
  deleteFilter: PropTypes.func,
  isEdit: PropTypes.bool,
  copyFilter: PropTypes.func,
  availableLocations: PropTypes.array,
}

FilterBooleanPredefinedSelect.defaultProps = {
  filter: {},
  updateFilterWithParent: ()=>{},
  deleteFilter: ()=>{},
  isEdit: false,
  copyFilter: ()=>{},
  availableLocations: [],
  bankAccounts: [],
}
const mapStateToProps = (state) => ({
  userInfo: userInfoSelector(state),
  bankAccounts: bankAccountsSelector(state),
  availableLocations: availableLocationsSelector(state),
  shippingTemplates: shippingTemplatesSelector(state),
})

const actionCreators = {
    listAllBankAccounts,
    listAvailableLocations,
    listShippingTemplates
}

export default withShipment({
  mapStateToProps,
  actionCreators
}, FilterBooleanPredefinedSelect);
