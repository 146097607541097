import {
    SCAN_PACKED_INPUT,
    SCAN_SCANNED_INPUT,
    GET_DASHBOARD_INFO,
    STORE_DASHBOARD_INFO,
    STORE_SCAN_RESPONSE,
    CLEAR_SCAN_RESPONSE,
    STORE_PACKED_RESPONSE,
    CLEAR_PACKED_RESPONSE,
    STORE_SCANNED_BARCODES,
    UPDATE_SCANNED_BARCODE,
    STORE_PACKED_BARCODES,
    UPDATE_PACKED_BARCODE,
    STORE_PENDING_PACKED_BARCODES,
    UPDATE_PENDING_PACKED_BARCODE,
    STORE_TOTAL_BARCODES,
    MANUAL_PACK,
    SET_REFRESH,
    DASHBOARD_REFRESH, MANUAL_CANCEL, MANUAL_VOID,
} from "../../constants/dashboard";

export const getDashboardInfo = (company, date, shipAddressId) => ({
    type: GET_DASHBOARD_INFO,
    company,
    date,
    shipAddressId
});

export const scanScannedInput = (scan) => ({
    type: SCAN_SCANNED_INPUT,
    scan,
});

export const scanPackedInput = (packed) => ({
    type: SCAN_PACKED_INPUT,
    packed,
});

export const storeDashboardInfo = (dashboard) => ({
    type: STORE_DASHBOARD_INFO,
    dashboard,
});

export const storeScanResponse = (scanResponse) => ({
    type: STORE_SCAN_RESPONSE,
    scanResponse,
});

export const clearScanResponse = () => ({
    type: CLEAR_SCAN_RESPONSE,
});

export const storePackedResponse = (packedResponse) => ({
    type: STORE_PACKED_RESPONSE,
    packedResponse,
});

export const clearPackedResponse = () => ({
    type: CLEAR_PACKED_RESPONSE
});

export const manualVoid = (trackingNumber, comment, reason) => ({
    type: MANUAL_VOID,
    trackingNumber,
    comment,
    reason
})

export const manualPack = (trackingNumber) => ({
    type: MANUAL_PACK,
    trackingNumber
})

export const storeScannedBarcodes = (barcodes) => ({
    type: STORE_SCANNED_BARCODES,
    barcodes
})

export const updateScannedBarcodes = (label) => ({
    type: UPDATE_SCANNED_BARCODE,
    label
})

export const storePackedBarcodes = (barcodes) => ({
    type: STORE_PACKED_BARCODES,
    barcodes
})

export const updatePackedBarcodes = (label) => ({
    type: UPDATE_PACKED_BARCODE,
    label
})

export const storePendingPackedBarcodes = (barcodes) => ({
    type: STORE_PENDING_PACKED_BARCODES,
    barcodes
})

export const updatePendingPackedBarcodes = (label) => ({
    type: UPDATE_PENDING_PACKED_BARCODE,
    label
})

export const storeTotalBarcodes = (barcodes) => ({
    type: STORE_TOTAL_BARCODES,
    barcodes
})

export const setRefresh = (refreshing) => ({
    type: SET_REFRESH,
    refreshing
})

export const refreshDashboard = (needsRefresh) => ({
    type: DASHBOARD_REFRESH,
    needsRefresh
})