export const SAVE_DAMAGED_ENTRY = 'SAVE_DAMAGE_ENTRY';
export const STORE_DAMAGED_ENTRY = 'STORE_DAMAGED_ENTRY';

export const FIND_PO_INFORMATION = 'FIND_PO_INFORMATION';
export const STORE_PO_INFORMATION = 'STORE_PO_INFORMATION';

export const LIST_ALL_DAMAGES = 'LIST_ALL_DAMAGES';
export const STORE_ALL_DAMAGES = 'STORE_ALL_DAMAGES';
export const LIST_SPECIFIC_DAMAGE = 'LIST_SPECIFIC_DAMAGE';
export const STORE_SPECIFIC_DAMAGE = 'STORE_SPECIFIC_DAMAGE';

export const DELETE_DAMAGE_ENTRY = 'DELETE_DAMAGE_ENTRY';

export const UPDATE_DAMAGE_ENTRY = 'UPDATE_DAMAGE_ENTRY';