import {
    CREATE_RETURN,
    LIST_RETURN_ACTIONS,
    LIST_RETURN_CONDITIONS,
    LIST_RETURN_REASONS,
    LIST_RETURNS,
    PREVIEW_RETURN_FILE,
    STORE_RETURN_ACTIONS,
    STORE_RETURN_CONDITIONS,
    STORE_RETURN_REASONS,
    STORE_RETURNS,
    STORE_TEST_FILE,
    STORE_UPDATED_RETURN,
    STORE_UPDATED_RETURN_WITH_PREVIEW,
    UPDATE_RETURN,
    UPDATE_RETURN_WITH_PREVIEW,
    UPLOAD_RETURN_ATTACHMENT,
    LIST_UNCOMPLETED_RETURNS,
    FETCH_RMA_INFO,
    FETCH_DUPLICATE_RMAS,
    STORE_RMA_FETCH,
    STORE_DUPLICATE_RMAS,
    LIST_CUSTOMER_MAILING_LIST
} from "../../constants/returns";


export const createReturn = (newReturnItem, reset) => ({
    type: CREATE_RETURN,
    newReturnItem,
    reset
});

export const storeReturns = (returnItems) => ({
    type: STORE_RETURNS,
    returnItems
});

export const storeUpdatedReturn = (updatedReturnItem) => ({
    type: STORE_UPDATED_RETURN,
    updatedReturnItem
});

export const updateReturnItem = (returnItem, reset) => ({
    type: UPDATE_RETURN,
    returnItem,
    reset
})

export const listReturns = () => ({
    type: LIST_RETURNS
});

export const listUncompletedReturns = () => ({
    type: LIST_UNCOMPLETED_RETURNS,
});

export const fetchRmaInfo = (rma, orderId, originalTrackingId, returnTrackingId) => ({
    type: FETCH_RMA_INFO,
    rma,
    orderId,
    originalTrackingId,
    returnTrackingId
})

export const fetchDuplicateRmas = (rmaNum, orderId) => ({
    type: FETCH_DUPLICATE_RMAS,
    rmaNum,
    orderId
})

export const listReturnActions = () => ({
    type: LIST_RETURN_ACTIONS
});
export const storeReturnActions = (returnActions) => ({
    type: STORE_RETURN_ACTIONS,
    returnActions
});


export const listReturnConditions = () => ({
    type: LIST_RETURN_CONDITIONS
})
export const storeReturnConditions = (returnConditions) => ({
    type: STORE_RETURN_CONDITIONS,
    returnConditions
});


export const listReturnReasons = () => ({
    type: LIST_RETURN_REASONS
})
export const storeReturnReasons = (returnReasons) => ({
    type: STORE_RETURN_REASONS,
    returnReasons
});


export const previewReturnFile = (previewRequest) => ({
    type: PREVIEW_RETURN_FILE,
    previewRequest
});


export const storeUpdatedReturnWithPreview = (updatedReturnItem) => ({
    type: STORE_UPDATED_RETURN_WITH_PREVIEW,
    updatedReturnItem
})

export const storeRmaFetch = (rmaFetch) => ({
    type: STORE_RMA_FETCH,
    rmaFetch
})

export const storeDuplicateRmas = (duplicateRmas) => ({
    type: STORE_DUPLICATE_RMAS,
    duplicateRmas
})



