import testSagas from '../sagas/test';
import ordersSagas from "./orders";
import authSagas from "./auth";
import rulesSaga from "./automationRules";
import userManagementSaga from "./userManagement";
import dashboardSaga from "./dashboard";
import settingsSagas from "./settings";
import invoicesSagas from "./invoices";
import purchaseOrdersSagas from "./purchaseOrders";
import inventorySagas from "./inventory"
import alertsSaga from "./alerts";
import returnsSaga from "./returns";
import ticketsSagas from "./tickets";
import accountSagas from './account';
import adminSagas from './admin';
import customerReviewsSaga from "./customerReviews";
import damageSagas from "./damage";
import globalSaga from "./global";
import fullTruckloadSagas from "./fullTruckload";
import claimsSagas from "./claims"
import accountingSagas from "./accounting";
import fullTruckloadBrokerSagas from "./fullTruckloadBrokers";
import fullTruckloadCarrierSagas from "./fullTruckloadCarriers";
import customerSagas from "./customer";

const sagas = [
  testSagas,
  ordersSagas,
  authSagas,
  rulesSaga,
  userManagementSaga,
  dashboardSaga,
  settingsSagas,
  purchaseOrdersSagas,
  inventorySagas,
  alertsSaga,
  returnsSaga,
  invoicesSagas,
  accountingSagas,
  ticketsSagas,
  fullTruckloadSagas,
  claimsSagas,
  fullTruckloadBrokerSagas,
  fullTruckloadCarrierSagas,
  accountSagas,
  adminSagas,
  damageSagas,
  adminSagas,
  customerReviewsSaga,
  globalSaga,
  customerSagas,
];

const registerSagas = (middleware) => {
  sagas.map((saga) => {
    middleware.run(saga);
  });
};

export default registerSagas;