import React from 'react';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import styles from "./DashboardFinal/DashboardFinal.module.css";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import {IconButton, TableBody, TableHead, Tooltip, Typography} from "@material-ui/core";
import {
    PENDING_TITLE,
    PENDING_TOOLTIP_INFO,
    TODAY_PENDING_TITLE,
    TOTAL_TITLE
} from "./DashboardFinal/constants";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon} from "@material-ui/icons";
import {Collapse} from "@mui/material";


//carrier summary table
class CarrierSummaryTable extends React.Component {
    constructor(props) {
        super(props);
    }

    filterCarrierBarcodes = (todayArr, currCarrierName) => {
        return todayArr ? todayArr.filter((elem) => elem.carrierName === currCarrierName).length : 0;
    }

    labelEntries = (todayArr, carriers, location) => {
        return (
            <>
                {carriers.filter((carrier) => carrier.alias === location).map((carrier) => (
                    <TableRow hover>
                        <TableCell align={'left'} className='cellPadding'>
                            <p className={styles.cardCellItems}>
                                {carrier.name === "Amazon" ? carrier.defaultService.split(" ")[0] + " (" + carrier.name + ")"
                                    : carrier.name}
                            </p>
                        </TableCell>
                        <TableCell align={'right'} className='cellPadding'>
                            <p className={styles.cardCellItems}>
                                {this.filterCarrierBarcodes(todayArr, carrier.name)}
                            </p>
                        </TableCell>
                    </TableRow>
                ))}
            </>
        )
    }

    // flag for Pending or Today's Labels Header
    labelHeader = (todayArr, flag, pendingOpen, handleCollapse) => {
        return (
            <TableRow>
                <TableCell align={'left'} className='cellPadding'>
                    <p className={styles.pendingCardTitle}>
                        {flag ? PENDING_TITLE : TODAY_PENDING_TITLE}
                        {flag ? (<>
                            <Tooltip
                                className={styles.tooltip}
                                enterTouchDelay={0}
                                leaveTouchDelay={30000}
                                enterDelay={400}
                                // leaveDelay={1000000} // for testing purposes
                                title={
                                    <React.Fragment>
                                        {PENDING_TOOLTIP_INFO}
                                        <br />
                                    </React.Fragment>
                                }
                            >
                                <IconButton aria-label='info' className={styles.tooltip}>
                                    <InfoOutlinedIcon variant='outlined' fontSize='small' />
                                </IconButton>
                            </Tooltip>
                            <IconButton onClick={handleCollapse}>
                                {pendingOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                        </>) : null}
                    </p>
                </TableCell>
                <TableCell align={'right'} className='cellPadding'>
                    <p className={styles.cardTotalHeader}>
                        {todayArr ? todayArr.length : 0}
                    </p>
                </TableCell>
            </TableRow>
        )
    }

    render() {
        const { todayArr, pendingArr, carriers, location, pendingOpen, handleCollapse, tableTitle} = this.props
        return(
            (<div className={styles.container}>
                <Typography variant="h6" component="div" gutterBottom className={styles.header}>
                    {tableTitle}
                    <hr className={styles.underline} />
                </Typography>
                <div className={styles.row}>
                    <TableContainer className={styles.tableContainer}>
                        <Table size={"small"} stickyHeader aria-label="sticky table">
                            <TableHead>
                                {this.labelHeader(pendingArr, true, pendingOpen, handleCollapse)}
                            </TableHead>
                            <TableCell style={{ padding: 0}} colSpan={2}>
                                <Collapse in={pendingOpen} timeout="auto" unmountOnExit>
                                    <Table size={"small"} stickyHeader aria-label="sticky table">
                                        {this.labelEntries(pendingArr, carriers, location)}
                                    </Table>
                                </Collapse>
                            </TableCell>
                            <TableHead>
                                {this.labelHeader(todayArr, false, pendingOpen, handleCollapse)}
                            </TableHead>
                            <TableBody>
                                {this.labelEntries(todayArr, carriers, location)}
                            </TableBody>
                            <TableRow>
                                <TableCell align={'left'} className='cellPadding'>
                                    <p className={styles.cardTotalHeader}>
                                        {TOTAL_TITLE}
                                    </p>
                                </TableCell>
                                <TableCell align={'right'} className='cellPadding'>
                                    <p className={styles.cardTotalHeader}>
                                        {todayArr && pendingArr ? todayArr.length + pendingArr.length : 0}
                                    </p>
                                </TableCell>
                            </TableRow>
                        </Table>
                    </TableContainer>
                </div>
            </div>)
        )
    }

}

export default CarrierSummaryTable;