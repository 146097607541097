import React from "react";
import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";

import { Layout } from "../../global/Table/TablePageHelpers/LayoutObject";
import {TableCell, Select, MenuItem, FormControl, InputLabel, Box, FormControlLabel} from "@mui/material";
import TablePage from "../../global/Table/TablePage";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {ADD_LEDGER, LEDGER_FIELDS, LEDGERS, LEDGERS_PAGES} from "./constants";
import { IconButton} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import './ledger.css';
import TwoStepDelete from "../../global/subcomponents/twoStepDelete/TwoStepDelete";

class LedgerTable extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            deleteId: "",
            isDeleted: false,
        }
    }

    getTableHeaders = () => {
        let headers = []
        headers.push(Layout.columnHead(null,null,null))

        LEDGER_FIELDS.forEach((headCell) => {
            headers.push(
                Layout.columnHead(headCell.label, headCell.id, "center")
            )
        })
        headers.push(Layout.columnHead(null, null, null, null))
        return headers
    }


    formatBalance = (balance) => {
        return parseFloat(balance)
            .toFixed(2)
            .toString()
            .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }

    getBalanceForSelectedYear = (row) => {
        const balanceForSelectedYear = row?.ledgerEntries?.find(
            (entry) => entry?.year === this.props.selectedYear
        )
        return balanceForSelectedYear?.balance ?? 0;
    }

    tableRow = (row) => {
        const balance = this.getBalanceForSelectedYear(row)

         return (
            <React.Fragment>
                <TableCell>
                    <IconButton size="small" onClick={() => {
                        this.props.setCurrentPage(LEDGERS_PAGES.EDIT, row)
                    }
                    }>
                        <EditIcon />
                    </IconButton>
                </TableCell>

                <TableCell className="custom-cell left">{row?.name}</TableCell>
                <TableCell className="custom-cell center">{row?.type}</TableCell>

                <TableCell
                    style={{
                        textAlign: "right",
                        padding: "5px",
                        paddingRight: "50px",
                        width: "auto"
                    }}
                >
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center'
                    }}>
                        <span style={{flexShrink: 0}}>$</span>
                        <span style={{
                            marginLeft: '55px',
                            minWidth: '80px',
                            textAlign: 'right'
                        }}>
                           {
                               this.formatBalance(balance)
                           }
                         </span>
                    </div>
                </TableCell>

                <TwoStepDelete
                    rowId={row.ledgerId}
                    handleDelete={(rowIdToDelete) => {
                        this.props.deleteLedgerByYear({ledgerId: row.ledgerId, year: this.props.selectedYear, currency: this.props.selectedCurrency})
                        this.setState({
                            isDeleted: true,
                            deleteId: ''
                        });
                    }}
                    type={this.state.isDeleted}
                    deleteId={this.state.deleteId}
                    setDeleteId={(newDeleteId) => this.setState({deleteId: newDeleteId})}
                />


            </React.Fragment>
        )
    }

    searchStrings = (row) => {
        return [row.name]
    }


    getContextualMenuItems = () => {
        let menuItems = []
        menuItems.push(
            {
                title: ADD_LEDGER,
                icon: <AddCircleIcon/>,
                onClick: () => {
                    this.props.setCurrentPage(LEDGERS_PAGES.ADD, {})
                }
            })

        return menuItems
    }


    render() {
        return (
            <React.Fragment>
                <TopToolbar
                    pageName={LEDGERS}
                    menuItems={this.getContextualMenuItems()}
                />

                <Box className="box-ledger">
                    <FormControl variant="outlined" className="form-control-ledger">
                        <InputLabel id="year-label">Year</InputLabel>
                        <Select
                            className="select-year-ledger"
                            labelId="year-label"
                            value={this.props.selectedYear}
                            onChange={this.props.handleYearChange}
                            label="Year"
                        >
                            {this.props.years.map((year) => (
                                <MenuItem key={year} value={year}>
                                    {year}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl variant="outlined" className="form-control">
                        <InputLabel id="currency-label">Currency</InputLabel>
                        <Select
                            className="select-year-ledger"
                            labelId="currency-label"
                            value={this.props.selectedCurrency}
                            onChange={this.props.handleChange}
                            label="Currency"
                        >
                            {this.props.currency.map((currency) => (
                                <MenuItem key={currency} value={currency}>
                                    {currency}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>


                    <TablePage
                        type="ledgerSummary"
                        tableName="LedgerTable"
                        tableRows={this.props.ledgers}
                        tableColumnHeads={this.getTableHeaders()}
                        tableRow={this.tableRow}
                        paginationText="Ledgers per page"
                        hasSearchField
                        searchStrings={this.searchStrings}
                    />

            </React.Fragment>
        )
    }
}

export default LedgerTable;
