export const FETCH_FULL_TRUCKLOADS = "FETCH_FULL_TRUCKLOADS"
export const STORE_FULL_TRUCKLOAD = "STORE_FULL_TRUCKLOAD"
export const SAVE_FULL_TRUCKLOAD = "SAVE_FULL_TRUCKLOAD"
export const UPDATE_FULL_TRUCKLOAD = "UPDATE_FULL_TRUCKLOAD"
export const UPLOAD_SIGNED_DOCUMENTS = "UPLOAD_SIGNED_DOCUMENTS"
export const SAVE_FULL_TRUCKLOAD_SETTINGS = "SAVE_FULL_TRUCKLOAD_SETTINGS"
export const STORE_FULL_TRUCKLOAD_SETTINGS = "STORE_FULL_TRUCKLOAD_SETTINGS"
export const FETCH_FULL_TRUCKLOAD_SETTINGS = "FETCH_FULL_TRUCKLOAD_SETTINGS"
export const PREVIEW_FULL_TRUCKLOAD_FILE = "PREVIEW_FULL_TRUCKLOAD_FILE"
export const STORE_FULL_TRUCKLOAD_FILE = "STORE_FULL_TRUCKLOAD_FILE"
export const PREVIEW_FULL_TRUCKLOAD_DOCUMENT = "PREVIEW_FULL_TRUCKLOAD_DOCUMENT"
export const STORE_FULL_TRUCKLOAD_DOCUMENT = "STORE_FULL_TRUCKLOAD_DOCUMENT"
export const LIST_FULL_TRUCKLOAD_CARRIERS = "LIST_FULL_TRUCKLOAD_CARRIERS"
export const STORE_FULL_TRUCKLOAD_CARRIERS = "STORE_FULL_TRUCKLOAD_CARRIERS"
export const LIST_FULL_TRUCKLOAD_BROKERS = "LIST_FULL_TRUCKLOAD_BROKERS"
export const STORE_FULL_TRUCKLOAD_BROKERS = "STORE_FULL_TRUCKLOAD_BROKERS"
export const STORE_FULL_TRUCKLOAD_OPERATIONS_RESPONSE = "STORE_FULL_TRUCKLOAD_OPERATIONS_RESPONSE"
export const STORE_FULL_TRUCKLOAD_UNAVAILABLE_ITEMS = "STORE_FULL_TRUCKLOAD_UNAVAILABLE_ITEMS"
