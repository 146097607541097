import {
    UPDATE_TOTAL_SCANNED,
    UPDATE_FEDEX_SCANNED,
    UPDATE_CANPAR_SCANNED,
    UPDATE_USPS_SCANNED,
    UPDATE_TOTAL_PACKED,
    UPDATE_FEDEX_PACKED,
    UPDATE_CANPAR_PACKED,
    UPDATE_USPS_PACKED,
    UPDATE_TOTAL_PRINTED,
    UPDATE_FEDEX_PRINTED,
    UPDATE_CANPAR_PRINTED,
    UPDATE_USPS_PRINTED,
    UPDATE_RAWSCANS,
    DELETE_RAWSCAN,
    UPDATE_RAWPACKED,
    DELETE_RAWPACKED
} from "../../constants/finalDashboard";

const initialState = {
    totalScannedCount: 0,
    totalPackedCount: 0,
    FedExScannedCount: 0,
    FedExPackedCount: 0,
    CanparScannedCount: 0,
    CanparPackedCount: 0,
    USPSScannedCount: 0,
    USPSPackedCount: 0,
    rawScans: [],
    rawPacked: [],
    totalPrintedCount: 0,
    FedExPrintedCount: 0,
    CanparPrintedCount: 0,
    USPSPrintedCount: 0,
    locations: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_TOTAL_SCANNED:
            return {
                ...state,
                totalScannedCount: action.increment ? state.totalScannedCount + 1 : state.totalScannedCount - 1
            }
        case UPDATE_TOTAL_PACKED:
            return {
                ...state,
                totalPackedCount: action.increment ? state.totalPackedCount + 1 : state.totalPackedCount - 1
            }
        case UPDATE_FEDEX_SCANNED:
            return {
                ...state,
                FedExScannedCount: action.increment ? state.FedExScannedCount + 1 : state.FedExScannedCount - 1
            }
        case UPDATE_FEDEX_PACKED:
            return {
                ...state,
                FedExPackedCount: action.increment ? state.FedExPackedCount + 1 : state.FedExPackedCount - 1
            }
        case UPDATE_CANPAR_SCANNED:
            return {
                ...state,
                CanparScannedCount: action.increment ? state.CanparScannedCount + 1 : state.CanparScannedCount - 1
            }
        case UPDATE_CANPAR_PACKED:
            return {
                ...state,
                CanparPackedCount: action.increment ? state.CanparPackedCount + 1 : state.CanparPackedCount - 1
            }
        case UPDATE_USPS_SCANNED:
            return {
                ...state,
                USPSScannedCount: action.increment ? state.USPSScannedCount + 1 : state.USPSScannedCount - 1
            }
        case UPDATE_USPS_PACKED:
            return {
                ...state,
                USPSPackedCount: action.increment ? state.USPSPackedCount + 1 : state.USPSPackedCount - 1
            }
        case UPDATE_TOTAL_PRINTED:
            return {
                ...state,
                totalPrintedCount: action.increment ? state.totalPrintedCount + 1 : state.totalPrintedCount - 1
            }
        case UPDATE_FEDEX_PRINTED:
            return {
                ...state,
                FedExPrintedCount: action.increment ? state.FedExPrintedCount + 1 : state.FedExPrintedCount - 1
            }
        case UPDATE_CANPAR_PRINTED:
            return {
                ...state,
                CanparPrintedCount: action.increment ? state.CanparPrintedCount + 1 : state.CanparPrintedCount - 1
            }
        case UPDATE_USPS_PRINTED: {
            return {
                ...state,
                USPSPrintedCount: action.increment ? state.USPSPrintedCount + 1 : state.USPSPrintedCount - 1
            }
        }
        case UPDATE_RAWSCANS:
            return {
                ...state,
                rawScans: [...state.rawScans, {scan: action.scan, key: action.key}]
            }
        case DELETE_RAWSCAN: {
            return {
                ...state,
                rawScans: state.rawScans.filter(scan => scan.key !== action.key)
            }
        }
        case UPDATE_RAWPACKED: {
            return {
                ...state,
                rawPacked: [...state.rawPacked, {scan: action.scan, key: action.key, shortCode: action.shortCode}]
            }
        }
        case DELETE_RAWPACKED: {
            return {
                ...state,
                rawPacked: state.rawPacked.filter(scan => scan.key !== action.key)
            }
        }


        default:
            return state;
    }
}