import React, {Component} from "react";
import "./Footer.css"
import {PROPERTIES} from "../../constants/config"

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="footer">
                {'\u00A9'} {PROPERTIES.YEAR} {PROPERTIES.COMPANY_NAME}
            </div>
        );
    }
}

export default Footer