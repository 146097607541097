import React, {useMemo} from "react";
import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";
import { BACK, SAVE, SETTINGS, STATEMENT_TAXES_PAGES, SUM_CATEGORY_OPTIONS, TYPE_OPTIONS } from "./constants";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import SaveIcon from "@material-ui/icons/Save";
import { IconButton, Menu, MenuItem } from "@mui/material";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuIcon from '@mui/icons-material/Menu';
import RefreshSharpIcon from '@mui/icons-material/RefreshSharp';

class StatementTemplates extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            templates: this.props.templates || [],
            anchorEl: null,
            activeIndex: null,
            draggedIndex: null,
            menuItems: [], // Added to store menu items
        };
    }

    componentDidMount() {

        if (this.props.templates && this.props.templates.length > 0) {
            this.setState({ templates: [...this.props.templates] });
        }

        this.setMenuItems();
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            nextProps.templates !== this.props.templates ||
            nextState.templates !== this.state.templates ||
            nextState.anchorEl !== this.state.anchorEl ||
            nextState.activeIndex !== this.state.activeIndex ||
            nextState.draggedIndex !== this.state.draggedIndex ||
            nextState.menuItems !== this.state.menuItems
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.categories !== this.props.categories || prevProps.templates !== this.props.templates) {
            this.setMenuItems();
        }
    }

    setMenuItems = () => {
        const { categories } = this.props;
        const menuItems = categories
            .filter(category => !category.name.includes('Taxes'))
            .map(category => (
                <MenuItem
                    key={category.name}
                    onClick={() => this.handleCategorySelect(category)}
                    style={{ padding: '10px', minWidth: '200px' }}
                >
                    {category.name}
                </MenuItem>
            ));

        this.setState({ menuItems });
    };


    onDragStart = (index) => {
        this.setState({ draggedIndex: index });
    };

    onDragOver = (index) => {
        const { draggedIndex, templates } = this.state;
        if (draggedIndex === index) return;

        const updatedTemplates = [...templates];
        const [draggedItem] = updatedTemplates.splice(draggedIndex, 1);
        updatedTemplates.splice(index, 0, draggedItem);

        this.setState({ templates: updatedTemplates, draggedIndex: index });
    };

    onDragEnd = () => {
        this.setState({ draggedIndex: null });
    };

    handleMenuOpen = (event, index) => {
        this.setState({ anchorEl: event.currentTarget, activeIndex: index });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null, activeIndex: null });
    };

    handleCategorySelect = (category) => {
        const { activeIndex, templates } = this.state;
        const updatedTemplates = [...templates];
        updatedTemplates[activeIndex].description = category.name;
        this.setState({ templates: updatedTemplates, anchorEl: null, activeIndex: null });
    };

    getContextualMenuItems = () => [
        {
            title: BACK,
            icon: <KeyboardBackspaceIcon />,
            onClick: () => { this.props.setCurrentPage(STATEMENT_TAXES_PAGES.TABLE, {}); },
        },
        {
            title: SAVE,
            icon: <SaveIcon />,
            onClick: this.handleSave,
            disabled: this.isSaveDisabled(),
        }
    ];

    handleSave = () => {
        this.props.handleSave(this.state.templates);
    };

    isSaveDisabled = () => {
        return this.state.templates.some(template =>
            !template.description || !template.type
        );
    };

    handleTemplateChange = (e, index) => {
        const { name, value } = e.target;
        this.setState(prevState => {
            const updatedTemplates = [...prevState.templates];
            updatedTemplates[index] = {
                ...updatedTemplates[index],
                [name]: value,
            };

            if (name === 'sumRule' && !value) {
                updatedTemplates[index].category = '';
            }

            return { templates: updatedTemplates };
        });
    };

    removeTemplateRow = (index) => {
        this.setState(prevState => {
            const updatedTemplates = prevState.templates.filter((_, curIndex) => curIndex !== index);
            return { templates: updatedTemplates };
        });
    };

    refreshCategory = () => {
        const { transactions } = this.props;
        const { templates } = this.state;

        // Extract unique categories from transactions
        const categories = [...new Set(transactions.map(transaction => transaction.category))];

        // Create new templates for the unique categories
        const newTemplates = categories
            .filter(category => !templates.some(template => template.category === category || template.description === category))
            .map(category => ({
                description: category,
                type: "",
                sumRule: "",
                category: "",
            }));

        // Update the state with the new templates
        const updatedTemplates = [...templates, ...newTemplates];

        this.setState({ templates: updatedTemplates });
    };


    addTemplateRow = () => {
        this.setState(prevState => {
            const updatedTemplates = [...prevState.templates, {
                description: "",
                type: "",
                sumRule: "",
                category: "",
            }];
            return { templates: updatedTemplates };
        });
    };

    render() {
        const { templates, anchorEl, draggedIndex,menuItems  } = this.state;
        const { categories } = this.props;

        return (
            <React.Fragment>
                <TopToolbar
                    pageName={SETTINGS}
                    menuItems={this.getContextualMenuItems()}
                />

                <div style={{ display: "flex", justifyContent: "center", marginTop: "16px" }}>
                    <div className="transaction-form-section">
                        {templates.map((template, index) => (
                            <TemplateRow
                                key={index}
                                index={index}
                                template={template}
                                draggedIndex={draggedIndex}
                                categories={categories}
                                onDragStart={this.onDragStart}
                                onDragOver={this.onDragOver}
                                onDragEnd={this.onDragEnd}
                                handleTemplateChange={this.handleTemplateChange}
                                removeTemplateRow={this.removeTemplateRow}
                                handleMenuOpen={this.handleMenuOpen}
                                anchorEl={anchorEl}
                                handleMenuClose={this.handleMenuClose}
                                handleCategorySelect={this.handleCategorySelect}
                                menuItems={menuItems}
                            />
                        ))}
                        <div style={{ marginTop: '30px' }}>
                            <IconButton onClick={this.refreshCategory}>
                                <RefreshSharpIcon fontSize="small" color='black' />
                            </IconButton>
                            <IconButton onClick={this.addTemplateRow}>
                                <AddCircleIcon fontSize="small" color='secondary' />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const TemplateRow = React.memo(({
                                    index, template, draggedIndex, categories, onDragStart, onDragOver, onDragEnd,
                                    handleTemplateChange, removeTemplateRow, handleMenuOpen, anchorEl, handleMenuClose, handleCategorySelect, menuItems
                                }) => {

    const handleClickMenuOpen = (e) => {
        handleMenuOpen(e, index);
    };

    const memoizedMenuItems = React.useMemo(() => menuItems.map(item => React.cloneElement(item)), [menuItems]);


    return (
        <div
            className="transaction-form-details"
            draggable
            onDragStart={() => onDragStart(index)}
            onDragOver={() => onDragOver(index)}
            onDragEnd={onDragEnd}
            style={{ background: draggedIndex === index ? "#f0f0f0" : "white" }}
        >
            <div style={{ width: '4.5%', display: "flex" }}>
                <IconButton onClick={() => removeTemplateRow(index)}>
                    <RemoveCircleIcon fontSize="small" color="error" />
                </IconButton>
            </div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                    variant="outlined"
                    name="description"
                    label="Description"
                    InputLabelProps={{ shrink: true }}
                    value={template.description || ""}
                    onChange={(e) => handleTemplateChange({ target: { name: "description", value: e.target.value } }, index)}
                    required
                    error={!template.description}
                />
                <IconButton onClick={handleClickMenuOpen}>
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    PaperProps={{
                        style: {
                            marginLeft: "16px",
                            border: "1px solid #b2b2b2",
                            borderRadius: "6px",
                            boxShadow: 'none',
                        },
                    }}
                >
                    {memoizedMenuItems}
                </Menu>
            </div>

            <Autocomplete
                options={TYPE_OPTIONS}
                getOptionLabel={(option) => option}
                value={template.type || null}
                onChange={(e, value) => handleTemplateChange({ target: { name: "type", value: value } }, index)}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        name="type"
                        label="Type"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        required
                        error={!template.type}
                    />
                }
            />

            <Autocomplete
                options={SUM_CATEGORY_OPTIONS}
                getOptionLabel={(option) => option}
                value={template.sumRule || null}
                onChange={(e, value) => handleTemplateChange({ target: { name: "sumRule", value: value } }, index)}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        name="sumRule"
                        label="Sum Rule"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}

                    />
                }
            />

            <Autocomplete
                options={(categories || []).slice()?.sort((a, b) => a.displayOrder - b.displayOrder)}
                getOptionLabel={(option) => option.name || ""}
                value={categories.find(category => category.name === template.category) || null}
                onChange={(e, value) => handleTemplateChange({
                    target: { name: "category", value: value ? value.name : null },
                }, index)}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        name="category"
                        label="Category"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                    />
                }
                disabled={!template.sumRule}
            />


        </div>
    );
});


export default StatementTemplates;



