import React, { useState, useCallback, useEffect } from 'react'
import Card from './Card'
import update from 'immutability-helper'
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
const style = {
  width: 150,
}
const Container = (props) => {
  {
    const [cards, setCards] = useState(props.cards)
    useEffect(() => {
      setCards(props.cards)

    }, [props.cards]);
    const moveCard = useCallback(
      (dragIndex, hoverIndex) => {
        const dragCard = cards[dragIndex]
        setCards(
          update(cards, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragCard],
            ],
          }),
        )
        props.handleTableDisplayChange(
            update(cards, {
                $splice: [
                  [dragIndex, 1],
                  [hoverIndex, 0, dragCard],
                ],
              })
        )
        update(cards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        })
      },
      [cards],
    )
    
    return (
      <>
        <List>
            {props.items?.map((card, i) =>
              <Card
                index={i}
                id={card.id}
                moveCard={moveCard}
                item={card.component}
                disabled={props.disabled}
              />
            )}
        </List>
        
      </>
    )
  }
}
export default Container