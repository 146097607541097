import {
    createSelector
} from 'reselect';

export const statusSelector = createSelector(state => state && state.global && state.global.status, statusSelector => statusSelector);
export const loadingSelector = createSelector(state => state && state.global && state.global.loading, loadingSelector => loadingSelector);
export const pricingDashboardLoadingSelector = createSelector(state => state && state.global && state.global.pricingDashboardLoading, pricingDashboardLoadingSelector => pricingDashboardLoadingSelector);
export const openSelector = createSelector(state => state && state.global && state.global.open, openSelector => openSelector);
export const savingSelector = createSelector(state => state && state.global && state.global.saving, savingSelector => savingSelector);
export const savingOpenSelector = createSelector(state => state && state.global && state.global.savingOpen, savingOpenSelector => savingOpenSelector);
export const createOrderOpenSelector = createSelector(state => state && state.global && state.global.createOrderOpen, createOrderOpenSelector => createOrderOpenSelector);
export const createReturnOpenSelector = createSelector(state => state && state.global && state.global.createReturnOpen, createReturnOpenSelector => createReturnOpenSelector);
export const createClonedOpenSelector = createSelector(state => state && state.global && state.global.createClonedOpen, createClonedOpenSelector => createClonedOpenSelector);
export const voidOpenSelector = createSelector(state => state && state.global && state.global.voidOpen, voidOpenSelector => voidOpenSelector);
export const voidStatusSelector = createSelector(state => state && state.global && state.global.voidStatus, voidStatusSelector => voidStatusSelector);
export const archiveOpenSelector = createSelector(state => state && state.global && state.global.archiveOpen, archiveOpenSelector => archiveOpenSelector);
export const archiveStatusSelector = createSelector(state => state && state.global && state.global.archiveStatus, archiveStatusSelector => archiveStatusSelector);
export const showingMenuItemsSelector = createSelector(state => state && state.global && state.global.showingMenuItems, showingMenuItemsSelector => showingMenuItemsSelector)
export const showErrorSelector = createSelector(state => state && state.global && state.global.showError, showErrorSelector => showErrorSelector)
export const errorMessageSelector = createSelector(state => state && state.global && state.global.errorMessage, errorMessageSelector => errorMessageSelector)
export const stateAndProvinceMappingSelector = createSelector(state => state && state.global && state.global.stateAndProvinceMapping, stateAndProvinceMappingSelector => stateAndProvinceMappingSelector)
