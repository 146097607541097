import {
    STORE_ORDERS,
    STORE_ORDER_RESPONSE, STORE_MARKETPLACE_COMPANIES, STORE_MARKETPLACE_NAMES_BY_COMPANY
} from "../../constants/admin";

const initialState = {
    orders: [],
    orderResponse: {},
    marketplaceCompanies: [],
    marketplaceCountries: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case STORE_ORDERS:
            return {
                ...state,
                orders: action.orders
            }
        case STORE_ORDER_RESPONSE:
            return {
               ...state,
               orderResponse: action.data.types
            }
        case STORE_MARKETPLACE_NAMES_BY_COMPANY:
            return {
                ...state,
                marketplaceNamesByCompany: action.data.marketplaceNames
            }
        case STORE_MARKETPLACE_COMPANIES:
            return {
                ...state,
                marketplaceCompanies: action.data
            }
        default:
            return state
    }
};