import React from "react";
import withShipment from "../../../withShipment";
import './Barcodes.css';
import {BARCODES_PAGES} from "./constants";
import ErrorFeedbackBar from "../../global/ErrorFeedbackBar";
import BarcodesTable from "./BarcodesPages/BarcodesTable";
import {getPrintConfig, listProducts} from "../../../redux/actions/settings";


class Barcodes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: BARCODES_PAGES.TABLE
        }

        this.props.listProducts()
        this.props.getPrintConfig()
    }

    setCurrentPage = (newPage, pageChangeInfo) => {
        switch (this.state.currentPage){
            case BARCODES_PAGES.TABLE:
                break
        }

        switch (newPage) {
            case BARCODES_PAGES.TABLE:
                break
        }

        this.setState({
            currentPage: newPage
        })
    }

    displayTable = () => {
        return <React.Fragment>
            <BarcodesTable
                setCurrentPage={this.setCurrentPage}
            />
        </React.Fragment>
    }

    displayCurrentPage = (currentPage) => {
        switch (currentPage) {
            case BARCODES_PAGES.TABLE:
                return this.displayTable()
            default:
                console.log("Error: current barcodes page doesn't exist")
                return <div>We encountered an error. Please refresh the page.</div>
        }
    }

    render() {
        return (
            <React.Fragment>
                <ErrorFeedbackBar/>
                {this.displayCurrentPage(this.state.currentPage)}
            </React.Fragment>
        )
    }
}

Barcodes.defaultProps = {
}

Barcodes.propTypes = {
}

const mapStateToProps = (state) => ({
})

const actionCreators = {
    listProducts,
    getPrintConfig
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, Barcodes);