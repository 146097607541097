import React from 'react';
import Base from './pages/Base/Base';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { store, persistor } from './redux/store/';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';

import Loading from './components/loading/Loading';
import ScrollToTop from "./ScrollToTop";
import purple from "@material-ui/core/colors/purple";
import green from "@material-ui/core/colors/green";
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import yellow from "@material-ui/core/colors/yellow";
function App() {
    const theme = createTheme({
        palette: {
            primary: {
                main: '#005E73',
            },
            secondary: {
                main: '#acd685',
            },
            error: {
                main: '#f50057'
            },
            tertiary: {
                main: '#e3d507'
            }

        },
    });
  return (
    <div className='shipment-background'>
      <Provider store={store}>
        <PersistGate loading={<Loading />} persistor={persistor}>
          <BrowserRouter>
            <ScrollToTop>
              <ThemeProvider theme={theme}>
                  <Base />
              </ThemeProvider>
            </ScrollToTop>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
