
export const LISTINGS_PAGES = {
    TABLE: "table",
    LOADING: "loading",
    SETTINGS : "Settings"

}

export const LISTING_TITLE = "Listings";
export const LISTING_SETTING_TITLE = "Listings Settings";
export const INVENTORY_ALLOCATION_INTRO = "Whenever inventory is changed for any product, the inventory quantity of the corresponding listing on each marketplace will adjust automatically based on % allocation below. If no % is assigned, that marketplace will be ignored.";
export const INVENTORY_ALLOCATION_TRIGGER_CHECK_INTRO = "System designated check that trigger automatically:";

export const INVENTORY_ALLOCATION_TRIGGER_CHECK = [
    {condition: "Out of Stock", action: "set corresponding quantity to 0"},
    {condition: "Insufficient Stock", action: "set corresponding quantity to 0"}
]
export const LISTINGS_ROWS_PER_PAGE_LABEL = "Listings per page"
export const EDIT_ENTRY = "Edit";

export const SKU_FILTER_NAMES = {
    ALL_SKUS: "allSkus",
    ACTIVE_SKUS: "activeSkus",
    INACTIVE_SKUS: "inactiveSkus",
    UNMAPPED_SKUS: "unmappedSkus",
    DELETED_SKUS: "deletedSkus"
}

export const CANCEL_EDIT_ENTRY = "Cancel";
export const APPLY_EDIT_ENTRY = "Apply";
export const DELETE_SKU = "Delete Sku";
export const LOADING_MESSAGE = "This may take a few minutes";


