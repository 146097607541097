import React from "react";

// material components
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  Checkbox
} from '@material-ui/core';

// custom components
import GlobalTableHead from "../../global/Table/GlobalTableHead";
import SearchField from "../../global/Search/SearchField";

// import styling
import "./QueryConditionFiltersTable.css";
import "../../global/SecondaryToolbar.css";

// import constants
import {
  DEFAULT_SORT_BY_HEAD_CELL,
  DEFAULT_ROWS_PER_PAGE,
  HEAD_ACTION_CELLS,
  ACTIONS, CONDITION_ACTIONS
} from "./constants";

import {
  FILTER_TYPE_BOOLEAN_1,
  FILTER_TYPE_BOOLEAN_2,
  FILTER_TYPE_NUMERIC,
  FILTER_TYPE_TEXT,
} from "../constants";

import { ASC, DESC } from "../../global/constants";
import SearchableTable from "../../global/Search/SearchableTable";
import PropTypes from "prop-types";
import {automationInfoSelector} from "../../../redux/selectors/account";
import withShipment from "../../../withShipment";
import { updateCompany, getCompany } from "../../../redux/actions/automationRules"



/**
 * Used in ConditionDialog
 *
 * How search works: The search field is passing on a call back function onSearchValueChange
 * Within ShipmentsSearchField, when onChange is triggered, the onSearchValueChange field is
 * called and that updates the searchValue field in the state.
 */
class ConditionFiltersRulesTable extends SearchableTable {
  constructor(props) {
    super(props);
    this.state = {
      rowsPerPage: DEFAULT_ROWS_PER_PAGE, // numbrt of rows per page
      page: 0, // page number
      order: ASC, // sort ascending or desending state
      orderBy: DEFAULT_SORT_BY_HEAD_CELL, // sorting order column
      isSearch: false, // true if search value is not empty, false otherwise
      searchValue: "", // the value in the search field (updates on change)
      searchRows: 0, // number of rows displayed while search criteria is met
      selectedProfitStatus: false, //to disable profit filter or not
    };
  }

  componentDidMount() {
    this.props.getCompany()
    this.setState({
      selectedProfitStatus: this.props?.automationSetting?.accountForProfitOrLoss,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.automationSetting !== this.props.automationSetting) {
      this.setState({
        selectedProfitStatus: this.props?.automationSetting?.accountForProfitOrLoss,
      });
    }
  }

  handleChangeDense = (event) => {
    this.setState({
      dense: event.target.checked,
    });
  };

  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === ASC;
    this.setState({
      order: isAsc ? DESC : ASC,
      orderBy: property,
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });
  };

  onSearchValueChange = (event) => {
    this.setState({
      isSearch: event.target.value.length > 0 ? true : false,
      searchValue: event.target.value,
    });
  };

  /**
   * Renders this component
   */
  render() {
    return (
      <div className='root'>
        <Paper className='paper'>
          {/* The row below the toolbar */}
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow className='secondaryToolbar2'>
                  <TableCell>
                    <SearchField onChange={this.onSearchValueChange} autoFocus={true} page='auto' searchText={this.state.searchValue} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <TableContainer className='conditionActionsContainer'>
            <Table
              aria-labelledby='tableTitle'
              size='medium'
              aria-label={CONDITION_ACTIONS}
            >
              <GlobalTableHead
                isShipmentTable={false}
                headCells={HEAD_ACTION_CELLS}
                numSelected={this.props.selected.length}
                order={this.state.order}
                orderBy={this.state.orderBy}
                onSelectAllClick={(event) =>
                  this.props.handleSelectAllClick(event, ACTIONS, false)
                }
                onRequestSort={this.handleRequestSort}
                rowCount={ACTIONS.length}
              />
              <TableBody>
                {/** only search by the first column "filter" */}
                {this.stableSort(
                  this.filterBySearchWithSpecificKey(ACTIONS, "name"),
                  this.getComparator(this.state.order, "name")
                )
                  .map((action, index) => {
                    const isItemSelected = this.props.isSelected(action.name, false);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={action.name === 'Profit Threshold' && !this.state.selectedProfitStatus ? null : (event) =>
                            this.props.handleClick(event, action, false)
                        }
                        role='checkbox'
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={action.name}
                        selected={isItemSelected}
                      >
                        <TableCell padding='checkbox'>
                          <Checkbox
                            checked={isItemSelected}
                            onClick={action.name === 'Profit Threshold' && !this.state.selectedProfitStatus ? null : (event) =>
                                this.props.handleClick(event, action, false)
                            }
                            inputProps={{ "aria-labelledby": labelId }}
                            disabled={action.name === 'Profit Threshold' && !this.state.selectedProfitStatus}
                          />
                        </TableCell>
                        <TableCell
                          align='left'
                          id={labelId}
                          scope='row'
                          padding='none'
                          style={{ color: action.name === 'Profit Threshold' && !this.state.selectedProfitStatus ? "gray" : "inherit" }}
                        >

                          {action.name}
                        </TableCell>
                        {/* <TableCell align="left">{row.address} {row.groupname}</TableCell> */}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    );
  }
}

ConditionFiltersRulesTable.propTypes = {
  selected: PropTypes.array,
  isSelected: PropTypes.func,
  handleClick: PropTypes.func,
  handleSelectAllClick: PropTypes.func
}

const mapStateToProps = (state) => ({
  automationSetting: automationInfoSelector(state)
})
const actionCreators = {
  getCompany,
}

ConditionFiltersRulesTable.defaultProps = {
  selected: [],
  isSelected: () => { },
  handleClick: () => { },
  handleSelectAllClick: () => { }
}

export default withShipment({
  mapStateToProps,
  actionCreators
}, ConditionFiltersRulesTable);
