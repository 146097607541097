import React from "react";

// material components
import {
  Select,
  MenuItem,
  Box,
  TextField,
  Tooltip,
  IconButton,
} from "@material-ui/core";

// material icons
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

// import constants
import {FILTER_TYPE_TEXT_OPTIONS, INFO_ON_CONTENT_FILTER} from "./constants";
import {COPY_FILTER, DELETE_FILTER, DRAG} from "./constants";

// import styling
import "./QueryFilterTextField.css";
import PropTypes from "prop-types";
import DehazeIcon from "@material-ui/icons/Dehaze";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import {
  CONTINUE_ON_MATCH,
  EXIT_ON_MATCH,
  INFO_ON_CONTINUE_OPTION,
  INFO_ON_EXIT_OPTION
} from "../../automationrules/constants";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

/**
 * Numeric Textfield with predefined dropdown and an inputTextField.
 */
class FilterTextField extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filterId: "",
      name: "",
      value1: "", // value for the first drop down
      value2: "", // value of the textfield
    };
  }

  componentDidMount = () => {
    this.setState({
      // on component mount, the parent component stores this child's state.
      // We restore it here
      ...this.props.filter,
    });
  };

  handleValue1Change = (event) => {
    const value1 = event.target.value;
    this.setState(
      {
        value1,
      },
      () => {
        this.props.updateFilterWithParent({ ...this.state });
      }
    );
  };

  handleValue2Change = (event) => {
    const value2 = event.target.value;
    this.setState(
      {
        value2,
      },
      () => {
        this.props.updateFilterWithParent({ ...this.state });
      }
    );
  };

  handleValue2DateChange = async (date) => {
    await this.setState({
      value2: date.toISOString().split('T')[0].replaceAll('-', '/')
    },
        () => {
          this.props.updateFilterWithParent({ ...this.state });
        }
        );
  }

  render() {
    let info = (<div></div>)
    if (this.props.filter.name === "Contents") {
      info = (
      <Tooltip
          className='infoTooltipIcon'
          enterTouchDelay={0}
          leaveTouchDelay={30000}
          enterDelay={400}
          // leaveDelay={1000000} // for testing purposes
          title={
            <React.Fragment>
              {INFO_ON_CONTENT_FILTER}
            </React.Fragment>
          }
      >
        <IconButton aria-label='info'>
          <InfoOutlinedIcon variant='outlined' fontSize='small' />
        </IconButton>
      </Tooltip>
      )
    }
    return (
      <Box
        className='filterTextField'
        display='flex'
        flexDirection='row'
        justifyContent='left'
        alignItems='center'
      >
        <Box className='filterTextFieldName'>{this.state.name}</Box>
        {!(this.state.name === "From Date" || this.state.name === "To Date") ?
            <Select
                className='filterTextFieldOptions'
                variant='outlined'
                label={this.state.name}
                value={this.state.value1}
                onChange={this.handleValue1Change}
            >
              {FILTER_TYPE_TEXT_OPTIONS.map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
            :
            null
        }
        {!(this.state.name === "From Date" || this.state.name === "To Date") ?
            <div>
              <TextField
                  className='filterTextFieldInput'
                  variant='outlined'
                  label={this.state.name}
                  value={this.state.value2}
                  onChange={this.handleValue2Change}
                  InputLabelProps={{
                    shrink: true,
                  }}
              />
            </div>
            :
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                  autoOk={false}
                  name="fromDate"
                  variant="outlined"
                  className="filterDateInput"
                  inputVariant="outlined"
                  label={this.state.name}
                  format="yyyy/MM/dd"
                  inputValue={this.state.value2}
                  InputAdornmentProps={{ position: "start" }}
                  onChange={date => {this.handleValue2DateChange(date)}}
              />
            </MuiPickersUtilsProvider>
        }
        {info}
        <div className='filterBooleanEmptyDiv'>
          <Tooltip title={COPY_FILTER}>
            <IconButton
                disabled={this.state.name !== "Do Not Include"}
                aria-label={COPY_FILTER}
                onClick={() => this.state.name === "Do Not Include" ? this.props.copyFilter(this.state.filterId) : null}>
              <FileCopyIcon fontSize='small'/>
            </IconButton>
          </Tooltip>
          <Tooltip title={DELETE_FILTER}>
            <IconButton
              aria-label={DELETE_FILTER}
              onClick={() => this.props.deleteFilter(this.state.filterId)}
            >
              <DeleteOutlineIcon variant='outlined' fontSize='small' />
            </IconButton>
          </Tooltip>
          <Tooltip title={DRAG}>
            <IconButton
                aria-label='drag'
            >
              <DehazeIcon variant='outlined' fontSize='small' />
            </IconButton>
          </Tooltip>
        </div>
      </Box>
    );
  }
}

FilterTextField.propTypes = {
  filter: PropTypes.object,
  updateFilterWithParent: PropTypes.func,
  deleteFilter: PropTypes.func,
  isEdit: PropTypes.bool,
  copyFilter: PropTypes.func
}

FilterTextField.defaultProps = {
  filter: {},
  updateFilterWithParent: ()=>{},
  deleteFilter: ()=>{},
  isEdit: false,
  copyFilter: ()=>{}
}

export default FilterTextField;
