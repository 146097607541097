export const ADD_CHEQUE = "Add Cheque"
export const IMPORT_CHEQUE = "Import Cheque"
export const EDIT_CHEQUE = "Edit Cheque"
export const PRINT_CHEQUE = "Print Cheque"

export const CHEQUE = "Cheques"

export const CHEQUE_FIELDS_HEAD_CELLS = [
    { id: 'payee', center: true, disablePadding: false, addDollar: false, rightAlign: false, label: 'Payee'},
    { id: 'chequeNumber', center: true, disablePadding: false, addDollar: false, rightAlign: false, label: 'Cheque Number'},
    { id: 'accountNumber', center: true, disablePadding: false, addDollar: false, rightAlign: false, label: 'Account Number'},
    { id: 'paymentDate', center: true, disablePadding: false, addDollar: false, rightAlign: false, label: 'Payment Date'},
    { id: 'amount', center: true, disablePadding: false, addDollar: true, rightAlign: true, label: 'Amount'},
    { id: 'status', center: true, disablePadding: false, addDollar: false, rightAlign: false, label: 'Status'},
    { id: 'currency', center: true, disablePadding: false, addDollar: false, rightAlign: false, label: 'Currency'},
    { id: 'category', center: true, disablePadding: false, addDollar: false, rightAlign: false, label: 'Category'},
]

export const ROWS_PER_PAGE_LABEL = "Cheque per page"

export const DEFAULT_SORT_BY_HEAD_CELL = "date"

export const DEFAULT_ROWS_PER_PAGE = 50;

export const CHEQUE_PAGES = {
    TABLE: "table",
    PREVIEW: "preview",
    ADD_CHECK: "add_check",
}

// tooltip names
export const PREVIEW = "Preview Cheque"
export const BACK = "Back to Menu"
export const SAVE_DRAFT = "Save Draft"
