import React from "react";
import {
    Button, Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl, FormHelperText, IconButton,
    MenuItem,
    Select,
    TextField, Tooltip
} from "@material-ui/core";

// material components
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

// constants

// styling
import "./FilePreviewDialog.css"
import {NAVIGATE_LEFT, NAVIGATE_RIGHT, START_INDEX} from "./constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";
import DeleteDialog from "./DeleteDialog";

class FilePreviewDialog extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            index: this.props.initialIndex,
            previewRequestOpen: false,
            previewCalled: false,
            fetchingPreviews: []

        }
    }

    componentDidMount() {
        this.setState({
            fetchingPreviews: Array(this.props.files.length).fill(0)
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.files.length !== this.props.files.length) {

        }
    }


    iterateFiles = (left) => {
        let curIndex = this.state.index
        // are we at the final limits
        if(((curIndex === this.props.files.length - 1) && !left) || (curIndex === 0 && left))
            return

        if(left)
            curIndex -= 1
        else
            curIndex += 1

        this.setState({
            index: curIndex
        })

    }

    fetchFilePreview = (file)=> {

        if(this.state.fetchingPreviews[this.state.index] === 0){
            let currentFetchingPreviews = this.state.fetchingPreviews
            currentFetchingPreviews[this.state.index] += 1
            this.setState({
                fetchingPreviews: currentFetchingPreviews
            }, ()=>{
                if(this.state.fetchingPreviews[this.state.index] === 1){
                    this.props.fetchFilePreview(file);
                }
            })
        }


        return <CircularProgress/>
    }


    getFilePreview = (file) => {

        if("localURL" in file){
            return (
                <div className='image-outline'>
                    <img src={this.props.files[this.state.index].localURL} className='logoImage'/>
                </div>
                )
        }

        return this.fetchFilePreview(this.props.files[this.state.index]);



    }
    render() {
        return (
            <>
                {/* <div className='logo-preview-header'>{this.props.files[this.state.index].displayName}</div> */}
                <div className='file-preview-content'>
                    <Tooltip title={NAVIGATE_LEFT}>
                        <IconButton
                            aria-label='previous'
                            onClick={() => this.iterateFiles(true)}
                            disabled={this.state.index === START_INDEX}
                        >
                            <ChevronLeftIcon variant='outlined' fontSize='large'/>
                        </IconButton>
                    </Tooltip>


                    {this.getFilePreview(this.props.files[this.state.index])}


                    <Tooltip title={NAVIGATE_RIGHT}>
                        <IconButton
                            aria-label='next'
                            onClick={() => this.iterateFiles(false)}
                            disabled={this.state.index === this.props.files.length - 1}
                        >
                            <ChevronRightIcon variant='outlined' fontSize='large'/>
                        </IconButton>
                    </Tooltip>

                </div>
            </>
        );
    }

}

FilePreviewDialog.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    files: PropTypes.array,
    initialIndex: PropTypes.number,
    fetchFilePreview: PropTypes.func
}

FilePreviewDialog.defaultProps = {
    open: false,
    handleClose: ()=>{},
    files: [],
    initialIndex: 0,
    fetchFilePreview: ()=>{}
}

export default FilePreviewDialog;