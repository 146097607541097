import * as React from 'react';
import withShipment from '../../withShipment';

// websockets
// import Websocket from "./websocket";
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';

import { buttonSelected } from '../../redux/actions/test';
import {
  Container,
  Button,
  TextField
} from '@material-ui/core';
import { getButtonSelectedSelector } from '../../redux/selectors/test';

class Playground extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      input: '',
      response: ''
    }
  }

  timeoutHandler = (message) => {
    const headers = {
      "Authorization": `Bearer ${localStorage.getItem('token')}`
    }
    const socket = new SockJS(`http://localhost:8080/stomp`);
    const client = Stomp.over(socket);

    client.connect(headers, () => {
      client.subscribe('/topic/hello', () => {
        // do something
      });
    }, () => {
      console.error('unable to connect');
    });
  };

  buttonClicked = (event) => {
    this.props.buttonSelected(event.currentTarget.name);
  };

  handleChange = (e) => {
    this.timeoutHandler('hello')
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  render() {
    return (
      <Container fixed>
        <div>
          <Button
            variant='contained'
            color='primary'
            name='primary'
            onClick={this.buttonClicked}
          >
            Primary
          </Button>
          <Button
            variant='contained'
            color='secondary'
            name='secondary'
            onClick={this.buttonClicked}
          >
            Secondary
          </Button>
          <TextField
              variant="outlined"
              value={this.state.input}
              name="input"
              onChange={this.handleChange}
          />
          <h1>
          </h1>
        </div>
        <div>{this.props.buttonSelectedName}</div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  buttonSelectedName: getButtonSelectedSelector(state),
});
const actionCreators = {
  buttonSelected,
};

export default withShipment({ mapStateToProps, actionCreators }, Playground);
