import {
    AUTH_LOGIN,
    STORE_TOKEN,
    REGISTER_USER,
    AUTH_LOGOUT, GET_USERS, STORE_USERS, UPDATE_USER_INFO, UPDATE_SELECTED_LOCATION,
} from "../../constants/auth";

export const authLogin = (username, password, history) => ({
    type: AUTH_LOGIN,
    username,
    password,
    history,
});

export const storeToken = (token, userInfo ) => ({
    type: STORE_TOKEN,
    token,
    userInfo,
    isAuthenticated: true
});

export const registerUser = (newUser) => ({
    type: REGISTER_USER,
    newUser,
});

export const authLogout = (history) => ({
    type: AUTH_LOGOUT,
    history,
});

export const getUsers = () => ({
    type: GET_USERS
})

export const storeUsers = (users) => ({
    type: STORE_USERS,
    users
})

export const updateUserInfo = (userInfo) => ({
    type: UPDATE_USER_INFO,
    userInfo
})

export const updateSelectedLocation = (selectedLocation) => ({
    type: UPDATE_SELECTED_LOCATION,
    selectedLocation
})