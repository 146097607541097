import React from "react";
import GlobalTableHead from "../global/Table/GlobalTableHead";
import {
    DEFAULT_ROWS_PER_PAGE,
    DEFAULT_SORT_BY_HEAD_CELL,
    EDIT_PURCHASE_ORDERS_CELLS,
    LIST_PURCHASE_ORDERS_CELLS, PACKING_SLIP_INFO, SUBMIT
} from "./constants";
import { Button, TableContainer, TableRow, TextField } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import {DESC, getByteArray} from "../global/constants";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import DescriptionIcon from "@material-ui/icons/Description";
import RenderPurchaseOrderPDFPreview from "./RenderPurchaseOrderPDFPreview";
import Collapse from "@material-ui/core/Collapse";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import CreatePurchaseOrder from "./CreatePurchaseOrder";
import './PurchaseOrderDetails.css';
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import DeleteIcon from "@material-ui/icons/Delete";
import FileUpload from "../global/FileUpload";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import FilePreviewDialog from "../global/FilePreviewDialog";
import {Tooltip} from "@mui/material";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

class PurchaseOrderDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rowsPerPage: DEFAULT_ROWS_PER_PAGE,
            page: 0,
            order: DESC,
            orderBy: DEFAULT_SORT_BY_HEAD_CELL,
            selectedOrder: [],
            isSearch: false,
            searchValue: '',
            searchRows: 0,
            isFilterApplied: false,
            filtersMap: {},
            filtersMapBeforeOpenDialog: {},
            selectedStatus: ['open', 'partial', 'completed'],
            dropdownAll: false,
            expectedDeliveryDate: this.initDate(),
            showDropdown: props.showDropdown,
            orderItems: props.purchaseOrder.orderItems,
            localQtyReceived: new Array(props.purchaseOrder.orderItems.length).fill("0"),
            openDelete: false,
            entryToDelete: '',
            dateChanged: false,
            poNumber: props.purchaseOrder.poNumber,
            receivedDate: new Date(),
            newDates: {},
            dates: [],
            newFiles: {},
            deletedFiles: {},
            files: [],
            filesChanged: false,
            receiverNotes: this.props.purchaseOrder.receiverNotes,
            receiverNotesChanged: false,
            datePickerDateError: false,
            previewing: false,
            isDelete: false,
            toEditPurchaseOrder: {},
            disableQtyField: false,
            previewingPSIndex: 0,
        };
    }

    componentDidMount() {
        let selectedPo = this.props.allPurchaseOrders.find((po) => po.poNumber === this.props.purchaseOrder.poNumber);
        let dates = []
        let files = []
        selectedPo.inventoryReceived.map((entry, index) => {
            dates.push(entry.createDate)
            //files.push(entry.files)
        })
        files[0] = selectedPo.packingSlipFiles
        this.setState({
            toEditPurchaseOrder: selectedPo,
            disableQtyField: selectedPo.status === "cancelled" || selectedPo.status === "completed",
            dates: dates,
            files: files,
            expectedDeliveryDate: this.props.purchaseOrder.expectedDate
        })
        this.props.canVoidPO(selectedPo.status === "open");
        this.props.checkCancelledPo(selectedPo.status === "cancelled");
        this.props.checkCompletedPo(selectedPo.status === "completed");

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let dates = []
        let files = []
        let updatedPo = this.props.allPurchaseOrders.find((po) => po.poNumber === this.props.purchaseOrder.poNumber);
        updatedPo.inventoryReceived.map((entry, index) => {
            dates.push(entry.createDate)
            //files.push(entry.files)
        })
        files[0] = updatedPo.packingSlipFiles
        if (prevProps.allPurchaseOrders !== this.props.allPurchaseOrders) {
            this.setState({
                toEditPurchaseOrder: updatedPo,
                orderItems: updatedPo.orderItems,
                disableQtyField: updatedPo.status === "cancelled" || updatedPo.status === "completed",
                dates: dates,
                files: files
            })
        }
        if (prevProps.purchaseOrder.expectedDate !== this.props.purchaseOrder.expectedDate){
            this.setState({
                expectedDeliveryDate : this.props.purchaseOrder.expectedDate
            })
        }
    }

    initDate = () => {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        return tomorrow
    }

    InvalidDate(newDate) {
        let today = new Date()
        today.setHours(0, 0, 0, 0)
        if ((new Date(newDate)) < today){
            this.setState({
                datePickerDateError : true
            })
            return true
        }
        else{
            this.setState({
                datePickerDateError : false
            })
            return false
        }
    }

    formatDate = (date) => {
        return date.substring(5, 7) + "/" + date.substring(8, 10) + "/" + date.substring(0, 4)
    }

    handlePreview = () => {
        this.props.fetchCreatedPurchaseOrderPreview(this.props.purchaseOrder.poNumber)
        this.setState({
            previewing: true
        })
    }

    handlePackingSlipPreview = (file, index) => {
        this.props.previewPackingSlipFile({
            path: file.path,
            poNumber: this.state.toEditPurchaseOrder.poNumber,
            index: 0
        })
        this.props.openPackingSlipPreview(file, this.state.toEditPurchaseOrder.poNumber)
        this.setState({
            previewingPSIndex: index,
        })
    }

    handleDeleteOpen = (entry) => {
        this.setState({
            entryToDelete: entry,
        });
    }

    handleDeleteClose = () => {
        this.setState({
            openDelete: false,
            entryToDelete: ''
        });
    }
    /*
    handleDateChange = (date, index) => {
        let entries = this.state.orderItems;
        entries[index].expectedDate = date;
        this.setState({
            orderItems: entries,
            dateChanged: true
        });
    }
     */

    dummyChange = (e, index) => {
        let local = this.state.localQtyReceived;
        local[index] = e.target.value;
        this.setState({
            localQtyReceived: local
        });
    }

    addStringInts = (one, two) => {
        return (parseInt(one) + parseInt(two)).toString();
    }

    handleUpdate(state, index) {
        let entries = this.state.orderItems
        let i = 0;
        entries.forEach(item => {
            item.quantityReceived = this.addStringInts(item.quantityReceived, this.state.localQtyReceived[i])
            i += 1
        });
        this.setState({
            localQtyReceived: new Array(this.props.purchaseOrder.orderItems.length).fill("0"),
            dateChanged: false,
            receiverNotesChanged: false,
            filesChanged: false,
            receivedDate: new Date(),
            newFiles: {},
            deletedFiles: {}
        });
        this.props.updatePurchaseOrder({ ...state, userId: this.props.user.userId });
    }

    checkNumberBound = (index) => {
        let entries = this.state.orderItems;

        let inBound = true
        if (parseInt(entries[index].quantityReceived) + parseInt(this.state.localQtyReceived[index]) > parseInt(entries[index].quantityOrdered)) {
            inBound = false
        }
        if (parseInt(this.state.localQtyReceived[index]) < 0 || this.state.localQtyReceived[index] === "") {
            inBound = false
        }
        return !inBound
    }

    isZero = (val) => {
        if (parseInt(val) < 1) {
            return '0';
        }
        return val;
    }

    checkAllNumberChanged = () => {
        return this.state.localQtyReceived.some(val => parseInt(val) > 0)
    }

    checkAllNumberBound = () => {
        let entries = this.state.orderItems;

        let inBound = true
        let index = 0
        entries.forEach(entrie => {
            if (parseInt(entrie.quantityReceived) + parseInt(this.state.localQtyReceived[index]) > parseInt(entrie.quantityOrdered)) {
                inBound = false
            }
            if (parseInt(this.state.localQtyReceived[index]) < 0) {
                inBound = false
            }
            if (this.state.localQtyReceived[index]==="") {
                inBound = false
            }
            index++
        })
        return inBound
    }

    handleAmountChange = (e, index) => {
        let newVal = e.target.value;
        if (parseInt(newVal) < 0) {
            return;
        }

        let localNewReceived = this.state.localQtyReceived
        localNewReceived[index] = newVal
        this.setState({
            localQtyReceived: localNewReceived,
        });
    }

    handleDateChange = (date, index) => {
        let newDates = this.state.newDates
        let dates = this.state.dates
        dates[index] = moment(date).format('YYYY-MM-DD')
        newDates[index] = date
        this.setState({
            newDates: newDates,
            dates: dates,
            dateChanged: true,
        })
    }

    handleDeleteConfirm = () => {
        this.props.deleteInventoryReceived(this.state.entryToDelete);
        this.handleDeleteClose();
        this.setState({
            localQtyReceived: new Array(this.props.purchaseOrder.orderItems.length).fill("0"),
        })
    }

    downloadPSFile = ()=> {
        let file = this.state.files[0][this.state.previewingPSIndex]
        let blob = new Blob([Uint8Array.from(file.byteArray)], {type: "application/pdf"});
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = file.displayName
        link.click();
        // now clear it from memory
        window.URL.revokeObjectURL(link.href)
    }

    handleReceiverNotesChange = (e) => {
        this.setState({
            receiverNotes: e.target.value,
            receiverNotesChanged: e.target.value !== this.props.purchaseOrder.receiverNotes
        })
    }

    toggleConfirmDelete = () => {
        this.setState({
            entryToDelete: ''
        })
    }

    freeFromMemory = (url) => {
        URL.revokeObjectURL(url)
    }

    getFormattedGeneralFileObject = (file) => {
        return { localURL: URL.createObjectURL(file), displayName: file.name, byteArray: getByteArray(file), type: file.type }

    }

    handleGeneralFileAdd = (file, index) => {
        let newFiles = this.state.newFiles
        //let prevNewFiles = this.state.newFiles
        //let prevFiles = this.state.files
        let newFile = this.getFormattedGeneralFileObject(file)
        if (newFiles[index] && newFiles[index].length > 0) {
            newFiles[index].push(newFile)
        }
        else {
            newFiles[index] = [newFile]
        }
        this.setState({
            newFiles: newFiles,
            filesChanged: true
        });
    }

    handleGeneralFileDelete = (file, fileIndex, index) => {
        let newFiles = this.state.newFiles
        let deletedFiles = this.state.deletedFiles
        let deletedFile = (newFiles[index].splice(fileIndex, 1))[0]
        /*let prevFiles = this.state.files
        let deletedFiles = this.state.deletedFiles
        let prevNewFiles = this.state.newFiles*/

        //files[index] = []
        let i = 0
        for (let file1 in newFiles[index]) {
            if (file1 === file) {
                newFiles[index].splice(i, 1)
            }
        }
        //newFiles[index] = []
        if (deletedFiles[index] && deletedFiles[index].length > 0) {
            deletedFiles[index].push(deletedFile)
        }
        else {
            deletedFiles[index] = [deletedFile]
        }
        if ("localURL" in file) {
            this.freeFromMemory(file.localURL)
        }

        this.setState({
            newFiles: newFiles,
            deletedFiles: deletedFiles,
            filesChanged: true
        })
    }

    render() {
        return (
            <>
                {this.props.previewing ?

                    <RenderPurchaseOrderPDFPreview
                        //allowSave={true}
                        data={this.props.pdfData}
                        //handlePreviewOpen={this.handlePreviewOpen}
                        //handlePreviewClose={this.handlePreviewClose}
                        //open={this.state.previewOpen}
                        handlePdfLoadSuccess={this.props.handlePdfLoadSuccess}
                        handlePdfLoadError={this.props.handlePdfLoadError}
                        scale={this.props.scale}
                    /> :

                    this.props.previewingPS ?
                        <RenderPurchaseOrderPDFPreview
                            //allowSave={true}
                            data={this.state.files[0][this.state.previewingPSIndex].byteArray}
                            //handlePreviewOpen={this.handlePreviewOpen}
                            //handlePreviewClose={this.handlePreviewClose}
                            //open={this.state.previewOpen}
                            handlePdfLoadSuccess={this.props.handlePdfLoadSuccess}
                            handlePdfLoadError={this.props.handlePdfLoadError}
                            scale={this.props.scale}
                        />
                    :

                    <div>
                        <Dialog
                            open={this.state.openDelete}
                            onClose={this.handleDeleteClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                Deleting Inventory Received
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Are you sure you would like to delete this entry from PO
                                    #: <b>{this.state.entryToDelete.poNumber}</b>?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleDeleteClose}>
                                    Cancel
                                </Button>
                                <Button onClick={this.handleDeleteConfirm}>
                                    Delete
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <div className="title-row">
                            <div className="textfields">
                                <TextField
                                    variant="outlined"
                                    name="poNumber"
                                    value={this.props.purchaseOrder.poNumber}
                                    onChange={() => {
                                    }}
                                    label="PO Number"
                                    aria-readonly={true}
                                    style={{ width: "inherit" }}
                                />
                            </div>
                            <div className="textfields">
                                <TextField
                                    variant="outlined"
                                    name="orderDate"
                                    value={this.formatDate(this.props.purchaseOrder.createDate)}
                                    onChange={() => {
                                    }}
                                    label="Order Date"
                                    style={{ width: "inherit" }}
                                />
                            </div>
                            <div className="textfields">
                                <KeyboardDatePicker
                                    autoOk
                                    variant="inline"
                                    inputVariant="outlined"
                                    label="Expected Delivery Date"
                                    format="MM/dd/yyyy"
                                    error={this.state.datePickerDateError}
                                    helperText={this.state.datePickerDateError ? "Expected Delivery Date cannot be earlier than today, please reselect" : ""}
                                    value={this.state.expectedDeliveryDate}
                                    InputAdornmentProps={{ position: "start" }}
                                    style={{ width: "inherit" }}
                                    onChange={date => {
                                        if(!this.InvalidDate(date.toISOString())){
                                            this.setState({
                                                expectedDeliveryDate : date.toISOString()
                                            })
                                            this.props.handleEditExpectedDeliveryDate(date.toISOString())
                                        }
                                    }}
                                />
                            </div>
                            <div className="pdf-row">
                                <TextField
                                    variant="outlined"
                                    name="status"
                                    value={this.state.toEditPurchaseOrder.status ? this.state.toEditPurchaseOrder.status.charAt(0).toUpperCase() + this.state.toEditPurchaseOrder.status.slice(1) : ''}
                                    onChange={() => {
                                    }}
                                    label="Status"
                                    style={{ width: "inherit" }}
                                />
                            </div>
                        </div>
                        <div className="title-row2">
                            <div className="textfields2">
                                <TextField
                                    variant="outlined"
                                    name="supplier"
                                    value={this.props.purchaseOrder.supplierName}
                                    onChange={() => {
                                    }}
                                    label="Supplier Name"
                                    style={{ width: "100%" }}
                                />
                            </div>
                        </div>

                        <TableContainer className="table">
                            <Table
                                aria-labelledby="tableTitle"
                                size={this.state.dense ? 'small' : 'medium'}
                                aria-label={this.props.title}
                                style={{ border: 'none' }}
                            >
                                <TableRow>
                                    <TableCell className="expanded-row no-border" colSpan={6}>
                                        <Table size={"small"} className="content-table">
                                            <TableHead>
                                                <TableRow>
                                                    {EDIT_PURCHASE_ORDERS_CELLS.map((headCell) => (
                                                        <TableCell key={headCell.id}
                                                            className={`${headCell.id !== 'quantityReceived' ? 'table-right-border' : 'qty-received-header'} center content-table-titles`}>
                                                            {headCell.label}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.toEditPurchaseOrder.orderItems && this.state.toEditPurchaseOrder.orderItems.map((item, index) => {
                                                    return (
                                                        <TableRow style={{height: "100px"}}>

                                                            <TableCell
                                                                className={`${index === this.props.purchaseOrder.orderItems.length - 1 ? 'no-border' : ' '} center table-right-border`}>
                                                                {item.shortcode}
                                                            </TableCell>
                                                            <TableCell
                                                                className={`${index === this.props.purchaseOrder.orderItems.length - 1 ? 'no-border' : ' '} center table-right-border`}>
                                                                {item.quantityOrdered}
                                                            </TableCell>
                                                            <TableCell
                                                                className={`${index === this.state.toEditPurchaseOrder.orderItems.length - 1 ? 'no-border' : ' '} center table-right-border`}>
                                                                <div className="qty-received-cell">
                                                                    <TextField
                                                                        style={{ paddingRight: 10 }}
                                                                        variant="outlined"
                                                                        label="Quantity"
                                                                        name="quantityReceived"
                                                                        value={this.state.localQtyReceived[index]}
                                                                        onChange={(e) => this.handleAmountChange(e, index)}
                                                                        className='shrink-qty-date-field'
                                                                        onFocus={(e) => e.target.select()}
                                                                        error={this.checkNumberBound(index)}
                                                                        size="medium"
                                                                        type="number"
                                                                     inputProps={{
                                                                            min: 0
                                                                        }}
                                                                        width="40%"
                                                                        disabled={this.state.disableQtyField}
                                                                    />
                                                                    <div>
                                                                        {this.state.toEditPurchaseOrder.inventoryReceived.length === 0 ? <div className="empty-div"></div> : null}
                                                                        {this.state.toEditPurchaseOrder.inventoryReceived.map((entry, index) => {
                                                                            return (
                                                                                <>
                                                                                    {entry.orderItems.shortcode === item.shortcode ?
                                                                                        <div
                                                                                            className="received-to-date-grid" style={{float: 'top'}}>
                                                                                            <div className="qty-received">
                                                                                                {entry.orderItems.poQuantity}
                                                                                            </div>
                                                                                            <div className="date-received" >
                                                                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                                                    <KeyboardDatePicker
                                                                                                        autoOk
                                                                                                        name="receivedDate"
                                                                                                        variant="inline"
                                                                                                        inputVariant="outlined"
                                                                                                        label="Date Received"
                                                                                                        format="yyyy/MM/dd"
                                                                                                        value={this.formatDate(this.state.dates[index])}
                                                                                                        InputAdornmentProps={{ position: "start" }}
                                                                                                        style={{width: "170px"}}
                                                                                                        disabled={false}
                                                                                                        onChange={date => {
                                                                                                            this.handleDateChange(date, index)
                                                                                                        }}
                                                                                                    />
                                                                                                </MuiPickersUtilsProvider>
                                                                                            </div>
                                                                                            <div style={{ textAlign: "center", marginLeft: "120px"}}>{entry.receivedByUsername ?? ''}</div>
                                                                                            <div style={{width: "650px", marginLeft: "80px", display: "flex"}}>
                                                                                                <div className="delete-icon">
                                                                                                    {this.state.entryToDelete !== entry ?
                                                                                                        <IconButton onClick={() => this.handleDeleteOpen(entry)}>
                                                                                                            <DeleteIcon />
                                                                                                        </IconButton> : <div  style={{display: "flex", width: "200px"}}>
                                                                                                            <Button onClick={this.toggleConfirmDelete} style={{marginRight: "10px"}}>
                                                                                                                Cancel
                                                                                                            </Button>
                                                                                                            <Button onClick={this.handleDeleteConfirm} variant="contained" startIcon={<DeleteIcon />}>
                                                                                                                Delete
                                                                                                            </Button>
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        :
                                                                                        null

                                                                                    }


                                                                                </>
                                                                            )
                                                                        }
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell
                                                                className={`${index === this.state.toEditPurchaseOrder ? this.state.toEditPurchaseOrder.orderItems.length - 1 ? 'no-border' : ' ' : -1} no-border center table-right-border`}>
                                                                {this.state.orderItems[index] && this.state.localQtyReceived[index] && (this.isZero(parseInt(this.state.orderItems[index].quantityOrdered) - parseInt(this.state.orderItems[index].quantityReceived) - (this.state.localQtyReceived[index] !== "" && this.state.localQtyReceived[index].match("^[A-Za-z0-9]+$") ? parseInt(this.state.localQtyReceived[index]) : 0)))}

                                                            </TableCell>

                                                        </TableRow>
                                                    );
                                                })
                                                }

                                            </TableBody>
                                        </Table>

                                        <Divider style={{marginTop: "1%", marginBottom: "1%", marginLeft: "1%", width: "98%"}} />

                                        <div className="notesField">
                                            <TextField
                                                variant="outlined"
                                                name="receiverNotes"
                                                value={this.state.receiverNotes}
                                                onChange={(event) => {
                                                    this.handleReceiverNotesChange(event)
                                                }}
                                                label="Receiver Notes"
                                                style={{ width: "inherit" }}
                                            />
                                        </div>

                                        <Divider style={{marginTop: "1%", marginBottom: "1%", marginLeft: "1%", width: "98%"}} />

                                        <Typography variant='h5' style={{marginLeft: "10px"}}>
                                            Packing Slips
                                        </Typography>
                                        <div className='title-row2'>
                                            <Tooltip
                                                className='infoTooltipIcon'
                                                style={{marginLeft: "7px"}}
                                                enterTouchDelay={0}
                                                leaveTouchDelay={30000}
                                                enterDelay={400}
                                                // leaveDelay={1000000} // for testing purposes
                                                title={
                                                    <React.Fragment>
                                                        {PACKING_SLIP_INFO}
                                                    </React.Fragment>
                                                }
                                            >
                                                <IconButton aria-label='info'>
                                                    <InfoOutlinedIcon variant='outlined' fontSize='small' />
                                                </IconButton>
                                            </Tooltip>
                                            {
                                                this.state.files.length > 0 ?
                                                    this.state.files[0]?.map((file, index) => {
                                                        let label = index + 1;
                                                        if (Object.keys(file).length > 0 && Object.keys(file).includes('displayName') && (file.displayName.includes('.pdf') || file.displayName.includes('.PDF'))) {
                                                            return (
                                                                <div className="pdf-icon">
                                                                    <div style={{position: "relative"}}>
                                                                        <IconButton
                                                                            style={{position: "relative"}}
                                                                            aria-label='previous'
                                                                            onClick={() => this.handlePackingSlipPreview(file, index)}
                                                                        >
                                                                            <DescriptionIcon variant='outlined' fontSize='large' />
                                                                        </IconButton>
                                                                        <div style={{position: "absolute", top: "40px", left: "30px", fontSize: "15px", color: "black"}}>
                                                                            {"PS-" + label}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                        else {
                                                            return null
                                                        }
                                                    })
                                                    :
                                                    null}
                                        </div>
                                        <div className="pdf-icon">
                                            <FileUpload
                                                handleFileAdd={(file) => this.handleGeneralFileAdd(file, 0)}
                                                handleFileDelete={(file, fileIndex) => this.handleGeneralFileDelete(file, fileIndex, 0)}
                                                files={this.state.newFiles[0] ? this.state.newFiles[0] : []}
                                                fetchFilePreview={(file) => this.props.previewPackingSlipFile({ path: file.path, poNumber: this.state.toEditPurchaseOrder.poNumber, index: 0})}
                                                singleFileUpload={true}
                                                isPackingSlip={true}
                                            />
                                        </div>

                                        <Divider style={{marginTop: "1%", marginBottom: "1%", marginLeft: "1%", width: "98%"}} />

                                        <div className="bottom-wrapper">
                                            <Button
                                                onClick={() => this.handleUpdate(this.state)}
                                                color="primary"
                                                variant="contained"
                                                className="submit-button"
                                                disabled={!(this.checkAllNumberBound() && (this.checkAllNumberChanged() || this.state.dateChanged || this.state.receiverNotesChanged || this.state.filesChanged))}
                                            >
                                                {SUBMIT}
                                            </Button>

                                        </div>

                                    </TableCell>
                                </TableRow>

                            </Table>

                        </TableContainer>

                    </div>}

            </>
        )
    }

}

export default PurchaseOrderDetails;