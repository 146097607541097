import React from "react";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Calender.css'

//styling
import "./TransactionsSettings.css"
// constants
import {
    BACK,
    TRANSACTION_INVOICE_PAGE_NAME,
    TRANSACTION_PAGES,
} from "./constants";

// custom components
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import PaymentPopperTransaction from "./PaymentPopperTransaction";
import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";
import withShipment from "../../../withShipment";
import ErrorFeedbackBar from "../../global/ErrorFeedbackBar";
import moment from "moment";
import {Checkbox} from "@material-ui/core";


class TransactionsInvoiceDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedCarrierInvoiceIds: new Set(),
            selectedSupplierInvoiceIds: new Set(),
            selectAll: false,
        }

    }

    handleSelectAllChange = (e) => {
        const { clickedDateEvents } = this.props

        const allCarrierInvoiceIds = []
        const allSupplierInvoiceIds = []

        clickedDateEvents.forEach(event => {
            if (event.data.type === 'Carrier') {
                allCarrierInvoiceIds.push(event.data.carrierInvoiceId)
            } else if (event.data.type === 'Supplier') {
                allSupplierInvoiceIds.push(event.data.supplierInvoiceId)
            }
        });

        if (e.target.checked) {
            this.setState({
                selectedCarrierInvoiceIds: new Set(allCarrierInvoiceIds),
                selectedSupplierInvoiceIds: new Set(allSupplierInvoiceIds),
                selectAll: true,
            })
        } else {
            this.setState({
                selectedCarrierInvoiceIds: new Set(),
                selectedSupplierInvoiceIds: new Set(),
                selectAll: false,
            })
        }
    }

    formatDollar = (amount) => {
        return '$' + String(amount).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
    }



    handleCheckboxChange = (event, invoiceId,invoiceType) => {
        const { checked } = event.target;
        const { selectedCarrierInvoiceIds, selectedSupplierInvoiceIds } = this.state;

        const newSelectedCarrierInvoiceIds = new Set(selectedCarrierInvoiceIds);
        const newSelectedSupplierInvoiceIds = new Set(selectedSupplierInvoiceIds);

        if (invoiceType === 'carrier') {
            if (checked) {
                newSelectedCarrierInvoiceIds.add(invoiceId);
            } else {
                newSelectedCarrierInvoiceIds.delete(invoiceId);
            }
        } else if (invoiceType === 'supplier') {
            if (checked) {
                newSelectedSupplierInvoiceIds.add(invoiceId);
            } else {
                newSelectedSupplierInvoiceIds.delete(invoiceId);
            }
        }



        this.setState({
            selectedCarrierInvoiceIds: newSelectedCarrierInvoiceIds,
            selectedSupplierInvoiceIds: newSelectedSupplierInvoiceIds
        });
    };


    render() {

        const {clickedDateEvents} = this.props;
        const {selectedCarrierInvoiceIds, selectedSupplierInvoiceIds, selectAll} = this.state;

        const carrierInvoiceIdsArray = Array.from(selectedCarrierInvoiceIds);
        const supplierInvoiceIdsArray = Array.from(selectedSupplierInvoiceIds);
        return (
            <>
                <ErrorFeedbackBar />

                {carrierInvoiceIdsArray.length > 0 || supplierInvoiceIdsArray.length > 0 ? (
                    <TopToolbar
                        pageName={TRANSACTION_INVOICE_PAGE_NAME}
                        menuItems={[
                            {
                                title: BACK,
                                icon: <KeyboardBackspaceIcon />,
                                onClick: () => { this.props.setCurrentPage(TRANSACTION_PAGES.CALENDER, {}); }
                            },
                            {
                                icon: <CreditCardIcon/>,
                                disabled: this.props.bankAccounts.length < 1,
                                title: "Add Payment Method",
                                popper: <PaymentPopperTransaction
                                    bankAccounts={this.props.bankAccounts}
                                    selectedSupplierInvoiceIds={supplierInvoiceIdsArray}
                                    selectedCarrierInvoiceIds={carrierInvoiceIdsArray}
                                />
                            }
                        ]}
                    />
                ) : (
                    <TopToolbar
                        pageName={TRANSACTION_INVOICE_PAGE_NAME}
                        menuItems={[
                            {
                                title: BACK,
                                icon: <KeyboardBackspaceIcon />,
                                onClick: () => { this.props.setCurrentPage(TRANSACTION_PAGES.CALENDER, {}); }
                            }
                        ]}
                    />
                )}


                  <h4 style={{margin:'24px'}}>Upcoming Payment Due on {moment.utc(clickedDateEvents[0]?.data?.dueDate).format('MMMM D, YYYY')}</h4>
                <div className="event-table-container">

                   <div className="event-table">
                        <table>
                            <thead>
                            <tr>
                                <th style={{paddingRight: '48px', paddingLeft: '24px'}}>
                                    <Checkbox
                                        type="checkbox"
                                        checked={selectAll}
                                        onChange={this.handleSelectAllChange}
                                     // style={{ width: '16px', height: '16px', accentColor: '#acd685',color: 'white' }}
                                    />
                                </th>
                                <th>Due Date</th>
                                <th>Name</th>
                                <th>Invoice Number</th>
                                <th>PO Number</th>
                                <th>Amount Due</th>
                                <th>Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            {clickedDateEvents.map((event, index) => (
                                <tr key={index}>
                                    <td style={{paddingRight: '48px', paddingLeft: '24px'}}>
                                        <Checkbox 
                                            type="checkbox"
                                            checked={selectedSupplierInvoiceIds.has(event.data.supplierInvoiceId) || selectedCarrierInvoiceIds.has(event.data.carrierInvoiceId)}
                                            onChange={(e) => this.handleCheckboxChange(e, event.data.supplierInvoiceId || event.data.carrierInvoiceId, event.data.type.toLowerCase())}
                                        />
                                    </td>

                                    <td>{moment.utc(event.data.dueDate.toUTCString()).format('YYYY-MM-DD')}</td>
                                    <td>{event.data.carrierName? event.data.carrierName: event.data.supplierName}</td>
                                    <td>{event.data.invoiceNumber}</td>
                                    <td>{event.data.poNumber}</td>
                                    <td style={{textAlign: 'right', position: 'relative'}}>
                                        <span style={{
                                            position: 'absolute',
                                            left: '0',
                                            paddingLeft: '8px',
                                            paddingRight: '16px'
                                        }}>$</span>
                                        <span
                                            style={{paddingRight: '24px'}}> {this.formatDollar(event.data.total == null ? event.data.invoiceTotal : event.data.total)}</span>
                                    </td>
                                 <td style={{color: event.data.status === "Needs Review" ? "red" : "inherit"}}>
                                     {event.data.status}
                                 </td>

                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>


            </>
        );
    }
}

const mapStateToProps = (state) => ({
    clickedDateEvents: state.calendar.clickedDateEvents,
});


export default withShipment({
    mapStateToProps,
}, TransactionsInvoiceDetail);

