import React, {useState, useEffect} from 'react';
import styles from "./CreateCarrierDialog.module.css";
import FormControl from "@material-ui/core/FormControl";
import {FormHelperText, MenuItem, Select} from "@material-ui/core";
import {BUY_SHIPPING_SETUP, CARRIER_SETUP, SETUP_TYPES, MARKETPLACE} from "./constants";

function CarrierSetup(props) {
    return (
        <div className={styles.rowSelects}>
            <div>
                <div className={styles.rowSpec}>
                    <FormControl error={props.errors.carrierSetup} required className={styles.formInput}>
                        <FormHelperText>{CARRIER_SETUP}</FormHelperText>
                        <Select
                            variant="outlined"
                            name="carrierSetup"
                            onChange={props.handleChange}
                            value={props.parentState.carrierSetup}
                            className={styles.formInput}
                            disabled={props.isEdit}
                            error={props.parentState.error && !props.parentState.carrierSetup}
                        >
                            {SETUP_TYPES.map((type, index) => (
                                <MenuItem key={index} value={type}>{type}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {props.parentState.carrierSetup === BUY_SHIPPING_SETUP &&
                        <FormControl error={props.errors.marketplace} required className={styles.formInput}>
                            <FormHelperText>{MARKETPLACE}</FormHelperText>
                            <Select
                                variant="outlined"
                                name="marketplace"
                                onChange={props.handleChange}
                                value={props.parentState.marketplace}
                                className={styles.formInput}
                                disabled={props.isEdit}
                                error={props.parentState.error && !props.parentState.marketplace}
                            >
                                {props.marketplaces.map((marketplace, index) => (
                                    <MenuItem key={index} value={marketplace.marketplaceId}>
                                        {marketplace.marketplaceName} ({marketplace.country ? marketplace.country : marketplace.customUrl})
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    }
                </div>
            </div>
        </div>
    )
}

export default CarrierSetup