import {STORE_FULL_TRUCKLOADS_BROKER, STORE_FULL_TRUCKLOADS_BROKERS} from "../../constants/fullTruckloadBrokers";


const initialState = {
    fullTruckloadBrokers: [],
}

export default (state = initialState, action) => {
    switch (action.type) {
        case STORE_FULL_TRUCKLOADS_BROKERS:
            return {
                ...state,
                fullTruckloadBrokers: action.fullTruckloadBrokers
            }
        case STORE_FULL_TRUCKLOADS_BROKER:
            const brokerIndex = state.fullTruckloadBrokers.findIndex(
                broker => broker.brokerId === action.fullTruckloadBroker.brokerId
            );
            if (brokerIndex >= 0) {
                // The brokerId exists, so replace the broker at that index
                return {
                    ...state,
                    fullTruckloadBrokers: [
                        ...state.fullTruckloadBrokers.slice(0, brokerIndex),
                        action.fullTruckloadBroker, // This is the new broker data
                        ...state.fullTruckloadBrokers.slice(brokerIndex + 1)
                    ]
                };
            } else {
                // The brokerId does not exist, so add the new broker to the end of the array
                return {
                    ...state,
                    fullTruckloadBrokers: [...state.fullTruckloadBrokers, action.fullTruckloadBroker]
                };
            }
        default:
            return state;
    }
}