import React from 'react';

// material components
import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    DialogActions,
    Button
} from "@material-ui/core";

// constants
import {
    DELETE_TITLE,
    DELETE_DESCRIPTION,
    CANCEL,
    DELETE,
} from "./constants";
import PropTypes from "prop-types";
import CreateCarrierDialog from "./CreateCarrierDialog";

function DeleteDialog(props) {
    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>{DELETE_TITLE}</DialogTitle>
            <DialogContent>
                <DialogContentText>{DELETE_DESCRIPTION}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={props.handleClose}
                    color="primary"
                    variant="outlined"
                    autoFocus
                >
                    {CANCEL}
                </Button>
                <Button
                    onClick={props.handleDelete}

                    variant="contained"
                >
                    {DELETE}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

DeleteDialog.propTypes = {
    open: PropTypes.bool,
}

DeleteDialog.defaultProps = {
    open: false,
}

export default DeleteDialog;