import React from 'react';

// material components
import {
    TextField,
    Typography,
    List,
    ListItem,
    ListItemText,
    Button,
    ListItemSecondaryAction, capitalize,
    TableBody, TableHead, IconButton, Tooltip, Switch, FormGroup,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@mui/icons-material/Clear';

import {
    Alert
} from "@material-ui/lab";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";

import {
    ExpandLess as ExpandLessIcon,
    ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";

// constants
import {
    LABELS_PRINTED_TITLE,
    FEDEX,
    CANPAR,
    FILTER_BY_CARRIER,
    SHIPMENTS_PACKED_TITLE,
    LABELS_SCANNED_TITLE,
    SCAN_LABEL_ACTION_TITLE,
    SHIPMENTS_FULLFILLED_TITLE,
    SCAN_SHIPMENTS_TITLE,
    RESOLVE_ERROR_TEXT,
    PENDING_TITLE,
    PENDING_TOOLTIP_INFO,
    SCAN_CARRIER_BARCODE_TITLE,
    TOTAL_TITLE,
    SCAN_PRODUCT_TITLE,
    SCAN_SECOND_PRODUCT_TITLE,
    DASHBOARD_TOOLBAR_TITLE,
    DASHBOARD_SUMMARY_TITLE,
    CONFIRM_CANCEL_BUTTON_TEXT,
    REASON_FOR_CANCEL,
    MANUAL_VOID_STEPS,
    COMMENT_FOR_CANCEL,
    NEXT_CANCEL_BUTTON_TEXT, MANUAL_VOID_REASONS, TODAY_PENDING_TITLE, SHIPMENTS_BREAKDOWN, SEARCH,
} from "./constants";

// redux
import withShipment from "../../../withShipment";
import {
    userInfoSelector
} from "../../../redux/selectors/auth";
import {
    allBarcodesSelector,
    dashboardSelector,
    packedBarcodesSelector,
    packedResponseSelector,
    pendingPackedBarcodesSelector,
    scannedBarcodesSelector,
    scanResponseSelector,
    refreshingSelector,
    dashboardRefreshSelector
} from "../../../redux/selectors/dashboard";
import {
    getDashboardInfo,
    scanScannedInput,
    scanPackedInput,
    clearScanResponse,
    clearPackedResponse,
    manualPack,
    refreshDashboard, manualVoid,
} from "../../../redux/actions/dashboard";
import {
    unshippedSelector,
    shippedSelector,
} from "../../../redux/selectors/orders";
import { updateUserInfo } from "../../../redux/actions/auth";
import {availableLocationsSelector, carriersSelector } from "../../../redux/selectors/settings";
import { listCarriers, listAvailableLocations } from "../../../redux/actions/settings";

// styling
import styles from './DashboardFinal.module.css';

import DashboardToolbar from "./DashboardToolbar";
import CircularProgressWithLabel from "../CircularProgressWithLabel";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PropTypes from "prop-types";
import {DAYS_OF_WEEK, ROLE_ADMIN} from "../../../components/global/constants";
import {
    CONTINUE_ON_MATCH,
    EXIT_ON_MATCH, INFO_ON_CONTINUE_OPTION,
    INFO_ON_DEFAULT_BEHAVIOUR,
    INFO_ON_EXIT_OPTION
} from "../../../components/automationrules/constants";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { statusSelector } from '../../../redux/selectors/global';
import { ERROR } from '../../../components/shipments/constants';
import FeedbackBar from '../../../components/feedbackBar/FeedbackBar';
import ErrorFeedbackBar from '../../../components/global/ErrorFeedbackBar';
// import FeedbackBar from "../../feedbackBar/FeedbackBar";
import CustomToolbar from "../../../components/global/CustomToolbar";
import { BACK_TO_MENU } from "../../../components/returns/constants";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import RefreshIcon from "@material-ui/icons/Refresh";
import {Assignment, HourglassEmptyTwoTone, SwapHoriz} from "@material-ui/icons";
import DashboardSummary from "../DashboardSummary";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import LocationSelectBox from "../../../components/global/subcomponents/locationSelectBox/LocationSelectBox";
import {Collapse} from "@mui/material";
import CarrierSummaryTable from "../CarrierSummaryTable";
import SearchField from "../../../components/global/Search/SearchField";

class DashboardFinal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            rawScanInput: '',
            rawPackedInput: '',
            shortCodeInput: '',
            fedexOrders: [],
            canparOrders: [],
            selectedDate: new Date(),
            selectedLocation: {},
            selectedLocationId: "",
            isMultipleBarcodes: false,
            barcodes: [],
            remainingCount: 0,
            barcodesCount: 0,
            showSummaryPage: false,
            showPrintedLabelRawScan: false,
            manualVoidTrackingNumber: "",
            manualVoidStep: MANUAL_VOID_STEPS.NOT_VOIDING,
            manualVoidComment: "",
            manualVoidReason: "",
            pendingOpen: false,
            isPrimePending: 0,
            isPrimeTotal: 0,
            nonPrimePending: 0,
            nonPrimeTotal: 0,
            searchText:""
        }
    }

    componentDidMount() {
        this.props.listAvailableLocations()
        this.updateCountState();
        this.props.listCarriers({
            id: this.props.userInfo.company
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.shipped.length !== this.props.shipped.length || prevProps.dashboard !== this.props.dashboard) {
            this.updateCountState()
        }

        if (prevProps.availableLocations !== this.props.availableLocations) {
            let findLocation = this.props.availableLocations.find((location) => location.shipAddressId === this.props.userInfo.selectedLocationId)
            let defaultLocation = findLocation ? findLocation : {}
            let selectedLocationId = defaultLocation?.shipAddressId
            this.setState({
                selectedLocation: defaultLocation,
                selectedLocationId: selectedLocationId
            })
            this.props.getDashboardInfo(this.props.userInfo.company, this.state.selectedDate, selectedLocationId)
        }

        if (this.props.dashboardRefresh) {
            this.props.refreshDashboard(false)
            this.props.getDashboardInfo(this.props.userInfo.company, this.state.selectedDate, this.state.selectedLocationId)
        }
        if (prevProps !== this.props) {
            this.getCategorizedShipmentValue()
        }
    }

    refreshComponent = () => {
        this.props.getDashboardInfo(this.props.userInfo.company, this.state.selectedDate, this.state.selectedLocationId)
    }

    filterShipped = (property, condition) => {
        return this.props.shipped.filter(order => order[property] === condition)
    }

    updateCountState = () => {
        this.setState({
            fedexOrders: [...this.filterShipped(FILTER_BY_CARRIER, FEDEX)],
            canparOrders: [...this.filterShipped(FILTER_BY_CARRIER, CANPAR)],
        })
    }

    clearInput = (scan) => {
        if (scan) {
            this.setState({ rawScanInput: '' })
        } else {
            this.setState({
                rawPackedInput: '',
                shortCodeInput: '',
                isMultipleBarcodes: false,
                barcodes: [],
                barcodesCount: 0
            })
            document.getElementById("packed").focus();
        }
    }

    markForManualVoid = (trackingNumber) => {
        this.setState({
            manualVoidTrackingNumber: trackingNumber.trackingNumber,
            manualVoidStep: MANUAL_VOID_STEPS.REASON,
            manualVoidComment: "",
            manualVoidReason: ""
        })
    }

    manualVoid = async (trackingNumber) => {
        if (!this.state.manualVoidComment || !this.state.manualVoidReason) {
            return
        }
        await this.props.manualVoid(trackingNumber, this.state.manualVoidComment, this.state.manualVoidReason)
    }

    manualPack = async (trackingNumber) => {
        await this.props.manualPack(trackingNumber);
    }
    clearBarcodeInput = (remainingCount) => {
        this.setState({
            shortCodeInput: '',
            isMultipleBarcodes: true,
            remainingCount: parseInt(remainingCount)
        })
        document.getElementById("shortCode").focus();
    }

    handleScannedInput = (e) => {
        if (!e.target.value.includes('\n')) {
            this.setState({
                rawScanInput: e.target.value
            })
        } else {
            const data = {
                companyId: this.props.userInfo.company,
                scan: this.state.rawScanInput,
                shipAddressId: this.state.selectedLocationId,
                requestedDate: this.state.selectedDate,
                clearInput: () => this.clearInput(true)
            }
            this.props.scanScannedInput(data)
        }
    }

    handlePackedScanInput = (e) => {
        if (!e.target.value.includes('\n')) {
            this.setState({
                rawPackedInput: e.target.value
            })
        } else {
            const data = {
                companyId: this.props.userInfo.company,
                rawScan: this.state.rawPackedInput,
                shortCode: this.state.shortCodeInput,
                userId: this.props.userInfo.userId,
                shipAddressId: this.state.selectedLocationId,
                requestedDate: this.state.selectedDate,
                clearInput: () => this.clearInput(false),
                clearBarcodeInput: this.clearBarcodeInput,
            }

            if (this.state.rawPackedInput.length > 0) {
                this.props.scanPackedInput(data)
            }

            if (!this.props.packedResponse.fail) {
                document.getElementById("shortCode").focus();
            }
        }
    }

    handleShortCodeInput = (e) => {
        if (!e.target.value.includes('\n')) {
            this.setState({
                shortCodeInput: e.target.value,
            })
        } else {
            let barcodes = this.state.barcodes
            barcodes.push(this.state.shortCodeInput)

            if (this.state.isMultipleBarcodes) {
                this.setState({
                    barcodes: barcodes,
                    barcodesCount: barcodes.length
                }, this.packBarcode)
            } else {
                this.packBarcode()
            }
        }
    }

    packBarcode = () => {
        const data = {
            companyId: this.props.userInfo.company,
            rawScan: this.state.rawPackedInput,
            shortCode: this.state.shortCodeInput,
            userId: this.props.userInfo.userId,
            shipAddressId: this.state.selectedLocationId,
            requestedDate: this.state.selectedDate,
            clearInput: () => this.clearInput(false),
            clearBarcodeInput: this.clearBarcodeInput,
            barcodes: this.state.barcodes,
            barcodesCount: this.state.barcodes.length
        }

        if (this.state.rawPackedInput.length > 0) {
            this.props.scanPackedInput(data)
            if (!this.state.isMultipleBarcodes) {
                document.getElementById("packed").focus();
            }
        }
    }

    resolveError = (scan) => {
        this.clearInput(scan);
        this.props.clearPackedResponse();
        this.props.clearScanResponse();
        if (scan) {
            document.getElementById("scan").focus();
        } else {
            document.getElementById("packed").focus();
        }
    }

    handleDateChange = (date) => {
        this.setState({
            selectedDate: date
        })
        if (date && !isNaN(date.getTime())) {
            //Only send request when the date is proper
            this.props.getDashboardInfo(this.props.userInfo.company, date, this.state.selectedLocationId)
        }
    }

    handleCollapse = () => {
        this.setState({
            pendingOpen: !this.state.pendingOpen
        })
    }

    formatTimestamp = (timestamp) => {
        return timestamp.split('T')[1].slice(0, -1)
    }

    userIsAnAdmin = () => {
        return this.props.userInfo?.authority === ROLE_ADMIN
    }

    displayManualVoidSection = (scan) => {
        return <React.Fragment>
            {this.state.manualVoidTrackingNumber !== scan.trackingNumber ? null :
                <ListItem className={styles.list} style={{backgroundColor: "#f1f1f1"}}>
                    <FormGroup style={{display: "flex", flexDirection: "row", width: "100%", height: "auto"}}
                               onBlur={e => this.setState({manualVoidTrackingNumber: "", manualVoidStep: MANUAL_VOID_STEPS.NOT_VOIDING, manualVoidComment: "", manualVoidReason: ""})}
                    >
                        <IconButton
                            style={{paddingLeft: "0px"}}
                            onClick={(e) => {
                                if (this.state.manualVoidStep === MANUAL_VOID_STEPS.COMMENT) {
                                    this.setState({
                                        manualVoidStep: MANUAL_VOID_STEPS.REASON
                                    })
                                } else if (this.state.manualVoidStep === MANUAL_VOID_STEPS.REASON) {
                                    this.setState({
                                        manualVoidStep: MANUAL_VOID_STEPS.NOT_VOIDING,
                                        manualVoidTrackingNumber: "",
                                        manualVoidComment: "",
                                        manualVoidReason: ""
                                    })
                                }
                            }}
                            onMouseDown={(e) => e.preventDefault()} // this prevents onBlur from triggering before server call
                        >
                            <KeyboardBackspaceIcon/>
                        </IconButton>
                        <div style={{margin: "0px", padding: "0px", flexGrow: "1"}}>
                            {this.state.manualVoidStep !== MANUAL_VOID_STEPS.REASON ? null :
                                <Autocomplete
                                    name="Reason"
                                    value={this.state.manualVoidReason}
                                    onChange={(e, value) => this.setState({manualVoidReason: value})}
                                    renderInput={ (params) =>
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label={REASON_FOR_CANCEL}
                                            InputLabelProps={{ shrink: true }}
                                            error={!this.state.manualVoidReason}
                                        />
                                    }
                                    options={MANUAL_VOID_REASONS}
                                    getOptionLabel={(entry) => entry}
                                />
                            }
                            {this.state.manualVoidStep !== MANUAL_VOID_STEPS.COMMENT ? null :
                                <TextField
                                    style={{width: "100%"}}
                                    name="Comment"
                                    variant="outlined"
                                    label={COMMENT_FOR_CANCEL}
                                    value={this.state.manualVoidComment}
                                    onChange={(e) => this.setState({manualVoidComment: e.target.value})}
                                    error={!this.state.manualVoidComment}
                                />
                            }
                        </div>
                        <div style={{display: "table-cell", margin: "0px", padding: "0px"}}>
                            {this.state.manualVoidStep !== MANUAL_VOID_STEPS.REASON ? null :
                                <Button
                                    style={{marginLeft: "10px", marginRight: "0px", height: "100%"}}
                                    variant="outlined"
                                    onClick={(e) => {
                                        if (this.state.manualVoidReason) {
                                            this.setState({manualVoidStep: MANUAL_VOID_STEPS.COMMENT})
                                        }
                                    }}
                                    onMouseDown={(e) => e.preventDefault()} // this prevents onBlur from triggering before server call
                                >
                                    {NEXT_CANCEL_BUTTON_TEXT}
                                </Button>
                            }
                            {this.state.manualVoidStep !== MANUAL_VOID_STEPS.COMMENT ? null :
                                <Button
                                    style={{marginLeft: "10px", marginRight: "0px", height: "100%"}}
                                    variant="outlined"
                                    onClick={(e) => {this.manualVoid(scan)}}
                                    onMouseDown={(e) => e.preventDefault()} // this prevents onBlur from triggering before server call
                                >
                                    {CONFIRM_CANCEL_BUTTON_TEXT}
                                </Button>
                            }
                        </div>
                    </FormGroup>
                </ListItem>
            }
        </React.Fragment>
    }

    getProgressValue = () => {
        let num = 0;
        let denom = 1;
        if (this.props.dashboard) {
            if (this.props.pendingPacked) {
                num = this.props.pendingPacked.length;
                if (this.props.packedBarcodes) {
                    num += this.props.packedBarcodes.length;
                }
            }
            let totalLabelsList = this.props.allBarcodes
            if (totalLabelsList && this.props.dashboard.unpackedBarcodes) {
                if (totalLabelsList.length + this.props.dashboard.unpackedBarcodes.length !== 0) {
                    denom = totalLabelsList.length + this.props.dashboard.unpackedBarcodes.length
                }
            }
        }
        return (num / denom) * 100;
    }

    getCategorizedShipmentValue = () => {
        let isPrimePending = 0
        let isPrimeTotal = 0
        let nonPrimePending = 0
        let nonPrimeTotal = 0
        if (this.props.dashboard) {
            if (this.props.pendingPacked) {
                isPrimePending = this.props.pendingPacked.filter(it => it.isPrime === true).length
                nonPrimePending = this.props.pendingPacked.filter(it => it.isPrime === false).length
                if (this.props.packedBarcodes) {
                    isPrimePending += this.props.packedBarcodes.filter(it => it.isPrime === true).length
                    nonPrimePending +=  this.props.packedBarcodes.filter(it => it.isPrime === false).length
                }
            }
            let totalLabelsList = this.props.allBarcodes
            if (totalLabelsList && this.props.dashboard.unpackedBarcodes) {
                if (totalLabelsList.length + this.props.dashboard.unpackedBarcodes.length !== 0) {
                    isPrimeTotal = totalLabelsList.filter(it => it.isPrime === true).length + this.props.dashboard.unpackedBarcodes.filter(it => it.isPrime === true).length
                    nonPrimeTotal = totalLabelsList.filter(it => it.isPrime === false).length + this.props.dashboard.unpackedBarcodes.filter(it => it.isPrime === false).length
                }
            }
        }
        this.setState({
            isPrimePending: isPrimePending,
            isPrimeTotal: isPrimeTotal,
            nonPrimePending: nonPrimePending,
            nonPrimeTotal: nonPrimeTotal
        })
    }

    toggleSummaryPage = () => {
        this.setState({
            showSummaryPage: !this.state.showSummaryPage
        })
    }

    handlePrintedLabelRawScanToggle = () => {
        this.setState({
            showPrintedLabelRawScan: !this.state.showPrintedLabelRawScan
        })
    }

    dateSelectRow = () => (
        this.state.showSummaryPage ?
        <div className={styles.dateselectrow} style={{justifyContent: "space-between"}}>
            <div style={{width: '24.5%'}}>
                <TextField
                    style={{width: "100%"}}
                    name="searchText"
                    variant="outlined"
                    label={SEARCH}
                    value={this.state.searchText}
                    onChange={(e) => this.setState({searchText: e.target.value})}
                />
            </div>
            <div style={{display: 'flex',width: '49%', justifyContent: "space-between"}}>
                <div className={styles.dateField} style={{width: '40%'}}>
                    <Typography variant="h4">
                        {DAYS_OF_WEEK[this.state.selectedDate?.getDay()]}
                    </Typography>
                </div>
                <div className={styles.dateLocationBlock}>
                    <div className={styles.dateField}>
                        <KeyboardDatePicker
                            className={styles.locationDateFields}
                            autoOk
                            name="toDate"
                            variant="inline"
                            inputVariant="outlined"
                            label="Date"
                            format="yyyy/MM/dd"
                            value={this.state.selectedDate}
                            InputAdornmentProps={{ position: "start" }}
                            onChange={date => this.handleDateChange(date)}
                            style={{}}
                        />
                    </div>

                    <LocationSelectBox
                        updateCallback={(value) => {
                            this.setState({
                                selectedLocation: value,
                                selectedLocationId: value["shipAddressId"]
                            })
                            this.props.getDashboardInfo(this.props.userInfo.company, this.state.selectedDate, value["shipAddressId"])
                        }
                        }
                    />
                </div>
            </div>
        </div>
            :
            <div className={styles.dateselectrow}>
                <div className={styles.dayOfWeek}>
                    <Typography variant="h4">
                        {DAYS_OF_WEEK[this.state.selectedDate?.getDay()]}
                    </Typography>
                </div>
                <div className={styles.dateLocationBlock}>
                    <div className={styles.dateField}>
                        <KeyboardDatePicker
                            className={styles.locationDateFields}
                            autoOk
                            name="toDate"
                            variant="inline"
                            inputVariant="outlined"
                            label="Date"
                            format="yyyy/MM/dd"
                            value={this.state.selectedDate}
                            InputAdornmentProps={{ position: "start" }}
                            onChange={date => this.handleDateChange(date)}
                            style={{}}
                        />
                    </div>

                    <LocationSelectBox
                        updateCallback={(value) => {
                            this.setState({
                                selectedLocation: value,
                                selectedLocationId: value["shipAddressId"]
                            })
                            this.props.getDashboardInfo(this.props.userInfo.company, this.state.selectedDate, value["shipAddressId"])
                        }
                        }
                    />

                </div>

            </div>

    )

    render() {
        let headerButtons;
        if (this.state.showSummaryPage) {
            headerButtons = [{ icon: <KeyboardBackspaceIcon />, handler: this.toggleSummaryPage, title: "Back" },
                {
                    icon: this.props.refreshing ? <HourglassEmptyTwoTone /> : <RefreshIcon />,
                    handler: this.refreshComponent,
                    title: this.props.refreshing ? "Refreshing..." : "Refresh",
                    disabled: this.props.refreshing
                }]
        } else {
            headerButtons = [{ icon: <Assignment />, handler: this.toggleSummaryPage, title: "Open Summary" },
                {
                    icon: this.props.refreshing ? <HourglassEmptyTwoTone /> : <RefreshIcon />,
                    handler: this.refreshComponent,
                    title: this.props.refreshing ? "Refreshing..." : "Refresh",
                    disabled: this.props.refreshing
                }]
        }
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <ErrorFeedbackBar />
                <CustomToolbar
                    title={this.state.showSummaryPage ? DASHBOARD_SUMMARY_TITLE : DASHBOARD_TOOLBAR_TITLE}
                    createTitle={'Dashboard'}
                    backTitle={BACK_TO_MENU}
                    onClick={() => {
                    }}
                    handleClose={this.toggleSummaryPage}
                    headerButtons={headerButtons}
                />
                {this.state.showSummaryPage ?
                    <DashboardSummary
                        scannedLabels={this.props.scannedBarcodes}
                        packedLabels={this.props.packedBarcodes}
                        pendingPackedLabels={this.props.pendingPacked}
                        unpackedLabels={this.props.dashboard.unpackedBarcodes}
                        printedLabels={this.props.dashboard.printedBarcodes}
                        dateSelectRow={this.dateSelectRow}
                        searchText={this.state.searchText}
                    />
                    :
                    <div>

                        {this.dateSelectRow()}

                        <div className={styles.dashboardMain}>


                            {/* Shipments Packed Summary */}
                            <div className={styles.container} style={{borderBottom: 'none'}}>
                                <Typography variant="h6" component="div" gutterBottom className={styles.header}>
                                    {SHIPMENTS_FULLFILLED_TITLE}
                                    <hr className={styles.underline} />
                                </Typography>
                                <div className={styles.center}>
                                    <div className={styles.loadingCircle}>
                                        <CircularProgressWithLabel
                                            value={this.getProgressValue()}
                                        />
                                    </div>
                                </div>
                                <Typography variant="h6" component="div" gutterBottom className={styles.header}>
                                    {SHIPMENTS_BREAKDOWN}
                                    <hr className={styles.underline} />
                                </Typography>
                                <Table>
                                    <TableBody>
                                        <TableRow hover>
                                            <TableCell>Prime</TableCell>
                                            <TableCell align={'right'}>{`${this.state.isPrimePending} / ${this.state.isPrimeTotal}`}</TableCell>
                                        </TableRow>
                                        <TableRow hover style={{ borderBottom: 'none' }}>
                                            <TableCell>Non Prime</TableCell>
                                            <TableCell align={'right'}>{`${this.state.nonPrimePending} / ${this.state.nonPrimeTotal}`}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </div>
                            <CarrierSummaryTable todayArr={this.props.allBarcodes}
                                                 pendingArr={this.props.dashboard.unpackedBarcodes}
                                                 carriers={this.props.carriers}
                                                 location={this.state.selectedLocation.name}
                                                 pendingOpen={this.state.pendingOpen}
                                                 handleCollapse={this.handleCollapse}
                                                 tableTitle={LABELS_PRINTED_TITLE}/>
                            <CarrierSummaryTable todayArr={this.props.scannedBarcodes}
                                                 pendingArr={this.props.dashboard.unpackedBarcodes}
                                                 carriers={this.props.carriers}
                                                 location={this.state.selectedLocation.name}
                                                 pendingOpen={this.state.pendingOpen}
                                                 handleCollapse={this.handleCollapse}
                                                 tableTitle={LABELS_SCANNED_TITLE}/>
                            <CarrierSummaryTable todayArr={this.props.packedBarcodes}
                                                 pendingArr={this.props.pendingPacked}
                                                 carriers={this.props.carriers}
                                                 location={this.state.selectedLocation.name}
                                                 pendingOpen={this.state.pendingOpen}
                                                 handleCollapse={this.handleCollapse}
                                                 tableTitle={SHIPMENTS_PACKED_TITLE}/>
                        </div>
                        {/* Scanning Printed labels */}
                        <div className={styles.dashboardMain}>
                            {/* Actions to Scan Printed Labels */}
                            <div className={styles.container}>
                                <Typography variant="h6" component="div" gutterBottom className={styles.header}>
                                    <div style={{display: "flex", justifyContent: "space-between"}}>
                                        {SCAN_LABEL_ACTION_TITLE}

                                        <div className="compactTableSwitch" style={{margin: 0, maxHeight: "20px"}}>
                                            <Tooltip title="Show Raw Scan">
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={this.state.showPrintedLabelRawScan}
                                                            onChange={this.handlePrintedLabelRawScanToggle}
                                                        />}
                                                />
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <hr className={styles.underline} />
                                </Typography>
                                <div className={styles.center}>
                                    <div className={styles.rowCol}>
                                        <TextField
                                            className={styles.scanInput}
                                            id="scan"
                                            rows={1}
                                            rowsMax={1}
                                            multiline
                                            name="rawScanInput"
                                            label={SCAN_CARRIER_BARCODE_TITLE}
                                            InputLabelProps={{ shrink: true }}
                                            disabled={/*this.state.selectedDate < new Date() ? true:  */(this.props.scanResponse ? this.props.scanResponse.fail : false)}
                                            value={this.state.rawScanInput}
                                            onChange={this.handleScannedInput}
                                            variant="outlined"
                                        />
                                        <List className={styles.scanInputs}>
                                            {this.props.scannedBarcodes ? this.props.scannedBarcodes.map((scan) => {
                                                return <React.Fragment>
                                                    <ListItem className={styles.list}>
                                                        <ListItemText primary={scan.trackingNumber}
                                                            secondary={
                                                                <div>
                                                                    <div>
                                                                        {this.state.showPrintedLabelRawScan ?
                                                                            scan.rawScan : (scan.product ? scan.product : '-')}
                                                                    </div>
                                                                    <div>
                                                                        {scan.orderId}
                                                                    </div>
                                                                </div>
                                                            } />
                                                        <ListItemSecondaryAction className='timestamp'>
                                                            <ListItemText primary={this.formatTimestamp(scan.timestamp)} />
                                                        </ListItemSecondaryAction>
                                                        <IconButton style={{marginRight: '0%', paddingLeft: '0%', paddingRight: '0%', color: 'lightsalmon'}} onClick={() => this.markForManualVoid(scan)}>
                                                            <ClearIcon fontSize="small"/>
                                                        </IconButton>
                                                        <IconButton style={{marginRight: '25%', paddingLeft: '0%', paddingRight: '0%', color: 'lightgreen'}} onClick={() => this.manualPack(scan)}>
                                                            <CheckIcon fontSize="small"/>
                                                        </IconButton>
                                                    </ListItem>
                                                    {this.displayManualVoidSection(scan)}
                                                </React.Fragment>
                                            }) : null}
                                            {this.props.dashboard.unpackedBarcodes ? this.props.dashboard.unpackedBarcodes.map(scan => {
                                                return <React.Fragment>
                                                    <ListItem className={styles.unpackedList} color='primary'>
                                                        <ListItemText primary={scan.trackingNumber}
                                                              secondary={
                                                                  <div>
                                                                      <div>
                                                                      {this.state.showPrintedLabelRawScan ?
                                                                          scan.rawScan : (scan.product ? scan.product : '-')}
                                                                      </div>
                                                                      <div>
                                                                          {scan.orderId}
                                                                      </div>
                                                                  </div>
                                                              } />
                                                        <ListItemSecondaryAction className='timestamp'>
                                                            <ListItemText primary={scan.timestamp&&scan.timestamp.split('T')[0]} />
                                                        </ListItemSecondaryAction>
                                                        <IconButton style={{marginRight: '0%', paddingLeft: '12px', paddingRight: '12px', color: 'lightsalmon'}} onClick={() => this.markForManualVoid(scan)}>
                                                            <ClearIcon fontSize="small"/>
                                                        </IconButton>

                                                        <IconButton style={{marginRight: '25%', paddingLeft: '12px', paddingRight: '12px', color: 'lightgreen'}} onClick={() => this.manualPack(scan)}>
                                                            <CheckIcon fontSize="small"/>
                                                        </IconButton>
                                                    </ListItem>
                                                    {this.displayManualVoidSection(scan)}
                                                </React.Fragment>
                                            }) : null}
                                        </List>
                                        {this.props.scanResponse.fail ?
                                            <div className={styles.rowScan}>
                                                <div className={styles.alert}>
                                                    <Alert severity="error">{this.props.scanResponse.message}</Alert>
                                                </div>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={() => this.resolveError(true)}
                                                >
                                                    {RESOLVE_ERROR_TEXT}
                                                </Button>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* Actions to Scan Packed Labels */}
                            <div className={styles.container}>
                                <Typography variant="h6" component="div" gutterBottom className={styles.header}>
                                    {SCAN_SHIPMENTS_TITLE}
                                    <hr className={styles.underline} />
                                </Typography>
                                <div className={styles.center}>
                                    <div className={styles.rowCol}>
                                        <div className={styles.rowColPacked}>
                                            <TextField
                                                className={styles.packedInput}
                                                disabled={(this.props.packedResponse && this.state.selectedDate < new Date()) ? this.props.packedResponse.fail : false}
                                                id="packed"
                                                rows={1}
                                                rowsMax={1}
                                                multiline
                                                name="rawPackedInput"
                                                label={SCAN_CARRIER_BARCODE_TITLE}
                                                InputLabelProps={{ shrink: true }}
                                                value={this.state.rawPackedInput}
                                                onChange={this.handlePackedScanInput}
                                                variant="outlined"
                                            />
                                            <TextField
                                                className={styles.packedInput}
                                                disabled={(this.props.packedResponse && this.state.selectedDate < new Date()) ? this.props.packedResponse.fail : false}
                                                id="shortCode"
                                                rowsMax={1}
                                                rows={1}
                                                multiline
                                                name="shortCodeInput"
                                                label={this.state.isMultipleBarcodes ? this.state.remainingCount + " Barcode(s) Remaining" : SCAN_PRODUCT_TITLE}
                                                InputLabelProps={{ shrink: true }}
                                                value={this.state.shortCodeInput}
                                                onChange={this.handleShortCodeInput}
                                                variant="outlined"
                                            />
                                        </div>
                                        <List className={styles.packedListInput}>
                                            {this.props.packedBarcodes ? this.props.packedBarcodes.map(scan => (
                                                <ListItem className={styles.list}>
                                                    <ListItemText primary={scan.trackingNumber}
                                                          secondary={
                                                              (scan.product ? scan.product : '-') + (scan.resolvedManually ? " -- [Missed Scan]" : "")
                                                          }
                                                    />
                                                    <ListItemSecondaryAction>
                                                        <ListItemText primary={this.formatTimestamp(scan.timestamp)} />
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            )) : null
                                            }
                                            {this.props.pendingPacked ? this.props.pendingPacked.map(scan => (
                                                <ListItem className={styles.unpackedList} color='primary'>
                                                    <ListItemText primary={scan.trackingNumber}
                                                        secondary={
                                                            (scan.product ? scan.product : '-') + (scan.resolvedManually ? " -- [Missed Scan]" : "")
                                                        }
                                                    />
                                                    <ListItemSecondaryAction>
                                                        <ListItemText primary={scan.timestamp.split('T')[0]}
                                                            secondary={this.formatTimestamp(scan.timestamp)} />
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            )) : null}
                                        </List>
                                        {this.props.packedResponse.fail ?
                                            <div className={styles.rowScan}>
                                                <div className={styles.alert}>
                                                    <Alert severity="error">{this.props.packedResponse.message}</Alert>
                                                </div>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={() => this.resolveError(false)}
                                                >
                                                    {RESOLVE_ERROR_TEXT}
                                                </Button>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </MuiPickersUtilsProvider>
        );
    }


}

const mapStateToProps = (state) => ({
    unshipped: unshippedSelector(state),
    shipped: shippedSelector(state),
    dashboard: dashboardSelector(state),
    userInfo: userInfoSelector(state),
    scanResponse: scanResponseSelector(state),
    packedResponse: packedResponseSelector(state),
    availableLocations: availableLocationsSelector(state),
    carriers: carriersSelector(state),
    scannedBarcodes: scannedBarcodesSelector(state),
    packedBarcodes: packedBarcodesSelector(state),
    pendingPacked: pendingPackedBarcodesSelector(state),
    allBarcodes: allBarcodesSelector(state),
    refreshing: refreshingSelector(state),
    dashboardRefresh: dashboardRefreshSelector(state),
})

const actionCreators =
{
    getDashboardInfo,
    scanScannedInput,
    listAvailableLocations,
    manualPack,
    manualVoid,
    clearScanResponse,
    scanPackedInput,
    clearPackedResponse,
    listCarriers,
    updateUserInfo,
    refreshDashboard,
}

DashboardFinal.propTypes = {
    dashboard: PropTypes.object,
    availableLocations: PropTypes.array,
    scannedBarcodes: PropTypes.array,
    packedBarcodes: PropTypes.array,
    pendingPacked: PropTypes.array,
    refreshing: PropTypes.bool,
    dashboardRefresh: PropTypes.bool,
}

DashboardFinal.defaultProps = {
    dashboard: {
        scannedBarcodes: [],
        packedBarcodes: [],
        unpackedBarcodes: [],
        availableLocations: [],
        printedBarcodes: []
    },
    scannedBarcodes: [],
    packedBarcodes: [],
    pendingPacked: [],
    refreshing: false
}

export default withShipment(
    {
        mapStateToProps,
        actionCreators
    }
    , DashboardFinal);