import React, { useEffect, useState } from 'react';

// custom components
import ShipmentForm from "./ShipmentForm";

// material components
import {
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,
    Button,
    FormControlLabel,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox, TableCell, IconButton
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

// constants
import {
    CREATE_ORDER_TITLE,
    CREATE_ORDER_SUBTITLE,
    CREATE_ORDER,
    CANCEL_CREATE_ORDER,
    MISSING_FIELDS
} from "./constants";
import {
    CARRIER_SERVICE_OPTIONS,
} from "../automationrules/ConditionFilters/constants";


// styling
import './CreateOrderDialog.css';
import { carriersSelector } from "../../redux/selectors/settings";
import { listCarriers } from "../../redux/actions/settings";
import withShipment from "../../withShipment";
import { deletePackage } from "../../redux/actions/orders";
import PropTypes from "prop-types";
import CompactTableSiwtch from "./CompactTableSwitch";
import Box from "@material-ui/core/Box";
import EditIcon from "@material-ui/icons/Edit";
import {CANCELLED, SHIPPED} from "../stages/constants";
import TableRow from "@material-ui/core/TableRow";



function CreateOrderDialog(props) {
    const blacklist = [
        'error',
        'errors',
        'metric',
        'accounts'
    ];

    const whitelist = [
        'errors',
        'error',
        'isResidential',
        'address2',
        'reference1',
        'reference2',
        'reference3',
        'email',
        'metric',
        'orderId',

    ];

    const shipmentRequired = [
        "service",
        "shippingAddressPhone",
        "shippingAddressName",
        "shippingAddressAddressLine1",
        "shippingAddressCity",
        "shippingAddressStateOrRegion",
        "shippingAddressPostalCode",
        "shippingAddressCountry",
        "signatureConfirmation",
    ]
    const fromAccountRequired = [
        "carrier",
        "fromAddressAccountNumber",
        "fromAddressAddressLine1",
        "fromAddressCity",
        "fromAddressCountry",
        "fromAddressName",
        "fromAddressPhone",
        "fromAddressPostalCode",
        "fromAddressState",
    ]

    const packageRequired = [
        "packaging",
        "weight",
        "length",
        "width",
        "height",
        "currency",
    ]

    const commodityField = [
        "numberOfPieces",
        "description",
        "countryOfManufacture",
        "harmonizedCode",
        "weight",
        "weightUnit",
        "quantity",
        "quantityUnit",
        "unitPrice",
        "customsValue",
        "tax",
        "currency",
    ]

    const [state, setState] = useState({
            fromAccount: {},
            accounts: [],
            metric: false,
            service: "",
            isResidential: false,
            tags: [],
            packages: [{
                packaging: '',
                weight: '',
                length: '',
                width: '',
                height: '',
                reference1: '',
                reference2: '',
                reference3: '',
                currency: '',
                declaredValue: ''
            }],
            commodities: [{
                shipmentId: props.shipmentId,
                numberOfPieces: "1",
                description: "",
                countryOfManufacture: "",
                harmonizedCode: "",
                weight: "0",
                weightUnit: "",
                quantity: "0",
                quantityUnit: "EA",
                unitPrice: "0.00",
                customsValue: "0.00",
                tax: "0.00",
                currency: "",
                packageId: 1,
            }],
            errors: {
                fromAddressAlias:false,
                fromAddressAddressLine1:false,
                fromAddressAddressLine2:false,
                fromAddressEmail:false,
                fromAddressName:false,
                fromAddressPhone:false,
                fromAddressPostalCode:false,
                fromAddressCity:false,
                fromAddressState:false,
                fromAddressCountry:false,
                fromAddressAccountNumber:false,
                fromAddressMeterNumber:false,
                carrier:false,
                packages: false,
            },
            error: false,
            notes: ''
        }
    );
    const [show, setShow] = useState(false)

    let baseState = state;

    useEffect(() => {

        let accounts = []
        props.carriers.map(carrier => {
            const alias = carrier.alias;
            const carrierName = carrier.name;
            const accountNumber = carrier.accountNumber;
            const meterNumber = carrier.meterNumber;
            const contractId= carrier.contractId;
            const defaultService = carrier.defaultService;
            const isBuyShipping = carrier.isBuyShipping;
            const marketplace = carrier?.marketplace?.marketplaceName
            carrier.addresses.map(address => {
                const account = {
                    fromAddressAlias: alias,
                    fromAddressAddressLine1: address.addressLine1,
                    fromAddressAddressLine2: address.addressLine2,
                    fromAddressEmail: address.email,
                    fromAddressName: address.name,
                    fromAddressCompanyName: address.companyName,
                    fromAddressContactName: address.contactName,
                    fromAddressPhone: address.phone,
                    fromAddressPostalCode: address.postalCode,
                    fromAddressCity: address.city,
                    fromAddressState: address.state,
                    fromAddressCountry: address.country,
                    fromAddressAccountNumber: accountNumber,
                    fromAddressMeterNumber: meterNumber,
                    carrier: carrierName,
                    defaultService: defaultService,
                    isDefault: address.isDefault,
                    isBuyShipping: isBuyShipping,
                    marketplace: marketplace ? marketplace + " - " : ""
                }
                accounts = [...accounts, account];
            })
        })
        let fromAccount = {
            fromAddressAddressLine1: "",
            fromAddressAddressLine2: "",
            fromAddressEmail: "",
            fromAddressName: "",
            fromAddressCompanyName: "",
            fromAddressContactName: "",
            fromAddressPhone: "",
            fromAddressPostalCode: "",
            fromAddressCity: "",
            fromAddressState: "",
            fromAddressCountry: "",
            fromAddressAccountNumber: "",
            fromAddressMeterNumber: "",
            carrier: "",
            isDefault: false,
            isBuyShipping: false,
            marketplace: ""
        }

        setState({
            ...state,
            accounts,
            fromAccount,
            service: ''
        })

    }, [props]);


    ////////////////////
    const handleDialogClose = () => {
        props.handleClose()
    }

    const handleCheck = (field) => {
        setState({ ...state, [field]: !state[field] })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        e.showLoadingOverlay = true  // Prevents spam clicking the button

        const keystate = [...Object.keys(state)]
        let newOrder = {}
        // adding desired data to send
        keystate.map(key => {
            if (!blacklist.includes(key)) {
                if (key === "fromAccount") {
                    const fromAccount = state[key]
                    const fromAccountKeys = [...Object.keys(fromAccount)]
                    fromAccountKeys.forEach(fromAccountKey => {
                        newOrder[fromAccountKey] = fromAccount[fromAccountKey]
                    })
                } else {
                    newOrder[key] = state[key]
                }
            }
        })
        newOrder.isReturnShipment = false
        newOrder.isCloneShipment = false
        newOrder.notes = state.notes

        const data = {
            newOrder: newOrder
        }

        props.createOrder(data, props.fromDate, props.toDate, props.shipByDate, props.locationId)
        setState({ ...baseState })
        handleDialogClose()
    }

    const handleCancel = (e) => {
        e.preventDefault();

        handleDialogClose()
    }

    const handleAccountSelect = (value, isCreateShip, refreshFromAddressName) => {
        let index
        if (value?.isBuyShipping) {
            index = state.accounts.findIndex(account => account.fromAddressAccountNumber === value?.fromAddressAccountNumber &&
                account.carrier === value?.carrier &&
                account.fromAddressAlias === value?.fromAddressAlias &&
                account.marketplace === value?.marketplace)
        } else {
            index = state.accounts.findIndex(account => account.fromAddressAccountNumber === value?.fromAddressAccountNumber &&
                account.fromAddressAlias === value?.fromAddressAlias &&
                account.carrier === value?.carrier)
        }

        setState({
            ...state,
            service: refreshFromAddressName ? "" : state.accounts[index]?.defaultService,
            fromAccount: state.accounts[index] ? state.accounts[index] : {
                fromAddressAddressLine1: "",
                fromAddressAddressLine2: "",
                fromAddressEmail: "",
                fromAddressName: refreshFromAddressName ? "" : state.fromAccount.fromAddressName,
                fromAddressCompanyName: "",
                fromAddressContactName: "",
                fromAddressPhone: "",
                fromAddressPostalCode: "",
                fromAddressCity: "",
                fromAddressState: "",
                fromAddressCountry: "",
                fromAddressAccountNumber: "",
                fromAddressMeterNumber: "",
                carrier: "",
                isDefault: false,
                isBuyShipping: false,
                marketplace: "",
            }
        })
    }

    const handleChange = (value, name, isPackage, index) => {
        if (isPackage) {
            let packages = [...state.packages];
            packages[index][name] = value;
            setState({
                ...state,
                packages: packages,
            })
        } else if (commodityField.includes(name)) {
            let commodities = [...state.commodities];
            commodities[index][name] = value;
            if (name == "quantity" || name == "unitPrice") {
                commodities[index].customsValue = Math.round((parseFloat(commodities[index].quantity) * parseFloat(commodities[index].unitPrice)) * 100) / 100
            }
            setState({
                ...state,
                commodities: commodities,
            })
        } else {
            setState({
                ...state,
                [name]: value
            })
        }
        readyForShipIndicator()
    }

    const handleAutocompleteChange = (value, name, isPackage, index) => {
        if (isPackage) {

            let packages = [...state.packages];
            packages[index][name] = value;
            setState({
                ...state,
                packages: packages,
            })
        } else if (name == "tags") {
            if (!state.tags.map(tag => { return tag.tagId }).includes(value)) {
                setState({
                    ...state,
                    tags: [...state.tags, props.tags.find(tag => tag.tagId == value)]
                })
            }
        } else if (name == "rtags") {
            setState({
                ...state,
                tags: state.tags.filter(tag => { return tag.tagId != value })
            })
        } else if (name == "fromAddressName") {
            const fromAccount = {
                fromAddressAddressLine1: "",
                fromAddressAddressLine2: "",
                fromAddressEmail: "",
                fromAddressName: value,
                fromAddressCompanyName: "",
                fromAddressContactName: "",
                fromAddressPhone: "",
                fromAddressPostalCode: "",
                fromAddressCity: "",
                fromAddressState: "",
                fromAddressCountry: "",
                fromAddressAccountNumber: "",
                fromAddressMeterNumber: "",
                carrier: "",
                isDefault: false,
            }
            setState({
                ...state,
                fromAccount,
            })
        } else if (commodityField.includes(name)) {
            let commodities = [...state.commodities];
            commodities[index][name] = value;
            setState({
                ...state,
                commodities: commodities,
            })
        } else {
            setState({
                ...state,
                [name]: value
            })
        }
    }

    const handleFromAddressChange = (value, name) => {
        const fromAccount = { ...state.fromAccount }
        fromAccount[name] = value
        setState({
            ...state,
            fromAccount,
        })
    }

    const handleCreateShipPackageDelete = (index) => {
        let packages = []
        for (let i = 0; i < state.packages.length; i++) {
            if (index !== i)
                packages.push(state.packages[i])
        }

        setState({
            ...state,
            packages: packages
        })
    }

    const handleCreateShipPackageAdd = () => {
        setState({
            ...state,
            packages: [...state.packages, {
                weight: '',
                length: '',
                width: '',
                height: '',
                reference1: '',
                reference2: '',
                reference3: '',
                declaredValue: '',
                currency: '',
                packaging: ''

            }]
        })
    }

    const handleCreateShipPackageCopy = (index) => {
        let packageToCopy = state.packages[index]
        setState({
            ...state,
            packages: [...state.packages, {
                weight: packageToCopy.weight,
                length: packageToCopy.length,
                width: packageToCopy.width,
                height: packageToCopy.height,
                reference1: packageToCopy.reference1,
                reference2: packageToCopy.reference2,
                reference3: packageToCopy.reference3,
                declaredValue: packageToCopy.declaredValue,
                currency: packageToCopy.currency,
                packaging: packageToCopy.packaging
            }]
        })
    }

    const checkDisable = () => {
        let isDisable = false
        fromAccountRequired.forEach(requiredField => {
            if (!(state.fromAccount && state.fromAccount[requiredField]?.length > 0)) {
                isDisable = true
            }
        })
        shipmentRequired.forEach(requiredField => {
            if (!(state[requiredField]?.length > 0)) {
                isDisable = true
            }
        })
        packageRequired.forEach(requiredField => {
            state.packages.forEach(p => {
                if (!(p[requiredField]?.length > 0)) {
                    isDisable = true
                }
            })
        })
        return isDisable
    }

    const addCommodity = (shipmentId, packageId) => {
        setState({
            ...state,
            commodities: [...state.commodities, {
                shipmentId: props.shipmentId,
                numberOfPieces: "1",
                description: "",
                countryOfManufacture: "MX",
                harmonizedCode: "",
                weight: "0",
                weightUnit: "LB",
                quantity: "0",
                quantityUnit: "EA",
                unitPrice: "0",
                tax: "0",
                customsValue: "0",
                currency: "CAD",
                packageId: packageId
            }]
        })
    }

    const readyForShipIndicator = () => {
        let isPackageNull
        for (let i =0;i<state.packages.length;++i) {
            if (state.packages[i].packaging == '' || state.packages[i].weight === '' || state.packages[i].length === ''||
                state.packages[i].width == '' || state.packages[i].height === '' || state.packages[i].currency === '' || state.packages[i].reference3 === '') {
                isPackageNull = true
            } else {
                isPackageNull = false
            }
        }
            if (state.fromAccount?.fromAddressAccountNumber !== "" && state.shippingAddressName !== undefined &&
                state.shippingAddressPhone !== undefined && state.shippingAddressAddressLine1 !== undefined &&
                state.shippingAddressCity !== undefined && state.shippingAddressStateOrRegion !== undefined &&
                state.shippingAddressPostalCode !== undefined && state.shippingAddressCountry !== undefined &&
                state.signatureConfirmation !== undefined && !isPackageNull) {
                return "#f9e076"
            } else {
            return "#f50057"
        }
    }

        return (
            <>
                <TableRow>
                    <TableCell style={{ padding: 5, backgroundColor: readyForShipIndicator(), left: 0 }}>
                    </TableCell>
                <div>
                    <Box margin={1}>
                        <ShipmentForm
                            orderInfo={state}
                            handleCheck={handleCheck}
                            handleSave={null}
                            handleFocus={null}
                            handleAccountSelect={handleAccountSelect}
                            handleFromAddressChange={handleFromAddressChange}
                            handleAutocompleteChange={handleAutocompleteChange}
                            handleChange={handleChange}
                            errors={state.errors}
                            handleCreateShipDeletePackage={handleCreateShipPackageDelete}
                            errorChecking={true}
                            required={true}
                            isCreateShip={true}
                            handleCreateShipPackageAdd={handleCreateShipPackageAdd}
                            handleCreateShipPackageCopy={handleCreateShipPackageCopy}
                            tags={props.tags}
                            locations={props.locations}
                            addCommodity={addCommodity}
                            boxes={props.boxes}
                        />
                    </Box>
                </div>
                <div className="createOrderDialogButtonBox">
                    <div className="createOrderDialogButton">
                    <Button onClick={handleCancel} color="secondary" variant="outlined">
                        {CANCEL_CREATE_ORDER}
                    </Button>
                </div>

                <div className="createOrderDialogButton">
                    <Button onClick={handleSubmit} color="primary" variant="contained" disabled={checkDisable()}>
                        {CREATE_ORDER}
                    </Button>
                </div>

            </div>
                </TableRow>
        </>
    );
}

CreateOrderDialog.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    createOrder: PropTypes.func,
    carriers: PropTypes.array
}

CreateOrderDialog.defaultProps = {
    open: false,
    handleClose: () => { },
    createOrder: () => { },
    carriers: []
}

export default CreateOrderDialog;