import React from "react";
import withShipment from "../../../withShipment";
import {DEFAULT_PDF_ZOOM} from "../../global/constants";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";
import {
    BACK,
    CANCEL,
    CONFIRM_DELETE,
    DELETE,
    TRANSACTION_PAGES,
    ZOOM_IN,
    ZOOM_OUT,
} from "./constants";
import PDFViewer from "../../global/PDFViewer";
import {attachmentSelector} from "../../../redux/selectors/accounting";
import DeleteIcon from "@material-ui/icons/Delete";
import ClearIcon from "@material-ui/icons/Clear";
import {deleteTransactionAttachment} from "../../../redux/actions/accounting";
import FileViewer from "../../global/subcomponents/fileViewer/FileViewer";


class Transactions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            scale: DEFAULT_PDF_ZOOM,
            deleting: false,
        }
    }

    setZoom(zoom) {
        let newScale = Math.min(Math.max(this.state.scale + zoom, 1), 2)
        this.setState({
            scale: newScale
        })
    }

    handleDelete = () => {
        const updatedTransaction = {...this.props.transaction}
        updatedTransaction.files = updatedTransaction.files.filter((file) => file !== this.props.file)

        this.props.deleteTransactionAttachment({
            file: {displayName: this.props.file},
            transaction: this.props.transaction
        }, () => {
            this.props.setCurrentPage(TRANSACTION_PAGES.UPDATE, updatedTransaction)
        })
    }

    twoStepDeleteIcon = () => (
        !this.state.deleting ? [
            {title: DELETE, icon: <DeleteIcon/>, onClick: () => this.setState({deleting: true})}
        ] : [
            {title: CANCEL, icon: <ClearIcon/>, onClick: () => this.setState({deleting: false})},
            {title: CONFIRM_DELETE, icon: <DeleteIcon/>, onClick: () => this.handleDelete()}
        ]
    )

    render() {
        return(
            <React.Fragment>
                <TopToolbar
                    pageName={""}
                    menuItems={[
                        {title: BACK, icon: <KeyboardBackspaceIcon/>, onClick: () => {this.props.setCurrentPage(TRANSACTION_PAGES.UPDATE, this.props.transaction)}},
                        {title: ZOOM_OUT, icon: <ZoomOutIcon/>, onClick: () => this.setZoom(-0.25)},
                        {title: (this.state.scale * 100) + '%', isNotIcon: true},
                        {title: ZOOM_IN, icon: <ZoomInIcon/>, onClick: () => this.setZoom(0.25)},
                        ...this.twoStepDeleteIcon()
                    ]}
                />
                <FileViewer
                    data={this.props.attachmentFile}
                    scale={this.state.scale}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    attachmentFile: attachmentSelector(state)
})

const actionCreators = {
    deleteTransactionAttachment,
}

export default withShipment({
    mapStateToProps,
    actionCreators,
}, Transactions);