import React from "react";
import withShipment from "../../../withShipment";

//components
import CustomToolbar from "../../global/CustomToolbar";
import CustomTableHeader from "../../global/Table/CustomTableHeader";
import SearchableTable from "../../global/Search/SearchableTable";
import GlobalTableHead from "../../global/Table/GlobalTableHead";
import FeedbackBar from "../../feedbackBar/FeedbackBar";

//material ui
import {
    TableBody,
    TablePagination,
    Table,
    TableContainer,
    TableRow,
    TableCell,
    IconButton,
    TextField,
    Tooltip,
    TableHead
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import EditAttributesIcon from '@material-ui/icons/EditAttributes';
import TableSortLabel from '@material-ui/core/TableSortLabel';


//constants
import { ASC, DESC } from "../../global/constants";
import {
    PRODUCT_CATEGORY_TOOLBAR_TITLE,
    MANAGE_PRODUCT_CATEGORY, PRODUCT_CATEGORY_TABLE_HEAD_CELLS, DEFAULT_ROWS_PER_PAGE, DEFAULT_SORT_BY_HEAD_CELL
} from "./constants";

import {SUCCESS, ERROR, BACK_TO_MENU_TITLE} from "../../shipments/constants";

//redux
import { saveProductCategoryChanges, removeProductCategory, getAllCategory, getUUID } from "../../../redux/actions/settings";
import {categorySelector} from "../../../redux/selectors/settings"


import './ManageProductCategory.css'
import ErrorFeedbackBar from "../../global/ErrorFeedbackBar";
import ManageProductCategoryTable from "./ManageProductCategoryTable";
import PropTypes from "prop-types";
import EditIcon from "@material-ui/icons/Edit";

class ManageProductCategory extends SearchableTable {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: DEFAULT_ROWS_PER_PAGE,
            order: ASC,
            orderBy: DEFAULT_SORT_BY_HEAD_CELL,
            isSearch: false, // true if search value is not empty, false otherwise
            searchValue: "", // the value in the search field (updates on change)
            searchRows: 0, // number of rows displayed while search criteria is met
            editChanges: [],
            editRows: [],
            rows: [],
            isEditProducts: false,
            changeRequest: false
        }
    }

    handleRequestSort = (event, property) => {
        const isAsc = this.state.orderBy === property && this.state.order === ASC;
        this.setState({
            order: isAsc ? DESC : ASC,
            orderBy: property,
        });
    };

    componentDidMount() {
        this.props.getAllCategory()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.changeRequest) {
            this.props.getAllCategory()
            this.setState({
                changeRequest: false
            })
        }
    }

    onSearchValueChange = (isSearch, searchValue) => {
        this.setState({
            isSearch: isSearch,
            searchValue: searchValue,
            page: 0
        });
    }

    checkDuplicatedCategory = (row) => {
        if (row.category?.length > 0) {
            let result = this.state.editRows.filter(entry => { return entry.category?.toLowerCase() === row.category?.toLowerCase() }).length > 1
            return result
        }
        return true
    }

    checkDuplicatedHSCode = (row) => {
        if (row.hsCode?.length > 0) {
            let result = this.state.editRows.filter(entry => { return entry.hsCode?.toLowerCase() === row.hsCode?.toLowerCase() }).length > 1
            return result
        }
        return true
    }

    handleEditingProductCategory = () => {
        let rows = this.props.categories
        let editRows = rows.map( obj => ({ ...obj, editedProperties: {}, isNew: false }))
        this.setState({
            editRows,
            isEditProducts: true,
        })
    }

    handleCancel = () => {
        this.setState({
            editChanges: [],
            editRows: [],
            isEditProducts: false,
        })
    }

    handleSuccessClose = () => {
        this.setState({
            feedbackOpen: false
        })
    }

    handleErrorClose = () => {
        this.setState({
            errorFeedbackOpen: false
        })
    }

    addCategory = async () => {
        let editChanges = this.state.editChanges
        let editRows = this.state.editRows
        const setUUID = (id) => {
            if (id && id != "") {
                const newCategory = {
                    id: id,
                    category: '',
                    hsCode: '',
                    editedProperties: {},
                    isNew: true
                }
                editChanges = [...editChanges, newCategory]
                editRows = [...editRows, newCategory]
                this.setState({
                    editChanges: editChanges,
                    editRows: editRows,
                })
            }
        }
        await this.props.getUUID("productCategory", setUUID)
    }

    handleCategoryNameChange = (e, row) => {
        let editChanges = this.state.editChanges
        let index = editChanges.findIndex(entry => entry.id === row.id);
        if (index !== -1) {
            editChanges[index].category = e.target.value
            editChanges[index].editedProperties.category = e.target.value
        } else {
            row.category = e.target.value
            row.editedProperties.category = e.target.value
            editChanges = [...editChanges, row]
        }
        this.setState({
            editChanges
        })
    }

    handleHSCodeChange = (e, row) => {
        let editChanges = this.state.editChanges
        let index = editChanges.findIndex(entry => entry.id === row.id);
        if (index !== -1) {
            editChanges[index].hsCode = e.target.value
            editChanges[index].editedProperties.hsCode = e.target.value
        } else {
            row.hsCode = e.target.value
            row.editedProperties.hsCode = e.target.value
            editChanges = [...editChanges, row]
        }
        this.setState({
            editChanges
        })
    }

    removeEntry = (id) => {
        let editRows = this.state.editRows
        let index = editRows.findIndex(x => x.id === id);
        let isNew = editRows[index].isNew
        if (index !== -1) {
            editRows.splice(index, 1)
            let editChanges = this.state.editChanges
            index = editChanges.findIndex(change => change.id === id)
            editChanges.splice(index, 1)
            // delete from rows only if it exists already
            if (!isNew) {
                this.props.removeProductCategory(id)
                this.setState({changeRequest: true})
            }
            this.setState({
                editChanges,
                editRows,
            })
        }

    }

    saveProductCategory = () => {
        if(this.state.editChanges.length > 0) {
            this.props.saveProductCategoryChanges(this.state.editChanges)
        }
        this.setState({
            isEditProducts: false,
            editChanges: [],
            editRows: [],
            changeRequest: true,
        })
    }

    render() {
        return (
            <>
                <ErrorFeedbackBar />
                <FeedbackBar
                    open={this.state.feedbackOpen}
                    handleClose={this.handleSuccessClose}
                    severity={SUCCESS}
                    message={this.state.feedbackMessage} />
                <FeedbackBar
                    open={this.state.errorFeedbackOpen}
                    handleClose={this.handleErrorClose}
                    severity={ERROR}
                    message={this.state.feedbackMessage} />
                <Paper className='paper'>
                    <CustomToolbar
                        title={PRODUCT_CATEGORY_TOOLBAR_TITLE}
                        customIcons={this.state.isEditProducts ?
                            <div className="contextualMenu">
                                <Tooltip title={BACK_TO_MENU_TITLE}>
                                    <IconButton aria-label={this.props.backTitle} onClick={this.handleCancel}>
                                        <KeyboardBackspaceIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            :
                            <div className="contextualMenu">
                                <Tooltip title={MANAGE_PRODUCT_CATEGORY}>
                                    <IconButton aria-label={this.props.createTitle} onClick={this.handleEditingProductCategory} >
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        }
                    />
                    {this.state.isEditProducts ?
                        <ManageProductCategoryTable
                            rows={this.state.editRows}
                            searchValue={this.state.searchValue}
                            onSearchValueChange={this.onSearchValueChange}
                            handleCategoryNameChange={this.handleCategoryNameChange}
                            handleHSCodeChange={this.handleHSCodeChange}
                            addCategory={this.addCategory}
                            removeEntry={this.removeEntry}
                            handleCancel={this.handleCancel}
                            handleSave={this.saveProductCategory}
                            checkHSCode={this.checkDuplicatedHSCode}
                            checkCategory={this.checkDuplicatedCategory}
                        />
                        :
                        <>
                            <CustomTableHeader
                                searchText={this.state.searchValue}
                                onSearchValueChange={(e) => this.onSearchValueChange(e.target.value.length > 0, e.target.value)}
                            />
                            <TableContainer>
                                <Table size='medium'>
                                    <GlobalTableHead
                                        isCreatePurchaseOrderTable={false}
                                        isShipmentTable={false}
                                        isOrderTable={false}
                                        isInvoiceTable={false}
                                        isPurchaseOrderTable={false}
                                        isProductCategoryTable={true}
                                        numSelected={0}
                                        headCells={PRODUCT_CATEGORY_TABLE_HEAD_CELLS}
                                        order={this.state.order}
                                        orderBy={this.state.orderBy}
                                        onRequestSort={this.handleRequestSort}
                                    />
                                    <TableBody>
                                        {this.stableSort(this.filterBySearch(this.props.categories), this.getComparator(this.state.order, this.state.orderBy)).map((row, index) => {
                                            return (
                                                <TableRow>
                                                    {/*<TableCell className='checkCircleBox'/>*/}

                                                    <TableCell align={"center"} className='inputBox'>
                                                        <div className='inputBox'>
                                                            <div className='textBox'>
                                                                {row.category}
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell align={"center"} className='inputBox'>
                                                        <div className='inputBox'>
                                                            <div className='textBox'>
                                                                {row.hsCode}
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className="endingBox"/>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    }
                </Paper>
            </>
        );
    }

}

ManageProductCategory.propTypes = {
    categories: PropTypes.array,
}

ManageProductCategory.defaultProps = {
    categories: [],
}

const mapStateToProps = (state) => ({
    categories: categorySelector(state),
})

const actionCreators = {
    saveProductCategoryChanges,
    removeProductCategory,
    getAllCategory,
    getUUID,
}
export default withShipment({
    mapStateToProps,
    actionCreators
}, ManageProductCategory);