import {
    GET_INVOICE_BY_INVOICE_NUMBER,
    REMOVE_DELETED_TRANSACTION,
    SET_PROCESSING,
    STORE_ALL_TRANSACTION_CATEGORIES,
    STORE_ALL_TRANSACTIONS,
    STORE_FOUND_INVOICE,
    STORE_SUPPLIER_REFERENCES,
    STORE_TRANSACTION_ATTACHMENT,
    STORE_UPDATED_TRANSACTION,
    STORE_UPDATED_TAXINFO,
    STORE_TAXTYPE_WITH_STATE_PROVINCE,
    STORE_UPDATED_TRANSACTIONS,
    STORE_EDITING_TRANSACTION,
    NAVIGATE_TO_PARENT_TRANSACTION,
    STORE_ALL_CHEQUES,
    STORE_UPDATED_CHEQUES,
    STORE_SHIPPING_SUMMARY,
    STORE_SHIPPING_SUMMARY_SETTINGS,
    UPDATE_SHIPPING_SUMMARY,
    STORE_STATEMENT_TAXES_TEMPLATE,
    STORE_STATEMENT_TAXES_SUMMARY,
    STORE_ALL_LEDGERS,
    STORE_TRANSACTIONS_LEDGER, STOP_LOADING, START_LOADING, STORE_PURCHASE_SUMMARY
} from "../../constants/accounting";


const initialState = {
    rates: [],
    cheques: [],
    shippingSummary: [],
    fetchTransactions: [],
    ledgers: [],
    transactions: [],
    editingTransaction: null,
    processing: false,
    attachmentFile: [],
    transactionCategories: [],
    fetchedReferences: [],
    taxInfos: [],
    taxTypewithStateProvince: [],
    paidList: [],
    receivedList: [],
    templates: [],
    loadingData: false,
    purchaseSummary: [],
}

const updateTransaction = (oldTransactions, updatedTransaction) => {
    let newTransactions = [...oldTransactions]
    const index = newTransactions.findIndex((transaction) => transaction.transactionId === updatedTransaction.transactionId)
    if (index !== -1) {
        newTransactions[index] = updatedTransaction
    } else {
        newTransactions.push(updatedTransaction)
    }
    return newTransactions
}

const removeTransaction = (oldTransactions, deletedTransactionId) => {
    return  oldTransactions.filter((transaction)=>
        transaction.transactionId !== deletedTransactionId && transaction.parentTransactionId !== deletedTransactionId
    )
}

export default (state = initialState, action) => {

    switch (action.type) {
        case STORE_ALL_TRANSACTIONS:
            return { ...state, transactions: action.transactions }
        case STORE_ALL_LEDGERS:
            return { ...state, ledgers: action.ledgers }
        case STORE_TRANSACTIONS_LEDGER:
            return { ...state, fetchTransactions: action.transactions }
        case STORE_ALL_CHEQUES:
            return { ...state, cheques: action.cheques }
        case STORE_SHIPPING_SUMMARY_SETTINGS:
            return { ...state, rates: action.rates }
        case STORE_STATEMENT_TAXES_TEMPLATE:
            return { ...state, templates: action.templates }
        case STORE_SHIPPING_SUMMARY:
            return { ...state, shippingSummary: action.summary }
        case STORE_PURCHASE_SUMMARY:
            return { ...state, purchaseSummary: action.purchaseSummary }
        case STORE_STATEMENT_TAXES_SUMMARY:
            return { ...state, paidList: action.taxSummary.paidList, receivedList: action.taxSummary.receivedList }
        case STORE_FOUND_INVOICE:
            return {...state, invoice: action.invoice}
        case STORE_UPDATED_TRANSACTION:
            return {...state, transactions: updateTransaction(state.transactions, action.updatedTransaction)}
        case STORE_UPDATED_TAXINFO:
            return {...state,  taxInfos: action.updatedTaxInfo}
        case STORE_TAXTYPE_WITH_STATE_PROVINCE:
            return {...state,  taxTypewithStateProvince: action.taxTypewithStateProvince}
        case REMOVE_DELETED_TRANSACTION:
            return {...state, transactions: removeTransaction(state.transactions, action.transactionId)}
        case SET_PROCESSING:
            return {...state, processing: action.processing}
        case STORE_SUPPLIER_REFERENCES:
            return {...state, fetchedReferences: action.references}
        case STORE_TRANSACTION_ATTACHMENT:
            return {...state, attachmentFile: action.attachmentFile}
        case STORE_ALL_TRANSACTION_CATEGORIES:
            return {...state, transactionCategories: action.data.transactionCategories}
        case STORE_UPDATED_TRANSACTIONS:
            let transactionIds = action.updatedTransactions.map((transaction) => transaction.transactionId)
            let filteredTransactions = state.transactions.filter((transaction)=>(
                !transactionIds.includes(transaction.transactionId)
            ))
            return {...state, transactions: [...filteredTransactions, ...action.updatedTransactions]}
        case STORE_UPDATED_CHEQUES:
            let chequesIds = action.updatedCheques.map((cheque) => cheque.chequeId)
            let filteredCheques = state.cheques.filter((cheque)=>(
                !chequesIds.includes(cheque.chequeId)
            ))
            return {...state, cheques: [...filteredCheques, ...action.updatedCheques]}
        case STORE_EDITING_TRANSACTION:
            return {...state, editingTransaction: action.editingTransaction}
        case NAVIGATE_TO_PARENT_TRANSACTION:
            return {...state, editingTransaction: state.transactions.find((transaction)=>transaction.transactionId == action.parentTransactionId)}
        case START_LOADING:
            return { ...state, loadingData: true };
        case STOP_LOADING:
            return { ...state, loadingData: false };
        default:
            return state
    }
}