import {
    CREATE_OR_EDIT_FTL_CARRIER_INVOICE_TEMPLATE,
    DELETE_FULL_TRUCKLOADS_CARRIER, DELETE_INVOICE_TEMPLATE,
    FETCH_FULL_TRUCKLOADS_CARRIERS,
    GET_FTL_CARRIER_INVOICE_TEMPLATE_PDF,
    LIST_FTL_CARRIER_INVOICE_TEMPLATES,
    LIST_FTL_CARRIER_WITHOUT_TEMPLATE,
    LIST_FTL_CARRIERS_WITHOUT_TEMPLATE, REMOVE_INVOICE_TEMPLATE,
    SAVE_FULL_TRUCKLOADS_CARRIERS,
    STORE_FTL_CARRIER_INVOICE_TEMPLATE_PDF,
    STORE_FTL_CARRIER_TEMPLATES,
    STORE_FTL_CARRIERS_WITHOUT_TEMPLATE,
    STORE_FULL_TRUCKLOADS_CARRIER,
    STORE_FULL_TRUCKLOADS_CARRIERS, UPDATE_FTL_CARRIER_INVOICE_TEMPLATES
} from "../../constants/fullTruckloadCarriers";


export const fetchFullTruckloadCarriers = () => ({
    type: FETCH_FULL_TRUCKLOADS_CARRIERS,
})

export const deleteFullTruckloadCarrier = (data) => ({
    type: DELETE_FULL_TRUCKLOADS_CARRIER,
    data
})

export const storeFullTruckloadCarriers = (fullTruckloadCarriers) => ({
    type: STORE_FULL_TRUCKLOADS_CARRIERS,
    fullTruckloadCarriers,
})

export const storeFullTruckloadCarrier = (fullTruckloadCarrier) => ({
    type: STORE_FULL_TRUCKLOADS_CARRIER,
    fullTruckloadCarrier,
})

export const saveFullTruckloadCarriers = (data) => ({
    type: SAVE_FULL_TRUCKLOADS_CARRIERS,
    data
})

export const listFTLCarrierInvoiceTemplates = () => ({
    type: LIST_FTL_CARRIER_INVOICE_TEMPLATES
})

export const createOrEditFTLCarrierInvoiceTemplate = (data, reset, error) => ({
    type: CREATE_OR_EDIT_FTL_CARRIER_INVOICE_TEMPLATE,
    data,
    reset,
    error
})

export const getFTLCarrierInvoiceTemplatePDF = (carrierId) => ({
    type: GET_FTL_CARRIER_INVOICE_TEMPLATE_PDF,
    carrierId
})

export const listFTLCarriersWithoutTemplate = () => ({
    type: LIST_FTL_CARRIERS_WITHOUT_TEMPLATE
})

export const storeFTLCarriersWithoutTemplate = (carriers) => ({
    type: STORE_FTL_CARRIERS_WITHOUT_TEMPLATE,
    carriers
})

export const updateFTLCarrierInvoiceTemplates = (template) => ({
    type: UPDATE_FTL_CARRIER_INVOICE_TEMPLATES,
    template
})

export const storeFTLCarrierInvoiceTemplates = (templates) => ({
    type: STORE_FTL_CARRIER_TEMPLATES,
    templates
})

export const storeFTLCarrierInvoiceTemplatePDF = (templatePDF) => ({
    type: STORE_FTL_CARRIER_INVOICE_TEMPLATE_PDF,
    templatePDF
})

export const deleteInvoiceTemplate = (carrierId) => ({
    type: DELETE_INVOICE_TEMPLATE,
    carrierId
})

export const removeInvoiceTemplate = (carrierId) => ({
    type: REMOVE_INVOICE_TEMPLATE,
    carrierId
})