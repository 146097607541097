import React from 'react';

// material components
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import Chip from "@material-ui/core/Chip";
import PropTypes from 'prop-types';
// constants

// styling
import './FileChip.css';
import {FILE_CHIP_MAX_TEXT_LENGTH} from "./constants";
import MenuItem from "../leftmenu/MenuItem";


class FileChip extends React.PureComponent {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <div className='attachment-chip'>
                <Chip
                    icon={<InsertDriveFileIcon/>}
                    label={this.props.displayName.length > FILE_CHIP_MAX_TEXT_LENGTH ? this.props.displayName.slice(0, FILE_CHIP_MAX_TEXT_LENGTH).concat('...') : this.props.displayName}
                    onDoubleClick={this.props.doubleClick}
                    onDelete={this.props.handleFileDelete}
                    className='custom-file-chip'
                />
            </div>
        );
    }
}

FileChip.propTypes = {
    displayName: PropTypes.string,
    doubleClick: PropTypes.func,
    handleFileDelete: PropTypes.func
};

FileChip.defaultProps = {
    displayName: "NO_FILE_NAME_ERR",
    doubleClick: ()=>{},
    handleFileDelete: ()=>{}
}

export default FileChip;