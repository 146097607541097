import React from 'react';

// material components
import Autocomplete from "@material-ui/lab/Autocomplete";
import { IconButton, TextField, Tooltip } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import green from "@material-ui/core/colors/green";
import Divider from "@material-ui/core/Divider";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import RemoveIcon from "@material-ui/icons/Remove";
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

// custom components
import CommentHistoryTable from "../global/CommentHistoryTable";


// constants
import {
    COMPLETED_STATUS,
    SAVE,
    CANCEL,
    ACTIONS,
    ADD_ACTION,
    TASKS_CHECKBOXES_HEADER,
    ADD_ACTION_BUTTON_TEXT,
    WRONG_ITEM_ORDERED_SHORTCODE_LABEL,
    WRONG_ITEM_ORDERED_QUANTITY_LABEL,
    WRONG_ITEM_SENT_QUANTITY_LABEL,
    WRONG_ITEM_SENT_SHORTCODE_LABEL, ACTION_LABEL
} from "./constants";

// styling
import './TicketsForm.css';
import { REFUND_AMOUNT_CHECKBOX } from "../returns/constants";
import InputAdornment from "@material-ui/core/InputAdornment";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import FileUpload from "../global/FileUpload";
import { getByteArray } from "../global/constants";
import PropTypes from "prop-types";
import styles from "../../pages/Dashboard/DashboardFinal/DashboardFinal.module.css";
import {PENDING_TOOLTIP_INFO} from "../../pages/Dashboard/DashboardFinal/constants";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";



class TicketsForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            originalTrackingId: this.getValue('originalTrackingId'),
            orderId: this.getValue('orderId'),
            actions: this.getValue('actions'),
            customerName: this.getValue('customerName'),
            assignedToId: this.getValue('assignedToId'),
            assignedToName: this.getValue('assignedToName'),
            dueDate: this.getValue('dueDate'),
            comments: this.getValue('comments'),
            ticketIssueId: this.getValue('ticketIssueId'),
            ticketId: this.getValue('ticketId'),
            ticketIssueName: this.getValue('ticketIssueName'),
            wrongShortcodeSent: this.getValue('wrongShortcodeSent'),
            wrongQuantitySent: this.getValue('wrongQuantitySent'),
            wrongShortcodeOrdered: this.getValue('wrongShortcodeOrdered'),
            wrongQuantityOrdered: this.getValue('wrongQuantityOrdered'),
            files: this.getValue('files'),
            location: this.getValue('location'),
            shipAddressId: this.getValue('shipAddressId'),

            newFiles: [],
            deletedFiles: [],
            validate: false,

            enableSave: true,
            whitelist: {
                'validate': true,
                'actions': true,
                'files': true,
                'newFiles': true,
                'deletedFiles': true,
                'comments': true,
                'ticketId': true,
                'enableSave': true
            },
            requiredListWrongItemSent: {
                'wrongShortcodeSent': true,
                'wrongQuantitySent': true,
            },
            requiredListWrongItemOrdered: {
                'wrongShortcodeOrdered': true,
                'wrongQuantityOrdered': true,
            }
        }

        this.baseState = this.state
    }

    componentDidMount() {
        if (!this.props.creatingTicket) {
            this.props.requestIssueSpecificActions({ ticketIssueId: this.props.ticket.ticketIssueId })
            this.props.requestIssueSpecificTasks({ ticketIssueId: this.props.ticket.ticketIssueId })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // if the file preview has been fetched
        if (this.props !== prevProps && this.props.ticket) {
            this.setState({
                files: this.props.ticket.files // TODO account for nested attachments
            })
        }

        if(this.props.orderInfoFetch !== prevProps.orderInfoFetch) {
            this.setState({originalTrackingId: this.props.orderInfoFetch?.trackingNumber})
            if(this.props.orderInfoFetch?.customerNameAndOrderItems[0]?.shippingAddressName?.length > 1){
                this.setState({
                    customerName: this.props.orderInfoFetch?.customerNameAndOrderItems[0]?.shippingAddressName,
                    orderId: this.props.orderInfoFetch?.customerNameAndOrderItems[0]?.orderItems[0]?.orderId,
                })
            }
        }

        if(this.state.ticketIssueName !== prevState.ticketIssueName) {
            this.props.requestIssueSpecificActions({ ticketIssueId: this.state.ticketIssueId })
            this.props.requestIssueSpecificTasks({ ticketIssueId: this.state.ticketIssueId })
            this.setState({
                wrongShortcodeSent: "",
                wrongQuantitySent: "",
                wrongShortcodeOrdered:"",
                wrongQuantityOrdered:""
            })
        }
    }

    handleDateChange = (date) => {
        this.setState({
            dueDate: date
        })
    }


    getValue = (key) => {
        if (this.props.creatingTicket && key === 'dueDate') {
            const today = new Date()
            const tomorrow = new Date(today)
            tomorrow.setDate(tomorrow.getDate() + 1)
            return tomorrow
        }

        if (key === 'files' && !this.props.creatingTicket)
            return this.props.ticket[key]
        else if (key === 'files')
            return []
        let location = this.props.locations.find(location => location["shipAddressId"] === this.props.currentUser.selectedLocationId)
        if (key === 'location') return location
        if (key === 'shipAddressId') return location.shipAddressId
        if (this.props.creatingTicket || key === 'comments')
            return ''

        return this.props.ticket[key]
    }

    handleIssueChange = (value) => {
        this.setState({
            ticketIssueId: value.ticketIssueId,
            ticketIssueName: value.ticketIssueName
        });

    }

    handleLocationChange = (value) => {
        this.setState({
            location: value,
            shipAddressId: value.shipAddressId
        })
    }

    handleAutocompleteChange = async (value, name, index) => {
        if(index === -1) {
            this.setState({
                [name]: value
            })
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleOrderIdChange = (e) => {
        this.trimField(e)
        if (e.target.value.trim() !== "")
            this.props.fetchOrderInfo({orderId: this.state.orderId})
    }
    handleOriginalTrackingIdChange = (e) => {
        this.trimField(e)
        if (e.target.value.trim() !== "")
            this.props.fetchOrderInfo({originalTrackingId: this.state.originalTrackingId})
    }

    trimField = (e) => {
        this.setState({
            [e.target.name]: e.target.value.trim()
        })
    }

    addAction = () => {
        const { actions } = this.state;
        actions.push({})
        this.setState({
            actions: actions
        })
    }
    removeAction = (index) => {
        const { actions } = this.state;
        actions.splice(index, 1)
        this.setState({
            actions: actions
        })
    }

    handleCommentUpdate = (e) => {
        this.setState({
            comments: {
                user: this.props.currentUser.firstname + " " + this.props.currentUser.lastname,
                date: new Date().toISOString(),
                comment: e.target.value
            }
        })
    }

    handleActionChange = (newAction, index) => {

        const { actions } = this.state;
        let taskItems = this.props.ticketActionTasks[this.state.ticketIssueName].ticketIssueTasks.find((task) => task.ticketActionName === newAction.ticketActionName)


        actions[index] = {
            actionName: newAction.ticketActionName,
            actionId: newAction.ticketActionId,
            tasks: taskItems,
            files: [],
            newFiles: [],
            deletedFiles: []
        }

        this.setState({
            actions: actions
        });
    }

    handleActionValueChange = (e, index) => {
        const { actions } = this.state
        actions[index][e.target.name] = e.target.value
        this.setState({
            actions: actions
        })
    }

    handleActionTaskUpdate = (e, index, taskIndex) => {
        const { actions } = this.state
        let curStatus = actions[index].tasks.ticketActionTasks[taskIndex].taskStatus
        actions[index].tasks.ticketActionTasks[taskIndex].taskStatus = !curStatus
        this.setState({
            actions: actions
        })

    }

    getTaskFields = (action, index) => {
        if (action?.tasks?.ticketActionTasks?.length > 1) {
            //Force a certain order for actions with 3 checkboxes
            let newTickerActionTasks = []
            newTickerActionTasks = [...newTickerActionTasks, action.tasks.ticketActionTasks.find(task => task.ticketActionTaskName.includes('label'))]
            newTickerActionTasks = [...newTickerActionTasks, action.tasks.ticketActionTasks.find(task => task.ticketActionTaskName.includes('shipped'))]
            newTickerActionTasks = [...newTickerActionTasks, action.tasks.ticketActionTasks.find(task => task.ticketActionTaskName.includes('received'))]
            action.tasks.ticketActionTasks = newTickerActionTasks
        }
        return (
            <div className='ticket-text-field'>
                <FormControl component="fieldset">
                    <FormGroup>
                        {action?.tasks?.ticketActionTasks?.map((task, j) => {
                            return (
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={this.state.actions[index].tasks.ticketActionTasks[j].taskStatus}
                                        onChange={(e) => this.handleActionTaskUpdate(e, index, j)}
                                        name={task.ticketActionTaskName}
                                    />}
                                    label={task.ticketActionTaskName}
                                />
                            )
                        })

                        }
                    </FormGroup>
                </FormControl>
            </div>

        );

    }

    validate = () => {
        // also go through all entries and validate if good !!!!!!!!
        let keys = Array.from(Object.keys(this.state));
        for (let i = 0; i < keys.length; i++) {
            if (keys[i] in this.state.whitelist)
                continue

            if (this.state.ticketIssueName !== "Wrong Item Sent" && this.state.ticketIssueName !== "Wrong Quantity Sent") {
                if (keys[i] in this.state.requiredListWrongItemSent) {
                    continue
                }
            }
            if (this.state.ticketIssueName !== "Wrong Item Ordered" && keys[i] in this.state.requiredListWrongItemOrdered) {
                continue
            }
            if (!this.state[keys[i]] || this.state[keys[i]].length === 0) {
                return false
            }
        }
        return !(this.state.dueDate == null || this.state.dueDate === 'Invalid Date');
    }

    handleAssignedToChange = (value) => {
        this.setState({
            assignedToId: value.userId,
            assignedToName: value.name
        })
    }

    handleFileAdd = (action, index, file) => {
        const { actions } = this.state
        //let prevActions = this.state.actions
        let prevFiles = actions[index].files
        let prevNewFiles = actions[index].newFiles ? actions[index].newFiles : []
        let prevFilesCopy = prevFiles
        let addedFile = this.getFormattedFileObject(action, file)

        //prevFiles.push(addedFile)
        //prevNewFiles.push(addedFile)

        actions[index].files = [addedFile]
        actions[index].newFiles = [addedFile]
        actions[index].prevFilesCopy = prevFilesCopy
        this.setState({
            actions: actions
        })


    }

    handleFileDelete = (action, index, fileIndex, file) => {
        const { actions } = this.state
        //let prevActions = this.state.actions
        let prevFiles = actions[index].files
        const prevDeletedFiles = actions[index].deletedFiles ? actions[index].deletedFiles : []
        const prevNewFiles = actions[index].newFiles

        const prevFilesCopy = prevFiles

        // we need to see if the deleted file is in the newFiles or if it is a file from the server
        let deletedFile = (prevFiles.splice(fileIndex, 1))[0]
        // remote file that already exists on the server
        if (!("file" in file)) {
            actions[index].prevFilesCopy = prevFilesCopy
            prevDeletedFiles.push(deletedFile)
        }
        // local file that is to be pushed to the server being removed
        else {
            prevNewFiles.splice(fileIndex, 1)
        }

        // if the file had been rendered
        if ("localURL" in file) {
            this.freeFromMemory(file.localURL)
        }

        actions[index].files = prevFiles
        actions[index].newFiles = prevNewFiles
        actions[index].deletedFiles = prevDeletedFiles

        this.setState({
            actions: actions
        })

    }

    freeFromMemory = (url) => {
        URL.revokeObjectURL(url)
    }


    getFormattedGeneralFileObject = (file) => {
        return { localURL: URL.createObjectURL(file), displayName: file.name, byteArray: getByteArray(file), type: file.type }

    }
    getFormattedFileObject = (action, file) => {
        return { localURL: URL.createObjectURL(file), displayName: file.name, byteArray: getByteArray(file), actionName: action.actionName, type: file.type }
    }

    // TODO need to delete all the blobs from memory after save is pressed
    getActionFields = (action, index) => {
        switch (action.actionName) {
            case 'Send Replacement Package':
                return (
                    <>
                        <TextField
                            required
                            className='ticket-text-field'
                            variant="outlined"
                            name="replacementTrackingId"
                            value={this.state.actions[index].replacementTrackingId}
                            onChange={(e) => this.handleActionValueChange(e, index)}
                            label="Replacement Tracking ID"
                            InputLabelProps={{ shrink: true }}
                        />
                        {this.getTaskFields(action, index)}
                    </>
                )
            case "Issue Refund":
                return (
                    <>
                        <TextField
                            required
                            className='ticket-text-field'
                            variant="outlined"
                            name="refundAmount"
                            value={this.state.actions[index].refundAmount}
                            onChange={(e) => this.handleActionValueChange(e, index)}
                            label={REFUND_AMOUNT_CHECKBOX}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AttachMoneyIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {this.getTaskFields(action, index)}
                    </>
                )
            case "File Claim":
                return (
                    <>
                        <TextField
                            required
                            className='ticket-text-field'
                            variant="outlined"
                            name="claimCaseId"
                            value={this.state.actions[index].claimCaseId}
                            onChange={(e) => this.handleActionValueChange(e, index)}
                            label="Claim Case ID"
                            InputLabelProps={{ shrink: true }}
                        />
                        {this.getTaskFields(action, index)}
                    </>
                )

            case "Investigate Lost Item":
                return (
                    <>
                        <TextField
                            required
                            className='ticket-text-field'
                            variant="outlined"
                            name="lostItemCaseId"
                            value={this.state.actions[index].lostItemCaseId}
                            onChange={(e) => this.handleActionValueChange(e, index)}
                            label="Case ID"
                            InputLabelProps={{ shrink: true }}
                        />
                        {this.getTaskFields(action, index)}
                    </>
                )

            case "Close Case":
                return (
                    <>
                        <TextField
                            required
                            className='ticket-text-field'
                            variant="outlined"
                            name="newOrderId"
                            value={this.state.actions[index].newOrderId}
                            onChange={(e) => this.handleActionValueChange(e, index)}
                            label="New Order ID"
                            InputLabelProps={{ shrink: true }}
                        />
                        {this.getTaskFields(action, index)}
                    </>
                )

            case "Send Redirect Label":
                return (
                    <>
                        <div className='ticket-text-field'>
                            <FileUpload
                                handleFileAdd={(file) => this.handleFileAdd(action, index, file)}
                                handleFileDelete={(file, fileIndex) => this.handleFileDelete(action, index, fileIndex, file)}
                                files={action.files ? action.files : []}
                                fetchFilePreview={(file) => this.props.fetchFilePreview({ path: file.path, ticketId: this.props.ticket.ticketId, actionIndex: index })}
                                singleFileUpload={true}
                            />
                        </div>
                        <div>
                            <div>
                                <TextField
                                    required
                                    className='ticket-text-field'
                                    variant="outlined"
                                    name="redirectLabelId"
                                    value={this.state.actions[index].redirectLabelId}
                                    onChange={(e) => this.handleActionValueChange(e, index)}
                                    label="Redirect Label ID"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </div>
                            <div>
                                {this.getTaskFields(action, index)}
                            </div>
                        </div>

                    </>
                )

            case "Send Return Label":
                return (
                    <>
                        <div className='ticket-text-field'>
                            <FileUpload
                                handleFileAdd={(file) => this.handleFileAdd(action, index, file)}
                                handleFileDelete={(file, fileIndex) => this.handleFileDelete(action, index, fileIndex, file)}
                                files={action.files}
                                fetchFilePreview={(file) => this.props.fetchFilePreview({ path: file.path, ticketId: this.props.ticket.ticketId, actionIndex: index })}
                                singleFileUpload={true}
                            />
                        </div>
                        <div>
                            <div>
                                <TextField
                                    required
                                    className='ticket-text-field'
                                    variant="outlined"
                                    name="returnTrackingId"
                                    value={this.state.actions[index].returnTrackingId}
                                    onChange={(e) => this.handleActionValueChange(e, index)}
                                    label="Return Tracking ID"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </div>
                            <div>
                                {this.getTaskFields(action, index)}
                            </div>
                        </div>
                    </>
                )
            case "Resolved - Non Issue":
            case "Customer - No Response":
                return (
                    <>
                        {this.getTaskFields(action, index)}
                    </>
                )
            case "Send Pending Shipment":
                return (
                    <>
                        <TextField
                            required
                            className='ticket-text-field'
                            variant="outlined"
                            name="trackingId"
                            value={this.state.actions[index].trackingId}
                            onChange={(e) => this.handleActionValueChange(e, index)}
                            label="Tracking ID"
                            InputLabelProps={{ shrink: true }}
                        />
                        {this.getTaskFields(action, index)}
                    </>
                )

            case 'Open a Case':
                return (
                    <>
                        <TextField
                            required
                            className='ticket-text-field'
                            variant="outlined"
                            name="caseId"
                            value={this.state.actions[index].caseId}
                            onChange={(e) => this.handleActionValueChange(e, index)}
                            label="Case ID"
                            InputLabelProps={{ shrink: true }}
                        />
                        {this.getTaskFields(action, index)}
                    </>
                )

            case "Credit Received":
                return (
                    <>
                        <TextField
                            required
                            className='ticket-text-field'
                            variant="outlined"
                            name="creditReceived"
                            value={this.state.actions[index].creditReceived}
                            onChange={(e) => this.handleActionValueChange(e, index)}
                            label= "Credit Received"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AttachMoneyIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {this.getTaskFields(action, index)}
                    </>
                )

            case "Respond to A-to-Z Claim":
                return (
                    <>
                        {this.getTaskFields(action, index)}
                    </>
                )

            case "Respond to Chargeback Claim":
                return (
                    <>
                        {this.getTaskFields(action, index)}
                    </>
                )
            // newly added action row
            default:
                return (
                    <>

                    </>
                )
        }
    }


    cancel = () => {
        if (this.state.actions.length !== 0) {
            this.state.actions.forEach((action) => {
                if (action.files) {
                    action.files.forEach((file) => {
                        if ("localURL" in file)
                            this.freeFromMemory(file.localURL)
                    })
                }
            })
        }


        this.props.cancel()
    }

    handleGeneralFileAdd = (file) => {
        const { files, newFiles } = this.state
        //let prevNewFiles = this.state.newFiles
        //let prevFiles = this.state.files
        let newFile = this.getFormattedGeneralFileObject(file)


        files.push(newFile)
        newFiles.push(newFile)

        this.setState({
            newFiles: newFiles,
            files: files
        });
    }

    handleGeneralFileDelete = (file, index) => {
        const { files, newFiles, deletedFiles } = this.state
        /*let prevFiles = this.state.files
        let deletedFiles = this.state.deletedFiles
        let prevNewFiles = this.state.newFiles*/

        let deletedFile = (files.splice(index, 1))[0]
        if (!("byteArray" in file)) {
            deletedFiles.push(deletedFile)
        }
        else {
            newFiles.splice(index, 1)
        }
        if ("localURL" in file) {
            this.freeFromMemory(file.localURL)
        }

        this.setState({
            files: files,
            deletedFiles: deletedFiles,
            newFiles: newFiles
        })
    }

    render() {
        return (
            <div className='ticket-box'>
                <div className='ticket-form-input-box'>
                    <div>
                        <div className='ticket-row-flexbox'>
                            <div className='row-empty-box'></div>
                            <Autocomplete
                                options={this.props.locations}
                                getOptionLabel={(option) => option?.name}
                                autoHighlight={true}
                                className='ticket-text-field'
                                onChange={(event, value) => { this.handleLocationChange(value) }}
                                value={this.state.location}
                                name="location"
                                blurOnSelect={true}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Location"
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        name="location"
                                        required
                                    />
                                }
                            />
                        </div>
                        <div className='ticket-row-flexbox'>
                            <div className='row-empty-box'></div>
                            <Autocomplete
                                options={this.props.ticketIssues}
                                getOptionLabel={(ticketIssues) => ticketIssues.ticketIssueName}
                                autoHighlight={true}
                                className='ticket-text-field'
                                onChange={(event, value) => { this.handleIssueChange(value) }}
                                value={{ ticketIssueId: this.state.ticketIssueId, ticketIssueName: this.state.ticketIssueName }}
                                name="issue"
                                disableClearable
                                blurOnSelect={true}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Issue"
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        name="issueType"
                                        required
                                    />
                                }
                            />
                            <TextField
                                required
                                className='ticket-text-field'
                                variant="outlined"
                                name="orderId"
                                value={this.state.orderId}
                                onChange={this.handleChange}
                                onBlur={this.handleOrderIdChange}
                                label="Order ID"
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                required
                                className='ticket-text-field'
                                variant="outlined"
                                name="originalTrackingId"
                                value={this.state.originalTrackingId}
                                onChange={this.handleChange}
                                onBlur={this.handleOriginalTrackingIdChange}
                                label="Original Tracking ID"
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>

                        <div className='ticket-row-flexbox'>
                            <div className='row-empty-box' />
                            <TextField
                                required
                                className='ticket-text-field'
                                variant="outlined"
                                name="customerName"
                                value={this.state.customerName}
                                onChange={this.handleChange}
                                label="Customer Name"
                                InputLabelProps={{ shrink: true }}
                            />


                            {this.state.ticketIssueName !== "Wrong Item Sent" &&
                            this.state.ticketIssueName !== "Wrong Item Ordered" &&
                            this.state.ticketIssueName !== "Wrong Quantity Sent"?
                                <>
                                    <div className='empty-box' />
                                    <div className='empty-box' />
                                </>
                                :
                                this.state.ticketIssueName === "Wrong Item Sent" ||  this.state.ticketIssueName === "Wrong Quantity Sent"?
                                    <>
                                        <Autocomplete
                                            options={this.props.products ? this.props.products.map(product => product.shortcode).sort((a, b) => { return a.toLowerCase().localeCompare(b.toLowerCase()) }) : []}
                                            getOptionLabel={(product) => product}
                                            autoHighlight={true}
                                            className='ticket-text-field'
                                            onChange={(event, value) => { this.handleAutocompleteChange(value, "wrongShortcodeSent", -1) }}
                                            value={this.state.wrongShortcodeSent}
                                            name="wrongShortcodeSent"
                                            blurOnSelect={true}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    label={WRONG_ITEM_SENT_SHORTCODE_LABEL}
                                                    InputLabelProps={{shrink: true}}
                                                    variant="outlined"
                                                    required
                                                />
                                            }
                                        />
                                        <TextField
                                            required
                                            className='ticket-text-field'
                                            variant="outlined"
                                            name="wrongQuantitySent"
                                            value={this.state.wrongQuantitySent}
                                            onChange={this.handleChange}
                                            label={WRONG_ITEM_SENT_QUANTITY_LABEL}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </>

                                    :

                                    <>
                                        <Autocomplete
                                            options={this.props.products ? this.props.products.map(product => product.shortcode).sort((a, b) => { return a.toLowerCase().localeCompare(b.toLowerCase()) }) : []}
                                            getOptionLabel={(product) => product}
                                            autoHighlight={true}
                                            className='ticket-text-field'
                                            onChange={(event, value) => { this.handleAutocompleteChange(value, "wrongShortcodeOrdered", -1) }}
                                            value={this.state.wrongShortcodeOrdered}
                                            name="wrongShortcodeOrdered"
                                            blurOnSelect={true}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    label={WRONG_ITEM_ORDERED_SHORTCODE_LABEL}
                                                    InputLabelProps={{shrink: true}}
                                                    variant="outlined"
                                                    required
                                                />
                                            }
                                        />
                                        <TextField
                                            required
                                            className='ticket-text-field'
                                            variant="outlined"
                                            name="wrongQuantityOrdered"
                                            value={this.state.wrongQuantityOrdered}
                                            onChange={this.handleChange}
                                            label={WRONG_ITEM_ORDERED_QUANTITY_LABEL}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </>


                            }
                        </div>
                    </div>
                </div>
                <Divider className='section-divider' />


                {/*This is the action adding autocomplete field*/}
                <div className='ticket-form-input-box'>
                    <div>
                        {!this.props.creatingTicket ?
                            <>
                                {/*dynamically generated list of autocompletes*/}
                                {this.state.actions.map((action, index) => {
                                    return (
                                        <>
                                            <div className='ticket-row-flexbox '>
                                                {/*<Divider className={'action-divider'}/>*/}
                                                {!(this.props.ticket && (this.props.ticket.status === COMPLETED_STATUS)) ?
                                                    <div className='remove-icon'>
                                                        <IconButton onClick={() => this.removeAction(index)}>
                                                            <RemoveCircleIcon
                                                                color="error"
                                                                style={{ color: "red" }}
                                                            />
                                                        </IconButton>
                                                    </div>
                                                    :
                                                    <div className='row-empty-box' />
                                                }
                                                <Autocomplete
                                                    options={this.props.ticketActions}
                                                    value={{ ticketActionId: action.actionId, ticketActionName: action.actionName }}
                                                    getOptionLabel={(ticketAction) => ticketAction.ticketActionName}
                                                    autoHighlight={true}
                                                    className='ticket-text-field'
                                                    disableClearable
                                                    name="actionName"
                                                    shrink
                                                    blurOnSelect={true}
                                                    onChange={(event, value) => { this.handleActionChange(value, index) }}
                                                    renderInput={(params) =>
                                                        <TextField
                                                            {...params}
                                                            label={ACTION_LABEL}
                                                            InputLabelProps={{ shrink: true }}
                                                            variant="outlined"
                                                            name="actionType"
                                                            required
                                                        />
                                                    }
                                                />
                                                {this.getActionFields(action, index)}
                                            </div>
                                            <Divider className='sub-section-divider' />
                                        </>
                                    )
                                })}

                                <div className='ticket-row-flexbox'>
                                    <div className='row-empty-box' />
                                    <div className='ticket-text-field'>
                                        {!(this.props.ticket && (this.props.ticket.status === COMPLETED_STATUS)) ?
                                            <Tooltip placement="bottom" title={ADD_ACTION}>
                                                <Button className='add-action-button' variant="contained" color="primary" onClick={this.addAction}>
                                                    {ADD_ACTION_BUTTON_TEXT}
                                                </Button>
                                            </Tooltip>
                                            :
                                            null
                                        }

                                    </div>
                                    <div className='ticket-text-field'></div>
                                    <div className='ticket-text-field'></div>
                                </div>


                            </>
                            :
                            <>
                                <div className='buttons'>
                                    <div className='ticket-row-flexbox'>
                                        <div className='row-empty-box' />
                                        <div className='ticket-text-field'>
                                            <Tooltip placement="left" title={ADD_ACTION}>
                                                <Button disabled className='add-action-button' variant="contained" color="primary" onClick={this.addAction}>
                                                    {ADD_ACTION_BUTTON_TEXT}
                                                </Button>
                                            </Tooltip>
                                        </div>
                                        <div className='ticket-text-field'></div>
                                        <div className='ticket-text-field'></div>
                                    </div>
                                    <Tooltip
                                        className={styles.tooltip}
                                        enterTouchDelay={0}
                                        leaveTouchDelay={30000}
                                        enterDelay={400}
                                        // leaveDelay={1000000} // for testing purposes
                                        title={
                                            <React.Fragment>
                                                The ADD ACTION button will be enabled after the ticket is created
                                                <br />
                                            </React.Fragment>
                                        }
                                    >
                                        <IconButton aria-label='info' className={styles.tooltip}>
                                            <InfoOutlinedIcon variant='outlined' fontSize='small' />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </>
                        }
                    </div>
                </div>


                <Divider className='section-divider' />

                <div className='ticket-form-input-box'>
                        <CommentHistoryTable
                            disabled={false}
                            comments={this.props.creatingTicket ? [] : this.props.ticket.comments}
                            handleCommentUpdate={this.handleCommentUpdate}
                            currentUser={this.props.currentUser}
                            clearComment={() => this.setState({ comments: '' })}
                            isTickets={true}
                        />
                </div>

                <Divider className='section-divider' />

                <div className='ticket-form-input-box'>
                    <div>
                        <div className='ticket-row-flexbox'>
                            <div className='row-empty-box' />
                            <div className='file-upload'>
                                <FileUpload
                                    handleFileAdd={this.handleGeneralFileAdd}
                                    handleFileDelete={this.handleGeneralFileDelete}
                                    files={this.state.files ? this.state.files : []}
                                    fetchFilePreview={(file) => this.props.fetchFilePreview({ path: file.path, ticketId: this.props.ticket.ticketId })}
                                    singleFileUpload={false}
                                />
                            </div>
                            <Autocomplete
                                options={this.props.users}
                                getOptionLabel={(user) => user.name}
                                autoHighlight={true}
                                className='ticket-text-field'
                                onChange={(event, value) => { this.handleAssignedToChange(value) }}
                                name="assignedTo"
                                value={this.props.users.find((user) => user.userId === this.state.assignedToId)}
                                blurOnSelect={true}
                                disableClearable
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Assigned To"
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        name="assignedToField"
                                        required
                                    />
                                }
                            />

                            <KeyboardDatePicker
                                required
                                error={this.state.dueDate == null || this.state.dueDate == 'Invalid Date'}
                                autoOk
                                variant="inline"
                                inputVariant="outlined"
                                label="Due Date"
                                format="yyyy/MM/dd"
                                className='ticket-text-field'
                                value={this.state.dueDate}
                                InputAdornmentProps={{ position: "start" }}
                                onChange={date => this.handleDateChange(date, true)}
                            />
                        </div>

                    </div>

                    <div className='buttons'>
                        <div className='ticket-cancel-button'>
                            <Button
                                variant='contained'
                                onClick={() => {
                                    //this.setState(this.baseState);
                                    this.cancel()

                                }
                                }
                            >
                                {CANCEL}
                            </Button>
                        </div>

                        <div className='ticket-save-button'>
                            <Button
                                variant='contained'
                                disabled={!this.validate()}
                                onClick={(event) => {
                                    event.showLoadingOverlay = true  // Prevents spam clicking the button
                                    this.props.submit(this.state, this.props.creatingTicket)
                                }}
                                color='primary'
                            >
                                {SAVE}
                            </Button>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

TicketsForm.propTypes = {
    currentUser: PropTypes.object,
    creatingTicket: PropTypes.bool,
    cancel: PropTypes.func,
    submit: PropTypes.func,
    ticket: PropTypes.object,
    ticketIssues: PropTypes.array,
    ticketActions: PropTypes.array,
    ticketActionTasks: PropTypes.object,
    users: PropTypes.array,
    requestIssueSpecificActions: PropTypes.func,
    requestIssueSpecificTasks: PropTypes.func,
    fetchFilePreview: PropTypes.func
}

TicketsForm.defaultProps = {
    currentUser: {},
    creatingTicket: false,
    cancel: () => { },
    submit: () => { },
    ticket: {},
    ticketIssues: [],
    ticketActions: [],
    ticketActionTasks: {},
    users: [],
    requestIssueSpecificActions: () => { },
    requestIssueSpecificTasks: () => { },
    fetchFilePreview: () => { }
}

export default TicketsForm;