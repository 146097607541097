import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from "uuid";

// custom components
import FeedbackBar from "../feedbackBar/FeedbackBar";
import ShipmentForm from "./ShipmentForm";
import RatingDialog from "./rating/RatingDialog";

// styling
import './ExpandedView.module.css';

// material components
import {
    Collapse,
    Typography,
    Box,
    TableRow,
    TableCell,
    IconButton,
    Checkbox,
    makeStyles,
    Tooltip, CircularProgress,
} from '@material-ui/core';

// material icons
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PrimeIcon from '../../media/images/Amazon-Prime.png'
import UnshippedIcon from '../../media/images/Unshipped.png'
import Label_printedIcon from '../../media/images/Label_printed.png'
import PickedIcon from '../../media/images/Picked-up.png'
import InTransitIcon from '../../media/images/In-Transit.png'
import ExceptionIcon from '../../media/images/Exception.png'
import DeliveredIcon from '../../media/images/Delivered.png'

// constants
import {
    TEST_ACCOUNTS,
    SUCCESS,
    ERROR,
    SUCCESS_MESSAGE,
    SAVING,
    SAVING_MESSAGE,
    WIDTH,
    LENGTH,
    HEIGHT,
    RATE_TITLE,
    LOADING_MESSAGE,
    FEDEX,
    RATE_SUCCESS,
    RATE_FAILED,
    SHIPMENT_READY_COLOR,
    SHIPMENT_CANCELLED_OR_SHIPPED_COLOR,
    SHIPMENT_REDIRECTED_OR_RETURNED_COLOR,
    SHIPMENT_MARK_SHIPPED_COLOR,
    ADDRESS_NOT_VALIDATED_COLOR,
    SHIPMENT_INFO_MISSING_COLOR,
    ADDRESS_VALIDATION_OPTIONS
} from './constants'
import Switch from "@material-ui/core/Switch";

// redux
import withShipment from "../../withShipment";
import {
    getStateAndProvinceMapping,
    setSavingOpen
} from "../../redux/actions/global";
import {
    editOrder,
    addPackage,
    copyPackage,
    deletePackage,
    editFromAddress,
    trackShipment,
    addCommodity,
    deleteCommodity,
    getShipmentCommercialInvoice,
    reprintPackage,
    openLinkedOrder,
    saveRedirectToOrderId,
    deleteRedirectToOrderId, getMessages, getAllMessages,
} from "../../redux/actions/orders";
import {
    savingSelector,
    statusSelector,
    savingOpenSelector, createOrderOpenSelector,
    stateAndProvinceMappingSelector,
} from "../../redux/selectors/global";
import {
    loadingShipmentRequestSelector,
    orderIdSelector, allLogsSelector
} from "../../redux/selectors/orders";
import {
    carriersSelector, tagsSelector
} from "../../redux/selectors/settings";
import {
    userInfoSelector
} from "../../redux/selectors/auth";
import PropTypes from "prop-types";
import { UNSHIPPED, SHIPPED, CANCELLED, RETURN, CLONED } from '../stages/constants';
import EditIcon from "@material-ui/icons/Edit";
import {CARRIER_SERVICE_OPTIONS} from "../automationrules/ConditionFilters/constants";
import {Archive} from "@material-ui/icons";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const useStyles = makeStyles((theme) => ({
    title: {
        backgroundColor: '#acd685',
        width: 30,
    },
    titleText: {
        marginLeft: 10
    },
    cell: {
        border: '1px solid #acd685'
    },
    expand: {
        width: 30,
        minWidth: 30,
    },
    tags: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: "left"
    },
    tagcell: {
        minWidth: 130,
        width: 130,
        paddingLeft: 4,
        paddingRight: 3,
    },
    spacing130cell: {
        minWidth: 130,
        width: 130,
        paddingLeft: 4,
        paddingRight: 3,
    },
    spacing170cell: {
        minWidth: 170,
        width: 170,
        paddingLeft: 4,
        paddingRight: 3,
    },
    spacing40cell: {
        minWidth: 40,
        width: 40,
        paddingLeft: 4,
        paddingRight: 3,
    },
    spacing100cell: {
        minWidth: 100,
        width: 100,
        paddingLeft: 4,
        paddingRight: 3,
    },
    spacing110cell: {
        minWidth: 110,
        width: 110,
        paddingLeft: 4,
        paddingRight: 3,
    },
    spacing120cell: {
        minWidth: 120,
        width: 120,
        paddingLeft: 4,
        paddingRight: 3,
    },
    spacing90cell: {
        minWidth: 90,
        width: 90,
        paddingLeft: 4,
        paddingRight: 3,
    },
    spacing160cell: {
        minWidth: 160,
        width: 160,
        paddingLeft: 4,
        paddingRight: 3,
    },
    status: {
        minWidth: 215,
        width: 215,
        paddingLeft: 4,
        paddingRight: 3,
        paddingTop: 0,
        paddingBottom: 0
    },
    statusText: {
        textAlign: 'center',
        justifyContent: 'center',
        color: '#005E73'
    },
    spacingcell: {
        width: "100%",
    },
    cell: {
        width: 100,
    },
    primeIcon: {
        height: 17,
        width: 60,
    },
    shipmentStatus: {
        height: 20,
        width: 150,
    },
    statusText: {
        justifyContent: 'center'
    },
    rateBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left'
    },
    rateNumber: {
        marginTop: 3,
        marginLeft: 2
    }
}))

function ExpandedView(props) {
    const classes = useStyles();
    const [show, setShow] = useState(false);
    const [commodityEditing, setCommodityEditing] = useState(false);
    const [isValidated, setIsValidated] = useState(false);
    const [phoneNumberFormatIncorrect, setPhoneNumberFormatIncorrect] = useState(false)
    const [shippingPhoneNumberFormatError, setShippingPhoneNumberFormatError] = useState(false)
    const [orderInfo, setOrderInfo] = useState({
        fromAccount: {},
        service: '',
        accounts: [],
        original: '',
        shipmentId: '',
        shippingAddressName: '',
        shippingAddressCompanyName: '',
        shippingAddressPhone: '',
        shippingAddressPhoneExtension: '',
        shippingAddressAddressLine1: '',
        shippingAddressAddressLine2: '',
        shippingAddressCity: '',
        shippingAddressStateOrRegion: '',
        shippingAddressPostalCode: '',
        shippingAddressCountry: '',
        isResidential: typeof props.row.isResidential === 'undefined' ? false : props.row.isResidential,
        isValidated: typeof props.row.isValidated === 'undefined' ? false : props.row.isValidated,
        email: '',
        signatureConfirmation: '',
        dutiesPaymentType: '',
        reasonForExport: '',
        termsOfShipment: '',
        documentsOnly: typeof props.row.documentsOnly === 'undefined' ? false : props.row.documentsOnly,
        documentsDescription: '',
        packages: [],
        commodities: [],
        orders: [],
        orderId: '',
        total: 0,
        rateFeedbackOpen: false,
        rateErrorFeedbackOpen: false,
        rateFeedbackMessage: '',
        notes: '',
        modifiedEarliestShipDate: props.row.modifiedEarliestShipDate,
        modifiedLatestShipDate: props.row.modifiedLatestShipDate,
        modifiedEarliestDeliveryDate: props.row.modifiedEarliestDeliveryDate,
        modifiedLatestDeliveryDate: props.row.modifiedLatestDeliveryDate,
    })
    const commodityField = [
        "numberOfPieces",
        "description",
        "countryOfManufacture",
        "harmonizedCode",
        "weight",
        "weightUnit",
        "quantity",
        "quantityUnit",
        "unitPrice",
        "customsValue",
        "tax",
        "currency",
    ]
    const [handleEditingValidateAddress , setHandleEditingValidateAddress] = useState(true)

    const [rateLoading, setRateLoading] = useState({ rateLoading: false })

    const [defaultShipmentReadyCheck] = useState({
        defaultCarrierArray: [
            "carrierName",
            "carrierService"
        ],
        defaultFromAddressArray: [
            "fromAddressAccountNumber",
            "fromAddressContactName",
            "fromAddressCompanyName",
            "fromAddressPhone",
            "fromAddressAddressLine1",
            "fromAddressCity",
            "fromAddressState",
            "fromAddressPostalCode",
            "fromAddressCountry",
            "fromAddressEmail"
        ],
        defaultShipmentAddressArray: [
            "shippingAddressName",
            "shippingAddressPhone",
            "shippingAddressAddressLine1",
            "shippingAddressCity",
            "shippingAddressStateOrRegion",
            "shippingAddressPostalCode",
            "shippingAddressCountry",
            "signatureConfirmation"
        ],
        defaultPackageArray: [
            "packaging",
            "weight",
            "length",
            "width",
            "height",
            "declaredValue",
            "currency",
            "reference1",
            "reference2",
            "reference3"
        ]
    })


    useEffect(() => {
        //alert(JSON.stringify(props.carriers))
        let total = 0;
        let packages = [];
        if (!props.row || !props.row.packages) {
            setShow(false)
            props.handleEditShipmentClose()
            return
        }
        props.row.packages.map(order => {
            if (order.orderItems) {
                order.orderItems.forEach(item => total += item.quantityOrdered)
            }
            const packageInfo = {
                packaging: order.packaging,
                packageId: order.packageId,
                shipmentId: order.shipmentId,
                trackingNumber: order.trackingNumber,
                reference1: order.reference1,
                reference2: order.reference2,
                reference3: order.reference3,
                weight: order.weight,
                length: order.length,
                width: order.width,
                height: order.height,
                canparLabelId: order.canparLabelId,
                zplLabel: order.zplLabel,
                skus: order.skus,
                currency: order.currency,
                declaredValue: order.declaredValue,
                products: order.products,
                orderItems: order.orderItems,
                rate: order.rate,
                rateCurrency: order.rateCurrency
            };
            packages.push(packageInfo);
        })

        let accounts = []

        props.carriers.map(carrier => {
            if (!carrier.isEnabled) return
            const alias = carrier.alias;
            const carrierName = carrier.name;
            const accountNumber = carrier.accountNumber;
            const meterNumber = carrier.meterNumber;
            const contractId= carrier.contractId;
            const defaultService = carrier.defaultService;
            const isBuyShipping = carrier.isBuyShipping;
            const marketplace = carrier?.marketplace?.marketplaceName;
            carrier.addresses.map(address => {
                const account = {
                    fromAddressAlias: alias,
                    fromAddressAddressLine1: address.addressLine1,
                    fromAddressAddressLine2: address.addressLine2,
                    fromAddressEmail: address.email,
                    fromAddressName: address.name,
                    fromAddressCompanyName: address.companyName,
                    fromAddressContactName: address.contactName,
                    fromAddressPhone: address.phone,
                    fromAddressPostalCode: address.postalCode,
                    fromAddressCity: address.city,
                    fromAddressState: address.state,
                    fromAddressCountry: address.country,
                    fromAddressAccountNumber: accountNumber,
                    fromAddressMeterNumber: meterNumber,
                    carrier: carrierName,
                    defaultService: defaultService,
                    isDefault: address.isDefault,
                    isBuyShipping: isBuyShipping,
                    marketplace: marketplace ? marketplace + " - " : ""
                }
                accounts = [...accounts, account];
            })
        })

        let fromAccount = {
            fromAddressAddressLine1: props.row.fromAddressAddressLine1 ? props.row.fromAddressAddressLine1 : "",
            fromAddressAddressLine2: props.row.fromAddressAddressLine2 ? props.row.fromAddressAddressLine2 : "",
            fromAddressEmail: props.row.fromAddressEmail ? props.row.fromAddressEmail : "",
            fromAddressName: props.row.fromAddressName ? props.row.fromAddressName : "",
            fromAddressCompanyName: props.row.fromAddressCompanyName ? props.row.fromAddressCompanyName : "",
            fromAddressContactName: props.row.fromAddressContactName ? props.row.fromAddressContactName : "",
            fromAddressPhone: props.row.fromAddressPhone ? formatPhoneNumber(props.row.fromAddressPhone,false) : "",
            fromAddressPostalCode: props.row.fromAddressPostalCode ? props.row.fromAddressPostalCode : "",
            fromAddressCity: props.row.fromAddressCity ? props.row.fromAddressCity : "",
            fromAddressState: props.row.fromAddressState ? props.row.fromAddressState : "",
            fromAddressCountry: props.row.fromAddressCountry ? props.row.fromAddressCountry : "",
            fromAddressAccountNumber: props.row.fromAddressAccountNumber ? props.row.fromAddressAccountNumber : "",
            fromAddressMeterNumber: props.row.fromAddressMeterNumber ? props.row.fromAddressMeterNumber : "",
            carrier: props.row.carrierName ? props.row.carrierName : "",
            isDefault: false,
            isBuyShipping: props.row?.isBuyShipping ?? false,
            marketplace: props.row?.marketplace ? props.row.marketplace + " - " : ""
        }

        // case where the account number was selected (don't include the from account)
        if (!props.row.fromAddressAlias /*|| props.row.fromAddressAlias === ''*/) {
            // if (props.row.carrierName) {
            //     const index = accounts.findIndex(account => account.carrier === props.row.carrierName && account.isDefault);
            //     if (index !== -1) {
            //         fromAccount = accounts[index]
            //     }
            // } else {
            fromAccount = {
                ...fromAccount,
                fromAddressAlias: ''
            }
            //}


        } else {
            fromAccount = {
                ...fromAccount,
                fromAddressAlias: props.row.fromAddressAlias
            }
        }

        setOrderInfo({
            ...orderInfo,
            accounts,
            fromAccount,
            service: props.row.carrierService,
            shipmentId: props.row.shipmentId,
            tags: props.row.tags,
            shippingAddressName: props.row.shippingAddressName,
            shippingAddressCompanyName: props.row.shippingAddressCompanyName,
            shippingAddressPhone: formatPhoneNumber(props.row.shippingAddressPhone,true),
            shippingAddressPhoneExtension: collectExtension(props.row.shippingAddressPhone),
            shippingAddressAddressLine1: props.row.shippingAddressAddressLine1,
            shippingAddressAddressLine2: props.row.shippingAddressAddressLine2,
            shippingAddressCity: props.row.shippingAddressCity,
            shippingAddressStateOrRegion: props.row.shippingAddressStateOrRegion,
            shippingAddressPostalCode: props.row.shippingAddressPostalCode,
            shippingAddressCountry: props.row.shippingAddressCountry,
            email: props.row.email,
            packages: packages,
            commodities: props.row?.commodities ?   props.row.commodities?.map(commodity => {
                return { ...commodity, id: uuidv4() }
            }): {},
            orders: props.row.orders,
            orderId: displayOrderId(),
            isResidential: props.row.isResidential,
            isValidated: props.row.isValidated,
            isReturnShipment: props.row.isReturnShipment,
            isCloneShipment: props.row.isCloneShipment,
            isRedirectShipment: props.row.isRedirectShipment,
            redirectRecipient: props.row.redirectRecipient,
            isMarkShippedShipment: props.row.isMarkShippedShipment,
            clonedOrderId: props.row.clonedOrderId,
            redirectFromOrderId: props.row.redirectFromOrderId,
            redirectToOrderId: props.row.redirectToOrderId,
            signatureConfirmation: props.row.signatureConfirmation,
            dutiesPaymentType: props.row.dutiesPaymentType,
            reasonForExport: props.row.reasonForExport,
            termsOfShipment: props.row.termsOfShipment,
            documentsOnly: props.row.documentsOnly,
            documentsDescription: props.row.documentsDescription,
            total: total,
            notes: props.row.notes
        })
        setShow(props.showInfo)
    }, [props.row])

    useEffect(() => {
        setIsValidated(props.row.isValidated)
    }, [props.row.isValidated])

    function collectExtension(input) {
        let cleaned = ('' + input).replace(/\D/g, '');
        return cleaned.length > 11 ? cleaned.substring(11) : "";
    }

    function formatPhoneNumber(input,isShipping) {
        if (!input) {
            return '';
        }

        let mainPart = input.replace(/\D/g, '');

        let trimmed = mainPart.length >= 11 ? mainPart.substring(0,11) : mainPart;
        let match = trimmed.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            let intlCode = (match[1] ? '+1 ' : '');
            if(shippingPhoneNumberFormatError) {
                setShippingPhoneNumberFormatError(false)
            }
            setPhoneNumberFormatIncorrect(false)
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        } else {
            if(input !== '' && isShipping){
                if(!shippingPhoneNumberFormatError) {
                    setShippingPhoneNumberFormatError(true)
                }
                setPhoneNumberFormatIncorrect(true)
            }
            return trimmed;
        }
    }

    const handleCarrierChange = (value, isCreateShip) => {
        let services = CARRIER_SERVICE_OPTIONS.filter(service =>
            service.startsWith(value)
        );
        setOrderInfo({ ...orderInfo, fromAccount: { ...orderInfo.fromAccount, carrier: value }, service: services[0]})
        const order = {
            id: props.row.shipmentId,
            field_to_edit: "carrierName",
            value: value,
            isPackage: false,
            index: -1,
            isProduct: false,
            productIndex: -1,
            quantity: -1,
            productInfo: {},
            service: services[0],
        }
        if (!isCreateShip) props.editOrder(order)
    }


    const handleAccountSelect = (value, isCreateShip, refreshFromAddressName) => {
        let index
        if (value?.isBuyShipping) {
            index = orderInfo.accounts.findIndex(account => account.fromAddressAccountNumber === value?.fromAddressAccountNumber &&
                account.carrier === value?.carrier &&
                account.fromAddressAlias === value?.fromAddressAlias &&
                account.marketplace === value?.marketplace)
        } else {
            index = orderInfo.accounts.findIndex(account => account.fromAddressAccountNumber === value?.fromAddressAccountNumber &&
                account.carrier === value?.carrier &&
                account.fromAddressAlias === value?.fromAddressAlias)
        }

        if (index !== -1) {
            orderInfo.accounts[index].fromAddressAlias = '';
        }

        setOrderInfo({
            ...orderInfo,
            service: orderInfo.accounts[index]?.defaultService,
            fromAccount: (orderInfo.accounts[index] && props.shipmentStatus !== RETURN) ? orderInfo.accounts[index] : {
                fromAddressAddressLine1: props.row.fromAddressAddressLine1 ? props.row.fromAddressAddressLine1 : "",
                fromAddressAddressLine2: props.row.fromAddressAddressLine2 ? props.row.fromAddressAddressLine2 : "",
                fromAddressEmail: props.row.fromAddressEmail ? props.row.fromAddressEmail : "",
                fromAddressName: props.row.fromAddressName ? props.row.fromAddressName : "",
                fromAddressCompanyName: props.row.fromAddressCompanyName ? props.row.fromAddressCompanyName : "",
                fromAddressContactName: props.row.fromAddressContactName ? props.row.fromAddressContactName : "",
                fromAddressPhone: props.row.fromAddressPhone ? props.row.fromAddressPhone : "",
                fromAddressPostalCode: props.row.fromAddressPostalCode ? props.row.fromAddressPostalCode : "",
                fromAddressCity: props.row.fromAddressCity ? props.row.fromAddressCity : "",
                fromAddressState: props.row.fromAddressState ? props.row.fromAddressState : "",
                fromAddressCountry: props.row.fromAddressCountry ? props.row.fromAddressCountry : "",
                fromAddressAccountNumber: props.row.fromAddressAccountNumber ? props.row.fromAddressAccountNumber : "",
                fromAddressMeterNumber: props.row.fromAddressMeterNumber ? props.row.fromAddressMeterNumber : "",
                carrier: props.row.carrierName ? props.row.carrierName : "",
                isDefault: false,
                isBuyShipping: false,
                marketplace: ""
            }
        })

        if (!isCreateShip) {
            var data = {
                ...orderInfo.accounts[index],
                id: props.row.shipmentId,
                refreshFromAddressName: refreshFromAddressName,
            }
            if (props.shipmentStatus === RETURN) {
                data = {
                    ...data,
                    fromAddressAddressLine1: props.row.fromAddressAddressLine1 ? props.row.fromAddressAddressLine1 : "",
                    fromAddressAddressLine2: props.row.fromAddressAddressLine2 ? props.row.fromAddressAddressLine2 : "",
                    fromAddressEmail: props.row.fromAddressEmail ? props.row.fromAddressEmail : "",
                    fromAddressName: props.row.fromAddressName ? props.row.fromAddressName : "",
                    fromAddressCompanyName: props.row.fromAddressCompanyName ? props.row.fromAddressCompanyName : "",
                    fromAddressContactName: props.row.fromAddressContactName ? props.row.fromAddressContactName : "",
                    fromAddressPhone: props.row.fromAddressPhone ? props.row.fromAddressPhone : "",
                    fromAddressPostalCode: props.row.fromAddressPostalCode ? props.row.fromAddressPostalCode : "",
                    fromAddressCity: props.row.fromAddressCity ? props.row.fromAddressCity : "",
                    fromAddressState: props.row.fromAddressState ? props.row.fromAddressState : "",
                    fromAddressCountry: props.row.fromAddressCountry ? props.row.fromAddressCountry : "",
                }
            }
            const dataConst = data
            props.editFromAddress(dataConst)
        }
    }


    // FIXX
    // change the value of the checkbox, and write to database (auto save)
    const handleCheck = (field) => {
        // if for some reason in the database isResidential is undefined, then we need to make it true when you click the box

        const order = {
            id: props.row.shipmentId,
            field_to_edit: field,
            value: (!(orderInfo[field])).toString(),
            isPackage: false,
            index: -1,
            isProduct: false,
            productIndex: -1,
            quantity: -1,
            productInfo: {}
        }

        setOrderInfo({
            ...orderInfo,
            [field]: !(orderInfo[field])
        })


        props.editOrder(order)
    }

    const getStateCode = (state) => {
        if (state) {
            let stateCode = props.stateAndProvinceMapping.find(stateEntry => stateEntry.aliases.find(alias => { return alias.toLowerCase() === state.toLowerCase() }))?.state
            if (stateCode) {
                return stateCode
            }
        }
        return state
    }

    const displayTags = (n) => {
        const tags = [...props.row.tags];
        if (tags?.length > 0 && tags[0].tagName) { //at least one tag
            return tags[0].tagName?.length > n ?
                tags[0].tagName.substr(0, n - 1) + '...'
                :
                tags.length > 1 ?
                    tags[0].tagName + '...'
                    :
                    tags[0].tagName
        }
        return ''
    }

    const displayContent = (content, n) => {
        let length = 0
        if (content) {
            length = content.length
        }

        return (
            length > n ? content.substr(0, n - 1) + '...' : content ? content : ''
        )
    }

    const handleChange = (value, name, isPackage, index) => {
       setIsValidated(false)
        if (isPackage) {
            let packages = [...orderInfo.packages];
            packages[index][name] = value;
            setOrderInfo({
                ...orderInfo,
                packages: packages,
            })
        } else if (commodityField.includes(name)) {
            let commodities = [...orderInfo.commodities];
            commodities[index][name] = value;
            if (name == "quantity" || name == "unitPrice") {
                commodities[index].customsValue = Math.round((parseFloat(commodities[index].quantity) * parseFloat(commodities[index].unitPrice)) * 100) / 100
            }
            setOrderInfo({
                ...orderInfo,
                commodities: commodities,
            })
        } else {
            if (name === "shippingAddressPhone") {
                value = formatPhoneNumber(value,true)
            }
            if (name === "fromAddressPhone") {
                value = formatPhoneNumber(value,false)
            }
            setOrderInfo({
                ...orderInfo,
                [name]: value
            })
        }

    }

    const handleAutocompleteChange = (value, name, isPackage, index) => {
        if (isPackage) {

            let packages = [...orderInfo.packages];
            packages[index][name] = value;
            setOrderInfo({
                ...orderInfo,
                packages: packages,
            })
        } else if (name == "tags") {
        } else {
            if (name == "fromAddressCountry") {
                setOrderInfo({
                    ...orderInfo,
                    [name]: value
                })
            } else {
                setOrderInfo({
                    ...orderInfo,
                    [name]: value
                })
            }
        }
    }

    const handleFromAccountChange = (value, name) => {
        const fromAccount = { ...orderInfo.fromAccount }
        fromAccount[name] = value
        setOrderInfo({
            ...orderInfo,
            fromAccount,
        })
    }

    const handleSave = async (e, isPackage, index, bypassCheck, validateAddress) => {
        let value = e.target.value
        let name = e.target.name

        if (name === "shippingAddressPhoneExtension") {
            name = "shippingAddressPhone"
            if(orderInfo.shippingAddressPhoneExtension !== "")
                value = orderInfo.shippingAddressPhone  +" x"+  value
            else
                value = orderInfo.shippingAddressPhone
        }
        else if (name === "shippingAddressPhone" && orderInfo.shippingAddressPhoneExtension !== "") {
            value = value +" x"+ orderInfo.shippingAddressPhoneExtension
        }

        const order = {
            id: props.row.shipmentId,
            field_to_edit: name,
            value: value,
            isPackage: isPackage,
            index: index,
            isProduct: false,
            productInfo: {},
            validateAddress: validateAddress
        }

        if (order.value !== orderInfo.original || bypassCheck) {
            await props.editOrder(order)
            // update the from account
        }
    }

    const handleBoxChange = (boxName, length, width, height, index) => {
        const order = {
            id: props.row.shipmentId,
            width: width,
            length: length,
            height: height,
            index: index,
            packaging: boxName
        }
        props.updateBoxType(order);
    }

    const romoveCommodity = (index) => {
        setCommodityEditing(true)
        props.deleteCommodity(props.row.shipmentId, index, setCommodityEditing)
    }

    const handleAutocompleteSave = (value, name, isPackage, index) => {

        const order = {
            id: props.row.shipmentId,
            field_to_edit: name,
            value: value,
            isPackage: isPackage,
            index: index,
            isProduct: false,
            productInfo: {}
        }
        props.editOrder(order)
    }

    const handleFocus = (e) => {
        setOrderInfo({
            ...orderInfo,
            original: e.target.value
        })
    }

    const handleAutocompleteFocus = (v) => {
        setOrderInfo({
            ...orderInfo,
            original: v
        })
    }

    const displaySkus = (n) => {
        if (orderInfo.packages.length > 0) {
            if (orderInfo.packages[0].skus?.length > 1) {
                return 'Multiple'
            } else {
                if (orderInfo.packages[0].skus) {
                    if (orderInfo.packages[0].skus[0] && orderInfo.packages[0].skus[0].length > n) {
                        return orderInfo.packages[0].skus[0].substr(0, n - 1) + '...'
                    } else {
                        return orderInfo.packages[0].skus[0]
                    }
                }
            }
        }
        return ''
    }

    const displayOrderId = () => {
        // if (props.row.orders) {
            if (props.row?.orders && props.row?.orders.length > 1) {
                return "Multiple"
            } else {
                return props.row?.orders ?
                    props.row?.orders[0].orderId
                    :
                    props.row?.packages[0].reference1?.length > 0 ?
                        props.row?.packages[0].reference1
                        :
                        ''
            }

        // } else {
        //     return props.orderId
        // }
    }

    const displayBrands = () => {
        const allBrands = [...new Set(props.row.packages
            .map(pkg => pkg.brands)  // Extract brands from each package
            .flat())];                // Flatten the array of arrays into a single array and remove duplicates

        return (allBrands.map((brand, index) => (
                    <div key={index}>
                            {brand}
                    </div>
                )))

    }

    const displayAddressValidatedTableCell = () => {

        switch (props.row.addressValidated) {
            case "Not Validated":
                return (
                    <TableCell align="center">
                        <Tooltip title={ADDRESS_VALIDATION_OPTIONS.NOT_VALIDATED}>
                            <CancelIcon style={{color: SHIPMENT_INFO_MISSING_COLOR}}/>
                        </Tooltip>
                    </TableCell>
                )
            case "System Validated":
                return (
                    <TableCell align="center">
                        <Tooltip title={ADDRESS_VALIDATION_OPTIONS.SYSTEM_VALIDATED}>
                            <CheckCircleIcon style={{color: ADDRESS_NOT_VALIDATED_COLOR}}/>
                        </Tooltip>
                    </TableCell>
                )
            case "Carrier Validated":
                return (
                    <TableCell align="center">
                        <Tooltip title={ADDRESS_VALIDATION_OPTIONS.CARRIER_VALIDATED}>
                            <CheckCircleIcon style={{color: SHIPMENT_READY_COLOR}}/>
                        </Tooltip>
                    </TableCell>
                )
            default:
                return <TableCell/>
        }
    }

    const displayOrderIdDetail = (orders) => {
        if (!orders) {
            return props.row.packages[0].reference1?.length > 0 ?
                props.row.packages[0].reference1
                :
                ''
        }
        const orderIds = [orders.map(order => order.orderId)]
        if (orders.length > 1) {
            return orderIds.join('/').toString()
        } else {
            return orderIds[0]
        }
    }

    const handleRate = (row) => {
        setRateLoading({
            rateLoading: true
        })
        props.openRatingShipment()
        const setState = (response, messages) => {
            const success = response?.length > 0 && response[0].rate
            let failMessage
            if (messages && messages.length > 0) {
                failMessage = messages[0]
            } else {
                failMessage = RATE_FAILED
            }
            props.closeRatingShipment()
            setRateLoading({
                rateLoading: false
            })
            setOrderInfo({
                ...orderInfo,
                rateFeedbackOpen: success,
                rateErrorFeedbackOpen: !success,
                feedbackMessage: success ? RATE_SUCCESS : failMessage,
            })
        }
        props.rateToDefault([{ id: row.shipmentId }], setState)
    }

    const handleRateFeedbackClose = () => {
        setOrderInfo({
            ...orderInfo,
            rateFeedbackOpen: false,
            rateErrorFeedbackOpen: false
        })
    }

    const handleTrack = (e) => {
        const data = {
            id: props.row.shipmentId,
            shipmentService: props.row.carrierService
        };
        props.trackShipment(data)
    }

    const formatDate = (date) => {
        if (date != null) {
            return date.split("T")[0]
        }
        return ""
    }

    const formatShipDate = (date) => {
        if (date != null) {
            let newDate = new Date(date)
            let times = newDate?.toString().split(' ')
            return times.length > 4 ? `${times[1]} ${times[2]}, ${times[3]}` : ""
        }
        return ""
    }

    const getOrderDate = () => {
        if (props.row.orderDate) {
            return formatDate(props.row.orderDate)
        }
        return props.row.orders ? props.row.orders.length > 0 ? formatDate(props.row.orders[0].purchaseDate) : '' : ''
    }

    const getShipDate = () => {
        return formatDate(props.row.shipDate)
    }

    const getCancelDate = () => {
        return formatDate(props.row.cancelDate)
    }

    const getShipByDate = () => {
        return props.row.orders ? props.row.orders.length > 0 ? props.modifyShipOrDeliverDate(props.row.modifiedLatestShipDate) : '' : ''
    }

    const getDeliverByDate = () => {
        return props.row.orders ? props.row.orders.length > 0 ? props.modifyShipOrDeliverDate(props.row.modifiedLatestDeliveryDate) : '' : ''
    }

    const getDeliveryIcon = () => {
        switch (props.row.shipmentStatus) {
            case 'Scanned':
            case 'Packed':
            //Scanned and packed have the same icon has printed
            case 'Printed':
                return <img src={Label_printedIcon} className={classes.shipmentStatus} />
            case 'Picked Up':
                return <img src={PickedIcon} className={classes.shipmentStatus} />
            case 'In Transit':
                return <img src={InTransitIcon} className={classes.shipmentStatus} />
            case 'Out For Delivery':
                return <img src={InTransitIcon} className={classes.shipmentStatus} />
            case 'On The Way':
                return <img src={InTransitIcon} className={classes.shipmentStatus} />
            case 'Delivered':
                return <img src={DeliveredIcon} className={classes.shipmentStatus} />
            case 'Exception':
                return <img src={ExceptionIcon} className={classes.shipmentStatus} />
            default:
                return <img src={UnshippedIcon} className={classes.shipmentStatus} />
        }
    }
    const getDeliveryStatus = () => {
        return (
            <div style={{ marginTop: 1, textAlign: 'center', justifyContent: 'center' }}>
                {getDeliveryIcon()}
                {!props.row.shipmentStatus ?
                    <div style={{ textAlign: 'center', justifyContent: 'center', color: '#005E73' }}>{"Pending"}</div>
                    : <div style={{
                        textAlign: 'center',
                        justifyContent: 'center',
                        color: '#005E73'
                    }}>{props.row.shipmentStatus}</div>
                }

                <div style={{
                    textAlign: 'center',
                    justifyContent: 'center',
                    color: '#005E73'
                }}>
                    {props.row.trackingNumber} {props.row.labelArchived ? <Archive /> : null}
                </div>
            </div>
        )
    }

    const getEmptyCells = () => {
        let emptyCells = []
        for (let index = 0; index < props.contentCells.length - 11; index++) {
            emptyCells.push(
                <TableCell align="left" className={classes.title}>
                </TableCell>
            )
        }
        return emptyCells
    }

    const expandShipment = () => {
        if (!show) {
            setShow(true)
            props.handleEditShipment()
        }
    }

    const readyForShipIndicator = () => {
        if (props.row.orderStatus === SHIPPED || props.row.orderStatus === CANCELLED) {
            if (props.row.orderStatus !== CANCELLED) {
                if (orderInfo.isRedirectShipment || orderInfo.isReturnShipment) {
                    return SHIPMENT_REDIRECTED_OR_RETURNED_COLOR
                } else if (orderInfo.isMarkShippedShipment) {
                    return SHIPMENT_MARK_SHIPPED_COLOR
                }
            }
            return SHIPMENT_CANCELLED_OR_SHIPPED_COLOR
        } else {
            if (props.isShipmentReady() && props.row.isValidated && !phoneNumberFormatIncorrect) {
                return SHIPMENT_READY_COLOR
            } else if (props.isShipmentReadyForMarkRedirected()) {
                return SHIPMENT_REDIRECTED_OR_RETURNED_COLOR
            }

            else if(!props.row.isValidated && props.isShipmentReady()) {
                return ADDRESS_NOT_VALIDATED_COLOR
            }  else  {
                return SHIPMENT_INFO_MISSING_COLOR
            }
        }
    }

    const openLinkedOrder = (orderId) => {
        const push = (path) => {
            props.history.push(path)
        }
        props.openLinkedOrder(orderId, push)
    }

    return (
        <React.Fragment>
            <FeedbackBar
                open={orderInfo.rateFeedbackOpen}
                handleClose={handleRateFeedbackClose}
                severity={SUCCESS}
                message={orderInfo.feedbackMessage}
            />
            <FeedbackBar
                open={orderInfo.rateErrorFeedbackOpen}
                handleClose={handleRateFeedbackClose}
                severity={ERROR}
                message={orderInfo.feedbackMessage}
            />
            <TableRow
                hover
                aria-checked={props.isItemSelected}
                tabIndex={-1}
                key={props.row.orderId}
                selected={props.isItemSelected}
                // onDoubleClick={expandShipment} // proved inconvenient with touch pad
            >
                {!show ?
                    <>
                        <td style={{ width: 5, backgroundColor: readyForShipIndicator(), left: 0 }}></td>
                        <TableCell className={classes.expand} style={show ? { backgroundColor: '#acd685' } : null}>
                            <IconButton aria-label="expand row" size="small" onClick={expandShipment}>
                                <EditIcon />
                            </IconButton>
                        </TableCell>
                    </>
                    : null}
                {show ? null
                    :
                    <>
                        <TableCell padding="checkbox">
                            <Checkbox
                                checked={props.isItemSelected}
                                onClick={(e) => props.handleClick(e, props.row.shipmentId)}
                                inputProps={{ 'aria-labelledby': props.labelId }}
                            />
                        </TableCell>
                        {props.contentCells.map((row) => {
                            if (row.selected == true) {
                                switch (row.id) {
                                    case 'tag':
                                        return (<TableCell className={classes.tagcell} align="left" id={props.labelId}
                                            scope="row" padding="none">
                                            {props.row.tags ? displayTags(13) : ''}
                                        </TableCell>)
                                    case 'name':
                                        return (<TableCell
                                            className={classes.spacing130cell}>{displayContent(props.row.shippingAddressName, 15)}</TableCell>)
                                    case 'address':
                                        return (<TableCell className={classes.spacing170cell}
                                            align="left">{displayContent(props.row.shippingAddressAddressLine1, 20)}</TableCell>)
                                    case 'city':
                                        return (<TableCell className={classes.spacing40cell}
                                            align="left">{displayContent(props.row.shippingAddressCity, 20)}</TableCell>)
                                    case 'state':
                                        return (<TableCell className={classes.spacing40cell}
                                            align="left">{displayContent(getStateCode(props.row.shippingAddressStateOrRegion), 3)}</TableCell>)
                                    case 'postalcode':
                                        return (<TableCell className={classes.spacing120cell}
                                                           align="left">{props.row.shippingAddressPostalCode}</TableCell>)
                                    case 'marketplace':
                                        return (<TableCell className={classes.spacing100cell}
                                            align="left">{props.row.marketplace}</TableCell>)
                                    case 'addressValidated':
                                        return (displayAddressValidatedTableCell())
                                    case 'brand':
                                        return (<TableCell className={classes.spacing100cell}
                                                                            align="left">{displayBrands()}</TableCell>)
                                    case 'sku':
                                        return (<TableCell className={classes.spacing130cell}
                                            align="left">{displaySkus(21)}</TableCell>)
                                    case 'shipfrom':
                                        return (<TableCell className={classes.spacing110cell}
                                            align="left">{props.row.fromAddressName}</TableCell>)
                                    case 'shipmethod':
                                        return (<TableCell className={classes.spacing120cell}
                                            align="left">{props.row.shipmentServiceLevel}</TableCell>)
                                    case 'rate':
                                        if (props.shipmentStatus === UNSHIPPED || props.shipmentStatus === RETURN || props.shipmentStatus === CLONED) {
                                            return (
                                                <TableCell align="left" className={classes.spacing130cell}>
                                                    <div className={classes.rateBox}>
                                                        <Tooltip title={`${RATE_TITLE} - ${displayOrderId()}`}>
                                                            <IconButton style={{ outline: 'none', padding: '0' }}
                                                                onClick={() => handleRate(props.row)}
                                                                disabled={!props.isShipmentReady()}>
                                                                <MonetizationOnIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                        {props.row.rate ? <div
                                                            className={classes.rateNumber}>{"$"}{parseFloat(props.row.rate).toFixed(2)}{" "}{props.row.rateCurrency} </div> : rateLoading.rateLoading ?
                                                            <CircularProgress size={25} /> : null}
                                                    </div>
                                                </TableCell>
                                            )
                                        } else {
                                            return (
                                                <TableCell align="left" className={classes.spacing130cell}>
                                                    <div className={classes.rateBox}>
                                                        {props.row.rate ? <div
                                                            className={classes.rateNumber}>{"$"}{parseFloat(props.row.rate).toFixed(2)}{" "}{props.row.rateCurrency} </div> : null}
                                                    </div>
                                                </TableCell>
                                            )
                                        }
                                    case 'carrier':
                                        return (<TableCell className={classes.spacing90cell}
                                            align="left">{props.row.carrierName}</TableCell>)
                                    case 'orderid':
                                        return (<TableCell className={classes.spacing160cell}
                                            align="left">{displayOrderId()}</TableCell>)
                                    case 'orderDate':
                                        return (<TableCell className={classes.spacing110cell}
                                            align="left">{getOrderDate()}</TableCell>)
                                    case 'shipDate':
                                        return (<TableCell className={classes.spacing130cell}
                                            align="left">{getShipDate()}</TableCell>)
                                    case 'cancelDate':
                                        return (<TableCell className={classes.spacing130cell}
                                            align="left">{getCancelDate()}</TableCell>)
                                    case 'shipBy':
                                        return (<TableCell className={classes.spacing110cell}
                                            align="left">{getShipByDate()}</TableCell>)
                                    case 'deliverBy':
                                        return (<TableCell className={classes.spacing120cell}
                                            align="left">{getDeliverByDate()}</TableCell>)
                                    case 'isprime':
                                        return (<TableCell className={classes.spacing40cell} align="left">
                                            {props.row.isPrime ?
                                                <img src={PrimeIcon} className={classes.primeIcon} /> : null}
                                        </TableCell>)
                                    case 'status':
                                        return (<TableCell className={classes.status} align="left">
                                            {getDeliveryStatus()}
                                        </TableCell>)
                                    default:
                                        return

                                }
                            }
                        })}
                    </>
                }
                {!show ? <TableCell className={classes.spacingcell}></TableCell> : null}
            </TableRow>
            <TableRow>
                {show ?
                    <TableCell style={{ padding: 5, backgroundColor: readyForShipIndicator(), left: 0 }}>
                    </TableCell> : null
                }
                <Collapse in={show} timeout={0} unmountOnExit>
                    <Box margin={1}>
                        {/*click on edit shipment form*/}
                        <ShipmentForm
                            // isShipmentValidated={props.isShipmentValidated}
                            showCommercialInvoice={props.showCommercialInvoice}
                            toggleCommercialInvoiceView={props.toggleCommercialInvoiceView}
                            isShipmentReady={props.isShipmentReady}
                            updateShipments={props.updateShipments}
                            setIsValidated={setIsValidated}
                            title={props.title}
                            shipmentStatus={props.shipmentStatus}
                            shipmentId={props.row.shipmentId}
                            orderInfo={orderInfo}
                            handleSave={handleSave}
                            handleAutocompleteSave={handleAutocompleteSave}
                            handleChange={handleChange}
                            handleFromAddressChange={handleFromAccountChange}
                            handleAutocompleteChange={handleAutocompleteChange}
                            handleCheck={handleCheck}
                            handleFocus={handleFocus}
                            handleAutocompleteFocus={handleAutocompleteFocus}
                            handleAccountSelect={handleAccountSelect}
                            handleCarrierChange={handleCarrierChange}
                            errorChecking={false}
                            required={false}
                            copyPackage={props.copyPackage}
                            deletePackage={props.deletePackage}
                            addPackage={props.addPackage}
                            editOrder={props.editOrder}
                            loading={props.loadingShipmentRequest}
                            isCreateShip={false}
                            tags={props.tags}
                            row={props.row}
                            locations={props.locations}
                            addCommodity={props.addCommodity}
                            romoveCommodity={romoveCommodity}
                            commodityEditing={commodityEditing}
                            getShipmentCommercialInvoice={props.getShipmentCommercialInvoice}
                            saveRedirectToOrderId={props.saveRedirectToOrderId}
                            deleteRedirectToOrderId={props.deleteRedirectToOrderId}
                            openLinkedOrder={openLinkedOrder}
                            reprintPackage={props.reprintPackage}
                            carriers={props.carriers?.filter((carrier) => carrier.isEnabled !== false)}
                            boxes={props.boxes}
                            handleBoxChange={handleBoxChange}
                            handleTrackShipment={handleTrack}
                            handleValidateAddress={props.validateAddress}
                            scale={props.scale}
                            handleCommercialInvoicePdfLoadSuccess={props.handleCommercialInvoicePdfLoadSuccess}
                            handleCommercialInvoicePdfLoadError={props.handleCommercialInvoicePdfLoadError}
                            commercialInvoicePdfLoadSuccess={props.commercialInvoicePdfLoadSuccess}
                            modifyShipOrDeliverDate={props.modifyShipOrDeliverDate}
                            marketplace={props.row.marketplace}
                            shippingPhoneNumberFormatError={shippingPhoneNumberFormatError}
                        />
                    </Box>
                </Collapse>
            </TableRow>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    status: statusSelector(state),
    savingOpen: savingOpenSelector(state),
    saving: savingSelector(state),
    loadingShipmentRequest: loadingShipmentRequestSelector(state),
    carriers: carriersSelector(state),
    userInfo: userInfoSelector(state),
    tags: tagsSelector(state),
    logs: orderIdSelector(state),
    stateAndProvinceMapping: stateAndProvinceMappingSelector(state),
})

const actionCreators = {
    editOrder,
    setSavingOpen,
    copyPackage,
    deletePackage,
    addPackage,
    editFromAddress,
    trackShipment,
    addCommodity,
    deleteCommodity,
    getShipmentCommercialInvoice,
    saveRedirectToOrderId,
    deleteRedirectToOrderId,
    openLinkedOrder,
    reprintPackage,
    getStateAndProvinceMapping,
}

ExpandedView.propTypes = {
    carriers: PropTypes.array,
    isItemSelected: PropTypes.bool,
    labelId: PropTypes.string,
    row: PropTypes.object,
    selected: PropTypes.object,
    handleClick: PropTypes.func,

    status: PropTypes.object,
    savingOpen: PropTypes.bool,
    saving: PropTypes.bool,
    loadingShipmentRequest: PropTypes.bool,
    userInfo: PropTypes.object,
    handleEditShipment: PropTypes.func,
    showInfo: PropTypes.bool,
    isShipmentReady: PropTypes.func,
    ratingShipment: PropTypes.bool,
    openRatingShipment: PropTypes.func,
    closeRatingShipment: PropTypes.func,
    handleEditShipmentClose: PropTypes.func,
    isShipmentReadyForMarkRedirected: PropTypes.func,
    saveRedirectToOrderId: PropTypes.func,
    deleteRedirectToOrderId: PropTypes.func,
    openLinkedOrder: PropTypes.func,
    clearLinkedShipment: PropTypes.func,
    packages: PropTypes.array,
}

ExpandedView.defaultProps = {
    carriers: [],
    isItemSelected: false,
    labelId: "",
    row: {},
    selected: {},
    handleClick: () => {
    },

    status: {},
    savingOpen: false,
    saving: false,
    loadingShipmentRequest: false,
    userInfo: {},
    handleEditShipment: () => {
    },
    showInfo: false,
    isShipmentReady: () => {
    },
    ratingShipment: false,
    packages: [],
    openRatingShipment: () => { },
    closeRatingShipment: () => { },
    handleEditShipmentClose: () => { },
    isShipmentReadyForMarkRedirected: () => { },
    saveRedirectToOrderId: () => { },
    deleteRedirectToOrderId: () => { },
    openLinkedOrder: () => { },
    clearLinkedShipment: () => { },
    stateAndProvinceMapping: [],
    modifyShipOrDeliverDate: () => {},
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, ExpandedView);