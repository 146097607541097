export const LOCATIONS_TOOLBAR_TITLE = "Locations";
export const CREATE_LOCATION_TITLE = "Add a Location";
export const BACK_TO_MENU_TITLE = "Back to Menu";

export const DUPLICATE_ERROR = "Duplicate location name";

export const DEFAULT_ROWS_PER_PAGE = 50;

export const MANAGE_LOCATIONS_TABLE_HEAD_CELLS = [
    { id: 'name', center: true, disablePadding: false, label: 'Name' },
    { id: 'addressLine1', center: true, disablePadding: false, label: 'Address', hide: true },
    { id: 'city', center: true, disablePadding: false, label: 'City', hide: true },
    { id: 'state', center: true, disablePadding: false, label: 'State', hide: true },
    { id: 'country', center: true, disablePadding: false, label: 'Country' },
    { id: 'enabled', center: true, disablePadding: false, label: 'Enabled' },
]

export const EDIT = "Edit"

export const ROWS_PER_PAGE_LABEL = "Locations per page"
export const DEFAULT_SORT_BY_HEAD_CELL = "name"

export const ENABLE_LOCATION_CHECKBOX_LABEL = "Enable location"