import React from 'react';

// import material icons
import {
    Box,
    IconButton, MenuItem, Select,
    TextField,
    Tooltip
} from "@material-ui/core";

// import constants
import {DELETE_ACTION, DRAG} from "./constants";
// material icons
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import DehazeIcon from '@material-ui/icons/Dehaze';

// import styling
import './QueryActionText.css';
import PropTypes from "prop-types";
import styles from "../../settings/ManageCarriers/CreateCarrierDialog.module.css";

class ActionText extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            actionId: "",
            type: "",
            name: "",
            value: "",
            disableManualValueField: false
        }
    }

    componentDidMount() {
        this.setState({
            ...this.props.action
        },
            ()=>{
            if(this.props.isEdit) {
                this.setState({
                    value: this.state.value === "null" ? "" : this.state.value,
                }, () => {
                    this.props.updateActionWithParent({...this.state});
                })
            }
            })
    }

    handleValueChange = (event) => {
        const value = event.target.value;
        this.setState(
            {
                value,
            },
            () => {
                this.props.updateActionWithParent({ ...this.state });
            }
        );
    }

    render() {
        return (
            <Box
                className='actionTextField'
                display='flex'
                flexDirection='row'
                justifyContent='left'
                alignItems='center'
            >
                <div className="actionTextFieldName">{this.state.name}</div >
                <div>
                    <TextField
                        className='actionTextFieldInput'
                        variant='outlined'
                        disabled={this.state.disableManualValueField}
                        label={this.state.name.split('Set ')[1]}
                        InputLabelProps={{ shrink: true }}
                        value={this.state.value}
                        onChange={this.handleValueChange}
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                </div>
                {/* in order to make sure that the delete icon aligns, we add an empty component */}
                <div className='actionTextEmptyDiv'>
                    <Tooltip title={DELETE_ACTION}>
                        <IconButton
                            aria-label={DELETE_ACTION}
                            onClick={() => this.props.deleteAction(this.state.actionId)}
                        >
                            <DeleteOutlineIcon variant='outlined' fontSize='small' />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={DRAG}>
                        <IconButton
                            aria-label='drag'
                        >
                            <DehazeIcon variant='outlined' fontSize='small' />
                        </IconButton>
                    </Tooltip>
                </div>
            </Box>
        );
    }

}

ActionText.propTypes = {
    action: PropTypes.array,
    updateActionWithParent: PropTypes.func,
    deleteAction: PropTypes.func,
    isEdit: PropTypes.bool
};

ActionText.defaultProps = {
    action: {},
    updateActionWithParent: ()=>{},
    deleteAction: ()=>{},
    isEdit: true
}

export default ActionText;