import React from 'react';

// material components
import {
    Table,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    TableContainer,
    Button,
    Tooltip,
    IconButton,
    Radio, Checkbox, TablePagination
} from "@material-ui/core";

// import constants
import {ASC, DESC} from "../../global/constants";
import {
    MARKETPLACE_HEAD_CELLS,
    REGISTERED_STORES_TITLE,
    ROWS_PER_PAGE_LABEL,
    DEFAULT_ROWS_PER_PAGE,
    MARKETPLACE_TOOLBAR_TITLE
} from "./constants";

// material icons
import EditIcon from "@material-ui/icons/Edit";

// styling
import '../ManageUsers/ManageUsersTable.css';

// custom components
import SettingsToolbar from "../SettingsToolbar";
import SearchableTable from "../../global/Search/SearchableTable";
import CreateMarketplaceDialog from "./CreateMarketplaceDialog";

// redux
import withShipment from "../../../withShipment";
import {
    userInfoSelector
} from "../../../redux/selectors/auth";
import GlobalTableHead from "../../global/Table/GlobalTableHead";
import PropTypes from "prop-types";
import CustomTableHeader from "../../global/Table/CustomTableHeader";
import {marketplacesSelector, responseMessageSelector} from "../../../redux/selectors/settings";
import {
    createMarketplace,
    deleteMarketplace,
    editMarketplace,
    listMarketplaces, storeResponseMessage, validateMarketplace
} from "../../../redux/actions/settings";
import DeleteSection from "../DeleteSection";
import ErrorFeedbackBar from "../../global/ErrorFeedbackBar";
import FeedbackBar from "../../feedbackBar/FeedbackBar";
import {SUCCESS} from "../../shipments/constants";

class MarketplacesTable extends SearchableTable {
    constructor(props) {
        super(props);
        this.state = {
            order: ASC,
            orderBy: "",
            isSearch: false, // true if search value is not empty, false otherwise
            searchValue: "", // the value in the search field (updates on change)
            searchRows: 0, // number of rows displayed while search criteria is met
            marketplaceDialog: false,
            deleteDialog: false,
            isDelete: {},
            rowsPerPage: DEFAULT_ROWS_PER_PAGE,
            page: 0,
            feedbackBarOpen: false,
            isEdit: false,
            selectedMarketplaceInfo: {
                marketplaceId: '',
                marketplaceName: '',
                marketplaceCompany: '',
                marketplaceCountry: '',
                marketplaceEnabled: false,
                url: '',
                sellerId: '',
                key: '',
                token: '',
                applicationName: "",
                lwaClientId: "",
                lwaClientSecret: "",
                externalMarketplaceId: "",
                refreshToken: "",
                endPoint: "",
                expiryDate: "",
                routeValidate: '',
                routeUpdate: '',
                routeFetch: '',
            }
        }
    }

    componentDidMount() {
        this.props.listMarketplaces({
            id: this.props.userInfo.company
        })
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevProps.responseMessage !== this.props.responseMessage && this.props.responseMessage!=="") {
            this.setState({
                feedbackBarOpen: true
            })
        }
    }

    handleRequestSort = (event, property) => {
        const isAsc = this.state.orderBy === property && this.state.order === ASC;
        this.setState({
            order: isAsc ? DESC : ASC,
            orderBy: property,
        });
    };

    handleOpenCreateDialog = () => {
        this.setState({
            marketplaceDialog: true,
            isEdit: false,
        })
    }

    handleOpenEditDialog = () => {
        this.setState({
            marketplaceDialog: true,
            isEdit: true
        })
    }

    handleCloseDialog = () => {
        this.setState({
            marketplaceDialog: false,
            selectedMarketplace: {},
            selectedMarketplaceInfo: {
                marketplaceId: '',
                marketplaceName: '',
                marketplaceCompany: '',
                marketplaceCountry: '',
                marketplaceEnabled: false,
                url: '',
                sellerId: '',
                key: '',
                token: '',
                lwaClientId: "",
                applicationName: "",
                lwaClientSecret: "",
                externalMarketplaceId: "",
                refreshToken: "",
                endPoint: "",
                expiryDate: "",
                routeValidate: '',
                routeUpdate: '',
                routeFetch: '',
            }
        })
    }

    // check if current selected === selected
    isSelected = (marketplaceId) => {
        return (this.state.selectedMarketplaceInfo.marketplaceId === marketplaceId)
    }

    // only one marketplace can be selected at a time for actions to be carried out correctly
    handleMarketplaceSelect = (marketplaceId) => () => {
        if (this.isSelected(marketplaceId)) {
            this.setState({
                selectedMarketplace: {},
                selectedMarketplaceInfo: {
                    marketplaceId: '',
                    marketplaceName: '',
                    marketplaceCompany: '',
                    marketplaceCountry: '',
                    marketplaceEnabled: false,
                    url: '',
                    sellerId: '',
                    key: '',
                    token: '',
                    lwaClientId: "",
                    applicationName: "",
                    lwaClientSecret: "",
                    externalMarketplaceId: "",
                    refreshToken: "",
                    endPoint: "",
                    expiryDate: "",
                    routeValidate: '',
                    routeUpdate: '',
                    routeFetch: '',
                }
            })
        } else {
            const selectedMarketplace = this.props.marketplaces.find(marketplace => marketplace.marketplaceId === marketplaceId)

            this.setState({
                selectedMarketplace: selectedMarketplace,
                selectedMarketplaceInfo: {
                    marketplaceId,
                    marketplaceName: selectedMarketplace.marketplaceName,
                    marketplaceCompany: selectedMarketplace.marketplaceCompany,
                    marketplaceCountry: selectedMarketplace.country,
                    marketplaceEnabled: selectedMarketplace.enabled,
                    url: selectedMarketplace.customUrl,
                    sellerId: selectedMarketplace.sellerId,
                    key: selectedMarketplace.key,
                    token: selectedMarketplace.token,
                    lwaClientId: selectedMarketplace.lwaClientId,
                    applicationName: selectedMarketplace.applicationName,
                    lwaClientSecret: selectedMarketplace.lwaClientSecret,
                    externalMarketplaceId: selectedMarketplace.externalMarketplaceId,
                    refreshToken: selectedMarketplace.refreshToken,
                    endPoint: selectedMarketplace.endPoint,
                    expiryDate: selectedMarketplace.expiryDate,

                    routeValidate: selectedMarketplace.routeValidate,
                    routeUpdate: selectedMarketplace.routeUpdate,
                    routeFetch: selectedMarketplace.routeFetch,
                }
            })
        }
        this.handleOpenEditDialog();
    }

    handleDeleteOpen = (index) => {
        let newIsDelete = this.state.isDelete
        newIsDelete[index] = true
        this.setState({isDelete: newIsDelete})
    }

    handleDeleteClose = (index) => {
        let newIsDelete = this.state.isDelete
        newIsDelete[index] = false
        this.setState({isDelete: newIsDelete})
    }

    handleDelete = (index) => {
        const data = {
            marketplaceId: this.state.selectedMarketplaceInfo.marketplaceId,
            companyId: this.props.userInfo.company
        }

        this.props.deleteMarketplace(data, this.handleDeleteClose(index))
    }

    onSearchValueChange = (event) => {
        this.setState({
            isSearch: event.target.value.length > 0,
            searchValue: event.target.value,
            page: 0
        });
    }

    closeFeedbackBar = () => {
        this.props.storeResponseMessage("")
        this.setState({
            feedbackBarOpen: false
        })
    }

    render() {
        return (
            <>
                <Paper className="paper">
                    <FeedbackBar
                        open={this.state.feedbackBarOpen}
                        severity={SUCCESS}
                        message={this.props.responseMessage}
                        handleClose={this.closeFeedbackBar}
                    />
                    <ErrorFeedbackBar />
                    {this.state.marketplaceDialog ?
                        <CreateMarketplaceDialog
                            open={this.state.marketplaceDialog}
                            isEdit={this.state.isEdit}
                            selectedMarketplace={this.state.selectedMarketplace}
                            marketplaceId={this.state.selectedMarketplaceInfo.marketplaceId}
                            handleClose={this.handleCloseDialog}
                            createMarketplace={this.props.createMarketplace}
                            editMarketplace={this.props.editMarketplace}
                            title={MARKETPLACE_TOOLBAR_TITLE}
                            selected={this.state.selectedMarketplaceInfo}
                            handleDeleteOpen={this.handleDeleteOpen}
                            handleDeleteClose={this.handleDeleteClose}
                            handleDelete={this.handleDelete}
                            deleteOpen={this.state.deleteDialog}
                            addingOrEditing={this.state.marketplaceDialog}
                            company={this.props.userInfo.company}
                            handleValidate={this.props.validateMarketplace}
                        />
                        :
                        <>
                            <SettingsToolbar
                                title={REGISTERED_STORES_TITLE}
                                handleCreateOpen={this.handleOpenCreateDialog}
                                handleEditOpen={this.handleOpenEditDialog}
                                selected={this.state.selectedMarketplaceInfo}
                                handleDeleteOpen={this.handleDeleteOpen}
                                handleDeleteClose={this.handleDeleteClose}
                                handleDelete={this.handleDelete}
                                deleteOpen={this.state.deleteDialog}
                                handleBack={this.handleBack}
                                handleClose={this.handleCloseDialog}
                                addingOrEditing={this.state.marketplaceDialog}
                            />
                            <CustomTableHeader
                                onSearchValueChange={this.onSearchValueChange}
                                pagination={
                                    <TablePagination
                                        className="table-pagination"
                                        labelRowsPerPage={ROWS_PER_PAGE_LABEL}
                                        rowsPerPageOptions={[5, 25, 50, 100]}
                                        count={!this.props.marketplaces ? 0 : this.filterBySearch(this.props.marketplaces).length}
                                        rowsPerPage={this.state.rowsPerPage}
                                        page={this.state.page}
                                        onChangePage={this.handleChangePage}
                                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    />
                                }
                            />
                            <TableContainer>
                                <Table
                                    aria-labelledby='tableTitle'
                                    size='medium'
                                    aria-label={MARKETPLACE_TOOLBAR_TITLE}>
                                    <GlobalTableHead
                                        isCarrierTable={false}
                                        headCells={MARKETPLACE_HEAD_CELLS}
                                        order={this.state.order}
                                        orderBy={this.state.orderBy}
                                        onRequestSort={this.handleRequestSort}
                                    />
                                    <TableBody>
                                        {this.stableSort(this.filterBySearch(this.props.marketplaces), this.getComparator(this.state.order, this.state.orderBy))
                                            .map((marketplace, index) => {
                                                const marketplaceId = marketplace.marketplaceId;
                                                const marketplaceName = marketplace.marketplaceName;
                                                const marketplaceCompany = marketplace.marketplaceCompany;
                                                const marketplaceCountry = marketplace.country;
                                                const marketplaceEnabled = marketplace.enabled;
                                                const handleDelete = () => {
                                                    const data = {
                                                        marketplaceId: marketplaceId,
                                                        companyId: this.props.userInfo.company
                                                    }

                                                    this.props.deleteMarketplace(data, this.handleDeleteClose(index))
                                                }
                                                return (
                                                    <TableRow key={marketplaceId}>
                                                        <TableCell>
                                                            <IconButton
                                                                onClick={this.handleMarketplaceSelect(marketplaceId)}>
                                                                <EditIcon/>
                                                            </IconButton>
                                                        </TableCell>

                                                        <TableCell>{marketplaceName}</TableCell>
                                                        <TableCell>{marketplaceCompany}</TableCell>
                                                        <TableCell>{marketplaceCountry}</TableCell>
                                                        <TableCell style={{ color: marketplaceEnabled ? "black" : "red" }}>
                                                            {marketplaceEnabled ? "Yes" : "No"}
                                                        </TableCell>
                                                        <DeleteSection
                                                            index={index}
                                                            handleDeleteFalse={this.handleDeleteClose}
                                                            handleDeleteOpen={this.handleDeleteOpen}
                                                            handleDeleteTrue={handleDelete}
                                                            isDelete={this.state.isDelete[index] ? this.state.isDelete : false}
                                                        />
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    }
                </Paper>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    marketplaces: marketplacesSelector(state),
    userInfo: userInfoSelector(state),
    responseMessage: responseMessageSelector(state),
});

const actionCreators = {
    createMarketplace,
    listMarketplaces,
    editMarketplace,
    deleteMarketplace,
    validateMarketplace,
    storeResponseMessage,
};

MarketplacesTable.propTypes = {
    marketplaces: PropTypes.array,
    userInfo: PropTypes.object
}

MarketplacesTable.defaultProps = {
    marketplaces: [],
    userInfo: {}
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, MarketplacesTable)