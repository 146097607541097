import React from 'react';

// material components
import {
    Toolbar,
    Typography,
    IconButton,
    Tooltip
} from "@material-ui/core";

// material icons
import RefreshIcon from '@material-ui/icons/Refresh';

// constants
import {
    DASHBOARD_TOOLBAR_TITLE,
    REFRESH_TITLE, SUMMARY_TITLE
} from "./constants";

// styling
import '../../../components/global/TableToolbar.css'
import {SwapHoriz} from "@material-ui/icons";

function DashboardToolbar(props) {
    return(
        <Toolbar className="toolbar">
            <div>
                <div className='refresh'>
                    <Tooltip title={REFRESH_TITLE}>
                        <IconButton onClick={props.refreshComponent}>
                            <RefreshIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <div>
                    <Tooltip title={SUMMARY_TITLE}>
                        <IconButton onClick={() => {}}>
                            <SwapHoriz/>
                        </IconButton>
                    </Tooltip>
                </div>
            </div>

            <div className="titleTypography">
                <Typography variant="h6" id="tableTitle">
                    {DASHBOARD_TOOLBAR_TITLE}

                </Typography>
            </div>

        </Toolbar>
    )
}

export default DashboardToolbar;