import React from 'react';

import {IconButton, Snackbar, Tooltip} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import PropTypes from "prop-types";
import FileCopyIcon from "@material-ui/icons/FileCopy";

import "./FeedbackBar.css";

class FeedbackBar extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            arrayMessages: []
        }
    }

    handleDialogClose = () => {
        this.props.handleClose()
    }

    copyText = (text) => {
        navigator.clipboard.writeText(text)
    }

    componentDidMount = async () => {
        await this.setState({
            arrayMessages: this.props.message.split("$")
        })
    }

    render() {
        const messageParts = this.props.message?.split("$").filter(part => part && part.trim() !== 'undefined');
        //for address validation, the detail will be provided in log
        const containsValidated = messageParts?.some(part => part.includes('validated'));
        return (
            <Snackbar
                open={this.props.open}
                autoHideDuration={6000}
                onClose={this.handleDialogClose}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                {/*<Alert variant="filled" onClose={this.handleDialogClose} severity={this.props.severity}>
                    {this.props.message.split("$")[0]}

                    <div>
                        {this.props.message.split("$").length > 1 && this.props.message.split("$")[1] !== ' ' ? <div>See <span onClick={this.props.pageLink} style={{textDecoration: "underline", cursor: "pointer"}}>logs</span> for more detail.</div> : null}
                    </div>
                </Alert>*/}
                <Alert variant="filled" onClose={this.handleDialogClose} severity={this.props.severity}>
                    {containsValidated ? (
                        <div style={{ whiteSpace: 'pre-line' }}>
                            {messageParts[0]}
                        </div>
                    ) : (
                        messageParts?.map((part, index) => (
                            <div key={index} style={{ whiteSpace: 'pre-line' }}>
                                {part}
                            </div>
                        ))
                    )}

                    <div>
                        {this.props.message.split("$").length > 1 && this.props.message.split("$")[1] !== ' ' ? <div>See <span onClick={this.props.pageLink} style={{textDecoration: "underline", cursor: "pointer"}}>logs</span> for more detail.</div> : null}
                    </div>
                </Alert>
            </Snackbar>
        );
    }

}

FeedbackBar.propTypes = {
    open: PropTypes.bool,
    severity: PropTypes.string,
    message: PropTypes.string,
    handleClose: PropTypes.func,
    canCopy: PropTypes.bool,
}

FeedbackBar.defaultProps = {
    open: false,
    severity: "",
    message: "",
    handleClose: ()=>{},
    canCopy: false
}

export default FeedbackBar;