import {SET_CLICKED_DATE_EVENTS} from "../../actions/accounting/calenderActions";

const initialState = {
    clickedDateEvents: [],
};

const calendarReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CLICKED_DATE_EVENTS:
            return {
                ...state,
                clickedDateEvents: action.payload,
            };
        default:
            return state;
    }
};

export default calendarReducer;
