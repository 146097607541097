import {
    put,
    call,
    takeLatest,
} from 'redux-saga/effects';
import axios from 'axios';

//constants
import {
    LIST_CLAIMS,
    CREATE_CLAIM,
    DELETE_CLAIM,
    UPDATE_CLAIM,
    PREVIEW_CLAIM_FILE,
    DELETE_CLAIM_FILE
} from "../../constants/claims";

//endpoints
import {
    CLAIM,
} from "../../../components/global/Server/endpoints";

// secondary actions
import {
    storeClaims,
    storeCreatedClaim,
    storeDeletedClaim,
    storeUpdatedClaim,
    storeUpdatedClaimFile
} from "../../actions/claims";

import {
    showErrorMessage,
    hideError, clearStatus, requestStatus,
} from "../../actions/global";

import { SERVER_FAILED_ERROR_MESSAGE } from '../../../pages/Dashboard/DashboardFinal/constants';
import {HTTP_SUCCESS} from "../../constants/global";

function* postRequest(endpoint, data) {
    return yield axios.post(endpoint, data, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
        .then(response => response);
}

const getRequest = (endpoint) => {
    return axios.get(endpoint, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
        .then(response => response);
}

function* listClaimsSaga() {
    yield put(hideError())
    try {
        const response = yield call(() => getRequest(CLAIM.LIST_CLAIMS))
        yield put(storeClaims(response.data))
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* createClaimSaga(action) {
    yield put(hideError())
    try{
        const response = yield call(() => postRequest(CLAIM.CREATE_CLAIM, action.claim))
        if (response.data.success) {
            yield put(storeCreatedClaim(response.data))
            action.refresh()
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function * updateClaimSaga(action) {
    yield put(hideError())
    try{
        const response = yield call(() => postRequest(CLAIM.UPDATE_CLAIM, action.claim))
        if (response.data.success) {
            yield put(storeUpdatedClaim(response.data))
            action.refresh()
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* deleteClaimSaga(action) {
    try {
        const response = yield postRequest(CLAIM.DELETE_CLAIM, {claimId: action.claimId})

        if (response.data.success) {
            try {
                yield put(storeDeletedClaim(response.data))
            } catch (e) {
                yield put(showErrorMessage("Update Claim Callback Failed"));
            }
        } else {
            yield put(showErrorMessage(response.data.message));
        }

    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE));
    }
}

function* previewClaimFileSaga(action) {
    yield call(clearStatus)
    yield put(hideError());
    try {
        const response = yield call(()=> postRequest(CLAIM.PREVIEW_CLAIM_FILE, action.previewRequest))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        }
        if (response.data.success) {
            yield put(storeUpdatedClaimFile(response.data.previewFile))
            action.refresh()
        }
        yield put((requestStatus(status)))
    }
    catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* deleteClaimFileSaga(action) {
    yield call(clearStatus)
    yield put(hideError());
    try{
        const response = yield call(()=> postRequest(CLAIM.DELETE_CLAIM_FILE, action.deleteRequest))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        }
        if (response.data.success) {
            yield put(storeUpdatedClaim(response.data))
            action.refresh()
        }
        yield put((requestStatus(status)))
    }
    catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

export default function* claimsSagas() {
    yield takeLatest(LIST_CLAIMS, listClaimsSaga);
    yield takeLatest(CREATE_CLAIM, createClaimSaga);
    yield takeLatest(DELETE_CLAIM, deleteClaimSaga);
    yield takeLatest(UPDATE_CLAIM, updateClaimSaga);
    yield takeLatest(PREVIEW_CLAIM_FILE, previewClaimFileSaga)
    yield takeLatest(DELETE_CLAIM_FILE, deleteClaimFileSaga)
}