import React from "react";
import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";
import {
    PRICING_DASHBOARD_PAGES,
    BACK_BUTTON, Add_BUTTON, Edit_BUTTON,
} from "../constants";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import './PricingDashboardForm.css';
import withShipment from "../../../withShipment";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import {
    addRelatedProduct,
    fetchPricingListings,
    getBuyBoxPrices,
    getSalesRank,
    editRelatedProduct, getRelatedProducts, updateRelatedProducts,
} from "../../../redux/actions/settings";
import {
    buyBoxPricesSelector,
    mainSalesRankSelector,
    subSalesRankSelector,
    relatedProductsMainSalesRankSelector, relatedProductsSelector, relatedProductBuyBoxPricesSelector,
} from "../../../redux/selectors/settings";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import AddRelatedProductPopper from "./AddRelatedProductPopper";
import EditRelatedProductPopper from "./EditRelatedProductPopper";
import {IconButton, Tooltip, Slide, Button} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import EditRelatedProducts from "./EditRelatedProducts";
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';

class PricingDashboardForm extends React.Component{
    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
        this.state = {
            showSubCategoryChart: false,
            responseMessage: "",
            feedbackBarOpen: false,
            showEditRelatedProducts: false,
            mainSalesRank:[],
            subSalesRank: [],
            relatedProductsMainSalesRank:[],
            originalRelatedProductsMainSalesRank: [],
            buyBoxPrices: [],
            relatedProductBuyBoxPrices: [],
            sortRelatedProductByRank: this.getcurrentAsinSetting()?.sortRelatedProductByRank?this.getcurrentAsinSetting().sortRelatedProductByRank : false,
        };
    }

    componentDidMount() {
        const currentDate = new Date()
        const thirtyDaysBefore = new Date(currentDate)
        thirtyDaysBefore.setDate(currentDate.getDate() - 30)
        // Convert both dates to ISO strings in UTC ("YYYY-MM-DDTHH:MM:SSZ")
        const startDate = thirtyDaysBefore.toISOString().replace(/\.\d+Z$/, 'Z') // Replaces '.sssZ' with 'Z'
        const endDate = currentDate.toISOString().replace(/\.\d+Z$/, 'Z') // Replaces '.sssZ' with 'Z'
        this.props.getSalesRank(this.props.row.webstoreProductId, startDate, endDate)
        this.props.getBuyBoxPrices(this.props.row.webstoreProductId, startDate, endDate)
        this.props.getRelatedProducts(this.props.row.webstoreProductId)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.mainSalesRank !== this.props.mainSalesRank ||
            prevProps.subSalesRank !== this.props.subSalesRank ||
            prevProps.relatedProductsMainSalesRank !== this.props.relatedProductsMainSalesRank ||
            prevProps.buyBoxPrices !== this.props.buyBoxPrices ||
            prevProps.relatedProductBuyBoxPrices !== this.props.relatedProductBuyBoxPrices) {

            this.setState({
                mainSalesRank: this.props.mainSalesRank,
                subSalesRank: this.props.subSalesRank,
                relatedProductsMainSalesRank: this.props.relatedProductsMainSalesRank,
                originalRelatedProductsMainSalesRank: [...this.props.relatedProductsMainSalesRank],
                buyBoxPrices: this.props.buyBoxPrices,
                relatedProductBuyBoxPrices: this.props.relatedProductBuyBoxPrices
            });
        }
        if(prevProps.tableSetting !== this.props.tableSetting){
            this.setState({
                sortRelatedProductByRank: this.getcurrentAsinSetting()?.sortRelatedProductByRank?this.getcurrentAsinSetting().sortRelatedProductByRank : false,
            })
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })

    }

    toggleSubCategoryChart = () => {
        this.setState(prevState => ({
            showSubCategoryChart: !prevState.showSubCategoryChart
        }));
    }

    handleAddRelatedProduct= (relatedProduct) => {
        this.props.addRelatedProduct(relatedProduct, this.props.row.webstoreProductId)
    }

    handleEditRelatedProduct= (relatedProduct) => {
        this.props.editRelatedProduct(relatedProduct, this.props.row.webstoreProductId)
    }

    toggleEditRelatedProductsShow = () => {
        this.setState({ showEditRelatedProducts: !this.state.showEditRelatedProducts })
    }

    getcurrentAsinSetting = () => {
        const { tableSetting, row } = this.props;

        if (!tableSetting || !tableSetting.pricingDashboardChartSetting) {
            return null
        }

        const currentSetting = tableSetting.pricingDashboardChartSetting.find(
            (setting) => setting.asin === row.asin && setting.marketplace === row.marketplaces[0]
        )
        return currentSetting || null
    }

    updateSortRelatedProductByRank = (newSetting) => {
        const { tableSetting, row } = this.props;

        if (!tableSetting) {
            return;
        }

        const pricingDashboardChartSetting = tableSetting.pricingDashboardChartSetting || [];

        const settingIndex = pricingDashboardChartSetting.findIndex(
            (setting) => setting.asin === row.asin && setting.marketplace === row.marketplaces[0]
        );

        if (settingIndex !== -1) {
            pricingDashboardChartSetting[settingIndex] = newSetting;
        } else {
            pricingDashboardChartSetting.push(newSetting);
        }

        this.props.saveTableSetting({
            userId: this.props.user.userId,
            changePricingDashboardChartSetting: true,
            pricingDashboardChartSetting: pricingDashboardChartSetting
        })
    }

    getPageName = () => {
        let rightHeaderButtons = [{ icon: <MenuIcon />, handler: this.toggleEditRelatedProductsShow, disabled: false, title: "Edit Related Products" }]
        return (
            <div>
                {this.props.row.shortcode? `(${this.props.row.shortcode})` + (this.props.row.quantity ? `-${this.props.row.quantity}` : '') +(this.props.row.marketplaces ? ` ${this.props.row.marketplaces[0]}` : '') : ""}
                {rightHeaderButtons && rightHeaderButtons.map((button, index) => (
                    <Tooltip title={button.title} key={button.title + index}>
                        <IconButton aria-label={button.title} onClick={button.handler} disabled={button.disabled}>
                            {button.icon}
                        </IconButton>
                    </Tooltip>
                ))}
            </div>
        );
    }
    getMainChartOptions = () => {

        let buyBoxPricesData = {
            name: `Buy Box Price`,
            data: this.state.buyBoxPrices.map(item => ({
                x: item.timeStamp,
                y: parseFloat(item.buyBoxPriceAmount),
                buyBoxWinnerSellerId: item.buyBoxWinner ? item.buyBoxWinner.sellerId : null,
                secondaryBuyBoxWinnerSellerId: item.secondaryBuyBoxWinner ? item.secondaryBuyBoxWinner.sellerId : null
            })),
            yAxis: 0,
            marker: {
                enabled: null,
                radius: 3,
                lineWidth: 1,
            },
            events: {
                legendItemClick: () => {
                }
            },
            tooltip: {
                valueDecimals: 2,
                valuePrefix: '$',
                pointFormatter: function () {
                    const buyBoxPrice = `${this.y.toFixed(2)}`;
                    const buyBoxWinnerSellerId = this.options.buyBoxWinnerSellerId;
                    const secondaryBuyBoxWinnerSellerId = this.options.secondaryBuyBoxWinnerSellerId;
                    let tooltipHtml = `<b>Buy Box Price:</b> ${buyBoxPrice}`;

                    if (buyBoxWinnerSellerId) {
                        tooltipHtml += `<br><b>Buy Box Winner:</b> ${buyBoxWinnerSellerId}`;
                    }
                    if (secondaryBuyBoxWinnerSellerId) {
                        tooltipHtml += `<br><b>Second Buy Box Winner:</b> ${secondaryBuyBoxWinnerSellerId}`;
                    }

                    return tooltipHtml;
                }
            }
        }

        let mainSalesRankData = this.state.mainSalesRank.map(categoryObj => {
            // Each categoryObj is an object where key is the category name and value is the rank array
            const categoryName = Object.keys(categoryObj)[0]; // Get the category name
            const ranks = categoryObj[categoryName]; // Get the array of rank objects
            const lastRank = ranks.length > 0 ? ranks[ranks.length - 1].rank : '';

            return {
                name: `Sales Rank - ${categoryName}</br>Last Rank: ${lastRank}`,
                data: ranks.map(({ timeStamp, rank }) => [timeStamp, parseInt(rank, 10)]),
                yAxis: 1,
                marker: {
                    enabled: null,
                    radius: 3,
                    lineWidth: 1,
                },
                events: {
                    legendItemClick: () => {
                    }
                },
                tooltip: {
                    valueDecimals: 0,
                    valuePrefix: '',
                    pointFormatter: function () {
                        const currentSalesRank = `${this.y}`;
                        let tooltipHtml = `<b>Sales Rank:</b> ${currentSalesRank}`;

                        if(lastRank){
                            tooltipHtml += `<br><b>Last Rank:</b> ${lastRank}`;
                        }

                        return tooltipHtml;
                    }
                }
            };
        });

        let subcategoryData = {
            name:`Subcategory<br>&nbsp;<br>`, //  dummy series for showing the sub category chart
            data: [],
            marker: {
                enabled: null,
                radius: 3,
                lineWidth: 1,
            },
            events: {
                legendItemClick: () => {
                    this.toggleSubCategoryChart(); // Toggle visibility of the second chart
                return false
                }
            }
        }

        if (this.state.sortRelatedProductByRank) {
            let sortedRelatedProducts = this.state.relatedProductsMainSalesRank?.sort((a, b) => {
                const lastRankA = a.salesRank[0] && a.salesRank[0][Object.keys(a.salesRank[0])[0]];
                const lastRankB = b.salesRank[0] && b.salesRank[0][Object.keys(b.salesRank[0])[0]];

                const rankA = lastRankA && lastRankA.length > 0 ? lastRankA[lastRankA.length - 1].rank : Infinity;
                const rankB = lastRankB && lastRankB.length > 0 ? lastRankB[lastRankB.length - 1].rank : Infinity;

                return parseInt(rankA, 10) - parseInt(rankB, 10);
            });
        }
        else {
            this.state.relatedProductsMainSalesRank = [...this.state.originalRelatedProductsMainSalesRank];
        }

        let relatedProductsData = [];

        //look up buy box prices using sales rank
        let section1 = this.state.relatedProductsMainSalesRank?.forEach((categoryObj, index, array) => {
            const relatedProductAsin = categoryObj.relatedProductAsin;
            const relatedProductPName = categoryObj.relatedProductName;
            const relatedProductMainRank = categoryObj.salesRank[0];
            const categoryName = Object.keys(relatedProductMainRank)[0]; // Get the category name
            const ranks = relatedProductMainRank[categoryName];
            const lastRanking = ranks && ranks.length > 0 ? ranks[ranks.length - 1].rank : '';
            const lastRank = ranks && ranks.length > 0 ? `</br>Last Rank: ${ranks[ranks.length - 1].rank}` : '';

            // Find corresponding BuyBox data
            const buyBoxObj = this.state.relatedProductBuyBoxPrices?.find(obj => obj.relatedProductAsin === relatedProductAsin);

            const salesRankName = relatedProductPName !== ''
                ? `Sales Rank - ${categoryName}</br>${relatedProductPName} (${relatedProductAsin})${lastRank}${!buyBoxObj ? '<br>&nbsp;<br>' : ''}`
                : `Sales Rank - ${categoryName}</br>(${relatedProductAsin})${lastRank}${!buyBoxObj ? '<br>&nbsp;<br>' : ''}`;

            // Sales Rank data
            relatedProductsData.push({
                name: salesRankName,
                data: ranks.map(({ timeStamp, rank }) => [timeStamp, parseInt(rank, 10)]),
                yAxis: 1,
                marker: {
                    enabled: null,
                    radius: 3,
                    lineWidth: 1,
                },
                events: {
                    legendItemClick: () => {
                    }
                },
                tooltip: {
                    valueDecimals: 0,
                    valuePrefix: '',
                    pointFormatter: function () {
                        const currentSalesRank = `${this.y}`;
                        let tooltipHtml = `<b>Sales Rank:</b> ${currentSalesRank}`;

                        if(lastRanking){
                            tooltipHtml += `<br><b>Last Rank:</b> ${lastRanking}`;
                        }

                        return tooltipHtml;
                    }
                }
            });

            if (buyBoxObj) {
                const buyBoxName = relatedProductPName !== ''
                    ? `Buy Box Price</br>${relatedProductPName} (${relatedProductAsin})<br>&nbsp;<br>`
                    : `Buy Box Price</br>(${relatedProductAsin})<br>&nbsp;<br>`;

                relatedProductsData.push({
                    name: buyBoxName,
                    data: buyBoxObj.buyBox.map(item => ({
                        x: item.timeStamp,
                        y: parseFloat(item.buyBoxPriceAmount),
                        buyBoxWinnerSellerId: item.buyBoxWinner ? item.buyBoxWinner.sellerId : null, // Include buyBoxWinnerSellerId
                        secondaryBuyBoxWinnerSellerId: item.secondaryBuyBoxWinner ? item.secondaryBuyBoxWinner.sellerId : null
                    })),
                    yAxis: 0,
                    marker: {
                        enabled: null,
                        radius: 3,
                        lineWidth: 1,
                    },
                    events: {
                        legendItemClick: () => {}
                    },
                    tooltip: {
                        valueDecimals: 2,
                        valuePrefix: '$',
                        pointFormatter: function () {
                            const buyBoxPrice = `${this.y.toFixed(2)}`;
                            const buyBoxWinnerSellerId = this.buyBoxWinnerSellerId;
                            const secondaryBuyBoxWinnerSellerId = this.secondaryBuyBoxWinnerSellerId;

                            let tooltipHtml = `<b>Buy Box Price:</b> ${buyBoxPrice}`;

                            if (buyBoxWinnerSellerId) {
                                tooltipHtml += `<br><b>Buy Box Winner:</b> ${buyBoxWinnerSellerId}`;
                            }
                            if (secondaryBuyBoxWinnerSellerId) {
                                tooltipHtml += `<br><b>Second Buy Box Winner:</b> ${secondaryBuyBoxWinnerSellerId}`;
                            }

                            return tooltipHtml;
                        }
                    },
                });
            }
        });

        // Add any remaining BuyBox data that wasn't matched to SalesRank
        let section2= this.state.relatedProductBuyBoxPrices?.forEach(buyBoxObj => {
            const relatedProductAsin = buyBoxObj.relatedProductAsin;
            const relatedProductPName = buyBoxObj.relatedProductName;

            // Check if this ASIN has already been processed (i.e., has SalesRank data)
            const alreadyProcessed = this.state.relatedProductsMainSalesRank?.some(
                obj => obj.relatedProductAsin === relatedProductAsin
            );

            if (!alreadyProcessed) {
                const buyBoxName = relatedProductPName !== ''
                    ? `Buy Box Price</br>${relatedProductPName} (${relatedProductAsin})<br>&nbsp;<br>`
                    : `Buy Box Price</br>(${relatedProductAsin})<br>&nbsp;<br>`;

                relatedProductsData.push({
                    name: buyBoxName,
                    data: buyBoxObj.buyBox.map(item => ({
                        x: item.timeStamp,
                        y: parseFloat(item.buyBoxPriceAmount),
                        buyBoxWinnerSellerId: item.buyBoxWinner ? item.buyBoxWinner.sellerId : null, // Include buyBoxWinnerSellerId
                        secondaryBuyBoxWinnerSellerId: item.secondaryBuyBoxWinner ? item.secondaryBuyBoxWinner.sellerId : null
                    })),
                    yAxis: 0,
                    marker: {
                        enabled: null,
                        radius: 3,
                        lineWidth: 1,
                    },
                    events: {
                        legendItemClick: () => {}
                    },
                    tooltip: {
                        valueDecimals: 2,
                        valuePrefix: '$',
                        pointFormatter: function () {
                            const buyBoxPrice = `${this.y.toFixed(2)}`;
                            const buyBoxWinnerSellerId = this.buyBoxWinnerSellerId;
                            const secondaryBuyBoxWinnerSellerId = this.secondaryBuyBoxWinnerSellerId;

                            let tooltipHtml = `<b>Buy Box Price:</b> ${buyBoxPrice}`;

                            if (buyBoxWinnerSellerId) {
                                tooltipHtml += `<br><b>Buy Box Winner:</b> ${buyBoxWinnerSellerId}`;
                            }
                            if (secondaryBuyBoxWinnerSellerId) {
                                tooltipHtml += `<br><b>Second Buy Box Winner:</b> ${secondaryBuyBoxWinnerSellerId}`;
                            }

                            return tooltipHtml;
                        }
                    },
                });
            }
        });

        let seriesData = [...mainSalesRankData, buyBoxPricesData, subcategoryData, ...relatedProductsData]

        let mainChartOptions = {
            chart: {
                type: 'line',
                width: 1200,
                height: 680
            },

            title: {
                text: '',
            },

            xAxis: {
                type: "datetime"
            },
            yAxis:[
                {
                    title: {
                        text: 'Buy Box Prices'
                    },
                    labels: {
                        format: '${value}',
                    }
                },
                {
                    title: {
                        text: 'Sales Rank'
                    },
                    opposite: true,
                    reversed: true,
                }

            ],

            series:
            seriesData,
            legend: {
                title: {
                    text: this.props.row.shortcode
                        ? this.props.row.shortcode + (this.props.row.quantity ? `-${this.props.row.quantity}` : '') + (this.props.row.asin ? ` (${this.props.row.asin})` : '') + '<br/>'
                        : (this.props.row.asin ? this.props.row.asin : ""),
                    style: {
                        fontSize: '1.2em'
                    }
                },
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'top',
                itemMarginBottom: 5,
                itemStyle: {fontSize : "1.05.em"},
            },
            time: {
                useUTC: false
            },
            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: false
                    },
                    pointStart: 2010
                }
            },
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
            }
        };

        return mainChartOptions;
    }

    getSubCategoryChartOptions = () => {
        let subSalesRankData = this.state.subSalesRank.map(categoryObj => {
            const categoryName = Object.keys(categoryObj)[0];
            const ranks = categoryObj[categoryName];
            const lastRank = ranks.length > 0 ? ranks[ranks.length - 1].rank : '';

            return {
                name: categoryName,
                data: ranks.map(({ timeStamp, rank }) => [timeStamp, parseInt(rank, 10)]),
                marker: {
                    enabled: null,
                    radius: 3,
                    lineWidth: 1,
                },
                tooltip: {
                    valueDecimals: 0,
                    valuePrefix: '',
                    pointFormatter: function () {
                        const currentSalesRank = `${this.y}`;
                        let tooltipHtml = `<b>Sales Rank:</b> ${currentSalesRank}`;

                        if(lastRank){
                            tooltipHtml += `<br><b>Last Rank:</b> ${lastRank}`;
                        }

                        return tooltipHtml;
                    }
                }
            };
        });

        let subChartOptions = {
            chart: {
                type: 'line',
                width: 1260,
                height: 680
            },
            title: {
                text: ''
            },
            xAxis: {
                type: 'datetime'
            },
            yAxis: {
                title: {
                    text: 'Sales Rank'
                },
                reversed: true,
            },
            series: subSalesRankData,
            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'top',
                itemMarginBottom: 5,
                itemStyle: {fontSize: "1.05.em"},
                title: {
                    text: this.props.row.shortcode ? `${this.props.row.shortcode}${this.props.row.quantity ? `-${this.props.row.quantity}` : ''}<br/>` : '',
                    style: {
                        fontSize: '1.2em'
                    }
                },
            },
            time: {
                useUTC: false
            }
        };

        return subChartOptions;
    }

    hideBuyBoxPrices = () => {
        const chart = this.chartRef?.current?.chart; // Access the chart instance
        if (chart) {
            chart.series.forEach(series => {
                // exclude "Buy Box Price", which is the main buyBoxPrice
                if (series.name.includes('Buy Box Price') && series.name !== 'Buy Box Price') {
                    series.setVisible(false, false);
                }
            });
            chart.redraw();
        }
    }

    hideSalesRank = () => {
        const chart = this.chartRef?.current?.chart; // Access the chart instance
        if (chart) {
            let firstSalesRankFound = false;

            chart.series.forEach(series => {
                if (series.name.includes('Sales Rank')) {
                    if (!firstSalesRankFound) {
                        firstSalesRankFound = true;
                    } else {
                        // Hide subsequent "Sales Rank" series
                        series.setVisible(false, false);
                    }
                }
            });
            chart.redraw();
        }
    }

    render() {
        const { showSubCategoryChart } = this.state;
        return (
            <React.Fragment>
                <TopToolbar
                    pageName={this.getPageName()}
                    menuItems={[
                        {title: BACK_BUTTON, icon: <KeyboardBackspaceIcon/>, onClick: () => {this.props.setCurrentPage(PRICING_DASHBOARD_PAGES.TABLE)}},
                        {title: Add_BUTTON, icon: <AddCircleIcon/>,
                            popper: <AddRelatedProductPopper
                                handleAddRelatedProduct={(relatedProduct) => this.handleAddRelatedProduct(relatedProduct)}
                            />},
                        /*{title: Edit_BUTTON, icon: <EditIcon/>,
                            popper: <EditRelatedProductPopper
                                row= {this.props.row}
                                handleEditRelatedProduct={(relatedProduct) => this.handleEditRelatedProduct(relatedProduct)}
                            />},*/

                    ]}
                />
                <div className="mainChart">
                    <div className="hidebutton" style={{ display: 'flex', alignItems: 'center'}}>
                        <Tooltip title="Hide all related product buy box prices">
                            <IconButton onClick={this.hideBuyBoxPrices} style={{ cursor: 'pointer',style: {marginRight: '10px'} }}>
                                <MoneyOffIcon />
                            </IconButton>
                        </Tooltip>
                        <span>Hide all related product buy box prices</span>

                        <Tooltip title="Hide all related product sales rank">
                            <IconButton onClick={this.hideSalesRank} style={{ cursor: 'pointer' }}>
                                <FilterListOffIcon />
                            </IconButton>
                        </Tooltip>
                        <span>Hide all related product sales rank</span>
                    </div>


                    <HighchartsReact
                        highcharts={Highcharts}
                        options={this.getMainChartOptions()}
                        ref={this.chartRef}
                    />
                </div>
                <div className="subChart">
                    {showSubCategoryChart?
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={this.getSubCategoryChartOptions()}
                        />
                        :null}
                </div>
                    {this.state.showEditRelatedProducts && (
                        <EditRelatedProducts
                            showEditRelatedProducts={this.state.showEditRelatedProducts}
                            relatedProducts={this.props.relatedProducts}
                            updateRelatedProducts={this.props.updateRelatedProducts}
                            row={this.props.row}
                            user={this.props.user}
                            currentAsinSetting={this.getcurrentAsinSetting()}
                            updateSortRelatedProductByRank={this.updateSortRelatedProductByRank}
                            sortRelatedProductByRank= {this.state.sortRelatedProductByRank}
                        />
                    )}
            </React.Fragment>
        )
    }
}

PricingDashboardForm.defaultProps = {
    mainSalesRank:[],
    subSalesRank:[],
    relatedProductsMainSalesRank:[],
    buyBoxPrices:[],
    relatedProducts:[],
    relatedProductBuyBoxPrices:[]

}
const mapStateToProps = (state) => ({
    mainSalesRank: mainSalesRankSelector(state),
    subSalesRank: subSalesRankSelector(state),
    relatedProductsMainSalesRank: relatedProductsMainSalesRankSelector(state),
    buyBoxPrices: buyBoxPricesSelector(state),
    relatedProducts: relatedProductsSelector(state),
    relatedProductBuyBoxPrices: relatedProductBuyBoxPricesSelector(state),
})

const actionCreators = {
    fetchPricingListings,
    getSalesRank,
    getBuyBoxPrices,
    getRelatedProducts,
    addRelatedProduct,
    updateRelatedProducts,
    editRelatedProduct
}

export default withShipment({
    actionCreators,
    mapStateToProps
}, PricingDashboardForm);