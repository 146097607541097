import React from "react";

// custom components
import FileChip from "./FileChip";
import FilePreviewDialog from "./FilePreviewDialog";
import FilePreviewWindow from "./FilePreviewWindow";

// material components

// constants

// styling
import "./FileUpload.css"
import Button from "@material-ui/core/Button";
import {CANCEL, DELETE_LOGO, SAVE, UPLOAD_FILE, UPLOAD_LOGO} from "./constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import {AddCircleOutline as AddIcon, Collections} from "@material-ui/icons";
import {IconButton, Paper, TableCell} from "@material-ui/core";
import green from "@material-ui/core/colors/green";
import PropTypes from "prop-types";
import DeleteIcon from "@material-ui/icons/Delete";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";


class FileUpload extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showingPreviews: false,
            initialIndex: 0,
            deleteOpen: false
        }
        this.hiddenFileInput = React.createRef()
    }

    componentWillUnmount() {
        this.hiddenFileInput = null
    }


    handleFilePreview = (index) => {
        this.setState({
            showingPreviews: true,
            initialIndex: index
        })
    }

    handleFileAdd = (event) => {
        for (let f = 0; f < event.target?.files?.length; f++) {
            this.props.handleFileAdd(event.target.files[f])
        }
    }

    handlePreviewClose = () => {
        this.setState({
            showingPreviews: false
        })
    }

    callFileInput = (event)=>{
        this.hiddenFileInput.current.click()
    }

    handleDeleteOpen = () => {
        this.setState({
            deleteOpen: true
        })
    }

    handleDeleteClose = () => {
        this.setState({
            deleteOpen: false
        })
    }

    render() {
        return (
            <> {/*TODO if the file is a .pdf render it with a diffferent component !!!!!!!!*/}
                {this.state.showingPreviews && !this.props.isLogo &&
                    <FilePreviewDialog
                        open={this.state.showingPreviews}
                        handleClose={this.handlePreviewClose}
                        files={this.props.files}
                        initialIndex={this.state.initialIndex}
                        fetchFilePreview={this.props.fetchFilePreview}
                        isPackingSlip={this.props.isPackingSlip}
                    />
                }

                <input type="file" ref={this.hiddenFileInput} onChange={this.handleFileAdd} className="hidden-file-field" id="group_image" multiple/>

                <div className='file-upload-container'>
                    {this.props.singleFileUpload ?
                        /*need to limit it to only one file upload*/
                        <div className={this.props.isFTLUpload ? 'single-file-container-borderless' : this.props.isPackingSlip || this.props.isFTL ? 'packing-slip-file-container' : 'single-file-container'}>

                            <div className='add-single-file-button'>
                                <IconButton onClick={this.callFileInput}>
                                    <AddIcon style={{color: green[500]}}/>
                                </IconButton>
                            </div>

                            <div className={this.props.isFTLUpload ? 'ftl-file-chip' : this.props.isPackingSlip || this.props.isFTL ? 'packing-slip-chip' : 'single-file-chip'}>
                                {this.props.files.map((file, index)=>{
                                    return (
                                        <FileChip
                                            displayName={file.displayName}
                                            doubleClick={()=>this.handleFilePreview(index)}
                                            handleFileDelete={()=>this.props.handleFileDelete(file, index)}
                                        />
                                    );
                                })}
                            </div>

                        </div>

                        :
                        <>
                            {!this.props.isLogo ?
                                <>
                                    <div className='file-upload-field'>
                                        <Button variant="contained" color="primary" onClick={this.callFileInput}>
                                            {UPLOAD_FILE}
                                        </Button>
                                    </div>
                                    <br/>
                                </>
                                : null
                            }
                            <div className='file-chips'>
                                {this.props.files.map((file, index)=>{
                                    return (
                                        <div className='vertical-file-chips' key={index}>
                                            <FileChip
                                                displayName={file.displayName}
                                                doubleClick={()=>this.handleFilePreview(index)}
                                                handleFileDelete={()=>this.props.handleFileDelete(file, index)}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                            {this.props.isLogo ?
                                <Table>
                                    <TableRow>
                                        <div>

                                            <Paper className='automationRulesPaper' variant='outlined'
                                                   style={{minWidth: 500, minHeight: 180}}>
                                                {(this.props.files[0] || this.props.existingLogo) ?
                                                    <FilePreviewWindow
                                                        files={this.props.existingLogo ? [this.props.existingLogo] : this.props.files}
                                                        initialIndex={this.state.initialIndex}
                                                        fetchFilePreview={this.props.fetchFilePreview}
                                                    />
                                                    :
                                                    null}
                                            </Paper>

                                        </div>
                                    </TableRow>
                                    <TableRow>
                                        {!this.props.files[0] ?
                                            <div className='save-cancel-buttons' style={{justifyContent:"left",alignItems:"start",alignContent:"start"}}>
                                                {!this.state.deleteOpen ?
                                                    <TableCell style={{
                                                        borderBottom: "none",
                                                        textAlign: "left"
                                                    }}>
                                                        <Button variant="contained" color="primary"
                                                                disabled={!this.props.existingLogo}
                                                                onClick={this.handleDeleteOpen}>

                                                            {DELETE_LOGO}

                                                        </Button>
                                                    </TableCell> : null}
                                                {this.state.deleteOpen ?
                                                    <TableCell style={{
                                                        borderBottom: "none",
                                                        textAlign: "left"
                                                    }}>
                                                        <Button
                                                            onClick={this.handleDeleteClose}
                                                        >
                                                            Cancel
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            startIcon={<DeleteIcon/>}
                                                            onClick={() => {
                                                                this.handleDeleteClose()
                                                                this.props.deleteExistingLogo()
                                                            }}
                                                        >
                                                            Delete
                                                        </Button>
                                                    </TableCell> : null}
                                                <TableCell
                                                    style={{borderBottom: "none", textAlign: "left"}}>
                                                    <Button variant="contained" color="primary"
                                                            onClick={this.callFileInput}>

                                                        {UPLOAD_LOGO}
                                                    </Button>
                                                </TableCell>
                                            </div>
                                            :
                                            this.props.files[0] ?
                                                <div className='save-cancel-buttons'>
                                                    <TableCell style={{
                                                        borderBottom: "none",
                                                        textAlign: "left"
                                                    }}>
                                                        <Button
                                                            variant='contained'
                                                            onClick={this.props.handleFileUploadCancel}
                                                        >
                                                            {CANCEL}
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell style={{
                                                        borderBottom: "none",
                                                        textAlign: "left"
                                                    }}>
                                                        <Button
                                                            variant='contained'
                                                            color='primary'
                                                            onClick={() => {
                                                                this.props.handleSave()
                                                            }}
                                                        >
                                                            {SAVE}
                                                        </Button>
                                                    </TableCell>
                                                </div> : null
                                        }
                                    </TableRow>
                                </Table>
                                : null}
                        </>
                    }



                </div>
        </>
        );
    }

}

FileUpload.propTypes = {
    handleFileAdd: PropTypes.func,
    handleFileDelete: PropTypes.func,
    files: PropTypes.array,
    fetchFilePreview: PropTypes.func,
    singleFileUpload: PropTypes.bool,
    existingLogo: PropTypes.object,
    deleteExistingLogo: PropTypes.func,
    handleFileUploadCancel: PropTypes.func,
    handleSave: PropTypes.func,
    isLogo: PropTypes.bool
}

FileUpload.defaultProps = {
    handleFileAdd: ()=>{},
    handleFileDelete: ()=>{},
    files: [],
    fetchFilePreview: ()=>{},
    singleFileUpload: false,
    existingLogo: null,
    deleteExistingLogo: ()=>{},
    handleFileUploadCancel: ()=>{},
    handleSave: ()=>{},
    isLogo: false
}

export default FileUpload;