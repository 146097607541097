import {
    Box,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    IconButton,
    TextField,
    Tooltip,
    Typography,
    Tabs,
    Tab,
} from "@material-ui/core";
import {
    ADD_COMMODITY_TOOLTIP,
    COMMERCIAL_INVOICE, COMMODITY_TITLE, COUNTRY_CODE_OPTIONS, CURRENCY_OPTIONS, DELETE_COMMODITY,
    DUTIES_PAYMENT_TYPE_OPTIONS,
    INTERNATIONAL_TITLE, QUANTITY_UNIT_OPTIONS,
    REASON_FOR_EXPORT_OPTIONS, TERM_OF_SHIPMENT_OPTIONS, WEIGHT_UNIT_OPTIONS
} from "./constants";
import {SHIPPED, UNSHIPPED} from "../stages/constants";
import RenderPurchaseOrderPDFPreview from "../purchaseOrders/RenderPurchaseOrderPDFPreview";
import DescriptionIcon from "@material-ui/icons/Description";
import Autocomplete from "@material-ui/lab/Autocomplete";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import React from "react";
import "./ShipmentForm.css"
import withShipment from "../../withShipment";
import {AddCircleOutline as AddIcon, Delete as DeleteIcon} from "@material-ui/icons";
import {DELETE_PACKAGE} from "./packageDetails/constants";
import {ADD_COMMODITY} from "../../redux/constants/orders";

const defaultProps = {
    bgcolor: 'background.paper',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    m: 1,

    border: 1,
    style: { width: '100%', height: '100%' },
};

class ShipmentFormInternationalDialogue extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showCommercialInvoice: false,
            value: 0,
            renderCommodities: false,
            orderInfo: this.props.orderInfo,
            filteredCommodities: []
        }
    }

    componentDidMount() {
        let filteredCommodities = this.filterCommodities()
        this.setState({
            filteredCommodities
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.packageId !== this.props.packageId || prevProps.orderInfo !== this.props.orderInfo) {
            let filteredCommodities = this.filterCommodities()
            this.setState({
                filteredCommodities
            })
        }
    }

    handleTabChange = (e, index) => {
        this.setState({value: index})
    }

    removeCommodity = async () => {
        let removeIndex = this.state.value
        if (this.state.filteredCommodities.length === 1) {
            this.setState({
                renderCommodities: false,
            })
        } else {
            if (this.state.value !== 0) {
                this.setState({
                    value: removeIndex - 1
                })
            }
        }
        await this.props.romoveCommodity(removeIndex)
    }

    filterCommodities = () => {
        if (this.props.orderInfo.commodities.length > 0) {
            let filtered = []
            this.props.orderInfo.commodities.forEach((commodity, index) => {
                if (commodity.packageId === this.props.packageId) {
                    filtered = [...filtered, {...commodity, idx: index}]
                }
            })
            return filtered
            // let filtered = this.props.orderInfo.commodities.filter(commodity => commodity.packageId === this.props.packageId)
            // return filtered.length > 0 ? filtered : []
        }
        return []
    }

    render() {
        return(<Box borderRadius="borderRadius" {...defaultProps} style={{ marginRight: '20px' }}>
            <div className="input-form">
                <div className="title-commodity">
                    <Typography variant="h6" gutterBottom component="div">
                        {INTERNATIONAL_TITLE}
                        <span style={{ float: 'right', textAlign: 'right', fontSize: '12px' }}></span>

                        {this.props.shipmentStatus == SHIPPED ?
                            <div className="commercial-invoice-box">
                                <Tooltip title={COMMERCIAL_INVOICE} style={{ width: 32, height: 32, marginRight: 10, marginTop: 5, float: 'right' }}>
                                    <IconButton
                                        onClick={() => {this.props.getShipmentCommercialInvoice(this.props?.shipmentId)
                                                        this.props.toggleCommercialInvoiceView()}}>

                                        <DescriptionIcon variant='outlined' fontSize='large' />
                                    </IconButton>
                                </Tooltip>
                            </div> : null
                        }
                        {this.props.shipmentStatus === UNSHIPPED ?
                            <Tooltip title={"Sample " + COMMERCIAL_INVOICE} style={{ width: 32, height: 32, marginRight: 10, marginTop: 5, float: 'right' }}>
                                <IconButton disabled={!this.props.isShipmentReady()}
                                            onClick={() => {this.props.getSampleCommercialInvoicePdf()
                                                            this.props.toggleCommercialInvoiceView()}}>

                                    <DescriptionIcon variant='outlined' fontSize='large' />
                                </IconButton>
                            </Tooltip> : null
                        }
                        <hr/>
                    </Typography>
                </div>

                <div className="row-spec">
                    <Autocomplete
                        options={DUTIES_PAYMENT_TYPE_OPTIONS}
                        getOptionLabel={(option) => option.label}
                        className="input-spec-international-first-col"
                        value={!this.state.orderInfo ? {} : DUTIES_PAYMENT_TYPE_OPTIONS.find((option) => { return option.value === this.state.orderInfo.dutiesPaymentType })}
                        onChange={(event, value) => {
                            if (value == null || value == undefined) {
                                this.props.handleAutocompleteChange(null, "dutiesPaymentType", false, 0)
                                if (!this.props.isCreateShip) {
                                    this.props.handleAutocompleteSave(null, "dutiesPaymentType", false, 0)
                                }
                            } else {
                                this.props.handleAutocompleteChange(value.value, "dutiesPaymentType", false, 0)
                                if (!this.props.isCreateShip) {
                                    this.props.handleAutocompleteSave(value.value, "dutiesPaymentType", false, 0)
                                }
                            }
                        }}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label="Duties Paid By"
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                name="dutiesPaymentType"
                                required
                            />
                        }
                    />
                    <FormControlLabel
                        name="documentsOnly"
                        className="input-spec-commodity"
                        control={<Checkbox
                            checked={this.state.orderInfo.documentsOnly ? this.state.orderInfo.documentsOnly : false}
                            onChange={() => this.props.handleCheck("documentsOnly")}
                        />}
                        label="Documents Only"
                    />

                    {/* TODO we didn't find where to send this Description so just disable this for now*/}
                    {/* <TextField
                                    className="input-spec-commodity-two-col"
                                    variant="outlined"
                                    name="documentsDescription"
                                    label="Description"
                                    InputLabelProps={{ shrink: true }}
                                    value={state.orderInfo.documentsDescription}
                                    onFocus={props.handleFocus}
                                    onChange={(e) => props.handleChange(e.target.value, e.target.name, false, 0)}
                                    onBlur={(e) => isCreateShip() ? null : props.handleSave(e, false, 0)}
                                /> */}
                </div>
                {!this.state.orderInfo.documentsOnly ?
                    <div className="row-spec">
                        <Autocomplete
                            options={REASON_FOR_EXPORT_OPTIONS}
                            getOptionLabel={(option) => option.label}
                            className="input-spec-international-first-col"
                            value={!this.state.orderInfo ? {} : REASON_FOR_EXPORT_OPTIONS.find((option) => { return option.value === this.state.orderInfo.reasonForExport })}
                            onChange={(event, value) => {
                                if (value == null || value == undefined) {
                                    this.props.handleAutocompleteChange(null, "reasonForExport", false, 0)
                                    if (!this.props.isCreateShip) {
                                        this.props.handleAutocompleteSave(null, "reasonForExport", false, 0)
                                    }
                                } else {
                                    this.props.handleAutocompleteChange(value.value, "reasonForExport", false, 0)
                                    if (!this.props.isCreateShip) {
                                        this.props.handleAutocompleteSave(value.value, "reasonForExport", false, 0)
                                    }
                                }
                            }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="Reason for Export"
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    name="reasonForExport"
                                    required
                                />
                            }
                        />
                        <Autocomplete
                            options={TERM_OF_SHIPMENT_OPTIONS}
                            getOptionLabel={(option) => option.label}
                            className="input-spec-commodity-one-half-col"
                            value={!this.state.orderInfo ? {} : TERM_OF_SHIPMENT_OPTIONS.find((option) => { return option.value === this.state.orderInfo.termsOfShipment })}
                            onChange={(event, value) => {
                                if (value == null || value == undefined) {
                                    this.props.handleAutocompleteChange(null, "termsOfShipment", false, 0)
                                    if (!this.props.isCreateShip) {
                                        this.props.handleAutocompleteSave(null, "termsOfShipment", false, 0)
                                    }
                                } else {
                                    this.props.handleAutocompleteChange(value.value, "termsOfShipment", false, 0)
                                    if (!this.props.isCreateShip) {
                                        this.props.handleAutocompleteSave(value.value, "termsOfShipment", false, 0)
                                    }
                                }
                            }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="Terms Of Shipment"
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    name="termsOfShipment"
                                    required
                                />
                            }
                        />
                    </div>
                    :
                    null
                }
            </div>
            <div style={{marginLeft: "20px"}}>
                <Typography variant="subtitle1" gutterBottom component="div" className="form-header">
                    {COMMODITY_TITLE}
                    <Tooltip title={ADD_COMMODITY_TOOLTIP} onClick={() => this.props.addCommodity(this.props?.shipmentId, this.props.packageId)}>
                        <IconButton style={{ float: 'right' }}>
                            <AddIcon/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={DELETE_COMMODITY} onClick={() => {this.props.romoveCommodity(this.state.filteredCommodities[this.state.value].idx)
                                                                        let index = this.state.value
                                                                        if (index !== 0) this.setState({value: index-1})}}>
                        <IconButton disabled={this.state.filteredCommodities.length <= 0} style={{ float: 'right' }}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                    <span style={{ float: 'right', textAlign: 'right', fontSize: '12px' }}></span>
                </Typography>
                {this.state.filteredCommodities !== null && this.state.filteredCommodities !== undefined && this.state.filteredCommodities.length > 0 ?
                    this.filterCommodities().length > 0 ?
                        <Tabs
                            value={this.state.value}
                            onChange={this.handleTabChange}
                            width={"25%"}
                            style={{ maxWidth: '710px', marginLeft: '10px' }}
                        >
                            {/*{this.state.filteredCommodities.filter(commodity => commodity.packageId === this.props.packageId)*/}
                            {

                                this.filterCommodities().map((tab, index) => (
                                    <Tab
                                        label={index + 1}
                                        className={this.state.value !== index ? "package-tab-not-selected" : "package-tab"}
                                    />
                                ))
                            }
                        </Tabs>
                        : null
                    : null
                }
            </div>
            <div className={"commodity-item-detail-border"}>
                <div className={"input-form"}>

                {this.props.commodityEditing ?
                    <div className="commodity-loading-box">
                        <CircularProgress size={100} />
                    </div>
                    :
                    <div className="input-form-commodity">
                        {this.state.filteredCommodities !== null && this.state.filteredCommodities !== undefined && this.state.filteredCommodities.length > 0?

                            <div className="row-spec">
                                <div>
                                    <div className="row-spec">
                                        <TextField
                                            required
                                            className="input-spec-extra-commodity-two-col"
                                            variant="outlined"
                                            name="description"
                                            label="Description"
                                            InputLabelProps={{ shrink: true }}
                                            value={this.state.filteredCommodities[this.state.value].description}
                                            onFocus={this.props.handleFocus}
                                            onChange={(e) => this.props.handleChange(e.target.value, e.target.name, false, this.state.filteredCommodities[this.state.value].idx)}
                                            onBlur={(e) => this.props.isCreateShip ? null : this.props.handleSave(e, false, this.state.filteredCommodities[this.state.value].idx)}
                                        />
                                    </div>
                                    <div className="row-spec">
                                        <TextField
                                            className="input-spec-extra-commodity"
                                            variant="outlined"
                                            name="harmonizedCode"
                                            label="Commodity Code"
                                            InputLabelProps={{ shrink: true }}
                                            value={this.state.filteredCommodities[this.state.value].harmonizedCode}
                                            onFocus={this.props.handleFocus}
                                            onChange={(e) => this.props.handleChange(e.target.value, e.target.name, false, this.state.filteredCommodities[this.state.value].idx)}
                                            onBlur={(e) => this.props.isCreateShip ? null : this.props.handleSave(e, false, this.state.filteredCommodities[this.state.value].idx)}
                                        />
                                        <Autocomplete
                                            options={COUNTRY_CODE_OPTIONS}
                                            getOptionLabel={(option) => option.label}
                                            className="input-spec-commodity-half-col"
                                            disableClearable
                                            value={!this.state.orderInfo ? {} : COUNTRY_CODE_OPTIONS.find((option) => { return option.value === this.state.filteredCommodities[this.state.value].countryOfManufacture })}
                                            onChange={(event, value) => {
                                                if (value == null || value == undefined) {
                                                    this.props.handleAutocompleteChange(null, "countryOfManufacture", false, this.state.filteredCommodities[this.state.value].idx)
                                                    if (!this.props.isCreateShip) {
                                                        this.props.handleAutocompleteSave(null, "countryOfManufacture", false, this.state.filteredCommodities[this.state.value].idx)
                                                    }
                                                } else {
                                                    this.props.handleAutocompleteChange(value.value, "countryOfManufacture", false, this.state.filteredCommodities[this.state.value].idx)
                                                    if (!this.props.isCreateShip) {
                                                        this.props.handleAutocompleteSave(value.value, "countryOfManufacture", false, this.state.filteredCommodities[this.state.value].idx)
                                                    }
                                                }
                                            }}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    label="Origin"
                                                    InputLabelProps={{ shrink: true }}
                                                    variant="outlined"
                                                    name="countryOfManufacture"
                                                    required
                                                />
                                            }
                                        />
                                        <TextField
                                            required
                                            className="input-spec-commodity-half-col"
                                            variant="outlined"
                                            name="quantity"
                                            value={this.state.filteredCommodities[this.state.value].quantity}
                                            label="Quantity"
                                            type="number"
                                            InputLabelProps={{ shrink: true }}
                                            onFocus={(e) => e.target.select()}
                                            onChange={(e) => this.props.handleChange(e.target.value, e.target.name, false, this.state.filteredCommodities[this.state.value].idx)}
                                            onBlur={(e) => this.props.isCreateShip ? null : this.props.handleSave(e, false, this.state.filteredCommodities[this.state.value].idx)}
                                        />
                                        <Autocomplete
                                            options={QUANTITY_UNIT_OPTIONS}
                                            getOptionLabel={(option) => option.label}
                                            className="input-spec-commodity-half-col"
                                            disableClearable
                                            value={!this.state.orderInfo ? {} : QUANTITY_UNIT_OPTIONS.find((option) => { return option.value === this.state.filteredCommodities[this.state.value].quantityUnit })}
                                            onChange={(event, value) => {
                                                if (value == null || value == undefined) {
                                                    this.props.handleAutocompleteChange(null, "quantityUnit", false, this.state.filteredCommodities[this.state.value].idx)
                                                    if (!this.props.isCreateShip) {
                                                        this.props.handleAutocompleteSave(null, "quantityUnit", false, this.state.filteredCommodities[this.state.value].idx)
                                                    }
                                                } else {
                                                    this.props.handleAutocompleteChange(value.value, "quantityUnit", false, this.state.filteredCommodities[this.state.value].idx)
                                                    if (!this.props.isCreateShip) {
                                                        this.props.handleAutocompleteSave(value.value, "quantityUnit", false, this.state.filteredCommodities[this.state.value].idx)
                                                    }
                                                }
                                            }}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    label="Quantity Unit"
                                                    InputLabelProps={{ shrink: true }}
                                                    variant="outlined"
                                                    name="quantityUnit"
                                                    required
                                                />
                                            }
                                        />
                                    </div>
                                    <div className="row-spec">
                                        <TextField
                                            required
                                            className="input-spec-commodity-first-col"
                                            variant="outlined"
                                            name="weight"
                                            value={this.state.filteredCommodities[this.state.value].weight}
                                            label="Weight"
                                            type="number"
                                            InputLabelProps={{ shrink: true }}
                                            onFocus={(e) => e.target.select()}
                                            onChange={(e) => this.props.handleChange(e.target.value, e.target.name, false, this.state.filteredCommodities[this.state.value].idx)}
                                            onBlur={(e) => this.props.isCreateShip ? null : this.props.handleSave(e, false, this.state.filteredCommodities[this.state.value].idx)}
                                        />
                                        <Autocomplete
                                            options={WEIGHT_UNIT_OPTIONS}
                                            getOptionLabel={(option) => option.label}
                                            className="input-spec-commodity-half-col"
                                            disableClearable
                                            value={!this.state.orderInfo ? {} : WEIGHT_UNIT_OPTIONS.find((option) => { return option.value === this.state.filteredCommodities[this.state.value].weightUnit })}
                                            onChange={(event, value) => {
                                                if (value == null || value == undefined) {
                                                    this.props.handleAutocompleteChange(null, "weightUnit", false, this.state.filteredCommodities[this.state.value].idx)
                                                    if (!this.props.isCreateShip) {
                                                        this.props.handleAutocompleteSave(null, "weightUnit", false, this.state.filteredCommodities[this.state.value].idx)
                                                    }
                                                } else {
                                                    this.props.handleAutocompleteChange(value.value, "weightUnit", false, this.state.filteredCommodities[this.state.value].idx)
                                                    if (!this.props.isCreateShip) {
                                                        this.props.handleAutocompleteSave(value.value, "weightUnit", false, this.state.filteredCommodities[this.state.value].idx)
                                                    }
                                                }
                                            }}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    label="Weight Unit"
                                                    InputLabelProps={{ shrink: true }}
                                                    variant="outlined"
                                                    name="weightUnit"
                                                    required
                                                />
                                            }
                                        />
                                        <TextField
                                            required
                                            className="input-spec-commodity-half-col"
                                            variant="outlined"
                                            name="unitPrice"
                                            value={this.state.filteredCommodities[this.state.value].unitPrice}
                                            label="Unit Price"
                                            type="number"
                                            InputLabelProps={{ shrink: true }}
                                            onFocus={(e) => e.target.select()}
                                            onChange={(e) => this.props.handleChange(e.target.value, e.target.name, false, this.state.filteredCommodities[this.state.value].idx)}
                                            onBlur={(e) => this.props.isCreateShip ? null : this.props.handleSave(e, false, this.state.filteredCommodities[this.state.value].idx)}
                                        />
                                        <Autocomplete
                                            options={CURRENCY_OPTIONS}
                                            getOptionLabel={(option) => option.label}
                                            className="input-spec-commodity-half-col"
                                            disableClearable
                                            value={!this.state.orderInfo ? {} : CURRENCY_OPTIONS.find((option) => { return option.value === this.state.filteredCommodities[this.state.value].currency })}
                                            onChange={(event, value) => {
                                                if (value == null || value == undefined) {
                                                    this.props.handleAutocompleteChange(null, "currency", false, this.state.filteredCommodities[this.state.value].idx)
                                                    if (!this.props.isCreateShip) {
                                                        this.props.handleAutocompleteSave(null, "currency", false, this.state.filteredCommodities[this.state.value].idx)
                                                    }
                                                } else {
                                                    this.props.handleAutocompleteChange(value.value, "currency", false, this.state.filteredCommodities[this.state.value].idx)
                                                    if (!this.props.isCreateShip) {
                                                        this.props.handleAutocompleteSave(value.value, "currency", false, this.state.filteredCommodities[this.state.value].idx)
                                                    }
                                                }
                                            }}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    label="Currency"
                                                    InputLabelProps={{ shrink: true }}
                                                    variant="outlined"
                                                    name="currency"
                                                    required
                                                />
                                            }
                                        />
                                        <TextField
                                            required
                                            disabled
                                            className="input-spec-commodity-half-col"
                                            variant="outlined"
                                            name="numberOfPieces"
                                            value={this.state.filteredCommodities[this.state.value].numberOfPieces}
                                            label="Number Of Pieces"
                                            type="number"
                                            InputLabelProps={{ shrink: true }}
                                            onFocus={(e) => e.target.select()}
                                            onChange={(e) => this.props.handleChange(e.target.value, e.target.name, false, this.state.filteredCommodities[this.state.value].idx)}
                                            onBlur={(e) => this.props.isCreateShip ? null : this.props.handleSave(e, false, this.state.filteredCommodities[this.state.value].idx)}
                                        />
                                    </div>
                                    <div className="row-spec">
                                        <TextField
                                            required
                                            disabled
                                            className="input-spec-commodity-first-col"
                                            variant="outlined"
                                            name="customsValue"
                                            value={this.state.filteredCommodities[this.state.value].customsValue}
                                            label="Total Price"
                                            InputLabelProps={{ shrink: true }}
                                            onFocus={this.props.handleFocus}
                                            onChange={(e) => this.props.handleChange(e.target.value, e.target.name, false, this.state.filteredCommodities[this.state.value].idx)}
                                            onBlur={(e) => this.props.isCreateShip ? null : this.props.handleSave(e, false, this.state.filteredCommodities[this.state.value].idx)}
                                        />
                                        <TextField
                                            required
                                            disabled
                                            className="input-spec-commodity-half-col"
                                            variant="outlined"
                                            name="totalWeight"
                                            value={Math.round((parseFloat(this.state.filteredCommodities[this.state.value].weight) * parseFloat(this.state.filteredCommodities[this.state.value].quantity)) * 100) / 100}
                                            label="Total Weight"
                                            type="number"
                                            InputLabelProps={{ shrink: true }}
                                            onFocus={(e) => e.target.select()}
                                            onChange={(e) => this.props.handleChange(e.target.value, e.target.name, false, this.state.filteredCommodities[this.state.value].idx)}
                                            onBlur={(e) => this.props.isCreateShip ? null : this.props.handleSave(e, false, this.state.filteredCommodities[this.state.value].idx)}
                                        />
                                        <TextField
                                            required
                                            className="input-spec-commodity-half-col"
                                            variant="outlined"
                                            name="tax"
                                            value={this.state.filteredCommodities[this.state.value].tax}
                                            label="Tax"
                                            type="number"
                                            InputLabelProps={{ shrink: true }}
                                            onFocus={(e) => e.target.select()}
                                            onChange={(e) => this.props.handleChange(e.target.value, e.target.name, false, this.state.filteredCommodities[this.state.value].idx)}
                                            onBlur={(e) => this.props.isCreateShip ? null : this.props.handleSave(e, false, this.state.filteredCommodities[this.state.value].idx)}
                                        />
                                        <TextField
                                            disabled
                                            className="input-spec-commodity-half-col"
                                            variant="outlined"
                                            name="packageId"
                                            value={this.state.filteredCommodities[this.state.value].packageId ?? this.state.value + 1}
                                            label="Linked Package"
                                            type="number"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </div>
                                </div>

                            </div> : ""}
                    </div>

                } </div>
            </div>
        </Box>);
    }

}

export default ShipmentFormInternationalDialogue