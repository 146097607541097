export const HEAD_CELLS = [
    { id: 'tag', numeric: false, disablePadding: true, label: 'Tag' },
    { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
    { id: 'address', numeric: false, disablePadding: false, label: 'Address' }, // do not change 'address' key or sorting will go wonky
    { id: 'state', numeric: false, disablePadding: false, label: 'State' },
    { id: 'postal', numeric: false, disablePadding: false, label: 'Postal Code' },
    { id: 'marketplace', numeric: false, disablePadding: false, label: 'Marketplace' },
    { id: 'sku', numeric: false, disablePadding: false, label: 'SKU' },
    { id: 'shipfrom', numeric: false, disablePadding: false, label: 'Ship From' },
    { id: 'shipmethod', numeric: false, disablePadding: false, label: 'Ship Method' },
    { id: 'rate', numeric: false, disablePadding: false, label: 'Rate' },
    { id: 'carrier', numeric: false, disablePadding: false, label: 'Carrier' },
    { id: 'orderid', numeric: false, disablePadding: false, label: 'Order ID' },
];

export const RULE_DIALOG_HEAD_CELLS = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Rule Name' },
    { id: 'group', numeric: false, disablePadding: false, label: 'Group Name' },
];

export const RULE_DIALOG_ORDER_BY = "rule";

export const FROM_DATE_DAYS_BEFORE_CURRENT_DATE = 15;

export const EXPAND_HEAD_CELL = {
    id: 'expand',
    numeric: false,
    disablePadding: false,
    label: 'Expand'
}

export const COMPTACT_LABEL = "Compact";
export const SHOW_IMCOMPLETE = "Show Incomplete"
export const TABLE_DISPLAY_LABEL = "Customize";
export const RESET = "Reset";

export const SHIPMENTS_PER_PAGE = "Shipments per page";
export const LOGS_PER_PAGE = "Logs per page";
export const PENDING_SHIPMENTS = "Pending shipments";
export const SHIPPED_SHIPMENTS = "Shipped Shipments";
export const CANCELLED_SHIPMENTS = "Cancelled Shipments";
export const RETURN_SHIPMENTS = "Return / Redirect Shipments";
export const CLONED_SHIPMENTS = "Cloned Shipments";
export const FILTER = "Filter";
export const FILTER_DIALOG_TITLE = "Filters";
export const DELETE_SELECTED = "Delete selected";

export const RATE_TITLE = "Rate Shipment";
export const RATE_SUCCESS = "Rate Success";
export const RATE_FAILED = "Failed to rate shipment: ";
export const CARRIER_MISSING_ERROR = "Please select a Carrier.";
export const RATE_DESCRIPTION = "Shipping to";
export const PACKAGES = "Package(s): ";

export const DEFAULT_SORT_BY_HEAD_CELL = 'tag';

export const DEFAULT_ROWS_PER_PAGE = 50;

export const ORDER_ID = "Order ID";
export const TAG = "Tag";
export const BRAND = "Brand";
export const NAME = "Name";
export const ADDRESS = "Address";
export const CITY = "City";
export const STATE = "Province / State";
export const COUNTRY = "Country";
export const SHORT_CODE = "Short Code";
export const SKU = "SKU"
export const MARKETPLACE = "Marketplace";
export const SHIP_FROM = "Ship From";
export const SHIP_METHOD = "Ship Method";
export const CARRIER = "Carrier";

export const CANADA = "Canada";
export const UNITED_STATES = "United States";
export const CLEAR_FILTERS = "Clear filters";

export const RULE_DIALOG_TITLE = "Run Rules";
export const RULE_DIALOG_SUBTITLE = "Select rules to apply";
export const RUN_RULES = "Apply";
export const CANCEL_RUN_RULES = "Cancel";

export const CREATE_ORDER_TITLE = "Create Shipment";
export const IMPORT_ORDER_TITLE = "Import Shipment";
export const CREATE_ORDER_SUBTITLE = "Create an order to be shipped";
export const CREATE_ORDER = "Create";
export const CANCEL_CREATE_ORDER = "Cancel"
export const MISSING_FIELDS = "Missing required fields.";
export const CREATE_ORDER_SUCCESS = "Order successfully created and added to pending shipments.";
export const CREATE_RETURN_SUCCESS = "Order(s) successfully created and added to return / redirect shipments.";
export const CREATE_CLONED_SUCCESS = "Order(s) successfully created and added to cloned shipments.";
export const CREATE_ORDER_ERROR = "Error creating order. Please try again.";
export const BACK_TO_MENU_TITLE = "Back to menu";
export const REFRESH = "Refresh";
export const GOOGLE_MAPS_TITLE = "Search on Google Maps"
export const DISPLAY_EVENT_LOG_MESSAGES = "Show system event log messages"

export const SHIPMENT_FORM_TITLE = "From";
export const SHIPMENT_FORM_TO = "To";

export const INTERNATIONAL_TITLE = "International";
export const COMMODITY_TITLE = "Commodity";
export const COMMERCIAL_INVOICE = "Commercial Invoice";

export const ORDER_INFORMATION_TITLE = "Order Information";

export const SAVING = "info";
export const SUCCESS = "success";
export const WARNING = "warning";
export const SAVING_MESSAGE = "Saving your changes...";
export const SUCCESS_MESSAGE = "Changes successfully saved!"
export const LOADING_MESSAGE = "Loading data..."

export const WIDTH = "width";
export const HEIGHT = "height";
export const LENGTH = "length";

export const CANCEL_ORDERS = "Cancel";
export const DELETE_SHIPMENTS = "Delete";
export const ADD_TAG = "Add Tag"
export const SHIP_ORDERS = "Ship";
export const MARK_AS_SHIPPED = "Mark as Shipped";
export const MARK_AS_CANCELLED = "Mark as Cancelled";
export const MARK_AS_REDIRECTED = "Mark as Redirected";
export const APPLY_RULES = "Run Rules";
export const VOID_ORDERS = "Void Orders";
export const MARK_AS_UNSHIPPED = "Mark as Unshipped";
export const MARK_AS_UNCANCELLED = "Mark as Uncancelled"
export const REPRINT_LABELS = "Reprint Labels";
export const ARCHIVE_LABELS = "Archive Labels";
export const CREATE_RETURN_SHIPMENT = "Create Return / Redirect Shipment";
export const RETURN_SHIPMENT = "Return / Redirect Shipment";
export const CREATE_CLONE_SHIPMENT = "Create Clone Shipment";
export const CLONED_SHIPMENT = "Cloned Shipment";
export const PRINT_TO_PDF = "Print to PDF";
export const APPLY_DEFAULT_SERVICE = "Rate";
export const APPLY_CHEAPEST_SERVICE = "Rate and Apply Cheapest Service";
export const RATE_AND_COMPARE = "Rate and Compare";
export const BUY_SHIPPING_SERVICES = "Buy Shipping Services"
export const VALIDATE_ADDRESS = "Validate Address";

export const ERROR = "error";
export const ORDERS_CANCELLED_SUCCESSFULLY = "Order(s) cancelled successfully.";
export const CANCEL_ORDER_REQUEST_ERROR = "Error cancelling orders, please try again";
export const CANCEL_ORDER_ERROR = "Cannot cancel orders from third party marketplaces. Try again.";

export const FEDEX = "FedEx";

export const DELETE_REDIRECT_ORDER_ID = "Delete Redirect Order ID"
export const ADD_REDIRECT_ORDER_ID = "Add Redirect Order ID"

export const SAVE = "Save";
export const CANCEL = "Cancel";
export const EDIT = "Edit";
export const NOTES = "notes";

export const DELETE_COMMODITY = "Delete Commodity"
export const ADD_COMMODITY_TOOLTIP = "Add Commodity"

export const UNASSIGNED_LOCATION_NAME = "Unassigned"


// Fake accounts for testing
export const TEST_ACCOUNTS = {
    ACCOUNT1: {
        id: 1233213,
        name: "FEDEX, EXPONET CANADA, TORONTO",
    },
    ACCOUNT2: {
        id: 1233214,
        name: "CANPAR, EXPONET CANADA, TORONTO"
    },
    ACCOUNT3: {
        id: 1233215,
        name: "FEDEX, EXPONET CANADA, HOUSTON",
    },
    ACCOUNT4: {
        id: 1233216,
        name: "CANPAR, EXPONET CANADA, HOUSTON"
    }
}

// for testing address validation purposes
export const TEST_WRONG_ADDRESS = {country: "CA", residential: "false", province: "ON", city: "Mississauga", addressLine1: "123 Pla Road", postalCode: 'L9T4V1'};
export const TEST_CORRECT_ADDRESS = {country: "CA", residential: "false", province: "ON", city: "Mississauga", addressLine1: "5035 Timberlea Blvd", postalCode: 'L4W2W9'};

export const WEIGHT_UNIT_OPTIONS = [
    {
        label: "LB",
        value: "LB"
    },
    {
        label: "KG",
        value: "KG"
    },
]

export const QUANTITY_UNIT_OPTIONS = [
    {
        label: "EA",
        value: "EA"
    },
    {
        label: "DZ",
        value: "DZ"
    },
]

export const COUNTRY_OPTIONS = [
    CANADA,
    UNITED_STATES,
]

export const COUNTRY_CODE_OPTIONS = [
    {
        label: "CA",
        value: "CA"
    },
    {
        label: "CN",
        value: "CN"
    },
    {
        label: "MX",
        value: "MX"
    },
    {
        label: "US",
        value: "US"
    },
]

export const CURRENCY_OPTIONS = [
    {
        label: "CAD",
        value: "CAD"
    },
    {
        label: "USD",
        value: "USD"
    },
]

export const DUTIES_PAYMENT_TYPE_OPTIONS = [
    {
        label: "Sender",
        value: "SENDER"
    },
    {
        label: "Recipient",
        value: "RECIPIENT"
    },
    {
        label: "Third Party",
        value: "THIRD_PARTY"
    },
]

export const REASON_FOR_EXPORT_OPTIONS = [
    {
        label: "Sold",
        value: "SOLD"
    },
    {
        label: "Gift",
        value: "GIFT"
    },
    {
        label: "Not Sold",
        value: "NOT_SOLD"
    },
    {
        label: "Personal Effects",
        value: "PERSONAL_EFFECTS"
    },
    {
        label: "Repair and Return",
        value: "REPAIR_AND_RETURN"
    },
    {
        label: "Sample",
        value: "SAMPLE"
    },
]

export const TERM_OF_SHIPMENT_OPTIONS = [
    {
        label: "None",
        value: null
    },
    {
        label: "CFR-Cost and Freight",
        value: "CFR"
    },
    {
        label: "CPT-Carriage Paid To",
        value: "CPT"
    },
    {
        label: "CIF-Cost Insurance and Freight",
        value: "CIF"
    },
    {
        label: "CIP- Carriage and Insurance Paid",
        value: "CIP"
    },
    {
        label: "DDP- Delivery Duty Paid",
        value: "DDP"
    },
    {
        label: "DDU- Delivery Duty Unpaid",
        value: "DDU"
    },
    {
        label: "EXW- Ex Works",
        value: "EXW"
    },
    {
        label: "FCA- Free Carrier",
        value: "FCA"
    },
    {
        label: "FOB- Free On Board",
        value: "FOB"
    },
    {
        label: "FAS-Free Alongside Ship",
        value: "FAS"
    },
    {
        label: "DEQ-Delivered Ex Quay",
        value: "DEQ"
    },
    {
        label: "DES- Delivered Ex Ship",
        value: "DES"
    },
    {
        label: "DAF- Delivered at Frontier",
        value: "DAF"
    },
    {
        label: "DAP- Delivered at Place",
        value: "DAP"
    },
    {
        label: "DAT- Delivered at Terminal",
        value: "DAT"
    },
]

// Shipment Color Palette
export const SHIPMENT_READY_COLOR = '#acd685'
export const SHIPMENT_REDIRECTED_OR_RETURNED_COLOR = '#005e73'
export const SHIPMENT_MARK_SHIPPED_COLOR = '#7ad7f0'
export const ADDRESS_NOT_VALIDATED_COLOR = '#f9e076'
export const SHIPMENT_INFO_MISSING_COLOR = '#f50057'
export const SHIPMENT_CANCELLED_OR_SHIPPED_COLOR = 'gray'

// Address Validation Tooltips
export const ADDRESS_VALIDATION_OPTIONS = {
    NOT_VALIDATED: "Not Validated",
    SYSTEM_VALIDATED: "System Validated",
    CARRIER_VALIDATED: "Carrier Validated",
}