import {
    SAVE_FULL_TRUCKLOAD,
    FETCH_FULL_TRUCKLOADS,
    STORE_FULL_TRUCKLOAD,
    UPDATE_FULL_TRUCKLOAD,
    SAVE_FULL_TRUCKLOAD_SETTINGS,
    STORE_FULL_TRUCKLOAD_SETTINGS,
    FETCH_FULL_TRUCKLOAD_SETTINGS,
    PREVIEW_FULL_TRUCKLOAD_FILE,
    STORE_FULL_TRUCKLOAD_FILE,
    LIST_FULL_TRUCKLOAD_CARRIERS,
    STORE_FULL_TRUCKLOAD_CARRIERS,
    PREVIEW_FULL_TRUCKLOAD_DOCUMENT,
    STORE_FULL_TRUCKLOAD_DOCUMENT,
    LIST_FULL_TRUCKLOAD_BROKERS,
    STORE_FULL_TRUCKLOAD_BROKERS,
    UPLOAD_SIGNED_DOCUMENTS, STORE_FULL_TRUCKLOAD_OPERATIONS_RESPONSE, STORE_FULL_TRUCKLOAD_UNAVAILABLE_ITEMS,
} from "../../constants/fullTruckload";
import {STORE_FULL_TRUCKLOADS_BROKERS} from "../../constants/fullTruckloadBrokers";

export const fetchFullTruckloads = () => ({
    type: FETCH_FULL_TRUCKLOADS,
})

export const saveFullTruckload = (data, newSettings, reset) => ({
    type: SAVE_FULL_TRUCKLOAD,
    data,
    newSettings,
    reset
})

export const updateFullTruckload = (data, newSettings, reset, operationName = undefined) => ({
    type: UPDATE_FULL_TRUCKLOAD,
    data,
    newSettings,
    reset,
    operationName
})

export const uploadSignedDocuments = (data, reset) => ({
    type: UPLOAD_SIGNED_DOCUMENTS,
    data,
    reset
})

export const storeFullTruckloads = (data) => ({
    type: STORE_FULL_TRUCKLOAD,
    data,
})

export const saveFullTruckloadSettings = (data, reset) => ({
    type: SAVE_FULL_TRUCKLOAD_SETTINGS,
    data,
    reset
})

export const storeFullTruckloadSettings = (data) => ({
    type: STORE_FULL_TRUCKLOAD_SETTINGS,
    data,
})

export const fetchFullTruckloadSettings = () => ({
    type: FETCH_FULL_TRUCKLOAD_SETTINGS,
})

export const previewFullTruckloadFile = (previewRequest) => ({
    type: PREVIEW_FULL_TRUCKLOAD_FILE,
    previewRequest
})

export const storeUpdatedFullTruckloadSettingsWithFile = (fullTruckloadSettings, index) => ({
    type: STORE_FULL_TRUCKLOAD_FILE,
    fullTruckloadSettings,
    index
})

export const previewFullTruckloadDocument = (previewRequest) => ({
    type: PREVIEW_FULL_TRUCKLOAD_DOCUMENT,
    previewRequest
})

export const storeUpdatedFullTruckloadWithFile = (data) => ({
    type: STORE_FULL_TRUCKLOAD_DOCUMENT,
    data
})

export const listFullTruckloadCarriers = () => ({
    type: LIST_FULL_TRUCKLOAD_CARRIERS
})

export const storeFullTruckloadCarriers = (data) => ({
    type: STORE_FULL_TRUCKLOAD_CARRIERS,
    data
})

export const listFullTruckloadBrokers = () => ({
    type: LIST_FULL_TRUCKLOAD_BROKERS
})

export const storeFullTruckloadBrokers = (data) => ({
    type: STORE_FULL_TRUCKLOADS_BROKERS,
    data
})

export const storeFullTruckloadOperationsResponse = (message) => ({
    type: STORE_FULL_TRUCKLOAD_OPERATIONS_RESPONSE,
    message
})

export const storeFullTruckloadUnavailableItems = (items) => ({
    type: STORE_FULL_TRUCKLOAD_UNAVAILABLE_ITEMS,
    items
})