import React from "react";
import {DEFAULT_PDF_ZOOM} from "../../global/constants";
import withShipment from "../../../withShipment";
import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";
import {BACK, ZOOM_IN, ZOOM_OUT} from "../../fullTruckload/constants";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import RenderPurchaseOrderPDFPreview from "../../purchaseOrders/RenderPurchaseOrderPDFPreview";
import {CLAIMS_PAGES} from "../constants";
import DeleteIcon from "@material-ui/icons/Delete";
import ClearIcon from "@material-ui/icons/Clear";
import {deleteClaimFile} from "../../../redux/actions/claims";

class ClaimPreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            scale: DEFAULT_PDF_ZOOM,
            isDelete: false
        }
    }

    setZoom(zoom) {
        let newScale = Math.min(Math.max(this.state.scale + zoom, 1), 2)
        this.setState({
            scale: newScale
        })
    }

    render() {
        const additionalMenuItems = !this.state.isDelete
            ? [{ title: "Delete", icon: <DeleteIcon />, onClick: () => this.setState({ isDelete: true }) }]
            : [
                { title: "Cancel", icon: <ClearIcon />, onClick: () => this.setState({ isDelete: false }) },
                { title: "Confirm Delete", icon: <DeleteIcon />, onClick: () => {this.props.deleteClaimFile({
                        displayName: this.props.previewFileName,
                        claimId: this.props.claimId
                }, () => {this.props.setCurrentPage(CLAIMS_PAGES.UPDATE, this.props.claimId)})}}
            ];
        return(
            <React.Fragment>
                <TopToolbar
                    pageName={"Claim File"}
                    menuItems={[
                        {title: BACK, icon: <KeyboardBackspaceIcon/>, onClick: () => {this.props.setCurrentPage(CLAIMS_PAGES.UPDATE, this.props.claimId)}},
                        {title: ZOOM_OUT, icon: <ZoomOutIcon/>, onClick: () => this.setZoom(-0.25)},
                        {title: (this.state.scale * 100) + '%', isNotIcon: true},
                        {title: ZOOM_IN, icon: <ZoomInIcon/>, onClick: () => this.setZoom(0.25)},
                        ...additionalMenuItems
                    ]}
                />

                <RenderPurchaseOrderPDFPreview
                    data={this.props.previewingClaimData}
                    handlePdfLoadSuccess={() => {}}
                    handlePdfLoadError={() => {}}
                    scale={this.state.scale}
                />

            </React.Fragment>
        )
    }
}

const actionCreators = {
    deleteClaimFile
}

export default withShipment({
    actionCreators
}, ClaimPreview);