import { createSelector } from 'reselect';

export const allSupplierInvoicesSelector = createSelector(state => state.invoices && state.invoices.supplierInvoices, supplierInvoices => supplierInvoices)
export const supplierInvoiceChangedSelector = createSelector(state => state.invoices && state.invoices.supplierInvoiceChanged, supplierInvoiceChangedSelector => supplierInvoiceChangedSelector)
export const supplierInvoiceOperationResponseSelector = createSelector(state => state.invoices && state.invoices.operationResponse, supplierInvoiceOperationResponseSelector => supplierInvoiceOperationResponseSelector)
export const supplierInvoicePDFSelector = createSelector(state => state.invoices && state.invoices.supplierInvoicePDF, supplierInvoicePDF => supplierInvoicePDF)
export const supplierInvoicePOSelector = createSelector(state => state.invoices && state.invoices.purchaseOrder, supplierInvoicePO => supplierInvoicePO)

export const supplierInvoiceTemplatesSelector = createSelector(state => state?.invoices?.supplierInvoiceTemplates, supplierInvoiceTemplatesSelector => supplierInvoiceTemplatesSelector)
export const suppliersWithoutTemplateSelector = createSelector(state => state?.invoices?.suppliersWithoutTemplate, suppliersWithoutTemplateSelector => suppliersWithoutTemplateSelector)
export const supplierInvoiceTemplatePDFSelector = createSelector(state => state?.invoices?.supplierInvoiceTemplatePDF, supplierInvoiceTemplatePDFSelector => supplierInvoiceTemplatePDFSelector)

export const carrierInvoiceTemplatesSelector = createSelector(state => state?.invoices?.carrierInvoiceTemplates, carrierInvoiceTemplatesSelector => carrierInvoiceTemplatesSelector)
export const carriersWithoutTemplateSelector = createSelector(state => state?.invoices?.carriersWithoutTemplate, carriersWithoutTemplateSelector => carriersWithoutTemplateSelector)
export const carrierInvoiceTemplatePDFSelector = createSelector(state => state?.invoices?.carrierInvoiceTemplatePDF, carrierInvoiceTemplatePDFSelector => carrierInvoiceTemplatePDFSelector)

export const allCarrierInvoicesSelector = createSelector(state => state?.invoices?.carrierInvoices, allCarrierInvoicesSelector => allCarrierInvoicesSelector)
export const carrierInvoicePDFSelector = createSelector(state => state?.invoices?.carrierInvoicePDF, carrierInvoicePDFSelector => carrierInvoicePDFSelector)
export const processingSelector = createSelector(state => state?.invoices?.processing, processingSelector => processingSelector)

export const allDateFormats = createSelector(state => state?.invoices?.dateFormats, allDateFormats => allDateFormats)

export const invoiceSmartSearchRowsSelector = createSelector(state => state.invoices.invoiceSmartSearchRows, invoiceSmartSearchRowsSelector => invoiceSmartSearchRowsSelector)
export const invoiceItemSmartSearchDetailsSelector = createSelector(state => state.invoices.invoiceItemSmartSearchDetails, invoiceItemSmartSearchDetailsSelector => invoiceItemSmartSearchDetailsSelector)
export const carrierInvoiceOperationResponseSelector = createSelector(state => state.invoices.carrierInvoiceOperationResponse, carrierInvoiceOperationResponseSelector => carrierInvoiceOperationResponseSelector)
export const ftlCarrierInvoicesSelector = createSelector(state => state.invoices.ftlCarrierInvoices, ftlCarrierInvoicesSelector => ftlCarrierInvoicesSelector)