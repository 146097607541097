

// a box to select the user's location, for use at the top of relevant pages

/* Usage instructions:
   listAvailableLocations (in actions/settings) should be run before this component is displayed (such as in componentDidMount() of the parent component)
        this updates the list of locations in the redux store where the user's options are pulled from

   selectedLocation: selectedLocationIdSelector(state) should be added to the parent component's mapStateToProps
        selectedLocation in the parent will then be updated whenever the user selects a location on this dropdown

   changing the location with this selector then changes selectedLocation across the entire app, not just on this page
*/

/*
  Optional Property:
  updateCallback(location) - is called with a location object when a new location is selected

  * Ideally, updateCallback would not be used, it exists only to make this subcomponent easier to apply to existing code


  alternativeValues={ [[an array of strings representing additional options to select]] }
        when an alternative value is selected, the box updates and the callback is called, but user's selected location is not set
  selectedAlternativeValue={ [[object with form {name: "...", shipAddressId: "..."} or null]] }
*/

/* example use:

   <LocationSelectBox/>

*/

// ----

import React from "react";
import withShipment from "../../../../withShipment";

import './LocationSelectBox.css';
import Autocomplete from "@material-ui/lab/Autocomplete";
import {TextField} from "@material-ui/core";
import {availableLocationsSelector} from "../../../../redux/selectors/settings";
import {updateSelectedLocation} from "../../../../redux/actions/auth";
import {selectedLocationIdSelector} from "../../../../redux/selectors/auth";
import PropTypes from "prop-types";
import {UNASSIGNED_LOCATION_NAME} from "../../../shipments/constants";


class LocationSelectBox extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedLocation: this.getSelectedLocation(),

            alternativeValues: this.props.alternativeValues ? this.props.alternativeValues : [],
            availableLocations: this.getAvailableLocations()
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.alternativeValues !== prevProps.alternativeValues ||
            this.props.selectedLocationId !== prevProps.selectedLocationId ||
            this.props.availableLocations !== prevProps.availableLocations) {
            let newLocation = this.getSelectedLocation()
            this.setState({
                selectedLocation: newLocation,
                alternativeValues: this.props.alternativeValues ? this.props.alternativeValues : [],
                availableLocations: this.getAvailableLocations()
            })
        }
    }

    getAvailableLocations = () => {
        let availableLocations = this.props.availableLocations ? this.props.availableLocations : []
        let alternativeValues = this.props.alternativeValues ? this.props.alternativeValues : []
        return availableLocations.filter(location => location.enabled).concat(alternativeValues.map(value => {return {name: value, shipAddressId: value}}))
    }

    getSelectedLocation = () => {
        let newSelectedLocation = this.props.availableLocations.find((value) => {return value.shipAddressId === this.props.selectedLocationId})
        if (newSelectedLocation) {
            return newSelectedLocation
        } else {
            return {}
        }
    }

    render() {
        return (
            <React.Fragment>

                <Autocomplete
                    className={"locationDateFields"}
                    options={this.state.availableLocations}
                    getOptionLabel={(entry) => entry.name}
                    autoHighlight={true}
                    disableClearable
                    onChange={(event, value) => {
                        this.props.updateSelectedLocation(value.shipAddressId)
                        if (this.props.updateCallback)
                            this.props.updateCallback(value)
                    }}
                    blurOnSelect={true}

                    filterOptions={(options, state) => options}
                    value={this.props.selectedLocationId == UNASSIGNED_LOCATION_NAME ? {name: UNASSIGNED_LOCATION_NAME, shipAddressId: UNASSIGNED_LOCATION_NAME} : this.state.selectedLocation}

                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label="Location"
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            name="locationSelect"
                        />
                    }
                />

            </React.Fragment>
        )
    }

}

LocationSelectBox.defaultProps = {

}

LocationSelectBox.propTypes = {
    selectedLocation: PropTypes.object
}

const mapStateToProps = (state) => ({
    availableLocations: availableLocationsSelector(state),
    selectedLocationId: selectedLocationIdSelector(state)
})

const actionCreators = {
    updateSelectedLocation
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, LocationSelectBox);