import {
    createSelector
} from 'reselect';

export const allInventorySelector = createSelector(state => state && state.inventory && state.inventory.allInventory, allInventorySelector => allInventorySelector);
export const inventoryAdjustmentTypesSelector = createSelector(state => state && state.inventory && state.inventory.inventoryAdjustmentTypes, inventoryAdjustmentTypesSelector => inventoryAdjustmentTypesSelector);
export const manufacturersSelector = createSelector(state => state && state.inventory && state.inventory.manufacturers, manufacturersSelector => manufacturersSelector);
export const alertsInventorySelector = createSelector(state => state && state.inventory && state.inventory.alerts, alertsInventorySelector => alertsInventorySelector);
export const boxesInventorySelector = createSelector(state => state && state.inventory && state.inventory.boxesInventory, boxesInventorySelector => boxesInventorySelector);
export const inventorySummaryForFTLSelector = createSelector(state => state && state.inventory && state.inventory.inventorySummaryForFTL, inventorySummaryForFTLSelector => inventorySummaryForFTLSelector);
export const productsInventorySelector = createSelector(state => state && state.inventory && state.inventory.productsInventory, productsInventorySelector => productsInventorySelector);

export const inventoryEntriesSelector = createSelector(state => state && state.inventory && state.inventory.inventoryEntries, inventoryEntriesSelector => inventoryEntriesSelector);


export const storeSelector = createSelector(state => state, storeSelector=> storeSelector);

