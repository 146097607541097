import React from "react";
import FilterTag from "../../../shipments/FilterTag";
import FilterTagsBar from "../../../shipments/FilterTagsBar";


class FiltersTagsArrayBar extends FilterTagsBar {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    createTags = () => {
        if (!this.props.filtersArray)
            return

        let children;
        children = this.props.filtersArray.map((filter, index) => {
            return this.props.filtersIdsArray ?
                <div className="shipment-tag">
                    <FilterTag tag={filter.name}
                               id={filter.name}
                               removeTag={this.props.onClick}
                               name={filter.name + ": " + filter.value}
                               tagTagId={this.props.filtersIdsArray[index]}
                    />
                </div>
                :
                <div className="shipment-tag">
                    <FilterTag tag={filter.name}
                               id={filter.name}
                               removeTag={this.props.onClick}
                               name={filter.name + ": " + filter.value}
                    />
                </div>
        })
        return children;
    }
}

FiltersTagsArrayBar.defaultProps = {
}

FiltersTagsArrayBar.propTypes = {
}

export default FiltersTagsArrayBar;