import React from "react";

import './FilterMatchCategory.css';
import {Box, IconButton, MenuItem, Select, TextField, Tooltip} from "@material-ui/core";
import {FILTER_TYPE_MATCH_CATEGORY_OPTIONS} from "./constants";
import {COPY_FILTER, DELETE_FILTER, DRAG} from "../constants";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import DehazeIcon from "@material-ui/icons/Dehaze";
import PropTypes from "prop-types";
import {Autocomplete} from "@mui/material";
import Chip from "@material-ui/core/Chip";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import {COUNTRY_OPTIONS} from "../ConditionActions/constants";

class FilterMatchCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filterId: "",
            name: "",
            value1: "", // value for the first drop down
            value2: "", // selected values of the second dropdown
            secondaryOptions: []
        }
    }

    componentDidMount = () => {
        this.setState({
            // on component mount, the parent component stores this child's state.
            // We restore it here
            ...this.props.filter,
        });
        this.setState({
            secondaryOptions: this.getSecondaryOptions(),
        });
    };

    getSecondaryOptions = () => {
        return this.props.stateAndProvinceMapping
    }

    handleValue1Change = (event) => {
        const value1 = event.target.value;
        this.setState(
            {
                value1,
            },
            () => {
                this.props.updateFilterWithParent({ ...this.state });
            }
        );
    };

    handleValue2Change = (event, value) => {
        this.setState(
            {
                value2: value?.state,
            },
            () => {
                this.props.updateFilterWithParent({ ...this.state });
            }
        );
    };

    render() {
        return (
            <Box
                className='filterMatchCategory'
                display='flex'
                flexDirection='row'
                justifyContent='left'
                alignItems='center'
            >
                <Box className='filterMatchCategoryName'>{this.state.name}</Box>

                <Select
                    className='filterMatchCategoryOptions'
                    variant='outlined'
                    label={this.state.name}
                    value={this.state.value1}
                    onChange={this.handleValue1Change}
                >
                    {FILTER_TYPE_MATCH_CATEGORY_OPTIONS.map((item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                    ))}
                </Select>
                <Autocomplete
                    className='filterMatchCategoryInput'
                    variant='outlined'
                    size='small'
                    label={this.state.name}
                    onChange={this.handleValue2Change}

                    autoHighlight={true}

                    options={this.state.secondaryOptions}
                    getOptionLabel={(entry) => (entry.state && entry.displayName ? entry.state + " (" + entry.displayName + ")" : "")}
                    value={this.props.stateAndProvinceMapping.find(state => state.state === this.state.value2) ?? []}

                    renderInput={(params) =>
                        <TextField
                            {...params}
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            name={this.state.name}
                            size='small'
                            style={{padding: "2px"}}
                        />

                    }
                />
                <div className='filterBooleanEmptyDiv'>
                    <Tooltip title={COPY_FILTER}>
                        <IconButton
                            aria-label={COPY_FILTER}
                            onClick={() => this.props.copyFilter(this.state.filterId)}>
                            <FileCopyIcon fontSize='small'/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={DELETE_FILTER}>
                        <IconButton
                            aria-label={DELETE_FILTER}
                            onClick={() => this.props.deleteFilter(this.state.filterId)}
                        >
                            <DeleteOutlineIcon variant='outlined' fontSize='small' />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={DRAG}>
                        <IconButton
                            aria-label='drag'
                        >
                            <DehazeIcon variant='outlined' fontSize='small' />
                        </IconButton>
                    </Tooltip>
                </div>
            </Box>
        );
    }
}

FilterMatchCategory.propTypes = {
    filter: PropTypes.object,
    updateFilterWithParent: PropTypes.func,
    deleteFilter: PropTypes.func,
    isEdit: PropTypes.bool,
    copyFilter: PropTypes.func
}

FilterMatchCategory.defaultProps = {
    filter: {},
    updateFilterWithParent: ()=>{},
    deleteFilter: ()=>{},
    isEdit: false,
    copyFilter: ()=>{}
}

export default FilterMatchCategory;