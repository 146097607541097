import React from "react";
import withShipment from "../../../withShipment";
import FullTruckloadBrokersForm from "./FullTruckloadBrokersForm";
import FullTruckloadBrokersTable from "./FullTruckloadBrokersTable";
import {deleteFullTruckloadBroker, fetchFullTruckloadBrokers} from "../../../redux/actions/fullTruckloadBrokers";
import {fullTruckloadBrokersSelector} from "../../../redux/selectors/fullTruckloadBroker";
import {stateAndProvinceMappingSelector} from "../../../redux/selectors/global";
import {getStateAndProvinceMapping} from "../../../redux/actions/global";

class FullTruckloadBrokers extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            addingOrEditing: false,
            selectedRow: null,
            rows: this.props.tableRows,
        }
    }

    componentDidMount() {
        if(!this.state.rows || this.state.rows.length===0)
            this.props.fetchFullTruckloadBrokers()
        this.props.getStateAndProvinceMapping()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.tableRows !== this.props.tableRows){

            this.setState({
                rows: this.props.tableRows
            })
        }
    }

    updateRow = (targetRow) => {
        this.setState(prevState => {
            const updatedRows = prevState.rows.map(row => {
                if (row.brokerId === targetRow.brokerId) {
                    return targetRow;
                }
                // This is not the row we want to update, so we'll leave it as is
                return row;
            });

            // Check if the row was updated
            if (!updatedRows.some(row => row.brokerId === targetRow.brokerId)) {
                // If not, add the new row
                updatedRows.push(targetRow);
            }

            return { rows: updatedRows };
        });
    }

    switchToFormAndTable = (row) => {
        this.setState({
            selectedRow: row?row:null,
            addingOrEditing: !this.state.addingOrEditing
        })
    }

    deleteRow = (broker) => {
        this.props.deleteFullTruckloadBroker(broker?.brokerId)
    }

    displayCurrentPage = () => {
        //shows the brokers form
        if(this.state.addingOrEditing){
            return <React.Fragment>
                <FullTruckloadBrokersForm
                    selectedTab={this.props.selectedTab}
                    handleTabChange={this.props.handleTabChange}
                    setCurrentPage={this.props.setCurrentPage}
                    prevPage={this.props.prevPage}
                    switchToFormAndTable={this.switchToFormAndTable}
                    selectedRow={this.state.selectedRow}
                    updateRow={this.updateRow}
                    stateAndProvinceMapping={this.props.stateAndProvinceMapping}
                />
            </React.Fragment>
        }
        //shows the brokers table
        else{
            return <React.Fragment>
                <FullTruckloadBrokersTable
                    selectedTab={this.props.selectedTab}
                    handleTabChange={this.props.handleTabChange}
                    setCurrentPage={this.props.setCurrentPage}
                    prevPage={this.props.prevPage}
                    switchToFormAndTable={this.switchToFormAndTable}
                    rows={this.state.rows}
                    deleteRow={this.deleteRow}
                />
            </React.Fragment>
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.displayCurrentPage()}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) =>({
    tableRows: fullTruckloadBrokersSelector(state),
    stateAndProvinceMapping: stateAndProvinceMappingSelector(state)
})

const actionCreators = {
    fetchFullTruckloadBrokers,
    deleteFullTruckloadBroker,
    getStateAndProvinceMapping,
}

export default withShipment({
    mapStateToProps,
    actionCreators,
}, FullTruckloadBrokers);