export const EMAIL_TOOLBAR_TITLE = 'Email Templates';

export const EMAIL_TEMPLATE_TYPE_PURCHASE_ORDER = 'Firebolt - Purchase Order';

export const  CREATE_TEMPLATE_TITLE = 'Add a template';

export const EMAIL_TABLE_HEAD_CELLS = [
    { id: 'ending', center: true, disablePadding: false, label: ''},
    { id: 'emailTemplate', center: true, disablePadding: false, label: 'Email Templates'},
    { id: 'sendgridTemplate', center: true, disablePadding: false, label: 'Type'},
    { id: 'ending', center: true, disablePadding: false, label: ''},
]

export const EMAIL_TEMPLATE_TABLE_HEAD_CELLS = [
    { id: 'ending', center: true, disablePadding: false, label: ''},
    { id: 'sendgridTemplate', center: true, disablePadding: false, label: 'Sendgrid Templates'},
    { id: 'ending', center: true, disablePadding: false, label: ''},
]