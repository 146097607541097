


    // this is a currently incomplete feature intended for requesting customer feedback
    // TODO: complete this feature



import React from "react";

// material components
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Paper from "@material-ui/core/Paper";

// custom components
import withShipment from "../../withShipment";
import CustomToolbar from "../global/CustomToolbar";

// constants
import {
    FEEDBACK_REQUEST_TABLE_HEAD_CELLS,
    FEEDBACK_REQUEST_TOOLBAR_TITLE
} from "./constants";
import {returnsSelector} from "../../redux/selectors/returns";
import {Checkbox, Table, TableBody, TableCell, TableContainer, TableRow} from "@material-ui/core";
import GlobalTableHead from "../global/Table/GlobalTableHead";
import {listCustomerMailingList} from "../../redux/actions/customerReviews";

// redux

// styling

class FeedbackRequestTable extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
           customers: [],
            checked: false
        }
    }

    async componentDidMount() {
        this.props.listCustomerMailingList();
        await this.setState({
            customers: [
                {firstName: 'testCustomer1firstname',
                lastName: 'testCustomer1lastName',
                emailAddress: 'haris.waheed2001@gmail.com',
                orderId: '702-123-4567'}
                ,{firstName: 'testCustomer2firstname',
                    lastName: 'testCustomer2lastName',
                    emailAddress: 'haris.waheed2001@gmail.com',
                    orderId: '702-456-2347'},
                {firstName: 'testCustomer3firstname',
                    lastName: 'testCustomer3lastName',
                    emailAddress: 'haris.waheed2001@gmail.com',
                    orderId: '702-153-6243'
                }]
        })
        let checkBoxPush = []
        this.state.customers.forEach(() => {
            checkBoxPush.push(true)
        })
        await this.setState({
            checked: checkBoxPush
        })
    }

    handleCheckBoxChange = async (i, check) => {
        let mutator = [...this.state.checked]
        mutator[i] = !mutator[i]
        await this.setState({
            checked: mutator
        })
    }

    render() {
        return <></> // until the feature is complete, disabling the page
        return (
            <>
                <CustomToolbar
                    title={FEEDBACK_REQUEST_TOOLBAR_TITLE}
                />
                <TableContainer>
                    <Table size='medium'>
                        <GlobalTableHead
                            isCreatePurchaseOrderTable={false}
                            isShipmentTable={false}
                            isOrderTable={false}
                            isInvoiceTable={false}
                            isPurchaseOrderTable={false}
                            isProductCategoryTable={true}
                            numSelected={0}
                            headCells={FEEDBACK_REQUEST_TABLE_HEAD_CELLS}
                            order={this.state.order}
                            orderBy={this.state.orderBy}
                            onRequestSort={this.handleRequestSort}
                        />
                        <TableBody>
                            {(this.state.customers && this.state.checked !== false ? this.state.customers : []).map((row, i) => {
                                return (
                                    <TableRow>
                                        <TableCell align={"center"} className='inputBoxFeedBack'>
                                            <div className='inputBoxFeedBack'>
                                                <div className='inputBoxFeedBack' style={{paddingTop: '5px'}}>
                                                    <Checkbox
                                                        checked={this.state.checked[i]}
                                                        onChange={(e) => this.handleCheckBoxChange(i, e.target.checked)}
                                                    />
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell align={"center"} className='inputBoxFeedBack'>
                                            <div className='inputBoxFeedBack'>
                                                <div className='inputBoxFeedBack' style={{paddingTop: '5px'}}>
                                                    {row.firstName}
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell align={"center"} className='inputBoxFeedBack'>
                                            <div className='inputBoxFeedBack'>
                                                <div className='textBox' style={{paddingTop: '5px'}}>
                                                    {row.lastName}
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell align={"center"} className='inputBoxFeedBack'>
                                            <div className='inputBoxFeedBack'>
                                                <div className='textBox' style={{paddingTop: '5px'}}>
                                                    {row.emailAddress}
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell align={"center"} className='inputBoxFeedBack'>
                                            <div className='inputBoxFeedBack'>
                                                <div className='textBox' style={{paddingTop: '5px'}}>
                                                    {row.orderId}
                                                </div>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    returns: returnsSelector(state),
});

const actionCreators = {
    listCustomerMailingList,
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, FeedbackRequestTable);