import axios from "axios";
import {call, put, takeLatest} from "redux-saga/effects";
import {showErrorMessage, storeStateAndProvinceMapping} from "../../actions/global";
import {STATE_AND_PROVINCE} from "../../../components/global/Server/endpoints";
import {SERVER_FAILED_ERROR_MESSAGE} from "../../../pages/Dashboard/DashboardFinal/constants";
import {GET_STATE_AND_PROVINCE_MAPPING} from "../../constants/global";


// asynchronous call to the backend server
function* postRequest(endpoint, data) {
    return yield axios.post(endpoint, data, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
        .then(response => response)
}

const getRequest = (endpoint) => {
    return axios.get(endpoint, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
        .then(response => response);
}


function* getStateAndProvinceMappingSaga(action){
    if (!action.currentStateAndProvinceMapping || action.currentStateAndProvinceMapping.length <= 0) {
        try{
            const response = yield call(() => postRequest(STATE_AND_PROVINCE.GET_STATE_AND_PROVINCE_MAPPING, {}))
            if(!response.data.success){
                yield put(showErrorMessage(response.data.message));
            } else {
                yield put(storeStateAndProvinceMapping(response.data.stateAndProvinceMapping))
            }
        }catch(e){
            yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE));
        }
    }
}


export default function* globalSaga() {
    yield takeLatest(GET_STATE_AND_PROVINCE_MAPPING, getStateAndProvinceMappingSaga)
}