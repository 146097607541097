import React from 'react';

// import material icons
import {
    Box, Checkbox, FormControlLabel, FormGroup,
    IconButton, MenuItem, Select,
    TextField,
    Tooltip
} from "@material-ui/core";

// import constants
import {
    DELETE_ACTION,
    DRAG,
} from "../constants";
// material icons
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import DehazeIcon from '@material-ui/icons/Dehaze';

// import styling
import './ActionText.css';
import PropTypes from "prop-types";
import {
    CONDITION_ACTIONS,
    PACKAGE_PRE_DEFINED_VALUES,
    PACKAGE_PRE_DEFINED_WARNING,
    SET_NUMBER_OF_PACKAGES,
    SET_PACKAGE_ORDER_ITEM_QUANTITY, SET_PACKAGE_ORDER_ITEM_QUANTITY_SEPARATE_LINE_ITEMS_MESSAGE
} from "./constants";
import styles from "../../settings/ManageCarriers/CreateCarrierDialog.module.css";

class ActionNumericDropdown extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            actionId: "",
            type: "",
            name: "",
            value: "",
            preDefinedValue: "-",
            disableManualValueField: false,
            copyPackages: false,
            preDefinedValues: this.getPredefinedValues()
        }
    }

    componentDidMount() {
        this.setState({
            ...this.props.action
        },
            ()=>{
            if(this.props.isEdit) {
                let copyPackages;
                if (typeof this.state.copyPackages === "string") {
                    copyPackages = this.state.copyPackages.toLowerCase() === "true"
                } else {
                    copyPackages = this.state.copyPackages
                }
                this.setState({
                    value: this.state.value === "null" ? "" : this.state.value,
                    preDefinedValue: this.state.preDefinedValue === "null" ? "-" : this.state.preDefinedValue,
                    disableManualValueField: this.state.preDefinedValue !== "null" && this.state.preDefinedValue !== "-",
                    //Copy packages can be either a bool or string
                    copyPackages: copyPackages
                }, () => {
                    this.props.updateActionWithParent({...this.state});
                })
            }
            })
    }

    getPredefinedValues = () => {
        let preDefinedValues = CONDITION_ACTIONS.find(action => action.name === this.props.action.name)?.predefinedValues
        if (!preDefinedValues) {
            preDefinedValues = PACKAGE_PRE_DEFINED_VALUES
        }
        return preDefinedValues
    }

    handleValueChange = (event) => {
        const value = event.target.value;
        this.setState(
            {
                value,
            },
            () => {
                this.props.updateActionWithParent({ ...this.state });
            }
        );
    }

    handlePredefinedValueChange = (e) => {
        const value = e.target.value
        this.setState({
            preDefinedValue: value,
            disableManualValueField: value !== '-',
            value: value !== '-'? '': this.state.value
        }, () => {
            this.props.updateActionWithParent({ ...this.state })})
    }

    handleChange = () => {
        this.setState({
            copyPackages: !this.state.copyPackages
        }, () => {
            this.props.updateActionWithParent({ ...this.state });
        })
    }

    render() {
        return (
            <Box
                className='actionNumericDropdownField'
                display='flex'
                flexDirection='column'
                justifyContent='left'
                alignItems='center'
            >
                <Box
                    display='flex'
                    flexDirection='row'
                    textAlign='left'
                    alignItems='center'
                    width='100%'
                >
                    <div className="actionTextFieldName">{this.state.name}</div >
                    <div>
                        <TextField
                            className='actionNumericFieldInput'
                            variant='outlined'
                            disabled={this.state.disableManualValueField}
                            type="number"
                            // label={this.state.name.split('Set ')[1]}
                            value={this.state.preDefinedValue === "Split product items"?'':this.state.value}
                            onChange={this.handleValueChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>

                    {!this.state.preDefinedValues || this.state.preDefinedValues.length <= 0 ? <></> :
                        <React.Fragment>
                            <div>
                                OR
                            </div>
                            <Select
                                variant="outlined"
                                name="predefinedValue"
                                onChange={this.handlePredefinedValueChange}
                                value={this.state.preDefinedValue === "null" ? '-' : this.state.preDefinedValue}
                                className='predefined-dropdown'
                            >
                                {this.state.preDefinedValues.map((value, index) => (
                                    <MenuItem value={value} key={index}>{value === '-'? 'Select a predefined value': value}</MenuItem>
                                ))}
                                {this.state.name === 'Set package order item quantity'?<MenuItem value={"Split product items"} key={PACKAGE_PRE_DEFINED_VALUES.length}>Split product items</MenuItem>:null}
                            </Select>
                        </React.Fragment>
                    }


                    {(this.state.preDefinedValue === PACKAGE_PRE_DEFINED_VALUES[1]) && this.props.action.name !== SET_PACKAGE_ORDER_ITEM_QUANTITY ?
                        <div className="package-action-warning">
                            <Tooltip
                                className='infoTooltipIcon'
                                enterTouchDelay={0}
                                leaveTouchDelay={30000}
                                enterDelay={400}
                                title={
                                    <React.Fragment>
                                        {PACKAGE_PRE_DEFINED_WARNING}
                                    </React.Fragment>
                                }
                            >
                                <IconButton aria-label='info'>
                                    <InfoOutlinedIcon variant='outlined' fontSize='small'/>
                                </IconButton>
                            </Tooltip>
                        </div>
                        :null}
                    {(this.state.preDefinedValue === "Separate line items")?
                        <div className="package-action-warning">
                            <Tooltip
                                className='infoTooltipIcon'
                                enterTouchDelay={0}
                                leaveTouchDelay={30000}
                                enterDelay={400}
                                title={
                                    <React.Fragment>
                                        {SET_PACKAGE_ORDER_ITEM_QUANTITY_SEPARATE_LINE_ITEMS_MESSAGE}
                                    </React.Fragment>
                                }
                            >
                                <IconButton aria-label='info'>
                                    <InfoOutlinedIcon variant='outlined' fontSize='small'/>
                                </IconButton>
                            </Tooltip>
                        </div>
                        :null}

                    {/* in order to make sure that the delete icon aligns, we add an empty component */}
                    <div className='actionTextEmptyDiv'>
                        <Tooltip title={DELETE_ACTION}>
                            <IconButton
                                aria-label={DELETE_ACTION}
                                onClick={() => this.props.deleteAction(this.state.actionId)}
                            >
                                <DeleteOutlineIcon variant='outlined' fontSize='small' />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={DRAG}>
                            <IconButton
                                aria-label='drag'
                            >
                                <DehazeIcon variant='outlined' fontSize='small' />
                            </IconButton>
                        </Tooltip>
                    </div>
                </Box>
                {this.state.name === SET_NUMBER_OF_PACKAGES ?
                    <Box
                        textAlign='left'
                        width='100%'
                        style={{marginLeft: 50}}
                    >
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.copyPackages} onChange={this.handleChange}
                                                   name="value"/>}
                                label="Copy existing package details?"
                            />
                        </FormGroup>
                    </Box> : null}
                {this.state.name === "Set package order item quantity" && this.state.preDefinedValue === "Split product items"?
                    <Box
                        textAlign='left'
                        width='100%'
                        style={{marginLeft: 398}}
                    >
                        <TextField
                            className='actionNumericFieldInput'
                            variant='outlined'
                            type="number"
                            label={"product item quantity"}
                            value={this.state.value}
                            onChange={this.handleValueChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Box> : null}
            </Box>
        );
    }

}

ActionNumericDropdown.propTypes = {
    action: PropTypes.array,
    updateActionWithParent: PropTypes.func,
    deleteAction: PropTypes.func,
    isEdit: PropTypes.bool
};

ActionNumericDropdown.defaultProps = {
    action: {},
    updateActionWithParent: ()=>{},
    deleteAction: ()=>{},
    isEdit: true
}

export default ActionNumericDropdown;