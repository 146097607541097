import React, {useState, useEffect} from 'react';

// custom components
import GlobalTableHead from "../../global/Table/GlobalTableHead";

// material components
import {
    Typography,
    Button,
    Select,
    MenuItem,
    TextField,
    Divider, Tooltip, IconButton
} from "@material-ui/core";

// styling
import styles from './CompareRatesTable.module.css';

// constants
import {
    CARRIER_SERVICE,
    FEDEX_RATES,
    APPLY,
    RATE,
    COMPARE,
    UNABLE_TO_RATE_THIS_SERVICE,
    ACCOUNT, LOCATION, SERVICE, INBOUND, OUTBOUND
} from "./constants";
import {
    CARRIER_SERVICE_OPTIONS
} from "../../automationrules/ConditionFilters/constants";
import PropTypes from "prop-types";
import PackageDetails from "../packageDetails/PackageDetails";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import {ArrowUpwardRounded} from "@material-ui/icons";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import {availableLocationsSelector} from "../../../redux/selectors/settings";
import withShipment from "../../../withShipment";

function CompareRatesTable(props) {
    const [state, setState] = useState({
        account: {}
    })
    const [rowsToDisplay, setRowsToDisplay] = useState([])
    useEffect(() => { // update rowsToDisplay to contain all rows in rates in props

        let rows = [];
        let taxesRow = null;
        let totalRow = null;
        props.rates.forEach((rateSet) => {
            if (rateSet.rates) {
                rateSet.rates.forEach((rate, rateIndex) => {
                    if (!rows.find((row) => row.name === rate.name)) {
/*
                        rows.push(rate)
*/
                        if (rate.name === 'Taxes') {
                            taxesRow = rate // Store the Taxes row separately
                        } else if (rate.name === 'Total') {
                            totalRow = rate // Store the Total row separately
                        } else {
                            rows.push(rate) // Push other rows
                        }
                    }
                })
            }
        })
        if (taxesRow) {
            rows.push(taxesRow)
        }
        if (totalRow) {
            rows.push(totalRow)
        }
        setRowsToDisplay(rows)

    }, [props.rates])

    const displayRateTypes = () => {
        return (
            <div className={styles.rowColTexts}>
                <p style={{textAlign: 'right', minHeight: '38px'}}>
                    <div className={styles.rowHeaderCol}>{ACCOUNT}</div>
                </p>
                <p style={{textAlign: 'right', minHeight: '38px'}}>
                    <div className={styles.rowHeaderCol}>
                        {LOCATION}
                        {props.isInboundShipment ?
                            <Tooltip title={INBOUND} size={"small"} style={{marginLeft: 2}}>
                                <IconButton aria-label={INBOUND}>
                                    <ArrowDownwardIcon />
                                </IconButton>
                            </Tooltip>
                            :
                            <Tooltip title={OUTBOUND} size={"small"} style={{marginLeft: 2}}>
                                <IconButton aria-label={OUTBOUND}>
                                    <ArrowUpwardIcon />
                                </IconButton>
                            </Tooltip>
                        }
                    </div>
                </p>
                <p style={{textAlign: 'right', minHeight: '38px'}}>
                    <div className={styles.rowHeaderCol}>{SERVICE}</div>
                </p>
                {getRateTypes()}
                <p style={{textAlign: 'right'}}></p>
            </div>
        )
    }

    const getRateTypes = () => {

        return rowsToDisplay.filter(row => row.name !== "").map((row) => {
            return <p style={{textAlign: 'right'}}>{row.name}</p>
        })
    }

    const filterServiceOptions = (carrierName) => {
        if(carrierName){
            let filteredService = CARRIER_SERVICE_OPTIONS.filter(service =>
                service.startsWith(carrierName)
            ).map(service => (
                <MenuItem value={service}>{service}</MenuItem>
            ))
            return filteredService ? filteredService : []
        }
        return CARRIER_SERVICE_OPTIONS.map(service => (
            <MenuItem value={service}>{service}</MenuItem>
        ))
    }

    const handleAccountChange = (e) => {
        const value = e.target.value
        const index = e.target.name
        props.handleAccountChange(value, index)
    }

    const handleServiceChange = (e) => {
        const service = e.target.value
        const index = e.target.name
        props.handleServiceChange(service, index)
    }

    const getEmptyRow = () => {
        var maxRow = 0
        const emptyList = []
        props.rates.forEach(rate => {
            if(rate && rate.rates?.length > maxRow){
                maxRow = rate.rates?.length
            }
        })
        for (let index = 0; index < maxRow; index++) {
            emptyList.push("")
        }
        return emptyList.map(n=>{return <div className={styles.ratesEmptyRow}/>})
    }

    const getFailEmptyRow = () => {
        var maxRow = 0
        const emptyList = []
        props.rates.forEach(rate => {
            if(rate.rates?.length > maxRow){
                maxRow = rate.rates.length
            }
        })
        for (let index = 0; index < maxRow-1; index++) {
            emptyList.push("")
        }
        return emptyList.map(n=>{return <div className={styles.ratesEmptyRow}/>})
    }

    const getCarrierCell = (carrier) => {
        return carrier.name + ", " + carrier.accountNumber + " (" + carrier.alias + ")"
    }

    return (
        <div className={styles.rowRates}>
            {displayRateTypes()}
            {props.ratedServices.map((services, i) => (
                <>
                {i!=0?
                <div className={styles.ratingDivider}>
                    <Divider orientation="vertical"/>
                </div>:null}
                <div className={styles.rowColOptions}>
                    <p style={{textAlign: 'right'}}>
                        <Select
                            variant="outlined"
                            onChange={handleAccountChange}
                            value={props.accounts[i]?.accountNumber}
                            name={i}
                            className="rateServiceSelectBtn"
                            disabled={i==0}
                            style={{minWidth: 220, height: 38, width: "100%"}}
                        >
                            {props.carriers.sort((a, b) => a.name.localeCompare(b.name)).map(carrier => (
                                <MenuItem value={i==0 && props.accounts[i].carrierId == carrier.carrierId ? carrier.accountNumber : carrier}>
                                    {getCarrierCell(carrier)}
                                </MenuItem>
                            ))}
                        </Select>
                    </p>
                    <p style={{textAlign: 'right'}}>
                        <div className="rateServiceText" style={{width: "100%"}}>
                            <TextField
                                variant="outlined"
                                value={props.accounts[i]?.addresses? props.accounts[i]?.addresses[0].name: null}
                                disabled
                                inputProps={{
                                    style:{width: '100%', maxHeight: 38, textAlign:'end'}
                                }}
                                size="small"
                                InputProps={{style:{width: '100%', maxHeight: 38}}}
                                style={{width: "100%"}}
                            />
                        </div>
                    </p>
                    <p style={{textAlign: 'right'}}>
                        <Select
                            variant="outlined"
                            onChange={handleServiceChange}
                            value={services}
                            name={i}
                            disabled={i==0 || !(props.accounts[i]?.accountNumber && props.accounts[i].accountNumber.length > 0)}
                            className="rateServiceSelectBtn"
                            style={{minWidth: 220, height: 38, width: "100%"}}
                        >
                            {filterServiceOptions(props.accounts[i]?.name)}
                        </Select>
                    </p>
                    {props.rates[i] && props.rates[i].rates ? (
                        <>
                            {
                                // Display all rows EXCEPT the ones with value == UNABLE_TO_RATE_THIS_SERVICE or default 0.00
                                rowsToDisplay.map((row) => {

                                    const foundRateObj = props.rates[i].rates.find((rateObj) => row.name!=="" && rateObj.name === row.name);

                                    let value = foundRateObj ? foundRateObj.value :"0.00";

                                    if( row.name==''){
                                        return null
                                    }
                                    // Check for values other than UNABLE_TO_RATE_THIS_SERVICE and 0.00
                                    if (value && value !== UNABLE_TO_RATE_THIS_SERVICE && row.name!=='' ) {
                                        value = "$" + value;
                                        return (
                                            <p style={{ textAlign: 'right' }}>
                                                <b>{value}</b>
                                            </p>
                                        );
                                    }
                                    return null
                                })
                            }
                            {
                                // If there's a row with UNABLE_TO_RATE_THIS_SERVICE, display it at the end
                                rowsToDisplay.some(row => props.rates[i].rates.find((rateObj) => rateObj.name === row.name)?.value == UNABLE_TO_RATE_THIS_SERVICE) && (
                                    <>
                                        <p style={{ textAlign: 'center' }}>
                                            <b>{UNABLE_TO_RATE_THIS_SERVICE}</b>
                                        </p>

                                       {/* {getFailEmptyRow()}*/}
                                    </>
                                )
                            }
                        </>
                    ) : (
                            getEmptyRow()
                        )
                    }
                    <div className={styles.ratingBtns}>
                        <div className={styles.ratingRateBtn}>
                            {i!=0 && services?.length > 0 ?
                                <Button
                                    onClick={()=>props.handleRate(i)}
                                    variant="outlined"
                                >
                                    {RATE}
                                </Button>
                            :null
                            }
                        </div>
                        <div className={styles.ratingApplyBtn}>
                        {props.rates[i] && props.rates[i].rates?.find(rate => rate.name == "Total") && props.rates[i].rates?.find(rate => rate.name == "Total").value ?
                            <Button
                                onClick={() => {
                                    var rateTotal = null
                                    var packageRates =  null
                                    if(props.rates[i]){
                                        let totalObject = props.rates[i].rates?.find(rate => rate.name == "Total")
                                        rateTotal = totalObject.value
                                        packageRates =props.rates[i].packageRates? props.rates[i].packageRates : null
                                    }
                                    props.handleApplyRate(props.accounts[i]?.accountNumber, services, rateTotal, packageRates, props.accounts[i].alias)
                                }}
                                variant="outlined"
                            >
                            {APPLY}
                            </Button>
                        :null
                        }
                        </div>
                    </div>
                </div>
                </>
            ))
            }
            <div className={styles.ratingDivider}>
                    <Divider orientation="vertical"/>
            </div>
            <div className={styles.ratingCompareBox}>
                <Button
                    onClick={props.handleAddService}
                    variant="outlined"
                    className={styles.ratingCompareBtn}
                >
                    {COMPARE}
                </Button>
            </div>
        </div>
    )
}

CompareRatesTable.propTypes = {
    rates: PropTypes.array,
    handleServiceChange: PropTypes.func,
}

CompareRatesTable.defaultProps = {
    rates: []
}

const mapStateToProps = (state) => ({
    availableLocations: availableLocationsSelector(state),
})

const actionCreators = {
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, CompareRatesTable);
