


export const SALES_PIPELINE = "Sales Pipeline"

export const SALES_PIPELINE_HEAD_CELLS = [
    { id: 'category', center: true, disablePadding: false, addDollar: false, rightAlign: false, label: 'Category'},
    { id: 'stage', center: true, disablePadding: false, addDollar: false, rightAlign: false, label: 'Stage'},
    { id: 'companyName', center: true, disablePadding: false, addDollar: false, rightAlign: false, label: 'Company Name'},
    { id: 'contactName', center: true, disablePadding: false, addDollar: false, rightAlign: false, label: 'Contact Name'},
    { id: 'contactPhone', center: true, disablePadding: false, addDollar: false, rightAlign: false, label: 'Phone'},
    { id: 'contactEmail', center: true, disablePadding: false, addDollar: false, rightAlign: false, label: 'Email'},
    { id: 'lastContactDate', center: true, disablePadding: false, addDollar: false, rightAlign: false, label: 'Last Contact Date'}
]


export const SALES_PIPELINE_PAGES = {
    TABLE: "table",
    ADD_SALES_PIPELINE: "add_sales_pipeline",
    ADD_SALES_SETTING: "add_sales_setting",
}

// tooltip names
export const BACK = "Back to Menu"
export const ADD_SALES_PIPELINE = "Add Sales Pipeline"
export const EDIT_SALES_PIPELINE = "Edit Sales Pipeline"

export const SAVE_SALES_PIPELINE = "Save"

export const ROWS_PER_PAGE_LABEL = "Sales pipeline per page"
export const ROWS_PER_PAGE = 50

export const SALES_STAGES_OPTIONS = [
    {name: "Potential Client"},
    {name: "First Contact"},
    {name: "Follow up"},
    {name: "Proposal"},
    {name: "Negotiation"},
    {name: "First Order"},
    {name: "Order Shipped"},
    {name: "Regular Business"},
    {name: "Future Opportunity"},
    {name: "Not Interested"},
]

