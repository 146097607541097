import React from 'react';

// Custom Components
import PDFViewer from "../../../global/PDFViewer";

// Constants
import {INVOICE_TEMPLATE_DATA_FIELDS, INVOICE_TEMPLATE_DATA_SUB_FIELDS} from "./constants";

// Material UI
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ClearIcon from '@material-ui/icons/Clear';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import {FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Checkbox, styled} from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import Slide from "@material-ui/core/Slide"

// Styles
import "./EditInvoiceTemplate.css"
import {CheckBox} from "@material-ui/icons";
import {Autocomplete} from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";

const GreenRadio = withStyles({
    root: {
        color: "#acd685",
        '&$checked': {
            color: "#acd685",
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);

class EditInvoiceTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: props.fields ? props.fields : JSON.parse(JSON.stringify(INVOICE_TEMPLATE_DATA_FIELDS)),
            subFields: props.subFields ? props.subFields : JSON.parse(JSON.stringify(INVOICE_TEMPLATE_DATA_SUB_FIELDS)),
            selectedField: "",
            prevSelectedField: "",
            moveType: {
                name: ""
            },
            label: {
                x: 0,
                y: 0,
                label: "",
                boundingRect: {
                    x: 0,
                    y: 0,
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    width: 0,
                    height: 0
                }
            }
        }
        this.startY = 140
        this.startX = 240
        this.pdfStartY = this.startY
        this.pdfEndY = 932
        this.pdfHeight = 792
        this.pdfWidth = 612
        this.resizeMargin = "3px"
        this.green = "#acd685"
        this.changeState = this.changeState.bind(this)
        this.createSelectBox = this.createSelectBox.bind(this)
        this.resizeSelectBox = this.resizeSelectBox.bind(this)
        this.completeSelectBox = this.completeSelectBox.bind(this)
        this.removeLabel = this.removeLabel.bind(this)
        this.handleSubItemCheck = this.handleSubItemCheck.bind(this)
        this.setAllVisible = this.setAllVisible.bind(this)
    }
    componentDidMount() {
        if (this.props.fieldDefined(this.state.fields.table?.position)) {
            let tableOffset = this.state.fields.table.position.x
            let newFields = this.scaleFields(this.state.fields, this.props.scale)
            let newSubFields = this.state.subFields
            for (const key in this.state.subFields) {
                newSubFields[key].position.x -= tableOffset
            }
            newSubFields = this.scaleFields(newSubFields, this.props.scale)
            this.setState({subFields: newSubFields, fields: newFields})
        }
        if (this.props.isFTLCarrierInvoice) {
            let newFields = this.scaleFields(this.state.fields, this.props.scale)
            this.setState({fields: newFields})
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.scale !== this.props.scale) {
            this.setState({
                fields: this.scaleFields(this.state.fields, this.props.scale / prevProps.scale),
                subFields: this.scaleFields(this.state.subFields, this.props.scale / prevProps.scale)
            })
        }
    }

    scaleFields(fields, scale) {
        let newFields = {...fields}
        for (const key in newFields) {
            fields[key].position.x = fields[key].position.x * scale
            fields[key].position.y = fields[key].position.y * scale
            fields[key].position.w = fields[key].position.w * scale
            fields[key].position.h = fields[key].position.h * scale
        }
        return newFields
    }
    changeState(field, value) {
        if (field === "selectedField" && this.state.selectedField === value) {
            value = ""
        }
        this.setState({ [field]: value })
    }
    createSelectBox(e) {
        e.preventDefault()
        if (this.state.selectedField !== "") {
            let newFields = this.state.fields
            let box = e.target.getBoundingClientRect()
            let posX = Math.round(e.pageX - box.x)
            let posY = Math.round(e.pageY + document.getElementsByClassName("shipment-background")[0].scrollTop - this.pdfStartY)
            newFields[this.state.selectedField].position.x = posX
            newFields[this.state.selectedField].position.y = posY
            newFields[this.state.selectedField].position.w = 0
            newFields[this.state.selectedField].position.h = 0
            this.setState({
                fields: newFields,
                moveType: { name: "selecting", startX: posX, startY: posY, fieldType: "fields" }
            })
        }
    }
    calculatePDFStart() {
        return ((document.documentElement.clientWidth - this.startX - (this.pdfWidth * this.props.scale)) / 2) + 233;
    }
    calculatePDFEnd() {
        return this.calculatePDFStart() + (this.pdfWidth * this.props.scale);
    }
    resizeSelectBox(e) {
        e.preventDefault()
        let pdfStartX = Math.round(this.calculatePDFStart())
        let pdfEndX = Math.round(this.calculatePDFEnd())
        let posX = Math.round(Math.max(Math.min(e.pageX, pdfEndX), pdfStartX) - pdfStartX);
        let posY = Math.round(Math.max(Math.min(e.pageY + document.getElementsByClassName("shipment-background")[0].scrollTop, this.pdfEndY * this.props.scale), this.pdfStartY) - this.pdfStartY);
        let selected = this.state.selectedField
        let type = this.state.moveType
        let newFields = this.state[type.fieldType]
        if (type.name === "selecting") {
            let startX = type.startX
            let startY = type.startY
            if (posX > startX) { // Mouse is to the right of the starting position
                if (selected === "table") {
                    let table = this.state.fields.table.position
                    let diff = (table.x + table.w) - posX;
                    let tempSubFields = this.state.subFields
                    for (let key in tempSubFields) {
                        if (tempSubFields[key].position.x === 0) {
                            tempSubFields[key].position.w = Math.min(tempSubFields[key].position.w, table.w)
                        } else if (tempSubFields[key].position.x + tempSubFields[key].position.w > table.w) {
                            tempSubFields[key].position.x = Math.max(0, tempSubFields[key].position.x - diff)
                        }
                    }
                    this.setState({subFields: tempSubFields})
                }
                newFields[selected].position.w = posX - newFields[selected].position.x
                if (posY > startY) { // Bottom right
                    newFields[selected].position.h = posY - newFields[selected].position.y
                } else { // Top Right
                    newFields[selected].position.y = posY
                    newFields[selected].position.h = startY - posY
                }
            } else { // Mouse is to the left of the starting position
                if (selected === "table") {
                    let table = this.state.fields.table.position
                    let diff = posX - table.x;
                    let tempSubFields = this.state.subFields
                    for (let key in tempSubFields) {
                        if (tempSubFields[key].position.x === 0) {
                            tempSubFields[key].position.w = Math.min(tempSubFields[key].position.w, table.w)
                        } else {
                            tempSubFields[key].position.x = Math.max(0, tempSubFields[key].position.x - diff)
                        }
                    }
                    this.setState({subFields: tempSubFields})
                }
                newFields[selected].position.w = startX - posX
                newFields[selected].position.x = posX
                if (posY > startY) { // Bottom left
                    newFields[selected].position.h = posY - newFields[selected].position.y
                } else { // Bottom right
                    newFields[selected].position.y = posY
                    newFields[selected].position.h = startY - posY
                }
            }
            this.setState({[type.fieldType]: newFields})
        } else if (type.name === "lineMove") {
            let table = this.state.fields.table?.position
            switch (type.type) {
                case "x":
                    if (type.fieldType === "subFields") {
                        posX = Math.max(Math.min(posX, table.x + table.w), table.x) - table.x;
                    } else if (selected === "table") {
                        let diff = posX - table.x;
                        let tempSubFields = this.state.subFields
                        for (let key in tempSubFields) {
                            if (tempSubFields[key].position.x === 0) {
                                tempSubFields[key].position.w = Math.min(tempSubFields[key].position.w, table.w)
                            } else {
                                tempSubFields[key].position.x = Math.max(0, tempSubFields[key].position.x - diff)
                            }
                        }
                        this.setState({subFields: tempSubFields})
                    }
                    if (posX < newFields[selected].position.x + newFields[selected].position.w) {
                        newFields[selected].position.w += newFields[selected].position.x - posX;
                        newFields[selected].position.x = posX;
                    } else {
                        type.type = "w"
                        this.setState({moveType: type})
                    }
                    break;
                case "w":
                    if (type.fieldType === "subFields") {
                        posX = Math.max(Math.min(posX, table.x + table.w), table.x) - table.x;
                    } else if (selected === "table") {
                        let diff = (table.x + table.w) - posX;
                        let tempSubFields = this.state.subFields
                        for (let key in tempSubFields) {
                            if (tempSubFields[key].position.x === 0) {
                                tempSubFields[key].position.w = Math.min(tempSubFields[key].position.w, table.w)
                            } else if (tempSubFields[key].position.x + tempSubFields[key].position.w > table.w) {
                                tempSubFields[key].position.x = Math.max(0, tempSubFields[key].position.x - diff)
                            }
                        }
                        this.setState({subFields: tempSubFields})
                    }
                    if (posX > newFields[selected].position.x) {
                        newFields[selected].position.w = posX - newFields[selected].position.x;
                    } else {
                        type.type = "x"
                        this.setState({moveType: type})
                    }
                    break;
                case "y":
                    if (posY < newFields[selected].position.y + newFields[selected].position.h) {
                        newFields[selected].position.h += newFields[selected].position.y - posY;
                        newFields[selected].position.y = posY;
                    } else {
                        type.type = "h"
                        this.setState({moveType: type})
                    }
                    break;
                case "h":
                    if (posY > newFields[selected].position.y) {
                        newFields[selected].position.h = posY - newFields[selected].position.y;
                    } else {
                        type.type = "y"
                        this.setState({moveType: type})
                    }
                    break;
            }
            this.setState({[type.fieldType]: newFields})
        } else if (type.name === "boxMove") {
            let boundEndX = this.pdfWidth * this.props.scale
            if (type.fieldType === "subFields") {
                let table = this.state.fields.table.position
                posX -= table.x
                boundEndX = table.w
            }
            let val = Math.max(posX - type.offsetX, 0)
            if (val + newFields[selected].position.w > boundEndX) {
                val = boundEndX - newFields[selected].position.w
            }
            newFields[selected].position.x = val
            if (type.offsetY) {
                val = Math.max(posY - type.offsetY, 0)
                if (val + newFields[selected].position.h > this.pdfHeight * this.props.scale) {
                    val = (this.pdfHeight * this.props.scale) - newFields[selected].position.h
                }
                newFields[selected].position.y = val
            }
            this.setState({[type.fieldType]: newFields})
        }
    }
    completeSelectBox(e) {
        e.preventDefault()
        if (this.state.moveType.name !== "") {
            this.setState({moveType: {name: ""}, selectedField: this.state.prevSelectedField, prevSelectedField: ""})
            this.props.updateParentFields(this.state.fields, this.state.subFields)
        }
    }
    handleSubItemCheck(e) {
        let field = e.target.value
        let newFields = this.state.subFields
        if (field === this.state.selectedField) {
            newFields[field].position.x = 0
            newFields[field].position.w = 0
        } else {
            let table = this.state.fields.table.position
            newFields[field].position.x = Math.round(table.w / 3);
            newFields[field].position.w = Math.round(table.w / 3);
        }
        this.setState({ subFields: newFields });
    }
    lineMove(e, type, fieldType, fieldName) {
        e.stopPropagation();
        const moveType = { name: "lineMove", type: type, fieldType: fieldType }
        this.setMoveType(moveType, fieldName)
    }
    initSelecting(e, startX, startY, fieldName) {
        e.stopPropagation();
        const moveType = { name: "selecting", startX: startX, startY: startY, fieldType: "fields" }
        this.setMoveType(moveType, fieldName)
    }
    boxMove(e, field, fieldType) {
        e.stopPropagation();
        let targetRect = e.target.getBoundingClientRect()
        let moveType = { name: "boxMove", offsetX: Math.round(e.pageX - targetRect.x), fieldType: fieldType }
        if (fieldType === "fields") {
            moveType.offsetY = Math.round(e.pageY - targetRect.y)
        }
        this.setMoveType(moveType, field)
    }
    setMoveType(moveType, field) {
        this.setState({ moveType: moveType, prevSelectedField: this.state.selectedField, selectedField: field })
    }
    clearBox(field, fieldType) {
        let newFields = this.state[fieldType]
        newFields[field].position.x = 0
        newFields[field].position.w = 0
        if (fieldType === "fields") {
            newFields[field].position.y = 0
            newFields[field].position.h = 0
        }
        let newSubFields = this.state.subFields
        if (field === "table") {
            for (const key in newSubFields) {
                newSubFields[key].position.x = 0
                newSubFields[key].position.w = 0
            }
        }
        this.setState({ subFields: newSubFields, [fieldType]: newFields })
    }
    showLabel(e, label) {
        if (this.state.label.label === "") {
            this.setState({
                label: {
                    x: e.pageX,
                    y: `calc(${e.pageY}px - 1em)`,
                    label: label,
                    boundingRect: e.target.getBoundingClientRect()
                }
            })
        }
    }
    removeLabel(e) {
        let mouseX = e.pageX
        let mouseY = e.pageY + document.getElementsByClassName("shipment-background")[0].scrollTop
        let boundingRect = this.state.label.boundingRect
        if (mouseX <= boundingRect.x || boundingRect.right <= mouseX || mouseY <= boundingRect.y || boundingRect.bottom <= mouseY) {
            this.setState({
                label: { x: 0, y: 0, label: "", boundingRect: { x: 0, y: 0, top: 0, left: 0, right: 0, bottom: 0, width: 0, height: 0 } }
            })
        }
    }
    selectField(e) {
        this.changeState("selectedField", e.target.value)
        if (e.target.value === this.props.fieldError) {
            this.props.resetError()
        }
    }
    changeVisibility(e, field, fieldType) {
        let temp = this.state[fieldType]
        temp[field].visible = e.target.checked
        this.setState({[fieldType]: temp})
    }
    fieldVisibility(visible, key) {
        return (this.state.moveType.name && this.state.selectedField !== key) || !visible ? "hidden" : "visible"
    }
    setAllVisible(e) {
        let tempFields = this.state.fields
        let tempSubFields = this.state.subFields
        for (const key in tempFields) {
            if (this.props.fieldDefined(tempFields[key].position)) tempFields[key].visible = e.target.checked
        }
        for (const key in tempSubFields) {
            if (this.props.fieldDefined(tempSubFields[key].position)) tempSubFields[key].visible = e.target.checked
        }
        this.setState({fields: tempFields, subFields: tempSubFields})
    }
    allVisible() {
        for (const key in this.state.fields) {
            if (!this.state.fields[key].visible) return false
        }
        for (const key in this.state.subFields) {
            if (!this.state.subFields[key].visible) return false
        }
        return true
    }

    render() {
        return (<>
            <div className="label" style={{ top: this.state.label.y, left: this.state.label.x }} onMouseLeave={this.removeLabel}>{this.state.label.label}</div>
            <div
                style={{position: "relative", overflow: "hidden"}}
                onMouseUp={this.completeSelectBox}
                onMouseMove={this.resizeSelectBox}
                onMouseLeave={this.completeSelectBox}
            >
                <div style={{ width: "100%", display: "inline-block" }}>
                    <PDFViewer
                        data={this.props.pdf.contents}
                        invoiceTemplateEdit={true}
                        selecting={ true }
                        scale={this.props.scale}
                        //resizeSelectBox={this.resizeSelectBox}
                        createSelectBox={this.createSelectBox}
                    />
                </div>
                <div className="selectAreaHolder" style={{ height: this.pdfHeight * this.props.scale }}>
                    <div
                        style={{width: this.pdfWidth * this.props.scale, position: "relative"}}
                        className={ this.state.selectedField !== "" && this.state.moveType.name === "" ? "selecting" : "" }
                        onMouseDown={this.createSelectBox}
                    >
                        {Object.keys(this.state.fields).map((key, index) => {
                            let thisBox = this.state.fields[key].position
                            let fieldBox = (<div
                                key={"box" + key + index}
                                style={{
                                    border: "2px dashed " + this.green,
                                    position: "absolute",
                                    top: thisBox.y + "px",
                                    left: thisBox.x + "px",
                                    width: thisBox.w + "px",
                                    height: thisBox.h + "px",
                                    cursor: "move",
                                    zIndex: 5,
                                    display: this.props.fieldDefined(thisBox) ? "block" : "none",
                                    visibility: this.fieldVisibility(this.state.fields[key].visible, key)
                                }}
                                onMouseDown={(e) => this.boxMove(e, key, "fields")}
                                onMouseMove={this.resizeSelectBox}
                                onMouseUp={this.completeSelectBox}
                            >
                                <div className="edgeContainer">
                                    {/* Labels for the boxes and reset button */}
                                    <div className="labelRow">
                                        <p
                                            className="boxLabel"
                                            onMouseEnter={(e) => this.showLabel(e, this.state.fields[key].label)}
                                            onMouseLeave={this.removeLabel}
                                        >.&#160;.&#160;.</p>
                                        <ClearIcon onClick={() => this.clearBox(key, "fields")} className="resetButton" />
                                    </div>
                                    {/* These divs are used to change the cursor around the edge of the boxes */}
                                    <div
                                        className="topEdge"
                                        style={{height: this.resizeMargin}}
                                        onMouseDown={(e) => this.lineMove(e, "y", "fields", key)}
                                        onMouseMove={this.resizeSelectBox}
                                        onMouseUp={this.completeSelectBox}
                                    />
                                    <div
                                        className="leftEdge"
                                        style={{width: this.resizeMargin}}
                                        onMouseDown={(e) => this.lineMove(e, "x", "fields", key)}
                                        onMouseMove={this.resizeSelectBox}
                                        onMouseUp={this.completeSelectBox}
                                    />
                                    <div
                                        className="rightEdge"
                                        style={{width: this.resizeMargin}}
                                        onMouseDown={(e) => this.lineMove(e, "w", "fields", key)}
                                        onMouseMove={this.resizeSelectBox}
                                        onMouseUp={this.completeSelectBox}
                                    />
                                    <div
                                        className="botEdge"
                                        style={{height: this.resizeMargin}}
                                        onMouseDown={(e) => this.lineMove(e, "h", "fields", key)}
                                        onMouseMove={this.resizeSelectBox}
                                        onMouseUp={this.completeSelectBox}
                                    />
                                    <div
                                        className="topLeftCorner"
                                        style={{height: this.resizeMargin, width: this.resizeMargin}}
                                        onMouseDown={(e) => this.initSelecting(e, thisBox.x + thisBox.w, thisBox.y + thisBox.h, key)}
                                        onMouseMove={this.resizeSelectBox}
                                        onMouseUp={this.completeSelectBox}
                                    />
                                    <div
                                        className="topRightCorner"
                                        style={{height: this.resizeMargin, width: this.resizeMargin}}
                                        onMouseDown={(e) => this.initSelecting(e, thisBox.x, thisBox.y + thisBox.h, key)}
                                        onMouseMove={this.resizeSelectBox}
                                        onMouseUp={this.completeSelectBox}
                                    />
                                    <div
                                        className="botLeftCorner"
                                        style={{height: this.resizeMargin, width: this.resizeMargin}}
                                        onMouseDown={(e) => this.initSelecting(e, thisBox.x + thisBox.w, thisBox.y, key)}
                                        onMouseMove={this.resizeSelectBox}
                                        onMouseUp={this.completeSelectBox}
                                    />
                                    <div
                                        className="botRightCorner"
                                        style={{height: this.resizeMargin, width: this.resizeMargin}}
                                        onMouseDown={(e) => this.initSelecting(e, thisBox.x, thisBox.y, key)}
                                        onMouseMove={this.resizeSelectBox}
                                        onMouseUp={this.completeSelectBox}
                                    />
                                </div>
                            </div>)
                            return key === "table" ? <React.Fragment key={"boxtable1"} /> : fieldBox;
                        })}
                        {this.state?.fields?.table &&
                            <div
                                key={"boxtable"}
                                style={{
                                    border: "2px dashed " + this.green,
                                    position: "absolute",
                                    top: this.state.fields.table.position.y + "px",
                                    left: this.state.fields.table.position.x + "px",
                                    width: this.state.fields.table.position.w + "px",
                                    height: this.state.fields.table.position.h + "px",
                                    cursor: "move",
                                    zIndex: 5,
                                    display: this.props.fieldDefined(this.state.fields.table.position) ? "block" : "none",
                                    visibility: this.fieldVisibility(this.state.fields.table.visible, "table")
                                }}
                                onMouseDown={(e) => this.boxMove(e, "table", "fields")}
                                onMouseMove={this.resizeSelectBox}
                                onMouseUp={this.completeSelectBox}
                            >
                                <div className="edgeContainer">
                                    {/* Labels for the boxes and reset button */}
                                    <div className="labelRow">
                                        <p
                                            className="boxLabel"
                                            onMouseEnter={(e) => this.showLabel(e, "Line Items")}
                                            onMouseLeave={this.removeLabel}
                                        >.&#160;.&#160;.</p>
                                        <ClearIcon onClick={() => this.clearBox("table", "fields")}
                                                   className="resetButton"/>
                                    </div>
                                    {/* These divs are used to change the cursor around the edge of the boxes */}
                                    <div
                                        className="topEdge"
                                        style={{height: this.resizeMargin}}
                                        onMouseDown={(e) => this.lineMove(e, "y", "fields", "table")}
                                        onMouseMove={this.resizeSelectBox}
                                        onMouseUp={this.completeSelectBox}
                                    />
                                    <div
                                        className="leftEdge"
                                        style={{width: this.resizeMargin}}
                                        onMouseDown={(e) => this.lineMove(e, "x", "fields", "table")}
                                        onMouseMove={this.resizeSelectBox}
                                        onMouseUp={this.completeSelectBox}
                                    />
                                    <div
                                        className="rightEdge"
                                        style={{width: this.resizeMargin}}
                                        onMouseDown={(e) => this.lineMove(e, "w", "fields", "table")}
                                        onMouseMove={this.resizeSelectBox}
                                        onMouseUp={this.completeSelectBox}
                                    />
                                    <div
                                        className="botEdge"
                                        style={{height: this.resizeMargin}}
                                        onMouseDown={(e) => this.lineMove(e, "h", "fields", "table")}
                                        onMouseMove={this.resizeSelectBox}
                                        onMouseUp={this.completeSelectBox}
                                    />
                                    <div
                                        className="topLeftCorner"
                                        style={{height: this.resizeMargin, width: this.resizeMargin}}
                                        onMouseDown={(e) => this.initSelecting(e, this.state.fields.table.position.x + this.state.fields.table.position.w, this.state.fields.table.position.y + this.state.fields.table.position.h, "table")}
                                        onMouseMove={this.resizeSelectBox}
                                        onMouseUp={this.completeSelectBox}
                                    />
                                    <div
                                        className="topRightCorner"
                                        style={{height: this.resizeMargin, width: this.resizeMargin}}
                                        onMouseDown={(e) => this.initSelecting(e, this.state.fields.table.position.x, this.state.fields.table.position.y + this.state.fields.table.position.h, "table")}
                                        onMouseMove={this.resizeSelectBox}
                                        onMouseUp={this.completeSelectBox}
                                    />
                                    <div
                                        className="botLeftCorner"
                                        style={{height: this.resizeMargin, width: this.resizeMargin}}
                                        onMouseDown={(e) => this.initSelecting(e, this.state.fields.table.position.x + this.state.fields.table.position.w, this.state.fields.table.position.y, "table")}
                                        onMouseMove={this.resizeSelectBox}
                                        onMouseUp={this.completeSelectBox}
                                    />
                                    <div
                                        className="botRightCorner"
                                        style={{height: this.resizeMargin, width: this.resizeMargin}}
                                        onMouseDown={(e) => this.initSelecting(e, this.state.fields.table.position.x, this.state.fields.table.position.y, "table")}
                                        onMouseMove={this.resizeSelectBox}
                                        onMouseUp={this.completeSelectBox}
                                    />
                                    {Object.keys(this.state.subFields).map((key, index) => (
                                        <div
                                            key={"line" + index}
                                            style={{
                                                borderStyle: this.fieldVisibility(this.state.fields.table.visible, "table") === "hidden" ? "dashed" : "none dashed none dashed",
                                                borderWidth: "2px",
                                                borderColor: this.green,
                                                position: "absolute",
                                                top: "0px",
                                                left: this.state.subFields[key].position.x + "px",
                                                width: this.state.subFields[key].position.w + "px",
                                                cursor: "move",
                                                height: "100%",
                                                display: this.props.fieldDefined(this.state.subFields[key].position) ? "block" : "none",
                                                visibility: this.fieldVisibility(this.state.subFields[key].visible, key),
                                                zIndex: 5
                                            }}
                                            onMouseDown={(e) => this.boxMove(e, key, "subFields")}
                                            onMouseMove={this.resizeSelectBox}
                                            onMouseUp={this.completeSelectBox}
                                        >
                                            <div className="edgeContainer">
                                                {/* Labels for the boxes and reset button */}
                                                <div className="subFieldLabelRow"
                                                     style={{borderStyle: this.fieldVisibility(this.state.fields.table.visible, "table") === "hidden" ? "dashed" : "none dashed none dashed"}}>
                                                    <p
                                                        className="boxLabel"
                                                        onMouseEnter={(e) => this.showLabel(e, this.state.subFields[key].label)}
                                                        onMouseLeave={this.removeLabel}
                                                    >.&#160;.&#160;.</p>
                                                    <ClearIcon onClick={() => this.clearBox(key, "subFields")}
                                                               className="resetButton"/>
                                                </div>
                                                {/* These divs are used to change the cursor around the edge of the boxes */}
                                                <div
                                                    className="leftEdge"
                                                    style={{width: this.resizeMargin}}
                                                    onMouseDown={(e) => this.lineMove(e, "x", "subFields", key)}
                                                    onMouseMove={this.resizeSelectBox}
                                                    onMouseUp={this.completeSelectBox}
                                                />
                                                <div className="rightEdge"
                                                     style={{width: this.resizeMargin}}
                                                     onMouseDown={(e) => this.lineMove(e, "w", "subFields", key)}
                                                     onMouseMove={this.resizeSelectBox}
                                                     onMouseUp={this.completeSelectBox}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="sidebarHolder" style={{ width: this.props.showSideBar ? "320px" : "0px", zIndex: 6 }}>
                    <Slide in={this.props.showSideBar} direction='left'>
                        <FormControl component="fieldset" style={{marginLeft: "50px", marginTop: "36px"}}>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <FormLabel component="legend" style={{margin: 0}}>Fields</FormLabel>
                                <Checkbox style={{ color: this.green, marginLeft: "auto", marginRight: "-35px" }} onChange={this.setAllVisible} checked={this.allVisible()} />
                            </div>
                            <RadioGroup
                                aria-label="gender"
                                name="gender1"
                                value={this.state.selectedField}
                            >
                                {Object.keys(this.state.fields).map((key, index) => (
                                    <>
                                        <div key={"select" + key + index} style={{display: "flex", alignItems: "center"}}>
                                            { this.props.fieldDefined(this.state.fields[key].position) ?
                                                <>
                                                    <div style={{ padding: "10px 6px 10px 0px", display: "inline-block" }}>
                                                        <CheckCircleIcon style={{ color: this.green }} />
                                                    </div>
                                                    <div style={{ display: "inline-block", paddingRight: "10px" }}>
                                                        {this.state.fields[key].label}
                                                        {key === 'taxAmount' && this.props.entryTaxText ?
                                                            <>
                                                                <br></br>
                                                                - "{this.props.entryTaxText}"
                                                            </>  : ""}
                                                    </div>
                                                    <div style={{ display: "inline-block", marginLeft: "auto", marginRight: "-35px"}}>
                                                        <Checkbox style={{ color: this.green }} onChange={(e) => this.changeVisibility(e, key, "fields")} checked={this.state.fields[key].visible} />
                                                    </div>
                                                </> :
                                                <FormControlLabel
                                                    value={key}
                                                    control={<GreenRadio onClick={(e) => this.selectField(e)} />}
                                                    label={this.state.fields[key].label + (this.state.fields[key].required ? " *" : "")}
                                                />}
                                            { this.props.fieldError === key && <p style={{ color: "red" }}>This field is required</p> }
                                        </div>
                                        <>
                                            {this.state.fields[key].label === "Date" && this.props.fieldDefined(this.state.fields[key].position) ?
                                                <div style={{minWidth: "220px", width: "100%", paddingLeft: "31px"}}>
                                                    <Autocomplete
                                                        className="dateField"
                                                        size="small"
                                                        disableClearable
                                                        options={this.props.dateFormats}
                                                        getOptionLabel={(entry) => entry}
                                                        autoHighlight={true}
                                                        defaultValue={this.props.selectedFormat}
                                                        value={this.state.selectedDateFormat}
                                                        onChange={(event, value) => {
                                                            this.props.setDateFormat(value)
                                                        }}
                                                        name="formats"
                                                        renderInput={(params) =>
                                                            <TextField
                                                                {...params}
                                                                label="Date Format"
                                                                InputLabelProps={{ shrink: true }}
                                                                variant="outlined"
                                                                name="formats"
                                                            />
                                                        }
                                                    />
                                                </div> : <></>
                                            }
                                        </>
                                    </>
                                ))}
                                {this.state.subFields && Object.keys(this.state.subFields).map((key, index) => (
                                    <div key={"selectSubField" + key + index} style={{alignItems: "center", paddingLeft: "31px", display: this.props.fieldDefined(this.state.fields.table.position) ? "flex" : "none"}}>
                                        { this.props.fieldDefined(this.state.subFields[key].position) ?
                                            <>
                                                <div style={{ padding: "10px 6px 10px 0px", display: "inline-block" }}>
                                                    <CheckCircleIcon style={{ color: this.green }} />
                                                </div>
                                                <div style={{ display: "inline-block" }}>
                                                    { this.state.subFields[key].label }
                                                </div>
                                                <div style={{ display: "inline-block", marginLeft: "auto", marginRight: "-35px" }}>
                                                    <Checkbox style={{ color: this.green }} onChange={(e) => this.changeVisibility(e, key, "subFields")} checked={this.state.subFields[key].visible} />
                                                </div>
                                            </> : <FormControlLabel
                                                value={key}
                                                control={<GreenRadio onClick={this.handleSubItemCheck} />}
                                                label={this.state.subFields[key].label + (this.state.subFields[key].required ? " *" : "")}
                                            />}
                                        { this.props.fieldError === key && <p style={{ color: "red" }}>This field is required</p> }
                                    </div>
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </Slide>
                </div>
            </div>
        </>);
    }
}

export default EditInvoiceTemplate