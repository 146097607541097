import React from 'react';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import FilterIcon from './FilterIcon'
import FilterAppliedIcon from './FilterAppliedIcon'
    
// import styling
import './ShipmentsSearchField.css'

// import constants
import {FILTER} from './constants';
import PropTypes from "prop-types";
import ShipmentForm from "./ShipmentForm";

class ShipmentsSearchField extends React.PureComponent {

    constructor(props) {
        super(props);
    }

    render() {
        return(
            <div>
                <TextField className="searchField" margin="dense" id="outlined-basic" label="Search" InputLabelProps={{ shrink: true }} variant="outlined"  
                    onChange={this.props.onChange} />

                <Tooltip title={FILTER}>
                    <IconButton className="filterButton" size='small' aria-label={FILTER} onClick={this.props.showFiltersDialog} >
                        {this.props.isFilterApplied ? <FilterAppliedIcon /> : <FilterIcon /> }
                    </IconButton>
                </Tooltip>
            </div>
        );
    }
}

ShipmentsSearchField.propTypes = {
    onChange: PropTypes.func,
    isFilterApplied: PropTypes.bool,
    showFiltersDialog: PropTypes.func
}

ShipmentsSearchField.defaultProps = {
    onChange: ()=>{},
    isFilterApplied: false,
    showFiltersDialog: ()=>{}
}
  
export default ShipmentsSearchField