import React, {useState, useEffect} from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
// material components
import {
    FormControl,
    FormHelperText,
    MenuItem,
    Select,
    TextField,
    Checkbox,
    FormControlLabel,
} from "@material-ui/core";

// styling
import styles from './CreateCarrierDialog.module.css';
import PropTypes from "prop-types";
function AddressForm(props) {
    const [addresses, setAddresses] = useState([])
    useEffect(() => {
        setAddresses([{addressLine1: "NONE", name: ""}, ...props.addresses])
    }, [props.parentState])

    // specific handling of address select
    const handleAddress = (v) => {
        const address = v
        if (address === "NONE" || address === null) {
            props.handleAddressChange({addressLine1: "NONE"}, false);
        } else {
            const selectedAddress = props.addresses.findIndex(item => item.addressLine1 === address)
            props.handleAddressChange(props.addresses[selectedAddress], true)
        }
    }


    return(
        <div className={styles.rowCarrier}>
            <div className={styles.innerBox}>
            
            <div className="row-spec">
                <Autocomplete
                    options={props.addresses}
                    getOptionLabel={(option)=>option.name}
                    className="input-spec"
                    name="addresses2"
                    value = {props.parentState.address ? props.addresses.find((account) => {return account.name === props.parentState.address.name}) : '-'}
                    onChange={(e,v) => {
                        if(v != null){
                            handleAddress(v.addressLine1)
                        }else{
                            handleAddress("NONE")
                        }
                    }}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label="Location"
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            name="address"
                            required
                        />
                    }
                />
            </div>
            <div className="row-spec">
                <TextField
                    className="input-spec"
                    variant="outlined"
                    name="contactName"
                    disabled
                    onChange={props.handleChange}
                    value={props.parentState.contactName}
                    label="Contact Name"
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    className="input-spec"
                    variant="outlined"
                    name="companyName"
                    disabled
                    onChange={props.handleChange}
                    value={props.parentState.companyName}
                    label="Company Name"
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    className="input-spec"
                    variant="outlined"
                    name="phone"
                    disabled
                    onChange={props.handleChange}
                    value={props.parentState.phone}
                    label="Phone"
                    InputLabelProps={{ shrink: true }}
                />
                
            </div>
            <div className="row-spec">
                <TextField
                    className="input-spec-single"
                    variant="outlined"
                    name="addressLine1"
                    disabled
                    onChange={props.handleChange}
                    value={props.parentState.addressLine1}
                    label="Address Line 1"
                    InputLabelProps={{ shrink: true }}
                />
            </div>
            
            <div className="row-spec">
                
                <TextField
                    className="input-spec-single"
                    variant="outlined"
                    name="addressLine2"
                    disabled
                    onChange={props.handleChange}
                    value={props.parentState.addressLine2}
                    label="Address Line 2"
                    InputLabelProps={{ shrink: true }}
                />
            </div>
            <div className="row-spec">
                <TextField
                    className="input-spec"
                    variant="outlined"
                    name="city"
                    disabled
                    onChange={props.handleChange}
                    value={props.parentState.city}
                    label="City"
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    className="input-spec"
                    variant="outlined"
                    name="state"
                    disabled
                    onChange={props.handleChange}
                    value={props.parentState.state}
                    label="State or Province"
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    className="input-spec"
                    variant="outlined"
                    name="postalCode"
                    disabled
                    onChange={props.handleChange}
                    value={props.parentState.postalCode}
                    label="Zip or Postal Code"
                    InputLabelProps={{ shrink: true }}
                />
            </div>
            <div className="row-spec">
                <TextField
                    className="input-spec"
                    variant="outlined"
                    name="country"
                    disabled
                    onChange={props.handleChange}
                    value={props.parentState.country}
                    label="Country"
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    className="input-spec-two-col"
                    variant="outlined"
                    name="email"
                    disabled
                    onChange={props.handleChange}
                    value={props.parentState.email}
                    label="Email"
                    InputLabelProps={{ shrink: true }}
                />
            </div>
            <div className="row-spec">
                <FormControlLabel
                    className="input-spec-two-col"
                    control={
                        <Checkbox
                            checked={props.parentState.isDefault}
                            disabled={!props.isEdit}
                            onChange={props.handleIsDefaultChange}
                        />
                    }
                    label="Default Address"
                />
            </div>
                <div className="row-spec">
                    <FormControlLabel
                        className="input-spec-two-col"
                        control={
                            <Checkbox
                                checked={props.parentState.isEnabled}
                                disabled={!props.isEdit}
                                onChange={props.handleEnabledChange}
                            />
                        }
                        label="Enable Carrier"
                    />
                </div>
            </div>
        </div>
    )

}

AddressForm.propTypes = {
    addresses: PropTypes.array,
    handleAddressChange: PropTypes.func,
    parentState: PropTypes.object,
    handleChange: PropTypes.array,
    errors: PropTypes.array,
    isEdit: PropTypes.bool,
    handleIsDefaultChange: PropTypes.func
}

AddressForm.defaultProps = {
    addresses: [],
    handleAddressChange: ()=>{},
    parentState: {},
    handleChange: [],
    errors: [],
    isEdit: false,
    handleIsDefaultChange: ()=>{}
}


export default AddressForm;