import React from "react";


// material components
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button, TextField } from "@material-ui/core";

// custom components
import withShipment from "../../../withShipment";
import RenderPurchaseOrderPDFPreview from "./RenderPurchaseOrderPDFPreview";
import CreateCustomerPurchaseOrderTable from "./CreateCustomerPurchaseOrderTable";

// constants
import { MAX_NOTES_LINES, NOTES } from "./constants";

// redux
import {
    createNewPurchaseOrder,
   // fetchAllcustomers, 
    createSendNewPurchaseOrder
} from "../../../redux/actions/purchaseOrders";

// styling
import './CreateCustomerPurchaseOrder.css';
import PropTypes from "prop-types";
import {listCustomers} from "../../../redux/actions/customer";
import {customersSelector as realCustomersSelector} from "../../../redux/selectors/customer";

class CreateCustomerPurchaseOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCustomerIndex: 0,
            numRows: 0,
            availableProducts: [],
            availableCustomerNames: [],
            key: 0,
            showNotes: false,
            notes: "",
            templateName: '',
            productsAndPackages: []
        };

        this.baseState = {
            selectedCustomerIndex: 0,
            numRows: 0,
            availableProducts: [],
            availableCustomerNames: [],
            showNotes: false
        };
    }


    componentDidMount() {
        this.props.listCustomers()
        this.setState({
            notes: this.props.notes
        })
    }



    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.state.selectedCustomerIndex && this.state.selectedCustomerIndex !== prevState.selectedCustomerIndex && this.props.customers) {
            let customer = this.props.customers[this.state.selectedCustomerIndex]
            if (customer) {
                this.setState({
                    productsAndPackages: [...customer?.internalPackagingInfo ?? [], ...customer?.internalProductInfo ?? []]
                })
            }
        }

        if (prevProps.selectedCustomer !== this.props.selectedCustomer) {
            // update pre-populated fields when customer is selected
            // go through all entries and update values
            if (this.props.selectedCustomer === '')
                return;
            let index = this.props.customers.findIndex((customer) => customer.name === this.props.selectedCustomer)

            let availableProds = this.props.customers[index].products?.map((product) => {
                return product.shortcode;
            });

            let customerId = this.props.customers[index].customerId

            this.props.updateField("customerId", customerId)

            this.setState({
                ...this.baseState,
                //selectedCustomer: this.state.selectedCustomer,
                selectedCustomerIndex: index,
                availableProducts: availableProds,
                //customerId: customerId,
                availableCustomerNames: this.state.availableCustomerNames,
                key: prevState.key + 1,
                //notes: this.state.notes,
                //samePreview: false
            });
        }

        if (prevProps.customers !== this.props.customers) {

            let customerNames = this.props.customers.map((val) => {
                if (val.name) {
                    return val.name;
                }
            });
            this.props.updateField("samePreview", false)
            this.setState({
                availableCustomerNames: customerNames,
                //samePreview: false
            });
        }
    }

    changeTemplateName = (e) => {
        if(e.length >= 1){
            this.setState({
                templateName: e[0].templateName
            })
        } else if(e.length === 0){
            this.setState({
                templateName: ''
            })
        }
    }

    addEntry = () => {
        let curEntries = this.props.entries;
        this.props.hasPricingRole ?
            curEntries.push({
                shortcode: '',
                partNum: '',
                description: '',
                discount: this.props.discountPercentage,
                price: '',
                qty: 0,
                deleteEntry: true
            })
            :
            curEntries.push({
                shortcode: '',
                partNum: '',
                description: '',
                discount: this.props.discountPercentage,
                qty: 0,
                deleteEntry: true
            })
        this.props.updateField("entries", curEntries)
        this.props.updateField("samePreview", false)
        this.setState({
            numRows: this.state.numRows + 1,
            key: this.state.key + 1,
        });

    }

    removeEntry = (index) => {
        let curEntries = this.props.entries;
        curEntries.splice(index, 1);
        this.props.updateField("entries", curEntries)
        this.props.updateField("samePreview", false)
        this.setState({
            numRows: this.state.numRows - 1,
        });
    }


    onShortcodeChange = (shortcode, index) => {
        // update the entries
        let curEntries = this.props.entries;

        let internalProduct = this.props.customers[this.state.selectedCustomerIndex].internalProductInfo.find((product) => {
            return product.shortcode === shortcode
        })
        if (!internalProduct) {
            internalProduct = this.props.customers[this.state.selectedCustomerIndex].internalPackagingInfo.find((product) => {
                return product.shortcode === shortcode
            })
        }

        let customerProduct = this.props.customers[this.state.selectedCustomerIndex].products?.find((product) => {
            return product.shortcode === shortcode
        })
        curEntries[index] = this.props.hasPricingRole ? {
                ...curEntries[index],
                shortcode: shortcode ? shortcode : '',
                partNum: customerProduct ? customerProduct.customerPartNumber : '',
                description: internalProduct ? internalProduct.textDescription : '',
                discount: this.props.discountPercentage,
                price: customerProduct ? customerProduct.price : '',
                quantity: 0,
                deleteEntry: true
            }
            :
            {
                ...curEntries[index],
                shortcode: shortcode ? shortcode : '',
                partNum: customerProduct ? customerProduct.customerPartNumber : '',
                description: internalProduct ? internalProduct.textDescription : '',
                discount: this.props.discountPercentage,
                quantity: 0,
                deleteEntry: true
            }

        this.props.updateField("entries", curEntries)
    }

    onCustomerChange = (value) => {
        // Ensure customers array is defined and filter results in a non-empty array
        let customer = this.props.customers?.filter(customer => customer["name"] === value)[0];

        // Check if the customer and products are defined before mapping
        if (customer && customer.products) {
            let selectedAvailableProds = customer.products.map((product) => product.shortcode);

            for (let i = 0; i < this.props.entries.length; i++) {
                let shortcode = this.props.entries[i]['shortcode'];
                if (!selectedAvailableProds.includes(shortcode)) this.onShortcodeChange("", i);
            }
        } else {
            // Handle the case where no matching customer is found or products is undefined
            for (let i = 0; i < this.props.entries.length; i++) {
                this.onShortcodeChange("", i);
            }
        }

        this.props.updateField("selectedCustomer", value);
    }

    onChange = (e, index) => {

        let curEntries = this.props.entries;

        if (e.target.value >= 0) {
            curEntries[index][e.target.name] = e.target.value
            const customer = this.props.customers[this.state.selectedCustomerIndex]
            const productsAndPackages = [
                ...(customer?.internalPackagingInfo ?? []),
                ...(customer?.internalProductInfo ?? [])
            ]

            if (e.target.name === "skid") {
                const ratio = productsAndPackages.find(entry => entry?.shortcode === curEntries[index]["shortcode"])?.paleteQuantity

                if (ratio) {
                    curEntries[index]["quantity"] = e.target.value * ratio
                }
            }

            if (e.target.name === "quantity") {
                const ratio = productsAndPackages.find(entry => entry?.shortcode === curEntries[index]["shortcode"])?.paleteQuantity
                if (ratio) {
                    curEntries[index]["skid"] = e.target.value / ratio
                }
            }

            this.props.updateField("entries", curEntries)
        }

    }

    formatPrice = (price, index) => {
        let curEntries = this.props.entries;
        if (Number(price) || (Number(price) === 0 && price !== "")) {
            curEntries[index]["price"] = Number(price).toFixed(2).toString();
            this.props.updateField("entries", curEntries);
        }
    }

    onNotesChange = () => {
        this.props.updateField("notes", this.state.notes)
    }

    handleNotesChange = (e) => {
        let newLines = e.target.value.split("\n").length
        if (newLines <= MAX_NOTES_LINES) {
            this.setState({
                notes: e.target.value
            })
        }
    }

    resetNotesField = () => {
        this.setState({
            notes: this.props.notes
        })
    }

    handleDateChange = (date) => {
        this.props.updateField("expectedDate", date)
    }

    handleShipByChange = (value) => {
        this.props.updateField("isSameAsBillingAddress", value)
    }

    handleHistoricalChange = (value) => {
        this.props.updateField("isHistorical", value)
    }


    handlePOChange = (value) => {
        this.props.updateField("poNumber", value)
    }

    handleShipToAddressChange = (name,value) => {
        this.props.updateShipToAddress(name, value)
    }

    handleCreateDateChange = (date) => {
        this.props.updateField("createDate", date)
    }

    showNotes = () => {
        this.setState({
            showNotes: true
        })
    }

    handleNotesClose = () => {
        this.setState({
            showNotes: false
        })
    }

    render() {
        return (
            <>
                {this.props.previewing &&
                    <RenderPurchaseOrderPDFPreview
                        //allowSave={true}
                        data={this.props.pdfData}
                        //handlePreviewOpen={this.handlePreviewOpen}
                        //handlePreviewClose={this.handlePreviewClose}
                        //open={this.state.previewOpen}
                        handleSubmit={this.handleSubmit}
                        creating={true}
                        handlePdfLoadSuccess={this.props.handlePdfLoadSuccess}
                        handlePdfLoadError={this.props.handlePdfLoadError}
                        scale={this.props.scale}
                    /> ||
                    <>
                        <Dialog open={this.state.showNotes} onClose={this.handleNotesClose} fullWidth={true}>
                            <DialogTitle>
                                {NOTES} (Max {MAX_NOTES_LINES} Lines)
                            </DialogTitle>
                            <DialogContent style={{ textAlign: "center" }}>
                                <TextField
                                    variant="outlined"
                                    name="notes"
                                    value={this.state.notes}
                                    onChange={this.handleNotesChange}
                                    style={{ width: '100%' }}
                                    multiline
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => {
                                    this.resetNotesField();
                                    this.handleNotesClose()
                                }}>
                                    Exit
                                </Button>
                                <Button onClick={() => {
                                    this.onNotesChange();
                                    this.handleNotesClose()
                                }
                                } color="primary">
                                    Confirm
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <CreateCustomerPurchaseOrderTable
                            selectedCustomer={this.props.selectedCustomer}
                            availableProducts={this.state.availableProducts}
                            availableCustomerNames={this.state.availableCustomerNames}
                            //pdfData={this.props.PDFpreviewReady}
                            handleSubmit={this.handleSubmit}
                            handleSendSubmit={this.handleSendSubmit}
                            //openPreview={this.state.previewOpen}
                            entries={this.props.entries}
                            numRows={this.state.numRows}
                            addEntry={this.addEntry}
                            removeEntry={this.removeEntry}
                            onShortcodeChange={this.onShortcodeChange}
                            onCustomerChange={this.onCustomerChange}
                            onNotesChange={this.onNotesChange}
                            onChange={this.onChange}
                            tableKey={this.state.key}
                            handleDateChange={this.handleDateChange}
                            expectedDate={this.props.expectedDate}
                            showNotes={this.showNotes}
                            //previewPDF={this.previewPDF}
                            //previewClose={this.handlePreviewClose}
                            handleCancel={this.props.handleCancel}
                            //changeHeader={this.props.changeHeader}
                            selectedLocation={this.props.selectedLocation}
                            taxPercentage={this.props.taxPercentage}
                            handleTaxChange={this.props.handleTaxChange}
                            discountPercentage={this.props.discountPercentage}
                            handleDiscountChange={this.props.handleDiscountChange}
                            locations={this.props.locations}
                            handleLocationChange={this.props.handleLocationChange}
                            editingPurchaseOrder={false}
                            hasPricingRole={this.props.hasPricingRole}
                            formatPrice={this.formatPrice}
                            selectedCustomerInfo={this.props.selectedCustomerInfo}
                            handleCreateDateChange={this.handleCreateDateChange}
                            handlePOChange={this.handlePOChange}
                            handleShipToAddressChange={this.handleShipToAddressChange}
                            handleShipByChange={this.handleShipByChange}
                            handleHistoricalChange={this.handleHistoricalChange}
                            isHistorical={this.props.isHistorical}
                            shipToAddress={this.props.shipToAddress}
                            isSameAsBillingAddress={this.props.isSameAsBillingAddress}
                            createDate={this.props.createDate}
                            poNumber={this.props.poNumber}

                        />

                    </>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    customers: realCustomersSelector(state),
})

const actionCreators = {
    createNewPurchaseOrder,
    createSendNewPurchaseOrder,
    listCustomers,
}


CreateCustomerPurchaseOrder.propTypes = {
    PDFpreviewReady: PropTypes.object,
    customers: PropTypes.array
}

CreateCustomerPurchaseOrder.defaultProps = {
    PDFpreviewReady: {},
    customers: []
}


export default withShipment({
    mapStateToProps,
    actionCreators
}, CreateCustomerPurchaseOrder);