import React, {useEffect, useState} from 'react'

//imports

import PropTypes from "prop-types";
import GlobalTableHead from "../../global/Table/GlobalTableHead";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {BUY_SHIPPING_SERVICES_TABLE_HEAD_CELLS, CANCEL} from "./constants";
import Radio from "@material-ui/core/Radio";
import {DialogContentText, DialogTitle, TableHead, Tooltip} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {ASC, DESC} from "../../global/constants";
import {RATE_DESCRIPTION, RATE_TITLE, SAVE} from "../constants";

function BuyShippingServicesTable(props) {
    const [state, setState] = useState({
        selectedServiceName: '',
        order: ASC,
        orderBy: 'rateAmount',
    })

    useEffect(() => {
        if (!state.selectedServiceName) {
            const serviceName = props.shippingServices?.find((shippingService) => shippingService.serviceName === props.shipment.carrierService)?.serviceName
            if (serviceName && state.selectedServiceName !== serviceName) {
                setState({
                    ...state,
                    selectedServiceName: serviceName
                })
                props.setBuyShippingService(serviceName)
            }
        }
    })

    const handleRadioChange = (serviceName) => {
        setState({
            ...state,
            selectedServiceName: serviceName
        })
        props.setBuyShippingService(serviceName)
        return null
    }

    const handleSave = () => {
        props.handleSave()
    }

    const handleCancel = () => {
        props.handleCancel()
    }

    const handleRequestSort = (event, property) => {
        const isAsc = state.orderBy === property && state.order === ASC;
        setState({
            ...state,
            order: isAsc ? DESC : ASC,
            orderBy: property,
        });
    };

    const getCellValue = (row, orderBy) => {
        switch(orderBy) {
            case('carrierName'):
                return row.carrier
            case('serviceName'):
                return row.serviceName
            case('rateAmount'):
                return parseFloat(row.rate)
            case('rateCurrency'):
                return row.rateCurrency
            default:
                return ''
        }
    }

    const sort = (order, orderBy, rows) => {
        if (!rows) {
            return []
        }
        rows.sort((a,b) => {
            let valueA = getCellValue(a, orderBy) ? getCellValue(a, orderBy) : false
            let valueB = getCellValue(b, orderBy) ? getCellValue(b, orderBy) : false

            if(valueA && typeof valueA !== 'number'){
                valueA = valueA ? valueA.toLowerCase() : ''; // ignore upper and lowercase
            }

            if(valueB && typeof valueB !== 'number'){
                valueB = valueB ? valueB.toLowerCase() : ''; // ignore upper and lowercase
            }

            if (valueA < valueB) {
                if(order === ASC){
                    return -1;
                }else{
                    return 1;
                }
            }
            if (valueA > valueB) {
                if(order === ASC){
                    return 1;
                }else{
                    return -1;
                }
            }
            return 0
        })
        return rows
    }

    const formatDate = (date) => {
        if (date != null) {
            // return date.split("T")[0].split('-').join('/')
            const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            return new Date(date).toLocaleDateString(undefined, options)
        }
        return ""
    }

    return (
        <div>
            <DialogTitle style={{textAlign:'left'}}>{RATE_TITLE} - {props.shipment.orders? props.shipment.orders[0].orderId : ''}</DialogTitle>
            <DialogContentText style={{textAlign:'left', marginLeft:42}}>{RATE_DESCRIPTION} - {props.shipment.shippingAddressCity}, {props.shipment.shippingAddressStateOrRegion} {props.shipment.shippingAddressPostalCode}</DialogContentText>
            <TableContainer>
                <Table>
                    <GlobalTableHead
                        headCells={BUY_SHIPPING_SERVICES_TABLE_HEAD_CELLS}
                        order={state.order}
                        orderBy={state.orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={!props.shippingServices ? 0 : props.shippingServices.length}
                    />
                    <TableBody>
                        {sort(state.order, state.orderBy, props.shippingServices)?.map((shippingService) => {
                            const carrierName = shippingService.carrier
                            const serviceName = shippingService.serviceName
                            const estimatedDeliveryDate = formatDate(shippingService.estimatedDeliveryDate)
                            const rateAmount = shippingService.rate
                            const rateCurrency = shippingService.rateCurrency
                            return (
                                <TableRow>
                                    <TableCell/>
                                    <TableCell>
                                        {carrierName}
                                    </TableCell>
                                    <TableCell>
                                        {serviceName}
                                    </TableCell>
                                    <TableCell>
                                        {estimatedDeliveryDate}
                                    </TableCell>
                                    <TableCell>
                                        ${rateAmount}
                                    </TableCell>
                                    <TableCell>
                                        {rateCurrency}
                                    </TableCell>
                                    <TableCell>
                                        <Radio
                                            checked={serviceName === state.selectedServiceName}
                                            onChange={()=>{handleRadioChange(serviceName)}}
                                        />
                                    </TableCell>
                                </TableRow>
                            )
                        })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <div style={{textAlign: 'center', marginTop: 10}}>
                <Tooltip title={CANCEL} style={{marginRight: 10, marginBottom: 10}}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            handleCancel()
                        }}
                    > {CANCEL}
                    </Button>
                </Tooltip>
                <Tooltip title={SAVE} style={{marginBottom: 10}}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            handleSave()
                        }}
                        disabled={!state.selectedServiceName}
                    > {SAVE}
                    </Button>
                </Tooltip>
            </div>
        </div>
    )
}

BuyShippingServicesTable.propTypes = {
    shippingServices: PropTypes.array,
    shipment: PropTypes.object,
    handleSave: PropTypes.func,
    handleCancel: PropTypes.func,
}

BuyShippingServicesTable.defaultProps = {
    shippingServices: [],
    shipment: {},
    handleSave: ()=>{},
    handleCancel: ()=>{},
}


export default BuyShippingServicesTable;