import React from "react";

import {DELETE_ACTION, DRAG} from "./constants";

// import styling
import {
    FormGroup,
    FormControlLabel,
    Checkbox,
    Box,
    Tooltip,
    IconButton
} from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import DehazeIcon from '@material-ui/icons/Dehaze';
import PropTypes from "prop-types";

class ActionBoolean extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            actionId: '',
            type: '',
            name: '',
            value: true
        }
    }

    componentDidMount() {
        this.setState({
            ...this.props.action
        })
    }

    handleChange = (event) => {
        this.state.value ? this.setState({value: false}, () => {this.props.updateActionWithParent({...this.state})}) : this.setState({value: true}, () => {this.props.updateActionWithParent({...this.state})})
    };

    render() {
        return (
            <Box
                className='filterBooleanSelect'
                display='flex'
                flexDirection='row'
                justifyContent='left'
                alignItems='center'
            >
                <Box className='filterBooleanSelectName'>{this.state.name}</Box>
                <FormGroup row className="filterBooleanSelectOptions">
                    <FormControlLabel
                        control={<Checkbox checked={this.state.value} onChange={this.handleChange} name="value" />}
                    />
                </FormGroup>

                {/* in order to make sure that the delete icon aligns, we add an empty component */}
                <div className='filterBooleanEmptyDiv'>
                    <Tooltip title={DELETE_ACTION}>
                        <IconButton
                            aria-label={DELETE_ACTION}
                            onClick={() => this.props.deleteAction(this.state.actionId)}
                        >
                            <DeleteOutlineIcon variant='outlined' fontSize='small' />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={DRAG}>
                        <IconButton
                            aria-label='drag'
                        >
                            <DehazeIcon variant='outlined' fontSize='small' />
                        </IconButton>
                    </Tooltip>
                </div>
            </Box>
        );
    }

}

ActionBoolean.propTypes = {
    action: PropTypes.array,
    updateActionWithParent: PropTypes.func,
    deleteAction: PropTypes.func,
    isEdit: PropTypes.bool
};

ActionBoolean.defaultProps = {
    action: {},
    updateActionWithParent: ()=>{},
    deleteAction: ()=>{},
    isEdit: true
}

export default ActionBoolean;