import axios from "axios";
import {call, put, takeLatest} from "redux-saga/effects";
import {
    DELETE_FULL_TRUCKLOADS_BROKER,
    FETCH_FULL_TRUCKLOADS_BROKERS,
    SAVE_FULL_TRUCKLOADS_BROKERS,
    STORE_FULL_TRUCKLOADS_BROKERS
} from "../../constants/fullTruckloadBrokers";
import {FULL_TRUCKLOAD, FULL_TRUCKLOAD_BROKERS} from "../../../components/global/Server/endpoints";
import {SERVER_FAILED_ERROR_MESSAGE} from "../../../pages/Dashboard/DashboardFinal/constants";
import {hideError, requestStatus, showErrorMessage} from "../../actions/global";
import {storeUpdatedSupplierInvoice} from "../../actions/invoices";
import {storeFullTruckloadBroker, storeFullTruckloadBrokers} from "../../actions/fullTruckloadBrokers";

const getHeaders = () => {
    const token = localStorage.getItem('token');
    return {
        "Authorization": `Bearer ${token}`
    }
}

const getRequest = (endpoint) => {
    return axios.get(endpoint, {
        headers: getHeaders()
    })
        .then(response => response);
}

const postRequest = (endpoint, data) => {
    return axios.post(endpoint, data, {
        headers: getHeaders()
    })
        .then(response => response)
}

function* fetchFullTruckloadBrokers() {
    yield put(hideError())
    try {
        const response = yield call(() => getRequest(FULL_TRUCKLOAD_BROKERS.FETCH_FULL_TRUCKLOADS_BROKERS))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === 200
        };
        if (response.data.success) {
            yield put(storeFullTruckloadBrokers(response.data.fullTruckloadBrokers))
        }
        yield put(requestStatus(status))
    }
    catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* saveFullTruckloadBrokers(action) {
    yield put(hideError())
    try{
        const response = yield call(() => postRequest(FULL_TRUCKLOAD_BROKERS.SAVE_FULL_TRUCKLOAD_BROKERS, {data : action.data}))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === 200
        };
        if (response.data.success) {
            yield put(storeFullTruckloadBroker(response.data.updatedFullTruckLoad))
        }
        yield put(requestStatus(status))
    }
    catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))

    }
}

function* deleteFullTruckloadBrokers(action) {
    yield put(hideError())
    try{
        const response = yield call(() => postRequest(FULL_TRUCKLOAD_BROKERS.DELETE_FULL_TRUCKLOAD_BROKER, {brokerId : action.data}))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.data.success
        }
        if (response.status === 200 && response.data.success) {
            yield put(storeFullTruckloadBrokers(response.data.fullTruckloadBrokers))
        } else {
            status.message = response.data?.message
            yield put(showErrorMessage(status.message))
            yield put(requestStatus(status))
        }
    }catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

export default function* fullTruckloadBrokerSagas() {
    yield takeLatest(FETCH_FULL_TRUCKLOADS_BROKERS,fetchFullTruckloadBrokers)
    yield takeLatest(SAVE_FULL_TRUCKLOADS_BROKERS,saveFullTruckloadBrokers)
    yield takeLatest(DELETE_FULL_TRUCKLOADS_BROKER,deleteFullTruckloadBrokers)
}