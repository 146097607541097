import React from 'react';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

// custom components
import AutomationRulesCondition from './AutomationRulesCondition';
import AutomationRulesMapping from "./AutomationRulesMapping";
import AutomationsRuleTableToolbar from './AutomationRulesTableToolbar';
import DragAndDrop from "../global/Dnd/DragAndDrop";
// material components
import {
  TextField,
  Paper,
  StepButton,
  Stepper,
  Step,
  Button,
  Typography
} from '@material-ui/core';

// material icons
import AddIcon from '@material-ui/icons/Add';

// redux
import withShipment from "../../withShipment";
import {
  newRuleSelector,
  isEditSelector,
  rulesSelector
} from "../../redux/selectors/automationRules";
import {
  createRule,
  editRule,
  clearStored,
} from "../../redux/actions/automationRules";
import {listTags, getUUID, getUUIDs, listPackages} from "../../redux/actions/settings";
import {selectedLocationIdSelector, userInfoSelector} from "../../redux/selectors/auth";
import { tagsSelector } from "../../redux/selectors/settings"

// import constants
import {
  ADD_CONDITION,
  AUTOMATION_RULES,
  BACK,
  CANCEL,
  DOWN,
  FINISH,
  GROUP_NAME,
  RULE_CATEGORY,
  RULE_CATEGORY_OPTIONS,
  NEW_RULE,
  NEXT,
  RULE_NAME,
  UP,
} from './constants';
import {
  COMPONENT_TYPES,
  SET_INVOICE_PAYMENT_DATE,
  SET_NUMBER_OF_PACKAGES,
  SET_SHIPPING_TEMPLATE
} from "./ConditionActions/constants";

// importing styling
import './AutomationRulesStepper.css';
import PropTypes from "prop-types";
import ErrorFeedbackBar from '../global/ErrorFeedbackBar';
import {stateAndProvinceMappingSelector} from "../../redux/selectors/global";
import {setShipmentSummaryOrderDirection} from "../../redux/actions/orders";
import {getStateAndProvinceMapping} from "../../redux/actions/global";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { format, parse, isValid } from 'date-fns';

const steps = ['Naming', 'Criteria', 'Mapping'];
const stepDescriptions = [
  'Specify rule and group name',
  'Add set of filters and assign actions',
  'Specify when the rule should execute',
];

const STEP_NAMING = 0;
const STEP_CRITERIA = 1;
const STEP_MAPPING = 2;

class AutomationRulesStepper extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      oldName: this.props.isEdit ? this.props.ruleToStore.rule.name : '',
      existRuleNames: this.props.rules ? this.props.rules.map(rule => rule.name) : [],
      ruleName: this.props.isEdit ? this.props.ruleToStore.rule.name : '',
      groupName: this.props.isEdit ? this.props.ruleToStore.rule.group : '',
      ruleCategory: this.props.isEdit && this.props.ruleToStore.rule.ruleCategory ? this.props.ruleToStore.rule.ruleCategory : '',
      conditions: this.props.isEdit ? [...this.props.ruleToStore.rule.conditions] : [],
      applyToAllShipments: this.props.isEdit ? this.props.ruleToStore.rule.applyToAllShipments : false,
      applyToAllListings: this.props.isEdit ? this.props.ruleToStore.rule.applyToAllListings : false,
      autoApply: this.props.isEdit ? this.props.ruleToStore.rule.autoApply : false,
      skus: this.props.isEdit ? [...this.props.ruleToStore.rule.skus] : [],
    };
    this.props.listPackages()
    this.props.getStateAndProvinceMapping(this.props.stateAndProvinceMapping)
  }

  componentDidMount = () => {
    const companyId = this.props.userInfo.company
    this.props.listTags(companyId)
    if (!this.props.isEdit) {
      const setUUID = (conditionUUID) => {
        this.setState({
          conditions: [{ conditionId: conditionUUID }], // initialize with the first condition set
        });
      }
      this.props.getUUID("condition", setUUID)
    }
  };

  getRuleCategoryOptions = () => {
    let options = RULE_CATEGORY_OPTIONS.filter(category => {
      return !((category === "Transactions" && !(this.props.user.routes.includes('accounting') || this.props.user.routes.includes('invoices')
              || this.props.user.routes.includes('transactions') || this.props.user.routes.includes('supplier-invoices')
              || this.props.user.routes.includes('carrier-invoices')))
          || (category === "Listings" && !(this.props.user.routes.includes('settings') || this.props.user.routes.includes('listings'))));
    });
    return options
  }

  redirect = (path) => {
    this.props.history.push(path)
  }
  getStepContent(stepIndex) {
    switch (stepIndex) {
      case STEP_NAMING:
        return (
          <div>
            <Paper className='automationRulesPaper' variant='outlined'>
              <div className="fieldsContainer">
                <TextField
                    className='ruleGroupField'
                    label={RULE_NAME}
                    required
                    error={this.state.ruleName != this.state.oldName && this.state.existRuleNames.includes(this.state.ruleName)}
                    InputLabelProps={{ shrink: true }}
                    value={this.state.ruleName}
                    onChange={this.ruleNameUpdated}
                    variant="outlined"
                />
                <TextField
                    className='ruleGroupField'
                    label={GROUP_NAME}
                    required
                    InputLabelProps={{ shrink: true }}
                    value={this.state.groupName}
                    onChange={this.groupNameUpdated}
                    variant="outlined"
                />
                <Autocomplete
                    variant="outlined"
                    className='ruleGroupField'
                    options={this.getRuleCategoryOptions()}
                    getOptionLabel={(option) => option} // Assuming each option is just a string. Adjust if your data structure is different.
                    name="ruleCategory"
                    onChange={(e, value) => this.setState({ruleCategory: value})}
                    value={this.state.ruleCategory}
                    blurOnSelect={true}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label={RULE_CATEGORY}
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            required
                            name="ruleCategory"
                        />
                    }
                />
              </div>
            </Paper>
          </div>
        );
      case STEP_CRITERIA:
        return (
          <div>
            {/* how the first condition by default */}
            {this.state.conditions.map((condition, i) => {
              return (
                <AutomationRulesCondition
                  condition={condition}
                  totalConditions={this.state.conditions.length}
                  updateConditionWithParent={this.updateCondition}
                  deleteCondition={this.deleteCondition}
                  moveCondition={this.handleMove}
                  copyCondition={this.handleCopy}
                  isEdit={this.props.isEdit}
                  tags={this.props.tags}
                  getUUIDs={this.props.getUUIDs}
                  key={condition.conditionId}
                  index={i}
                  ruleCategory={this.state.ruleCategory}
                  getStateAndProvinceMapping={this.props.getStateAndProvinceMapping}
                  stateAndProvinceMapping={this.props.stateAndProvinceMapping}
                />
              );
            })}
            <div className='div'>
              <Button
                className='automationRulesConditionButton'
                variant='contained'
                color='primary'
                startIcon={<AddIcon />}
                onClick={this.addCondition}
                deleteMapping={this.deleteCondition}
              >
                {ADD_CONDITION}
              </Button>
            </div>
          </div>
        );
      case STEP_MAPPING:
        return (
          <div>
            <AutomationRulesMapping
              key={`rules-mapping`}
              updateMappingWithParent={this.updateMapping}
              applyToAllShipments={this.state.applyToAllShipments}
              applyToAllListings={this.state.applyToAllListings}
              autoApply={this.state.autoApply}
              skus={this.state.skus}
              ruleCategory={this.state.ruleCategory}
              conditions={this.state.conditions}
              ruleName={this.state.ruleName}
              isEdit={this.props.isEdit}
              ruleCategory={this.state.ruleCategory}
            />
          </div>
        );
      default:
        this.props.clearStored()
        this.handleReset()
        return this.redirect('/automation-rules');
    }
  }

  addCondition = () => {
    const setUUID = (conditionUUID) => {
      this.setState({
        conditions: [...this.state.conditions, { conditionId: conditionUUID }],
      });
    }
    this.props.getUUID("condition", setUUID)
  };

  updateCondition = (newCondition) => {
    const index = this.state.conditions.findIndex(
      (condition) => condition.conditionId === newCondition.conditionId
    );
    if (index !== -1) {
      const conditions = [...this.state.conditions];
      conditions.splice(index, 1, { ...newCondition });
      this.setState({
        conditions,
      });
    }
  };

  updateMapping = (mappings) => {
    this.setState({
      applyToAllShipments: mappings.applyToAllShipments,
      applyToAllListings: mappings.applyToAllListings,
      autoApply: mappings.autoApply,
      skus: mappings.skus
    })
  };

  deleteCondition = (conditionId) => {
    const index = this.state.conditions.findIndex(
      (condition) => condition.conditionId === conditionId
    );
    if (index !== -1) {
      const conditions = [...this.state.conditions];
      conditions.splice(index, 1);
      this.setState({
        conditions,
      });
    }
  };

  setStep = (index) => {
    this.setState({
      activeStep: index,
    });
  };

  handleClear = () => {
    this.setState({
      ruleName: '',
      groupName: '',
      conditions: [],
    })
  }

  isDateInFormat = (dateStr, dateFormat = 'yyyy/MM/dd') => {
    const parsedDate = parse(dateStr, dateFormat, new Date());
    return isValid(parsedDate);
  };

  handleCreateOrEditRule = () => {
    let conditions = []

    this.state.conditions.map(condition => {
      let filters = []
      let actions = {
        prePackageActions: [],
        packageActions: [],
        postPackageActions: [],
        listingsActions :[],
        transactionsActions: []
      }
      let conditionFilters = condition.filters ? condition.filters : []
      conditionFilters.map(filter => {
        let filterToAdd = {}
        if (filter.type === 1) {
          filterToAdd = {
            name: filter.name,
            operator: "boolean",
            operand: filter.value,
            type: filter.type,
            filterId: filter.filterId
          }
        } else {
          filterToAdd = {
            name: filter.name,
            operator: filter.value1,
            operand: filter.value2,
            operand2: filter.value3,
            type: filter.type,
            filterId: filter.filterId
          }
          if (filter.preDefinedValue && filter.preDefinedValue !== '-' && filter.preDefinedValue !== '') {
            filterToAdd.operand3 = filter.preDefinedValue;
          }
          if (filter.preDefinedValue2 && filter.preDefinedValue2 !== '-' && filter.preDefinedValue2 !== '') {
            filterToAdd.operand4 = filter.preDefinedValue2;
          }
          if (filter.preDefinedValue3 && filter.preDefinedValue3 !== '-' && filter.preDefinedValue3 !== '') {
            filterToAdd.operand5 = filter.preDefinedValue3;
          }
        }
        filters = [...filters, filterToAdd]
      })
      const stepNames = ["prePackageActions", "packageActions", "postPackageActions","listingsActions", "transactionsActions"]
      for (let i = 0; i < 5; i++) {
        if (condition.actions) {
          condition.actions[stepNames[i]].map(action => {
            let actionToAdd = {}
            switch (action.type) {
              case COMPONENT_TYPES.NUMERICAL_INPUT_2:
                actionToAdd = {
                  name: action.name,
                  action: action.value1 + " " + action.value2,
                  type: action.type,
                  actionId: action.actionId,
                  step: action.step,
                  package: action.package
                };
                break;
              case COMPONENT_TYPES.DROPDOWN_2:
                actionToAdd = {
                  name: action.name,
                  action: action.value1 + " " + action.value2,
                  type: action.type,
                  actionId: action.actionId,
                  step: action.step,
                  package: action.package
                };
                break;
              case COMPONENT_TYPES.NUMERICAL_INPUT_3:
                actionToAdd = {
                  name: action.name,
                  action: action.value1 + " " + action.value2 + " " + action.value3,
                  type: action.type,
                  actionId: action.actionId,
                  preDefinedValue: action.preDefinedValue,
                  step: action.step,
                  package: action.package
                };
                break;
              case COMPONENT_TYPES.TEXT_INPUT_1:
                let valueToStore = ''
                if (action.name === SET_INVOICE_PAYMENT_DATE && action.value !== '' && action.value !== null && action.value !== "null" && ! this.isDateInFormat(action.value)) {
                  valueToStore = format(action.value, 'yyyy/MM/dd').toString()
                }
                else{
                  valueToStore = action.value.toString()
                }
                actionToAdd = {
                  name: action.name,
                  action: valueToStore,
                  type: action.type,
                  actionId: action.actionId,
                  preDefinedValue: action.preDefinedValue,
                  step: action.step,
                  package: action.package
                };
                break;
              case COMPONENT_TYPES.NUMERICAL_DROPDOWN_1:
                actionToAdd = {
                  name: action.name,
                  copyPackages: action.name === SET_NUMBER_OF_PACKAGES ? action.copyPackages : false,
                  action: action.value.toString(),
                  type: action.type,
                  actionId: action.actionId,
                  preDefinedValue: action.preDefinedValue,
                  step: action.step,
                  package: action.package,
                };
                break;
                case COMPONENT_TYPES.DROPDOWN_1:
                  actionToAdd = {
                    name: action.name,
                    action: action.value.toString(),
                    type: action.type,
                    actionId: action.actionId,
                    preDefinedValue: action.preDefinedValue,
                    step: action.step,
                    package: action.package,
                    sendNotification: action.name === SET_SHIPPING_TEMPLATE ? action.sendNotification : false
                  }
                  break;
              default:
                actionToAdd = {
                  name: action.name,
                  action: action.value.toString(),
                  type: action.type,
                  actionId: action.actionId,
                  preDefinedValue: action.preDefinedValue,
                  step: action.step,
                  package: action.package
                }
            }
            actions[stepNames[i]] = [...actions[stepNames[i]], actionToAdd]
          })
        }
      }
      let conditionToAdd = {
        filters,
        actions,
        applyAllFilters: condition.applyAllFilters,
        exitOnMatch: condition.exitOnMatch,
        conditionId: condition.conditionId
      }
      conditions = [...conditions, conditionToAdd]
    })

    const newRule = {
      name: this.state.ruleName,
      group: this.state.groupName,
      ruleCategory: this.state.ruleCategory,
      applyToAllShipments: this.state.applyToAllShipments,
      applyToAllListings: this.state.applyToAllListings,
      autoApply: this.state.autoApply,
      skus: this.state.skus,
      conditions,
    }

    if (this.props.isEdit) {
      const editedRule = {
        ruleId: this.props.ruleToStore.rule.ruleId,
        name: this.state.ruleName,
        group: this.state.groupName,
        ruleCategory: this.state.ruleCategory,
        applyToAllShipments: this.state.applyToAllShipments,
        applyToAllListings: this.state.applyToAllListings,
        autoApply: this.state.autoApply,
        skus: this.state.skus,
        conditions,
      }
      this.props.clearStored()
      this.handleReset()
      this.props.editRule(editedRule, this.props.history)
      this.redirect('/automation-rules')
    } else {
      this.props.clearStored()
      this.handleReset()
      this.props.createRule(newRule)
      this.redirect('/automation-rules')
    }
  }

  handleNext = async (progress) => {

    if (progress - 1 === this.state.activeStep) {
      this.handleCreateOrEditRule()
    } else {
      this.setState({
        activeStep: this.state.activeStep + 1,
      });
    }
  };

  handleBack = () => {
    this.setState({
      activeStep: this.state.activeStep - 1,
    });
  };

  ruleNameUpdated = (event) => {
    this.setState({
      ruleName: event.target.value,
    });
  };

  groupNameUpdated = (event) => {
    this.setState({
      groupName: event.target.value,
    });
  };

  handleReset = () => {
    const setUUID = (conditionUUID) => {
      this.setState({
        activeStep: 0,
        ruleName: '',
        groupName: '',
        conditions: [{ conditionId: conditionUUID }],
      });
    }
    this.props.getUUID("condition", setUUID)
  };

  disableButton = () => {
    //Disables the button if the rule or group name is empty, or if the new rule name conflicts with an existing one
    switch (this.state.activeStep) {
      case (STEP_NAMING):
        return this.state.ruleName == '' || this.state.groupName == '' || this.state.ruleCategory == '' || this.state.ruleCategory == null || (this.state.ruleName != this.state.oldName && this.state.existRuleNames.includes(this.state.ruleName))
      //May need to disable the next button in the future for other steps, hence the switch statement
      default:
        return false
    }
  }

  handleMove = (id, direction) => {
    const position = this.state.conditions.findIndex((i) => i.conditionId === id)
    //Makes sure that the condition isn't outside of the range of conditions
    //These if statements should never be triggered, but the check is for safety reasons
    if (position < 0 || position >= this.state.conditions.length) {
      throw new Error('Condition not found.')
    } else if ((position === 0 && direction === UP) || (position === this.state.conditions.length - 1 && direction === DOWN)) {
      return //Cannot move up or down from current
    }
    let conditions = [...this.state.conditions]
    if (direction === UP) {
      [conditions[position], conditions[position - 1]] = [conditions[position - 1], conditions[position]]
    } else {
      [conditions[position], conditions[position + 1]] = [conditions[position + 1], conditions[position]]
    }
    this.setState({
      conditions
    })
  }

  handleCopy = (id) => {
    const conditionToCopy = this.state.conditions.find((i) => i.conditionId === id)
    const setUUID = (conditionUUID) => {
      this.setState({
        conditions: [...this.state.conditions,
        {
          conditionId: conditionUUID,
          filters: [...conditionToCopy.filters],
          actions: { ...conditionToCopy.actions },
          applyAllFilters: conditionToCopy.applyAllFilters,
          exitOnMatch: conditionToCopy.exitOnMatch,
        }],
      });
    }
    this.props.getUUID("condition", setUUID)
  }

  render() {
    return (
      <div>
        <ErrorFeedbackBar />
        <AutomationsRuleTableToolbar
          title={AUTOMATION_RULES}
          redirect={this.redirect}
          prepEdit={this.prepEdit}
          handleBack={this.handleBack}
          path={'/automation-rules'}
        />
        <Stepper activeStep={this.state.activeStep} alternativeLabel>
          {steps.map((label, index) => (
            <Step className='step' key={label}>
              <StepButton
                onClick={() => {
                  this.setStep(index);
                }}
                optional={
                  <Typography variant='caption'>
                    {stepDescriptions[index]}
                  </Typography>
                }
              >
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>

        <div className='automationRulesStepper'>
          {this.state.activeStep === steps.length ? (
            <div>
              <Button
                variant='contained'
                color='primary'
                onClick={this.handleReset}
              >
                {NEW_RULE}
              </Button>
            </div>
          ) : (
            <div>
              {this.getStepContent(this.state.activeStep)}
              <div>
                <Button
                  className='automation-rules-margin'
                  variant='contained'
                  onClick={this.handleBack}
                >
                  {this.state.activeStep === 0 ? CANCEL : BACK}
                </Button>
                <Button
                  className='automation-rules-margin'
                  variant='contained'
                  color='primary'
                  onClick={() => this.handleNext(steps.length)}
                  disabled={this.disableButton()}
                >
                  {this.state.activeStep === steps.length - 1 ? FINISH : NEXT}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ruleToStore: newRuleSelector(state),
  isEdit: isEditSelector(state),
  tags: tagsSelector(state),
  userInfo: userInfoSelector(state),
  user: userInfoSelector(state),
  rules: rulesSelector(state),
  selectedLocation: selectedLocationIdSelector(state),
  stateAndProvinceMapping: stateAndProvinceMappingSelector(state),
})
const actionCreators = {
  createRule,
  editRule,
  clearStored,
  listTags,
  getUUID,
  getUUIDs,
  listPackages,
  setShipmentSummaryOrderDirection,
  getStateAndProvinceMapping,
}

AutomationRulesStepper.propTypes = {
  ruleToStore: PropTypes.array,
  isEdit: PropTypes.bool
}

AutomationRulesStepper.defaultProps = {
  ruleToStore: [],
  isEdit: false,
  stateAndProvinceMapping: [],
}

export default withShipment({
  mapStateToProps,
  actionCreators
}, AutomationRulesStepper);
