import React from "react";
import withShipment from "../../../withShipment";
import CustomToolbar from "../../global/CustomToolbar";
import {
    BACK_TO_MENU,
} from "../ManageSuppliers/constants";
import {
    CLAIMS, CLAIMS_TOOLBAR_TITLE,
    HISTORICAL_DATA_HEAD_CELLS,
    HISTORICAL_DATA_IMPORT_OPTIONS,
    Process_Historical_TITLE, SUBMIT
} from "./constants";
import {
    Button, IconButton,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import './ImportClaimsData.css'
import {CANCEL, SAVE} from "../../global/constants";
import {clearHistoricalData, importHistoricalData} from "../../../redux/actions/settings";
import {historicalDataSelector, productsSelector} from "../../../redux/selectors/settings";
import GlobalTableHead from "../../global/Table/GlobalTableHead";
import {Box, Typography} from "@mui/material";
import CircularProgress from "@material-ui/core/CircularProgress";

class ImportClaimsData extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            onImportPage : false,
            source : "",
            processQueue: [],
            folderName: "",
            processing: false
        }

    }

    componentDidMount() {
        this.props.clearHistoricalData()
    }

    parseLines = (value) => value.replace(/(\\n)/g, "\n");

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if(prevProps.historicalData !== this.props.historicalData && this.props.historicalData!="") {
            this.setState({
                processing: false
            })
        }
    }

    handleSubmit = async () => {
        const curImport = {
            source: this.state.source,
            folderName: this.state.folderName
        }
        this.props.importHistoricalData(curImport,CLAIMS)
        this.setState({
            source: "",
            processQueue: [...this.state.processQueue, curImport],
            onImportPage: false,
            processing: true
        })
    }

    styleLineContent = (line) => {
        let styledLine;

        if (line.includes("high")) {
            styledLine = line.replace("high", "").replace(/(.*?):/, "<strong>$1</strong>:").replace("imported", "<span style='color: red;'>imported");
        } else if (line.includes("medium")) {
            styledLine = line.replace("medium", "").replace(/(.*?):/, "<strong>$1</strong>:").replace("imported", "<span style='color: orange;'>imported");
        } else if (line.includes("low")) {
            styledLine = line.replace("low", "").replace(/(.*?):/, "<strong>$1</strong>:").replace("imported", "<span style='color: green;'>imported");
        } else {
            styledLine = line;
        }

        // Add closing span tag
        if (styledLine.includes("imported")) {
            styledLine += "</span>";
        }

        return styledLine;
    }

    render() {
        const lines = this.props.historicalData.split('*');
        return (
            <>
                <CustomToolbar
                    creatingOrder={this.state.onImportPage}
                    title={CLAIMS_TOOLBAR_TITLE}
                    createTitle={Process_Historical_TITLE}
                    backTitle={BACK_TO_MENU}
                    handleClose={() => {
                        this.setState({
                            onImportPage: false
                        })
                    }}
                    onClick={() => {
                        this.setState({
                            onImportPage: true
                        })
                    }}
                />

                {this.state.onImportPage &&
                    <>
                        <div style={{
                            display: "flex",
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '70%',
                            margin: '0 auto'
                        }}>

                            <TextField
                                value={this.state.source}
                                className={"input-select-field fullWidth"}
                                onChange={(event) => this.setState({ source: event.target.value })}
                                name="Claims Spread Sheet Link"
                                variant="outlined"
                                label="Claims Spread Sheet Link"
                                InputLabelProps={{ shrink: true }}
                            />

                            <TextField
                                value={this.state.folderName}
                                className={"input-select-field fullWidth"}
                                onChange={(event) => this.setState({ folderName: event.target.value })}
                                name="Claims Pdf Folder Name"
                                variant="outlined"
                                label="Claims Pdf Folder Name"
                                InputLabelProps={{ shrink: true }}
                            />

                            <div style={{display: "flex", flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: '25px'}}>
                                <Button
                                    variant='contained'
                                    onClick={() => this.setState({onImportPage:false})}
                                >
                                    {CANCEL}
                                </Button>
                                <div className='save-spacing' />
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={() => this.handleSubmit()}
                                    disabled={this.state.source == "" || this.state.folderName == ""}
                                >
                                    {SUBMIT}
                                </Button>
                            </div>
                        </div>
                    </>
                }
                {!this.state.onImportPage &&
                    <Box
                        component="div"
                        padding={2}
                        margin={2}
                        border={1}
                        borderColor="divider"
                        borderRadius={2}
                    >
                        {lines.map((line, index) => {
                            const styledContent = this.styleLineContent(line.replace(/,/g, ""));
                            return (
                                <Box
                                    component="div"
                                    key={index}
                                    marginBottom={index !== lines.length - 1 ? 2 : 0}
                                >
                                    <Typography variant="h5" gutterBottom>
                                        <span dangerouslySetInnerHTML={{ __html: styledContent }} />
                                    </Typography>
                                </Box>
                            );
                        })}
                        { this.state.processing ?
                            <div>
                                <CircularProgress/>
                            </div>
                            :
                            ""
                        }
                    </Box>
                }
            </>
        );
    }


}


const mapStateToProps = (state) => ({
    historicalData: historicalDataSelector(state),
})

const actionCreators = {
    importHistoricalData,
    clearHistoricalData
}

ImportClaimsData.propTypes = {

}

ImportClaimsData.defaultProps = {

}

export default withShipment({
    mapStateToProps,
    actionCreators
}, ImportClaimsData);