import React from "react";
import withShipment from "../../../withShipment";
import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";
import {
    BACK, CANCEL,
    COUNTRY_OPTIONS,
    FTL_PAGES,
    FULL_TRUCKLOAD_CARRIERS,
    NEW_CARRIER,
    SAVE,
} from "../constants";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import {Box} from "@mui/material";
import {Button, Tab, Tabs, TextField, Typography} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {saveFullTruckloadCarriers} from "../../../redux/actions/fullTruckloadCarriers";
import {listAvailableLocations} from "../../../redux/actions/settings";
import {availableLocationsSelector} from "../../../redux/selectors/settings";
import {getStateAndProvinceMapping} from "../../../redux/actions/global";
import {stateAndProvinceMappingSelector} from "../../../redux/selectors/global";

const defaultProps = {
    bgcolor: 'background.paper',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    m: 1,

    border: 1,
    style: { width: '100%', height: '100%' },
};

class FullTruckloadCarriersForm extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            carrierInformation: {
                carrierId       : "",
                location        : "",
                accountNumber   : "",
                contactName     : "",
                currencyCode    : "",
                name            : "",   //company name
                phoneNumber     : "",
                addressLine1    : "",
                addressLine2    : "",
                city            : "",
                stateOrProvince : "",
                postalCode      : "",
                country         : "",
                email           : "",
            },
            availableLocations: [],
            allowSave: false
        }
    }

    componentDidMount() {
        this.props.listAvailableLocations()
        this.props.getStateAndProvinceMapping()

        const newCarrierInformation = {};
        Object.keys(this.state.carrierInformation).forEach(key => {
            newCarrierInformation[key] = this.props.parentState[key]
        });
        this.setState({
            carrierInformation: newCarrierInformation
        });
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevProps.availableLocations !== this.props.availableLocations) {
            this.setState({
                availableLocations: this.props.availableLocations.map(location =>{
                    return location.name
                })
            })
        }

        // when the user has made change on the form, update to the carrierStepper, when updated, update the state
        // then check if save should be allowed
        if (prevProps.parentState !== this.props.parentState) {
            const newCarrierInformation = {};
            Object.keys(this.state.carrierInformation).forEach(key => {
                newCarrierInformation[key] = this.props.parentState[key]
            });
            this.setState({
                carrierInformation: newCarrierInformation
            });
        }
    }

    handleCarrierInfoChange= (e) => {
        this.props.handleChange(e)
    }

    // handleSaveCarrier = () => {
    //     this.setState({
    //         showError:true
    //     })
    //
    //     if(this.state.carrierInformation.contactName && this.state.carrierInformation.name && this.state.carrierInformation.phoneNumber &&
    //         this.state.carrierInformation.addressLine1 &&this.state.carrierInformation.city && this.state.carrierInformation.stateOrProvince &&
    //         this.state.carrierInformation.postalCode && this.state.carrierInformation.country && this.state.carrierInformation.email &&
    //         this.state.carrierInformation.accountNumber && this.state.carrierInformation.location ){
    //         //send save request
    //         this.props.saveFullTruckloadCarriers(this.state.carrierInformation)
    //         //update the row in the table
    //         this.props.updateRow(this.state.carrierInformation)
    //         //go back to table page
    //         this.props.switchToFormAndTable()
    //     }
    //     else{
    //
    //     }
    // }

    handleCancel = () => {
        this.props.switchToFormAndTable()
    }

    render() {
        return (
            <React.Fragment>

                <div style={{margin: "25px"}}>

                </div>

                <div className="ftl-address-section">
                    <Box borderRadius="borderRadius" {...defaultProps} style={{borderColor: "lightgray", borderRadius: "5px"}}>

                        <div className="ftl-row-spec">
                            <Autocomplete
                                style={{margin: '10px', width: '200px'}}
                                value={this.state.carrierInformation.location}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                options={this.state.availableLocations}
                                getOptionLabel={(entry) => entry}
                                autoHighlight={true}
                                name="SelectedLocation"
                                onChange={(event, value) => {
                                    this.handleCarrierInfoChange({
                                        target: {
                                            name: "location",
                                            value: value
                                        }
                                    })
                                }}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Location"
                                        error={this.state.showError && this.state.carrierInformation.location === ""}
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        name="selectedLocation"
                                    />
                                }
                            />
                            <TextField
                                style={{margin: '10px', width: '200px'}}
                                variant="outlined"
                                name="accountNumber"
                                label="Account Number"
                                value={this.state.carrierInformation.accountNumber}
                                error={this.state.showError && this.state.carrierInformation.accountNumber === ""}
                                onChange={this.handleCarrierInfoChange}
                                size="medium"
                                InputLabelProps={{shrink: true}}
                            />
                            <TextField
                                style={{margin: '10px', width: '200px'}}
                                variant="outlined"
                                name="currencyCode"
                                label="Currency"
                                value={this.state.carrierInformation.currencyCode}
                                error={this.state.showError && this.state.carrierInformation.currencyCode === ""}
                                onChange={this.handleCarrierInfoChange}
                                size="medium"
                                InputLabelProps={{shrink: true}}
                            />
                        </div>
                        <div className="ftl-row-spec">

                            <TextField
                                style={{margin: '10px', width: '200px'}}
                                variant="outlined"
                                name="contactName"
                                label="Contact Name"
                                value={this.state.carrierInformation.contactName}
                                error={this.state.showError && this.state.carrierInformation.contactName === ""}
                                onChange={this.handleCarrierInfoChange}
                                size="medium"
                                InputLabelProps={{shrink: true}}
                            />

                            <TextField
                                style={{margin: '10px', width: '200px'}}
                                variant="outlined"
                                name="name"
                                label="Company Name"
                                value={this.state.carrierInformation.name}
                                error={this.state.showError && this.state.carrierInformation.name === ""}
                                onChange={this.handleCarrierInfoChange}
                                size="medium"
                                InputLabelProps={{shrink: true}}
                            />

                            <TextField
                                style={{margin: '10px', width: '200px'}}
                                variant="outlined"
                                name="phoneNumber"
                                label="Phone Number"
                                value={this.state.carrierInformation.phoneNumber}
                                error={this.state.showError && this.state.carrierInformation.phoneNumber === ""}
                                onChange={this.handleCarrierInfoChange}
                                size="medium"
                                InputLabelProps={{shrink: true}}
                            />
                        </div>

                        <div className="ftl-row-spec">
                            <TextField
                                style={{margin: '10px', width: '100%'}}
                                variant="outlined"
                                name="addressLine1"
                                label="Address Line 1"
                                value={this.state.carrierInformation.addressLine1}
                                error={this.state.showError && this.state.carrierInformation.addressLine1 === ""}
                                onChange={this.handleCarrierInfoChange}
                                size="medium"
                                InputLabelProps={{shrink: true}}
                            />
                        </div>

                        <div className="ftl-row-spec">
                            <TextField
                                style={{margin: '10px', width: '100%'}}
                                variant="outlined"
                                name="addressLine2"
                                label="Address Line 2"
                                value={this.state.carrierInformation.addressLine2}
                                onChange={this.handleCarrierInfoChange}
                                size="medium"
                                InputLabelProps={{shrink: true}}
                            />
                        </div>

                        <div className="ftl-row-spec">
                            <TextField
                                style={{margin: '10px', width: '200px'}}
                                variant="outlined"
                                name="city"
                                label="City"
                                value={this.state.carrierInformation.city}
                                error={this.state.showError && this.state.carrierInformation.city === ""}
                                onChange={this.handleCarrierInfoChange}
                                size="medium"
                                InputLabelProps={{shrink: true}}
                            />

                            <Autocomplete
                                style={{margin: '10px', width: '200px'}}
                                value={this.state.carrierInformation.stateOrProvince}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                options={this.props.stateAndProvinceMapping ? this.props.stateAndProvinceMapping.map(state => state.state) : []}
                                getOptionLabel={(entry) => entry}
                                autoHighlight={true}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        {option + " (" + this.props.stateAndProvinceMapping.find(state => state.state === option)?.displayName + ")"}
                                    </React.Fragment>
                                )}
                                name="stateOrProvince"
                                onChange={(event, value) => {
                                    this.handleCarrierInfoChange({
                                        target: {
                                            name: "stateOrProvince",
                                            value: value
                                        }
                                    })
                                }}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="State or Province"
                                        InputLabelProps={{ shrink: true }}
                                        error={this.state.showError && this.state.carrierInformation.stateOrProvince === ""}
                                        variant="outlined"
                                        name="StateOrProvince"
                                    />
                                }
                            />

                            <TextField
                                style={{margin: '10px', width: '200px'}}
                                variant="outlined"
                                name="postalCode"
                                label="Postal Code"
                                value={this.state.carrierInformation.postalCode}
                                error={this.state.showError && this.state.carrierInformation.postalCode === ""}
                                onChange={this.handleCarrierInfoChange}
                                size="medium"
                                InputLabelProps={{shrink: true}}
                            />
                        </div>

                        <div className="ftl-row-spec">
                            <Autocomplete
                                style={{margin: '10px', width: '200px'}}
                                value={this.state.carrierInformation.country}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                options={COUNTRY_OPTIONS}
                                getOptionLabel={(entry) => entry}
                                autoHighlight={true}
                                name="country"
                                onChange={(event, value) => {
                                    this.handleCarrierInfoChange({
                                        target: {
                                            name: "country",
                                            value: value
                                        }
                                    })
                                }}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Country"
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        error={this.state.showError && this.state.carrierInformation.country === ""}
                                        name="country"
                                    />
                                }
                            />

                            <TextField
                                style={{margin: '10px', width: '420px'}}
                                variant="outlined"
                                name="email"
                                label="email"
                                value={this.state.carrierInformation.email}
                                error={this.state.showError && this.state.carrierInformation.email === ""}
                                onChange={this.handleCarrierInfoChange}
                                size="medium"
                                InputLabelProps={{shrink: true}}
                            />
                        </div>
                    </Box>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    availableLocations: availableLocationsSelector(state),
    stateAndProvinceMapping: stateAndProvinceMappingSelector(state),
})

const actionCreators = {
    saveFullTruckloadCarriers,
    listAvailableLocations,
    getStateAndProvinceMapping
}

export default withShipment({
    mapStateToProps,
    actionCreators,
}, FullTruckloadCarriersForm);