export const FETCH_ALL_ORDERS = 'FETCH_ALL_ORDERS';
export const FETCH_ALL_SHIPMENTS = 'FETCH_ALL_SHIPMENTS';
export const FETCH_BY_ORDER_ID = 'FETCH_BY_ORDER_ID';
export const FETCH_BY_TRACKING_ID = 'FETCH_BY_TRACKING_ID';
export const RECREATE_ORDERS = 'RECREATE_ORDERS';
export const UPDATE_SHIPMENTS = 'UPDATE_SHIPMENTS';
export const DELETE_SHIPMENTS = 'DELETE_SHIPMENTS';
export const STORE_SHIPMENTS = 'STORE_SHIPMENTS';
export const PRINT_ORDER = 'PRINT_ORDER';
export const PRINT_TO_PDF = 'PRINT_PDF_ORDER';
export const MARK_AS_SHIPPED = 'MARK_AS_SHIPPED';
export const MARK_AS_CANCELLED = 'MARK_AS_CANCELLED';
export const MARK_AS_UNSHIPPED = 'MARK_AS_UNSHIPPED';
export const MARK_AS_UNCANCELLED = 'MARK_AS_UNCANCELLED';
export const MARK_AS_REDIRECTED = 'MARK_AS_REDIRECTED';
export const UPDATE_PRINTED_COUNT = 'UPDATE_PRINTED_COUNT';
export const EDIT_ORDER = 'EDIT_ORDER';
export const EDIT_MULTIPLE_ORDER = 'EDIT_MULTIPLE_ORDER'
export const FILTER_UNSHIPPED = 'FILTER_UNSHIPPED';
export const FILTER_SHIPPED = 'FILTER_SHIPPED';
export const FILTER_CANCELLED = 'FILTER_CANCELLED';
export const FILTER_RETURNS = 'FILTER_RETURNS';
export const FILTER_CLONED = 'FILTER_CLONED';
export const CANCEL_ORDER = 'CANCEL_ORDER';
export const CREATE_ORDER = 'CREATE_ORDER';
export const CREATE_RETURN_SHIPMENTS = 'CREATE_RETURN_SHIPMENTS';
export const REPRINT_ORDER = 'REPRINT_ORDER';
export const ARCHIVE_LABEL = 'ARCHIVE_LABEL';
export const VOID_ORDER = 'VOID_ORDER';
export const ADD_PACKAGE = 'ADD_PACKAGE';
export const COPY_PACKAGE = 'COPY_PACKAGE';
export const DELETE_PACKAGE = 'DELETE_PACKAGE';
export const REPRINT_PACKAGE = 'REPRINT_PACKAGE';
export const UPDATE_ORDERS = 'UPDATE_ORDERS';
export const UPDATE_ORDERS_BY_LIST = 'UPDATE_ORDERS_BY_LIST';
export const LOADING_SHIPMENT_REQUEST = 'LOADING_SHIPMENT_REQUEST';
export const EDIT_FROM_ADDRESS = 'EDIT_FROM_ADDRESS';
export const RATE_SHIPMENT = 'RATE_SHIPMENT';
export const TRACK_SHIPMENT = 'TRACK_SHIPMENT';
export const STORE_RATES = 'STORE_RATES';
export const SET_RATE_LOADING = 'RATE_LOADING';
export const CLEAR_RATES = 'CLEAR_RATES';
export const UPDATE_BOX_TYPE = 'UPDATE_BOX_TYPE';
export const SAVE_REDIRECT_ORDER_ID = 'SAVE_REDIRECT_ORDER_ID';
export const DELETE_REDIRECT_ORDER_ID = 'DELETE_REDIRECT_ORDER_ID';
export const OPEN_LINKED_ORDER = 'OPEN_LINKED_ORDER';
export const STORE_LINKED_SHIPMENT = 'STORE_LINKED_SHIPMENT';
export const CLEAR_LINKED_SHIPMENT = 'CLEAR_LINKED_SHIPMENT'
export const STORE_SHIPMENT = 'STORE_SHIPMENT'
export const REMOVE_SHIPMENT = 'REMOVE_SHIPMENT'

export const TEST_PRINT = 'TEST_PRINT';

export const IMPORT_ORDERS = 'IMPORT_ORDERS';
export const SET_IMPORT_STATUS = 'SET_IMPORT_STATUS';

export const GET_SHIPMENT_COMMERCIAL_INVOICE = 'GET_SHIPMENT_COMMERCIAL_INVOICE';
export const STORE_SHIPMENT_COMMERCIAL_INVOICE = 'STORE_SHIPMENT_COMMERCIAL_INVOICE';

export const APPLY_DEFAULT_SERVICE = 'APPLY_DEFAULT_SERVICE';
export const APPLY_CHEAPEST_SERVICE = 'APPLY_CHEAPEST_SERVICE';

export const VALIDATE_ADDRESS_SERVICE = 'VALIDATE_ADDRESS_SERVICE';

export const EDIT_SHIPMENT_CARRIER = 'EDIT_SHIPMENT_CARRIER';

export const ADD_COMMODITY = 'ADD_COMMODITY';
export const DELETE_COMMODITY = 'DELETE_COMMODITY';

export const GET_SUGGESTED_BOX_TYPES = "GET_SUGGESTED_BOX_TYPES";
export const STORE_SUGGESTED_BOX_TYPES = "STORE_SUGGESTED_BOX_TYPES";

export const OF = 'of';
export const RATED = 'rated';
export const VALIDATED = 'validated';

export const FETCH_BUY_SHIPPING_SERVICES = "FETCH_BUY_SHIPPING_SERVICES"
export const STORE_BUY_SHIPPING_SERVICES = "STORE_BUY_SHIPPING_SERVICES"
export const SAVE_BUY_SHIPPING_SERVICE = "SAVE_BUY_SHIPPING_SERVICE"

export const IGNORE_BOX_SUGGESTION = "IGNORE_BOX_SUGGESTION";

export const GET_MESSAGES = "GET_MESSAGES";
export const STORE_MESSAGES = 'STORE_MESSAGES';

export const GET_MESSAGE = "GET_MESSAGE";
export const GET_ALL_MESSAGES = "GET_ALL_MESSAGES";
export const STORE_ALL_MESSAGES = 'STORE_ALL_MESSAGES';
export const STORE_MESSAGE = 'STORE_MESSAGE';

export const GET_SHIPMENTS = 'GET_SHIPMENTS';
export const STORE_MESSAGES_FROM_SHIPMENTS = 'STORE_MESSAGES_FROM_SHIPMENTS';

export const GET_SAMPLE_COMMERCIAL_INVOICE_PDF = 'GET_SAMPLE_COMMERCIAL_INVOICE_PDF';
export const STORE_SAMPLE_COMMERCIAL_INVOICE_PDF = 'STORE_SAMPLE_COMMERCIAL_INVOICE_PDF';
export const RESET_PDF_DATA = 'RESET_PDF_DATA';

export const UPDATE_AND_VALIDATE_SHIPMENTS='UPDATE_AND_VALIDATE_SHIPMENTS';

export const SET_SHIPMENTS_OPEN_EDIT_ROW = 'SET_SHIPMENTS_OPEN_EDIT_ROW';
export const UPDATE_SHIPMENTS_OPEN_EDIT_ROW = 'UPDATE_SHIPMENTS_OPEN_EDIT_ROW'

export const EXPORT_SHIPMENT_SUMMARY_TO_EXCEL='EXPORT_SHIPMENT_SUMMARY_TO_EXCEL';
export const SET_SHIPMENT_SUMMARY_ORDER='SET_SHIPMENT_SUMMARY_ORDER';

export const SET_SHIPMENT_SMART_SEARCH_ROWS = "SET_SHIPMENT_SMART_SEARCH_ROWS"

