import React from "react";
import withShipment from "../../../withShipment";
import PropTypes from "prop-types";

//styling
import "./TransactionsSettings.css"

// constants
import {
    ADD_CATEGORY,
    BACK,
    TRANSACTION_CATEGORY_HEAD_CELLS,
    TRANSACTION_PAGES,
    TRANSACTION_SETTINGS_PAGE_NAME
} from "./constants";

// custom components
import ErrorFeedbackBar from "../../global/ErrorFeedbackBar";
import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";
import TwoStepDelete from "../../global/subcomponents/twoStepDelete/TwoStepDelete";

// mui components and icons
import {IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

// redux
import {
    deleteTransactionCategory,
    saveTransactionCategory
} from "../../../redux/actions/accounting";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

class TransactionSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            transactionCategories: this.props.transactionCategories,
            beforeEditRow: null,
            editingRowIndex: null,
            deleteId: "",
        }
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if(prevProps.transactionCategories !== this.props.transactionCategories){
            this.setState({
                transactionCategories: this.props.transactionCategories
            })
        }
    }

    handleCancelRow = (rowIndex) => {
        const updatedTransactionCategories = [...this.state.transactionCategories]
        if (this.state.beforeEditRow)
            updatedTransactionCategories[rowIndex] = this.state.beforeEditRow
        else
            updatedTransactionCategories.splice(rowIndex, 1)
        this.setState({
            editingRowIndex: null,
            transactionCategories: updatedTransactionCategories,
            beforeEditRow: null,
        })
    }

    handleEditRow = (rowIndex) => {
        this.setState({
            editingRowIndex: rowIndex,
            beforeEditRow: {...this.state.transactionCategories[rowIndex]}
        })
    }

    handleSaveRow = (rowIndex) => {
        this.props.saveTransactionCategory(this.state.transactionCategories[rowIndex])
        this.setState({editingRowIndex: null})
    }

    handleDeleteRow = () => {
        this.props.deleteTransactionCategory({transactionCategoryId: this.state.deleteId})
        this.setState({ deleteId: "" })
    }

    handleChange = (e, index) => {
        const updatedTransactionCategories = [...this.state.transactionCategories]
        updatedTransactionCategories[index][e.target.name] = e.target.value
        this.setState({ transactionCategories: updatedTransactionCategories })
    }

    addEntry = () => {
        const newCategory = {}
        TRANSACTION_CATEGORY_HEAD_CELLS.forEach((cell) => {
            newCategory[cell.id] = cell.options ? cell.options[0] : ""
        })
        this.setState({
            transactionCategories: [...this.state.transactionCategories, newCategory],
            editingRowIndex: this.state.transactionCategories.length
        })
    }

    removeEntry = (index) => {
        const updatedTransactionCategories = [...this.state.transactionCategories]
        updatedTransactionCategories.splice(index, 1)
        this.setState({transactionCategories: updatedTransactionCategories})
    }

    render() {
        return (
            <>
                <ErrorFeedbackBar />
                <TopToolbar
                    pageName={TRANSACTION_SETTINGS_PAGE_NAME}
                    menuItems={[
                        {
                            title: BACK,
                            icon: <KeyboardBackspaceIcon/>,
                            onClick: () => {this.props.setCurrentPage(TRANSACTION_PAGES.TABLE, {})}
                        },
                    ]}
                />
                <Table className="table-wrapper" style={{marginTop: '15px'}}>
                    <TableHead>
                        <TableRow className="table">
                            <TableCell className="icon-cell"/>
                            {TRANSACTION_CATEGORY_HEAD_CELLS.map((headCell) => (
                                <TableCell key={headCell.id} style={{width: '400px'}}>
                                    {headCell.label}
                                </TableCell>))
                            }
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.transactionCategories?.map((row, rowIndex) => {
                            const isEditing = this.state.editingRowIndex === rowIndex
                            return (
                                <TableRow>
                                    <TableCell className="icon-cell">
                                        {isEditing?
                                            <IconButton onClick={() => this.handleCancelRow(rowIndex)}>
                                                <CancelIcon fontSize="small" color="error"/>
                                            </IconButton>
                                            :
                                            <IconButton onClick={() => this.handleEditRow(rowIndex)}>
                                                <EditIcon fontSize="small"/>
                                            </IconButton>
                                        }
                                    </TableCell>
                                    {TRANSACTION_CATEGORY_HEAD_CELLS.map((headCell, colIndex) => (
                                        <TableCell key={colIndex} className="table-cell">
                                            {isEditing ? (
                                                (() => {
                                                    switch (headCell.fieldType) {
                                                        case "Textfield":
                                                            return (
                                                                <TextField
                                                                    variant="outlined"
                                                                    name={headCell.id}
                                                                    value={row[headCell.id]}
                                                                    onChange={(e) => this.handleChange(e, rowIndex)}
                                                                    style={{width: "250px"}}
                                                                />
                                                            )
                                                        default:
                                                            return row[headCell.id]
                                                    }
                                                })()
                                            ): (
                                                row[headCell.id]
                                            )}
                                        </TableCell>
                                    ))}
                                    {isEditing ?
                                        <TableCell className="table-cell">
                                            <IconButton
                                                onClick={() => this.handleSaveRow(rowIndex)}
                                                disabled={Object.values(this.state.transactionCategories[rowIndex]).some(value => value === '')}
                                            >
                                                <CheckCircleIcon
                                                    fontSize="small"
                                                    color={Object.values(this.state.transactionCategories[rowIndex]).some(value => value === '') ? "disabled" : "secondary"}/>
                                            </IconButton>
                                        </TableCell>
                                        :
                                        <TwoStepDelete
                                            rowId={row.transactionCategoryId}
                                            handleDelete={(rowIdToDelete) => this.handleDeleteRow()}
                                            deleteId={this.state.deleteId}
                                            setDeleteId={(newDeleteId) => this.setState({deleteId: newDeleteId})}
                                            disabled={row.isDefault}
                                        />
                                    }
                                </TableRow>
                            )
                        })}
                        {this.state.editingRowIndex === null ?
                            <TableRow>
                                <TableCell className='check'>
                                    <Tooltip placement="left" title={ADD_CATEGORY}>
                                        <IconButton onClick={this.addEntry}>
                                            <AddCircleIcon
                                                fontSize="small"
                                                color='secondary'
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                            :
                            null
                        }
                    </TableBody>
                </Table>
            </>
        )
    }
}

const actionCreators = {
    saveTransactionCategory,
    deleteTransactionCategory,
}

TransactionSettings.propTypes = {
    transactionCategories: PropTypes.array,
}

TransactionSettings.defaultProps = {
    transactionCategories: [],
}

export default withShipment({
    actionCreators
}, TransactionSettings);