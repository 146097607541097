import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";

// import tables
import QueryConditionFiltersTable from "./QueryConditionFiltersTable";
import QueryConditionActionsTable from "./QueryConditionActionsTable";

// import styling
import "./QueryConditionDialog.css";

//import { APPLY, CANCEL } from ".../global/constants";

// import constants
import PropTypes from "prop-types";
import {TABLE_TYPES} from "../../automationrules/constants";
import ConditionFiltersTable from "../../automationrules/ConditionFilters/ConditionFiltersTable";
import ConditionActionsTable from "../../automationrules/ConditionActions/ConditionActionsTable";

class ConditionDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedFilters: [], // selected filters - we store this here and not the table
      selectedActions: []
    };
  }

  isSelected = (name, isFilter) => {
    // handle filter or action accordingly
    if (isFilter) {
      return this.state.selectedFilters.findIndex((item) => item.name === name) !== -1;
    } else {
      return this.state.selectedActions.findIndex((item) => item.name === name) !== -1;
    }
  };

  handleSelectAllClick = (event, rows, isFilter) => {
    // handle filter or action accordingly
    let selected = isFilter ? [...this.state.selectedFilters] : [...this.state.selectedActions];
    if (event.target.checked) {
      selected = rows;
    } else {
      selected = [];
    }

    // handle filter or action accordingly
    isFilter ? this.setState({selectedFilters: selected,}) : this.setState({selectedActions: selected});

  };

  handleClick = (event, filter, isFilter) => {
    // make a copy of the current selected items
    let selected = isFilter ? [...this.state.selectedFilters] : [...this.state.selectedActions];

    // first check if this row actually exists in the list of selections
    let index = -1;
    index = selected.findIndex((item) => item.name === filter.name);

    // if it does not exist, add the item else remove it
    if (index === -1) {
      selected = [...selected, filter];
    } else {
      selected.splice(index, 1);
    }


    // update the state with actions or filters accordingly
    isFilter ? this.setState({selectedFilters: selected,}) : this.setState({selectedActions: selected});

  };

  onApply = (isFilter) => {
    // apply selectedFilters or actions accordingly
    this.props.onApply(isFilter ? this.state.selectedFilters : this.state.selectedActions);
    // reset both state variables to empty arrays
    // no need to check which one to reset based off of isFilter, since they will both be empty anyway
    this.setState({
      selectedFilters: [],
      selectedActions: []
    });
  };

  closeDialog = () => {
    this.setState({
      selectedFilters: [],
      selectedActions: []
    });
    this.props.onClose(false)
  };

  renderCorrectTable = () => {
    switch (this.props.tableType) {
      case TABLE_TYPES.FILTER:
        return (
            <QueryConditionFiltersTable
                selected={this.state.selectedFilters}
                isSelected={this.isSelected}
                handleClick={this.handleClick}
                handleSelectAllClick={this.handleSelectAllClick}
                isFilterSet={this.props.isFilterSet}
            />
        )
      case TABLE_TYPES.ACTION:
        return (
            <QueryConditionActionsTable
                selected={this.state.selectedActions}
                isSelected={this.isSelected}
                handleClick={this.handleClick}
                handleSelectAllClick={this.handleSelectAllClick}
            />
        )
    }
  }

  render() {
    return (
      <Dialog
        open={this.props.isOpen}
        onClose={() => this.props.onClose(false)}
      >
        <DialogContent className='conditionFiltersDialogContent'>
          {this.renderCorrectTable()}{" "}
        </DialogContent>{" "}
        <DialogActions>
          <Button onClick={() => this.closeDialog()} color='primary'>
            {" "}
            {"Cancel"}{" "}
          </Button>{" "}
          <Button onClick={() => this.onApply(this.props.tableType === TABLE_TYPES.FILTER)} color='primary'>
            {" "}
            {"Apply"}{" "}
          </Button>{" "}
        </DialogActions>{" "}
      </Dialog>
    );
  }
}

ConditionDialog.propTypes = {
  numSelected: PropTypes.number,
  selected: PropTypes.array,
  redirect: PropTypes.func,
  prepEdit: PropTypes.func,
  handleCopy: PropTypes.func,
  handleDelete: PropTypes.func,
  hasSetPackageNumber: PropTypes.bool
}

ConditionDialog.defaultProps = {
  numSelected: 0,
  selected: [],
  redirect: ()=>{},
  prepEdit: ()=>{},
  handleCopy: ()=>{},
  handleDelete: ()=>{},
  hasSetPackageNumber: false
}

export default ConditionDialog;
