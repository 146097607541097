import {
    REQUEST_STATUS,
    SET_LOADING_STATUS,
    SET_PRICING_LOADING_STATUS,
    SET_OPEN,
    SET_SAVING_OPEN,
    CLEAR_STATUS,
    SET_SAVING_STATUS,
    SET_CREATE_ORDER_OPEN,
    SET_VOID_OPEN,
    SET_VOID_STATUS,
    HIDE_MENU_ITEMS,
    SHOW_MENU_ITEMS,
    SET_CREATE_RETURN_OPEN,
    SET_CREATE_CLONED_OPEN,
    SHOW_ERROR_MESSAGE,
    HIDE_ERROR_MESSAGE,
    SET_ARCHIVE_STATUS, SET_ARCHIVE_OPEN, GET_STATE_AND_PROVINCE_MAPPING, STORE_STATE_AND_PROVINCE_MAPPING
} from "../../constants/global";

export const requestStatus = (status) => ({
    type: REQUEST_STATUS,
    status,
});

export const setLoadingStatus = (loading) => ({
    type: SET_LOADING_STATUS,
    loading,
});
export const setPricingLoadingStatus = (pricingDashboardLoading) => ({
    type: SET_PRICING_LOADING_STATUS,
    pricingDashboardLoading,
});

export const setOpen = (open) => ({
    type: SET_OPEN,
    open,
});

export const clearStatus = () => ({
    type: CLEAR_STATUS
});

export const setSavingStatus = (saving) => ({
    type: SET_SAVING_STATUS,
    saving,
});

export const setSavingOpen = (savingOpen) => ({
    type: SET_SAVING_OPEN,
    savingOpen,
});

export const setCreateOrderOpen = (createOrderOpen) => ({
    type: SET_CREATE_ORDER_OPEN,
    createOrderOpen,
});

export const setCreateReturnOpen = (createReturnOpen) => ({
    type: SET_CREATE_RETURN_OPEN,
    createReturnOpen,
});

export const setCreateClonedOpen = (createClonedOpen) => ({
    type: SET_CREATE_CLONED_OPEN,
    createClonedOpen,
});

export const setVoidOpen = (voidOpen) => ({
    type: SET_VOID_OPEN,
    voidOpen,
});

export const setVoidStatus = (voidStatus) => ({
    type: SET_VOID_STATUS,
    voidStatus,
});

export const setArchiveOpen = (archiveOpen) => ({
    type: SET_ARCHIVE_OPEN,
    archiveOpen,
});

export const setArchiveStatus = (archiveStatus) => ({
    type: SET_ARCHIVE_STATUS,
    archiveStatus,
});

export const hideMenuItems = () => ({
    type: HIDE_MENU_ITEMS
});

export const showMenuItems = () => ({
    type: SHOW_MENU_ITEMS
});

export const showErrorMessage = (message) => ({
    type: SHOW_ERROR_MESSAGE,
    message
});

export const hideError = () => ({
    type: HIDE_ERROR_MESSAGE,
});

export const getStateAndProvinceMapping = (currentStateAndProvinceMapping) => ({
    type: GET_STATE_AND_PROVINCE_MAPPING,
    currentStateAndProvinceMapping
})
export const storeStateAndProvinceMapping = (mapping) => ({
    type: STORE_STATE_AND_PROVINCE_MAPPING,
    mapping
})


